import axios from 'axios';
import { createSelector } from 'reselect';
import { deleteFromArr } from '../../../utils/deleteFromArray';

const moduleName = 'market';
const SET_MARKET_PRODUCT_OFFERS = `${moduleName}/SET_MARKET_PRODUCT_OFFERS`;
const SET_MARKET_CATEGORIES = `${moduleName}/SET_MARKET_CATEGORIES`;
const SET_LOADING = `${moduleName}/SET_LOADING`;
const ADD_TO_BASKET = `${moduleName}/ADD_TO_BASKET`;
const DELETE_FROM_BASKET = `${moduleName}/DELETE_FROM_BASKET`;
const CHANGE_BASKET_PRODUCT_COUNT = `${moduleName}/CHANGE_BASKET_PRODUCT_COUNT`;
const GET_MORE_MARKET_PRODUCTS = `${moduleName}/GET_MORE_MARKET_PRODUCTS`;
const SET_MORE_LOADING = `${moduleName}/SET_MORE_LOADING`;
const GET_PROVIDER_LIST = `${moduleName}/GET_PROVIDER_LIST`;
const GET_PROVIDER_DETAIL = `${moduleName}/GET_PROVIDER_DETAIL`;
const LOAD_PROVIDER_DETAIL = `${moduleName}/LOAD_PROVIDER_DETAIL`;

const initialState = {
  productList: [],
  providerProducts: null,
  purchaserProducts: null,
  providerList: [],
  providerDetail: null,
  loading: false,
  productDetail: null,
  productDetailOffers: null,
  categories: null,
  basketProducts: [],
  catalogFilters: null,
  pagination: { limit: 10, offset: 0 },
  listEnded: false,
  moreLoading: false,
  providerIsLoading: true
};

const changeProductCount = (itemId, val, products) => {
  const targetProduct = products.filter(item => item.id === itemId)[0];
  const itemIndex = products.indexOf(targetProduct);
  const newArr = [...products];
  newArr[itemIndex] = { ...targetProduct, basketCount: val };
  return newArr;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'GET_MARKET_PRODUCTS':
      return {
        ...state,
        productList: action.payload.results,
        listEnded: action.payload.count <= action.payload.results.length,
        loading: false,
      };

    case SET_MORE_LOADING:
      return {
        ...state,
        moreLoading: true
      };
    case GET_MORE_MARKET_PRODUCTS:
      return {
        ...state,
        productList: [...state.productList, ...action.payload.results],
        listEnded: action.payload.count <= [...state.productList, ...action.payload.results].length,
        moreLoading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
        catalogFilters: action.payload
      };
    case 'GET_MARKET_PROVIDER_PRODUCTS':
      return {
        ...state,
        providerProducts: action.payload,
      };
    case 'GET_MARKET_PURCHASER_PRODUCTS':
      return {
        ...state,
        purchaserProducts: action.payload,
      };
    case 'GET_MORE_PRODUCTS':
      const productListResults = state.productList.concat(action.payload);

      return {
        ...state,
        productList: productListResults,
        nextProducts: action.next,
      };
    case GET_PROVIDER_LIST:
      return {
        ...state,
        providerList: action.payload,
      };
    case GET_PROVIDER_DETAIL:
      return {
        ...state,
        providerDetail: action.payload,
        providerIsLoading: false
      };
    case LOAD_PROVIDER_DETAIL:
      return {
        ...state,
        providerIsLoading: true
      };
    case SET_MARKET_PRODUCT_OFFERS:
      return {
        ...state,
        productDetailOffers: action.payload,
      };
    case SET_MARKET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case ADD_TO_BASKET:
      return {
        ...state,
        basketProducts: !state.basketProducts
          ? [{ ...action.payload.product, basketCount: action.payload.basketCount }]
          : [...state.basketProducts, { ...action.payload.product, basketCount: action.payload.basketCount  }],
      };
    case DELETE_FROM_BASKET:
      return {
        ...state,
        basketProducts: deleteFromArr(action.payload, state.basketProducts),
      };
    case CHANGE_BASKET_PRODUCT_COUNT:
      return {
        ...state,
        basketProducts: changeProductCount(
          action.payload.id,
          action.payload.val,
          state.basketProducts
        ),
      };
    case 'ATTACH_PROVIDER':
      return {
        ...state,
        providerList: action.payload,
      };
    case 'GET_MARKET_PRODUCT_DETAILS': {
      return {
        ...state,
        productDetail: action.payload,
      };
    }
    case 'DETACH_PROVIDER':
      return {
        ...state,
        providerList: action.payload,
      };

    default:
      return state;
  }
};

export const stateSelector = state => state[moduleName];
export const marketCategoriesSelector = createSelector(stateSelector, state => state.categories);
export const marketProvidersSelector = createSelector(stateSelector, state => state.providerList);
export const marketProviderDetailSelector = createSelector(stateSelector, state => state.providerDetail);
export const marketProviderLoadingSelector = createSelector(stateSelector, state => state.providerIsLoading);
export const basketProductsSelector = createSelector(stateSelector, state => state.basketProducts);
export const catalogFiltersSelector = createSelector(stateSelector, state => state.catalogFilters);
export const catalogPaginationSelector = createSelector(stateSelector, state => state.pagination);
export const catalogListEndedSelector = createSelector(stateSelector, state => state.listEnded);
export const catalogMoreLoadingSelector = createSelector(stateSelector, state => state.moreLoading);

export const addToBasket = (product, basketCount) => {
  return {
    type: ADD_TO_BASKET,
    payload: { product, basketCount }
  };
};
export const deleteFromBasket = product => {
  return {
    type: DELETE_FROM_BASKET,
    payload: product,
  };
};
export const changeBasketProductCount = (id, val) => {
  return {
    type: CHANGE_BASKET_PRODUCT_COUNT,
    payload: { id, val },
  };
};

export function getMarketCategories(entityId) {
  const config = {};
  return dispatch =>
    axios.get(`/entities/${entityId}/market/categories/`, config).then(response => {
      dispatch({
        type: SET_MARKET_CATEGORIES,
        payload: response.data.results,
      });
    });
}

export const getMarketProducts = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, categoryId = null, filters = null, paginationParams = null, attributes = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        categories: categoryId,
        ...filters,
        attributes
      },
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };
    return dispatch => {
      dispatch({
        type: SET_LOADING,
        payload: filters
      });
      axios.get(`/entities/${entityId}/market/products/`, config).then(response => {
        dispatch({
          type: 'GET_MARKET_PRODUCTS',
          payload: response.data,
        });
      });
    };
  };
})();

export const getMoreMarketProducts = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, categoryId = null, filters = null, paginationParams = null, attributes = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        categories: categoryId,
        ...filters,
        attributes
      },
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };
    return dispatch => {
      dispatch({
        type: SET_MORE_LOADING
      });
      axios.get(`/entities/${entityId}/market/products/`, config).then(response => {
        dispatch({
          type: GET_MORE_MARKET_PRODUCTS,
          payload: response.data,
        });
      });
    };
  };
})();

export function getProductDetail(entityId, productId) {
  const config = {};
  return dispatch =>
    axios.get(`/entities/${entityId}/market/products/${productId}/`, config).then(response => {
      dispatch({
        type: 'GET_MARKET_PRODUCT_DETAILS',
        payload: response.data,
      });
    });
}

export const getProductOffers = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, productId, paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: sorting,
      },
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };
    return dispatch =>
      axios
        .get(`/entities/${entityId}/market/products/${productId}/offers/`, config)
        .then(response => {
          dispatch({
            type: SET_MARKET_PRODUCT_OFFERS,
            payload: response.data,
          });
        });
  };
})();

export function getMoreProducts(key, entryId, next) {
  const config = {
    headers: {
      Authorization: `Token ${key}`,
      'Cache-Control': 'no-cache',
    },
  };
  return dispatch =>
    axios.get(next, config).then(response => {
      dispatch({
        type: 'GET_MORE_PRODUCTS',
        payload: response.data.results,
        next: response.data.next,
      });
    });
}

export function getProviderList(entytyId) {
  const config = {};
  return dispatch =>
    axios.get(`/entities/${entytyId}/market/providers/`, config).then(response => {
      dispatch({
        type: GET_PROVIDER_LIST,
        payload: response.data.results,
        next: response.data.next,
      });
    });
}
export function getProviderDetail(entytyId, providerId) {
  const config = {};
  return dispatch => {
    dispatch({
      type: LOAD_PROVIDER_DETAIL,
    });
    axios.get(`/entities/${entytyId}/market/providers/${providerId}/`, config).then(response => {
      dispatch({
        type: GET_PROVIDER_DETAIL,
        payload: response.data,
      });
    });
  }
}
export function getMoreProvider(entityId, authkey, query) {
  ///market/3/?search=
}
export function attachProvider(authKey, entityId, providerEntityId) {
  const config = {
    headers: {
      Authorization: `Token ${authKey}`,
      'Cache-Control': 'no-cache',
    },
  };
  return dispatch =>
    axios
      .get(`/purchasers/${entityId}/providers/${providerEntityId}/attach/`, config)
      .then(res => console.log(res.data));
}
export function getProviderProducts(key, entityId) {
  const config = {
    headers: {
      Authorization: `Token ${key}`,
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
  };
  return dispatch =>
    axios.get(`/providers/${entityId}/products/`, config).then(response => {
      dispatch({
        type: 'GET_MARKET_PROVIDER_PRODUCTS',
        payload: response.data.results,
      });
    });
}

export function getPurchasherProducts(key, entityId) {
  const config = {
    headers: {
      Authorization: `Token ${key}`,
      'Cache-Control': 'no-cache',
    },
  };
  return dispatch =>
    axios.get(`/purchasers/${entityId}/products/`, config).then(response => {
      dispatch({
        type: 'GET_MARKET_PURCHASER_PRODUCTS',
        payload: response.data.results,
      });
    });
}

export function serachProducts(userKey, entryId, query) {
  const config = {
    headers: {
      Authorization: `Token ${userKey}`,
      'Cache-Control': 'no-cache',
    },
    params: {
      search: query,
    },
  };
  return dispatch =>
    axios.get(`/market/${entryId}/`, config).then(response => {
      dispatch({
        type: 'GET_MARKET_PRODUCTS',
        payload: response.data.results,
      });
    });
}
