import React, { MouseEventHandler, useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import useOnClickOutside from "hooks/useOnClickOutside";

import { diagramFiltersSelector } from "redux/modules/common/building/manufacturing/selectors";
import { updateDiagramFilters } from "redux/modules/common/building/manufacturing/thunks";

import TicketAcceptedCircle from "images/TicketAcceptedCircle";
import TicketSharedCircle from "images/TicketSharedCircle";
import TicketDeniedTriangle from "images/TicketDeniedTriangle";
import arrowDownSelect from "images/icons/arrowDownSelect.svg";
import diagramLinkingIcon from "images/diagramLinkingIcon.svg";
import closeNotyItem from "images/icons/closeNotyItem.svg";

import { Switch } from "../../../../UI/atoms/Switch/Switch";
import Icon from "../../../../UI/Icon";

import { DiagramFilters } from "../../constants";

import styles from "./DiagramActions.module.scss";

const DiagramActions: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const diagramFilters = useSelector(diagramFiltersSelector);

  const containerRef = useRef<HTMLDivElement>(null);
  const onClose = useCallback(() => setIsOpen(false), []);

  useOnClickOutside(containerRef, onClose);

  const onActionChange = (name: string, value: boolean) => {
    dispatch(updateDiagramFilters({ name, value }));
  };

  const toggleOpen: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  }, []);

  const isEnabledConfirmedHighlight = diagramFilters[DiagramFilters.confirmed_highlight];
  const isEnabledModerationHighlight = diagramFilters[DiagramFilters.moderation_highlight];
  const isEnabledCancelHighlight = diagramFilters[DiagramFilters.canceled_highlight];

  const isShownSeparateLine = useMemo(
    () => [isEnabledConfirmedHighlight, isEnabledModerationHighlight, isEnabledCancelHighlight].some((x) => x),
    [isEnabledConfirmedHighlight, isEnabledModerationHighlight, isEnabledCancelHighlight]
  );

  const isLinkingEnabled = diagramFilters[DiagramFilters.linking_enabled];

  const disableLinking = useCallback(() => onActionChange(DiagramFilters.linking_enabled, false), [])

  return (
    <div className={styles.diagramActions} ref={containerRef}>
      {isLinkingEnabled && (
        <div className={styles.linkingModeBubble}>
          <Icon icon={diagramLinkingIcon} className={styles.linkingModeBubbleLinksIcon} />
          <span>Связи между работами</span>
          <Icon icon={closeNotyItem} className={styles.linkingModeBubbleCloseIcon} onClick={disableLinking}/>
        </div>
      )}
      <div className={styles.actionsContainer}>
        <div className={cn(styles.head, { [styles.isOpen]: isOpen })} onClick={toggleOpen}>
          {isEnabledConfirmedHighlight && <TicketAcceptedCircle className={styles.sharedIcon} />}
          {isEnabledModerationHighlight && <TicketSharedCircle className={styles.sharedIcon} />}
          {isEnabledCancelHighlight && <TicketDeniedTriangle className={styles.sharedIcon} />}
          {isShownSeparateLine && <div className={styles.labelSeparator} />}
          <span>Действия</span>
          <Icon icon={arrowDownSelect} className={cn(styles.arrow, { [styles.arrowReverse]: isOpen })} />
        </div>
        {isOpen && (
          <div className={styles.content}>
            <div className={styles.actionsGroup}>
              <span className={styles.actionsGroupLabel}>Выделить на графике:</span>
              <Switch
                className={styles.action}
                onChange={(e) => onActionChange(DiagramFilters.confirmed_highlight, e.target.checked)}
                checked={isEnabledConfirmedHighlight}
                label={
                  <div className={styles.actionLabel}>
                    <TicketAcceptedCircle className={styles.sharedIcon} />
                    <span>Принятые</span>
                  </div>
                }
              />
              <Switch
                className={styles.action}
                onChange={(e) => onActionChange(DiagramFilters.moderation_highlight, e.target.checked)}
                checked={isEnabledModerationHighlight}
                label={
                  <div className={styles.actionLabel}>
                    <TicketSharedCircle className={styles.sharedIcon} />
                    <span>Переданные</span>
                  </div>
                }
              />
              <Switch
                className={styles.action}
                onChange={(e) => onActionChange(DiagramFilters.canceled_highlight, e.target.checked)}
                checked={isEnabledCancelHighlight}
                label={
                  <div className={styles.actionLabel}>
                    <TicketDeniedTriangle className={styles.sharedIcon} />
                    <span>Отклоненные</span>
                  </div>
                }
              />
            </div>
            <div className={styles.actionsGroup}>
              <span className={styles.actionsGroupLabel}>Связи между отрезками:</span>
              <Switch
                className={styles.action}
                onChange={(e) => onActionChange(DiagramFilters.linking_enabled, e.target.checked)}
                checked={isLinkingEnabled}
                label={<span className={styles.actionLabel}>Отображать связи</span>}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(DiagramActions);
