import {
  DROP_LOADED_CHART_MONTHS,
  GET_ESTIMATE_MATERIALS_TREE,
  GET_ESTIMATE_TREE,
  GET_EXPENDITURE,
  GET_INTERVAL,
  GET_MATERIAL_INTERVAL,
  GET_MATERIALS,
  GET_MODAL_FILES,
  GET_MODAL_INTERVAL,
  GET_MODAL_INTERVAL_LIST,
  GET_MODAL_MATERIALS,
  GET_PLAN,
  GET_WEEK_MATERIALS,
  GET_WEEK_PLAN,
  MARK_CONSTRUCTING_PROJECT_DATA_TYPE_MONTH_YEAR,
  MARK_MANUFACTURING_PROJECT_DATA_TYPE_MONTH_YEAR,
  PROJECT_ESTIMATE_SET,
  SET_ACTIVE_BRANCH,
  SET_CHART_VIEW_MODE,
  UPDATE_DIAGRAM_FILTERS,
  SET_IS_LOADING_CHART_DATA,
  SET_IS_LOADING_CHART_TREE,
  SET_IS_LOADING_MODAL,
  SET_MATERIAL_DATA,
  SET_MATERIAL_WEEK_DATA,
  SET_MODAL_DATA,
  SET_MONTH_MARKERS,
  SET_PROJECT_DATA,
  SET_PROJECT_WEEK_DATA,
  SET_TAB,
  SET_IS_BEING_DRAGGED,
  SET_IS_ENABLED_LINKING,
  ADD_INTERVAL_LINK,
  SET_ARROWS,
  DELETE_ARROW,
  UPDATE_ARROW,
  SET_RELATIONS_TO_CURRENT_INTERVAL,
  SET_RELATIONS_FROM_CURRENT_INTERVAL,
  SET_AVAILABLE_SECTIONS,
  SET_AVAILABLE_INTERVALS,
  PUSH_TO_RELATIONS,
  PUSH_FROM_RELATIONS,
  REMOVE_TO_RELATIONS,
  REMOVE_TO_RELATION,
  REMOVE_FROM_RELATION,
  UPDATE_TO_RELATION,
  UPDATE_FROM_RELATION,
  HIGHLIGHT_INTERVAL,
  DROP_HIGHLIGHTED_INTERVAL, SET_ARROW_HASH
} from './manufacturing'

export const getEstimateTreeAction = (tree) => ({
  type: GET_ESTIMATE_TREE,
  payload: tree,
});

export const getEstimateMaterialsTreeAction = (materialsTree) => ({
  type: GET_ESTIMATE_MATERIALS_TREE,
  payload: materialsTree,
});

export const getPlanAction = (plan) => ({
  type: GET_PLAN,
  payload: plan,
});

export const markManufacturingYearMonthAction = ({
  projectId,
  fetchedDates,
  dataType,
}) => ({
  type: MARK_MANUFACTURING_PROJECT_DATA_TYPE_MONTH_YEAR,
  payload: {
    projectId,
    dataType,
    fetchedDates,
  },
});

export const markConstructingYearMonthAction = ({
  projectId,
  fetchedDates,
  dataType,
}) => ({
  type: MARK_CONSTRUCTING_PROJECT_DATA_TYPE_MONTH_YEAR,
  payload: {
    projectId,
    dataType,
    fetchedDates,
  },
});

export const getMaterialsAction = (materials) => ({
  type: GET_MATERIALS,
  payload: materials,
});

export const getWeekPlanAction = (weekPlan) => ({
  type: GET_WEEK_PLAN,
  payload: weekPlan,
});

export const getWeekMaterialsAction = (weekMaterials) => ({
  type: GET_WEEK_MATERIALS,
  payload: weekMaterials,
});

export const getIntervalAction = (interval) => ({
  type: GET_INTERVAL,
  payload: interval,
});

export const getMaterialIntervalAction = (materialInterval) => ({
  type: GET_MATERIAL_INTERVAL,
  payload: materialInterval,
});

export const setChartViewModeAction = (chartViewMode) => ({
  type: SET_CHART_VIEW_MODE,
  payload: chartViewMode,
});

export const setManufacturingTabAction = (manufacturingTab) => ({
  type: SET_TAB,
  payload: manufacturingTab,
});

export const startLoadingModalAction = () => ({
  type: SET_IS_LOADING_MODAL,
  payload: true,
});

export const stopLoadingModalAction = () => ({
  type: SET_IS_LOADING_MODAL,
  payload: false,
});

export const getModalIntervalListAction = (intervalList) => ({
  type: GET_MODAL_INTERVAL_LIST,
  payload: intervalList,
});

export const getModalIntervalAction = (modalInterval) => ({
  type: GET_MODAL_INTERVAL,
  payload: modalInterval,
});

export const getModalIntervalFilesAction = (modalIntervalFiles) => ({
  type: GET_MODAL_FILES,
  payload: modalIntervalFiles,
});

export const getModalIntervalMaterialsAction = (modalIntervalFiles) => ({
  type: GET_MODAL_MATERIALS,
  payload: modalIntervalFiles,
});

export const setModalIntervalDataAction = (modalIntervalData) => ({
  type: SET_MODAL_DATA,
  payload: modalIntervalData,
});

export const getExpenditureAction = (expenditure) => ({
  type: GET_EXPENDITURE,
  payload: expenditure,
});

export const setIsLoadingChartDataAction = (isLoading) => ({
  type: SET_IS_LOADING_CHART_DATA,
  payload: isLoading,
});

export const setProjectDataAction = (projectData) => ({
  type: SET_PROJECT_DATA,
  payload: projectData,
});

export const setMaterialDataAction = (materialData) => ({
  type: SET_MATERIAL_DATA,
  payload: materialData,
});

export const setProjectWeekDataAction = (projectWeekData) => ({
  type: SET_PROJECT_WEEK_DATA,
  payload: projectWeekData,
});

export const setMaterialWeekDataAction = (materialWeekData) => ({
  type: SET_MATERIAL_WEEK_DATA,
  payload: materialWeekData,
});

export const projectEstimateSetAction = ({ key, value }) => ({
  type: PROJECT_ESTIMATE_SET,
  payload: {
    key,
    value,
  },
});

export const dropLoadedChartMonthsAction = () => ({
  type: DROP_LOADED_CHART_MONTHS,
});

export const setManufacturingMonthMarkersAction = (monthMarkers) => ({
  type: SET_MONTH_MARKERS,
  payload: monthMarkers,
});

export const startLoadingChartTreeAction = () => ({
  type: SET_IS_LOADING_CHART_TREE,
  payload: true,
});

export const stopLoadingChartTreeAction = () => ({
  type: SET_IS_LOADING_CHART_TREE,
  payload: false,
});

export const setActiveBranchAction = ({ index, eventFrom }) => ({
  type: SET_ACTIVE_BRANCH,
  payload: { index, eventFrom },
});

export const updateDiagramFiltersAction = ({ name, value }) => ({
  type: UPDATE_DIAGRAM_FILTERS,
  payload: { name, value },
});

export const startDragIntervalAction = ({
  projectId,
  intervalId,
  startDate,
}) => ({
  type: SET_IS_BEING_DRAGGED,
  payload: { projectId, intervalId, isBeingDragged: true, startDate },
});

export const stopDragIntervalAction = ({ projectId, intervalId }) => ({
  type: SET_IS_BEING_DRAGGED,
  payload: { projectId, intervalId, isBeingDragged: false },
});

export const enableIntervalLinkingAction = () => ({
  type: SET_IS_ENABLED_LINKING,
  payload: true,
});

export const disableIntervalLinkingAction = () => ({
  type: SET_IS_ENABLED_LINKING,
  payload: false,
});

export const addIntervalLinkAction = ({ projectId, arrows }) => ({
  type: ADD_INTERVAL_LINK,
  payload: { projectId, arrows },
});

export const setArrowsAction = ({ projectId, arrows }) => ({
  type: SET_ARROWS,
  payload: { projectId, arrows },
});

export const deleteArrowAction = ({ projectId, arrowId }) => ({
  type: DELETE_ARROW,
  payload: { projectId, arrowId },
});

export const updateArrowAction = ({ projectId, arrowId, data }) => ({
  type: UPDATE_ARROW,
  payload: { projectId, arrowId, data },
});

export const setRelationsFromCurrentIntervalAction = (relations) => ({
  type: SET_RELATIONS_FROM_CURRENT_INTERVAL,
  payload: relations,
});

export const setRelationsToCurrentIntervalAction = (relations) => ({
  type: SET_RELATIONS_TO_CURRENT_INTERVAL,
  payload: relations,
});

export const loadAvailableSectionsAction = (sections) => ({
  type: SET_AVAILABLE_SECTIONS,
  payload: sections,
});

export const loadAvailableIntervalsAction = (intervals) => ({
  type: SET_AVAILABLE_INTERVALS,
  payload: intervals,
});

export const pushToRelationsAction = (intervals) => ({
  type: PUSH_TO_RELATIONS,
  payload: intervals,
});

export const pushFromRelationsAction = (intervals) => ({
  type: PUSH_FROM_RELATIONS,
  payload: intervals,
});

export const removeToRelationAction = (toIntervalId) => ({
  type: REMOVE_TO_RELATION,
  payload: toIntervalId,
});

export const removeFromRelationAction = (fromIntervalId) => ({
  type: REMOVE_FROM_RELATION,
  payload: fromIntervalId,
});

export const updateToRelationAction = ({ relationId, data }) => ({
  type: UPDATE_TO_RELATION,
  payload: { relationId, data },
});

export const updateFromRelationAction = ({ relationId, data }) => ({
  type: UPDATE_FROM_RELATION,
  payload: { relationId, data },
});

export const highlightRelatedIntervalsAction = ({ intervalId, projectId }) => ({
  type: HIGHLIGHT_INTERVAL,
  payload: { intervalId, projectId },
});

export const dropHighlightRelatedIntervalsAction = () => ({
  type: DROP_HIGHLIGHTED_INTERVAL,
});

export const setArrowHashAction = (hash) => ({
  type: SET_ARROW_HASH,
  payload: hash
});
