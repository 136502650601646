import React from "react";

import Modal from "components/UI/atoms/Modal";
import Button from "components/UI/atoms/ButtonBase";
import { useKs23 } from "../../useKs23";

import { IKs2Row, IKsModalProps, KS_TYPES } from "../../types";

import { splitThousands } from "utils/splitThousands";
import { dropNonSignificantZeros } from "utils/dropNonSignificantZeros";

import styles from "../../Ks23Modal.module.scss";

const Ks2Modal: React.FC<IKsModalProps> = ({
  isOpen,
  onClose,
  expenditureId,
  buildingId,
  year,
  month,
  isPrefetch,
}) => {
  const {
    ks: ks2,
    handleDownloadFile,
    handleClose,
  } = useKs23({
    expenditureId,
    buildingId,
    isPrefetch,
    year,
    month,
    type: KS_TYPES.ks2,
    onClose,
  });
  const hasRows = ks2?.rows && ks2.rows.length > 0;

  return (
    <Modal
      isOpen={isOpen && hasRows}
      onClose={handleClose}
      className={styles.ksModal}
    >
      <div className={styles.ksContent}>
        <h1>
          АКТ <br /> О ПРИЕМКЕ ВЫПОЛНЕННЫХ РАБОТ
        </h1>
        <table className={styles.table}>
          <thead>
            <tr>
              <td colSpan={2}>Номер</td>
              <td rowSpan={2}>Наименование работ</td>
              <td rowSpan={2}>Номер единичной расценки</td>
              <td rowSpan={2}>Единица измерения</td>
              <td colSpan={3}>Выполнено работ</td>
            </tr>
            <tr>
              <td>по порядку</td>
              <td>позиции по смете</td>
              <td>количество</td>
              <td>цена за единицу, руб.</td>
              <td>стоимость, руб.</td>
            </tr>
          </thead>
          <tbody>
            {ks2?.rows?.map((row: IKs2Row, index: number) => (
              <tr key={row.justification + index}>
                <td>{index + 1}</td>
                <td>{row.exp_number}</td>
                <td>{row.name}</td>
                <td>{row.justification}</td>
                <td>{row.measure}</td>
                <td>{dropNonSignificantZeros(row.sum_count_accepted)}</td>
                <td>{splitThousands(row.price, false, false)}</td>
                <td>{splitThousands(row.worth, false, false)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.actionBlock}>
          <Button primary onClick={handleDownloadFile}>
            Выгрузить
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(Ks2Modal);
