import { chatRoomTypes } from "redux/modules/common/chatV2/types";

import { isShowChatParam } from "components/UI/organism/Chat/constants";

import {
  ORDERS_SECTION_VIEW as ORDERS_PURCHASES_SECTION_VIEW,
  VIEW_EXPORT_LIST as VIEW_PURCHASES_EXPORT_LIST,
  VIEW_PACKING_LISTS as VIEW_PURCHASES_PACKING_LISTS,
  VIEW_REQUISITIONS_LIST as VIEW_PURCHASES_REQUISITIONS_LIST,
  VIEW_BILLS_LIST as VIEW_PURCHASES_BILL_LIST,
} from "constants/permissions/purchasesPermissions";
import {
  VIEW_MANUFACTURING_ORDER_SECTION,
  VIEW_MANUFACTURING_PACKING_LIST_SECTION as VIEW_MANUFACTURING_PACKING_LISTS,
  VIEW_MANUFACTURING_REQUISITION_SECTION as VIEW_MANUFACTURING_REQUISITION_LIST,
  VIEW_MANUFACTURING_BILL_SECTION as VIEW_MANUFACTURING_BILL_LIST,
} from "constants/permissions/manufacturingPermissions";
import {
  VIEW_EXPORT_LIST as VIEW_CONSTRUCTING_EXPORT_LIST,
  VIEW_PACKING_LISTS as VIEW_CONSTRUCTING_PACKING_LISTS,
  VIEW_BILLS_LIST as VIEW_CONSTRUCTING_BILL_LIST,
} from "constants/permissions/constructingPermissions";

import usePermission from "hooks/usePermission";
import { findChatTypePage } from "../utils/findChatTypePage";


export const useOrderLink = (order_id: number, building_id: number) => {
  const haveViewPurchasesOrderSectionPermission = usePermission(ORDERS_PURCHASES_SECTION_VIEW);
  const haveViewManufacturingOrderSectionPermission = usePermission(VIEW_MANUFACTURING_ORDER_SECTION);

  if (haveViewPurchasesOrderSectionPermission) return `/purchases/orders/${order_id}`;
  if (haveViewManufacturingOrderSectionPermission) return `/objects/${building_id}/orders/${order_id}`;

  return `/objects/${building_id}/orders/${order_id}`;
};

export const useChatLink = (link: string) => {
  return `${link}/?${isShowChatParam}=true`;
};

export const useChatV2Link = (chat_type: chatRoomTypes, building_id: number) => {
  return `/objects/${building_id}/${findChatTypePage(chat_type)}/?${isShowChatParam}=true`;
};

export const useExportLink = () => {
  const haveViewConstructingExportListPermission = usePermission(VIEW_CONSTRUCTING_EXPORT_LIST);
  const haveViewPurchasesOrderSectionPermission = usePermission(VIEW_PURCHASES_EXPORT_LIST);

  if (haveViewConstructingExportListPermission) return '/constructing/documents/export';
  if (haveViewPurchasesOrderSectionPermission) return '/purchases/documents/export';

  return '/purchases/documents/export';
};

export const usePackingListLink = (packinglist_id: number, building_id: number) => {
  const haveViewPurchasesPackingListPermission = usePermission(VIEW_PURCHASES_PACKING_LISTS);
  const haveViewManufacturingPackingListPermission = usePermission(VIEW_MANUFACTURING_PACKING_LISTS);
  const haveViewConstructingPackingListPermission = usePermission(VIEW_CONSTRUCTING_PACKING_LISTS);

  if (haveViewPurchasesPackingListPermission) return `/purchases/documents/packing-lists/${packinglist_id}`;
  if (haveViewManufacturingPackingListPermission) return `/objects/${building_id}/documents/packing-lists/${packinglist_id}`;
  if (haveViewConstructingPackingListPermission) return `/constructing/documents/packing-lists/${packinglist_id}`;

  return `/constructing/documents/packing-lists/${packinglist_id}`;
};

export const usePaymentLink = (invoice_id: number, building_id: number) => {
  const haveViewPurchasesBillListPermission = usePermission(VIEW_PURCHASES_BILL_LIST);
  const haveViewManufacturingBillListPermission = usePermission(VIEW_MANUFACTURING_BILL_LIST);
  const haveViewConstructingBillListPermission = usePermission(VIEW_CONSTRUCTING_BILL_LIST);

  if (haveViewPurchasesBillListPermission) return `/purchases/documents/bills/${invoice_id}`;
  if (haveViewManufacturingBillListPermission) return `/objects/${building_id}/documents/bills/${invoice_id}`;
  if (haveViewConstructingBillListPermission) return `/constructing/documents/bills/${invoice_id}`;

  return `/constructing/documents/bills/${invoice_id}`;
};

export const useRequisitionLink = (requisition_id: number, building_id: number) => {
  const haveViewPurchasesRequisitionListPermission = usePermission(VIEW_PURCHASES_REQUISITIONS_LIST);
  const haveViewManufacturingRequisitionListPermission = usePermission(VIEW_MANUFACTURING_REQUISITION_LIST);

  if (haveViewPurchasesRequisitionListPermission) return `/purchases/requisitions/in-work/${requisition_id}`;
  if (haveViewManufacturingRequisitionListPermission) return `/objects/${building_id}/requisitions/in-work/${requisition_id}`;

  return `/objects/${building_id}/requisitions/in-work/${requisition_id}`;
};

export const useTasksTrackLink = (id: number) => `/tasks/all/0/${id}`;


