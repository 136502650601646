import { compose } from "redux";
import axios from "axios";

import { PermissionPropertiesEnum } from "../../../../../components/pages/Settings/components/Users/UserPage/enums";
import { setUserPermissionsDataAction, setUserPermissionsLoadingAction } from "../actions";

import { errorCatcher } from "utils/errorCatcher";

const filterOffPermissions = (permissions) =>
  permissions.filter((permission) => permission.property !== PermissionPropertiesEnum.off);

export const getUserPermissions = (userId) => async (dispatch) => {
  compose(dispatch, setUserPermissionsLoadingAction)(true);

  const response = await axios.get("/user/permissions/", { params: { user_id: userId } }).catch(errorCatcher);
  compose(dispatch, setUserPermissionsDataAction, filterOffPermissions)(response.data);
  compose(dispatch, setUserPermissionsLoadingAction)(false);
};
