import SearchSelectFilter from 'components/UI/atoms/SearchSelectFilter';
import TableReusableHead, { TableReusableHeaderCell } from 'components/UI/atoms/TableReusable/TableReusableHead';
import React from 'react'
import { statusFilterDataJournalDelivery } from '../../constants';
import styles from "./JournalDeliveryTableHead.module.scss"

interface IProps {
    onChangeFilter: (name: string, value: string) => void;
    setIsShow: (val: boolean) => void;
    isShow: boolean;
}

const JournalDeliveryTableHead: React.FC<IProps> = ({ onChangeFilter, setIsShow, isShow }) => {
    return (
        <TableReusableHead className={styles.row} isSticky isWithEar>
            <TableReusableHeaderCell
                filterType="text"
                filterField="id"
                filterTitle="Номер"
                onChangeFilter={onChangeFilter}
            >
                №
            </TableReusableHeaderCell>
            <TableReusableHeaderCell>
                Наименование
            </TableReusableHeaderCell>
            <TableReusableHeaderCell
                filterType="text"
                filterField="author"
                filterTitle="Сдал"
                onChangeFilter={onChangeFilter}
            >
                Сдал
            </TableReusableHeaderCell>
            <TableReusableHeaderCell className={styles.centered}>
                Дата
            </TableReusableHeaderCell>
            <TableReusableHeaderCell className={styles.centered}>
                Ед. изм.
            </TableReusableHeaderCell>
            <TableReusableHeaderCell>
                К сдаче
            </TableReusableHeaderCell>
            <TableReusableHeaderCell>
                Принято
            </TableReusableHeaderCell>
            <TableReusableHeaderCell>
                    Статус
                    <SearchSelectFilter
                        title="Статус"
                        setFilter={setIsShow}
                        last
                        filterName={statusFilterDataJournalDelivery}
                        defaultValue={isShow}
                    />
            </TableReusableHeaderCell>
        </TableReusableHead>
    )
}

export default JournalDeliveryTableHead