import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  billsListLoadingSelector,
  billsListSelector,
  DEFAULT_LIMIT_BILL,
  loadBills,
  loadMoreBills,
} from "redux/modules/common/building/documents/bills";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import { statusBillsArray } from "utils/constant";

import EmptyPlaceholder from "../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import BillsIcon from "images/icons/documentsTab/BillsIcon";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import BillRow from "./BillRow/BillRow";
import { IBillInList } from "types/interfaces/Bill";
import { Spinner } from "components/UI/Spinner/Spinner";
import BillsTableHead from "./BillsTableHead/BillsTableHead";

export interface IBillsProps {
  isSwitch: boolean;
}

type FiltersType = { limit: number; offset: number; show?: "formed" };

const Bills: React.FC<IBillsProps> = ({ isSwitch }) => {
  const dispatch = useDispatch();
  const billsList = useSelector(billsListSelector);
  const [filters, setFilters] = useState<FiltersType>({
    limit: DEFAULT_LIMIT_BILL,
    offset: 0,
  });
  const billsListLoading = useSelector(billsListLoadingSelector);
  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);

  const formattedObjects = React.useMemo(() => {
    //@ts-ignore
    return breadcrumbsProjects.results.map((el) => ({ id: el.id, name: el.id, label: el.name }));
  }, [breadcrumbsProjects]);

  const changeFilters = (name: string, value: string) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const hasNextPage = useMemo(() => billsList?.count > billsList?.results?.length, [billsList]);

  const loadMore = useCallback(() => {
    if (!billsList) return;

    const params: FiltersType = { ...filters, offset: billsList.results.length };
    if (!isSwitch) params.show = "formed";
    dispatch(loadMoreBills(params));
  }, [billsList?.results?.length, filters, isSwitch]);

  useEffect(() => {
    const params = { ...filters };
    if (!isSwitch) params.show = "formed";
    dispatch(loadBills(false, params));
  }, [filters, isSwitch]);

  return (
    <div>
      <BillsTableHead
        onChangeFilter={changeFilters}
        statusBillsArray={statusBillsArray}
        formattedObjects={formattedObjects}
      />
      {billsList?.results.map((el: IBillInList) => (
        <BillRow key={el.id} bill={el} />
      ))}
      {!billsListLoading && !billsList?.results.length && <EmptyPlaceholder text="Нет счетов" svgJsx={<BillsIcon />} />}
      {billsListLoading && <Spinner />}
      <ShowMoreButton
        isExists={hasNextPage && !billsListLoading}
        handleAdd={loadMore}
        showedCount={billsList?.results?.length}
        allCount={billsList?.count}
      />
    </div>
  );
};

export default React.memo(Bills);
