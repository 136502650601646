import React, { useState } from 'react';
import styles from './CalendarHeader.module.scss';
import OldSelect from 'components/UI/atoms/Select/OldSelect';
import arowSelectSmallBlue
  from 'images/icons/arowSelectSmallBlue.svg';
import moment from 'moment';
import { eventSelectArr } from 'utils/constant';
import { Events } from '../Events/Events';
import Modal from "components/UI/atoms/Modal"
import ButtonBase from "components/UI/atoms/ButtonBase"
import MonthsSlider from "./../../../UI/molecules/MonthsYearsSlider/MonthsYearsSlider"
import { eventsBtnSvg } from './eventsBtnSvg';

const CalendarHeader = ({
  date,
  setDate,
  setIsSlideModal,
  setDateBefore,
  setDateAfter,
  setEventSelect,
  eventSelect,
  setObjSelect,
  objSelectArr,
  objSelect
}) => {

  const [isModal, setIsModal] = React.useState(false)

  const setMonthAll = (month) => {
    setDate(month);
    setDateAfter(moment(month).day(0).add(-21, 'days'))
    setDateBefore(moment(month).day(28).add(25, 'days'))
  };

  const clearCalendarFilters = () => {
    setEventSelect(eventSelectArr[0].name);
    setObjSelect(objSelectArr[0].name);
    setIsModal(false)
  };

  const [localObj, setLocalObj] = React.useState(objSelect)
  const [localEvents, setLocalEvents] = React.useState(eventSelect)

  const submitHandler = () => {
    setObjSelect(localObj)
    setEventSelect(localEvents)
    setIsModal(false)
  }

  const [isFilterActive, setIsFilterActive] = React.useState(false)
  React.useEffect(() => {
    setLocalObj(objSelect)
    setLocalEvents(eventSelect)
    if ((!objSelect || objSelect === "Все проекты") && (!eventSelect || eventSelect === "events")) {
      setIsFilterActive(false)
    } else {
      setIsFilterActive(true)
    }
  }, [objSelect, eventSelect])

  return (
    <div className={styles.top}>
      <Events />
      <div className={styles.topBox2}>
        <MonthsSlider date={date} onChange={setMonthAll} />
      </div>
      <div className={styles.topBox1}>
        <button className={styles.topBox1Btn}
          onClick={() => setIsSlideModal(true)}>{eventsBtnSvg}</button>
      </div>
      <button className={`${isFilterActive ? styles.activeFilter : ""} ${styles.filterBtn}`} onClick={() => setIsModal(true)}>
        <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.82031 1.3457H23.1826L14.5908 9.93555V20.6519L11.1904 18.7427V9.93457L2.82031 1.3457Z" stroke="#707070" strokeWidth="2" />
        </svg>
      </button>
      <Modal isOpen={isModal} onClose={() => setIsModal(false)} title="Параметры отображения" className={styles.modal}>
        <div className={styles.selects}>
          <OldSelect className={styles.select}
            icon={arowSelectSmallBlue}
            defaultValue={objSelect}
            options={objSelectArr}
            onClick={(val) => setLocalObj(val)}
            placeholder='Все проекты'
          />
          <OldSelect className={styles.select}
            defaultValue={eventSelect}
            options={eventSelectArr}
            icon={arowSelectSmallBlue}
            onClick={(val) => setLocalEvents(val)}
            placeholder='Все события'
          />
        </div>
        <div className={styles.btns}>
          <ButtonBase onClick={clearCalendarFilters} buttonPrimaryEmpty>Очистить</ButtonBase>
          <ButtonBase onClick={submitHandler}>Применить</ButtonBase>
        </div>
      </Modal>
    </div>

  );
};

export default CalendarHeader;