import React from 'react'
import cn from "classnames"
import styles from "./WorkersList.module.scss"
import getShortFullName from "utils/getShortFullName"
import { sliceTextByConstraint } from 'utils/sliceTextByConstraint'
import { personFullNameWithId } from 'types/personsTypes'

type propsType = {
  worker: personFullNameWithId,
  color?: "green",
  className?: string
}

function WorkerItem({ worker, color, className }: propsType) {
  return (
    <div
      className={cn(styles.worker, styles[color as string], className)}
      title={getShortFullName(worker)}
    >
      {sliceTextByConstraint(getShortFullName(worker), 20)}
    </div>
  )
}

export default WorkerItem