import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { ThunkMiddleware } from "redux-thunk";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import {
  ksesLoadingSelector,
  ksesSelector,
} from "redux/modules/common/building/documents/kses/reducer";
import { getKses } from "redux/modules/common/building/documents/kses/thunks/getKses";
import { getMoreKses } from "redux/modules/common/building/documents/kses/thunks/getMoreKses";
import { resetKses } from "redux/modules/common/building/documents/kses/thunks/resetKses";
import { useTypedSelector } from "redux/typedUseSelector";
import { IbuildingResponse } from "redux/modules/common/building/object/types";

import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import { Spinner } from "components/UI/Spinner/Spinner";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import TableReusableHead, {
  TableReusableHeaderCell,
} from "components/UI/atoms/TableReusable/TableReusableHead";
import TableReusableRow, {
  TableReusableCell,
} from "components/UI/atoms/TableReusable/TableReusableRow";
import Person from "components/UI/molecules/Person/Person";
import { KS_TYPES, KsType } from "../../../UI/molecules/Ks23Modal/types";
import Ks23Modal from "../../../UI/molecules/Ks23Modal/Ks23Modal";

import { personFullNameWithId } from "types/personsTypes";
import { IIdAndName } from "../../../../types/idAndName";
import { KS_STATUSES_FILTER_OPTIONS, KsStatusesEnum } from "./constants";

import { splitThousands } from "utils/splitThousands";
import { RUBLE } from "utils/constant";

import BillsIcon from "images/icons/documentsTab/BillsIcon";

import styles from "./Kses.module.scss";

interface IKs {
  id: number;
  expenditure: IIdAndName & { number: string };
  building: IIdAndName;
  ks2_sum: string;
  status_ks2: keyof typeof KsStatusesEnum;
  status_ks3: keyof typeof KsStatusesEnum;
  author: personFullNameWithId;
}

const defaultActiveKs = { expenditureId: null, buildingId: null, type: null };

const Kses: FC = () => {
  const dispatch = useDispatch();
  const kses = useSelector(ksesSelector);
  const ksesAreLoading = useSelector(ksesLoadingSelector);
  const buildings: { results: IbuildingResponse[] } | undefined =
    useTypedSelector(objectsBreadcrumbsSelector);

  const [activeKs, setActiveKs] = useState<{
    expenditureId: number | null;
    buildingId: number | null;
    type: KsType | null;
  }>(defaultActiveKs);

  const openKs2Modal = useCallback(
    (ks: IKs) =>
      setActiveKs({
        expenditureId: ks.expenditure.id,
        buildingId: ks.building.id,
        type: KS_TYPES.ks2,
      }),
    []
  );

  const openKs3Modal = useCallback(
    (ks: IKs) =>
      setActiveKs({
        expenditureId: ks.expenditure.id,
        buildingId: ks.building.id,
        type: KS_TYPES.ks3,
      }),
    []
  );

  const handleCloseKsModal = useCallback(
    () => setActiveKs(defaultActiveKs),
    []
  );

  const buildingsFilterOptions = useMemo(
    () =>
      buildings
        ? buildings.results.map(({ id, name }) => ({
            id,
            name: id,
            label: name,
          }))
        : [],
    [buildings]
  );

  const [filters, setFilters] = useState({});

  const handleGetMoreKses = useCallback(
    () => compose<ThunkMiddleware>(dispatch, getMoreKses)(filters),
    [filters]
  );

  const onChangeFilter = useCallback((name: string, value: string) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  useEffect(() => {
    dispatch(resetKses());
    compose<ThunkMiddleware>(dispatch, getKses)(filters);
  }, [filters]);

  return (
    <div>
      <TableReusableHead className={styles.head} isSticky>
        <TableReusableHeaderCell>№</TableReusableHeaderCell>
        <TableReusableHeaderCell
          filterField="building_id"
          filterOptions={buildingsFilterOptions}
          filterTitle="Проект"
          filterType="select"
          onChangeFilter={onChangeFilter}
        >
          Проект
        </TableReusableHeaderCell>
        <TableReusableHeaderCell
          filterType="text"
          filterField="expenditure_name"
          filterTitle="Наименование расценки"
          onChangeFilter={onChangeFilter}
        >
          Расценка
        </TableReusableHeaderCell>
        <TableReusableHeaderCell
          filterType="text"
          filterField="author"
          filterTitle="Автор"
          onChangeFilter={onChangeFilter}
        >
          Автор
        </TableReusableHeaderCell>
        <TableReusableHeaderCell
          filterField="status_ks2"
          filterOptions={KS_STATUSES_FILTER_OPTIONS}
          filterTitle="Статус КС2"
          filterType="select"
          onChangeFilter={onChangeFilter}
        >
          Статус КС2
        </TableReusableHeaderCell>
        <TableReusableHeaderCell
          filterField="status_ks3"
          filterOptions={KS_STATUSES_FILTER_OPTIONS}
          filterTitle="Статус КС3"
          filterType="select"
          onChangeFilter={onChangeFilter}
        >
          Статус КС3
        </TableReusableHeaderCell>
        <TableReusableHeaderCell isRighted>
          Сумма, {RUBLE}
        </TableReusableHeaderCell>
      </TableReusableHead>
      {kses &&
        kses.results.map((ks: IKs) => (
          <TableReusableRow
            className={styles.row}
            key={ks.id}
            isHoverable={false}
          >
            <TableReusableCell>{ks.expenditure.number}</TableReusableCell>
            <TableReusableCell isNoBreak>{ks.building.name}</TableReusableCell>
            <TableReusableCell isNoBreak>
              {ks.expenditure.name}
            </TableReusableCell>
            <TableReusableCell>
              <Person cell={ks.author} />
            </TableReusableCell>
            <TableReusableCell>
              <span
                onClick={() => openKs2Modal(ks)}
                className={styles.openKsLabel}
              >
                {KsStatusesEnum[ks.status_ks2]}
              </span>
            </TableReusableCell>
            <TableReusableCell>
              <span
                onClick={() => openKs3Modal(ks)}
                className={styles.openKsLabel}
              >
                {KsStatusesEnum[ks.status_ks3]}
              </span>
            </TableReusableCell>
            <TableReusableCell isRighted>
              {splitThousands(ks.ks2_sum, false, false)}
            </TableReusableCell>
          </TableReusableRow>
        ))}
      {ksesAreLoading && <Spinner />}
      {kses && kses.results.length === 0 && (
        <EmptyPlaceholder text="Нет КС" svgJsx={<BillsIcon />} />
      )}
      {kses && (
        <ShowMoreButton
          isExists={kses.results.length < kses.count}
          handleAdd={handleGetMoreKses}
          showedCount={kses.results.length}
          allCount={kses.count}
        />
      )}
      <Ks23Modal
        type={activeKs.type}
        isOpen={!!activeKs.type}
        onClose={handleCloseKsModal}
        expenditureId={activeKs.expenditureId}
        buildingId={activeKs.buildingId}
        isPrefetch={true}
      />
    </div>
  );
};

export default React.memo(Kses);
