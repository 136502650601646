import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import { useQueryParams } from "utils/hooks/useQueryParams";

const FOLDER_IDS = "folderIds";

export const useFileStorageNavigation = () => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const history = useHistory();
  const location = useLocation();
  const folderIdsFromParams =
    useQueryParams(FOLDER_IDS)
      ?.split("/")
      .filter((el) => !!el)
      .map((el) => +el) || [];

  const [foldersIdsArray, setFoldersIdsArray] =
    React.useState<number[]>(folderIdsFromParams);
  const cachedObjectId = React.useRef<string>(objectId);

  React.useEffect(() => {
    if (objectId === cachedObjectId.current) return;
    setFoldersIdsArray([]);
    cachedObjectId.current = "None";
  }, [objectId]);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set(FOLDER_IDS, foldersIdsArray.join("/"));
    history.replace({ search: params.toString() });
  }, [foldersIdsArray, location.search]);

  const backHandler = React.useCallback(() => {
    setFoldersIdsArray((prev) => {
      const tempArr = [...prev];
      tempArr.pop();
      return tempArr;
    });
  }, [setFoldersIdsArray]);

  const openFolderHandler = React.useCallback(
    (folderId: number) => {
      setFoldersIdsArray((prev) => [...prev, folderId]);
    },
    [setFoldersIdsArray]
  );

  const currentFolderId = foldersIdsArray[foldersIdsArray.length - 1];

  const foldersDepth = foldersIdsArray.length;

  return {
    backHandler,
    openFolderHandler,
    currentFolderId,
    foldersDepth,
  };
};
