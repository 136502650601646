import React from "react";
import cn from "classnames";

import styles from "./Avatar.module.scss";
import { personFullNameWithId } from "types/personsTypes";

const getFirstLettersInName = (person?: personFullNameWithId) => {
  if (!person || !person?.first_name || !person?.last_name) return "-";
  return person?.last_name[0] + "" + person?.first_name[0];
};

export interface IAvatarProps {
  person: personFullNameWithId;
  img?: string;
  className?: string;
}

function Avatar({ person, img, className }: IAvatarProps) {
  return (
    <div className={cn(styles.avatar, className)}>
      {img ? <img src={img} alt="аватар" /> : <div>{getFirstLettersInName(person)}</div>}
    </div>
  );
}

export default React.memo(Avatar);
