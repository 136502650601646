export interface IDisplayedRelation {
  id: number;
  delay_day: number | null;
  from_interval: number;
  to_interval: number;
  from_expenditure_name: string;
  to_expenditure_name: string;
  from_section_name: string;
  to_section_name: string;
}

export enum RelationFromTypes {
  from = "from",
  to = "to",
}

export type RelationFromType = keyof typeof RelationFromTypes;

export interface IIntervalToConnect {
  id: number
  expenditure_name: string
}