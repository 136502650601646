import { useCallback, useState } from "react";
import {
  ISpittingTreeElement,
} from "../types";

export interface IUseSpittingTreeProps {
  spittingTreeGenerationFn: (args: any) => ISpittingTreeElement[]
  spittingTreeFnArgs: any
}

export const useSpittingTree = ({
  spittingTreeGenerationFn,
  spittingTreeFnArgs
}: IUseSpittingTreeProps) => {
  const [spittingTree, setSpittingTree] = useState<ISpittingTreeElement[]>([]);
  const [isGeneratingTree, setIsGeneratingTree] = useState(true);

  const handleGenerateSpittingTree = useCallback(() => {
    const newSpittingTree = spittingTreeGenerationFn(spittingTreeFnArgs);
    setSpittingTree(newSpittingTree);
    setIsGeneratingTree(false);
  }, [spittingTreeGenerationFn, spittingTreeFnArgs]);

  const skipGeneratingTreeHandler = useCallback(() => {
    setIsGeneratingTree(false)
  }, [setIsGeneratingTree])

  return {
    generateSpittingTree: handleGenerateSpittingTree,
    isGeneratingTree,
    spittingTree,
    skipGeneratingTreeHandler
  };
};
