import React, { useCallback, useState } from "react";
import cn from "classnames";

import PlusFillCircleIcon from "images/icons/PlusFillCircleIcon";

import CreateSectionInput from "components/pages/Handler/components/CreateSection/components/CreateSectionInput/CreateSectionInput";
import Draft from "components/pages/Handler/components/Body/components/Sections/components/Draft/Draft";

import styles from "components/pages/Handler/components/CreateSection/CreateSection.module.scss";

interface ICreateSectionProps {
  isSubsections: boolean;
  sectionsAreEmpty: boolean;
  onSubmitCreateSection: (name: string) => void;
}

const CreateSection = ({ isSubsections, sectionsAreEmpty, onSubmitCreateSection }: ICreateSectionProps) => {
  const [isSectionInputOpen, setIsSectionInputOpen] = useState(false);
  const openSectionInput = useCallback(() => setIsSectionInputOpen(true), []);
  const closeSectionInput = useCallback(() => setIsSectionInputOpen(false), []);

  const addButtonLabel = `Добавить ${isSubsections ? "раздел" : "ЛСР"}`
  const addInputPlaceholder = `Введите название ${isSubsections ? "раздела" : "локального сметного расчета"}`

  return (
    <>
      <button className={styles.addSectionButton} onClick={openSectionInput}>
        <span className={styles.text}>{addButtonLabel}</span>
        <PlusFillCircleIcon />
      </button>
      {isSectionInputOpen && (
        <div className={cn({ [styles.withBorderBottom]: !sectionsAreEmpty })}>
          <CreateSectionInput onSubmit={onSubmitCreateSection} close={closeSectionInput} placeholder={addInputPlaceholder}/>
        </div>
      )}
      {sectionsAreEmpty && !isSubsections && <Draft onCreateSection={openSectionInput} />}
    </>
  );
};

export default React.memo(CreateSection);
