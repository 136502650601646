import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isSessionValid, setCurrentUser } from "redux/modules/common/auth";
import { loadObjectsBreadcrumbs, objectsBreadcrumbsLoadingSelector } from "redux/modules/common/building/objects";

import { OslaLoader } from "./pages/OslaLoader/OslaLoader";
import TasksRoutes from "./routes/components/TasksRoutes/TasksRoutes";

import "../scss/main.scss";

const MainTasks = () => {
  const dispatch = useDispatch();

  const breadcrumbsProjectsAreLoading = useSelector(objectsBreadcrumbsLoadingSelector);

  const [sessionValid, setSessionValid] = useState(false);

  useEffect(() => {
    dispatch(setCurrentUser());
    dispatch(isSessionValid(() => setSessionValid(true)));
    dispatch(loadObjectsBreadcrumbs({ limit: 1000 }));
  }, []);

  return sessionValid && !breadcrumbsProjectsAreLoading ? <TasksRoutes /> : <OslaLoader />;
};

export default MainTasks;
