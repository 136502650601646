import TableReusableRow, { TableReusableCell } from 'components/UI/atoms/TableReusable/TableReusableRow'
import { NavLinkIcon } from 'components/UI/NavLinkIcon/NavLinkIcon'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { IPackingListInList } from 'types/interfaces/PackingList'
import { useUrlModule } from 'utils/hooks/useUrlModule'
import { sliceTextByConstraint } from 'utils/sliceTextByConstraint'
import { transformDigitToFinancial } from 'utils/transformDigitToFinancial'
import styles from "./PackingListsRow.module.scss"

interface Iprops {
    packingList: IPackingListInList
}

const PackingListsRow: React.FC<Iprops> = ({ packingList }) => {
    const history = useHistory()
    const urlAdapter = useUrlModule();

    const rowClickHandler = () => {
        history.push(`/${urlAdapter}/documents/packing-lists/${packingList.id}`);
    }

    return (
        <TableReusableRow className={styles.row} onClick={rowClickHandler}>
            <TableReusableCell>
                {packingList.number ? sliceTextByConstraint(String(packingList.number), 8) : "Формируется"}
            </TableReusableCell>
            <TableReusableCell>
                <NavLinkIcon
                    to={`/${urlAdapter}/orders/${packingList.order.id}`}
                    title={sliceTextByConstraint(String(packingList.order.number), 8)}
                    className={styles.orderLink}
                />
            </TableReusableCell>
            <TableReusableCell isNoBreak>
                {packingList.building.name}
            </TableReusableCell>
            <TableReusableCell isNoBreak>
                {packingList.provider.name}
            </TableReusableCell>
            <TableReusableCell isCentered>
                {(packingList.created_at ? moment(packingList.created_at).format("DD.MM.YYYY") : "---")}
            </TableReusableCell>
            <TableReusableCell isRighted>
                {transformDigitToFinancial(packingList.amount_total)}
            </TableReusableCell>
            <TableReusableCell>
                {packingList.status_name}
            </TableReusableCell>
        </TableReusableRow>
    )
}

export default PackingListsRow