import { message } from 'antd'
import BackNavigationBar from 'components/UI/atoms/BackNavigationBar/BackNavigationBar'
import { Spinner } from 'components/UI/Spinner/Spinner'
import Paper from 'components/UI/templates/Paper/Paper'
import TemplateSimple from 'components/UI/templates/TemplateSimple'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import { isServiceActLoadingSelector, serviceActFilesSelector, serviceActSelector } from 'redux/modules/common/building/documents/serviceActs/selectors'
import { acceptServiceAct, changeAct, changeServiceActNumber, deleteServiceActFile, getServiceAct, getServiceActFiles, postServiceActFiles } from 'redux/modules/common/building/documents/serviceActs/thunks'
import { IServiceAct } from 'types/interfaces/ServiceAct'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import Footer from '../PackingList/components/Footer'
import Header from '../PackingList/components/Header'
import Products from '../PackingList/components/Products'
import SplitModal from '../PackingList/components/SplitModal'
import { PACKING_LIST_STATUSES } from '../PackingList/constants'
import PackingListValidator from '../PackingList/domain/PackingListValidator'
import { ACT_TYPES } from './constants'
import styles from "./ServiceAct.module.scss"

interface IProps {
    isSimplified: boolean
}

const ServiceAct: React.FC<IProps> = ({
    isSimplified = false
}) => {
    const dispatch = useDispatch()
    const { actId } = useParams<{ actId: string }>()
    const isLoading = useSelector(isServiceActLoadingSelector)
    const serviceAct = useSelector(serviceActSelector)
    const from = useQueryParams("from")
    const [products, setProducts] = useState<IServiceAct["items"] | null>(null);
    const [comment, setComment] = useState("");
    const files = useSelector(serviceActFilesSelector)

    const commentChangeHandler = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value), []);

    useEffect(() => {
        dispatch(getServiceAct(+actId))
        dispatch(getServiceActFiles(actId))
    }, [actId])

    useEffect(() => {
        if (!serviceAct) return;

        setProducts(serviceAct.items || []);
    }, [serviceAct?.items]);

    const productsWithoutRemoved = useMemo(() => products?.filter((product) => !product.is_removed) || [], [products]);

    const onChangeActNumber = useCallback(
        (number: string) => compose(dispatch, changeServiceActNumber)(actId, number),
        [actId]
    );

    const changeExecutionDate = useCallback(
        (date: string) => compose(dispatch, changeAct)(actId, { date: moment(date).format("YYYY-MM-DD") }),
        [actId]
    );

    const handleAddFiles = useCallback(
        (files: File[]) => compose(dispatch, postServiceActFiles)(actId, files),
        [actId]
    );

    const handleDeleteFile = useCallback(
        (removedFileId: number) => compose(dispatch, deleteServiceActFile)(actId, removedFileId),
        [actId]
    );

    const acceptPackingList = useCallback(
        (isSplit = false) => {
            if (!serviceAct || !products) return;

            compose(dispatch, acceptServiceAct)(actId, {
                comment,
                items: products,
                refuse_product: !isSplit,
            });
        },
        [products, serviceAct, comment, actId]
    );

    const onAccept = useCallback(() => {
        const onValidatorError = (errors: string[]) => errors?.forEach((error) => message.error(error));
        const packingListValidator = new PackingListValidator(onValidatorError);

        if (!packingListValidator.validate(serviceAct?.number, products)) return;
        acceptPackingList();
    }, [serviceAct?.number, acceptPackingList, products, productsWithoutRemoved]);

    if (!serviceAct || isLoading) return <Spinner />

    const canEditPackingList =
        serviceAct?.status === "waiting_completion"

    return (
        <TemplateSimple contentClassName="">
            <div className={styles.packingListContainer}>
                {!isSimplified && (
                    <BackNavigationBar title="Документы" backLink={from} rightSideText={serviceAct?.building?.name} onBack={undefined} />
                )}
                <Paper>
                    <div className={styles.packingList}>
                        <Header
                            packingList={serviceAct}
                            canEdit={canEditPackingList}
                            type="service-acts"
                            onChangeNumber={onChangeActNumber}
                            addFiles={handleAddFiles}
                            deleteFile={handleDeleteFile}
                            changeExecutionDate={changeExecutionDate}
                            isSimplified={isSimplified}
                            propsFiles={files}
                        />
                        {productsWithoutRemoved.length !== 0 && (
                            <Products
                                products={productsWithoutRemoved}
                                type={ACT_TYPES.SERVICE_ACTS}
                                files={{}}
                                onDeleteProduct={() => null}
                                onChangeProductItem={() => null}
                                deleteFile={() => null}
                                addFiles={() => null}
                                getProductComments={() => null}
                                createProductComment={() => null}
                            />
                        )}
                        <Footer
                            canEditPackingList={canEditPackingList}
                            productsHaveChanges={false}
                            onAccept={onAccept}
                            packingList={serviceAct}
                            commentChangeHandler={commentChangeHandler}
                            comment={comment}
                        />
                    </div>
                </Paper>
            </div>
        </TemplateSimple>
    )
}

export default ServiceAct