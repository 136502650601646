// @ts-nocheck
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { userSelector } from "redux/modules/common/auth";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";

import { TASK_LISTS_TYPES } from "components/pages/Tasks/constants";

import MenuItem from "./MenuItem";

import {
  VIEW_BUILDINGS_SECTION,
  VIEW_CONSTRUCTING_CHART,
  VIEW_COUNTERPARTIES_SECTION as VIEW_CONSTRUCTING_COUNTERPARTIES_SECTION,
  VIEW_DOCUMENTS_SECTION as VIEW_CONSTRUCTING_DOCUMENTS_SECTION,
  VIEW_FINANCE_SECTION,
} from "constants/permissions/constructingPermissions";
import { VIEW_SETTINGS_SECTION } from "constants/permissions/generalPermissions";
import {
  VIEW_MANUFACTURING_CHART,
  VIEW_MANUFACTURING_JOURNAL,
  VIEW_MANUFACTURING_MATERIALS_SECTION,
  VIEW_MANUFACTURING_PLAN_FACT_SECTION,
  VIEW_MANUFACTURING_PROJECT,
  VIEW_MANUFACTURING_REQUISITION_SECTION,
  VIEW_MANUFACTURING_STOCKS_SECTION,
  VIEW_MANUFACTURING_WORKERS,
} from "constants/permissions/manufacturingPermissions";
import {
  ORDERS_SECTION_VIEW,
  VIEW_PURCHASES_CHART,
  VIEW_COUNTERPARTIES_SECTION as VIEW_PURCHASES_COUNTERPARTIES_SECTION,
  VIEW_DOCUMENTS_SECTION as VIEW_PURCHASES_DOCUMENTS_SECTION,
  VIEW_REQUISITIONS_LIST,
} from "constants/permissions/purchasesPermissions";
import { IRouterParamsWithObjectId } from "types/routerTypes";

import usePermission from "hooks/usePermission";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import applications from "images/icons/navigation/applications.svg";
import contractors from "images/icons/navigation/contractors.svg";
import estimateIcon from "images/icons/navigation/estimateIcon.svg";
import finance from "images/icons/navigation/finance.svg";
import graphIcon from "images/icons/navigation/graphIcon.svg";
import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";
import materials from "images/icons/navigation/materials.svg";
import planFact from "images/icons/navigation/plan-fact.svg";
import scheduleIcon from "images/icons/navigation/scheduleIcon.svg";
import settingGear from "images/icons/navigation/settingGear.svg";
import taskIcon from "images/icons/navigation/tasksIcon.svg";
import workersIcon from "images/icons/navigation/timesheetIcon.svg";
import twoListsIcon from "images/icons/navigation/twoListsIcon.svg";
import warehouses from "images/icons/navigation/warehouses.svg";
import tasksAll from "images/menu-icons/tasksAll.svg";
import tasksAssigned from "images/menu-icons/tasksAssigned.svg";
import tasksLists from "images/menu-icons/tasksLists.svg";
import tasksMy from "images/menu-icons/tasksMy.svg";
import tasksViewing from "images/menu-icons/tasksViewing.svg";

import styles from "./index.module.scss";

const NavigationBar = () => {
  const location = useLocation();
  const { objectId } = useParams<IRouterParamsWithObjectId>();

  const user = useSelector(userSelector);
  const objects = useSelector(objectsBreadcrumbsSelector);

  const locationArray = location.pathname.split("/").filter((item: string) => item) || [];
  const cachedObjectId = localStorage.getItem("lastObjectId") || objectId;

  const objectIdAsNumber = objectId || 0;

  const isWithoutPermissions = !isAllModulesAllowed();
  const isShowSettingsInTasksModule = isWithoutPermissions && user?.is_staff;
  const objectIdForTasksModule = isWithoutPermissions ? cachedObjectId : objectIdAsNumber;

  const haveViewRequisitionsListPermission = usePermission(VIEW_REQUISITIONS_LIST);
  const haveViewOrdersListPermission = usePermission(ORDERS_SECTION_VIEW);

  const haveViewPurchasesDocumentsPermission = usePermission(VIEW_PURCHASES_DOCUMENTS_SECTION);
  const haveViewPurchasesCounterpartiesPermission = usePermission(VIEW_PURCHASES_COUNTERPARTIES_SECTION);
  const haveViewBuildingsSectionPermission = usePermission(VIEW_BUILDINGS_SECTION);
  const haveViewFinanceSectionPermission = usePermission(VIEW_FINANCE_SECTION);
  const haveViewConstructingCounterpartiesPermission = usePermission(VIEW_CONSTRUCTING_COUNTERPARTIES_SECTION);
  const haveViewConstructingDocumentsPermission = usePermission(VIEW_CONSTRUCTING_DOCUMENTS_SECTION);

  const haveViewWorkersPermission = usePermission(VIEW_MANUFACTURING_WORKERS);
  const haveViewJournalPermisiion = usePermission(VIEW_MANUFACTURING_JOURNAL);
  const haveViewProjectPermission = usePermission(VIEW_MANUFACTURING_PROJECT);
  const haveViewManufacturingChartPermission = usePermission(VIEW_MANUFACTURING_CHART);
  const haveViewStocksPermission = usePermission(VIEW_MANUFACTURING_STOCKS_SECTION);
  const haveManufacturingRequisitionsListPermission = usePermission(VIEW_MANUFACTURING_REQUISITION_SECTION);
  const haveViewMaterialsPermission = usePermission(VIEW_MANUFACTURING_MATERIALS_SECTION);
  const haveViewPlanFactPermission = usePermission(VIEW_MANUFACTURING_PLAN_FACT_SECTION);
  const haveViewSettingsSectionPermission = usePermission(VIEW_SETTINGS_SECTION);

  const haveViewConstructingChartPermission = usePermission(VIEW_CONSTRUCTING_CHART);
  const haveViewPurchasesChartPermission = usePermission(VIEW_PURCHASES_CHART);

  const productionsMenu = () => {
    const cachedObjectId = localStorage.getItem("lastObjectId") || objectId;

    if (!objects?.results?.length) return null;

    return (
      <>
        {locationArray.length > 1 && (
          <>
            <MenuItem
              isExists={haveViewManufacturingChartPermission}
              title="График"
              link={`/objects${cachedObjectId ? "/" + cachedObjectId : ""}/manufacturing`}
              icon={scheduleIcon}
              isDirectlyActive={locationArray.includes("manufacturing")}
            />
            <MenuItem
              isExists={haveViewProjectPermission}
              title="Проект"
              link={`/objects/${cachedObjectId}/estimate`}
              icon={estimateIcon}
              isDirectlyActive={locationArray.includes("estimate")}
            />
            <MenuItem
              isExists={haveViewJournalPermisiion}
              title="Журнал учета"
              link={`/objects/${cachedObjectId}/journal`}
              icon={jurnalIcon}
              isDirectlyActive={locationArray.includes("journal")}
            />
            <MenuItem
              isExists={haveViewWorkersPermission}
              title="Сотрудники"
              link={`/objects/${cachedObjectId}/workers`}
              icon={workersIcon}
              isDirectlyActive={locationArray.includes("workers")}
            />
            <MenuItem
              isExists={haveManufacturingRequisitionsListPermission}
              title="Заявки"
              link={`/objects/${cachedObjectId}/requisitions`}
              icon={applications}
              isDirectlyActive={locationArray.includes("requisitions")}
            />
            <MenuItem
              isExists={haveViewStocksPermission}
              title="Склад"
              link={`/objects/${cachedObjectId}/stocks`}
              icon={warehouses}
              isDirectlyActive={locationArray.includes("stocks")}
            />
            <MenuItem
              isExists={haveViewMaterialsPermission}
              title="Ресурсы"
              link={`/objects/${cachedObjectId}/materials`}
              icon={materials}
              isDirectlyActive={locationArray.includes("materials")}
            />
            <MenuItem
              isExists={haveViewPlanFactPermission}
              title="План-факт"
              link={`/objects/${cachedObjectId}/plan-fact`}
              icon={planFact}
              isDirectlyActive={locationArray.includes("plan-fact")}
            />
            {haveViewSettingsSectionPermission && (
              <div className={styles.bottom}>
                <MenuItem title="Настройки" link={`/objects/${cachedObjectId}/settings`} icon={settingGear} />
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      {location.pathname.indexOf("/constructing") !== -1 && (
        <>
          {haveViewConstructingChartPermission && (
            <MenuItem title="График" link={`/constructing/manufacturing`} icon={scheduleIcon} />
          )}
          {haveViewBuildingsSectionPermission && (
            <MenuItem title="Проекты" link="/constructing/projects" icon={estimateIcon} />
          )}
          {haveViewFinanceSectionPermission && (
            <MenuItem
              title="Финансы"
              link="/constructing/finance/0"
              icon={finance}
              isDirectlyActive={locationArray[1] === "finance"}
            />
          )}
          {haveViewConstructingCounterpartiesPermission && (
            <MenuItem title="Контрагенты" link="/constructing/counterparties" icon={contractors} />
          )}
          {haveViewConstructingDocumentsPermission && (
            <MenuItem title="Документы" link="/constructing/documents/" icon={twoListsIcon} />
          )}
          {haveViewSettingsSectionPermission && (
            <div className={styles.bottom}>
              <MenuItem title="Настройки" link="/constructing/settings" icon={settingGear} />
            </div>
          )}
        </>
      )}
      {location.pathname.indexOf("/purchases") !== -1 && (
        <>
          {haveViewPurchasesChartPermission && (
            <MenuItem title="График" link={`/purchases/manufacturing`} icon={scheduleIcon} />
          )}
          {haveViewRequisitionsListPermission && (
            <MenuItem title="Заявки" link="/purchases/requisitions/" icon={applications} />
          )}
          {haveViewOrdersListPermission && <MenuItem title="Заказы" link="/purchases/orders/" icon={graphIcon} />}
          {haveViewPurchasesDocumentsPermission && (
            <MenuItem title="Документы" link="/purchases/documents/" icon={twoListsIcon} />
          )}
          {haveViewPurchasesCounterpartiesPermission && (
            <MenuItem title="Контрагенты" link="/purchases/counterparties" icon={contractors} />
          )}
          {haveViewSettingsSectionPermission && (
            <div className={styles.bottom}>
              <MenuItem title="Настройки" link="/purchases/settings" icon={settingGear} />
            </div>
          )}
        </>
      )}
      {location.pathname.indexOf("/objects") !== -1 && productionsMenu()}
      {locationArray[0] === "settings" && (
        <>
          <MenuItem title="Задачи" link="/settings/tasks" icon={taskIcon} />
        </>
      )}
      {location.pathname.indexOf("/tasks") !== -1 && (
        <>
          <MenuItem
            title="Все задачи"
            link={`/tasks/${TASK_LISTS_TYPES.ALL}/${objectIdForTasksModule}`}
            icon={tasksAll}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.ALL)}
          />
          <MenuItem
            title="Мои задачи"
            link={`/tasks/${TASK_LISTS_TYPES.MY}/${objectIdForTasksModule}`}
            icon={tasksMy}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.MY)}
          />
          <MenuItem
            title="Поручил"
            link={`/tasks/${TASK_LISTS_TYPES.ASSIGNED}/${objectIdForTasksModule}`}
            icon={tasksAssigned}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.ASSIGNED)}
          />
          <MenuItem
            title="Наблюдаю"
            link={`/tasks/${TASK_LISTS_TYPES.WATCHING}/${objectIdForTasksModule}`}
            icon={tasksViewing}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.WATCHING)}
          />
          <MenuItem
            title="Списки задач"
            link={`/tasks/${TASK_LISTS_TYPES.LISTS}/${objectIdForTasksModule}`}
            icon={tasksLists}
            isDirectlyActive={locationArray.includes("tasks") && locationArray.includes(TASK_LISTS_TYPES.LISTS)}
          />
          {(haveViewSettingsSectionPermission || isShowSettingsInTasksModule) && (
            <div className={styles.bottom}>
              <MenuItem title="Настройки" link="/tasks/settings" icon={settingGear} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NavigationBar;
