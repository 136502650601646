import React, { useEffect, useState } from "react";
import cn from "classnames";

import Button from "components/UI/atoms/ButtonBase";
import { splitThousands } from "utils/splitThousands";
import Modal from "components/UI/atoms/Modal";
import { apiGetEstimateExpenditure } from "redux/modules/common/building/shedulesApi";

import styles from "./ProductInfo.module.scss";

const ProductInfo = ({ isOpen, closeModal, objectId, chosenMatchingProduct, openRelationToProduct }) => {
  const [expenditure, setExpenditure] = useState(false);

  const loadEx = async (objectId, id) => {
    const ex = await apiGetEstimateExpenditure(objectId, id);
    await setExpenditure(ex);
  };

  useEffect(() => {
    if (chosenMatchingProduct?.estimate_expenditure) {
      setExpenditure(chosenMatchingProduct.estimate_expenditure);
    }
    if (chosenMatchingProduct?.estimate_expenditure?.id) {
      loadEx(objectId, chosenMatchingProduct?.estimate_expenditure?.id);
    }
  }, [chosenMatchingProduct]);

  return (
    <Modal
      isOpen={isOpen?.id}
      onClose={closeModal}
      closeOnClickOutside={false}
      title="По смете"
      className={styles.modal}
    >
      <div className={cn("modal-content", styles.content)}>
        <div className={cn("modal-body", styles.body)}>
          {expenditure && (
            <div>
              <div>
                <b>Раздел: </b>
                <br />
                {expenditure.section.name}
              </div>
              {expenditure.number && (
                <div className={styles.field}>
                  <b>Номер: </b>
                  <br />
                  {expenditure.number}
                </div>
              )}
              {expenditure.justification && (
                <div className={styles.field}>
                  <b>Обоснование: </b>
                  <br />
                  {expenditure.justification}
                </div>
              )}
              <div className={styles.field}>
                <b>Позиция: </b>
                <br />
                {expenditure.name}
              </div>
              {expenditure.price && (
                <div className={styles.field}>
                  <b>Цена за ед. : </b>
                  {splitThousands(parseFloat(expenditure.price))}
                </div>
              )}
              {expenditure.count && (
                <div className={styles.field}>
                  <b>Кол-во по смете: </b>
                  {splitThousands(parseFloat(expenditure.count), false)}
                </div>
              )}
              {expenditure.price && (
                <div className={styles.field}>
                  <b>Бюджет по смете: </b>
                  {splitThousands(parseFloat(expenditure.price) * parseFloat(expenditure.count))}
                </div>
              )}
              {!isOpen?.editable && (
                <footer className={styles.footer}>
                  <Button onClick={() => openRelationToProduct()} style={{ marginTop: "1rem" }}>
                    Изменить
                  </Button>
                </footer>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ProductInfo);
