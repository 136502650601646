import React, { useCallback, useMemo, useRef } from "react";
import { Field, Form } from "react-final-form";

import Input from "components/UI/atoms/InputBase";
import arowSelectSmallBlue from "images/icons/arowSelectSmallBlue.svg";
import { measures } from "utils/constant";
import { composeFieldValidators, maxLength, required } from "utils/validations";

import { addMaterial } from "../../../../../../redux/modules/common/building/sections/sectionsApi";
import useFormFocus from "../../../../../../utils/hooks/useFormFocus";

import ButtonBase from "../../../../atoms/ButtonBase";
import Select from "../../../../atoms/Select";
import SelectedSectionField from "../../../SelectedSectionField/SelectedSectionField";

import styles from "./index.module.scss";

const TAG_OPTIONS = [
  { id: "expendable_material", name: "Расходный материал" },
  { id: "instrument", name: "Инструмент" },
  { id: "provision", name: "Обеспечение" },
  { id: "shaped_element", name: "Фасонный элемент" },
];

const getMeasureOptions = measures.map((measure) => ({ id: measure, name: measure }));

const NewProductForm = ({ objectId, sections, handleAddProducts, permissions }) => {
  const formRef = useRef(null);
  const inputRootRef = useFormFocus(formRef?.current?.form?.submit);

  const handleSubmit = useCallback(
    async ({ section, ...values }) => {
      addMaterial(objectId, { ...values, section_id: section.id, section_name: section.name }).then((response) =>
        handleAddProducts([{ ...response.data, required_count: response.data.count }])
      );
    },
    [handleAddProducts, objectId]
  );

  const fromEstimateOptions = useMemo(() => {
    const result = [];

    if (permissions.viewAddOutOfEstimateProducts) result.push({ id: false, name: "Вне сметы" });
    if (permissions.viewAddAdditionalProducts) result.push({ id: true, name: "Доп" });

    return result;
  }, [permissions]);

  return (
    <div className={styles.container}>
      <Form
        ref={formRef}
        onSubmit={(values) => handleSubmit(values)}
        render={({ handleSubmit, reset, values }) => (
          <form onSubmit={(e) => handleSubmit(e).then(reset)}>
            <div className={styles.content} ref={inputRootRef}>
              <div className={styles.formRow}>
                <Field
                  name="section"
                  validate={composeFieldValidators(required())}
                  component={SelectedSectionField}
                  sections={sections}
                />
                <div>
                  <Field
                    name="from_estimate"
                    component={Select}
                    options={fromEstimateOptions}
                    className={styles.select}
                    icon={arowSelectSmallBlue}
                    selfControlled
                  />
                </div>
                {!values.from_estimate && (
                  <div>
                    <Field
                      name="tag"
                      component={Select}
                      options={TAG_OPTIONS}
                      validate={composeFieldValidators(required())}
                      className={styles.select}
                      icon={arowSelectSmallBlue}
                      selfControlled
                    />
                  </div>
                )}
                <ButtonBase onClick={handleSubmit}>Добавить</ButtonBase>
              </div>
              <div className={styles.formRow2}>
                <div className={styles.field}>
                  <Field
                    label="Наименование"
                    name="name"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    label="Марка"
                    name="brand"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    label="Код"
                    name="number"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    label="Количество"
                    name="count"
                    component={Input}
                    selfControlled={true}
                    classNameInput={styles.input}
                    validate={composeFieldValidators(required(), maxLength(256))}
                  />
                </div>
                <div>
                  <Field
                    label="Ед.измерения"
                    name="measure"
                    component={Select}
                    options={getMeasureOptions}
                    selfControlled={true}
                    className={styles.select2}
                    validate={composeFieldValidators(required())}
                    icon={arowSelectSmallBlue}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
};


export default React.memo(NewProductForm);