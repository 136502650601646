import React, { FC, useCallback, useMemo } from "react";

import IProduct from "types/interfaces/Product";

import Checkbox from "components/UI/atoms/Checkbox/Checkbox";
import { NavLinkIcon } from "components/UI/NavLinkIcon/NavLinkIcon";

import styles from "./ProductsTable.module.scss";

export interface IProductsTableProps {
  baseModuleRoute: string;
  products: IProduct[];
  productsChecker: {};
  className?: string;
}

type ProductsBySections = Record<number, IProduct[]>

const ProductsTable: FC<IProductsTableProps> = ({ baseModuleRoute, products, productsChecker, className }) => {
  const productsBySections: ProductsBySections = useMemo(() => {
    const result: ProductsBySections = {};

    products.forEach((product) => {
      if (!result[product.section.id]) result[product.section.id] = [];
      result[product.section.id].push(product);
    });

    return result;
  }, [products]);

  const onCheckAll = useCallback(
    (e) => productsChecker.checkAll(e.target.checked),
    [productsChecker.checkAll]
  );

  return (
    <div className={className}>
      <header className={styles.header}>
        <div className={styles.checkboxCol}>
          <Checkbox checked={productsChecker.checkedCount === products.length} onCheck={onCheckAll} />
        </div>
        <div className={styles.nameCol}>Название</div>
        <div className={styles.countCol}>Необходимое кол-во</div>
        <div className={styles.countCol}>Принято</div>
        <div className={styles.ordersCol}>Заказы</div>
      </header>
      {Object.values(productsBySections).map((sectionProducts) => (
        <div>
          <div className={styles.section}>{sectionProducts[0].section.name}</div>
          <div>
            {sectionProducts.map((product) => (
              <div className={styles.row} key={product.id}>
                <div className={styles.checkboxCol}>
                  <Checkbox
                    checked={productsChecker.items[product.id]}
                    onCheck={(e) => productsChecker.checkOnce(product.id, e.target.checked)}
                  />
                </div>
                <div className={styles.nameCol} title={product.product_building.name}>
                  {product.product_building.name}
                </div>
                <div className={styles.countCol}>{product.required_count}</div>
                <div className={styles.countCol}>{product.accepted_count}</div>
                <div className={styles.ordersCol}>
                  <div className={styles.orders}>
                    {product.orders.map((order) => (
                      <NavLinkIcon to={`/${baseModuleRoute}/orders/${order.order.id}`} title={`Заказ №${order.order.number}`} />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(ProductsTable);
