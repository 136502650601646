import Axios from "axios";
import React from "react";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";
import { Spinner } from "components/UI/Spinner/Spinner";
import { errorCatcher } from "utils/errorCatcher";
import WorkListItem from "./../components/WorkListItem/WorkListItem";
import { useParams } from "react-router-dom";
import moment from "moment";
import getShortFullName from "utils/getShortFullName";
import { splitThousands } from "utils/splitThousands";
import cn from "classnames";
import styles from "./JournalDoneModal.module.scss";
import Expandable from "./../../../atoms/Expandable/Expandable";
import ExpandableFileInput from "./../../../molecules/ExpandableFileInput/ExpandableFileInput";
import downloadFileByUrl from "utils/downloadFileByUrl";

import JornalDay from "../JournalTicketModal/JornalDay";
import NavBar from "./../components/NavBar/NavBar";
import WorkersList from "./../components/WorkersList/WorkersList";
import MaterialBlockInWork from "../components/MaterialsInWork/MaterialBlockInWork";
import {dropNonSignificantZeros} from '../../../../../utils/dropNonSignificantZeros'

function JournalDoneWorks({ data }) {
  const { objectId } = useParams();
  const [intervalId, setIntervalId] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [list, setList] = React.useState(null);

  React.useEffect(() => {
    if (data?.id) {
      setIsLoading(true);
      Axios.get(`/building/${objectId}/tickets/history/${data?.id}/approve_works/`)
        .then((resp) => setList(resp.data.results))
        .finally(() => setIsLoading(false));
    }
  }, [data]);

  const [itemData, setItemData] = React.useState(null);
  const intervalClickHandler = (id, name, date) => {
    setIsLoading(true);
    setIntervalId(id);
    setAccepterName(name);
    setAcceptedDate(date);
    Axios.get(`/building/${objectId}/estimate/items/${data?.id}/fact-intervals/${id}/`)
      .then((resp) => {
        setItemData(resp.data);
      })
      .catch(errorCatcher)
      .finally(() => setIsLoading(false));
  };

  const [accepterName, setAccepterName] = React.useState(null);
  const [acceptedDate, setAcceptedDate] = React.useState(null);
  React.useEffect(() => {
    if (list && list.length === 1) {
      setIsLoading(true);
      setIntervalId(list[0].id);
      Axios.get(`/building/${objectId}/estimate/items/${data?.id}/fact-intervals/${list[0].fact_interval?.id}/`)
        .then((resp) => {
          setItemData(resp.data);
          setAccepterName(
            getShortFullName(list[0].author?.last_name, list[0].author?.first_name, list[0].author?.middle_name)
          );
          setAcceptedDate(list[0].created_at);
        })
        .catch(errorCatcher)
        .finally(() => setIsLoading(false));
    }
  }, [list]);

  const backHandler = () => {
    setItemData(null);
    setIntervalId(null);
  };

  const fileUploadHandler = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    Axios.post(`/building/${objectId}/estimate/items/${data?.id}/fact-intervals/${itemData?.id}/files/`, formData).then(
      (resp) => {
        Axios.get(`/building/${objectId}/estimate/items/${data?.id}/fact-intervals/${itemData?.id}/files/`).then(
          (resp) => {
            setItemData((prev) => ({ ...prev, files: resp.data.results }));
          }
        );
      }
    );
  };

  const fileRemoveHandler = (fileId) => {
    Axios.delete(
      `/building/${objectId}/estimate/items/${data?.id}/fact-intervals/${itemData?.id}/files/${fileId}`
    ).then((resp) => {
      Axios.get(`/building/${objectId}/estimate/items/${data?.id}/fact-intervals/${itemData?.id}/files/`).then(
        (resp) => {
          setItemData((prev) => ({ ...prev, files: resp.data.results }));
        }
      );
    });
  };

  const accepted = itemData?.accepted?.[0] || {}

  return (
    <>
      {isLoading && <Spinner />}
      {!intervalId && (
        <div>
          {Array.isArray(list) &&
            list.map((el) => (
              <WorkListItem
                key={el.id}
                name={el.fact_interval?.title || data?.name}
                measure={el.measure}
                passName={el.fact_interval?.author}
                acceptDate={el.created_at}
                acceptName={el.author}
                passDate={el.fact_interval?.created_at}
                passCount={dropNonSignificantZeros(el.fact_interval?.count)}
                acceptCount={dropNonSignificantZeros(el.count)}
                passAmount={dropNonSignificantZeros(el.fact_interval_amount)}
                acceptAmount={dropNonSignificantZeros(el.amount)}
                onClick={() =>
                  intervalClickHandler(
                    el.fact_interval?.id,
                    getShortFullName(el.author?.last_name, el.author?.first_name, el.author?.middle_name),
                    el.created_at
                  )
                }
              />
            ))}
        </div>
      )}
      {!intervalId && !isLoading && !list?.length ? (
        <EmptyPlaceholder text="Нет сведений" img={jurnalIcon} size="small" />
      ) : null}
      {intervalId && !isLoading && (
        <>
          <NavBar isExist={list?.length > 1} onClick={() => setIntervalId(null)} />
          <h2 className={styles.title}>{itemData?.expenditure_name}</h2>
          <div className={styles.pair}>
            <JornalDay
              day={moment(itemData?.created_at).format("DD/MM/YYYY")}
              time={moment(itemData?.created_at).format("HH:mm")}
            />
            <div className={styles.pass}>
              <b>Сдал:</b>
              <span>
                {getShortFullName(
                  itemData?.author?.last_name,
                  itemData?.author?.first_name,
                  itemData?.author?.middle_name
                )}
              </span>
            </div>
          </div>
          <div className={styles.pair}>
            <JornalDay
              day={moment(acceptedDate).format("DD/MM/YYYY")}
              time={moment(acceptedDate).format("HH:mm")}
            />
            <div className={styles.pass}>
              <b>Принял:</b>
              <span>{accepterName}</span>
            </div>
          </div>
          <div className={styles.hr}></div>
          <div className={styles.pair}>
            <div className={styles.info}>
              <b>Количество (к сдаче):</b>
              <span>
                {dropNonSignificantZeros(itemData?.count)} ({data?.measure})
              </span>
            </div>
            <div className={styles.info}>
              <b>Израсходовано:</b>
              <span>{splitThousands(itemData?.work_amount)}</span>
            </div>
          </div>
          <div className={styles.pair}>
            <div className={styles.info}>
              <b>Количество (принято):</b>
              <span>
                {dropNonSignificantZeros(accepted?.count)} ({data?.measure})
              </span>
            </div>
            <div className={styles.info}>
              <b>Стоимость по проекту:</b>
              <span>{splitThousands(itemData?.work_with_material_amount)}</span>
            </div>
          </div>
          <div className={cn(styles.info, styles.mb)}>
            <b>Раздел:</b>
            <span>{itemData?.section_name}</span>
          </div>
          <WorkersList workers={itemData?.workers} />
          {itemData?.stock_using_materials?.length ? (
            <Expandable title={`Материалы: ${itemData?.stock_using_materials?.length}`}>
              {itemData?.stock_using_materials?.map((el) => (
                <MaterialBlockInWork
                  name={el.stock_using_material?.product_building?.name}
                  measure={el.stock_using_material?.product_building?.measure}
                  count={dropNonSignificantZeros(el.count)}
                  amount={dropNonSignificantZeros(el.amount)}
                  key={el.id}
                />
              ))}
            </Expandable>
          ) : null}
          <ExpandableFileInput
            files={itemData?.files || []}
            onRemoveFileDirectly={fileRemoveHandler}
            onUploadFileDirectly={fileUploadHandler}
          />
        </>
      )}
    </>
  );
}

export default JournalDoneWorks