import { createSelector } from "reselect";
import { message } from "antd";

import {
  apiGetSections,
  apiGetSubSections,
  apiUpdateExpenditure,
} from "./materialsApi";

import { errorCatcher } from "utils/errorCatcher";

const moduleName = "materials";

const SET_MATERIAL_SECTIONS = `${moduleName}/SET_MATERIAL_SECTIONS`;
const SET_MATERIAL_SUB_SECTIONS = `${moduleName}/SET_MATERIAL_SUB_SECTIONS`;
const SET_MATERIAL_EXPENDITURES = `${moduleName}/SET_MATERIAL_EXPENDITURES`;
const UPDATE_MATERIAL_EXPENDITURES = `${moduleName}/UPDATE_MATERIAL_EXPENDITURES`;
const SHOW_LOADER = `${moduleName}/SHOW_LOADER`;
const HIDE_LOADER = `${moduleName}/HIDE_LOADER`;
export const CLEAR_MATERIAL_SUBSECTIONS = `${moduleName}/CLEAR_SUBSECTIONS`;
export const CLEAR_MATERIAL_EXPENDITURES = `${moduleName}/CLEAR_EXPENDITURES`;

const initialState = {
  material_sections: [],
  material_sub_sections: {},
  material_expenditures: {},
  isLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_LOADER:
      return { ...state, isLoading: true };
    case HIDE_LOADER:
      return { ...state, isLoading: false };

    case SET_MATERIAL_SECTIONS:
      return {
        ...state,
        material_sections: payload,
      };
    case SET_MATERIAL_SUB_SECTIONS:
      return {
        ...state,
        material_sub_sections: payload,
      };
    case SET_MATERIAL_EXPENDITURES:
      return {
        ...state,
        material_expenditures: payload,
      };
    case UPDATE_MATERIAL_EXPENDITURES:
      return {
        ...state,
        material_expenditures: {
          ...state.material_expenditures,
          expenditures: [
            ...state.material_expenditures.expenditures.map((el) =>
              el.id === payload.id ? payload : el
            ),
          ],
        },
      };
    case CLEAR_MATERIAL_SUBSECTIONS:
      return {
        ...state,
        material_sub_sections: {},
      };
    case CLEAR_MATERIAL_EXPENDITURES:
      return {
        ...state,
        material_expenditures: {},
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];

export const materialSectionsSelector = createSelector(
  stateSelector,
  (state) => state.material_sections
);
export const materialSubSectionsSelector = createSelector(
  stateSelector,
  (state) => state.material_sub_sections
);
export const materialExpendituresSelector = createSelector(
  stateSelector,
  (state) => state.material_expenditures
);
export const isLoadingSectionSelector = createSelector(
  stateSelector,
  (state) => state.isLoading
);

const getSectionsAC = (data) => ({
  type: SET_MATERIAL_SECTIONS,
  payload: data,
});
const getSubSectionsAC = (data) => ({
  type: SET_MATERIAL_SUB_SECTIONS,
  payload: data,
});
const getExpendituresAC = (data) => ({
  type: SET_MATERIAL_EXPENDITURES,
  payload: data,
});
const updateExpendituresAC = (data) => ({
  type: UPDATE_MATERIAL_EXPENDITURES,
  payload: data,
});

export const showLoaderAction = () => ({ type: SHOW_LOADER });
export const hideLoaderAction = () => ({ type: HIDE_LOADER });

export const getSections = (objectId) => {
  return async (dispatch) => {
    try {
      const { data } = await apiGetSections(objectId);
      await dispatch(getSectionsAC(data));
    } catch (error) {
      errorCatcher(error);
    }
  };
};
export const getMaterialsSubSections = (objectId, Id) => {
  return async (dispatch) => {
    try {
      dispatch(showLoaderAction());
      const { data } = await apiGetSubSections(objectId, Id);
      await dispatch(getSubSectionsAC(data));
      dispatch(hideLoaderAction());
    } catch (error) {
      errorCatcher(error);
    }
  };
};
export const getMaterialsExpenditures = (objectId, Id) => {
  return async (dispatch) => {
    try {
      dispatch(showLoaderAction());
      const { data } = await apiGetSubSections(objectId, Id);
      await dispatch(getExpendituresAC(data));
      dispatch(hideLoaderAction());
    } catch (error) {
      errorCatcher(error);
    }
  };
};
export const updateExpenditure = (objectId, Id, config) => {
  return async (dispatch) => {
    try {
      const { data } = await apiUpdateExpenditure(objectId, Id, config);
      await dispatch(updateExpendituresAC(data));
      await message.success("Обновлено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};
