import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import cn from "classnames";

import SignIn from "./AuthTabs/SignIn";
import SignUp from "./AuthTabs/SignUp";
import SignUpTasks from "./AuthTabs/SignUpTasks";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import LogoImage from "images/authorization/auth_logo.svg";

import styles from "./authorization.module.scss";

const SIGN_IN = "signin";
const SIGN_UP = "signup";
const SIGN_UP_TASKS = "signupTasks";

const Authorization = () => {
  const auth = useSelector((state) => state.auth);

  const [activeTab, setActiveTab] = useState(SIGN_IN);

  if (auth.isAuthenticated) return <Redirect to="/" />;

  return (
    <main className="authorization">
      <div className={styles.header}>
        <img src={LogoImage} alt="Лого" className={styles.logo} />
      </div>
      <div className={styles.container}>
        <div className={styles.tabbar}>
          <div
            className={cn(styles.tab, { [styles.activeTab]: activeTab === SIGN_IN })}
            onClick={() => setActiveTab(SIGN_IN)}
          >
            Вход
          </div>
          <div
            className={cn(styles.tab, { [styles.activeTab]: activeTab === SIGN_UP || activeTab === SIGN_UP_TASKS })}
            onClick={() => setActiveTab(isAllModulesAllowed() ? SIGN_UP : SIGN_UP_TASKS)}
          >
            Регистрация
          </div>
        </div>
        <div>
          {activeTab === SIGN_IN && <SignIn />}
          {activeTab === SIGN_UP && <SignUp />}
          {activeTab === SIGN_UP_TASKS && <SignUpTasks setActiveTab={setActiveTab} />}
        </div>
      </div>
    </main>
  );
}

export default Authorization;
