import React from 'react'

export const COLORS_INFO = {
  "to_pay":  '#72E2E6',
  "completed": '#8FE52B',
  "accepted": '#FFE86A'
}


export const COLORS_RUBLE = {
  "work_completed":  '#8FE52B',
  "work_accepted": '#FFE86A',
  "to_payed_amount": '#72E2E6'
}

export const RADIAN = Math.PI / 180;
export const renderLabel = (props) => {
  const { cx, cy, startAngle, outerRadius, percent, index} = props;
  const radius = outerRadius * 1.1;
  let x = cx + radius * Math.cos(-startAngle * RADIAN);
  let y = cy + radius * Math.sin(-startAngle * RADIAN);
  if (percent === 1){
    x -= 5
    y +=5
  }
  if (index === 1 && percent > 0){
    return (
      <text x={x} y={y} fill="black" textAnchor={ percent > 0.5 && percent <= 1 ? 'end' : 'start'} dominantBaseline={percent > 0.25 && percent < 0.75 ? "hanging" : "auto"}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  }
  return null;
};
