import React from 'react'
import styles from "./MeasureBtn.module.scss"
import cn from "classnames"

export const measureContentSwitcher = (measure) => {
    if (measure === 1000000) {
        return "млн."
    } else if (measure === 1000) {
        return "тыс."
    } else {
        return "руб."
    }
}

function MeasureBtn({ measure, onMeasureChange, className }) {
  return (
    <button className={cn(styles.measureBtn, className)} onClick={onMeasureChange}>Ед. изм. | {measureContentSwitcher(measure)}</button>
  )
}

export default MeasureBtn