import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';

import {MonthArray} from 'utils/constant';
import newArrow from '../../../../../../images/icons/arrow-calendar.svg'
import rightArow from '../../../../../../images/icons/arrowRight.svg'

import styles from "./index.module.scss";

const MonthPicker = ({date, setDate}) => {
  const setMonth = (val) => {
    const currentMonth = moment(date).month();
    let month;
    let year = moment(date).year();
    if (val === 'previous') {
      month = MonthArray.find(item => item.id === (currentMonth > 0 ? currentMonth - 1 : 11));
      year = month.id === 11 ? +year - 1 : year;
    }
    if (val === 'next') {
      month = MonthArray.find(item => item.id === (currentMonth < 11 ? currentMonth + 1 : 0));
      year = month.id === 0 ? +year + 1 : year;
    }
    setDate(moment(date).year(year).month(month.id));
  }

  const handleSetYear = (value) => {
    let year = moment(date).year()
    if(value === 'next') {
      year += 1
    } 
    if(value === 'previous') {
      year -= 1
    }
    setDate(moment(date).year(year))
  }

  return (
    <div className={styles.container}>

      <div className={styles.wrapperFlex}>
          <div style={{cursor:'pointer'}} onClick={() => handleSetYear('previous')}>
                <img src={newArrow} alt="" />
                <img src={newArrow} alt="" />
            </div>

          <div className={styles.flexFirst}>
              <div style={{cursor:'pointer'}} onClick={() => setMonth('previous')}>
                  <img src={newArrow} alt="" />
              </div>
              <div className={styles.innerDate}>
                <div style={{marginRight:5}} className={styles.dateText}>{MonthArray[moment(date).month()]?.label}</div>
                <div className={styles.dateText}>{moment(date).format('Y')}</div>
              </div>
              <div style={{cursor:'pointer'}} onClick={() => setMonth('next')}>
                  <img src={rightArow} alt="" />
              </div>
          </div>

          <div style={{cursor:'pointer'}} onClick={() => handleSetYear('next')}>
              <img  src={rightArow} alt="" />
              <img src={rightArow} alt="" />
          </div>
      </div>

        

    </div>
  )
}

export default MonthPicker;