import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import { useUrlModule } from "utils/hooks/useUrlModule";

import { useTasksModal } from "components/pages/Tasks/hooks/useTasksModal";
import TasksModal from "components/pages/Tasks/components/TasksModal/TasksModal";
import { TASK_LISTS_TYPES } from "components/pages/Tasks/constants";

import newTaskIcon from "images/icons/newTaskIcon.svg";

import Icon from "../../../../Icon";

import styles from "./NewTaskButton.module.scss";

const NewTaskButton: React.FC = () => {
  const moduleName = useUrlModule();

  const { objectId } = useParams<{ objectId: string }>();

  const { openAddModal } = useTasksModal();

  const handleOpenTaskModal = useCallback(() => {
    openAddModal({
      placementType: +objectId !== 0 ? "byCertainBuilding" : "byAllBuildings",
      listType: TASK_LISTS_TYPES.ALL,
    });
  }, [objectId]);

  if (['objects', 'purchases', 'constructing'].includes(moduleName))
    return (
      <>
        <Icon icon={newTaskIcon} onClick={handleOpenTaskModal} className={styles.button} />
        <TasksModal presetBuildingId={objectId ? +objectId : 0} />
      </>
    );
  return null;
};

export default React.memo(NewTaskButton);
