import React, { useCallback, useState } from "react";
import cn from "classnames";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";

import {
  expendituresSubMaterialsLoadingSelector,
  expendituresSubMaterialsSelector,
  getExpenditureSubMaterials,
} from "../../../../../../redux/modules/common/building/sections/sections";
import { sliceTextByConstraint } from "../../../../../../utils/sliceTextByConstraint";

import ChartPopup from "../../../../../pages/Building/components/ChartPopup/ChartPopup";
import SpecificationModal from "../../../../SpecificationModal/SpecificationModal";
import PaperClipIcon from "../../../../../../images/icons/PaperClipIcon";

import SaveFileModel from "../../../../organism/SaveFileModel";

import { VARIANTS } from "../../constants";

import Ks, { KS_TYPES } from "./components/Ks/Ks";
import styles from "./Info.module.scss";

const MAX_NAME_LENGTH = 85;

const Info = ({
  expenditure,
  buildingId,
  variant,
  isShared,
  onOverview,
  overviewState,
  canDeleteFiles,
  canAddFiles,
}) => {
  const [isOpenJustification, setIsOpenJustification] = useState(false);

  const dispatch = useDispatch();
  const subMaterialsAreLoading = useSelector(expendituresSubMaterialsLoadingSelector);
  const subMaterials = useSelector(expendituresSubMaterialsSelector);

  const areThereSubMaterials = subMaterials && subMaterials.length !== 0;

  const onClickByJustification = useCallback(() => {
    setIsOpenJustification(true);
    if (!subMaterials[expenditure.id]) compose(dispatch, getExpenditureSubMaterials)(buildingId, expenditure.id);
  }, [buildingId, expenditure.id, subMaterials]);

  const closeJustification = useCallback(() => setIsOpenJustification(false), []);

  const [isOpenFilesModal, setIsOpenFilesModal] = useState(false);
  const closeFilesModal = useCallback(() => setIsOpenFilesModal(false), []);

  return (
    <>
      <div title={expenditure.name}>{sliceTextByConstraint(expenditure.name, MAX_NAME_LENGTH)}</div>
      <div className={styles.labelBox}>
        <div
          className={cn(styles.blueLabel, { [styles.cursorPointer]: areThereSubMaterials })}
          title={expenditure.justification}
          onClick={onClickByJustification}
        >
          {expenditure.justification || "-"}
        </div>
        <div className={styles.greyLabel} onClick={() => setIsOpenFilesModal(true)}>
          <PaperClipIcon width={7} height={11} fill="#808080" />
          {expenditure.count_files}
        </div>
        {variant !== VARIANTS.HANDLER && variant !== VARIANTS.HANDLER_DRAFT && (
          <>
            <Ks
              count={expenditure.ks2_count}
              expenditureId={expenditure.id}
              buildingId={buildingId}
              type={KS_TYPES.KS2}
            />
            <Ks
              count={expenditure.ks3_count}
              expenditureId={expenditure.id}
              buildingId={buildingId}
              type={KS_TYPES.KS3}
            />
          </>
        )}
        {!isShared && variant !== VARIANTS.HANDLER_DRAFT && (
          <ChartPopup
            className={styles.roubleBtn}
            onHover={onOverview}
            accepted={overviewState.accepted}
            all={overviewState.all}
            completed={overviewState.completed}
          />
        )}
      </div>
      <SaveFileModel
        isOpen={isOpenFilesModal}
        handleClose={closeFilesModal}
        product={expenditure}
        objectId={buildingId}
        canDelete={canDeleteFiles}
        canUpload={canAddFiles}
      />
      {areThereSubMaterials && (
        <SpecificationModal
          name={expenditure.justification}
          isOpen={isOpenJustification}
          onClose={closeJustification}
          subMaterials={subMaterials[expenditure.id]}
          isLoading={subMaterialsAreLoading}
        />
      )}
    </>
  );
};

export default React.memo(Info);
