import React, {Fragment} from 'react'
import cn from 'classnames'

import styles from './DaysLine.module.scss'
import {generateDaysForLine} from '../../../../utils'
import {ICalendarDateLineProps} from '../../CalendarDateLine'
import {useUnitMultiplier} from '../../../../hooks/useUnitMultiplier'

const DaysLine = React.forwardRef<HTMLDivElement, ICalendarDateLineProps>(({year}, ref) => {
  const unitMultiplier = useUnitMultiplier()
  const days = generateDaysForLine(+year)
  return (
    <div className={styles.daysWrapper} ref={ref}>
      {days.map((day, index) => {
        return (
          <Fragment key={index}>
            <div
              className={cn(
                {
                  startMonth: day.day === 1,
                  [styles.weekend]: day.weekend,
                  [styles.today]: day.today,
                },
                styles.dayWrapper
              )}
              style={{
                left: `${index * unitMultiplier}rem`,
                width: `${unitMultiplier}rem`,
              }}
            >
              <div className={styles.day}>{day.day}</div>
            </div>
          </Fragment>
        );
      })}
    </div>
  )
})

export default React.memo(DaysLine)