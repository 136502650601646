import cn from 'classnames'
import CalendarRange from 'components/UI/molecules/CalendarRange';
import React from 'react'
import styles from "./PeriodOfExecution.module.scss"

type propsType = {
    dateStart: string;
    dateEnd: string;
    isDisabled?: boolean;
}

function PeriodOfExecution({
    dateEnd,
    dateStart,
    isDisabled = true
}: propsType) {
    return (
        <div className={cn(styles.pair, styles.centered)}>
            <b>Период выполнения:</b>{/* @ts-ignore */}
            <CalendarRange
                defaultDateStart={dateStart}
                defaultDateEnd={dateEnd}
                disabled={isDisabled}
                classNameSelect={styles.calendar}
                isTooRight
            />
        </div>
    )
}

export default PeriodOfExecution