import axios from 'axios'
import { createSelector } from 'reselect'
import moment from 'moment'

import { financeStateSelector, stateSelector as indexStateSelector } from '../finance'
import {
  prepareSections,
  addSectionChildren,
  setSectionIsOpen,
  prepareTableSections,
  prepareSectionExpenditures,
  prepareSectionsAllObjects
} from './utils'

const initialState = {
  date: {
    start: moment().format(),
    end: moment().format()
  },
  usedId: 0,
  sections: [],
  isLoading: true,
  tableHighlighting: {
    positive: false,
    negative: true
  }
}

const moduleName = 'audit'
const actionsPrefix = 'constructing/finance/audit'

const SET_DATE = `${actionsPrefix}_SET_DATE`
const SET_SECTIONS = `${actionsPrefix}_SET_SECTIONS`
const SET_LOADING = `${actionsPrefix}_SET_LOADING`
const SET_TABLE_HIGHLIGHTING = `${actionsPrefix}_SET_TABLE_HIGHLIGHTING`
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`

export const stateSelector = createSelector(financeStateSelector, (state) => state[moduleName])
export const objectIdSelector = createSelector(indexStateSelector, (state) => state.objectId)
export const dateSelector = createSelector(stateSelector, (state) => state.date)
export const sectionsSelector = createSelector(stateSelector, (state) => state.sections)
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading)
export const tableHighlightingSelector = createSelector(
  stateSelector,
  (state) => state.tableHighlighting
)
export const tableDataSelector = createSelector(sectionsSelector, prepareTableSections)

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_DATE:
      return {
        ...state,
        date: {
          ...state.date,
          ...payload
        }
      }
    case SET_SECTIONS:
      return {
        ...state,
        sections: payload.data,
        usedId: payload.id
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case SET_TABLE_HIGHLIGHTING:
      return {
        ...state,
        tableHighlighting: {
          ...state.tableHighlighting,
          [payload.field]: payload.value
        }
      }
    case CLEAR_DATA:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export const setDate = (data) => ({
  type: SET_DATE,
  payload: data
})

export const setSections = (data, id) => ({
  type: SET_SECTIONS,
  payload: {data, id}
})

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data
})

export const innerListsAutoOpener = (data) => (dispatch, getState) => {
  const objectId = objectIdSelector(getState())
  const sections = sectionsSelector(getState())
  const date = dateSelector(getState())
  let count = data?.length
  if (data?.length < 10) {
    const dataPromises = []
    data.forEach(el => {
      console.log("el", el)
      const urlId = objectId != 0 ? objectId : el.building_id
      count = count + el.subsection_count
      dataPromises.push(axios.get(`/building/${urlId}/plan-fact/audit/sections/`, {
        params: {
          parent: el.id,
          selected_period_start: moment(date.start).format('YYYY-MM-DD'),
          selected_period_end: moment(date.end).format('YYYY-MM-DD')
        }
      }))
    })
    Promise.all(dataPromises).then(dataResponses => {
      dataResponses.forEach((resp, idx) => {
        const sections = sectionsSelector(getState())
        const urlId = objectId != 0 ? objectId : data[idx].building_id
        const newSections = JSON.parse(JSON.stringify(sections))
        addSectionChildren(newSections, data[idx].id, resp.data)
        dispatch(setSections(newSections))
        const promisesArr = []
        resp.data.forEach(ns => {
          promisesArr.push(axios.get(
            `/building/${urlId}/plan-fact/audit/sections/${ns.id}/expenditures/`,
            {
              params: {
                selected_period_start: moment(date.start).format('YYYY-MM-DD'),
                selected_period_end: moment(date.end).format('YYYY-MM-DD')
              }
            }))
        }) 
        Promise.all(promisesArr).then(allResp => {
          allResp.forEach((response, i) => {
            const sections = sectionsSelector(getState())
            const newSections = JSON.parse(JSON.stringify(sections))
            const preparedSections = prepareSectionExpenditures(response.data, resp.data[i].id)
            addSectionChildren(newSections, resp.data[i].id, preparedSections)
            dispatch(setSections(newSections))
          })
        })
      })
    }).catch(err => null)
  }
}

export const loadSections = () => {
  return async (dispatch, getState) => {
    const objectId = objectIdSelector(getState())
    const date = dateSelector(getState())
    if (objectId != 0) {
      try {
        dispatch(setLoading(true))
        const response = await axios.get(`/building/${objectId}/plan-fact/audit/sections/`, {
          params: {
            selected_period_start: moment(date.start).format('YYYY-MM-DD'),
            selected_period_end: moment(date.end).format('YYYY-MM-DD')
          }
        })
        const preparedSections = prepareSections(response.data)
        dispatch(setSections(preparedSections))
        dispatch(innerListsAutoOpener(preparedSections))
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    } else {
      try {
        dispatch(setLoading(true))
        const response = await axios.get(`/plan-fact/audit/buildings/`, {
          params: {
            selected_period_start: moment(date.start).format('YYYY-MM-DD'),
            selected_period_end: moment(date.end).format('YYYY-MM-DD')
          }
        })
        dispatch(setSections(prepareSectionsAllObjects(response.data)))
        //dispatch(innerListsAutoOpener(prepareSectionsAllObjects(response.data)))

      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    }

  }
}

export const loadSectionChildren = (parentId, buildingId) => {
  return async (dispatch, getState) => {
    const objectId = objectIdSelector(getState())
    const sections = sectionsSelector(getState())
    const date = dateSelector(getState())
    const urlId = objectId != 0 ? objectId : buildingId
    try {
      const response = await axios.get(`/building/${urlId}/plan-fact/audit/sections/`, {
        params: {
          parent: parentId,
          selected_period_start: moment(date.start).format('YYYY-MM-DD'),
          selected_period_end: moment(date.end).format('YYYY-MM-DD'),
          limit: 500 
        }
      })
      const newSections = JSON.parse(JSON.stringify(sections))
      addSectionChildren(newSections, parentId, response.data)
      dispatch(setSections(newSections))
      //sectionsAutoOpener()
    } catch (e) {
      console.error(e)
    }
  }
}

export const loadBuildingSectionChildren = (objectId) => {
  return async (dispatch, getState) => {
    const sections = sectionsSelector(getState())
    const date = dateSelector(getState())
    try {
      const response = await axios.get(`/building/${objectId}/plan-fact/audit/sections/`, {
        params: {
          selected_period_start: moment(date.start).format('YYYY-MM-DD'),
          selected_period_end: moment(date.end).format('YYYY-MM-DD'),
          limit: 500 
        }
      })
      const newSections = JSON.parse(JSON.stringify(sections))
      addSectionChildren(newSections, objectId, response.data)
      dispatch(setSections(newSections))
      //sectionsAutoOpener()
    } catch (e) {
      console.error(e)
    }
  }
}

export const closeSection = (sectionId) => {
  return async (dispatch, getState) => {
    const sections = sectionsSelector(getState())
    const newSections = JSON.parse(JSON.stringify(sections))
    setSectionIsOpen(newSections, sectionId, false)
    dispatch(setSections(newSections))
  }
}

export const openSection = (sectionId) => {
  return async (dispatch, getState) => {
    const sections = sectionsSelector(getState())
    const newSections = JSON.parse(JSON.stringify(sections))
    setSectionIsOpen(newSections, sectionId, true)
    dispatch(setSections(newSections))
  }
}

export const loadSectionExpenditures = (parentId, buildingId) => {
  return async (dispatch, getState) => {
    const objectId = objectIdSelector(getState())
    const sections = sectionsSelector(getState())
    const date = dateSelector(getState())
    const realParentId = parentId//.replace('_virtual', '')
    const urlId = objectId != 0 ? objectId : buildingId
    try {
      const response = await axios.get(
        `/building/${urlId}/plan-fact/audit/sections/${realParentId}/expenditures/`,
        {
          params: {
            selected_period_start: moment(date.start).format('YYYY-MM-DD'),
            selected_period_end: moment(date.end).format('YYYY-MM-DD'),
            limit: 500 
          }
        }
      )
      const newSections = JSON.parse(JSON.stringify(sections))
      const preparedSections = prepareSectionExpenditures(response.data, realParentId)
      addSectionChildren(newSections, parentId, preparedSections)
      dispatch(setSections(newSections))
    } catch (e) {
      console.error(e)
    }
  }
}

export const setTableHighlighting = ({ field, value }) => ({
  type: SET_TABLE_HIGHLIGHTING,
  payload: { field, value }
})

export const clearData = () => ({
  type: "CLEAR_DATA_DISABLED_ACTION"
})
