import React from "react"
import { message } from "antd"
import { IMaterialInPlanInterval, ISerializedMaterialInPlan } from "types/interfaces/Materials"
import { serializePlanMaterialsInEditPlan } from "components/pages/Manufacturing/components/ManufacturingModal/PlanEditing/utils"

export const useSelectingMaterials = (defaultMatreials?: IMaterialInPlanInterval[], isExistingPlan?: boolean) => {
    const [addedMaterials, setAddedMaterials] = React.useState<ISerializedMaterialInPlan[]>([])
    const [selectedMaterials, setSelectedMaterials] = React.useState<ISerializedMaterialInPlan[]>([])

    React.useEffect(() => {
        if (!defaultMatreials?.length) return
        setAddedMaterials(defaultMatreials.map(el => ({
            id: el.material.id, 
            count: el.count, 
            name: el.material.name, 
            measure: el.material.measure, 
            local_count: el.count
        })))
    }, [defaultMatreials])

    const addedMaterialsIds = React.useMemo(() => {
        return addedMaterials.map(el => el.id)
    }, [addedMaterials])

    const selectedMaterialsIds = React.useMemo(() => {
        return selectedMaterials.map(el => el.id)
    }, [selectedMaterials])

    const confirmMaterialsHandler = () => {
        setAddedMaterials(prev => [...prev, ...selectedMaterials])
        setSelectedMaterials([])
    }

    const cancelMaterialsHandler = () => {
        setSelectedMaterials([])
    }

    const changeCountMaterialsHandler = (id: number, count: string, type: "selected" | "added") => {
        const mapper = (arr: any[]) => arr.map(el => {
            if (el.id === id) {
                return { ...el, local_count: count }
            } else {
                return el
            }
        })
        if (type === "selected") {
            setSelectedMaterials(prev => mapper(prev))
        } else {
            setAddedMaterials(prev => mapper(prev))
        }
    }

    const validateSubmittingMaterials = (submit: () => void) => {
        if (addedMaterials.some(el => (!el.local_count && !el.count))) {
            message.error("Укажите кол-во каждому выбранному материалу")
            return
        }
        submit()
    }

    const deleteAddedHandler = (id: number) => {
        const result = addedMaterials.filter(el => el.id !== id)
        setAddedMaterials(result)
    }

    const selectMaterialsHandler = (e: React.ChangeEvent<HTMLInputElement>, item: any) => {
        if (e.target.checked) {
            setSelectedMaterials(prev => [...prev, item])
        } else {
            setSelectedMaterials(prev => prev.filter(el => el.id !== item.id))
        }
    }

    const clearMaterials = () => {
        setAddedMaterials([])
        setSelectedMaterials([])
    }

    const setPresettedMaterials = (presetedMaterials: Array<IMaterialInPlanInterval|ISerializedMaterialInPlan>) => {
        if (!presetedMaterials?.length) return
        setAddedMaterials(isExistingPlan ? serializePlanMaterialsInEditPlan(presetedMaterials as IMaterialInPlanInterval[]) : presetedMaterials as ISerializedMaterialInPlan[])
    }

    return {
        confirmMaterialsHandler,
        cancelMaterialsHandler,
        changeCountMaterialsHandler,
        validateSubmittingMaterials,
        deleteAddedHandler,
        selectMaterialsHandler,
        addedMaterials,
        selectedMaterials,
        selectedMaterialsIds,
        addedMaterialsIds,
        clearMaterials,
        setPresettedMaterials
    }
}