import React from 'react'
import ModalContainer from '../components/ModalContainer/ModalContainer'
import TabBarNotLinks from "../../../../../elements/navigation/TabBar/TabBarNotLinks"
import JournalDoneWorks from './JournalDoneWorks'
import styles from "./JournalDoneModal.module.scss"
import JournalDoneMaterials from './JournalDoneMaterials'

function JournalDoneModal({ isOpen, onClose, data }) {
    const [activeTab, setActiveTab] = React.useState("works")//"materials"
    const [isTabsExist, setIsTabsExist] = React.useState(true)

    React.useEffect(() => {
        if (!isOpen) {
            setIsTabsExist(true)
            setActiveTab("works")
        }
    }, [isOpen])

    return (
        <ModalContainer isOpen={isOpen && data} onClose={onClose} name={data?.name} justification={data?.justification}>
            {isTabsExist && <TabBarNotLinks
                tabs={[{ text: "Работы", id: "works" }, { text: "Материалы", id: "materials" }]}
                onClick={setActiveTab}
                activeId={activeTab}
                className={styles.tabs}
            />}
            {isOpen && activeTab === "works" && <JournalDoneWorks data={data} onClose={onClose} />}
            {isOpen && <JournalDoneMaterials data={data} isOpen={isOpen} onHideTabs={setIsTabsExist} isExist={activeTab === "materials"} />}
        </ModalContainer>
    )
}

export default JournalDoneModal