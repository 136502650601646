import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { documentsSelector, loadingStateSelector } from "redux/modules/common/documents/reducer";
import { DEFAULT_LIMIT_DOCUMENT } from "redux/modules/common/documents/constants";
import { loadDocuments, loadMoreDocuments } from "redux/modules/common/documents/documents";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";

import { statusActsArray } from "utils/constant";
import ActsIcon from "../../../../images/icons/documentsTab/ActsIcon";
import EmptyPlaceholder from "../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import PackingListsRow from "./PackingListsRow/PackingListsRow";
import { IPackingListInList } from "types/interfaces/PackingList";
import PackingListsTableHeader from "./PackingListsTableHeader/PackingListsTableHeader";
import { Spinner } from "components/UI/Spinner/Spinner";
import { IIdAndName } from "types/idAndName";

const PackingLists = () => {
  const dispatch = useDispatch();
  const documents = useSelector(documentsSelector);
  const [filters, setFilters] = useState({ limit: DEFAULT_LIMIT_DOCUMENT, offset: 0 });
  const isListLoading = useSelector(loadingStateSelector);
  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);

  const formattedObjects = React.useMemo(() => {
    return breadcrumbsProjects.results.map((el: IIdAndName) => ({ id: el.id, name: el.id, label: el.name }));
  }, [breadcrumbsProjects]);

  const changeFilters = (name: string, value: string) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const hasNextPage = useMemo(() => documents?.count > documents?.results?.length, [documents]);
  const loadMore = () => {
    dispatch(
      loadMoreDocuments({
        ...filters,
        offset: documents?.results?.length,
      })
    );
  };

  useEffect(() => {
    dispatch(loadDocuments(filters));
  }, [filters]);

  return (
    <div>
      <PackingListsTableHeader
        onChangeFilter={changeFilters}
        statusBillsArray={statusActsArray}
        formattedObjects={formattedObjects}
      />
      {documents?.results.map((el: IPackingListInList) => (
        <PackingListsRow key={el.id} packingList={el} />
      ))}
      {!isListLoading && !documents?.results.length && <EmptyPlaceholder text="Нет УПД" svgJsx={<ActsIcon />} />}
      {isListLoading && <Spinner />}
      <ShowMoreButton
        isExists={hasNextPage && !isListLoading}
        showedCount={documents?.results?.length}
        allCount={documents?.count}
        handleAdd={loadMore}
      />
    </div>
  );
};

export default PackingLists;
