import React from "react";
import Draggable from "react-draggable";

import Stick from "../../../../../../images/icons/Stick";

import { getPreparedMonthArray } from "./utils";
import styles from "./MonthSlider.module.scss";
import { ScrollPositionType, useMonthSlider } from "./useMonthSlider";
import ButtonArrow from "../../../../atoms/ButtonArrow/ButtonArrow";

export interface IMonthSliderProps {
  tableScrollPosition: ScrollPositionType;
  tableViewWidthPercent: number;
  setTableScrollPosition: (x: number, sliderElement: Element) => void;
  showArrows?: boolean;
  year?: number;
  onArrowClick?: (percent: number) => void;
  handleYearChange?: (year: number) => void;
}

const MonthSlider: React.FC<IMonthSliderProps> = ({
  tableScrollPosition,
  tableViewWidthPercent,
  setTableScrollPosition,
  showArrows,
  handleYearChange,
  onArrowClick,
  year,
}) => {
  const { x, handleDrag, sliderRef, decreaseMonth, increaseMonth } = useMonthSlider({
    tableScrollPosition,
    tableViewWidthPercent,
    setTableScrollPosition,
    onArrowClick,
    handleYearChange,
    year,
  });
  return (
    <div className={styles.wrapper}>
      {showArrows && <ButtonArrow direction="left" onClick={decreaseMonth} className={styles.arrow}/>}
      <div className={styles.monthSlider} ref={sliderRef}>
        <Draggable axis="x" bounds="parent" position={{ x, y: 0 }} onStop={handleDrag}>
          <div className={styles.monthSlider__draggable} style={{ width: `${tableViewWidthPercent}%` }}>
            <span className={styles.monthSlider__left}>
              <div className={styles.monthSlider__stick}>
                <Stick />
              </div>
            </span>
            <span className={styles.monthSlider__right}>
              <div className={styles.monthSlider__stick}>
                <Stick />
              </div>
            </span>
          </div>
        </Draggable>
        <div className={styles.monthSlider__inner}>
          {getPreparedMonthArray().map((item) => (
            <div key={item.id}>{item.label}</div>
          ))}
        </div>
      </div>
      {showArrows && <ButtonArrow direction="right" onClick={increaseMonth} className={styles.arrow}/>}
    </div>
  );
};

export default React.memo(MonthSlider);
