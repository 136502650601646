import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLoadOrders,
  DEFAULT_LIMIT_ORDERS,
  loadOrders,
  ordersListLoadingSelector,
  ordersListSelector,
} from "redux/modules/common/orders/registry/ordersRegistry";

import TemplateBase from "components/UI/templates/TemplateBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";

import TabBarNotLinks from "elements/navigation/TabBar/TabBarNotLinks";
import graphIcon from "images/icons/navigation/graphIcon.svg";

import { ACCEPTED_ORDERS_STATUSES, COMPLETED_ORDERS_STATUSES, IN_WORK_ORDERS_STATUSES } from "./constants";
import OrdersRow from "./OrdersRow/OrdersRow";
import { IOrderInList } from "types/interfaces/Order";
import { Spinner } from "components/UI/Spinner/Spinner";
import OrdersTableHead from "./OrdersTableHead/OrdersTableHead";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";

function OrdersList() {
  const dispatch = useDispatch();

  const orders = useSelector(ordersListSelector);
  const loadingState = useSelector(ordersListLoadingSelector);

  const [tab, setTab] = React.useState("inWorkOrders");
  const [filters, setFilters] = React.useState({ limit: DEFAULT_LIMIT_ORDERS, statuses: IN_WORK_ORDERS_STATUSES });

  React.useEffect(() => {
    dispatch(loadOrders(filters));
  }, []);

  React.useEffect(() => {
    dispatch(loadOrders(filters));
  }, [filters]);

  const loadMoreHandler = () => {
    dispatch(addLoadOrders({ ...filters, offset: orders?.results.length }));
  };

  const tabClickHandler = (tab: unknown) => {
    if (tab === "acceptedOrders") {
      setTab("acceptedOrders");
      setFilters((prev) => ({ ...prev, statuses: ACCEPTED_ORDERS_STATUSES }));
    } else if (tab === "completedOrders") {
      setTab("completedOrders");
      setFilters((prev) => ({ ...prev, statuses: COMPLETED_ORDERS_STATUSES }));
    } else if (tab === "inWorkOrders") {
      setTab("inWorkOrders");
      setFilters((prev) => ({ ...prev, statuses: IN_WORK_ORDERS_STATUSES }));
    }
  };

  const hasNextPage = orders?.count > orders?.results.length;

  const changeFiltersHandler = (name: string, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <TemplateBase>
      <ListPageHeaderTemplate>
        <TabBarNotLinks
          activeId={tab}
          onClick={tabClickHandler}
          tabs={[
            { text: "В работе", id: "inWorkOrders" },
            { text: "Одобрены", id: "acceptedOrders" },
            { text: "Исполнены", id: "completedOrders" },
          ]}
        />
      </ListPageHeaderTemplate>
      <OrdersTableHead onChangeFilter={changeFiltersHandler} />
      {!loadingState && orders?.results.map((el: IOrderInList) => (
        <OrdersRow
          key={el.id}
          order={el}
        />
      ))}
      {(!loadingState && !orders?.results.length) && <EmptyPlaceholder text="Нет заказов" img={graphIcon} />}
      {loadingState && <Spinner />}
      <ShowMoreButton
        isExists={hasNextPage && !loadingState}
        handleAdd={loadMoreHandler}
        allCount={orders?.count}
        showedCount={orders?.results?.length}
      />
    </TemplateBase>
  );
}

export default OrdersList;
