import React, { useState } from "react";
import axios from "axios";
import { message, Upload } from "antd";

import ButtonBase from "../atoms/ButtonBase";

const ExcelProductsUpload = ({
  buttonText = "Выбрать файл для импорта",
  disabled,
  showUploadList,
  className,
  api,
  loadImportList = null,
  postCallback = null,
  id = null,
  noMessage = true,
  multiple = false,
  apiMethod = "post",
  excelValidation,
  isUploading = false,
  beginUploading
}) => {
  const [fileList, setFileList] = useState([]);

  const handleRequest = ({ onSuccess, onError, file }) => {
    if (fileCheck(file)) {
      const formData = new FormData();
      if (multiple) {
        formData.set("files", file);
      } else {
        formData.set("file", file);
      }

      if(beginUploading) beginUploading();

      axios[apiMethod](api, formData)
        .then((res) => {
          onSuccess();
          !noMessage && message.success(`${file.name} успешно загружен`);
          loadImportList?.(id);
          postCallback?.(res);
        })
        .catch((err) => {
          onError();
          message.error(`Ошибка загрузки`);
        });
    }
  };

  const fileCheck = (file) => {
    if (!excelValidation) return true;
    const ext = file.name.split(".").pop();
    if (ext === "xls" || ext === "xlsx" || ext === "xlsb" || ext === "gge" || ext === "xml" || ext === "kenml") {
      return true;
    } else {
      message.error("Неверное расширение файла. Допустимые расширения: xls, xlsx, xlsb, gge, xml, kenml.");
    }
  };

  return (
    <Upload
      name="file"
      customRequest={handleRequest}
      fileList={fileList}
      disabled={disabled || isUploading}
      className="ant-upload-no-style"
      showUploadList={showUploadList !== undefined ? showUploadList : true}
      multiple={multiple}
    >
      <ButtonBase className={className} isLoading={isUploading} primary>
        {buttonText}
      </ButtonBase>
    </Upload>
  );
};

export default React.memo(ExcelProductsUpload);
