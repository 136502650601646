import moment from 'moment'
import React from 'react'
import { personFullNameWithId } from 'types/personsTypes'
import WorkerItem from '../WorkersList/WorkerItem'
import styles from "./DoubleWorkers.module.scss"

type propsType = {
    date: string,
    label1?: string,
    worker1: personFullNameWithId,
    label2?: string,
    worker2: personFullNameWithId
}

function DoubleWorkers({
    date,
    worker1,
    worker2,
    label1 = "Выдал:",
    label2 = "Получил:"
}: propsType) {
    return (
        <div className={styles.doubleInfo}>
            <div className={styles.grid}>
                <div className={styles.moment}>{moment(date).format("DD/MM/YYYY")}</div>
                <div className={styles.accept}>{label1}</div>
                <WorkerItem worker={worker1} color="green" />
            </div>
            <div className={styles.grid}>
                <div className={styles.moment}>в {moment(date).format("HH:mm")}</div>
                <div className={styles.accept}>{label2}</div>
                <WorkerItem worker={worker2} />
            </div>
        </div>
    )
}

export default DoubleWorkers