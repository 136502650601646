import axios from 'axios';
import { createSelector } from 'reselect';

import { setTicketStatus } from './ticketsEstimate';

import { errorCatcher } from 'utils/errorCatcher';


const moduleName = 'shedules';
const LOAD_SHEDULES = `${moduleName}/LOAD_SHEDULES`;
const SET_SHEDULES = `${moduleName}/SET_SHEDULES`;
const SET_EXPENDITORIES = `${moduleName}/SET_EXPENDITORIES`;
const SET_SHEDULE = `${moduleName}/SET_SHEDULE`;
const SET_SHEDULE_BUILDING = `${moduleName}/SET_SHEDULE_BUILDING`;
const SET_SHEDULE_SECTION = `${moduleName}/SET_SHEDULE_SECTION`;
const SET_SHEDULE_SUBSECTION = `${moduleName}/SET_SHEDULE_SUBSECTION`;
const CHANGE_OPEN_BUILDINGS = `${moduleName}/CHANGE_OPEN_BUILDINGS`;
const CHANGE_OPEN_SECTION = `${moduleName}/CHANGE_OPEN_SECTION`;
const CHANGE_OPEN_SUBSECTION = `${moduleName}/CHANGE_OPEN_SUBSECTION`;


const initialState = {
  shedules: null,
  isLoading: true,
  loadingSave: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_SHEDULES:
      return {
        ...state,
        isLoading: true,
      };
    case SET_SHEDULES:
      return {
        ...state,
        shedules: payload,
        isLoading: false,
      };
    case SET_SHEDULE:
      return {
        ...state,
        shedule: {
          buildings: (state?.shedule?.buildings ? state?.shedule?.buildings : payload?.buildings).map(item => {
            return {
              ...item, 
              plan_intervals: payload?.plan_intervals
                ?.filter(plan => item.id === plan.current_building_id),
              fact_intervals: [
                ...(item?.fact_intervals || []), 
                ...payload?.fact_intervals?.filter(fact => item.id === fact.current_building_id)
              ]?.sort((a, b) => a.start_at < b.start_at ? -1 : 1)
            }
          }),
        },
        isLoading: false,
      };
    case SET_SHEDULE_BUILDING:
      return {
        ...state, 
        shedule: {
          buildings: state?.shedule?.buildings.map(item => {
            return {
              ...item, 
              sections: (item?.sections ? item.sections : payload?.sections)
                ?.filter(section => item.id === section.building_id && !item.parent_id)
                .map(item => {
                  return {
                    ...item, 
                    fact_intervals: payload?.fact_intervals
                      ?.filter(plan => item.id === plan.current_parentsection_id)
                      ?.sort((a, b) => a.start_at < b.start_at ? -1 : 1)
                  }
                }),
            }
          })
        }
      }
    case SET_SHEDULE_SECTION:
      return {
        ...state, 
        shedule: {
          buildings: state?.shedule?.buildings.map(item => {
            return {
              ...item, 
              sections: item?.sections.map(item => {
                return {
                  ...item, 
                  subsections: payload?.sections.filter(subsection => item.id === subsection.parent_id)
                    .map(item => {
                      return {
                        ...item,
                        fact_intervals: payload?.fact_intervals
                          ?.filter(plan => item.id === plan.current_section_id)
                          ?.sort((a, b) => a.start_at < b.start_at ? -1 : 1),
                      }
                    })
                }
              })
            }
          })
        }
      }
    case SET_SHEDULE_SUBSECTION:
      return {
        ...state, 
        shedule: {
          buildings: state?.shedule?.buildings.map(item => {
            return {
              ...item, 
              sections: item?.sections.map(item => {
                return {
                  ...item, 
                  subsections: item?.subsections.map(item => {
                    return {
                      ...item,
                      expenditures_work: payload?.expenditures.filter(expenditure => 
                        expenditure.current_section_id === item.id 
                        && expenditure.expenditure_type === 'work'
                      ).map(item => {
                        return {
                          ...item,
                          fact_intervals: payload?.fact_intervals
                            ?.filter(interval => interval.current_expenditure_id === item.id)
                            ?.sort((a, b) => a.start_at < b.start_at ? -1 : 1),
                        }
                      }),
                      expenditures_material: payload?.expenditures.filter(expenditure => 
                        expenditure.current_section_id === item.id 
                        && expenditure.expenditure_type === 'material'
                      )
                    }
                  })
                }
              })
            }
          })
        }
      }
    case SET_EXPENDITORIES:
      return {
        ...state,
        expenditure: payload,
      }
    case CHANGE_OPEN_BUILDINGS:
      return {
        ...state,
        shedule: {
          buildings: state?.shedule?.buildings.map(item => {
            return {
              ...item,
              isOpen: item.id === payload.id ? payload.val : item.isOpen
            }
          })
        }
      }
    case CHANGE_OPEN_SECTION:
      return {
        ...state,
        shedule: {
          buildings: state?.shedule?.buildings.map(item => {
            return {
              ...item,
              sections: item?.sections.map(item => {
                return {
                  ...item,
                  isOpen: item.id === payload.id ? payload.val : item.isOpen
                }    
              })
            }
          })
        }
      }
    case CHANGE_OPEN_SUBSECTION:
      return {
        ...state,
        shedule: {
          buildings: state?.shedule?.buildings.map(item => {
            return {
              ...item,
              sections: item?.sections.map(item => {
                return {
                  ...item,
                  subsections: item?.subsections?.map(item => {
                    return {
                      ...item,
                      isOpen: item.id === payload.id ? payload.val : item.isOpen
                    }
                  })
                }    
              })
            }
          })
        }
      }
    default:
      return state;
  }
};

export const stateSelector = state => state[moduleName];
export const shedulesSelector = createSelector(stateSelector, state => state.shedules);
export const sheduleSelector = createSelector(stateSelector, state => state.shedule);
export const shedulesLoadingSelector = createSelector(stateSelector, state => state.isLoading);
export const expenditureSelector = createSelector(stateSelector, state => state.expenditure);

export const loadShedule = (params) => {
  const config = {
    params: {
      ...params
    }
  };

  return dispatch => {
    dispatch({
      type: LOAD_SHEDULES,
    });
    axios.get(`/shedule/`, config).then(response => {
      if(params.subsection_id) {
        dispatch({
          type: SET_SHEDULE_SUBSECTION,
          payload: response.data,
        });
      } else if(params.section_id) {
        dispatch({
          type: SET_SHEDULE_SECTION,
          payload: response.data,
        });
      } else if(params.building_id && !params.getOnlyOne) {
        dispatch({
          type: SET_SHEDULE_BUILDING,
          payload: response.data,
        });
      } else {
        dispatch({
          type: SET_SHEDULE,
          payload: response.data,
        });
      }
    }).catch(errorCatcher);
  };
};

export const changeOpenBuildings = (val, id) => {
  return dispatch => {
    dispatch({
      type: CHANGE_OPEN_BUILDINGS,
      payload: {val, id}
    });
  }
}

export const changeOpenSection = (val, id) => {
  return dispatch => {
    dispatch({
      type: CHANGE_OPEN_SECTION,
      payload: {val, id}
    });
  }
}

export const changeOpenSubSection = (val, id) => {
  return dispatch => {
    dispatch({
      type: CHANGE_OPEN_SUBSECTION,
      payload: {val, id}
    });
  }
}

export const loadShedules = (id, params) => {
  const config = {
    params
  };

  return dispatch => {
    dispatch({
      type: LOAD_SHEDULES,
    });
    axios.get(`/building/${id}/estimate/shedules/`, config).then(response =>
      dispatch({
        type: SET_SHEDULES,
        payload: response.data,
      })
    ).catch(errorCatcher);
  };
};

export const loadExpenditure = (id, expenditureId) => {
  const config = {
    params: {
    }
  };

  return dispatch => {
    dispatch({
      type: LOAD_SHEDULES,
    });

    axios
      .get(`/building/${id}/estimate/expenditure/${expenditureId}/`, config)
      .then(response => {
        dispatch({
          type: SET_EXPENDITORIES,
          payload: response.data,
        })
        dispatch(setTicketStatus(response.data))
      }).catch(errorCatcher);
  };
};