import React from "react";
import cn from "classnames";
import moment from "moment";

import styles from "../../Intervals.module.scss";

const IntervalBlock = ({ item, fullCount, intervalsColor, onClickInterval }) => {
  const percents = React.useMemo(() => {
    return !isNaN(Math.round(item.sum_count / (fullCount / 100)))
      ? Math.round(item.sum_count / (fullCount / 100))
      : 0;
  }, [fullCount, item]);

  return (
    <div className={styles.outer}>
      <div
        className={cn(styles.intervalBlock, styles[intervalsColor])}
        title={item.sum_count}
        onClick={() =>
          onClickInterval(
            item.expenditure_id,
            moment(item.dates[0]).format("YYYY-MM-DD"),
            moment(item.dates[1]).format("YYYY-MM-DD")
          )
        }
      >
        <div className={styles.count}>
          {item.sum_count.length < 6 ? item.sum_count : item.sum_count?.slice(0, 5) + "..."}
        </div>
        <div className={styles.devider} />
        <div className={styles.date}>
          {moment(item.dates[0]).format("DD.MM-")}
          {moment(item.dates[1]).format("DD.MM")}
        </div>
      </div>
      <div className={styles.bar}>
        <div className={styles.percents} style={{ width: `${percents}%` }}></div>
      </div>
    </div>
  );
};

export default React.memo(IntervalBlock);
