import axios from "axios";
import { createSelector } from "reselect";
import { errorCatcher } from "../../../../../utils/errorCatcher";

const moduleName = "ordersRegistry";

const LOAD_ORDERS = `${moduleName}/LOAD_ORDERS`;
const SET_ORDERS = `${moduleName}/SET_ORDERS`;
const ADD_ORDERS = `${moduleName}/ADD_ORDERS`;

export const DEFAULT_LIMIT_ORDERS = 10;

const initialState = {
  orders: {
    count: 1,
    list: [],
    results: [],
  },
  isLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ORDERS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: payload,
        isLoading: false,
      };
    case ADD_ORDERS:
      return {
        ...state,
        orders: {
          count: action.payload.count,
          results: [...state.orders.results, ...action.payload.results],
        },
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const ordersListSelector = createSelector(stateSelector, (state) => state.orders);
export const ordersListLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);

export const loadOrders = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return ({ statuses, ...params }) => {
    if (ld_cancel) ld_cancel();

    const paramsForRequest = { ...params };
    if (statuses && !paramsForRequest.status) paramsForRequest.status = statuses.join(",");

    return (dispatch, getState) => {
      dispatch({ type: LOAD_ORDERS });
      axios
        .get(`/purchaser/orders/`, {
          params: paramsForRequest,
          cancelToken: new CancelToken((c) => (ld_cancel = c)),
        })
        .then((response) => {
          dispatch({ type: SET_ORDERS, payload: response.data });
        })
        .catch(errorCatcher);
    };
  };
})();

export const addLoadOrders = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return ({ statuses, ...params }) => {
    if (ge_cancel) ge_cancel();
    const config = {
      params,
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    if (statuses || !config.params.status) config.params.status = statuses.join(",");

    return (dispatch, getState) => {
      axios
        .get(`/purchaser/orders/`, config)
        .then((res) => dispatch({ type: ADD_ORDERS, payload: res.data }))
        .catch(errorCatcher);
    };
  };
})();
