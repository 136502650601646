import CheckFillRound from 'images/icons/CheckFillRound';
import ExclamationMarkInCircleIcon from 'images/icons/ExclamationMarkInCircleIcon';
import React from 'react'
import { ITicketJournalDelivery } from 'types/interfaces/Tickets'

interface IProps {
    ticket: ITicketJournalDelivery
}

const JournalAcceptedRoundCell: React.FC<IProps> = ({
    ticket
}) => {
    if (ticket.expenditure_id !== null && ticket.exists_accepted) return <CheckFillRound />;

    if (ticket.expenditure_id !== null && !ticket.exists_accepted)
      return <CheckFillRound color={"#00C4FF"} colorFill={"none"} />;
      
    if (!ticket.expenditure_id) return <ExclamationMarkInCircleIcon />;

    return null
}

export default JournalAcceptedRoundCell