import axios from "axios";
import { message } from "antd";
import { createSelector } from "reselect";

const moduleName = "users";
const LOAD_USERS = `${moduleName}/LOAD_USERS`;
const SET_USERS = `${moduleName}/SET_USERS`;
const LOAD_USER_DETAIL = `${moduleName}/LOAD_USER_DETAIL`;
const SET_USER_DETAIL = `${moduleName}/SET_USER_DETAIL`;
const SET_USER_ROLES = `${moduleName}/SET_USER_ROLES`;

const initialState = {
  users: null,
  isLoading: true,
  userDetail: null,
  userLoading: true,
  roles: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_USERS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_USERS:
      return {
        ...state,
        users: payload,
        isLoading: false,
      };
    case LOAD_USER_DETAIL:
      return {
        ...state,
        userLoading: true,
      };
    case SET_USER_DETAIL:
      return {
        ...state,
        userDetail: payload,
        userLoading: false,
      };
    case SET_USER_ROLES:
      return {
        ...state,
        roles: payload,
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const usersSelector = createSelector(stateSelector, (state) => state.users);
export const usersLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const userDetailSelector = createSelector(stateSelector, (state) => state.userDetail);
export const userDetailLoadingSelector = createSelector(stateSelector, (state) => state.userLoading);
export const usersRolesSelector = createSelector(stateSelector, (state) => state.roles);

export const loadUsers = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId, paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_USERS,
      });
      axios.get(`/admin/accounts/${accountId}/users/`, config).then((response) =>
        dispatch({
          type: SET_USERS,
          payload: response.data,
        })
      );
    };
  };
})();

export const loadUserDetail = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId, userId) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_USER_DETAIL,
      });
      axios.get(`/admin/accounts/${accountId}/users/${userId}/`, config).then((response) =>
        dispatch({
          type: SET_USER_DETAIL,
          payload: response.data,
        })
      );
    };
  };
})();

export const createUser = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId, body, callback) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_USER_DETAIL,
      });
      axios.post(`/admin/accounts/${accountId}/users/`, body, config).then(
        (response) => {
          callback?.(response.data);
          message.success("Сотрудник успешно создан");
        },
        (error) => {}
      );
    };
  };
})();

export const editUser = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId, userId, body, callback) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      dispatch({
        type: LOAD_USER_DETAIL,
      });
      axios.patch(`/admin/accounts/${accountId}/users/${userId}/`, body, config).then(
        (response) => {
          dispatch({
            type: SET_USER_DETAIL,
            payload: response.data,
          });
          message.success("Сохранено");
        },
        (error) => {}
      );
    };
  };
})();

export const loadRoles = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios.get(`/admin/accounts/${accountId}/roles/`, config).then((response) =>
        dispatch({
          type: SET_USER_ROLES,
          payload: response.data.results,
        })
      );
    };
  };
})();

export const setPassword = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId, userId, body) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios
        .post(`/admin/accounts/${accountId}/users/${userId}/set-password/`, body, config)
        .then((response) => message.success("Пароль успешно обновлен"));
    };
  };
})();
