import React, { useCallback, useEffect, useState } from "react";
import Axios from "axios";
import cn from "classnames";

import BlueLabel from "components/UI/atoms/BlueLabel";
import MatchingIcon, { MatchingStatusesEnum } from "../../../../../../UI/atoms/MatchingIcon/MatchingIcon";
import SpecificationModal from "../../../../../../UI/SpecificationModal/SpecificationModal";
import ProductInfo from "../../../JournalDelivery/components/ProductInfo";
import InfoPopup from "./components/InfoPopup";
import RelatedMaterial from "./components/RelatedMaterial";
import FormMenu from "./components/FormMenu";
import KCModalForm from "./components/KCModalForm/KCModalForm";

import { VIEW_MANUFACTURING_JOURNAL_PROGRESS_CREATE_KS23 } from "constants/permissions/manufacturingPermissions";

import { sliceTextByConstraint } from "../../../../../../../utils/sliceTextByConstraint";
import usePermission from "hooks/usePermission";
import { splitThousands } from "utils/splitThousands";

import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";

import styles from "./TableRow.module.scss";

const TableRow = ({ expenditure, objectId, loadEstimateTicket, onRowClick, year, month }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isKsOpens, setIsKsOpens] = useState(false);
  const [isOpenJustification, setIsOpenJustification] = useState(false);
  const [isOpenProductInfo, setIsOpenProductInfo] = useState(false);
  const [usingMaterials, setUsingMaterials] = useState([]);
  const [justificationData, setJustificationData] = useState(null);
  const [isJustificationLoading, setIsJustificationLoading] = useState(false);
  const [localAddedAmount, setLocalAddedAmount] = useState(0);
  const [localApprovalsCount, setLocalApprovalsCount] = useState(0);

  const loadEx = async (objectId, id) => {
    if (id && isOpen) {
      Axios.get(`/building/${objectId}/estimate/items/${expenditure.id}/stockusingmaterials/`).then((resp) => {
        setUsingMaterials(resp.data.results);
      });
    }
  };

  useEffect(() => {
    loadEx(objectId, expenditure.id);
  }, [expenditure]);

  const handleActive = () => {
    setIsOpen(!isOpen);
  };

  const loadFilteredEx = () => {
    Axios
      .get(`/building/${objectId}/estimate/items/${expenditure.id}/stockusingmaterials/?year=${year}&month=${month}`)
      .then((resp) =>
        setUsingMaterials(resp.data.results)
      );
  }

  useEffect(() => {
    if (!isOpen && !isKsOpens) return;
    loadFilteredEx();
  }, [isOpen, isKsOpens]);

  useEffect(() => {
    if (!isOpenJustification) return;
    setIsJustificationLoading(true);
    Axios.get(`/building/${objectId}/relates/expenditures/${expenditure.id}/`)
      .then((resp) => setJustificationData(resp.data.expenditures))
      .finally(() => setIsJustificationLoading(false));
  }, [isOpenJustification]);

  const haveCreateKs23Permission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_CREATE_KS23);

  const increaseLocalApprovals = useCallback(() => setLocalApprovalsCount((prev) => prev + 1), []);

  return (
    <div
      className={cn(styles.tableRow, {
        [styles.active]: isOpen,
      })}
    >
      <div className={styles.header}>
        <div
          className={cn(styles.notProcess, {
            [styles.buttonOpen]: expenditure?.count_fact_work_materials > 0,
            [styles.approved]:
              expenditure.count_fact_work_materials > 0 &&
              expenditure.count_fact_work_materials === expenditure.count_fact_work_materials_approved + localApprovalsCount,
            [styles.process]: expenditure.count_fact_work_materials_approved + localApprovalsCount > 0,
          })}
          onClick={() => handleActive()}
        >
          {expenditure.count_fact_work_materials - (expenditure.count_fact_work_materials_approved + localApprovalsCount) > 0 && (
            <div className={styles.count}>
              {expenditure.count_fact_work_materials - (expenditure.count_fact_work_materials_approved + localApprovalsCount)}
            </div>
          )}
          <ArrowSmallUpGrey color={"#000"} rotate={isOpen ? 0 : 180} />
        </div>
        <div className={cn(styles.rowItem, styles.nameBlock)} onClick={() => onRowClick && onRowClick(expenditure)}>
          <div className={styles.nameContainer}>
            <div className={styles.name}>{expenditure.name}</div>
            {expenditure.justification && (
              <BlueLabel
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenJustification(true);
                }}
                className={cn(styles.blueLabel, {
                  [styles.blueLabelNonActive]: !isOpen,
                })}
              >
                {sliceTextByConstraint(expenditure.justification, 30)}
              </BlueLabel>
            )}
          </div>
          <div>
            <MatchingIcon
              status={MatchingStatusesEnum.auto}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpenProductInfo({ id: true });
              }}
              className={styles.checkFile}
            />
          </div>
        </div>
        <div className={cn(styles.rowItem, styles.doneRow)}>
          {expenditure?.work?.count_accepted_work || 0}&nbsp;&nbsp;({sliceTextByConstraint(expenditure.measure, 8)})
          <InfoPopup data={expenditure} objectId={objectId} />
        </div>
        <div className={cn(styles.rowItem, styles.righted)}>
          {splitThousands(+expenditure.sum_using_amount + localAddedAmount || 0, false, false)}
        </div>
        <div className={cn(styles.rowItem, styles.righted)}>
          {splitThousands(expenditure.accepted_amount_with_using_material + localAddedAmount, false, false)}
        </div>
        <div className={cn(styles.rowItem, styles.checkBlock)}>
          <div className={styles.formBlock}>
            <KCModalForm
              name="KC2"
              type="ks2"
              data={expenditure}
              objectId={objectId}
              loadEstimateTicket={loadEstimateTicket}
            />
            <KCModalForm
              name="KC3"
              type="ks3"
              data={expenditure}
              objectId={objectId}
              loadEstimateTicket={loadEstimateTicket}
            />
          </div>
          {haveCreateKs23Permission && (
            <FormMenu
              expenditure={expenditure}
              onOpen={setIsKsOpens}
              year={year}
              month={month}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className={styles.content}>
          <div className={styles.headerMaterial}>
            <div className={cn(styles.headerItem, styles.name)}>Наименование материала</div>
            <div className={cn(styles.headerItem, styles.measure)}>Ед.изм.</div>
            <div className={cn(styles.headerItem, styles.centeredContent)}>Выдано</div>
            <div className={cn(styles.headerItem, styles.centeredContent)}>Принято</div>
            <div className={cn(styles.headerItem, styles.centeredContent)}>ФИО</div>
            <div className={cn(styles.headerItem, styles.checkBlock, styles.centeredContent)}>Стоимость, ₽</div>
          </div>
          {(usingMaterials || []).map((item) => (
            <RelatedMaterial
              key={item.id}
              data={item}
              objectId={objectId}
              loadEx={() => loadEx(objectId, expenditure.id)}
              loadFilteredEx={loadFilteredEx}
              expenditureId={expenditure.id}
              loadEstimateTicket={loadEstimateTicket}
              setLocalAddedAmount={setLocalAddedAmount}
              increaseLocalApprovals={increaseLocalApprovals}
            />
          ))}
        </div>
      )}
      <SpecificationModal
        isOpen={isOpenJustification}
        onClose={() => setIsOpenJustification(false)}
        subMaterials={justificationData}
        name={expenditure?.name}
        isLoading={isJustificationLoading}
      />
      <ProductInfo
        isOpen={isOpenProductInfo}
        closeModal={() => setIsOpenProductInfo(false)}
        objectId={objectId}
        chosenMatchingProduct={expenditure}
        directlyCantEdit
      />
    </div>
  );
};

export default React.memo(TableRow);
