import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";

import { loadExpenditure } from "redux/modules/common/building/shedules";

import { IRouterParamsWithObjectId } from "types/routerTypes";
import { IKs23ModalProps, IKsObject } from './types';

import { errorCatcher } from "utils/errorCatcher";
import downloadFile from "utils/download";


export type useKs23Type = Pick<IKs23ModalProps, "expenditureId" | "buildingId" | "isPrefetch" | "year" | "month" | "type" | "onClose">;

const ksDefaultState = {
  rows: [],
};

export const useKs23 = ({ expenditureId, buildingId, isPrefetch, year, month, type, onClose }: useKs23Type) => {
  const dispatch = useDispatch();

  const { objectId } = useParams<IRouterParamsWithObjectId>();

  const actualObjectId = buildingId || objectId;

  const [ks, setKs] = useState<IKsObject>(ksDefaultState);

  useEffect(() => {
    if (!actualObjectId || !expenditureId || !isPrefetch) return;
    axios
      .get(`/building/${actualObjectId}/estimate/tickets/${expenditureId}/${type}/`, {
        params: {
          year,
          month,
        },
      })
      .then(({ data }) => setKs(data));
  }, [actualObjectId, expenditureId, year, month, isPrefetch, type]);

  const handleDownloadFile = useCallback(() => {
    downloadFile(
      `${axios.defaults.baseURL}/building/${actualObjectId}/estimate/reports/${expenditureId}/${type}/?year=${year}&month=${month}`,
      `${type || "ks"}.xlsx`
    )
    .then(() => dispatch(loadExpenditure(objectId, expenditureId)))
    .catch(errorCatcher);
  }, [actualObjectId, expenditureId, type]);

  const handleClose = useCallback(() => {
    onClose?.()
    setKs(ksDefaultState);
  }, [onClose]);

  return {
    ks,
    handleDownloadFile,
    handleClose
  };
};
