import React from 'react'
import styles from "./ExpandableFileInput.module.scss"
import Expandable from "../../atoms/Expandable/Expandable"
import downloadFileByUrl from '../../../../utils/downloadFileByUrl'
import InputFiles from '../../atoms/InputFiles/InputFiles'

type propsType = {
    files: any[];
    setFiles?: (file: any) => void;
    onUploadFileDirectly?: (file: any) => void;
    onRemoveFileDirectly?: (fileId: number) => void;
    canExtractFiles?: boolean;
    canUploadFiles?: boolean;
    canDeleteFiles?: boolean;
    title?: string
}

function ExpandableFileInput({
    files,
    setFiles,
    onUploadFileDirectly,
    onRemoveFileDirectly,
    canExtractFiles = true,
    canUploadFiles = true,
    canDeleteFiles = true,
    title = "Список вложений:"
}: propsType) {
    const getFilesHandler = (e: React.MouseEvent) => {
        e.stopPropagation()
        files?.forEach(el => {
            downloadFileByUrl(el.file, el.name || el.originalname)
        })
    }

    return (//@ts-ignore
        <Expandable
            className={styles.filesContainer}
            additionalHeadline={<div className={styles.filesHead}>
                <span>{title} {files?.length}</span>
                {canExtractFiles && !!files.length && <div onClick={getFilesHandler}>Выгрузить</div>}
            </div>}
        >{/* @ts-ignore */}
            <InputFiles
                value={files}
                setValue={setFiles}
                uploadFilesDirectly={onUploadFileDirectly}
                removeFileDirectly={onRemoveFileDirectly}
                permissions={{
                    addFiles: canUploadFiles,
                    deleteFiles: canDeleteFiles
                }}
            />
        </Expandable>
    )
}

export default React.memo(ExpandableFileInput)