import React from "react";

export const DeleteButton = React.memo((props) => {
  return (
    <button
      {...props}
      type="button"
      onClick={props.onClick || null}
      className={`btn-delete ${props.header ? "table-button_header" : ""}`}
    >
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9851 14.208L7.7711 10.994L4.5571 14.208C4.37137 14.3939 4.15085 14.5412 3.90815 14.6418C3.66545 14.7424 3.40531 14.7941 3.1426 14.7941C2.87988 14.7941 2.61975 14.7424 2.37704 14.6418C2.13434 14.5412 1.91382 14.3939 1.7281 14.208L1.0851 13.565C0.899293 13.3793 0.751902 13.1588 0.651342 12.9161C0.550782 12.6734 0.499023 12.4133 0.499023 12.1505C0.499023 11.8878 0.550782 11.6277 0.651342 11.385C0.751902 11.1423 0.899293 10.9218 1.0851 10.736L4.3001 7.52305L1.0861 4.30905C0.900293 4.12332 0.752902 3.90281 0.652342 3.6601C0.551782 3.4174 0.500023 3.15726 0.500023 2.89455C0.500023 2.63184 0.551782 2.3717 0.652342 2.129C0.752902 1.88629 0.900293 1.66578 1.0861 1.48005L1.7291 0.837049C1.91482 0.651246 2.13534 0.503855 2.37804 0.403295C2.62075 0.302735 2.88088 0.250977 3.1436 0.250977C3.40631 0.250977 3.66645 0.302735 3.90915 0.403295C4.15185 0.503855 4.37237 0.651246 4.5581 0.837049L7.7711 4.05205L10.9851 0.83805C11.1708 0.652247 11.3913 0.504855 11.634 0.404295C11.8767 0.303735 12.1369 0.251976 12.3996 0.251976C12.6623 0.251976 12.9224 0.303735 13.1652 0.404295C13.4079 0.504855 13.6284 0.652247 13.8141 0.83805L14.4571 1.48105C14.6429 1.66678 14.7903 1.88729 14.8909 2.13C14.9914 2.3727 15.0432 2.63284 15.0432 2.89555C15.0432 3.15826 14.9914 3.4184 14.8909 3.6611C14.7903 3.90381 14.6429 4.12432 14.4571 4.31005L11.2421 7.52305L14.4561 10.737C14.6419 10.9228 14.7893 11.1433 14.8899 11.386C14.9904 11.6287 15.0422 11.8888 15.0422 12.1515C15.0422 12.4143 14.9904 12.6744 14.8899 12.9171C14.7893 13.1598 14.6419 13.3803 14.4561 13.566L13.8131 14.209C13.6274 14.3949 13.4069 14.5422 13.1642 14.6428C12.9214 14.7434 12.6613 14.7951 12.3986 14.7951C12.1359 14.7951 11.8757 14.7434 11.633 14.6428C11.3903 14.5422 11.1698 14.3949 10.9841 14.209L10.9851 14.208Z"
          fill="#C4C4C4"
        />
      </svg>
    </button>
  );
});
