import { compose } from "redux";
import axios from "axios";

import { errorCatcher } from "../../../../../../../utils/errorCatcher";

import { setKsesAction, setKsesLoadingAction } from "../actions";

export const getKses = (filters) => async (dispatch) => {
  compose(dispatch, setKsesLoadingAction)(true);

  const response = await axios.get("/building/work/approve_dates/", { params: filters }).catch(errorCatcher);
  if (response) compose(dispatch, setKsesAction)(response.data);
  compose(dispatch, setKsesLoadingAction)(false);
}