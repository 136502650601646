import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";

import Icon from "components/UI/Icon";
import arrowDownSelect from "images/icons/arrowDownSelect.svg";

import styles from "./OldSelect.module.scss";

const OldSelect = ({
                     style = "",
                     id = "",
                     options = [],
                     defaultValue,
                     name,
                     placeholder = "Выберите",
                     selectedAction,
                     input,
                     onClick,
                     className,
                     optionsBlockClassName,
                     label,
                     disabled = false,
                     meta,
                     icon = arrowDownSelect
                   }) => {
  // const { error, touched, valid } = meta;
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options.find((item) => input && item.name === input.value)
  );

  useEffect(() => {
    if (defaultValue || input?.value)
      setSelectedOption(
        options.find((item) => {
          if (defaultValue) return item.id == defaultValue;
          else if (input?.value[0]?.id) return item.name === input?.value[0]?.id;
          else if (input?.value) return item.name === input?.value;
        })
      );
  }, [defaultValue, options]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleSelect = (item) => {
    input && input.onChange(item.name);
    setSelectedOption(item);

    if (onClick) onClick(item.id);
  };

  const setToggle = () => {
    setIsOpen(prev => !prev);
  };
  return (
    <>
      {label && <label>{label}</label>}
      <div
        className={cn(styles.select, className, {
          [styles.isOpen]: isOpen && !disabled,
          [styles.disabled]: disabled
        })}
        onClick={setToggle}
        ref={wrapperRef}
      >
        <span className={cn(styles.title)}>
          {selectedOption ? selectedOption.label : placeholder}
        </span>
        <Icon
          icon={icon}
          className={cn(styles.arrow, { [styles.arrowReverse]: isOpen && !disabled })}
        />
        {!disabled && (
          <div
            id={id}
            className={cn(styles.optionsBlock, optionsBlockClassName, {
              [styles.isOpen]: isOpen
            })}
          >
            {options &&
              options.length > 0 &&
              options.map((item) => (
                <div key={item.id} className={styles.option} onClick={() => handleSelect(item)} title={item.label}>
                  {item.label}
                </div>
              ))}
            {(!options || (options && options.length === 0)) && (
              <div className={styles.option}>Нет данных</div>
            )}
          </div>
        )}
      </div>
      {meta?.touched && meta?.error && <div className={cn(styles.errorMessage)}>{meta.error}</div>}
    </>
  );
};

export default OldSelect;
