import React from "react";

import getShortFullName from "../../../../utils/getShortFullName";

import Avatar from "../../atoms/Avatar/Avatar";

import styles from "./Person.module.scss";

const Person = ({ cell }) => {
  return (
    <div className={styles.person}>
      <Avatar className={styles.avatar} person={cell} />
      {getShortFullName(cell)}
    </div>
  );
};

export default React.memo(Person);
