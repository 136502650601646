import {
  SET_STOCK_USING_MATERIALS_PRICE_LIST,
  SET_IS_STOCK_USING_MATERIALS_PRICE_UPDATE_SUCCESS,
} from "./reducer";

import { IStockUsingMaterialsResponse } from "./types";

export const setIsStockUsingMaterialsPriceUpdateSuccess = (
  payload: boolean
) => ({
  type: SET_IS_STOCK_USING_MATERIALS_PRICE_UPDATE_SUCCESS,
  payload,
});

export const setStockUsingMaterialsPriceList = (
  payload: IStockUsingMaterialsResponse
) => ({
  type: SET_STOCK_USING_MATERIALS_PRICE_LIST,
  payload,
});