import React, { useCallback, useMemo, useState } from "react";
import { message } from "antd";

import downloadFile from "../../../../utils/download";

import FileRow from "../../atoms/FileRow/FileRow";
import ConfirmationModal from "../ConfirmationModal";

const serializeFileName = (file) => ({ ...file, name: file.originalname || file.name || file.file.name });

const FilesList = ({ files, removeFile, isDisabled, maxDisplayingCount, mustConfirmDownload, canExport = true }) => {
  const [filesLoading, setFilesLoading] = useState({});

  const [downloadedFile, setDownloadedFile] = useState(null);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const closeConfirmationModal = useCallback(() => setIsOpenConfirmationModal(false), []);

  const handleDownloadFile = useCallback(async (file) => {
    const fileUrl =
      file.link || (typeof file.file === "object" && file.file.link) || (typeof file.file === "string" && file.file);

    if (!fileUrl) {
      message.error("Невозможно выгрузить несохраненные файл");
      return;
    }

    setFilesLoading((prevState) => ({ ...prevState, [file.id]: true }));
    await downloadFile(fileUrl, file.name).catch(() => {
      message.error("Невозможно выгрузить файл");
    });
    setFilesLoading((prevState) => ({ ...prevState, [file.id]: false }));
  }, []);

  const slicedFilesByMaxDisplayingCount = useMemo(() => {
    if (!maxDisplayingCount) return [];
    return files.slice(0, maxDisplayingCount);
  }, [maxDisplayingCount, files]);

  const openConfirmDownloadModal = useCallback((downloadedFile) => {
    setDownloadedFile(downloadedFile);
    setIsOpenConfirmationModal(true);
  }, []);

  const onConfirmDownload = useCallback(() => {
    handleDownloadFile(downloadedFile);
    setDownloadedFile(null);
    closeConfirmationModal();
  }, [downloadedFile, closeConfirmationModal]);

  const downloadIfCan = useCallback(
    (file) => {
      if (!canExport) return;
      mustConfirmDownload ? openConfirmDownloadModal(file) : handleDownloadFile(file);
    },
    [canExport, handleDownloadFile, mustConfirmDownload, openConfirmDownloadModal]
  );

  return (
    <>
      {(maxDisplayingCount ? slicedFilesByMaxDisplayingCount : files).map((file) => (
        <FileRow
          file={serializeFileName(file)}
          download={downloadIfCan}
          isLoading={filesLoading[file.id]}
          remove={removeFile}
          canRemove={!isDisabled}
          key={file.id}
        />
      ))}
      <ConfirmationModal isOpen={isOpenConfirmationModal} onClose={closeConfirmationModal} action={onConfirmDownload}>
        Вы действительно хотите выгрузить файл?
      </ConfirmationModal>
    </>
  );
};

export default React.memo(FilesList);
