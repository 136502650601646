import axios from "axios";
import { compose } from "redux";

import { errorCatcher } from "../../../../../utils/errorCatcher";

import { deletePackingItemFileAction } from "../actions";

export const deletePackingItemFile = (packingItemId, fileId) => (dispatch) => {
  axios
    .delete(`/purchaser/packinglists/packingitems/${packingItemId}/files/${fileId}/`)
    .then(() => compose(dispatch, deletePackingItemFileAction)({ itemId: packingItemId, fileId }))
    .catch(errorCatcher);
};
