import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { 
  isServiceActsListLoadingSelector, 
  serviceActsListSelector 
} from "redux/modules/common/building/documents/serviceActs/selectors";
import { getServiceActsList } from "redux/modules/common/building/documents/serviceActs/thunks";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import { DEFAULT_LIMIT_DOCUMENT } from "redux/modules/common/documents/constants";

import { Spinner } from "components/UI/Spinner/Spinner";
import ActListsTableHeader from "./ActListsTableHeader/ActListsTableHeader";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import ActListsRow from "./ActListsRow/ActListsRow";

import { IIdAndName } from "types/idAndName";
import { IServiceAct } from "types/interfaces/ServiceAct";

import ActsIcon from "images/icons/documentsTab/ActsIcon";

import { statusActsArray } from "utils/constant";
import { setServiceActsList } from "redux/modules/common/building/documents/serviceActs/actions";


const ActLists = () => {
  const dispatch = useDispatch();
  const actsList = useSelector(serviceActsListSelector);

  const isLoading = useSelector(isServiceActsListLoadingSelector);
  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);

  const [filters, setFilters] = useState({ limit: DEFAULT_LIMIT_DOCUMENT, offset: 0 });

  useEffect(() => {
    dispatch(getServiceActsList(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    return () => {
      dispatch(setServiceActsList(null));
    }
  }, []);

  const formattedObjects = useMemo(() => {
    return breadcrumbsProjects.results.map((el: IIdAndName) => ({ id: el.id, name: el.id, label: el.name }));
  }, [breadcrumbsProjects]);

  const hasNextPage = useMemo(() => {
    if (actsList?.results?.length) {
      return actsList?.count > actsList?.results?.length;
    }

    return false;
  }, [actsList]);

  const changeFilters = (name: string, value: string) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const loadMore = () => {
    dispatch(
      getServiceActsList({
        ...filters,
        offset: actsList?.results?.length,
      })
    );
  };

  return (
    <div>
      <ActListsTableHeader
        onChangeFilter={changeFilters}
        statusBillsArray={statusActsArray}
        formattedObjects={formattedObjects}
      />
      {(Array.isArray(actsList?.results) && !isLoading) && (
        actsList?.results.map((item: IServiceAct) => (
          <ActListsRow key={item.id} listItem={item} />
        ))
      )}
      {(!isLoading && !actsList?.results.length) && (
        <EmptyPlaceholder 
          text="Нет Актов" 
          svgJsx={<ActsIcon />} 
        />
      )}
      {isLoading && <Spinner />}
      <ShowMoreButton
        isExists={hasNextPage && !isLoading}
        showedCount={actsList?.results?.length}
        allCount={actsList?.count}
        handleAdd={loadMore}
      />
    </div>
  );
};

export default React.memo(ActLists);
