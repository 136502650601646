import {createSelector} from "reselect";
import axios from "axios";
import {setDocuments} from "../../documents/documents";

const moduleName = 'visibilityZones';

const GET_VISIBILITY_ZONES = `${moduleName}/GET_VISIBILITY_ZONES`;
const SET_VISIBILITY_ZONES = `${moduleName}/SET_VISIBILITY_ZONES`;
const SET_VISIBILITY_ZONES_ENABLED = `${moduleName}/SET_VISIBILITY_ZONES_ENABLED`;

const initialState = {
  visibilityZonesEnabled: false,
  visibilityZonesList: null,
  visibilityZonesLoading: true,
};

/*
  Reducer
*/

export default (state = initialState, action) => {
  const {payload, type} = action;

  switch (type) {

    case GET_VISIBILITY_ZONES:
      return {
        ...state,
        visibilityZonesLoading: true
      };

    case SET_VISIBILITY_ZONES_ENABLED:
      return{
        ...state,
        visibilityZonesEnabled: payload
      };

    case SET_VISIBILITY_ZONES:
      return {
        ...state,
        visibilityZonesList: payload.visible_users,
        visibilityZonesEnabled: payload.limited_visibility,
        visibilityZonesLoading: false
      };

    default: {
      return state;
    }
  }
}

export const stateSelector = state => state[moduleName];
export const visibilityZonesLoadingSelector = createSelector(stateSelector, state => state.visibilityZonesLoading);
export const visibilityZonesListSelector = createSelector(stateSelector, state => state.visibilityZonesList);
export const visibilityZonesEnabledSelector = createSelector(stateSelector, state => state.visibilityZonesEnabled);

const setVisibilityZones = data => ({
  type: SET_VISIBILITY_ZONES,
  payload: data
});

export const setVisibilityZonesEnabled = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, value) => {
    if (ld_cancel) ld_cancel();

    const body = {
      limited_visibility: value
    };
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      })
    };


    return dispatch => {
      dispatch({
        type: SET_VISIBILITY_ZONES_ENABLED,
        payload: value
      });
      axios
        .patch(`/users/${id}/visibility/`, body, config)
        .then(
          response => dispatch({
            type: SET_VISIBILITY_ZONES_ENABLED,
            payload: response.data.limited_visibility
          }),
          error => dispatch({
            type: SET_VISIBILITY_ZONES_ENABLED,
            payload: false
          }),
        );
    }
  }
})();

export const setVisibilityZonesUsers = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, data) => {
    if (ld_cancel) ld_cancel();

    const body = {
      visible_users_ids: data
    };
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      })
    };


    return dispatch => {
      axios
        .patch(`/users/${id}/visibility/`, body, config)
        .then(
          response => dispatch({
            type: SET_VISIBILITY_ZONES,
            payload: response.data
          }),
        );
    }
  }
})();

export const getVisibilityZones = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      })
    };


    return dispatch => {
      dispatch({
        type: GET_VISIBILITY_ZONES
      });
      axios
        .get(`/users/${id}/visibility/`, config)
        .then(
          response => dispatch(setVisibilityZones(response.data)),
        );
    }
  }
})();