import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { rolesSelector } from "redux/modules/common/userRedux";

import CustomInput from "../UI/CustomInput/CustomInput";
import { MiniModal } from "../UI/minimodal/MiniModal";
import ButtonBase from "components/UI/atoms/ButtonBase";
import Select from "components/UI/atoms/Select";

import InputMask from "react-input-mask";

import { NUMBER_PLACEHOLDER } from "constants/placeholders";

import useFormFocus from "utils/hooks/useFormFocus";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlineCancel } from "react-icons/md";
import { RiCloseFill } from "react-icons/ri";

import styles from "./InputData.module.scss";

export const InputData = ({ isEditable = true, innerInput, setInnerInput, setFormValid }) => {
  const roles = useSelector(rolesSelector);

  const [modal, setModal] = useState(false);

  const onChangeRole = (selectedRoleId) => {
    const foundRole = roles.find((role) => role.id === selectedRoleId);
    setInnerInput({ ...innerInput, roles: [foundRole] });
    setModal(false);
  };

  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [lastNameDirty, setLastNameDirty] = useState(false);
  const [middleNameDirty, setMiddleNameDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [numberDirty, setNumberDirty] = useState(false);

  const [checkIcon, setCheckIcon] = useState(false);
  const [checkIconName, setCheckIconName] = useState(false);
  const [checkIconMiddleName, setCheckIconMiddleName] = useState(false);
  const [checkIconNumber, setCheckIconNumer] = useState(false);
  const [checkIconEmail, setCheckIconEmail] = useState(false);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [numberError, setNumberError] = useState("");

  useEffect(() => {
    setFormValid(!firstNameError && !lastNameError && !middleNameError && !numberError && !emailError);
  }, [emailError, firstNameError, lastNameError, middleNameError, numberError, setFormValid]);

  const firstNameHandler = (event) => {
    setInnerInput({ ...innerInput, first_name: event.target.value });
    const regex = /[\wа-я]+/gi;
    if (!event.target.value.match(regex)) {
      setFirstNameError("Фамилия не может быть пустой либо содержать некоректные символы");
      setCheckIcon(false);
    } else {
      setFirstNameError("");
      setCheckIcon(true);
    }
  };

  const lastNameHandler = (event) => {
    setInnerInput({ ...innerInput, last_name: event.target.value });
    const regex = /[\wа-я]+/gi;
    if (!event.target.value.match(regex)) {
      setLastNameError("Имя не может быть пустым либо содержать некоректные символы");
      setCheckIconName(false);
    } else {
      setLastNameError("");
      setCheckIconName(true);
    }
  };

  const middleNameHandler = (event) => {
    setInnerInput({ ...innerInput, middle_name: event.target.value });
    const regex = /[\wа-я]+/gi;
    if (!event.target.value.match(regex) && event.target.value !== "") {
      setMiddleNameError("Отчество не может быть пустым либо содержать некоректные символы");
      setCheckIconMiddleName(false);
    } else {
      setMiddleNameError("");
      setCheckIconMiddleName(true);
    }
  };

  const numberHandler = (event) => {
    setInnerInput({ ...innerInput, phone_number: event.target.value });
    const regex = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
    if (
      !event.target.value.match(regex) &&
      String(event.target.value) !== NUMBER_PLACEHOLDER &&
      String(event.target.value) !== ""
    ) {
      setNumberError("Номер введене неккоректно");
      setCheckIconNumer(false);
    } else {
      setNumberError("");
      setCheckIconNumer(true);
    }
  };

  const emailHandler = (event) => {
    setInnerInput({ ...innerInput, email: event.target.value });
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!String(event.target.value).toLowerCase().match(regex) && String(event.target.value) !== "") {
      setEmailError("Email введен неккоректно");
      setCheckIconEmail(false);
    } else {
      setEmailError("");
      setCheckIconEmail(true);
    }
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "first_name":
        setFirstNameDirty(true);
        break;
      case "last_name":
        setLastNameDirty(true);
        break;
      case "middle_name":
        setMiddleNameDirty(true);
        break;
      case "email":
        setEmailDirty(true);
        break;
      case "number":
        setNumberDirty(true);
        break;
      default:
    }
  };

  const inputRootRef = useFormFocus();

  const rolesOptions = useMemo(() => (roles ? roles.map(({ id, name }) => ({ id, name })) : []), [roles]);

  return (
    <div>
      <div className={styles.inputForm} ref={inputRootRef}>
        <div style={{ flexBasis: "45%" }}>
          <MiniModal visible={modal} setVisible={setModal}>
            <div className={styles.flexAction}>
              <div style={{ fontSize: "22px", fontWeight: "500", color: "#000" }}>Подтвердите действие</div>
              <RiCloseFill size="25" style={{ cursor: "pointer", color: "#707070" }} onClick={() => setModal(!modal)} />
            </div>
            <div>
              <div style={{ padding: "0 20px", marginBottom: "17px" }}>
                <div className={styles.text}>Назначить пользователю стандартные доступы выбранной должности?</div>
              </div>
              <div
                style={{
                  borderTop: "1px solid #d3d3d3",
                  padding: "10px 19px 11px 0px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonBase style={{ marginRight: "12.5px" }} onClick={() => setModal(!modal)} medium secondary>
                  Отменить
                </ButtonBase>
                <ButtonBase onClick={() => setModal(!modal)} medium primary>
                  Да
                </ButtonBase>
              </div>
            </div>
          </MiniModal>
          <div className={styles.flexInput}>
            <label style={{ marginBottom: "7px" }}>*Фамилия</label>
            {lastNameDirty && lastNameError && <MdOutlineCancel className="badIcon" />}
            {checkIconName && <AiOutlineCheckCircle className="goodIcon" />}
            <CustomInput
              readOnly={!isEditable}
              onBlur={blurHandler}
              name="last_name"
              type="text"
              placeholder="Введите фамилию"
              value={innerInput.last_name}
              onChange={lastNameHandler}
            />
          </div>
          <div className={styles.flexInput}>
            <label style={{ marginBottom: "7px" }}>*Имя</label>
            {firstNameDirty && firstNameError && <MdOutlineCancel className="badIcon" />}
            {checkIcon && <AiOutlineCheckCircle className="goodIcon" />}
            <CustomInput
              readOnly={!isEditable}
              onBlur={blurHandler}
              name="first_name"
              type="text"
              placeholder="Введите имя"
              value={innerInput.first_name}
              onChange={firstNameHandler}
            />
          </div>
          <div className={styles.flexInput}>
            <label style={{ marginBottom: "7px" }}>Отчество</label>
            {middleNameDirty && middleNameError && <MdOutlineCancel className="badIcon" />}
            {checkIconMiddleName && <AiOutlineCheckCircle className="goodIcon" />}
            <CustomInput
              readOnly={!isEditable}
              onBlur={blurHandler}
              name="middle_name"
              type="text"
              placeholder="Введите отчество"
              value={innerInput.middle_name}
              onChange={middleNameHandler}
            />
          </div>
        </div>
        <div style={{ flexBasis: "45%" }}>
          <div className={styles.flexInput}>
            <label style={{ marginBottom: "7px" }}>Номер телефона</label>
            {numberDirty && numberError && <MdOutlineCancel className="badIcon" />}
            {checkIconNumber && <AiOutlineCheckCircle className="goodIcon" />}
            <InputMask
              className="cleave-input"
              mask="+7 (999) 999-99-99"
              placeholder={NUMBER_PLACEHOLDER}
              readOnly={!isEditable}
              name="number"
              onBlur={blurHandler}
              value={innerInput.phone_number}
              onChange={numberHandler}
              style={{ fontSize: "1rem" }}
            />
          </div>
          <div className={styles.flexInput}>
            <label style={{ marginBottom: "7px" }}>E-mail</label>
            {emailDirty && emailError && <MdOutlineCancel className="badIcon" />}
            {checkIconEmail && <AiOutlineCheckCircle className="goodIcon" />}
            <CustomInput
              name="email"
              onBlur={blurHandler}
              readOnly={!isEditable}
              placeholder="Введите e-mail"
              value={innerInput.email}
              onChange={emailHandler}
            />
          </div>
          <div className={styles.flexInput}>
            {isAllModulesAllowed() && (
              <Select
                name="roles"
                selectClassName={styles.border}
                onChange={onChangeRole}
                label="Должность"
                options={rolesOptions}
                value={innerInput.roles[0]?.id}
                labelColor="baseColor"
                disabled={!isEditable}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
