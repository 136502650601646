import React, { useContext } from "react";
import { transformDigitToFinancial } from "utils/transformDigitToFinancial";
import { RUBLE } from "utils/constant";
import styles from "./index.module.scss";
import { getUnifiedMeasurePrices } from "utils/helpers";
import { formatNumberWithMeasure } from "../../../../../../../../utils/formatNumberWithMeasure";
import { PlanFactMeasureContext } from "../../../PlanFact";

const SectionRow = ({ data }) => {
  const { measure } = useContext(PlanFactMeasureContext);

  const planPrice = formatNumberWithMeasure(data?.plan, measure);
  const factPrice = formatNumberWithMeasure(data?.fact, measure);
  const balancePrice = formatNumberWithMeasure(data?.balance, measure);

  if (!data?.expenditure_count) return null;

  return (
    <div className={styles.container}>
      <div className={styles.dot} />
      <div className={styles.name}>{data?.name}</div>
      <div className={styles.valueName}>План</div>
      <div className={styles.value}>{planPrice}</div>
      <div className={styles.valueName}>Факт</div>
      <div className={styles.value}>{factPrice}</div>
      <div className={styles.divider} />
      <div className={styles.objectCount}>
        Объектов {data?.expenditure_count}
      </div>
      <div className={styles.valueName}>Бюджет</div>
      <div className={styles.balance}>{balancePrice}</div>
    </div>
  );
};

export default SectionRow;
