import React from "react";

import { sliceTextByConstraint } from "../../../../../../utils/sliceTextByConstraint";

import styles from "../../Expenditure.module.scss"

const RelatedWork = ({ relatedWork }) => (
  <div className={styles.relatedWork} title={relatedWork.name}>
    №{relatedWork.number} {sliceTextByConstraint(relatedWork.name, 50)}
  </div>
);

export default React.memo(RelatedWork);
