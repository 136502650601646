import IExpenditure from "types/interfaces/Expenditure";
import ISection from "types/interfaces/Section";
import { getExpenditureAmount } from "./getExpenditureAmount";

export const getAddingToApproveAmount = (estimateItems: (IExpenditure | ISection)[]) => {
    return estimateItems.reduce((acc, estimateItem) => {
        if ((estimateItem as IExpenditure).expenditure_type) return acc + +getExpenditureAmount(estimateItem);
        return acc + +(estimateItem as ISection)?.indicators?.current_estimate_amount;
    }, 0)
}
