import React from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

const ProgressBar = ({completed, className, direction}) => {
  return (
    <div className={cn(styles.blockStyles, {
      [styles.column]: direction === 'column'
    })}>
      <span className={cn(styles.labelStyles, className)}>{`${completed}%`}</span>
      <div className={styles.containerStyles}>
        <div
          className={cn(styles.fillerStyles, className)}
          style={{ width: `${completed >= 100 ? '6.2rem' : completed}%` }}
        >
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;