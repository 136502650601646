import React, { useState } from 'react'
import cn from 'classnames'

import { apiGetStockProducts } from 'redux/modules/common/building/stocksApi'
import SearchFilterTable from 'components/UI/atoms/SearchFilterTable'
import ProductItem from './ProductItem'

import styles from './ReceiptTableRow.module.scss'
import moment from 'moment'
import SearchRangeDateFilter from 'components/UI/atoms/SearchRangeDateFilter'
import { useHistory } from 'react-router-dom'
import { useUrlModule } from '../../../../../../../utils/hooks/useUrlModule'
import { useSelector } from 'react-redux'
import { stockMeasureSelector } from '../../../../../../../redux/modules/common/building/stocks'
import TableRow from '../../../common/TableRow/TableRow'

const TableRowReceipt = ({ data, index, objectId, stockId }) => {
  const history = useHistory()
  const urlModule = useUrlModule()
  const [isOpen, setIsOpen] = useState(false)
  const [products, setProducts] = useState([])
  const [filters, setFilters] = useState(null)
  const measure = useSelector(stockMeasureSelector)

  const changeFilters = (value, name) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleOpen = async () => {
    const dataProduct = await apiGetStockProducts(stockId, data?.id)
    await setProducts(dataProduct)
    setIsOpen(!isOpen)
  }

  const sectionsString = React.useMemo(() => {
    return data?.sections?.join(', ')
  }, [data])

  return (
    <TableRow
      countChildren={data?.replenishments_count}
      rowNumber={data?.row_number}
      name={data?.product_building?.name}
      sectionString={sectionsString}
      measureProduct={data?.product_building?.measure}
      measureMoney={measure}

      count1={data?.count_received}  // поступления кол-во
      amount1={data?.product_received_amount} // поступления сумма

      count2={data?.product_production_count}  // кол-во в производстве
      amount2={data?.product_production_amount} // сумма в производстве

      count3={data?.count}  // остаток кол-во
      amount3={data?.amount}  // остаток сумма

      onOpen={handleOpen}
    >
      <div className={styles.content}>
        <div className={styles.tableHeader}>
          <div className={cn(styles.rowItem, styles.fieldNumber)}>
            <div className={styles.dot}></div>
          </div>
          <div className={cn(styles.rowItem, styles.fieldTitle)}>
            <div className={styles.title}>Номенклатура</div>
            <SearchFilterTable
              title="Номенклатура"
              setFilter={(value) => {
                changeFilters(value, 'product_building_name')
              }}
            />
          </div>
          <div className={cn(styles.rowItem, styles.fieldSection)}>
            Поставщик
            <SearchFilterTable
              title="Поставщик"
              setFilter={(value) => {
                changeFilters(value, 'provider_name')
              }}
            />
          </div>
          <div className={cn(styles.rowItem, styles.fieldCount, styles.center)}>
            Поставка
            <SearchRangeDateFilter
              title="Поставка"
              setFilterStart={(value) => {
                changeFilters(
                  value,
                  'packinglist_delivery_date_after'
                )
              }}
              setFilterEnd={(value) => {
                changeFilters(
                  value,
                  'packinglist_delivery_date_before'
                )
              }}
            />
          </div>
          <div className={cn(styles.rowItem, styles.center)}>
            Количество
          </div>
          <div className={cn(styles.rowItem, styles.center)}>
            Сумма
          </div>
        </div>
        {products
            ?.filter((item) => {
              let isShow = 0
              if (filters?.product_building_name) {
                isShow = item?.name?.indexOf(filters.product_building_name) !== -1
                return isShow ? isShow + 1 : isShow
              }
              if (filters?.provider_name) {
                isShow = item?.provider?.name?.indexOf(filters.provider_name) !== -1
                return isShow ? isShow + 1 : isShow
              }
              if (
                filters?.packinglist_delivery_date_after &&
                filters?.packinglist_delivery_date_before
              ) {
                isShow =
                  +moment(filters?.packinglist_delivery_date_after).format('YYYYMMDD') <=
                    +moment(item?.received_at).format('YYYYMMDD') &&
                  +moment(item?.received_at).format('YYYYMMDD') <=
                    +moment(filters?.packinglist_delivery_date_before).format('YYYYMMDD') &&
                  moment(item?.received_at).format('DD.MM.YYYY')
                return isShow ? isShow + 1 : isShow
              }
              return isShow > 0 ? isShow : item
            })
.map((item) => (
            <ProductItem data={item} measure={measure} onClick={() => history.push(`/${urlModule}/${objectId}/stocks/receipt/?upd=${item?.packinglist?.id}`)} />
          ))}
      </div>
    </TableRow>
  )
}

export default TableRowReceipt
