import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { objectsTasksSelector } from "redux/modules/common/building/objects";
import { tasksListFilterType } from "redux/modules/purchaser/Tasks/types";

import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";

import TaskRowByAllObjects from "../TaskRowByAllObjects/TaskRowByAllObjects";

import { TASK_LISTS_TYPES } from "../../constants";

import { useDeleteTask } from "../../hooks/useDeleteTask";
import { useTasksByAllBuildingsData } from "../../hooks/useTasksByAllBuildingsData";
import { useTasksModal } from "../../hooks/useTasksModal";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import taskIcon from "images/icons/navigation/tasksIcon.svg";

type propsType = {
  filters: tasksListFilterType;
  type: TASK_LISTS_TYPES;
  onChangeSection: (id: number) => void;
};

function TaskListAllObjects({ filters, type, onChangeSection }: propsType) {
  const { taskId } = useParams<{ taskId: string }>();
  const history = useHistory();

  const objects = useSelector(objectsTasksSelector);

  const { tasksByType, isTasksLoading, loadMoreTasksHandler } = useTasksByAllBuildingsData({ type, filters });
  const { deleteTaskHandler } = useDeleteTask({ variant: "byAllBuildings", type });
  const { openAddModal, openEditModal, openViewModal, onClose } = useTasksModal();

  const [hiddenBuildingIds, setHiddenBuildingIds] = useState<number[]>([]);

  useEffect(() => {
    if (!objects?.results?.length && isAllModulesAllowed()) return;

    if (localStorage.getItem("lastObjectId")) {
      history.push(`/tasks/${TASK_LISTS_TYPES.ALL}/${localStorage.getItem("lastObjectId")}`);
    } else {
      history.push(`/tasks/${TASK_LISTS_TYPES.ALL}/${objects?.results[0]?.id}`);
      localStorage.setItem("lastObjectId", objects?.results[0]?.id);
    }
  }, [history, objects]);

  useEffect(() => {
    if (!taskId) return;

    handleOpenTask(Number(taskId));
  }, [taskId])

  const expandHandler = useCallback(
    (id: number) => {
      setHiddenBuildingIds((prev) => {
        if (prev.includes(id)) {
          return prev.filter((el) => Number(el) !== Number(id));
        } else {
          return [...prev, id];
        }
      });
    },
    [setHiddenBuildingIds]
  );

  const handleOpenTask = useCallback(
    (id: number) => {
      openViewModal({
        placementType: "byAllBuildings",
        listType: type,
        id,
      });
    },
    [type]
  );

  const handleEditTask = useCallback(
    (id: number) => {
      openEditModal({
        placementType: "byAllBuildings",
        listType: type,
        id,
      });
    },
    [type]
  );

  const handleAddTask = useCallback(() => {
    openAddModal({
      placementType: "byAllBuildings",
      listType: type,
    });
  }, [type]);

  if (isTasksLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!!tasksByType?.results?.length &&
        tasksByType.results.map((el, i, arr) => (
          <TaskRowByAllObjects
            key={el.id}
            {...el}
            dateEnd={el.deadline}
            dateStart={el.created_at}
            commentsCount={el.task_comments?.length || 0}
            executor={el.executor_user}
            onDelete={deleteTaskHandler}
            onEdit={handleEditTask}
            isExists={!hiddenBuildingIds.includes(el.building)}
            isFirstInObject={i === 0 || el.building !== arr[i - 1].building}
            building={el.building_obj}
            onExpand={expandHandler}
            handleOpen={() => handleOpenTask(el.id)}
            onChangeSection={onChangeSection}
            filters={filters}
          />
        ))}
      <ShowMoreButton
        handleAdd={loadMoreTasksHandler}
        allCount={tasksByType?.count}
        showedCount={tasksByType?.results?.length}
      />
      {!tasksByType?.results?.length && (
        <EmptyPlaceholder text="Нет данных в разделе задач" img={taskIcon}>
          <ButtonBase primary onClick={handleAddTask}>
            Новая задача
          </ButtonBase>
        </EmptyPlaceholder>
      )}
    </>
  );
}

export default TaskListAllObjects;
