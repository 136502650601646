import React from 'react'
import styles from "./WorkersList.module.scss"
import getShortFullName from "./../../../../../../utils/getShortFullName"
function WorkersList({ workers = [] }) {
    return (
        <div className={styles.workers}>
            <b>Исполнители: {workers?.length}</b>
            <div className={styles.workersList}>
                {workers?.length ? workers?.map(el => (
                    <div key={el.id} className={styles.worker} title={getShortFullName(el.last_name, el.first_name, el.middle_name)}>{getShortFullName(el.last_name, el.first_name, el.middle_name)}</div>
                )) : <div className={styles.emptyWorkers}>Исполнители не назначены</div>}
            </div>
        </div>
    )
}

export default WorkersList