import TableReusableHead, { TableReusableHeaderCell } from 'components/UI/atoms/TableReusable/TableReusableHead'
import React from 'react'
import styles from "./JournalDoneTableHead.module.scss"

interface IProps {

}

const JournalDoneTableHead: React.FC<IProps> = () => {
  return (
    <TableReusableHead isSticky isWithEar className={styles.row}>
        <TableReusableHeaderCell>
            Наименование работы
        </TableReusableHeaderCell>
        <TableReusableHeaderCell>
            Выполнено
        </TableReusableHeaderCell>
        <TableReusableHeaderCell>
            Материалы, ₽
        </TableReusableHeaderCell>
        <TableReusableHeaderCell>
            Итого по смете, ₽
        </TableReusableHeaderCell>
    </TableReusableHead>
  )
}

export default JournalDoneTableHead