import React, { useRef, useState } from "react";
import moment from "moment";
import cn from "classnames";

import TicketTail from "../../../../../../../images/TicketTail";
import ticketBackground from "../../../../../../../images/ticket-background.svg";

import {
  INTERVAL_MAX_Z_INDEX,
  INTERVAL_TYPES,
  MONTH_COLOR_MAP as colorMap,
  TAILS_OFFSET_COMPENSATION_REM,
} from "../../../../constants";

import InfoPopup from "../InfoPopup/InfoPopup";

import styles from "../../Month.module.scss";
import ManufacturingPlanSectionModal from "components/modals/ManufacturingPlanSectionModal/ManufacturingPlanSectionModal";
import DiagramInterval from "../DiagramInterval/DiagramInterval";
import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";
import { useIntervalLinks } from "../../useIntervalLinks";
import ManufacturingModal from "../../../ManufacturingModal/ManufacturingModal";
import { getIntervalDatesLabel } from "../../../../utils";

const Plan = ({
  branch,
  offset,
  isSection = false,
  offsetLeft,
  objectId = null,
  isSectionPlan,
}) => {
  const unitMultiplier = useUnitMultiplier();
  const {
    isLinkingEnabled,
    beingDraggedArrows,
    draggedIntervalEntry,
    isSomeArrowBeingDragged,
    isIntervalHighlighted,
  } = useIntervalLinks({ toIntervalId: branch.plan?.data?.id });

  const [hover, setHover] = useState(false);
  const planWrapperRef = useRef(null);

  const currentPlanDraggedInfo =
    branch.plan.data.id && beingDraggedArrows[branch.plan.data.id];

  const isPlanHighlighted =
    isLinkingEnabled &&
    (isIntervalHighlighted ||
      (hover && !isSomeArrowBeingDragged) ||
      (draggedIntervalEntry &&
        hover &&
        moment(branch.plan.start).isAfter(
          draggedIntervalEntry[1]?.startDate
        )) ||
      currentPlanDraggedInfo?.isBeingDragged);

  const borderColor = isPlanHighlighted ? "#4FB1EB" : colorMap["defaultBorder"];

  const [isBottomTooltip, setIsBottomTooltip] = useState(false);

  const onHover = (e) => {
    const containerNode = e.target?.closest(".calendar_node");
    if (!containerNode) return;
    setIsBottomTooltip(planWrapperRef.current?.parentNode?.offsetTop < 48);
  };

  const onBraceHover = (e) => {
    onHover(e);
    setHover(true);
  };

  const onBraceLeave = () => {
    setHover(false);
  };

  const [isOpenWorkCard, setIsOpenWorkCard] = useState(false);

  const handleCloseModal = () => {
    setIsOpenWorkCard(false);
    setHover(false);
  };

  const planWidthRem = Math.max(branch.plan.days, 1) * unitMultiplier;

  const planWrapperStyles = {
    zIndex: currentPlanDraggedInfo?.isBeingDragged ?  INTERVAL_MAX_Z_INDEX + 1 : isPlanHighlighted ? INTERVAL_MAX_Z_INDEX : null,
  };

  const planFullStyles = {
    backgroundImage: `url(${ticketBackground})`,
    borderTop: `1px solid ${borderColor}`,
    borderBottom: `1px solid ${borderColor}`,
  };

  const planBraceStyles = {
    maxWidth: `${planWidthRem}rem`,
    left: `calc(${
      (branch.day - 1) * unitMultiplier - TAILS_OFFSET_COMPENSATION_REM + 0.44
    }rem + ${offsetLeft}px)`,
    width: `${planWidthRem - 0.44}rem`,
    zIndex: hover || isOpenWorkCard ? INTERVAL_MAX_Z_INDEX : 1,
  };

  const modalType =
    +branch.parentId === +objectId || !branch.parentId
      ? "building"
      : isSection
      ? "section"
      : "expenditure";

  const dateStart = moment(
    branch.plan.interval_start_date || branch.plan.start
  )?.format("YYYY-MM-DD");
  const dateEnd = moment(
    branch.plan.interval_end_date || branch.plan.end
  )?.format("YYYY-MM-DD");

  const interval = getIntervalDatesLabel(branch.plan.start, branch.plan.end);

  const isInfoPopupShown =
    !isLinkingEnabled &&
    (hover || isOpenWorkCard) &&
    branch.plan.type === "full";
  const isPlanBraceShown = !isLinkingEnabled && branch.plan.type === "brace";

  if (isLinkingEnabled && !branch.plan.data.id) return null;

  return (
    <>
      {isPlanBraceShown && (
        <div
          className={styles.planBrace}
          onClick={() => setIsOpenWorkCard(true)}
          style={planBraceStyles}
          onMouseEnter={onBraceHover}
          onMouseLeave={onBraceLeave}
        >
          {(hover || isOpenWorkCard) && (
            <div
              className={cn(styles.tooltip, {
                [styles.bottomTooltip]: isBottomTooltip,
              })}
              style={{ left: `${planWidthRem / 2}rem` }}
            >
              Плановый диапазон: {interval}
            </div>
          )}
        </div>
      )}
      <DiagramInterval
        day={branch.day}
        daysLength={branch.plan.days}
        unitMultiplier={unitMultiplier}
        onMouseEnter={onHover}
        offsetLeft={offsetLeft}
        hover={hover}
        setHover={setHover}
        intervalWrapperRef={planWrapperRef}
        isCardOpen={isOpenWorkCard}
        type={INTERVAL_TYPES.plan}
        intervalId={branch.plan.data.id}
        wrapperStyles={planWrapperStyles}
        projectId={objectId && +objectId}
        startDate={branch.plan.start}
      >
        <TicketTail fillColor={"none"} borderColor={borderColor} />
        <div className={styles.planFull} style={planFullStyles} />
        <TicketTail isRight fillColor={"none"} borderColor={borderColor} />
        {
          isInfoPopupShown && <InfoPopup
            isOpen
            setOpenPopup={setHover}
            interval={interval}
            data={branch.plan.data}
            type={INTERVAL_TYPES.plan}
            objectId={objectId}
            setOpenModal={setIsOpenWorkCard}
          >
            <div className={styles.popupAnchor} />
          </InfoPopup>
        }
        {isOpenWorkCard && !isSectionPlan && (
          <ManufacturingModal
            isOpen
            onClose={handleCloseModal}
            type={INTERVAL_TYPES.plan}
            date_start={dateStart}
            date_end={dateEnd}
            objectId={objectId}
            intervaldata={branch.plan.data}
            expenditureId={branch.plan.data.expenditure_id || branch.parentId}
            modalType={modalType}
          />
        )}
        {isOpenWorkCard && isSectionPlan && (
          <ManufacturingPlanSectionModal
            isOpen
            onClose={handleCloseModal}
            type={INTERVAL_TYPES.plan}
            date_start={dateStart}
            date_end={dateEnd}
            objectId={objectId}
            intervaldata={branch.plan.data}
            expenditureId={branch.plan.data.expenditure_id || branch.parentId}
            modalType={modalType}
          />
        )}
      </DiagramInterval>
    </>
  );
};

export default React.memo(Plan);
