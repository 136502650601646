import axios from "axios";
import { message } from "antd";

import downloadFileByUrl from "./downloadFileByUrl";

const downloadFile = async (url, name = "doc.xlsx") => {
  message.info("Загрузка файла...");

  if (new URL(url).origin === new URL(axios.defaults.baseURL).origin) {
    const fileBlobUrl = await axios.get(url, { responseType: "blob" })
      .then((response) => window.URL.createObjectURL(new Blob([response.data])))
    const link = document.createElement("a");

    link.href = fileBlobUrl;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    return true;
  }

  return downloadFileByUrl(url, name);
};


export default downloadFile;