import React from "react";

import styles from "./MonthsWork.module.scss";

const MonthsWork = ({ halfMonthCountDays, halfMonthSumHours, monthCountDays, monthSumHours }) => {
  return (
    <div className={styles.monthBlocks}>
      <div className={styles.firstColumn}>
        <div className={styles.monthValueBlock}>
          <div className={styles.monthValue}>{halfMonthCountDays}</div>
          <div className={styles.monthValue}>{halfMonthSumHours}</div>
          <div className={styles.monthValue}>{monthCountDays - halfMonthCountDays}</div>
          <div className={styles.monthValue}>{monthSumHours - halfMonthSumHours}</div>
        </div>
      </div>
      <div className={styles.secondColumn}>
        <div className={styles.monthValueBlock}>
          <div className={styles.monthValue1}>{monthCountDays}</div>
          <div className={styles.monthValue1}>{monthSumHours}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MonthsWork);
