import axios from "axios";
import { message } from "antd";
import { createSelector } from "reselect";

import { errorCatcher } from "utils/errorCatcher";


const moduleName = "tickets";
const LOAD_TICKETS = `${moduleName}/LOAD_TICKETS`;
const SET_TICKETS = `${moduleName}/SET_TICKETS`;
const INSERT_TICKET = `${moduleName}/INSERT_TICKET`;
const PATCH_TICKET = `${moduleName}/PATCH_TICKET`;

const initialState = {
  tickets: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_TICKETS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_TICKETS:
      return {
        ...state,
        tickets: payload,
        isLoading: false,
      };
    case INSERT_TICKET:
      return {
        ...state,
        tickets: [payload, ...state.tickets],
        isLoading: false,
      };
    case PATCH_TICKET:
      return {
        ...state,
        tickets: state?.tickets?.map((item) => {
          if (item.id === payload.id) item = payload;
          return item;
        }),
        isLoading: false,
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const ticketsSelector = createSelector(stateSelector, (state) => state.tickets);
export const ticketsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);

export const loadTickets = (objectId, params) => {
  const config = {
    params: {
      ...params,
    },
  };

  return (dispatch) => {
    dispatch({
      type: LOAD_TICKETS,
    });
    axios
      .get(`/building/${objectId}/tickets/`, config)
      .then((response) =>
        dispatch({
          type: SET_TICKETS,
          payload: response.data,
        })
      )
      .catch(errorCatcher);
  };
};

export const patchTicket = (objectId, id, params, successCallback, successMsg) => {
  const config = {
    ...params,
  };

  return (dispatch) => {
    axios
      .patch(`/building/${objectId}/tickets/${id}/`, config)
      .then((response) => {
        dispatch({
          type: PATCH_TICKET,
          payload: response.data,
        });
        successCallback && successCallback();
        successMsg && message.success(successMsg);
      })
      .catch(errorCatcher);
  };
};

export const createTicket = (objectId, params, files = [], locationArray = []) => {
  const config = {
    ...params,
  };

  return (dispatch) => {
    axios
      .post(`/building/${objectId}/tickets/`, config)
      .then((response) => {

        if (locationArray.includes('journal')) {
          dispatch({
            type: INSERT_TICKET,
            payload: { ...response.data, exists_accepted: false, sum_count_aproved: null },
          });

          message.success("Запись успешно добавлена");
        } else {
          message.success("Запись успешно добавлена в Журнал Учета");
        }

        if (files.length) {
          const formData = new FormData();
          files.forEach((file) => formData.append("file", file.file));
          axios
            .post(
              `/building/${objectId}/estimate/items/${response.data.expenditure_id}/fact-intervals/${response.data.id}/files/`,
              formData
            )
            .catch(errorCatcher);
        }
      })
      .catch(errorCatcher);
  };
};
