import { compose } from "redux";
import React, { useCallback, useEffect, useState } from "react";
import { memoize } from "lodash";
import update from "immutability-helper";

import InputBase, { VALUE_TYPES } from "../../../../atoms/InputBase/InputBaseOrder";
import Difference from "../../../../atoms/Difference/Difference";

import MeasureSelect from "../../../../atoms/MeasureSelect/MeasureSelect";

import { getValueFromEventTarget } from "../../../../../../utils/getValueFromEventTarget";
import { getLocalizedDate } from "../../../../../../utils/getLocalizedDate";
import { stringifyArgs } from "../../../../../../utils/stringifyArgs";
import { getIdentifier } from "../../../../../../utils/getIdentifier";
import { transformDigitToFinancial } from "../../../../../../utils/transformDigitToFinancial";
import { beautifyNumberValue } from "../../../../../../utils/beautifyNumberValue";

import GroupByIcon from "../../../../../../images/icons/GroupByIcon";
import plusIcon from "../../../../../../images/plus-bordered.svg";

import Calendar from "../../../Calendar";
import DeleteOffer from "../DeleteOffer";

import styles from "../../index.module.scss";
import TextareaAutosize from "react-textarea-autosize";

const OfferColumns = (props) => {
  const {
    offer,
    setOffer,
    handleKitComponentsOpening,
    deleteOffer,
    isShownCountRequestMeasure,
    createOfferSupply,
    offerIsKitWithNotKitMeasure,
    permissions,
  } = props;

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const openConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(true), []);
  const closeConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(false), []);

  const handleDeleteOffer = useCallback(() => {
    deleteOffer(getIdentifier(offer));
    closeConfirmDeleteModal();
  }, [offer, deleteOffer, closeConfirmDeleteModal]);

  const onChangeField = useCallback((name, value) => setOffer({ ...offer, [name]: value }), [offer, setOffer]);

  const onChangeFieldByName = useCallback(
    (name) => memoize((value) => onChangeField(name, value), stringifyArgs),
    [onChangeField]
  );

  const onChangeDate = useCallback(
    (changedDate) => setOffer(update(offer, { supplies: { 0: { date: { $set: changedDate.format("YYYY-MM-DD") } } } })),
    [setOffer, offer]
  );

  const onChangeCountRequestMeasure = useCallback(
    (event) =>
      setOffer(
        update(offer, {
          supplies: { 0: { count_request_measure: { $set: event.target.value } } },
        })
      ),
    [setOffer, offer]
  );

  const onChangeCount = useCallback(
    (event) =>
      setOffer(
        update(offer, {
          supplies: { 0: { count: { $set: event.target.value } } },
        })
      ),
    [setOffer, offer]
  );

  const onChangeAmount = useCallback(
    (changedAmount) =>
      setOffer(
        update(offer, {
          supplies: { 0: { amount: { $set: changedAmount } } },
        })
      ),
    [setOffer, offer]
  );

  const calculateOfferAmount = (price, count) => beautifyNumberValue(price * count);

  useEffect(() => {
    if (!offer.supplies[0].count || !offer.price) return;
    compose(onChangeAmount, calculateOfferAmount)(offer.price, offer.supplies[0].count);
  }, [offer.price, offer.supplies[0].count]);

  return (
    <>
      <div className={styles.nameColumn}>
        {offer.is_kit && <GroupByIcon className={styles.kitOfferIcon} onClick={handleKitComponentsOpening} />}
        {permissions.editRequest ? (
          <TextareaAutosize
            className={styles.input}
            value={offer.name}
            placeholder="Наименование предложения"
            onChange={compose(onChangeFieldByName("name"), getValueFromEventTarget)}
          />
        ) : (
          offer.name
        )}
      </div>
      <div className={styles.dateColumn}>
        {permissions.editRequest ? (
          <Calendar classNameSelect={styles.calendar} setValue={onChangeDate} value={offer.supplies[0].date || ""} />
        ) : (
          getLocalizedDate(offer.supplies[0].date)
        )}
      </div>
      <div className={styles.countColumn}>
        {permissions.editRequest && !offerIsKitWithNotKitMeasure ? (
          <InputBase
            className={styles.input}
            valueType={VALUE_TYPES.NUMBER}
            variant="secondary"
            value={offer.supplies[0].count}
            onChange={onChangeCount}
            placeholder="Кол-во"
          />
        ) : (
          offer.supplies[0].count
        )}
      </div>
      <div className={styles.measureColumn}>
        {permissions.editRequest ? (
          <MeasureSelect value={offer.measure} onChange={onChangeFieldByName("measure")} />
        ) : (
          offer.measure
        )}
      </div>
      {isShownCountRequestMeasure && (
        <span className={styles.countColumn}>
          {permissions.editRequest ? (
            <InputBase
              className={styles.input}
              valueType={VALUE_TYPES.NUMBER}
              variant="secondary"
              value={offer.supplies[0].count_request_measure}
              onChange={onChangeCountRequestMeasure}
              placeholder="Кол-во в ед.изм"
            />
          ) : (
            offer.supplies[0].count_request_measure
          )}
        </span>
      )}
      <div className={styles.priceColumn}>
        {permissions.editRequest ? (
          <InputBase
            className={styles.input}
            valueType={VALUE_TYPES.PRICE}
            variant="secondary"
            value={offer.price}
            onChange={compose(onChangeFieldByName("price"), getValueFromEventTarget)}
            placeholder="Цена"
          />
        ) : (
          <span className={styles.financial}>{transformDigitToFinancial(offer.price)}</span>
        )}
      </div>
      <div className={styles.sumColumn}>
        {permissions.editRequest ? (
          <InputBase
            className={styles.input}
            valueType={VALUE_TYPES.PRICE}
            variant="secondary"
            value={offer.supplies[0].amount}
            onChange={compose(onChangeAmount, getValueFromEventTarget)}
            placeholder="Сумма"
          />
        ) : (
          <span className={styles.financial}>{transformDigitToFinancial(offer.supplies[0].amount)}</span>
        )}
      </div>
      {permissions.editRequest &&
        (!offerIsKitWithNotKitMeasure ? (
          <div className={styles.actionsColumn}>
            {offer.supplies.length === 1 && (
              <img
                className={styles.createOfferSupplyIcon}
                src={plusIcon}
                alt="Иконка добавления предложения"
                onClick={createOfferSupply}
              />
            )}
            <div className={styles.delete}>
              <DeleteOffer
                openModal={openConfirmDeleteModal}
                closeModal={closeConfirmDeleteModal}
                isOpen={isConfirmDeleteModalOpen}
                deleteOffer={handleDeleteOffer}
              />
            </div>
          </div>
        ) : (
          offerIsKitWithNotKitMeasure && <div className={styles.actionsColumn} />
        ))}
      {!permissions.editRequest && permissions.viewInvoiceDifference && (
        <div className={styles.differenceColumn}>
          <Difference amount={offer.benefit?.amount_plan_diff} percent={offer.benefit?.percent_plan_diff} />
        </div>
      )}
    </>
  );
};

export default React.memo(OfferColumns);
