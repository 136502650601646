export const MODULE_NAME = "requisition";

export const SEND_REQUISITION_LOADING = `${MODULE_NAME}/SEND_REQUISITION_LOADING`;
export const SEND_REQUISITION_ERROR = `${MODULE_NAME}/SEND_REQUISITION_ERROR`;
export const SET_REQUISITION_EMPLOYEES = `${MODULE_NAME}/SET_REQUISITION_EMPLOYEES`;
export const SET_REQUISITION_EMPLOYEES_LOADING = `${MODULE_NAME}/SET_REQUISITION_EMPLOYEES_LOADING`;
export const SET_REQUISITION_EMPLOYEES_ERROR = `${MODULE_NAME}/SET_REQUISITION_EMPLOYEES_ERROR`;
export const ADD_REQUISITION_EMPLOYEES = `${MODULE_NAME}/ADD_REQUISITION_EMPLOYEES`;
export const DELETE_REQUISITION_EMPLOYEE = `${MODULE_NAME}/DELETE_REQUISITION_EMPLOYEE`;

export const SET_INFO_LOADING = `${MODULE_NAME}/GET_INFO`;
export const SET_INFO = `${MODULE_NAME}/SET_INFO`;
export const SET_INFO_ERROR = `${MODULE_NAME}/SET_INFO_ERROR`;
export const SET_REQUISITION_IS_AGREED = `${MODULE_NAME}/SET_REQUISITION_IS_AGREED`;

export const GET_ORDERS = `${MODULE_NAME}/GET_ORDERS`;
export const SET_ORDERS = `${MODULE_NAME}/SET_ORDERS`;
export const UPDATE_ORDER = `${MODULE_NAME}/UPDATE_ORDER`;
export const ADD_ORDER = `${MODULE_NAME}/ADD_ORDER`;
export const SET_ORDER_STATUS = `${MODULE_NAME}/SET_ORDER_STATUS`;
export const SET_PAYMENT_LOADING = `${MODULE_NAME}/SET_PAYMENT_LOADING`;
export const SET_PAYMENT_ERROR = `${MODULE_NAME}/SET_PAYMENT_ERROR`;

export const SET_PRODUCTS_LOADING = `${MODULE_NAME}/SET_PRODUCTS_LOADING`;
export const SET_PRODUCTS = `${MODULE_NAME}/SET_PRODUCTS`;
export const ADD_PRODUCTS = `${MODULE_NAME}/ADD_PRODUCTS`;
export const EDIT_PRODUCT = `${MODULE_NAME}/EDIT_PRODUCT`;
export const LOAD_COMPARE_PRODUCT = `${MODULE_NAME}/LOAD_COMPARE_PRODUCT`;
export const UPDATE_PRODUCT = `${MODULE_NAME}/UPDATE_PRODUCT`;
export const LOAD_DELETE_PRODUCT = `${MODULE_NAME}/LOAD_DELETE_PRODUCT`;
export const DELETE_PRODUCT = `${MODULE_NAME}/DELETE_PRODUCT`;

export const RESET_TO_INITIAL = `${MODULE_NAME}/RESET_TO_INITIAL`;