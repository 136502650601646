import axios from "axios";
import { compose } from "redux";

import { errorCatcher } from "../../../../../utils/errorCatcher";

import { setCommentsLoadingAction, setItemIdCommentsAction } from "../actions";
import { DEFAULT_QUERY_PARAMS } from "../constants";

export const getPackingItemComments = (packingItemId) => async (dispatch) => {
  compose(dispatch, setCommentsLoadingAction)(true);

  const response = await axios
    .get(`/purchaser/packinglists/packingitems/${packingItemId}/comments/`, { params: DEFAULT_QUERY_PARAMS })
    .catch(errorCatcher);

  compose(dispatch, setItemIdCommentsAction)({ comments: response.data.results, itemId: packingItemId });
  compose(dispatch, setCommentsLoadingAction)(false);
};
