import React, { useMemo } from "react";
import cn from "classnames";

import CheckBlue from "../../../../../../images/icons/check-blue.svg";
import ArrowGrayRight from "../../../../../../images/icons/arrow-gray-right.svg";
import ArrowGrayFillRight from "../../../../../../images/icons/arrow-gray-fill-right.svg";

import IntervalBlock from "./components/IntervalBlock";
import EmptyProgressBlock from "./components/EmptyProgressBlock/EmptyProgressBlock";
import styles from "./Intervals.module.scss";

const Intervals = ({
  items,
  isExpand,
  onExpand,
  isCompleted,
  fullCount,
  isShared,
  hideArrow,
  onClickInterval,
  displayItemsConstraint,
  intervalsColor, // "blue"|"green"|"lightGreen"
  approvedIntervalColor, // "blue"|"green"|"lightGreen"
}) => {
  const displayItems = useMemo(
    () => (isExpand ? items : items.slice(0, displayItemsConstraint)),
    [isExpand, items, displayItemsConstraint]
  );

  if (!items || isShared || (items.length === 0 && !isCompleted)) return <EmptyProgressBlock />;

  if (isCompleted) {
    return (
      <>
        <div className={cn(styles.completed, styles[intervalsColor])}>
          <img src={CheckBlue} alt="check" />
        </div>
        {!hideArrow && <img className={styles.arrow} src={ArrowGrayRight} alt="arrow-right" />}
      </>
    );
  }

  return (
    <>
      <div className={styles.wrapper}>
        {displayItems.map((item) => (
          <IntervalBlock
            item={item}
            fullCount={fullCount}
            intervalsColor={item.approved ? approvedIntervalColor : intervalsColor}
            onClickInterval={onClickInterval}
            key={item.week}
          />
        ))}
        {items.length > displayItemsConstraint && !isExpand && (
          <div className={styles.outer}>
            <button onClick={onExpand} className={styles.more}>
              еще {items.length - displayItemsConstraint}
              <img src={ArrowGrayFillRight} alt="arrow-right" />
            </button>
          </div>
        )}
      </div>
      {!hideArrow && <img className={styles.arrow} src={ArrowGrayRight} alt="arrow-right" />}
    </>
  );
};

export default React.memo(Intervals);
