import { message } from "antd"
import React from "react"

export const useSelectingWorkers = (defaultWorkers, defaultWorkersCounts) => {
    const [addedUsers, setAddedUsers] = React.useState([])
    const [selectedUsers, setSelectedUsers] = React.useState([])

    React.useEffect(() => {
        if (!defaultWorkers?.length) return
        setAddedUsers(defaultWorkers.map((el, i) => {
            if (defaultWorkersCounts[el.id]) {
                return { ...el, count: defaultWorkersCounts[el.id] }
            } else {
                return el
            }
        }))
    }, [defaultWorkers, defaultWorkersCounts])

    const addedUsersIds = React.useMemo(() => {
        return addedUsers.map(el => el.id)
    }, [addedUsers])

    const selectedUsersIds = React.useMemo(() => {
        return selectedUsers.map(el => el.id)
    }, [selectedUsers])

    const confirmHandler = () => {
        setAddedUsers(prev => [...prev, ...selectedUsers])
        setSelectedUsers([])
    }

    const cancelHandler = () => {
        setSelectedUsers([])
    }

    const changeCountHandler = (id, count, type) => { //type: "selected"|"added"
        const mapper = (arr) => arr.map(el => {
            if (el.id === id) {
                return { ...el, count }
            } else {
                return el
            }
        })
        if (type === "selected") {
            setSelectedUsers(prev => mapper(prev))
        } else {
            setAddedUsers(prev => mapper(prev))
        }
    }

    const validateSubmitting = (submit) => {
        if (addedUsers.some(el => !el.count)) {
            message.error("Укажите часы каждому выбранному сотруднику")
            return
        }
        submit()
    }

    const deleteAddedHandler = (id) => {
        const result = addedUsers.filter(el => el.id !== id)
        setAddedUsers(result)
    }

    const selectHandler = (e, item) => {
        if (e.target.checked) {
            setSelectedUsers(prev => [...prev, item])
        } else {
            setSelectedUsers(prev => prev.filter(el => el.id !== item.id))
        }
    }

    const clearWorkers = () => {
        setAddedUsers([])
        setSelectedUsers([])
    }

    return {
        confirmHandler,
        cancelHandler,
        changeCountHandler,
        validateSubmitting,
        deleteAddedHandler,
        selectHandler,
        addedUsers,
        selectedUsers,
        selectedUsersIds,
        addedUsersIds,
        clearWorkers
    }
}