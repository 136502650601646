export const IN_WORK_ORDERS_STATUSES = [
  "payment_decision",
  "new",
  "pcr_need_approve",
  "pvr_need_approve",
  "pvr_rejected",
  "pcr_rejected",
];

export const COMPLETED_ORDERS_STATUSES = ["completed"];

export const ACCEPTED_ORDERS_STATUSES = ["waiting_payment", "waiting_shipment"];
