import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useParams, useRouteMatch } from "react-router-dom";
import { compose } from "redux";

import { loadObjects, objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import {
  loadMoreRequisitions,
  loadRequisitions,
  requisitionsLoadingSelector,
  requisitionsSelector,
  resetStateToInitialAction,
} from "redux/modules/common/building/requisitions";
import {
  createRequisition,
  getLastRequisitionDraftId,
  lastRequisitionDraftIdSelector,
  resetLastRequisitionDraftIdAction,
} from "redux/modules/common/building/object/nowObject";

import { Spinner } from "components/UI/Spinner/Spinner";
import TemplateBase from "components/UI/templates/TemplateBase";
import ButtonBase from "components/UI/atoms/ButtonBase";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import RequisitionsTableHead from "./components/RequisitionsTableHead/RequisitionsTableHead";
import RequisitionsRow from "./components/RequisitionsRow/RequisitionsRow";
import CreateRequisitionModal from "./components/CreateRequisitionModal/CreateRequisitionModal";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";

import { TABS_LINKS } from "./constants";
import { IRequisitionInList } from "types/interfaces/Requisition";
import { IRouterParamsWithObjectId } from "types/routerTypes";
import { IIdAndName } from "types/idAndName";
import useRequisitionsStatusesByActiveTabId from "./hooks/useRequisitionsStatusesByActiveTabId";
import useTabs from "./hooks/useTabs";
import TabBar from "elements/navigation/TabBar/TabBar";

import applications from "images/icons/navigation/applications.svg";


const RequisitionsList: React.FC<RouteComponentProps<IRouterParamsWithObjectId> & { permissions: Record<string, string> }> = ({ match, history, permissions }) => {
  const { objectId: buildingId } = useParams<IRouterParamsWithObjectId>();
  const activeTabMatch = useRouteMatch(`${match.path}/:activeTab`);//@ts-ignore
  const activeTab = activeTabMatch && activeTabMatch.params.activeTab;

  const dispatch = useDispatch();
  const requisitions = useSelector(requisitionsSelector);
  const requisitionsAreLoading = useSelector(requisitionsLoadingSelector);
  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);
  const lastRequisitionDraftId = useSelector(lastRequisitionDraftIdSelector);

  const displayingRequisitionsStatuses = useRequisitionsStatusesByActiveTabId(activeTab);
  const { tabs } = useTabs(match.url, buildingId, activeTab);

  const modifiedProjectsArgs = useMemo(
    () =>
      breadcrumbsProjects.results.map((project: IIdAndName) => ({
        id: project.id,
        name: project.name,
        label: project.name,
      })),
    [breadcrumbsProjects.results]
  );

  const [filters, setFilters] = useState({ building_id: buildingId });
  const [isCreateRequisitionModalOpen, setIsCreateRequisitionModalOpen] = useState(false);

  const changeFilters = (name: string, value: string) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const loadMore = () => {
    dispatch(
      loadMoreRequisitions({
        ...filters,
        offset: requisitions?.results?.length,
        statuses: displayingRequisitionsStatuses,
      })
    );
  };

  const handleResetLastRequisitionDraftId = useCallback(
    () => compose(dispatch, resetLastRequisitionDraftIdAction)(),
    []
  );

  const onCloseCreateRequisitionModal = useCallback(() => {
    handleResetLastRequisitionDraftId();
    setIsCreateRequisitionModalOpen(false);
  }, [handleResetLastRequisitionDraftId]);

  const hasNextPage = useMemo(() => requisitions?.count > requisitions?.results?.length, [requisitions]);

  const handleCreateRequisition = useCallback(//@ts-ignore
    (buildingId) =>//@ts-ignore
      compose(dispatch, createRequisition)(buildingId, (response) =>//@ts-ignore
        history.push(`${match.url}/${TABS_LINKS.NEW_REQUISITIONS}/${response.data.id}`)
      ),
    [history, match.url]
  );

  const onClickByCreateRequisitionButton = useCallback(() => {
    if (!buildingId) {
      setIsCreateRequisitionModalOpen(true);
      return;
    }

    compose(
      dispatch,
      getLastRequisitionDraftId//@ts-ignore
    )(buildingId).then((responseData) => {
      if (responseData) {
        setIsCreateRequisitionModalOpen(true);
      } else {
        handleCreateRequisition(buildingId);
      }
    });
  }, [buildingId]);

  const onUseExistedRequisitionDraft = useCallback(() => {
    if (!lastRequisitionDraftId) return;
    onCloseCreateRequisitionModal();
    history.push(`${match.url}/${TABS_LINKS.NEW_REQUISITIONS}/${lastRequisitionDraftId}`);
  }, [history, match.url, lastRequisitionDraftId, onCloseCreateRequisitionModal]);

  useEffect(() => {
    if (displayingRequisitionsStatuses.length === 0) return;

    const requestParams = { ...filters, building_id: filters?.building_id || buildingId, statuses: displayingRequisitionsStatuses };
    dispatch(loadRequisitions(requestParams));
  }, [filters, displayingRequisitionsStatuses]);

  useEffect(() => {
    if (buildingId) return;//@ts-ignore
    compose(dispatch, loadObjects)({ limit: 1000 });
  }, [buildingId]);

  useEffect(() => {
    handleResetLastRequisitionDraftId();
  }, [buildingId]);

  useEffect(
    () => () => {
      dispatch(resetStateToInitialAction());
      handleResetLastRequisitionDraftId();
    },
    []
  );

  return (
    <TemplateBase>
      {tabs ? (
        <div>
          <ListPageHeaderTemplate>
            <div>{/* @ts-ignore */}
              <TabBar tabs={tabs} />
            </div>
            {permissions.viewCreateRequisition && (
                <ButtonBase
                  secondary
                  onClick={onClickByCreateRequisitionButton}
                >
                  Создать заявку
                </ButtonBase>
            )}
          </ListPageHeaderTemplate>
          <div>
            <RequisitionsTableHead
              objectId={buildingId}
              onChangeFilter={changeFilters}
              projects={modifiedProjectsArgs}
            />
            {!requisitionsAreLoading && 
              requisitions &&
              requisitions.results.map((el: IRequisitionInList) => (
                <RequisitionsRow
                  key={el.id}
                  requisition={el}
                  objectId={buildingId}
                />
              ))}
            {requisitionsAreLoading && <Spinner />}
            {!requisitionsAreLoading && !requisitions?.results?.length && (
              <EmptyPlaceholder text="Нет заявок" img={applications}>
                {permissions.viewCreateRequisition && (
                  <ButtonBase primary onClick={onClickByCreateRequisitionButton}>
                    Создать заявку
                  </ButtonBase>
                )}
              </EmptyPlaceholder>
            )}
            <ShowMoreButton
              isExists={hasNextPage && !requisitionsAreLoading}
              handleAdd={loadMore}
              allCount={requisitions?.count}
              showedCount={requisitions?.results?.length}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      {permissions.viewCreateRequisition && (
        <CreateRequisitionModal
          isOpen={isCreateRequisitionModalOpen}
          onClose={onCloseCreateRequisitionModal}
          buildings={breadcrumbsProjects}
          buildingId={buildingId}
          createRequisition={handleCreateRequisition}
          onUseExistedDraft={onUseExistedRequisitionDraft}
        />
      )}
    </TemplateBase>
  );
};

export default RequisitionsList;
