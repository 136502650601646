import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import Settings from "../../../pages/Settings/Settings";
import { UserPage } from "../../../pages/Settings/components/Users/UserPage/UserPage";
import EditOrganization from "../../../pages/Settings/components/EditOrganization/EditOrganization";
import EditProject from "../../../pages/Settings/EditProject/EditProject";

const SettingsRoutes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/users/:id`} component={UserPage} />
      <Route exact path={`${match.path}/organization/:id`} component={EditOrganization} />
      <Route path={`${match.path}/project/:id`} component={EditProject} />
      <Route path={match.path} component={Settings} />
    </Switch>
  );
};

export default React.memo(SettingsRoutes);