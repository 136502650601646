import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import TableReusableRow, { TableReusableCell } from 'components/UI/atoms/TableReusable/TableReusableRow';
import { NavLinkIcon } from 'components/UI/NavLinkIcon/NavLinkIcon';

import { IServiceAct } from 'types/interfaces/ServiceAct';
import { ACT_LISTS_PATH } from '../../constants';

import { useUrlModuleWithObjectId } from 'utils/hooks/useUrlModuleWithObjectId';
import { sliceTextByConstraint } from 'utils/sliceTextByConstraint';
import { transformDigitToFinancial } from 'utils/transformDigitToFinancial';

import styles from "./ActListsRow.module.scss";


interface Iprops {
  listItem: IServiceAct;
}

const ActListsRow: React.FC<Iprops> = ({ listItem }) => {
  const history = useHistory();
  const fullUrlModule = useUrlModuleWithObjectId();

  const rowClickHandler = () => {
    history.push(`/${fullUrlModule}/documents/${ACT_LISTS_PATH}/${listItem.id}`);
  };

  return (
    <TableReusableRow className={styles.row} onClick={rowClickHandler}>
      <TableReusableCell>
        {listItem.number ? sliceTextByConstraint(String(listItem.number), 8) : "Формируется"}
      </TableReusableCell>
      <TableReusableCell>
        <NavLinkIcon
          to={`/${fullUrlModule}/orders/${listItem.order.id}`}
          title={sliceTextByConstraint(String(listItem.order.number), 8)}
          className={styles.orderLink}
        />
      </TableReusableCell>
      <TableReusableCell isNoBreak>
        {listItem.building.name}
      </TableReusableCell>
      <TableReusableCell isNoBreak>
        {listItem.provider.name}
      </TableReusableCell>
      <TableReusableCell isCentered>
        {(listItem.created_at ? moment(listItem.created_at).format("DD.MM.YYYY") : "---")}
      </TableReusableCell>
      <TableReusableCell isRighted>
        {transformDigitToFinancial(listItem.amount)}
      </TableReusableCell>
      <TableReusableCell>
        {listItem.status_name}
      </TableReusableCell>
    </TableReusableRow>
  )
}

export default React.memo(ActListsRow);