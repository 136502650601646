import React from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import styles from './index.module.scss';
import { updateExpenditure } from 'redux/modules/common/building/materials/materials';

import ButtonBase from 'components/UI/atoms/ButtonBase';
import InputBase from 'components/UI/atoms/InputBase';
// import Select from 'components/UI/atoms/Select';
import arowSelectSmallBlue from 'images/icons/arowSelectSmallBlue.svg';
import { composeFieldValidators, maxLength, required } from 'utils/validations';
import { measuresMaterials } from 'utils/constant';
import { expenditureTypeMaterials } from 'utils/constant';
import CrossRoundedIcon from 'images/icons/CrossRoundedIcon';
import OldSelect from 'components/UI/atoms/Select/OldSelect';

const UpdateExpenditureModal = ({ objId, close, section, data }) => {
  const dispatch = useDispatch();
  const submitRequisite = (value) => {
    dispatch(updateExpenditure(objId, section.id, value));
    close();
  };

  return (
    <div className={styles.container}>
      <Form
        onSubmit={(values) => submitRequisite(values)}
        initialValues={section}
        render={({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.topSide}>
              <div className={styles.headerContainer}>
                <div className={styles.block1Title}>
                  <span className={styles.title}>Редактирование позиции</span>
                  <div className={styles.closeIcon} onClick={close}>
                    <CrossRoundedIcon />
                  </div>
                </div>
              </div>
              <div className={styles.contentContainer}>
                <div className={styles.row1}>
                  <div className={styles.field4}>
                    <Field
                      name="name"
                      icon={arowSelectSmallBlue}
                      component={OldSelect}
                      // options={}
                      selfControlled={true}
                    />
                  </div>
                  <div className={styles.field4}>
                    <Field
                      name="expenditure_type"
                      icon={arowSelectSmallBlue}
                      component={OldSelect}
                      options={expenditureTypeMaterials}
                      defaultValue={section?.expenditure_type}
                    />
                  </div>
                </div>
                <div className={styles.titleFont}>{data[0].name}</div>
                <div className={styles.row}>
                  <div className={styles.field1}>
                    <Field
                      name="name"
                      label="Наименование *"
                      component={InputBase}
                      selfControlled={true}
                      validate={composeFieldValidators(required(), maxLength(155))}
                      className={styles.select}
                      // disabled={!isEditable}
                    />
                  </div>
                  <div className={styles.field2}>
                    <Field
                      name="justification"
                      label="Обоснование"
                      component={InputBase}
                      selfControlled={true}
                      validate={composeFieldValidators(required(), maxLength(255))}
                      className={styles.select}

                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.field3}>
                    <Field className={styles.select} name="estimate_count" label="Смет.Количество" component={InputBase} />
                  </div>
                  <div className={styles.field3}>
                    <Field className={styles.select} name="count" label="Количество" component={InputBase} />
                  </div>
                  <div className={styles.field3}>
                    <Field className={styles.select} name="estimate_price" label="Смет. Цена" component={InputBase} />
                  </div>
                  <div className={styles.field3}>
                    <Field className={styles.select} name="price" label="Цена" component={InputBase} />
                  </div>
                  <div className={styles.field3}>
                    <Field className={styles.select} name="estimate_amount" label="Сумма" component={InputBase} />
                  </div>
                  <div className={styles.field3}>
                    <Field className={styles.select} name="estimate_measure" label="Смет. Ед.изм." component={InputBase} />
                  </div>
                  <div className={styles.field3}>
                    <Field
                      name="measure"
                      label="Ед.изм."
                      component={OldSelect}
                      options={measuresMaterials}
                      className={styles.select}
                      defaultValue={section?.measure}
                      selfControlled={true}
                    />
                  </div>
                </div>
              </div>
              
            </div>
            <div className={cn(styles.actionBlock, { [styles.action]: pristine })}>
              <ButtonBase primary medium type="submit">
                Сохранить
              </ButtonBase>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default UpdateExpenditureModal;
