import axios from "axios";
import { compose } from "redux";
import { message } from "antd";

import { errorCatcher } from "../../../../../../utils/errorCatcher";

import { addEmployeesAction } from "../actions";
import { MESSAGES } from "../constants";

const approve = (options, orderId) => async (dispatch) => {
  const sendRequest = options.isApprove ? axios.post : axios.delete;

  sendRequest(`/purchaser/orders/${orderId}/${options.approvalType}/`)
    .then((response) => {
      if (response.data.id) compose(dispatch, addEmployeesAction)([response.data], options.approvalType);

      message.success(
        options.isApprove ? (MESSAGES[options.approvalType].APPROVED) : (MESSAGES[options.approvalType].UNAPPROVED)
      );
    })
    .catch(errorCatcher);
};

export { approve };