import React, { useState } from 'react';
import cn from 'classnames';

import styles from './index.module.scss';

export const Tab = ({name, title, setTab, activeTab, Icon, className}) => {
  return (
    <div
      className={cn(styles.sliderItem, className, {
        [styles.active]: name === activeTab
      })}
      onClick={() => setTab(name)}
    >
      <div className={styles.icon}>
        {Icon && <Icon color={name === activeTab ? '#fff' : '#000'} />}
      </div>
      {title}
    </div>
  )
}

export const Header = ({children, tabsHeight}) => {

  return (
    <div className={styles.sliderBlock}>
      {children}
    </div>
  )
}
