import TableReusableHead, { TableReusableHeaderCell } from 'components/UI/atoms/TableReusable/TableReusableHead'
import React from 'react'
import styles from "./ContractsTableHeader.module.scss"
import cn from "classnames"

interface Iprops {
    onChangeFilter: (name: string, value: string) => void;
    className?: string;
}

const ContractsTableHeader: React.FC<Iprops> = ({
    onChangeFilter,
    className
}) => {
    return (
        <TableReusableHead className={cn(styles.row, className)} isSticky>
            <TableReusableHeaderCell
                filterField="contract_num"
                filterTitle="№"
                filterType="text"
                onChangeFilter={onChangeFilter}
            >
                №
            </TableReusableHeaderCell>
            <TableReusableHeaderCell
                filterField="building_name"
                filterTitle="Проект"
                filterType="text"
                onChangeFilter={onChangeFilter}
            >
                Проект
            </TableReusableHeaderCell>
            <TableReusableHeaderCell
                filterField="entity_name"
                filterTitle="Контрагент"
                filterType="text"
                onChangeFilter={onChangeFilter}
            >
                Контрагент
            </TableReusableHeaderCell>
            <TableReusableHeaderCell
                filterTitle="Дата"
                filterType="datesRange"
                onChangeFilter={onChangeFilter}
                rangeEndField="contract_date_before"
                rangeStartField="contract_date_after"
                isCentered
            >
                Дата
            </TableReusableHeaderCell>
            <TableReusableHeaderCell
            >
                    Стоимость ₽
            </TableReusableHeaderCell>
            <TableReusableHeaderCell
            >
                    Файлы
            </TableReusableHeaderCell>
            <TableReusableHeaderCell
            >
                    Действия
            </TableReusableHeaderCell>
        </TableReusableHead>
    )
}

export default ContractsTableHeader