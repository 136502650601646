import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadStockProducts,
  stockProductsSelector,
  addStockProducts,
  stockProductsLoadingSelector
} from 'redux/modules/common/building/stocks';
import TableRow from './components/TableRow';

import styles from './Receipt.module.scss';
import warehouses from "images/icons/navigation/warehouses.svg";
import EmptyPlaceholder from "../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder"
import { Spinner } from '../../../../UI/Spinner/Spinner';
import { useQueryParams } from "./../../../../../utils/hooks/useQueryParams"
import PackingList from "./../../../PackingList/index"
import TableHeader from '../common/TableHeader/TableHeader';
import ShowMoreButton from '../../../../UI/atoms/ShowMoreButton';
import usePermission from 'hooks/usePermission';
import { VIEW_MANUFACTURING_PACKING_LIST_SECTION } from 'constants/permissions/manufacturingPermissions';

const Receipt = ({ stockId, objectId }) => {
  const dispatch = useDispatch();
  const products = useSelector(stockProductsSelector);
  const isLoading = useSelector(stockProductsLoadingSelector)
  const [filters, setFilters] = useState({ limit: 10, offset: 0 });
  const updId = useQueryParams("upd")


  useEffect(() => {
    stockId && dispatch(loadStockProducts(stockId, filters));
  }, [filters, stockId]);

  const changeFilters = (name, value) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleAdd = () => {
    dispatch(addStockProducts(stockId, {offset: products?.results?.length}))
  }

  const haveViewUpdPermission = usePermission(VIEW_MANUFACTURING_PACKING_LIST_SECTION)

  return (<>

    <>
      <TableHeader onFiltersChange={changeFilters} lastColName="Остаток" preLastColName="В производстве" prePreLastColName="Приход" />
      {isLoading && <Spinner />}
      {!isLoading && !!products?.results.length && products?.results?.map((item, index) =>
        <TableRow data={item} index={index} stockId={stockId} objectId={objectId} key={item.id} />
      )}
      {!isLoading && !products?.results.length && <EmptyPlaceholder img={warehouses} />}
      <ShowMoreButton
        allCount={products?.count}
        showedCount={products?.results?.length}
        handleAdd={handleAdd}
        isExists={!isLoading && (products?.count > products?.results.length)}
      />
    </>
    {updId && haveViewUpdPermission && <div className={styles.updWrapper}><PackingList propsPackingListId={updId} /></div>}
  </>);
}

export default Receipt;