import axios from "axios";
import { createSelector } from "reselect";
import { message } from "antd";

import { messageErrorHandler } from "utils/errorHandler";

import { customersStateSelector } from "../customers";

const initialState = {
  isLoading: true,
  customer: null,
  deleteModalOpen: false
};

const moduleName = "item";
const actionsPrefix = "constructing/counterparties/customers/item";

const SET_LOADING = `${actionsPrefix}_SET_LOADING`;
const SET_CUSTOMER = `${actionsPrefix}_SET_CUSTOMER`;
const SET_DELETE_MODAL_STATE = `${actionsPrefix}_SET_DELETE_MODAL_STATE`;
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`;

export const stateSelector = createSelector(customersStateSelector, (state) => state[moduleName]);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const customerSelector = createSelector(stateSelector, (state) => state.customer);
export const deleteModalOpenSelector = createSelector(
  stateSelector,
  (state) => state.deleteModalOpen
);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_CUSTOMER:
      return {
        ...state,
        customer: payload
      };
    case SET_DELETE_MODAL_STATE:
      return {
        ...state,
        deleteModalOpen: payload
      };
    case CLEAR_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data
});

export const setCustomer = (data) => ({
  type: SET_CUSTOMER,
  payload: data
});

export const loadCustomer = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axios.get(`/customers/${id}/`);
      dispatch(setCustomer(response.data));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const setDeleteModalState = (data) => ({
  type: SET_DELETE_MODAL_STATE,
  payload: data
});

export const changeManager = (manager) => {
  return async (dispatch, getState) => {
    const customer = customerSelector(getState());
    try {
      await axios.post(`/customers/${customer.id}/change_manager/`, {
        manager_id: manager ? manager.id : null
      });
      message.success("Успешно сохранено");
      dispatch(setCustomer({ ...customer, contact_manager: manager }));
    } catch (e) {
      messageErrorHandler(e.response.data.errors, true);
    }
  };
};

export const deleteCustomer = () => {
  return async (_, getState) => {
    const customer = customerSelector(getState());
    try {
      await axios.delete(`/customers/${customer.id}/`);
    } catch (e) {
      console.error(e);
    }
  };
};

export const clearData = () => ({
  type: CLEAR_DATA
});
