import React from "react";
import { compose } from "redux";

import { stopEventPropagation } from "../../../../../../../../utils/stopEventPropagation";

import styles from "../../ContractorsTable.module.scss";

function DeleteContractorCell({ onClick }) {
  return (
    <div className={styles.delete} onClick={compose(onClick, stopEventPropagation)}>
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.3653 13.6908L8.00026 9.32484L3.63426 13.6908C3.49156 13.8367 3.32135 13.9528 3.13348 14.0325C2.94561 14.1121 2.7438 14.1537 2.53975 14.1548C2.3357 14.1559 2.13345 14.1166 1.94472 14.039C1.75598 13.9614 1.5845 13.8472 1.44022 13.7029C1.29593 13.5586 1.18169 13.3871 1.10412 13.1984C1.02655 13.0096 0.987184 12.8074 0.988305 12.6033C0.989425 12.3993 1.03101 12.1975 1.11065 12.0096C1.19028 11.8217 1.3064 11.6515 1.45226 11.5088L5.81726 7.14284L1.45226 2.77684C1.16814 2.48641 1.01003 2.09564 1.01226 1.68935C1.01449 1.28306 1.17688 0.894046 1.46417 0.606752C1.75147 0.319459 2.14048 0.157072 2.54677 0.154841C2.95306 0.152611 3.34383 0.310717 3.63426 0.594839L8.00026 4.95984L12.3653 0.594839C12.6546 0.305355 13.0471 0.142672 13.4564 0.142578C13.8657 0.142484 14.2583 0.304987 14.5478 0.594339C14.8372 0.88369 14.9999 1.27619 15 1.68549C15.0001 2.09478 14.8376 2.48735 14.5483 2.77684L10.1783 7.14284L14.5433 11.5078C14.6891 11.6505 14.8052 11.8207 14.8849 12.0086C14.9645 12.1965 15.0061 12.3983 15.0072 12.6023C15.0083 12.8064 14.969 13.0086 14.8914 13.1974C14.8138 13.3861 14.6996 13.5576 14.5553 13.7019C14.411 13.8462 14.2395 13.9604 14.0508 14.038C13.8621 14.1156 13.6598 14.1549 13.4558 14.1538C13.2517 14.1527 13.0499 14.1111 12.862 14.0315C12.6742 13.9518 12.504 13.8357 12.3613 13.6898L12.3653 13.6908Z"
          fill="#606060"
        />
      </svg>
    </div>
  );
}

export default DeleteContractorCell;
