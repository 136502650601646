import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import fileSvg from "./components/FileItem/assets/files.svg";
import {
  createFolderInStorage,
  getFileStorageItems,
  getRecentFiles,
} from "redux/modules/common/filesStorage/thunks";
import {
  filesByFolderIdSelector,
  filesByObjectIdSelector,
} from "redux/modules/common/filesStorage/selectors";

import TemplateBase from "components/UI/templates/TemplateBase";
import FilesIndicators from "./components/FilesIndicators/FilesIndicators";
import FileItem from "./components/FileItem/FileItem";
import FilesNavBar from "./components/FilesNavBar/FilesNavBar";
import { useFileStorageNavigation } from "./useFileStorageNavigation";
import Expandable from "components/UI/atoms/Expandable/Expandable";
import FileUploadInStorage from "./components/FileUploadInStorage/FileUploadInStorage";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import styles from "./FileStorage.module.scss";
import { Spinner } from "components/UI/Spinner/Spinner";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

const FilesStorage = () => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const isAllObjects = objectId === "0";
  const filesByObject = useSelector(filesByObjectIdSelector)[objectId];

  const [isCreatingFolder, setIsCreatingFolder] = React.useState(false);

  const creatingFolderSwitcher = React.useCallback(() => {
    setIsCreatingFolder((prev) => !prev);
  }, [setIsCreatingFolder]);

  const { currentFolderId, backHandler, openFolderHandler, foldersDepth } =
    useFileStorageNavigation();
  const filesByFolder = useSelector(filesByFolderIdSelector)[currentFolderId];

  const fileStorage = currentFolderId ? filesByFolder : filesByObject;
  React.useEffect(() => {
    dispatch(getFileStorageItems(objectId, currentFolderId));
    !currentFolderId && dispatch(getRecentFiles(objectId));
    setIsCreatingFolder(false);
  }, [objectId, currentFolderId, dispatch]);

  const createFolder = React.useCallback(
    (name: string) => {
      dispatch(createFolderInStorage(name, objectId, currentFolderId));
      setIsCreatingFolder(false);
    },
    [objectId, currentFolderId]
  );

  const isTooDeep = foldersDepth >= 2;

  const isEmpty =
    fileStorage?.isLoaded && !fileStorage?.items?.length && !isCreatingFolder;

  return (
    <TemplateBase>
      <FileUploadInStorage sectionId={currentFolderId} />
      <FilesIndicators />
      <FilesNavBar
        canBack={!!currentFolderId}
        canCreateFolder={!isAllObjects && !isTooDeep}
        onCreateNewFolder={creatingFolderSwitcher}
        onGoBack={backHandler}
        text={fileStorage?.parentName}
      />
      {!currentFolderId && !!fileStorage?.recentFiles?.length && (
        <Expandable title="Недавние файлы:">
          <div className={styles.grid}>
            {fileStorage?.recentFiles?.map((el) => (
              <FileItem
                key={el.id + "recent"}
                name={el.originalname}
                link={el.file}
                id={el.id}
              />
            ))}
          </div>
        </Expandable>
      )}
      <Expandable
        title={`Все файлы: Папок: ${fileStorage?.foldersCount || 0}  файлов: ${
          fileStorage?.filesCount || 0
        }`}
      >
        {!isEmpty && (
          <div className={styles.grid}>
            {isCreatingFolder && (
              <FileItem
                id={-1}
                isFolder
                name="Новая папка"
                isNewFolder
                onCancelEditing={creatingFolderSwitcher}
                onApproveEditing={createFolder}
              />
            )}
            {fileStorage?.items?.map((el) => (
              <FileItem
                key={el.id + String(el.isFolder)}
                name={el.name}
                link={el.link}
                id={el.id}
                isFolder={el.isFolder}
                onOpenFolder={openFolderHandler}
                filesCount={el.filesCount}
                foldersCount={el.foldersCount}
              />
            ))}
            {!fileStorage?.isLoaded && !fileStorage?.items?.length && (
              <Spinner />
            )}
          </div>
        )}
        {isEmpty && <EmptyPlaceholder text="Нет файлов" img={fileSvg} />}
      </Expandable>
    </TemplateBase>
  );
};

export default FilesStorage;
