import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SliderModal from "components/UI/atoms/SliderModal";

import {
  modalFilesSelector,
  modalIntervalListSelector,
  modalIntervalSelector,
  modalIsLoadingSelector,
  modalMaterialsSelector,
} from "redux/modules/common/building/manufacturing/selectors";
import {
  clearExpenditure,
  clearModalData,
  clearModalInterval,
  getModalFiles,
  getModalInterval,
  getModalIntervalList,
  getModalWorkMaterials,
  removeModalFile,
  uploadModalFile,
} from "redux/modules/common/building/manufacturing/thunks";

import MaterialBlockInWork from "../../../../UI/organism/WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import { Spinner } from "../../../../UI/Spinner/Spinner";
import ButtonBack from "../../../../UI/atoms/ButtonBack";
import Expandable from "../../../../UI/atoms/Expandable/Expandable";
import WorkersList from "../../../../UI/organism/WorkOrMaterialsModals/components/WorkersList/WorkersList";
import WorkListItem from "../../../../UI/organism/WorkOrMaterialsModals/components/WorkListItem/WorkListItem";
import styles from "./ManufacturingModal.module.scss";
import ExpandableFileInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput";
import ModalContainer from "components/UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import ProductIntervalCardContent from "components/pages/Journal/components/JournalDelivery/components/ProductIntervalCard/ProductIntervalCardContent";
import ManufacturingModalContentPlan from "./ManufacturingModalContentPlan";
import ManufacturingModalContentFact from "./ManufacturingModalContentFact";

import {
  manufacturingMaterialsStatusSwitcher,
  manufacturingModalStatusSwitcher,
} from "./manufacturingModalStatusSwitcher";
import NavBar from "components/UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";

import PlanEditing from "./PlanEditing/PlanEditing";
import { useCanEditPlan } from "./PlanEditing/useCanEditPlan";
import { useApprovePlan } from "./PlanEditing/useApprovePlan";
import moment from "moment";
import WorkSharingModalContent from "./WorkSharingModalContent/WorkSharingModalContent";
import WorkApprovingSharedModalContent from "./WorkSharingModalContent/WorkApprovingSharedModalContent";
import EmptyPlaceholder from "../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import scheduleIcon from "../../../../../images/icons/navigation/scheduleIcon.svg";
import {
  INTERVALS,
  FACT_INTERVALS,
} from "redux/modules/common/building/manufacturing/manufacturing";
import ManufacturingModalBottom from "./ManufacturingModalBottom/ManufacturingModalBottom";
import TabBarNotLinks from "../../../../../elements/navigation/TabBar/TabBarNotLinks";

import IntervalRelationsContent from "./IntervalRelationsContent/IntervalRelationsContent";
import { useLoadIntervalRelations } from "./IntervalRelationsContent/useLoadIntervalRelations";
import { MAIN_PLAN_TAB, PLAN_TABS, RELATIONS_PLAN_TAB } from "./constants";
import usePermission from "../../../../../hooks/usePermission";
import { VIEW_MANUFACTURING_CHART_EDIT_RELATIONS } from "../../../../../constants/permissions/manufacturingPermissions";
import { VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS } from "../../../../../constants/permissions/constructingPermissions";
import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  date_start: string;
  date_end: string;
  objectId: string;
  type: string;
  intervaldata: Record<string, any>;
  expenditureId: number;
  modalType: string;
  updateWorkStatus?: (status: string, id: number) => void;
}

function ManufacturingModal({
  isOpen,
  onClose,
  date_start,
  date_end,
  objectId,
  type,
  intervaldata = {},
  expenditureId,
  modalType,
  updateWorkStatus,
}: IProps) {
  const dispatch = useDispatch();
  const list = useSelector(modalIntervalListSelector);
  const interval = useSelector(modalIntervalSelector);
  const files = useSelector(modalFilesSelector);
  const modalMaterials = useSelector(modalMaterialsSelector);
  const isLoading = useSelector(modalIsLoadingSelector);

  const [planTab, setPlanTab] = useState(MAIN_PLAN_TAB);

  const module = useUrlModule();

  const canEditRelationsInManufacturing = usePermission(
    VIEW_MANUFACTURING_CHART_EDIT_RELATIONS
  );

  const canEditRelationsInConstructing = usePermission(
    VIEW_CONSTRUCTING_CHART_EDIT_RELATIONS
  );

  const hasEditRelationsPermission =
    (module === "objects" && canEditRelationsInManufacturing) ||
    (module === "constructing" && canEditRelationsInConstructing);

  const activeModule = type === "plan" ? INTERVALS : FACT_INTERVALS;
  //@ts-ignore
  if (intervaldata?.building_id) {
    //@ts-ignore
    objectId = intervaldata.building_id;
  }

  useEffect(() => {
    if (!isOpen) return;
    dispatch(
      getModalIntervalList(
        objectId,
        expenditureId,
        activeModule,
        date_start,
        date_end,
        modalType
      )
    );
  }, [isOpen, objectId]);

  const {
    hasRelations,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
  } = useLoadIntervalRelations({
    projectId: +objectId,
    intervalId: interval?.id,
  });

  const intervalClickHandler = (
    interval_id: number,
    expenditure_id: number
  ) => {
    dispatch(
      getModalInterval(objectId, activeModule, expenditure_id, interval_id)
    );
  };

  useEffect(() => {
    if (!interval?.id || !interval?.expenditure_id) return;
    if (type === "work") {
      updateWorkStatus?.(interval.status, interval.id);
      dispatch(
        getModalWorkMaterials(objectId, interval.expenditure_id, interval.id)
      );
    }
    dispatch(
      getModalFiles(
        objectId,
        interval.expenditure_id,
        activeModule,
        interval.id
      )
    );
  }, [interval, updateWorkStatus]);

  const closeHandler = () => {
    onClose && onClose();
    dispatch(clearModalData());
  };

  const fileUploadHandler = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      uploadModalFile(
        objectId,
        interval?.expenditure_id,
        activeModule,
        interval.id,
        formData
      )
    );
  };

  const fileRemoveHandler = (fileId: number) =>
    dispatch(
      removeModalFile(
        objectId,
        interval?.expenditure_id,
        activeModule,
        interval.id,
        fileId
      )
    );

  const handleListBackButton = () => {
    dispatch(clearExpenditure());
    dispatch(clearModalInterval());
  };

  const intervalDataFetcher = (id: number) => {
    dispatch(getModalInterval(objectId, activeModule, expenditureId, id));
  };

  const [isAddingNew, setIsAddingNew] = React.useState(false);
  const [isEditingPlan, setIsEditingPlan] = React.useState(false);

  const canEditPlan = useCanEditPlan(isOpen, objectId);
  const planIsInFuture = moment(date_start).isAfter(moment(), "day");

  const approveHandler = useApprovePlan(
    Number(objectId),
    expenditureId,
    interval?.id,
    intervalDataFetcher
  );

  const closePlanEditingHandler = () => {
    setIsEditingPlan(false);
    intervalDataFetcher(interval?.id);
  };

  const [isSharing, setIsSharing] = React.useState(false);
  const [isReviewing, setIsReviewing] = React.useState(false);

  const {
    text: statusText,
    color: statusColor,
    icon: statusIcon,
  } = manufacturingModalStatusSwitcher({
    isPlan: activeModule !== "fact-intervals",
    status: interval?.work_status,
    sharingStatus: interval?.status,
  });

  if (isSharing || isReviewing) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={interval?.expenditure_name}
        justification={interval?.justification} //@ts-ignore
        statusColor={statusColor}
        statusText={statusText}
        statusIcon={statusIcon}
      >
        {isSharing && (
          <WorkSharingModalContent
            onClose={() => setIsSharing(false)}
            interval={interval}
            onIntervalReload={intervalDataFetcher}
            objectId={objectId}
            files={files}
            onAddFile={fileUploadHandler}
            onDeleteFile={fileRemoveHandler}
          />
        )}
        {isReviewing && (
          <WorkApprovingSharedModalContent
            onCloseWholeModal={closeHandler}
            onClose={() => setIsReviewing(false)}
            interval={interval}
            onIntervalReload={intervalDataFetcher}
            objectId={objectId}
            files={files}
            onAddFile={fileUploadHandler}
            onDeleteFile={fileRemoveHandler}
          />
        )}
      </ModalContainer>
    );
  }

  if (isEditingPlan) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={interval?.expenditure_name}
        justification={interval?.justification} //@ts-ignore
        statusColor={statusColor}
        statusText={statusText}
        statusIcon={statusIcon}
      >
        <ButtonBack
          onClick={() => setIsEditingPlan(false)}
          className={styles.back}
        />
        <PlanEditing
          planCreator={interval?.author}
          planCreatingDate={interval?.created_at}
          productId={interval?.expenditure_id}
          buildingId={+objectId}
          intervalId={interval?.id}
          planCount={interval?.count}
          planDateStart={interval?.start_at}
          planDateEnd={interval?.end_at}
          onClose={closePlanEditingHandler}
          files={files}
          onDeleteFiles={fileRemoveHandler}
          onUploadFiles={fileUploadHandler}
          defaultMaterials={interval?.planned_materials_list}
          defaultWorkers={interval?.workers}
          defaultWorkersCounts={interval?.count_workers_data}
        />
      </ModalContainer>
    );
  }

  if (interval?.id && !isLoading) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={interval?.expenditure_name}
        justification={interval?.justification} //@ts-ignore
        statusColor={statusColor}
        statusText={statusText}
        statusIcon={statusIcon}
        isFixedHeader={hasRelations && planTab === RELATIONS_PLAN_TAB}
      >
        {isAddingNew && (
          <NavBar
            isExist
            text="Вернуться к работе"
            onClick={() => setIsAddingNew(false)}
            className={styles.back}
          />
        )}
        {isAddingNew && (
          <ProductIntervalCardContent //@ts-ignore
            objectId={intervaldata.building_id}
            handleClose={() => setIsAddingNew(false)}
            directlySection={interval?.section_id}
            directlyWork={interval?.expenditure_id}
            isOpen={isOpen}
          />
        )}
        {!isAddingNew && (
          <>
            <NavBar
              isExist={list?.length > 1}
              onClick={handleListBackButton}
              className={styles.back}
            />
            {hasRelations && (
              <TabBarNotLinks
                tabs={PLAN_TABS}
                activeId={planTab}
                onClick={setPlanTab}
                className={styles.relationsTabs}
              />
            )}
            {planTab === RELATIONS_PLAN_TAB && (
              <IntervalRelationsContent
                hasEditPermission={hasEditRelationsPermission}
                intervalId={interval.id}
                projectId={+objectId}
                relationsFromCurrentInterval={relationsFromCurrentInterval}
                relationsToCurrentInterval={relationsToCurrentInterval}
                intervalStartAt={interval.start_at}
                intervalEndAt={interval.end_at}
              />
            )}
            {planTab === MAIN_PLAN_TAB && (
              <>
                {type === "plan" && (
                  <ManufacturingModalContentPlan
                    planCreator={interval?.author}
                    planCreatingDate={interval?.created_at}
                    measure={interval?.measure}
                    dateStart={interval?.start_at}
                    dateEnd={interval?.end_at}
                    projectAmount={interval?.work_amount_with_materials}
                    projectFullAmount={interval?.expenditure_amount}
                    projectFullCount={interval?.expenditure_count}
                    planCount={interval?.count}
                    planIsInWork={interval?.work_status === "actived"}
                    planApprover={interval?.approved_user}
                    planApprovingDate={interval?.approved_at}
                  />
                )}
                {type !== "plan" && (
                  <ManufacturingModalContentFact
                    dateStart={interval?.start_at}
                    dateEnd={interval?.end_at}
                    measure={interval?.measure}
                    passingAuthor={interval?.author}
                    passingDate={interval?.created_at}
                    passingCount={interval?.count}
                    investedAmount={interval?.work_amount}
                    acceptedAmount={
                      interval?.accepted?.length > 0 &&
                      interval.accepted[0].amount
                    }
                    acceptedAuthor={
                      interval?.accepted?.length > 0 &&
                      interval.accepted[0].approve_user
                    }
                    acceptedCount={
                      interval?.accepted?.length > 0 &&
                      interval.accepted[0].count
                    }
                    acceptedDate={
                      interval?.accepted?.length > 0 &&
                      interval.accepted[0].created_at
                    }
                    planCreator={interval?.from_interval?.author}
                    planCreatingDate={interval?.from_interval?.created_at}
                    planCount={interval?.from_interval?.count}
                    projectAmount={interval?.from_interval?.work_amount}
                    planApprover={interval?.from_interval?.approved_user}
                    planApprovingDate={interval?.from_interval?.approved_at}
                  />
                )}

                <div className={styles.pair}>
                  <div className={styles.info}>
                    <b>Раздел:</b>
                    <span>{interval?.section_name}</span>
                  </div>
                </div>
                <WorkersList workers={interval?.workers} />
                {!!modalMaterials?.length && (
                  <Expandable title={`Материалы: ${modalMaterials.length}`}>
                    {modalMaterials.map((el: any, index: number) => (
                      <MaterialBlockInWork
                        name={el.stock_using_material?.product_building?.name}
                        measure={
                          el.stock_using_material?.product_building?.measure
                        }
                        count={el.count}
                        amount={el.amount}
                        key={el.id} //@ts-ignore
                        statusColor={
                          manufacturingMaterialsStatusSwitcher(interval, index)
                            .color
                        }
                        statusText={
                          manufacturingMaterialsStatusSwitcher(interval, index)
                            .text
                        }
                      />
                    ))}
                  </Expandable>
                )}
                {!!interval?.planned_materials_list?.length && (
                  <Expandable
                    title={`Материалы: ${interval?.planned_materials_list.length}`}
                  >
                    {interval?.planned_materials_list.map((el: any) => (
                      <MaterialBlockInWork
                        name={el.material?.name}
                        measure={el.material?.measure}
                        count={el.count}
                        amount={
                          el.material?.estimate_expanditure?.estimate_amount
                        }
                        key={el.id}
                        statusColor="blue"
                        statusText="План"
                      />
                    ))}
                  </Expandable>
                )}
                {!!files?.length && (
                  <ExpandableFileInput
                    files={files || []}
                    canExtractFiles
                    canDeleteFiles={false}
                    canUploadFiles={false}
                  />
                )}
                <ManufacturingModalBottom
                  interval={interval}
                  isPlan={type === "plan"}
                  canEditPlan={canEditPlan}
                  planIsInFuture={planIsInFuture}
                  onAddToJournal={() => setIsAddingNew(true)}
                  onApprove={approveHandler}
                  onEdit={() => setIsEditingPlan(true)}
                  onSetSharing={() => setIsSharing(true)}
                  onSetReviewingShared={() => setIsReviewing(true)}
                />
              </>
            )}
          </>
        )}
      </ModalContainer>
    );
  }

  const isWorkListShown = !interval?.id && list?.length > 1;

  return (
    <SliderModal
      closeHandler={closeHandler}
      isOpen={isOpen}
      className={styles.modalContainer}
    >
      {isLoading && <Spinner />}
      {isWorkListShown && (
        <div className={styles.list}>
          {list.map(
            (
              el: any //@ts-ignore
            ) => (
              <WorkListItem
                key={el.id} //@ts-ignore
                type={type}
                onClick={() => intervalClickHandler(el.id, el.expenditure_id)}
                name={el.expenditure_name}
                passAmount={el.completed_amount}
                passCount={el.count}
                passDate={el.created_at}
                passName={el.author}
                acceptAmount={el.accepted?.length ? el.accepted[0].amount : 0}
                acceptCount={el.accepted?.length ? el.accepted[0].count : 0}
                acceptDate={
                  el.accepted?.length ? el.accepted[0].created_at : ""
                }
                acceptName={
                  el.accepted?.length ? el.accepted[0].approve_user : ""
                }
                measure={el.measure}
                hideAccepted={!el.accepted?.length}
              />
            )
          )}
        </div>
      )}
      {!isLoading && !isWorkListShown && (
        <EmptyPlaceholder
          img={scheduleIcon}
          text={"В данном интервале пока что нет записей"}
          className={styles.emptyPlaceholder}
        />
      )}
    </SliderModal>
  );
}

export default React.memo(ManufacturingModal);
