import React, {useMemo, useRef, useState} from 'react'
import moment, { Moment } from "moment";
import cn from "classnames";

import Button from "../../atoms/ButtonBase";
import Icon from "../../Icon";
import MonthPicker from "./components/MonthPicker";
import DatePicker from "./components/DatePicker";

import useOnClickOutside from "../../../../hooks/useOnClickOutside";

import disCalendar from "../../../../images/icons/disCalendar.svg";
import activeCalendarBlue from "../../../../images/icons/activeCalendarBlue.png";
import activeCalendar from "../../../../images/icons/activeCalendar.svg";

import styles from "./CalendarRange.module.scss";
import "./Global.scss";

interface IProps {
  defaultDateStart?: string;
  setDefaultDateStart?: (date: string) => void;
  defaultDateEnd?: string;
  setDefaultDateEnd?: (date: string) => void;
  classNameSelect?: string;
  classNamePopup?: string;
  disabled?: boolean;
  isTooRight?: boolean;
  containerClassName?: string;
  label?: string;
}

const CalendarRange: React.FC<IProps> = ({
  defaultDateStart,
  setDefaultDateStart,
  defaultDateEnd,
  setDefaultDateEnd,
  classNameSelect,
                                           classNamePopup,
  disabled = false,
  isTooRight,
  containerClassName,
  label,
}) => {
  const [dateStart, setDateStart] = useState(defaultDateStart);
  const [dateEnd, setDateEnd] = useState(defaultDateEnd);
  const [isOpen, setIsOpen] = useState(false);
  const [activeDate, setActiveDate] = useState("start");
  const date = useMemo(() => activeDate === "start" ? moment(dateStart) : moment(dateEnd), [dateStart, dateEnd, activeDate]);
  const ref = useRef<HTMLDivElement>(null);

  const setDate = (val: string) => {
    if (activeDate === "start") {
      if (moment(val).isAfter(dateEnd)) {
        setDateEnd(val);
      }
      setDateStart(val);
    } else {
      if (moment(val).isBefore(dateStart)) {
        setDateStart(val);
      }
      setDateEnd(val);
    }
  };

  const clickEndDate = () => {
    setActiveDate("end");
  };

  const setToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSetDate = () => {
    setDefaultDateStart?.(dateStart);
    setDefaultDateEnd?.(dateEnd);
    setToggle();
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className={containerClassName}>
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={cn(styles.select, {
          [styles.isOpen]: isOpen && !disabled,
          [styles.disabled]: disabled,
          [classNameSelect!]: classNameSelect,
        })}
        onClick={setToggle}
        ref={ref}
      >
        <div className={styles.outViewCalendar}>
          <div className={styles.outViewDateFlex}>
            <div className={styles.outViewDateFlexText}>
              {moment(defaultDateStart).format("DD.MM.YY")}
            </div>
            {"  -  "}
            <div className={styles.outViewDateFlexText}>
              {moment(defaultDateEnd).format("DD.MM.YY")}
            </div>
          </div>
          <div className={styles.calendatIconView}>
            <img src={activeCalendarBlue} alt="" />
          </div>
        </div>

        {!disabled && (
          <div
            className={cn(styles.optionsBlock, {
              [styles.isOpen]: isOpen,
              [styles.isTooRight]: isTooRight,
            }, classNamePopup)}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={cn(styles.container)}>
              <div className={styles.smallPicker}></div>
              <div className={styles.titleBlock}>
                <div className={styles.dateBlock}>
                  <div
                    className={cn(styles.dateValue, {
                      [styles.activeDate]: activeDate === "start",
                    })}
                    onClick={() => setActiveDate("start")}
                  >
                    <div className={styles.textPeriod}>От</div>
                    <div className={styles.textDate}>
                      {moment(dateStart).format("DD.MM.YYYY")}
                    </div>
                    {activeDate === "start" ? (
                      <Icon icon={activeCalendar} />
                    ) : (
                      <Icon icon={disCalendar} />
                    )}
                  </div>
                  <div
                    className={cn(styles.dateValue, {
                      [styles.activeDate]: activeDate === "end",
                    })}
                    onClick={clickEndDate}
                  >
                    <div className={styles.textPeriod}>До</div>
                    <div className={styles.textDate}>
                      {moment(dateEnd).format("DD.MM.YYYY")}
                    </div>
                    {activeDate !== "start" ? (
                      <Icon icon={activeCalendar} />
                    ) : (
                      <Icon icon={disCalendar} />
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.wrapper}>
                <MonthPicker date={date} setDate={setDate} />
              </div>
              <DatePicker
                dateStart={dateStart}
                dateEnd={dateEnd}
                date={date}
                setDate={setDate}
              />
              <div className={styles.btnPosition}>
                <Button primary medium onClick={() => handleSetDate()}>
                  Применить
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CalendarRange);
