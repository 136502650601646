import React from "react";
import cn from "classnames";

import { ISectionPlan } from "redux/modules/common/building/processTypes";

import CalendarRange from "components/UI/molecules/CalendarRange";
import DayWorkerAndEvent from "components/UI/organism/WorkOrMaterialsModals/components/DayWorkerAndEvent/DayWorkerAndEvent";
import InfoItem from "components/UI/organism/WorkOrMaterialsModals/components/InfoItem/InfoItem";
import ModalContainer from "components/UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import { useGetSectionPlan } from "./useGetSectionPlan";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EditSectionPlan from "./EditSectionPlan/EditSectionPlan";
import { useEditSectionPlan } from "./useEditSectionPlan";

import styles from "./ManufacturingPlanSectionModal.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  intervaldata?: { id: number };
  objectId: string;
  externalData?: ISectionPlan;
}

const ManufacturingPlanSectionModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  intervaldata,
  objectId,
  externalData,
}) => {
  const innerIntervalId = externalData?.id || intervaldata?.id || -1;
  const [isEditing, setIsEditing] = React.useState(false);
  const { planData, getSectionPlanFetcher } = useGetSectionPlan(
    objectId,
    innerIntervalId,
    isOpen && !externalData
  );

  const innerData = planData || externalData || null;

  const { approveHandler } = useEditSectionPlan(
    innerData,
    getSectionPlanFetcher
  );

  const isApproved = !!innerData?.confirm_date;
  const IS_PERMISSION = true;

  if (isEditing && innerData)
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={onClose}
        name={innerData?.section_name}
      >
        <EditSectionPlan
          onClose={() => setIsEditing(false)}
          planData={innerData}
          dataRefetcher={getSectionPlanFetcher}
        />
      </ModalContainer>
    );

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      name={innerData?.section_name}
    >
      {innerData && (
        <>
          <DayWorkerAndEvent
            date={innerData?.created_at}
            label="Создал"
            author={innerData?.author}
          />
          {innerData.confirm_date && (
            <DayWorkerAndEvent
              date={innerData.confirm_date}
              label="Согласовал" // @ts-ignore
              author={innerData.confirm_user}
            />
          )}
          <div className={cn(styles.pair, styles.centered)}>
            <b>Период выполнения:</b>
            <CalendarRange
              defaultDateStart={innerData?.start_at}
              defaultDateEnd={innerData?.end_at}
              disabled
              classNameSelect={styles.calendar}
            />
          </div>
          <InfoItem
            label="Бюджет (по проекту)"
            value={innerData.section_estimate_amount}
          />
        </>
      )}
      <BottomControls isDoubleBtns isExists={IS_PERMISSION && !isApproved}>
        <ButtonBase secondary onClick={() => setIsEditing(true)}>
          Редактировать
        </ButtonBase>
        <ButtonBase primary onClick={approveHandler}>
          Согласовать
        </ButtonBase>
      </BottomControls>
    </ModalContainer>
  );
};

export default ManufacturingPlanSectionModal;
