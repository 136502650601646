import React, { useState } from 'react';

import styles from '../Section/index.module.scss';
import CrossRoundedIcon from 'images/icons/CrossRoundedIcon';
import PlusInFolders from 'images/icons/PlusInFolders';
import PlusInCircleIcon from 'images/icons/PlusInCircleIcon';

const SubSection = ({ data, blockOpenExpenditure }) => {
  return (
    <>
      <div className={styles.container} onClick={() => blockOpenExpenditure(data)}>
        <div className={styles.name}>{data?.name}</div>
        <div className={styles.count}>
          <span>Материалы: </span>
          {data?.expenditure_count}
        </div>

      </div>
    </>
  );
};

export default SubSection;
