import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styles from './Import.module.scss';
import {
  materialsImportListSelector,
  getImportList,
  deleteImportList,
  addImportList,
} from 'redux/modules/common/building/materials/importMaterials';
import ButtonBase from 'components/UI/atoms/ButtonBase';
import downloadFile from 'utils/download';
import CrossRoundedIcon from 'images/icons/CrossRoundedIcon';
import { TableOslaLocal } from 'components/UI/TableOslaCustom/TableOslaLocal';
import { TableHeader } from 'components/UI/TotoRowTable/TableHeader/TableHeader';
import SaveImportList from 'images/icons/SaveImportList';
import { message, Upload } from 'antd';
import axios from "axios";
import getShortFullName from 'utils/getShortFullName';

const Import = ({ objId, close }) => {
  const dispatch = useDispatch();
  const ImportList = useSelector(materialsImportListSelector);
  const nameFormatter = (cell, row) => {
    if (row?.log_journal?.failed !== 0) {
      let message = [];
      for (let prop in row?.log_journal?.lines) {
        message.push(row?.log_journal?.lines[prop][0].message);
      }
      return (
        <div>
          <span>{cell}</span>
          {message.map((el) => (
            <div className={styles.errorName}>{el}</div>
          ))}
        </div>
      );
    } else {
      return cell;
    }
  };
  const dateFormatter = (cell) => (cell !== null ? moment(cell).format('DD.MM.YYYY') : '---');
  const saveFormatter = (cell, row) => {
    return (
      <a className={styles.download} href={cell} download={row.name}>
        <SaveImportList />
      </a>
    );
  };
  const deleteFormatter = (cell, row) => {
    return (
      <div
        className={styles.closeIcon}
        onClick={() => {
          dispatch(deleteImportList(objId, row.id));
        }}
      >
        <CrossRoundedIcon width={'14px'} />
      </div>
    );
  };
  useEffect(() => {
    dispatch(getImportList(objId));
  }, [objId]);

  const fileCheck = (file) => {
    const ext = file.name.split('.').pop();
    if (ext === 'xls' || ext === 'xlsx' || ext === 'xlsb' || ext === 'gge' || ext === 'xml' || ext === 'kenml') {
      return true;
    } else {
      message.error('Неверное расширение файла. Допустимые расширения: xls, xlsx, xlsb, gge, xml, kenml.');
    }
  };
  const handleRequest = ({ file }) => {
    if (fileCheck(file)) {
      const formData = new FormData();
      formData.set('file', file);
      dispatch(addImportList(objId, formData));
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.block1}>
        <div className={styles.block1Title}>
          <span>Импорт</span>
          <div className={styles.closeIcon} onClick={close}>
            <CrossRoundedIcon />
          </div>
        </div>
        <div className={styles.block1btn}>
          <Upload
            name="file"
            customRequest={handleRequest}
            fileList={null}
            className="ant-upload-no-style"
            multiple={false}
          >
            <ButtonBase className={styles.sendBtn}>Загрузить</ButtonBase>
          </Upload>
          <ButtonBase
            secondary
            medium
            type="submit"
            onClick={() =>
              downloadFile(`${axios.defaults.baseURL}/building/products/import/template/`, 'Шаблон спецификации.xlsx')
            }
          >
            Скачать шаблон
          </ButtonBase>
        </div>
      </div>
      <TableOslaLocal
        data={ImportList.results}
        pagination={false}
        rowClass={styles.tableRowClass}
        headerClass={styles.tableHeaderClass}
      >
        <TableHeader 
            style={{ width: '5%', textAlign: 'left', padding: '0 1.5rem' }}
            cellStyle={{ textAlign: 'left', width: '5%', padding: '1rem 1.5rem'}}
            numberGeneration={true}
        >
            <div className={styles.tableTextBold}>
                №
            </div>
        </TableHeader>
        <TableHeader 
              style={{ width: '25%', textAlign: 'left', padding: '0 1.5rem' }}
              cellStyle={{ textAlign: 'left', width: '25%', padding: '1rem 1.5rem'}}
              data={'name'}
              formatter={nameFormatter}
          >
              <div className={styles.tableTextBold}>
                  Наименование
              </div>
        </TableHeader>

        <TableHeader
          style={{ width: '15%', textAlign: 'left', padding: '0 1.5rem' }}
          cellStyle={{ textAlign: 'left', width: '15%', padding: '1rem 1.5rem'}}
          data={'creator'}
          formatter={(cell) =>
            getShortFullName(cell)
          }
        >
            <div className={styles.tableTextBold}>
              Пользователь
            </div>
        </TableHeader>
        <TableHeader 
          style={{ width: '10%', textAlign: 'left', padding: '0 1.5rem' }}
          cellStyle={{ textAlign: 'left', width: '10%', padding: '1rem 1.5rem'}}
          data={'log_journal'} 
          formatter={(cell) => cell?.total}>
            <div className={styles.tableTextBold}>
              Всего
            </div>
        </TableHeader>
        <TableHeader 
          style={{ width: '12%', textAlign: 'left', padding: '0 1.5rem' }}
          cellStyle={{ textAlign: 'left', width: '12%', padding: '1rem 1.5rem'}}
          data={'log_journal'} 
          formatter={(cell) => cell?.failed}>
            <div className={styles.tableTextBold}>
              Ошибки
            </div>
        </TableHeader>
        <TableHeader 
          style={{ width: '12%', textAlign: 'left', padding: '0 1.5rem' }}
          cellStyle={{ textAlign: 'left', width: '12%', padding: '1rem 1.5rem'}}
          data={'status_name'}>
            <div className={styles.tableTextBold}>
              Статус
            </div>
          </TableHeader>
        <TableHeader 
          style={{ width: '12%', textAlign: 'left', padding: '0 1.5rem' }}
          cellStyle={{ textAlign: 'left', width: '12%', padding: '1rem 1.5rem'}}
          data={'created_at'} 
          formatter={dateFormatter}>
            <div className={styles.tableTextBold}>
                Дата&nbsp;загрузки              
            </div>
        </TableHeader>
        <TableHeader data={'file'} formatter={saveFormatter}></TableHeader>
        <TableHeader formatter={deleteFormatter}></TableHeader>
      </TableOslaLocal>
    </div>
  );
};

export default Import;
