import React from "react";
import moment from "moment";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import { Spinner } from "../../../../../UI/Spinner/Spinner";
import Icon from "../../../../../UI/Icon";
import EmptyPlaceholder from '../../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder'

import { eventsTypes } from "../../../constants";
import { splitThousands } from "../../../../../../utils/splitThousands";
import { useUrlModuleWithObjectId } from "../../../../../../utils/hooks/useUrlModuleWithObjectId";

import { eventsBtnSvg } from '../../../CalendarHeader/eventsBtnSvg'
import loadSvg from "../../../../../../images/icons/loadingSvgPayment.svg";
import doneSvg from "../../../../../../images/icons/doneSvgLoad.svg";
import purpleLoading from "../../../../../../images/icons/loadPurpleSvg.svg";
import purpleDone from "../../../../../../images/icons/purpleDoneSvg.svg";
import arrSmallGray from "../../../../../../images/icons/arrSmallGray.svg";

import styles from "../../index.module.scss";
import { NavLinkIcon } from "components/UI/NavLinkIcon/NavLinkIcon";


const EventsList = ({ events, isEventsLoading }) => {
  const urlAdapter = useUrlModuleWithObjectId();

  const groupedEvents = React.useMemo(() => {
    return events.reduce((acc, event) => {
      const dateArr = acc.find((item) => item.date === event.date);
      if (dateArr) {
        dateArr.events.push(event);
      } else {
        acc.push({
          date: event.date,
          events: [event],
        });
      }
      return acc;
    }, []);
  }, [events]);

  if (isEventsLoading)
    return (
      <div className={styles.container}>
        <Spinner isFixed />
      </div>
    );

  if (events.length === 0) return <EmptyPlaceholder svgJsx={eventsBtnSvg} text={'Нет событий'} className={styles.eventsListEmptyPlaceholder} />;

  return (
    <div className={styles.container}>
      {groupedEvents.map((item) => (
        <React.Fragment key={item.date}>
          <>
            <div className={styles.sliderDayTitle}>
              События на <b>{moment(item.date).format("DD.MM.YY")}</b>
            </div>
            {item.events.map((event, i) => (
              <div
                key={event.date + i}
                className={cn(styles.sliderEvent, {
                  [styles.sliderEventPay]: event.event_type === "supply",
                })}
              >
                <div>
                  {!event.is_completed && event.event_type === "supply" && (
                    <div className={styles.notPayedSupplies}>
                      <div>
                        <img src={loadSvg} alt="" />
                      </div>
                      <div className={styles.notPayedSuppliesText}>Ожидает отгрузки</div>
                    </div>
                  )}

                  {event.is_completed && event.event_type === "supply" && (
                    <div className={styles.PayedSupplies}>
                      <div>
                        <img src={doneSvg} alt="" />
                      </div>
                      <div className={styles.PayedSuppliesText}>Принята</div>
                    </div>
                  )}
                  {!event.is_completed && event.event_type === "payment" && (
                    <div className={styles.NotPayedPayment}>
                      <div>
                        <img src={purpleLoading} alt="" />
                      </div>
                      <div className={styles.NotPayedPaymentText}>Ожидает оплаты</div>
                    </div>
                  )}
                  {event.is_completed && event.event_type === "payment" && (
                    <div className={styles.PayedPayment}>
                      <div>
                        <img src={purpleDone} alt="" />
                      </div>
                      <div className={styles.PayedPaymentText}>Оплачен</div>
                    </div>
                  )}
                  {(event.task?.status === "completed" || event.task?.status === "closed") && event.event_type === "task" && (
                    <div className={cn(styles.PayedPayment, styles.taskStatus)}>
                      <div>
                        <img src={purpleDone} alt="" />
                      </div>
                      <div className={styles.PayedPaymentText}>Выполнена</div>
                    </div>
                  )}
                  {(event.task?.status !== "completed" && event.task?.status !== "closed") && event.event_type === "task" && (
                    <div className={cn(styles.NotPayedPayment, styles.taskStatus)}>
                      <div>
                        <img src={purpleLoading} alt="" />
                      </div>
                      <div className={styles.NotPayedPaymentText}>Выполняется</div>
                    </div>
                  )}
                </div>
                <div>
                  <span
                    className={cn(styles.valueEvent, {
                      [styles.valueEventPay]: event.event_type === "supply",
                    })}
                  >
                    {eventsTypes[event.event_type]}
                  </span>
                  {event.event_type === "payment" && (
                    <div className={styles.paymentWrapper}>
                      <div className={styles.flexWithLink}>
                        <div className={styles.paymentBigFlex}>
                          <div className={styles.marginRightPaymentAndSupply}>
                            <div className={styles.flexPaymentSum}>
                              <div className={styles.MountTitlte}>Тип:</div>
                              <div className={styles.MountMoney}>{event.payment.payment_term.payment_type_name}</div>
                            </div>
                            <div className={styles.flexPaymentSum2}>
                              <div className={styles.MountTitlte}>Сумма:</div>
                              <div className={styles.MountMoney}>{splitThousands(event.payment.amount)}</div>
                            </div>
                          </div>
                          <div className={styles.paymentBorder}></div>
                        </div>
                        <div className={styles.sliderEventLink}>
                          <div className={styles.eventLink}>
                            <NavLink to={`/${urlAdapter}/documents/bills/${event.invoice.id}`}>
                              <div title={event.invoice.number} className={styles.blockIcon}>
                                <span>Счет №{event.invoice.number}</span>
                                <Icon icon={arrSmallGray} />
                              </div>
                            </NavLink>
                          </div>

                          <div className={styles.eventLink}>
                            <NavLink to={`/${urlAdapter}/orders/${event.order.id}`}>
                              <div title={event.order.number} className={styles.blockIcon}>
                                <span>Заказ №{event.order.number}</span>
                                <Icon icon={arrSmallGray} />
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {event.event_type === "supply" && (
                    <div className={styles.cardRowsWrapper}>
                      <div className={styles.row}>
                        <div className={styles.wrapperTitleText}>
                          <div className={styles.supplyerTitle}>Поставщик:</div>
                          <div className={styles.objectTitle}>Объект:</div>
                        </div>
                        <div className={styles.wrapperTitleContent}>
                          <div className={styles.supplyerContent}>{event.packinglist.provider.name}</div>
                          <div className={styles.objectContent}>{event.building.name}</div>
                        </div>
                      </div>
                      <div className={styles.flexWithLink}>
                        <div className={styles.flexLilSupply}>
                          <div className={styles.flexSupplySum}>
                            <div className={styles.MountTitlte}>Сумма:</div>
                            <div className={styles.MountMoney}>{splitThousands(event.packinglist_amount)}</div>
                          </div>
                          <div className={styles.supplyBorder}></div>
                        </div>
                        <div className={styles.sliderEventLink}>
                          <div className={styles.eventLinkSupply}>
                            <NavLink to={`/${urlAdapter}/documents/packing-lists/${event.packinglist.id}`}>
                              <div title={event.packinglist.number} className={styles.blockIcon}>
                                <span>{event.packinglist.number ? `УПД № ${event.packinglist.number}` : "УПД №"} </span>
                                <Icon icon={arrSmallGray} />
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {event.event_type === "task" && (
                    <div className={cn(styles.cardRowsWrapper, styles.task)}>
                      <div className={styles.row}>
                        <div className={styles.title}>Название:</div>
                        <div className={styles.value} title={event.task.name}>
                          <NavLinkIcon
                            to={`/tasks/all/0/${event.task.id}`}
                            title={event.task.name}
                            target="_self"
                          />
                        </div>
                      </div>
                      <div className={styles.row}>
                        <div className={styles.title}>Проект:</div>
                        <div className={styles.value} title={event.task.building_name}>
                          {event.task.building_name}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        </React.Fragment>
      ))}
    </div>
  );
};

export default React.memo(EventsList);
