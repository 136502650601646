import React, { Fragment } from "react";
import cn from "classnames";

import styles from "../../Month.module.scss";
import Plan from "../Plan/Plan";
import Work from "../Work/Work";
import Material from "../Material/Material";
// import WorksBunch from '../WorksBunch/WorksBunch'

const Branch = ({
  branch,
  index = 0,
  offsetLeft = 0,
  objectId,
  isShownAsSection,
  isShownAsExpenditure,
  isShownSectionPlan,
}) => {
  return (
    <div className={cn(styles.branch, "calendar_branch")} style={{top: `${index * 3}rem`}}>
      {branch.days.map((item, branchIndex) => {
        return (
          <Fragment key={branchIndex}>
            {!isShownAsSection && !isShownAsExpenditure && isShownSectionPlan && item.plan?.isSectionPlan && (
              <Plan
                isSection
                branch={{ ...item, plan: { ...item.plan, type: "full" } }}
                offsetLeft={offsetLeft}
                objectId={objectId}
                isSectionPlan
              />
            )}
            {isShownAsSection && (
              <Fragment>
                {item.plan && (
                  <Plan
                    isSection
                    branch={item}
                    offsetLeft={offsetLeft}
                    objectId={objectId}
                  />
                )}
                {item.work && (
                  <Work
                    isSection
                    branch={item}
                    offsetLeft={offsetLeft}
                    objectId={objectId}
                    estimateCompletedPercent={branch.completedPercent}
                  />
                )}
                {item.material && (
                  <Material
                    isSection
                    branch={item}
                    offsetLeft={offsetLeft}
                    objectId={objectId}
                  />
                )}
                {/*{item.worksBunch && <WorksBunch isSection branch={item} offsetLeft={offsetLeft} objectId={objectId}/>}*/}
              </Fragment>
            )}
            {isShownAsExpenditure && (
              <Fragment>
                {item.plan && (
                  <Plan
                    branch={item}
                    offsetLeft={offsetLeft}
                    objectId={objectId}
                  />
                )}
                {item.work && (
                  <Work
                    branch={item}
                    offsetLeft={offsetLeft}
                    objectId={objectId}
                    estimateCompletedPercent={branch.completedPercent}
                  />
                )}
                {item.material && (
                  <Material
                    branch={item}
                    offsetLeft={offsetLeft}
                    objectId={objectId}
                  />
                )}
                {/*{item.worksBunch && <WorksBunch branch={item} offsetLeft={offsetLeft} objectId={objectId}/>}*/}
              </Fragment>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(Branch);
