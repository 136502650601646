import Axios from "axios"
import React from "react"
import { errorCatcher } from "utils/errorCatcher"

export const useMaterialsList = ({objectId, new_section_id, exp_work_id}) => {
    const [allMaterials, setAllMaterials] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [count, setCount] = React.useState(0)

    const [filters, setFilters] = React.useState(null)

    const getMaterialsParams = React.useMemo(() => {
        const params = {
            ...filters,
            section_id: new_section_id,
            related_work_id: exp_work_id,
            for_tickets: true,
            limit: 10
        }
        for (const key of Object.keys(params)) {
            if (!params[key]) {
                delete params[key];
            }
        }
        return params
    }, [filters, new_section_id, exp_work_id])

    React.useEffect(() => {
        setIsLoading(true)
        Axios.get(`/building/${objectId}/estimate/ticket/materials/`, { params: getMaterialsParams })
            .then(resp => {
                setAllMaterials(resp.data.results)
                setCount(resp.data.count)
            })
            .catch(errorCatcher)
            .finally(() => setIsLoading(false))
    }, [objectId, filters, new_section_id, exp_work_id])

    const filterHandler = (value, fieldName) => {
        setFilters(prev => ({
            ...prev,
            [fieldName]: value
        }))
    }

    const loadMoreHandler = () => {
        Axios.get(`/building/${objectId}/estimate/ticket/materials/`, { params: { ...getMaterialsParams, offset: allMaterials.length } })
            .then(resp => setAllMaterials(prev => [...prev, ...resp.data.results]))
    }

    return {
        allMaterials,
        isLoading,
        count,
        filterHandler,
        loadMoreHandler
    }
}