import React from "react";
import cn from "classnames";
import { compose } from "redux";

import { checkStringHasSymbol } from "../../../../utils/checkStringHasSymbol";
import { transformDigitToFinancial } from "../../../../utils/transformDigitToFinancial";

import styles from "./Difference.module.scss";

const Difference = ({ amount, percent }) => {
  const checkValueAndSetClasses = (value) => {
    const valueHasMinus = checkStringHasSymbol(String(value), "-");
    return ({ [styles.positive]: !valueHasMinus && value !== 0, [styles.negative]: valueHasMinus });
  };

  if (!amount && !percent) return (
    <span className={styles.nullValue}>&#8722;</span>
  );

  if (!percent) return (
    <div className={styles.difference}>
      <div className={compose(cn, checkValueAndSetClasses)(amount)}>
        {transformDigitToFinancial(amount, { withSign: +amount > 0, withFloat: true, withRuble: true })}
      </div>
    </div>
  );

  return (
    <div className={styles.difference}>
      <div className={compose(cn, checkValueAndSetClasses)(amount)}>
        {transformDigitToFinancial(amount, { withSign: +amount > 0, withFloat: true, withRuble: true })}
      </div>
      <div className={cn(styles.diffDenominator, checkValueAndSetClasses(percent))}>
        {transformDigitToFinancial(percent, { withSign: +amount > 0, withPercent: true, withFloat: true })}
      </div>
    </div>
  );
};

export default React.memo(Difference);