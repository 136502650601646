import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { compose } from "redux";

import {
  addLoadObjects,
  loadObjects,
  objectsLoadingSelector,
  objectsSelector,
  updateObjects,
} from "redux/modules/common/building/projects";

import { TableOslaLocal } from "components/UI/TableOslaCustom/TableOslaLocal";
import { TableHeader } from "components/UI/TotoRowTable/TableHeader/TableHeader";
import SearchFilterTable from "components/UI/atoms/SearchFilterTable";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ContactsModal from "components/UI/atoms/ContactsModal/ContactsModal";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import Person from "../../../../UI/molecules/Person/Person";

import { stopEventPropagation } from "utils/stopEventPropagation";

import settingGear from "images/icons/navigation/settingGear.svg";

import styles from "./ProjectsTable.module.scss";


let offset = 0;

function ProjectsTable() {
  const history = useHistory();
  const dispatch = useDispatch();
  const objects = useSelector(objectsSelector);
  const objectsLoading = useSelector(objectsLoadingSelector);

  const [personal, setPersonal] = useState(null);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    return () => {
      dispatch(updateObjects({ results: [] }));
    }
  }, []);
  

  const filterHandler = (val, name) => setFilter((prev) => ({ ...prev, [name]: val }));

  const hasNextPage = useMemo(() => objects.count > objects.results.length, [objects]);

  const loadMoreHandler = () => {
    offset += 10;
    dispatch(addLoadObjects({ offset }, filter));
  };

  const resetPersonal = useCallback(() => setPersonal(null), []);

  useEffect(() => {
    offset = 0;
    dispatch(loadObjects({ offset }, filter));
  }, [filter]);

  return (
    <>
      <div className={styles.wrapper}>
        <TableOslaLocal
          data={objects.results}
          pagination={false}
          tableClass={styles.table}
          headerClass={styles.table__header}
          rowClass={styles.table__row}
          isLoading={objectsLoading && objects.results.length === 0}
          isStaticLoader
          onRowClick={(row) => history.push(`project/${row.id}/passport`)}
          tableStyle={{ tableLayout: "fixed" }}
          emptyPlaceholder={<EmptyPlaceholder text="Нет проектов" img={settingGear} />}
          paginationHide
        >
          <TableHeader
            data="name"
            cellStyle={{ width: "35%" }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Наименование проекта</div>
              <SearchFilterTable
                title="Наименование компании"
                setFilter={(value) => {
                  filterHandler(value, "name");
                }}
              />
            </div>
          </TableHeader>
          <TableHeader
            data="entity_name"
            cellStyle={{
              width: "20%",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Организация</div>
              <SearchFilterTable
                title="Организация"
                setFilter={(value) => {
                  filterHandler(value, "entity_name");
                }}
              />
            </div>
          </TableHeader>
          <TableHeader
            data="address"
            cellStyle={{
              width: "24%",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Адрес</div>
            </div>
          </TableHeader>
          <TableHeader
            data="responsible_employee"
            cellStyle={{
              width: "18%",
            }}
            formatter={(cell) => (
              <div onClick={compose(() => setPersonal(cell), stopEventPropagation)}><Person cell={cell} /></div>
            )}
          >
            <div className={styles.table__titleBlock}>
              <div>Ответственный</div>
            </div>
          </TableHeader>
        </TableOslaLocal>
      </div>
      {hasNextPage && (
        <div className={styles.moreButton}>
          <ShowMoreButton 
            handleAdd={loadMoreHandler} 
            allCount={objects.count} 
            showedCount={objects.results.length} 
          />
        </div>
      )}
      {personal && (
        <ContactsModal
          isOpen 
          title="Контакты ответственного" 
          contacts={personal} 
          onClose={resetPersonal} 
        />
      )}
    </>
  );
}

export default React.memo(ProjectsTable);
