import React, { useEffect } from "react";
import cn from "classnames";

import Portal from "components/UI/atoms/Portal";

import useEscapeHandler from "../../../../utils/hooks/useEscapeHandler";

import { stopEventPropagation } from "utils/stopEventPropagation";

import CrossIcon from "images/icons/CrossIcon";

import styles from "./index.module.scss";

interface IProps {
  children: React.ReactNode;
  isOpen: boolean;
  closeHandler: () => void;
  className?: string;
  childrenContainerClassName?: string;
  containerClassName?: string;
}

const SliderModal = ({
  children,
  isOpen,
  closeHandler,
  className,
  childrenContainerClassName,
  containerClassName,
}: IProps) => {
  useEscapeHandler(closeHandler);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    }

    document.body.style.overflow = "auto";
  }, [isOpen]);

  return (
    <Portal>
      {isOpen && <div className={styles.shadow} onClick={closeHandler} />}
      <div
        onClick={stopEventPropagation}
        className={cn(styles.containerWrapper, className, {
          [styles.active]: isOpen,
        })}
      >
        <div className={styles.closeButtonBlock} onClick={closeHandler}>
          <CrossIcon width={"1rem"} color={"#fff"} />
        </div>
        <div className={cn(styles.container, containerClassName)}>
          <div
            className={cn(styles.childrenContainer, childrenContainerClassName)}
          >
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default React.memo(SliderModal);
