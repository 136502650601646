import React from "react";
import cn from "classnames";

import {
  MONTH_COLOR_MAP,
  MATERIALS_TAB_ID,
  WORKS_TAB_ID,
  MATERIALS_COLOR_MAP,
  ACCEPTED_MATERIAL,
  ON_STOCK_MATERIAL,
  PURCHASES_MATERIAL,
  STOCKLESS_MATERIAL,
  MATERIALS_STATUSES,
} from "../../constants";

import styles from "./index.module.scss";

const BigLegenda = ({ type = WORKS_TAB_ID }) => {
  return (
    <div className={styles.container}>
      <div className={styles.big}>
        {type === WORKS_TAB_ID && (
          <>
            <CompBox title={"План"} style={{ backgroundColor: MONTH_COLOR_MAP["default"] }} />
            <CompBox title={"Выполнено"} className={styles.doned} />
            <CompBox title={"Принято"} style={{ background: MONTH_COLOR_MAP["received"] }} />
            <CompBox title={"Подтверждено"} className={styles.confirmed} />
            <CompBox title={"К сдаче"} style={{ backgroundColor: MONTH_COLOR_MAP["topay"] }} />
            <CompBox title={"К оплате"} style={{ backgroundColor: MONTH_COLOR_MAP["paid"] }} />
          </>
        )}
        {type === MATERIALS_TAB_ID &&
          Object.entries(MATERIALS_STATUSES).map(([key, value]) => (
            <CompBox key={key} title={value.name} style={{ backgroundColor: MATERIALS_COLOR_MAP[key] }} />
          ))}
        <div className={styles.triangleRight}/>
      </div>
    </div>
  );
};

const CompBox = ({ title, style, className }) => {
  return (
    <div className={styles.box}>
      <div className={cn(styles.devider, className)} style={style}></div>
      <div className={styles.item}>{title}</div>
    </div>
  );
};
export default BigLegenda;
