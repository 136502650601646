import {EXPENDITURE_TYPES_NAMES} from 'utils/constant'

export const prepareSections = (sections) => {
  return sections.map((section) => ({
    ...section,
    isOpen: false
  }))
}

const getExpenditureParentSection = (section) => {
  const expenditureSection = JSON.parse(JSON.stringify(section))
  expenditureSection.name = 'Вид работ'
  expenditureSection.id = `${section.id}_virtual`
  expenditureSection.isOpen = false
  return expenditureSection
}

const prepareChildrenSections = (childrenSections) => {
  const preparedSections = prepareSections(childrenSections)

  return preparedSections.map((section) => {
    /* if (!section.subsection_count && section.expenditure_count) {
      const expenditureSection = getExpenditureParentSection(section)
      section.children = [expenditureSection]
      return section
    } */
    return section
  })
}

export const addSectionChildren = (sections, parentId, childrenSections) => {
  const parentSection = sections.find((section) => section.id === parentId)
  if (parentSection) {
    parentSection.children = prepareChildrenSections(childrenSections)
    parentSection.isOpen = true
  } else {
    sections.forEach((section) => {
      if (section.children) {
        addSectionChildren(section.children, parentId, childrenSections)
      }
    })
  }
}

const prepareExpenditurePlanFact = (planFact) => {
  const preparedPlanFact = {...planFact}
  for (const key in preparedPlanFact) {
    preparedPlanFact[key] = Number(preparedPlanFact[key])
  }
  return preparedPlanFact
}

const calculateExpenditurePlanFact = (sections) => {
  const planFact = sections.reduce((acc, section, idx) => {
    const preparedPlanFact = prepareExpenditurePlanFact(section.plan_fact_data)
    if (idx === 0) {
      acc = preparedPlanFact
    } else {
      for (const key in acc) {
        acc[key] += preparedPlanFact[key]
      }
    }
    return acc
  }, null)
  return planFact
}

export const prepareSectionExpenditures = (sections, parentId) => {
  const categorizedSections = sections.reduce((acc, section) => {
    if (!acc[section.expenditure_type]) {
      acc[section.expenditure_type] = [section]
    } else {
      acc[section.expenditure_type].push(section)
    }
    return acc
  }, {})

  const preparedSections = Object.entries(categorizedSections).map(([type, sections], idx) => {
    return {
      id: `${idx}_expenditure_${parentId}`,
      name: EXPENDITURE_TYPES_NAMES[type],
      plan_fact_data: calculateExpenditurePlanFact(sections),
      isOpen: false,
      children: sections
    }
  })

  return preparedSections
}

export const setSectionIsOpen = (sections, sectionId, isOpen) => {
  const parentSection = sections.find((section) => section.id === sectionId)
  if (parentSection) {
    parentSection.isOpen = isOpen
  } else {
    sections.forEach((section) => {
      if (section.children) {
        setSectionIsOpen(section.children, sectionId, isOpen)
      }
    })
  }
}

const getTableSection = (section, year) => {
  const monthInYear = 12

  let tableSection = {}
  for (let i = 0; i < monthInYear; i++) {
    const fact = Number(section.plan_fact_data[`fact_amount_${year}_${i + 1}`])
    const plan = Number(section.plan_fact_data[`plan_amount_${year}_${i + 1}`])

    const planFact = {
      [`plan_${i}`]: plan,
      [`fact_${i}`]: fact,
      [`diff_${i}`]: plan - fact
    }

    tableSection = {
      key: section.id,
      ...tableSection,
      ...planFact
    }
  }
  return tableSection
}

const getSectionsData = (sections, year) => {
  const preparedSections = []
  sections.forEach((section) => {
    preparedSections.push(getTableSection(section, year))
    if (section.isOpen) {
      const children = getSectionsData(section.children, year)
      preparedSections.push(...children)
    }
  })
  return preparedSections
}

export const prepareTableSections = (sections, year) => {
  const preparedSections = getSectionsData(sections, year)
  return preparedSections
}

export const getScrollPositionPercent = (data) => {
  if (typeof data === 'number') return data
  return (data.scrollPosition / data.containerWidth) * 100
}

export const prepareSectionsAllObjects = (rawData) => {
  const res = []
  for (let key in rawData) {
    res.push({
      id: key,
      name: rawData[key].name,
      subsection_count: 2,
      isOpen: false,
      isBuilding: true,
      plan_fact_data: rawData[key].plan_fact_data,
      children: rawData[key].sections?.map(el =>({...el, isOpen: false, building_id: key})) || []
    })
  }
  return res
}