import { Iemployee } from "types/personsTypes";
import {remToPx} from '../../../../utils/remToPx'

export const getMentionIndexInString = (text: string, selectionStart: number) => {
    const charArr = text.split("")
    let mentionIndex = charArr.reduce((result, char, index) => {
        if (
            char === "@" &&
            (index === 0 || charArr[index - 1] === " ") &&
            selectionStart > index 
        ) {
            const lastIndexOfDog = charArr.lastIndexOf("@")
            const rangeCharArrBetweenCaretAndDog = [...charArr].splice(lastIndexOfDog + 1, selectionStart - lastIndexOfDog - 1)
            const isRangeFilled = rangeCharArrBetweenCaretAndDog.some(el => el !== " ")
            if (!isRangeFilled) {
                result += 1;
            }
        }
        return result;
    }, -1);

    const lastSubstringInSelectedText = text.substring(0, selectionStart).split(" ").pop()

    if (lastSubstringInSelectedText && (lastSubstringInSelectedText[0] !== "@")) {
        mentionIndex = -1;
    }

    return mentionIndex
}

export const getMentionedSubsting = (text: string, mentionIndex: number) => {
    if (mentionIndex === -1) {
        return ""
    } else {
        return text.split(" ").filter((word) => word[0] === "@")[mentionIndex].substring(1)
    }
}

export const filterMentions = (text: string, mentionedUsers: Iemployee[]) => {
    const filteredMentions = mentionedUsers.filter(el => text.includes(el.last_name))
    return filteredMentions.map(el => el.id)
}

export const CHAT_BLOCK_BOUNDS = {
    right: remToPx(2),
    bottom: remToPx(2),
    left: -window.innerWidth + remToPx(14.19),
    top: -window.innerHeight + remToPx(5.7)
}