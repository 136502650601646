import { applyMiddleware, compose, createStore } from "redux"; // @ts-ignore

import persistState from "redux-localstorage";
import thunk from "redux-thunk";

import { rootReducer } from "./rootReducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export type InferPropertiesTypes<T> = T extends { [key: string]: infer U }
  ? U
  : never;

export type IActions<T extends { [key: string]: (...args: any[]) => any }> =
  ReturnType<InferPropertiesTypes<T>>;

const middlewares = [thunk];
const composeEnhancers =
  process.env.NODE_ENV === "development" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  persistState(["auth", "breadcrumbs"])
);
// @ts-ignore
const store = createStore(rootReducer, {}, enhancer);

export default store;
