import React from "react";
import { Field } from "react-final-form";
import cn from "classnames";

import CalendarRange from "../../../UI/molecules/CalendarRange";
import InputBase from "../../../UI/atoms/InputBase";

import { composeFieldValidators, maxLength, required } from "../../../../utils/validations";

import styles from "./EditProject.module.scss";
import useFormFocus from '../../../../utils/hooks/useFormFocus'
import TextareaAutosize from 'react-textarea-autosize'

function EditProjectPassport({ dateStart, dateEnd, setDefaultDateStart, setDefaultDateEnd, isDisabled = false }) {
  const inputRootRef = useFormFocus();

  return (
    <div
      ref={!isDisabled ? inputRootRef : null}
      className={cn(styles.fullWidth, { [styles.disabled]: isDisabled })}
    >
      <div className={styles.fullWidth}>
        <Field
          name="object_name"
          label="*Наименование объекта"
          validate={composeFieldValidators(required(), maxLength(255, 'Не более 255 символов'))}
          render={({ input, meta: { touched, error } }) => (
            <div className={styles.fullWidth}>
              <label>*Наименование объекта</label>
              <TextareaAutosize
                {...input}
              />
              {touched && error && (
                <div className={styles.errorMessage}>{error}</div>
              )}
            </div>
          )}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="address"
          label="*Адрес"
          component={InputBase}
          validate={composeFieldValidators(required(), maxLength(255))}
          classNameInput={styles.colorInput}
        />
        <Field
          name="permit_build"
          label="Разрешение на строительство"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="responsible_full_name"
          label="*Ответственный"
          component={InputBase}
          validate={composeFieldValidators(required(), maxLength(255))}
          classNameInput={styles.colorInput}
        />
        <div className={styles.date}>
          <label className={styles.calend}>Сроки строительства</label>
          <Field
            name="construction_date"
            component={CalendarRange}
            defaultDateStart={dateStart}
            setDefaultDateStart={setDefaultDateStart}
            defaultDateEnd={dateEnd}
            setDefaultDateEnd={setDefaultDateEnd}
          />
        </div>
      </div>
      <div className={styles.row}>
        <Field
          name="customer"
          label="Заказчик"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
        <Field
          name="developer"
          label="Застройщик"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
      </div>
      <div className={styles.row}>
        <Field
          name="general_contractor"
          label="Генеральный подрядчик"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
        <Field
          name="technical_supervision"
          label="Технический надзор"
          component={InputBase}
          validate={composeFieldValidators(maxLength(255))}
          classNameInput={styles.colorInput}
        />
      </div>
    </div>
  );
}

export default React.memo(EditProjectPassport);
