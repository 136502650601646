import axios from "axios";
import { message } from "antd";

import { ESTIMATE_STATES_IDS } from "../../../../../components/pages/Handler/constants";

import { errorCatcher } from "../../../../../utils/errorCatcher";
import { getExpendituresUrl } from "./utils/getExpendituresUrl";
import { queryParamsFormatter } from "utils/queryParamsFormatter";

const ESTIMATE_ITEMS_LIMIT = 500;

export function apiLoadBuildingSections(buildingId) {
  return axios
    .get(`/building/${buildingId}/sections/`, {
      params: { limit: ESTIMATE_ITEMS_LIMIT },
    })
    .then(
      ({ data }) => data,
      (error) => {
        console.log(error);
      }
    )
    .catch((e) => console.log(e));
}

export function apiLoadBuildingMaterialSections(
  buildingId,
  type = "for-requisition"
) {
  return axios
    .get(`/building/${buildingId}/materials/${type}/sections/`, {
      params: { limit: ESTIMATE_ITEMS_LIMIT },
    })
    .then(
      ({ data }) => data,
      (error) => {
        console.log(error);
      }
    )
    .catch((e) => console.log(e));
}

export function apiLoadBuildingMaterialSection(
  buildingId,
  sectionId,
  type = "for-requisition"
) {
  return axios
    .get(`/building/${buildingId}/materials/${type}/sections/${sectionId}/`, {
      params: { limit: ESTIMATE_ITEMS_LIMIT },
    })
    .then(
      ({ data }) => data,
      (error) => {
        console.log(error);
      }
    )
    .catch((e) => console.log(e));
}

export const apiLoadSections = (() => {
  let cancel;
  return (buildingId, estimateStateId, params) => {
    if (cancel) cancel();

    let url = `/building/${buildingId}/estimate/${estimateStateId}/sections/`;
    if (estimateStateId === ESTIMATE_STATES_IDS.PRODUCTION)
      url = `/building/${buildingId}/estimate/sections/`;
    return axios
      .get(url, {
        params: { limit: ESTIMATE_ITEMS_LIMIT, ...params },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
      .then(({ data }) => data);
  };
})();

export function apiLoadSection(buildingId, sectionId, estimateStateId) {
  let url = `/building/${buildingId}/estimate/${estimateStateId}/sections/${sectionId}/`;
  if (estimateStateId === ESTIMATE_STATES_IDS.PRODUCTION)
    url = `/building/${buildingId}/estimate/items/${sectionId}/`;
  return axios
    .get(url)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export const addMaterial = (buildingId, data) => {
  return axios
    .post(`/building/${buildingId}/materials/out-of-estimate/`, data)
    .catch(errorCatcher);
};

export const changeEstimateItemsStateRequest = (buildingId, payload) => {
  if (payload.from_state === ESTIMATE_STATES_IDS.DRAFT) payload.from_state = "";
  return axios.post(`/building/${buildingId}/estimate/change_state/`, payload);
};

export const changeEstimateItemsStateToProductionRequest = (
  buildingId,
  payload
) => {
  return axios.post(`/building/${buildingId}/estimate/items/confirm/`, payload);
};

export const getSections = (buildingId, config) => {
  return axios
    .get(`/building/${buildingId}/estimate/items/`, {
      params: { limit: ESTIMATE_ITEMS_LIMIT },
      ...config,
    })
    .then((res) => {
      return res?.data;
    })
    .catch(errorCatcher);
};

export const getSectionsExpenditure = (buildingId, sectionId, config) => {
  return axios
    .get(`/building/${buildingId}/estimate/items/${sectionId}/`, {
      params: { limit: ESTIMATE_ITEMS_LIMIT },
      ...config,
    })
    .then((response) => response.data)
    .catch(errorCatcher);
};

export function apiLoadExpenditures(
  buildingId,
  sectionId,
  config = {},
  type = "draft"
) {
  return axios
    .get(
      `/building/${buildingId}/estimate/${type}/sections/${sectionId}/expenditures/`,
      {
        params: { limit: ESTIMATE_ITEMS_LIMIT },
        ...config,
      }
    )
    .then(
      ({ data }) => data,
      (error) => {
        console.log(error);
      }
    )
    .catch(errorCatcher);
}

export function apiLoadTypeExpenditures(
  buildingId,
  params,
  estimateStateId = ESTIMATE_STATES_IDS.DRAFT
) {
  let url = `/building/${buildingId}/estimate/${estimateStateId}/expenditures/`;
  if (estimateStateId === ESTIMATE_STATES_IDS.PRODUCTION)
    url = `/building/${buildingId}/estimate/expenditures/`;
  return axios
    .get(url, {
      params: queryParamsFormatter(params, { limit: ESTIMATE_ITEMS_LIMIT }),
    })
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function apiCreateExpenditure(
  buildingId,
  sectionId,
  expenditure,
  type = "draft"
) {
  return axios
    .post(
      `/building/${buildingId}/estimate/${type}/sections/${sectionId}/expenditures/`,
      expenditure
    )
    .then(({ data }) => {
      message.success("Расценка успешно создана");
      return data;
    })
    .catch(errorCatcher);
}

export const changeExpenditureRequest = (ids, changedExpenditure) => {
  return axios.patch(
    `${getExpendituresUrl(ids)}${changedExpenditure.id}`,
    changedExpenditure
  );
};

export function deleteExpenditureRequest(ids) {
  const expendituresUrl = getExpendituresUrl(ids);
  return axios.delete(`${expendituresUrl}${ids.expenditure}/`);
}

export const changeEstimateItemStatusRequest = (buildingId, payload) => {
  if (payload.from_state === ESTIMATE_STATES_IDS.DRAFT) payload.from_state = "";
  return axios.post(`/building/${buildingId}/estimate/change_status/`, payload);
};

export const changeSectionRequest = (
  buildingId,
  estimateStateId,
  changedSection
) =>
  axios.patch(
    `/building/${buildingId}/estimate/${estimateStateId}/sections/${changedSection.id}`,
    changedSection
  );

export const getExpenditureSubMaterialsRequest = (buildingId, expenditureId) =>
  axios.get(`/building/${buildingId}/relates/expenditures/${expenditureId}/`);

export const getExpenditureKsRequest = (buildingId, expenditureId) =>
  axios
    .get(
      `/building/${buildingId}/estimate/expenditures/${expenditureId}/work_files/`
    )
    .then((response) => response.data.results);
