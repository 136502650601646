import React from "react";

import { Spinner } from "../../../../Spinner/Spinner";
import ButtonBack from "../../../../atoms/ButtonBack";
import Expandable from "../../../../atoms/Expandable/Expandable";
import MaterialBlockInWork from "../../../../organism/WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import ModalContainer from "../../../../organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import ExpandableFileInput from "../../../../../UI/molecules/ExpandableFileInput/ExpandableFileInput";
import WorkListItem from "../../../../../UI/organism/WorkOrMaterialsModals/components/WorkListItem/WorkListItem";
import WorkersList from "../../../../../UI/organism/WorkOrMaterialsModals/components/WorkersList/WorkersList";
import ManufacturingModalContentPlan from "../../../../../pages/Manufacturing/components/ManufacturingModal/ManufacturingModalContentPlan";
import ManufacturingModalContentFact from "../../../../../pages/Manufacturing/components/ManufacturingModal/ManufacturingModalContentFact";
import ButtonBase from "../../../../../UI/atoms/ButtonBase";
import ProductIntervalCardContent from "../../../../../pages/Journal/components/JournalDelivery/components/ProductIntervalCard/ProductIntervalCardContent";
import NavBar from "../../../../../UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";
import PlanEditing from "../../../../../pages/Manufacturing/components/ManufacturingModal/PlanEditing/PlanEditing";
import BottomControls from "../../../../../UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import { useCanEditPlan } from "../../../../../pages/Manufacturing/components/ManufacturingModal/PlanEditing/useCanEditPlan";
import { useApprovePlan } from "../../../../../pages/Manufacturing/components/ManufacturingModal/PlanEditing/useApprovePlan";
import { manufacturingModalStatusSwitcher } from "../../../../../pages/Manufacturing/components/ManufacturingModal/manufacturingModalStatusSwitcher";

import { useModalIntervalData } from "./useModalIntervalData";
import { splitThousands } from "../../../../../../utils/splitThousands";

import styles from "./IntervalModal.module.scss";

function IntervalModal({
  isOpen,
  onClose,
  expidentureId,
  expidentureObj,
  sectionName,
  activeModule = "fact-intervals",
  date_start,
  date_end,
  objectId,
  canDeleteFiles,
  loadProduct,
}) {
  const {
    fileRemoveHandler,
    fileUploadHandler,
    clearIntervalDataHandler,
    intervalDataFetcher,
    intervalClickHandler,
    backToIntervalsListHandler,
    list,
    intervalId,
    isLoading,
    data,
    files,
  } = useModalIntervalData({
    isOpen,
    buildingId: objectId,
    expenditureId: expidentureId,
    dateEnd: date_end,
    dateStart: date_start,
    activeModule,
  });

  const closeHandler = () => {
    onClose();
    clearIntervalDataHandler();
    setIsAddingNew(false);
    setIsEditingPlan(false);
  };

  const [isAddingNew, setIsAddingNew] = React.useState(false);
  const [isEditingPlan, setIsEditingPlan] = React.useState(false);
  const canEditPlan = useCanEditPlan();

  const approveCallback = (id) => {
    intervalDataFetcher(id);
    loadProduct && loadProduct();
  };

  const approveHandler = useApprovePlan(
    Number(objectId),
    expidentureId,
    data?.id,
    approveCallback
  );

  const closePlanEditingHandler = () => {
    setIsEditingPlan(false);
    intervalDataFetcher(data?.id);
  };

  if (isAddingNew) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={expidentureObj?.name}
        justification={data?.justification}
        statusColor={
          manufacturingModalStatusSwitcher({
            isPlan: activeModule !== "fact-intervals",
            status: data?.work_status,
          }).color
        }
        statusText={
          manufacturingModalStatusSwitcher({
            isPlan: activeModule !== "fact-intervals",
            status: data?.work_status,
          }).text
        }
      >
        {isAddingNew && (
          <NavBar
            isExist
            text="Вернуться к работе"
            onClick={() => setIsAddingNew(false)}
            className={styles.back}
          />
        )}
        {isAddingNew && (
          <ProductIntervalCardContent
            objectId={objectId}
            handleClose={() => setIsAddingNew(false)}
            directlySection={data?.section_id}
            directlyWork={data?.expenditure_id}
            isOpen={isOpen}
          />
        )}
      </ModalContainer>
    );
  }

  if (isEditingPlan) {
    return (
      <ModalContainer
        isOpen={isOpen}
        onClose={closeHandler}
        name={expidentureObj?.name}
        justification={data?.justification}
        statusColor={
          manufacturingModalStatusSwitcher({
            isPlan: activeModule !== "fact-intervals",
            status: data?.work_status,
          }).color
        }
        statusText={
          manufacturingModalStatusSwitcher({
            isPlan: activeModule !== "fact-intervals",
            status: data?.work_status,
          }).text
        }
      >
        <PlanEditing
          planCreator={data?.author}
          planCreatingDate={data?.created_at}
          productId={data?.expenditure_id}
          buildingId={objectId}
          intervalId={data?.id}
          planCount={data?.count}
          planDateStart={data?.start_at}
          planDateEnd={data?.end_at}
          onClose={closePlanEditingHandler}
          files={files}
          onDeleteFiles={fileRemoveHandler}
          onUploadFiles={fileUploadHandler}
          defaultMaterials={data?.planned_materials_list}
          defaultWorkers={data?.workers}
          defaultWorkersCounts={data?.count_workers_data}
        />
      </ModalContainer>
    );
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={closeHandler}
      name={expidentureObj?.name}
      justification={data?.justification}
      statusColor={
        manufacturingModalStatusSwitcher({
          isPlan: activeModule !== "fact-intervals",
          status: data?.work_status,
        }).color
      }
      statusText={
        manufacturingModalStatusSwitcher({
          isPlan: activeModule !== "fact-intervals",
          status: data?.work_status,
        }).text
      }
    >
      {isLoading && <Spinner />}
      {!intervalId && (
        <div className={styles.list}>
          {Array.isArray(list) &&
            list.map((el) => (
              <WorkListItem
                key={el.id}
                name={el.expenditure_name}
                measure={el.measure}
                passAmount={el.completed_amount}
                passCount={el.count}
                passDate={el.created_at}
                passName={el.author}
                acceptAmount={el.accepted?.[0]?.amount}
                acceptCount={el.accepted?.[0]?.count}
                acceptDate={el.accepted?.[0]?.created_at}
                acceptName={el.accepted?.[0]?.approve_user}
                hideAccepted={!el.accepted?.length}
                onClick={() => intervalClickHandler(el.id)}
              />
            ))}
        </div>
      )}
      {intervalId && !isLoading && (
        <>
          <NavBar
            onClick={backToIntervalsListHandler}
            className={styles.back}
            isExist={list?.length > 1}
          />
          {activeModule !== "fact-intervals" && (
            <ManufacturingModalContentPlan
              planCreator={data?.author}
              planCreatingDate={data?.created_at}
              measure={data?.measure}
              dateStart={data?.start_at}
              dateEnd={data?.end_at}
              projectAmount={data?.work_amount_with_materials}
              projectFullAmount={data?.expenditure_amount}
              projectFullCount={data?.expenditure_count}
              planCount={data?.count}
              planIsInWork={data?.work_status === "actived"}
              planApprover={data?.approved_user}
              planApprovingDate={data?.approved_at}
              isEditing={isEditingPlan}
            />
          )}

          {activeModule === "fact-intervals" && (
            <ManufacturingModalContentFact
              dateStart={data?.start_at}
              dateEnd={data?.end_at}
              measure={data?.measure}
              passingAuthor={data?.author}
              passingDate={data?.created_at}
              passingCount={data?.count}
              investedAmount={data?.work_amount}
              acceptedAmount={data?.accepted[0]?.amount}
              acceptedAuthor={data?.accepted[0]?.approve_user}
              acceptedCount={data?.accepted[0]?.count}
              acceptedDate={data?.accepted[0]?.created_at}
              planCreator={data?.from_interval?.author}
              planCreatingDate={data?.from_interval?.created_at}
              planCount={data?.from_interval?.count}
              projectAmount={data?.from_interval?.work_amount}
              planApprover={data?.from_interval?.approved_user}
              planApprovingDate={data?.from_interval?.approved_at}
            />
          )}

          <div className={styles.info}>
            <p className={styles.subtitle}>Раздел:</p>
            <p className={styles.value}>{sectionName}</p>
          </div>
          <WorkersList workers={data?.workers} />
          {data?.planned_materials_list?.length ? (
            <Expandable
              title={`Материалы: ${data?.planned_materials_list?.length}`}
            >
              {data?.planned_materials_list?.map((el) => (
                <MaterialBlockInWork
                  key={el.material?.id}
                  name={el.material?.name}
                  measure={el.material?.measure}
                  count={el.count}
                  amount={el.material?.estimate_expanditure?.price * el.count}
                />
              ))}
            </Expandable>
          ) : null}
          {data?.stock_using_materials?.length ? (
            <Expandable
              title={`Материалы: ${data?.stock_using_materials?.length}`}
            >
              {data?.stock_using_materials?.map((el) => (
                <MaterialBlockInWork
                  key={el.id}
                  name={el.stock_using_material?.product_building?.name}
                  measure={el.stock_using_material?.product_building?.measure}
                  count={el.count}
                  amount={el.amount}
                />
              ))}
            </Expandable>
          ) : null}
          <ExpandableFileInput
            files={files || []}
            onRemoveFileDirectly={fileRemoveHandler}
            onUploadFileDirectly={fileUploadHandler}
            canUploadFiles={false}
            canDeleteFiles={canDeleteFiles}
          />
          <BottomControls
            isExists={
              activeModule !== "fact-intervals" &&
              data?.work_status === "actived"
            }
          >
            <ButtonBase onClick={() => setIsAddingNew(true)}>
              Добавить запись в Журнал Учета
            </ButtonBase>
          </BottomControls>
          <BottomControls
            isExists={
              activeModule !== "fact-intervals" &&
              data?.work_status !== "actived" &&
              !data?.approved_at &&
              canEditPlan
            }
          >
            <ButtonBase secondary onClick={() => setIsEditingPlan(true)}>
              Редактировать
            </ButtonBase>
            &nbsp;&nbsp;&nbsp;
            <ButtonBase onClick={approveHandler}>Согласовать</ButtonBase>
          </BottomControls>
          <BottomControls isExists={data?.work_status === "to_payed"}>
            <div className={styles.sum}>
              Итого к оплате:
              <strong>{splitThousands(data?.total_sum_amount)}</strong>
            </div>
          </BottomControls>
        </>
      )}
    </ModalContainer>
  );
}

export default React.memo(IntervalModal);
