import axios from "axios"
import { IList } from "types/ListResponse"
import { Iemployee } from "types/personsTypes"
import { IchatMessageResponse, IgetChatMessagesParams } from "./types"

export const chatApi = {
    getMessages: (params: IgetChatMessagesParams, optionalParams: Record<string, number | string> | null = null) => {
        return axios.get<IList<IchatMessageResponse>>(`/chat_v2/${params.building_id}/${params.room}/`, { params: { limit: 100, ...optionalParams } })
    },
    postMessage: (params: IgetChatMessagesParams, text: string, mentions: number[]) => {
        return axios.post<IchatMessageResponse>(`/chat_v2/${params.building_id}/${params.room}/`, { text, mentions })
    },
    getSuggectedUsers: (buildingId: number, search: string) => {
        return axios.get<Iemployee[]>(`/building/${buildingId}/employees/`, { params: { search } })
    }
}