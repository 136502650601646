export const isEmpty = (value) =>
  value == null || (typeof value === "string" && value.trim().length === 0);

export const composeFieldValidators = (...validators) => (value, values) =>
  validators
    .filter(v => typeof v === "function")
    .reduce((error, validator) => error || validator(value, values), undefined);


export const required = (
  errorMessage = "Обязательное поле"
) => (value) => isEmpty(value) || value === false ? errorMessage : undefined;

export const maxLength = (
  max,
  errorMessage = "Слишком длинный"
) => withEmpty((value) => (value.length > max ? errorMessage : undefined));

export const minLength = (
  min,
  errorMessage = "Слишком короткий"
) => withEmpty((value) => (value.length < min ? errorMessage : undefined));

const withEmpty = (f) => (
  value,
  values,
  props
) => {
  if (!isEmpty(value)) {
    return f(value, values, props);
  } else {
    return undefined;
  }
};

export const mustBeNumber = (value = "") => {
  if (value.length === 0) return;

  if (isNaN(value)) return "Должно быть числом";
}

export const email = (value = "") => {
  if (value.length === 0) return;

  const emailSymbolCount = value.split("").reduce((acc, symbol) => {
    if (symbol === "@") return acc + 1;
    return acc;
  }, 0);

  if (emailSymbolCount !== 1) return "Некорректный email";
}

