import React from 'react'
import { chatApi } from 'redux/modules/common/chatV2/chatApi'
import { Iemployee } from 'types/personsTypes'
import { getMentionedSubsting, getMentionIndexInString } from './utils'

export const useChatMentions = (building: number, inputRef: React.RefObject<HTMLTextAreaElement>) => {
    const [suggestedUsers, setSuggestedUsers] = React.useState<Iemployee[]>([])
    const [mentionedSubstring, setMentionedSubstring] = React.useState("")
    const [mentionedIndex, setMentionedIndex] = React.useState(-1)
    const [mentions, setMentions] = React.useState<Iemployee[]>([])

    const inputMentionsHandler = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const mentionIndex = getMentionIndexInString(e.target.value, e.target.selectionStart)
        const substring = getMentionedSubsting(e.target.value, mentionIndex)
        const mentionedSubstr = mentionIndex !== -1 ? substring : ""
        setMentionedSubstring(mentionedSubstr)
        setMentionedIndex(mentionIndex)
        if (mentionIndex === -1) {
            setSuggestedUsers([])
        }
    }, [])

    const selectUserHandler = React.useCallback((user: Iemployee) => {
        if (!inputRef.current) return
        
        let nowIndex = -1;
        const wordArr = inputRef.current.value.split(" ")
        const newValue = wordArr
            .map((word, index) => {
                if (word[0] === "@") {
                    nowIndex += 1
                    if (nowIndex === mentionedIndex) {
                        return `@${user.last_name}${index >= wordArr.length - 1 ? " " : ""}`
                    }
                }
                return word
            })
            .join(" ")
        const newMentions = [...mentions]
        newMentions[mentionedIndex] = user
        setMentions(newMentions)
        inputRef.current.value = newValue
        setSuggestedUsers([])
        setMentionedSubstring("")
        setMentionedIndex(-1)
        inputRef.current.focus()
    }, [mentionedIndex, mentions])

    React.useEffect(() => {
        if (mentionedIndex === -1) return
        chatApi.getSuggectedUsers(building, mentionedSubstring)
            .then(({ data }) => {
                setSuggestedUsers(data)
            })
    }, [building, mentionedIndex, mentionedSubstring])

    return {
        suggestedUsers,
        inputMentionsHandler,
        selectUserHandler, 
        mentions, 
        setMentions
    }
}