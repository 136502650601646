import { compose } from "redux";
import axios from "axios";

import { errorCatcher } from "../../../../../../utils/errorCatcher";

import { getEmployeesRequestAction, getEmployeesSuccessAction } from "../actions";

const getEmployees = (orderId, options) => (dispatch) => {
  dispatch(getEmployeesRequestAction(options.approvalType));

  axios.get(`/${options.userType}/orders/${orderId}/${options.approvalType}/employees/`)
    .then((response) => {
      compose(dispatch, getEmployeesSuccessAction)(response.data, options.approvalType);
    })
    .catch(errorCatcher);
};

export { getEmployees };