import React, { FC, useMemo, useState } from "react";

import { getLocalizedDate } from "utils/getLocalizedDate";
import { transformDigitToFinancial } from "utils/transformDigitToFinancial";

import BlueLabel from "components/UI/atoms/BlueLabel";
import calendarIcon from "images/icons/calendarIcon.svg";

import { IRequest } from "../../OrdersComparison";

import styles from "./OrderRequestCard.module.scss";
import { transformPrice } from "../../utils";
import Select from "../../../../atoms/Select";

export interface IOrderRequestCardProps {
  request: IRequest;
}

const OrderRequestCard: FC<IOrderRequestCardProps> = ({ request }) => {
  const [activeSupplyIdx, setActiveSupplyIdx] = useState<number>(0);

  const supplyOptions = useMemo(
    () => request.offers && request.offers[0] ?
      request.offers[0].supplies?.map((x, index) => ({
        id: index,
        name: `Поставка ${index + 1}`,
        label: `Поставка ${index + 1}`,
      })) : [],
    [request.offers]
  );

  if(!(request.offers && request.offers[0])) return null

  const actualOffer = request.offers[0]

  const activeSupply = supplyOptions.length > 0 && actualOffer.supplies[activeSupplyIdx];

  return (
    <div className={styles.card}>
      {supplyOptions.length > 1 && (
        <div className={styles.selectSpace}>
          <Select
            options={supplyOptions}
            value={activeSupplyIdx}
            onChange={(newValue) => setActiveSupplyIdx(+newValue)}
          />
        </div>
      )}
      <header>
        <div className={styles.blockName}>Номенклатура:</div>
        <div className={styles.bold}>{request.name}</div>
      </header>
      <div className={styles.block}>
        <div className={styles.blockName}>Цена за ед.:</div>
        <div className={styles.bold}>{transformPrice(actualOffer.price)}</div>
      </div>
      <div className={styles.block}>
        <div className={styles.blockName}>Общая стоимость:</div>
        <BlueLabel className={styles.label} blueFont={undefined}>
          {transformPrice(actualOffer.amount)}
        </BlueLabel>
      </div>
      {
        activeSupply && (
          <div>
            <div>Поставки:</div>
            <div className={styles.supply}>
              <div>{`${activeSupply.count} ${actualOffer.measure}`}</div>
              <div>
                <img className={styles.calendar} src={calendarIcon} alt="иконка календаря" />
                {getLocalizedDate(activeSupply.date)}
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default React.memo(OrderRequestCard);
