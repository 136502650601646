import React, { useCallback } from "react";
import cn from "classnames";
import moment from "moment";

import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";
import MatchingIcon, { MatchingStatusesEnum } from "components/UI/atoms/MatchingIcon/MatchingIcon";
import JournalAprovingCountCell from "../JournalAprovingCountCell/JournalAprovingCountCell";
import JournalAcceptedRoundCell from "../JournalAcceptedRoundCell/JournalAcceptedRoundCell";

import { ITicketJournalDelivery } from "types/interfaces/Tickets";
import { sliceTextByConstraint } from "utils/sliceTextByConstraint";
import getShortFullName from "utils/getShortFullName";

import styles from "./JournalDeliveryRow.module.scss";


interface Iprops {
  ticket: ITicketJournalDelivery,
  objectId: string;
  haveApproveTicketPermission: boolean;
  onRowClick: (row: ITicketJournalDelivery) => void;
  onMatchingClick: (e: React.MouseEvent, ticket: ITicketJournalDelivery) => void;
}

const JournalDeliveryRow: React.FC<Iprops> = ({
  ticket,
  haveApproveTicketPermission,
  objectId,
  onRowClick,
  onMatchingClick,
}) => {
  const rowClickHandler = useCallback(() => {
    onRowClick(ticket);
  }, [onRowClick, ticket]);

  const getTicketMatchingStatus = (ticket: ITicketJournalDelivery) => {
    if (!ticket.expenditure_id) return MatchingStatusesEnum.outOfEstimate;
    if (!ticket.is_after_related) return MatchingStatusesEnum.auto;
    return MatchingStatusesEnum.manual;
  };

  const matchingClickHandler = useCallback((e?: React.MouseEvent) => {
    onMatchingClick(e!, ticket);
  },[onMatchingClick, ticket]);

  return (
    <TableReusableRow className={styles.row} onClick={rowClickHandler} isHoverable={false}>
      <TableReusableCell isNoBreak>{String(ticket.id)}</TableReusableCell>
      <TableReusableCell>
        <div
          className={cn(styles.roundLabel, {
            [styles.redLabel]: !ticket.exists_accepted,
          })}
        ></div>
      </TableReusableCell>
      <TableReusableCell className={styles.nameCell}>
        <div className={styles.title} title={ticket.title}>
          {ticket.title}
        </div>
        <MatchingIcon
          className={styles.matching}
          onClick={matchingClickHandler}
          status={getTicketMatchingStatus(ticket)}
        />
        <div className={styles.devider}></div>
      </TableReusableCell>
      <TableReusableCell isNoBreak>
        {getShortFullName(ticket.author)}
        <div className={styles.devider}></div>
      </TableReusableCell>
      <TableReusableCell className={styles.centered}>
        {moment(ticket.created_at).format("DD.MM.YY")}
        <div className={styles.devider}></div>
      </TableReusableCell>
      <TableReusableCell className={styles.centered}>
        {sliceTextByConstraint(ticket.measure, 8)}
        <div className={styles.devider}></div>
      </TableReusableCell>
      <TableReusableCell>
        {sliceTextByConstraint(ticket.count, 6)}
        {!!ticket.count && (
          <div className={styles.devider}></div>
        )}
      </TableReusableCell>
      <TableReusableCell>
        <JournalAprovingCountCell
          haveApproveTicketPermission={haveApproveTicketPermission}
          ticket={ticket}
          objectId={objectId}
        />
      </TableReusableCell>
      <TableReusableCell isCentered>
        <JournalAcceptedRoundCell ticket={ticket} />
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default React.memo(JournalDeliveryRow);
