import React, { useCallback, useState } from "react";
import cn from "classnames";

import { getIdentifier } from "../../../../../../utils/getIdentifier";

import OfferColumns from "../OfferColumns";
import OfferComponentColumns from "../OfferComponentColumns";
import OfferSupplyColumns from "../OfferSupplyColumns";

import styles from "../../index.module.scss";

const Offer = ({
                 offer,
                 setOffer,
                 deleteOffer,
                 requestMeasure,
                 kitRequestsMeasures,
                 createOfferSupply,
                 setSupply,
                 deleteSupply,
                 permissions,
               }) => {
  const [isComponentsOpen, setIsComponentsOpen] = useState(false);

  const handleComponentsOpening = useCallback(() => setIsComponentsOpen((prevState) => !prevState), []);

  const isShownCountRequestMeasure = !offer.is_kit && offer.measure !== requestMeasure;
  const offerIsKitWithNotKitMeasure = offer.is_kit && offer.measure !== "компл";

  const offerSuppliesWithoutFirst = offer.supplies.slice(1);

  return (
    <>
      <div className={cn(styles.offer)}>
        <OfferColumns
          offer={offer}
          setOffer={setOffer}
          handleKitComponentsOpening={handleComponentsOpening}
          deleteOffer={deleteOffer}
          requestMeasure={requestMeasure}
          createOfferSupply={createOfferSupply}
          isShownCountRequestMeasure={isShownCountRequestMeasure}
          offerIsKitWithNotKitMeasure={offerIsKitWithNotKitMeasure}
          permissions={permissions}
        />
      </div>
      {offerSuppliesWithoutFirst.map((supply, index) => (
        <div className={cn(styles.offer)} key={getIdentifier(supply)}>
          <OfferSupplyColumns
            canEdit={permissions.editRequest}
            supply={supply}
            setSupply={setSupply}
            deleteSupply={deleteSupply}
            createSupply={createOfferSupply}
            canCreateSupply={index === offerSuppliesWithoutFirst.length - 1}
          />
        </div>
      ))}
      {offer.is_kit &&
        isComponentsOpen &&
        offer.components.map((offerComponent) => (
          <div className={cn(styles.offer)} key={getIdentifier(offerComponent)}>
            <OfferComponentColumns
              canEdit={permissions.editRequest}
              offer={offer}
              offerComponent={offerComponent}
              setOffer={setOffer}
              requestMeasure={kitRequestsMeasures[offerComponent.request_id]}
            />
          </div>
        ))}
    </>
  );
};

export default React.memo(Offer);
