import classNames from "classnames";
import React, { ChangeEventHandler } from "react";

import styles from "./Switch.module.scss";

type propsType = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string | number;
  checked: boolean;
  disabled?: boolean;
  label?: string | React.ReactNode;
  className?: string;
};

const SwitchComponent = ({ onChange, value, checked, disabled, label, className, ...props }: propsType) => {
  return (
    <div {...props} className={classNames(styles.container, className)}>
      {label && <label className={styles.label}>{label}</label>}
      <input
        className={styles.switcher}
        type="checkbox"
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
};

export const Switch = React.memo(SwitchComponent);
