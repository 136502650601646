import axios from 'axios';
import { createSelector } from 'reselect';

const moduleName = 'chat';
const SET_ITEMS = `${moduleName}/SET_ITEMS`;
const ADD_PREV_ITEMS = `${moduleName}/ADD_PREV_ITEMS`;
const ADD_ITEM = `${moduleName}/ADD_ITEM`;
const SET_IS_LOADING = `${moduleName}/SET_IS_LOADING`;
const READ_ALL = `${moduleName}/READ_ALL`;
const RESET_TO_INITIAL = `${moduleName}/RESET_TO_INITIAL`;

const initialState = {
  unreadValue: 0,
  allMessages: [],
  isLoading: true,
  maxCount: 0,
  dynamicUnreadMessages: [],
  dynamicMaxMessages: [],
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_ITEMS:
      return {
        ...state,
        allMessages: [...payload.results.reverse(), ...state.allMessages].reverse().reduce((result, item) => {
          if (!result.find((message) => message.id === item.id)) {
            result.push(item);
          }
          return result;
        }, []).reverse(),
        isLoading: false,
        maxCount: payload.count,
        unreadValue: payload.unread_count,
        dynamicMaxMessages: state.dynamicMaxMessages.filter(
          (item) => !payload.results.find((message) => message.id === item.id)
        ),
      };

    case ADD_PREV_ITEMS:
      return {
        ...state,
        allMessages: [...payload.results.reverse(), ...state.allMessages],
        isLoading: false,
        maxCount: payload.count,
        unreadValue: payload.unread_count,
      };

    case ADD_ITEM:
      return {
        ...state,
        allMessages: [...state.allMessages, payload.message],
        dynamicUnreadMessages: payload.unreadValue
          ? [...state.dynamicUnreadMessages, payload.unreadValue]
          : state.dynamicUnreadMessages,
        dynamicMaxMessages: [...state.dynamicMaxMessages, payload.message],
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    case READ_ALL:
      return {
        ...state,
        unreadValue: 0,
        dynamicUnreadMessages: [],
      };
    case RESET_TO_INITIAL:
      return initialState;
    default:
      return state;
  }
};

export const stateSelector = (state) => state.chat;
export const allMessagesSelector = createSelector(stateSelector, (state) => state.allMessages);
export const unreadValSelector = createSelector(stateSelector, (state) => state.unreadValue);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const maxCountSelector = createSelector(stateSelector, (state) => state.maxCount);
export const dynamicUnreadMessagesSelector = createSelector(
  stateSelector,
  (state) => state.dynamicUnreadMessages
);
export const dynamicMaxMessagesSelector = createSelector(
  stateSelector,
  (state) => state.dynamicMaxMessages
);

export const setMessages = (items) => ({
  type: SET_ITEMS,
  payload: items,
});

export const addMessages = (items) => ({
  type: ADD_PREV_ITEMS,
  payload: items,
});

export const addMessage = (item) => (dispatch, getState) =>
  dispatch({
    type: ADD_ITEM,
    payload: {
      message: item,
      unreadValue: parseFloat(item.sender.id) === parseFloat(getState().auth.user.id) ? null : item,
    },
  });

const setMessagesRead = () => ({
  type: READ_ALL,
});

const setIsLoading = () => ({
  type: SET_IS_LOADING,
});

export const getMessages = (urlType, doc_id, offset, more = false, callback) => {
  const config = {
    params: {
      limit: 50,
      offset,
    },
  };

  return (dispatch) => {
    dispatch(setIsLoading());
    axios.get(`/chat/${urlType}/${doc_id}/messages/`, config).then((res) => {
      if (!more) {
        callback?.(res.data);
      }
      more ? dispatch(addMessages(res.data)) : dispatch(setMessages(res.data));
    }).catch((error) => console.error(error));
  };
};

export const readMessages = (doc_type, doc_id) => {
  return (dispatch) =>
    axios.post(`/chat/${doc_type}/${doc_id}/messages/`).then((res) => {
      dispatch(setMessagesRead());
    });
};

export const resetToInitial = () => ({ type: RESET_TO_INITIAL });
