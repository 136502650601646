import React from 'react'
import styles from "./ConfirmBlock.module.scss"
import cn from "classnames"
import { declineSvg, acceptSvg } from "./icons"

interface IProps {
  count: number, 
  onAccept: React.MouseEventHandler, 
  onDecline: React.MouseEventHandler, 
  className?: string, 
  onCommonClick?: React.MouseEventHandler
}

function ConfirmBlock({ count, onAccept, onDecline, className, onCommonClick }: IProps) {
  return (
    <div className={cn(styles.container, className)} onClick={onCommonClick}>
            <button onClick={onDecline}>
                {declineSvg}
                Отменить
            </button>
            <button onClick={onAccept} disabled={!count}>
                {acceptSvg}
                Подтвердить: {count||0}
            </button>
    </div>
  )
}

export default ConfirmBlock