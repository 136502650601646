import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AddTaskByBuilding from "./components/AddTaskByBuilding/AddTaskByBuilding";
import ModalAddTask from "./components/ModalAddTask/ModalAddTask";
import ModalEditTask from "./components/ModalEditTask/ModalEditTask";
import ModalViewTask from "./components/ModalViewTask/ModalViewTask";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import SliderModal from "components/UI/atoms/SliderModal";
import NavBar from "components/UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";
import TabBarNotLinks from "elements/navigation/TabBar/TabBarNotLinks";

import { MAIN_MODAL_TAB, TASKS_MODAL_TABS, TASK_MODAL_TYPES } from "../../constants";
import { IIdAndName } from "types/idAndName";

import { useGetTask } from "../../hooks/useGetTask";
import { useTasksModal } from "../../hooks/useTasksModal";

import Check from "images/icons/Check";
import EditIcon from "images/icons/EditIcon";
import tasksAll from "images/menu-icons/tasksAll.svg";

import styles from "./TasksModal.module.scss";

export interface ITasksModalPops {
  presetBuildingId: number;
}

const TasksModal: React.FC<ITasksModalPops> = ({ presetBuildingId }) => {
  const addFormRef = useRef<HTMLFormElement>(null);
  const editFormRef = useRef<HTMLFormElement>(null);

  const [activeTab, setActiveTab] = useState<string>(MAIN_MODAL_TAB);

  const { onClose, type, isOpen, taskId, changeModalType, deleteFilesOnCancel } = useTasksModal();
  const { certainTask, isCertainTaskLoading } = useGetTask(taskId, isOpen);

  const defaultLocalBuildingState = useMemo(
    () => ({
      id: presetBuildingId || 0,
      name: "",
    }),
    [presetBuildingId]
  );

  const [localBuilding, setLocalBuilding] = useState<IIdAndName>(defaultLocalBuildingState);

  useEffect(() => {
    setLocalBuilding(defaultLocalBuildingState);
  }, [defaultLocalBuildingState]);

  const isDeletedTask = useMemo(
    () => type !== TASK_MODAL_TYPES.ADD && !isCertainTaskLoading && !certainTask,
    [certainTask, isCertainTaskLoading, type]
  );
  const isLoading = useMemo(() => isCertainTaskLoading && type !== TASK_MODAL_TYPES.ADD, [isCertainTaskLoading, type]);

  const handleSetLocalBuilding = useCallback(
    (newBuilding: IIdAndName) => setLocalBuilding(newBuilding),
    [setLocalBuilding]
  );

  const handleClose = useCallback(() => {
    if (onClose) onClose();
    setActiveTab(MAIN_MODAL_TAB);
    !presetBuildingId && setLocalBuilding(defaultLocalBuildingState);
    addFormRef.current && addFormRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));
    editFormRef.current && editFormRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));
  }, [onClose, addFormRef.current, editFormRef.current, presetBuildingId]);

  const handleCreateButton = useCallback(() => {
    if (!addFormRef.current) return;
    addFormRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  }, [addFormRef.current]);

  const handleSaveButton = useCallback(() => {
    if (!editFormRef.current) return;
    editFormRef.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  }, [editFormRef.current]);

  const handleEditButton = useCallback(() => changeModalType(TASK_MODAL_TYPES.EDIT), []);

  const handleCancelButton = useCallback(() => {
    if (type === TASK_MODAL_TYPES.EDIT) {
      changeModalType(TASK_MODAL_TYPES.VIEW);
      deleteFilesOnCancel();
    } else handleClose();
  }, [type]);

  const isCancelButtonShown = type === TASK_MODAL_TYPES.ADD || type === TASK_MODAL_TYPES.EDIT;
  const isCommonControlsShown = localBuilding.id !== 0 || type !== TASK_MODAL_TYPES.ADD;
  const isProjectsListShown = type === TASK_MODAL_TYPES.ADD && localBuilding.id === 0;

  if (isLoading) {
    return (
      <SliderModal isOpen={isOpen} closeHandler={handleClose} className={undefined}>
        <Spinner />
      </SliderModal>
    );
  }

  if (isDeletedTask) {
    return (
      <SliderModal isOpen={isOpen} closeHandler={handleClose} className={undefined}>
        <EmptyPlaceholder text="Задача была удалена" img={tasksAll} />
      </SliderModal>
    );
  }

  return (
    <SliderModal isOpen={isOpen} closeHandler={handleClose} className={undefined}>
      <div className={styles.container}>
        <div className={styles.headline}>
          <p className={styles.taskName}>{type === TASK_MODAL_TYPES.ADD ? "Новая задача" : certainTask?.name}</p>
          {localBuilding.id !== 0 && localBuilding.name && (
            <div className={styles.back}>
              <NavBar
                isExist
                onClick={() => handleSetLocalBuilding({ id: presetBuildingId, name: "" })}
                text={localBuilding.name}
                textFontSize={1}
                textFontWeight={500}
              />
            </div>
          )}
          {isCommonControlsShown && (
            <TabBarNotLinks
              tabs={TASKS_MODAL_TABS}
              activeId={activeTab}
              onClick={setActiveTab}
              className={styles.tabs}
              disabled={undefined}
            />
          )}
        </div>
        <div className={styles.content}>
          {type === TASK_MODAL_TYPES.ADD && (
            <>
              <AddTaskByBuilding setBuilding={handleSetLocalBuilding} isHidden={!isProjectsListShown} />
              {!isProjectsListShown && (
                <ModalAddTask
                  formRef={addFormRef}
                  activeTab={activeTab}
                  presetBuildingId={localBuilding.id}
                  handleCloseModal={handleClose}
                />
              )}
            </>
          )}
          {type === TASK_MODAL_TYPES.VIEW && <ModalViewTask activeTab={activeTab} />}
          {type === TASK_MODAL_TYPES.EDIT && (
            <ModalEditTask formRef={editFormRef} activeTab={activeTab} handleCloseModal={handleClose} />
          )}
        </div>

        {isCommonControlsShown && (
          <div className={styles.footer}>
            {isCancelButtonShown && (
              <ButtonBase secondary className={cn(styles.button, styles.cancelButton)} onClick={handleCancelButton}>
                Отменить
              </ButtonBase>
            )}
            {type === TASK_MODAL_TYPES.ADD && (
              <ButtonBase primary className={cn(styles.button, styles.createButton)} onClick={handleCreateButton}>
                <Check width={"15"} color={"white"} />
                <span>Создать</span>
              </ButtonBase>
            )}
            {type === TASK_MODAL_TYPES.VIEW && (
              <ButtonBase primary className={cn(styles.button, styles.editButton)} onClick={handleEditButton}>
                <EditIcon width={"15"} color={"white"} />
                <span>Редактировать</span>
              </ButtonBase>
            )}
            {type === TASK_MODAL_TYPES.EDIT && (
              <ButtonBase primary className={cn(styles.button, styles.saveButton)} onClick={handleSaveButton}>
                Сохранить
              </ButtonBase>
            )}
          </div>
        )}
      </div>
    </SliderModal>
  );
};

export default TasksModal;
