import React, {useContext, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Spinner } from 'components/UI/Spinner/Spinner'

import {
  objectIdSelector,
  isLoadingSelector,
  sectionsSelector,
  planFactEstimateSelector,
  loadSections,
  loadPlanFactEstimate,
  clearData
} from 'redux/modules/common/constructing/finance/planFact'
import replaceAll from 'utils/replaceAll'
import { useParams } from 'react-router-dom'
import { ROUTES } from '../../../../../../constants/routes'

import Indicators from './components/Indicators'
import SectionRow from './components/SectionRow'
import planFact from "images/icons/navigation/plan-fact.svg";
import EmptyPlaceholder from "../../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder"
import MeasureBtn from '../../../../../UI/atoms/MeasureBtn/MeasureBtn'
import {PlanFactMeasureContext} from '../PlanFact'

import styles from './List.module.scss'

const PlanFactList = (props) => {
  const { children } = props
  const dispatch = useDispatch()
  const isLoading = useSelector(isLoadingSelector)
  const sections = useSelector(sectionsSelector)
  const planFactEstimate = useSelector(planFactEstimateSelector)
  const { objectId } = useParams()

  const {measure, toggleMeasure} = useContext(PlanFactMeasureContext)

  useEffect(() => {
    dispatch(loadSections(objectId))
    dispatch(loadPlanFactEstimate(objectId))

    return () => {
      dispatch(clearData())
    }
  }, [objectId])

  return (
    <div className={styles.listContainer}>
      <MeasureBtn measure={measure} onMeasureChange={toggleMeasure} className={styles.measureBtn}/>
      {children}
      <Indicators data={planFactEstimate} />
      {isLoading && <Spinner />}
      {(!isLoading && sections?.length) ? sections?.map((item) => (
        <Link
          key={item?.id}
          to={replaceAll(ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_ITEM, {
            ':objectId': objectId,
            ':sectionId': item.id
          })}
        >
          <SectionRow data={item} />
        </Link>
      )) : null}
      {(!isLoading && !sections?.length) ? <EmptyPlaceholder img={planFact} /> : null}
    </div>
  )
}

export default PlanFactList
