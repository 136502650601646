import React from "react";

import { DeleteButton } from "components/UI/DeleteButton/DeleteButton";

export const deleteColumnFormatter = ({ onDelete }) => (cell, row) => {

  return (
    <div title={`Нажать для ${!row.is_removed ? "удаления" : "восстановления"} товара`}>
      <DeleteButton onClick={onDelete(row.id)} is_removed={row.is_removed} />
    </div>
  );
};