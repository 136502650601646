import React from "react";

import { splitThousands } from "../../../../../../utils/splitThousands";

import InputBase, { VALUE_TYPES } from "../../../../../UI/atoms/InputBase";
import styles from "../index.module.scss";

export const amountWithTaxColumnFormatter =
  ({ canEdit, isPurchaser, onChange }) =>
  (_cellData, row) => {
    const isShownEditableInput = !row.is_union && canEdit && isPurchaser;

    return (
      <div>
        {isShownEditableInput ? (
          <InputBase
            variant="secondary"
            valueType={VALUE_TYPES.PRICE}
            value={row.amount}
            onChange={onChange(row.id, "amount")}
            disabled={!isPurchaser || row.is_removed}
            classNameInput={styles.input}
          />
        ) : (
          <div style={{ width: "100%", textAlign: "right" }}>{splitThousands(row.amount, false, false)}</div>
        )}
      </div>
    );
  };
