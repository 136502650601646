import axios from "axios";
import { createSelector } from "reselect";
import { errorCatcher } from "utils/errorCatcher";

const moduleName = "projects";
const LOAD_OBJECTS = `${moduleName}/LOAD_OBJECTS`;
const SET_OBJECTS = `${moduleName}/SET_OBJECTS`;
const ADD_OBJECTS = `${moduleName}/ADD_OBJECTS`;
const SET_LOADING = `${moduleName}/SET_LOADING`;

const initialState = {
  objects: { results: [] },
  accepted: { results: [] },
  transferred: { results: [] },
  isLoading: false,
  loadingSave: false,
  events: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case LOAD_OBJECTS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_OBJECTS:
      return {
        ...state,
        [action.area || "objects"]: payload,
      };
    case ADD_OBJECTS:
      return {
        ...state,
        [action.area || "objects"]: {
          ...payload,
          results: [
            ...state[action.area || "objects"].results,
            ...payload.results,
          ],
        },
        isLoading: false,
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const objectsSelector = createSelector(
  stateSelector,
  (state) => state.objects
);
export const transferredSelector = createSelector(
  stateSelector,
  (state) => state.transferred
);
export const acceptedSelector = createSelector(
  stateSelector,
  (state) => state.accepted
);
export const objectsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.isLoading
);

export const updateObjects = (payload, area) => ({
  type: SET_OBJECTS,
  payload: payload,
  area: area,
});

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});

export const addLoadObjects = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios
        .get(`/projects/`, config)
        .then((response) =>
          dispatch({
            type: ADD_OBJECTS,
            payload: response.data,
            area: config.params.shared_status,
          })
        )
        .catch((e) => console.error(e));
    };
  };
})();

export const loadObjects = (paginationParams, filterParams) => {
  const config = { params: { ...paginationParams, ...filterParams } };
  return (dispatch, getState) => {
    if (!getState()?.projects?.objects?.results?.length) {
      dispatch({
        type: LOAD_OBJECTS,
      });
    }
    axios
      .get(`/projects/`, config)
      .then((response) => {
        dispatch(updateObjects(response.data, config.params.shared_status));
        !config.params.shared_status && dispatch(setLoading(false));
      })
      .catch(errorCatcher);
  };
};

export const loadMultipleObjects = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (objectsParams) => {
    if (ld_cancel) ld_cancel();
    return (dispatch, getState) => {
      Promise.all(
        objectsParams.map((params) => {
          if (!getState()?.projects?.objects?.results?.length) {
            dispatch({
              type: LOAD_OBJECTS,
            });
          }
          dispatch({
            type: LOAD_OBJECTS,
          });
          axios
            .get(`/projects/`, {
              params: {
                ...params["paginationParams"],
                ...params["filterParams"],
                ordering: params["ordering"],
              },
              cancelToken: new CancelToken((c) => {
                ld_cancel = c;
              }),
            })
            .then((response) => {
              dispatch(
                updateObjects(
                  response.data,
                  params?.filterParams?.shared_status
                )
              );
            })
            .catch((e) => console.error(e));
        })
      )
    };
  };
})();

export const changeObjectStatus = (id, status, params) => {
  return (dispatch) => {
    return axios
      .post(`/building/${id}/estimate/items/entities/change_status/`, {
        status,
      })
      .then(dispatch(loadMultipleObjects(params)))
      .catch(errorCatcher);
  };
};
