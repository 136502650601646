import { compose } from "redux";
import axios from "axios";

import { deleteFileAction } from "../actions";

const deleteFile = (orderId, fileId) => (dispatch, getState) => {
  const isPurchaser = getState().auth.entity.type === "pcr";
  const type = isPurchaser ? "purchaser" : "provider";

  axios.delete(`/${type}/orders/${orderId}/files/${fileId}/`);
  compose(dispatch, deleteFileAction)(fileId);
};

export { deleteFile };