import React, { useCallback } from "react";
import { compose } from "redux";
import moment from "moment";
import cn from "classnames";

import { getLocalizedDate } from "../../../../../../../../../utils/getLocalizedDate";
import EditIcon from "../../../../../../../../../images/icons/EditIcon";

import { checkIsDateToday } from "../../utils/checkIsDateToday";
import { checkIsDateWeekend } from "../../utils/checkIsDateWeekend";

import styles from "./WorkDayHeaderCell.module.scss";

const WorkDayHeaderCell = ({ workDay, setChangedDay, isChangedDay, isChangeDisabled, isFullDisabled }) => {
  const workDayIsWeekend = checkIsDateWeekend(workDay);

  const onSetChangedDay = useCallback(
    () => setChangedDay({ date: workDay.date(), week: workDay.week() }),
    [workDay, setChangedDay]
  );

  const getLocalizedUpperCaseWeekday = (weekDay) => weekDay.locale("ru").format("ddd").toUpperCase();

  return (
    <div className={styles.workDay}>
      <div className={cn(styles.date, { [styles.weekend]: workDayIsWeekend })}>
        {checkIsDateToday(workDay) ? (
          <>
            <span>Сегодня</span>
            <span className={styles.numeric}>
            {getLocalizedUpperCaseWeekday(workDay)}. - {compose(getLocalizedDate, moment)()}
          </span>
          </>
        ) : (
          <>
            <span>{getLocalizedUpperCaseWeekday(workDay)}</span>
            <span className={styles.numericDay}>{getLocalizedDate(workDay)}</span>
          </>
        )}
      </div>
      {(!isFullDisabled && (!isChangedDay && !isChangeDisabled)) && (
        <EditIcon
          className={styles.editIcon}
          color={workDayIsWeekend ? "#8BD4FF" : "#606060"}
          onClick={onSetChangedDay}
        />
      )}
    </div>
  );
};

export default React.memo(WorkDayHeaderCell);
