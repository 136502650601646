import axios from "axios";
import { compose } from "redux";

import { addFileAction } from "../actions";

const addFile = (orderId, file) => async (dispatch, getState) => {
  const isPurchaser = getState().auth.entity.type === "pcr";
  const type = isPurchaser ? "purchaser" : "provider";
  const formData = new FormData();

  formData.append("files", file);

  const response = await axios.post(`/${type}/orders/${orderId}/files/`, formData);

  compose(dispatch, addFileAction)(response.data[0]);
};

export { addFile };