import React, { useMemo } from 'react';
import formatStringByPattern from "format-string-by-pattern";
import { Form, Field } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';

import { useOrganizationData } from './useOrganizationData';

import ButtonBack from 'components/UI/atoms/ButtonBack';
import TemplateBase from 'components/UI/templates/TemplateBase';
import ButtonBase from 'components/UI/atoms/ButtonBase';
import InputBase from 'components/UI/atoms/InputBase';
import OldSelect from 'components/UI/atoms/Select/OldSelect';
import Icon from 'components/UI/Icon';
import BankDetails from '../Profile/BankDetails';

import plusSmalCercle from 'images/icons/plusSmalCercle.svg';

import { maxLengthValidator } from 'utils/formHelpers/maxLengthValidator';
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";
import { composeFieldValidators, maxLength, required, minLength } from 'utils/validations';
import { vatTypeArray } from 'utils/constant';
import useFormFocus from 'utils/hooks/useFormFocus';

import styles from "./EditOrganization.module.scss";


function EditOrganization() {
  const urlId = useParams().id;
  const history = useHistory();
  const inputRootRef = useFormFocus();
  const fullUrlModule = useUrlModuleWithObjectId();

  const backLink = `/${fullUrlModule}/settings/profiles`;

  const isNew = urlId === "new";
  const isEdit = (new URLSearchParams(window.location.search).get("edit") === "true") || isNew;
  const { 
    taxes, 
    initialForm, 
    submitHandler, 
    bankDetails, 
    addbankDetails, 
    deleteEmptyHandler, 
    specialDeleteBankDetailHandler 
  } = useOrganizationData(urlId, isNew);

  const getIncludingVat = () => {
    return taxes?.map(item => {
      return {
        id: item.id,
        name: item.id,
        label: item.name,
      };
    }) || [];
  };

  return (
    <TemplateBase>
      <Form
        initialValues={initialForm}
        onSubmit={submitHandler}
        render={({ handleSubmit, dirty }) => (
          <div ref={inputRootRef}>
            <div className={styles.headline}>
              <ButtonBack link={backLink} />
              {!isNew && <h1>{initialForm?.name}</h1>}
              {isEdit && dirty && <ButtonBase className={styles.save} type="submit" onClick={handleSubmit}>
                Сохранить
              </ButtonBase>}
              {isEdit && dirty && <ButtonBase buttonPrimaryEmpty onClick={() => history.push(`/${fullUrlModule}/settings`)}>
                Отменить
              </ButtonBase>}
            </div>
            <div className={styles.nameInput}>
              <Field
                name="name"
                label="Наименование организации"
                component={InputBase}
                validate={composeFieldValidators(required(), maxLength(255))}
                classNameInput={styles.colorInput}
                disabled={!isEdit}
              />
            </div>
            <div className={styles.row}>
              <div className={styles.cellInRow1}>
                <Field
                  name="contact_full_name"
                  label="Контактное лицо"
                  component={InputBase}
                  validate={composeFieldValidators(maxLength(255))}
                  disabled={!isEdit}
                />
                <Field
                  name="contact_email"
                  label="Почта"
                  component={InputBase}
                  validate={composeFieldValidators(maxLength(255))}
                  disabled={!isEdit}
                  type="email"
                />
              </div>
              <Field
                name="contact_phone"
                label="Телефон"
                component={InputBase}
                validate={composeFieldValidators(maxLength(255))}
                disabled={!isEdit}
                parse={formatStringByPattern("+7 (999) 999 99 99")}
              />
              <div className={styles.select}>
                <Field
                  name="including_vat"
                  label="НДС"
                  component={OldSelect}
                  options={vatTypeArray() || []}
                  selfControlled={true}
                  validate={composeFieldValidators(required(), maxLength(255))}
                  disabled={!isEdit}
                />
              </div>
              <div className={styles.select}>
                <Field
                  name="tax_system"
                  label="Налогообложение"
                  component={OldSelect}
                  options={getIncludingVat() || []}
                  selfControlled={true}
                  validate={composeFieldValidators(required(), maxLength(255))}
                  disabled={!isEdit}
                />
              </div>
              <Field
                name="factaddress"
                label="Фактический адрес"
                component={InputBase}
                validate={composeFieldValidators(required(), maxLength(255))}
                disabled={!isEdit}
              />
            </div>
            <div className={styles.row}>
              <Field
                name="yur_name"
                label="Наименование юридического лица"
                component={InputBase}
                validate={composeFieldValidators(required(), maxLength(255))}
                disabled={!isEdit}
              />
              <Field
                name="itn"
                label="ИНН"
                component={InputBase}
                validate={composeFieldValidators(required(), minLength(10), maxLength(10))}
                disabled={!isEdit}
                formatOnBlur
                format={maxLengthValidator(10)}
              />
              <Field
                name="iec"
                label="КПП"
                component={InputBase}
                validate={composeFieldValidators(required(), minLength(9), maxLength(9))}
                disabled={!isEdit}
                formatOnBlur
                format={maxLengthValidator(9)}
              />
              <Field
                name="psrn"
                label="ОГРН/ОГРНИП"
                component={InputBase}
                validate={composeFieldValidators(required(), maxLength(13), minLength(13))}
                disabled={!isEdit}
                formatOnBlur
                format={maxLengthValidator(13)}
              />
              <Field
                name="address"
                label="Юридический адрес"
                component={InputBase}
                validate={composeFieldValidators(required(), maxLength(255))}
                disabled={!isEdit}
                formatOnBlur
              />
            </div>
            {(isEdit) ? (
              <div className={styles.add}>
              Банковские реквизиты
              {isEdit && (
                <button className={styles.btnAdd} onClick={addbankDetails}>
                  <Icon
                    icon={plusSmalCercle}
                  />
                </button>
              )}
              </div>
              ) : null
            }
            {bankDetails?.map((item, index) => (
              <BankDetails
                key={index}
                item={item}
                index={index}
                deleteEmptyHandler={deleteEmptyHandler}
                specialDeleteHandler={specialDeleteBankDetailHandler}
                isDisabled={!isEdit}
                isLast={bankDetails?.length <= 1}
                addNew={addbankDetails}
              />
            ))}
          </div>
        )}
      />
    </TemplateBase>
  )

}

export default EditOrganization