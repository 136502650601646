import React from 'react';

import styles from './NotificationDeleted.module.scss';


export interface IProps {
  handleClick: (event: React.MouseEvent, value: boolean) => void;
}

const NotificationDeleted = ({ handleClick }: IProps) => {
  return (
    <div className={styles.noteItem}>
      <div className={styles.deletedInner}>
        <div className={styles.deletedText}>
          Уведомление удалено. 
          <span onClick={(event) => handleClick(event, false)}>
            Восстановить
          </span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(NotificationDeleted);