import React from "react";
import cn from "classnames";

import { useChartMaterialModal } from "./useChartMaterialModal";

import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import ExpandableFileInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput";
import BottomControls from "../components/BottomControls/BottomControls";
import DayWorkerAndEvent from "../components/DayWorkerAndEvent/DayWorkerAndEvent";
import DoubleWorkers from "../components/DoubleWorkers/DoubleWorkers";
import InfoItem from "../components/InfoItem/InfoItem";
import ManufacturingModalDivider from "../components/ManufacturingModalDivider/ManufacturingModalDivider";
import ModalContainer from "../components/ModalContainer/ModalContainer";
import PairOfCountAndAmount from "../components/PairOfInfoItems/PairOfCountAndAmount";
import PeriodOfExecution from "../components/PeriodOfExecution/PeriodOfExecution";

import styles from "./ChartMaterialModal.module.scss";

type propsType = {
  isOpen: boolean;
  onClose: () => void;
  name?: string;
  objectId: number;
  expId: number;
  start_date: string;
  end_date: string;
};

function ChartMaterialModal({
  name,
  isOpen,
  onClose,
  objectId,
  expId,
  start_date,
  end_date,
}: propsType) {
  const { data, isLoading, files, addToRequisitionHandler } =
    useChartMaterialModal({ objectId, expId, isOpen, start_date, end_date });

  const isOnlyPlan = React.useMemo(() => {
    return !!(
      isOpen &&
      data?.planed_materials?.length &&
      !data?.on_stock?.length &&
      !data?.passed?.length &&
      !data?.payed?.length &&
      !data?.purchases?.length &&
      !data?.stockless?.length &&
      !data?.to_paid?.length
    );
  }, [data, isOpen]);

  const planItem = React.useMemo(() => {
    return !!data?.planed_materials?.length ? data?.planed_materials[0] : null;
  }, [data]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      name={name as string}
      justification={data?.justification}
    >
      {isLoading && <Spinner />}
      {!isLoading && data && (
        <>
          {planItem && (
            <>
              <ManufacturingModalDivider type="plan" />
              <DayWorkerAndEvent
                date={planItem.workinterval.created_at}
                label="Создал :"
                author={planItem.workinterval.author}
              />
              <PairOfCountAndAmount
                measure={data?.measure}
                count={planItem.count}
                amount={planItem.amount}
                labelAmount="Стоимость (по проекту):"
                labelCount="Кол-во (план):"
              />
              {isOnlyPlan && (
                <>
                  <PeriodOfExecution
                    dateEnd={planItem.workinterval.end_at}
                    dateStart={planItem.workinterval.start_at}
                  />
                  <PairOfCountAndAmount
                    measure={data?.measure}
                    count={data?.count}
                    amount={data?.estimate_amount}
                    labelAmount="Бюджет (по проекту):"
                    labelCount="Всего (по проекту):"
                  />
                  <InfoItem label="Работа:" value={data.related_work.name} />
                  <InfoItem label="Раздел:" value={data.section_name} />
                </>
              )}
            </>
          )}
          {!!data?.purchases?.length && (
            <div>
              <ManufacturingModalDivider
                type="order"
                isWithoutMarginTop={!data?.planed_materials?.length}
              />
              {data?.purchases.map((el) => (
                <div
                  className={cn({
                    [styles.event]: data?.purchases?.length > 1,
                  })}
                  key={el.id}
                >
                  <DayWorkerAndEvent
                    date={el.created_at}
                    label="Оформил :"
                    author={el.creator}
                  />
                  <PairOfCountAndAmount
                    measure={data?.measure}
                    count={el.confirm_count || el.count}
                    amount={el.amount}
                    labelAmount="Стоимость (по счету):"
                    labelCount="Кол-во (заказано):"
                  />
                </div>
              ))}
            </div>
          )}
          {!!data?.on_stock?.length && (
            <div>
              <ManufacturingModalDivider
                type="supply"
                isWithoutMarginTop={!data?.purchases?.length}
              />
              {data?.on_stock.map((el) => (
                <div
                  className={cn({ [styles.event]: data?.on_stock?.length > 1 })}
                  key={el.id}
                >
                  <DayWorkerAndEvent
                    date={el.delivery_date}
                    label="Принял :"
                    author={el.accepted_user}
                  />
                  <PairOfCountAndAmount
                    measure={data?.measure}
                    count={el.count_get || el.confirm_count || el.count}
                    amount={el.amount}
                    labelAmount="Стоимость (по факту):"
                    labelCount="Кол-во (поступило):"
                  />
                </div>
              ))}
            </div>
          )}
          {!!data?.stockless?.length && (
            <div>
              <ManufacturingModalDivider
                type="fromStock"
                isWithoutMarginTop={!data?.on_stock?.length}
              />
              {data?.stockless.map((el) => (
                <div
                  className={cn({
                    [styles.event]: data?.stockless?.length > 1,
                  })}
                  key={el.id}
                >
                  <DoubleWorkers
                    date={el.created_at}
                    worker1={el.who_issued_it}
                    worker2={el.who_accepted}
                  />
                  <PairOfCountAndAmount
                    measure={data?.measure}
                    count={el.confirm_count || el.count}
                    amount={el.amount}
                    labelAmount="Стоимость (по проекту):"
                    labelCount="Кол-во (выдано):"
                  />
                </div>
              ))}
            </div>
          )}
          {!!data?.payed?.length && (
            <div>
              <ManufacturingModalDivider
                type="acceptedMaterial"
                isWithoutMarginTop={!data?.stockless?.length}
              />
              {data?.payed.map((el) => (
                <div
                  className={cn({ [styles.event]: data?.payed?.length > 1 })}
                  key={el.id}
                >
                  <DayWorkerAndEvent
                    date={el.confirm_date!}
                    label="Принял:"
                    author={el.confirm_user}
                  />
                  <PairOfCountAndAmount
                    measure={data?.measure}
                    count={el.confirm_count || el.count}
                    amount={el.amount}
                    labelAmount="Стоимость (по проекту):"
                    labelCount="Кол-во (принято):"
                  />
                </div>
              ))}
            </div>
          )}
          {!!data?.to_paid?.length && (
            <div>
              <ManufacturingModalDivider
                type="toPay"
                isWithoutMarginTop={!data?.payed?.length}
              />
              {data?.to_paid.map((el) => (
                <div
                  className={cn({ [styles.event]: data?.to_paid?.length > 1 })}
                  key={el.id}
                >
                  <DayWorkerAndEvent
                    date={el.confirm_date!}
                    label="Принял:"
                    author={el.confirm_user}
                  />
                  <PairOfCountAndAmount
                    measure={data?.measure}
                    count={el.confirm_count || el.count}
                    amount={el.amount}
                    labelAmount="Стоимость (по проекту):"
                    labelCount="Кол-во (принято):"
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
      {!isOnlyPlan && !!files?.length && (
        <ExpandableFileInput
          files={files || []}
          canDeleteFiles={false}
          canUploadFiles={false}
        />
      )}
      <BottomControls isExists={isOnlyPlan}>
        {/* @ts-ignore  */}
        <ButtonBase onClick={addToRequisitionHandler}>
          Добавить в заявку
        </ButtonBase>
      </BottomControls>
    </ModalContainer>
  );
}

export default ChartMaterialModal;
