import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";

import {
  loadStockDetail,
  setStocksMeasure,
  stockDetailSelector,
  stockMeasureSelector,
} from "../../../redux/modules/common/building/stocks";

import { detailDataSelector, getDetail } from "../../../redux/modules/common/building/object/nowObject";
import TemplateBase from "components/UI/templates/TemplateBase";
import Receipt from "./components/Receipt";
import Income from "./components/Income";
import Expense from "./components/Expense";

import styles from "./Stocks.module.scss";
import Line from "images/icons/Line";
import { useUrlModule } from "../../../utils/hooks/useUrlModule";
import { WriteOff } from "./components/WriteOff/WriteOff";
import ButtonBase from "../../UI/atoms/ButtonBase";
import { Collect } from "./icons/Collect";
import { ActiveCollect } from "./icons/ActiveCollect";
import TabBar from "../../../elements/navigation/TabBar/TabBar";
import MeasureBtn from "./../../UI/atoms/MeasureBtn/MeasureBtn";
import { measureSwitcher } from "../../../utils/measureSwitcher";
import { formatNumberWithMeasure } from "../../../utils/formatNumberWithMeasure";
import { useLocationArray } from "../../../utils/hooks/useLocationArray";
import usePermission from "hooks/usePermission";
import {
  VIEW_MANUFACTURING_STOCKS_INCOMES,
  VIEW_MANUFACTURING_STOCKS_MOVINGS,
  VIEW_MANUFACTURING_STOCKS_OUTCOMES,
  VIEW_MANUFACTURING_STOCKS_TO_PRODUCTION,
} from "constants/permissions/manufacturingPermissions";
import ForbiddenPage from "components/routes/components/ForbiddenPage/ForbiddenPage";
import { compose } from "redux";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";

const Stock = () => {
  const { objectId, tab } = useParams();
  const dispatch = useDispatch();

  const module = useUrlModule();
  const history = useHistory();
  const match = useRouteMatch();
  const measure = useSelector(stockMeasureSelector);
  const locationArray = useLocationArray();

  const stockDetail = useSelector(stockDetailSelector);
  const objectDetail = useSelector(detailDataSelector);

  const haveIncomeTabPermission = usePermission(VIEW_MANUFACTURING_STOCKS_INCOMES);
  const haveOutcomeTabPermission = usePermission(VIEW_MANUFACTURING_STOCKS_OUTCOMES);
  const haveMovingsTabPermission = usePermission(VIEW_MANUFACTURING_STOCKS_MOVINGS);
  const haveToProductionTabPermission = usePermission(VIEW_MANUFACTURING_STOCKS_TO_PRODUCTION);

  const tabs = useMemo(() => {
    const res = [];
    if (haveIncomeTabPermission) res.push({ link: `/${module}/${objectId}/stocks/receipt`, text: "Поступления" });
    if (haveOutcomeTabPermission) res.push({ link: `/${module}/${objectId}/stocks/expense`, text: "Списания" });
    if (haveMovingsTabPermission) res.push({ link: `/${module}/${objectId}/stocks/income`, text: "Движение" });
    return res;
  }, [haveIncomeTabPermission, module, objectId, haveOutcomeTabPermission, haveMovingsTabPermission]);

  useEffect(() => {
    if (!haveIncomeTabPermission && haveOutcomeTabPermission) {
      history.replace(`/${module}/${objectId}/stocks/expense`);
    }
    if (!haveIncomeTabPermission && !haveOutcomeTabPermission && haveMovingsTabPermission) {
      history.replace(`/${module}/${objectId}/stocks/income`);
    }
    if (
      !haveIncomeTabPermission &&
      !haveOutcomeTabPermission &&
      !haveMovingsTabPermission &&
      haveToProductionTabPermission
    ) {
      history.replace(`/${module}/${objectId}/stocks/writeOff`);
    }
  }, []);

  useEffect(() => {
    if (!tab) {
      history.replace(`/${module}/${objectId}/stocks/receipt`);
    }
  }, [tab]);

  useEffect(() => {
    if (!objectDetail || !objectId) return;
    compose(dispatch, loadStockDetail)(objectDetail.stock_id);
  }, [objectDetail, objectId]);

  useEffect(() => {
    compose(dispatch, getDetail)(objectId);
  }, [objectId]);

  if (
    !haveIncomeTabPermission &&
    !haveOutcomeTabPermission &&
    !haveMovingsTabPermission &&
    !haveToProductionTabPermission
  ) {
    return (
      <TemplateBase>
        <ForbiddenPage />
      </TemplateBase>
    );
  }

  return (
    <TemplateBase>
        <ListPageHeaderTemplate>
          <TabBar tabs={tabs} />
          <div className={styles.rightSide}>
            {(locationArray.includes("receipt") || locationArray.includes("expense")) && (
              <span className={styles.line}>
                <Line height={29} width={3} color="#CECECE" />
              </span>
            )}
            {locationArray.includes("receipt") && "Итого поступило "}
            {locationArray.includes("expense") && "Итого выдано  "}
            {locationArray.includes("receipt") && (
              <span className={styles.count}>
                {formatNumberWithMeasure(stockDetail?.balance?.replenishment_amount, measure)}
              </span>
            )}
            {locationArray.includes("expense") && (
              <span className={styles.count}>
                {formatNumberWithMeasure(stockDetail?.balance?.using_amount, measure)}
              </span>
            )}
          </div>
          {haveToProductionTabPermission && (
            <div>
              <NavLink
                className={(isActive) => (isActive ? styles.activeWriteLink : "")}
                to={`/${module}/${objectId}/stocks/writeOff`}
              >
                <ButtonBase secondary medium className={styles.toWriteOff}>
                  <span>В производство </span>
                  {match.url === `/objects/${objectId}/stocks/writeOff` ? <ActiveCollect /> : <Collect />}
                </ButtonBase>
              </NavLink>
            </div>
          )}
          <MeasureBtn
            measure={measure}
            onMeasureChange={() => dispatch(setStocksMeasure(measureSwitcher(measure)))}
            className={styles.measure}
          />
        </ListPageHeaderTemplate>
        <Switch>
          <Route
            exact
            path={[
              `/${module}/${objectId}/stocks`,
              `/${module}/${objectId}/stocks/receipt`,
              `/${module}/${objectId}/stocks/receipt/:packingListId`,
            ]}
          >
            <Receipt stockId={stockDetail?.id} objectId={objectId} />
          </Route>
          <Route exact path={`/${module}/${objectId}/stocks/expense`}>
            <Expense stockId={stockDetail?.id} objectId={objectId} />
          </Route>
          <Route exact path={`/${module}/${objectId}/stocks/income`}>
            <Income stockId={stockDetail?.id} buildingId={objectId} />
          </Route>
          <Route exact path={`/${module}/${objectId}/stocks/writeOff`}>
            <WriteOff
              objectDetail={objectDetail}
              objectDetailId={objectDetail?.id}
              stockDetailId={stockDetail?.id}
              objectId={objectId}
            />
          </Route>
        </Switch>
    </TemplateBase>
  );
};

export default Stock;
