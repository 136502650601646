import React, { useRef } from "react";
import Portal from "components/UI/atoms/Portal";
import cn from "classnames";

import Icon from "components/UI/Icon";
import logoIcon from "images/icons/logoIcon.svg";
import useEscapeHandler from "utils/hooks/useEscapeHandler";
import useOnClickOutside from "hooks/useOnClickOutside";

import styles from "./Modal.module.scss";
import { DeleteIcon } from "./DeleteIcon";

const Modal = (props) => {
  const { children, isOpen, onClose, title = "", className, isShownLogo, closeOnClickOutside = true } = props;
  const ref = useRef();

  useEscapeHandler(() => onClose(false));
  useOnClickOutside(ref, () => {
    if (!closeOnClickOutside || !isOpen) return;
    onClose(false);
  });

  return (
    <>
      {isOpen && (
        <Portal>
          <div className={styles.shadow}>
            <div className={cn(styles.container, className)} ref={ref}>
              <header className={styles.header}>
                <div className={styles.title}>
                  {isShownLogo && <Icon icon={logoIcon} className={styles.title} />}
                  {title}
                </div>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose(false);
                  }}
                  className={`closeModal ${styles.crossIcon}`}
                >
                  <DeleteIcon />
                </span>
              </header>
              {children}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default React.memo(Modal);
