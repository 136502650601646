import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { currentUserPermissionsDataSelector } from "../reducer";

export const checkUserHavePermission =
  (permissionNameOrNames: string | string[]): ThunkAction<void, any, unknown, Action> =>
  (_, getState) => {
    const wrapper = (permissionName: string) => {
      const userPermissions = currentUserPermissionsDataSelector(getState());
      if (!userPermissions) return false;
      return userPermissions.indexOf(permissionName) !== -1;
    };

    if (Array.isArray(permissionNameOrNames)) return permissionNameOrNames.some(wrapper);
    return wrapper(permissionNameOrNames);
  };
