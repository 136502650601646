import React from "react";

import styles from "../index.module.scss";
import InputBase, { VALUE_TYPES } from "../../../../../UI/atoms/InputBase";
import { dropNonSignificantZeros } from "utils/dropNonSignificantZeros";

export const factCountColumnFormatter =
  ({ canEdit, isPurchaser, onChange }) =>
  (_cellData, row) => {
    const isShownEditableInput = !row.is_union && canEdit && isPurchaser;

    return (
      <div>
        {isShownEditableInput ? (
          <InputBase
            variant="secondary"
            valueType={VALUE_TYPES.NUMBER}
            value={dropNonSignificantZeros(row.count_get)}
            onChange={onChange(row.id, "count_get")}
            disabled={!isPurchaser || row.is_removed}
            classNameInput={styles.input}
          />
        ) : (
          <span>{dropNonSignificantZeros(row.count_get)}</span>
        )}
      </div>
    );
  };
