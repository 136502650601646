import { message } from "antd";
import React from "react";

export const useProjectApprovals = (urlId, isNew, objectDetail) => {
  const [employees, setEmployees] = React.useState({
    requisitionApprovalEmployees: [],
    orderApprovalEmployees: [],
    paymentApprovalEmployees: [],
    timesheetApprovalEmployees: [],
  });

  const [isDirty, setIsDirty] = React.useState(false);

  React.useEffect(() => {
    if (isNew || !objectDetail || !urlId) return;
    setEmployees({
      requisitionApprovalEmployees: objectDetail?.requisition_approval_employees
        ?.map((item) => {
          return {
            ...item,
            position:
              objectDetail?.requisitionchainapprovalemployees_set.find((chain) => chain.user === item.id)?.position ||
              null,
          };
        })
        ?.sort((a, b) => (a.position > b.position ? 1 : -1)),
      orderApprovalEmployees: objectDetail?.order_approval_employees
        ?.map((item) => {
          return {
            ...item,
            position:
              objectDetail?.orderchainapprovalemployees_set.find((chain) => chain.user === item.id)?.position || null,
          };
        })
        ?.sort((a, b) => (a.position > b.position ? 1 : -1)),
      paymentApprovalEmployees: objectDetail?.payment_approval_employees
        ?.map((item) => {
          return {
            ...item,
            position:
              objectDetail?.paymentchainapprovalemployees_set.find((chain) => chain.user === item.id)?.position || null,
          };
        })
        ?.sort((a, b) => (a.position > b.position ? 1 : -1)),
      timesheetApprovalEmployees: objectDetail?.timesheet_approval_employees
        ?.map((item) => {
          return {
            ...item,
            position:
              objectDetail?.timesheetchainapprovalemployees_set.find((chain) => chain.user === item.id)?.position ||
              null,
          };
        })
        ?.sort((a, b) => (a.position > b.position ? 1 : -1)),
    });
  }, [urlId, isNew, objectDetail]);

  const handleAddChainEmployee = (employes, type, prevEmployee) => {
    setIsDirty(true);
    let errorEmployees = [];
    let approvalEmployees = employees[type] || [];
    let prevIndex;

    employes.forEach((employee) => {
      if (approvalEmployees.find((item) => item.id === employee.id)) {
        errorEmployees.push(employee);
      }
    });

    if (errorEmployees.length === 0) {
      const employeesWithPosition = approvalEmployees.map((item, index) => {
        if (item.id === prevEmployee.id) {
          prevIndex = index;
          return { ...item, position: item.position || 1 };
        }
        return item;
      });

      employes[0].position = prevEmployee.position ? prevEmployee.position + 1 : 2;
      employeesWithPosition.splice(prevIndex + 1, 0, employes[0]);

      setEmployees((prev) => ({
        ...prev,
        [type]: employeesWithPosition,
      }));
    }

    errorEmployees.forEach((employee) => {
      message.error(`${employee.last_name} ${employee.first_name} ${employee.middle_name} уже есть в списке`);
    });
  };

  const deleteAgreementsEmployee = (employee, type) => {
    setIsDirty(true);
    const approvalEmployees = employees[type];
    const newEmployees = approvalEmployees
      .map((item) =>
        employee.position && item.position > employee.position
          ? {
              ...item,
              position: item.position - 1,
            }
          : item
      )
      .filter((stateEmployee) => stateEmployee.id !== employee.id);
    setEmployees((prev) => ({
      ...prev,
      [type]: newEmployees,
    }));
    message.success(
      `${employee.name || employee.last_name + " " + employee.first_name + " " + employee.middle_name} успешно удален`
    );
  };

  const handleChangeAgreementsEmployees = (items, type) => {
    setIsDirty(true);
    let errorEmployees = [];
    let newEmployees = [];

    const approvalEmployees = employees[type] || [];

    items.forEach((employee) => {
      if (approvalEmployees.find((item) => item.id === employee.id)) {
        errorEmployees.push(employee);
      } else {
        newEmployees.push({
          id: employee.id,
          name: employee.name,
          roles: employee.roles,
        });
      }
    });

    errorEmployees.forEach((employee) => {
      const name = employee.name || employee.last_name + " " + employee.first_name;
      message.error(`${name} уже есть в списке`);
    });

    setEmployees((prev) => ({
      ...prev,
      [type]: [...approvalEmployees, ...newEmployees],
    }));
  };

  const getValues = () => {
    const requisitionchainapprovalemployees_set = employees?.requisitionApprovalEmployees?.map((employee) => {
      return { user: employee.id, position: employee.position };
    });
    const orderchainapprovalemployees_set = employees?.orderApprovalEmployees?.map((employee) => {
      return { user: employee.id, position: employee.position };
    });
    const paymentchainapprovalemployees_set = employees?.paymentApprovalEmployees?.map((employee) => {
      return { user: employee.id, position: employee.position };
    });
    const timesheetchainapprovalemployees_set = employees?.timesheetApprovalEmployees?.map((employee) => {
      return { user: employee.id, position: employee.position };
    });

    return {
      name: objectDetail.name,
      entity: {
        //id: objectDetail.entity.id
      },
      requisitionchainapprovalemployees_set,
      orderchainapprovalemployees_set,
      paymentchainapprovalemployees_set,
      timesheetchainapprovalemployees_set,
    };
  };

  return {
    employees,
    handleAddChainEmployee,
    deleteAgreementsEmployee,
    handleChangeAgreementsEmployees,
    getValues,
    isDirty,
  };
};
