import axios from "axios";
import { createSelector } from "reselect";
import { REQUISITION_STATUS } from "../../../../utils/constant";
import { errorCatcher } from "../../../../utils/errorCatcher";

const moduleName = "requisitions";

const LOAD_REQUISITIONS = `${moduleName}/LOAD_REQUISITIONS`;
const SET_REQUISITIONS = `${moduleName}/SET_REQUISITIONS`;
const SET_NEW_REQUISITIONS = `${moduleName}/SET_NEW_REQUISITIONS`;
const SET_MORE_REQUISITIONS = `${moduleName}/SET_MORE_REQUISITIONS`;

const RESET_STATE_TO_INITIAL = `${moduleName}/RESET_STATE_TO_INITIAL`;

export const DEFAULT_LIMIT = 10;
const INITIAL_STATE = { requisitions: null, newRequisitions: null, isLoading: true };

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_REQUISITIONS:
      return { ...state, isLoading: true };
    case SET_REQUISITIONS:
      return { ...state, requisitions: payload, isLoading: false };
    case SET_NEW_REQUISITIONS:
      return { ...state, newRequisitions: payload };
    case SET_MORE_REQUISITIONS:
      return {
        ...state, requisitions: {
          ...state?.requisitions, results: [...state?.requisitions?.results, ...payload?.results]
        }, isLoading: false
      };
    case RESET_STATE_TO_INITIAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const requisitionsSelector = createSelector(stateSelector, (state) => state.requisitions);
export const newRequisitionsSelector = createSelector(stateSelector, (state) => state.newRequisitions);
export const requisitionsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);

export const loadRequisitionAction = () => ({ type: LOAD_REQUISITIONS });
export const resetStateToInitialAction = () => ({ type: RESET_STATE_TO_INITIAL });

const parseStatusParam = (statuses) => {
  let statusParam = "?status=";

  statuses.forEach((statusItem, i) => {
    statusParam += statusItem;
    if (i !== statuses.length - 1) statusParam += ",";
  });

  return statusParam;
};

export const loadRequisitions = ({ statuses, ...params }) => {
  let url = "/requisition/";
  if (statuses) url += parseStatusParam(statuses);

  return (dispatch) => {
    dispatch(loadRequisitionAction());
    axios
      .get(url, { params: { ...params, limit: DEFAULT_LIMIT } })
      .then((response) => dispatch({ type: SET_REQUISITIONS, payload: response.data }))
      .catch(errorCatcher);
  };
};

export const loadMoreRequisitions = ({ statuses, ...params }) => {
  let url = "/requisition/";
  if (statuses) url += parseStatusParam(statuses);

  return (dispatch) => {
    axios
      .get(url, { params: { ...params, limit: DEFAULT_LIMIT } })
      .then((response) => dispatch({ type: SET_MORE_REQUISITIONS, payload: response.data }))
      .catch(errorCatcher);
  };
};

export const loadNewRequisitions = (params) => {
  return (dispatch) => {
    return axios
      .get(`/requisition/?status=${REQUISITION_STATUS.NEW},${REQUISITION_STATUS.DRAFT}`, {
        params: {
          ...params,
          limit: DEFAULT_LIMIT
        }
      })
      .then((response) => {
        dispatch({ type: SET_NEW_REQUISITIONS, payload: response.data });
        return response.data;
      })
      .catch(errorCatcher);
  };
};