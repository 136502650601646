import { message } from "antd";
import { TASK_LISTS_TYPES } from "components/pages/Tasks/constants";
import { Dispatch } from "redux";
import { RootState } from "redux/rootReducer";
import { errorCatcher } from "utils/errorCatcher";
import {
  addCommentToTask,
  addMoreTasksByAllBuildings,
  addMoreTasksByBuilding,
  addMoreTasksByList,
  addNewTaskInAllBuildings,
  addNewTaskInCertainBuilding,
  addNewTaskInTasksList,
  createOneTaskList,
  deleteOneTaskInAllBuildings,
  deleteOneTaskInCertainBuilding,
  deleteOneTaskInTasksList,
  deleteOneTaskList,
  setCertainTask,
  setCertainTaskLoading,
  setTasksByAllBuildings,
  setTasksByBuilding,
  setTasksByList,
  setTasksLoading,
  updateCreatedTaskListId,
  updateTasksListInfo,
  setTasksFields,
  setTaskCommentsState,
  updateTaskInTasksList,
  updateTaskInAllBuildings,
  updateTaskInCertainBuilding,
  filterTasksBySectionActionCreator,
  setTaskSubmitted
} from './actions'
import { tasksApi } from "./api";
import {
  ADD_SECTION_TASK_FILTER,
  SET_ALL_TASKS_STATE,
  SET_MORE_TASKS_LISTS,
  SET_TASK_MODAL_FILE_ID_BUFFER,
  SET_TASKS_IN_LIST_LOADING,
  SET_TASKS_LISTS,
  SET_TASKS_INDICATORS_BY_BUILDING,
  SET_SECTIONS_FOR_BUILDING,
  SET_TASKS_AGGREGATIONS,
  SET_IS_TASKS_SECTIONS_BY_BUILDING_LOADING
} from "./tasks";
import {
  createOneTaskParams,
  deleteOneTaskParams,
  listOfTaskListsType,
  tasksListFilterType,
  tasksFieldsFilterType,
  updateOneTaskParams,
  ItaskAggregationsParams,
} from "./types";
import { generateTaskAggregationsParams } from "./utils";

export const loadTasksByBuilding =
  (params: tasksListFilterType, type: TASK_LISTS_TYPES, building: string) =>
    (dispatch: Dispatch, getState: () => RootState) => {
      if (!getState().tasks.tasks?.[building]?.[type]?.results?.length) {
        dispatch(setTasksLoading(true));
      }
      tasksApi
        .getTasks({ ...params, building, query_filter: type })
        .then((resp) => {
          dispatch(setTasksByBuilding(resp.data.results, resp.data.count, type, building));
        })
        .catch(errorCatcher)
        .finally(() => dispatch(setTasksLoading(false)));
    };

export const loadMoreTasksByBuilding =
  (params: tasksListFilterType, type: TASK_LISTS_TYPES, building: string, offset: number) => (dispatch: Dispatch) => {
    tasksApi.getTasks({ ...params, building, query_filter: type, offset }).then((resp) => {
      dispatch(addMoreTasksByBuilding(resp.data.results, resp.data.count, type, building));
    });
  };

export const loadTasksByAllBuildings =
  (params: tasksListFilterType, type: TASK_LISTS_TYPES) => (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().tasks.tasksByAllBuildings?.[type]?.results?.length) {
      dispatch(setTasksLoading(true));
    }
    tasksApi
      .getTasks({ ...params, query_filter: type, ordering: "building" })
      .then(({ data }) => {
        dispatch(setTasksByAllBuildings(data.results, data.count, type));
      })
      .catch(errorCatcher)
      .finally(() => dispatch(setTasksLoading(false)));
  };

export const loadMoreTasksByAllBuildings =
  (params: tasksListFilterType, type: TASK_LISTS_TYPES, offset: number) => (dispatch: Dispatch) => {
    tasksApi.getTasks({ ...params, query_filter: type, ordering: "building", offset }).then((resp) => {
      dispatch(addMoreTasksByAllBuildings(resp.data.results, resp.data.count, type));
    });
  };

export const loadTasksLists = () => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().tasks.tasksLists.results.length) {
    dispatch(setTasksLoading(true));
  }
  tasksApi
    .getTasksLists()
    .then(({ data }) => {
      dispatch({
        type: SET_TASKS_LISTS,
        payload: data,
      });
    })
    .finally(() => dispatch(setTasksLoading(false)));
};

export const loadMoreTasksLists = (offset: number) => (dispatch: Dispatch) => {
  tasksApi.getTasksLists({ offset }).then(({ data }) => {
    dispatch({
      type: SET_MORE_TASKS_LISTS,
      payload: data.results,
    });
  });
};

export const loadTasksByList = (params: tasksListFilterType) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().tasks.tasksInLists[params.lists as number]?.results?.length) {
    dispatch({
      type: SET_TASKS_IN_LIST_LOADING,
      payload: {
        listId: params.lists,
        status: true,
      },
    });
  }
  tasksApi
    .getTasks(params)
    .then(({ data }) => {
      dispatch(setTasksByList(data, params.lists as number));
    })
    .finally(() =>
      dispatch({
        type: SET_TASKS_IN_LIST_LOADING,
        payload: {
          listId: params.lists,
          status: false,
        },
      })
    );
};

export const loadMoreTasksByList = (params: tasksListFilterType) => (dispatch: Dispatch) => {
  tasksApi.getTasks(params).then(({ data }) => {
    dispatch(addMoreTasksByList(data.results, params.lists as number));
  });
};

export const editTasksList =
  (params: listOfTaskListsType, successCallback?: () => void) => (dispatch: Dispatch, getState: () => RootState) => {
    const fallbackState = { ...getState().tasks.tasksLists };
    dispatch(updateTasksListInfo(params));
    tasksApi
      .patchTasksList(params)
      .then(() => {
        message.success("Изменения сохранены");
        successCallback && successCallback();
      })
      .catch((e: any) => {
        errorCatcher(e);
        dispatch({
          type: SET_TASKS_LISTS,
          payload: fallbackState,
        });
      });
  };

export const deleteTasksList =
  (id: number, successCallback?: () => void) => (dispatch: Dispatch, getState: () => RootState) => {
    const fallbackState = { ...getState().tasks.tasksLists };
    dispatch(deleteOneTaskList(id));
    tasksApi
      .deleteTasksList(id)
      .then(() => {
        message.success("Успешно удалено");
        successCallback && successCallback();
      })
      .catch((e: any) => {
        errorCatcher(e);
        dispatch({
          type: SET_TASKS_LISTS,
          payload: fallbackState,
        });
      });
  };

export const createTasksList =
  (data: Omit<listOfTaskListsType, "id" | "tasks_count">, successCallback?: () => void) =>
    (dispatch: Dispatch, getState: () => RootState) => {
      const tempId = Math.random();
      const fallbackState = { ...getState().tasks.tasksLists };
      dispatch(createOneTaskList({ ...data, id: tempId }));
      successCallback && successCallback();
      tasksApi
        .createTasksList(data)
        .then((resp) => {
          message.success("Успешно создано");
          dispatch(updateCreatedTaskListId(tempId, resp.data.id));
        })
        .catch((e: any) => {
          errorCatcher(e);
          dispatch({
            type: SET_TASKS_LISTS,
            payload: fallbackState,
          });
        });
    };

export const deleteOneTask =
  ({ id, list_id, building_id, type, variant }: deleteOneTaskParams) =>
    (dispatch: Dispatch) => {
      switch (variant) {
        case "inLists":
          dispatch(deleteOneTaskInTasksList(id, list_id as number));
          break;
        case "byAllBuildings":
          dispatch(deleteOneTaskInAllBuildings(id, type!));
          break;
        case "byCertainBuilding":
          dispatch(deleteOneTaskInCertainBuilding(id, type!, building_id!));
          break;
      }
      tasksApi
        .deleteTask(id)
        .then(() => {
          message.success("Успешно удалено");
        })
        .catch((e: any) => {
          errorCatcher(e);
        });
    };

export const getOneTask = (id: number) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().tasks?.certainTasks?.[id]) {
    dispatch(setCertainTaskLoading(id, true));
  }
  return tasksApi
    .getTask(id)
    .then(({ data }) => {
      dispatch(setCertainTask(data));
    })
    .catch(errorCatcher)
    .finally(() => dispatch(setCertainTaskLoading(id, false)));
};

export const loadTasksFields = (params: tasksFieldsFilterType) => (dispatch: Dispatch, getState: () => RootState) => {
  tasksApi.getTasksFields(params).then(({ data }) => {
    dispatch(setTasksFields(data));
  }).catch(errorCatcher)
};

export const createTask =
  ({ data, variant, list_id, type, building_id }: createOneTaskParams) =>
    (dispatch: Dispatch, getState: () => RootState) => {
      const tempId = Math.random();
      const cachedStringifiedState = JSON.stringify(getState().tasks);
      const preparedData = { ...data, id: tempId };
      switch (variant) {
        case "inLists":
          dispatch(addNewTaskInTasksList(preparedData, list_id as number));
          break;
        case "byAllBuildings":
          dispatch(addNewTaskInAllBuildings(preparedData, type!));
          break;
        case "byCertainBuilding":
          dispatch(addNewTaskInCertainBuilding(preparedData, type!, building_id!));
          break;
      }
      tasksApi
        .createTask(data)
        .then((resp) => {
          message.success("Успешно создано");
          const stringifiedState = JSON.stringify(getState().tasks);
          const updatedStringifiedState = stringifiedState.replaceAll(
            JSON.stringify(preparedData),
            JSON.stringify(resp.data)
          );
          dispatch({
            type: SET_ALL_TASKS_STATE,
            payload: JSON.parse(updatedStringifiedState),
          });
          dispatch(setTaskSubmitted(true, resp.data.id))//@ts-ignore
          dispatch(getTasksAggregations(generateTaskAggregationsParams()))
        })
        .catch((e: any) => {
          errorCatcher(e);
          dispatch({
            type: SET_ALL_TASKS_STATE,
            payload: JSON.parse(cachedStringifiedState),
          });
        });
    };

export const createTaskComment =
  (text: string, task: number, creatorId: number, date: string, creator_name: string) =>
    (dispatch: Dispatch, getState: () => RootState) => {
      const tempId = Math.random();
      const cachedTaskComments = JSON.stringify(getState().tasks.certainTasks[task].task_comments);
      dispatch(addCommentToTask(text, task, creator_name, tempId));
      tasksApi.createTaskComment(text, task, creatorId, date).catch((e: any) => {
        errorCatcher(e);
        dispatch(setTaskCommentsState(task, JSON.parse(cachedTaskComments)));
      });
    };

export const updateTask =
  ({ id, data, variant, list_id, type, building_id }: updateOneTaskParams) =>
    (dispatch: Dispatch, getState: () => RootState) => {
      const currentUserId = getState().auth.user.id;
      const cachedStringifiedState = JSON.stringify(getState().tasks);
      switch (variant) {
        case "inLists":
          dispatch(updateTaskInTasksList(data, list_id as number));
          break;
        case "byAllBuildings":
          dispatch(updateTaskInAllBuildings(data, type!, currentUserId!));
          break;
        case "byCertainBuilding":
          dispatch(updateTaskInCertainBuilding(data, type!, building_id!, currentUserId!));
          break;
      }
      tasksApi
        .updateTask(id, data)
        .then((resp) => {
          message.success("Успешно обновлено");
          const stringifiedState = JSON.stringify(getState().tasks);
          const updatedStringifiedState = stringifiedState.replaceAll(JSON.stringify(data), JSON.stringify(resp.data));
          dispatch({
            type: SET_ALL_TASKS_STATE,
            payload: JSON.parse(updatedStringifiedState),
          });//@ts-ignore
          dispatch(getTasksAggregations(generateTaskAggregationsParams()))
        })
        .catch((e: any) => {
          errorCatcher(e);
          dispatch({
            type: SET_ALL_TASKS_STATE,
            payload: JSON.parse(cachedStringifiedState),
          });
        });
    };

export const getFilteredTasksBySection = (params: tasksListFilterType) => (dispatch: Dispatch) => {
  const formattedParams = { ...params, ordering: "building", limit: 100 };
  if (formattedParams.section_id) {
    delete formattedParams.section_id;
  }
  if (formattedParams.building) {
    delete formattedParams.building;
  }
  formattedParams.building_section = `${params.building}:${params.section_id}`;
  dispatch({
    type: ADD_SECTION_TASK_FILTER,
    payload: {
      building: params.building,
      section: params.section_id,
    },
  });
  tasksApi.getTasks(formattedParams).then(({ data }) => {
    dispatch(filterTasksBySectionActionCreator(data, params.section_id!, params.building!, params.query_filter!));
  });
};

export const deleteFilesFromTaskEditBuffer = () => (dispatch: Dispatch, getState: () => RootState) => {
  const { fileIdBufferEdit } = getState().tasks.modal;
  fileIdBufferEdit.forEach((fileId) => tasksApi.deleteTaskFile(fileId).catch(errorCatcher));
  dispatch({
    type: SET_TASK_MODAL_FILE_ID_BUFFER,
    payload: [],
  });
};

export const getTasksIndicatorsByBuilding = (building: number, filters: tasksListFilterType) => (dispatch: Dispatch) => {
  tasksApi.getByBuildingIndicators(building, filters)
    .then(({ data }) => {
      dispatch({
        type: SET_TASKS_INDICATORS_BY_BUILDING,
        payload: {
          building,
          data
        }
      })
    })
    .catch(errorCatcher)
}

export const getSectionsForBuilding = (building_id: number) => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().tasks.sectionsByBuildings[building_id]) {
    dispatch({
      type: SET_IS_TASKS_SECTIONS_BY_BUILDING_LOADING,
      payload: { building: building_id, status: true}
    })
  }
  tasksApi.getTasksFields({ building_id })
    .then(({ data }) => {
      dispatch({
        type: SET_SECTIONS_FOR_BUILDING,
        payload: {
          building: building_id,
          data: data.section
        }
      })
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch({
        type: SET_IS_TASKS_SECTIONS_BY_BUILDING_LOADING,
        payload: { building: building_id, status: false}
      })
    })
}

export const getTasksAggregations = (params: ItaskAggregationsParams) => (dispatch: Dispatch) => {
  tasksApi.getTasksAggregation(params)
    .then(({ data }) => {
      dispatch({
        type: SET_TASKS_AGGREGATIONS,
        payload: {
          data,
          key: `${params.section}_${params.building_id || 0}`
        }
      })
    }).catch(errorCatcher)
}