import axios from "axios";
import { createSelector } from "reselect";
import { errorCatcher } from "utils/errorCatcher";


const moduleName = 'notifications';
const SET_ITEMS = `${moduleName}/SET_ITEMS`;
const ADD_ITEM = `${moduleName}/ADD_ITEM`;
const READ_ITEM = `${moduleName}/READ_ITEM`;
const SET_IS_LOADING = `${moduleName}/SET_IS_LOADING`;
const ADD_ITEMS = `${moduleName}/ADD_ITEMS`;

export const initialState = {
  unreadValue: 0,
  eventCount: 0,
  allNotifications: [],
  isLoading: true,
  maxCount: 0
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_ITEMS:
      return {
        ...state,
        allNotifications: payload.results,
        isLoading: false,
        maxCount: payload.count,
        unreadValue: payload.unread_count,
        eventCount: payload.event_count,
      };

    case ADD_ITEMS:
      return {
        ...state,
        allNotifications: [...state.allNotifications, ...payload.results],
        isLoading: false,
        maxCount: payload.count,
        unreadValue: payload.unread_count
      };

    case ADD_ITEM:
      return {
        ...state,
        unreadValue: state.unreadValue + 1
      };


    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case READ_ITEM:
      const oldItem = state.allNotifications.find(notification => notification.id === payload);
      const newItem = {
        ...oldItem,
        is_read: true
      };
      const newArr = [...state.allNotifications];
      const oldIndex = newArr.indexOf(oldItem);
      newArr.splice(oldIndex, 1);
      newArr.splice(oldIndex, 0, newItem);
      return {
        ...state,
        allNotifications: newArr,
        unreadValue: state.unreadValue - 1
      };

    default:
      return state;
  }
};

export const stateSelector = state => state.notifications;
export const allNotificationsSelector = createSelector(stateSelector, state => state.allNotifications);
export const unreadValSelector = createSelector(stateSelector, state => state.unreadValue);
export const eventCountSelector = createSelector(stateSelector, state => state.eventCount);
export const isLoadingSelector = createSelector(stateSelector, state => state.isLoading);
export const maxCountSelector = createSelector(stateSelector, state => state.maxCount);

export const setItems = items => ({
  type: SET_ITEMS,
  payload: items,
});

export const addItems = items => ({
  type: ADD_ITEMS,
  payload: items,
});

export const addNotification = item => ({
  type: ADD_ITEM,
  payload: item,
});

export const setNotificationRead = id => ({
  type: READ_ITEM,
  payload: id,
});

export const setIsLoading = () => ({
  type: SET_IS_LOADING
});

export const getNotifications = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (offset, more = false) => {
    if (ge_cancel) ge_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
      params: {
        limit: 20,
        offset
      }
    };

    return dispatch => {
      dispatch(setIsLoading());
      axios.get(
        `/users/current/notifications/`,
        config
      ).then(res => {
        more ? dispatch(addItems(res.data)) :  dispatch(setItems(res.data));
      })
      .catch(errorCatcher)
    }
  };
})();

export const readNotification = (id) => {
  const data = {
    is_read: true
  };

  return dispatch => axios.patch(
    `/users/current/notifications/${id}/`,
    data,
  ).then(() => {
    dispatch(setNotificationRead(id));
  })
  .catch(errorCatcher)
};

export const hideNotification = (id, val) => {
  const data = {
    is_hidden: val
  };

  return () => axios.patch(
    `/users/current/notifications/${id}/`,
    data,
  )
};
