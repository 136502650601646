import React from "react";
import styles from "./AmountAndCount.module.scss";
import { formatNumberWithMeasure } from "../../../../../../utils/formatNumberWithMeasure";
import { dropNonSignificantZeros } from "../../../../../../utils/dropNonSignificantZeros";

function AmountAndCount({ amount, count, measure }) {
  const roundedCount = !!count
    ? dropNonSignificantZeros((+count).toFixed(4))
    : " - ";
  const formattedMeasure = amount
    ? formatNumberWithMeasure(amount, measure)
    : " - ₽";
  return (
    <div className={styles.container}>
      <div className={styles.count} title={roundedCount}>
        {roundedCount}
      </div>
      <div className={styles.divider} />
      <div className={styles.amount} title={formattedMeasure}>
        {formattedMeasure}
      </div>
    </div>
  );
}

export default AmountAndCount;
