import React, {MouseEventHandler, useMemo, useRef} from 'react'
import Xarrow from "react-xarrows";
import moment from "moment";

import Icon from "../../../../../../../../UI/Icon";
import {
  IDiagramIntervalLink,
  IDraggedInterval,
} from "../../../../useIntervalLinks";

import {DIAGRAM_ARROW_CONFIG, INTERVAL_MAX_Z_INDEX} from '../../../../../../constants'

import diagramAddLinkCircle from "../../../../../../../../../images/diagramAddLinkCircle.svg";

import styles from "./ConnectPointsWrapper.module.scss";

export interface IConnectPointsWrapperProps {
  intervalId: number;
  isAddingArrowAllowed?: boolean;
  hover: boolean;
  position: {
    x?: number;
    y?: number;
  };
  handleDragStart: MouseEventHandler;
  handleDragEnd: MouseEventHandler;
  handleDragging: MouseEventHandler;
  projectId: number;
  beingDraggedArrows: {
    [key: number]: IDraggedInterval;
  };
  projectArrows: IDiagramIntervalLink[];
  isLinkingEnabled: boolean;
  isSomeArrowBeingDragged: boolean;
  startDate?: string;
}

const ConnectPointsWrapper: React.FC<IConnectPointsWrapperProps> = ({
  intervalId,
  isAddingArrowAllowed,
  hover,
  position,
  handleDragStart,
  handleDragEnd,
  handleDragging,
  projectId,
  beingDraggedArrows,
  projectArrows,
  isLinkingEnabled,
  isSomeArrowBeingDragged,
  startDate,
}) => {
  const ref1 = useRef<HTMLDivElement>(null);

  const isBeingDragged = useMemo(
    () => beingDraggedArrows[intervalId]?.isBeingDragged,
    [beingDraggedArrows, intervalId]
  );

  const isAcceptPointShown = useMemo(() => {
    return (
      isLinkingEnabled &&
      intervalId &&
      (Object.entries(beingDraggedArrows).some(
        ([
          id,
          {
            isBeingDragged,
            projectId: draggedProjectId,
            startDate: draggedStartDate,
          },
        ]) =>
          id &&
          +id !== intervalId &&
          isBeingDragged &&
          draggedProjectId === projectId &&
          moment(draggedStartDate).isSameOrBefore(startDate, "day")
      ) ||
        projectArrows.some((x) => x.to_interval === intervalId))
    );
  }, [
    isLinkingEnabled,
    beingDraggedArrows,
    projectArrows,
    intervalId,
    projectId,
    startDate,
  ]);

  const isAddPointShown = useMemo(() => {
    return (
      isLinkingEnabled &&
      isAddingArrowAllowed &&
      (hover || isBeingDragged) &&
      (!isSomeArrowBeingDragged || isBeingDragged)
    );
  }, [
    isLinkingEnabled,
    hover,
    isAddingArrowAllowed,
    isSomeArrowBeingDragged,
    isBeingDragged,
    projectArrows,
    intervalId,
    beingDraggedArrows,
  ]);

  return (
    <React.Fragment>
      {isAddPointShown && (
        <div
          style={{
            position: isBeingDragged ? "fixed" : "absolute",
            left: position.x,
            top: position.y,
            transform: isBeingDragged ? "none" : "translate(30%, -50%)",
            pointerEvents: isBeingDragged ? "none" : "auto",
          }}
          draggable
          onDragStart={handleDragStart}
          onDrag={handleDragging}
          onDragEnd={handleDragEnd}
          onClick={handleDragStart}
          ref={ref1}
          className={styles.connectPoint}
        >
          <Icon icon={diagramAddLinkCircle} />
        </div>
      )}
      {isAcceptPointShown && (
        <div className={styles.acceptPoint} onClick={handleDragEnd} />
      )}
      {isBeingDragged && (
        <Xarrow
          {...DIAGRAM_ARROW_CONFIG}
          start={intervalId?.toString()}
          end={ref1}
          zIndex={INTERVAL_MAX_Z_INDEX + 2}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(ConnectPointsWrapper);
