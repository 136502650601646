export const percentsFormatter = (value) => {
    if (Number(value) === 0) return "0%"

    if (Number(value) >= 999) return "999%"

    if (!value||isNaN(Number(value))) return "-%"

    if (Number(value) > 0 && Number(value) < 1) return "<1%"

    return `${Math.round(+value)}%`
}