import React, { useState } from 'react';
import cn from 'classnames';

import Button from 'components/UI/atoms/ButtonBase';

import styles from './index.module.scss';

const ShowMoreButton = ({allCount, showedCount, handleAdd, limit = 10, isExists = true}) => {

  if (!isExists) return null

  return (
    <>
      {allCount > showedCount &&
        <div className={styles.boxButtonPaginator}>
          <Button
            onClick={handleAdd}
            className={styles.buttonPaginator}
          >
            Показать еще {allCount - showedCount > limit
              ? limit
              : allCount - showedCount} из {allCount - showedCount}
          </Button>
        </div>
      }
    </>
  )
}

export default ShowMoreButton;