export interface IKsModalProps {
  isOpen: boolean;
  onClose?: () => void;
  expenditureId: number | null;
  buildingId?: number | null;
  year?: number | string;
  month?: number;
  isPrefetch: boolean;
}

export interface IKs23ModalProps extends IKsModalProps {
  type: KsType | null;
}

export type KsType = keyof typeof KS_TYPES;

export enum KS_TYPES {
  ks2 = "ks2",
  ks3 = "ks3",
}

export interface IKs2Row {
  exp_number: number;
  name: string;
  justification: string;
  measure: string;
  sum_count_accepted: string | number;
  price: string | number;
  worth: string | number;
}

export interface IKs3Row {
  name: string;
  justification: string;
  total_price: string | number;
  year_price: string | number;
  month_price: string | number;
}

export interface IKsObject {
  rows: IKs2Row[] | IKs3Row[];
}