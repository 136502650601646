import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import cn from "classnames";
import { useDrag } from "react-dnd";
import { memoize } from "lodash";

import { userSelector } from "../../../../../../../redux/modules/common/auth";
import { intervalsLoadingSelector, loadIntervals } from "../../../../../../../redux/modules/common/building/process";
import { detailDataSelector } from "../../../../../../../redux/modules/common/building/object/nowObject";
import {
  expendituresAreLoadingSelector,
  expendituresSelector,
  loadExpenditures,
  resetExpendituresAction,
  resetSectionAction,
  sectionIsLoadingSelector,
  sectionSelector,
} from "../../../../../../../redux/modules/common/building/sections/sections";

import { stringifyArgs } from "../../../../../../../utils/stringifyArgs";
import PlusFillCircleIcon from "../../../../../../../images/icons/PlusFillCircleIcon";

import { Spinner } from "../../../../../../UI/Spinner/Spinner";
import { VARIANTS } from "../../../../../../UI/molecules/Expenditure/constants";
import Amount from "../../../../../../UI/atoms/Amount/Amount";

import { buildExpendituresDictionaryByIds } from "../../../../utils/buildExpendituresDictionaryByIds";
import { getSectionAmount } from "../../../../utils/getSectionAmount";
import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../constants";

import EmptyMessage from "../../../EmptyMessage/EmptyMessage";

import ManageExpenditureModal, {
  VARIANTS as MANAGE_EXPENDITURE_MODAL_VARIANTS,
} from "./components/ManageExpenditureModal/ManageExpenditureModal";
import Expenditure from "./components/Expenditure/Expenditure";
import styles from "./Expenditures.module.scss";

const Expenditures = ({
  stickyHeaderTop = "0",
  activeEstimateStateId,
  checkerItems,
  checkOnce,
  subsectionId,
  permissions,
}) => {
  const statusFromQueryParams = new URL(window.location.href).searchParams.get("status");

  const dispatch = useDispatch();
  const activeSection = useSelector(sectionSelector);
  const activeSectionIsLoading = useSelector(sectionIsLoadingSelector);
  const expenditures = useSelector(expendituresSelector);
  const expendituresAreLoading = useSelector(expendituresAreLoadingSelector);

  const user = useSelector(userSelector);
  const building = useSelector(detailDataSelector);
  const userIsResponsibleEmployee = user?.id === building?.responsible_estimate?.id;

  const intervals = useSelector((state) => state.process.intervals);
  const intervalsAreLoading = useSelector(intervalsLoadingSelector);

  const [changedExpenditure, setChangedExpenditure] = useState(null);
  const [isManageExpenditureModalOpen, setIsManageExpenditureModalOpen] = useState(false);

  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;
  const isProduction = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

  const openManageExpenditureModal = useCallback(() => setIsManageExpenditureModalOpen(true), []);

  const resetManageExpenditure = useCallback(() => {
    setIsManageExpenditureModalOpen(false);
    setChangedExpenditure(null);
  }, []);

  const onEditExpenditure = useCallback(
    (expenditureId) => {
      if (!expenditures) return;
      setChangedExpenditure(expenditures.results.find((expenditure) => expenditure.id === expenditureId));
      openManageExpenditureModal();
    },
    [openManageExpenditureModal, expenditures?.results]
  );

  const handleLoadExpenditures = useCallback(
    () =>
      compose(dispatch, loadExpenditures)(
        { building: building.id, section: subsectionId, estimateState: activeEstimateStateId },
        statusFromQueryParams
      ),
    [building.id, subsectionId, activeEstimateStateId, statusFromQueryParams]
  );

  const isShared = building.shared_estimate_item_count !== 0;

  const [, drag] = useDrag(
    () => ({
      type: "box",
      item: [activeSection],
      canDrag: statusFromQueryParams !== ESTIMATE_ITEM_STATUSES.NEW && !isProduction
    }),
    [activeSection, statusFromQueryParams, isProduction]
  );

  const handleCheckOnce = useMemo(
    () => memoize((estimateItemId) => (isChecked) => checkOnce(estimateItemId, isChecked), stringifyArgs),
    [checkOnce]
  );

  const checkedExpenditures = useMemo(() => {
    if (!expenditures) return {};

    const displayedExpendituresIds = buildExpendituresDictionaryByIds(expenditures.results);
    const checkedExpendituresDictionaryByIds = {};

    Object.entries(checkerItems)
      .filter(([, isChecked]) => isChecked)
      .forEach(([checkedExpenditureId]) => {
        if (!displayedExpendituresIds[checkedExpenditureId]) return;
        checkedExpendituresDictionaryByIds[checkedExpenditureId] = displayedExpendituresIds[checkedExpenditureId];
      });

    return checkedExpendituresDictionaryByIds;
  }, [checkerItems, expenditures?.results]);

  const expendituresByStatusAndPermission = useMemo(() => {
    if (!expenditures) return [];
    if (statusFromQueryParams === ESTIMATE_ITEM_STATUSES.NEW && !userIsResponsibleEmployee) return [];

    return expenditures.results;
  }, [expenditures, statusFromQueryParams, userIsResponsibleEmployee]);

  const getExpenditureVariant = useCallback(() => {
    if (isDraft) return VARIANTS.HANDLER_DRAFT;
    if (isProduction) return VARIANTS.HANDLER_PRODUCTION;
    return VARIANTS.HANDLER;
  }, [isDraft, isProduction]);

  useEffect(() => {
    handleLoadExpenditures();
    return () => compose(dispatch, resetExpendituresAction)();
  }, [building.id, subsectionId]);

  useEffect(() => {
    compose(dispatch, loadIntervals)(building.id, subsectionId, "progress", null, null, true);
  }, []);

  useEffect(() => () => compose(dispatch, resetSectionAction)(), []);

  if (expendituresAreLoading || activeSectionIsLoading || intervalsAreLoading)
    return <Spinner isStatic />;

  if (!activeSection || !intervals || !expenditures) return null;

  return (
    <div>
      <div className={styles.container} ref={drag}>
        <div className={styles.titleContainer}>
          <div className={styles.name}>{activeSection.name}</div>
          {isDraft && (
            <button className={styles.addButton} onClick={openManageExpenditureModal}>
              <span className={styles.text}>Добавить позицию</span>
              <PlusFillCircleIcon />
            </button>
          )}
          {!isShared && !isDraft && (!isProduction || activeSection.sum_child_subsections) && (
            <>
              <Amount
                className={styles.amount}
                title="Израсходовано"
                value={activeSection.indicators.invested}
              />
              <Amount
                className={styles.amount}
                title="Выполнено"
                value={activeSection.indicators.work_completed}
              />
              <Amount
                className={styles.amount}
                title="Принято"
                value={activeSection.indicators.amount_accepted}
              />
              <div className={styles.divider} />
            </>
          )}
          <Amount
            className={cn(styles.amount, styles.budget)}
            title="Бюджет"
            value={getSectionAmount(activeSection, activeEstimateStateId)}
          />
        </div>
        {expendituresByStatusAndPermission.length !== 0 && (
          <div>
            <header className={cn(styles.header, { [styles.draft]: isDraft })} style={{ top: stickyHeaderTop }}>
              <div className={styles.numberCol}>№</div>
              <div className={styles.nameCol}>Наименование</div>
              <div className={styles.countCol}>Количество</div>
              {isDraft && <div className={styles.priceCol}>Цена, ₽</div>}
              <div className={styles.completedCol}>Выполнено</div>
              <div className={styles.acceptedCol}>Принято</div>
              {userIsResponsibleEmployee && <div className={styles.actionsCol}>Действия</div>}
            </header>
            {expendituresByStatusAndPermission.map((expenditure) => (
              <Expenditure
                activeEstimateStateId={activeEstimateStateId}
                checkedExpenditures={checkedExpenditures}
                isParentSectionChecked={checkerItems[+subsectionId]}
                withActions={userIsResponsibleEmployee}
                expenditure={expenditure}
                intervals={intervals[expenditure.id]}
                buildingId={building.id}
                loadProduct={handleLoadExpenditures}
                variant={getExpenditureVariant()}
                isShared={isShared}
                isChecked={checkerItems[expenditure.id]}
                check={handleCheckOnce(expenditure.id)}
                sectionName={activeSection.name}
                onEdit={onEditExpenditure}
                permissions={permissions}
                key={expenditure.id}
              />
            ))}
          </div>
        )}
      </div>
      {expendituresByStatusAndPermission.length === 0 && (
        <EmptyMessage message={isDraft ? "Чтобы продолжить, добавьте позицию сметы" : "Нет позиций"} />
      )}
      <ManageExpenditureModal
        variant={changedExpenditure ? MANAGE_EXPENDITURE_MODAL_VARIANTS.EDIT : MANAGE_EXPENDITURE_MODAL_VARIANTS.ADD}
        sectionId={subsectionId}
        buildingId={building.id}
        activeEstimateStateId={activeEstimateStateId}
        isOpen={isManageExpenditureModalOpen}
        onClose={resetManageExpenditure}
        initialValues={changedExpenditure}
      />
    </div>
  );
};

export default React.memo(Expenditures);
