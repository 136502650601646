import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { tasksByListLoadingSelector, tasksByListSelector } from "redux/modules/purchaser/Tasks/selectors";
import { loadMoreTasksByList, loadTasksByList } from "redux/modules/purchaser/Tasks/thunks";
import { tasksListFilterType } from "redux/modules/purchaser/Tasks/types";
import { useTypedSelector } from "redux/typedUseSelector";

type propsType = {
  listId: number;
  filters: tasksListFilterType;
};

export const useCurrentTasksList = ({ listId, filters }: propsType) => {
  const dispatch = useDispatch();
  const tasksInLists = useTypedSelector(tasksByListSelector);
  const tasksInListsLoading = useTypedSelector(tasksByListLoadingSelector);

  useEffect(() => {
    dispatch(loadTasksByList({ ...filters, lists: listId }));
  }, [listId, filters]);

  const tasksByCurrentList = useMemo(() => {
    return tasksInLists[listId] || { results: [], count: 0 };
  }, [listId, tasksInLists]);

  const isCurrentTasksLoading = useMemo(() => {
    return Boolean(tasksInListsLoading[listId]);
  }, [tasksInListsLoading, listId]);

  const loadMoreTasksByListHandler = useCallback(() => {
    dispatch(loadMoreTasksByList({ ...filters, offset: tasksByCurrentList.results.length, lists: listId }));
  }, [tasksByCurrentList, listId]);

  return {
    tasksByCurrentList,
    isCurrentTasksLoading,
    loadMoreTasksByListHandler,
  };
};
