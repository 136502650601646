import React, { useMemo } from "react";
import { memoize, partial } from "lodash";

import { sliceTextByConstraint } from "../../../../../utils/sliceTextByConstraint";
import { stringifyArgs } from "../../../../../utils/stringifyArgs";

import { TableHeader } from "../../../../UI/TotoRowTable/TableHeader/TableHeader";
import { TableOslaLocal } from "../../../../UI/TableOslaCustom/TableOslaLocal";
import { ProductCommentsFormatter } from "../../../../UI/molecules/ProductCommentsFormatter/ProductCommentsFormatter";

import ProductFilesModal from "../../../Requisition/components/ProductFilesModal";

import { deleteColumnFormatter } from "./formatters/deleteColumnFormatter";
import { priceColumnFormatter } from "./formatters/priceColumnFormatter";
import { factCountColumnFormatter } from "./formatters/factCountColumnFormatter";
import { amountWithTaxColumnFormatter } from "./formatters/amountWithTaxColumnFormatter";

import styles from "./index.module.scss";
import { ACT_TYPES } from "components/pages/ServiceAct/constants";
import { dropNonSignificantZeros } from "utils/dropNonSignificantZeros";

const Products = ({
  products,
  productsComments,
  productsCommentsAreLoading,
  createProductComment,
  getProductComments,
  files,
  addFiles,
  deleteFile,
  onChangeProductItem,
  canEdit,
  onDeleteProduct,
  isPurchaser,
  permissions,
  type = ACT_TYPES.PACKING_LISTS
}) => {
  const partialAddFiles = useMemo(
    () => memoize((productId) => partial(addFiles, productId), stringifyArgs),
    [addFiles]
  );

  const partialDeleteFile = useMemo(
    () => memoize((productId) => partial(deleteFile, productId), stringifyArgs),
    [deleteFile]
  );

  const partialCreateComment = useMemo(
    () => memoize((productId) => partial(createProductComment, productId), stringifyArgs),
    [createProductComment]
  );

  return (
    <TableOslaLocal
      data={products}
      pagination={false}
      rowClass={styles.tableRowClass}
      tableClass={styles.tables}
      headerClass={styles.tableHeaderClass}
    >
      <TableHeader cellStyle={{ width: "3%", textAlign: "center" }} numberGeneration>
        №
      </TableHeader>
      <TableHeader
        data={"name"}
        cellStyle={{ width: canEdit ? "33%" : "37%", textAlign: "left" }}
        formatter={(cell) => <div title={cell}>{sliceTextByConstraint(cell, 80)}</div>}
      >
        Предложение
      </TableHeader>
      {type === ACT_TYPES.PACKING_LISTS && <TableHeader
        cellStyle={{ width: "5%", textAlign: "center" }}
        formatter={(_, product) => (
          <ProductFilesModal
            variant="secondary"
            files={files[product.id]}
            uploadFilesCallback={partialAddFiles(product.id)}
            deleteFilesCallback={partialDeleteFile(product.id)}
            permissions={permissions}
          />
        )}
      />}
      {type === ACT_TYPES.PACKING_LISTS && <TableHeader
        cellStyle={{ width: "5%", textAlign: "center" }}
        formatter={(_, product) => (
          <ProductCommentsFormatter
            product={product}
            productComments={productsComments?.[product.id]}
            productsCommentsAreLoading={productsCommentsAreLoading}
            createProductComment={partialCreateComment(product.id)}
            getProductComments={getProductComments}
          />
        )}
      />}
      <TableHeader data={"measure"} cellStyle={{ width: "10%", textAlign: "center" }}>
        Ед. изм.
      </TableHeader>
      <TableHeader
        data={"count"}
        cellStyle={{ width: "10%", textAlign: "center" }}
        formatter={(cell) => dropNonSignificantZeros(cell)}
      >
        Кол-во
      </TableHeader>
      {type === ACT_TYPES.PACKING_LISTS && <TableHeader
        cellStyle={{ width: "10%", textAlign: "center" }}
        formatter={factCountColumnFormatter({
          canEdit,
          isPurchaser,
          onChange: onChangeProductItem,
        })}
      >
        Факт. Кол-во
      </TableHeader>}
      <TableHeader formatter={priceColumnFormatter} cellStyle={{ width: "10%" }}>
        Цена&nbsp; <span className={styles.fontOfRuble}>₽</span>{" "}
      </TableHeader>
      <TableHeader
        cellStyle={{ width: "10%" }}
        formatter={amountWithTaxColumnFormatter({
          canEdit: false,
          isPurchaser,
          onChange: onChangeProductItem,
        })}
      >
        Сумма с&nbsp;НДС&nbsp;<span className={styles.fontOfRuble}>₽</span>
      </TableHeader>
      {canEdit && (
        <TableHeader formatter={deleteColumnFormatter({ onDelete: onDeleteProduct })} cellStyle={{ width: "4%" }} />
      )}
    </TableOslaLocal>
  );
};

export default React.memo(Products);
