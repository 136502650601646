import ButtonBase from 'components/UI/atoms/ButtonBase'
import BottomControls from 'components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls'
import React from 'react'
import { IFactInterval } from 'types/interfaces/FactInterval'
import { splitThousands } from 'utils/splitThousands'
import styles from "./ManufacturingModalBottom.module.scss"

interface IProps {
    interval?: IFactInterval & { approved_at?: string },
    isPlan: boolean,
    canEditPlan: boolean,
    planIsInFuture: boolean,
    onAddToJournal: () => void;
    onEdit: () => void;
    onApprove: () => void;
    onSetSharing: () => void;
    onSetReviewingShared: () => void;
}

const ManufacturingModalBottom: React.FC<IProps> = ({
    interval,
    isPlan,
    canEditPlan,
    planIsInFuture,
    onAddToJournal,
    onEdit,
    onApprove,
    onSetSharing,
    onSetReviewingShared
}) => {
    const showPlanEditing = isPlan && interval?.work_status !== "actived" && !interval?.approved_at && canEditPlan && planIsInFuture
    const showAddingToJournal = isPlan && interval?.work_status === "actived"
    const showTotalAmount = interval?.work_status === "to_payed"
    const showSetToSharing = interval?.is_shared && interval?.is_ended && interval?.status !== "moderation" && interval?.status !== "confirmed"
    const showSetToReviewingShared = interval?.is_from_provider && interval?.is_ended && interval?.status !== "canceled" && interval?.status !== "confirmed"

    return (
        <>
            <BottomControls isExists={showSetToSharing || showTotalAmount || showSetToReviewingShared}>
                <div className={styles.column}>
                    {showSetToReviewingShared && <ButtonBase primary onClick={onSetReviewingShared}>Принять решение</ButtonBase>}
                    {showSetToSharing && <ButtonBase primary onClick={onSetSharing}>Передача работы</ButtonBase>}
                    {showTotalAmount && <div className={styles.sum}>
                        Итого к оплате:<strong>{splitThousands(interval?.total_sum_amount)}</strong>
                    </div>}
                </div>
            </BottomControls>
            <BottomControls isExists={showAddingToJournal}>
                <ButtonBase primary onClick={onAddToJournal}>
                    Добавить запись в Журнал Учета
                </ButtonBase>
            </BottomControls>
            <BottomControls
                isExists={showPlanEditing}
                isDoubleBtns
            >
                <ButtonBase secondary onClick={onEdit}>
                    Редактировать
                </ButtonBase>
                <ButtonBase primary onClick={onApprove}>Согласовать</ButtonBase>
            </BottomControls>
        </>
    )
}

export default ManufacturingModalBottom