import React, { useState } from 'react';
import moment from 'moment';

import {
  apiAddFactInterval
} from 'redux/modules/common/building/processApi';

import Button from 'components/UI/atoms/ButtonBase';
import SliderModal from 'components/UI/atoms/SliderModal';
import CalendarRange from "components/UI/molecules/CalendarRange";
import InputNumber from "components/UI/atoms/InputNumber";
import ExpandableFilesInput from "../../../ExpandableFileInput/ExpandableFileInput"
import AddWorkersInModal from '../../../../organism/AddingListInModal/AddWorkersInModal';
import AddMaterialsInModal from '../../../../organism/AddingListInModal/AddMaterialsInModal';

import { useSelectingWorkers } from '../../../../organism/AddingListInModal/hooks/useSelectingWorkers';
import { useSelectingMaterials } from '../../../../organism/AddingListInModal/hooks/useSelectingMaterials';

import styles from "./index.module.scss";


const ProductIntervalCard = ({ isOpen, handleClose, product, objectId }) => {
  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const [count, setCount] = useState('');
  const [files, setFiles] = useState([]);

  const handleCloseModal = () => {
    handleClose();
    clearMaterials();
    clearWorkers();
  }

  const handleSubmit = () => {
    const data = {
      start_at: dateStart.format('YYYY-MM-DD'),
      end_at: dateEnd.format('YYYY-MM-DD'),
      count: count || 0,
      workers: addedUsers,
      expenditure_id: product.id,
      stock_using_materials: addedMaterials.map(el => ({stock_using_material_id: el.using_ids[0], count: el.local_count}))
    }
    
    if(addedUsers) {
      const count_workers = addedUsers.map((item, index) => {
        return {worker_id: item?.id, count: item?.count}
      })

      data.count_workers = count_workers;
      
    }

    apiAddFactInterval(objectId, product.id, data, false, { successCall: () => { handleClose() }, files });
  }

  const {
    confirmHandler,
    cancelHandler,
    changeCountHandler,
    validateSubmitting,
    deleteAddedHandler,
    selectHandler,
    addedUsers,
    selectedUsers,
    selectedUsersIds,
    addedUsersIds,
    clearWorkers
  } = useSelectingWorkers()

  const {
    confirmMaterialsHandler,
    cancelMaterialsHandler,
    changeCountMaterialsHandler,
    validateSubmittingMaterials,
    deleteAddedHandler: deleteAddedMaterial,
    selectMaterialsHandler,
    addedMaterials,
    selectedMaterials,
    selectedMaterialsIds,
    addedMaterialsIds,
    clearMaterials
  } = useSelectingMaterials()

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Добавить в факт</div>
            <div className={styles.subTitle}>{product.name}</div>
          </div>
          <div className={styles.content}>
            <div className={styles.hiddenWrapper}>
              <div className={styles.fieldTitle}>Количество</div>
              <div className={styles.fieldValue}>
                <InputNumber value={count} setValue={setCount} decimalPlace="4" />
              </div>
              <div className={styles.fieldTitle}>Укажите период </div>
              <div className={styles.fieldRow}>
                <div className={styles.fieldCalendar}>
                  <CalendarRange 
                    defaultDateStart={dateStart} 
                    setDefaultDateStart={setDateStart}
                    defaultDateEnd={dateEnd} 
                    setDefaultDateEnd={setDateEnd}
                  />
                </div>
              </div>
              {isOpen && <AddWorkersInModal
                addedUsers={addedUsers}
                selectedUsers={selectedUsers}
                onAccept={confirmHandler}
                onDecline={cancelHandler}
                onChangeCount={changeCountHandler}
                onSelect={selectHandler}
                selectedIds={selectedUsersIds}
                onDelete={deleteAddedHandler}
                addedIds={addedUsersIds}
              />}
              {isOpen && <AddMaterialsInModal
                addedMaterials={addedMaterials}
                selectedUsers={selectedMaterials}
                onAccept={confirmMaterialsHandler}
                onDecline={cancelMaterialsHandler}
                onChangeCount={changeCountMaterialsHandler}
                onSelect={selectMaterialsHandler}
                selectedIds={selectedMaterialsIds}
                onDelete={deleteAddedMaterial}
                addedIds={addedMaterialsIds}
                activeSection={product.section_id}
                activeWork={product.id}
              />}
              <ExpandableFilesInput files={files} setFiles={setFiles} canExtractFiles={false} />
            </div>
          </div>
          <div className={styles.actionBlock}>
            <Button type='button' medium secondary disabled={false} onClick={handleCloseModal}>
              Отменить                
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button medium primary onClick={() => validateSubmittingMaterials(() => validateSubmitting(handleSubmit))} disabled={!count}>
              Сохранить                
            </Button>
          </div>
        </div>
      </SliderModal>
    </>
  )
}

export default ProductIntervalCard;