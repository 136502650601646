import React, { FC } from "react";

import { NavLinkIcon } from "components/UI/NavLinkIcon/NavLinkIcon";
import StatusFormatter from "components/UI/atoms/StatusFormatter";
import { termsNames as deliveryTermsNames } from "components/UI/molecules/DeliveryTerms";
import BlueLabel from "components/UI/atoms/BlueLabel";

import { IOrder } from "../../OrdersComparison";

import {transformPrice} from '../../utils'

import styles from "./OrderCard.module.scss";


export interface IOrderCardProps {
  order: IOrder;
  baseModuleRoute: string;
}

const OrderCard: FC<IOrderCardProps> = ({ order, baseModuleRoute }) => {
  return (
    <div className={styles.card}>
      <header className={styles.header}>
        <NavLinkIcon to={`${baseModuleRoute}/orders/${order.id}`} title={`Заказ №${order.number}`} />
        <div>{StatusFormatter(order.status_name, { flow: "in" })}</div>
        <div className={styles.buildingName} title={order.building.name}>
          {order.building.name}
        </div>
      </header>
      <div className={styles.block}>
        <div className={styles.blockName}>Оплата:</div>
        <div className={styles.bold}>{order.payment_terms.map((term) => term.payment_type_name).join(", ")}</div>
      </div>
      <div className={styles.block}>
        <div className={styles.blockName}>Доставка:</div>
        <div className={styles.bold}>
          {order.delivery_terms.map((term) => {
            if (!term.confirmed) return null;
            if (term.term_type === "paid") return `${deliveryTermsNames[term.term_type]}(${term.cost} ₽)`;
            return deliveryTermsNames[term.term_type];
          })}
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.blockName}>Стоимость товаров:</div>
        <BlueLabel className={styles.label} blueFont={undefined}>{transformPrice(order.calculation.amount)}</BlueLabel>
      </div>
    </div>
  );
};

export default React.memo(OrderCard);
