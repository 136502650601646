import React, { FunctionComponent, MouseEventHandler, ReactNode } from "react";
import cn from "classnames";

import { Spinner } from "components/UI/Spinner/Spinner";

import styles from "./index.module.scss";

interface IButtonBaseProps {
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  error?: boolean;
  big?: boolean;
  medium?: boolean;
  small?: boolean;
  primaryBig?: boolean;
  buttonPrimaryEmpty?: boolean;
  meta?: { touched: boolean; error: boolean };
  onClick?: MouseEventHandler;
  type?: "button" | "submit" | "reset";
}

type PrimaryButtonProps = IButtonBaseProps & { primary: true };
type SecondaryButtonProps = IButtonBaseProps & { secondary: true };
type TertiaryButtonProps = IButtonBaseProps & { tertiary: true };
type QuaternaryButtonProps = IButtonBaseProps & { quaternary: true };

export default function ButtonBase(props: PrimaryButtonProps): ReturnType<FunctionComponent>;
export default function ButtonBase(props: SecondaryButtonProps): ReturnType<FunctionComponent>;
export default function ButtonBase(props: TertiaryButtonProps): ReturnType<FunctionComponent>;
export default function ButtonBase(props: QuaternaryButtonProps): ReturnType<FunctionComponent>;

export default function ButtonBase({
  children,
  className,
  isLoading,
  disabled,
  primary,
  secondary,
  tertiary,
  danger,
  quaternary,
  error,
  big,
  medium,
  small,
  primaryBig,
  buttonPrimaryEmpty,
  meta,
  onClick,
  type = "button",
  ...rest
}: IButtonBaseProps & { primary?: true, secondary?: true, tertiary?: true, quaternary?: true, danger?: true }): ReturnType<FunctionComponent> {
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={cn(styles.button, className, {
        [styles.buttonError]: meta?.error && meta?.touched,
        [styles.buttonPrimary]: primary,
        [styles.buttonSecondary]: secondary,
        [styles.buttonTertiary]: tertiary,
        [styles.buttonQuaternary]: quaternary,
        [styles.buttonError]: error,
        [styles.buttonDanger]: danger,
        [styles.big]: big,
        [styles.medium]: medium,
        [styles.small]: small,
        [styles.buttonPrimaryBig]: primaryBig,
        [styles.buttonPrimaryEmpty]: buttonPrimaryEmpty,
      })}
      {...rest}
      onClick={onClick}
    >
      {isLoading && (
        <div className={styles.loading}>
          <Spinner isSmallGray />
        </div>
      )}
      {children}
    </button>
  );
}
