import axios from "axios";

import { errorCatcher } from "utils/errorCatcher";

export function loadTickets(
  objectId: string,
  params: { [key: string]: string }
) {
  const config = {
    params: { ...params },
  };

  return axios
    .get(`/building/${objectId}/tickets/`, config)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export function saveFile(
  objectId: string,
  estimateId: number,
  type: string,
  data: unknown
) {
  return axios
    .post(`/building/${objectId}/estimate/reports/${estimateId}/${type}/`, data)
    .then(({ data }) => data)
    .catch(errorCatcher);
}
