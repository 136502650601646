import React, { useMemo } from "react";
import { compose } from "redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import { checkUserHavePermission } from "../../../../redux/modules/common/permissions/thunks/checkUserHavePermission";

import TemplatedRequisition from "../../../pages/Requisition/TemplatedRequisition";
import RequisitionsList from "../../../pages/RequisitionsList/RequisitionsList";
import OrdersList from "../../../pages/OrdersList/OrdersList";
import PaymentsList from "../../../pages/PaymentsList";
import SuppliesList from "../../../pages/SuppliesList";
import ConstructingCounterparties from "../../../pages/Constructing/Counterparties";
import { ProfilePage } from "../../../UI/molecules/Header/components/Profile/ProfilePage/ProfilePage";
import Calendar from "../../../pages/Calendar";
import ForbiddenPage from "../ForbiddenPage/ForbiddenPage";
import DocumentsRoutes from "../DocumentsRoutes/DocumentsRoutes";
import SettingsRoutes from "../SettingsRoutes/SettingsRoutes";
import TemplatedOrder from "../../../pages/Order/TemplatedOrder";
import ServiceAct from "components/pages/ServiceAct/ServiceAct";
import Manufacturing from "components/pages/Manufacturing/Manufacturing";
import FilesStorage from "components/pages/FilesStorage/FilesStorage";

import { VIEW_SETTINGS_SECTION } from "../../../../constants/permissions/generalPermissions";
import {
  ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
  ORDER_FILES_ATTACH,
  ORDER_FILES_DELETE,
  ORDER_PRODUCT_FILE_ATTACH,
  ORDER_PRODUCT_FILE_DELETE,
  ORDERS_SECTION_VIEW,
  VIEW_ADD_ADDITIONAL_PRODUCTS,
  VIEW_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE,
  VIEW_ADD_CONTACT,
  VIEW_ADD_CONTRACT_FILES,
  VIEW_ADD_OUT_OF_ESTIMATE_PRODUCTS,
  VIEW_ADD_PACKING_LIST_FILES,
  VIEW_ADD_REQUISITION_APPROVERS,
  VIEW_ADD_REQUISITION_PRODUCTS_COMMENTS,
  VIEW_ADD_REQUISITION_PRODUCTS_FILES,
  VIEW_ADD_REQUISITION_VIEWERS,
  VIEW_ADD_SUPPLIERS_CONTRACTS,
  VIEW_ADD_SUPPLIERS_CONTRACTS_FILES,
  VIEW_ASSIGN_REQUISITION_EXECUTOR,
  VIEW_BILL,
  VIEW_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID,
  VIEW_BILLS_LIST,
  VIEW_CONTACTS_LIST,
  VIEW_CONTRACTS_LIST,
  VIEW_COUNTERPARTIES_SECTION,
  VIEW_CREATE_REQUISITION,
  VIEW_CREATE_REQUISITION_PRODUCTS_PROVIDER,
  VIEW_DELETE_BILL_FILES,
  VIEW_DELETE_CONTACTS,
  VIEW_DELETE_CONTRACT,
  VIEW_DELETE_CONTRACT_FILES,
  VIEW_DELETE_EXPORTS,
  VIEW_DELETE_PACKING_LIST_FILES,
  VIEW_DELETE_REQUISITION_APPROVERS,
  VIEW_DELETE_REQUISITION_PRODUCTS_FILES,
  VIEW_DELETE_REQUISITION_VIEWERS,
  VIEW_DELETE_SUPPLIERS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES,
  VIEW_DOCUMENTS_SECTION,
  VIEW_EDIT_CONTACT,
  VIEW_EDIT_REQUISITION_BEFORE_APPROVE,
  VIEW_EDIT_SUPPLIER_MANAGER,
  VIEW_EXPORT_LIST,
  VIEW_INVITE_SUPPLIER,
  VIEW_INVITES_LIST,
  VIEW_INVITES_LIST_ACTIONS,
  VIEW_ORDER_INVOICE_DIFFERENCE,
  VIEW_PACKING_LIST_ACCEPT,
  VIEW_PACKING_LIST_PAGE,
  VIEW_PACKING_LISTS,
  VIEW_PACKING_LISTS_VAT_CALCULATION,
  VIEW_PROCESS_ORDERS,
  VIEW_PROCESS_PAYMENTS,
  VIEW_PROCESS_REQUISITION,
  VIEW_PRODUCTS_REQUISITION_COMPLETE,
  VIEW_REQUISITION,
  VIEW_REQUISITION_IN_PROCESSING_MODE,
  VIEW_REQUISITION_LOGS,
  VIEW_REQUISITION_PRODUCTS_COMMENTS,
  VIEW_REQUISITION_REASSIGN,
  VIEW_REQUISITION_TAKE_TO_WORK,
  VIEW_REQUISITION_UN_APPROVE,
  VIEW_REQUISITIONS_LIST,
  VIEW_SUPPLIERS_LIST,
  VIEW_PURCHASES_CHART,
} from "../../../../constants/permissions/purchasesPermissions";

import usePermission from "../../../../hooks/usePermission";

const PurchasesRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const haveViewRequisitionsListPermission = usePermission(
    VIEW_REQUISITIONS_LIST
  );
  const haveViewRequisitionPermission = usePermission(VIEW_REQUISITION);
  const haveViewProcessRequisitionPermission = usePermission(
    VIEW_PROCESS_REQUISITION
  );
  const haveViewProcessOrdersPermission = usePermission(VIEW_PROCESS_ORDERS);
  const haveViewProcessPaymentsPermission = usePermission(
    VIEW_PROCESS_PAYMENTS
  );
  const haveViewOrderListPermission = usePermission(ORDERS_SECTION_VIEW);
  const haveViewOrderInvoiceDifferencePermission = usePermission(
    VIEW_ORDER_INVOICE_DIFFERENCE
  );
  const haveViewAddOrderRequestsFilesPermission = usePermission(
    ORDER_PRODUCT_FILE_ATTACH
  );
  const haveViewDeleteOrderRequestsFilesPermission = usePermission(
    ORDER_PRODUCT_FILE_DELETE
  );
  const haveViewDocumentsSectionPermission = usePermission(
    VIEW_DOCUMENTS_SECTION
  );
  const haveViewCounterpartiesSectionPermission = usePermission(
    VIEW_COUNTERPARTIES_SECTION
  );
  const haveViewSettingsSectionPermission = usePermission(
    VIEW_SETTINGS_SECTION
  );
  const haveViewPurchasesChartPermission = usePermission(VIEW_PURCHASES_CHART);

  const getAblePurchasesUrl = () => {
    if (haveViewPurchasesChartPermission) return `${match.url}/manufacturing`;
    if (haveViewRequisitionsListPermission) return `${match.url}/requisitions`;
    if (haveViewOrderListPermission) return `${match.url}/orders`;
    if (haveViewDocumentsSectionPermission) return `${match.url}/documents`;
    if (haveViewCounterpartiesSectionPermission)
      return `${match.url}/counterparties`;
  };

  const requisitionsListPermissions = useMemo(
    () => ({
      viewCreateRequisition: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_CREATE_REQUISITION),
    }),
    []
  );

  const canViewRequisition = useMemo(() => {
    const haveOnceOfProcessPermissions =
      haveViewProcessRequisitionPermission ||
      haveViewProcessOrdersPermission ||
      haveViewProcessPaymentsPermission;

    return haveViewRequisitionPermission && haveOnceOfProcessPermissions;
  }, []);

  const requisitionPermissions = useMemo(
    () => ({
      viewProcessRequisition: haveViewProcessRequisitionPermission,
      viewProcessOrders: haveViewProcessOrdersPermission,
      viewProcessPayments: haveViewProcessPaymentsPermission,
      viewAddOutOfEstimateProducts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_OUT_OF_ESTIMATE_PRODUCTS),
      viewAddAdditionalProducts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_ADDITIONAL_PRODUCTS),
      viewEditRequisitionBeforeApprove: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_EDIT_REQUISITION_BEFORE_APPROVE),
      viewAssignRequisitionExecutor: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ASSIGN_REQUISITION_EXECUTOR),
      viewRequisitionTakeToWork: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_REQUISITION_TAKE_TO_WORK),
      viewRequisitionReassign: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_REQUISITION_REASSIGN),
      viewAddRequisitionApprovers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_REQUISITION_APPROVERS),
      viewAddRequisitionViewers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_REQUISITION_VIEWERS),
      viewDeleteRequisitionApprovers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_REQUISITION_APPROVERS),
      viewDeleteRequisitionViewers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_REQUISITION_VIEWERS),
      viewRequisitionUnApprove: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_REQUISITION_UN_APPROVE),
      viewRequisitionLogs: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_REQUISITION_LOGS),
      viewRequisitionInProcessingMode: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_REQUISITION_IN_PROCESSING_MODE),
      viewDeleteRequisitionProductsFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_REQUISITION_PRODUCTS_FILES),
      viewAddRequisitionProductsFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_REQUISITION_PRODUCTS_FILES),
      viewRequisitionProductsComments: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_REQUISITION_PRODUCTS_COMMENTS),
      viewAddRequisitionProductsComments: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_REQUISITION_PRODUCTS_COMMENTS),
      viewCreateRequisitionProductsProvider: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_CREATE_REQUISITION_PRODUCTS_PROVIDER),
      viewOrderInvoiceDifference: haveViewOrderInvoiceDifferencePermission,
      viewAddOrderRequestsFiles: haveViewAddOrderRequestsFilesPermission,
      viewDeleteOrderRequestsFiles: haveViewDeleteOrderRequestsFilesPermission,
      viewCompleteRequisition: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PRODUCTS_REQUISITION_COMPLETE),
    }),
    []
  );

  const orderPermissions = useMemo(
    () => ({
      viewOrderInvoiceDifference: haveViewOrderInvoiceDifferencePermission,
      autoTransitionToPayment: compose(
        dispatch,
        checkUserHavePermission
      )(ORDER_AUTOMATIC_AGREEMENT_TRANSITION),
      addFiles: compose(dispatch, checkUserHavePermission)(ORDER_FILES_ATTACH),
      deleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(ORDER_FILES_DELETE),
      viewAddOrderRequestsFiles: haveViewAddOrderRequestsFilesPermission,
      viewDeleteOrderRequestsFiles: haveViewDeleteOrderRequestsFilesPermission,
    }),
    []
  );

  const counterpartiesPermissions = useMemo(
    () => ({
      viewContactsList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_CONTACTS_LIST),
      viewAddContact: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_CONTACT),
      viewDeleteContacts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CONTACTS),
      viewEditContact: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_EDIT_CONTACT),
      viewSuppliersList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_SUPPLIERS_LIST),
      viewInviteSupplier: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_INVITE_SUPPLIER),
      viewDeleteSuppliers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_SUPPLIERS),
      viewAddSuppliersContracts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_SUPPLIERS_CONTRACTS),
      viewDeleteSuppliersContract: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_SUPPLIERS_CONTRACTS),
      viewEditSupplierManager: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_EDIT_SUPPLIER_MANAGER),
      viewAddSuppliersContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_SUPPLIERS_CONTRACTS_FILES),
      viewDeleteSuppliersContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES),
      viewInvitesList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_INVITES_LIST),
      viewInvitesListActions: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_INVITES_LIST_ACTIONS),
    }),
    []
  );

  const documentsPermissions = useMemo(
    () => ({
      viewBillsList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BILLS_LIST),
      viewPackingLists: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PACKING_LISTS),
      viewBill: compose(dispatch, checkUserHavePermission)(VIEW_BILL),
      viewPackingListPage: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PACKING_LIST_PAGE),
      viewPackingListsVatCalculation: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PACKING_LISTS_VAT_CALCULATION),
      viewContractsList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_CONTRACTS_LIST),
      viewDeleteContract: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CONTRACT),
      viewAddContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_CONTRACT_FILES),
      viewDeleteContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CONTRACT_FILES),
      viewExportList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_EXPORT_LIST),
      viewDeleteExports: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_EXPORTS),
    }),
    []
  );

  const billPermissions = useMemo(
    () => ({
      viewBillInEdit: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BILL_IN_EDIT),
      addFiles: compose(dispatch, checkUserHavePermission)(VIEW_ADD_BILL_FILES),
      deleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_BILL_FILES),
      viewAddBillPaymentsFile: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_BILL_PAYMENTS_FILE),
      viewBillPaymentsMakePaid: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BILL_PAYMENTS_MAKE_PAID),
    }),
    []
  );

  const packingListPermissions = useMemo(
    () => ({
      viewPackingListAccept: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PACKING_LIST_ACCEPT),
      addFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_PACKING_LIST_FILES),
      deleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_PACKING_LIST_FILES),
    }),
    []
  );

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => <Redirect replace to={getAblePurchasesUrl()} />}
      />
      {haveViewPurchasesChartPermission && (
        <Route
          exact
          path={[
            `${match.path}/manufacturing/`,
            `${match.path}/manufacturing/:objectId`,
          ]}
          component={Manufacturing}
        />
      )}
      <Route
        exact
        path={`${match.path}/requisitions/:activeTab/:requisitionId`}
        render={(props) =>
          canViewRequisition ? (
            <TemplatedRequisition
              {...props}
              permissions={requisitionPermissions}
            />
          ) : (
            <ForbiddenPage />
          )
        }
      />
      {haveViewRequisitionsListPermission && (
        <Route
          path={`${match.path}/requisitions`}
          render={(props) => (
            <RequisitionsList
              {...props}
              permissions={requisitionsListPermissions}
            />
          )}
        />
      )}
      {haveViewOrderListPermission && (
        <Route exact path={`${match.path}/orders`} component={OrdersList} />
      )}
      {haveViewOrderListPermission && (
        <Route
          exact
          path={`${match.path}/orders/:orderId`}
          render={(props) => (
            <TemplatedOrder {...props} permissions={orderPermissions} />
          )}
        />
      )}
      {haveViewDocumentsSectionPermission && (
        <Route
          path={`${match.path}/documents`}
          render={(props) => (
            <DocumentsRoutes
              {...props}
              documentsPermissions={documentsPermissions}
              billPermissions={billPermissions}
              packingListPermissions={packingListPermissions}
            />
          )}
        />
      )}
      {haveViewCounterpartiesSectionPermission && (
        <Route
          path={`${match.path}/counterparties`}
          render={(props) => (
            <ConstructingCounterparties
              {...props}
              permissions={counterpartiesPermissions}
            />
          )}
        />
      )}
      <Route
        exact
        path={`${match.path}/events/payments`}
        component={PaymentsList}
      />
      <Route
        exact
        path={`${match.path}/documents/:actId`}
        component={ServiceAct}
      />
      <Route path={`${match.path}/supplies`} component={SuppliesList} />
      <Route exact path={`${match.path}/profile`} component={ProfilePage} />
      <Route
        exact
        path={`${match.path}/events/calendar`}
        component={Calendar}
      />
      <Route
        exact
        path={`${match.path}/events/supplies`}
        component={SuppliesList}
      />
      <Route
        exact
        path={`${match.path}/files/:objectId/`}
        component={FilesStorage}
      />
      {haveViewSettingsSectionPermission && (
        <Route path={`${match.path}/settings`} component={SettingsRoutes} />
      )}
      <Route component={ForbiddenPage} />
    </Switch>
  );
};

export default React.memo(PurchasesRoutes);
