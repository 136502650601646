import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Field, Form } from "react-final-form";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import InputMask from "react-input-mask";

import {
  addFilesToWorker,
  deleteWorker,
  setCreateOrEditStatusAction,
  workersCreateOrEditSuccessSelector,
  workersLoadingSelector
} from "redux/modules/common/building/workers";
import { loadPosts, postsSelector } from "redux/modules/common/building/posts";

import { MAX_DISPLAYING_FILES_COUNT } from "components/UI/atoms/InputFiles/InputFiles";
import { Switch } from "components/UI/atoms/Switch/Switch";
import Select, { COLORS } from "components/UI/atoms/Select";
import InputFiles from "components/UI/atoms/InputFiles";
import InputBase from "components/UI/atoms/InputBase";
import ButtonBase from "components/UI/atoms/ButtonBase/index.tsx";
import Calendar from "components/UI/molecules/Calendar";
import ConfirmationModal from "components/UI/molecules/ConfirmationModal";

import { QUALIFY_LIST } from "./constants";
import { NUMBER_PLACEHOLDER } from "constants/placeholders";

import { email, maxLength, mustBeNumber, required } from "utils/validations";

import UserGrayIcon from "images/icons/user-gray-icon.svg";
import PlusIcon from "images/icons/plusIcon.svg";
import CalendarBlue from "images/icons/calendarBlue.svg";
import TrashIcon from "images/icons/trash-white.svg";

import styles from "./EditableWorkerInfo.module.scss";


const EditableWorkerInfo = ({
  isOpen,
  onClose,
  buildingId,
  workerId,
  submitAction,
  worker,
  workerFiles,
  isShowEditActions,
}) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const avatarRef = useRef();

  const posts = useSelector(postsSelector);
  const isSuccess = useSelector(workersCreateOrEditSuccessSelector);
  const isLoading = useSelector(workersLoadingSelector);

  const [avatar, setAvatar] = useState();
  const [avatarPreview, setAvatarPreview] = useState(worker?.avatar);
  const [selectedPostId, setSelectedPostId] = useState(worker?.post?.id);
  const [selectedQualifyId, setSelectedQualifyId] = useState();
  const [selectedQualifyName, setSelectedQualifyName] = useState();
  const [isStaff, setIsStaff] = useState(worker?.is_staff !== undefined ? worker?.is_staff : true);
  const [isInTimesheet, setIsInTimesheet] = useState(
    worker?.is_in_timesheet !== undefined ? worker?.is_in_timesheet : true
  );
  const [startWorkAt, setStartWorkAt] = useState(moment(worker?.start_work_at));
  const [phoneNumber, setPhoneNumber] = useState(worker?.phone_number);
  const [files, setFiles] = useState(workerFiles || []);
  const [isResetInputFiles, setIsResetInputFiles] = useState(false);
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);

  useEffect(() => {
    compose(dispatch, loadPosts)(buildingId, false);
  }, [buildingId, dispatch]);

  useEffect(() => {
    if (worker?.post?.qualification) {
      const defaultQualify = QUALIFY_LIST.filter((item) => item.name === worker?.post?.qualification)[0];

      setSelectedQualifyId(defaultQualify?.id);
      setSelectedQualifyName(defaultQualify?.name);
    }
  }, [worker?.post?.qualification]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    } else {
      setIsResetInputFiles(false);
    }
  }, [isOpen]);

  const numberHandler = (event) => {
    setPhoneNumber(event.target.value);
  };

  const postsList = useMemo(() => {
    return (
      posts?.map((item) => {
        return {
          id: item.id,
          name: item.title,
          qualification: item.qualification,
        };
      }) || []
    );
  }, [posts]);

  const selectPostHandler = (id) => {
    setSelectedPostId(id);

    const selectedPostQualify = postsList.filter((item) => item.id === id)[0]?.qualification;

    const defaultQualify = QUALIFY_LIST.filter((item) => item.name === selectedPostQualify)[0];

    setSelectedQualifyId(defaultQualify?.id);
    setSelectedQualifyName(defaultQualify?.name);
  };

  const selectQualifyHandler = (id) => {
    setSelectedQualifyId(id);

    const selectedName = QUALIFY_LIST.filter((item) => item.id === id)[0]?.name;
    setSelectedQualifyName(selectedName);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file === undefined) return;

    setAvatar(file);
    setAvatarPreview(URL.createObjectURL(file));
  };

  const addFiles = useCallback(() => {
    compose(dispatch, addFilesToWorker)(buildingId, workerId, files);
  }, [dispatch, buildingId, workerId, files]);

  const resetForm = useCallback(() => {
    if (!formRef.current || !avatarRef.current) return;
    formRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));
    avatarRef.current.value = "";
    setIsResetInputFiles(true);

    setAvatar();
    setAvatarPreview(worker?.avatar);
    setSelectedPostId(worker?.post?.id);
    setSelectedQualifyId();
    setSelectedQualifyName();
    setIsStaff(worker?.is_staff !== undefined ? worker?.is_staff : true);
    setIsInTimesheet(worker?.is_in_timesheet !== undefined ? worker?.is_in_timesheet : true);
    setStartWorkAt(moment(worker?.start_work_at));
    setPhoneNumber(worker?.phone_number || NUMBER_PLACEHOLDER);
    setFiles(workerFiles || []);
    setIsOpenRemoveModal(false);
  }, [worker?.avatar, worker?.is_in_timesheet, worker?.is_staff, worker?.phone_number, worker?.post?.id, worker?.start_work_at, workerFiles]);

  useEffect(() => {
    if (files?.length > 0 && workerId && isSuccess) {
      addFiles();
      onClose();
    } else if (isSuccess) {
      resetForm();
      onClose();
    }
    dispatch(setCreateOrEditStatusAction(false));
  }, [workerId, files, addFiles, isSuccess, dispatch, onClose, resetForm]);

  const onSubmit = useCallback(({ ...values }) => {
    compose(dispatch, submitAction)(
      buildingId,
      {
        ...values,
        id: workerId,
        building_id: buildingId,
        avatar,
        start_work_at: startWorkAt.isValid() ? startWorkAt.format("YYYY-MM-DD") : null,
        is_in_timesheet: isInTimesheet,
        is_staff: isStaff,
        post: selectedPostId,
        qualification: selectedQualifyName,
        phone_number: phoneNumber !== NUMBER_PLACEHOLDER ? phoneNumber : undefined,
      },
      workerId
    );
  },
    [
      dispatch,
      submitAction,
      buildingId,
      workerId,
      avatar,
      startWorkAt,
      isInTimesheet,
      isStaff,
      selectedPostId,
      selectedQualifyName,
      phoneNumber,
    ]
  );

  const deleteWorkerHandler = useCallback(() => {
    compose(dispatch, deleteWorker)(buildingId, workerId);
    onClose();
  }, [buildingId, dispatch, onClose, workerId]);

  const isSaveButtonDisabled = !selectedQualifyName || selectedQualifyName === "" || isLoading

  return (
    <>
      <header className={styles.header}>
        <div className={styles.avatarContainer}>
          {avatarPreview ? (
            <img className={styles.avatar} src={avatarPreview} alt="Фото" width={42} height={42} />
          ) : (
            <div className={styles.avatarNone}>
              <img src={UserGrayIcon} alt="Нет фото" width={42} height={42} />
            </div>
          )}
          <div className={styles.addAvatarContainer}>
            <div className={styles.addAvatarIcon}>
              <img src={PlusIcon} alt="Добавить" width={18} height={18} />
            </div>
            <input
              ref={avatarRef}
              type="file"
              name="avatar"
              id="avatar"
              className={styles.addAvatarInput}
              accept=".png, .jpg, .gif"
              onChange={handleFileUpload}
            />
          </div>
        </div>
        <div className={styles.headerInfo}>
          <h2 className={`${styles.title} ${styles.dotsIfLong}`}>
            {worker ? `${worker?.last_name} ${worker?.first_name} ${worker?.middle_name}` : "Новый сотрудник"}
          </h2>
          <div className={styles.headerInfoItem}>
            <span className={styles.medium}>Должность</span>
            <Select
              className={styles.selectContainer}
              color={COLORS.BLUE}
              placeholder={worker?.post?.title || "Выберите"}
              onChange={selectPostHandler}
              options={postsList}
              value={selectedPostId}
            />
          </div>
          <div className={styles.flexRow}>
            <div className={styles.headerInfoItem}>
              <span className={styles.textSmall}>Штатный сотрудник</span>
              <div className={styles.switcher}>
                <Switch checked={isStaff} onChange={() => setIsStaff((prev) => !prev)} />
              </div>
            </div>
            <div className={styles.headerInfoItem}>
              <span className={styles.textSmall}>Вывести в табеле</span>
              <div className={styles.switcher}>
                <Switch checked={isInTimesheet} onChange={() => setIsInTimesheet((prev) => !prev)} />
              </div>
            </div>
          </div>
        </div>
      </header>
      <h3 className={`${styles.medium} ${styles.partContainer} ${styles.subtitle}`}>Основные</h3>
      <Form
        onSubmit={onSubmit}
        initialValues={{ is_staff: true, is_in_timesheet: true }}
        render={({ handleSubmit, form }) => (
          <form ref={formRef} onSubmit={handleSubmit} onReset={() => form.restart()}>
            <div className={`${styles.formContainer} ${styles.partContainer}`}>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="last_name"
                  label="Фамилия"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  validate={required()}
                  initialValue={worker?.last_name}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="first_name"
                  label="Имя"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  validate={required()}
                  initialValue={worker?.first_name}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="middle_name"
                  label="Отчество"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  initialValue={worker?.middle_name}
                />
              </div>
              <div className={`${styles.fieldContainer} ${styles.fieldSelect}`}>
                <Select
                  name="qualification"
                  label="Степень/разряд"
                  color={COLORS.BLUE}
                  options={QUALIFY_LIST}
                  disabled={!selectedPostId}
                  onChange={selectQualifyHandler}
                  variant="brandColorTransparent"
                  value={selectedQualifyId}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Calendar
                  name="created_at"
                  label="Дата начала работы"
                  icon={CalendarBlue}
                  setValue={setStartWorkAt}
                  variant="brandColorTransparent"
                  classNameInput={styles.field}
                  classNameSelect={styles.calendarSelect}
                  classNameOptions={styles.calendarOptions}
                  value={startWorkAt.isValid() ? startWorkAt : null}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="table_num"
                  label="Табельный №"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  validate={maxLength(15)}
                  initialValue={worker?.table_num}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="stake"
                  label="Ставка, ₽"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  validate={mustBeNumber}
                  initialValue={worker?.stake}
                />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="phone_number">Номер телефона</label>
                <InputMask
                  name="phone_number"
                  mask="+7 (999) 999-99-99"
                  placeholder={NUMBER_PLACEHOLDER}
                  classNameInput={styles.field}
                  onChange={numberHandler}
                  variant="brandColorTransparent"
                  value={phoneNumber}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="telegram"
                  label="Telegram"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  initialValue={worker?.telegram}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="email"
                  label="E-mail"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  validate={(value) => email(value || "")}
                  initialValue={worker?.email}
                />
              </div>
              <div className={styles.files}>
                <h3 className={`${styles.medium} ${styles.subtitle}`}>Вложения: {files.length}</h3>
                <InputFiles
                  value={files}
                  setValue={setFiles}
                  canExpand={files?.length > MAX_DISPLAYING_FILES_COUNT}
                  isReset={isResetInputFiles}
                />
              </div>
              {isShowEditActions && (
                <div className={styles.deleteButton}>
                  <h3 className={`${styles.medium} ${styles.subtitle}`}>Действия</h3>
                  <ButtonBase type="button" danger onClick={() => setIsOpenRemoveModal(true)}>
                    <img src={TrashIcon} alt="Удалить" />
                    Удалить рабочего
                  </ButtonBase>

                  <ConfirmationModal
                    variant="secondary"
                    isOpen={isOpenRemoveModal}
                    action={deleteWorkerHandler}
                    onClose={() => setIsOpenRemoveModal(false)}
                  >
                    Подтвердить удаление?
                  </ConfirmationModal>
                </div>
              )}
            </div>
            <footer className={styles.footer}>
              <ButtonBase onClick={onClose} secondary medium>
                Отменить
              </ButtonBase>
              <ButtonBase type="submit" primary medium disabled={isSaveButtonDisabled} isLoading={isLoading}>
                Сохранить
              </ButtonBase>
            </footer>
          </form>
        )}
      />
    </>
  );
};

export default React.memo(EditableWorkerInfo);
