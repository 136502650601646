import React, { useCallback, useEffect, useMemo } from "react";
import cn from "classnames";

import { addIntervalChecker } from "../../../pages/Building/utils";
import ChartPopup from "../../../pages/Building/components/ChartPopup/ChartPopup";
import Intervals from "./components/Intervals/Intervals";
import ProductIntervalCard from "./components/ProductIntervalCard";
import ProductFactIntervalCard from "./components/ProductFactIntervalCard";
import IntervalModal from "./components/IntervalModal/IntervalModal";
import RelatedWork from "./components/RelatedWork/RelatedWork";
import Info from "./components/Info/Info";
import Checkbox from "../../Checkbox";
import ThreeDotsDropdown from "../../atoms/ThreeDotsDropdown/ThreeDotsDropdown";

import { VARIANTS } from "./constants";
import { transformDigitToFinancial } from "utils/transformDigitToFinancial";
import { EXPENDITURE_TYPES } from "../../../../utils/constant";

import plusIcon from "../../../../images/icons/addOrOpenBtn.svg";

import styles from "./Expenditure.module.scss";
import { useUrlModule } from "utils/hooks/useUrlModule";


const Expenditure = ({
  className,
  buildingId,
  expenditure,
  intervals,
  loadProduct,
  variant,
  isShared,
  sectionName,
  isChecked,
  check,
  canCheck,
  actions,
  permissions,
}) => {
  const moduleRoute = useUrlModule()
  const [overviewState, setOverviewState] = React.useState({});
  const [activeExpId, setActiveExpId] = React.useState(null);

  const [isFactIntervalInPlanning, setIsFactIntervalInPlanning] = React.useState(false)

  const handleCloseIntervalModal = React.useCallback(() => {
    setActiveExpId(null)
    setIsFactIntervalInPlanning(false)
  }, [])

  const [activeDateStart, setActiveDateStart] = React.useState("");
  const [activeDateEnd, setActiveDateEnd] = React.useState("");

  const [isOpenAddInterval, setIsOpenAddInterval] = React.useState(false);
  const [isOpenAddFactInterval, setIsOpenAddFactInterval] = React.useState(false);

  const [isExpanded, setIsExpanded] = React.useState(false);

  const isHandlerDraftVariant = variant === VARIANTS.HANDLER_DRAFT;
  const isProgressVariant = variant === VARIANTS.PROGRESS;
  const isPlanVariant = variant === VARIANTS.PLAN;
  const isSomeOfHandlersVariant = useMemo(
    () => [VARIANTS.HANDLER_PRODUCTION, VARIANTS.HANDLER_DRAFT, VARIANTS.HANDLER].indexOf(variant) !== -1,
    [variant]
  );

  const slicedIntervals = React.useMemo(() => {
    if (!intervals) return { plans: [], facts: [], donned: [], received: [], approveds: [] };
    return {
      plans: intervals.plans ? intervals.plans.slice(0, isExpanded ? intervals.plans.length : 3) : [],
      facts: intervals.facts ? intervals.facts.slice(0, isExpanded ? intervals.facts.length : 3) : [],
      donned: intervals.donned ? intervals.donned.slice(0, isExpanded ? intervals.donned.length : 3) : [],
      received: intervals.received ? intervals.received.slice(0, isExpanded ? intervals.received.length : 3) : [],
      approveds: intervals.approveds ? intervals.approveds.slice(0, isExpanded ? intervals.approveds.length : 3) : [],
    };
  }, [intervals, isExpanded]);

  const planIntervalItems = useMemo(() => {
    if (!intervals) return [];
    return isProgressVariant || isSomeOfHandlersVariant ? intervals.donned || [] : intervals.approveds || [];
  }, [intervals, isProgressVariant, isSomeOfHandlersVariant]);

  const factIntervalItems = useMemo(() => {
    if (!intervals) return [];
    return isProgressVariant || isSomeOfHandlersVariant ? intervals.received || [] : intervals.facts || [];
  }, [intervals, isProgressVariant, isSomeOfHandlersVariant]);

  const inProductionIntervalItems = useMemo(() => {
    if (!intervals) return [];
    return isProgressVariant ? intervals.facts || [] : intervals.plans || [];
  }, [intervals, isProgressVariant]);

  const addNewHandler = React.useCallback(() => {
    switch (variant) {
      case VARIANTS.PROGRESS:
        setIsOpenAddFactInterval(true);
        break;
      case VARIANTS.PLAN:
        setIsOpenAddInterval(true);
        break;
      default:
    }
  }, [variant]);

  const handleSetOverviewState = useCallback(() => {
    if (!expenditure.indicators) return;
    setOverviewState({
      all: expenditure.indicators.estimate_amount,
      completed: expenditure.indicators.work_completed,
      accepted: expenditure.indicators.work_accepted,
      count_completed: expenditure.indicators.count_completed,
      count_accepted: expenditure.indicators.count_accepted,
    });
  }, [expenditure.indicators]);

  const clickIntervalHandler = (id) => setActiveExpId(id);

  const handleCheck = useCallback(() => check(!isChecked), [check, isChecked]);

  const canAddIntervals =
    expenditure.expenditure_type === EXPENDITURE_TYPES.WORK &&
    addIntervalChecker({
      activeTab: variant,
      haveAddingPlanPermission: permissions.canAddPlan,
      haveAddingProgressPermission: permissions.canAddProgress,
      moduleRoute
    });

  const onExpand = useCallback(() => setIsExpanded(true), []);

  useEffect(() => {
    setIsExpanded(false);
  }, [variant]);

  return (
    <div className={cn(styles.container, styles[variant], { [styles.expanded]: isExpanded }, className)}>
      <div className={styles.content}>
        <div className={styles.numberCol}>
          <div className={styles.number}>{expenditure.number || "-"}</div>
          {canCheck && <Checkbox checked={isChecked} onChange={handleCheck} />}
        </div>
        <div className={styles.infoCol}>
          <Info
            expenditure={expenditure}
            buildingId={buildingId}
            variant={variant}
            isShared={isShared}
            onOverview={handleSetOverviewState}
            overviewState={overviewState}
            canAddFiles={permissions.canAddFiles}
            canDeleteFiles={permissions.canDeleteFiles}
          />
        </div>
        <div className={styles.countCol}>
          <div>
            {expenditure.count}
            <br />
            <span title={expenditure.count + " (" + expenditure.measure + ")"} className={styles.measure}>
              ({expenditure.measure})
            </span>
          </div>
          {!isHandlerDraftVariant && (
            <ChartPopup
              type="info"
              containerClassName={styles.infoContainer}
              onHover={handleSetOverviewState}
              accepted={overviewState.count_accepted}
              all={expenditure.count}
              completed={overviewState.count_completed}
            />
          )}
        </div>
        {isHandlerDraftVariant && (
          <div className={styles.priceCol}>
            {transformDigitToFinancial(expenditure.price)}
          </div>
        )}
        {!isSomeOfHandlersVariant && (
          <>
            {canAddIntervals ? (
              <div className={styles.addIntervalCol}>
                <img className={styles.addIntervalIcon} src={plusIcon} alt="иконка плюса" onClick={addNewHandler} />
              </div>
            ) : <div className={styles.addIntervalCol} />}
            {expenditure.expenditure_type === EXPENDITURE_TYPES.WORK && (
              <div className={styles.intervalCol}>
                <Intervals
                  items={inProductionIntervalItems}
                  isExpand={isExpanded}
                  onExpand={onExpand}
                  fullCount={expenditure.count}
                  intervalsColor="blue"
                  approvedIntervalColor="green"
                  isCompleted={
                    variant === VARIANTS.PROGRESS
                      ? !slicedIntervals.facts?.length &&
                        (slicedIntervals.donned?.length || slicedIntervals.received?.length)
                      : !slicedIntervals.plans?.length &&
                        (slicedIntervals.approveds?.length || slicedIntervals.facts?.length)
                  }
                  isShared={isShared}
                  onClickInterval={(id, date_start, date_end) => {
                    clickIntervalHandler(id);
                    setActiveDateEnd(date_end);
                    setActiveDateStart(date_start);
                  }}
                  displayItemsConstraint={isSomeOfHandlersVariant ? 3 : 2}
                />
              </div>
            )}
          </>
        )}
        {expenditure.expenditure_type !== EXPENDITURE_TYPES.WORK && expenditure.related_work && (
          <RelatedWork relatedWork={expenditure.related_work} />
        )}
        <div className={styles.intervalCol}>
          {expenditure.expenditure_type === EXPENDITURE_TYPES.WORK && (
            <Intervals
              items={planIntervalItems}
              isExpand={isExpanded}
              onExpand={onExpand}
              fullCount={expenditure.count}
              intervalsColor="green"
              isCompleted={
                [VARIANTS.PROGRESS, VARIANTS.HANDLER, VARIANTS.HANDLER_DRAFT].indexOf(variant) !== -1
                  ? !slicedIntervals.donned?.length &&
                    slicedIntervals.received?.length &&
                    !slicedIntervals.facts?.length
                  : !slicedIntervals.approveds?.length &&
                    slicedIntervals.facts?.length &&
                    !slicedIntervals.plans?.length
              }
              isShared={isShared}
              onClickInterval={(id, date_start, date_end) => {
                clickIntervalHandler(id);
                setActiveDateEnd(date_end);
                setActiveDateStart(date_start);
              }}
              displayItemsConstraint={isSomeOfHandlersVariant ? 3 : 2}
            />
          )}
        </div>
        <div className={styles.intervalCol}>
          {expenditure.expenditure_type === EXPENDITURE_TYPES.WORK && (
            <Intervals
              items={factIntervalItems}
              isExpand={isExpanded}
              onExpand={onExpand}
              fullCount={expenditure.count}
              intervalsColor="lightGreen"
              isShared={isShared}
              onClickInterval={(id, date_start, date_end) => {
                isPlanVariant && setIsFactIntervalInPlanning(true);
                clickIntervalHandler(id);
                setActiveDateEnd(date_end);
                setActiveDateStart(date_start);
              }}
              displayItemsConstraint={isSomeOfHandlersVariant ? 3 : 2}
              hideArrow
            />
          )}
        </div>
        {actions.length !== 0 && (
          <div className={styles.actionsCol}>
            <ThreeDotsDropdown items={actions} hoverTitle="Действия" />
          </div>
        )}
        {isExpanded && (
          <button className={styles.collapseBtn} onClick={() => setIsExpanded(false)}>
            <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.853516 6.06641L6.00051 0.919434L11.1475 6.06641" fill="white" />
            </svg>
          </button>
        )}
      </div>
      {(isProgressVariant || isPlanVariant) && (
        <>
          <ProductIntervalCard
            isOpen={isOpenAddInterval}
            handleClose={() => {
              loadProduct();
              setIsOpenAddInterval(false);
            }}
            product={expenditure}
            objectId={buildingId}
          />
          <ProductFactIntervalCard
            isOpen={isOpenAddFactInterval}
            handleClose={() => {
              loadProduct();
              setIsOpenAddFactInterval(false);
            }}
            product={expenditure}
            objectId={buildingId}
          />
        </>
      )}
      <IntervalModal
        objectId={buildingId}
        isOpen={!!activeExpId}
        expidentureId={activeExpId}
        onClose={handleCloseIntervalModal}
        expidentureObj={expenditure}
        sectionName={sectionName}
        activeModule={
          isSomeOfHandlersVariant || isProgressVariant || isFactIntervalInPlanning ? "fact-intervals" : "intervals"
        }
        date_start={activeDateStart}
        date_end={activeDateEnd}
        canDeleteFiles={permissions.canDeleteFiles}
        loadProduct={loadProduct}
      />
    </div>
  );
};

export default React.memo(Expenditure);
