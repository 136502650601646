import React from "react";
import Draggable from "react-draggable";
import cn from "classnames";

import Stick from "../../../../../images/icons/Stick";
import ButtonArrow from "../../../atoms/ButtonArrow/ButtonArrow";

import { useMonthsSliderWidth } from "./useMonthsSliderWidth";
import { useMonthsSlider } from "./useMonthsSlider";

import styles from "./MonthsSlider.module.scss";

function MonthsSlider({ date, changeDate, selectingMonthsCount }) {
  const monthSliderWidth = useMonthsSliderWidth();
  const year = date.year();
  const monthIndex = date.month();

  const {
    decreaseMonth,
    increaseMonth,
    monthsArray
  } = useMonthsSlider(year, monthIndex, changeDate, selectingMonthsCount);

  const [x, setX] = React.useState(monthSliderWidth * monthIndex);

  const onStopDrag = (_, { x }) => {
    const updatedMonthIndex = Math.round(x / monthSliderWidth);
    setX(updatedMonthIndex * monthSliderWidth);
    changeDate(year, updatedMonthIndex);
  };

  React.useEffect(() => {
    if (selectingMonthsCount === 1) {
      setX(monthSliderWidth * monthIndex);
    }
  }, [monthSliderWidth, monthIndex, selectingMonthsCount]);

  return (
    <div className={styles.container}>
      <ButtonArrow direction="left" onClick={decreaseMonth} />
      <div className={cn(styles.monthSlider, "monthSlider")}>
        <Draggable
          axis="x"
          bounds="parent"
          position={{ x, y: 0 }}
          onStop={onStopDrag}
        >
          <div
            className={styles.draggable}
            style={{ width: `${monthSliderWidth * selectingMonthsCount}px` }}
          >
            <span className={styles.left}>
                <div className={styles.stick}>
                    <Stick />
                </div>
            </span>
            <span className={styles.right}>
                <div className={styles.stick}>
                    <Stick />
                </div>
            </span>
          </div>
        </Draggable>
        <div className={styles.inner}>
          {monthsArray.map((month) => (
            <div key={month.id} style={{ width: `${monthSliderWidth}px` }} className={styles.month}>{month.label}</div>
          ))}
        </div>
      </div>
      <ButtonArrow direction="right" onClick={increaseMonth} />
    </div>
  );
}

export default React.memo(MonthsSlider);
