import React, { useContext, useState } from "react";
import cn from "classnames";

import { IPlanFactEstimate } from "components/pages/Constructing/Finance/interfaces/interfaces";
import CircleComponents from "components/UI/molecules/CircleComponents";

import styles from "./index.module.scss";
import { PlanFactMeasureContext } from "../../../PlanFact";
import { formatNumberWithMeasure } from "../../../../../../../../utils/formatNumberWithMeasure";

interface Iprops {
  data: IPlanFactEstimate;
}

const Indicators = ({ data }: Iprops) => {
  const { measure } = useContext(PlanFactMeasureContext);

  const plan = data?.plan || 0;
  const fact = data?.fact || 0;
  const balance = data?.balance || 0;
  const percent =
    data?.percent !== undefined
      ? +data.percent > 100
        ? +(data.percent[0] + data.percent[1])
        : Math.round(+data.percent)
      : 0;

  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={cn(styles.container, { [styles.open]: isOpen })}>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className={cn(styles.btn, { [styles.activeBtn]: isOpen })}
      >
        <svg
          width="7"
          height="9"
          viewBox="0 0 7 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.592072 9L6.13306 4.5L0.592072 0" fill="black" />
        </svg>
      </button>
      <div className={styles.content}>
        {data && (
          <div className={styles.circleBlock}>
            <div className={styles.item}>
              <CircleComponents
                progress={percent}
                percent="%"
                color="#8FE52B"
                description="Процент выполнения"
                titleClassName={styles.circleTitle}
              />
            </div>
            <div className={styles.divider} />
            <div className={styles.item}>
              <CircleComponents
                progress={100}
                color="#00C3F2"
                description="План"
                displayingValue={formatNumberWithMeasure(plan, measure) as string}
                isProgressStringHidden
                titleClassName={styles.circleTitle}
              />
            </div>
            <div className={styles.item}>
              <CircleComponents
                progress={fact / (plan / 100)}
                color="#C55EEB"
                description="Факт"
                displayingValue={formatNumberWithMeasure(fact, measure) as string}
                isProgressStringHidden
                titleClassName={styles.circleTitle}
              />
            </div>
            <div className={styles.item}>
              <CircleComponents
                progress={balance / (plan / 100)}
                color="#72E2E6"
                description="Бюджет"
                displayingValue={formatNumberWithMeasure(balance, measure) as string}
                isProgressStringHidden
                titleClassName={styles.circleTitle}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Indicators);
