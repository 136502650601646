import { compose } from "redux";
import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";

import { getValueFromEventTarget } from "../../../../../../utils/getValueFromEventTarget";
import { getLocalizedDate } from "../../../../../../utils/getLocalizedDate";
import { getIdentifier } from "../../../../../../utils/getIdentifier";
import { transformDigitToFinancial } from "../../../../../../utils/transformDigitToFinancial";

import InputBase, { VALUE_TYPES } from "../../../../atoms/InputBase/InputBaseOrder";
import Calendar from "../../../Calendar";

import DeleteOffer from "../DeleteOffer";

import styles from "../../index.module.scss";
import plusIcon from "../../../../../../images/plus-bordered.svg";
import { beautifyNumberValue } from "../../../../../../utils/beautifyNumberValue";

const OfferSupplyColumns = (props) => {
  const { canEdit, supply, setSupply, deleteSupply, createSupply, canCreateSupply } =
    props;

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const openConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(true), []);
  const closeConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(false), []);

  const handleDeleteSupply = useCallback(() => {
    deleteSupply(getIdentifier(supply));
    closeConfirmDeleteModal();
  }, [supply, deleteSupply, closeConfirmDeleteModal]);

  const onChangeDate = useCallback(
    (changedDate) => setSupply(update(supply, { date: { $set: changedDate.format("YYYY-MM-DD") } })),
    [setSupply, supply]
  );

  const onChangeCount = useCallback(
    (event) =>
      setSupply(
        update(supply, {
          count: { $set: event.target.value },
        })
      ),
    [setSupply, supply]
  );

  const onChangeAmount = useCallback(
    (changedAmount) =>
      setSupply(
        update(supply, {
          amount: { $set: changedAmount },
        })
      ),
    [setSupply, supply]
  );

  const calculateSupplyAmount = (price, count) => beautifyNumberValue(price * count);

  useEffect(() => {
    if (!supply.count || !supply.price) return;

    onChangeAmount(calculateSupplyAmount(supply.price, supply.count));
  }, [supply.price, supply.count]);

  return (
    <>
      <div className={styles.nameColumn} />
      <div className={styles.dateColumn}>
        {canEdit ? (
          <Calendar classNameSelect={styles.calendar} setValue={onChangeDate} value={supply.date || ""} />
        ) : (
          getLocalizedDate(supply.date)
        )}
      </div>
      <div className={styles.countColumn}>
        {canEdit ? (
          <InputBase
            className={styles.input}
            valueType={VALUE_TYPES.NUMBER}
            variant="secondary"
            value={supply.count}
            onChange={onChangeCount}
            placeholder="Кол-во"
          />
        ) : (
          supply.count
        )}
      </div>
      <div className={styles.measureColumn}>{supply.measure}</div>
      <div className={styles.priceColumn}>
        <span className={styles.financial}>{transformDigitToFinancial(supply.price)}</span>
      </div>
      <div className={styles.sumColumn}>
        {canEdit ? (
          <InputBase
            className={styles.input}
            valueType={VALUE_TYPES.PRICE}
            variant="secondary"
            value={supply.amount}
            onChange={compose(onChangeAmount, getValueFromEventTarget)}
            placeholder="Сумма"
          />
        ) : (
          <span className={styles.financial}>{transformDigitToFinancial(supply.amount)}</span>
        )}
      </div>
      {canEdit && (
        <div className={styles.actionsColumn}>
          {canCreateSupply && (
            <img
              className={styles.createOfferSupplyIcon}
              src={plusIcon}
              alt="Иконка добавления предложения"
              onClick={createSupply}
            />
          )}
          <div className={styles.delete}>
            <DeleteOffer
              openModal={openConfirmDeleteModal}
              closeModal={closeConfirmDeleteModal}
              isOpen={isConfirmDeleteModalOpen}
              deleteOffer={handleDeleteSupply}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(OfferSupplyColumns);
