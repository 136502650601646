import { useCallback, useEffect, useMemo } from "react";
import {
  clearLoadedIntervalRelations,
  loadIntervalRelations,
  loadRelationAvailableSections,
} from "../../../../../../redux/modules/common/building/manufacturing/thunks";
import { IDisplayedRelation } from "./types";
import { useDispatch, useSelector } from "react-redux";
import {
  relationsFromCurrentIntervalSelector,
  relationsToCurrentIntervalSelector,
} from "../../../../../../redux/modules/common/building/manufacturing/selectors";

export interface IUseLoadIntervalRelationsProps {
  projectId: number | undefined;
  intervalId: number | undefined;
}

export const useLoadIntervalRelations = ({
  projectId,
  intervalId,
}: IUseLoadIntervalRelationsProps) => {
  const dispatch = useDispatch();

  const relationsFromCurrentInterval: IDisplayedRelation[] =
    useSelector(relationsFromCurrentIntervalSelector) || [];

  const relationsToCurrentInterval: IDisplayedRelation[] =
    useSelector(relationsToCurrentIntervalSelector) || [];

  const hasRelations = useMemo(
    () =>
      relationsFromCurrentInterval?.length > 0 ||
      relationsToCurrentInterval?.length > 0,
    [relationsFromCurrentInterval?.length, relationsToCurrentInterval?.length]
  );

  useEffect(() => {
    if (!intervalId || !projectId) return;
    dispatch(loadIntervalRelations({ projectId, intervalId }));
    dispatch(loadRelationAvailableSections(projectId));
    return () => {
      dispatch(clearLoadedIntervalRelations());
    };
  }, [intervalId, projectId]);

  return {
    hasRelations,
    relationsFromCurrentInterval,
    relationsToCurrentInterval,
  };
};
