import ProductFilesModal from 'components/pages/Requisition/components/ProductFilesModal'
import Actions from 'components/UI/atoms/Actions/Actions'
import TableReusableRow, { TableReusableCell } from 'components/UI/atoms/TableReusable/TableReusableRow'
import moment from 'moment'
import React from 'react'
import { IContract } from 'types/interfaces/Contract'
import { splitThousands } from 'utils/splitThousands'
import styles from "./ContractsRow.module.scss"

interface Iprops {
    contract: IContract,
    onDeleteFile: () => void;
    files: unknown[];
    onAddFile: () => void;
    canDeleteContract?: boolean;
    onDeleteContract: () => void;
    filesPermissions: any
}

const ContractsRow: React.FC<Iprops> = ({ contract, onDeleteFile, files, onAddFile, canDeleteContract, onDeleteContract, filesPermissions }: Iprops) => {
    return (
        <TableReusableRow className={styles.row}>
            <TableReusableCell>
                {contract.contract_num}
            </TableReusableCell>
            <TableReusableCell isNoBreak>
                {contract.building_name || "-"}
            </TableReusableCell>
            <TableReusableCell isNoBreak>
                {contract.entity_name}
            </TableReusableCell>
            <TableReusableCell isCentered>
                {moment(contract.contract_date).format("DD.MM.YY")}
            </TableReusableCell>
            <TableReusableCell isRighted>
                {splitThousands(contract.amount, false, false)}
            </TableReusableCell>
            {!canDeleteContract && <div />}
            <TableReusableCell>
                <ProductFilesModal
                    files={files}
                    variant="secondary"
                    uploadFilesCallback={onAddFile}
                    deleteFilesCallback={onDeleteFile}
                    permissions={filesPermissions}
                />
            </TableReusableCell>
            {canDeleteContract && <TableReusableCell>
                <Actions className={styles.actions} canRemove onRemove={onDeleteContract} />
            </TableReusableCell>}
        </TableReusableRow>
    )
}

export default ContractsRow