import React from "react";

import { sliceTextByConstraint } from "../../../utils/sliceTextByConstraint";
import EmptyPlaceholder from "../atoms/EmptyPlaceholder/EmptyPlaceholder";

import Modal from "../atoms/Modal";
import { Spinner } from "../Spinner/Spinner";

import styles from "./SpecificationModal.module.scss";
import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";
import IExpenditure from "types/interfaces/Expenditure";
const MAX_NAME_LENGTH = 85;

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  name?: string;
  isLoading: boolean;
  subMaterials: Pick<IExpenditure, "count" | "id" | "measure" | "name">[]
}

function SpecificationModal({ onClose, isOpen, subMaterials, name, isLoading = false }: IProps) {
  if (!isOpen) return null;

  return (
    <Modal className={styles.modal} title="Состав расценки" onClose={onClose} isOpen={isOpen}>
      <div className={styles.subtitle} title={name}>{name}</div>
      <div className={styles.list}>
        {isLoading && <Spinner />}
        {(!isLoading && subMaterials?.length) ? subMaterials?.map((subMaterial) => (
          <div className={styles.item} key={subMaterial.id + subMaterial.name}>
            <div className={styles.itemName} title={subMaterial.name}>
              {sliceTextByConstraint(subMaterial.name, MAX_NAME_LENGTH)}
            </div>
            <div className={styles.itemCount} title={subMaterial.count + " " + subMaterial.measure}>
              {subMaterial.count} {subMaterial.measure}
            </div>
          </div>
        )) : null}
        {(!isLoading && !subMaterials?.length) ? <EmptyPlaceholder fullHeight={false} text="Нет сведений" img={jurnalIcon} className={styles.placeholder} /> : null}
      </div>
      <footer className={styles.footer}/>
    </Modal>
  );
}

export default React.memo(SpecificationModal);
