import React, { useEffect, useState } from "react";
import SliderModal from "components/UI/atoms/SliderModal";
import Button from "components/UI/atoms/ButtonBase";
import styles from "./index.module.scss";
import { apiAddFile, apiGetFile } from "redux/modules/common/building/processFileApi";
import downloadFileByUrl from "utils/downloadFileByUrl";
import InputFiles from "../../atoms/InputFiles/InputFiles";

const SaveFileModel = ({
  isOpen,
  handleClose,
  product,
  objectId,
  onDirectlyDeleteFile,
  canUpload = true,
  canDelete = true,
}) => {
  const [files, setFiles] = useState([]);
  const [newFile, setNewFile] = useState([]);
  const allFile = [...newFile.filter((item) => !item.delete), ...files];

  const handleCloseModal = () => {
    handleClose();
    setFiles([]);
  };
  const handleSubmit = () => {
    if (newFile.length > 0) newFile.forEach((file) => apiAddFile(objectId, product.id, file.file))
   handleCloseModal();
  };

  const loadFile = async () => {
    const data = await apiGetFile(objectId, product.id);
    setNewFile(data.results);
  };

  useEffect(() => {
    isOpen && loadFile(objectId, product.id);
  }, [isOpen, product]);

  const downloadAllHandler = () => {
    const preparedFiles = newFile?.filter((el) => el.file);
    preparedFiles.forEach((el) => {
      downloadFileByUrl(el.file, el.file_name || el.name);
    });
  };

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Вложения</div>
          </div>
          <div className={styles.content}>
            <div className={styles.hiddenWrapper}>
              <div className={styles.fieldTitle}>Список вложений: {!!allFile.length && allFile.length}</div>
              <button type="button" onClick={downloadAllHandler} className={styles.fieldTitleActive}>
                Выгрузить
              </button>
            </div>
            <InputFiles
              value={newFile?.map((el) => ({ ...el, name: el.file_name }))}
              setValue={setNewFile}
              removeFileDirectly={onDirectlyDeleteFile}
              permissions={{ addFiles: canUpload, deleteFiles: canDelete }}
            />
          </div>
          <div className={styles.actionBlock}>
            <Button type="button" medium secondary onClick={handleCloseModal}>
              Отменить
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button medium primary onClick={handleSubmit}>
              Сохранить
            </Button>
          </div>
        </div>
      </SliderModal>
    </>
  );
};

export default SaveFileModel;
