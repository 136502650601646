import axios from "axios";
import { compose } from "redux";
import { message } from "antd";

import { errorCatcher } from "../../../../../utils/errorCatcher";

import { setPackingListNumberAction } from "../actions";

export const setPackingListNumber = (packingListId, number) => (dispatch) =>
  axios
    .post(`/purchaser/packinglists/${packingListId}/set-number/`, { number })
    .then((response) => {
      compose(dispatch, setPackingListNumberAction)(response.data.number);
      message.success("Номер УПД успешно изменен");
    })
    .catch(errorCatcher);
