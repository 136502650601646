import { Dispatch } from "redux";

import { setIsAutoRegisterSuccess } from "./actions";
import { registrationApi } from "./registrationApi";

import { IAutoRegistrationValues } from "./types";

import { errorCatcher } from "utils/errorCatcher";

export const autoRegisterUser = (values: IAutoRegistrationValues) => (dispatch: Dispatch) => {
  registrationApi
    .postAutoRegistration(values)
    .then(() => dispatch(setIsAutoRegisterSuccess(true)))
    .catch(errorCatcher);
};
