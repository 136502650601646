import React from 'react';

import TableReusableHead, { TableReusableHeaderCell } from 'components/UI/atoms/TableReusable/TableReusableHead';

import { IdNameLabelType } from 'types/IdNameLabelType';

import styles from "./ActListsTableHeader.module.scss";


interface Iprops {
  onChangeFilter: (name: string, value: string) => void;
  statusBillsArray: IdNameLabelType[];
  formattedObjects: IdNameLabelType[];
}

const ActListsTableHeader: React.FC<Iprops> = ({ onChangeFilter, statusBillsArray, formattedObjects }) => {
  return (
    <TableReusableHead className={styles.row} isSticky>
      <TableReusableHeaderCell
        filterType="text"
        filterField="number"
        filterTitle="№"
        onChangeFilter={onChangeFilter}
      >
        №
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterType="text"
        filterField="order_number"
        filterTitle="Заказ №"
        onChangeFilter={onChangeFilter}
      >
        Заказ №
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="building_id"
        filterOptions={formattedObjects}
        filterTitle="Проект"
        filterType="select"
        onChangeFilter={onChangeFilter}
      >
        Проект
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="provider_name"
        filterType="text"
        filterTitle="Поставщик2"
        onChangeFilter={onChangeFilter}
      >
        Поставщик
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterTitle="Создан"
        filterType="datesRange"
        onChangeFilter={onChangeFilter}
        rangeEndField="created_at_before"
        rangeStartField="created_at_after"
        isCentered
      >
        Создан
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterTitle="Сумма, ₽"
        filterType="sum"
        onChangeFilter={onChangeFilter}
        rangeEndField="amount_total_max"
        rangeStartField="amount_total_min"
        isRighted
      >
        Сумма, ₽
      </TableReusableHeaderCell>
      <TableReusableHeaderCell
        filterField="status"
        filterOptions={statusBillsArray}
        filterTitle="Статус"
        filterType="select"
        onChangeFilter={onChangeFilter}
        isLast
      >
        Статус
      </TableReusableHeaderCell>
    </TableReusableHead>
  )
}

export default React.memo(ActListsTableHeader);