import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";

import { MONTH, HALF_MONTH, WEEK, YEAR } from "redux/modules/common/building/manufacturing/manufacturing";
import { chartViewModeSelector } from "redux/modules/common/building/manufacturing/selectors";
import { mergeData, mergeWeekData } from "redux/modules/common/building/manufacturing/thunks";

import {
  ExpandedBranchesType,
  IMonthArrayElement,
  IProcessedBranch,
  IProject,
  ManufacturingTabsType,
  ProjectEstimateType,
} from "../types";

export interface IUseProjectTreeProps {
  actualProjects: IProject[];
  tab: ManufacturingTabsType;
  expandedBranches: ExpandedBranchesType;
  setExpandedBranches: (newBranches: ExpandedBranchesType) => void;
  projectEstimate: ProjectEstimateType;
  projectEstimateSet: (id: number) => Action;
  generateSpittingTree: () => void;
  month: IMonthArrayElement;
  year: number | string;
  startWeek: number;
  endWeek: number;
  projectId: number | string;
  setIsEmpty: (newIsEmpty: boolean) => void;
}

export const useProjectTree = ({
  actualProjects,
  tab,
  expandedBranches,
  setExpandedBranches,
  projectEstimate,
  projectEstimateSet,
  generateSpittingTree,
  month,
  year,
  startWeek,
  endWeek,
  projectId,
  setIsEmpty,
}: IUseProjectTreeProps) => {
  const chartViewMode = useSelector(chartViewModeSelector);
  const dispatch = useDispatch();

  const checkIsExpandedBranchId = useCallback(
    (id: number) => {
      return expandedBranches.has(id);
    },
    [expandedBranches]
  );

  const merge = async (projectBranchId: number | string) => {
    if (chartViewMode === MONTH || chartViewMode === HALF_MONTH)
      await dispatch(mergeData(projectBranchId, year, month.id + 1));

    if (chartViewMode === WEEK || chartViewMode === YEAR)
      await dispatch(mergeWeekData(projectBranchId, startWeek, endWeek, year));
  };

  const reloadProjectsTrees = () => {
    const projectsToMerge = actualProjects?.filter((x) => expandedBranches.has(x.id));
    if (!projectsToMerge?.length) return;

    Promise.allSettled(
      projectsToMerge.map(async (project) => {
        await merge(project.id);
        if (!projectEstimate.has(`${project.id}_${tab}`)) await dispatch(projectEstimateSet(project.id));
      })
    ).then(generateSpittingTree);
  };

  const toggleBranch = async (branch: IProcessedBranch | { id: number; lvl: number }) => {
    if (branch.lvl === 1) {
      if (!projectEstimate.has(`${branch.id}_${tab}`)) {
        await merge(branch.id);
        await dispatch(projectEstimateSet(branch.id));
      }
    }

    if (expandedBranches.has(branch.id)) {
      expandedBranches.delete(branch.id);
      setExpandedBranches(expandedBranches);
    } else {
      setExpandedBranches(expandedBranches.add(branch.id));
    }

    generateSpittingTree();
  };

  useEffect(() => {
    const cleanUp = () => {
      setIsEmpty(false);
      expandedBranches.clear();
      setExpandedBranches(expandedBranches);
      generateSpittingTree();
    };
    if (!projectId || !actualProjects?.length) return;
    if (!expandedBranches.has(+projectId)) toggleBranch({ id: +projectId, lvl: 1 });
    return () => cleanUp();
  }, [projectId, actualProjects]);

  useEffect(() => {
    reloadProjectsTrees();
  }, [year, chartViewMode, tab, projectId, month, projectEstimate, expandedBranches]);

  return {
    checkIsExpandedBranchId,
    toggleBranch,
  };
};
