import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Route, useParams, useRouteMatch } from "react-router-dom";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import cn from "classnames";
import axios from "axios";

import {
  detailDataSelector,
  detailLoadingSelector,
  getDetail,
} from "../../../redux/modules/common/building/object/nowObject";
import {
  aggregationLoadingSelector,
  aggregationSelector,
  loadContracts,
} from "../../../redux/modules/common/building/aggregations";
import {
  addSection,
  changeEstimateItemsState,
  expendituresSelector,
  loadExpenditures,
  loadSection,
  sectionSelector,
  sectionsSelector,
} from "../../../redux/modules/common/building/sections/sections";

import useArrayItemsChecker from "../../../hooks/useArrayItemsChecker";
import { errorCatcher } from "../../../utils/errorCatcher";

import TemplateSimple from "../../UI/templates/TemplateSimple";
import Select from "../../UI/atoms/Select";
import BackNavigationBar from "../../UI/atoms/BackNavigationBar/BackNavigationBar";
import { Spinner } from "../../UI/Spinner/Spinner";
import ExcelProductsUpload from "../../UI/ExcelProductsUpload/ExcelProductsUpload";

import useEstimateState from "./hooks/useEstimateState";
import useGetSections from "./hooks/useGetSections";
import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS, ESTIMATE_STATES_NAMES_FOR_FROM } from "./constants";

import EstimateStatesIndicators from "./components/EstimateStatesIndicators/EstimateStatesIndicators";
import Amounts from "./components/Amounts/Amounts";
import ChangeEstimateItemsState from "./components/ChangeEstimasteItemsState/ChangeEstimateItemsState";

import Body from "./components/Body/Body";
import CreateSection from "./components/CreateSection/CreateSection";
import styles from "./Handler.module.scss";
import { useCheckUploadStatus } from "./hooks/useCheckUploadStatus";

const EMPTY_ARRAY = [];

const Handler = ({ match, permissions }) => {
  const { buildingId } = useParams();
  const sectionIdMatch = useRouteMatch(`${match.path}/:sectionId`);
  const subsectionIdMatch = useRouteMatch(`${match.path}/:sectionId/:subsectionId`);

  const subsectionId = subsectionIdMatch && subsectionIdMatch.params.subsectionId;
  const sectionId = sectionIdMatch && sectionIdMatch.params.sectionId;
  const isSubsections = !!sectionId;

  const dispatch = useDispatch();
  const building = useSelector(detailDataSelector);
  const buildingIsLoading = useSelector(detailLoadingSelector);
  const aggregations = useSelector(aggregationSelector);
  const aggregationsAreLoading = useSelector(aggregationLoadingSelector);
  const activeSection = useSelector(sectionSelector);
  const expenditures = useSelector(expendituresSelector);
  const sections = useSelector(sectionsSelector);

  const isBuildingShared = building && building.is_shared;

  const { activeEstimateStateId, setActiveEstimateStateId, availableEstimateStates, estimateStatesMenu } =
    useEstimateState(match.url, permissions, isBuildingShared);

  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;
  const isLocale = activeEstimateStateId === ESTIMATE_STATES_IDS.LOCALE;
  const isProduction = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

  const [consolidateStateChapter, setConsolidateStateChapter] = useState(null);
  const resetConsolidateChapter = useCallback(() => setConsolidateStateChapter(null), []);

  const chapterIsSelectedInConsolidateState =
    activeEstimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE && consolidateStateChapter;

  const statusFromQueryParams = new URL(window.location.href).searchParams.get("status");

  const {
    areLoading: sectionsAreLoading,
    refreshSections,
    allSections,
  } = useGetSections({
    buildingId,
    sectionId,
    subsectionId,
    estimateStateId: activeEstimateStateId,
    status: statusFromQueryParams,
    chapterId: consolidateStateChapter,
  });

  const [areIndicatorsOpen, setAreIndicatorsOpen] = useState(true);

  const estimateItems = useMemo(
    () => (expenditures ? [...allSections, ...expenditures.results] : EMPTY_ARRAY),
    [allSections, expenditures]
  );

  const estimateItemsChecker = useArrayItemsChecker(estimateItems, "id");

  const isDraftWithoutSections = useMemo(() => {
    if (!aggregations) return false;
    return isDraft && +aggregations.drafter_count === 0;
  }, [aggregations, isDraft]);

  const getAggregations = useCallback(() => {
    compose(dispatch, loadContracts)(buildingId);
  }, [buildingId]);

  const handleLoadExpenditures = useCallback(
    () =>
      compose(dispatch, loadExpenditures)(
        { building: buildingId, section: subsectionId, estimateState: activeEstimateStateId },
        statusFromQueryParams
      ),
    [buildingId, subsectionId, activeEstimateStateId, statusFromQueryParams]
  );

  const handleChangeEstimateItemsState = useCallback(
    async (targetEstimateStateId, changedEstimateItemsIds, chapter) => {
      const payload = { fromState: activeEstimateStateId, state: targetEstimateStateId, ids: changedEstimateItemsIds };
      if (chapter) payload.chapter = chapter;

      compose(dispatch, changeEstimateItemsState)(buildingId, payload)
        .then((response) => {
          if (response.data.count_updated === 0) {
            const activeEstimateStateName = ESTIMATE_STATES_NAMES_FOR_FROM[activeEstimateStateId.toUpperCase()];
            message.error(`Невозможно перенести выбранные объекты из ${activeEstimateStateName}`);
            return;
          }

          getAggregations();
          const section = availableEstimateStates?.find((estimateState) => estimateState.id === targetEstimateStateId);
          if (section) message.success(`Разделы успешно перенеслись в ${section.name.toLowerCase()}`);

          if (targetEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION) return;

          if (subsectionId) {
            handleLoadExpenditures();
          } else {
            refreshSections();
          }
        })
        .catch(() => message.error("Невозможно перенести выбранные объекты"));
    },
    [
      activeEstimateStateId,
      buildingId,
      getAggregations,
      availableEstimateStates,
      subsectionId,
      handleLoadExpenditures,
      refreshSections,
    ]
  );

  const { checkUploadStatus, isUploading, beginUploading } = useCheckUploadStatus({
    buildingId,
    onComplete: () => {
      refreshSections();
      getAggregations();
    },
  });

  const handleIndicatorsOpening = useCallback(() => setAreIndicatorsOpen((prevState) => !prevState), []);

  const sectionsAreEmpty = sections?.length === 0;

  const onSubmitCreateSection = useCallback(
    (name) => compose(dispatch, addSection)(buildingId, { name, parent: isSubsections ? sectionId : null }),
    [buildingId, isSubsections, sectionId]
  );

  useEffect(() => {
    compose(dispatch, getDetail)(buildingId);
    getAggregations();
  }, [buildingId, getAggregations]);

  useEffect(() => {
    if (!activeEstimateStateId) return;
    if (subsectionId) {
      compose(dispatch, loadSection)(buildingId, subsectionId, activeEstimateStateId);
      return;
    }
    if (sectionId) {
      compose(dispatch, loadSection)(buildingId, sectionId, activeEstimateStateId);
    }
  }, [sectionId, buildingId, subsectionId, activeEstimateStateId]);

  useEffect(() => {
    estimateItemsChecker.reset();
  }, [activeEstimateStateId, buildingId, subsectionId, sectionId]);

  if (buildingIsLoading || aggregationsAreLoading)
    return (
      <TemplateSimple>
        <Spinner />
      </TemplateSimple>
    );

  if (!building || !aggregations || !activeEstimateStateId || !availableEstimateStates) return null;

  return (
    <TemplateSimple>
      <div className={cn(styles.handler, { [styles.withOpenIndicators]: areIndicatorsOpen })}>
        <header className={styles.header}>
          <EstimateStatesIndicators
            activeEstimateStateId={activeEstimateStateId}
            setActiveEstimateStateId={setActiveEstimateStateId}
            changeEstimateItemsState={handleChangeEstimateItemsState}
            isOpen={areIndicatorsOpen}
            handleOpening={handleIndicatorsOpening}
            isShared={isBuildingShared}
            permissions={permissions}
          />
          <div className={styles.headerContent}>
            <div className={styles.headerBottomRow}>
              <BackNavigationBar
                title={isSubsections ? activeSection?.name : "Проекты"}
                backLink={isSubsections ? null : "/constructing/projects"}
                onBack={
                  chapterIsSelectedInConsolidateState && !sectionId && !subsectionId ? resetConsolidateChapter : null
                }
              />
              {!isDraft && <Amounts isBuilding={!isSubsections} activeEstimateStateId={activeEstimateStateId} />}
            </div>
            <div className={styles.estimateState}>
              <Select
                className={styles.estimateStateSelect}
                onChange={setActiveEstimateStateId}
                value={activeEstimateStateId}
                options={availableEstimateStates}
                disabled={isDraftWithoutSections}
              />
              {isLocale && (
                <span className={styles.estimateStateDescription}>
                  Вы можете перетащить разделы, подразделы и/ или записи в сводный сметный расчет или отправить позиции
                  в производство
                </span>
              )}
            </div>
            <Route exact path={[`${match.path}/:sectionId`, match.path]}>
              {isDraft && (
                <CreateSection
                  sectionsAreEmpty={sectionsAreEmpty}
                  isSubsections={isSubsections}
                  onSubmitCreateSection={onSubmitCreateSection}
                />
              )}
            </Route>
            {!isSubsections && isDraft && (
              <div className={styles.uploadFile}>
                <ExcelProductsUpload
                  className={styles.button}
                  buttonText="Загрузить xml файл"
                  api={`/building/${buildingId}/estimates/`}
                  postCallback={checkUploadStatus}
                  isUploading={isUploading}
                  beginUploading={beginUploading}
                  noMessage
                />
              </div>
            )}
          </div>
        </header>
        <div className={styles.body}>
          {!isDraftWithoutSections && !isProduction && statusFromQueryParams !== ESTIMATE_ITEM_STATUSES.NEW && (
            <div className={styles.changeStateContainer}>
              <ChangeEstimateItemsState
                subsectionId={subsectionId}
                estimateStatesMenu={estimateStatesMenu}
                estimateItemsChecker={estimateItemsChecker}
                allSections={allSections}
                changeEstimateItemsState={handleChangeEstimateItemsState}
              />
            </div>
          )}
          <Body
            activeEstimateStateId={activeEstimateStateId}
            areIndicatorsOpen={areIndicatorsOpen}
            match={match}
            permissions={permissions}
            estimateItemsChecker={estimateItemsChecker}
            sectionsAreLoading={sectionsAreLoading}
            consolidateStateChapter={consolidateStateChapter}
            onSelectConsolidateStateChapter={setConsolidateStateChapter}
          />
        </div>
      </div>
    </TemplateSimple>
  );
};

export default Handler;
