import React, {MouseEventHandler, useCallback} from 'react'

import ProjectsBranch from "./components/ProjectsBranch/ProjectsBranch";

import {ISpittingTreeElement, ManufacturingTabsType} from '../../types'

import styles from "./ProjectsTree.module.scss";

export interface IProjectsTreeProps {
  spittingTree: ISpittingTreeElement[];
  type: ManufacturingTabsType;
  checkIsExpandedBranchId: (id: number) => boolean;
  toggleBranch: (branch: ISpittingTreeElement) => void;
  checkIsCheckboxVisible: (branch: ISpittingTreeElement) => boolean
  checkIsCheckboxTrue: (branch: ISpittingTreeElement) => boolean
  blockAndMoveRunningLine: MouseEventHandler
  releaseRunningLine: MouseEventHandler
}

const ProjectsTree: React.FC<IProjectsTreeProps> = ({ spittingTree, checkIsExpandedBranchId, toggleBranch, type, checkIsCheckboxVisible, checkIsCheckboxTrue, blockAndMoveRunningLine, releaseRunningLine }) => {
  return (
    <div className={styles.tree} style={{ height: `${(spittingTree.length + 1) * 3}rem` }}>
      {spittingTree.map((item, index) => (
        <ProjectsBranch
          key={index}
          index={index}
          branch={item}
          checkIsExpandedBranchId={checkIsExpandedBranchId}
          toggleBranch={toggleBranch}
          type={type}
          checkIsCheckboxVisible={checkIsCheckboxVisible}
          checkIsCheckboxTrue={checkIsCheckboxTrue}
          onHover={blockAndMoveRunningLine}
          onLeave={releaseRunningLine}
        />
      ))}
    </div>
  );
};

export default React.memo(ProjectsTree);
