import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import axios from "axios";
import moment from "moment";

import {
  detailDataSelector,
  setTimesheetApprovalFile,
  timesheetApprovalSelector
} from "redux/modules/common/building/object/nowObject";
import { workersCountWorkSelector, workersSelector } from "redux/modules/common/building/workers";

import FileBlock from "components/UI/molecules/FileBlock";
import Approval from "./components/Approval/Approval";
import WorkersTable from "./components/WorkersTable/WorkersTable";
import Info from "./components/Info/Info";

import { getLocalizedMonth } from "utils/getLocalizedMonth";
import { serializeFile } from "./utils/serializeFile";

import styles from "./Timesheet.module.scss";


const Timesheet = ({ buildingId, activeDate, loadMore, workersPaginationLimit }) => {
  const dispatch = useDispatch();
  const building = useSelector(detailDataSelector);
  const workers = useSelector(workersSelector);
  const timesheetApproval = useSelector(timesheetApprovalSelector);
  const workersCountWork = useSelector(workersCountWorkSelector);

  const year = activeDate.year();
  const monthIndex = activeDate.month();
  const monthNumber = monthIndex + 1;

  const chosenMonthAndYearIsFuture = useMemo(
    () => moment().year(year).month(monthIndex).isAfter(moment(), "date"),
    [monthIndex, year]
  );

  const handleSetTimesheetApprovalFile = useCallback(([file]) => {
    compose(dispatch, setTimesheetApprovalFile)({ buildingId, year, month: monthNumber }, file);
  }, [buildingId, year, monthNumber]);

  const deleteTimesheetApprovalFile = useCallback(() => {
    compose(dispatch, setTimesheetApprovalFile)({ buildingId, year, month: monthNumber }, null);
  }, [buildingId, year, monthNumber]);

  const filesForFileBlock = useMemo(
    () => {
      if (chosenMonthAndYearIsFuture) return [];
      const file = { url: timesheetApproval.file };
      if (!timesheetApproval.file) {
        file.url = `${axios.defaults.baseURL}/building/${buildingId}/t13-download/${year}/${monthNumber}/`;
        file.extension = "xlsx";
      }
      return [serializeFile(file, getLocalizedMonth(monthNumber - 1), year)];
    },
    [timesheetApproval.file, monthNumber, year, chosenMonthAndYearIsFuture]
  );

  return (
    <div className={styles.container}>
      <div className={styles.topBlock}>
        <Info
          docNumber={timesheetApproval.doc_number}
          status={timesheetApproval.status}
          brandName={building.entity_name}
          departmentTitle={timesheetApproval.department_title}
        />
        <div>
        <FileBlock
          files={filesForFileBlock}
          addFiles={handleSetTimesheetApprovalFile}
          deleteFile={deleteTimesheetApprovalFile}
          isDisabled
          mustConfirmDownload
        />
        </div>
      </div>
      <WorkersTable
        workers={workers}
        loadMore={loadMore}
        workersLimit={workersPaginationLimit}
        monthIndex={monthIndex}
        year={year}
        workersCountWork={workersCountWork}
      />
      <Approval year={year} monthNumber={monthNumber} buildingId={buildingId} employees={building.employees} />
    </div>
  );
};

export default React.memo(Timesheet);
