import React from 'react'
import styles from "./ManufacturingModalDivider.module.scss"
import cn from "classnames"

type propsType = {
    type: "plan" | "progress" | "accepted",
    className?: string
}

const textSwitcher = {
    plan: "План",
    progress: "К сдаче",
    accepted: "Принято"
}

function ManufacturingModalDivider({ type, className }: propsType) {
  return (
    <div className={cn(styles.container, styles[type], className)}>
        <div className={styles.status}>
            <div className={styles.icon} />
            {textSwitcher[type]}
        </div>
    </div>
  )
}

export default ManufacturingModalDivider