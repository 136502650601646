import axios from "axios";
import { compose } from "redux";

import { errorCatcher } from "../../../../../utils/errorCatcher";

import { setPackingItemFilesAction, setPackingItemsFilesLoadingAction } from "../actions";

const getPackingItemFiles = (packingItemId) => (dispatch) =>
  axios
    .get(`/purchaser/packinglists/packingitems/${packingItemId}/files/`)
    .then((response) =>
      compose(dispatch, setPackingItemFilesAction)({ itemId: packingItemId, files: response.data.results })
    )
    .catch(errorCatcher);

export const getPackingItemsFiles = (packingItemsIds) => async (dispatch) => {
  compose(dispatch, setPackingItemsFilesLoadingAction)(true);
  await Promise.all(packingItemsIds.map((packingItemId) => compose(dispatch, getPackingItemFiles)(packingItemId)));
  compose(dispatch, setPackingItemsFilesLoadingAction)(false);
};
