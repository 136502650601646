import axios from "axios";
import { compose } from "redux";

import { errorCatcher } from "../../../../../utils/errorCatcher";

import { addPackingItemFileAction } from "../actions";

export const addPackingItemFile = (packingItemId, file) => (dispatch) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("item_id", packingItemId);

  axios
    .post(`/purchaser/packinglists/packingitems/${packingItemId}/files/`, formData)
    .then((response) => compose(dispatch, addPackingItemFileAction)({ itemId: packingItemId, file: response.data }))
    .catch(errorCatcher);
};
