import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import {
  deleteSection,
  getSpecificationsExpenditures,
  getSpecificationsSections,
  getSpecificationsSubSections,
  isLoadingSpecificationSelector,
  MATERIALS_TYPES,
  specificationExpendituresSelector,
  specificationSectionsSelector,
  specificationSubSectionsSelector,
} from "../../../../../redux/modules/common/building/materials/specification";

import Icon from "components/UI/Icon";
import Expenditure from "./components/Expenditure";
import Section from "./components/Section";
import BottomSliderMaterials from "../BottomSliderMaterials";
import Chapter from "../Chapter";
import SubSection from "./components/SubSection";
import { Spinner } from "../../../../UI/Spinner/Spinner";
import EmptyPlaceholder from "../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import { TABS_IDS } from "../../index";

import arrowSmallBack from "images/icons/arrowSmallBack.svg";
import materialsIcon from "../../../../../images/icons/navigation/materials.svg";

import styles from "./Specification.module.scss";

const getMaterialTypeByUrl = (url) => {
  if (url === TABS_IDS.SPECIFICATION) return MATERIALS_TYPES.SPECIFICATION;
  if (url === TABS_IDS.OUT_OF_ESTIMATE) return MATERIALS_TYPES.OUT_OF_ESTIMATE;
  if (url === TABS_IDS.ADDITIONAL) return MATERIALS_TYPES.ADDITIONAL;
};

const Specification = ({
  objectId,
  typeUrl,
  setTitleBtn,
  setIdSubsection,
  titleBtn,
  setAddPosition,
  canEdit = true,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSpecificationSelector);
  const specificationSections = useSelector(specificationSectionsSelector);
  const specificationSubSections = useSelector(
    specificationSubSectionsSelector
  );
  const specificationSubExpenditures = useSelector(
    specificationExpendituresSelector
  );

  const [hasCategory, setHasCategory] = useState(true);
  const [isVisibleSection, setIsVisibleSection] = useState(true);
  const [isVisibleExpenditures, setIsVisibleExpenditures] = useState(false);
  const [isVisibleSubSection, setIsVisibleSubSection] = useState(false);
  const [positionEditModalOpen, setPositionEditModalOpen] = useState(false);

  const loadSubSection = (subSectionId) => {
    dispatch(
      getSpecificationsSubSections(
        objectId,
        getMaterialTypeByUrl(typeUrl),
        subSectionId
      )
    );
  };
  const loadExpenditures = (subSectionId) => {
    dispatch(
      getSpecificationsExpenditures(
        objectId,
        getMaterialTypeByUrl(typeUrl),
        subSectionId
      )
    );
  };

  const blockOpenSubSections = (item) => {
    if (item.subsections_count === 0 && item.products_count !== 0) {
      loadExpenditures(item.id);
      setTitleBtn("Добавить позицию");
      setIsVisibleSection(false);
      setIsVisibleExpenditures(true);
      setIsVisibleSubSection(false);
      setHasCategory(false);
    }
    if (item.subsections_count !== 0) {
      loadSubSection(item.id);
      setIdSubsection(item.id);
      setTitleBtn("Добавить подраздел");
      setIsVisibleSection(false);
      setIsVisibleSubSection(true);
      setIsVisibleExpenditures(false);
    }
    if (item.subsections_count === 0 && item.products_count === 0) {
      loadSubSection(item.id);
      setTitleBtn("Добавить подраздел");
      setIsVisibleSection(false);
      setIsVisibleSubSection(true);
      setIsVisibleExpenditures(false);
    }
  };

  const blockOpenExpenditure = (item) => {
    loadExpenditures(item.id);
    setTitleBtn("Добавить позицию");
    setIsVisibleSubSection(false);
    setIsVisibleExpenditures(true);
  };

  const closePositionEditModal = () => {
    dispatch(deleteSection(objectId));
    setPositionEditModalOpen(false);
  };

  const backToSections = () => {
    setTitleBtn("Добавить раздел");
    setIsVisibleSubSection(false);
    setIsVisibleSection(true);
  };

  const changePositionState = () => {
    setTitleBtn("Добавить подраздел");
    setAddPosition(false);
    if (hasCategory === false) {
      setIsVisibleExpenditures(false);
      setIsVisibleSection(true);
      setHasCategory(true);
    } else {
      setIsVisibleExpenditures(false);
      setIsVisibleSection(false);
      setIsVisibleSubSection(true);
    }
  };

  const visibleSection =
    isVisibleSection &&
    specificationSections.results &&
    specificationSections.results.length > 0;
  const visibleExpenditure =
    isVisibleExpenditures && specificationSubExpenditures?.products;

  useEffect(() => {
    setIsVisibleSubSection(false);
  }, [typeUrl]);

  useEffect(() => {
    dispatch(
      getSpecificationsSections(objectId, getMaterialTypeByUrl(typeUrl))
    );
    setTitleBtn("Добавить раздел");
    setIsVisibleSection(true);
    setIsVisibleExpenditures(false);
  }, [typeUrl, objectId]);

  return (
    <>
      <BottomSliderMaterials
        isOpen={positionEditModalOpen}
        closeFunc={closePositionEditModal}
      >
        <Chapter objId={objectId} close={closePositionEditModal} />
      </BottomSliderMaterials>
      <div
        className={cn(styles.container, {
          [styles.active]: isVisibleExpenditures,
        })}
      >
        {isLoading ? (
          <Spinner isStatic />
        ) : (
          <>
            {specificationSections.results &&
            specificationSections.results.length === 0 ? (
                <EmptyPlaceholder img={materialsIcon} />
              ) : (
                <>
                  {visibleSection &&
                  specificationSections.results.map(
                    (item) =>
                      item.subsections_count > 0 && (
                        <Section
                          key={item.id}
                          titleBtn={titleBtn}
                          objectId={objectId}
                          data={item}
                          blockOpenSubSections={blockOpenSubSections}
                          canEdit={canEdit}
                          count={item.products_count}
                        />
                      )
                  )}
                </>
              )}
            {isVisibleSubSection && (
              <>
                <div className={styles.containerBtn}>
                  <div
                    className={styles.button}
                    onClick={() => backToSections()}
                  >
                    <Icon icon={arrowSmallBack} className={styles.arrowLeft} />
                    Назад
                  </div>
                  <span>{specificationSubSections?.name}</span>
                </div>
                {specificationSubSections?.subsections_count === 0 ? (
                  <EmptyPlaceholder img={materialsIcon} />
                ) : (
                  <>
                    {specificationSubSections?.subsections.map(
                      (subSection) =>
                        subSection.products_count > 0 && (
                          <div onClick={() => setAddPosition(true)}>
                            <SubSection
                              key={subSection.id}
                              objectId={objectId}
                              data={subSection}
                              setPositionEditModalOpen={
                                setPositionEditModalOpen
                              }
                              blockOpenExpenditure={blockOpenExpenditure}
                              canEdit={canEdit}
                              count={subSection.products_count}
                            />
                          </div>
                        )
                    )}
                  </>
                )}
              </>
            )}
            {visibleExpenditure && (
              <>
                <div className={styles.containerBtnActive}>
                  <div
                    className={styles.button}
                    onClick={() => changePositionState()}
                  >
                    <Icon icon={arrowSmallBack} className={styles.arrowLeft} />
                    Назад
                  </div>
                  <span>{specificationSubExpenditures?.name}</span>
                </div>
                {specificationSubExpenditures.products.length === 0 ? (
                  <EmptyPlaceholder img={materialsIcon} />
                ) : (
                  <div>
                    <Expenditure
                      objectId={objectId}
                      data={specificationSubExpenditures.products}
                      typeUrl={typeUrl}
                      canEdit={canEdit}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Specification;
