import React  from "react";
import styles from "../../../../Manufacturing.module.scss";
import { generateDaysForLine } from "../../../../utils";
import { ICalendarBackgroundProps } from "../../CalendarBackground";
import cn from "classnames";
import {useUnitMultiplier} from '../../../../hooks/useUnitMultiplier'

const DaysBackground: React.FC<ICalendarBackgroundProps> = ({ year, unitHeight }) => {
  const unitMultiplier = useUnitMultiplier()
  const days = generateDaysForLine(+year);
  return (
    <div className={styles.daysLine} style={{ height: `${unitHeight * 3}rem` }}>
      {days.map((day, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={cn({
                [styles.dayWeekend]: day.weekend,
                [styles.dayWork]: !day.weekend,
              })}
              style={{
                left: `${index * unitMultiplier}rem`,
                width: `${unitMultiplier}rem`,
              }}
            />
            {day.today && (
              <div
                className={styles.currentDay}
                style={{
                  left: `${(index + 0.5) * unitMultiplier}rem`,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(DaysBackground);
