import React from 'react'
import RelatedProduct from '../RelatedProduct/RelatedProduct'
import styles from "./RequisitionsRowProducts.module.scss"

interface Iprops {
    products: any[];
    buildingId: number;
    loadProductsHandler: () => void;
}

const RequisitionsRowProducts: React.FC<Iprops> = ({ products, buildingId, loadProductsHandler }) => {
    return (
        <div className={styles.content}>
            <div className={styles.headerMaterial}>
                <div className={styles.headerItemBig}>Номенклатура</div>
                <div className={styles.box}>
                    <div className={styles.headerItem1} />
                    <div className={styles.headerItem}>Запрос</div>
                    <div className={styles.headerItem}>Заказано</div>
                    <div className={styles.headerItem}>Поставка</div>
                    <div className={styles.headerItem}>№ заказа</div>
                </div>
            </div>
            {products && products.map((product) => (
                <RelatedProduct
                    updateProducts={loadProductsHandler}
                    product={product}
                    objectId={buildingId}
                    key={product.id}
                />
            ))}
        </div>
    )
}

export default RequisitionsRowProducts