import update from "immutability-helper";

import { getIdentifier } from "../../../../utils/getIdentifier";

import { calculateRequestsTotalCount } from "./calculateRequestsTotalCount";
import { getRequestsCounts } from "./getRequestsCounts";

export const compareOffersAndSetCounts = (requests, baseOffers, comparedOffers) => {
  const DEFAULT_COMPONENT_COUNT = 1;
  const counts = { supplies: calculateRequestsTotalCount(requests), components: getRequestsCounts(requests) };

  const baseOffersById = {};
  baseOffers.forEach((offer) => baseOffersById[getIdentifier(offer)] = offer);

  return comparedOffers.map((offer) => {
    const baseCurrentOffer = baseOffersById[getIdentifier(offer)];
    if (baseCurrentOffer && offer.measure === baseCurrentOffer.measure) return offer;

    if (offer.measure === "компл") return update(offer, {
      supplies: { 0: { count: { $set:  offer.components.length * DEFAULT_COMPONENT_COUNT } } },
      components: {
        $set: offer.components.map((component) => ({ ...component, count: DEFAULT_COMPONENT_COUNT }))
      }
    });

    return update(offer, {
      supplies: { 0: { count: { $set: counts.supplies } } },
      components: {
        $set: offer.components.map((component) => ({ ...component, count: counts.components[component.request_id] }))
      }
    });
  });
};