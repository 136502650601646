import React from "react";
import moment from "moment";
import axios from "axios";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import memoize from "memoizee";

import Button from "components/UI/atoms/ButtonBase";
import { Spinner } from "components/UI/Spinner/Spinner";

import {
  addMessage,
  allMessagesSelector,
  dynamicMaxMessagesSelector,
  dynamicUnreadMessagesSelector,
  getMessages,
  isLoadingSelector,
  maxCountSelector,
  readMessages,
  resetToInitial,
  setMessages,
  unreadValSelector
} from "../../../../redux/modules/common/chat/chat";

import { socketAddEvent, socketRemoveEvent, socketSend } from "utils/socketEvents";
import { remToPx } from "utils/remToPx";
import { getFullNameString } from "utils/helpers";
import { isMobileDevice } from "utils/isMobileDevice";

import SendIcon from "images/icons/circle-arrow-up.svg";

import Message from "./Message/Message";
import { isShowChatParam } from "./constants";

import styles from "./Chat.module.scss";


class Chat extends React.PureComponent {
  state = {
    show: false,
    inputValue: "",
    offset: 0,
    newMessagesBelow: false,
    mentionedUser: "",
    suggestedUsers: [],
    mentions: [],
    mentionIndex: -1,
    maxCount: 0,
    unreadVal: 0
  };

  componentDidMount() {
    const { docId, getMessages, urlType, isStub, setMessages } = this.props;
    const { offset } = this.state;
    const isShowChat = new URL(window.location.href).searchParams.get(isShowChatParam);

    if (isStub) {
      setMessages({ results: [], count: 0 });
      return;
    }

    if (isShowChat) {
      this.setState({
        show: true
      });
    }

    socketAddEvent("chat", this.getMessage);
    getMessages(urlType, docId, offset, false, this.setValues);
  }

  componentWillUnmount() {
    if (this.props.isStub) return;

    socketRemoveEvent("chat", this.getMessage);
    this.props.resetToInitial();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { urlType, docId, readMessages, isStub } = this.props;

    if (isStub) return;
    if (this.state.show !== prevState.show) {
      if (this.state.show === true) {
        this.scrollTopEnd();
        readMessages(urlType, docId);
        if (this.state.unreadVal !== 0) {
          this.setState({
            unreadVal: 0
          });
        }
        if (isMobileDevice()) {
          document.body.style.position = "fixed";
        }
      } else {
        if (isMobileDevice()) {
          document.body.style.position = "static";
        }
      }
    }
  }

  setValues = (data) => {
    this.setState({
      maxCount: data.count,
      unreadVal: data.unread_count
    });
  };

  getMessage = (data) => {
    const { docType, docId, readMessages, addMessage, userId, urlType } = this.props;
    const { show } = this.state;
    if (data.doc_type === docType && parseFloat(data.doc_id) === docId) {
      addMessage(data);
      if (parseFloat(data.sender.id) === parseFloat(userId)) {
        this.scrollTopEnd();
      }
      if (show) {
        readMessages(urlType, docId);
        if (this.state.unreadVal !== 0) {
          this.setState({
            unreadVal: 0
          });
        }
      }
      if (
        this.messagesContainer &&
        this.messagesContainer.scrollHeight -
        (this.messagesContainer.scrollTop + this.messagesContainer.offsetHeight) >
        remToPx(1)
      ) {
        this.setState({
          newMessagesBelow: true
        });
      }
    }
  };

  getUsers = () => {
    const { buildingId } = this.props;
    const { mentionedUser } = this.state;
    if (buildingId) {
      axios
        .get(`/building/${buildingId}/employees/`, {
          params: {
            search: mentionedUser
          }
        })
        .then(
          (response) => {
            this.setState({
              suggestedUsers: response.data
            });
          },
          (err) => {
            this.setState({
              suggestedUsers: []
            });
          }
        );
    }
  };

  selectSuggestedUser = (user) => {
    const { inputValue, mentionIndex, mentions } = this.state;

    let nowIndex = -1;
    const wordArr = inputValue.split(" ");
    const newValue = wordArr
      .map((word, index) => {
        if (word[0] === "@") {
          nowIndex += 1;
          if (nowIndex === mentionIndex) {
            return `@${user.last_name}${index >= wordArr.length - 1 ? " " : ""}`;
          }
        }
        return word;
      })
      .join(" ");
    const newMentions = [...mentions];
    newMentions[mentionIndex] = user;
    this.setState(
      {
        inputValue: newValue,
        mentionedUser: "",
        suggestedUsers: [],
        mentionIndex: -1,
        mentions: newMentions
      },
      () => this.input.focus()
    );
  };

  showHandler = () =>
    this.setState({
      show: true
    });

  hideHandler = () =>
    this.setState({
      show: false
    });

  inputChangeHandler = (e) => {
    const charArr = [...e.target.value];
    let mentionIndex = charArr.reduce((result, char, index) => {
      if (
        char === "@" &&
        (index === 0 || charArr[index - 1] === " ") &&
        e.target.selectionStart > index
      ) {
        result += 1;
      }
      return result;
    }, -1);

    if (e.target.value.substring(0, e.target.selectionStart).split(" ").pop()[0] !== "@") {
      mentionIndex = -1;
    }

    const mentionedUser =
      mentionIndex !== -1
        ? e.target.value
          .split(" ")
          .filter((word) => word[0] === "@")
        [mentionIndex].substring(1)
        : "";

    this.setState(
      {
        inputValue: e.target.value,
        mentionedUser: mentionIndex !== -1 ? mentionedUser : "",
        mentionIndex: mentionIndex,
        [mentionIndex === -1 ? "suggestedUsers" : null]: []
      },
      mentionIndex !== -1 ? this.getUsers : null
    );
  };

  sendMessage = () => {
    const { inputValue, mentions } = this.state;
    const { docType, docId } = this.props;
    if (!inputValue.replace(/\s/g, "")) return false;
    let nowIndex = -1;
    socketSend({
      message: inputValue
        .split(" ")
        .map((word) => {
          if (word[0] === "@") {
            nowIndex += 1;
            return mentions[nowIndex]
              ? word.replace(`${mentions[nowIndex].last_name}`, `${mentions[nowIndex].id}@`)
              : word;
          }
          return word;
        })
        .join(" "),
      doc_type: docType,
      doc_id: docId,
      mentions: mentions.map((user) => user.id)
    });
    this.setState({
      inputValue: "",
      mentionedUser: "",
      suggestedUsers: [],
      mentions: [],
      mentionIndex: -1
    });
  };

  scrollTopEnd = () => {
    try {
      this.messagesContainer.scrollTop = this.messagesContainer.scrollHeight;
      this.setState({
        newMessagesBelow: false
      });
    } catch (e) {
    }
  };

  enterKeyHandle = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.sendMessage();
    }
  };

  onMessageLoadMore = () => {
    const { urlType, docId, getMessages } = this.props;
    this.setState(
      (prevState) => ({
        offset: prevState.offset + 50
      }),
      () => getMessages(urlType, docId, this.state.offset, true)
    );
  };

  onMessageScroll = (e) => {
    if (e.target.scrollTop + e.target.offsetHeight - e.target.scrollHeight >= 0) {
      this.setState({
        newMessagesBelow: false
      });
    }
  };

  formatMessages = memoize((messages) => {
    const formedDataByDate = messages.reduce((accumulator, currentValue) => {
      const format = "DD.MM.YYYY";
      // const lastBlock = accumulator[accumulator.length - 1];
      // const lastBlockDate = lastBlock && moment(lastBlock.created_at).format(format);
      const currentValueDate = moment(currentValue.created_at).format(format);
      if (accumulator.find(item => moment(item.created_at).format(format) === currentValueDate)) {
        accumulator.find(item => moment(item.created_at).format(format) === currentValueDate).messages.push(currentValue);
      } else {
        accumulator.push({
          date: moment(currentValue.created_at).format("DD MMMM"),
          created_at: currentValue.created_at,
          messages: [currentValue]
        });
      }
      return accumulator;
    }, []);

    return formedDataByDate.map((date) => ({
      ...date,
      messages: date.messages.reduce((accumulator, currentValue) => {
        const lastBlock = accumulator[accumulator.length - 1];
        const nowMessage = {
          id: currentValue.id,
          text: currentValue.message,
          time: currentValue.created_at,
          mentions: currentValue.mentions
        };
        if (lastBlock?.sender.id === currentValue.sender.id) {
          lastBlock.messages.push(nowMessage);
        } else {
          accumulator.push({
            system_message: currentValue.system_message,
            sender: currentValue.sender,
            messages: [nowMessage]
          });
        }
        return accumulator;
      }, [])
    }));
  });

  render() {
    const {
      title,
      subTitle,
      userId,
      messages,
      isLoading,
      openText = "Открыть чат",
      closeText = "Закрыть чат",
      dynamicUnreadMessages,
      dynamicMaxMessages,
      docType,
      openOffset = { x: 0, y: 0 }
    } = this.props;

    const dynamicUnreadMessagesCount = dynamicUnreadMessages.filter(
      (message) => message.doc_type === docType
    ).length;

    const {
      show,
      inputValue,
      offset,
      newMessagesBelow,
      suggestedUsers,
      maxCount,
      unreadVal
    } = this.state;

    return (
      <React.Fragment>
        {show && (
          <div className={styles.dragContainer}>
            <Draggable
              defaultPosition={{
                x: openOffset.x,
                y: openOffset.y
              }}
              handle={"#chatTitleHandle"}
              disabled={isMobileDevice()}
            >
              <div className={styles.chatWindow}>
                <div className={styles.handle}>
                  <div id={"chatTitleHandle"} className={styles.handleContent}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.subTitle}>{subTitle}</div>
                    {isMobileDevice() ? (
                      <div className={styles.cancelButton}>
                        <Button type={"cancel"} onClick={this.hideHandler}>
                          Закрыть
                        </Button>
                      </div>
                    ) : (
                      <div onClick={this.hideHandler} className={styles.minimizeButton} />
                    )}
                  </div>
                </div>
                <div
                  ref={(messagesContainer) => (this.messagesContainer = messagesContainer)}
                  className={styles.messagesContainer}
                  onScroll={this.onMessageScroll}
                >
                  {isLoading && offset > 0 && (
                    <div className={styles.loadMorePlate}>
                      <Spinner isSmall />
                    </div>
                  )}
                  {!isLoading && maxCount > messages.length && (
                    <div className={styles.loadMoreButton}>
                      <Button onClick={this.onMessageLoadMore}>Показать больше</Button>
                    </div>
                  )}
                  {isLoading && offset === 0 ? (
                    <div className={styles.loadingPlate}>
                      <Spinner />
                    </div>
                  ) : (
                    this.formatMessages(
                      messages.filter(
                        (message) =>
                          message.doc_type === this.props.docType &&
                          parseFloat(message.doc_id) === parseFloat(this.props.docId)
                      )
                    ).map((block) => (
                      <div key={block.date} className={styles.dateContainer}>
                        <span className={styles.dateText}>{block.date}</span>
                        {block.messages.map((message) => (
                          <Message isMe={message.sender.id === parseFloat(userId)} info={message} />
                        ))}
                      </div>
                    ))
                  )}
                  {!isLoading && messages.length === 0 && (
                    <span className={styles.emptyMessages}>История сообщений пуста</span>
                  )}
                </div>
                <div className={styles.controls}>
                  {newMessagesBelow && (
                    <span onClick={this.scrollTopEnd} className={styles.belowMessages}>
                      Новые сообщения
                    </span>
                  )}
                  {suggestedUsers.length > 0 && (
                    <div className={styles.usersSuggestionsContainer}>
                      <div className={styles.usersSuggestions}>
                        {suggestedUsers.map((user) => (
                          <div
                            onClick={() => this.selectSuggestedUser(user)}
                            className={styles.usersItems}
                          >
                            <div className={styles.userName}>
                              {getFullNameString(user.first_name, user.last_name, user.middle_name)}
                            </div>
                            <div className={styles.userRole}>
                              {user.roles.map((role) => role.name).join(", ")}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <TextareaAutosize
                    ref={(input) => (this.input = input)}
                    onKeyDown={this.enterKeyHandle}
                    onChange={this.inputChangeHandler}
                    value={inputValue}
                    maxLength={510}
                    minRows={1}
                    maxRows={3}
                  />
                  <img onClick={this.sendMessage} alt={"Отправить"} src={SendIcon} />
                </div>
              </div>
            </Draggable>
          </div>
        )}
        <div onClick={show ? this.hideHandler : this.showHandler} className={styles.openChatBlock}>
          <div className={styles.hiddenChat}>
            {
              <div className={styles.tabsCounter}>
                {(unreadVal > 0 || dynamicUnreadMessagesCount > 0) && (
                  <div className={`${styles.tabsCounterCircle} ${styles.tabsCounterCircle_Red}`}>
                    {Math.min(unreadVal + dynamicUnreadMessagesCount, 999)}
                  </div>
                )}
                <div className={styles.tabsCounterCircle}>
                  {maxCount +
                    dynamicMaxMessages.filter((message) => message.doc_type === docType).length}
                </div>
              </div>
            }
          </div>
          <div className={styles.openChatText}>
            {show ? closeText : openText}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  (state) => ({
    userId: state.auth.user.id,
    messages: allMessagesSelector(state),
    unreadVal: unreadValSelector(state),
    isLoading: isLoadingSelector(state),
    maxCount: maxCountSelector(state),
    dynamicUnreadMessages: dynamicUnreadMessagesSelector(state),
    dynamicMaxMessages: dynamicMaxMessagesSelector(state)
  }),
  {
    setMessages,
    getMessages,
    addMessage,
    readMessages,
    resetToInitial
  }
)(Chat);
