import React from "react";
import cn from "classnames";

import { transformDigitToFinancial } from "../../../../utils/transformDigitToFinancial";
import { formatNumberWithMeasure } from "../../../../utils/formatNumberWithMeasure";

import styles from "./Amount.module.scss";

const Amount = ({ value, title, isBold, className, titleClassName, measure }) => (
  <div className={cn(styles.amount, { [styles.bold]: isBold }, className)}>
    <div>
      <span className={styles.numericValue}>
        {measure
          ? formatNumberWithMeasure(value, measure)
          : transformDigitToFinancial(value, {
              withFloat: false,
              withRuble: true,
            })}
      </span>
      {title && <div className={cn(styles.title, titleClassName)}>{title}</div>}
    </div>
  </div>
);

export default React.memo(Amount);
