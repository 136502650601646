import React from "react";
import getShortFullName from "utils/getShortFullName";
import { localizeServerDateInUserTimezone } from "../../../../../utils/localizeServerDateInUserTimezone";

import styles from "./Message.module.scss";

export default class Message extends React.PureComponent {
  render() {
    const { isMe, info } = this.props;
    const {
      sender: { first_name, last_name, middle_name, roles },
      messages,
      system_message
    } = info;

    return system_message ? (
      <div className={styles.systemMessage}>{messages[0]}</div>
    ) : (
      <div
        className={styles.container}
        style={{
          alignSelf: isMe ? "flex-end" : "flex-start",
          alignItems: isMe ? "flex-end" : "flex-start"
        }}
      >
        <div className={styles.sender} style={{ alignSelf: isMe ? "flex-end" : "flex-start" }}>
          {getShortFullName(first_name, last_name, middle_name)}<br />
          {roles.length > 0 && (
            <span className={styles.senderRole}>
              {" "}
              ({roles.map((role) => role.name).join(", ")}):{" "}
            </span>
          )}
        </div>
        {messages.map((message) => {
          const formattedText = message.text.split(" ").map((word) => {
            const matchResult = word.match(/\@.*\@/);
            if (matchResult) {
              const user = message.mentions.find((mention) => matchResult[0] === `@${mention.id}@`);
              return user ?
                word.replace(
                  matchResult[0],
                  `@${user?.last_name}`
                )
                :
                word
                ;
            }
            return word;
          }).join(" ");

          return (
            <div
              key={message.id}
              style={{ flexDirection: isMe ? "row-reverse" : "row" }}
              className={styles.message}
            >
              <div className={styles.text}>{formattedText}</div>
              <div className={styles.time}>
                {localizeServerDateInUserTimezone(message.time, "HH:mm")}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
