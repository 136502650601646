import styled from "styled-components";


export const Wrapper = styled.section`
  width: 100%;
  position: relative;
  height: 100%;
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
`;

export const NavigationInfo = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5625rem;
`;

export const InputForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

export const PasswordForm = styled.div`
  margin-top: 3.125rem;
`;

export const FlexPassword = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 1.8rem;
`;

export const FlexInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.$mbNone ? 0 : "1.8rem"};
  position: relative;

  & input {
    border-color: var(--color-font);
  }
`;

export const Label = styled.label`
  margin-bottom: 0.35rem;
`;

export const MidText = styled.div`
  font-size: ${props => props.$fontSize ? `${props.$fontSize}rem` : "1.125rem"};
  font-weight: 500;
  color: #000;
`;

export const NavigationTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--color-font-secondary1);
`

export const AdaptiveSize = styled.div`
  flex-basis: 45%;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxTitle = styled.div`
  margin-left: 0.969rem;
  color: var(--color-font4);
  font-size: 1.125rem;
  font-weight: 400;
`;

export const ErrorMessage = styled.div`
  margin-top: 14;
  color: var(--color-status-active);
  font-size: 0.875rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 40;
  display: flex;
  justify-content: flex-end;
`;
