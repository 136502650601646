import React, { Fragment } from "react";
import moment from "moment";

import { DAYS_ABBRS, MONTH_IDS } from "../../../../constants";

import styles from "./MonthsLine.module.scss";
import { ICalendarDateLineProps } from "../../CalendarDateLine";
import {useUnitMultiplier} from '../../../../hooks/useUnitMultiplier'

const MonthsLine = React.forwardRef<HTMLDivElement, ICalendarDateLineProps>(({ year }, ref) => {
  const unitMultiplier = useUnitMultiplier()
  return (
    <div className={styles.monthsLine} ref={ref}>
      {MONTH_IDS.map((monthId, index) => {
        const monthMoment = moment()
          .year(+year)
          .month(monthId);

        const today = moment().isSame(monthMoment, 'month')
          ? moment().date()
          : undefined;

        const todayLabel = today !== undefined && `${DAYS_ABBRS[moment().weekday()]}, ${moment().format('DD MMM')}`

        return (
          <Fragment key={index}>
            <div
              className={styles.monthCaptionBlock}
              style={{ left: `${index * unitMultiplier}rem`, width: `${unitMultiplier}rem` }}
            >
              <span className={styles.monthName}>{monthMoment.format("MMM")}</span>
              <span className={styles.monthInterval}>
                {monthMoment.startOf("month").format("DD.MM")}-{monthMoment.endOf("month").format("DD.MM")}
              </span>
            </div>
            {today !== undefined && (
              <div
                className={styles.currentDay}
                style={{
                  left: `${(index + today / monthMoment.daysInMonth()) * unitMultiplier}rem`,
                }}
              >
                <span>{todayLabel}</span>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
});
export default React.memo(MonthsLine);
