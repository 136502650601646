import React, { useCallback } from "react";
import cn from "classnames";
import moment from "moment";

import { SIZES } from "../../atoms/Select";

import MonthSlider from "./MonthsSlider/MonthsSlider";
import styles from "./MonthsYearsSlider.module.scss";
import YearSelect from '../../atoms/YearSelect/YearSelect'

const DEFAULT_DATE = moment();

function MonthsYearsSlider({ date = DEFAULT_DATE, onChange, className, selectingMonthsCount = 1 }) {
  const year = date.year();
  const monthIndex = date.month();

  const changeDate = useCallback(
    (year, monthIndex) => onChange(moment(new Date(year, monthIndex, 1))),
    [onChange]
  );

  const onChangeYear = useCallback((changedYear) => {
    changeDate(changedYear, monthIndex);
  }, [monthIndex, changeDate]);

  return (
    <div className={cn(styles.container, className)}>
      <YearSelect
        size={SIZES.SMALL}
        value={year}
        onChange={onChangeYear}
      />
      <MonthSlider date={date} changeDate={changeDate} selectingMonthsCount={selectingMonthsCount} />
    </div>
  );
}

export default React.memo(MonthsYearsSlider);
