import axios from "axios";
import { compose } from "redux";

import { errorCatcher } from "utils/errorCatcher";

import { PermissionPropertiesEnum } from "../../../../../components/pages/Settings/components/Users/UserPage/enums";

import { setUserPermissionsDataAction } from "../actions";
import { userPermissionsDataSelector } from "../reducer";
import { concatenateWithoutDuplicates } from "../../../../../utils/concatenateWithoutDuplicates";

const serializePermissions = (permissions) =>
  Object.entries(permissions).map(([alias, isOn]) => ({
    alias,
    name: alias,
    property: isOn ? PermissionPropertiesEnum.optionalOn : PermissionPropertiesEnum.optionalOff,
  }));

export const updateUserPermissions = (userId, permissions) => (dispatch, getState) => {
  const serializedUpdatedPermissions = serializePermissions(permissions);
  const serializedUserPermissions = userPermissionsDataSelector(getState()).map((permission) => ({
    ...permission,
    alias: permission.name,
  }));

  const updatedUserPermissions = concatenateWithoutDuplicates(
    serializedUserPermissions,
    serializedUpdatedPermissions,
    "alias"
  );

  axios
    .post("/user/permissions/", { user_id: userId, aliases: updatedUserPermissions })
    .then(() => {
      compose(dispatch, setUserPermissionsDataAction)(updatedUserPermissions);
    })
    .catch(errorCatcher);
};
