import React from 'react'

const Component = React.lazy(() => import('./Item'))

function PlanFactItem(props) {
  return (
    <React.Suspense fallback={null}>
      <Component {...props} />
    </React.Suspense>
  )
}

export default PlanFactItem
