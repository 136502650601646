import moment from 'moment';
import React from 'react'
import { editPlanInterval } from 'redux/modules/common/building/processApi';
import { IMaterialInPlanInterval, ISerializedMaterialInPlan } from 'types/interfaces/Materials';
import { errorCatcher } from 'utils/errorCatcher';
import { mapEditingPlanMaterials } from './utils';

interface IidAndCount {
    id: number;
    count: number;
}

interface Iprops {
    planDateStart: string,
    planDateEnd: string,
    planCount: number,
    addedUsers: IidAndCount[],
    addedMaterials: ISerializedMaterialInPlan[],
    expenditureId: number,
    buildingId: number,
    intervalId: number,
    onClose: () => void;
    previousMaterials: IMaterialInPlanInterval[]
}

export const usePlanEdit = ({
    planDateStart,
    planDateEnd,
    planCount,
    addedUsers,
    addedMaterials,
    expenditureId,
    intervalId,
    buildingId,
    onClose,
    previousMaterials
}: Iprops) => {
    const [dateStart, setDateStart] = React.useState(planDateStart);
    const [dateEnd, setDateEnd] = React.useState(planDateEnd);
    const [count, setCount] = React.useState(planCount);

    const submitHandler = () => {
        const data = {
            start_at: moment(dateStart).format('YYYY-MM-DD'),
            end_at: moment(dateEnd).format('YYYY-MM-DD'),
            count: count || 0,
            workers: addedUsers,
            planned_materials: mapEditingPlanMaterials(addedMaterials, previousMaterials),
            count_workers: addedUsers.map((item) => ({ worker_id: item.id, count: item.count }))
        }

        editPlanInterval({
            buildingId,
            intervalId,
            expenditureId,
            data
        })
            .then(onClose)
            .catch(errorCatcher)
    }

    return {
        submitHandler,
        count,
        setCount,
        dateEnd,
        setDateEnd,
        dateStart,
        setDateStart
    }
}
