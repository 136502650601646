import Axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'
import MaterialsInfoItem from '../components/MaterialInfoItem/MaterialsInfoItem'
import MaterialListItem from '../components/MaterialListItem/MaterialListItem'
import NavBar from '../components/NavBar/NavBar'
import ExpandableFileInput from "./../../../molecules/ExpandableFileInput/ExpandableFileInput"
import EmptyPlaceholder from "../../../atoms/EmptyPlaceholder/EmptyPlaceholder"
import styles from "./JournalDoneModal.module.scss"
import { splitThousands } from '../../../../../utils/splitThousands'
import { Spinner } from '../../../Spinner/Spinner'
import WorkerItem from "./../components/WorkersList/WorkerItem"
import moment from 'moment'
import materialsIcon from "images/icons/navigation/materials.svg";
import { transformDigitToFinancial } from 'utils/transformDigitToFinancial'
import getShortFullName from 'utils/getShortFullName'
import {dropNonSignificantZeros} from '../../../../../utils/dropNonSignificantZeros'

function JournalDoneMaterials({ data, isOpen, onHideTabs, isExist }) {
  const { objectId } = useParams()
  const [currentMaterial, setCurrentMaterial] = React.useState(null)
  const [materials, setMaterials] = React.useState([])
  const [writeOffs, setWriteOffs] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [files, setFiles] = React.useState([])

  React.useEffect(() => {
    if (!isOpen) return
    setIsLoading(true)
    Axios.get(`/stocks/${data?.stock_id}/using/expenditures/${data?.id}/`).then(resp => {
      setMaterials(resp.data.results)
      setIsLoading(false)
    })
    Axios.get(`/building/${objectId}/estimate/items/${data?.id}/stockusingmaterials/`).then(resp => {
      const writeOffs = resp.data.results.filter(el => !!el.confirm_count)
      setWriteOffs(writeOffs)
    })
  }, [objectId, isOpen])

  React.useEffect(() => {
    if (!currentMaterial) return
    Axios.get(`/building/${objectId}/expenditure_files/${data?.id}/`)
      .then(resp => setFiles(resp.data.results?.map(el => ({ ...el, name: el.file_name }))))
  }, [currentMaterial])

  React.useEffect(() => {
    if (!isOpen) {
      setWriteOffs(null)
      setMaterials(null)
      setCurrentMaterial(null)
      setFiles([])
    }
  }, [isOpen, objectId])

  React.useEffect(() => {
    if (!materials?.length && !isLoading) {
      onHideTabs(false)
    } else {
      onHideTabs(true)
    }
    if (materials?.length === 1) {
      setCurrentMaterial(materials[0])
    }
  }, [materials, isLoading])

  const fileDeleteHandler = (fileId) => {
    Axios.delete(`/building/${objectId}/expenditure_files/${data?.id}/${fileId}`).then(() => {
      Axios.get(`/building/${objectId}/expenditure_files/${data?.id}/`)
        .then(resp => setFiles(resp.data.results?.map(el => ({ ...el, name: el.file_name }))))
    })
  }

  const fileUploadHandler = (file) => {
    const formData = new FormData()
    formData.append("file", file)
    Axios.post(`/building/${objectId}/expenditure_files/${data?.id}/`, formData).then(resp => {
      Axios.get(`/building/${objectId}/expenditure_files/${data?.id}/`)
        .then(resp => setFiles(resp.data.results?.map(el => ({ ...el, name: el.file_name }))))
    })
  }

  if (!isExist) return null

  return (
    <>
      {(!isLoading && !currentMaterial && materials?.length) ? materials?.map(el => <MaterialListItem
        name={el.stock_product?.product_building?.name}
        count={dropNonSignificantZeros(el.count)}
        measure={el.stock_product?.product_building?.measure}
        key={el.id}
        item={el}
        onClick={setCurrentMaterial}
      />) : null}
      {(!isLoading && !currentMaterial && !materials?.length) ? <EmptyPlaceholder img={materialsIcon} /> : null}
      {isLoading && <Spinner />}
      {currentMaterial && <>
        <NavBar text="Все материалы" isExist={materials?.length > 1} onClick={() => setCurrentMaterial(null)} />
        <h2 className={styles.title}>{currentMaterial.stock_product?.product_building?.name}</h2>
        <div className={styles.row2}>
          <div className={styles.cell}>
            <div className={styles.label}>Кол-во:</div>
            <div className={styles.value}
              title={transformDigitToFinancial(currentMaterial?.count) + "  (" + currentMaterial.stock_product?.product_building?.measure + ")"}
            >
              {transformDigitToFinancial(currentMaterial?.count)} ({currentMaterial.stock_product?.product_building?.measure})
            </div>
          </div>
          <div className={styles.cell}>
            <div className={styles.label}>Стоимость:</div>
            <div className={styles.value}>{splitThousands(currentMaterial.amount)}</div>
          </div>
        </div>
        <div className={styles.doubleInfo}>
          <div className={styles.grid}>
            <div className={styles.moment}>{moment(currentMaterial?.stock_using?.used_at).format("DD/MM/YYYY")}</div>
            <div className={styles.accept}>Выдал:</div>
            <WorkerItem worker={currentMaterial?.stock_using?.responsible || {}} color="green" />
          </div>
          <div className={styles.grid}>
            <div className={styles.moment}>в {moment(currentMaterial?.stock_using?.used_at).format("HH:mm")}</div>
            <div className={styles.accept}>Получил:</div>
            <WorkerItem worker={currentMaterial?.stock_using?.user || currentMaterial?.stock_using?.worker || {}} />
          </div>
        </div>
        <div className={styles.hr} />
        {writeOffs?.map(el => <MaterialsInfoItem
          date={el.confirm_date}
          name={getShortFullName(el.confirm_user)}
          count={dropNonSignificantZeros(el.confirm_count)}
          measure={el.stock_using_material?.product_building?.measure}
          amount={el.confirm_estimate_amount}
        />)}
        <div className={styles.hr} />
        <ExpandableFileInput files={files || []} onRemoveFileDirectly={fileDeleteHandler} onUploadFileDirectly={fileUploadHandler} />
      </>}

    </>
  )
}

export default JournalDoneMaterials