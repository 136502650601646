import React, { useCallback, useMemo } from "react";
import moment, { Moment } from "moment";

import {
  WEEK,
  YEAR,
} from "../../../../../redux/modules/common/building/manufacturing/manufacturing";

import TabBarNotLinks from "../../../../../elements/navigation/TabBar/TabBarNotLinks";
import MonthsYearsSlider from "../../../../UI/molecules/MonthsYearsSlider/MonthsYearsSlider";
import ButtonArrow from "../../../../UI/atoms/ButtonArrow/ButtonArrow";
import SelectYearWithArrows from "../../../../UI/atoms/SelectYearWithArrows/SelectYearWithArrows";

import { WEEK_SCROLL_LENGTH } from "../../constants";
import { IMonthArrayElement } from "../../types";

import { useChartViewModeTabs } from "../../hooks/useChartViewModeTabs";
import { useTabsWithPermissions } from "../../hooks/useTabsWithPermissions";

import styles from "../../Manufacturing.module.scss";

export interface IManufacturingControlsProps {
  year: number | string;
  month: IMonthArrayElement;
  handleDateChange: (newDate: Moment) => void;
  scrollCalendar: (units: number) => void;
  haveViewWorksTabPermission: boolean;
  haveViewMaterialsTabPermission: boolean;
}

const ManufacturingControls: React.FC<IManufacturingControlsProps> = ({
  year,
  month,
  handleDateChange,
  scrollCalendar,
  haveViewWorksTabPermission,
  haveViewMaterialsTabPermission,
}) => {
  const { chartViewMode, setViewTab, chartViewModeTabs } =
    useChartViewModeTabs();

  const { worksMaterialsTabs, tab, setTab } = useTabsWithPermissions({
    haveViewWorksTabPermission,
    haveViewMaterialsTabPermission,
  });

  const increaseWeek = useCallback(
    () => scrollCalendar(WEEK_SCROLL_LENGTH),
    []
  );
  const decreaseWeek = useCallback(
    () => scrollCalendar(-WEEK_SCROLL_LENGTH),
    []
  );

  const selectedDate = useMemo(
    () =>
      chartViewMode === YEAR
        ? moment()
            .year(+year)
            .dayOfYear(1)
        : moment()
            .year(+year)
            .month(month.id)
            .date(1),
    [year, chartViewMode, month]
  );

  return (
    <div className={styles.controls}>
      <TabBarNotLinks
        tabs={worksMaterialsTabs}
        activeId={tab}
        onClick={setTab}
      />
      <div>
        {chartViewMode === YEAR ? (
          <SelectYearWithArrows
            date={selectedDate}
            onChange={handleDateChange}
          />
        ) : (
          <MonthsYearsSlider
            date={selectedDate}
            onChange={handleDateChange}
            className={styles.datePicker}
          />
        )}
      </div>
      <TabBarNotLinks
        tabs={chartViewModeTabs}
        activeId={chartViewMode}
        onClick={setViewTab}
        className={styles.viewTabs}
      />
      {chartViewMode === WEEK && (
        <div className={styles.weeksLineControls}>
          <ButtonArrow
            direction={"left"}
            className={styles.weeksLineButton}
            onClick={decreaseWeek}
          />
          <ButtonArrow
            direction={"right"}
            className={styles.weeksLineButton}
            onClick={increaseWeek}
          />
        </div>
      )}
    </div>
  );
};

export default ManufacturingControls;
