import { Dispatch } from "redux";
import { message } from "antd";

import { journalApi } from "./journalApi";
import {
  setStockUsingMaterialsPriceList,
  setIsStockUsingMaterialsPriceUpdateSuccess,
} from "./actions";

import { errorCatcher } from "utils/errorCatcher";

export const getStockUsingMaterialsPrice =
  (buildingId: number, expenditureId: number, stockId: number) =>
  (dispatch: Dispatch) => {
    journalApi
      .getStockUsingMaterialsPrice(buildingId, expenditureId, stockId)
      .then(({ data }) => {
        dispatch(setStockUsingMaterialsPriceList(data));
      })
      .catch(errorCatcher);
  };

export const updateStockUsingMaterialsPrice =
  (
    buildingId: number,
    expenditureId: number,
    stockId: number,
    amount: number
  ) =>
  (dispatch: Dispatch) => {
    dispatch(setIsStockUsingMaterialsPriceUpdateSuccess(false));

    journalApi
      .patchStockUsingMaterialsConfirmPrice(
        buildingId,
        expenditureId,
        stockId,
        amount
      )
      .then(() => {
        dispatch(setIsStockUsingMaterialsPriceUpdateSuccess(true));
        message.success("Стоимость успешно выбрана");
      })
      .catch(errorCatcher);
  };
