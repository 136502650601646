import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { addExpenditure } from "../../../redux/modules/common/building/materials/specification";

import LabeledSwitch from "components/UI/atoms/LabeledSwitch";
import ButtonBase from "components/UI/atoms/ButtonBase";
import TemplateBase from "components/UI/templates/TemplateBase";
import ExpenditureForm from "../../UI/ExpenditureForm/ExpenditureForm";
import Specification from "./components/Specification";
import Estimate from "./components/Estimate";
import Chapter from "./components/Chapter";
import BottomSliderMaterials from "./components/BottomSliderMaterials";
import Import from "./components/Import";
import TabBarNotLinks from "../../../elements/navigation/TabBar/TabBarNotLinks";
import ForbiddenPage from "components/routes/components/ForbiddenPage/ForbiddenPage";
import Mims from "./components/Mims/Mims";

import {
  VIEW_MANUFACTURING_MATERIALS_ADDITIONAL,
  VIEW_MANUFACTURING_MATERIALS_ADDITIONAL_EDITING,
  VIEW_MANUFACTURING_MATERIALS_ESTIMATE,
  VIEW_MANUFACTURING_MATERIALS_ESTIMATE_EDITING,
  VIEW_MANUFACTURING_MATERIALS_OUT_OF_ESTIMATE,
  VIEW_MANUFACTURING_MATERIALS_OUT_OF_ESTIMATE_EDITING,
  VIEW_MANUFACTURING_MATERIALS_SPECIFICATION,
  VIEW_MANUFACTURING_MATERIALS_SPECIFICATION_EDITING,
} from "constants/permissions/manufacturingPermissions";

import usePermission from "hooks/usePermission";

import TwoHorizontalSmallLinesIcon from "images/icons/TwoHorizontalSmallLinesIcon";
import CrossRoundedIcon from "../../../images/icons/CrossRoundedIcon";

import styles from "./Materials.module.scss";

export const TABS_IDS = {
  ESTIMATE: "estimate",
  SPECIFICATION: "specification",
  OUT_OF_ESTIMATE: "out-of-estimate",
  ADDITIONAL: "additional",
  MIMS: "services",
};

const Materials = () => {
  const { objectId } = useParams();
  const dispatch = useDispatch();

  const [tab, setTab] = useState(TABS_IDS.ESTIMATE);
  const [isOpenModalAddSection, setIsOpenModalAddSection] = useState(false);
  const [isOpenModalAddExpenditures, setIsOpenModalAddExpenditures] =
    useState(false);
  const [isOpenModalImport, setIsOpenModalImport] = useState(false);
  const [isSwitch, setIsSwitch] = useState(false);
  const [titleBtn, setTitleBtn] = useState("Добавить раздел");
  const [idSubsection, setIdSubsection] = useState(null);
  const [addPosition, setAddPosition] = useState(false);

  const getCurrentTab = () => {
    switch (tab) {
      case TABS_IDS.ESTIMATE:
        return <Estimate objectId={objectId} isSwitch={isSwitch} />;
      case TABS_IDS.SPECIFICATION:
        return (
          <Specification
            titleBtn={titleBtn}
            setIdSubsection={setIdSubsection}
            setTitleBtn={setTitleBtn}
            objectId={objectId}
            typeUrl={tab}
            setAddPosition={setAddPosition}
            canEdit={haveEditSpecificationPermission}
          />
        );
      case TABS_IDS.OUT_OF_ESTIMATE:
        return (
          <Specification
            titleBtn={titleBtn}
            setIdSubsection={setIdSubsection}
            setTitleBtn={setTitleBtn}
            objectId={objectId}
            typeUrl={tab}
            setAddPosition={setAddPosition}
            canEdit={haveEditOutOfEstimatePermission}
          />
        );
      case TABS_IDS.ADDITIONAL:
        return (
          <Specification
            setIdSubsection={setIdSubsection}
            setTitleBtn={setTitleBtn}
            objectId={objectId}
            typeUrl={tab}
            setAddPosition={setAddPosition}
            canEdit={haveEditAdditionalPermission}
          />
        );
      case TABS_IDS.MIMS:
        return (
          <Mims
            setIdSubsection={setIdSubsection}
            setTitleBtn={setTitleBtn}
            objectId={objectId}
            typeUrl={tab}
            setAddPosition={setAddPosition}
            canEdit={haveEditAdditionalPermission}
          />
        );
      default:
      // return <Estimate objectId={objectId} typeUrl={tab} />;
    }
  };
  const closeModalAddSection = () => {
    setIsOpenModalAddSection(false);
  };
  const closeModalAddExpenditures = () => {
    setIsOpenModalAddExpenditures(false);
  };
  const closeModalImport = () => {
    setIsOpenModalImport(false);
  };

  React.useEffect(() => {
    if (
      [
        TABS_IDS.SPECIFICATION,
        TABS_IDS.OUT_OF_ESTIMATE,
        TABS_IDS.ADDITIONAL,
      ].includes(tab)
    ) {
      setAddPosition(false);
    }
  }, [tab]);

  const submitAddPositionForm = useCallback(
    (values) => {
      closeModalAddExpenditures();
      dispatch(addExpenditure(objectId, tab, { ...values, section_id: null }));
    },
    [objectId, tab, closeModalAddExpenditures]
  );

  const haveEstimatePermission = usePermission(
    VIEW_MANUFACTURING_MATERIALS_ESTIMATE
  );
  const haveSpecificationPermission = usePermission(
    VIEW_MANUFACTURING_MATERIALS_SPECIFICATION
  );
  const haveOutOfEstimatePermission = usePermission(
    VIEW_MANUFACTURING_MATERIALS_OUT_OF_ESTIMATE
  );
  const haveAdditionalPermission = usePermission(
    VIEW_MANUFACTURING_MATERIALS_ADDITIONAL
  );

  const haveEditEstimatePermission = usePermission(
    VIEW_MANUFACTURING_MATERIALS_ESTIMATE_EDITING
  );
  const haveEditSpecificationPermission = usePermission(
    VIEW_MANUFACTURING_MATERIALS_SPECIFICATION_EDITING
  );
  const haveEditOutOfEstimatePermission = usePermission(
    VIEW_MANUFACTURING_MATERIALS_OUT_OF_ESTIMATE_EDITING
  );
  const haveEditAdditionalPermission = usePermission(
    VIEW_MANUFACTURING_MATERIALS_ADDITIONAL_EDITING
  );

  React.useEffect(() => {
    if (!haveEstimatePermission && haveSpecificationPermission) {
      setTab(TABS_IDS.SPECIFICATION);
    }
    if (
      !haveEstimatePermission &&
      !haveSpecificationPermission &&
      haveOutOfEstimatePermission
    ) {
      setTab(TABS_IDS.OUT_OF_ESTIMATE);
    }
    if (
      !haveEstimatePermission &&
      !haveSpecificationPermission &&
      !haveOutOfEstimatePermission &&
      haveAdditionalPermission
    ) {
      setTab(TABS_IDS.ADDITIONAL);
    }
  }, [
    haveEstimatePermission,
    haveSpecificationPermission,
    haveOutOfEstimatePermission,
    haveAdditionalPermission,
  ]);

  if (
    !haveEstimatePermission &&
    !haveSpecificationPermission &&
    !haveOutOfEstimatePermission &&
    !haveAdditionalPermission
  ) {
    return (
      <TemplateBase>
        <ForbiddenPage />
      </TemplateBase>
    );
  }

  return (
    <>
      <BottomSliderMaterials
        isOpen={isOpenModalAddSection}
        closeFunc={closeModalAddSection}
      >
        <Chapter
          titleBtn={titleBtn}
          id={idSubsection}
          objId={objectId}
          typeUrl={tab}
          close={closeModalAddSection}
        />
      </BottomSliderMaterials>
      <BottomSliderMaterials
        isOpen={isOpenModalAddExpenditures}
        closeFunc={closeModalAddExpenditures}
      >
        <div className={styles.headerContainer}>
          <div className={styles.block1Title}>
            <span className={styles.title}>Добавить позицию</span>
            <div
              className={styles.closeIcon}
              onClick={closeModalAddExpenditures}
            >
              <CrossRoundedIcon />
            </div>
          </div>
        </div>
        <ExpenditureForm
          submit={submitAddPositionForm}
          isServices={tab === TABS_IDS.MIMS}
        />
      </BottomSliderMaterials>
      <BottomSliderMaterials
        isOpen={isOpenModalImport}
        closeFunc={closeModalImport}
      >
        <Import objId={objectId} close={closeModalImport} />
      </BottomSliderMaterials>
      <TemplateBase>
        <div className={styles.container}>
          <TabBarNotLinks
            tabs={[
              {
                text: "По смете",
                id: TABS_IDS.ESTIMATE,
                isHidden: !haveEstimatePermission,
              },
              {
                text: "Спецификация",
                id: TABS_IDS.SPECIFICATION,
                isHidden: !haveSpecificationPermission,
              },
              {
                text: "МиМ",
                id: TABS_IDS.MIMS,
              },
              {
                text: "Вне сметы",
                id: TABS_IDS.OUT_OF_ESTIMATE,
                isHidden: !haveOutOfEstimatePermission,
              },
              {
                text: "Допы",
                id: TABS_IDS.ADDITIONAL,
                isHidden: !haveAdditionalPermission,
              },
            ]}
            activeId={tab}
            onClick={setTab}
            className={styles.tabs}
          />
          {haveEditEstimatePermission && tab === TABS_IDS.ESTIMATE && (
            <div className={styles.boxEstimate}>
              <div className={styles.switchBox}>
                <LabeledSwitch
                  label={"Просмотр"}
                  labelRight={"Редактирование"}
                  onChange={() => setIsSwitch(!isSwitch)}
                  value={isSwitch}
                />
              </div>
            </div>
          )}
          {(((tab === TABS_IDS.SPECIFICATION || tab === TABS_IDS.MIMS) &&
            haveEditSpecificationPermission) ||
            (tab === TABS_IDS.ADDITIONAL && haveEditAdditionalPermission)) && (
            <div className={styles.actionBlock}>
              {titleBtn !== "Добавить позицию" && (
                <ButtonBase
                  buttonPrimaryEmpty
                  onClick={() => setIsOpenModalAddSection(true)}
                >
                  {titleBtn}
                </ButtonBase>
              )}
              {addPosition && (
                <ButtonBase
                  buttonPrimaryEmpty
                  onClick={() => setIsOpenModalAddExpenditures(true)}
                >
                  Добавить позицию
                  <span className={styles.plusIcon}>
                    <TwoHorizontalSmallLinesIcon />
                  </span>
                </ButtonBase>
              )}
            </div>
          )}
          {tab === TABS_IDS.OUT_OF_ESTIMATE && haveEditOutOfEstimatePermission && (
            <div className={styles.actionBlock}>
              {addPosition && (
                <ButtonBase
                  className={styles.marginRight}
                  buttonPrimaryEmpty
                  onClick={() => setIsOpenModalAddExpenditures(true)}
                >
                  Добавить позицию
                  <span className={styles.plusIcon}>
                    <TwoHorizontalSmallLinesIcon />
                  </span>
                </ButtonBase>
              )}
              <ButtonBase
                onClick={() => setIsOpenModalImport(true)}
                medium
                buttonPrimaryEmpty
              >
                Импорт
              </ButtonBase>
            </div>
          )}
        </div>
        {getCurrentTab()}
      </TemplateBase>
    </>
  );
};

export default Materials;
