import { createSelector } from "reselect";
import axios from "axios";
import { messageErrorHandler } from "../../../../utils/errorHandler";
import { message } from "antd";
import { compose } from "redux";
import { concatenateWithoutDuplicates } from "../../../../utils/concatenateWithoutDuplicates";


const moduleName = "virtualProviders";

const GET_VIRTUAL_PROVIDERS = `${moduleName}/GET_VIRTUAL_PROVIDERS`;
const SET_VIRTUAL_PROVIDERS = `${moduleName}/SET_VIRTUAL_PROVIDERS`;
const SET_MIXED_PROVIDERS_LOADING = `${moduleName}/SET_MIXED_PROVIDERS_LOADING`;
const ADD_MIXED_PROVIDERS = `${moduleName}/ADD_MIXED_PROVIDERS`;
const RESET_MIXED_PROVIDERS = `${moduleName}/RESET_MIXED_PROVIDERS`;
const SET_VIRTUAL_PROVIDERS_ERRORS = `${moduleName}/SET_VIRTUAL_PROVIDERS_ERRORS`;
const SET_VIRTUAL_PROVIDERS_LOADING = `${moduleName}/SET_VIRTUAL_PROVIDERS_LOADING`;
const SET_CURRENT_VIRTUAL_PROVIDERS_LOADING = `${moduleName}/SET_CURRENT_VIRTUAL_PROVIDERS_LOADING`;

const GET_VIRTUAL_PROVIDER = `${moduleName}/GET_VIRTUAL_PROVIDER`;
const SET_VIRTUAL_PROVIDER = `${moduleName}/SET_VIRTUAL_PROVIDER`;
const RESET_TO_INITIAL = `${moduleName}/RESET_TO_INITIAL`;

const INITIAL_STATE = {
  virtualProviders: null,
  isLoading: true,
  createVirtualProviderErrors: [],
  createVirtualProviderLoading: false,
  mixedProviders: { results: null },
  isLoadingMixedProviders: false,
  currentVirtualProvider: null,
  currentVirtualProviderIsLoading: true
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VIRTUAL_PROVIDERS:
      return {
        ...state,
        isLoading: true
      };
    case SET_VIRTUAL_PROVIDERS:
      return {
        ...state,
        virtualProviders: payload,
        isLoading: false
      };
    case GET_VIRTUAL_PROVIDER:
      return {
        ...state,
        currentVirtualProviderIsLoading: true
      };
    case SET_VIRTUAL_PROVIDER:
      return {
        ...state,
        currentVirtualProvider: payload,
        currentVirtualProviderIsLoading: false
      };
    case SET_CURRENT_VIRTUAL_PROVIDERS_LOADING:
      return {
        ...state,
        currentVirtualProviderIsLoading: payload
      };
    case SET_MIXED_PROVIDERS_LOADING:
      return {
        ...state,
        isLoadingMixedProviders: true
      };
    case ADD_MIXED_PROVIDERS:
      return {
        ...state,
        mixedProviders: {
          ...state.mixedProviders,
          ...payload,
          results: concatenateWithoutDuplicates(state.mixedProviders.results || [], payload.results, "id")
        },
        isLoadingMixedProviders: false
      };
    case RESET_MIXED_PROVIDERS:
      return {
        ...state,
        mixedProviders: INITIAL_STATE.mixedProviders
      };
    case SET_VIRTUAL_PROVIDERS_ERRORS:
      return {
        ...state,
        createVirtualProviderErrors: payload
      };
    case SET_VIRTUAL_PROVIDERS_LOADING:
      return {
        ...state,
        createVirtualProviderLoading: payload
      };
    case RESET_TO_INITIAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const resetVirtualProviders = () => ({ type: RESET_TO_INITIAL });

export const stateSelector = state => state[moduleName];

export const virtualProvidersSelector = createSelector(stateSelector, state => state.virtualProviders);
export const virtualProvidersLoadingSelector = createSelector(stateSelector, state => state.isLoading);
export const currentVirtualProviderSelector = createSelector(stateSelector, state => state.currentVirtualProvider);
export const currentVirtualProviderIsLoadingSelector = createSelector(stateSelector, state => state.currentVirtualProviderIsLoading);
export const mixedProvidersSelector = createSelector(stateSelector, state => state.mixedProviders);
export const mixedProvidersLoadingSelector = createSelector(stateSelector, state => state.isLoadingMixedProviders);
export const createVirtualProviderErrorsSelector = createSelector(stateSelector, state => state.createVirtualProviderErrors);
export const createVirtualProviderLoadingSelector = createSelector(stateSelector, state => state.createVirtualProviderLoading);

export const getVirtualProviders = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (paginationParams, filterParams) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams
      },
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      })
    };

    return (dispatch, getState) => {
      dispatch({
        type: GET_VIRTUAL_PROVIDERS
      });

      axios.get(`/partnership/virtual-providers/`, config).then(
        response => {
          dispatch({
            type: SET_VIRTUAL_PROVIDERS,
            payload: response.data
          });
        },
        error => {
        }
      );
    };
  };
})();

export const addVirtualProvider = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (data, virtualProviderUpdate = true, cb = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      })
    };

    return (dispatch) => {
      dispatch({
        type: SET_VIRTUAL_PROVIDERS_ERRORS,
        payload: []
      });

      dispatch({
        type: SET_VIRTUAL_PROVIDERS_LOADING,
        payload: true
      });

      axios.post(`/partnership/virtual-providers/`, data, config).then(
        () => {
          dispatch({
            type: SET_VIRTUAL_PROVIDERS_LOADING,
            payload: false
          });

          if (cb) cb();
          if (virtualProviderUpdate) compose(dispatch, getVirtualProviders)({ limit: 50, offset: 0 });
        },
        (error) => {
          error.response.data?.errors?.forEach(item => message.error(item?.message));

          dispatch({
            type: SET_VIRTUAL_PROVIDERS_ERRORS,
            payload: error.response.data.errors
          });

          dispatch({
            type: SET_VIRTUAL_PROVIDERS_LOADING,
            payload: false
          });
        }
      );
    };
  };
})();

export const loadMixedProviders = (() => {
  const CancelToken = axios.CancelToken;
  let lp_cancel;

  return (params, setLoading) => {
    if (lp_cancel) lp_cancel();

    const config = {
      params,
      cancelToken: new CancelToken(c => {
        lp_cancel = c;
      })
    };

    return (dispatch) => {
      if (params.offset === 0) {
        dispatch({ type: RESET_MIXED_PROVIDERS });
        dispatch({ type: SET_MIXED_PROVIDERS_LOADING });
      }

      return axios.get(`/partnership/mixed-partners/`, config).then(response =>
        dispatch({
          type: ADD_MIXED_PROVIDERS,
          payload: response.data
        })
      );
    };
  };
})();

export const loadVirtualProvider = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      })
    };

    return (dispatch, getState) => {
      dispatch({
        type: GET_VIRTUAL_PROVIDER
      });

      axios.get(`/partnership/virtual-providers/${id}/`, config).then(
        response => {
          dispatch({
            type: SET_VIRTUAL_PROVIDER,
            payload: response.data
          });
        },
        error => {
        }
      );
    };
  };
})();

export const updateVirtualProvider = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (data) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      })
    };

    return (dispatch) => {
      dispatch({
        type: GET_VIRTUAL_PROVIDER
      });

      axios.put(`/partnership/virtual-providers/${data.id}/`, data, config).then(
        response => {
          dispatch({
            type: SET_VIRTUAL_PROVIDER,
            payload: response.data
          });
          message.success("Сохранено");
        },
        error => {
          messageErrorHandler(error.response.data.errors, true);
          dispatch({
            type: SET_CURRENT_VIRTUAL_PROVIDERS_LOADING,
            payload: false
          });
        }
      );
    };
  };
})();

export const deleteVirtualProvider = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, cb) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      })
    };

    return (dispatch) => {
      axios.delete(`/partnership/virtual-providers/${id}/`, config).then(
        response => {
          cb?.();
          message.success("Контакт успешно удален");
        },
        error => {
        }
      );
    };
  };
})();
