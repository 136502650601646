import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import Icon from "../../../../../../../UI/Icon";
import AddButton from "../../../../../../../UI/atoms/AddButton/AddButton";
import ConfirmBlock from "../../../../../../../UI/organism/AddingListInModal/components/ConfirmBlock/ConfirmBlock";
import Select from "../../../../../../../UI/atoms/Select";
import Checkbox from "../../../../../../../UI/atoms/Checkbox/Checkbox";

import useArrayItemsChecker from "../../../../../../../../hooks/useArrayItemsChecker";

import {
  bulkCreateRelations,
  clearRelationAvailableIntervals,
  loadRelationAvailableIntervals,
} from "../../../../../../../../redux/modules/common/building/manufacturing/thunks";

import {
  relationAvailableIntervalsSelector,
  relationAvailableSectionsSelector,
  relationsFromCurrentIntervalSelector,
  relationsToCurrentIntervalSelector,
} from "../../../../../../../../redux/modules/common/building/manufacturing/selectors";

import {
  IDisplayedRelation,
  IIntervalToConnect,
  RelationFromType,
  RelationFromTypes,
} from "../../types";

import { ReactComponent as DoubleCheck } from "images/icons/doubleCheck.svg";

import styles from "./RelationsHeader.module.scss";

export interface IRelationsHeaderProps {
  icon?: string;
  label: string;
  canAddRelation?: boolean;
  projectId: number;
  type: RelationFromType;
  intervalStartAt: string;
  intervalEndAt: string;
  addingNewRelationFrom: string | null;
  setAddingNewRelationFrom: (value: string | null) => void;
  intervalId: number;
}

const RelationsHeader: React.FC<IRelationsHeaderProps> = ({
  icon,
  label,
  canAddRelation,
  projectId,
  type,
  intervalStartAt,
  intervalEndAt,
  addingNewRelationFrom,
  setAddingNewRelationFrom,
  intervalId,
}) => {
  const dispatch = useDispatch();
  const relationAvailableSections: IIntervalToConnect[] =
    useSelector(relationAvailableSectionsSelector) || [];
  const relationAvailableIntervals: IIntervalToConnect[] =
    useSelector(relationAvailableIntervalsSelector) || [];

  const relationsFromCurrentInterval: IDisplayedRelation[] =
    useSelector(relationsFromCurrentIntervalSelector) || [];

  const relationsToCurrentInterval: IDisplayedRelation[] =
    useSelector(relationsToCurrentIntervalSelector) || [];

  const checkIsIntervalInRelations = useCallback(
    (id: number) => {
      return (
        relationsFromCurrentInterval.some((x) => x.to_interval === id) ||
        relationsToCurrentInterval.some((x) => x.from_interval === id)
      );
    },
    [relationsFromCurrentInterval, relationsToCurrentInterval]
  );

  const [isAddingRelation, setIsAddingRelation] = useState<boolean>(false);
  const [selectedSectionId, setSelectedSectionId] = useState<
    number | undefined
  >(undefined);

  const startAddingRelation = useCallback(() => {
    setIsAddingRelation(true);
    setAddingNewRelationFrom(type);
  }, []);

  const stopAddingRelation = useCallback(() => {
    setIsAddingRelation(false);
    setAddingNewRelationFrom(null);
    setSelectedSectionId(undefined);
    dispatch(clearRelationAvailableIntervals());
    resetSelected();
  }, []);

  const {
    items,
    checkedCount,
    checkOnce,
    reset: resetSelected,
  } = useArrayItemsChecker(relationAvailableIntervals, "id");

  const handleSectionChange = useCallback(
    (sectionId: number) => {
      if (!sectionId || !projectId) return;
      dispatch(
        loadRelationAvailableIntervals({
          projectId,
          sectionId: sectionId,
          start_at__gte:
            type === RelationFromTypes.from ? intervalEndAt : undefined,
          start_at__lte:
            type === RelationFromTypes.to ? intervalStartAt : undefined,
          intervalId,
        })
      );
      setSelectedSectionId(sectionId);
    },
    [projectId, intervalEndAt, intervalStartAt, type]
  );

  const handleAddBulkRelations = useCallback(() => {
    const relationCandidates = Object.entries(items)
      .filter(([id, isChecked]) => isChecked)
      .map(([id, isChecked]) => id);
    dispatch(
      bulkCreateRelations({ intervalId, relationCandidates, type, projectId })
    );
    !!selectedSectionId && handleSectionChange(selectedSectionId);
    resetSelected();
  }, [items, intervalId, type, selectedSectionId, resetSelected]);

  return (
    <>
      <div className={styles.relationLocationLabel}>
        {icon && <Icon icon={icon} className={styles.relationLocationIcon} />}
        <span>{label}</span>
      </div>
      {canAddRelation && (
        <div className={styles.newRelationContainer}>
          {!isAddingRelation && (
            <AddButton
              text={"Добавить новую связь"}
              className={styles.addRelationButton}
              onClick={startAddingRelation}
            />
          )}
          {isAddingRelation && (
            <>
              <ConfirmBlock
                onDecline={stopAddingRelation}
                count={checkedCount}
                onAccept={handleAddBulkRelations}
              />
              <div>
                <Select
                  label={
                    <span className={styles.selectLabel}>
                      Укажите наименование подраздела
                    </span>
                  }
                  options={relationAvailableSections}
                  onChange={handleSectionChange}
                  value={selectedSectionId}
                  isScrolledToDefaultOption
                />
              </div>
              {selectedSectionId && relationAvailableIntervals?.length > 0 && (
                <span className={styles.selectLabel}>
                  Укажите ниже работу или работы, которые будут связаны с
                  текущей работой
                </span>
              )}
              <div className={styles.intervals}>
                {!!selectedSectionId &&
                  relationAvailableIntervals.map((interval, index) => {
                    const isDoubleChecked = checkIsIntervalInRelations(
                      interval.id
                    );
                    return (
                      <div
                        className={cn(styles.intervalToCheck, {
                          [styles.blueBg]: index % 2 === 1,
                        })}
                        title={interval.expenditure_name}
                        key={index}
                      >
                        {isDoubleChecked ? (
                          <DoubleCheck className={styles.doubleCheckIcon} />
                        ) : (
                          <Checkbox
                            onCheck={(e) =>
                              checkOnce(interval.id, e.target.checked)
                            }
                            checked={items[interval.id]}
                            className={styles.checkbox}
                          />
                        )}
                        <span>{index + 1}</span>
                        <span className={styles.expenditureName}>
                          {interval.expenditure_name}
                        </span>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(RelationsHeader);
