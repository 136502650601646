import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  buildingEmployeesSelector,
  getEmployees,
  resetEmployees,
} from "redux/modules/common/building/object/nowObject";

import ButtonBase from "components/UI/atoms/ButtonBase";
import Approvers, { TYPES } from "components/UI/organism/Approvers";
import Viewers from "components/UI/organism/Viewers";
import { Spinner } from "components/UI/Spinner/Spinner";

import styles from "./index.module.scss";

const RequisitionApproversAndViewers = ({
  buildingId,
  approvers,
  handleDeleteApprover,
  handleAddApprovers,
  permissions,
  viewers,
  handleDeleteViewer,
  handleAddViewers,
  canApprove,
  canView,
  canUnApprove,
  canUnView,
  approve,
  unApprove,
  approversAreLoading,
  requisitionIsAgreed,
}) => {
  const dispatch = useDispatch();
  const buildingEmployees = useSelector(buildingEmployeesSelector);

  const haveAddApproversOrViewersPermission =
    permissions.viewAddRequisitionApprovers || permissions.viewAddRequisitionViewers;

  useEffect(() => {
    if (haveAddApproversOrViewersPermission && !buildingEmployees.data && !buildingEmployees.isLoading) {
      compose(dispatch, getEmployees)(buildingId);
    }
  }, [haveAddApproversOrViewersPermission, buildingEmployees.data, buildingId]);

  useEffect(() => () => compose(dispatch, resetEmployees)(), []);

  if (buildingEmployees.isLoading || approversAreLoading) return <Spinner />;
  if (!buildingEmployees.data && haveAddApproversOrViewersPermission) return null;

  return (
    <div className={styles.approversAndViewers}>
      <Approvers
        className={styles.approvers}
        employees={buildingEmployees.data}
        type={TYPES.requisition}
        approvedElements={approvers}
        handleDeleteEmployee={handleDeleteApprover}
        handleAddEmployees={handleAddApprovers}
        canAddApprovers={!requisitionIsAgreed && permissions.viewAddRequisitionApprovers}
        canDeleteApprovers={permissions.viewDeleteRequisitionApprovers}
      />
      {(permissions.viewAddRequisitionViewers || viewers.length !== 0) && (
        <Viewers
          className={styles.viewers}
          employees={buildingEmployees.data}
          type={TYPES.requisition}
          viewers={viewers}
          handleDeleteViewer={handleDeleteViewer}
          handleAddViewers={handleAddViewers}
          canAddViewers={!requisitionIsAgreed && permissions.viewAddRequisitionViewers}
          canDeleteViewers={permissions.viewDeleteRequisitionViewers}
        />
      )}
      <footer className={styles.footer}>
        {canApprove && (
          <ButtonBase medium onClick={approve}>
            Согласовать
          </ButtonBase>
        )}
        {canView && (
          <ButtonBase medium onClick={approve}>
            Просмотреть
          </ButtonBase>
        )}
        {canUnApprove && !requisitionIsAgreed && permissions.viewRequisitionUnApprove && (
          <ButtonBase secondary medium onClick={unApprove}>
            Отменить согласование
          </ButtonBase>
        )}
        {canUnView && (
          <ButtonBase secondary medium onClick={unApprove}>
            Отменить просмотр
          </ButtonBase>
        )}
      </footer>
    </div>
  );
};

export default React.memo(RequisitionApproversAndViewers);
