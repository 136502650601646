import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { loadInterval, loadMaterialInterval } from "redux/modules/common/building/manufacturing/thunks";

import { objectsSelector, loadObjects } from "redux/modules/common/building/objects";

import { IMonthArrayElement } from "../types";

export interface IUseIntervalsProps {
  year: number | string;
  month: IMonthArrayElement;
}

export const useIntervals = ({ year, month }: IUseIntervalsProps) => {
  const dispatch = useDispatch();
  const projects = useSelector(objectsSelector);

  useEffect(() => {
    !projects?.results?.length && dispatch(loadObjects({ limit: 50, offset: 0 }));
    dispatch(
      loadInterval(
        moment()
          .year(+year)
          .startOf("year"),
        moment()
          .year(+year)
          .endOf("year")
      )
    );
    dispatch(
      loadMaterialInterval(
        moment()
          .year(+year)
          .startOf("year"),
        moment()
          .year(+year)
          .endOf("year")
      )
    );
  }, [year]);
};
