import React from 'react'
import {AiOutlineCheckCircle} from 'react-icons/ai'
import './Alert.scss'

export const Alert = () => {
  return (
    <div 
        id="upfatedProfile"
        style={{
            display:'flex', alignItems:'center', boxShadow:'0 0 2px rgba(0,0,0,0.4)',
            padding: '10px 15px', borderRadius:'8px',
            maxWidth:300,
            position:'absolute', top: '0%', left:'40%'
        }}
        className='alert'
    >
        <AiOutlineCheckCircle color='green' style={{marginRight:10}} />
        <div>Профиль успешно обновлен</div>
    </div>
  )
}
