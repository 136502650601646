import axios from 'axios';
import { createSelector } from 'reselect';

const moduleName = 'addresses';
const SET_PAGINATION = `${moduleName}/SET_PAGINATION`;
const LOAD_ADDRESSES_LIST = `${moduleName}/LOAD_ADDRESSES_LIST`;
const SET_ADDRESSES_LIST = `${moduleName}/SET_ADDRESSES_LIST`;
const SET_ADDRESS = `${moduleName}/SET_ADDRESS`;

const initialState = {
  tableData: {
    count: 1,
    list: []
  },
  nowAddress: null,
  pagination: {
    params: { limit: 50, offset: 0 },
    page: 1,
  },
  sorting: null,
  loading: true
};

/*
  Reducer
*/

export default (state = initialState, action) => {
  const {payload, type} = action;

  switch (type) {

    case SET_PAGINATION: {
      return{
        ...state,
        pagination: {
          ...payload
        }
      }
    }

    case LOAD_ADDRESSES_LIST: {
      return{
        ...state,
        loading: true
      }
    }

    case SET_ADDRESSES_LIST: {
      return{
        ...state,
        tableData: {
          count: payload.count,
          list: payload.results,
          results: payload.results
        },
        loading: false
      }
    }

    case SET_ADDRESS: {
      return{
        ...state,
        nowAddress: payload
      }
    }

    default: {
      return state;
    }
  }
}

/*
  Selectors
*/

export const stateSelector = state => state.addresses;
export const loadingStateSelector = createSelector(stateSelector, state => state.loading);
export const sortingStateSelector = createSelector(stateSelector, state => state.sorting);
export const paginationStateSelector = createSelector(stateSelector, state => state.pagination);
export const dataStateSelector = createSelector(stateSelector, state => state.tableData);
export const nowAddressStateSelector = createSelector(stateSelector, state => state.nowAddress);

/*
  Action creators
*/

export const setPaginationState = (params, page) => {
  return {
    type: SET_PAGINATION,
    payload: {params, page}
  };
};

export const loadAddressesState = () => {
  return {
    type: LOAD_ADDRESSES_LIST
  };
};

export const setAddressesState = value => {
  return {
    type: SET_ADDRESSES_LIST,
    payload: value
  };
};

export const setAddressState = value => {
  return {
    type: SET_ADDRESS,
    payload: value
  };
};

/*
  Thunks
*/

export const getAddressesList = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;
  return (entityId, paginationParams, filterParams = null, sorting = null) => {
    if (gu_cancel) gu_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams
      },
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return dispatch => {
      dispatch(loadAddressesState());
      axios.get(`/profile/address/`, config).then(
        res => {
          dispatch(setAddressesState(res.data));
        },
        err => {
          console.error(err);
        }
      );
    }
  }
})();

export const getAddress = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;
  return id => {
    if (gu_cancel) gu_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return dispatch =>
      axios.get(`/profile/address/${id}/`, config).then(
        res => {
          dispatch(setAddressState(res.data));
        },
        err => {
          console.error(err);
        }
      );
  }
})();

export const deleteAddress = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;
  return (id, data, callback = null) => {
    if (gu_cancel) gu_cancel();

    const params = {
      ...data,
      is_removed: true
    };
    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return dispatch =>
      axios.put(`/profile/address/${id}/`, params, config).then(
        res => {
          if (callback) callback();
        },
        err => {
          console.error(err);
        }
      );
  }
})();

export const setNewAddress = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;
  return (data, callback = null) => {
    if (gu_cancel) gu_cancel();
    const params = {
      ...data
    };
    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return dispatch =>
      axios.post(`/profile/address/`, params, config).then(
        res => {
          console.log('done');
          if (callback) callback();
          //dispatch(setAddressesState(res.data));
        },
        err => {
          console.error(err);
          if (callback) callback(err);
        }
      );
  }
})();

export const updateAddress = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;
  return (id, data, callback = null) => {
    if (gu_cancel) gu_cancel();
    const params = {
      ...data
    };
    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return dispatch =>
      axios.put(`/profile/address/${id}/`, params, config).then(
        res => {
          if (callback) callback();
        },
        err => {
          console.error(err);
          if (callback) callback(err);
        }
      );
  }
})();