import React from "react";
import cn from "classnames";

import { ISectionPlan } from "redux/modules/common/building/processTypes";

import ButtonBase from "components/UI/atoms/ButtonBase";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import CalendarRange from "components/UI/molecules/CalendarRange";
import { useEditSectionPlan } from "../useEditSectionPlan";

import styles from "./../ManufacturingPlanSectionModal.module.scss";

interface IProps {
  onClose: () => void;
  planData: ISectionPlan;
  dataRefetcher: () => void;
}

const EditSectionPlan: React.FC<IProps> = ({
  onClose,
  planData,
  dataRefetcher,
}) => {
  const { dateEnd, dateStart, setDateEnd, setDateStart, saveHandler } =
    useEditSectionPlan(planData, dataRefetcher, onClose);

  return (
    <>
      <div className={cn(styles.pair, styles.centered)}>
        <b>Период выполнения:</b>
        <CalendarRange
          defaultDateStart={dateStart}
          defaultDateEnd={dateEnd}
          classNamePopup={styles.calendarPopup}
          setDefaultDateEnd={setDateEnd}
          setDefaultDateStart={setDateStart}
        />
      </div>
      <BottomControls isExists isDoubleBtns>
        <ButtonBase secondary onClick={onClose}>
          Отменить
        </ButtonBase>
        <ButtonBase primary onClick={saveHandler}>
          Сохранить
        </ButtonBase>
      </BottomControls>
    </>
  );
};

export default EditSectionPlan;
