import { IFileStorageState, ISetFoldersAndFilesActionProps } from "./types";

import {
  mergeFilesAndFolders,
  removeItemFromStorage,
  renameItemInStorage,
} from "./utils";

export const setMergedFoldersAndFiles = (
  state: IFileStorageState,
  {
    filesCount,
    foldersCount,
    objectId,
    folderId,
    files,
    folders,
    parentName,
  }: ISetFoldersAndFilesActionProps
): IFileStorageState => {
  if (objectId && !folderId) {
    const stateByCurrentObject = state.byObjectId[objectId] || {};
    return {
      ...state,
      byObjectId: {
        ...state.byObjectId,
        [objectId]: {
          ...stateByCurrentObject,
          filesCount,
          foldersCount,
          items: mergeFilesAndFolders(files, folders),
          parentName,
        },
      },
    };
  } else if (folderId) {
    const stateByCurrentFolder = state.byFolderId[folderId] || {};
    return {
      ...state,
      byFolderId: {
        ...state.byFolderId,
        [folderId]: {
          ...stateByCurrentFolder,
          filesCount,
          foldersCount,
          items: mergeFilesAndFolders(files, folders),
          parentName,
        },
      },
    };
  }
  return state;
};

export const deleteFileFromStorage = (
  state: IFileStorageState,
  fileId: number
): IFileStorageState => {
  return {
    ...state,
    byObjectId: removeItemFromStorage(fileId, state.byObjectId, false),
    byFolderId: removeItemFromStorage(fileId, state.byFolderId, false),
  };
};

export const deleteFolderFromStorage = (
  state: IFileStorageState,
  folderId: number
): IFileStorageState => {
  return {
    ...state,
    byObjectId: removeItemFromStorage(folderId, state.byObjectId, true),
    byFolderId: removeItemFromStorage(folderId, state.byFolderId, true),
  };
};

export const setFileStorageIsLoading = (
  state: IFileStorageState,
  payload: { status: boolean; objectId: string; sectionId?: number }
): IFileStorageState => {
  const { status, objectId, sectionId } = payload;
  if (sectionId) {
    const folderKey = String(sectionId);
    const byFolderKey = state.byFolderId[folderKey] || {};
    return {
      ...state,
      byFolderId: {
        ...state.byFolderId,
        [folderKey]: {
          ...byFolderKey,
          isLoaded: status,
        },
      },
    };
  } else {
    const byObjectKey = state.byObjectId[objectId] || {};
    return {
      ...state,
      byObjectId: {
        ...state.byObjectId,
        [objectId]: {
          ...byObjectKey,
          isLoaded: status,
        },
      },
    };
  }
};

export const renameItemInAllStorage = (
  state: IFileStorageState,
  payload: { name: string; id: number; isFolder: boolean }
): IFileStorageState => {
  return {
    ...state,
    byFolderId: renameItemInStorage(
      payload.id,
      state.byFolderId,
      payload.isFolder,
      payload.name
    ),
  };
};
