import React from "react";
import axios from "axios";
import { message } from "antd";
import cn from "classnames";

import SliderModal from "components/UI/atoms/SliderModal";
import InputFiles from "../../../../UI/atoms/InputFiles/index";
import Button from "components/UI/atoms/ButtonBase";

import { LABELS } from "../../constants";

import downloadFileByUrl from "utils/downloadFileByUrl";
import { errorCatcher } from "utils/errorCatcher";
import { prepareFiles } from "./utils";

import PaperClipIcon from "images/icons/PaperClipIcon";

import styles from "./ProfuctFilesModal.module.scss";

function ProductFilesModal({
  permissions = { addFiles: true, deleteFiles: true },
  ...props
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [filesWasUploaded, setFilesWasUploaded] = React.useState(false);

  React.useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  React.useEffect(() => {
    if (!filesWasUploaded && props.fetchFilesCallback && isOpen) {
      setFilesWasUploaded(true);
      props.fetchFilesCallback();
    }
  }, [isOpen, filesWasUploaded]);

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  const handleFilesChange = (files) => {
    setFiles(files);
  };

  const handleSubmit = async () => {
    const newFiles = files.filter((file) => !file.file.link);
    const deletedFiles = props.files.filter(
      (propsFile) => !files.find((file) => file.id === propsFile.id)
    );

    try {
      if (newFiles?.length) await uploadFiles(newFiles);
      if (deletedFiles?.length) await deleteFiles(deletedFiles);
      message.success("Файлы успешно сохранены");
    } catch (e) {
      errorCatcher(e);
    }
  };

  const uploadFiles = async (files) => {
    const { buildingId, itemId, uploadFilesCallback = null } = props;

    const formData = new FormData();
    files.map((file) => formData.append("files", file.file));
    if (uploadFilesCallback) {
      uploadFilesCallback(files);
    } else {
      await axios
        .post(`/building/${buildingId}/materials/${itemId}/files/`, formData)
        .then((resp) => {
          const tempFiles = [...files].map((el, i) => ({
            ...el,
            file: {
              ...el.file,
              link: resp.data[i].file,
              name: el.file.name || resp.data[i].path,
            },
          }));
          setFiles(tempFiles);
        })
        .catch(errorCatcher);
    }
  };

  const deleteFiles = async (files) => {
    const deleteFilesFunc = files.map((file) => deleteFile(file));
    await Promise.all(deleteFilesFunc);
  };

  const deleteFile = async (file) => {
    const { buildingId, itemId, deleteFilesCallback = null } = props;
    if (deleteFilesCallback) {
      deleteFilesCallback(file);
    } else {
      await axios.delete(
        `/building/${buildingId}/materials/${itemId}/files/${file.id}/`
      );
    }
  };

  const downloadFiles = () => {
    const isNewFiles = files.some((el) => !el.file.link);
    if (isNewFiles) {
      message.info("Сохраните прежде, чем выгружать");
      return;
    }
    files.forEach((file) => {
      if (file.file?.link) {
        downloadFileByUrl(
          file.file.link || file.file,
          file.file.name || file.name
        );
      }
    });
  };

  const preparedFiles = prepareFiles(files);

  const { readOnly = false, productName, variant, filesCount } = props;

  return (
    <div>
      <SliderModal
        isOpen={isOpen}
        closeHandler={closeModal}
        className={styles.addFilesModal}
      >
        <div className={styles.addFilesModal__inner}>
          <div className={styles.addFilesModal__form}>
            <h3 className={styles.addFilesModal__form_title}>{LABELS.TITLE}</h3>
            <p className={styles.addFilesModal__form_subtitle}>{productName}</p>
            <div className={styles.addFilesModal__files}>
              <div className={styles.addFilesModal__files_header}>
                <span className={styles.title}>
                  {LABELS.FILES_INPUT.TITLE} {files?.length}
                </span>
                <button className={styles.actionButton} onClick={downloadFiles}>
                  {LABELS.FILES_INPUT.DOWNLOAD}
                </button>
              </div>
              <InputFiles
                value={preparedFiles}
                setValue={handleFilesChange}
                permissions={permissions}
              />
            </div>
          </div>
          <div className={styles.addFilesModal__buttons}>
            <Button secondary medium onClick={closeModal}>
              {LABELS.BUTTONS.CANCEL}
            </Button>
            <Button primary medium onClick={handleSubmit}>
              {LABELS.BUTTONS.SUBMIT}
            </Button>
          </div>
        </div>
      </SliderModal>
      <div
        className={cn(styles.paperclipIcon, [styles[variant]])}
        onClick={(files?.length > 0 || !readOnly) && openModal}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div title={"Прикрепленные файлы"}>
            <button
              type="button"
              style={{
                width: "1.3rem",
                cursor: files?.length > 0 || !readOnly ? "pointer" : "default",
              }}
              className="filesModalTrigger"
            >
              <PaperClipIcon fill="#6C6C6C" width="0.57rem" />
            </button>
          </div>
        </div>
        {filesCount || preparedFiles?.length > 0 ? (
          <div
            className={`filesModalTriggerCounter ${styles.paperclipIcon__count}`}
          >
            {filesCount || preparedFiles?.length}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default React.memo(ProductFilesModal);
