import moment from 'moment';
import React from 'react'
import { personFullNameWithId } from 'types/personsTypes';
import getShortFullName from 'utils/getShortFullName';
import JornalDay from '../../JournalTicketModal/JornalDay';
import styles from "./DayWorkerAndEvent.module.scss"

type propsType = {
    date: string;
    label: string;
    author: personFullNameWithId
}

function DayWorkerAndEvent({
    date,
    label,
    author
}: propsType) {
    if (!date) return null

    return (
        <div className={styles.pair}>
            <JornalDay day={moment(date).format("DD/MM/YYYY")} time={moment(date).format("HH:mm")} />
            <div className={styles.pass}>
                <b>{label}</b>
                <span>{getShortFullName(author, 13)}</span>
            </div>
        </div>
    )
}

export default DayWorkerAndEvent