export const concatenateWithoutDuplicates = (firstList, secondList, comparedField) => {
    const firstListByField = {};
    const secondListByField = {};

    firstList.forEach((element) => {
        const fieldValue = element[comparedField];

        firstListByField[fieldValue] = element;
    });

    secondList.forEach((element) => {
        const fieldValue = element[comparedField];

        secondListByField[fieldValue] = element;
    });

    return [
        ...firstList.map((element) => {
            const fieldValue = element[comparedField];

            if (!secondListByField[fieldValue]) return element;
            return { ...element, ...secondListByField[fieldValue] };
        }),
        ...secondList.filter((element) => {
            const fieldValue = element[comparedField];

            return !firstListByField[fieldValue];
        }),
    ];
};