import React from "react";
import cn from "classnames";

import { sliceTextByConstraint } from "../../../../utils/sliceTextByConstraint";
import { parseFileExtension } from "../../../../utils/parseFileFormat";
import CrossInCircle from "../../../../images/icons/CrossInCircle";
import pdf from "../../../../images/icons/pdf.svg";
import xls from "../../../../images/icons/xls.svg";
import docx from "../../../../images/icons/docx.svg";
import anyFile from "../../../../images/file-extensions/anyfile.svg";

import styles from "./FileRow.module.scss";

const getFileIcon = (fileName) => {
  const fileExtension = parseFileExtension(fileName);
  switch (fileExtension) {
    case "pdf":
      return pdf;
    case "xls":
      return xls;
    case "xlsx":
      return xls;
    case "docx":
      return docx;
    default:
      return anyFile;
  }
};

const MAX_FILE_NAME_LENGTH = 35;

const FileRow = ({ isLoading, file, download, remove, canRemove }) => {
  return (
    <div className={cn(styles.file, { [styles.loading]: isLoading })} title={file.name}
         key={file.id}>
      <span className={styles.fileBody} onClick={() => download(file)}>
        <img src={getFileIcon(file.name)} alt="Иконка файла" />
        <p className={styles.fileName}>{sliceTextByConstraint(file.name, MAX_FILE_NAME_LENGTH)}</p>
      </span>
      {canRemove && (
        <button onClick={() => remove(file.id)}>
          <CrossInCircle width={18} />
        </button>
      )}
    </div>
  );
};

export default React.memo(FileRow);