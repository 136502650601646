import React from 'react'
import axios from 'axios';
import styles from './index.module.scss';
import { getShortPrice } from 'utils/helpers';
import { errorCatcher } from '../../../../../utils/errorCatcher';

function RangeSumNew(props) {
    const { range, isPurchaser, events, building_id } = props
    const [sum, setSum] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(true)

    React.useEffect(() => {
        getSumFromRange(range)
    }, [range, events, building_id])

    const getSumFromRange = (range) => {
        setTimeout(() => {
            axios
                .get(`/${isPurchaser ? 'purchaser' : 'provider'}/invoices/payments/summary/`, {
                    params: {
                        date_after: range[0],
                        date_before: range[1],
                        events,
                        building_id: isNaN(Number(building_id)) ? null : building_id
                    },
                })
                .then((response) =>
                    setSum(response.data)
                )
                .catch(errorCatcher)
                .finally(() => setIsLoading(false))
        }, 1000);
    };

    return (
        <>
            {isLoading || !sum ? (
                <div>Подсчет...</div>
            ) : (
                <div className={styles.blockSum}>
                    <div className={styles.block1}>
                        <span>Оплачено: </span>
                        <span
                            className={styles.value1}> {getShortPrice(sum.paid_amount)}</span>
                    </div>
                    <div className={styles.block1}>
                        <span> К оплате: </span>
                        <span
                            className={styles.value1}>{getShortPrice(sum.unpaid_amount)}</span>
                    </div>
                    <div className={styles.block2}>
                        <span> Итого:</span>
                        <span className={styles.value2}>
                            {getShortPrice(parseFloat(sum.unpaid_amount) + parseFloat(sum.paid_amount))}
                        </span>
                    </div>
                </div>
            )}
        </>
    )
}

export default React.memo(RangeSumNew)