import React, { useCallback, useState } from "react";

import TableReusableHead, {
  TableReusableHeaderCell,
} from "../../../../../UI/atoms/TableReusable/TableReusableHead";
import Icon from "../../../../../UI/Icon";

import relationAfter from "images/icons/relationAfter.svg";
import relationBefore from "images/icons/relationBefore.svg";
import editIcon from "images/icons/editIcon.svg";

import DisplayedRelation from "./components/DisplayedRelation/DisplayedRelation";
import RelationsHeader from "./components/RelationsHeader/RelationsHeader";

import { IDisplayedRelation, RelationFromTypes } from "./types";

import styles from "./IntervalRelationsContent.module.scss";

export interface IIntervalRelationsContentProps {
  hasEditPermission?: boolean;
  intervalId: number;
  projectId: number;
  relationsFromCurrentInterval: IDisplayedRelation[];
  relationsToCurrentInterval: IDisplayedRelation[];
  intervalStartAt: string;
  intervalEndAt: string;
}

const IntervalRelationsContent: React.FC<IIntervalRelationsContentProps> = ({
  hasEditPermission,
  intervalId,
  projectId,
  relationsFromCurrentInterval,
  relationsToCurrentInterval,
  intervalStartAt,
  intervalEndAt,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [addingNewRelationFrom, setAddingNewRelationFrom] = useState(null);

  const toggleEditMode = useCallback(
    () => setIsEditMode((prevState) => !prevState),
    []
  );

  return (
    <>
      <TableReusableHead className={styles.relationsTableHeader}>
        <TableReusableHeaderCell className={styles.relationsTableIntervalName}>
          Наименование работы
        </TableReusableHeaderCell>
        {!addingNewRelationFrom && (
          <TableReusableHeaderCell
            className={styles.relationsTableIntervalBreak}
          >
            Перерыв, дни
            {hasEditPermission && (
              <Icon
                icon={editIcon}
                className={styles.tableEditIcon}
                onClick={toggleEditMode}
              />
            )}
          </TableReusableHeaderCell>
        )}
      </TableReusableHead>
      <div className={styles.relationsContent}>
        {(!addingNewRelationFrom ||
          addingNewRelationFrom === RelationFromTypes.from) && (
          <RelationsHeader
            label={"Начнется после окончания работы:"}
            icon={relationAfter}
            canAddRelation={hasEditPermission && isEditMode}
            projectId={projectId}
            intervalId={intervalId}
            type={RelationFromTypes.from}
            intervalStartAt={intervalStartAt}
            intervalEndAt={intervalEndAt}
            addingNewRelationFrom={addingNewRelationFrom}
            setAddingNewRelationFrom={setAddingNewRelationFrom}
          />
        )}
        {relationsFromCurrentInterval?.length > 0 &&
          !addingNewRelationFrom &&
          relationsFromCurrentInterval.map((relation) => (
            <DisplayedRelation
              relation={relation}
              isEditMode={hasEditPermission && isEditMode}
              key={relation.id}
              projectId={projectId}
              type={RelationFromTypes.from}
            />
          ))}
        {(!addingNewRelationFrom ||
          addingNewRelationFrom === RelationFromTypes.to) && (
          <RelationsHeader
            label={"Окончится перед началом работы:"}
            icon={relationBefore}
            canAddRelation={hasEditPermission && isEditMode}
            projectId={projectId}
            intervalId={intervalId}
            type={RelationFromTypes.to}
            intervalStartAt={intervalStartAt}
            intervalEndAt={intervalEndAt}
            addingNewRelationFrom={addingNewRelationFrom}
            setAddingNewRelationFrom={setAddingNewRelationFrom}
          />
        )}
        {relationsToCurrentInterval?.length > 0 &&
          !addingNewRelationFrom &&
          relationsToCurrentInterval.map((relation) => (
            <DisplayedRelation
              relation={relation}
              isEditMode={hasEditPermission && isEditMode}
              key={relation.id}
              projectId={projectId}
              type={RelationFromTypes.to}
            />
          ))}
      </div>
    </>
  );
};

export default React.memo(IntervalRelationsContent);
