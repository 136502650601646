import axios from "axios";
import { message } from "antd";
import { createSelector } from "reselect";
import update from "immutability-helper";
import { compose } from "redux";

import { concatenateWithoutDuplicates } from "utils/concatenateWithoutDuplicates";
import { errorCatcher } from "../../../../../utils/errorCatcher";

import { apiAddRequisitionEmployees } from "../nowRequsitionApi/nowRequsitionApi";
import {
  ADD_ORDER,
  ADD_PRODUCTS,
  ADD_REQUISITION_EMPLOYEES,
  DELETE_PRODUCT,
  DELETE_REQUISITION_EMPLOYEE,
  EDIT_PRODUCT,
  GET_ORDERS,
  LOAD_COMPARE_PRODUCT,
  LOAD_DELETE_PRODUCT,
  MODULE_NAME,
  RESET_TO_INITIAL,
  SEND_REQUISITION_ERROR,
  SEND_REQUISITION_LOADING,
  SET_INFO,
  SET_INFO_ERROR,
  SET_INFO_LOADING,
  SET_ORDER_STATUS,
  SET_ORDERS,
  SET_PAYMENT_ERROR,
  SET_PAYMENT_LOADING,
  SET_PRODUCTS,
  SET_PRODUCTS_LOADING,
  SET_REQUISITION_EMPLOYEES,
  SET_REQUISITION_EMPLOYEES_ERROR,
  SET_REQUISITION_EMPLOYEES_LOADING,
  SET_REQUISITION_IS_AGREED,
  UPDATE_ORDER,
  UPDATE_PRODUCT,
} from "./constants";
import { setRequisitionInfoAction } from "./actions";

const initialState = {
  info: {
    data: null,
    isLoading: false,
    isError: false,
  },
  products: {
    data: [],
    isLoading: false,
    sendingOrders: false,
    sendingOrdersError: null,
    compareLoadingProduct: new Set(),
    deleteLoadingProduct: new Set(),
  },
  orders: {
    data: null,
    isLoading: false,
    paymentIsLoading: false,
    paymentError: null,
  },
  employees: {
    data: null,
    isLoading: false,
    isError: false,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INFO_LOADING:
      return update(state, {
        info: {
          isLoading: {
            $set: true,
          },
        },
      });
    case SET_INFO:
      return update(state, {
        info: {
          data: { $set: payload },
          isLoading: { $set: false },
          isError: { $set: false },
        },
      });
    case SET_REQUISITION_IS_AGREED:
      return update(state, {
        info: { data: { is_agreed: { $set: payload } } },
      });
    case SET_PRODUCTS_LOADING:
      return update(state, {
        products: {
          isLoading: {
            $set: true,
          },
        },
      });
    case SET_PRODUCTS:
      return update(state, {
        products: {
          data: {
            $set: payload,
          },
          isLoading: {
            $set: false,
          },
          sendingOrders: {
            $set: false,
          },
        },
      });
    case EDIT_PRODUCT:
      return update(state, {
        products: {
          data: {
            [state.products.data.indexOf(state.products.data.find((item) => item.id === payload.id))]: {
              [payload.prop]: {
                $set: payload.value,
              },
            },
          },
        },
      });
    case ADD_PRODUCTS:
      return update(state, {
        products: {
          data: {
            $push: payload,
          },
        },
      });
    case LOAD_COMPARE_PRODUCT:
      return update(state, {
        products: {
          compareLoadingProduct: {
            $add: [payload],
          },
        },
      });
    case UPDATE_PRODUCT:
      return update(state, {
        products: {
          data: {
            [state.products.data.indexOf(state.products.data.find((item) => item.id === payload.id))]: {
              $set: payload,
            },
          },
          compareLoadingProduct: {
            $remove: [payload.id],
          },
        },
      });
    case LOAD_DELETE_PRODUCT:
      return update(state, {
        products: {
          deleteLoadingProduct: {
            $add: [payload],
          },
        },
      });
    case DELETE_PRODUCT:
      return update(state, {
        products: {
          data: {
            $splice: [[state.products.data.indexOf(payload), 1]],
          },
          deleteLoadingProduct: {
            $remove: [payload.id],
          },
        },
      });
    case GET_ORDERS:
      return update(state, {
        orders: {
          isLoading: {
            $set: true,
          },
        },
      });
    case SET_ORDERS:
      return update(state, {
        orders: {
          data: {
            $set: payload,
          },
          isLoading: {
            $set: false,
          },
        },
      });
    case UPDATE_ORDER: {
      if (!state.orders.data) return state;
      const order = state.orders.data.find((order) => order.id === payload.id);
      return update(state, {
        orders: {
          data: {
            [state.orders.data.indexOf(order)]: {
              $set: payload,
            },
          },
          isLoading: {
            $set: false,
          },
        },
      });
    }
    case ADD_ORDER:
      return update(state, {
        orders: state.orders.data ? { data: { $push: payload } } : {},
        info: {
          data: {
            have_unpaid_orders: { $set: true },
            state: {
              active: { orders: { $set: state.info.data.state.active.orders + 1 } },
              total: { orders: { $set: state.info.data.state.total.orders + 1 } },
            },
          },
        },
      });
    case SET_ORDER_STATUS: {
      if (!state.orders.data) return state;
      const order = state.orders.data.find((order) => order.id === payload.id);
      return update(state, {
        orders: {
          data: {
            [state.orders.data.indexOf(order)]: {
              $set: {
                ...order,
                ...payload,
              },
            },
          },
        },
      });
    }
    case SET_PAYMENT_LOADING:
      return update(state, {
        orders: {
          paymentIsLoading: {
            $set: payload,
          },
        },
      });
    case SET_PAYMENT_ERROR:
      return update(state, {
        orders: {
          paymentError: {
            $set: payload,
          },
        },
      });
    case SET_REQUISITION_EMPLOYEES:
      return update(state, { employees: { data: { $set: payload }, isLoading: { $set: false } } });
    case ADD_REQUISITION_EMPLOYEES:
      return update(state, {
        employees: {
          data: { $set: concatenateWithoutDuplicates(state.employees.data, payload, "id") },
          isLoading: { $set: false },
          isError: { $set: false },
        },
      });
    case DELETE_REQUISITION_EMPLOYEE:
      return update(state, {
        employees: {
          data: {
            $set: state.employees.data.filter((employee) => employee.id !== payload),
          },
        },
      });
    case SET_REQUISITION_EMPLOYEES_LOADING:
      return update(state, { employees: { isLoading: { $set: true } } });
    case SET_REQUISITION_EMPLOYEES_ERROR:
      return update(state, { employees: { isError: { $set: true } } });
    case RESET_TO_INITIAL:
      return initialState;
    case SET_INFO_ERROR:
      return update(state, {
        info: {
          isError: {
            $set: true,
          },
          isLoading: {
            $set: false,
          },
        },
      });
    default:
      return state;
  }
};

export const stateSelector = (state) => state[MODULE_NAME];

export const infoDataSelector = createSelector(stateSelector, (state) => state.info.data);
export const infoErrorSelector = createSelector(stateSelector, (state) => state.info.isError);
export const infoLoadingSelector = createSelector(stateSelector, (state) => state.info.isLoading);

export const employeesSelector = createSelector(stateSelector, (state) => state.employees.data);
export const employeesLoadingSelector = createSelector(stateSelector, (state) => state.employees.isLoading);
export const employeesErrorSelector = createSelector(stateSelector, (state) => state.employees.isError);

export const ordersDataSelector = createSelector(stateSelector, (state) => state.orders.data);
export const ordersLoadingSelector = createSelector(stateSelector, (state) => state.orders.isLoading);

export const productsDataSelector = createSelector(stateSelector, (state) => state.products.data);
export const productsLoadingSelector = createSelector(stateSelector, (state) => state.products.isLoading);

export const getInfo = (id) => (dispatch) => {
  dispatch({ type: SET_INFO_LOADING });

  axios
    .get(`/requisition/${id}/`)
    .then((response) => compose(dispatch, setRequisitionInfoAction)(response.data))
    .catch(() => dispatch({ type: SET_INFO_ERROR }));
};

export const updateInfo = (id) => (dispatch) =>
  axios.get(`/requisition/${id}/`).then((response) => compose(dispatch, setRequisitionInfoAction)(response.data));

export const patchRequisition = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, data, callback) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios.patch(`/requisition/${id}/`, data, config).then((response) => {
        callback?.();
        compose(dispatch, setRequisitionInfoAction)(response.data);
        message.success("Заявка успешно обновлена");
      });
    };
  };
})();

export const createRequisition = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: SEND_REQUISITION_LOADING,
        payload: true,
      });
      dispatch({
        type: SEND_REQUISITION_ERROR,
        payload: null,
      });
      axios.post(`/requisition/${id}/create/`, null, config).then(
        (response) => {
          dispatch({
            type: SEND_REQUISITION_LOADING,
            payload: false,
          });
          compose(dispatch, setRequisitionInfoAction)(response.data);
          message.success("Заявка успешно создана");
        },
        (error) => {
          dispatch({
            type: SEND_REQUISITION_LOADING,
            payload: false,
          });
          dispatch({
            type: SEND_REQUISITION_ERROR,
            payload: error.response?.data?.errors?.[0]?.message || "Неизвестный сбой.",
          });
          message.error(error.response.data.errors?.[0]?.message);
        }
      );
    };
  };
})();

export const getOrders = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  let lastFilterParams;
  return (id, filterParams = lastFilterParams) => {
    lastFilterParams = filterParams;
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...filterParams,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: GET_ORDERS,
      });
      dispatch({
        type: SET_PAYMENT_ERROR,
        payload: null,
      });
      axios.get(`/requisition/${id}/orders/`, config).then((response) => {
        dispatch({
          type: SET_ORDERS,
          payload: response.data,
        });
      });
    };
  };
})();

export const updateOrder = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (requisitionId, orderId) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios.get(`/requisition/${requisitionId}/orders/`, config).then((response) => {
        dispatch({
          type: UPDATE_ORDER,
          payload: response.data.find((order) => order.id === orderId),
        });
      });
    };
  };
})();

export const setOrderPayment = (id, val, callback, dispatchCallback) => (dispatch, getState) => {
  dispatch({ type: SET_PAYMENT_ERROR, payload: null });
  dispatch({ type: SET_PAYMENT_LOADING, payload: true });

  axios
    .post(`/purchaser/orders/${id}/to-waiting-shipment/`, val)
    .then((response) => {
      message.success("Заказ успешно отправлен в оплату");

      const requisitionInfoData = getState().requisition.info.data;
      if (requisitionInfoData) dispatch(updateInfo(requisitionInfoData.id));

      dispatch({
        type: SET_ORDER_STATUS,
        payload: response.data,
      });

      if (dispatchCallback && callback) {
        dispatch(callback(response.data));
        return;
      }

      if (callback) callback(response.data);
    })
    .catch(errorCatcher);
};

export const getProducts =
  (id, setLoadingStatus = true) =>
  (dispatch) => {
    if (setLoadingStatus) dispatch({ type: SET_PRODUCTS_LOADING });

    axios.get(`/requisition/${id}/products/`).then((response) => {
      dispatch({ type: SET_PRODUCTS, payload: response.data });
    });
  };

export const addProducts = (id, products = []) => {
  const body = products.map((product) => ({
    product_building_id: product.id,
    required_count: product.required_count,
    count: product.count,
    count_provider: product?.estimate_expenditure?.count,
    price_provider: product.price,
  }));

  return (dispatch) => {
    axios
      .post(`/requisition/${id}/products/`, body)
      .then((response) => {
        dispatch({
          type: ADD_PRODUCTS,
          payload: response.data,
        });
        message.success("Добавлено");
      })
      .catch((error) => console.error(error));
  };
};

export const compareProduct = (id, productId, val) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_COMPARE_PRODUCT,
      payload: productId,
    });

    axios
      .patch(`/requisition/${id}/products/${productId}/`, val)
      .then((response) => dispatch({ type: UPDATE_PRODUCT, payload: response.data }))
      .catch(errorCatcher);
  };
};

export const editProduct = (id, prop, value) => ({
  type: EDIT_PRODUCT,
  payload: {
    id,
    prop,
    value,
  },
});

export const editProductSave = (id, productId, prop, val) => {
  return (dispatch) => {
    axios
      .patch(`/requisition/${id}/products/${productId}/`, { [prop]: val })
      .then((response) => {
        dispatch(editProduct(productId, prop, val));
      })
      .catch((error) => {
        error?.response?.data?.errors?.forEach((item) => message.error(item?.message));
      });
  };
};

export const deleteProduct = (id, product) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_DELETE_PRODUCT,
      payload: product.id,
    });
    axios.delete(`/requisition/${id}/products/${product.id}/`).then(() => {
      dispatch({
        type: DELETE_PRODUCT,
        payload: product,
      });
    });
  };
};

export const createOrder = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;

  return (requisitionId, order) => {
    if (ld_cancel) ld_cancel();

    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios.post(`/requisition/${requisitionId}/create-orders/`, order, config).then(
        (response) => {
          dispatch({ type: ADD_ORDER, payload: response.data });
          compose(dispatch, getProducts)(requisitionId);
          message.success("Заказ успешно сформирован");
        },
        (error) => {
          const errorMessage = error.response.data?.errors?.[0]?.message || "Неизвестный сбой.";

          message.error(errorMessage);
        }
      );
    };
  };
})();

export const becomeExecutor = () => {
  return (dispatch, getState) => {
    const requisitionInfoData = getState().requisition?.info?.data;
    if (!requisitionInfoData) return;

    axios.post(`/requisition/${requisitionInfoData.id}/take/`).then(
      () => {
        dispatch(updateInfo(requisitionInfoData.id));
        message.success("Заявка успешно взята в работу");
      },
      (error) => {
        message.error(error.response.data.errors[0].message);
      }
    );
  };
};

export const updateExecutor = (id) => {
  return (dispatch, getState) => {
    const requisitionInfoData = getState().requisition.info.data;
    if (!requisitionInfoData) return;

    axios
      .patch(`/requisition/${requisitionInfoData.id}/change-executor/`, {
        executor_id: id,
      })
      .then(
        () => {
          dispatch(updateInfo(requisitionInfoData.id));
          message.success("Заявка успешно передана");
        },
        (error) => {
          message.error(error.response.data.errors[0].message);
        }
      );
  };
};

export const getRequisitionEmployees = (requisitionId) => (dispatch) => {
  dispatch({ type: SET_REQUISITION_EMPLOYEES_LOADING });

  axios
    .get(`requisition/${requisitionId}/employees/`)
    .then(({ data }) => dispatch({ type: SET_REQUISITION_EMPLOYEES, payload: data }))
    .catch(() => dispatch({ type: SET_REQUISITION_EMPLOYEES_ERROR }));
};

export const addRequisitionEmployees = (requisitionId, addedEmployees) => {
  return async (dispatch) => {
    const result = await apiAddRequisitionEmployees(requisitionId, addedEmployees);

    dispatch({
      type: ADD_REQUISITION_EMPLOYEES,
      payload: result,
    });
  };
};

export const deleteRequisitionEmployee = (requisitionId, deletedEmployeeId) => (dispatch) => {
  dispatch({
    type: DELETE_REQUISITION_EMPLOYEE,
    payload: deletedEmployeeId,
  });

  axios.delete(`/requisition/${requisitionId}/employees/${deletedEmployeeId}/`).catch(errorCatcher);
};

export const approveRequisition = (requisitionId) => (dispatch) => {
  axios
    .post(`/requisition/${requisitionId}/approve/`)
    .then((response) => {
      dispatch({ type: ADD_REQUISITION_EMPLOYEES, payload: [response.data] });
    })
    .catch(errorCatcher);
};

export const unApproveRequisition = (requisitionId) => (dispatch) => {
  axios
    .delete(`/requisition/${requisitionId}/approve/`)
    .then((response) => {
      dispatch({ type: ADD_REQUISITION_EMPLOYEES, payload: [response.data] });
    })
    .catch(errorCatcher);
};

export const resetToInitialAction = () => ({ type: RESET_TO_INITIAL });
