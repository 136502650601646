//@ts-nocheck
import axios from "axios";
import { message } from "antd";
import { createSelector } from "reselect";
import update from "immutability-helper";
import { compose } from "redux";

import { messageErrorHandler } from "../../../../../utils/errorHandler";
import { errorCatcher } from "../../../../../utils/errorCatcher";

import {
  getLastRequisitionDraftRequest,
  getTimesheetApprovalRequest,
  postTimesheetApprovalRequest,
} from "./nowObjectApi";
import { InowObjectState } from "./types";
import { RootState } from "redux/rootReducer";

const moduleName = "nowObject";

const GET_PRODUCTS = `${moduleName}/GET_PRODUCTS`;
const SET_PRODUCTS = `${moduleName}/SET_PRODUCTS`;

const GET_IMPORT_LIST = `${moduleName}/GET_IMPORT_LIST`;
const SET_IMPORT_LIST = `${moduleName}/SET_IMPORT_LIST`;

const GET_REQUISITIONS = `${moduleName}/GET_REQUISITIONS`;
const SET_REQUISITIONS = `${moduleName}/SET_REQUISITIONS`;

const GET_DETAIL = `${moduleName}/GET_DETAIL`;
const SET_DETAIL = `${moduleName}/SET_DETAIL`;
const RESET_DETAIL = `${moduleName}/RESET_DETAIL`;

const SET_EMPLOYEES_LOADING = `${moduleName}/SET_EMPLOYEES_LOADING`;
const SET_EMPLOYEES = `${moduleName}/SET_EMPLOYEES`;
const RESET_EMPLOYEES = `${moduleName}/RESET_EMPLOYEES`;

const SET_TIMESHEET_APPROVAL = `${moduleName}/SET_TIMESHEET_APPROVAL`;
const SET_TIMESHEET_APPROVERS_LOADING = `${moduleName}/SET_TIMESHEET_APPROVERS_LOADING`;
const SET_TIMESHEET_APPROVERS_DATA = `${moduleName}/SET_TIMESHEET_APPROVERS_DATA`;
const ADD_TIMESHEET_APPROVER = `${moduleName}/ADD_TIMESHEET_APPROVER`;
const DELETE_TIMESHEET_APPROVER = `${moduleName}/DELETE_TIMESHEET_APPROVER`;

const SET_PRODUCT_USING = `${moduleName}/SET_PRODUCT_USING`;

const SET_LAST_REQUISITION_DRAFT_ID = `${moduleName}/SET_LAST_REQUISITION_DRAFT_ID`;
const RESET_LAST_REQUISITION_DRAFT_ID = `${moduleName}/RESET_LAST_REQUISITION_DRAFT_ID`;

export const initialState: InowObjectState = {
  products: {
    data: [],
    isLoading: true,
  },
  importList: {
    data: [],
    isLoading: true,
  },
  requisitions: {
    data: [],
    isLoading: true,
  },
  detail: {
    data: null,
    isLoading: false,
  },
  employees: {
    data: null,
    isLoading: false,
  },
  timesheetApprovers: {
    data: null,
    isLoading: false,
  },
  lastRequisitionDraftId: null,
};

export default (state = initialState, action): InowObjectState => {
  const { type, payload } = action;
  switch (type) {
    case GET_PRODUCTS:
      return update(state, {
        products: {
          isLoading: { $set: true },
        },
      });

    case SET_PRODUCTS:
      return update(state, {
        products: {
          data: {
            $set: payload,
          },
          isLoading: {
            $set: false,
          },
        },
      });

    case GET_IMPORT_LIST:
      return update(state, {
        importList: {
          isLoading: { $set: true },
        },
      });

    case SET_IMPORT_LIST:
      return update(state, {
        importList: {
          data: {
            $set: payload,
          },
          isLoading: {
            $set: false,
          },
        },
      });

    case GET_REQUISITIONS:
      return update(state, {
        requisitions: {
          isLoading: { $set: true },
        },
      });

    case SET_REQUISITIONS:
      return update(state, {
        requisitions: {
          data: {
            $set: payload,
          },
          isLoading: {
            $set: false,
          },
        },
      });

    case GET_DETAIL:
      return update(state, {
        detail: {
          isLoading: { $set: true },
        },
      });

    case SET_DETAIL:
      return update(state, {
        detail: {
          data: {
            $set: payload,
          },
          isLoading: {
            $set: false,
          },
        },
      });
    case RESET_DETAIL:
      return update(state, { detail: { $set: initialState.detail } });
    case SET_EMPLOYEES_LOADING:
      return update(state, { employees: { isLoading: { $set: true } } });
    case SET_EMPLOYEES:
      return update(state, { employees: { data: { $set: payload }, isLoading: { $set: false } } });
    case RESET_EMPLOYEES:
      return update(state, { employees: { $set: initialState.employees } });
    case SET_TIMESHEET_APPROVAL:
      return {
        ...state,
        timesheetApproval: payload,
      };

    case SET_PRODUCT_USING:
      return {
        ...state,
        productUsing: payload,
      };
    case SET_TIMESHEET_APPROVERS_DATA:
      return update(state, { timesheetApprovers: { data: { $set: payload } } });
    case SET_TIMESHEET_APPROVERS_LOADING:
      return update(state, { timesheetApprovers: { isLoading: { $set: payload } } });
    case ADD_TIMESHEET_APPROVER:
      return update(state, { timesheetApprovers: { data: { $push: [payload] } } });
    case DELETE_TIMESHEET_APPROVER:
      return update(state, {
        timesheetApprovers: {
          data: {
            $set: state.timesheetApprovers.data.filter((approver) => approver.id !== payload),
          },
        },
      });
    case SET_LAST_REQUISITION_DRAFT_ID:
      return { ...state, lastRequisitionDraftId: payload };
    case RESET_LAST_REQUISITION_DRAFT_ID:
      return { ...state, lastRequisitionDraftId: initialState.lastRequisitionDraftId };
    default:
      return state;
  }
};

export const resetEmployees = () => ({ type: RESET_EMPLOYEES });
export const resetDetail = () => ({ type: RESET_DETAIL });
export const setDetailAction = (payload) => ({ type: SET_DETAIL, payload });
export const setTimesheetApproversDataAction = (payload) => ({ type: SET_TIMESHEET_APPROVERS_DATA, payload });
export const setTimesheetApproversLoadingAction = (payload) => ({ type: SET_TIMESHEET_APPROVERS_LOADING, payload });
export const addTimesheetApproverAction = (payload) => ({ type: ADD_TIMESHEET_APPROVER, payload });
export const deleteTimesheetApproverAction = (payload) => ({ type: DELETE_TIMESHEET_APPROVER, payload });

const setLastRequisitionDraftIdAction = (payload) => ({ type: SET_LAST_REQUISITION_DRAFT_ID, payload });
export const resetLastRequisitionDraftIdAction = () => ({ type: RESET_LAST_REQUISITION_DRAFT_ID });

export const stateSelector = (state: RootState) => state[moduleName];

export const productsDataSelector = createSelector(stateSelector, (state) => state.products.data);
export const productsLoadingSelector = createSelector(stateSelector, (state) => state.products.isLoading);

export const importListDataSelector = createSelector(stateSelector, (state) => state.importList.data);
export const importListLoadingSelector = createSelector(stateSelector, (state) => state.importList.isLoading);

export const requisitionsDataSelector = createSelector(stateSelector, (state) => state.requisitions.data);
export const requisitionsLoadingSelector = createSelector(stateSelector, (state) => state.requisitions.isLoading);

export const detailDataSelector = createSelector(stateSelector, (state) => state.detail.data);
export const detailLoadingSelector = createSelector(stateSelector, (state) => state.detail.isLoading);

export const detailEmployeesSelector = createSelector(stateSelector, (state) => state.employees?.data);

export const timesheetApprovalSelector = createSelector(stateSelector, (state) => state.timesheetApproval);

export const lastRequisitionDraftIdSelector = createSelector(stateSelector, (state) => state.lastRequisitionDraftId);

export const timesheetApproversSelector = createSelector(stateSelector, (state) => state.timesheetApprovers.data);
export const timesheetApproversLoadingSelector = createSelector(
  stateSelector,
  (state) => state.timesheetApprovers.isLoading
);

export const productUsingSelector = createSelector(stateSelector, (state) => state.productUsing);
export const buildingEmployeesSelector = createSelector(stateSelector, (state) => state.employees);

export const addNewSection = (id, data, callback = null) => {
  return (dispatch) => {
    axios.post(`/building/${id}/sections/`, data).then(
      (response) => {
        message.success(`Раздел "${response.data.name}" успешно добавлен в список материалов`);
        callback?.();
      },
      (error) => {}
    );
  };
};

export const deleteSection = (id, target, callback = null) => {
  return (dispatch) => {
    axios.delete(`/building/${id}/sections/${target}/`).then(
      (response) => {
        message.success(`Раздел успешно удален из списка материалов`);
        callback?.();
      },
      (error) => {
        messageErrorHandler(error.response.data.errors);
      }
    );
  };
};

export const getImportList = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;

  let lastPaginationParams, lastFilterParams;
  return (id, paginationParams = lastPaginationParams, filterParams = lastFilterParams) => {
    lastPaginationParams = paginationParams;
    lastFilterParams = filterParams;
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({ type: GET_IMPORT_LIST });

      axios.get(`/building/${id}/products/import/`, config).then(
        (response) => {
          dispatch({
            type: SET_IMPORT_LIST,
            payload: response.data,
          });
        },
        (error) => {}
      );
    };
  };
})();

export const deleteImport = (id, target, callback = null) => {
  return (dispatch) => {
    axios.delete(`/building/${id}/products/import/${target}/`).then(
      (response) => {
        message.success(`Импорт успешно удален из списка`);
        dispatch(getImportList(id));
        callback?.();
      },
      (error) => {}
    );
  };
};

export const getRequisitions = (() => {
  return (id, paginationParams, filterParams) => {
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
      },
    };

    return (dispatch) => {
      dispatch({
        type: GET_REQUISITIONS,
      });

      axios.get(`/building/${id}/requisition/`, config).then(
        (response) => {
          dispatch({
            type: SET_REQUISITIONS,
            payload: response.data,
          });
        },
        (error) => {}
      );
    };
  };
})();

export const createRequisition =
  (id, callback = null) =>
  () => {
    axios.post(`/requisition/`, { building_id: id }).then((response) => {
      callback?.(response);
    });
  };

export const getLastRequisitionDraftId = (buildingId) => (dispatch) =>
  getLastRequisitionDraftRequest(buildingId)
    .then((response) => {
      compose(dispatch, setLastRequisitionDraftIdAction)(response.data.id);
      return response.data.id;
    })
    .catch(errorCatcher);

export const getDetail = (id) => (dispatch) => {
  dispatch({ type: GET_DETAIL });
  axios
    .get(`/building/${id}/`)
    .then((response) => dispatch({ type: SET_DETAIL, payload: response.data }))
    .catch(errorCatcher);
};

export const getProductUsings = (objectId, expenditureId) => {
  const config = {
    params: {
      estimate_item_id: expenditureId,
    },
  };

  return (dispatch) => {
    axios.get(`/building/${objectId}/productusings/`, config).then(
      (response) => {
        dispatch({
          type: SET_PRODUCT_USING,
          payload: response.data,
        });
      },
      (error) => {}
    );
  };
};

export const setDetailNull = () => {
  return (dispatch) => {
    dispatch({
      type: SET_DETAIL,
      payload: {},
    });
  };
};

export const getEmployees = (buildingId) => (dispatch) => {
  dispatch({ type: SET_EMPLOYEES_LOADING });

  axios
    .get(`/building/${buildingId}/employees/`)
    .then((response) => dispatch({ type: SET_EMPLOYEES, payload: response.data }))
    .catch(errorCatcher);
};

export const getTimesheetApproval = (options) => {
  let cancel;
  return async (dispatch) => {
    if (cancel) cancel();
    const responseData = await getTimesheetApprovalRequest(options, {
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    });
    await dispatch({
      type: SET_TIMESHEET_APPROVAL,
      payload: responseData,
    });
  };
};

export const setTimesheetApprovalFile = (options, file) => {
  const formData = new FormData();
  formData.append("file", file);

  return async (dispatch) => {
    const data = await postTimesheetApprovalRequest(options, formData);
    await dispatch({
      type: SET_TIMESHEET_APPROVAL,
      payload: data,
    });
  };
};

export const setObjectDetail = (objectDetail) => (dispatch) => {
  axios
    .patch(`/building/${objectDetail.id}/`, objectDetail)
    .then((response) => compose(dispatch, setDetailAction)(response.data))
    .catch(errorCatcher);
};

export const getTimesheetApprovers = (options) => async (dispatch) => {
  compose(dispatch, setTimesheetApproversLoadingAction)(true);

  await axios
    .get(`/building/${options.buildingId}/timesheet_approval/${options.year}/${options.month}/approvers/`)
    .then((response) => compose(dispatch, setTimesheetApproversDataAction)(response.data.results))
    .catch((e) => {
      errorCatcher(e);
      compose(dispatch, setTimesheetApproversDataAction)([]);
    });

  compose(dispatch, setTimesheetApproversLoadingAction)(false);
};

export const addTimesheetApprover = (options, userId) => (dispatch) => {
  axios
    .post(`/building/${options.buildingId}/timesheet_approval/${options.year}/${options.month}/approvers/`, {
      user_id: userId,
    })
    .then((response) => {
      compose(dispatch, addTimesheetApproverAction)(response.data);
      message.success("Согласующий успешно добавлен");
    })
    .catch(errorCatcher);
};

export const deleteTimesheetApprover = (options, userId) => (dispatch) => {
  axios
    .delete(`/building/${options.buildingId}/timesheet_approval/${options.year}/${options.month}/approvers/${userId}/`)
    .then(() => {
      compose(dispatch, deleteTimesheetApproverAction)(userId);
      message.success("Согласующий успешно удален");
    })
    .catch(errorCatcher);
};

export const approveTimesheet = (options) => (dispatch) =>
  axios
    .post(`/building/${options.buildingId}/timesheet_approval/${options.year}/${options.month}/`, { approved: true })
    .then((response) => {
      dispatch({ type: SET_TIMESHEET_APPROVAL, payload: response.data });
      message.success("Табель успешно согласован");
    })
    .catch(errorCatcher);

export const generateTimesheet = (options) => (dispatch) =>
  axios
    .post(`/building/${options.buildingId}/timesheet_approval/${options.year}/${options.month}/responsible_approve/`, {
      approved: true,
    })
    .then((response) => {
      dispatch({ type: SET_TIMESHEET_APPROVAL, payload: response.data });
      message.success("Табель успешно сформирован");
    })
    .catch(errorCatcher);
