import React, { useCallback, useState } from "react";

import { getProductMatchingStatus } from "../../../../utils/getProductMatchingStatus";

import ProductInfoModal from "../../../../../../UI/molecules/ProductInfoModal/ProductInfoModal";
import MatchingIcon from "../../../../../../UI/atoms/MatchingIcon/MatchingIcon";

const ProductMatching = ({ product, onEditProductMatching }) => {
  const [isMatchingInfoModalOpen, setIsMatchingInfoModalOpen] = useState(false);

  const openMatchingInfoModal = useCallback(() => setIsMatchingInfoModalOpen(true), []);
  const closeMatchingInfoModal = useCallback(() => setIsMatchingInfoModalOpen(false), []);

  const handleOnEditProductMatching = useCallback(
    () => onEditProductMatching(product),
    [onEditProductMatching, product]
  );

  const onClickByMatchingIcon = useCallback(() => {
    if (product.product_building.estimate_expanditure) {
      openMatchingInfoModal();
    } else {
      handleOnEditProductMatching()
    }
  }, []);

  return (
    <>
      {product.product_building.estimate_expanditure && (
        <ProductInfoModal
          isOpen={isMatchingInfoModalOpen}
          onClose={closeMatchingInfoModal}
          onOpenEditing={handleOnEditProductMatching}
          section={product.product_building.estimate_expanditure.section.name}
          number={product.product_building.estimate_expanditure.number}
          justification={product.product_building.estimate_expanditure.justification}
          expenditureName={product.product_building.estimate_expanditure.name}
          price={product.product_building.estimate_expanditure.price}
          count={product.product_building.estimate_expanditure.count}
          canEdit
        />
      )}
      <MatchingIcon
        onClick={onClickByMatchingIcon}
        status={getProductMatchingStatus({
          ...product,
          product_building: { ...product.product_building, created_from_estimate: product.created_from_estimate },
        })}
      />
    </>
  );
};

export default React.memo(ProductMatching);
