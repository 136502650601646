import React from "react";

import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import Modal from "../../../UI/atoms/Modal";

import { splitThousands } from "utils/splitThousands";

import styles from "./ProductInfoModal.module.scss";

interface IProps {
  isOpen: boolean,
  isLoading?: boolean,
  onClose: () => void,
  onOpenEditing: () => void,
  canEdit: boolean,
  section: string,
  number: number,
  justification: string,
  expenditureName: string,
  price: string,
  count: string
}

function ProductInfoModal({
  isOpen,
  isLoading,
  onClose,
  onOpenEditing,
  canEdit,
  section,
  number,
  justification,
  expenditureName,
  price,
  count,
}: IProps) {

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="По смете" closeOnClickOutside={true}>
      <div className={styles.body}>
        {!isLoading ? (
          <>
            <div>
              <b>Раздел: </b>
              <br />
              {section}
            </div>
            {number && (
              <div className={styles.item}>
                <b>Номер: </b>
                <br />
                {number}
              </div>
            )}
            {justification && (
              <div className={styles.item}>
                <b>Обоснование: </b>
                <br />
                {justification}
              </div>
            )}
            {expenditureName && (
              <div className={styles.item}>
                <b>Позиция: </b>
                <br />
                {expenditureName}
              </div>
            )}
            {price && (
              <div className={styles.item}>
                <b>Цена за ед. : </b>
                {splitThousands(parseFloat(price))}
              </div>
            )}
            {count && (
              <div className={styles.item}>
                <b>Кол-во по смете: </b>
                {splitThousands(parseFloat(count), false)}
              </div>
            )}
            {price && (
              <div className={styles.item}>
                <b>Бюджет по смете: </b>
                {splitThousands(parseFloat(price) * parseFloat(count))}
              </div>
            )}
          </>
        ) : (
          <Spinner />
        )}
      </div>
      <footer className={styles.footer}>
        {canEdit && (
          <div className={styles.btns}>
            <ButtonBase primary onClick={onOpenEditing} medium>
              Изменить
            </ButtonBase>
          </div>
        )}
      </footer>
    </Modal>
  );
}

export default React.memo(ProductInfoModal);
