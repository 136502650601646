import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  addLoadObjects,
  loadObjects,
  objectsLoadingSelector,
  objectsSelector,
  transferredSelector
} from "../../../../redux/modules/common/building/projects";
import { changeObjectStatus } from "redux/modules/common/building/projects";

import Button from "components/UI/atoms/ButtonBase";
import TemplateBase from "components/UI/templates/TemplateBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import AlertPopup from "../../../../elements/popups/AlertPopup/AlertPopup";
import TabBarNotLinks from "../../../../elements/navigation/TabBar/TabBarNotLinks";
import ForbiddenPage from "../../../routes/components/ForbiddenPage/ForbiddenPage";
import projectsIcon from "images/icons/navigation/estimateIcon.svg";
import styles from "./index.module.scss";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";
import ObjectsHeaderRow from "./components/ObjectsRow/ObjectsHeaderRow";
import { Spinner } from "components/UI/Spinner/Spinner";
import ObjectsRow from "./components/ObjectsRow/ObjectsRow";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";

const Objects = ({ isLoading, addLoadObjects, permissions }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accepted = useSelector(objectsSelector);
  const transferred = useSelector(transferredSelector);

  const sentTabAbleToView = accepted.results.length + transferred.results.length - accepted.results.length !== 0;

  const getDefaultActiveTab = () => {
    if (permissions.viewAllBuildingsTab) return "all";
    if (permissions.viewSentBuildingsTab && sentTabAbleToView) return "sent";
    if (permissions.viewAcceptedBuildingsTab) return "accepted";
  };
  const [tab, setTab] = useState(getDefaultActiveTab());

  const [acceptedFilters, setAcceptedFilters] = useState({
    limit: 10,
    offset: 0,
  });

  const transferredFilters = useMemo(
    () => ({
      limit: 10,
      offset: 0,
      shared_status: "transferred",
    }),
    []
  );

  const changeAcceptedFilters = (name, value) => {
    setAcceptedFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAcceptedAdd = () => {
    addLoadObjects(null, {
      ...acceptedFilters,
      offset: accepted?.results.length,
    });
  };

  const handleTransferredAdd = () => {
    addLoadObjects(null, {
      ...transferredFilters,
      offset: transferred?.results.length,
    });
  };

  const onCreateClick = useCallback(() => {
    history.push("projects/create-project/new/passport");
  }, [history]);

  const [confirmModal, setConfirmModal] = useState({ open: false, name: "", id: null, status: null });
  const clearConfirm = useCallback(() => {
    setConfirmModal({ open: false, name: "", id: null, status: null });
  }, []);

  const handleStatusChange = (row, value) => {
    setConfirmModal({
      open: true,
      id: row?.id,
      name: row?.name,
      status: value,
    });
  };

  const changeStatus = useCallback(
    async (id, status) => {
      dispatch(
        changeObjectStatus(id, status, [
          {
            paginationParams: null,
            filterParams: acceptedFilters,
            ordering: null,
          },
          {
            paginationParams: null,
            filterParams: transferredFilters,
            ordering: null,
          },
        ])
      );
      clearConfirm();
    },
    [confirmModal.id, confirmModal.status]
  );

  const cancelChange = () => {
    clearConfirm();
  };

  const tabs = React.useMemo(() => {
    const result = [];
    if (permissions.viewAllBuildingsTab) result.push({ text: "Все проекты", id: "all" });
    if (permissions.viewSentBuildingsTab && sentTabAbleToView) result.push({ text: "Выполненные", id: "sent" });
    if (permissions.viewAcceptedBuildingsTab) result.push({ text: "Выполняются", id: "accepted" });
    return result;
  }, [
    permissions.viewAcceptedBuildingsTab,
    permissions.viewAllBuildingsTab,
    permissions.viewSentBuildingsTab,
    sentTabAbleToView,
  ]);

  useEffect(() => {
    dispatch(loadObjects(null, acceptedFilters, null));
  }, [acceptedFilters]);

  useEffect(() => {
    dispatch(loadObjects(null, transferredFilters, null));
  }, [transferredFilters]);

  return (
    <TemplateBase>
      <ListPageHeaderTemplate>
        <TabBarNotLinks tabs={tabs} activeId={tab} onClick={setTab} />
        {permissions.viewCreateBuilding && (
          <Button onClick={onCreateClick} secondary>
            Создать проект
          </Button>
        )}
      </ListPageHeaderTemplate>
      {
        tab === "all" && transferred?.results?.length > 0 && (
          <div className={styles.sharedBlock}>
            <p>
              Предложения от заказчика: <strong>{transferred.results.length}</strong>
            </p>
            {transferred.results.map(el => <ObjectsRow
              key={el.id}
              object={el}
              onStatusChange={handleStatusChange}
              isTransferred
              viewBuildingPermission={permissions.viewBuilding}
              viewEditBuildingPermission={permissions.viewEditBuildings}
              viewHandlerPermission={permissions.viewHandler}
            />)}
            <ShowMoreButton
              handleAdd={handleTransferredAdd}
              showedCount={transferred?.results.length}
              allCount={transferred?.count}
              isExists={transferred?.count > transferred?.results.length}
            />
            {confirmModal.open && (
              <AlertPopup
                onClose={clearConfirm}
                title={`${confirmModal.status === "accepted" ? "Принять" : "Отклонить"} проект "${confirmModal.name}"`}
              >
                <div className={styles.alert}>
                  <p>
                    Вы действительно хотите{" "}
                    <strong>{confirmModal.status === "accepted" ? "принять" : "отклонить"}</strong> проект{" "}
                    <strong>{confirmModal.name}</strong>?
                  </p>
                </div>
                <div className={styles.btns}>
                  <button onClick={() => cancelChange(confirmModal.id)}>Отменить</button>
                  <button
                    onClick={() => changeStatus(confirmModal.id, confirmModal.status)}
                    className={confirmModal.status === "accepted" ? styles.accept : styles.decline}
                  >
                    {confirmModal.status === "accepted" ? "Принять" : "Отклонить"}
                  </button>
                </div>
              </AlertPopup>
            )}
          </div>
        )
      }
      {tab !== "sent" && (
        <>
          <ObjectsHeaderRow onChangeFilter={changeAcceptedFilters} />
          {!isLoading && !accepted?.results.length && <EmptyPlaceholder text="Нет проектов" img={projectsIcon} />}
          {accepted?.results?.map(el => (
            <ObjectsRow
              key={el.id}
              object={el}
              viewBuildingPermission={permissions.viewBuilding}
              viewEditBuildingPermission={permissions.viewEditBuildings}
              viewHandlerPermission={permissions.viewHandler}
            />
          ))}
          {isLoading && !accepted?.results.length && <Spinner isFullHeight={!!transferred?.results?.length} isStatic />}
        </>
      )}
      <ShowMoreButton
        allCount={accepted?.count}
        showedCount={accepted?.results.length}
        isExists={["all", "accepted"].includes(tab) && accepted?.count > accepted?.results.length && !isLoading}
        handleAdd={handleAcceptedAdd}
      />
      {tab === "sent" && <EmptyPlaceholder text="Нет проектов" img={projectsIcon} />}
      {!tab && <ForbiddenPage />}
    </TemplateBase>
  );
};

export default connect(
  (state) => ({
    isLoading: objectsLoadingSelector(state),
    full_access: state.auth.user.full_access,
  }),
  {
    addLoadObjects,
  }
)(Objects);
