import axios from 'axios';
import { createSelector } from 'reselect';
const IMPORT_LIST = 'importList';
const SET_IMPORT_LIST = `${IMPORT_LIST}/SET_IMPORT_LIST`;
const SET_IMPORT_TEMPLATE = `${IMPORT_LIST}/SET_IMPORT_TEMPLATE`;
const SET_IMPORT_ERRORS = `${IMPORT_LIST}/SET_IMPORT_ERRORS`;

const initialState = {
  importList: [],
  template: null,
  errors: null,
  isLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_IMPORT_LIST: {
      return {
        ...state,
        isLoading: false,
        importList: payload,
      };
    }

    case SET_IMPORT_TEMPLATE: {
      return {
        ...state,
        template: payload,
      };
    }

    case SET_IMPORT_ERRORS: {
      return {
        ...state,
        errors: payload,
      };
    }

    default:
      return state;
  }
};

export const stateSelector = state => state.importList;
export const importListSelector = createSelector(stateSelector, state => state.importList);
export const importTemplateSelector = createSelector(stateSelector, state => state.template);
export const importErrorsSelector = createSelector(stateSelector, state => state.errors);
export const loadingSelector = createSelector(stateSelector, state => state.isLoading);

export const setImportList = data => {
  return {
    type: SET_IMPORT_LIST,
    payload: data,
  };
};

export const setImportTemplate = data => {
  return {
    type: SET_IMPORT_TEMPLATE,
    payload: data,
  };
};
export const setImportErrors = data => {
  return {
    type: SET_IMPORT_ERRORS,
    payload: data,
  };
};

export const loadImportList = (() => {
  return entityId => {
    return dispatch => {
      axios.get(`/provider/products/import/`).then(
        res => {
          dispatch(setImportList(res.data));
        },
        error => {
          // console.log(error);
        }
      );
    };
  };
})();

export const loadImportTemplate = (() => {
  return entityId => {
    return dispatch => {
      axios.get(`/provider/products/import/template/`).then(
        res => {
          dispatch(setImportTemplate(res.data));
        },
        error => {
          // console.log(error);
        }
      );
    };
  };
})();

export const loadImportErrors = (() => {
  return (entityId, uploadId) => {
    return dispatch => {
      axios.get(`entities/${entityId}/products-provider/import/${uploadId}/errors/`).then(
        res => {
          dispatch(setImportErrors(res.data));
        },
        error => {
          // console.log(error);
        }
      );
    };
  };
})();

export const deleteImport = (() => {
  return (entityId, uploadId) => {
    return dispatch =>
      axios.delete(`/provider/products/import/${uploadId}/`).then(
        res => {
          // console.log(res);
        },
        err => {
          // console.log(err);
        }
      );
  };
})();
