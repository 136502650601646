import React, { useState, useRef, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import cn from "classnames";

import { projectEstimateSelector } from "redux/modules/common/building/manufacturing/selectors";
import { projectEstimateSet } from "redux/modules/common/building/manufacturing/thunks";
import { objectsLoadingSelector, objectsSelector } from "redux/modules/common/building/objects";

import TemplateSimple from "components/UI/templates/TemplateSimple";
import Month from "./components/Month/Month";
import EmptyPlaceholder from "../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import BigLegenda from "./components/BigLegenda";
import { Spinner } from "../../UI/Spinner/Spinner";
import ForbiddenPage from "../../routes/components/ForbiddenPage/ForbiddenPage";
import ProjectsTree from "./components/ProjectsTree/ProjectsTree";
import ManufacturingControls from "./components/ManufacturingControls/ManufacturingControls";
import CalendarBackground from "./components/CalendarBackground/CalendarBackground";
import CalendarDateLine from "./components/CalendarDateLine/CalendarDateLine";
import DiagramActions from "./components/DiagramActions/DiagramActions";

import {
  VIEW_CONSTRUCTING_CHART_MATERIALS,
  VIEW_CONSTRUCTING_CHART_WORKS,
} from "../../../constants/permissions/constructingPermissions";
import { WORKS_TAB_ID } from "./constants";
import { VIEW_PURCHASES_CHART_MATERIALS } from "constants/permissions/purchasesPermissions";
import { MODULES_ENUM } from "types/enums/ModulesEnum";

import usePermission from "../../../hooks/usePermission";
import {
  getMonthInfo,
  getWeeksInYear,
  spittingTreeGenerationFn,
  checkIsMonthBranchShownAsSection,
  checkIsMonthBranchShownAsExpenditure,
  checkIsShownSectionPlan,
} from "./utils";
import { useCalendarScroll } from "./hooks/useCalendarScroll";
import { useSpittingTree } from "./hooks/useSpittingTree";
import { useProjectTree } from "./hooks/useProjectTree";
import { useIntervals } from "./hooks/useIntervals";
import { useTabsWithPermissions } from "./hooks/useTabsWithPermissions";
import { useUrlModule } from "utils/hooks/useUrlModule";

import scheduleIcon from "../../../images/icons/navigation/scheduleIcon.svg";

import styles from "./Manufacturing.module.scss";
import {Xwrapper} from 'react-xarrows'

const Manufacturing = () => {
  const { objectId: projectId } = useParams();
  const calendarRef = useRef(null);
  const containerRef = useRef(null);

  const urlModule = useUrlModule();
  const currentDay = moment().date();
  const currentMonth = moment().month();

  const projectEstimate = useSelector(projectEstimateSelector);
  const projects = useSelector(objectsSelector);
  const isProjectsLoading = useSelector(objectsLoadingSelector);

  const [isEmpty, setIsEmpty] = useState(false);
  const [expandedBranches, setExpandedBranches] = useState(new Set());
  const [month, setMonth] = useState(() => getMonthInfo(currentMonth));
  const [year, setYear] = useState(moment().format("YYYY"));

  const weeksInYear = getWeeksInYear(year);

  const startWeek = 1;
  const endWeek = weeksInYear;

  const haveViewWorksTabPermission = usePermission(urlModule === MODULES_ENUM.CONSTRUCTING ? VIEW_CONSTRUCTING_CHART_WORKS : false);
  const haveViewMaterialsTabPermission = usePermission(urlModule === MODULES_ENUM.CONSTRUCTING ? VIEW_CONSTRUCTING_CHART_MATERIALS : VIEW_PURCHASES_CHART_MATERIALS);

  const { tab } = useTabsWithPermissions({ haveViewWorksTabPermission, haveViewMaterialsTabPermission });

  const actualProjects = useMemo(() => {
    if (projectId) {
      const candidate = projects?.results?.filter((x) => +x.id === +projectId);
      if (candidate?.length) return candidate;
      return [];
    }
    return projects?.results;
  }, [projectId, projects?.results]);

  useIntervals({ year, month });

  const { generateSpittingTree, isGeneratingTree, spittingTree, skipGeneratingTreeHandler } = useSpittingTree({
    spittingTreeGenerationFn,
    spittingTreeFnArgs: { actualProjects, expandedBranches, projectEstimate, tab },
  });

  useEffect(() => {
    if (!actualProjects?.length) {
      skipGeneratingTreeHandler();
      return;
    }
    generateSpittingTree();
  }, [actualProjects, projectEstimate, tab, projectId]);

  useEffect(() => {
    if (!spittingTree.length || !projectId || !actualProjects) return;
    if (actualProjects[0]?.works_count === 0 && actualProjects[0]?.products_count === 0) setIsEmpty(true);
    return () => setIsEmpty(false);
  }, [spittingTree, projectId]);

  const { checkIsExpandedBranchId, toggleBranch } = useProjectTree({
    actualProjects,
    tab,
    projectEstimate,
    projectEstimateSet,
    generateSpittingTree,
    month,
    year,
    startWeek,
    endWeek,
    projectId,
    setIsEmpty,
    expandedBranches,
    setExpandedBranches,
  });

  const { computedMonthMarkers, handleDateChange, scrollCalendar } = useCalendarScroll({
    calendarRef,
    month,
    year,
    projectId,
    setMonth,
    setYear,
  });

  const checkIsCheckboxTrue = useCallback(
    (branch) => (branch.lvl === 1 ? !checkIsExpandedBranchId(branch.id) : checkIsExpandedBranchId(branch.id)),
    [checkIsExpandedBranchId]
  );

  const checkIsCheckboxVisible = useCallback((branch) => branch.lvl < 4, []);

  if (!haveViewWorksTabPermission && !haveViewMaterialsTabPermission) {
    return (
      <TemplateSimple>
        <ForbiddenPage />
      </TemplateSimple>
    );
  }

  return (
    <TemplateSimple contentClassName={styles.template}>
      {isEmpty || (actualProjects?.length === 0 && !isGeneratingTree && !isProjectsLoading) ? (
        <EmptyPlaceholder img={scheduleIcon} />
      ) : isGeneratingTree || isProjectsLoading ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          <ManufacturingControls
            year={year}
            month={month}
            handleDateChange={handleDateChange}
            scrollCalendar={scrollCalendar}
            haveViewWorksTabPermission={haveViewWorksTabPermission}
            haveViewMaterialsTabPermission={haveViewMaterialsTabPermission}
          />
          <div className={styles.content} ref={containerRef}>
            <div
              className={cn(styles.calendar, "diagram_calendar")}
              ref={calendarRef}
              style={{ height: `${spittingTree.length * 3}rem` }}
            >
              <CalendarDateLine year={year} />
              <ProjectsTree
                spittingTree={spittingTree}
                checkIsExpandedBranchId={checkIsExpandedBranchId}
                toggleBranch={toggleBranch}
                type={tab}
                checkIsCheckboxTrue={checkIsCheckboxTrue}
                checkIsCheckboxVisible={checkIsCheckboxVisible}
              />
              <CalendarBackground year={year} unitHeight={spittingTree.length} />
              <Xwrapper>
                <Month
                  projects={actualProjects}
                  tree={spittingTree}
                  year={year}
                  month={month}
                  today={currentMonth === month.id ? currentDay : -1}
                  monthMarkers={computedMonthMarkers}
                  type={tab}
                  startWeek={startWeek}
                  endWeek={endWeek}
                  checkIsMonthBranchShownAsSection={checkIsMonthBranchShownAsSection}
                  checkIsShownSectionPlan={checkIsShownSectionPlan}
                  checkIsMonthBranchShownAsExpenditure={checkIsMonthBranchShownAsExpenditure}
                />
              </Xwrapper>
              <div className={styles.legendaWrapper}>
                <div className={styles.legenda}>
                  <BigLegenda type={tab} />
                </div>
              </div>
              {tab === WORKS_TAB_ID && (
                <div className={styles.diagramActionsWrapper}>
                  <DiagramActions />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </TemplateSimple>
  );
};

export default Manufacturing;
