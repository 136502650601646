import React from "react";
import cn from "classnames";

import styles from "./MatchingIcon.module.scss";

export enum MatchingStatusesEnum {
  outOfEstimate,
  manual,
  auto,
}

const titlesByStatuses = {
  [MatchingStatusesEnum.outOfEstimate]: "Не сопоставлено со сметой. Требуется сопоставление",
  [MatchingStatusesEnum.manual]: "Ручное сопоставление со сметой",
  [MatchingStatusesEnum.auto]: "Автоматическое сопоставление со сметой",
};

export interface IMatchingIconProps {
  status: MatchingStatusesEnum;
  onClick?: (e?: React.MouseEvent) => void;
  className?: string;
  disabled?: boolean;
}

function MatchingIcon({ onClick, status, className, disabled }: IMatchingIconProps) {
  return (
    <button
      className={cn(styles.container, className)}
      type="button"
      disabled={disabled}
      title={titlesByStatuses[status]}
    >
      <div
        onClick={onClick}
        className={cn(styles.icon, {
          [styles.black]: status === MatchingStatusesEnum.outOfEstimate,
          [styles.blue]: status === MatchingStatusesEnum.auto,
          [styles.green]: status === MatchingStatusesEnum.manual,
        })}
      />
      {status === MatchingStatusesEnum.outOfEstimate && <span className={styles.attention}>!</span>}
    </button>
  );
}

export default React.memo(MatchingIcon);
