import axios from "axios";
import { createSelector } from "reselect";
import { message } from "antd";

import { messageErrorHandler } from "utils/errorHandler";

import { listStateSelector } from "../list";
import { FIELDS } from "./constants";
import { errorCatcher } from "../../../../../../../../utils/errorCatcher";

const initialState = {
  addModalOpen: false,
  values: Object.values(FIELDS).reduce(
    (acc, field) => ({
      ...acc,
      [field]: null
    }),
    {}
  )
};

const moduleName = "addModal";
const actionsPrefix = "constructing/counterparties/contractors/list/addModal";

const SET_ADD_MODAL_STATE = `${actionsPrefix}_SET_ADD_MODAL_STATE`;
const SET_VALUE = `${actionsPrefix}_SET_VALUE`;
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`;

export const stateSelector = createSelector(listStateSelector, (state) => state[moduleName]);
export const addModalOpenSelector = createSelector(stateSelector, (state) => state.addModalOpen);
export const valuesSelector = createSelector(stateSelector, (state) => state.values);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ADD_MODAL_STATE:
      return {
        ...state,
        addModalOpen: payload
      };
    case SET_VALUE:
      return {
        ...state,
        values: {
          ...state.values,
          ...payload
        }
      };
    case CLEAR_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export const setAddModalState = (data) => ({
  type: SET_ADD_MODAL_STATE,
  payload: data
});

export const setValue = (data) => ({
  type: SET_VALUE,
  payload: data
});

export const clearData = () => ({
  type: CLEAR_DATA
});

export const inviteContractor = (values) => (dispatch, getState) => {
  const params = {
    itn: values[FIELDS.ITN],
    email: values[FIELDS.EMAIL],
    from_type: "contractor"
  };

  axios.post("/partnership/invitations/", params)
    .then(() => {
      message.success("Приглашение успешно отправлено");
      dispatch(setAddModalState(false));
      dispatch(clearData());
    })
    .catch(errorCatcher);
};
