import { RootState } from "redux/rootReducer";
import { createSelector } from "reselect";
import { IServiceActsInitialState } from "./types";


export const stateSelector = (state: RootState): IServiceActsInitialState => state.serviceActs;
export const serviceActsListSelector = createSelector(stateSelector, state => state.serviceActsList);
export const serviceActSelector = createSelector(stateSelector, state => state.serviceAct);
export const isServiceActsListLoadingSelector = createSelector(stateSelector, state => state.isActsListLoading);
export const isServiceActLoadingSelector = createSelector(stateSelector, state => state.isActLoading);
export const serviceActFilesSelector = createSelector(stateSelector, state => state.files);