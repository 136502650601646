import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Moment } from "moment";

import { manufacturingMonthMarkersSelector } from "redux/modules/common/building/manufacturing/selectors";

import { IMonthArrayElement } from "../types";

import { useRem } from "./useRem";
import {useUnitMultiplier} from './useUnitMultiplier'
import {getMonthInfo} from '../utils'

export interface IUseCalendarScrollProps {
  calendarRef: React.RefObject<HTMLElement>;
  month: IMonthArrayElement;
  year: number | string;
  projectId?: number | string;
  setMonth: (newMonth: IMonthArrayElement | undefined) => void;
  setYear: (newYear: string) => void;
  unitMultiplier: number;
}

export const useCalendarScroll = ({
  calendarRef,
  month,
  year,
  projectId,
  setMonth,
  setYear,
}: IUseCalendarScrollProps) => {
  const unitMultiplier = useUnitMultiplier();

  const manufacturingMonthMarkers = useSelector(manufacturingMonthMarkersSelector);
  const { REM } = useRem();

  useEffect(() => {
    if (!calendarRef.current) return;
    calendarRef.current.scrollTop = 0;
  }, [projectId, calendarRef.current]);

  useEffect(() => {
    if (!calendarRef.current) return;

    const getMonthMarker = (scroll: number) => {
      if (!calendarRef.current) return;
      const marker: number[] = Array.from(manufacturingMonthMarkers);
      scroll += calendarRef.current.clientWidth * 0.25;
      scroll /= REM;
      marker.push(scroll);
      let markerIndex = marker.sort((a, b) => a - b).findIndex((i) => i === scroll);

      markerIndex--;

      if (markerIndex === -1) {
        markerIndex = 0;
      }

      return markerIndex;
    };

    const handleCalendarScroll = (e: Event) => {
      const activeIndex = getMonthMarker((e.target as HTMLElement).scrollLeft) || 0;
      setMonth(getMonthInfo(activeIndex));
    };

    calendarRef.current.addEventListener("scroll", handleCalendarScroll);
    return () => {
      calendarRef.current?.removeEventListener("scroll", handleCalendarScroll);
    };
  }, [calendarRef.current, manufacturingMonthMarkers]);

  useEffect(() => {
    if (!manufacturingMonthMarkers[month.id] || !calendarRef.current) return;
    calendarRef.current.scrollLeft = manufacturingMonthMarkers[month.id] * REM;
  }, [calendarRef.current, manufacturingMonthMarkers, REM]);

  const handleDateChange = (date: Moment) => {
    if (manufacturingMonthMarkers[date.month()] !== undefined && calendarRef.current) {
      calendarRef.current.scrollLeft = manufacturingMonthMarkers[date.month()] * REM;
    }
    date.month() !== month.id && setMonth(getMonthInfo(date.month()));
    date.year() !== +year && setYear(date.year().toString());
  };

  const scrollCalendar = (units: number) => {
    if (!calendarRef.current) return;
    calendarRef.current.scrollLeft += units * unitMultiplier * REM;
  };

  return {
    computedMonthMarkers: manufacturingMonthMarkers.map((x: number) => x * REM),
    handleDateChange,
    scrollCalendar,
  };
};
