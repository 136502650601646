import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { isTasksLoadingSelector, tasksbyBuildingSelector } from "redux/modules/purchaser/Tasks/selectors";
import { loadMoreTasksByBuilding, loadTasksByBuilding } from "redux/modules/purchaser/Tasks/thunks";
import { tasksListFilterType } from "redux/modules/purchaser/Tasks/types";
import { useTypedSelector } from "redux/typedUseSelector";
import { TASK_LISTS_TYPES } from "../constants";

type propsType = {
    objectId?: string;
    type?: TASK_LISTS_TYPES;
    filters: tasksListFilterType
}

export const useTasksData = ({
    objectId,
    type,
    filters
}: propsType) => {
    const dispatch = useDispatch()
    const tasksData = useTypedSelector(tasksbyBuildingSelector)
    const isTasksLoading = useTypedSelector(isTasksLoadingSelector)

    React.useEffect(() => {
        if (!objectId || !type || objectId === "0") return
        dispatch(loadTasksByBuilding(filters, type, objectId))
    }, [objectId, type, filters])

    const tasksByBuilding = React.useMemo(() => {
        if (!objectId || !type || objectId === "0") return null
        return tasksData?.[objectId]?.[type] || null
    }, [tasksData, objectId, type])

    const loadMoreTasksHandler = React.useCallback(() => {
        if (!tasksByBuilding?.results?.length) return
        dispatch(loadMoreTasksByBuilding(filters, type!, objectId!, tasksByBuilding?.results?.length))
    }, [tasksByBuilding?.results, filters, type, objectId])

    return {
        tasksByBuilding,
        isTasksLoading,
        loadMoreTasksHandler
    }
}