import React from "react";
import { Route, Switch } from "react-router-dom";

import Authorization from "./pages/Authorization/Authorization";
import RegisterConfirm from "./pages/Authorization/RegisterConfirm/RegisterConfirm";
import TasksLanding from "./pages/Tasks/TasksLanding/TasksLanding";
import SimplifiedRoutes from "./routes/components/SimplifiedRoutes/SimplifiedRoutes";

import MainTasks from "./MainTasks";
import ProtectedRoute from "./ProtectedRoute";

const AppTasks = () => (
  <Switch>
    <Route path="/auth" component={Authorization} />
    <Route path="/register/confirm/:token" component={RegisterConfirm} />
    <Route path="/simplified/:page/:userId" component={SimplifiedRoutes} />
    <ProtectedRoute path="/" component={MainTasks} SubstituteComponent={TasksLanding} />
  </Switch>
);

export default AppTasks;
