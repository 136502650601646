import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";

import useEscapeHandler from "../../../../../../utils/hooks/useEscapeHandler";
import arrowDownSelect from "../../../../../../images/icons/arrowDownSelect.svg";
import Icon from '../../../../../UI/Icon';

import styles from "./SelectStatus.module.scss";

const INITIAL_OPTIONS = [];

const getOptionById = (options, id) => options.find((option) => option.id === id);

const SelectStatus = (props) => {
  const {
    options = INITIAL_OPTIONS,
    value,
    placeholder = "Выберите",
    onChange,
    disabled,
    className,
    label,
    icon = arrowDownSelect,
  } = props;

  const selectRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [localValueId, setLocalValueId] = useState(value);
  const [availableOptions, setAvailableOptions] = useState(options.length && value ? options.filter(x => x.id !== value) : options)


  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  const handleOpening = useCallback(() => {
    if (disabled) return;
    setIsOpen((prevState) => !prevState);
  }, [disabled]);

  const selectOption = useCallback((optionId) => {
    if (onChange) onChange(optionId);
    setLocalValueId(optionId);
    setAvailableOptions(options.filter(x => x.id !== optionId))
    setIsOpen(false)
  }, [onChange]);

  useEscapeHandler(() => setIsOpen(false));

  return (
    <>
      {label && (
        <label>{label}</label>
      )}
      <div className={cn(styles.selectContainer, className)} ref={selectRef}>
        <div
          className={cn(styles.select, {
            [styles.isOpen]: isOpen && !disabled,
            [styles.disabled]: disabled
          })}
        >
        <span className={cn(styles.title)} onClick={() => onChange(localValueId)}>
          {localValueId ? getOptionById(options, localValueId)?.name : placeholder}
        </span>
        <div className={styles.iconContainer} onClick={handleOpening}>
          {!disabled && (
            <Icon
              icon={icon}
              className={cn(styles.arrow, { [styles.arrowReverse]: isOpen && !disabled })}
            />
          )}
        </div>
        </div>
        {isOpen && (
          <div
            className={styles.optionsBlock}
          >
            {availableOptions && availableOptions.map((option) => (
              <div
                className={cn(styles.option, { [styles.selected]: option.id === localValueId })}
                onClick={() => selectOption(option.id)}
                key={option.id}
              >
                <span className={styles.name}>{option.name}</span>
              </div>
            ))}
            {(!availableOptions || availableOptions.length === 0) && (
              <div className={styles.option}>Нет данных</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(SelectStatus);
