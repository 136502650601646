import React from "react";

import download from "../../../../images/download.svg";

import ButtonBase from "../ButtonBase";

import styles from "./DownloadButton.module.scss";

const DownloadButton = ({ className, onDownload }) => {
  return (
    <ButtonBase className={className} onClick={onDownload} quaternary>
      Скачать
      <img className={styles.downloadIcon} alt="Иконка скачать" src={download} />
    </ButtonBase>
  );
};

export default React.memo(DownloadButton);
