import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import TemplateBase from "components/UI/templates/TemplateBase";
import { TableHeader } from "components/UI/TotoRowTable/TableHeader/TableHeader";
import {
  addLoadDocumentPayment,
  DEFAULT_LIMIT_PAYMENT,
  loadingStateSelector,
  loadPaymentV2,
  paymentSelector
} from "redux/modules/purchaser/payments";
import { entityIdSelector } from "redux/modules/common/auth";
import SearchFilterTable from "../../UI/atoms/SearchFilterTable"
import SearchRangeDateFilter from "components/UI/atoms/SearchRangeDateFilter";
import SearchSelectFilter from "components/UI/atoms/SearchSelectFilter";
import { statusTypeArray } from "utils/constant";
import SearchFilterSum from "components/UI/atoms/SearchFilterSum";
import { transformDigitToFinancial } from "../../../utils/transformDigitToFinancial";
import styles from "./index.module.scss";
import Clip from "images/icons/Clip";
import { TableOslaLocal } from "components/UI/TableOslaCustom/TableOslaLocal";
import { Events } from "../Calendar/Events/Events";
import { NavLinkIcon } from "../../UI/NavLinkIcon/NavLinkIcon";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import CalendarEvents from "images/icons/navigation/CalendarEvents";

class Payments extends Component {
  state = {
    filters: null,
    limit: DEFAULT_LIMIT_PAYMENT
  };

  componentDidMount() {
    this.props.loadPaymentV2(null, { limit: DEFAULT_LIMIT_PAYMENT, offset: 0 });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.filters !== this.state.filters) {
      this.props.loadPaymentV2(null, null, null, this.state.filters, null);
    }
  }

  handleAdd = () => {
    this.props.addLoadDocumentPayment(
      null,
      {
        limit: DEFAULT_LIMIT_PAYMENT,
        offset: this.props.payment?.results.length
      },
      this.state.filters
    );
  };
  onRowClick = (row) => {
    const { history, match, location } = this.props;
    let formattedPathName = location.pathname.split("/")[1];
    if (formattedPathName === "objects") {
      history.push(`/${formattedPathName}/${match.params.objectId}/documents/bills/${row.invoice}`);
    } else {
      history.push(`/${formattedPathName}/documents/bills/${row.invoice}`);
    }
  };

  changeFilters = (value, name) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [name]: value
      }
    }));
  };
  hasNextPage = () => this.props.payment?.count > this.props.payment?.results.length;

  render() {
    const { payment, loadPaymentV2, loadingState, entityId, history, isPurchaser } = this.props;

    const { filters } = this.state;

    return (
      <div style={{ position: "relative" }}>
        <div className={styles.overFlowLine}></div>
        <TemplateBase>
          <div className={styles.stickyTabs}>
            <Events />
          </div>
          <TableOslaLocal
            data={payment?.results}
            onRowClick={this.onRowClick}
            dataLoader={loadPaymentV2}
            paginationHide={true}
            dataLoaderArguments={[entityId]}
            dataLoaderFilters={filters}
            isLoading={loadingState}
            rowClass={styles.tableRowClass}
            headerClass={styles.tableHeaderClass}
            tableClass={styles.tables}
            emptyPlaceholder={<EmptyPlaceholder svgJsx={<CalendarEvents />} />}
          >
            <TableHeader data={"invoice_number"} formatter={(cell) => cell || "Ожидается"} cellStyle={{ width: "10%" }}>
              <div className={styles.titleBlock}>
                <div className={styles.title}>№</div>
                <SearchFilterTable
                  title="Счет"
                  setFilter={(value) => {
                    this.changeFilters(value, "invoice_number");
                  }}
                />
              </div>
            </TableHeader>
            <TableHeader
              data={"provider"}
              formatter={(cell) => cell?.name}
              cellStyle={{
                width: "22%"
              }}
            >
              <div className={styles.titleBlock}>
                <div className={styles.title}>Получатель</div>
                <SearchFilterTable
                  title="Получатель"
                  setFilter={(value) => {
                    this.changeFilters(value, "provider");
                  }}
                />
              </div>
            </TableHeader>
            <TableHeader data={"amount"} formatter={(cell) => transformDigitToFinancial(cell)}
              cellStyle={{ width: "15%", textAlign: "right", paddingRight: "2.5rem" }}>
              <div className={styles.titleBlockSum}>
                <div className={styles.title}> Сумма ₽</div>
                <SearchFilterSum
                  title="Сумма ₽"
                  setFilterStart={(value) => {
                    this.changeFilters(value, "amount_min");
                  }}
                  setFilterEnd={(value) => {
                    this.changeFilters(value, "amount_max");
                  }}
                />
              </div>
            </TableHeader>
            <TableHeader
              data={"payment_date"}
              cellStyle={{ width: "9%" }}
              formatter={(cell) => (cell ? moment(cell).format("DD.MM.YY") : "")}
            >
              <div className={styles.titleBlock}>
                <div className={styles.title}>Платеж</div>
                <SearchRangeDateFilter
                  title="Платеж"
                  setFilterStart={(value) => {
                    this.changeFilters(moment(value).format("YYYY-MM-DD"), "payment_date_after");
                  }}
                  setFilterEnd={(value) => {
                    this.changeFilters(moment(value).format("YYYY-MM-DD"), "payment_date_before");
                  }}
                />
              </div>
            </TableHeader>
            <TableHeader
              data={"paid"}
              formatter={(cell) => (cell ? "Оплачен" : "Ожидает оплаты")}
              cellStyle={{
                width: '10%'
              }}
            >
              <div className={styles.titleBlock}>
                <div className={styles.title}> Статус</div>
                <SearchSelectFilter
                  filterName={statusTypeArray()}
                  title="Статус"
                  setFilter={(value) => {
                    this.changeFilters(value, "paid");
                  }}
                />
              </div>
            </TableHeader>
            <TableHeader
              data={'file'}
              cellStyle={{ width: "8%", textAlign: 'center' }}
              formatter={
                (cell) =>
                  cell ? (
                    <a
                      href={cell}
                      download={true}
                      className={styles.documents}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Clip width={"0.5rem"} color={"#000"} /> 1
                    </a>
                  ) : (
                    <a className={styles.documents}>
                      <Clip width={"0.5rem"} color={"#000"} /> 0
                    </a>
                  )
              }
            >
              Документ
            </TableHeader>
            <TableHeader
              data={"purchaser"}
              formatter={(cell) => cell?.name}
              cellStyle={{
                width: '14%'
              }}
            >
              <div className={styles.titleBlock}>
                <div className={styles.title}> Заказчик</div>
                <SearchFilterTable
                  title=" Заказчик"
                  setFilter={(value) => {
                    this.changeFilters(value, "purchaser");
                  }}
                />
              </div>
            </TableHeader>
            <TableHeader
              data={"packinglist"}
              cellStyle={{ width: "12%" }}
              style={{ textAlign: "left" }}
              formatter={(cell) => cell && (
                <NavLinkIcon
                  className={styles.link}
                  variant="tertiary"
                  to={`/purchases/documents/packing-lists/${cell.id}`}
                  title={`№ ${cell.number || "Ожидается"}`}
                />
              )}
            >
              <div>УПД</div>
            </TableHeader>

          </TableOslaLocal>
          {this.hasNextPage() && (
              <ShowMoreButton 
                isExists={!loadingState}
                handleAdd={this.handleAdd}
                showedCount={this.props.payment?.results?.length}
                allCount={this.props.payment?.count}
              />
          )}
        </TemplateBase>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    entityId: entityIdSelector(state),
    payment: paymentSelector(state),
    loadingState: loadingStateSelector(state),
    isPurchaser: state.auth.entity.type === "pcr"
  }),
  { loadPaymentV2, addLoadDocumentPayment }
)(Payments);

// export default Payments;
