import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";

import { IList } from "types/ListResponse";
import { IRouterParamsWithObjectId } from "types/routerTypes";

interface IPlanSection {
  start_at: string;
  end_at: string;
}

export const useSectionPlan = (sectionId: number, isOpen?: boolean) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const [planData, setPlanData] = React.useState<null | IPlanSection>(null);

  React.useEffect(() => {
    refetchSectionPlan();
  }, [objectId, sectionId, isOpen]);

  const refetchSectionPlan = React.useCallback(() => {
    if (!isOpen) return;
    axios
      .get<IList<IPlanSection>>(
        `/building/${objectId}/estimate/sections/plan-intervals/`,
        {
          params: { section_id: sectionId },
        }
      )
      .then(({ data }) => {
        if (data.results?.[0]) {
          setPlanData(data.results[0]);
        }
      });
  }, [objectId, sectionId, isOpen]);

  return { planData, refetchSectionPlan };
};
