import React, { Fragment } from "react";
import moment from "moment";
import cn from "classnames";

import { DAYS_ABBRS } from "../../../../constants";

import styles from "./WeeksLine.module.scss";

import { ICalendarDateLineProps } from "../../CalendarDateLine";

import { generateWeeksForLine } from "../../../../utils";
import {useUnitMultiplier} from '../../../../hooks/useUnitMultiplier'

const WeeksLine = React.forwardRef<HTMLDivElement, ICalendarDateLineProps>(({ year }, ref) => {
  const unitMultiplier = useUnitMultiplier()
  const weeks = generateWeeksForLine(+year);
  return (
    <div className={styles.weeksLine} ref={ref}>
      {weeks?.map((item, index) => {
        const today = moment().isBetween(moment(item.week.day(0)), moment(item.week.day(6)))
          ? moment().weekday()
          : undefined;
        return (
          <Fragment key={index}>
            <div
              className={cn(styles.weekCaptionBlock, {
                startMonth: item.isNewMonth,
              })}
              style={{ left: `${index * 7 * unitMultiplier}rem`, width: `${7 * unitMultiplier}rem` }}
            >
              <span>Неделя {index + 1}</span>
              <span className={styles.weekInterval}>
                {item.week.weekday(0).format("DD.MM")}-{item.week.weekday(6).format("DD.MM")}
              </span>
            </div>
            {today !== undefined && (
              <div
                className={styles.currentWeekDay}
                style={{
                  left: `${(index * 7 + today) * unitMultiplier}rem`,
                }}
              >
                <span>{DAYS_ABBRS[today]}</span>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
});
export default React.memo(WeeksLine);
