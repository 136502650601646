import InputNumber from 'components/UI/atoms/InputNumber'
import CalendarRange from 'components/UI/molecules/CalendarRange'
import DayWorkerAndEvent from 'components/UI/organism/WorkOrMaterialsModals/components/DayWorkerAndEvent/DayWorkerAndEvent'
import React from 'react'
import { Iemployee, personFullNameWithId } from 'types/personsTypes'
import styles from "./PlanEditing.module.scss"
import ExpandableFilesInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput"
import AddWorkersInModal from 'components/UI/organism/AddingListInModal/AddWorkersInModal';
import { useSelectingWorkers } from 'components/UI/organism/AddingListInModal/hooks/useSelectingWorkers';
import AddPlanMaterialsInModal from 'components/UI/organism/AddingListInModal/AddPlanMaterialsInModal';
import { useSelectingMaterials } from 'components/UI/organism/AddingListInModal/hooks/useSelectingMaterials';
import Button from 'components/UI/atoms/ButtonBase';
import { usePlanEdit } from './usePlanEdit'
import BottomControls from 'components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls'
import { IMaterialInPlanInterval } from 'types/interfaces/Materials'

interface Iprops {
    planCreatingDate?: string,
    planCreator?: personFullNameWithId,
    productId: number,
    planDateStart: string,
    planDateEnd: string,
    planCount: number,
    defaultMaterials: IMaterialInPlanInterval[],
    defaultWorkers: Iemployee[],
    onClose: () => void;
    buildingId: number,
    intervalId: number,
    files: unknown[],
    onUploadFiles: (file: File) => void;
    onDeleteFiles: (id: number) => void;
    defaultWorkersCounts: { [key: string]: number }
}

function PlanEditing({
    planCreatingDate,
    planCreator,
    productId,
    planDateEnd,
    planDateStart,
    planCount,
    defaultMaterials,
    defaultWorkers,
    onClose,
    buildingId,
    intervalId,
    files,
    onDeleteFiles,
    onUploadFiles,
    defaultWorkersCounts
}: Iprops) {
    const {
        confirmHandler,
        cancelHandler,
        changeCountHandler,
        validateSubmitting,
        deleteAddedHandler,
        selectHandler,
        addedUsers,
        selectedUsers,
        selectedUsersIds,
        addedUsersIds,
        clearWorkers
    } = useSelectingWorkers(defaultWorkers, defaultWorkersCounts)

    const {
        confirmMaterialsHandler,
        cancelMaterialsHandler,
        changeCountMaterialsHandler,
        validateSubmittingMaterials,
        deleteAddedHandler: deleteAddedMaterial,
        selectMaterialsHandler,
        addedMaterials,
        selectedMaterials,
        selectedMaterialsIds,
        addedMaterialsIds,
        clearMaterials
    } = useSelectingMaterials(defaultMaterials, true)

    const {
        count,
        setCount,
        dateEnd,
        dateStart,
        setDateEnd,
        setDateStart,
        submitHandler
    } = usePlanEdit({
        planCount,
        planDateEnd,
        planDateStart,
        addedUsers,
        addedMaterials,
        expenditureId: productId,
        buildingId,
        intervalId,
        onClose,
        previousMaterials: defaultMaterials
    })

    return (
        <>
            <div className={styles.container}>
                <DayWorkerAndEvent date={planCreatingDate!} author={planCreator!} label="Создал:" />
                <div className={styles.content}>
                    <div className={styles.hiddenWrapper}>
                        <div className={styles.label}>Количество</div>
                        <div className={styles.fieldValue}>{/* @ts-ignore */}
                            <InputNumber value={count} setValue={setCount} decimalPlace="4" />
                        </div>
                        <div className={styles.label}>Укажите период </div>
                        <div className={styles.fieldRow}>
                            <div className={styles.fieldCalendar}>{/* @ts-ignore */}
                                <CalendarRange
                                    defaultDateStart={dateStart}
                                    setDefaultDateStart={setDateStart}
                                    defaultDateEnd={dateEnd}
                                    setDefaultDateEnd={setDateEnd}
                                />
                            </div>
                        </div>
                        <AddWorkersInModal
                            addedUsers={addedUsers}
                            selectedUsers={selectedUsers}
                            onAccept={confirmHandler}
                            onDecline={cancelHandler}
                            onChangeCount={changeCountHandler}
                            onSelect={selectHandler}
                            selectedIds={selectedUsersIds}
                            onDelete={deleteAddedHandler}
                            addedIds={addedUsersIds}
                        />
                        <AddPlanMaterialsInModal
                            addedMaterials={addedMaterials}
                            selectedUsers={selectedMaterials}
                            onAccept={confirmMaterialsHandler}
                            onDecline={cancelMaterialsHandler}
                            onChangeCount={changeCountMaterialsHandler}
                            onSelect={selectMaterialsHandler}
                            selectedIds={selectedMaterialsIds}
                            onDelete={deleteAddedMaterial}
                            addedIds={addedMaterialsIds}
                            expenditureId={productId}
                            wholeWorkCount={count}
                        />
                        <ExpandableFilesInput
                            files={files}
                            canExtractFiles={false}
                            onRemoveFileDirectly={onDeleteFiles}
                            onUploadFileDirectly={onUploadFiles}
                        />
                    </div>
                </div>
                <BottomControls isExists>
                    <Button medium secondary disabled={false} onClick={onClose}>
                        Отменить
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button medium primary onClick={() => validateSubmittingMaterials(() => validateSubmitting(submitHandler))} disabled={!count}>
                        Сохранить
                    </Button>
                </BottomControls>
            </div>
        </>
    )
}

export default PlanEditing