import axios from "axios";
import { message } from "antd";
import { createSelector } from "reselect";
import { compose } from "redux";

import { errorCatcher } from "../../../../utils/errorCatcher";

import { approveStockIncoming, approveStockOutgoing, getStockEventRequest } from "./stocksApi";

const moduleName = "stocks";
const LOAD_STOCKS = `${moduleName}/LOAD_STOCKS`;
const SET_STOCKS = `${moduleName}/SET_STOCKS`;
const LOAD_USING = `${moduleName}/LOAD_USING`;
const SET_USING = `${moduleName}/SET_USING`;
const ADD_USING = `${moduleName}/ADD_USING`;
const LOAD_REPLENISHMENT = `${moduleName}/LOAD_REPLENISHMENT`;
const SET_REPLENISHMENT = `${moduleName}/SET_REPLENISHMENT`;
const ADD_REPLENISHMENT = `${moduleName}/ADD_REPLENISHMENT`;
const LOAD_SAVE = `${moduleName}/LOAD_SAVE`;
const SET_SAVE = `${moduleName}/SET_SAVE`;
const LOAD_STOCK_DETAIL = `${moduleName}/LOAD_STOCK_DETAIL`;
const SET_STOCK_DETAIL = `${moduleName}/SET_STOCK_DETAIL`;
const LOAD_STOCK_PRODUCTS = `${moduleName}/LOAD_STOCK_PRODUCTS`;
const SET_STOCK_PRODUCTS = `${moduleName}/SET_STOCK_PRODUCTS`;
const ADD_STOCK_PRODUCTS = `${moduleName}/ADD_STOCK_PRODUCTS`;
const CHANGE_STOCK_BINDING = `${moduleName}/CHANGE_STOCK_BINDING`;
const SET_STOCKS_MEASURE = `${moduleName}/SET_STOCKS_MEASURE`;
const SET_STOCK_MOVEMENT_APPROVED = `${moduleName}/SET_STOCK_MOVEMENT_APPROVED`;

const SET_STOCK_EVENT_DATA = `${moduleName}/SET_STOCK_EVENT_DATA`;
const SET_STOCK_EVENT_LOADING = `${moduleName}/SET_STOCK_EVENT_LOADING`;

const initialState = {
  stocks: null,
  isLoading: true,
  loadingSave: false,
  stockDetail: null,
  stockDetailIsLoading: true,
  products: null,
  productsIsLoading: true,
  using: null,
  usingIsLoading: true,
  replenishments: null,
  replenishmentsIsLoading: true,
  measure: 1000,
  stockEvent: {
    data: null,
    isLoading: true,
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_STOCKS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_STOCKS:
      return {
        ...state,
        stocks: payload,
        isLoading: false,
      };
    case LOAD_USING:
      return {
        ...state,
        usingIsLoading: true,
      };
    case SET_USING:
      return {
        ...state,
        using: payload,
        usingIsLoading: false,
      };
    case ADD_USING:
      return {
        ...state,
        using: {
          count: payload.count,
          results: [...state.using.results, ...payload.results],
        },
      };
    case LOAD_REPLENISHMENT:
      return {
        ...state,
        replenishmentsIsLoading: true,
      };
    case SET_REPLENISHMENT:
      return {
        ...state,
        replenishments: payload,
        replenishmentsIsLoading: false,
      };
    case ADD_REPLENISHMENT:
      return {
        ...state,
        replenishments: {
          count: payload.count,
          results: [...state.replenishments.results, ...payload.results],
        },
      };
    case LOAD_STOCK_DETAIL:
      return {
        ...state,
        stockDetailIsLoading: true,
      };
    case SET_STOCK_DETAIL:
      return {
        ...state,
        stockDetail: payload,
        stockDetailIsLoading: false,
      };
    case LOAD_STOCK_PRODUCTS:
      return {
        ...state,
        productsIsLoading: true,
      };
    case SET_STOCK_PRODUCTS:
      return {
        ...state,
        products: payload,
        productsIsLoading: false,
      };
    case ADD_STOCK_PRODUCTS:
      return {
        ...state,
        products: {
          count: payload.count,
          results: [...state.products.results, ...payload.results],
        },
      };
    case LOAD_SAVE:
      return {
        ...state,
        loadingSave: true,
      };
    case SET_SAVE:
      return {
        ...state,
        loadingSave: false,
      };
    case CHANGE_STOCK_BINDING:
      return {
        ...state,
        products: {
          ...state.products,
          results: [...state.products.results],
        },
      };
    case SET_STOCKS_MEASURE:
      return {
        ...state,
        measure: payload,
      };
    case SET_STOCK_MOVEMENT_APPROVED:
      return {
        ...state,
        replenishments: {
          count: state.replenishments?.count,
          results: (state.replenishments?.results || []).map((el) => {
            if (el.id == payload) {
              return { ...el, status: "Подтвержден" };
            } else {
              return el;
            }
          }),
        },
      };
    case SET_STOCK_EVENT_DATA:
      return { ...state, stockEvent: { ...state.stockEvent, data: payload } };
    case SET_STOCK_EVENT_LOADING:
      return { ...state, stockEvent: { ...state.stockEvent, isLoading: payload } };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const stocksSelector = createSelector(stateSelector, (state) => state.stocks);
export const stocksLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const usingSelector = createSelector(stateSelector, (state) => state.using);
export const usingLoadingSelector = createSelector(stateSelector, (state) => state.usingIsLoading);
export const replenishmentsSelector = createSelector(stateSelector, (state) => state.replenishments);
export const replenishmentsLoadingSelector = createSelector(stateSelector, (state) => state.replenishmentsIsLoading);
export const stocksLoadingSaveSelector = createSelector(stateSelector, (state) => state.loadingSave);
export const stockDetailSelector = createSelector(stateSelector, (state) => state.stockDetail);
export const stockDetailLoadingSelector = createSelector(stateSelector, (state) => state.stockDetailIsLoading);
export const stockProductsSelector = createSelector(stateSelector, (state) => state.products);
export const stockProductsLoadingSelector = createSelector(stateSelector, (state) => state.productsIsLoading);
export const stockMeasureSelector = createSelector(stateSelector, (state) => state.measure);

export const stockEventDataSelector = createSelector(stateSelector, (state) => state.stockEvent.data);
export const stockEventLoadingSelector = createSelector(stateSelector, (state) => state.stockEvent.isLoading);

export const setStocksMeasure = (measure) => ({
  type: SET_STOCKS_MEASURE,
  payload: measure,
});

export const loadStocks = (() => {
  return (paginationParams, filterParams, sorting = null) => {
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: sorting,
      },
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_STOCKS,
      });
      axios
        .get(`/stocks/`, config)
        .then((response) =>
          dispatch({
            type: SET_STOCKS,
            payload: response.data,
          })
        )
        .catch((e) => console.error(e));
    };
  };
})();

export const createStock = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (data, callback = null, errorCallback = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_SAVE,
      });
      axios
        .post(`/stocks/`, data, config)
        .then(
          (response) => {
            dispatch({
              type: SET_SAVE,
            });
            message.success("Склад успешно создан");
            callback && callback();
          },
          (error) => errorCallback && errorCallback(error.response.data)
        )
        .catch((e) => console.error(e));
    };
  };
})();

export const saveStock = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, data, errorCallback = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_SAVE,
      });
      axios
        .patch(`/stocks/${id}/`, data, config)
        .then(
          (response) => {
            dispatch({
              type: SET_SAVE,
            });
            message.success("Изменения успешно сохранены");
          },
          (error) => errorCallback && errorCallback(error.response.data)
        )
        .catch((e) => console.error(e));
    };
  };
})();

export const deleteStock = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, callback = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios
        .delete(`/stocks/${id}/`, config)
        .then((response) => {
          message.success("Склад успешно удален");
          callback && callback();
        })
        .catch((e) => console.error(e));
    };
  };
})();

export const loadStockDetail = (() => {
  return (id) => {
    const config = {};

    return (dispatch) => {
      dispatch({
        type: LOAD_STOCK_DETAIL,
      });
      axios
        .get(`/stocks/${id}/`, config)
        .then((response) =>
          dispatch({
            type: SET_STOCK_DETAIL,
            payload: response.data,
          })
        )
        .catch((error) => console.error(error));
    };
  };
})();

export const loadStockProducts = (() => {
  let ld_cancel;
  return (entityId, paginationParams, filterParams = null) => {
    if (ld_cancel) ld_cancel();
    const config = { params: { ...paginationParams, ...filterParams } };
    return (dispatch) => {
      axios
        .get(`/stocks/${entityId}/products/`, config)
        .then((response) =>
          dispatch({
            type: SET_STOCK_PRODUCTS,
            payload: response.data,
          })
        )
        .catch((e) => console.error(e));
    };
  };
})();
export const addStockProducts = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, paginationParams, filterParams = null, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        count_min: 0.0001,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios
        .get(`/stocks/${entityId}/products/`, config)
        .then((response) =>
          dispatch({
            type: ADD_STOCK_PRODUCTS,
            payload: response.data,
          })
        )
        .catch((e) => console.error(e));
    };
  };
})();

export const createUsing = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, data, callback = null, errorCallback = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios
        .post(`/stocks/${id}/using/`, data, config)
        .then((response) => {
          dispatch({ type: SET_SAVE });
          message.success("Списание успешно произведено");
          callback?.();
        })
        .catch(errorCatcher);
    };
  };
})();

export const loadUsing = (() => {
  return (entityId, paginationParams, filterParams = null, sorting = null) => {
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
      },
    };

    return (dispatch) => {
      axios
        .get(`/stocks/${entityId}/using_expenditures/`, config)
        .then((response) =>
          dispatch({
            type: SET_USING,
            payload: response.data,
          })
        )
        .catch((error) => console.log(error));
    };
  };
})();
export const addUsing = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, paginationParams, filterParams = null, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };
    return (dispatch) => {
      axios
        .get(`/stocks/${entityId}/using_expenditures/`, config)
        .then((response) =>
          dispatch({
            type: ADD_USING,
            payload: response.data,
          })
        )
        .catch((e) => console.error(e));
    };
  };
})();

export const loadReplenishments = (() => {
  return (id, paginationParams, filterParams = null, sorting = null) => {
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
      },
    };

    return (dispatch) => {
      axios
        .get(`/stocks/${id}/events/`, config)
        .then((response) =>
          dispatch({
            type: SET_REPLENISHMENT,
            payload: response.data,
          })
        )
        .catch((error) => console.error(error));
    };
  };
})();

export const addReplenishments = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;
  return (id, paginationParams, filterParams = null, sorting = null) => {
    if (gu_cancel) gu_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
      },
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      }),
    };

    return (dispatch) => {
      axios
        .get(`/stocks/${id}/events/`, config)
        .then(
          (res) => {
            dispatch({
              type: ADD_REPLENISHMENT,
              payload: res.data,
            });
          },
          (err) => {
            console.error(err);
          }
        )
        .catch((e) => console.error(e));
    };
  };
})();

export const approveStockMovement =
  ({ stockId, stockUsing, packingList, payload, itemId }) =>
  async (dispatch) => {
    try {
      if (packingList) {
        await approveStockIncoming(packingList, payload);
      } else if (stockUsing) {
        await approveStockOutgoing(stockId, stockUsing, payload);
      } else {
        return;
      }

      dispatch({
        type: SET_STOCK_MOVEMENT_APPROVED,
        payload: itemId,
      });
    } catch (e) {
      errorCatcher(e);
    }
  };

const setStockEventDataAction = (payload) => ({ type: SET_STOCK_EVENT_DATA, payload });
const setStockEventLoadingAction = (payload) => ({ type: SET_STOCK_EVENT_LOADING, payload });

export const getStockEvent = (stockId, eventId) => async (dispatch) => {
  compose(dispatch, setStockEventLoadingAction)(true);
  await getStockEventRequest(stockId, eventId).then((data) => compose(dispatch, setStockEventDataAction)(data));
  compose(dispatch, setStockEventLoadingAction)(false);
};
