import React, { useCallback, useEffect, useState } from "react";
import { message } from "antd";

import {
  apiLoadBuildingMaterialSection,
  apiLoadBuildingMaterialSections,
} from "../../../../redux/modules/common/building/sections/sectionsApi";
import { Header, Tab } from "components/UI/atoms/Tabs";
import SliderModal from "components/UI/atoms/SliderModal";

import { Spinner } from "../../Spinner/Spinner";

import Materials from "./components/Materials";
import NewProductForm from "./components/NewProductForm";

import styles from "./index.module.scss";

const getSubsectionsForSections = async (sections, objectId) => {
  const sectionsWithSubsections = [];
  for (const section of sections) {
    const sectionWithSubsections = await apiLoadBuildingMaterialSection(objectId, section.id, "new_product");
    sectionsWithSubsections.push(sectionWithSubsections);
  }
  return sectionsWithSubsections;
};

const AddProductsModal = ({ isOpen, objectId, closeAddProductsBlock, addProducts, permissions }) => {
  const [tab, setTab] = useState("materials");
  const [materialSections, setMaterialSections] = useState();
  const [servicesSections, setServicesSections] = useState()
  const [outEstimateSections, setOutEstimateSections] = useState();
  const [newMaterialSections, setNewMaterialSections] = useState();
  const [products, setProducts] = useState([]);

  const haveSomeAddPermission = permissions.viewAddAdditionalProducts || permissions.viewAddOutOfEstimateProducts;

  const loadSection = useCallback(
    async (objectId) => {
      const materialSection = await apiLoadBuildingMaterialSections(objectId);
      setMaterialSections(materialSection);

      const servicesSection = await apiLoadBuildingMaterialSections(objectId, "services");
      setServicesSections(servicesSection);

      const outEstimateSection = await apiLoadBuildingMaterialSections(objectId, "out-of-estimate");
      setOutEstimateSections(outEstimateSection);

      if (haveSomeAddPermission) {
        const newMaterialSection = await apiLoadBuildingMaterialSections(objectId, "new_product");
        getSubsectionsForSections(newMaterialSection, objectId).then(setNewMaterialSections);
      } else {
        setNewMaterialSections([]);
      }
    },
    [haveSomeAddPermission]
  );

  const handleAddProducts = (newProduct) => {
    const addedProducts = newProduct || products;
    if (addedProducts.some((product) => !product.required_count || +product.required_count <= 0)) {
      message.error("Введите кол-во выбранных материалов");
      return;
    }

    addProducts(addedProducts);
    closeAddProductsBlock();
    setProducts([]);
  };

  const changeSetProducts = (val) => {
    setProducts([...products, val]);
  };

  useEffect(() => {
    if (!objectId || (materialSections && outEstimateSections && newMaterialSections)) return;
    loadSection(objectId);
  }, [objectId, loadSection]);

  return (
    <SliderModal isOpen={isOpen} closeHandler={closeAddProductsBlock} className={styles.slider}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Header>
            <Tab name="materials" title="Материалы" setTab={setTab} activeTab={tab} className={styles.tab} />
            <Tab name="services" title="Услуги" setTab={setTab} activeTab={tab} className={styles.tab} />
            <Tab name="outEstimate" title="Вне сметы" setTab={setTab} activeTab={tab} className={styles.tab} />
            {haveSomeAddPermission && (
              <Tab name="newProduct" title="Новый товар" setTab={setTab} activeTab={tab} className={styles.tab} />
            )}
          </Header>
        </div>
        {materialSections && outEstimateSections && newMaterialSections ? (
          <div className={styles.content}>
            {tab === "materials" && (
              <Materials
                sections={materialSections}
                objectId={objectId}
                handleAddProducts={handleAddProducts}
                products={products}
                setProducts={setProducts}
                type="for-requisition"
              />
            )}
            {tab === "services" && (
              <Materials
                sections={servicesSections}
                objectId={objectId}
                handleAddProducts={handleAddProducts}
                products={products}
                setProducts={setProducts}
                type="services"
              />
            )}
            {tab === "outEstimate" && (
              <Materials
                sections={outEstimateSections}
                objectId={objectId}
                handleAddProducts={handleAddProducts}
                changeSetProducts={changeSetProducts}
                products={products}
                setProducts={setProducts}
                type="out-of-estimate"
              />
            )}
            {tab === "newProduct" && (
              <>
                <NewProductForm
                  sections={newMaterialSections}
                  objectId={objectId}
                  handleAddProducts={handleAddProducts}
                  permissions={permissions}
                />
                <Materials
                  products={products}
                  setProducts={setProducts}
                  sections={newMaterialSections}
                  objectId={objectId}
                  handleAddProducts={handleAddProducts}
                  changeSetProducts={changeSetProducts}
                  permissions={permissions}
                  type="new_product"
                />
              </>
            )}
          </div>
        ) : (
          <Spinner isFixed />
        )}
      </div>
    </SliderModal>
  );
};

export default React.memo(AddProductsModal);
