import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SliderModal from "components/UI/atoms/SliderModal";
import { TableOslaLocal } from "components/UI/TableOslaCustom/TableOslaLocal";
import { TableHeader } from "components/UI/TotoRowTable/TableHeader/TableHeader";
import {
  loadLog,
  logSelector
} from "redux/modules/common/building/log";

import { localizeServerDateInUserTimezone } from "../../../../../utils/localizeServerDateInUserTimezone";

import styles from "./index.module.scss";
import useEscapeHandler from '../../../../../utils/hooks/useEscapeHandler'

const Logs = ({ requisitionId, isOpen, closeLogsBlock }) => {
  const dispatch = useDispatch();
  const log = useSelector(logSelector);

  useEffect(() => {
    if (!isOpen) return;
    dispatch(loadLog(requisitionId));
  }, [isOpen]);

  useEscapeHandler(closeLogsBlock)

  return (
    <SliderModal
      isOpen={isOpen}
      closeHandler={closeLogsBlock}
    >
      <div className={styles.sliderTitle}>История действий</div>
      <div className={styles.logList}>
        <TableOslaLocal
          data={log?.results}
          paginationHide
        >
          <TableHeader cellStyle={{ width: '10%' }} numberGeneration={"reverse"} dataLength={log?.count}>№</TableHeader>
          <TableHeader cellStyle={{ width: '40%' }} data={"action_name"}>Название</TableHeader>
          <TableHeader
            cellStyle={{ width: '25%' }}
            data={"name"}
            formatter={cell => `${cell.last_name} ${cell.first_name}`}
          >
            Пользователь
          </TableHeader>
          <TableHeader
            cellStyle={{ width: '25%' }}
            data={"datetime"}
            formatter={(cell) => localizeServerDateInUserTimezone(cell, "DD.MM.YY HH:mm")}
          >
            Время
          </TableHeader>
        </TableOslaLocal>
      </div>
    </SliderModal>
  );
};

export default Logs;