import axios from "axios";
import { message } from "antd";
import { createSelector } from "reselect";

import { errorCatcher } from "utils/errorCatcher";


const moduleName = "users";
const SET_USER = `${moduleName}/SET_USER`;
const UPDATE_USERS = `${moduleName}/UPDATE_USERS`;
const SET_ROLES = `${moduleName}/SET_ROLES`;
const SET_IS_SUCCESS = `${moduleName}/SET_IS_SUCCESS`;

const initialState = { 
  user: {}, 
  roles: null,
  isSuccess: false,
};

export default function reducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_USER:
      return { 
        ...state, 
        user: { ...payload },
        isSuccess: true,
      };
    case SET_ROLES:
      return { 
        ...state, 
        rolesSelectorList: payload 
      };
    case SET_IS_SUCCESS:
      return { 
        ...state, 
        isSuccess: payload 
      };
    default:
      return state;
  }
}

export const stateSelector = (state) => state.userRedux;
export const rolesSelector = createSelector(stateSelector, (state) => state.rolesSelectorList);
export const userSelector = createSelector(stateSelector, (state) => state.user);
export const userSuccessSelector = createSelector(stateSelector, (state) => state.isSuccess);

export const setRolesSelectorList = (data) => {
  return {
    type: SET_ROLES,
    payload: data,
  };
};

export const setUser = (value) => {
  return (dispatch) =>
    dispatch({
      type: SET_USER,
      payload: value,
    });
};

export const setIsSuccess = (data) => {
  return {
    type: SET_IS_SUCCESS,
    payload: data,
  };
};

export const createUser = (data) => (dispatch) => {
  axios
    .post("/users/", data)
    .then(({ data }) => dispatch(setUser(data)))
    .catch(errorCatcher);
};


export const updateUser = (id, data) => (dispatch) => {
  return axios
    .put(`/users/${id}/`, data)
    .then(({ data }) => {
      dispatch({ type: UPDATE_USERS });
      message.success("Пользователь успешно обновлен");
      return data
    })
    .catch(errorCatcher);
};

export const getRoles = (() => {
  return () => {
    return (dispatch) => {
      axios
        .get(`/roles/`)
        .then((res) => dispatch(setRolesSelectorList(res.data)))
        .catch(errorCatcher);
    };
  };
})();

export const resetPasswordId = (id, data) => {
  return axios.post(`/users/${id}/reset-password/`, data).catch(errorCatcher);
};
