import React, { useMemo, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { compose } from "redux";
import { useDispatch } from "react-redux";

import { checkUserHavePermission } from "../../../../redux/modules/common/permissions/thunks/checkUserHavePermission";

import { ProfilePage } from "../../../UI/molecules/Header/components/Profile/ProfilePage/ProfilePage";
import Manufacturing from "../../../pages/Manufacturing/Manufacturing";
import ConstructingFinance from "../../../pages/Constructing/Finance";
import ConstructingObjects from "../../../pages/Constructing/Objects";
import Building from "../../../pages/Building/Building";
import ConstructingCounterparties from "../../../pages/Constructing/Counterparties";
import Handler from "../../../pages/Handler/Handler";
import Calendar from "../../../pages/Calendar";
import PaymentsList from "../../../pages/PaymentsList";
import SuppliesList from "../../../pages/SuppliesList";
import ForbiddenPage from "../ForbiddenPage/ForbiddenPage";
import DocumentsRoutes from "../DocumentsRoutes/DocumentsRoutes";
import SettingsRoutes from "../SettingsRoutes/SettingsRoutes";
import TemplatedOrder from "../../../pages/Order/TemplatedOrder";
import TemplatedRequisition from "../../../pages/Requisition/TemplatedRequisition";
import EditProject from "../../../pages/Settings/EditProject/EditProject";
import ServiceAct from "components/pages/ServiceAct/ServiceAct";
import FilesStorage from "components/pages/FilesStorage/FilesStorage";

import { VIEW_SETTINGS_SECTION } from "../../../../constants/permissions/generalPermissions";
import {
  VIEW_ACCEPTED_BUILDINGS_TAB,
  VIEW_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE,
  VIEW_ADD_CONTACT,
  VIEW_ADD_CONTRACT_FILES,
  VIEW_ADD_CONTRACTOR,
  VIEW_ADD_CONTRACTORS_CONTRACTS,
  VIEW_ADD_CONTRACTORS_CONTRACTS_FILES,
  VIEW_ADD_CUSTOMERS_CONTRACTS,
  VIEW_ADD_CUSTOMERS_CONTRACTS_FILES,
  VIEW_ADD_PACKING_LIST_FILES,
  VIEW_ADD_SUPPLIERS_CONTRACTS,
  VIEW_ADD_SUPPLIERS_CONTRACTS_FILES,
  VIEW_ALL_BUILDINGS_TAB,
  VIEW_BILL,
  VIEW_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID,
  VIEW_BILLS_LIST,
  VIEW_BUILDING,
  VIEW_BUILDING_ADD_EXPENDITURE_FILES,
  VIEW_BUILDING_ADD_FACT_INTERVALS,
  VIEW_BUILDING_ADD_PLAN_INTERVALS,
  VIEW_BUILDING_DELETE_EXPENDITURE_FILES,
  VIEW_BUILDING_PLAN_TAB,
  VIEW_BUILDING_PROGRESS_TAB,
  VIEW_BUILDING_TRANSFER_FACT_WORKS,
  VIEW_BUILDING_TRANSFER_PLAN_WORKS,
  VIEW_BUILDINGS_SECTION,
  VIEW_CONSOLIDATE_HANDLER_STATE,
  VIEW_CONSTRUCTING_CHART,
  VIEW_CONTACTS_LIST,
  VIEW_CONTRACTORS_LIST,
  VIEW_CONTRACTS_LIST,
  VIEW_COUNTERPARTIES_SECTION,
  VIEW_CREATE_BUILDING,
  VIEW_CUSTOMERS_LIST,
  VIEW_DELETE_BILL_FILES,
  VIEW_DELETE_CONTACTS,
  VIEW_DELETE_CONTRACT,
  VIEW_DELETE_CONTRACT_FILES,
  VIEW_DELETE_CONTRACTORS,
  VIEW_DELETE_CONTRACTORS_CONTRACTS,
  VIEW_DELETE_CONTRACTORS_CONTRACTS_FILES,
  VIEW_DELETE_CUSTOMERS,
  VIEW_DELETE_CUSTOMERS_CONTRACTS,
  VIEW_DELETE_CUSTOMERS_CONTRACTS_FILES,
  VIEW_DELETE_EXPORTS,
  VIEW_DELETE_PACKING_LIST_FILES,
  VIEW_DELETE_SUPPLIERS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS,
  VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES,
  VIEW_DOCUMENTS_SECTION,
  VIEW_DRAFT_HANDLER_STATE,
  VIEW_EDIT_BUILDINGS,
  VIEW_EDIT_CONTACT,
  VIEW_EDIT_CONTRACTOR_MANAGER,
  VIEW_EDIT_CUSTOMER_MANAGER,
  VIEW_EDIT_SUPPLIER_MANAGER,
  VIEW_EXPORT_LIST,
  VIEW_FINANCE_SECTION,
  VIEW_HANDLER,
  VIEW_INVITE_CUSTOMER,
  VIEW_INVITE_SUPPLIER,
  VIEW_INVITES_LIST,
  VIEW_INVITES_LIST_ACTIONS,
  VIEW_LOCALE_HANDLER_STATE,
  VIEW_OBJECT_HANDLER_STATE,
  VIEW_PACKING_LIST_ACCEPT,
  VIEW_PACKING_LIST_PAGE,
  VIEW_PACKING_LISTS,
  VIEW_PACKING_LISTS_VAT_CALCULATION,
  VIEW_PRODUCTION_HANDLER_STATE,
  VIEW_SENT_BUILDINGS_TAB,
  VIEW_SUPPLIERS_LIST,
} from "../../../../constants/permissions/constructingPermissions";
import { ROUTES } from "../../../../constants/routes";

import usePermission from "../../../../hooks/usePermission";
import { useLocationArray } from "utils/hooks/useLocationArray";
import { CONSTRUCTING_DEFAULT_PAGES_ENUM } from "types/enums/ModulesDefaultPageEnum";

const ConstructingRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const locationArray = useLocationArray();
  const location = useLocation();

  const haveViewConstructingChartPermission = usePermission(
    VIEW_CONSTRUCTING_CHART
  );
  const haveViewBuildingsSectionPermission = usePermission(
    VIEW_BUILDINGS_SECTION
  );
  const haveViewHandlerPermission = usePermission(VIEW_HANDLER);
  const haveViewFinancePermission = usePermission(VIEW_FINANCE_SECTION);
  const haveViewCounterpartiesPermission = usePermission(
    VIEW_COUNTERPARTIES_SECTION
  );
  const haveViewDocumentsPermission = usePermission(VIEW_DOCUMENTS_SECTION);
  const haveViewBuildingPermission = usePermission(VIEW_BUILDING);
  const haveViewSettingsSectionPermission = usePermission(
    VIEW_SETTINGS_SECTION
  );
  const haveViewCreateBuildingPermission = usePermission(VIEW_CREATE_BUILDING);
  const haveViewEditBuildingsPermission = usePermission(VIEW_EDIT_BUILDINGS);

  useEffect(() => {
    if ((
      locationArray[1] === CONSTRUCTING_DEFAULT_PAGES_ENUM.PROJECTS ||
      locationArray[1] === CONSTRUCTING_DEFAULT_PAGES_ENUM.SCHEDULE) &&
      !isNaN(locationArray[2])
    ) {
      localStorage.setItem("lastObjectId", locationArray[2]);
    }
  }, [location]);

  const ableUrl = useMemo(() => {
    if (haveViewConstructingChartPermission)
      return `${match.url}/manufacturing`;
    if (haveViewBuildingsSectionPermission) return `${match.url}/projects`;
    if (haveViewFinancePermission) return `${match.url}/finance/0`;
    if (haveViewCounterpartiesPermission) return `${match.url}/counterparties`;
    if (haveViewDocumentsPermission) return `${match.url}/documents`;
  }, [
    haveViewBuildingsSectionPermission,
    haveViewConstructingChartPermission,
    haveViewCounterpartiesPermission,
    haveViewDocumentsPermission,
    haveViewFinancePermission,
    match.url,
  ]);

  const documentsPermissions = useMemo(
    () => ({
      viewBillsList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BILLS_LIST),
      viewBill: compose(dispatch, checkUserHavePermission)(VIEW_BILL),
      viewPackingLists: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PACKING_LISTS),
      viewPackingListPage: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PACKING_LIST_PAGE),
      viewPackingListsVatCalculation: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PACKING_LISTS_VAT_CALCULATION),
      viewContractsList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_CONTRACTS_LIST),
      viewDeleteContract: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CONTRACT),
      viewAddContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_CONTRACT_FILES),
      viewDeleteContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CONTRACT_FILES),
      viewExportList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_EXPORT_LIST),
      viewDeleteExports: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_EXPORTS),
    }),
    []
  );

  const billPermissions = useMemo(
    () => ({
      viewBillInEdit: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BILL_IN_EDIT),
      addFiles: compose(dispatch, checkUserHavePermission)(VIEW_ADD_BILL_FILES),
      deleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_BILL_FILES),
      viewAddBillPaymentsFile: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_BILL_PAYMENTS_FILE),
      viewBillPaymentsMakePaid: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BILL_PAYMENTS_MAKE_PAID),
    }),
    []
  );

  const packingListPermissions = useMemo(
    () => ({
      viewPackingListAccept: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PACKING_LIST_ACCEPT),
      addFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_PACKING_LIST_FILES),
      deleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_PACKING_LIST_FILES),
    }),
    []
  );

  const requisitionPermissions = useMemo(
    () => ({
      viewProcessRequisition: true,
      viewProcessOrders: true,
      viewProcessPayments: true,
      viewAddOutOfEstimateProducts: true,
      viewAddAdditionalProducts: true,
      viewAddProductsBeforeApprove: true,
      viewAssignRequisitionExecutor: true,
      viewRequisitionTakeToWork: true,
      viewRequisitionReassign: true,
      viewAddRequisitionApprovers: true,
      viewAddRequisitionViewers: true,
      viewDeleteRequisitionApprovers: true,
      viewDeleteRequisitionViewers: true,
      viewRequisitionUnApprove: true,
      viewRequisitionLogs: true,
      viewRequisitionInProcessingMode: true,
      viewDeleteRequisitionProductsFiles: true,
      viewAddRequisitionProductsFiles: true,
      viewRequisitionProductsComments: true,
      viewAddRequisitionProductsComments: true,
      viewCreateRequisitionProductsProvider: true,
      viewOrderInvoiceDifference: true,
      viewAddOrderRequestsFiles: true,
      viewDeleteOrderRequestsFiles: true,
    }),
    []
  );

  const orderPermissions = useMemo(
    () => ({
      viewOrderInvoiceDifference: true,
      autoTransitionToPayment: true,
      addFiles: true,
      deleteFiles: true,
      viewAddOrderRequestsFiles: true,
      viewDeleteOrderRequestsFiles: true,
    }),
    []
  );

  const counterpartiesPermissions = useMemo(
    () => ({
      viewContactsList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_CONTACTS_LIST),
      viewAddContact: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_CONTACT),
      viewDeleteContacts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CONTACTS),
      viewEditContact: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_EDIT_CONTACT),
      viewContractorsList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_CONTRACTORS_LIST),
      viewAddContractor: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_CONTRACTOR),
      viewDeleteContractors: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CONTRACTORS),
      viewEditContractorManager: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_EDIT_CONTRACTOR_MANAGER),
      viewAddContractorsContracts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_CONTRACTORS_CONTRACTS),
      viewDeleteContractorsContract: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CONTRACTORS_CONTRACTS),
      viewAddContractorsContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_CONTRACTORS_CONTRACTS_FILES),
      viewDeleteContractorsContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CONTRACTORS_CONTRACTS_FILES),
      viewSuppliersList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_SUPPLIERS_LIST),
      viewInviteSupplier: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_INVITE_SUPPLIER),
      viewDeleteSuppliers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_SUPPLIERS),
      viewEditSupplierManager: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_EDIT_SUPPLIER_MANAGER),
      viewAddSuppliersContracts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_SUPPLIERS_CONTRACTS),
      viewDeleteSuppliersContract: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_SUPPLIERS_CONTRACTS),
      viewAddSuppliersContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_SUPPLIERS_CONTRACTS_FILES),
      viewDeleteSuppliersContractFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES),
      viewCustomersList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_CUSTOMERS_LIST),
      viewInviteCustomer: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_INVITE_CUSTOMER),
      viewDeleteCustomers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CUSTOMERS),
      viewEditCustomerManager: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_EDIT_CUSTOMER_MANAGER),
      viewAddCustomersContracts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_CUSTOMERS_CONTRACTS),
      viewDeleteCustomersContracts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CUSTOMERS_CONTRACTS),
      viewAddCustomersContractsFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ADD_CUSTOMERS_CONTRACTS_FILES),
      viewDeleteCustomersContractsFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DELETE_CUSTOMERS_CONTRACTS_FILES),
      viewInvitesList: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_INVITES_LIST),
      viewInvitesListActions: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_INVITES_LIST_ACTIONS),
    }),
    []
  );

  const buildingsPermissions = useMemo(
    () => ({
      viewAllBuildingsTab: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ALL_BUILDINGS_TAB),
      viewSentBuildingsTab: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_SENT_BUILDINGS_TAB),
      viewAcceptedBuildingsTab: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_ACCEPTED_BUILDINGS_TAB),
      viewCreateBuilding: haveViewCreateBuildingPermission,
      viewEditBuildings: haveViewEditBuildingsPermission,
      viewHandler: haveViewHandlerPermission,
      viewBuilding: haveViewBuildingPermission,
    }),
    []
  );

  const handlerPermissions = useMemo(
    () => ({
      viewDraftHandlerState: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_DRAFT_HANDLER_STATE),
      viewLocaleHandlerState: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_LOCALE_HANDLER_STATE),
      viewObjectHandlerState: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_OBJECT_HANDLER_STATE),
      viewConsolidateHandlerState: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_CONSOLIDATE_HANDLER_STATE),
      viewProductionHandlerState: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_PRODUCTION_HANDLER_STATE),
      canAddFiles: true,
      canDeleteFiles: true,
    }),
    []
  );

  const objectEstimatePermissions = useMemo(
    () => ({
      canViewPlan: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BUILDING_PLAN_TAB),
      canViewProgress: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BUILDING_PROGRESS_TAB),
      canAddPlan: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BUILDING_ADD_PLAN_INTERVALS),
      canAddProgress: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BUILDING_ADD_FACT_INTERVALS),
      canSharePlan: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BUILDING_TRANSFER_PLAN_WORKS),
      canShareProgress: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BUILDING_TRANSFER_FACT_WORKS),
      canAddFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BUILDING_ADD_EXPENDITURE_FILES),
      canDeleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_BUILDING_DELETE_EXPENDITURE_FILES),
    }),
    []
  );

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => <Redirect replace to={ableUrl} />}
      />
      {haveViewConstructingChartPermission && (
        <Route
          exact
          path={[
            `${match.path}/manufacturing/`,
            `${match.path}/manufacturing/:objectId`,
          ]}
          component={Manufacturing}
        />
      )}
      {haveViewBuildingsSectionPermission && (
        <Route
          exact
          path={ROUTES.CONSTRUCTING_OBJECTS}
          render={(props) => (
            <ConstructingObjects
              {...props}
              permissions={buildingsPermissions}
            />
          )}
        />
      )}
      {haveViewCreateBuildingPermission && (
        <Route
          path={`${match.path}/projects/create-project/:id`}
          component={EditProject}
        />
      )}
      {haveViewEditBuildingsPermission && (
        <Route
          path={`${match.path}/projects/edit-project/:id`}
          component={EditProject}
        />
      )}
      {haveViewBuildingsSectionPermission && haveViewBuildingPermission && (
        <Route
          path={`${match.path}/projects/:objectId`}
          render={(props) => (
            <Building {...props} permissions={objectEstimatePermissions} />
          )}
        />
      )}
      {haveViewHandlerPermission && (
        <Route
          path={`${match.path}/handler/:buildingId`}
          render={(props) => (
            <Handler {...props} permissions={handlerPermissions} />
          )}
        />
      )}
      {haveViewFinancePermission && (
        <Route
          path={ROUTES.CONSTRUCTING_FINANCE}
          component={ConstructingFinance}
        />
      )}
      {haveViewCounterpartiesPermission && (
        <Route
          path={ROUTES.CONSTRUCTING_COUNTERPARTIES}
          render={(props) => (
            <ConstructingCounterparties
              {...props}
              permissions={counterpartiesPermissions}
            />
          )}
        />
      )}
      {haveViewDocumentsPermission && (
        <Route
          path={`${match.path}/documents`}
          render={(props) => (
            <DocumentsRoutes
              {...props}
              documentsPermissions={documentsPermissions}
              billPermissions={billPermissions}
              packingListPermissions={packingListPermissions}
            />
          )}
        />
      )}
      <Route exact path={`${match.path}/profile`} component={ProfilePage} />
      <Route
        exact
        path={`${match.path}/events/calendar`}
        component={Calendar}
      />
      <Route
        exact
        path={`${match.path}/events/payments`}
        component={PaymentsList}
      />
      <Route
        exact
        path={`${match.path}/events/supplies`}
        component={SuppliesList}
      />
      <Route
        exact
        path={`${match.path}/documents/service-acts/:actId`}
        component={ServiceAct}
      />
      <Route
        exact
        path={`${match.path}/requisitions/:activeTab/:requisitionId`}
        render={(props) => (
          <TemplatedRequisition
            {...props}
            permissions={requisitionPermissions}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/orders/:orderId`}
        render={(props) => (
          <TemplatedOrder {...props} permissions={orderPermissions} />
        )}
      />
      <Route
        exact
        path={`${match.path}/files/:objectId/`}
        component={FilesStorage}
      />
      {haveViewSettingsSectionPermission && (
        <Route path={`${match.path}/settings`} component={SettingsRoutes} />
      )}
      <ForbiddenPage />
    </Switch>
  );
};

export default React.memo(ConstructingRoutes);
