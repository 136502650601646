import React from "react";
import cn from "classnames";

import DragHandlerIcon from "../../../../../../../../../images/icons/DragHandlerIcon";

import { getSectionAmount } from "../../../../../../utils/getSectionAmount";
import { getExpenditureAmount } from "../../../../../../utils/getExpenditureAmount";

import styles from "./EstimateItem.module.scss";
import { transformDigitToFinancial } from "../../../../../../../../../utils/transformDigitToFinancial";

const EstimateItem = ({ estimateItem, activeEstimateStateId }) => {
  const isExpenditure = !!estimateItem.expenditure_type;
  const amount = transformDigitToFinancial(
    isExpenditure ? getExpenditureAmount(estimateItem) : getSectionAmount(estimateItem, activeEstimateStateId),
    { withFloat: false, withRuble: true }
  );

  return (
    <div className={cn(styles.itemBlock, { [styles.expenditure]: isExpenditure })}>
      <div className={styles.itemName}>
        {isExpenditure && (
          <DragHandlerIcon className={styles.icon} />
        )}
        {estimateItem.name}
      </div>
      <div className={styles.amount}>{amount}</div>
    </div>
  );
};

export default React.memo(EstimateItem);
