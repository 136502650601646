import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import moment from "moment";
import { useParams } from "react-router-dom";

import { NavLinkIcon } from "components/UI/NavLinkIcon/NavLinkIcon";
import { NavLinkVariantsEnum } from "components/UI/NavLinkIcon/constants";
import Checkbox from "components/UI/Checkbox";
import Difference from "../../../../UI/atoms/Difference/Difference";

import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";
import { COMPLETED, WAITING_SHIPMENT } from "utils/constant";
import { transformDigitToFinancial } from "../../../../../utils/transformDigitToFinancial";

import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";

import styles from "./index.module.scss";

const haveRequestsOffers = (requests) => requests.every((request) => request.offers.length !== 0);

const OrderHeader = ({
  isOrderOpen,
  order,
  handleOrderOpening,
  isShownInvoiceDifference,
  isSimplified,
  isChecked,
  onCheck,
}) => {
  const isCompleted = order.status === COMPLETED || order.status === WAITING_SHIPMENT;
  const haveOffers = useMemo(() => haveRequestsOffers(order.body.requests), [order.body.requests]);
  const urlAdapter = useUrlModule();
  const { objectId } = useParams();

  const urlPrefix = () => {
    if (urlAdapter !== "objects") return urlAdapter;
    return `${urlAdapter}/${objectId}`;
  };

  const check = useCallback((e) => onCheck(order.id, e.target.checked), [onCheck, order.id]);

  return (
    <div className={styles.orderHeader}>
      <div
        className={cn(styles.buttonOpen, {
          [styles.completed]: isCompleted,
          [styles.haveOffers]: !isCompleted && haveOffers,
        })}
        onClick={() => handleOrderOpening(order.id)}
      >
        <ArrowSmallUpGrey color="#000" rotate={isOrderOpen ? 0 : 180} />
      </div>
      <div className={styles.checkbox}>
        <Checkbox checked={isChecked} onChange={check} />
      </div>
      <div className={styles.rightSide}>
        <div className={styles.topSide}>
          <div>
            Заказ:{" "}
            {!isSimplified ? (
              <NavLinkIcon
                to={`/${urlPrefix()}/orders/${order.id}`}
                variant={NavLinkVariantsEnum.SECONDARY}
                title={order.number}
              >
                {order.number}
              </NavLinkIcon>
            ) : (
              order.number
            )}
          </div>
          <div className={styles.status}>{order.status_name}</div>
          <div className={styles.divider} />
          <div className={styles.infoBlock}>
            <span className={styles.name}>Сумма:</span>
            <span>{transformDigitToFinancial(order.calculation.amount, { withFloat: true, withRuble: true })}</span>
          </div>
          <div className={styles.divider} />
          <div className={styles.infoBlock}>
            <span className={styles.name}>Сумма по смете:</span>
            <span>{transformDigitToFinancial(order.benefit.amount_plan, { withFloat: true, withRuble: true })}</span>
          </div>
          {isShownInvoiceDifference && (
            <>
              <div className={styles.divider} />
              <div className={styles.infoBlock}>
                <span className={styles.name}>Разница:</span>
                <Difference amount={order.benefit.amount_plan_diff} />
              </div>
            </>
          )}
        </div>
        <div className={styles.bottomSide}>
          <div className={styles.infoBlock} title={order.executor_provider?.last_name}>
            <span className={styles.name}>Поставщик:</span>
            <span className={styles.halfOpacity}>{order.executor_provider?.last_name}</span>
          </div>
          {(!isSimplified && order.acts?.length !== 0) && (
            <>
              <div className={styles.divider} />
              <div className={cn(styles.infoBlock, styles.packingLists)}>
                <span className={styles.name}>Список актов:</span>
                {order.acts.map((act) => (
                  <div key={act.id} className={styles.act}>
                    <NavLinkIcon
                      to={`/${urlPrefix()}/documents/service-acts/${act.id}`}
                      title={moment(act.date).format("DD.MM.YYYY")}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {(!isSimplified && order.packinglists?.length !== 0) && (
            <>
              <div className={styles.divider} />
              <div className={cn(styles.infoBlock, styles.packingLists)}>
                <span className={styles.name}>Список УПД:</span>
                {order.packinglists.map((packingList) => (
                  <div key={packingList.id} className={styles.packingList}>
                    <NavLinkIcon
                      to={`/${urlPrefix()}/documents/packing-lists/${packingList.id}`}
                      title={moment(packingList.delivery_date).format("DD.MM.YYYY")}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {!isSimplified && (
            <>
              <div className={styles.divider} />
              <div className={styles.infoBlock}>
                <span className={styles.name}>Счет:</span>
                {order.invoice ? (
                  <NavLinkIcon
                    to={`/${urlPrefix()}/documents/bills/${order.invoice.id}`}
                    title={order.invoice.number || "сформирован"}
                  />
                ) : (
                  <span className={styles.halfOpacity}>формируется</span>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(OrderHeader);
