import React from "react";
import Select, { ISelectProps } from "../Select";
import cn from 'classnames'
import styles from './YearSelect.module.scss'
import moment from 'moment'
import {YEAR_SELECT_MIN_YEAR, YEAR_SELECT_UPPER_BOUND_FROM_CURRENT_YEAR} from '../../../../utils/constant'

export interface IYearSelectProps extends Omit<ISelectProps, "options"> {}

const YearSelect: React.FC<IYearSelectProps> = ({className, ...props}) => {

  const generatedYears = React.useMemo(() => {
    const res = [];
    for (let i = moment().year() + YEAR_SELECT_UPPER_BOUND_FROM_CURRENT_YEAR ; i >= YEAR_SELECT_MIN_YEAR; i--) {
      res.push({ id: i, name: i, label: i });
    }
    return res;
  }, []);

  return (
    <Select
      options={generatedYears}
      className={cn(styles.year, className)}
      isScrolledToDefaultOption
      {...props}
    />
  );
};

export default YearSelect;
