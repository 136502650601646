import React, { useState, useCallback } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";

import { userSelector } from "redux/modules/common/auth";
import { extendedTariffApi } from "redux/modules/common/extendedTariff/extendedTariffApi";
import { ISubscriptionRequestValues } from "redux/modules/common/extendedTariff/types";

import { errorCatcher } from "utils/errorCatcher";


const CANNOTT_BE_REPEATED_MESSAGE = "Поля user, status, desired_tariff должны производить массив с уникальными значениями.";

export const useSubscriptionRequest = () => {
  const [isShowRequestModal, setIsShowRequestModal] = useState(false);
  const user = useSelector(userSelector);
  
  const sendSubscriptionRequest = useCallback((values: ISubscriptionRequestValues) => {
    const updatedValues = {
      ...values,
      desired_tariff: "PROFESSIONAL",
      user: user.id,
    };

    extendedTariffApi.postSubscriptionRequest(updatedValues)
      .then(() => {
        setIsShowRequestModal(false);
        message.success("Заявка успешно отправлена")
      })
      .catch((error) => {
        if (error?.response?.data?.errors[0].message === CANNOTT_BE_REPEATED_MESSAGE) {
          setIsShowRequestModal(false);
          return message.error("Вы уже отправляли заявку");
        }

        return errorCatcher(error);
      })
  }, []);

  return {
    isShowRequestModal,
    setIsShowRequestModal,
    sendSubscriptionRequest
  }
};

