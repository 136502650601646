import { USER_TYPES } from "./constant";

export const getUserType = (authEntityType) => {
  switch (authEntityType) {
    case "pcr":
      return USER_TYPES.PURCHASER;
    case "pvr":
      return USER_TYPES.PROVIDER;
    default:
      return null;
  }
};