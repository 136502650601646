import React from "react";
import cn from "classnames";

import PaperClipIcon from "../../../../../../images/icons/PaperClipIcon";
import ButtonBase from "../../../ButtonBase";

import styles from "./AddFile.module.scss";

export const VARIANTS = {
  BUTTON: "button",
  BOOK: "book"
};

const AddFile = ({ variant, openFileDialog, disabled }) => {
  if (variant === VARIANTS.BOOK) return (
    <div 
      className={cn(styles.addFile, {
        [styles.disabled]: disabled
      })}
      onClick={openFileDialog}
    >
      <div className={styles.iconContainer}>
        <PaperClipIcon width={12} fill="#000" />
      </div>
      <div className={styles.text}>Прикрепить файл</div>
    </div>
  );

  if (variant === VARIANTS.BUTTON) return (
    <ButtonBase 
      className={cn(styles.button, {
        [styles.disabled]: disabled
      })}
      onClick={openFileDialog} 
      secondary 
      medium
    >
      <PaperClipIcon className={styles.buttonIcon} width={8} fill="#000" />
      Прикрепить файл
    </ButtonBase>
  );

  return null;
};

export default React.memo(AddFile);