import React, { SyntheticEvent, useState} from 'react'
import cn from "classnames";

import { Ks6aListItemType } from "../../../../../../../../../../../redux/modules/common/ks6a/types";
import Icon from '../../../../../../../../../../UI/Icon'

import infoIcon from 'images/icons/infoIcon.svg'

import worksStyles from "../../Ks6aWorks.module.scss";
import styles from "./Ks6aWorkPopup.module.scss";

export interface IKs6aWorkPopupProps {
  work: Ks6aListItemType;
  isHorizontal?: boolean
}

const Ks6aWorkPopup: React.FC<IKs6aWorkPopupProps> = ({ work, isHorizontal }) => {
  const [hover, setHover] = useState<boolean>(false);
  const [isTopPopup, setIsTopPopup] = useState<boolean>(false)

  const onHover = (e: SyntheticEvent<MouseEvent>) => {
    const containerNode = (e?.target as Element)?.closest('.ks6a_table')
    if (!containerNode) return
    setIsTopPopup(containerNode.clientHeight + containerNode.scrollTop - (e?.target as Element)?.closest(`.${worksStyles.worksRow}`)?.offsetTop - 250 < 0);
    setHover(true)
  };

  const onBlur = () => setHover(false);

  return (
    <div className={styles.popupContainer} onMouseEnter={onHover} onMouseLeave={onBlur}>
      <Icon icon={infoIcon} className={styles.workInfo}/>
      {hover && (
        <div
          className={cn(styles.popupWrapper, {
            [styles.topPopup]: isTopPopup,
            [styles.horizontalPopupWrapper]: isHorizontal,
          })}
        >
          <div className={styles.popup}>
            <div className={styles.popupJustification}>
              <span>Номер единичной расценки</span>
              <span className={styles.justification}>{work.justification}</span>
            </div>
            <div className={styles.popupMeasure}>
              <span>Единица измерения</span>
              <span>{work.measure}</span>
            </div>
            <div className={styles.popupPrice}>
              <span>Цена за единицу, ₽</span>
              <span>{work.price}</span>
            </div>
            <div className={styles.popupEstimateCount}>
              <span>Кол-во работ по смете</span>
              <span>{work.estimate_count}</span>
            </div>
            <div className={styles.popupEstimatePrice}>
              <span>Сметная стоимость, ₽</span>
              <span>{work.estimate_price}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Ks6aWorkPopup);
