import React from "react";
import { useDispatch } from "react-redux";

import { checkUserHavePermission } from "redux/modules/common/permissions/thunks/checkUserHavePermission";

const usePermission = (permissionName: string | string[]): boolean => {
  const dispatch = useDispatch(); //@ts-ignore
  const permission = React.useRef<boolean>(dispatch(checkUserHavePermission(permissionName)));

  return permission.current;
};

export default usePermission;