export const LOAD_DOCUMENTS = "documents/LOAD_DOCUMENTS";
export const SET_DOCUMENTS = "documents/SET_DOCUMENTS";
export const SET_PACKING_LIST_LOADING = "documents/SET_PACKING_LIST_LOADING";
export const SET_PACKING_LIST_ERROR = "documents/SET_PACKING_LIST_ERROR";
export const SET_PACKING_LIST = "documents/SET_PACKING_LIST";
export const SET_PACKING_LIST_NUMBER = "documents/SET_PACKING_LIST_NUMBER";
export const ADD_DOCUMENT = `documents/ADD_DOCUMENT`;
export const SET_MORE_DOCUMENT = `documents/SET_MORE_DOCUMENT`;
export const ADD_PACKING_LIST_FILE = `documents/ADD_PACKING_LIST_FILE`;
export const DELETE_PACKING_LIST_FILE = `documents/DELETE_PACKING_LIST_FILE`;

export const SET_PACKING_ITEMS_FILES_LOADING = `documents/SET_PACKING_ITEMS_FILES_LOADING`;
export const SET_PACKING_ITEM_FILES = `documents/SET_PACKING_ITEM_FILES`;
export const ADD_PACKING_ITEM_FILE = `documents/ADD_PACKING_ITEM_FILE`;
export const DELETE_PACKING_ITEM_FILE = `documents/DELETE_PACKING_ITEM_FILE`;

export const DEFAULT_LIMIT_DOCUMENT = 10;