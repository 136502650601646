import React from "react";
import { useSelector } from "react-redux";

import { ks6aFileUrlSelector } from "redux/modules/common/ks6a/ks6a";
import FileBlock from "components/UI/molecules/FileBlock";

import Ks6aDocumentInfo, { IKs6aDocumentInfoProps } from "./components/Ks6aDocumentInfo/Ks6aDocumentInfo";
import styles from "./K6saHeader.module.scss";

export interface IKs6aHeaderProps extends IKs6aDocumentInfoProps {
  year: number;
}

const Ks6aHeader: React.FC<IKs6aHeaderProps> = ({ year, docNumber, status, organisationName }) => {
  const fileUrl = useSelector(ks6aFileUrlSelector);
  const fileBlockPermissions = { addFiles: false, deleteFiles: false };
  const files = fileUrl.length
    ? [
        {
          file: fileUrl,
          id: 1,
          name: `КС-6а "${organisationName}" ${year}.xlsx`,
        },
      ]
    : [];

  return (
    <div className={styles.header}>
      <Ks6aDocumentInfo docNumber={docNumber} status={status} organisationName={organisationName} />
      <FileBlock
        files={files}
        permissions={fileBlockPermissions}
        className={styles.fileBlock}
        addFiles={undefined}
        deleteFile={undefined}
        isDisabled={undefined}
        mustConfirmDownload={undefined}
      />
    </div>
  );
};

export default React.memo(Ks6aHeader);
