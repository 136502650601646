import axios from 'axios';
import { message } from 'antd';
import { createSelector } from 'reselect';
import { errorCatcher } from 'utils/errorCatcher';

const moduleName = 'entities';
const LOAD_ENTITIES = `${moduleName}/LOAD_ENTITIES`;
const SET_ENTITIES = `${moduleName}/SET_ENTITIES`;
const LOAD_ENTITY_DETAIL = `${moduleName}/LOAD_ENTITY_DETAIL`;
const STOP_LOAD_ENTITY_DETAIL = `${moduleName}/STOP_LOAD_ENTITY_DETAIL`;
const SET_ENTITY_DETAIL = `${moduleName}/SET_ENTITY_DETAIL`;

const initialState = {
  entities: null,
  isLoading: true,
  entityDetail: null,
  entityLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ENTITIES:
      return {
        ...state,
        isLoading: true,
      };
    case SET_ENTITIES:
      return {
        ...state,
        entities: payload,
        isLoading: false,
      };
    case LOAD_ENTITY_DETAIL:
      return {
        ...state,
        entityLoading: true,
      };
    case STOP_LOAD_ENTITY_DETAIL:
      return {
        ...state,
        entityLoading: false,
      };
    case SET_ENTITY_DETAIL:
      return {
        ...state,
        entityDetail: payload,
        entityLoading: false,
      };
    default:
      return state;
  }
};

export const stateSelector = state => state[moduleName];
export const entitiesSelector = createSelector(stateSelector, state => state.entities);
export const entitiesLoadingSelector = createSelector(stateSelector, state => state.isLoading);
export const entityDetailSelector = createSelector(stateSelector, state => state.entityDetail);
export const entityDetailLoadingSelector = createSelector(stateSelector, state => state.entityLoading);

export const loadEntities = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId, paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: sorting,
      },
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_ENTITIES,
      });
      axios.get(`/admin/accounts/${accountId}/entities/`, config)
        .then(response =>
          dispatch({
            type: SET_ENTITIES,
            payload: response.data,
          })
        );
    };
  };
})();

export const loadEntityDetail = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId, entityId) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_ENTITY_DETAIL,
      });
      axios.get(`/admin/accounts/${accountId}/entities/${entityId}/`, config)
        .then(response =>
          dispatch({
            type: SET_ENTITY_DETAIL,
            payload: response.data,
          })
        );
    };
  };
})();

export const createEntity = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId, body, callback) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };

    return dispatch => {
      dispatch({
        type: LOAD_ENTITY_DETAIL,
      });
      axios.post(`/admin/accounts/${accountId}/entities/`, body, config).then(response =>
          callback?.(response.data),
      );
    };
  };
})();

export const editEntity = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (accountId, entityId, body, callback) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      dispatch({
        type: LOAD_ENTITY_DETAIL,
      });
      axios.patch(`/admin/accounts/${accountId}/entities/${entityId}/`, body, config).then(response =>
          dispatch({
            type: SET_ENTITY_DETAIL,
            payload: response.data,
          }),
        error => {
          dispatch({
            type: STOP_LOAD_ENTITY_DETAIL
          });
          errorCatcher(error)
        }
      );
    };
  };
})();

