import moment from "moment";
import React, { useRef, useState } from "react";

import ManufacturingModal from "../../../ManufacturingModal/ManufacturingModal";
import DiagramInterval from "../DiagramInterval/DiagramInterval";
import InfoPopup from "../InfoPopup/InfoPopup";

import {getWorkStatus, INTERVAL_MAX_Z_INDEX, INTERVAL_TYPES, MONTH_COLOR_MAP as colorMap} from '../../../../constants'
import TicketTail from '../../../../../../../images/TicketTail'

import styles from "../../Month.module.scss";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";
import { useIntervalLinks } from "../../useIntervalLinks";
import {getIntervalDatesLabel} from '../../../../utils'

const Work = ({ branch, offset, isSection = false, offsetLeft, objectId = null, estimateCompletedPercent }) => {
  const intervalWrapperRef = useRef();
  const { isLinkingEnabled } = useIntervalLinks({});
  const unitMultiplier = useUnitMultiplier();

  const [hover, setHover] = useState(false);

  const { confirmed, doned, paid, topay, received } = branch.work.data.percentage;

  const lastColorKey = getWorkStatus(branch.work.data.percentage);
  const borderColor = colorMap[lastColorKey + "Border"];
  const leftTailProps = {
    fillColor: "none",
    borderColor,
  };
  const rightTailProps = {
    fillColor: "none",
    borderColor,
    isRight: true,
  };
  if (doned > 0) {
    leftTailProps.linesColor = colorMap["received"];
  }
  if (doned >= 1) {
    rightTailProps.linesColor = colorMap["received"];
  }
  if (received > 0) {
    leftTailProps.fillColor = colorMap["received"];
  }
  if (received >= 1) {
    rightTailProps.fillColor = colorMap["received"];
  }
  if (confirmed > 0) {
    leftTailProps.wideLinesColor = colorMap["confirmed"];
  }
  if (confirmed >= 1) {
    rightTailProps.wideLinesColor = colorMap["confirmed"];
  }
  if (topay > 0) {
    leftTailProps.linesColor = "none";
    leftTailProps.wideLinesColor = "none";
    leftTailProps.fillColor = colorMap["topay"];
  }
  if (topay >= 1) {
    rightTailProps.linesColor = "none";
    rightTailProps.wideLinesColor = "none";
    rightTailProps.fillColor = colorMap["topay"];
  }
  if (paid > 0) {
    leftTailProps.fillColor = colorMap["paid"];
    leftTailProps.linesColor = "none";
    leftTailProps.wideLinesColor = "none";
  }
  if (paid >= 1) {
    rightTailProps.fillColor = colorMap["paid"];
    rightTailProps.linesColor = "none";
    rightTailProps.wideLinesColor = "none";
  }

  const onHover = (e) => {
    const containerNode = e.target?.closest(".calendar_node");
    if (!containerNode) return;
  };

  const [isOpenWorkCard, setIsOpenWorkCard] = useState(false);

  const handleCloseModal = () => {
    setIsOpenWorkCard(false);
    setHover(false);
  };

  const workWrapperStyles = {
    zIndex: hover || isOpenWorkCard ? INTERVAL_MAX_Z_INDEX : Math.min(branch.work.days, INTERVAL_MAX_Z_INDEX),
  };

  const modalType =
    +branch.parentId === +objectId || !branch.parentId ? "building" : isSection ? "section" : "expenditure";

  const dateStart = moment(branch.work.interval_start_date || branch.work.start)?.format("YYYY-MM-DD");
  const dateEnd = moment(branch.work.interval_end_date || branch.work.end)?.format("YYYY-MM-DD");

  const interval = getIntervalDatesLabel(branch.work.start, branch.work.end);
  const { status, is_shared, is_from_provider } = branch.work.data;

  const updateWorkStatus = (newStatus, intervalId) => {
    if (intervalId !== branch.work.data.id) return;
    branch.work.data.status = newStatus;
  };

  if (isLinkingEnabled) return null;

  return (
    <DiagramInterval
      day={branch.day}
      daysLength={branch.work.days}
      unitMultiplier={unitMultiplier}
      onMouseEnter={onHover}
      wrapperStyles={workWrapperStyles}
      offsetLeft={offsetLeft}
      hover={hover}
      setHover={setHover}
      intervalWrapperRef={intervalWrapperRef}
      workStatus={status}
      isShared={is_shared}
      isFromProvider={is_from_provider}
      isCardOpen={isOpenWorkCard}
      type={INTERVAL_TYPES.work}
    >
      <TicketTail {...leftTailProps} />
      <div
        className={styles.work}
        style={{
          borderTop: `1px solid ${borderColor}`,
          borderBottom: `1px solid ${borderColor}`,
        }}
      >
        <div className={styles.planBg} style={{ width: `100%` }} />
        {+branch.work.data.percentage.doned > 0 && (
          <div className={styles.doneBg} style={{ width: `${branch.work.data.percentage.doned * 100}%` }} />
        )}
        {+branch.work.data.percentage.received > 0 && (
          <div
            className={styles.receivedBg}
            style={{
              width: `${branch.work.data.percentage.received * 100}%`,
            }}
          />
        )}
        {+branch.work.data.percentage.topay > 0 && (
          <div className={styles.topayBg} style={{ width: `${branch.work.data.percentage.topay * 100}%` }} />
        )}
        {+branch.work.data.percentage.confirmed > 0 && (
          <div
            className={styles.confirmedBg}
            style={{
              width: `${branch.work.data.percentage.confirmed * 100}%`,
            }}
          />
        )}
        {+branch.work.data.percentage.paid > 0 && (
          <div className={styles.paidBg} style={{ width: `${branch.work.data.percentage.paid * 100}%` }} />
        )}
      </div>
      <TicketTail {...rightTailProps} />
      {
        (hover || isOpenWorkCard) && <InfoPopup
          setOpenPopup={setHover}
          interval={interval}
          data={branch.work.data}
          estimateCompletedPercent={estimateCompletedPercent}
          type={INTERVAL_TYPES.work}
          setOpenModal={setIsOpenWorkCard}
          isOpen
        >
          <div className={styles.popupAnchor} />
        </InfoPopup>
      }
      {isOpenWorkCard && (
        <ManufacturingModal
          isOpen
          onClose={handleCloseModal}
          type={INTERVAL_TYPES.work}
          date_start={dateStart}
          date_end={dateEnd}
          objectId={objectId}
          intervaldata={branch.work.data}
          expenditureId={branch.work.data.expenditure_id || branch.parentId}
          modalType={modalType}
          updateWorkStatus={updateWorkStatus}
        />
      )}
    </DiagramInterval>
  );
};

export default React.memo(Work);
