import axios from "axios";
import { compose } from "redux";
import { message } from "antd";

import { errorCatcher } from "../../../../../../utils/errorCatcher";

import { addEmployeesAction } from "../actions";
import { MESSAGES } from "../constants";

const addEmployees = (employees, options) => async (dispatch) => axios.post(
  `/${options.userType}/orders/${options.orderId}/${options.approvalType}/employees/`,
  employees
)
  .then((response) => {
    compose(dispatch, addEmployeesAction)(response.data, options.approvalType);
    message.success(MESSAGES[options.approvalType].ADDED);
  })
  .catch(errorCatcher);

export { addEmployees };