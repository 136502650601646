import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ADD_SELECTED_SUBSECTIONS,
  DELETE_SELECTED_SUBSECTIONS,
  estimateMeasureSelector,
  selectedSubsectionsSelector,
} from "../../../../../redux/modules/common/building/process";

import EstimateSection from "../../../../UI/molecules/EstimateSection/EstimateSection";
import { AMOUNTS_VARIANTS } from "../../../../UI/molecules/EstimateSection/components/Amounts/Amounts";

const Section = ({ section, canSharing }) => {
  const dispatch = useDispatch();
  const measure = useSelector(estimateMeasureSelector);

  const selectedSubsections = useSelector(selectedSubsectionsSelector);

  const checkboxHandler = (isChecked) => {
    if (isChecked) {
      dispatch({ type: ADD_SELECTED_SUBSECTIONS, payload: section.id });
    } else {
      dispatch({ type: DELETE_SELECTED_SUBSECTIONS, payload: section.id });
    }
  };

  const amounts = useMemo(
    () => ({
      all: section.indicators ? section.indicators.estimate_amount : 0,
      completed: section.indicators ? section.indicators.work_completed : 0,
      accepted: section.indicators ? section.indicators.amount_accepted : 0,
      invested: section.indicators ? section.indicators.invested : 0,
      variant: AMOUNTS_VARIANTS.INLINE,
      measure,
    }),
    [section.indicators, measure]
  );

  return (
    <EstimateSection
      section={section}
      isChecked={selectedSubsections.includes(section.id)}
      onCheck={checkboxHandler}
      amounts={amounts}
      isDisplayEstimateStateMark
      isDisplayAmounts
      canCheck={canSharing}
    />
  );
};

export default React.memo(Section);
