import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HALF_MONTH, MONTH, WEEK, YEAR } from "redux/modules/common/building/manufacturing/manufacturing";

import { chartViewModeSelector } from "redux/modules/common/building/manufacturing/selectors";
import {
  dropLoadedChartMonths, loadArrows,
  loadMaterials,
  loadPlan,
  loadTree,
  loadWeekMaterials,
  loadWeekPlan
} from 'redux/modules/common/building/manufacturing/thunks'

import {
  ExpandedBranchesType,
  IMonthArrayElement,
  IProcessedBranch,
  ManufacturingViewType,
} from "../../Manufacturing/types";
import {getWeeksInYear} from '../../Manufacturing/utils'


export interface IUseProjectTreeProps {
  tab: string;
  projectId: number | string;
  chartViewMode: ManufacturingViewType;
  year: number | string;
  month: IMonthArrayElement;
  startWeek: number;
  endWeek: number;
  expandedBranches: ExpandedBranchesType;
  setExpandedBranches: (newBranches: ExpandedBranchesType) => void;
  generateSpittingTree: () => void;
}

export const useProjectTree = ({
  projectId,
  year,
  month,
  startWeek,
  endWeek,
  expandedBranches,
  setExpandedBranches,
  generateSpittingTree,
}: IUseProjectTreeProps) => {
  const dispatch = useDispatch();
  const chartViewMode = useSelector(chartViewModeSelector);

  const loadEstimateTree = () => {
    dispatch(loadTree(projectId));
  };

  const loadWeeksPlan = () => {
    if (chartViewMode === YEAR) {
      dispatch(loadWeekPlan(projectId, 1, getWeeksInYear(+year), year));
    } else {
      dispatch(loadWeekPlan(projectId, startWeek, endWeek, year));
    }
  };

  const loadDaysMaterials = () => {
    dispatch(loadMaterials(projectId, year, month.id + 1));
  };

  const loadDaysPlan = () => {
    dispatch(loadPlan(projectId, year, month.id + 1));
  };

  const loadWeeksMaterials = () => {
    if (chartViewMode === YEAR) {
      dispatch(loadWeekMaterials(projectId, 1, getWeeksInYear(+year), year));
    } else {
      dispatch(loadWeekMaterials(projectId, startWeek, endWeek, year));
    }
  };

  useEffect(() => {
    if (!projectId) return;
    dispatch(dropLoadedChartMonths());
    loadEstimateTree();
    dispatch(loadArrows(projectId))
  }, [projectId]);

  useEffect(() => {
    if (chartViewMode === MONTH || chartViewMode === HALF_MONTH) {
      loadDaysPlan();
      loadDaysMaterials();
    }
  }, [chartViewMode, year, month.id, projectId]);

  useEffect(() => {
    if (chartViewMode === WEEK || chartViewMode === YEAR) {
      loadWeeksPlan();
      loadWeeksMaterials();
    }
  }, [chartViewMode, year, startWeek, endWeek, projectId]);

  const toggleBranch = (branch: IProcessedBranch) => {
    if (expandedBranches.has(branch.id)) {
      expandedBranches.delete(branch.id);
      setExpandedBranches(expandedBranches);
    } else {
      setExpandedBranches(expandedBranches.add(branch.id));
    }

    generateSpittingTree();
  };

  const checkIsExpandedBranchId = useCallback(
    (id: number) => {
      return expandedBranches.has(id);
    },
    [expandedBranches]
  );

  return {
    toggleBranch,
    checkIsExpandedBranchId,
  };
};
