import React from "react";
import { useSelector } from "react-redux";
import {
  HALF_MONTH,
  MONTH,
  WEEK,
  YEAR,
} from "../../../../../redux/modules/common/building/manufacturing/manufacturing";
import { chartViewModeSelector } from "../../../../../redux/modules/common/building/manufacturing/selectors";

import WeeksBackground from "./components/WeeksBackground/WeeksBackground";
import DaysBackground from "./components/DaysBackground/DaysBackground";
import YearBackground from "./components/YearBackground/YearBackground";

export interface ICalendarBackgroundProps {
  year: number | string;
  unitHeight: number;
}

const CalendarBackground: React.FC<ICalendarBackgroundProps> = ({ year, unitHeight }) => {
  const chartViewMode = useSelector(chartViewModeSelector);
  if (chartViewMode === WEEK)
    return <WeeksBackground year={year} unitHeight={unitHeight} />;
  if (chartViewMode === MONTH || chartViewMode === HALF_MONTH)
    return <DaysBackground year={year} unitHeight={unitHeight} />;
  if (chartViewMode === YEAR)
    return <YearBackground year={year} unitHeight={unitHeight} />;
  return null;
};

export default React.memo(CalendarBackground);
