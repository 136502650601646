import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import cn from "classnames";
import formatStringByPattern from "format-string-by-pattern";

import {
  clearData,
  contractorSelector,
  isLoadingSelector,
  loadContractor,
} from "../../../../../../redux/modules/common/constructing/counterparties/contractors/item/item";
import getShortFullName from "../../../../../../utils/getShortFullName";

import { Spinner } from "../../../../../UI/Spinner/Spinner";

import styles from "./ContractorInfo.module.scss";

const ContractorInfo = ({ contractorId }) => {
  const dispatch = useDispatch();
  const contractor = useSelector(contractorSelector);
  const contractorIsLoading = useSelector(isLoadingSelector);

  useEffect(() => {
    compose(dispatch, loadContractor)(contractorId);
    return () => compose(dispatch, clearData)();
  }, []);

  if (contractorIsLoading) return <Spinner />;

  return (
    <div>
      <section>
        <header className={cn(styles.header, styles.title)}>Основные</header>
        <div className={styles.main}>
          <div className={styles.block}>
            <h2 className={styles.title}>ИНН</h2>
            <div>{contractor.itn}</div>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>КПП</h2>
            <div>{contractor.iec}</div>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>ОГРН</h2>
            <div>{contractor.psrn}</div>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>Телефон</h2>
            <a href={`tel:${contractor.contact_manager.phone_number}`} className={styles.blueValue}>
              {formatStringByPattern("+7(999)999-99-99")(contractor.contact_manager.phone_number)}
            </a>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>Представитель</h2>
            <div>
              {getShortFullName(
                contractor.contact_manager.last_name,
                contractor.contact_manager.first_name,
                contractor.contact_manager.middle_name
              )}
            </div>
          </div>
          <div className={styles.block}>
            <h2 className={styles.title}>Email</h2>
            <a className={styles.blueValue} href={`mailto:${contractor.contact_manager.email}`}>
              {contractor.contact_manager.email}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default React.memo(ContractorInfo);
