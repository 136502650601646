import React, { useCallback } from "react";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";

import {
  providerRequisitesSelector,
  setProviderRequisitesModalOpenAction,
  updateProviderRequisites,
} from "../../../../../../../redux/modules/common/orderCard/orders";

import Modal from "../../../../../../UI/atoms/Modal";

import phoneIcon from "../../../../../../../images/tel.svg";
import mailIcon from "../../../../../../../images/email.svg";
import { getFullNameString } from "../../../../../../../utils/helpers";

import ProviderRequisitesForm from "./components/ProviderRequisitesForm/ProviderRequisitesForm";
import styles from "./ProviderContacts.module.scss";

const ProviderContacts = ({ info, orderId }) => {
  const dispatch = useDispatch();
  const { data: requisites, isModalOpen } = useSelector(providerRequisitesSelector);

  const openModal = useCallback(() => compose(dispatch, setProviderRequisitesModalOpenAction)(true), []);
  const closeModal = useCallback(() => compose(dispatch, setProviderRequisitesModalOpenAction)(false), []);

  const fullName = getFullNameString(info.first_name, info.last_name, info.middle_name);

  const updateRequisites = useCallback(
    (updatedRequisites) => compose(dispatch, updateProviderRequisites)(orderId, updatedRequisites),
    [orderId]
  );

  const onSubmit = useCallback(
    (values) => {
      const updatedFields = {};
      Object.entries(values).forEach(
        ([fieldName, fieldValue]) => requisites[fieldName] !== fieldValue && (updatedFields[fieldName] = fieldValue)
      );
      if (Object.keys(updatedFields).length !== 0) updateRequisites(updatedFields);
    },
    [updateRequisites, requisites]
  );

  return (
    <>
      <div title="Просмотреть сведения">
        <a className={styles.title} onClick={openModal}>
          <span>{fullName}</span>
        </a>
      </div>
      <Modal className={styles.modal} isOpen={isModalOpen} title="Контакты поставщика" onClose={closeModal}>
        <header className={styles.header}>
          <div className={styles.field}>
            <span>{fullName}</span>
          </div>
          {info.phone_number && (
            <div className={styles.field}>
              <img src={phoneIcon} alt="Иконка телефона" />
              <span>{info.phone_number}</span>
            </div>
          )}
          {info.email && (
            <div className={styles.field}>
              <img src={mailIcon} alt="Иконка email" />
              <span>{info.email}</span>
            </div>
          )}
        </header>
        <div className={styles.body}>
          <ProviderRequisitesForm requisites={requisites} onSubmit={onSubmit} />
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ProviderContacts);
