import React from 'react'
import Checkbox from '../../../../atoms/Checkbox/Checkbox'
import styles from "./TableRow.module.scss"
import Actions from "./../../../../atoms/Actions/Actions"
import cn from 'classnames'
import { stopEventPropagation } from "./../../../../../../utils/stopEventPropagation"
import { isNanChecker } from 'utils/isNanChecker'
import { ISerializedMaterialInPlan } from 'types/interfaces/Materials'
import { sliceTextByConstraint } from 'utils/sliceTextByConstraint'
import { dropNonSignificantZeros } from 'utils/dropNonSignificantZeros'
import useDebounce from 'utils/hooks/useDebouncedValue'

interface IProps {
    material: ISerializedMaterialInPlan, 
    onCheck?: (e: React.ChangeEvent<HTMLInputElement>, material: ISerializedMaterialInPlan) => void, 
    onChangeCount: (e: React.ChangeEvent<HTMLInputElement>) => void, 
    canBeChecked?: boolean, 
    onDelete?: () => void, 
    canDelete?: boolean, 
    isSelected?: boolean,
    isPrefilled?: boolean;
    wholeWorkCount?: number;
}

const initialLocalCountSetter = (material: IProps["material"], isPrefilled?: IProps["isPrefilled"]) => {
  if (!isPrefilled) return ""
  const measure = material.measure
  const value = isNanChecker(Number(material.local_count || material.count))
  if (measure?.toLowerCase().includes("шт")) {
    return String(Math.ceil(value))
  } 
  return String(value.toFixed(2))
}

function PlanMaterialsTableRow({ material, onCheck, onChangeCount, canBeChecked = true, onDelete, canDelete, isSelected = false, isPrefilled, wholeWorkCount }: IProps) {
  const [inputValue, setInputValue] = React.useState("")

  React.useEffect(() => {
    if (!material.local_count) return
    setInputValue(initialLocalCountSetter(material, isPrefilled))
  }, [wholeWorkCount])

  const debouncedMaterialCount = useDebounce(material.local_count, 1000)

  React.useEffect(() => {
    if (!material.local_count) return
    setInputValue(initialLocalCountSetter(material, isPrefilled))
  }, [debouncedMaterialCount, isPrefilled])
  
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCount(e)
    setInputValue(e.target.value)
  }

  const ref = React.useRef<HTMLDivElement>(null)

  const clickDrowningHandler = () => {
    ref.current?.click()
  }

  return (
    <div className={cn(styles.row, styles.planMaterialRow, { [styles.selected]: isSelected })} onClick={clickDrowningHandler}>
      <div className={styles.checkbox}>
        {canBeChecked && onCheck &&
          <Checkbox onCheck={(e: React.ChangeEvent<HTMLInputElement>) => onCheck(e, { ...material, local_count: inputValue })} checked={isSelected}>
            <div ref={ref} />
          </Checkbox>}
      </div>
      <div className={styles.name}
        title={material?.name}
      >
        {material?.name}
      </div>
      <div onClick={stopEventPropagation}>
        <input className={styles.input} onChange={changeHandler} value={inputValue} placeholder="Кол-во" />
      </div>
      <div className={cn(styles.name, styles.measure)} title={material?.count + " " + material?.measure}>
        {`${dropNonSignificantZeros(Number(material?.count).toFixed(2))} ${sliceTextByConstraint(material?.measure, 4)}`}
        {canDelete && <Actions canRemove={canDelete} onRemove={onDelete} className={cn(styles.delete, styles.deleteMaterial)} />}
      </div>
    </div>
  )
}

export default PlanMaterialsTableRow