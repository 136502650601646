import React, { useCallback } from "react";
import moment from "moment";
import axios from "axios";
import cn from "classnames";

import Icon from "components/UI/Icon";

import statusIn from "images/statuses/status-in.svg";
import statusOut from "images/statuses/status-out.svg";
import calendarBlue from "images/icons/calendarBlue.svg";

import Calendar from "../../../../UI/molecules/Calendar";
import FileBlock from "../../../../UI/molecules/FileBlock";
import { NavLinkIcon } from "../../../../UI/NavLinkIcon/NavLinkIcon";
import DynamicTitleInput, { SIZES } from "../../../../UI/atoms/DynamicTitleInput/DynamicTitleInput";
import DownloadButton from "../../../../UI/atoms/DownloadButton/DownloadButton";
import OpeningInfo from "../../../../UI/molecules/OpeningInfo";

import { PACKING_LIST_STATUSES } from "../../constants";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";
import downloadFile from "../../../../../utils/download";

import styles from "./index.module.scss";
import { ACT_TYPES } from "components/pages/ServiceAct/constants";


const Header = ({
  packingList,
  onChangeNumber,
  addFiles,
  deleteFile,
  canEdit,
  changeExecutionDate,
  subPackingList,
  filesPermissions,
  isSimplified,
  type = ACT_TYPES.PACKING_LISTS,
  propsFiles
}) => {
  const fullUrlModule = useUrlModuleWithObjectId();

  const handleDownload = useCallback(() => {
    const purchaserPrefix = type === ACT_TYPES.PACKING_LISTS ? "/purchaser" : ""
    downloadFile(`${axios.defaults.baseURL}${purchaserPrefix}/${type}/${packingList.id}/reports/excel/`);
  }, [packingList.id, type]);

  const getUrlPrefix = useCallback(() => {
    if (fullUrlModule !== "objects") return fullUrlModule;
    return `${fullUrlModule}/${packingList.building.id}`;
  }, [fullUrlModule, packingList.building.id]);

  const address = packingList.delivery_address || packingList.address

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>{type === "packinglists" ? "УПД №" : "Акт №"}</h2>
          {canEdit ? (
            <DynamicTitleInput
              title={packingList.number}
              onChange={onChangeNumber}
              placeholder="Введите номер"
              size={SIZES.NORMAL}
            />
          ) : (
            <div className={styles.number}>{packingList.number}</div>
          )}
        </div>
        <DownloadButton className={styles.downloadButton} onDownload={handleDownload} />
        <div className={styles.mainInfo}>
          <div className={styles.row}>
            <div>Дата поставки:</div>
            <Calendar
              classNameSelect={cn(styles.calendar, { [styles.calendarDisabled]: !canEdit })}
              icon={calendarBlue}
              value={moment(packingList.delivery_date || packingList.date)}
              setValue={changeExecutionDate}
              disabled={!canEdit}
            />
          </div>
          <div className={styles.row}>
            <div>заказ:</div>
            <div className={styles.rowValue}>
              {!isSimplified ? (
                <NavLinkIcon
                  to={`/${getUrlPrefix()}/orders/${packingList.order.id}`}
                  title={`№${packingList.order.number}`}
                />
              ) : (
                <div className={styles.hiddenText}>{`№${packingList.order.number}`}</div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.topBlock}>
          <div className={styles.col}>
            <div className={styles.topBox}>
              <div className={styles.topBoxName}>Статус:</div>
              <div className={styles.myOrdersStatus}>
                <span>
                  {packingList.status === PACKING_LIST_STATUSES.accepted ? (
                    <Icon icon={statusIn} className={styles.arrowLeft} />
                  ) : (
                    <Icon icon={statusOut} className={styles.arrowLeft} />
                  )}
                  {packingList.status_name}
                </span>
              </div>
            </div>
            <div className={styles.topBox}>
              <div className={styles.topBoxName}>Заказчик:</div>
              <OpeningInfo info={packingList.executor_purchaser} title="Контакты заказчика" />
            </div>
            <div className={styles.topBox}>
              <div className={styles.topBoxName}>Поставщик:</div>
              <OpeningInfo info={packingList.executor_provider} title="Контакты поставщика" />
            </div>
          </div>
          <div className={cn(styles.col, styles.middleCol)}>
            {packingList.status === PACKING_LIST_STATUSES.accepted && (
              <div className={styles.topBox}>
                <div className={styles.topBoxName}>Принял:</div>
                <OpeningInfo info={packingList.accepted_user} title="Поставку принял" />
              </div>
            )}
            <div className={styles.topBox}>
              <div className={styles.topBoxName}>Адрес доставки:</div>
              <div>{address ? address : "отсутствует"}</div>
            </div>
            {subPackingList && (
              <div className={styles.topBox}>
                <div className={styles.topBoxName}>УПД на остаток:&nbsp;</div>
                {!isSimplified ? (
                  <NavLinkIcon
                    to={`/${fullUrlModule}/documents/packing-lists/${subPackingList.id}`}
                    title={`${subPackingList.number ? `УПД № ${subPackingList.number}` : "УПД"}`}
                  />
                ) : (
                  <div className={styles.hiddenText}>
                    {`${subPackingList.number ? `УПД № ${subPackingList.number}` : "УПД"}`}
                  </div>
                )}
              </div>
            )}
          </div>
          <FileBlock
            className={styles.fileBlock}
            files={propsFiles || packingList.files}
            addFiles={addFiles}
            deleteFile={deleteFile}
            permissions={filesPermissions}
          />
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
