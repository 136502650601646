import React, { useCallback, useRef, useState } from "react";
import cn from "classnames";

import { KS_TYPES } from "components/UI/molecules/Ks23Modal/types";
import Ks23Modal from 'components/UI/molecules/Ks23Modal/Ks23Modal'

import useOnClickOutside from "hooks/useOnClickOutside";

import ThreeDotsMenuIcon from "images/icons/ThreeDotsMenuIcon";

import styles from "./FormMenu.module.scss";


const FormMenu = ({ expenditure, onOpen, year, month }) => {
  const ref = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);

  const handleCloseKsModal = useCallback(() => {
    setActiveMenu(null);
  }, [])

  const handleMenuClick = (name) => {
    setActiveMenu(name);
  };

  const onClose = useCallback(() => {
    if (!activeMenu) setIsOpen(false);
  }, [activeMenu]);

  useOnClickOutside(ref, onClose);

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={cn(styles.button, {
          [styles.isOpen]: isOpen,
        })}
        onClick={() => {
          setIsOpen(!isOpen);
          onOpen && onOpen((prev) => !prev);
        }}
      >
        <ThreeDotsMenuIcon />
      </div>
      {isOpen && (
        <div className={styles.content}>
          <div className={styles.top} />
          <div className={styles.bottom}>
            <div className={styles.title}>Сформировать:</div>
            <div className={styles.buttonForm} onClick={() => handleMenuClick(KS_TYPES.ks2)}>
              Форма КС2
            </div>
            <div className={styles.buttonForm} onClick={() => handleMenuClick(KS_TYPES.ks3)}>
              Форма КС3
            </div>
          </div>
        </div>
      )}
      <Ks23Modal
        type={activeMenu}
        isOpen={!!activeMenu}
        onClose={handleCloseKsModal}
        expenditureId={expenditure?.id}
        year={year}
        month={month}
        isPrefetch={isOpen}
      />
    </div>
  );
};

export default React.memo(FormMenu);
