import axios from "axios";
import update from "immutability-helper";
import { createSelector } from "reselect";
import { compose } from "redux";
import { errorCatcher } from "../../../../../utils/errorCatcher";
import { message } from "antd";

const initialState = {
  exports: null,
  isLoading: true,
};
export const DEFAULT_LIMIT_EXPORT = 10;

const moduleName = "exports";

const LOAD_EXPORTS = `${moduleName}/LOAD_EXPORTS`;
const SET_EXPORTS = `${moduleName}/SET_EXPORTS`;
const SET_MORE_EXPORTS = `${moduleName}/SET_MORE_EXPORTS`;
const ADD_EXPORT = `${moduleName}/ADD_EXPORT`;

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_EXPORTS:
      return update(state, {
        isLoading: {
          $set: true,
        },
      });

    case SET_EXPORTS:
      return update(state, {
        exports: {
          $set: payload,
        },
        isLoading: {
          $set: false,
        },
      });
    case SET_MORE_EXPORTS:
      return {
        ...state,
        exports: {
          ...state?.exports,
          results: [...state?.exports?.results, ...payload?.results],
        },
        isLoading: false,
      };
    case ADD_EXPORT:
      return { ...state, exports: { results: [payload, ...state.exports.results], count: state.exports.count + 1 } };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const exportsSelector = createSelector(stateSelector, (state) => state.exports);
export const exportsIsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);

export const addExportAction = (payload) => ({ type: ADD_EXPORT, payload });

export const loadExports = (() => {
  const CancelToken = axios.CancelToken;
  let lp_cancel;
  let lastPaginationParams, lastFilterParams;
  return (paginationParams = lastPaginationParams, filterParams = lastFilterParams) => {
    lastPaginationParams = paginationParams;
    lastFilterParams = filterParams;

    if (lp_cancel) lp_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: DEFAULT_LIMIT_EXPORT,
      },
      cancelToken: new CancelToken((c) => {
        lp_cancel = c;
      }),
    };
    return (dispatch) => {
      dispatch({
        type: LOAD_EXPORTS,
      });
      return axios.get(`/export/`, config).then((response) =>
        dispatch({
          type: SET_EXPORTS,
          payload: response.data,
        })
      );
    };
  };
})();

export const makeExport = (exported) => (dispatch) => {
  return axios
    .post(`/export/`, exported)
    .then((response) => {
      compose(dispatch, addExportAction)(response.data);
      message.success("Выгрузка сформирована");
    })
    .catch(errorCatcher);
};

export const deleteExport = (() => {
  const CancelToken = axios.CancelToken;
  let lp_cancel;
  return (id) => {
    if (lp_cancel) lp_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        lp_cancel = c;
      }),
    };
    return (dispatch) => {
      return axios.delete(`/export/${id}/`, config).then((response) => dispatch(loadExports()));
    };
  };
})();
export const loadMoreExport = (params) => {
  const config = { params: { ...params, limit: DEFAULT_LIMIT_EXPORT } };

  return (dispatch) => {
    dispatch({ type: LOAD_EXPORTS });

    axios
      .get(`/export/`, config)
      .then((response) =>
        dispatch({
          type: SET_MORE_EXPORTS,
          payload: response.data,
        })
      )
      .catch((error) => console.error(error));
  };
};
