export const eventsTypes = {
    task: "Задача",
    payment: "Оплата",
    supply: "Поставка"
}

export const CALENDAR_LEGEND_OPTIONS = [
    {
        text: "Оплачено",
        color: "#a866ee"
    },
    {
        text: "Оплата",
        color: "#fff",
        border: "#a866ee"
    },
    {
        text: "Поставка",
        color: "#ee9226"
    },
    {
        text: "Задача",
        color: "#73c0e3"
    },
]