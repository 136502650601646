import { EXPENDITURE_TYPES_NAMES } from 'utils/constant'

export const prepareSections = (sections) => {
  return sections.map((section) => ({
    ...section,
    isOpen: false
  }))
}

const getExpenditureParentSection = (section) => {
  const expenditureSection = JSON.parse(JSON.stringify(section))
  expenditureSection.name = 'Вид работ'
  expenditureSection.id = `${section.id}_virtual`
  expenditureSection.isOpen = false
  return expenditureSection
}

const prepareChildrenSections = (childrenSections) => {
  const preparedSections = prepareSections(childrenSections)

  return preparedSections.map((section) => {
    return section
  })
}

export const addSectionChildren = (sections, parentId, childrenSections) => {
  const parentSection = sections.find((section) => section.id === parentId)
  if (parentSection) {
    parentSection.children = prepareChildrenSections(childrenSections)
    parentSection.isOpen = true
  } else {
    sections.forEach((section) => {
      if (section.children) {
        addSectionChildren(section.children, parentId, childrenSections)
      }
    })
  }
}

export const setSectionIsOpen = (sections, sectionId, isOpen) => {
  const parentSection = sections.find((section) => section.id === sectionId)
  if (parentSection) {
    parentSection.isOpen = isOpen
  } else {
    sections.forEach((section) => {
      if (section.children) {
        setSectionIsOpen(section.children, sectionId, isOpen)
      }
    })
  }
}

const prepareExpenditureAuditData = (auditData) => {
  const preparedAuditData = { ...auditData }
  for (const key in preparedAuditData) {
    preparedAuditData[key] = Number(preparedAuditData[key])
  }
  return preparedAuditData
}

const calculateExpenditureAuditData = (sections) => {
  const auditData = sections.reduce((acc, section, idx) => {
    const preparedAuditData = prepareExpenditureAuditData(section.audit_data)
    if (idx === 0) {
      acc = preparedAuditData
    } else {
      for (const key in acc) {
        acc[key] += preparedAuditData[key]
      }
    }
    return acc
  }, null)
  return auditData
}

export const prepareSectionExpenditures = (sections, parentId) => {
  const categorizedSections = sections.reduce((acc, section) => {
    if (!acc[section.expenditure_type]) {
      acc[section.expenditure_type] = [section]
    } else {
      acc[section.expenditure_type].push(section)
    }
    return acc
  }, {})

  const preparedSections = Object.entries(categorizedSections).map(([type, sections], idx) => {
    return {
      id: `${idx}_expenditure_${parentId}`,
      name: EXPENDITURE_TYPES_NAMES[type],
      audit_data: calculateExpenditureAuditData(sections),
      isOpen: false,
      children: sections
    }
  })

  return preparedSections
}

const getTableSection = (section) => {
  return {
    key: section.id,
    ...section.audit_data
  }
}

const getSectionsData = (sections) => {
  const sectionsData = []
  sections.forEach((section) => {
    sectionsData.push(getTableSection(section))
    if (section.isOpen) {
      const children = getSectionsData(section.children)
      sectionsData.push(...children)
    }
  })
  return sectionsData
}

export const prepareTableSections = (sections) => {
  const preparedSections = getSectionsData(sections)
  return preparedSections
}

export const prepareSectionsAllObjects = (rawData) => {
  const res = []
  for (let key in rawData) {
    res.push({
      id: key,
      name: rawData[key].name,
      subsection_count: 2,
      isOpen: false,
      isBuilding: true,
      audit_data: rawData[key].audit_data || {
        current_period_delta: "0.00",
        current_period_fact: "0.00",
        current_period_plan: "0.00",
        project_delta: "0.00",
        project_fact: "0.00",
        project_plan: "0.00",
        selected_period_delta: "0.00",
        selected_period_fact: "0.00",
        selected_period_plan: "0.00"
      },
      children: rawData[key].sections?.map(el =>({...el, isOpen: false, building_id: key})) || []
    })
  }
  return res
}

