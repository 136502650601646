import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';

import { DATE_TYPE, MonthArray} from 'utils/constant';
import Icon from 'components/UI/Icon';
import newArrow from '../../../../../../images/icons/arrow-calendar.svg'
import rightArow from '../../../../../../images/icons/arrowRight.svg'

import styles from "./index.module.scss";
import arrowDownSelect from 'images/icons/arrowDownSelect.svg';

const MonthPicker = ({date, setDate}) => {
  const setMonth = (val) => {
    const currentMonth = moment(date).month();
    let month;
    let year = moment(date).year();
    if (val === 'previous') {
      month = MonthArray.find(item => item.id === (currentMonth > 0 ? currentMonth - 1 : 11));
      year = month.id === 11 ? year - 1 : year;
    }
    if (val === 'next') {
      month = MonthArray.find(item => item.id === (currentMonth < 11 ? currentMonth + 1 : 0));
      year = month.id === 0 ? year + 1 : year;
    }
    setDate(moment(date).year(year).month(month.id));
  }

  const handleSetYear = (value) => {
    let year = moment(date).year()
    if(value === 'next') {
      year += 1
    } 
    if(value === 'previous') {
      year -= 1
    }
    setDate(moment(date).year(year))
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerFlex}>
          <div style={{cursor:'pointer'}} onClick={() => handleSetYear('previous')}>
              <img src={newArrow} alt="" />
              <img src={newArrow} alt="" />
          </div>

          <div className={styles.flexFirstArr}>
              <div style={{cursor:'pointer'}} onClick={() => setMonth('previous')}>
                  <img src={newArrow} alt="" />
              </div>
              <div className={styles.flexDate}>
                  <div style={{marginRight:'5px'}} className={styles.monthTitle}>{moment(date).format('D')}</div>
                  <div style={{marginRight:'5px'}} className={styles.monthTitle}>{MonthArray[moment(date).month()]?.label}</div>
                  <div className={styles.monthTitle}>{moment(date).format('Y')}</div>
              </div>
              <div style={{cursor:'pointer'}} onClick={() => setMonth('next')}>
                  <img src={rightArow} alt="" />
              </div>
          </div>

          <div style={{cursor:'pointer'}} onClick={() => handleSetYear('next')}>
              <img  src={rightArow} alt="" />
              <img src={rightArow} alt="" />
          </div>
      </div>
    </div>
  )
}

export default MonthPicker
