import JornalDay from 'components/UI/organism/WorkOrMaterialsModals/JournalTicketModal/JornalDay'
import moment from 'moment'
import React from 'react'
import { personFullNameWithId } from 'types/personsTypes'
import getShortFullName from 'utils/getShortFullName'
import styles from "./ManufacturingModal.module.scss"
import ManufacturingModalDivider from './ManufacturingModalDivider/ManufacturingModalDivider'
import cn from "classnames"
import CalendarRange from 'components/UI/molecules/CalendarRange'
import { splitThousands } from 'utils/splitThousands'

type propsType = {
    projectAmount?: string;
    measure: string;
    dateStart: string;
    dateEnd: string;

    passingDate?: string;
    passingAuthor?: personFullNameWithId;
    passingCount?: string;
    investedAmount?: number;

    acceptedDate?: string;
    acceptedAuthor?: personFullNameWithId;
    acceptedCount?: string;
    acceptedAmount?: number;

    planCreatingDate?: string,
    planCreator?: personFullNameWithId,
    planApprovingDate?: string;
    planApprover?: personFullNameWithId;
    planCount?: string;

}

function ManufacturingModalContentFact({
    projectAmount,
    measure,
    dateStart,
    dateEnd,

    passingAuthor,
    passingCount,
    passingDate,
    investedAmount,

    acceptedAmount,
    acceptedAuthor,
    acceptedCount,
    acceptedDate,

    planApprover,
    planApprovingDate,
    planCount,
    planCreatingDate,
    planCreator

}: propsType) {
    return (
        <>
            {planCreatingDate && <>
                <ManufacturingModalDivider type="plan" className={styles.mt0} />
                {planCreatingDate && <div className={styles.pair}>
                    <JornalDay day={moment(planCreatingDate).format("DD/MM/YYYY")} time={moment(planCreatingDate).format("HH:mm")} />
                    <div className={styles.pass}>
                        <b>Создал :</b>
                        <span>{getShortFullName(planCreator)}</span>
                    </div>
                </div>}
                {planApprovingDate && <div className={styles.pair}>
                    <JornalDay day={moment(planApprovingDate).format("DD/MM/YYYY")} time={moment(planApprovingDate).format("HH:mm")} />
                    <div className={styles.pass}>
                        <b>Согласовал :</b>
                        <span>{getShortFullName(planApprover)}</span>
                    </div>
                </div>}
                <div className={styles.pair}>
                    <div className={styles.info}>
                        <b>Кол-во (план):</b>
                        <span>{planCount} ({measure})</span>
                    </div>
                    <div className={styles.info}>
                        <b>Стоимость (по проекту):</b>
                        <span>{splitThousands(projectAmount)}</span>
                    </div>
                </div>
            </>}

            <ManufacturingModalDivider type="progress" className={planCreatingDate ? undefined : styles.mt0} />
            {passingDate && <div className={styles.pair}>
                <JornalDay day={moment(passingDate).format("DD/MM/YYYY")} time={moment(passingDate).format("HH:mm")} />
                <div className={styles.pass}>
                    <b>Выполнил:</b>
                    <span>{getShortFullName(passingAuthor, 13)}</span>
                </div>
            </div>}
            <div className={styles.pair}>
                <div className={styles.info}>
                    <b>Кол-во (к сдаче):</b>
                    <span>{passingCount} ({measure})</span>
                </div>
                <div className={styles.info}>
                    <b>Израсходовано:</b>
                    <span>{splitThousands(investedAmount)}</span>
                </div>
            </div>
            <div className={cn(styles.pair, styles.centered)}>
                <b>Период выполнения:</b>{/* @ts-ignore */}
                <CalendarRange
                    defaultDateStart={dateStart}
                    defaultDateEnd={dateEnd}
                    disabled
                    classNameSelect={styles.calendar}
                />
            </div>

            {acceptedDate && <ManufacturingModalDivider type="accepted" />}
            {acceptedDate && <div className={styles.pair}>
                <JornalDay day={moment(acceptedDate).format("DD/MM/YYYY")} time={moment(acceptedDate).format("HH:mm")} />
                <div className={styles.pass}>
                    <b>Принял :</b>
                    <span>{getShortFullName(acceptedAuthor)}</span>
                </div>
            </div>}
            {acceptedDate && <div className={styles.pair}>
                <div className={styles.info}>
                    <b>Кол-во (принято) :</b>
                    <span>{acceptedCount} ({measure})</span>
                </div>
                <div className={styles.info}>
                    <b>Стоимость (по проекту):</b>
                    <span>{splitThousands(acceptedAmount)}</span>
                </div>
            </div>}
        </>
    )
}

export default ManufacturingModalContentFact