import React from "react";

import Modal from "components/UI/atoms/Modal";
import ButtonBase from "components/UI/atoms/ButtonBase";

import styles from "./index.module.scss";

const SplitModal = ({ isOpen, onClose, acceptPackingList, acceptPackingListWithSplitting }) => {

  const handleAcceptList = () => {
    acceptPackingList()
    // onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} modalType="small">
      <div className={styles.modalContent}>
        <p>Сформировать новую УПД на остаток?</p>
        <footer className={styles.footer}>
          <ButtonBase onClick={handleAcceptList} secondary medium>Нет</ButtonBase>
          <ButtonBase onClick={acceptPackingListWithSplitting} medium>Да</ButtonBase>
        </footer>
      </div>
    </Modal>
  );
};

export default React.memo(SplitModal);