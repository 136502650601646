import React from 'react';
import cn from "classnames";

import SearchFilterTable from '../SearchFilterTable';
import SearchRangeDateFilter from '../SearchRangeDateFilter';
import SearchSelectFilter from '../SearchSelectFilter';
import SearchFilterSum from '../SearchFilterSum';

import styles from "./TableReusableHead.module.scss";


type cellPropsType = {
  children?: React.ReactNode;
  className?: string;
  isCentered?: boolean;
  filterType?: "text" | "select" | "datesRange" | "sum";
  onChangeFilter?: (name: string, value: string) => void;
  filterTitle?: string;
  filterOptions?: any[];
  filterField?: string;
  rangeStartField?: string;
  rangeEndField?: string;
  isRighted?: boolean;
  isLast?: boolean
}

function Cell({
  children,
  className,
  isCentered,
  filterOptions,
  filterTitle,
  filterType,
  onChangeFilter,
  filterField,
  rangeEndField,
  rangeStartField,
  isRighted,
  isLast
}: cellPropsType) {
  return (
    <div className={cn(styles.headerCell, className, { [styles.centered]: isCentered, [styles.righted]: isRighted })}>
      {children}
      {filterType === "text" && (
        <SearchFilterTable 
          title={filterTitle!} 
          setFilter={value => onChangeFilter?.(filterField!, value)} 
          last={isLast} 
        />
      )}
      {filterType === "datesRange" && (
        <SearchRangeDateFilter
          last={isLast}
          title={filterTitle!}
          setFilterEnd={(value: string) => onChangeFilter?.(rangeEndField!, value)}
          setFilterStart={(value: string) => onChangeFilter?.(rangeStartField!, value)}
        />
      )}
      {filterType === "select" && (
        <SearchSelectFilter
          title={filterTitle!}
          setFilter={(value: string) => onChangeFilter?.(filterField!, value)}
          filterName={filterOptions}
          last={isLast} 
        />
      )}
      {filterType === "sum" && (
        <SearchFilterSum
          last={isLast}
          title={filterTitle!}
          setFilterStart={(value: string) => {
            onChangeFilter?.(rangeStartField!, value);
          }} 
          setFilterEnd={(value: string) => {
            onChangeFilter?.(rangeEndField!, value);
          }}
        />
      )}
    </div>
  )
}

export const TableReusableHeaderCell = React.memo(Cell);


type propsType = {
  className?: string;
  children?: React.ReactNode;
  isWithEar?: boolean;
  isSticky?: boolean;
}

function TableReusableHead({
  className,
  children,
  isSticky,
  isWithEar
}: propsType) {
  return (
    <div className={cn(styles.tableHeader, className, { [styles.sticky]: isSticky, [styles.withEar]: isWithEar })}>
      {children}
    </div>
  )
}

export default React.memo(TableReusableHead)