import React from "react";

import ButtonBack from "../ButtonBack";

import styles from "./BackNavigationBar.module.scss";

const BackNavigationBar = ({ backLink, title, rightSideText, onBack }) => {
  return (
    <nav className={styles.backNavigationBar}>
      <div className={styles.leftSide}>
        <ButtonBack className={styles.button} link={backLink} onClick={onBack} />
        <p className={styles.text}>{title}</p>
      </div>
      <div className={styles.rightSide}>
        <p className={styles.text}>{rightSideText}</p>
      </div>
    </nav>
  );
};

export default React.memo(BackNavigationBar);