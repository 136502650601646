import axios from 'axios';

export function apiGetSections(objectId, type) {
  return axios.get(`/building/${objectId}/materials_v2/${type}/sections/`);
}
export function apiGetSubSections(objectId, type, Id) {
  return axios.get(`building/${objectId}/materials_v2/${type}/sections/${Id}/`);
}
export function apiPostSubSections(objectId, config) {
  return axios.post(`building/${objectId}/sections/`, config);
}
export function apiPostExpenditures(objectId, type, config) {
  return axios.post(`building/${objectId}/materials/${type}/`, config);
}

export function apiUpdateExpenditure(objectId, typeUrl, Id, config) {
  return axios.patch(`/building/${objectId}/materials/${typeUrl}/${Id}/`, config);
}
export function apiDeleteSections(objectId, Id) {
  return axios.delete(`building/${objectId}/sections/${Id}/`);
}
export function apiDeleteExpenditures(objectId, typeUrl, Id) {
  return axios.delete(`/building/${objectId}/materials/${typeUrl}/${Id}/`);
}
export const apiAddFile = (objectId, itemId, data) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post(`/building/${objectId}/materials/${itemId}/files/`, data, config);
};
export const apiDeleteFile = (objectId, itemId, fileId) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.delete(`/building/${objectId}/materials/${itemId}/files/${fileId}/`, config);
};

export function apiUpdateSpecificationDetail(objectId, specificationId, data) {
  return axios.patch(`/building/${objectId}/products/${specificationId}/`, data);
}

