import React from 'react';
import styles from './MiniModal.module.scss'
import useEscapeHandler from '../../../../../../../../utils/hooks/useEscapeHandler'

export const MiniModal = ({visible, setVisible, children, ...props}) => {
    useEscapeHandler(() => setVisible(false))
    const rootClasses = [styles.miniModal]
    const rootContent = [styles.miniModalContent]

    if(visible) {
        rootClasses.push(styles.active)
        rootContent.push(styles.activeContent)
    }

    return (
            <div {...props} className={rootClasses.join(' ')} onClick={() => setVisible(!visible)}>
                <div className={rootContent.join(' ')} onClick={e => e.stopPropagation()}>
                    {children}
                </div>
            </div>
    ) 
};
