import React from 'react';
import styles from './Chapter.module.scss';
import { Field, Form } from 'react-final-form';
import cn from 'classnames';
import ButtonBase from 'components/UI/atoms/ButtonBase';
import InputBase from 'components/UI/atoms/InputBase';
import { useDispatch } from 'react-redux';
import { addSection, addSubSection } from 'redux/modules/common/building/materials/specification';
import CrossRoundedIcon from 'images/icons/CrossRoundedIcon';

const Chapter = ({ objId, close, label = 'раздел', name, id, titleBtn }) => {
  const dispatch = useDispatch();
  const submitRequisite = (value) => {
    if (titleBtn === 'Добавить раздел') {
      dispatch(addSection(objId, value));
    }
    if (titleBtn === 'Добавить подраздел') {
      dispatch(addSubSection(objId, { ...value, parent_id: id }));
    }
    close();
  };
  return (
    <div className={styles.container}>
      <Form
        onSubmit={(values) => submitRequisite(values)}
        render={({ handleSubmit, pristine, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.topSide}>
              <div className={styles.block1Title}>
                <span className={styles.title}>Добавить {label}</span>
                <div className={styles.closeIcon} onClick={close}>
                  <CrossRoundedIcon />
                </div>
              </div>
              <div className={styles.field3}>
                <Field
                  name="name"
                  label={name ? name : 'Наименование раздела'}
                  component={InputBase}
                  classNameInput={styles.select}
                  placeholder="Введите наименование раздела"
                />
              </div>
            </div>
            <div className={cn(styles.actionBlock
)}>
              <ButtonBase primary medium type="submit" disabled={pristine || hasValidationErrors}>
                Добавить
              </ButtonBase>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default Chapter;
