import React from "react";

import SwitchedPermission from "./components/SwitchedPermission/SwitchedPermission";

import styles from "./Permissions.module.scss";

const Permissions = ({ permissions, checkedPermissions, onChangeChecked, canEdit }) => {
  return (
    <div className={styles.permissions}>
      {permissions.map((permission) => (
        <SwitchedPermission
          permission={permission}
          checkedPermissions={checkedPermissions}
          onChangeChecked={onChangeChecked}
          canEdit={canEdit}
          key={permission.name}
        />
      ))}
    </div>
  );
};

export default React.memo(Permissions);
