import axios from 'axios';
import { message } from 'antd';
import { createSelector } from 'reselect';

const moduleName = 'posts';
const LOAD_POSTS = `${moduleName}/LOAD_POSTS`;
const SET_POSTS = `${moduleName}/SET_POSTS`;
const ADD_DELETE_WORKER_ID = `${moduleName}/ADD_DELETE_WORKER_ID`;
const REMOVE_DELETE_WORKER_ID = `${moduleName}/REMOVE_DELETE_WORKER_ID`;

const initialState = {
  posts: [],
  deletePostsId: [],
  isLoading: true
};

export default (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case LOAD_POSTS:
      return {
        ...state,
        posts: payload,
        isLoading: true,
      };
    case SET_POSTS:
      return {
        ...state,
        posts: payload,
        isLoading: false,
      };
    case ADD_DELETE_WORKER_ID:
      return {
        ...state,
        deletePostsId: [...state.deletePostsId, payload]
      };
    case REMOVE_DELETE_WORKER_ID:
      return {
        ...state,
        deletePostsId: state.deletePostsId.filter(id => id !== payload)
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const postsDeleteLoadingSelector = createSelector(stateSelector, (state) => state.deletePostsId);
export const postsSelector = createSelector(stateSelector, (state) => state.posts);
export const postsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);

export const loadPosts = (() => {
    return (dispatch) => {
      dispatch({
        type: LOAD_POSTS,
      });
      axios.get(`/posts/`).then((response) =>
        dispatch({
          type: SET_POSTS,
          payload: response.data,
        })
      );
    };
});

export const createPost = (buildingId, data) => {
  return (dispatch) => {
    axios.post(`/building/${buildingId}/posts/`, data).then((response) =>
      dispatch(loadPosts(buildingId, false))
    );
  };
};


export const deletePost = (buildingId, workerId) => {
  return (dispatch) => {
    dispatch({
      type: ADD_DELETE_WORKER_ID,
      payload: workerId
    });
    axios.delete(`/building/${buildingId}/posts/${workerId}/`).then((response) => {
        dispatch(loadPosts(buildingId, false));
        dispatch({
          type: REMOVE_DELETE_WORKER_ID,
          payload: workerId
        });
      }
    );
  };
};



export const editPost = (buildingId, workerId, data) => {
  return (dispatch) => {
    axios.patch(`/building/${buildingId}/posts/${workerId}/`, data).then((response) => {
        dispatch(loadPosts(buildingId, false));
      }
    );
  };
};
