import React from 'react';

function PaperClipIcon({
  width = '12.681',
  height = '27.846',
  fill = '#000',
  className = null,
  style = null
}) {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12.681 27.846"
    >
      <g transform="translate(-760 -111.151)">
        <path
          fill={fill}
          d="M227.487,8.056a.855.855,0,0,0-.869.854V21.528a4.664,4.664,0,0,1-4.641,4.61,4.61,4.61,0,0,1-4.61-4.61V4.874a3.046,3.046,0,0,1,.916-2.2,3.111,3.111,0,0,1,4.47,0,3.046,3.046,0,0,1,.916,2.2V19.045a1.544,1.544,0,0,1-1.568,1.568,1.57,1.57,0,0,1-1.552-1.568V11.672a.854.854,0,1,0-1.707,0v7.373a3.162,3.162,0,0,0,.947,2.328,3.127,3.127,0,0,0,2.313.947,3.2,3.2,0,0,0,2.328-.947,3.106,3.106,0,0,0,.947-2.328V4.874a4.712,4.712,0,0,0-1.428-3.43,4.8,4.8,0,0,0-6.861,0,4.711,4.711,0,0,0-1.428,3.43V21.528a6.341,6.341,0,1,0,12.681,0V8.91A.842.842,0,0,0,227.487,8.056Z"
          transform="translate(544.34 111.151)"
        />
      </g>
    </svg>
  );
}

export default React.memo(PaperClipIcon);