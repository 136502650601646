import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getLocalMaterialsTree,
  getLocalTree,
  getMaterialsMaps,
  getMaterialsWeekMaps,
  getMaterialsWeekTree,
  getWeekWorksTree,
  getWeekWorksMaps,
  getWorksMaps,
  WORKS_TAB_ID,
  MATERIALS_TAB_ID,
  getMonthsWorksTree,
} from "../constants";

import { getMonthEnumerateDataForChart } from "../utils";

import {
  IMonthArrayElement,
  IProcessedBranch,
  IProject,
  IProjectMaterialsDataInterval,
  IProjectWorksDataInterval,
  ISpittingTreeElement,
  ManufacturingTabsType,
} from "../types";

import { HALF_MONTH, MONTH, WEEK, YEAR } from "redux/modules/common/building/manufacturing/manufacturing";
import { dropLoadedChartMonths } from "redux/modules/common/building/manufacturing/thunks";
import {
  planSelector,
  projectDataSelector,
  projectWeekDataSelector,
  chartViewModeSelector,
  intervalSelector,
  materialDataSelector,
  materialIntervalSelector,
  materialsSelector,
  materialWeekDataSelector,
  weekPlanSelector,
  weekMaterialsSelector,
} from "redux/modules/common/building/manufacturing/selectors";

export interface IUseChartTreesProps {
  tree: ISpittingTreeElement[];
  year: number;
  month: IMonthArrayElement;
  projects?: IProject[];
  startWeek: number;
  endWeek: number;
  projectInterval?: IProjectWorksDataInterval | IProjectMaterialsDataInterval;
  monthMarkers: number[];
  type: ManufacturingTabsType;
  isGeneratingSpittingTree: boolean;
}

export const useChartTrees = ({
  tree,
  year,
  month,
  projects,
  startWeek,
  endWeek,
  monthMarkers,
  type,
  isGeneratingSpittingTree,
}: IUseChartTreesProps) => {
  const [calcTrees, setCalcTrees] = useState<IProcessedBranch[][]>([]);
  const chartViewMode = useSelector(chartViewModeSelector);
  const dispatch = useDispatch();

  const multipleProjectsWorksData = useSelector(projectDataSelector);
  const multipleProjectsMaterialsData = useSelector(materialDataSelector);
  const multipleProjectsWeekWorksData = useSelector(projectWeekDataSelector);
  const multipleProjectsWeekMaterialsData = useSelector(materialWeekDataSelector);

  const interval = useSelector(intervalSelector);
  const materialInterval = useSelector(materialIntervalSelector);

  const singleProjectWorksData = useSelector(planSelector);
  const singleProjectWeekWorksData = useSelector(weekPlanSelector);
  const singleProjectMaterialsData = useSelector(materialsSelector);
  const singleProjectWeekMaterialsData = useSelector(weekMaterialsSelector);

  const monthEnumerateData = useMemo(() => getMonthEnumerateDataForChart({ year, monthMarkers }), [year, monthMarkers]);

  const generateDaysWorkTree = useCallback(() => {
    const trees: IProcessedBranch[][] = [];
    const data = projects ? multipleProjectsWorksData : singleProjectWorksData;
    if (!data) return;
    const { work, plan, workDays, planDays } = getWorksMaps(data, projects, interval);
    monthEnumerateData.forEach((x) =>
      trees.push(getLocalTree(tree, x.maxDay, x.year, x.monthNumber, x.offsetLeft, work, plan, workDays, planDays))
    );
    setCalcTrees(trees);
  }, [projects, multipleProjectsWorksData, singleProjectWorksData, interval, monthEnumerateData, tree]);

  const generateDaysMaterialTree = useCallback(() => {
    const trees: IProcessedBranch[][] = [];
    const data = projects ? multipleProjectsMaterialsData : singleProjectMaterialsData;
    if (!data) return;
    const { on_stock, plans, purchases, stockless, accepted, payed } = getMaterialsMaps(
      data,
      projects,
      materialInterval
    );
    monthEnumerateData.forEach((x) =>
      trees.push(
        getLocalMaterialsTree(
          tree,
          x.maxDay,
          x.year,
          x.monthNumber,
          x.offsetLeft,
          on_stock,
          plans,
          purchases,
          stockless,
          accepted,
          payed
        )
      )
    );
    setCalcTrees(trees);
  }, [projects, multipleProjectsMaterialsData, singleProjectMaterialsData, materialInterval, monthEnumerateData, tree]);

  const generateWeeksWorkTree = useCallback(() => {
    const data = projects ? multipleProjectsWeekWorksData : singleProjectWeekWorksData;
    if (!data) return;
    const { workMap, planMap, workDaysMap, planDaysMap } = getWeekWorksMaps({
      data,
      projectInterval: interval,
      projects,
      year: +year,
    });

    const localTree = getWeekWorksTree({
      tree,
      workMap,
      planMap,
      startWeek,
      endWeek,
      year,
      workDaysMap,
      planDaysMap,
    });

    setCalcTrees([localTree]);
  }, [projects, multipleProjectsWeekWorksData, singleProjectWeekWorksData, interval, year, tree, startWeek, endWeek]);

  const generateWeeksMaterialTree = useCallback(() => {
    const data = projects ? multipleProjectsWeekMaterialsData : singleProjectWeekMaterialsData;
    if (!data) return;
    const { on_stock, plans, purchases, stockless, accepted, payed } = getMaterialsWeekMaps({
      projects,
      projectInterval: materialInterval,
      year,
      data,
    });
    const localTree = getMaterialsWeekTree({
      tree,
      startWeek,
      endWeek,
      year,
      on_stock,
      plans,
      purchases,
      stockless,
      accepted,
      payed,
    });
    setCalcTrees([localTree]);
  }, [
    projects,
    multipleProjectsWeekMaterialsData,
    singleProjectWeekMaterialsData,
    materialInterval,
    year,
    tree,
    startWeek,
    endWeek,
  ]);

  const generateMonthsWorkTree = useCallback(() => {
    const data = projects ? multipleProjectsWeekWorksData : singleProjectWeekWorksData;
    if (!data) return;
    const { workMap, planMap, workDaysMap, planDaysMap } = getWeekWorksMaps({
      data,
      projectInterval: interval,
      projects,
      year: +year,
    });

    const localTree = getMonthsWorksTree({
      tree,
      workMap,
      planMap,
      year,
    });
    setCalcTrees([localTree]);
  }, [projects, multipleProjectsWeekWorksData, singleProjectWeekWorksData, interval, year, tree]);

  const generateMonthsMaterialsTree = useCallback(() => {}, []);

  useEffect(() => {
    if (!tree?.length || isGeneratingSpittingTree) return;
    if (type === WORKS_TAB_ID && (chartViewMode === MONTH || chartViewMode === HALF_MONTH)) {
      generateDaysWorkTree();
    }
    if (type === MATERIALS_TAB_ID && (chartViewMode === MONTH || chartViewMode === HALF_MONTH)) {
      generateDaysMaterialTree();
    }
    if (type === WORKS_TAB_ID && chartViewMode === WEEK) {
      generateWeeksWorkTree();
    }
    if (type === MATERIALS_TAB_ID && chartViewMode === WEEK) {
      generateWeeksMaterialTree();
    }
    if (type === WORKS_TAB_ID && chartViewMode === YEAR) {
      generateMonthsWorkTree();
    }
    if (type === MATERIALS_TAB_ID && chartViewMode === YEAR) {
      generateMonthsMaterialsTree();
    }
  }, [
    tree,
    isGeneratingSpittingTree,
    type,
    chartViewMode,
    generateDaysWorkTree,
    generateDaysMaterialTree,
    generateWeeksWorkTree,
    generateWeeksMaterialTree,
    generateMonthsWorkTree,
    generateMonthsMaterialsTree,
  ]);

  useEffect(() => {
    return () => {
      dispatch(dropLoadedChartMonths());
    };
  }, []);

  return {
    calcTrees,
  };
};
