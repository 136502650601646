import React, { useState, useRef } from 'react';
import cn from 'classnames';

import Checkbox from '../../../../atoms/Checkbox/Checkbox';
import Actions from "../../../../atoms/Actions/Actions";

import { IMaterialInAddingInFact } from 'types/interfaces/Materials';
import { stopEventPropagation } from "../../../../../../utils/stopEventPropagation";

import styles from "./TableRow.module.scss";
import { dropNonSignificantZeros } from 'utils/dropNonSignificantZeros';
import { sliceTextByConstraint } from 'utils/sliceTextByConstraint';


interface IProps {
  material: IMaterialInAddingInFact,
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>, item: IMaterialInAddingInFact) => void, 
  onChangeCount: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onDelete?: () => void,
  isCanBeChecked?: boolean, 
  isCanDelete?: boolean,
  isSelected?: boolean,
}

function MaterialsTableRow({ 
  material, 
  onCheck, 
  onChangeCount,
  onDelete,
  isCanBeChecked = true, 
  isCanDelete, 
  isSelected 
}: IProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [localValue, setLocalValue] = useState<string>(material.local_count || "");

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeCount(e)
    setLocalValue(e.target.value)
  }

  const clickDrowningHandler = () => {
    ref.current?.click()
  }

  React.useEffect(() => {
    setLocalValue(material.local_count || "")
  }, [material])

  return (
    <div 
      className={cn(styles.row, styles.materialRow, { [styles.selected]: isSelected })} 
      onClick={clickDrowningHandler}
    >
      <div className={styles.checkbox}>
        {isCanBeChecked &&
          <Checkbox 
            onCheck={(e: React.ChangeEvent<HTMLInputElement>) => onCheck?.(e, { ...material, local_count: localValue })} 
            checked={isSelected as boolean}
          >
            <div ref={ref} />
          </Checkbox>}
      </div>
      <div 
        className={styles.name}
        title={material?.name}
      >
        {material?.name}
      </div>
      <div onClick={stopEventPropagation}>
        <input 
          className={styles.input} 
          onChange={changeHandler} 
          placeholder="Кол-во"
          value={localValue} 
        />
      </div>
      <div className={cn(styles.name, styles.measure)} title={`${material?.sum_stock_count} ${material?.measure}`}>
        {`${dropNonSignificantZeros(Number(material?.sum_stock_count).toFixed(2))} ${sliceTextByConstraint(material?.measure, 4)}`}
        {isCanDelete && (
          <Actions 
            canRemove={isCanDelete} 
            onRemove={onDelete} 
            className={cn(styles.delete, styles.deleteMaterial)} 
          />
        )}
      </div>
    </div>
  )
}

export default React.memo(MaterialsTableRow);
