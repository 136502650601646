import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  arrowHashSelector,
  diagramFiltersSelector,
  diagramIntervalArrowsSelector, highlightedArrowsSelector, highlightedIntervalsSelector,
  isBeingDraggedDiagramIntervalSelector
} from '../../../../../redux/modules/common/building/manufacturing/selectors'
import {
  addIntervalLink,
  deleteArrow,
  startDragInterval,
  stopDragInterval,
} from "../../../../../redux/modules/common/building/manufacturing/thunks";

import { DiagramFilters } from "../../constants";

export interface IDraggedInterval {
  isBeingDragged: boolean;
  projectId: number;
  startDate: string;
}

export interface IDiagramIntervalLink {
  from_interval: number;
  to_interval: number;
  id: number;
  delay_day: number | null;
}

export interface IUseIntervalLinksProps {
  intervalWrapperRef?: React.RefObject<HTMLDivElement> | undefined;
  toIntervalId?: number | undefined;
  projectId?: number | null;
  startDate?: string;
}

export interface IArrows {
  [key: number]: IDiagramIntervalLink[];
}

export const useIntervalLinks = ({
  intervalWrapperRef,
  toIntervalId,
  projectId,
  startDate,
}: IUseIntervalLinksProps) => {
  const dispatch = useDispatch();
  const arrowHash = useSelector(arrowHashSelector);

  const [position, setPosition] = useState<{
    x: number | undefined;
    y: number | undefined;
  }>({ x: undefined, y: undefined });
  const arrows: IArrows = useSelector(diagramIntervalArrowsSelector);
  const beingDraggedArrows: IDraggedInterval[] = useSelector(
    isBeingDraggedDiagramIntervalSelector
  );

  const projectArrows = useMemo(
    () => (projectId && arrows[projectId]) || [],
    [projectId, arrows]
  );

  const isSomeArrowBeingDragged = useMemo(
    () => Object.values(beingDraggedArrows).some((x) => x.isBeingDragged),
    [beingDraggedArrows]
  );

  const draggedIntervalEntry = useMemo(
    () =>
      Object.entries(beingDraggedArrows).find(
        ([_, { isBeingDragged }]) => isBeingDragged
      ),
    [beingDraggedArrows]
  );

  const draggedIntervalId: number | undefined =
    draggedIntervalEntry && +draggedIntervalEntry[0];

  const diagramFilters = useSelector(diagramFiltersSelector);
  const isLinkingEnabled = diagramFilters[DiagramFilters.linking_enabled];

  const addArrow = useCallback(
    ({
      from_interval,
      to_interval,
    }: Pick<IDiagramIntervalLink, "from_interval" | "to_interval">) => {
      if (!from_interval || !to_interval || !projectId) return;

      const arrowCandidate = { from_interval, to_interval };

      if (
        projectArrows.findIndex(
          (x) =>
            x.from_interval === arrowCandidate.from_interval &&
            x.to_interval === arrowCandidate.to_interval
        ) === -1
      ) {
        dispatch(addIntervalLink(projectId, arrowCandidate));
      }
    },
    [projectArrows, projectId]
  );

  const handleDragging = useCallback((e: MouseEvent | DragEvent) => {
    setPosition({
      x: e.clientX - 10,
      y: e.clientY - 10,
    });
  }, []);

  const handleDragEnd = useCallback(() => {
    if (!draggedIntervalId || !isSomeArrowBeingDragged) return;
    dispatch(stopDragInterval({ projectId, intervalId: draggedIntervalId }));
    setPosition({ x: undefined, y: undefined });
  }, [draggedIntervalId, projectId, isSomeArrowBeingDragged]);

  useEffect(() => {
    if (
      !draggedIntervalId ||
      !toIntervalId ||
      draggedIntervalId !== toIntervalId
    )
      return;
    window.addEventListener("mousemove", handleDragging);
    return () => {
      window.removeEventListener("mousemove", handleDragging);
      handleDragEnd();
    };
  }, [draggedIntervalId, handleDragging, handleDragEnd, toIntervalId]);

  const handleDragStart = useCallback(
    (e: MouseEvent) => {
      if (!toIntervalId) return;
      setPosition({
        x: e.clientX - 10,
        y: e.clientY - 10,
      });
      dispatch(
        startDragInterval({ projectId, intervalId: toIntervalId, startDate })
      );
    },
    [toIntervalId, projectId]
  );

  const handleCreateIntervalLink = useCallback(() => {
    if (!draggedIntervalEntry) return;
    if (
      draggedIntervalId &&
      toIntervalId &&
      draggedIntervalId !== toIntervalId &&
      draggedIntervalEntry[1]?.projectId === projectId &&
      moment(draggedIntervalEntry[1]?.startDate).isBefore(
        startDate,
        "day"
      )
    ) {
      const newArrow = {
        from_interval: draggedIntervalId,
        to_interval: toIntervalId,
      };
      addArrow?.(newArrow);
      handleDragEnd();
    }
  }, [
    addArrow,
    draggedIntervalEntry,
    draggedIntervalId,
    handleDragEnd,
    toIntervalId,
    projectId,
  ]);

  const handleIntervalClick = useCallback(() => {
    if (!isSomeArrowBeingDragged) return;
    if (toIntervalId === draggedIntervalId) {
      handleDragEnd();
      return;
    }
    handleCreateIntervalLink();
  }, [
    handleCreateIntervalLink,
    isSomeArrowBeingDragged,
    toIntervalId,
    draggedIntervalId,
  ]);

  const arrowsStartsWithCurrentIntervalId = useMemo(
    () => projectArrows.filter((x) => x.from_interval === toIntervalId),
    [projectArrows, toIntervalId]
  );

  const highlightedIntervals: number[] = useSelector(highlightedIntervalsSelector);
  const highlightedArrows: number[] = useSelector(highlightedArrowsSelector);
  const isIntervalHighlighted = highlightedIntervals?.length > 0 && !!toIntervalId && highlightedIntervals.indexOf(toIntervalId) !== -1

  return {
    arrows,
    projectArrows,
    isLinkingEnabled,
    beingDraggedArrows,
    isSomeArrowBeingDragged,
    handleIntervalClick,
    position,
    handleDragging,
    handleDragStart,
    handleCreateIntervalLink,
    arrowsStartsWithCurrentIntervalId,
    draggedIntervalEntry,
    isIntervalHighlighted,
    highlightedArrows,
    arrowHash
  };
};
