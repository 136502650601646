import { initialState } from "./manufacturing";
import { RelationFromTypes } from "../../../../../components/pages/Manufacturing/components/ManufacturingModal/IntervalRelationsContent/types";

const concatArrayField = (a, b) => Array.from(new Set(a.concat(b || [])));

export const concatStateWorkField = (fieldName, state, payload) => {
  return {
    ...state,
    [fieldName]: {
      ...state[fieldName],
      plans: concatArrayField(state[fieldName].plans, payload?.plans),
      works: concatArrayField(state[fieldName].works, payload?.works),
      sections: concatArrayField(state[fieldName].sections, payload?.sections),
      planned_sections: concatArrayField(
        state[fieldName].planned_sections,
        payload?.planned_sections
      ),
    },
  };
};

export const concatStateMaterialField = (fieldName, state, payload) => {
  return {
    ...state,
    [fieldName]: {
      ...state[fieldName],
      accepted: concatArrayField(state[fieldName].accepted, payload?.accepted),
      on_stock: concatArrayField(state[fieldName].on_stock, payload?.on_stock),
      payed: concatArrayField(state[fieldName].payed, payload?.payed),
      plans: concatArrayField(state[fieldName].plans, payload?.plans),
      purchases: concatArrayField(
        state[fieldName].purchases,
        payload?.purchases
      ),
      stockless: concatArrayField(
        state[fieldName].stockless,
        payload?.stockless
      ),
    },
  };
};

export const markYearMonth = (fieldName, state, payload) => {
  return {
    ...state,
    [fieldName]: {
      ...state[fieldName],
      [payload.projectId]: {
        ...(state[fieldName][payload.projectId]
          ? state[fieldName][payload.projectId]
          : {}),
        [payload.dataType]: state[fieldName][payload.projectId]?.hasOwnProperty(
          payload.dataType
        )
          ? state[fieldName][payload.projectId][payload.dataType].concat(
              payload.fetchedDates
            )
          : payload.fetchedDates,
      },
    },
  };
};

export const dropChartMonths = (state) => {
  return {
    ...state,
    loadedConstructingChartData: {
      ...initialState.loadedConstructingChartData,
    },
    loadedManufacturingChartData: {
      ...initialState.loadedManufacturingChartData,
    },
    plan: { ...initialState.plan },
    materials: { ...initialState.materials },
    projectData: { ...initialState.projectData },
    materialData: { ...initialState.materialData },
    projectWeekData: { ...initialState.projectWeekData },
    materialWeekData: { ...initialState.materialWeekData },
    weekPlan: { ...initialState.weekPlan },
    weekMaterials: { ...initialState.weekMaterials },
    diagramIntervalLinks: { ...initialState.diagramIntervalLinks },
  };
};

export const getNearestYearsMonths = (year, month) => {
  let nextYear = +year;
  let nextMonth = month;
  let prevYear = +year;
  let prevMonth = month;
  if (month === 11) {
    nextYear += 1;
    nextMonth = 0;
  } else if (month === 0) {
    prevYear -= 1;
    prevMonth = 11;
  } else {
    nextMonth += 1;
    prevMonth -= 1;
  }
  return {
    nextYear,
    nextMonth,
    prevYear,
    prevMonth,
  };
};

export const pushMaterialsToAcc = (acc, materials) => {
  acc["accepted"] = concatArrayField(acc.accepted, materials?.accepted);
  acc["on_stock"] = concatArrayField(acc.on_stock, materials?.on_stock);
  acc["payed"] = concatArrayField(acc.payed, materials?.payed);
  acc["plans"] = concatArrayField(acc.plans, materials?.plans);
  acc["purchases"] = concatArrayField(acc.purchases, materials?.purchases);
  acc["stockless"] = concatArrayField(acc.stockless, materials?.stockless);
};

export const pushWorksToAcc = (acc, works) => {
  acc["plans"] = concatArrayField(acc.plans, works?.plans);
  acc["sections"] = concatArrayField(acc.sections, works?.sections);
  acc["works"] = concatArrayField(acc.works, works?.works);
  acc["planned_sections"] = concatArrayField(
    acc.planned_sections,
    works?.planned_sections
  );
};

export const addIntervalLinkHelper = (state, linkPayload) => {
  const currentArrowsByProjectId =
    state.diagramIntervalLinks.arrows[linkPayload.projectId];
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      arrows: {
        ...state.diagramIntervalLinks.arrows,
        [linkPayload.projectId]: currentArrowsByProjectId
          ? Array.from(
              new Set(currentArrowsByProjectId.concat(linkPayload.arrows))
            )
          : [].concat(linkPayload.arrows),
      },
    },
  };
};

export const setArrowsHelper = (state, linkPayload) => {
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      arrows: {
        ...state.diagramIntervalLinks.arrows,
        [linkPayload.projectId]: linkPayload.arrows,
      },
    },
  };
};

export const deleteArrowsHelper = (state, linkPayload) => {
  const currentArrowsByProjectId =
    state.diagramIntervalLinks.arrows[linkPayload.projectId];
  if (!currentArrowsByProjectId) return state;
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      arrows: {
        ...state.diagramIntervalLinks.arrows,
        [linkPayload.projectId]: currentArrowsByProjectId.filter(
          (x) => x.id !== linkPayload.arrowId
        ),
      },
    },
  };
};

export const isArrowDraggedHelper = (state, payload) => {
  const prevBeingDragged =
    state.diagramIntervalLinks.isBeingDragged[payload.intervalId];
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      isBeingDragged: {
        ...state.diagramIntervalLinks.isBeingDragged,
        [payload.intervalId]: {
          isBeingDragged: payload.isBeingDragged,
          projectId: payload.projectId || prevBeingDragged.projectId,
          startDate: payload.startDate || prevBeingDragged.startDate,
        },
      },
    },
  };
};

export const updateArrowHelper = (state, linkPayload) => {
  const currentArrowsByProjectId =
    state.diagramIntervalLinks.arrows[linkPayload.projectId];
  if (!currentArrowsByProjectId) return state;
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      arrows: {
        ...state.diagramIntervalLinks.arrows,
        [linkPayload.projectId]: currentArrowsByProjectId.map((x) => {
          if (x.id === linkPayload.arrowId) {
            return linkPayload.data;
          }
          return x;
        }),
      },
    },
  };
};

export const getRelationsForBulkCreate = ({
  intervalId,
  relationCandidates,
  type,
}) => {
  if (type === RelationFromTypes.from) {
    return relationCandidates.map((candidateId) => ({
      from_interval: +intervalId,
      to_interval: +candidateId,
    }));
  }
  if (type === RelationFromTypes.to) {
    return relationCandidates.map((candidateId) => ({
      to_interval: +intervalId,
      from_interval: +candidateId,
    }));
  }
  return [];
};

const mapProjectArrows = (
  projectArrows,
  arrowCandidates,
  intervalCandidates,
  intervalId
) => {
  projectArrows.forEach((x) => {
    if (x.from_interval === intervalId) {
      arrowCandidates.push(x.id);
      intervalCandidates.push(intervalId, x.to_interval);

      mapProjectArrows(
        [...projectArrows].filter((y) => y.id !== x.id),
        arrowCandidates,
        intervalCandidates,
        x.to_interval
      );
    }

    if (x.to_interval === intervalId) {
      arrowCandidates.push(x.id);
      intervalCandidates.push(intervalId, x.from_interval);

      mapProjectArrows(
        [...projectArrows].filter((y) => y.id !== x.id),
        arrowCandidates,
        intervalCandidates,
        x.from_interval
      );
    }
  });
};

export const highlightArrow = (state, { intervalId, projectId }) => {
  const projectArrows =
    (state.diagramIntervalLinks.arrows[projectId] && [
      ...state.diagramIntervalLinks.arrows[projectId],
    ]) ||
    [];
  let arrowsCandidates = [];
  let intervalsCandidates = [];

  mapProjectArrows(
    projectArrows,
    arrowsCandidates,
    intervalsCandidates,
    intervalId
  );

  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      highlightedArrows:
        state.diagramIntervalLinks.highlightedArrows.concat(arrowsCandidates),
      highlightedIntervals:
        state.diagramIntervalLinks.highlightedArrows.concat(
          intervalsCandidates
        ),
    },
  };
};

export const dropHighlightedElements = (state) => {
  return {
    ...state,
    diagramIntervalLinks: {
      ...state.diagramIntervalLinks,
      highlightedArrows: [],
      highlightedIntervals: [],
    },
  };
};
