import React from "react";

export const DeleteIcon = React.memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
    <title>Group 8</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols_NEW"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="close_icon_blue"
        transform="translate(1.000000, 1.000000)"
        fillRule="nonzero"
        stroke="#B1B6BB"
        strokeWidth="2"
      >
        <g id="Group-8">
          <path d="M2.04281037e-14,2.7533531e-14 L11.3137085,11.3137085" id="Line-3" />
          <path
            d="M2.04281037e-14,2.7533531e-14 L11.3137085,11.3137085"
            id="Line-3-Copy"
            transform="translate(5.656854, 5.656854) rotate(-270.000000) translate(-5.656854, -5.656854) "
          />
        </g>
      </g>
    </g>
  </svg>
));
