import React from 'react';

import styles from "./FieldErrorMessage.module.scss";


export interface IProps {
  errorMessage: string;
}

const FieldErrorMessage = ({ errorMessage }: IProps) => {
  return (
    <span className={styles.errorMessage}>{errorMessage}</span>
  )
}

export default React.memo(FieldErrorMessage);