import { useLocation } from "react-router-dom";


export type urlModulesType = "constructing" | "objects" | "purchases" | "tasks";

export const useUrlModule = () => {
  const { pathname } = useLocation();
  const array = pathname.split("/");
  return array[1] as urlModulesType;
};
