import React, { useCallback, useRef } from "react";
import { Field, Form } from "react-final-form";

import MeasureSelect from "../atoms/MeasureSelect/MeasureSelect";
import CheckboxFieldComponent from "../CheckboxFieldComponent/CheckboxFieldComponent";
import Select from "../atoms/Select";
import ButtonBase from "../atoms/ButtonBase";
import InputBase from "../atoms/InputBase";

import { materialsType } from "../../../utils/constant";
import {
  composeFieldValidators,
  maxLength,
  mustBeNumber,
  required,
} from "../../../utils/validations";

import styles from "./ExpenditureForm.module.scss";

const EXPENDITURE_TYPES = [
  { name: "Работа", id: "work" },
  { name: "Материал", id: "material" },
];

const ExpenditureForm = ({ submit, isEstimate, initialValues, isServices }) => {
  const formRef = useRef();

  const onSubmit = useCallback(
    ({ type, ...values }) => {
      submit(values.from_estimate ? values : { ...values, type });

      if (!formRef.current || !formRef.current.form) return;
      formRef.current.form.restart();
    },
    [submit]
  );

  return (
    <div className={styles.container}>
      <Form
        ref={formRef}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ values, handleSubmit, pristine, hasValidationErrors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.topSide}>
                <div className={styles.headerContent}>
                  <div className={styles.rowFirst}>
                    <div className={styles.field1}>
                      <Field
                        name="name"
                        label="Наименование *"
                        component={InputBase}
                        validate={composeFieldValidators(
                          required(),
                          maxLength(155)
                        )}
                      />
                    </div>
                    <div className={styles.field2}>
                      <Field
                        name="measure"
                        label="Ед.изм.*"
                        component={MeasureSelect}
                        validate={composeFieldValidators(
                          required(),
                          maxLength(255)
                        )}
                        isServices={isServices}
                      />
                    </div>
                    <div className={styles.field3}>
                      <Field
                        name="count"
                        label="Кол-во *"
                        type="number"
                        component={InputBase}
                        validate={composeFieldValidators(
                          mustBeNumber,
                          required(),
                          maxLength(15)
                        )}
                      />
                    </div>
                  </div>
                  <div className={styles.row}>
                    {isEstimate ? (
                      <>
                        <div className={styles.field3}>
                          <Field
                            name="price"
                            label="Цена *"
                            component={InputBase}
                            type="number"
                            validate={composeFieldValidators(required())}
                          />
                        </div>
                        <div className={styles.estimateField}>
                          <Field
                            name="justification"
                            label="Обоснование *"
                            component={InputBase}
                            validate={composeFieldValidators(
                              required(),
                              maxLength(155)
                            )}
                          />
                        </div>
                        <div className={styles.fieldRow5}>
                          <Field
                            name="expenditure_type"
                            label="Тип *"
                            component={Select}
                            options={EXPENDITURE_TYPES}
                            validate={required()}
                          />
                        </div>
                        <div className={styles.estimateField}>
                          <Field
                            name="estimate_measure"
                            label="Смет.ед.измерения"
                            component={InputBase}
                          />
                        </div>
                        <div className={styles.estimateField}>
                          <Field
                            name="estimate_count"
                            label="Смет.кол-во"
                            component={InputBase}
                          />
                        </div>
                        <div className={styles.estimateField}>
                          <Field
                            name="estimate_price"
                            label="Смет.цена"
                            component={InputBase}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.fieldRow1}>
                          <Field
                            name="address"
                            label="Производитель"
                            component={InputBase}
                          />
                        </div>
                        <div className={styles.fieldRow2}>
                          <Field
                            name="number"
                            label="Код"
                            component={InputBase}
                          />
                        </div>
                        <div className={styles.fieldRow3}>
                          <Field
                            name="brand"
                            label="Марка"
                            component={InputBase}
                          />
                        </div>
                        <div className={styles.fieldRow5}>
                          <Field
                            name="type"
                            label="Тип"
                            component={Select}
                            options={materialsType}
                            disabled={values.from_estimate}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {!isEstimate && (
                    <div className={styles.fieldRow4}>
                      <Field
                        className={styles.checkbox}
                        name="from_estimate"
                        label="Доп"
                        type="checkbox"
                        component={CheckboxFieldComponent}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.actionBlock}>
                <ButtonBase
                  disabled={pristine || hasValidationErrors}
                  type="submit"
                  primary
                  medium
                >
                  Сохранить
                </ButtonBase>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default React.memo(ExpenditureForm);
