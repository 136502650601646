import React, { KeyboardEventHandler, useCallback, useState } from "react";

import CrossRoundedIcon from "images/icons/CrossBoldRoundedIcon";

import styles from "components/pages/Handler/components/CreateSection/components/CreateSectionInput/CreateSectionInput.module.scss";

interface ICreateSectionInputProps {
  close: () => void;
  onSubmit: (name: string) => void;
  placeholder?: string
}

const CreateSectionInput = ({ close, onSubmit, placeholder }: ICreateSectionInputProps) => {
  const [title, setTitle] = useState("");

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key !== "Enter") return;
      onSubmit(title);
      setTitle("");
      close();
    },
    [close, onSubmit, title]
  );

  return (
    <div className={styles.container}>
      <div className={styles.blockTop}>
        <div className={styles.boxInput}>
          <span>*</span>
          <input
            placeholder={placeholder || "Введите наименование"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={styles.boxBtn} onClick={close}>
          <CrossRoundedIcon width="14.4" color="#606060" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CreateSectionInput);
