import { combineReducers } from "redux";

import kses from "redux/modules/common/building/documents/kses/reducer";
import serviceActs from "redux/modules/common/building/documents/serviceActs/reducer";
import journal from "redux/modules/common/building/journal/reducer";

import accounts from "./modules/admin/accounts/accounts";
import entities from "./modules/admin/accounts/entities";
import users from "./modules/admin/accounts/users";
import auth, { USER_AUTO_LOGOUT, USER_LOGOUT } from "./modules/common/auth";
import breadcrumbs from "./modules/common/breadcrumbs";
import aggregations from "./modules/common/building/aggregations";
import bills from "./modules/common/building/documents/bills";
import contracts from "./modules/common/building/documents/contracts";
import exports from "./modules/common/building/documents/exports";
import log from "./modules/common/building/log";
import manufacturing from "./modules/common/building/manufacturing/manufacturing";
import importMaterials from "./modules/common/building/materials/importMaterials";
import materials from "./modules/common/building/materials/materials";
import specification from "./modules/common/building/materials/specification";
import nowObject from "./modules/common/building/object/nowObject";
import objects from "./modules/common/building/objects";
import posts from "./modules/common/building/posts";
import process from "./modules/common/building/process";
import projects from "./modules/common/building/projects";
import requisition from "./modules/common/building/requisition/reducer";
import requisitions from "./modules/common/building/requisitions";
import sections from "./modules/common/building/sections/sections";
import shedules from "./modules/common/building/shedules";
import stocks from "./modules/common/building/stocks";
import tickets from "./modules/common/building/tickets";
import ticketsEstimate from "./modules/common/building/ticketsEstimate";
import virtualProviders from "./modules/common/building/virtualProviders";
import workers from "./modules/common/building/workers";
import chat from "./modules/common/chat/chat";
import chatReducerV2 from "./modules/common/chatV2/reducers";
import comments from "./modules/common/comments/reducer";
import constructing from "./modules/common/constructing";
import documents from "./modules/common/documents/reducer";
import fileStorage from "./modules/common/filesStorage/filesStorage";
import invites from "./modules/common/invites/invites";
import inviteOrg from "./modules/common/invites/sendInvite";
import ks6a from "./modules/common/ks6a/ks6a";
import notifications from "./modules/common/notifications";
import approval from "./modules/common/orderCard/approval/reducer";
import files from "./modules/common/orderCard/files/reducer";
import orderMatrix from "./modules/common/orderCard/orderMatrix";
import orders from "./modules/common/orderCard/orders";
import ordersRegistry from "./modules/common/orders/registry/ordersRegistry";
import permissions from "./modules/common/permissions/reducer";
import products from "./modules/common/products";
import registrationReducer from "./modules/common/registrations/reducers";
import settings from "./modules/common/settings";
import organization from "./modules/common/settings/organization";
import addresses from "./modules/common/settings/ourCompanyTabs/addresses";
import employees from "./modules/common/settings/ourCompanyTabs/employees";
import profile from "./modules/common/settings/ourCompanyTabs/profile";
import visibilityZones from "./modules/common/settings/visibilityZones/visibilityZones";
import { stockFilesReducer } from "./modules/common/stockFiles/stockFilesReducer";
import userRedux from "./modules/common/userRedux";
import importList from "./modules/provider/importList";
import matrixProvider from "./modules/provider/matrixProvider";
import ordersProvider from "./modules/provider/ordersProvider";
import tasks from "./modules/purchaser/Tasks/tasks";
import events from "./modules/purchaser/events";
import market from "./modules/purchaser/market";
import matrixPurchaser from "./modules/purchaser/matrixPurchaser";
import payments from "./modules/purchaser/payments";

import { IbasicReduxAction } from "types/utilTypes/reduxTypes";

export const allReducers = {
  auth,
  products,
  documents,
  matrixProvider,
  orderMatrix,
  ordersProvider,
  market,
  settings,
  organization,
  employees,
  addresses,
  matrixPurchaser,
  profile,
  orders,
  breadcrumbs,
  inviteOrg,
  invites,
  importList,
  notifications,
  visibilityZones,
  objects,
  projects,
  requisitions,
  bills,
  nowObject,
  requisition,
  ordersRegistry,
  chat,
  virtualProviders,
  events,
  exports,
  accounts,
  entities,
  users,
  stocks,
  workers,
  log,
  payments,
  tasks,
  contracts,
  shedules,
  process,
  posts,
  tickets,
  materials,
  ticketsEstimate,
  userRedux,
  sections,
  aggregations,
  constructing,
  specification,
  importMaterials,
  files,
  approval,
  manufacturing,
  ks6a,
  stockFilesReducer,
  permissions,
  comments,
  chatReducerV2,
  registrationReducer,
  kses,
  serviceActs,
  fileStorage,
  journal,
};

export const appReducer = combineReducers({
  auth,
  products,
  documents,
  matrixProvider,
  orderMatrix,
  ordersProvider,
  market,
  settings,
  organization,
  employees,
  addresses,
  matrixPurchaser,
  profile,
  orders,
  breadcrumbs,
  inviteOrg,
  invites,
  importList,
  notifications,
  visibilityZones,
  objects,
  projects,
  requisitions,
  bills,
  nowObject,
  requisition,
  ordersRegistry,
  chat,
  virtualProviders,
  events,
  exports,
  accounts,
  entities,
  users,
  stocks,
  workers,
  log,
  payments,
  tasks,
  contracts,
  shedules,
  process,
  posts,
  tickets,
  materials,
  ticketsEstimate,
  userRedux,
  sections,
  aggregations,
  constructing,
  specification,
  importMaterials,
  files,
  approval,
  manufacturing,
  ks6a,
  stockFilesReducer,
  permissions,
  comments,
  chatReducerV2,
  registrationReducer,
  kses,
  serviceActs,
  fileStorage,
  journal,
});

export const rootReducer = (state: ReturnType<typeof appReducer>, action: IbasicReduxAction) => {
  if (action.type === USER_LOGOUT) {
    state = {
      // @ts-ignore
      auth: {
        isAuthenticated: false,
      },
    };
  }

  if (action.type === USER_AUTO_LOGOUT) {
    // @ts-ignore
    state = {};
    localStorage.clear();
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
