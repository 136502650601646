import axios from "axios";

import { IList } from "types/ListResponse";
import {
  IFileInStorage,
  IFilesFilterParams,
  IFolderInStorage,
  IStorageIndicators,
} from "./types";

export const filesStorageApi = {
  createFolder: (
    building_id: string | null,
    title: string,
    parent_id?: number
  ) => {
    const data = {
      building_id,
      title,
      parent_id,
      project_id: building_id,
    };
    return axios.post(`/project/filesections/`, data);
  },
  createFile: (
    file: File,
    params: Pick<IFilesFilterParams, "project_id" | "section_id">
  ) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("project_id", String(params.project_id));
    params.section_id &&
      formData.append("section_id", String(params.section_id));
    return axios.post(`/project/files/`, formData);
  },
  getCounters: (objectId: string | null) => {
    return axios.get<IStorageIndicators>(`/project/files/counts/`, {
      params: { project_id: objectId },
    });
  },
  deleteFolder: (folderId: number) => {
    return axios.delete(`/project/filesections/${folderId}/`);
  },
  deleteFile: (fileId: number) => {
    return axios.delete(`/project/files/${fileId}/`);
  },
  renameFolder: (folderId: number, name: string) => {
    return axios.patch(`/project/filesections/${folderId}/`, { title: name });
  },
  renameFile: (fileId: number, name: string) => {
    return axios.patch(`/project/files/${fileId}/`, { originalname: name });
  },
  getFiles: (params: IFilesFilterParams) => {
    return axios.get<IList<IFileInStorage>>(`/project/files/`, { params });
  },
  getFolders: (params: IFilesFilterParams) => {
    return axios.get<IList<IFolderInStorage> & { parent_name: string }>(
      `/project/filesections/`,
      {
        params: { ...params, parent_id: params.section_id || null },
      }
    );
  },
};
