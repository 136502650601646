import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";

import { ISectionPlan } from "redux/modules/common/building/processTypes";

import PopoverOverlay from "components/UI/molecules/PopoverOverlay/PopoverOverlay";
import SectionPlanModal from "components/modals/ManufacturingPlanSectionModal/ManufacturingPlanSectionModal";

import { IRouterParamsWithObjectId } from "types/routerTypes";

import CalendarEvents from "images/icons/navigation/CalendarEvents";

import styles from "./PlanSectionMark.module.scss";

type IProps = ISectionPlan;

const PlanSectionMark: React.FC<IProps> = (props) => {
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const { start_at, end_at, section_id, section_name } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const diff = React.useMemo(() => {
    return moment(end_at).diff(start_at, "days");
  }, [start_at, end_at]);

  const clickHandler = React.useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <PopoverOverlay
        openType="hover"
        content={
          <div className={styles.content} onClick={clickHandler}>
            <div className={styles.title}>План производства работ</div>
            <div>
              {moment(start_at).format("DD/MM/YYYY")} -{" "}
              {moment(end_at).format("DD/MM/YYYY")}
            </div>
            <div>Продолжительность - {diff + 1} дней </div>
          </div>
        }
        placement="bottom"
        popoverBorderColor="primary"
      >
        <div className={styles.mark} onClick={clickHandler}>
          <CalendarEvents />
        </div>
      </PopoverOverlay>
      <SectionPlanModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        externalData={props}
        objectId={objectId}
        intervaldata={{ id: -1 }}
      />
    </>
  );
};

export default PlanSectionMark;
