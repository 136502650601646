import axios from "axios";
import { message } from "antd";
import { createSelector } from "reselect";

import { errorCatcher } from "../../../../utils/errorCatcher";

const moduleName = "objects";
const LOAD_OBJECTS = `${moduleName}/LOAD_OBJECTS`;
const SET_OBJECTS = `${moduleName}/SET_OBJECTS`;
const LOAD_SAVE = `${moduleName}/LOAD_SAVE`;
const SET_SAVE = `${moduleName}/SET_SAVE`;
const ADD_OBJECTS = `${moduleName}/ADD_OBJECTS`;
const SET_OBJECTS_BREADCRUMBS = `${moduleName}/SET_OBJECTS_BREADCRUMBS`;

const SET_OBJECTS_TASKS = `${moduleName}/SET_OBJECTS_TASKS`;
const ADD_BREADCRUMBS_OBJECT = `${moduleName}/ADD_BREADCRUMBS_OBJECT`;

const SET_EVENTS = `${moduleName}/SET_EVENTS`;

export const initialState = {
  objects: { results: [] },
  objectsBreadcrumbs: { results: [] },
  objectsTasks: { results: [] },
  isLoading: true,
  isBreadcrumbsLoading: true,
  objectsTasksIsLoading: true,
  loadingSave: false,
  events: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_EVENTS:
      return {
        ...state,
        events: payload,
      };
    case LOAD_OBJECTS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_OBJECTS:
      return {
        ...state,
        objects: payload,
        isLoading: false,
      };
    case SET_OBJECTS_BREADCRUMBS:
      return {
        ...state,
        isBreadcrumbsLoading: false,
        objectsBreadcrumbs: {
          ...payload,
          results: payload.results,
        },
      };
    case SET_OBJECTS_TASKS:
      return {
        ...state,
        objectsTasksIsLoading: false,
        objectsTasks: {
          ...payload,
          results: payload.results,
        },
      };
    case ADD_BREADCRUMBS_OBJECT:
      return {
        ...state,
        objectsBreadcrumbs: { ...state.objectsBreadcrumbs, results: [payload, ...state.objectsBreadcrumbs.results] },
      };
    case LOAD_SAVE:
      return {
        ...state,
        loadingSave: true,
      };
    case SET_SAVE:
      return {
        ...state,
        loadingSave: false,
      };
    case ADD_OBJECTS:
      return {
        ...state,
        objects: {
          ...state.objects,
          count: payload.count,
          results: [...state.objects.results, ...payload.results],
        },
        isLoading: false,
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const objectsSelector = createSelector(stateSelector, (state) => state.objects);
export const objectsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const objectsLoadingSaveSelector = createSelector(stateSelector, (state) => state.loadingSave);
export const objectsBreadcrumbsSelector = createSelector(stateSelector, (state) => state.objectsBreadcrumbs);
export const objectsTasksSelector = createSelector(stateSelector, (state) => state.objectsTasks);
export const objectsTasksLoadingSelector = createSelector(stateSelector, (state) => state.objectsTasksIsLoading);
export const objectsBreadcrumbsLoadingSelector = createSelector(stateSelector, (state) => state.isBreadcrumbsLoading);
export const allEventsSelector = createSelector(stateSelector, (state) => state.events);

export const loadEvents = (eventsArray, dateAfter, dateBefore, building_id) => {
  return (dispatch) => {
    const config = {
      params: {
        date_after: dateAfter,
        date_before: dateBefore,
        building_id: building_id,
      },
    };
    let events =
      eventsArray.length === 1 ? `events=${eventsArray[0]}` : `events=${eventsArray[0]}&events=${eventsArray[1]}`;
    if (eventsArray.length > 0) {
      axios
        .get(`/events?${events}&detail=true`, config)
        .then((res) =>
          dispatch({
            type: SET_EVENTS,
            payload: res.data,
          })
        )
        .catch((e) => console.log(e));
    } else {
      dispatch({
        type: SET_EVENTS,
        payload: [],
      });
    }
  };
};

export const loadObjects = (paginationParams, filterParams) => {
  const config = { params: { ...paginationParams, ...filterParams } };
  return (dispatch) => {
    dispatch({ type: LOAD_OBJECTS });
    axios
      .get(`/projects/`, config)
      .then(({ data }) => {
        dispatch({
          type: SET_OBJECTS,
          payload: data,
        });
      })
      .catch(errorCatcher);
  };
};

export const loadTasksObjects = (paginationParams, filterParams) => {
  const config = { params: { ...paginationParams, ...filterParams } };
  return (dispatch) => {
    axios
      .get(`/projects/`, config)
      .then(({ data }) => {
        dispatch({
          type: SET_OBJECTS_TASKS,
          payload: data,
        });
      })
      .catch(errorCatcher);
  };
};

export const loadObjectsBreadcrumbs = (() => {
  return (paginationParams, filterParams, sorting = null) => {
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: sorting,
      },
    };

    return (dispatch) => {
      axios
        .get(`/projects/`, config)
        .then((response) => dispatch({ type: SET_OBJECTS_BREADCRUMBS, payload: response.data }))
        .catch(errorCatcher);
    };
  };
})();

export const createBuilding = (data) => (dispatch) => {
  return axios
    .post(`/building/`, data)
    .then((response) => {
      message.success("Проект успешно создан");
      dispatch({ type: ADD_BREADCRUMBS_OBJECT, payload: response.data });
      return response.data;
    })
    .catch(errorCatcher);
};

export const saveObject = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, data, errorCallback = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_SAVE,
      });
      axios
        .put(`/building/${id}/`, data, config)
        .then(
          (response) => {
            dispatch({
              type: SET_SAVE,
            });
            message.success("Изменения успешно сохранены");
          },
          (error) => errorCallback && errorCallback(error.response.data)
        )
        .catch((error) => console.log(error));
    };
  };
})();
export const savePatchObject = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, data, errorCallback = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      dispatch({
        type: LOAD_SAVE,
      });
      axios
        .patch(`/building/${id}/`, data, config)
        .then(
          (response) => {
            dispatch({
              type: SET_SAVE,
            });
            message.success("Изменения успешно сохранены");
          },
          (error) => errorCallback && errorCallback(error.response.data)
        )
        .catch((error) => console.log(error));
    };
  };
})();

export const deleteObject = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id, callback = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch) => {
      axios
        .delete(`/building/${id}/`, config)
        .then((response) => {
          message.success("Проект успешно удален");
          callback && callback();
        })
        .catch((error) => console.log(error));
    };
  };
})();
