import React, { useMemo } from "react";
import { memoize, partial } from "lodash";
import ContractIcon from "../../../../images/icons/documentsTab/ContractIcon";
import { stringifyArgs } from "../../../../utils/stringifyArgs";
import EmptyPlaceholder from "../../atoms/EmptyPlaceholder/EmptyPlaceholder";

import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import { IContract } from "types/interfaces/Contract";
import ContractsRow from "./ContractsRow/ContractsRow";
import { Spinner } from "components/UI/Spinner/Spinner";
import ContractsTableHeader from "./ContractsTableHeader/ContractsTableHeader";

interface Iprops {
  contracts: IContract[],
  files: Record<number, unknown[]>,
  isLoading: boolean,
  changeFilter: (name: string, value: string) => void;
  deleteContract: () => void,
  loadMore: () => void,
  allCount: number,
  addFiles: () => void,
  deleteFile: () => void,
  permissions: Record<string, boolean>,
  containerClassName?: string;
  headerClassName?: string
}

const ContractsTable: React.FC<Iprops> = ({
  contracts,
  files,
  isLoading,
  changeFilter,
  deleteContract,
  loadMore,
  allCount,
  addFiles,
  deleteFile,
  permissions,
  containerClassName,
  headerClassName
}) => {
  const hasNextPage = allCount > contracts.length;

  const partialAddFiles = useMemo(
    () => memoize((contractId) => partial(addFiles, contractId), stringifyArgs),
    [addFiles]
  );

  const partialDeleteFile = useMemo(
    () => memoize((contractId) => partial(deleteFile, contractId), stringifyArgs),
    [deleteFile]
  );

  const partialDeleteContract = useMemo(
    () => memoize((contractId) => partial(deleteContract, contractId), stringifyArgs),
    [deleteContract]
  );

  const filesPermissions = useMemo(() => ({
    addFiles: permissions.viewAddContractFiles,
    deleteFiles: permissions.viewDeleteContractFiles,
  }), [permissions.viewAddContractFiles, permissions.viewDeleteContractFiles]);

  return (
    <div className={containerClassName}>
      <ContractsTableHeader onChangeFilter={changeFilter} className={headerClassName} />
      {contracts?.map((el: IContract) => (
        <ContractsRow
          key={el.id}
          contract={el}
          filesPermissions={filesPermissions}
          files={files[el.id]}
          canDeleteContract={permissions.viewDeleteContract}
          onAddFile={partialAddFiles(el.id)}
          onDeleteContract={partialDeleteContract(el.id)}
          onDeleteFile={partialDeleteFile(el.id)}
        />
      ))}
      {(!isLoading && !contracts?.length) && (
        <EmptyPlaceholder text="Нет контрактов" svgJsx={<ContractIcon />} />
      )}
      {isLoading && <Spinner />}
      <ShowMoreButton isExists={hasNextPage} handleAdd={loadMore} allCount={allCount} showedCount={contracts.length} />
    </div>
  );
};

export default React.memo(ContractsTable);
