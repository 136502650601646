import React from "react";
import cn from "classnames";

import styles from "./index.module.scss";

const BlueLabel = ({ children, className, blueFont, ...props }) => {
  return (
    <div className={cn(styles.container, className, { [styles.blueFont]: blueFont })} {...props}>
      {children}
    </div>
  );
};

export default React.memo(BlueLabel);
