import React from "react";
import NavigationBar from "../../molecules/NavigationBar";
import Header from "components/UI/molecules/Header";
import cn from "classnames";
import styles from "./index.module.scss";

const TemplateSimple = ({ children, contentClassName }) => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <NavigationBar />
        <div className={cn(styles.content, contentClassName)}>
          {children}
        </div>
      </div>
    </>
  );
};

export default TemplateSimple;
