import { TASK_LISTS_TYPES, TASK_MODAL_TYPES } from "../../../../components/pages/Tasks/constants";

import { ITasksInitialState } from "./types";

import {
  addFileIdToBuffer,
  addMoreTasksByAllBuildings,
  addMoreTasksByBuilding,
  createTaskList,
  filterTasksBySection,
  insertCommentToTask,
  insertTaskByAllBuildings,
  insertTaskByCertainBuilding,
  insertTaskCommentState,
  insertTaskFromTasksList,
  openModal,
  removeOneTaskByAllBuildings,
  removeOneTaskByCertainBuilding,
  removeOneTaskFromTasksList,
  removeOneTasksList,
  renewTasksListInfo,
  replaceTaskByAllBuildings,
  replaceTaskByCertainBuilding,
  replaceTaskFromTasksList,
  setFileIdBuffer,
  setOneTask,
  setOneTaskLoading,
  updateIdInCreatedTasksList,
  updateMoreTasksByList,
  updateTasksByAllBuildings,
  updateTasksByBuilding,
  updateTasksByList,
} from "./utils";
import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

const moduleName = "tasks";
export const SET_TASKS = `${moduleName}/SET_TASKS`;
export const SET_TASKS_MORE = `${moduleName}/SET_TASKS_MORE`;
export const SET_TASKS_LOADING = `${moduleName}/SET_TASKS_LOADING`;
export const SET_TASKS_BY_ALL_BUILDINGS = `${moduleName}/SET_TASKS_BY_ALL_BUILDINGS`;
export const SET_MORE_TASKS_BY_ALL_BUILDINGS = `${moduleName}/SET_MORE_TASKS_BY_ALL_BUILDINGS`;

export const SET_TASKS_LISTS = `${moduleName}/SET_TASKS_LISTS`;
export const SET_MORE_TASKS_LISTS = `${moduleName}/SET_MORE_TASKS_LISTS`;
export const SET_TASKS_IN_LIST = `${moduleName}/SET_TASKS_IN_LIST`;
export const SET_MORE_TASKS_IN_LIST = `${moduleName}/SET_MORE_TASKS_IN_LIST`;
export const SET_TASKS_IN_LIST_LOADING = `${moduleName}/SET_TASKS_IN_LIST_LOADING`;

export const UPDATE_TASKS_LIST = `${moduleName}/UPDATE_TASKS_LIST`;
export const DELETE_TASKS_LIST = `${moduleName}/DELETE_TASKS_LIST`;
export const CREATE_TASKS_LIST = `${moduleName}/CREATE_TASKS_LIST`;
export const UPDATE_CREATED_TASKS_LIST_ID = `${moduleName}/UPDATE_CREATED_TASKS_LIST_ID`;

export const OPEN_TASK_MODAL = `${moduleName}/OPEN_TASK_MODAL`;
export const CLOSE_TASK_MODAL = `${moduleName}/CLOSE_TASK_MODAL`;

export const SET_IS_TASK_SUBMITTED = `${moduleName}/SET_IS_TASK_SUBMITTED`;

export const SET_TASKS_FIELDS = `${moduleName}/SET_TASKS_FIELDS`;
export const SET_TASK_MODAL_TYPE = `${moduleName}/SET_TASK_MODAL_TYPE`;
export const SET_TASK_MODAL_PLACEMENT_TYPE = `${moduleName}/SET_TASK_MODAL_PLACEMENT_TYPE`;

export const DELETE_TASK_IN_TASKS_LIST = `${moduleName}/DELETE_TASK_IN_TASKS_LIST`;
export const DELETE_TASK_BY_COMMON_BUILDINGS_LIST = `${moduleName}/DELETE_TASK_BY_COMMON_BUILDINGS_LIST`;
export const DELETE_TASK_BY_CERTAIN_BUILDING = `${moduleName}/DELETE_TASK_BY_CERTAIN_BUILDING`;

export const SET_CERTAIN_TASK = `${moduleName}/SET_CERTAIN_TASK`;
export const SET_CERTAIN_TASK_LOADING = `${moduleName}/SET_CERTAIN_TASK_LOADING`;

export const SET_ALL_TASKS_STATE = `${moduleName}/SET_ALL_TASKS_STATE`;

export const ADD_NEW_TASK_IN_TASKS_LIST = `${moduleName}/ADD_NEW_TASK_IN_TASKS_LIST`;
export const ADD_NEW_TASK_BY_COMMON_BUILDINGS_LIST = `${moduleName}/ADD_NEW_TASK_BY_COMMON_BUILDINGS_LIST`;
export const ADD_NEW_TASK_BY_CERTAIN_BUILDING = `${moduleName}/ADD_NEW_TASK_BY_CERTAIN_BUILDING`;

export const UPDATE_TASK_IN_TASKS_LIST = `${moduleName}/UPDATE_TASK_IN_TASKS_LIST`;
export const UPDATE_TASK_BY_COMMON_BUILDINGS_LIST = `${moduleName}/UPDATE_TASK_BY_COMMON_BUILDINGS_LIST`;
export const UPDATE_TASK_BY_CERTAIN_BUILDING = `${moduleName}/UPDATE_TASK_BY_CERTAIN_BUILDING`;

export const ADD_COMMENT_TO_TASK = `${moduleName}/ADD_COMMENT_TO_TASK`;
export const SET_TASK_COMMENT_STATE = `${moduleName}/SET_TASK_COMMENT_STATE`;

export const ADD_FILE_ID_TO_TASK_MODAL_BUFFER = `${moduleName}/ADD_FILE_ID_TO_TASK_MODAL_BUFFER`;
export const SET_TASK_MODAL_FILE_ID_BUFFER = `${moduleName}/SET_TASK_MODAL_FILE_ID_BUFFER`;

export const FILTER_TASKS_BY_SECTION = `${moduleName}/FILTER_TASKS_BY_SECTION`;
export const ADD_SECTION_TASK_FILTER = `${moduleName}/ADD_SECTION_TASK_FILTER`;
export const CLEAR_SECTION_TASK_FILTER = `${moduleName}/CLEAR_SECTION_TASK_FILTER`;
export const SET_SECTIONS_FOR_BUILDING = `${moduleName}/SET_SECTIONS_FOR_BUILDING`;

export const SET_TASKS_FILTERS = `${moduleName}/SET_TASKS_FILTERS`;

export const SET_TASKS_INDICATORS_BY_BUILDING = `${moduleName}/SET_TASKS_INDICATORS_BY_BUILDING`;
export const SET_IS_TASKS_SECTIONS_BY_BUILDING_LOADING = `${moduleName}/SET_IS_TASKS_SECTIONS_BY_BUILDING_LOADING`;
export const SET_TASKS_AGGREGATIONS = `${moduleName}/SET_TASKS_AGGREGATIONS`;

export const INITIAL_STATE: ITasksInitialState = {
  tasks: {},
  isLoading: false,
  tasksByAllBuildings: {},
  tasksLists: { results: [], count: 0 },
  tasksInLists: {},
  isTasksInListLoading: {},
  certainTasks: {},
  isCertainTaskLoading: {},
  tasksFields: {
    section: [],
    users: [],
  },
  modal: {
    type: isAllModulesAllowed() ? TASK_MODAL_TYPES.ADD : TASK_MODAL_TYPES.VIEW,
    isOpen: false,
    taskId: -1,
    listType: TASK_LISTS_TYPES.ALL,
    placementType: "byAllBuildings",
    list_id: -1,
    fileIdBufferEdit: [],
    submission: {
      isSubmitted: false,
      taskId: -1,
    },
  },
  sectionFilters: {},
  indicatorsByBuilding: {},
  isSectionsByBuildingLoading: {},
  sectionsByBuildings: {},
  aggregations: {},
  tasksFilters: {},
};

export default (state = INITIAL_STATE, action: any): ITasksInitialState => {
  const { type, payload } = action;
  switch (type) {
    case SET_TASKS:
      return updateTasksByBuilding(state, action);
    case SET_TASKS_MORE:
      return addMoreTasksByBuilding(state, action);
    case SET_TASKS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_TASKS_BY_ALL_BUILDINGS:
      return updateTasksByAllBuildings(state, action);
    case SET_MORE_TASKS_BY_ALL_BUILDINGS:
      return addMoreTasksByAllBuildings(state, action);
    case SET_TASKS_LISTS:
      return {
        ...state,
        tasksLists: payload,
      };
    case SET_MORE_TASKS_LISTS:
      return {
        ...state,
        tasksLists: {
          ...state.tasksLists,
          results: [...state.tasksLists.results, ...payload],
        },
      };
    case SET_TASKS_IN_LIST_LOADING:
      return {
        ...state,
        isTasksInListLoading: {
          ...state.isTasksInListLoading,
          [payload.listId]: payload.status,
        },
      };
    case SET_TASKS_IN_LIST:
      return updateTasksByList(state, action);
    case SET_MORE_TASKS_IN_LIST:
      return updateMoreTasksByList(state, action);
    case UPDATE_TASKS_LIST:
      return renewTasksListInfo(state, action);
    case DELETE_TASKS_LIST:
      return removeOneTasksList(state, action);
    case CREATE_TASKS_LIST:
      return createTaskList(state, action);
    case UPDATE_CREATED_TASKS_LIST_ID:
      return updateIdInCreatedTasksList(state, action);
    case DELETE_TASK_IN_TASKS_LIST:
      return removeOneTaskFromTasksList(state, action);
    case DELETE_TASK_BY_COMMON_BUILDINGS_LIST:
      return removeOneTaskByAllBuildings(state, action);
    case DELETE_TASK_BY_CERTAIN_BUILDING:
      return removeOneTaskByCertainBuilding(state, action);
    case SET_CERTAIN_TASK:
      return setOneTask(state, action);
    case SET_CERTAIN_TASK_LOADING:
      return setOneTaskLoading(state, action);
    case SET_ALL_TASKS_STATE:
      return payload;
    case ADD_NEW_TASK_IN_TASKS_LIST:
      return insertTaskFromTasksList(state, action);
    case ADD_NEW_TASK_BY_COMMON_BUILDINGS_LIST:
      return insertTaskByAllBuildings(state, action);
    case ADD_NEW_TASK_BY_CERTAIN_BUILDING:
      return insertTaskByCertainBuilding(state, action);
    case UPDATE_TASK_IN_TASKS_LIST:
      return replaceTaskFromTasksList(state, action);
    case UPDATE_TASK_BY_COMMON_BUILDINGS_LIST:
      return replaceTaskByAllBuildings(state, action);
    case UPDATE_TASK_BY_CERTAIN_BUILDING:
      return replaceTaskByCertainBuilding(state, action);
    case ADD_COMMENT_TO_TASK:
      return insertCommentToTask(state, action);
    case SET_TASK_COMMENT_STATE:
      return insertTaskCommentState(state, action);
    case ADD_FILE_ID_TO_TASK_MODAL_BUFFER:
      return addFileIdToBuffer(state, action);
    case SET_TASK_MODAL_FILE_ID_BUFFER:
      return setFileIdBuffer(state, action);
    case OPEN_TASK_MODAL:
      return openModal(state, action);
    case CLOSE_TASK_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: false,
        },
      };
    case SET_IS_TASK_SUBMITTED:
      return {
        ...state,
        modal: {
          ...state.modal,
          submission: action.payload,
        },
      };
    case SET_TASKS_FIELDS:
      return {
        ...state,
        tasksFields: payload,
      };
    case SET_TASK_MODAL_TYPE:
      return {
        ...state,
        modal: {
          ...state.modal,
          type: action.payload,
        },
      };
    case FILTER_TASKS_BY_SECTION:
      return filterTasksBySection(state, action);
    case ADD_SECTION_TASK_FILTER:
      return {
        ...state,
        sectionFilters: {
          ...state.sectionFilters,
          [payload.building]: payload.section,
        },
      };
    case CLEAR_SECTION_TASK_FILTER:
      return {
        ...state,
        sectionFilters: {},
      };
    case SET_TASK_MODAL_PLACEMENT_TYPE:
      return {
        ...state,
        modal: {
          ...state.modal,
          placementType: action.payload,
        },
      };
    case SET_TASKS_INDICATORS_BY_BUILDING:
      return {
        ...state,
        indicatorsByBuilding: {
          ...state.indicatorsByBuilding,
          [payload.building]: payload.data,
        },
      };
    case SET_IS_TASKS_SECTIONS_BY_BUILDING_LOADING:
      return {
        ...state,
        isSectionsByBuildingLoading: {
          ...state.isSectionsByBuildingLoading,
          [payload.building]: payload.status,
        },
      };
    case SET_SECTIONS_FOR_BUILDING:
      return {
        ...state,
        sectionsByBuildings: {
          ...state.sectionsByBuildings,
          [payload.building]: payload.data,
        },
      };
    case SET_TASKS_AGGREGATIONS:
      return {
        ...state,
        aggregations: {
          ...state.aggregations,
          [payload.key]: payload.data,
        },
      };
    case SET_TASKS_FILTERS:
      return {
        ...state,
        tasksFilters: action.payload,
      };
    default:
      return state;
  }
};
