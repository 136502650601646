import React, { useEffect } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  buildingEmployeesSelector,
  getEmployees,
  resetEmployees
} from "../../../../../redux/modules/common/building/object/nowObject";

import Approvers, { TYPES } from "../../../../UI/organism/Approvers";
import Viewers from "../../../../UI/organism/Viewers";
import { Spinner } from "../../../../UI/Spinner/Spinner";

import styles from "./index.module.scss";

const ApproversAndViewers = (props) => {
  const {
    buildingId,
    orderApprovers,
    paymentApprovers,
    handleDeleteOrderApprover,
    handleAddOrderApprovers,
    canAddOrderApprovers,
    canAddPaymentApprovers,
    orderViewers,
    handleDeleteOrderViewer,
    handleAddOrderViewers,
    areShownPaymentApproversAndViewers,
    paymentViewers,
    handleDeletePaymentApprover,
    handleAddPaymentApprovers,
    handleDeletePaymentViewer,
    handleAddPaymentViewers,
    approversAreLoading,
    className
  } = props;

  const dispatch = useDispatch();
  const buildingEmployees = useSelector(buildingEmployeesSelector);

  const canAddSomeApprovers = (canAddPaymentApprovers || canAddOrderApprovers) && !approversAreLoading;

  useEffect(() => {
    if (!canAddSomeApprovers || buildingEmployees.data || buildingEmployees.isLoading) return;
    compose(dispatch, getEmployees)(buildingId);
  }, [canAddSomeApprovers, buildingEmployees.data, buildingId]);

  useEffect(() => () => compose(dispatch, resetEmployees)(), []);

  if (approversAreLoading) return (
    <Spinner />
  );

  return (
    <>
      {(canAddOrderApprovers || orderApprovers.length !== 0) && (
        <Approvers
          className={cn(styles.approversViewers, className)}
          employees={buildingEmployees.data}
          type={TYPES.order}
          approvedElements={orderApprovers}
          handleDeleteEmployee={handleDeleteOrderApprover}
          handleAddEmployees={handleAddOrderApprovers}
          canAddApprovers={canAddOrderApprovers}
        />
      )}
      {(canAddOrderApprovers || orderViewers.length !== 0) && (
        <Viewers
          className={cn(styles.approversViewers, className)}
          employees={buildingEmployees.data}
          type={TYPES.order}
          viewers={orderViewers}
          handleDeleteViewer={handleDeleteOrderViewer}
          handleAddViewers={handleAddOrderViewers}
          canAddViewers={canAddOrderApprovers}
        />
      )}
      {areShownPaymentApproversAndViewers && (
        <>
          {(canAddPaymentApprovers || paymentApprovers.length !== 0) && (
            <Approvers
              className={cn(styles.approversViewers, className)}
              employees={buildingEmployees.data}
              type={TYPES.payment}
              approvedElements={paymentApprovers}
              handleDeleteEmployee={handleDeletePaymentApprover}
              handleAddEmployees={handleAddPaymentApprovers}
              canAddApprovers={canAddPaymentApprovers}
            />
          )}
          {(canAddPaymentApprovers || paymentViewers.length !== 0) && (
            <Viewers
              className={cn(styles.approversViewers, className)}
              employees={buildingEmployees.data}
              type={TYPES.payment}
              viewers={paymentViewers}
              handleDeleteViewer={handleDeletePaymentViewer}
              handleAddViewers={handleAddPaymentViewers}
              canAddViewers={canAddPaymentApprovers}
            />
          )}
        </>
      )}
    </>
  );
};

export default React.memo(ApproversAndViewers);