import React, { useEffect, useState } from 'react';
import { apiGetEstimateExpenditure } from 'redux/modules/common/building/shedulesApi';
import ProductInfoModal from 'components/UI/molecules/ProductInfoModal/ProductInfoModal';

const ProductInfo = ({
  isOpen,
  closeModal,
  objectId,
  chosenMatchingProduct,
  openRelationToProduct,
  directlyCantEdit
}) => {
  const [expenditure, setExpenditure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadEx = async (objectId, id) => {
    setIsLoading(true);
  
    const ex = await apiGetEstimateExpenditure(objectId, id);
    await setExpenditure(ex);
    await setIsLoading(false);
  };

  useEffect(() => {
    (chosenMatchingProduct?.expenditure_id || chosenMatchingProduct?.id) &&
      loadEx(objectId, (chosenMatchingProduct?.expenditure_id || chosenMatchingProduct?.id));
  }, [chosenMatchingProduct]);

  return (
    <ProductInfoModal
      isOpen={isOpen?.id && expenditure}
      isLoading={isLoading}
      onClose={closeModal}
      onOpenEditing={openRelationToProduct}
      canEdit={!directlyCantEdit}
      section={expenditure?.section?.name}
      number={expenditure?.number}
      justification={expenditure?.justification}
      expenditureName={expenditure?.name}
      price={expenditure?.price}
      count={expenditure?.count}
    />
  )
};

export default ProductInfo;
