import React from "react";

export default function ExclamationMarkInCircleIcon({color = '#000', width = "3"}) {
  return (
    <svg 
      width="31" 
      height="32" 
      viewBox="0 0 31 32" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M15.3784 1.37109C12.9058 1.37577 10.4742 2.00355 8.30828 3.19643C6.14239 4.38932 4.31207 6.10882 2.98642 8.19609C1.50571 10.5277 0.729321 13.2371 0.750419 15.9991C0.750419 18.8922 1.60834 21.7204 3.21568 24.126C4.82303 26.5315 7.10761 28.4064 9.78052 29.5136C12.4534 30.6208 15.3946 30.9104 18.2322 30.346C21.0698 29.7816 23.6762 28.3884 25.722 26.3427C27.7677 24.2969 29.1609 21.6904 29.7253 18.8529C30.2898 16.0153 30.0001 13.0741 28.8929 10.4012C27.7858 7.72828 25.9109 5.4437 23.5053 3.83636C21.0997 2.22901 18.2716 1.37109 15.3784 1.37109V1.37109Z" 
        stroke="#00C4FF" 
        strokeWidth="1.4"
      />
      <path 
        transform="translate(14.5, 9)"
        d="M0.836 9.22H1.826L2.096 0.399999H0.584L0.836 9.22ZM1.34 13.09C1.88 13.09 2.276 12.658 2.276 12.154C2.276 11.632 1.88 11.236 1.34 11.236C0.8 11.236 0.386 11.632 0.386 12.154C0.386 12.658 0.8 13.09 1.34 13.09Z" 
        fill={color}
      />
    </svg>
  )
}