import React, { useCallback, useEffect, useMemo } from "react";
import { memoize } from "lodash";

import PaymentTerm from "./components/PaymentTerm";

import CircleAddIcon from "images/icons/CircleAddIcon";

import { PAYMENT_TYPE } from "utils/constant";
import { stringifyArgs } from "utils/stringifyArgs";

import styles from "./index.module.scss";

const calculateTotalPercent = (terms) => terms.reduce((sum, item) => sum + parseFloat(item.percent), 0);
const checkAllTermsPercentsFill = (terms) => terms.every((term) => term.percent);

export const PAYMENT_TERMS = [
  { id: "prepay", name: "Предоплата", disabled: false },
  { id: "upon_shipment", name: "По факту отгрузки", disabled: false },
  { id: "delay", name: "Отсрочка", disabled: false },
];

const INITIAL_TERM = { payment_type: null, percent: 0 };
const EMPTY_TERMS = [];

const PaymentTerms = ({ selectedTerms = EMPTY_TERMS, disabled, changeSelectedTerms }) => {
  const addInitialTerm = useCallback(
    () => changeSelectedTerms([...selectedTerms, INITIAL_TERM]),
    [changeSelectedTerms, selectedTerms]
  );

  const deleteTerm = useCallback(
    (deletedTermIndex) => changeSelectedTerms(selectedTerms.filter((_term, i) => i !== deletedTermIndex)),
    [selectedTerms, changeSelectedTerms]
  );

  const memoizedDeleteTerm = useMemo(
    () => memoize((deletedTermIndex) => () => deleteTerm(deletedTermIndex), stringifyArgs),
    [deleteTerm]
  );

  const changeTerm = useCallback(
    (changedTermIndex, field, value) =>
      changeSelectedTerms(
        selectedTerms.map((term, i) => (i === changedTermIndex ? { ...term, [field]: value } : term))
      ),
    [selectedTerms, changeSelectedTerms]
  );

  const onChangePercent = useCallback(
    (changedTermIndex, e) => {
      if (isNaN(+e.target.value)) return;
      const changedTerms = selectedTerms.map((term, index) =>
        index === changedTermIndex
          ? {
              ...term,
              percent: +e.target.value,
            }
          : term
      );

      const totalPercent = calculateTotalPercent(changedTerms);

      if (totalPercent > 100) return;
      changeSelectedTerms(changedTerms);
    },
    [selectedTerms, changeSelectedTerms]
  );

  const isDelayAdded = useMemo(
    () => selectedTerms.some(({ payment_type }) => payment_type === PAYMENT_TYPE.DELAY),
    [selectedTerms]
  );

  const isShownAddButton = useMemo(
    () => checkAllTermsPercentsFill(selectedTerms) && calculateTotalPercent(selectedTerms) < 100 && !disabled,
    [disabled, selectedTerms]
  );

  useEffect(() => {
    if (selectedTerms.length !== 0) return;
    addInitialTerm();
  }, [addInitialTerm, selectedTerms.length]);

  return (
    <div className={styles.paymentTerms}>
      <header className={styles.header}>
        <h2 className={styles.headerText}>Условия оплаты:</h2>
        {isShownAddButton && (
          <button className={styles.addButton} onClick={addInitialTerm}>
            <CircleAddIcon />
          </button>
        )}
      </header>
      <div className={styles.content}>
        <div className={styles.fieldsSignatures}>
          {selectedTerms.length !== 0 && <div className={styles.fieldsSignature}>%</div>}
          {isDelayAdded && <div className={styles.fieldsSignature}>дни</div>}
        </div>
        {selectedTerms.map((term, i) => (
          <PaymentTerm
            term={term}
            disabled={disabled}
            changeTerm={changeTerm}
            onDeleteTerm={memoizedDeleteTerm(i)}
            onChangePercent={onChangePercent}
            terms={PAYMENT_TERMS}
            canDeleteTerm={selectedTerms.length > 1}
            index={i}
            key={i}
          />
        ))}
        {disabled && selectedTerms.length === 0 && "Нет условий оплаты"}
      </div>
    </div>
  );
};

export default React.memo(PaymentTerms);
