import { message } from "antd";

const messageError = (error, displayField) => {
  if (typeof error === "string") {
    message.error(error);
    return;
  }

  if (error.message) {
    message.error(`${displayField ? (error.field + ": ") : ""}${error.message}`);
  }

  if (error.nested) {
    messageErrorHandler(error.nested, displayField);
  }
};

export const messageErrorHandler = (errors, displayField) => {
  if (!errors) return;

  const messagedErrors = Array.isArray(errors) ? errors : errors.non_field_errors;
  if (!messagedErrors) return;
  messagedErrors.forEach(error => messageError(error, displayField));
};
