import React, { useCallback } from "react";

import { MonthArray } from "../../../../../utils/constant";

export const useMonthsSlider = (year, monthIndex, changeDate, selectingMonthsCount) => {
  const preparedMonthArray = React.useMemo(() => {
    return MonthArray.map((month) => ({
      ...month,
      label: month.label.length > 4 ? month.label.slice(0, 3) : month.label
    }));
  }, []);

  const [usingMonthArray, setUsingMonthArray] = React.useState(preparedMonthArray)

  const increaseMonth = useCallback(() => {
    if (selectingMonthsCount > 1) {
      const tempMonths = [...usingMonthArray]
      const firstMonth = tempMonths.shift()
      tempMonths.push(firstMonth)
      setUsingMonthArray(tempMonths)
      changeDate(year, monthIndex + 1);
    } else {
      if (monthIndex === preparedMonthArray.length - 1) {
        changeDate(year + 1, 0);
        return;
      }
      changeDate(year, monthIndex + 1);
    }
  }, [changeDate, year, monthIndex, selectingMonthsCount, usingMonthArray]);

  const decreaseMonth = useCallback(() => {
    if (selectingMonthsCount > 1) {
      const tempMonths = [...usingMonthArray]
      const lastMonth = tempMonths.pop()
      tempMonths.unshift(lastMonth)
      setUsingMonthArray(tempMonths)
      changeDate(year, monthIndex - 1);
    } else {
      if (monthIndex === 0) {
        changeDate(year - 1, preparedMonthArray.length - 1);
        return;
      }
      changeDate(year, monthIndex - 1);
    }
  }, [changeDate, year, monthIndex, selectingMonthsCount, usingMonthArray]);

  return { monthsArray: usingMonthArray, increaseMonth, decreaseMonth };
};