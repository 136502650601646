import React from "react";
import { useSelector } from "react-redux";

import { diagramFiltersSelector } from "../../../../../../../../../redux/modules/common/building/manufacturing/selectors";

import styles from "./SharedBrace.module.scss";

import TicketSharedCircle from "../../../../../../../../../images/TicketSharedCircle";
import TicketAcceptedCircle from "../../../../../../../../../images/TicketAcceptedCircle";
import TicketDeniedTriangle from "../../../../../../../../../images/TicketDeniedTriangle";
import { DiagramFilters, SharedBraceColors, SharedBraceStatuses } from "../../../../../../constants";

export interface ISharedBraceProps {
  status: keyof typeof SharedBraceStatuses;
}

const SharedBrace: React.FC<ISharedBraceProps> = ({ status }) => {
  const sharedColor = SharedBraceColors[status];

  const diagramFilters = useSelector(diagramFiltersSelector);

  const sharedBraceStyle = {
    borderBottomColor: sharedColor,
    borderLeftColor: sharedColor,
  };

  if (diagramFilters[status + '_highlight'])
    return (
      <div className={styles.sharedBrace} style={sharedBraceStyle}>
        {status === SharedBraceStatuses.confirmed && <TicketAcceptedCircle className={styles.sharedCircle} />}
        {status === SharedBraceStatuses.moderation && <TicketSharedCircle className={styles.sharedCircle} />}
        {status === SharedBraceStatuses.canceled && <TicketDeniedTriangle className={styles.sharedCircle} />}
      </div>
    );

  return null;
};

export default SharedBrace;
