import {
  SET_ALL_PERMISSIONS_DATA,
  SET_ALL_PERMISSIONS_LOADING,
  SET_CURRENT_USER_PERMISSIONS_DATA,
  SET_CURRENT_USER_PERMISSIONS_LOADING,
  SET_USER_PERMISSIONS_DATA,
  SET_USER_PERMISSIONS_LOADING,
  UPDATE_USER_PERMISSIONS_DATA,
} from "./constants";

export const setAllPermissionsDataAction = (payload) => ({ type: SET_ALL_PERMISSIONS_DATA, payload });
export const setAllPermissionsLoadingAction = (payload) => ({ type: SET_ALL_PERMISSIONS_LOADING, payload });

export const setCurrentUserPermissionsDataAction = (payload) => ({ type: SET_CURRENT_USER_PERMISSIONS_DATA, payload });
export const setCurrentUserPermissionsLoadingAction = (payload) => ({
  type: SET_CURRENT_USER_PERMISSIONS_LOADING,
  payload,
});

export const setUserPermissionsDataAction = (payload) => ({ type: SET_USER_PERMISSIONS_DATA, payload });
export const setUserPermissionsLoadingAction = (payload) => ({ type: SET_USER_PERMISSIONS_LOADING, payload });
export const updateUserPermissionsDataAction = (payload) => ({ type: UPDATE_USER_PERMISSIONS_DATA, payload });
