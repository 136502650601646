import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  allNotificationsSelector,
  getNotifications,
  isLoadingSelector,
  maxCountSelector,
  unreadValSelector,
} from "redux/modules/common/notifications";

import { Spinner } from "components/UI/Spinner/Spinner";
import NotificationItem from "./components/NotificationItem/NotificationItem";
import Modal from "./components/Modal/Modal";
import PopoverModal from "./components/PopoverModal/PopoverModal";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

import { INotification } from "./types/types";

import NotificationIcon from "images/icons/navigation/NotificationIcon";

import styles from "./Notification.module.scss";


const Notification = () => {
  const dispatch = useDispatch();

  const maxCount = useSelector(maxCountSelector);
  const isLoading = useSelector(isLoadingSelector);
  const notifications = useSelector(allNotificationsSelector);
  const unreadVal = useSelector(unreadValSelector);

  const [offset, setOffset] = useState(0);
  const [isModal, setIsModal] = useState(false);
  const [isPopover, setIsPopover] = useState(false);

  const isMaxCountNotify = notifications.length >= maxCount;

  useEffect(() => dispatch(getNotifications(offset)), []);

  const openAllNotifications = () => {
    setIsPopover(true);
    setIsModal(false);
    setOffset(10);
  };

  const openSmallModal = () => {
    setIsModal(true);
    dispatch(getNotifications(0));
  };

  const loadMore = useCallback(() => {
    if (!isMaxCountNotify) {
      setOffset((prev) => prev + 10);
    }

    if (isPopover) {
      dispatch(getNotifications(offset, true));
    }
  }, [dispatch, isMaxCountNotify, isPopover, offset]);

  const scrollHandler: React.UIEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const element = event.target as HTMLElement;

      if (element.scrollHeight - (element.scrollTop + window.innerHeight) < 150 && !isLoading && !isMaxCountNotify) {
        loadMore();
      }
    },
    [isLoading, isMaxCountNotify, loadMore]
  );

  return (
    <div className={styles.notyWrapper}>
      <div onClick={openSmallModal} className={styles.notyCounterBlock}>
        {unreadVal > 0 && (
          <div className={styles.notyCounter}>
            {unreadVal}
          </div>
        )}
        <NotificationIcon />
      </div>

      <Modal isVisible={isModal} setIsVisible={setIsModal}>
        <div className={styles.innerModal}>
          {!isLoading && !notifications.length ? (
            <EmptyPlaceholder text={"Нет уведомлений"} svgJsx={<NotificationIcon />} className={styles.emptyPlaceholder}/>
          ) : isLoading && !notifications.length ? (
            <Spinner />
          ) : (
            <>
              <div className={styles.notyQuantity}>
                Уведомления: {notifications.slice(0, 5).length} из {maxCount}
              </div>
              <div className={styles.notyList}>
                {notifications.slice(0, 5).map((notification: INotification) => (
                  <NotificationItem 
                    key={notification.id} 
                    notification={notification} 
                  /> 
                ))}
              </div>
              <div className={styles.showAllElements}>
                <ButtonBase secondary medium onClick={openAllNotifications}>
                  Показать все
                </ButtonBase>
              </div>
            </>
          )}
        </div>
      </Modal>

      <PopoverModal isVisible={isPopover} setIsVisible={setIsPopover}>
        <div onScroll={scrollHandler} className={styles.innerLargeModal}>
          {!isLoading && !notifications.length ? (
            <EmptyPlaceholder text={"Нет уведомлений"} svgJsx={<NotificationIcon />} className={styles.emptyPlaceholder}/>
          ) : isLoading && !notifications.length ? (
            <Spinner />
          ) : (
            <>
              <div className={styles.marginNoty}>
                <div className={styles.notyTextTitle}>Уведомления</div>
              </div>
              <div className={styles.notyList}>
                {notifications.map((notification: INotification) => (
                  <NotificationItem 
                    key={notification.id} 
                    notification={notification} 
                  />
                ))}
                {!isLoading && !isMaxCountNotify && <div>Загрузка данных...</div>}
              </div>
            </>
          )}
        </div>
      </PopoverModal>
    </div>
  );
};

export default React.memo(Notification);
