import axios from 'axios';

export function apiDeleteWork(buildingId, expenditureId) {
  return axios
    .delete(`/building/${buildingId}/estimate/expenditure/${expenditureId}/`)
    .then(
      ({ data }) => data,
      (error) => {
        console.log(error);
      }
    )
    .catch((e) => console.log(e));
}

export function apiGetEstimateExpenditure(buildingId, expenditureId, data) {
  return axios
    .get(`/building/${buildingId}/estimate/expenditure/${expenditureId}/`, data)
    .then(
      ({ data }) => data,
      (error) => {
        console.log(error);
      }
    )
    .catch((e) => console.log(e));
}

export function apiGetExpenditure(buildingId, expenditureId, data) {
  return axios
    .get(`/building/${buildingId}/expenditure/${expenditureId}/`, data)
    .then(
      ({ data }) => data,
      (error) => {
        console.log(error);
      }
    )
    .catch((e) => console.log(e));
}

export const apiGetEstimateExpenditures = ({ buildingId, params }) => {
  return axios
    .get(`/building/${buildingId}/estimate/expenditures/`, { params })
    .then(
      ({ data }) => data,
      (error) => {
        console.log(error);
      }
    )
    .catch((e) => console.log(e));
};

export function apiGetTicketHistory(buildingId, expenditureId) {
  return axios
    .get(`/building/${buildingId}/tickets/history/${expenditureId}/approve_works/`)
    .then(
      ({ data }) => data,
      (error) => {
        console.log(error);
      }
    )
    .catch((e) => console.log(e));
}
