import React from "react"
import { useDispatch, useSelector } from "react-redux";
import {
    workersSelector,
    loadWorkers,
    workersLoadingSelector,
    addToWorkers
} from 'redux/modules/common/building/workers';

export const useWorkersList = (objectId) => {
    const dispatch = useDispatch()
    const workers = useSelector(workersSelector);
    const isLoading = useSelector(workersLoadingSelector)

    const [filters, setFilters] = React.useState(null)

    React.useEffect(() => {
        dispatch(loadWorkers(objectId, filters))
    }, [objectId, filters])

    const loadMoreHandler = React.useCallback(() => {
        dispatch(addToWorkers(objectId, { ...filters, offset: workers?.results?.length }))
    }, [filters, workers, objectId])

    const filtersHandler = React.useCallback((value, fieldName) => {
        setFilters(prev => ({
            ...prev,
            [fieldName]: value
        }))
    }, [filters])

    return {
        workers,
        isLoading,
        filtersHandler,
        loadMoreHandler
    }
}