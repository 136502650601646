import {
  ADD_PACKING_ITEM_FILE,
  DELETE_PACKING_ITEM_FILE,
  SET_PACKING_ITEM_FILES,
  SET_PACKING_ITEMS_FILES_LOADING,
  SET_PACKING_LIST_NUMBER,
} from "./constants";

export const setPackingItemsFilesLoadingAction = (payload) => ({ type: SET_PACKING_ITEMS_FILES_LOADING, payload });
export const setPackingItemFilesAction = (payload) => ({ type: SET_PACKING_ITEM_FILES, payload });
export const addPackingItemFileAction = (payload) => ({ type: ADD_PACKING_ITEM_FILE, payload });
export const deletePackingItemFileAction = (payload) => ({ type: DELETE_PACKING_ITEM_FILE, payload });

export const setPackingListNumberAction = (payload) => ({ type: SET_PACKING_LIST_NUMBER, payload });
