import React from "react";

import {
  MATERIALS_COLOR_MAP, MATERIALS_STATUSES,
} from '../../../../../../constants'

import styles from "../../InfoPopup.module.scss";

const MaterialStatusBlock = ({ status }) => {
  return (
    <div className={styles.statusBlock}>
      <div
        className={styles.status}
        style={{ backgroundColor: MATERIALS_COLOR_MAP[status], borderColor: MATERIALS_COLOR_MAP[status] }}
      />
      <div className={styles.indicators}>
        <span className={styles.statusName}>{MATERIALS_STATUSES[status]?.name}</span>
      </div>
    </div>
  );
};

export default MaterialStatusBlock;
