import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  loadEstimateTickets,
  ticketsEstimateSelector,
  ticketsLoadingSelector,
} from "redux/modules/common/building/ticketsEstimate";

import { Spinner } from "components/UI/Spinner/Spinner";
import TableRow from "./components/TableRow";
import JournalDoneTableHead from "./components/JournalDoneTableHead/JournalDoneTableHead";
import JornalDoneModal from "components/UI/organism/WorkOrMaterialsModals/JournalDoneModal/JournalDoneModal";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalDone.module.scss";

const JournalDone = ({ isOpen, objectId, year, month }) => {
  const dispatch = useDispatch();
  const tickets = useSelector(ticketsEstimateSelector);
  const isLoading = useSelector(ticketsLoadingSelector);

  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    isOpen && loadEstimateTicket();
  }, [objectId, year, month, isOpen]);

  const loadEstimateTicket = () => {
    const params = {
      year: year,
      month: month,
      ordering: "-id",
    };

    dispatch(loadEstimateTickets(objectId, params));
  };

  return (
    isOpen ? (
      <div className={styles.container}>
        <div className={styles.tableBlock}>
          <JournalDoneTableHead />
          {isLoading && <Spinner />}
          {!isLoading && tickets?.results?.length
            ? tickets?.results?.map((expenditure) => (
                <TableRow
                  expenditure={expenditure}
                  objectId={objectId}
                  loadEstimateTicket={loadEstimateTicket}
                  onRowClick={setModalData}
                  key={expenditure.id}
                  year={year}
                  month={month}
                />
              ))
            : null}
          {!isLoading && !tickets?.results?.length ? <EmptyPlaceholder text="Нет сведений" img={jurnalIcon} /> : null}
        </div>
        <JornalDoneModal isOpen={!!modalData} data={modalData} onClose={() => setModalData(null)} />
      </div>
    ) : null
  );
};

export default React.memo(JournalDone);
