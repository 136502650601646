import { IchatInitialState, IchatMessageResponse } from "./types";

const moduleName = 'chatV2';
export const SET_CHAT_ITEMS = `${moduleName}/SET_CHAT_ITEMS`;
export const ADD_PREV_CHAT_ITEMS = `${moduleName}/ADD_PREV_CHAT_ITEMS`;
export const ADD_CHAT_ITEM = `${moduleName}/ADD_CHAT_ITEM`;
export const SET_CHAT_IS_LOADING = `${moduleName}/SET_CHAT_IS_LOADING`;
export const READ_ALL = `${moduleName}/READ_ALL`;
export const RESET_CHAT_TO_INITIAL = `${moduleName}/RESET_CHAT_TO_INITIAL`;
export const SET_FALLBACK_MESSAGES = `${moduleName}/SET_FALLBACK_MESSAGES`;

const initialState: IchatInitialState = {
  unreadValue: 0,
  allMessages: [],
  isLoading: true,
  maxCount: 0,
  unreadCount: 0
};

export default (state = initialState, action: any): IchatInitialState => {
  const { payload, type } = action;

  switch (type) {
    case SET_CHAT_ITEMS:
      return {
        ...state,
        allMessages: [...payload.results.reverse(), ...state.allMessages].reverse().reduce((result, item) => {
          if (!result.find((message: any) => message.id === item.id)) {
            result.push(item);
          }
          return result;
        }, []).reverse(),
        isLoading: false,
        maxCount: payload.count,
        unreadCount: payload.results.filter((el: IchatMessageResponse) => !el.is_read).length
      };

    case ADD_PREV_CHAT_ITEMS:
      return {
        ...state,
        allMessages: [...payload.results.reverse(), ...state.allMessages],
        isLoading: false,
        maxCount: payload.count,
        unreadValue: payload.unread_count,
      };

    case ADD_CHAT_ITEM:
      return {
        ...state,
        allMessages: [...state.allMessages, payload],
        maxCount: state.maxCount + 1
      };

    case SET_CHAT_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case READ_ALL:
      return {
        ...state,
        unreadValue: 0,
      };
    case RESET_CHAT_TO_INITIAL:
      return initialState;
    case SET_FALLBACK_MESSAGES: 
      return {
        ...state,
        allMessages: payload
      }
    default:
      return state;
  }
};