import React from "react";
import styles from "./Ks6aDocumentInfo.module.scss";
import StatusFormatter from "../../../../../../../../UI/atoms/StatusFormatter";
import { DocStatusFlowType, DocStatusNameType } from "../../../../types";

export interface IKs6aDocumentInfoProps {
  docNumber: number;
  status: {
    name: DocStatusNameType;
    flow: DocStatusFlowType;
  };
  organisationName: string;
}

const Ks6aDocumentInfo: React.FC<IKs6aDocumentInfoProps> = ({ docNumber, status, organisationName }) => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <span>Номер документа:</span>
        <span className={styles.underlined}>№{docNumber}</span>
      </div>
      <div className={styles.item}>
        <span>Статус:</span>
        <span>{StatusFormatter(status.name, { flow: status.flow })}</span>
      </div>
      <div className={styles.item}>
        <span>Наименование организации:</span>
        <span className={styles.underlined}>{organisationName}</span>
      </div>
    </div>
  );
};

export default React.memo(Ks6aDocumentInfo);
