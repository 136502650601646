import axios from 'axios'
import {createSelector} from 'reselect'

import {financeStateSelector, stateSelector as indexStateSelector} from '../finance'

const initialState = {
  sectionId: undefined,
  sections: [],
  subsections: {},
  isLoading: false,
  isLoadingItem: false,
  planFactEstimate: {}
}

const moduleName = 'planFact'
const actionsPrefix = 'constructing/finance/planFact'

const SET_SECTION_ID = `${actionsPrefix}_SET_SECTION_ID`
const SET_LOADING = `${actionsPrefix}_SET_LOADING`
const SET_LOADING_ITEM = `${actionsPrefix}_SET_LOADING_ITEM`
const SET_SECTIONS = `${actionsPrefix}_SET_SECTIONS`
const SET_SUBSECTIONS = `${actionsPrefix}_SET_SUBSECTIONS`
const SET_PLAN_FACT_ESTIMATE = `${actionsPrefix}_SET_PLAN_FACT_ESTIMATE`
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`

export const stateSelector = createSelector(financeStateSelector, (state) => state[moduleName])
export const objectIdSelector = createSelector(indexStateSelector, (state) => state.objectId)
export const sectionIdSelector = createSelector(stateSelector, (state) => state.sectionId)
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading)
export const isLoadingItemSelector = createSelector(stateSelector, (state) => state.isLoadingItem)
export const sectionsSelector = createSelector(stateSelector, (state) => state.sections)
export const subsectionsSelector = createSelector(stateSelector, (state) => state.subsections)
export const planFactEstimateSelector = createSelector(
  stateSelector,
  (state) => state.planFactEstimate
)

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case SET_SECTION_ID:
      return {
        ...state,
        sectionId: payload
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case SET_LOADING_ITEM:
      return {
        ...state,
        isLoadingItem: payload
      }
    case SET_SECTIONS:
      return {
        ...state,
        sections: payload
      }
    case SET_SUBSECTIONS:
      return {
        ...state,
        subsections: payload
      }
    case SET_PLAN_FACT_ESTIMATE:
      return {
        ...state,
        planFactEstimate: payload
      }
    case CLEAR_DATA:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export const setSectionId = (data) => ({
  type: SET_SECTION_ID,
  payload: data
})

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data
})

export const setLoadingItem = (data) => ({
  type: SET_LOADING_ITEM,
  payload: data
})

export const setSections = (data) => ({
  type: SET_SECTIONS,
  payload: data
})

export const setSubsections = (data) => ({
  type: SET_SUBSECTIONS,
  payload: data
})

export const setPlanFactEstimate = (data) => ({
  type: SET_PLAN_FACT_ESTIMATE,
  payload: data
})

export const loadSections = (buildingId, type = 'estimate') => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.get(`/building/${buildingId}/plan-fact/${type}/items/`)
      dispatch(setSections(response.data))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const apiGetSubsection = async (objectId, itemId, type = 'estimate') => {
  try {
    const response = await axios.get(`/building/${objectId}/plan-fact/${type}/items/${itemId}/`)
    return response.data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const loadSubSections = (objectId, type = 'estimate') => {
  return async (dispatch, getState) => {
    const sectionId = sectionIdSelector(getState())
    try {
      dispatch(setLoadingItem(true))
      const data = await apiGetSubsection(objectId, sectionId)
      dispatch(setSubsections(data))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoadingItem(false))
    }
  }
}

export const loadPlanFactEstimate = (objectId) => {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(`/building/${objectId}/plan-fact/estimate/`)
      dispatch(setPlanFactEstimate(response.data))
    } catch (e) {
      console.error(e)
    }
  }
}

export const clearData = () => ({
  type: CLEAR_DATA
})
