import React, { useState, useMemo } from 'react';
import cn from 'classnames';

import ButtonBase from 'components/UI/atoms/ButtonBase';
import HistoryModal from './components/HistoryModal';
import RoundChart from 'components/UI/atoms/RoundChart';

import { dropNonSignificantZeros } from "utils/dropNonSignificantZeros";

import InfoIcon from 'images/icons/InfoIcon';

import styles from './InfoPopup.module.scss';


const InfoPopup = ({ data, objectId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const chartData = useMemo(
    () => [
      { width: data?.work?.percent_fact_works, color: "#FFE86A" }, 
      { width: data?.work?.percent_accepted_works, color: '#8FE532' },
      { width: data?.work?.percent_to_payed_count, color: "#72E2E6" }, 
    ],
    [data]
  );

  return (
    <div className={styles.container}>
      <HistoryModal
        objectId={objectId}
        expenditure={data}
        isOpen={isOpen}
        handleCloseModal={() => setIsOpen(false)}
      />
      <InfoIcon />
      <div className={styles.popupContainer}>
        <div className={styles.arrow}></div>
        <div className={styles.title}>Сводные показатели</div>
        <div className={styles.infoCercleBlock}>
          <div className={styles.cercle}>
            <div className={styles.circleInner}>
              <RoundChart data={chartData} />
            </div>
          </div>
          <div className={styles.legendBlock}>
            <div className={styles.legend}>
              <div className={styles.projectBlock}></div>
              По проекту: {data?.count ? dropNonSignificantZeros(data.count) : "0.00"}
            </div>
            <div className={styles.legend}>
              <div className={cn(styles.projectBlock, styles.doneStatus)}></div>
              Выполнено: {data?.work?.fact_count ? dropNonSignificantZeros(data.work.fact_count) : "0.00"} ({data?.estimate_measure})
            </div>
            <div className={styles.legend}>
              <div className={cn(styles.projectBlock, styles.acceptedStatus)}></div>
              Принято: {data?.work?.count_accepted_work ? dropNonSignificantZeros(data.work.count_accepted_work) : "0.00"} ({data?.estimate_measure})
            </div>
            <div className={styles.legend}>
              <div className={cn(styles.projectBlock, styles.toPayStatus)}></div>
              К оплате: {data?.work?.to_payed_count ? dropNonSignificantZeros(data.work.to_payed_count) : ""}
            </div>
          </div>
        </div>
        <div className={styles.button}>
          <ButtonBase onClick={() => setIsOpen(true)}>История выполнения</ButtonBase>
        </div>
      </div>
    </div>
  );
};

export default InfoPopup;
