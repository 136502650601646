import { message } from "antd";
import Axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Action, compose } from "redux";

import { userSelector } from "redux/modules/common/auth";
import { detailDataSelector, getDetail } from "redux/modules/common/building/object/nowObject";
import { clearIntervals, clearSelecteds, setLoadingIntervals } from "redux/modules/common/building/process";
import { apiLoadProductsSection } from "redux/modules/common/building/processApi";
import { loadObjects } from "redux/modules/common/building/projects";
import {
  loadSections,
  sectionsLoadingSelector,
  sectionsSelector,
} from "redux/modules/common/building/sections/sections";
import { apiLoadSections } from "redux/modules/common/building/sections/sectionsApi";

import EstimateIndicators from "./components/EstimateIndicators/EstimateIndicators";
import ProjectHeader from "./components/ProjectHeader/ProjectHeader";
import ProjectSubsectionsHeader from "./components/ProjectHeader/ProjectSubsectionsHeader";
import ProjectNavbar from "./components/ProjectNavbar/ProjectNavbar";
import ProjectSharingBar from "./components/ProjectSharingBar/ProjectSharingBar";
import Section from "./components/Section";
import SubSection from "./components/SubSection/SubSectionContainer";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import TemplateBase from "components/UI/templates/TemplateBase";
import { ESTIMATE_STATES_IDS } from "components/pages/Handler/constants";
import ForbiddenPage from "components/routes/components/ForbiddenPage/ForbiddenPage";

import { TabsEnum } from "./enums";
import { IExpenditureTypesFiltersInBuilding, IbuildingSubsection } from "./types";
import ISection from "types/interfaces/Section";

import { useUrlModule } from "utils/hooks/useUrlModule";

import { sharingBarExistingChecker } from "./utils";
import { errorCatcher } from "utils/errorCatcher";

import estimateIcon from "images/icons/navigation/estimateIcon.svg";

import styles from "./Building.module.scss";

export interface IBuildingProps {
  editable?: boolean;
  permissions: Record<string, boolean>;
}

const Building = ({ editable = true, permissions }: IBuildingProps) => {
  const history = useHistory();
  const params = useParams<{ objectId: string }>();
  const buildingId = params.objectId;
  const dispatch = useDispatch();
  const [subSection, setSubSection] = useState<IbuildingSubsection | null>();
  const [filter, setFilter] = useState<IExpenditureTypesFiltersInBuilding | null>(null);
  const urlModule = useUrlModule();
  const objectInfo = useSelector(detailDataSelector);
  const userInfo = useSelector(userSelector);

  const sectionsFromSelector: ISection[] = useSelector(sectionsSelector);
  const sectionsAreLoading: boolean = useSelector(sectionsLoadingSelector);

  const sections = React.useMemo(() => {
    return (sectionsFromSelector || []).filter((el) => !!el.subsection_count);
  }, [sectionsFromSelector]);

  const getDefaultActiveTab = useCallback(() => {
    if (permissions.canViewProgress) return TabsEnum.progress;
    if (permissions.canViewPlan) return TabsEnum.plan;
  }, [permissions.canViewPlan, permissions.canViewProgress]);
  const [activeTab, setActiveTab] = useState<TabsEnum | undefined>(getDefaultActiveTab());

  const [isIndicatorsOpen, setIsIndicatorsOpen] = useState(true);
  const [selectingMonthsCount, setSelectingMonthsCount] = useState(1);

  const [date, setDate] = useState(moment());

  const startOfMonthFormattedDate = useMemo(() => moment(date).startOf("month").format("YYYY-MM-DD"), [date]);
  const endOfMonthFormattedDate = useMemo(
    () =>
      moment(date)
        .add(selectingMonthsCount - 1, "month")
        .endOf("month")
        .format("YYYY-MM-DD"),
    [date, selectingMonthsCount]
  );

  const loadSubSection = async (subSectionId: number) => {
    setIsLoadingSubSections(true);
    const section = await apiLoadProductsSection(+buildingId, subSectionId, editable);

    await apiLoadSections(buildingId, ESTIMATE_STATES_IDS.PRODUCTION, {
      parent: subSectionId,
    })
      .then((responseData) => setSubSection(() => ({ ...section, subsections: responseData.results })))
      .catch(errorCatcher);
    setIsLoadingSubSections(false);
  };

  const approveSharingHandler = () => {
    Axios.post(`/building/${buildingId}/estimate/items/entities/change_status/`, { status: "accepted" })
      .then(() => {
        dispatch(loadObjects(null, { limit: 10, offset: 0 }));
        dispatch(loadObjects(null, { limit: 10, offset: 0, shared_status: "transferred" }));
        message.success("Успешно принято");
      })
      .catch(errorCatcher);
  };

  const declineSharingHandler = () => {
    Axios.post(`/building/${buildingId}/estimate/items/entities/change_status/`, { status: "canceled" })
      .then(() => {
        dispatch(loadObjects(null, { limit: 10, offset: 0 }));
        dispatch(loadObjects(null, { limit: 10, offset: 0, shared_status: "transferred" }));
        message.success("Успешно отклонено");
      })
      .catch(errorCatcher);
  };

  const [wasSectionsAutoExpanded, setWasSectionsAutoExpanded] = useState(false);
  const [isLoadingSubSections, setIsLoadingSubSections] = useState(false);

  useEffect(() => {
    dispatch(getDetail(buildingId));
    dispatch(clearSelecteds());
    setSubSection(null);
    compose<Action>(dispatch, loadSections)({ estimateStateId: ESTIMATE_STATES_IDS.PRODUCTION, buildingId });
  }, [buildingId]);

  useEffect(() => {
    setWasSectionsAutoExpanded(false);
  }, [buildingId]);

  useEffect(() => {
    if (sections?.length === 1 && !wasSectionsAutoExpanded) {
      loadSubSection(sections[0].id);
      setWasSectionsAutoExpanded(true);
    }
  }, [sections]);

  const isShared = useMemo(
    () => Boolean(objectInfo?.is_shared && objectInfo?.shared_status === "transferred"),
    [objectInfo?.is_shared, objectInfo?.shared_status]
  );

  const canSharing = useMemo(() => {
    if (userInfo.tariff === "BASIC") return false;
    return sharingBarExistingChecker({
      isShared,
      haveSharingPlanPermission: permissions.canSharePlan,
      haveSharingProgressPermission: permissions.canShareProgress,
      activeTab,
    });
  }, [isShared, permissions, activeTab, userInfo]);

  const isSectionsExist = useMemo(() => sections && sections.length > 0, [sections]);

  const backPath = useMemo(() => (urlModule === "constructing" ? "/constructing/projects" : false), [urlModule]);

  useEffect(() => {
    if (permissions.canViewPlan && !permissions.canViewProgress) {
      setActiveTab(TabsEnum.plan);
    }
  }, [permissions]);

  if ((!permissions.canViewPlan && !permissions.canViewProgress) || !activeTab) {
    return (
      <TemplateBase>
        <ForbiddenPage />
      </TemplateBase>
    );
  }

  return (
    <TemplateBase contentClassName={styles.outerTemplate}>
      <div className={styles.head}>
        <ProjectNavbar
          isTabs={!isShared}
          onTabChange={(tab: TabsEnum) => {
            if (tab === activeTab) return;
            dispatch(clearIntervals());
            dispatch(setLoadingIntervals(true));
            setActiveTab(tab);
          }}
          activeTab={activeTab}
          filters={filter}
          setFilters={setFilter}
          timeSliderDate={date}
          onChangeTimeSlider={setDate}
          selectingMonthsCount={selectingMonthsCount}
          setSelectingMonthsCount={setSelectingMonthsCount}
          permissions={permissions}
        />
        {isSectionsExist && (
          <>
            <EstimateIndicators id={buildingId} isOpen={isIndicatorsOpen} setIsOpen={setIsIndicatorsOpen} />
            <ProjectHeader
              isExists={!subSection}
              back={backPath}
              name={objectInfo?.name}
              isShared={isShared}
              onApproveSharing={approveSharingHandler}
              onDeclineSharing={declineSharingHandler}
            />
            <ProjectSubsectionsHeader
              onBackClick={() => setSubSection(null)}
              isExists={subSection}
              projectName={objectInfo?.name}
              isShared={isShared}
              subSection={subSection}
              onApproveSharing={approveSharingHandler}
              onDeclineSharing={declineSharingHandler}
            />
            <ProjectSharingBar isExists={canSharing} />
          </>
        )}
      </div>
      {isSectionsExist ? (
        <div className={styles.container}>
          {isLoadingSubSections && <Spinner />}
          {!subSection &&
            sections &&
            !isLoadingSubSections &&
            sections?.map((section) => (
              <div key={section.id} onClick={() => loadSubSection(section.id)}>
                <Section section={section} canSharing={canSharing} />
              </div>
            ))}
          {subSection && (
            <>
              {subSection.subsections?.length > 0 &&
                subSection.subsections?.map((subSection) => {
                  return (
                    <SubSection
                      objectId={+buildingId}
                      section={subSection}
                      editable={editable}
                      activeTab={activeTab}
                      date_start={startOfMonthFormattedDate}
                      date_end={endOfMonthFormattedDate}
                      isShared={isShared}
                      isIndicatorsOpen={isIndicatorsOpen}
                      key={subSection.id}
                      filters={filter!}
                      canSharing={canSharing} // @ts-ignore
                      permissions={permissions}
                    />
                  );
                })}
              {subSection.subsections?.length === 0 && <div className={styles.errorMessage}>Нет разделов</div>}
            </>
          )}
        </div>
      ) : sectionsAreLoading ? (
        <div data-testid="spinner">
          <Spinner />
        </div>
      ) : (
        <EmptyPlaceholder img={estimateIcon}>
          <ButtonBase primary onClick={() => history.push(`/constructing/handler/${buildingId}`)}>
            Импортировать данные
          </ButtonBase>
        </EmptyPlaceholder>
      )}
    </TemplateBase>
  );
};

export default Building;
