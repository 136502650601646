import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import cn from "classnames";

import useEscapeHandler from "../../../../utils/hooks/useEscapeHandler";

import MonthPicker from "./components/MonthPicker/index";
import DatePicker from "./components/DatePicker/index";
import Icon from "components/UI/Icon";

import calendarIcon from "images/icons/calendarIcon.svg";

import styles from "./index.module.scss";

const Calendar = ({
  value,
  setValue,
  classNameSelect,
  classNameOptions,
  disabled = false,
  icon = calendarIcon,
  label,
  placeholder = "",
  format = "DD.MM.YY",
  testId,
}) => {
  const [date, setDate] = useState(value || moment());
  const [isOpen, setIsOpen] = useState(false);

  useEscapeHandler(() => setIsOpen(false));

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const setToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSetDate = (date) => {
    setValue(date);
    setToggle();
  };

  const submitHandler = (date) => {
    setTimeout(() => {
      handleSetDate(date);
    }, 100);
  };

  return (
    <div>
      {label && <label className={styles.label}>{label}</label>}
      <div
        className={cn(styles.select, classNameSelect, {
          [styles.isOpen]: isOpen && !disabled,
        })}
        onClick={setToggle}
        ref={wrapperRef}
      >
        <span className={cn(styles.title)} data-testid={testId}>
          {value ? moment(value).format(format) : placeholder}
        </span>
        <Icon icon={icon} className={styles.calendarIcon} />
        {!disabled && (
          <div
            className={cn(styles.optionsBlock, classNameOptions, {
              [styles.isOpen]: isOpen,
            })}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.wrapper}>
              <div className={styles.smallPicker} />
              <div>
                <MonthPicker date={date} setDate={setDate} />
                <DatePicker date={date} setDate={setDate} onDirectlyChangeDate={submitHandler} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Calendar);
