import { useParams } from "react-router-dom";
import { useUrlModule } from "./useUrlModule";


export const useUrlModuleWithObjectId = (objectIdFallback?: string): string => {
  const urlModule = useUrlModule();
  const { objectId } = useParams<{ objectId?: string }>();

  if (urlModule === "objects") {
    return `objects/${objectId || objectIdFallback}`
  }

  return urlModule;
}