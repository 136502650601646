import React, { FC, useMemo } from "react";
import { memoize } from "lodash";

import { stringifyArgs } from "utils/stringifyArgs";

import HideBlock from "components/UI/atoms/HideBlock";

import ISection from "types/interfaces/Section";
import IExpenditure from "types/interfaces/Expenditure";

import HiddenSubsection from "./components/HiddenSubsection/HiddenSubsection";
import styles from "./SectionsWithCheckedExpenditures.module.scss";

interface IEstimateTarget {
  sectionId: ISection["id"];
  subsectionId: ISection["id"];
  expenditureId: IExpenditure["id"];
}

export interface ISectionsWithCheckedExpendituresProps {
  sections: (ISection & { subsections: ISection[] })[];
  expendituresBySections: IExpenditure[];
  checkedExpenditures: Record<IExpenditure["id"], boolean>;
  onCheckExpenditure: (target: IEstimateTarget, isChecked: boolean) => void;
  onOpenHiddenSubsection: (subsectionId: ISection["id"]) => void;
  isDisabled?: boolean;
}

const SectionsWithCheckedExpenditures: FC<ISectionsWithCheckedExpendituresProps> = ({
  sections,
  expendituresBySections,
  checkedExpenditures,
  onCheckExpenditure,
  onOpenHiddenSubsection,
}) => {
  const partialCheckExpenditure =
    (sectionId: ISection["id"]) => (estimateTarget: Omit<IEstimateTarget, "sectionId">, isChecked: boolean) => {
      onCheckExpenditure(
        {
          ...estimateTarget,
          sectionId,
        },
        isChecked
      );
    };

  const memoizedPartialCheckExpenditure = useMemo(
    () => memoize(partialCheckExpenditure, stringifyArgs),
    [onCheckExpenditure]
  );

  return (
    <div>
      {sections.map((section) => (
        <HideBlock titleClassName={styles.sectionTitleClassName} title={section.name} key={section.id}>
          <div className={styles.subSection}>
            {Array.isArray(section.subsections) && 
              section.subsections.map((subsection) => (
                <HiddenSubsection
                  subsection={subsection}
                  expenditures={expendituresBySections[subsection.id]}
                  expendituresAreLoading={!expendituresBySections[subsection.id]}
                  checkedExpenditures={checkedExpenditures}
                  onCheckExpenditure={memoizedPartialCheckExpenditure(section.id)}
                  onOpen={onOpenHiddenSubsection}
                  key={subsection.id}
                />
              )
            )}
          </div>
        </HideBlock>
      ))}
    </div>
  );
};

export default React.memo(SectionsWithCheckedExpenditures);
