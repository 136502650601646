import { MATERIALS_TAB_ID, WORKS_TAB_ID } from "../Manufacturing/constants";
import {
  ExpandedBranchesType,
  IProcessedBranch,
  ISpittingTreeElement,
  ManufacturingTabsType
} from '../Manufacturing/types'

export interface ISpittingTreeGenerationFnProps {
  projectTree: any;
  tab: ManufacturingTabsType;
  expandedBranches: ExpandedBranchesType;
}

export const spittingTreeGenerationFn = ({ projectTree, tab, expandedBranches }: ISpittingTreeGenerationFnProps) => {
  const newSpittingTree: ISpittingTreeElement[] = [];
  projectTree?.map((item) => {
    const hasToBeShown = tab === WORKS_TAB_ID || (tab === MATERIALS_TAB_ID && item.count_material);
    if (!hasToBeShown) return;
    newSpittingTree.push({
      id: item.id,
      name: item.name,
      lvl: 1,
      childCount: item.children?.length || 0,
      count_work: item.count_work,
      count_material: item.count_material,
      data: item,
      collapsed: expandedBranches.has(item.id),
    });
    if (!expandedBranches.has(item.id)) {
      item.children?.length &&
        item.children.map((itemChild) => {
          const hasToBeShown = tab === WORKS_TAB_ID || (tab === MATERIALS_TAB_ID && itemChild.count_material);
          if (!hasToBeShown) return;
          newSpittingTree.push({
            id: itemChild.id,
            name: itemChild.name,
            lvl: 2,
            childCount: itemChild.expenditures?.length || 0,
            count_work: itemChild.count_work,
            count_material: itemChild.count_material,
            data: itemChild,
            collapsed: expandedBranches.has(itemChild.id),
          });
          if (!expandedBranches.has(itemChild.id)) {
            itemChild.expenditures?.map((itemExpenditure) => {
              newSpittingTree.push({
                id: itemExpenditure.id,
                name: itemExpenditure.name,
                lvl: 3,
                childCount: 0,
                data: itemExpenditure,
                collapsed: false,
              });
            });
          }
        });
    }
  });
  return newSpittingTree;
};


export const checkIsMonthBranchShownAsSection = (branch: IProcessedBranch) => (branch.lvl < 3 && branch.collapsed)

export const checkIsMonthBranchShownAsExpenditure = (branch: IProcessedBranch) => branch.lvl === 3

export const checkIsShownSectionPlan = (branch: IProcessedBranch) => branch.lvl === 1 && branch.collapsed || branch.lvl === 2;
