import { message } from "antd"
import axios from "axios"
import moment from "moment"
import React from "react"
import { useParams } from "react-router-dom"
import { createSectionPlan } from "redux/modules/common/building/processApi"
import { IRouterParamsWithObjectId } from "types/routerTypes"
import { errorCatcher } from "utils/errorCatcher"

const DEFAULT_DATE = moment()

export const useSectionPlan = (sectionId: number, onClose: () => void) => {
    const { objectId } = useParams<IRouterParamsWithObjectId>()
    const [dateStart, setDateStart] = React.useState(DEFAULT_DATE)
    const [dateEnd, setDateEnd] = React.useState(DEFAULT_DATE)

    const submitHandler = () => {
        if (!objectId) return

        const data = {
            start_at: dateStart.format('YYYY-MM-DD'),
            end_at: dateEnd.format('YYYY-MM-DD'),
            section_id: String(sectionId),
        }

        createSectionPlan(objectId, data)
            .then(() => {
                message.success("План создан")
                onClose()
            })
            .catch(errorCatcher)
    }

    return {
        dateEnd,
        dateStart,
        setDateEnd,
        setDateStart,
        submitHandler
    }
}