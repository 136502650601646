import { createSelector } from "reselect";
import axios from "axios";
import { message } from "antd";
import { compose } from "redux";

import {
  apiCreateExpenditure,
  apiLoadExpenditures,
  apiLoadSection,
  apiLoadSections,
  apiLoadTypeExpenditures,
  changeEstimateItemsStateRequest,
  changeEstimateItemsStateToProductionRequest,
  changeEstimateItemStatusRequest,
  changeExpenditureRequest,
  changeSectionRequest,
  deleteExpenditureRequest,
  getExpenditureKsRequest,
  getExpenditureSubMaterialsRequest,
} from "./sectionsApi";
import { loadContracts } from "../aggregations";
import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../../components/pages/Handler/constants";

import { changeArrayItemById } from "./utils/changeArrayItemById";
import { filterArrayItemById } from "./utils/filterArrayItemById";
import { addOrReplaceItemInArray } from "./utils/addOrReplaceItemInArray";
import { filterSectionsWithoutExpenditures } from "./utils/filterSectionsWithoutExpenditures";
import { errorCatcher } from "../../../../../utils/errorCatcher";
import { queryParamsFormatter } from "utils/queryParamsFormatter";

const moduleName = "sections";

const SET_SECTIONS_WITH_CONFIRMED_CHILD_STATUS = `${moduleName}/SET_SECTIONS_WITH_CONFIRMED_CHILD_STATUS`;
const SET_SECTIONS_WITH_NEW_CHILD_STATUS = `${moduleName}/SET_SECTIONS_WITH_NEW_CHILD_STATUS`;
const SET_SECTIONS = `${moduleName}/SET_SECTIONS`;
const SET_SECTIONS_LOADING = `${moduleName}/SET_SECTIONS_LOADING`;

const CONFIRM_SECTION = `${moduleName}/CONFIRM_SECTION`;
const CANCEL_SECTION = `${moduleName}/CANCEL_SECTION`;
const DELETE_SECTION = `${moduleName}/DELETE_SECTION`;
const RESET_SECTIONS = `${moduleName}/RESET_SECTIONS`;
const CHANGE_SECTION = `${moduleName}/CHANGE_SECTION`;
const ADD_SECTION = `${moduleName}/ADD_SECTION`;

const SET_SECTION = `${moduleName}/SET_SECTION`;
const SET_SECTION_IS_LOADING = `${moduleName}/SET_SECTION_IS_LOADING`;
const RESET_SECTION = `${moduleName}/RESET_SECTION`;

const SET_EXPENDITURES = `${moduleName}/SET_EXPENDITURES`;
const SET_EXPENDITURES_ARE_LOADING = `${moduleName}/SET_EXPENDITURES_ARE_LOADING`;
const RESET_EXPENDITURES = `${moduleName}/RESET_EXPENDITURES`;
const ADD_EXPENDITURE = `${moduleName}/ADD_EXPENDITURE`;
const CHANGE_EXPENDITURE = `${moduleName}/CHANGE_EXPENDITURE`;
const CONFIRM_EXPENDITURE = `${moduleName}/CONFIRM_EXPENDITURE`;
const CANCEL_EXPENDITURE = `${moduleName}/CANCEL_EXPENDITURE`;
const DELETE_EXPENDITURE = `${moduleName}/DELETE_EXPENDITURE`;

const SET_EXPENDITURE_SUB_MATERIALS = `${moduleName}/SET_EXPENDITURE_SUB_MATERIALS`;
const SET_EXPENDITURES_SUB_MATERIALS_LOADING = `${moduleName}/SET_EXPENDITURES_SUB_MATERIALS_LOADING`;
const RESET_EXPENDITURES_SUB_MATERIALS = `${moduleName}/RESET_EXPENDITURES_SUB_MATERIALS`;

const SET_EXPENDITURE_KS = `${moduleName}/SET_EXPENDITURE_KS`;
const RESET_EXPENDITURE_KS = `${moduleName}/RESET_EXPENDITURE_KS`;

const ADD_EXPENDITURES_BY_SECTION = `${moduleName}/ADD_EXPENDITURES_BY_SECTION`;

const SET_SECTIONS_LOADING_BY_KEY = `${moduleName}/SET_SECTIONS_LOADING_BY_KEY`;

export const INITIAL_STATE = {
  sectionsWithConfirmedChildStatus: null,
  sectionsWithNewChildStatus: null,
  sections: null,
  sectionsAreLoading: true,
  section: null,
  sectionIsLoading: true,
  expenditures: null,
  expendituresAreLoading: false,
  expendituresSubMaterials: {},
  expendituresSubMaterialsAreLoading: false,
  expenditureKs: null,
  expendituresBySections: {},
  sectionsLoadingByKey: {},
};

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SECTION:
      return { ...state, section: payload };
    case SET_SECTION_IS_LOADING:
      return { ...state, sectionIsLoading: payload };
    case RESET_SECTION:
      return { ...state, section: INITIAL_STATE.section };
    case RESET_SECTIONS:
      return {
        ...state,
        sectionsWithConfirmedChildStatus: INITIAL_STATE.sectionsWithConfirmedChildStatus,
        sectionsWithNewChildStatus: INITIAL_STATE.sectionsWithNewChildStatus,
        sections: INITIAL_STATE.sections,
      };
    case SET_SECTIONS_WITH_CONFIRMED_CHILD_STATUS:
      return { ...state, sectionsWithConfirmedChildStatus: payload };
    case SET_SECTIONS_WITH_NEW_CHILD_STATUS:
      return { ...state, sectionsWithNewChildStatus: payload };
    case SET_SECTIONS:
      return { ...state, sections: payload };
    case SET_SECTIONS_LOADING:
      return { ...state, sectionsAreLoading: payload };
    case SET_EXPENDITURES:
      return { ...state, expenditures: payload };
    case SET_EXPENDITURES_ARE_LOADING:
      return { ...state, expendituresAreLoading: payload };
    case RESET_EXPENDITURES:
      return { ...state, expenditures: INITIAL_STATE.expenditures };
    case ADD_EXPENDITURE:
      return { ...state, expenditures: { ...state.expenditures, results: [payload, ...state.expenditures.results] } };
    case CHANGE_EXPENDITURE:
      return {
        ...state,
        expenditures: {
          ...state.expenditures,
          results: state.expenditures.results.map((expenditure) =>
            expenditure.id === payload.id ? { ...expenditure, ...payload } : expenditure
          ),
        },
      };
    case DELETE_EXPENDITURE:
      return {
        ...state,
        expenditures: {
          ...state.expenditures,
          results: state.expenditures.results.filter((expenditure) => expenditure.id !== payload.expenditureId),
        },
      };
    case CONFIRM_SECTION:
      return {
        ...state,
        sectionsWithNewChildStatus: filterArrayItemById(state.sectionsWithNewChildStatus, payload.id),
        sectionsWithConfirmedChildStatus: addOrReplaceItemInArray(state.sectionsWithConfirmedChildStatus, payload),
      };
    case CANCEL_SECTION:
      return {
        ...state,
        sectionsWithNewChildStatus: filterArrayItemById(state.sectionsWithNewChildStatus, payload.id),
      };
    case DELETE_SECTION:
      return {
        ...state,
        sections: filterArrayItemById(state.sections, payload.sectionId),
        sectionsWithNewChildStatus: filterArrayItemById(state.sectionsWithNewChildStatus, payload.sectionId),
        sectionsWithConfirmedChildStatus: filterArrayItemById(
          state.sectionsWithConfirmedChildStatus,
          payload.sectionId
        ),
      };
    case CHANGE_SECTION:
      return {
        ...state,
        sections: changeArrayItemById(state.sections, payload),
        sectionsWithConfirmedChildStatus: changeArrayItemById(state.sectionsWithConfirmedChildStatus, payload),
        sectionsWithNewChildStatus: changeArrayItemById(state.sectionsWithNewChildStatus, payload),
      };
    case ADD_SECTION:
      return { ...state, sections: [payload, ...state.sections] };

    // можно утверждать или отклонять только со статусом "new"
    // поэтому в state.expenditures.results в данный момент лежат только expenditures со статусом "new"
    // утверждение/отклонение это смена статуса expenditure
    case CONFIRM_EXPENDITURE:
    case CANCEL_EXPENDITURE:
      return {
        ...state,
        expenditures: { ...state.expenditures, results: filterArrayItemById(state.expenditures.results, payload) },
      };
    case SET_EXPENDITURE_SUB_MATERIALS:
      return {
        ...state,
        expendituresSubMaterials: { ...state.expendituresSubMaterials, [payload.id]: payload.expenditures },
      };
    case SET_EXPENDITURES_SUB_MATERIALS_LOADING:
      return { ...state, expendituresSubMaterialsAreLoading: payload };
    case RESET_EXPENDITURES_SUB_MATERIALS:
      return { ...state, expendituresSubMaterials: INITIAL_STATE.expendituresSubMaterials };
    case SET_EXPENDITURE_KS:
      return { ...state, expenditureKs: payload };
    case RESET_EXPENDITURE_KS:
      return { ...state, expenditureKs: INITIAL_STATE.expenditureKs };
    case ADD_EXPENDITURES_BY_SECTION:
      return {
        ...state,
        expendituresBySections: {
          ...state.expendituresBySections,
          [payload.sectionId]: payload.expenditures,
        },
      };
    case SET_SECTIONS_LOADING_BY_KEY:
      return {
        ...state,
        disabledSectionsByKey: {
          ...state.sectionsLoadingByKey,
          [payload.key]: payload.isLoading,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const stateSelector = (state) => state[moduleName];

export const sectionsWithConfirmedChildStatusSelector = createSelector(
  stateSelector,
  (state) => state.sectionsWithConfirmedChildStatus
);
export const sectionsWithNewChildStatusSelector = createSelector(
  stateSelector,
  (state) => state.sectionsWithNewChildStatus
);
export const sectionsSelector = createSelector(stateSelector, (state) => state.sections);
export const sectionsLoadingSelector = createSelector(stateSelector, (state) => state.sectionsAreLoading);

export const sectionSelector = createSelector(stateSelector, (state) => state.section);
export const sectionIsLoadingSelector = createSelector(stateSelector, (state) => state.sectionIsLoading);

export const expendituresSelector = createSelector(stateSelector, (state) => state.expenditures);
export const expendituresAreLoadingSelector = createSelector(stateSelector, (state) => state.expendituresAreLoading);
export const expendituresSubMaterialsSelector = createSelector(
  stateSelector,
  (state) => state.expendituresSubMaterials
);
export const expendituresSubMaterialsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.expendituresSubMaterialsAreLoading
);

export const expendituresKsSelector = createSelector(stateSelector, (state) => state.expenditureKs);
export const expendituresKsLoadingSelector = createSelector(stateSelector, (state) => state.expenditureKsAreLoading);

export const expendituresBySectionsSelector = createSelector(stateSelector, (state) => state.expendituresBySections);

export const sectionsLoadingByKeySelector = createSelector(stateSelector, (state) => state.sectionsLoadingByKey);

const setSectionsWithConfirmedChildStatusAction = (payload) => ({
  type: SET_SECTIONS_WITH_CONFIRMED_CHILD_STATUS,
  payload,
});
const setSectionsWithNewChildStatusAction = (payload) => ({ type: SET_SECTIONS_WITH_NEW_CHILD_STATUS, payload });
const setSectionsAction = (payload) => ({ type: SET_SECTIONS, payload });
const setSectionsLoadingAction = (payload) => ({ type: SET_SECTIONS_LOADING, payload });
export const resetSectionsAction = () => ({ type: RESET_SECTIONS });

const setSectionIsLoadingAction = (payload) => ({ type: SET_SECTION_IS_LOADING, payload });
const confirmSectionAction = (payload) => ({ type: CONFIRM_SECTION, payload });
const cancelSectionAction = (payload) => ({ type: CANCEL_SECTION, payload });
const deleteSectionAction = (payload) => ({ type: DELETE_SECTION, payload });
const changeSectionAction = (payload) => ({ type: CHANGE_SECTION, payload });
const addSectionAction = (payload) => ({ type: ADD_SECTION, payload });

const setExpendituresAction = (payload) => ({ type: SET_EXPENDITURES, payload });
const setExpendituresAreLoadingAction = (payload) => ({ type: SET_EXPENDITURES_ARE_LOADING, payload });
export const resetExpendituresAction = () => ({ type: RESET_EXPENDITURES });
const addExpenditureAction = (payload) => ({ type: ADD_EXPENDITURE, payload });
const changeExpenditureAction = (payload) => ({ type: CHANGE_EXPENDITURE, payload });
const confirmExpenditureAction = (payload) => ({ type: CONFIRM_EXPENDITURE, payload });
const cancelExpenditureAction = (payload) => ({ type: CANCEL_EXPENDITURE, payload });
const deleteExpenditureAction = (payload) => ({ type: DELETE_EXPENDITURE, payload });

export const resetSectionAction = () => ({ type: RESET_SECTION });

export const startLoadingSection = (key) => ({ type: SET_SECTIONS_LOADING_BY_KEY, payload: { key, isLoading: true } });
export const stopLoadingSection = (key) => ({ type: SET_SECTIONS_LOADING_BY_KEY, payload: { key, isLoading: false } });
export const getSectionKey = (sectionId, buildingId) => `${sectionId}_${buildingId}`;

export const setExpenditureSubMaterialsAction = (payload) => ({ type: SET_EXPENDITURE_SUB_MATERIALS, payload });
export const setExpenditureSubMaterialsLoadingAction = (payload) => ({
  type: SET_EXPENDITURES_SUB_MATERIALS_LOADING,
  payload,
});
export const resetExpendituresSubMaterialsAction = () => ({ type: RESET_EXPENDITURES_SUB_MATERIALS });

export const setExpenditureKsAction = (payload) => ({ type: SET_EXPENDITURE_KS, payload });
export const resetExpenditureKsAction = () => ({ type: RESET_EXPENDITURE_KS });

export const addExpendituresBySectionAction = (payload) => ({ type: ADD_EXPENDITURES_BY_SECTION, payload });

export const loadSectionsWithConfirmedChildStatus =
  ({ buildingId, estimateStateId, parentId, chapterId }) =>
    async (dispatch) => {
      const requestParams = { with_child_status: ESTIMATE_ITEM_STATUSES.CONFIRMED };
      if (parentId) requestParams.parent = parentId;
      if (chapterId) requestParams.chapter = chapterId;

      return apiLoadSections(buildingId, estimateStateId, requestParams).then((responseData) =>
        compose(dispatch, setSectionsWithConfirmedChildStatusAction)(responseData.results)
      );
    };

export const loadSections =
  ({ buildingId, estimateStateId, parentId, chapterId }) =>
    async (dispatch) => {
      const requestParams = { parent: parentId };
      if (chapterId) requestParams.chapter = chapterId;

      compose(dispatch, setSectionsLoadingAction)(true);
      const response = await apiLoadSections(buildingId, estimateStateId, requestParams).then((responseData) =>
        compose(dispatch, setSectionsAction)(responseData.results)
      );
      compose(dispatch, setSectionsLoadingAction)(false);

      return response;
    };

export const loadSectionsWithNewChildStatus =
  ({ buildingId, estimateStateId, parentId, chapterId }) =>
    async (dispatch) => {
      const requestParams = { with_child_status: ESTIMATE_ITEM_STATUSES.NEW };
      if (chapterId) requestParams.chapter = chapterId;
      if (parentId) requestParams.parent = parentId;

      return apiLoadSections(buildingId, estimateStateId, requestParams).then((responseData) => {
        compose(dispatch, setSectionsWithNewChildStatusAction, filterSectionsWithoutExpenditures)(responseData.results);
      });
    };

export const loadSection = (buildingId, sectionId, estimateStateId) => async (dispatch) => {
  compose(dispatch, setSectionIsLoadingAction)(true);
  await apiLoadSection(buildingId, sectionId, estimateStateId).then((responseData) =>
    dispatch({
      type: SET_SECTION,
      payload: responseData,
    })
  );
  compose(dispatch, setSectionIsLoadingAction)(false);
};

export const addSection = (buildingId, section) => (dispatch) => {
  return axios
    .post(`/building/${buildingId}/estimate/draft/sections/`, section)
    .then((response) => {
      compose(dispatch, addSectionAction)(response.data);
      message.success(`${section.parent ? "Подраздел" : "Раздел"} успешно создан`);
    })
    .catch(errorCatcher);
};

export const changeSection =
  (buildingId, estimateStateId, { childStatus, ...changedSection }) =>
    (dispatch) => {
      return changeSectionRequest(buildingId, estimateStateId, changedSection)
        .then((response) => {
          message.success("Раздел успешно изменен");
          compose(dispatch, changeSectionAction)(response.data);
        })
        .catch(errorCatcher);
    };

export const deleteSection = (buildingId, sectionId, estimateStateId) => (dispatch) => {
  return axios
    .delete(`/building/${buildingId}/estimate/${estimateStateId}/sections/${sectionId}/`)
    .then(() => {
      message.success(`Раздел успешно удален`);
      compose(dispatch, deleteSectionAction)({ sectionId });
    })
    .catch(errorCatcher);
};

export const loadExpenditures = (ids, expenditureStatus) => async (dispatch) => {
  compose(dispatch, setExpendituresAreLoadingAction)(true);

  let expenditures;
  if (ids.estimateState === ESTIMATE_STATES_IDS.DRAFT) {
    expenditures = await apiLoadExpenditures(ids.building, ids.section);
  } else {
    expenditures = await apiLoadTypeExpenditures(ids.building, { section: ids.section }, ids.estimateState);
  }

  const estimateStateNotDraftAndProduction =
    [ESTIMATE_STATES_IDS.DRAFT, ESTIMATE_STATES_IDS.PRODUCTION].indexOf(ids.estimateState) === -1;

  if (expenditureStatus && estimateStateNotDraftAndProduction)
    expenditures = {
      ...expenditures,
      results: expenditures.results.filter((expenditure) => expenditure.status === expenditureStatus),
    };

  compose(dispatch, setExpendituresAction)(expenditures);
  compose(dispatch, setExpendituresAreLoadingAction)(false);
};

export const createExpenditure = (buildingId, sectionId, expenditure, type) => async (dispatch) => {
  return apiCreateExpenditure(buildingId, sectionId, expenditure, type).then((responseData) =>
    compose(dispatch, addExpenditureAction)(responseData)
  );
};

export const changeExpenditure = (ids, changedExpenditure) => (dispatch) => {
  changeExpenditureRequest(ids, changedExpenditure)
    .then((response) => {
      compose(dispatch, changeExpenditureAction)(response.data);
      message.success("Позиция успешно изменена");
    })
    .catch(errorCatcher);
};

export const changeEstimateItemsState =
  (buildingId, { fromState, ...payload }) =>
    () => {
      if (payload.state === ESTIMATE_STATES_IDS.PRODUCTION) {
        return changeEstimateItemsStateToProductionRequest(buildingId, { ids: payload.ids, state: fromState });
      } else {
        return changeEstimateItemsStateRequest(buildingId, { ...payload, from_state: fromState });
      }
    };

export const confirmSection = (params) => async (dispatch) => {
  compose(dispatch, startLoadingSection, getSectionKey)(params.section.id, params.buildingId);
  await changeEstimateItemStatusRequest(params.buildingId, {
    ids: [params.section.id],
    status: ESTIMATE_ITEM_STATUSES.CONFIRMED,
    from_state: params.fromState,
  })
    .then(() => {
      compose(dispatch, confirmSectionAction)({ ...params.section, status: ESTIMATE_ITEM_STATUSES.CONFIRMED });
      message.success("Раздел утвержден");
    })
    .catch((e) => {
      errorCatcher(e);
    });
  compose(dispatch, stopLoadingSection, getSectionKey)(params.section.id, params.buildingId);
};

export const cancelSection = (params) => async (dispatch) => {
  compose(dispatch, startLoadingSection, getSectionKey)(params.section.id, params.buildingId);
  await changeEstimateItemStatusRequest(params.buildingId, {
    ids: [params.section.id],
    status: ESTIMATE_ITEM_STATUSES.CANCELED,
    from_state: params.fromState,
  })
    .then(() => {
      compose(dispatch, cancelSectionAction)(params.section);
      compose(dispatch, loadContracts)(params.buildingId);
      message.success("Раздел отклонен");
    })
    .catch((e) => {
      errorCatcher(e);
    });
  compose(dispatch, stopLoadingSection, getSectionKey)(params.section.id, params.buildingId);
};

export const confirmExpenditure = (params) => (dispatch) =>
  changeEstimateItemStatusRequest(params.buildingId, {
    ids: [params.expenditureId],
    status: ESTIMATE_ITEM_STATUSES.CONFIRMED,
    from_state: params.fromState,
  })
    .then(() => {
      compose(dispatch, confirmExpenditureAction)(params.expenditureId);
      message.success("Позиция утверждена");
    })
    .catch(errorCatcher);

export const cancelExpenditure = (params) => (dispatch) =>
  changeEstimateItemStatusRequest(params.buildingId, {
    ids: [params.expenditureId],
    status: ESTIMATE_ITEM_STATUSES.CANCELED,
    from_state: params.fromState,
  })
    .then(() => {
      compose(dispatch, cancelExpenditureAction)(params.expenditureId);
      message.success("Позиция отклонена");
    })
    .catch(errorCatcher);

export const deleteExpenditure = (ids) => async (dispatch) => {
  try {
    await deleteExpenditureRequest(ids);
    compose(dispatch, deleteExpenditureAction)({ expenditureId: ids.expenditure });
    message.success("Позиция успешно удалена");
  } catch (e) {
    errorCatcher(e);
  }
};

export const getExpenditureSubMaterials = (buildingId, expenditureId) => (dispatch) => {
  compose(dispatch, setExpenditureSubMaterialsLoadingAction)(true);

  return getExpenditureSubMaterialsRequest(buildingId, expenditureId)
    .then((response) => {
      compose(dispatch, setExpenditureSubMaterialsAction)(response.data);
      compose(dispatch, setExpenditureSubMaterialsLoadingAction)(false);
    })
    .catch(errorCatcher);
};

export const getExpenditureKs = (buildingId, expenditureId) => async (dispatch) => {
  const ks = await getExpenditureKsRequest(buildingId, expenditureId);
  compose(dispatch, setExpenditureKsAction)(ks);
};

export const getExpendituresBySection = (ids, filters = {}) => (dispatch) => {
  const sectionIdFilter = { section: ids.section }
  const formattedfilters = queryParamsFormatter(filters, sectionIdFilter)
  return apiLoadTypeExpenditures(ids.building, formattedfilters, ids.estimateState).then((expenditures) =>
    compose(dispatch, addExpendituresBySectionAction)({ expenditures, sectionId: ids.section })
  );
};
