import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  loadObjects,
  objectsSelector,
} from "redux/modules/common/building/projects";
import EmptyPlaceholder from "../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import { Spinner } from "../../UI/Spinner/Spinner";

import settingGear from "images/icons/navigation/settingGear.svg";
import TemplateSimple from "../../UI/templates/TemplateSimple";
import { objectsLoadingSelector } from "../../../redux/modules/common/building/objects";
import styles from "./ObjectsList.module.scss";

const ObjectsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const objects = useSelector(objectsSelector);
  const isLoadingObjects = useSelector(objectsLoadingSelector);

  useEffect(() => {
    dispatch(loadObjects(null, { limit: 10, offset: 0 }));
  }, []);

  useEffect(() => {
    if (!objects?.results?.length) return;
    if (localStorage.getItem("lastObjectId")) {
      history.push(`/objects/${localStorage.getItem("lastObjectId")}`);
    } else {
      history.push(`/objects/${objects?.results[0]?.id}`);
      localStorage.setItem("lastObjectId", objects?.results[0]?.id);
    }
  }, [objects]);

  if (objects.results && objects.results.length === 0 && !isLoadingObjects) {
    return (
      <TemplateSimple>
        <EmptyPlaceholder
          text="Нет проектов"
          img={settingGear}
          className={styles.placeholder}
        />
      </TemplateSimple>
    );
  }

  if (isLoadingObjects) {
    return (
      <TemplateSimple>
        <Spinner />
      </TemplateSimple>
    );
  }

  return null;
};

export default ObjectsList;
