import cn from "classnames";
import React, { FC } from "react";

import styles from "./TabBar.module.scss";

export interface ITab {
  id: string;
  text: string;
  className?: string;
  isHidden?: boolean;
}

export interface ITabBarNotLinks {
  tabs: ITab[];
  className?: string;
  disabled?: boolean;
  activeId: ITab["id"];
  onClick: (tabId: ITab["id"]) => void;
  isTested?: boolean;
}

const TabBarNotLinks: FC<ITabBarNotLinks> = ({ tabs, className, disabled, activeId, onClick, isTested, ...rest }) => {
  return (
    <div className={`${className || ""} ${disabled ? styles.disabled : ""} ${styles.tabBar}`} {...rest}>
      {Array.isArray(tabs) &&
        tabs
          .filter((el) => !el.isHidden)
          .map((el) => (
            <div
              className={cn(styles.tab, el.className, { [styles.active]: el.id == activeId })}
              onClick={() => onClick(el.id)}
              data-testid={isTested ? `test-${el.id}` : null}
              key={el.text}
            >
              {el.text}
            </div>
          ))}
    </div>
  );
};

export default React.memo(TabBarNotLinks);
