import getShortFullName from 'utils/getShortFullName'

export const prepareTableData = (data) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    itn: item.itn,
    iec: item.iec,
    managerName: getShortFullName(
      item.contact_manager.last_name,
      item.contact_manager.first_name,
      item.contact_manager.middle_name
    ),
    managerNumber: item.contact_manager.phone_number,
    managerEmail: item.contact_manager.email,
    managerFullName: item.contact_manager.last_name + " " +
      item.contact_manager.first_name + " " +
      item.contact_manager.middle_name
  }))
}
