import React from 'react'

export default function CustomersIcon({color = '#000', width = '24'}) {
  return (
    <svg
      width={width}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8,2C13.8,2,13.8,2,13.8,2h2.8c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,1,0.6,1.6v1.4l-0.9-0.9c-0.1-0.1-0.2-0.2-0.4-0.2
		c0,0,0,0,0,0c-0.1,0-0.3,0.1-0.4,0.2C17,4.8,16.9,5,16.9,5.1c0,0.1,0.1,0.3,0.2,0.4l1.8,1.8C19,7.4,19,7.4,19.1,7.5
		c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1l1.8-1.8c0.1-0.1,0.2-0.2,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2c0,0,0,0,0,0c-0.1,0-0.3,0.1-0.4,0.2l-0.9,0.9V4.2c0-0.9-0.3-1.7-1-2.3c-0.6-0.6-1.5-1-2.3-1h-2.8
		c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.3,0.2,0.4C13.5,2,13.7,2,13.8,2z"
        fill={color}
      />
      <path
        d="M9.7,15.8c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2s0-0.1,0-0.2c0-0.2-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.2H1.6c0.1-0.4,0.3-0.8,0.6-1.2c0.3-0.4,0.8-0.6,1.2-0.7l2.5-0.6c0.1,0,0.2-0.1,0.3-0.1
		c0.1-0.1,0.1-0.2,0.1-0.3l0.3-1.2c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.2C5.9,9.5,5.5,8.8,5.4,8c0-0.1-0.1-0.3-0.2-0.4
		C5.2,7.5,5,7.5,4.9,7.5c0,0,0,0,0,0H4.8c0,0,0,0,0,0c0,0,0,0,0,0L4.6,6.2c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0.1,0c0.1,0,0.2,0,0.2,0
		C5,6.1,5.1,6.1,5.1,6c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.3-0.1-1.1-0.1-1.4c0-0.7,0-1.5,2.1-1.5c0,0,0,0,0,0
		c0.1,0,0.2,0,0.3-0.1C8.1,2.2,8.8,2,9.4,2c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.3,0.7,0.5c0.3,0.5,0.2,0.7,0.1,0.8l-0.3,0.3
		c-0.1,0.1-0.2,0.2-0.2,0.4l-0.1,1.5c0,0.1,0,0.2,0,0.2S10.6,6,10.7,6c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l0,0l0.1-0.1
		c0,0,0,0,0,0l-0.1,0.1L11,7.4c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.8-0.4,1.5-1,2.1
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.2,0,0.3l0.3,1.1c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1l0.1,0c0,0,0.1,0,0.1,0
		c0.1,0,0.2,0,0.3-0.1s0.2-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.2l-0.1-0.5c0.6-0.6,0.9-1.4,1.1-2.2
		c0.2-0.1,0.3-0.2,0.4-0.3C11.9,8,12,7.8,12.1,7.6l0.2-1.2c0-0.2,0-0.3,0-0.5c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.2l0-0.9
		l0.2-0.2c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.4-0.7-0.7-1.1-0.9c-0.4-0.2-0.9-0.3-1.4-0.2
		c-0.8,0-1.6,0.2-2.4,0.6C4.3,1.6,4,3,4,4.1c0,0.2,0,0.7,0.1,1.1C4,5.3,3.9,5.4,3.8,5.5C3.7,5.6,3.6,5.7,3.5,5.9
		c0,0.2-0.1,0.3,0,0.5l0.2,1.2c0,0.2,0.1,0.4,0.3,0.6C4,8.3,4.2,8.5,4.4,8.5c0.2,0.8,0.5,1.5,1,2.1l-0.1,0.5l-2.1,0.5
		c-0.8,0.2-1.5,0.7-2,1.3c-0.5,0.6-0.8,1.4-0.8,2.2c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2L9.7,15.8
		C9.7,15.8,9.7,15.8,9.7,15.8z"
        fill={color}
      />
      <path
        d="M21.8,20.3c-0.5-0.6-1.2-1.1-2-1.3l-2.1-0.5L17.6,18c0.6-0.6,0.9-1.4,1.1-2.2c0.2-0.1,0.3-0.2,0.4-0.3
		c0.1-0.2,0.2-0.4,0.2-0.6l0.2-1.2c0-0.2,0-0.3,0-0.5c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.2l0-0.9l0.2-0.2
		c0.3-0.3,0.4-0.7,0.4-1.1c0-0.4-0.1-0.8-0.4-1.1C19,9,18.6,8.7,18.1,8.5c-0.4-0.2-0.9-0.3-1.4-0.2c-0.8,0-1.6,0.2-2.3,0.6
		c-2.7,0.1-3.1,1.5-3.1,2.6c0,0.2,0,0.7,0.1,1.1c-0.1,0.1-0.2,0.1-0.3,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2-0.1,0.3,0,0.5l0.2,1.2
		c0,0.2,0.1,0.4,0.3,0.6c0.1,0.2,0.3,0.3,0.5,0.3c0.2,0.8,0.5,1.5,1,2.1l-0.1,0.5L10.5,19c-0.8,0.2-1.5,0.7-2,1.3
		c-0.5,0.6-0.8,1.4-0.8,2.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2C8,23,8.2,23.1,8.3,23.1H22c0.1,0,0.1,0,0.2,0
		c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2C22.6,21.7,22.3,20.9,21.8,20.3z M13.5,19.3
		c0.1-0.1,0.1-0.2,0.1-0.3l0.3-1.2c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.6-0.5-0.9-1.3-1-2c0-0.1-0.1-0.3-0.2-0.4
		c-0.1-0.1-0.2-0.2-0.4-0.1h-0.1c0,0,0,0,0,0c0,0,0,0,0,0l-0.2-1.2c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0
		c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.3-0.1-1.1-0.1-1.4c0-0.7,0-1.5,2.1-1.5c0,0,0,0,0,0
		c0.1,0,0.2,0,0.3-0.1c0.6-0.4,1.2-0.5,1.8-0.5l0,0c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.3,0.7,0.5c0.3,0.5,0.2,0.7,0.1,0.8l-0.3,0.3
		c-0.1,0.1-0.2,0.2-0.2,0.4L17.8,13c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0l0,0
		c0,0,0,0,0,0l-0.1,1.2c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.8-0.4,1.5-1,2.1
		c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.2,0,0.3l0.3,1.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1l2.5,0.6
		c0.5,0.1,0.9,0.4,1.2,0.7c0.3,0.3,0.5,0.7,0.6,1.1H8.9c0.1-0.4,0.3-0.8,0.6-1.1c0.3-0.4,0.8-0.6,1.2-0.7l2.5-0.6
		C13.3,19.5,13.4,19.4,13.5,19.3z"
        fill={color}
      />
      <path
        d="M6.5,22c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-1-0.6-1.5v-1.4l0.9,0.9c0.2,0.2,0.6,0.2,0.8,0C6,19.2,6,19.2,6.1,19.1
		s0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2l-1.8-1.8c0-0.1-0.1-0.1-0.2-0.1l0,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
		l-0.1,0c-0.1,0-0.2,0.1-0.2,0.1l-1.8,1.8c-0.1,0.1-0.1,0.1-0.1,0.2s0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2
		c0.2,0.2,0.6,0.2,0.8,0l0.9-0.9v1.4c0,0.9,0.3,1.7,1,2.3c0.6,0.6,1.5,1,2.3,1h0h0c0.1,0,0.3-0.1,0.4-0.2C7,22.8,7,22.7,7,22.5
		c0-0.1-0.1-0.3-0.2-0.4C6.8,22,6.6,22,6.5,22z"
        fill={color}
      />
    </svg>
  )
}
