import {createSelector} from 'reselect'

import {parentStateSelector} from '../constructing'
import {moduleName as parentModuleName} from './index'

const initialState = {
  objectId: undefined
}

export const moduleName = 'index'
const actionsPrefix = 'constructing/finance'

const SET_OBJECT = `${actionsPrefix}_SET_OBJECT`

export const financeStateSelector = createSelector(
  parentStateSelector,
  (state) => state[parentModuleName]
)
export const stateSelector = createSelector(financeStateSelector, (state) => state[moduleName])
export const objectIdSelector = createSelector(stateSelector, (state) => state.objectId)

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case SET_OBJECT:
      return {
        ...state,
        objectId: payload
      }
    default:
      return state
  }
}

export const setObjectId = (id) => ({
  type: SET_OBJECT,
  payload: id
})
