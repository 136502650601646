import { compose } from "redux";
import axios from "axios";
import { message } from "antd";

import { errorCatcher } from "../../../../../../utils/errorCatcher";

import { deleteEmployeeAction } from "../actions";
import { MESSAGES } from "../constants";

const deleteEmployee = (employeeId, options) => (dispatch) => axios.delete(
  `/${options.userType}/orders/${options.orderId}/${options.approvalType}/employees/${employeeId}/`
)
  .then(() => {
    compose(dispatch, deleteEmployeeAction)(employeeId, options.approvalType);
    message.success(MESSAGES[options.approvalType].DELETED);
  })
  .catch(errorCatcher);

export { deleteEmployee };