import React, { useCallback, useMemo, useState } from "react";

import Modal from "../../atoms/Modal";
import Section from "../../atoms/Section";
import ButtonBase from "../../atoms/ButtonBase";

import styles from "./SelectSectionsModal.module.scss";

const SelectSectionsModal = ({ isOpen, onClose, sections, selectSection }) => {
  const [chosenSection, setChosenSection] = useState(null);
  const resetChosenSection = useCallback(() => setChosenSection(null), []);

  const sectionsWithSubsections = useMemo(
    () => sections.filter((section) => section.subsections.length !== 0),
    [sections]
  );

  const handleSelectSection = useCallback((selectedSection) => {
    selectSection(selectedSection);
    resetChosenSection();
    onClose();
  }, [selectSection, resetChosenSection, onClose]);

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose} title="Выберите раздел">
      <div className={styles.container}>
        {chosenSection && (
          <header className={styles.header}>
            <ButtonBase primary onClick={resetChosenSection}>Назад</ButtonBase>
            <h2 className={styles.title}>{chosenSection.name}</h2>
          </header>
        )}
        <div className={styles.scroll}>
          {!chosenSection ? sectionsWithSubsections.map((section) => (
            <Section data={section} onClick={setChosenSection} key={section.id} />
          )) : chosenSection.subsections.map((subsection) => (
            <Section data={subsection} onClick={handleSelectSection} key={subsection.id} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(SelectSectionsModal);