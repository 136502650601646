import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./TableReusable.module.scss";
import ArrowSmallUpGrey from "images/icons/ArrowSmallUpGrey";
import { stopEventPropagation } from 'utils/stopEventPropagation';

type cellPropsType = {
    children: React.ReactNode;
    className?: string;
    isCentered?: boolean;
    isNoBreak?: boolean;
    isRighted?: boolean;
    onClick?: (e: React.MouseEvent) => void;
}

function Cell({
    children,
    className,
    isCentered,
    isNoBreak, 
    isRighted,
    onClick
}: cellPropsType) {
    const isWrapped = isCentered || isNoBreak || isRighted || false
    const title = (typeof children === "string" && isNoBreak) ? children : undefined
    return (
        <div className={cn(styles.rowCell, className)} onClick={onClick}>
            {isWrapped && <div
                className={cn({
                    [styles.centered]: isCentered,
                    [styles.noBreak]: isNoBreak,
                    [styles.righted]: isRighted
                })}
                title={title}
            >
                {children}
            </div>}
            {!isWrapped && children}
        </div>
    )
}

export const TableReusableCell = React.memo(Cell);

type propsType = {
  className?: string;
  children: React.ReactNode;
  isEarCounter?: boolean;
  isExpandable?: boolean;
  isDefaultExpanded?: boolean;
  onExpand?: (e?: React.MouseEvent) => void;
  earCount?: number;
  isEarBlue?: boolean;
  isEarGreen?: boolean;
  innerContent?: React.ReactNode;
  onClick?: (e?: React.MouseEvent) => void;
  isHoverable?: boolean;
  containerClassName?: string;
  onCollapse?: () => void;
};

function TableReusableRow({
  children,
  className,
  isEarBlue,
  isEarCounter,
  isEarGreen,
  isExpandable,
  onExpand,
  earCount,
  innerContent,
  onClick,
  isHoverable = true,
  containerClassName,
  onCollapse,
  isDefaultExpanded,
}: propsType) {
  const [isOpen, setIsOpen] = React.useState(false);
  const openHandler = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsOpen((prev) => {
        if (prev) {
          onCollapse?.();
        } else {
          onExpand?.();
        }
        return !prev;
      });
    },
    [setIsOpen, onExpand, isOpen]
  );

  useEffect(() => {
    if (!isDefaultExpanded || !isExpandable || isOpen) return;
    setIsOpen(true);
    onExpand?.();
  }, [isDefaultExpanded, isExpandable]);

    return (
        <div
            className={cn(styles.row, containerClassName, {
                [styles.active]: isOpen,
                [styles.hoverable]: isHoverable
            })}
            onClick={onClick}
        >
            <div className={cn(styles.header, className)}>
                {isExpandable && <div
                    className={cn(styles.buttonOpen, {
                        [styles.blue]: isEarBlue,
                        [styles.green]: isEarGreen
                    })}
                    onClick={openHandler}
                >
                    {isEarCounter && <div className={styles.earCount}>
                        {earCount}
                    </div>}
                    <ArrowSmallUpGrey color={'#000'} rotate={String(isOpen ? 0 : 180)} />
                </div>}
                {children}
            </div>
            {isOpen && (
                <div className={styles.content} onClick={stopEventPropagation}>
                    {innerContent}
                </div>
            )}
        </div>
    )
}

export default React.memo(TableReusableRow);
