import React from "react";
import { Cell, Label, Pie, PieChart } from "recharts";
import { COLORS_INFO as COLORS } from "../constants";

export interface IPopoverChartProps {
  chartData: {
    name: string;
    value: number;
    key: any;
    percent: number;
  }[];
  dataAll: {
    name: string;
    value: number;
  };
  colors: any;
}

const PopoverChart: React.FC<IPopoverChartProps> = ({ chartData, dataAll, colors }) => {
  if(dataAll.value <= 0) dataAll.value = 0.0000000000000000001
  return (
    <PieChart width={200} height={170}>
      {chartData.length > 0 ? (
        chartData
          .sort((a, b) => b.value - a.value)
          .map((el, i) => (
            <Pie
              key={`cell-${i}`}
              innerRadius={40 - i / 3}
              outerRadius={60 + i / 3}
              data={[{ ...dataAll, value: dataAll.value - el.value }, el]}
              cx="50%"
              cy="50%"
              dataKey="value"
              animationBegin={0}
              animationDuration={600}
              startAngle={90}
              endAngle={450}
              label={false}
              labelLine={false}
            >
              {[{ ...dataAll, value: dataAll.value - el.value }, el].map((cell, index) => (
                <Cell fill={index > 0 ? colors[el.key] : i > 0 ? "transparent" : "#F0F0F0"} key={cell.name} stroke={'transparent'} strokeWidth={5}/>
              ))}
              {i === 0 ? (
                <Label
                  content={
                    <text x={"50%"} y={20} fill="black" textAnchor={"middle"} dominantBaseline="auto">
                      0
                    </text>
                  }
                />
              ) : null}
            </Pie>
          ))
      ) : null}
    </PieChart>
  );
};

export default PopoverChart;
