import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isSessionValid, pageLoadingSelector, setCurrentUser, userSelector } from "redux/modules/common/auth";
import { currentUserPermissionsLoadingSelector } from "redux/modules/common/permissions/reducer";
import { socketConnectV2 } from "redux/modules/common/chatV2/socketEventsV2";
import { getCurrentUserPermissions } from "redux/modules/common/permissions/thunks/getCurrentUserPermissions";
import {
  loadObjectsBreadcrumbs,
  objectsBreadcrumbsLoadingSelector,
  objectsBreadcrumbsSelector
} from 'redux/modules/common/building/objects'

import { OslaLoader } from "./pages/OslaLoader/OslaLoader";

import Routes from "./routes/Routes";
import { socketConnect, socketKill } from "utils/socketEvents";

import "../scss/main.scss";


const Main = () => {
  const [sessionValid, setSessionValid] = useState(false);
  const user = useSelector(userSelector);
  const pageIsLoading = useSelector(pageLoadingSelector);

  const dispatch = useDispatch();
  const userPermissionsAreLoading = useSelector(currentUserPermissionsLoadingSelector);
  const breadcrumbsProjectsAreLoading = useSelector(objectsBreadcrumbsLoadingSelector);

  useEffect(() => {
    dispatch(setCurrentUser());
    dispatch(getCurrentUserPermissions());
    dispatch(isSessionValid(() => setSessionValid(true)));
    dispatch(loadObjectsBreadcrumbs({ limit: 1000 }));
    socketConnect();
    socketConnectV2();
    return () => {
      socketKill();
    };
  }, []);

  useEffect(() => {
    socketConnect(user.key);
    return () => socketKill();
  }, [user.key]);

  return sessionValid && !userPermissionsAreLoading && !breadcrumbsProjectsAreLoading && !pageIsLoading ? (
    <Routes />
  ) : (
    <OslaLoader />
  );
}

export default Main;
