import React from "react";

import SliderModal from "components/UI/atoms/SliderModal";

import Permissions from "./components/Permissions/Permissions";
import styles from "./ManagePermissionsModal.module.scss";

const ManagePermissionsModal = ({ isOpen, onClose, permissions, checkedPermissions, onChangeChecked, canEdit }) => {
  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose}>
      <header className={styles.header}>
        <h2 className={styles.title}>Управление доступами</h2>
        <p className={styles.subtitle}>Выберите доступ в дереве доступов ниже для выбранного пользователя</p>
      </header>
      <div className={styles.body}>
        <Permissions
          permissions={permissions}
          checkedPermissions={checkedPermissions}
          onChangeChecked={onChangeChecked}
          canEdit={canEdit}
        />
      </div>
    </SliderModal>
  );
};

export default React.memo(ManagePermissionsModal);
