import React from 'react'
import styles from "./BottomControls.module.scss"
import cn from "classnames"

type propsType = {
    children: React.ReactNode,
    isExists?: boolean,
    isDoubleBtns?: boolean;
}

function BottomControls({
    children,
    isExists,
    isDoubleBtns
}: propsType) {
    if (!isExists) return null

    return (
        <>
            <div className={cn(styles.controls, { [styles.doubleBtns]: isDoubleBtns })}>
                {children}
            </div>
            <div className={styles.controlsWrapper}></div>
        </>
    )
}

export default BottomControls