import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { memoize } from "lodash";
import { useHistory, useLocation } from "react-router-dom";

import {
  cancelSection,
  changeSection,
  confirmSection,
  deleteSection,
  sectionsLoadingByKeySelector,
  getSectionKey,
  resetSectionAction,
  resetSectionsAction,
  sectionsSelector,
  sectionsWithConfirmedChildStatusSelector,
  sectionsWithNewChildStatusSelector,
} from "../../../../../../../redux/modules/common/building/sections/sections";
import { userSelector } from "../../../../../../../redux/modules/common/auth";
import { detailDataSelector } from "../../../../../../../redux/modules/common/building/object/nowObject";
import { loadContracts } from "../../../../../../../redux/modules/common/building/aggregations";

import { stringifyArgs } from "../../../../../../../utils/stringifyArgs";

import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../constants";
import EmptyMessage from "../../../EmptyMessage/EmptyMessage";

import Section from "./components/Section";
import SeparatedByChildStatusSections from "./components/SeparatedByChildStatusSections/SeparatedByChildStatusSections";
import { filterNotConfirmedSections } from "./utils/filterNotConfirmedSections";

const Sections = ({ activeEstimateStateId, sectionId, checkerItems, checkOnce }) => {
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();
  const sectionsWithNewChildStatus = useSelector(sectionsWithNewChildStatusSelector);
  const sectionsWithConfirmedChildStatus = useSelector(sectionsWithConfirmedChildStatusSelector);
  const sections = useSelector(sectionsSelector);
  const disabledSectionsByKey = useSelector(sectionsLoadingByKeySelector);

  const statusFromQueryParams = new URL(window.location.href).searchParams.get("status");
  const statusFromQueryParamsIsNew = statusFromQueryParams === ESTIMATE_ITEM_STATUSES.NEW;

  const user = useSelector(userSelector);
  const building = useSelector(detailDataSelector);

  const userIsResponsibleEmployee = user?.id === building?.responsible_estimate?.id;
  const canConfirmSections = statusFromQueryParamsIsNew && userIsResponsibleEmployee;

  const isSubsections = sectionId;
  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;
  const isProduction = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

  const handleCheckOnce = useMemo(
    () => memoize((estimateItemId) => (isChecked) => checkOnce(estimateItemId, isChecked), stringifyArgs),
    [checkOnce]
  );

  const displayingSections = useMemo(() => {
    if (isDraft || isProduction) return sections;
    if (!userIsResponsibleEmployee) return filterNotConfirmedSections(sections || []);
    if (statusFromQueryParams === ESTIMATE_ITEM_STATUSES.NEW) return sectionsWithNewChildStatus;
    if (statusFromQueryParams === ESTIMATE_ITEM_STATUSES.CONFIRMED) return sectionsWithConfirmedChildStatus;
    return sectionsWithConfirmedChildStatus;
  }, [
    statusFromQueryParams,
    isDraft,
    isProduction,
    sectionsWithNewChildStatus,
    sections,
    sectionsWithConfirmedChildStatus,
    userIsResponsibleEmployee,
  ]);

  const handleDeleteSection = useCallback(
    (sectionId) => {
      compose(dispatch, deleteSection)(building.id, sectionId, activeEstimateStateId);
    },
    [building.id, activeEstimateStateId]
  );

  const onClickBySection = useCallback(
    (sectionId, sectionStatus) => {
      if (sectionStatus) {
        history.push(`${location.pathname}/${sectionId}?status=${sectionStatus}`);
      } else {
        history.push(`${location.pathname}/${sectionId}`);
      }
      compose(dispatch, resetSectionsAction)();
    },
    [history, location.pathname]
  );

  const handleConfirmSection = useCallback(
    async (section) => {
      await compose(dispatch, confirmSection)({ buildingId: building.id, section, fromState: activeEstimateStateId });
      compose(dispatch, loadContracts)(building.id);
    },
    [activeEstimateStateId, building.id]
  );

  const handleCancelSection = useCallback(
    async (section) => {
      await compose(dispatch, cancelSection)({ buildingId: building.id, section, fromState: activeEstimateStateId });
    },
    [activeEstimateStateId, building.id]
  );

  const submitEditSectionName = useCallback(
    (changedName, sectionId) => {
      compose(dispatch, changeSection)(building.id, activeEstimateStateId, { id: sectionId, name: changedName });
    },
    [building.id, activeEstimateStateId]
  );

  const getSectionActions = useCallback(
    (section) => {
      if (!userIsResponsibleEmployee) return;
      const isLoading = disabledSectionsByKey[getSectionKey(section.id, building.id)];
      return {
        onConfirm: () => handleConfirmSection(section),
        onCancel: () => handleCancelSection(section),
        onSaveEditName: (changedName) => submitEditSectionName(changedName, section.id),
        onRemove: () => handleDeleteSection(section.id),
        isLoading,
        isDisabled: !isLoading && Object.values(disabledSectionsByKey).some((x) => x),
        canRemove: section.count_subsections === 0 && section.count_expenditures === 0,
        canConfirm: canConfirmSections,
      };
    },
    [
      userIsResponsibleEmployee,
      disabledSectionsByKey,
      building.id,
      canConfirmSections,
      handleConfirmSection,
      handleCancelSection,
      submitEditSectionName,
      handleDeleteSection,
    ]
  );

  useEffect(
    () => () => {
      compose(dispatch, resetSectionAction)();
    },
    []
  );

  const sectionsAreEmpty = sections?.length === 0;
  const sectionsByChildStatusAreEmpty =
    sectionsWithConfirmedChildStatus?.length === 0 && sectionsWithNewChildStatus?.length === 0;

  const isDisplaySeparatedSectionsByChildStatus =
    !isDraft &&
    !isProduction &&
    userIsResponsibleEmployee &&
    !isSubsections &&
    sectionsWithNewChildStatus?.length !== 0;
  const isDisplayEmptyMessage =
    !isDraft &&
    (isDisplaySeparatedSectionsByChildStatus ? sectionsByChildStatusAreEmpty : displayingSections?.length === 0);

  const isDisplayDraftEmptyMessage = isDraft && sectionsAreEmpty && isSubsections;

  return (
    <>
      {isDisplayEmptyMessage && <EmptyMessage message="Чтобы продолжить, перенесите разделы и/или позиции" />}
      {isDisplayDraftEmptyMessage && (
        <EmptyMessage message={`Чтобы продолжить, добавьте ${isSubsections ? "подраздел" : "раздел"} сметы`} />
      )}
      {isDisplaySeparatedSectionsByChildStatus ? (
        <SeparatedByChildStatusSections
          checkerItems={checkerItems}
          handleCheckOnce={handleCheckOnce}
          activeEstimateStateId={activeEstimateStateId}
          onClickBySection={onClickBySection}
          getSectionActions={getSectionActions}
        />
      ) : (
        displayingSections?.map((section) => (
          <div onClick={() => onClickBySection(section.id, statusFromQueryParams)}>
            <Section
              section={section}
              isChecked={checkerItems[section.id]}
              check={handleCheckOnce(section.id)}
              activeEstimateStateId={activeEstimateStateId}
              actions={getSectionActions(section)}
              canChangeEstimateState={!isProduction && !canConfirmSections}
              key={section.id}
            />
          </div>
        ))
      )}
    </>
  );
};

export default React.memo(Sections);
