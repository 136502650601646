import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import cn from "classnames";

import { Spinner } from '../../Spinner/Spinner';
import MaterialsTableHeader from './components/TableHeader/MaterialsTableHeader';
import MaterialsTableRow from './components/TableRow/MaterialTableRow';
import ConfirmBlock from "./components/ConfirmBlock/ConfirmBlock";
import MoreButton from './components/MoreButton/MoreButton';
import AddButton from '../../atoms/AddButton/AddButton';
import EmptyPlaceholder from '../../atoms/EmptyPlaceholder/EmptyPlaceholder';
import Expandable from '../../atoms/Expandable/Expandable';

import { useMaterialsList } from '../../../../hooks/useMaterialsList';
import { IMaterialInAddingInFact } from 'types/interfaces/Materials';

import materials from "images/icons/navigation/materials.svg";

import styles from "./AddingListInModal.module.scss";


interface IProps {
  addedMaterials: any[] | IMaterialInAddingInFact[], 
  selectedUsers: IMaterialInAddingInFact[], 
  onAccept: () => void, 
  onDecline: () => void, 
  onChangeCount: (id: number, count: number, type: "selected" | "added") => void, 
  onDelete: (id: number) => void, 
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, item: IMaterialInAddingInFact) => void, 
  selectedIds: number[], 
  addedIds: number[], 
  isDisabled: boolean,
  activeSection: number | null,
  activeWork: number | null,
}

function AddMaterialsInModal({ 
  addedMaterials, 
  selectedUsers, 
  onAccept, 
  onDecline, 
  onChangeCount, 
  onDelete, 
  onSelect, 
  selectedIds, 
  addedIds, 
  isDisabled, 
  activeSection, 
  activeWork 
}: IProps) {
  const { objectId } = useParams<{ objectId: string }>();
  const { allMaterials, isLoading, loadMoreHandler, count, filterHandler } = useMaterialsList({objectId, new_section_id: activeSection, exp_work_id: activeWork});

  const [isAdding, setIsAdding] = useState(false);

  return (
    <Expandable title={`Материалы: ${addedMaterials?.length || "-"}`} className={styles.wrapper}>
      {!!addedMaterials?.length && (
        <div className={styles.table}>
          <MaterialsTableHeader />
          {addedMaterials?.map((el, i) => (
            <MaterialsTableRow
              key={el.id}
              material={el}
              isCanDelete
              onChangeCount={e => onChangeCount(el.id, Number(e.target.value), "added")}
              onDelete={() => onDelete(el.id)}
              isCanBeChecked={false}
            />
          ))}
        </div>
      )}
      {count !== addedMaterials?.length  && (
        <>
          <div className={styles.subtitle}>Добавить {!!addedMaterials?.length ? "еще " : ""}материал</div>
          {!isAdding && (
            <AddButton 
              isDisabled={isDisabled} 
              onClick={() => setIsAdding(true)} 
            />
          )}
        </>
      )}
      {isAdding && (
        <ConfirmBlock 
          count={selectedUsers?.length} 
          onAccept={onAccept} 
          onDecline={onDecline} 
          onCommonClick={() => setIsAdding(false)} 
        />
      )}
      {isAdding && (
        <div className={cn(styles.table, styles.bordered, { [styles.minHeight]: isLoading })}>
          <MaterialsTableHeader onFiltersChange={filterHandler} className={styles.header} />
          {isLoading && <Spinner />}
          {!!(!isLoading && allMaterials?.length) && (
            allMaterials
              ?.filter((el: any) => !addedIds.includes(el.id))
              .map((item: any, i) => (
                <MaterialsTableRow
                  key={item.id}
                  material={item}
                  onChangeCount={e => onChangeCount(item.id, Number(e.target.value), "selected")}
                  onCheck={onSelect}
                  isSelected={selectedIds.includes(item.id)}
                />
            ))
          )}
          {!!(!isLoading && !allMaterials?.length) && <EmptyPlaceholder img={materials} />}
          <MoreButton 
            isLoading={isLoading} 
            allCount={count} 
            existingCount={allMaterials?.length} 
            onLoadMore={loadMoreHandler} 
          />
        </div>
      )}
    </Expandable>
  )
}

export default React.memo(AddMaterialsInModal);
