import React, { useCallback, useMemo } from "react";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { memoize } from "lodash";

import usePermission from "hooks/usePermission";

import { userSelector } from "../../../../../../../redux/modules/common/auth";
import {
  addTimesheetApprover,
  approveTimesheet,
  deleteTimesheetApprover,
  timesheetApprovalSelector,
  timesheetApproversLoadingSelector,
  timesheetApproversSelector
} from "../../../../../../../redux/modules/common/building/object/nowObject";

import Approvers, { TYPES } from "../../../../../../UI/organism/Approvers";
import ApprovalFooter from './ApprovalFooter'

import { stringifyArgs } from "../../../../../../../utils/stringifyArgs";

import { STATUSES } from "../../constants";

import { serializeTimesheetApprovers } from "../../utils/serializeTimesheetApprovers";

import styles from "./Approval.module.scss";


import { VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_APPROVERS } from "constants/permissions/manufacturingPermissions";

const Approval = ({ year, monthNumber, buildingId, employees }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const timesheetApproval = useSelector(timesheetApprovalSelector);
  const timesheetApprovers = useSelector(timesheetApproversSelector);
  const timesheetApproversAreLoading = useSelector(timesheetApproversLoadingSelector);

  const serializedTimesheetApprovers = useMemo(() => {
    if (!timesheetApproval || !timesheetApprovers) return [];
    return serializeTimesheetApprovers(timesheetApprovers, timesheetApproval.approvaltimesheetusers_set);
  }, [timesheetApprovers, timesheetApproval]);

  const approvers = useMemo(() => ({
    data: serializedTimesheetApprovers,
    areLoading: timesheetApproversAreLoading
  }), [serializedTimesheetApprovers, timesheetApproversAreLoading]);

  const deleteEmployee = useCallback((deletedEmployeeId) => compose(dispatch, deleteTimesheetApprover)(
    { buildingId, year, month: monthNumber },
    deletedEmployeeId
  ), [buildingId, year, monthNumber]);

  const userIsApproverAndNotApprove = useMemo(() => {
    if (!approvers.data) return false;
    return approvers.data.some((approver) => approver.employee.id === user.id && !approver.is_approved);
  }, [approvers.data]);

  const memoizedDeleteEmployee = useMemo(
    () => memoize((deletedEmployeeId) => () => deleteEmployee(deletedEmployeeId), stringifyArgs),
    [deleteEmployee]
  );

  const addEmployees = useCallback((addedEmployees) => addedEmployees.forEach(
    (employee) => compose(dispatch, addTimesheetApprover)({ buildingId, year, month: monthNumber }, employee.id)
  ), [buildingId, year, monthNumber]);

  const approve = useCallback(() => compose(dispatch, approveTimesheet)(
    { buildingId, year, month: monthNumber }
  ), [buildingId, year, monthNumber]);

  const haveAddApproverPermission = usePermission(VIEW_MANUFACTURING_WORKERS_TIMESHEET_ADD_APPROVERS)

  const isApproveButtonDisplayed = useMemo(() => timesheetApproval.status === STATUSES.ACTIVED && userIsApproverAndNotApprove, [timesheetApproval.status, userIsApproverAndNotApprove])

  return (
    <>
      <Approvers
        className={styles.approvers}
        type={TYPES.timesheet}
        approvedElements={approvers.data}
        handleDeleteEmployee={memoizedDeleteEmployee}
        handleAddEmployees={addEmployees}
        employees={employees}
        canAddApprovers={timesheetApproval.status === STATUSES.ACTIVED && haveAddApproverPermission}
        canDeleteApprovers={timesheetApproval.status === STATUSES.ACTIVED && haveAddApproverPermission}
      />
      <ApprovalFooter approve={approve} isApproveButtonDisplayed={isApproveButtonDisplayed}/>
    </>
  );
};

export default React.memo(Approval);
