import React, { Component } from "react";
import validator from "validator";
import { Field, Form } from "react-final-form";
import { message } from "antd";
import axios from "axios";
import cn from "classnames";

import Button from "../../../UI/atoms/ButtonBase";
import ConfirmPanel from "../components/ConfirmPanel";

import styles from "./AuthTabs.module.scss";


class PasswordRecovery extends Component {
  state = {
    sent: false,
    error: false,
  };

  submitHandler = async (formData) => {
    try {
      await axios.post("/auth/password/reset/", formData);

      this.setState({ sent: true });
    } catch (err) {
      message.error(err.response.statusText);
    }
  };

  validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Заполните поле";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Неправильный формат почты";
    }

    return errors;
  };

  render() {
    return (
      <div>
        {!this.state.sent ? (
          <Form
            onSubmit={this.submitHandler}
            validate={this.validate}
            render={({ handleSubmit, submitting }) => (
              <form className="authorization__form password-recovery" onSubmit={handleSubmit}>
                <div className="authorization__controls">
                  <Field name="email">
                    {({ input, meta }) => (
                      <div className="authorization__form-item">
                        <label className="authorization__label">
                          <span className="authorization__input-icon authorization__input-icon_mail" />
                          <input
                            placeholder="Введите Ваш E-mail"
                            {...input}
                            className={cn("authorization__input form__input", styles.input)}
                          />
                        </label>
                        {(meta.error || meta.submitError) && (meta.touched || meta.submitError) && (
                          <span className="help-text error is-visible">{meta.error || meta.submitError}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <div className="authorization__buttons">
                  <Button primary type={"submit"} className={cn("btn-main", styles.submit)} disabled={submitting}>
                    Отправить
                  </Button>
                  <Button secondary onClick={this.props.forgotPasswordClick} type={"cancel"}>
                    Отменить
                  </Button>
                </div>
              </form>
            )}
          />
        ) : (
          <ConfirmPanel 
            text="Мы отправили новый пароль на Вашу почту!"
            buttonText="Ок!"
            onClick={this.props.forgotPasswordClick}
          />
        )}
      </div>
    );
  }
}

export default PasswordRecovery;
