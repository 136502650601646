import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { saveFile } from "redux/modules/common/building/ticketsApi";
import { loadExpenditure } from "redux/modules/common/building/shedules";

import Modal from "components/UI/atoms/Modal";
import ButtonBase from "components/UI/atoms/ButtonBase";

import { VIEW_MANUFACTURING_JOURNAL_PROGRESS_ATTACH_KS23 } from "constants/permissions/manufacturingPermissions";

import { statusApprovedMaterial } from "utils/constant";
import usePermission from "hooks/usePermission";

import CheckNote from "images/icons/CheckNote";

import styles from "./KCModalForm.module.scss";

interface IKCModalForm {
  name: "KC2" | "KC3";
  data: any;
  objectId: string | number;
  type: "ks2" | "ks3";
}

const KCModalForm: React.FC<IKCModalForm> = ({
  name,
  data,
  objectId,
  type,
}) => {
  const dispatch = useDispatch();

  const haveAttachKs23Permission = usePermission(
    VIEW_MANUFACTURING_JOURNAL_PROGRESS_ATTACH_KS23
  );

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState<File | string>("");

  const checkNoteColor = useMemo(
    () =>
      statusApprovedMaterial.find((item) => item.name === data?.status[type])
        ?.color || "#888888",
    [data?.status, type]
  );

  const handleAddDocument = async (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const target = event.target as HTMLInputElement;
    const addedFile: File = (target.files as FileList)[0];

    setFile(addedFile);
  };

  const submitFile = async () => {
    const formData = new FormData();
    formData.set(`${type}_file`, file);
    const response = await saveFile(objectId, data.id, type, formData);

    response && dispatch(loadExpenditure(objectId, data.id));

    setIsOpen(false);
  };

  return (
    <div
      className={cn(styles.checkItem, {
        [styles.disabled]: !haveAttachKs23Permission,
      })}
    >
      <span onClick={() => setIsOpen(true)}>
        <CheckNote color={checkNoteColor} />
      </span>
      {name}
      <Modal
        isOpen={isOpen && haveAttachKs23Permission}
        onClose={() => setIsOpen(false)}
      >
        <div className={styles.content}>
          {typeof file === "object" ? (
            <div className={styles.fileName} title={file?.name}>
              {file?.name}
            </div>
          ) : (
            <div className={styles.fileName}></div>
          )}
          <ButtonBase secondary className={styles.inputSecondary}>
            <input
              id="fileInput"
              type={"file"}
              name={"file"}
              onChange={handleAddDocument}
              style={{ display: "none" }}
            />
            <label className={styles.addFileButton} htmlFor={"fileInput"}>
              Выбрать файл
            </label>
          </ButtonBase>
          <ButtonBase primary onClick={submitFile} disabled={!file}>
            Отправить
          </ButtonBase>
        </div>
      </Modal>
    </div>
  );
};

export default KCModalForm;
export type { IKCModalForm };
