import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "redux";
import { useDispatch } from "react-redux";

import ManufacturingProduct from "components/pages/ManufacturingProduct/ManufacturingProduct";
import Journal from "components/pages/Journal";
import Stock from "components/pages/Stock/Stock";
import Materials from "components/pages/Materials";
import { ProfilePage } from "components/UI/molecules/Header/components/Profile/ProfilePage/ProfilePage";
import usePermission from "hooks/usePermission";
import { checkUserHavePermission } from "redux/modules/common/permissions/thunks/checkUserHavePermission";

import { ROUTES } from "../../../../constants/routes";
import { VIEW_SETTINGS_SECTION } from "../../../../constants/permissions/generalPermissions";

import {
  VIEW_MANUFACTURING_BILL_ATTACH_FILES,
  VIEW_MANUFACTURING_BILL_ATTACH_PAYMENT_DOC,
  VIEW_MANUFACTURING_BILL_DELETE_FILES,
  VIEW_MANUFACTURING_BILL_EDITING,
  VIEW_MANUFACTURING_BILL_MARK_PAYMENT_AS_PAYED,
  VIEW_MANUFACTURING_BILL_SECTION,
  VIEW_MANUFACTURING_CHART,
  VIEW_MANUFACTURING_EDIT_REQUISITION_BEFORE_APPROVE,
  VIEW_MANUFACTURING_JOURNAL,
  VIEW_MANUFACTURING_MATERIALS_SECTION,
  VIEW_MANUFACTURING_ORDER_AUTOMATIC_AGREEMENT_TRANSITION,
  VIEW_MANUFACTURING_ORDER_FILES_ATTACH,
  VIEW_MANUFACTURING_ORDER_FILES_DELETE,
  VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE,
  VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH,
  VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE,
  VIEW_MANUFACTURING_ORDER_SECTION,
  VIEW_MANUFACTURING_PACKING_LIST_ACCEPT,
  VIEW_MANUFACTURING_PACKING_LIST_ATTACH_FILES,
  VIEW_MANUFACTURING_PACKING_LIST_DELETE_FILES,
  VIEW_MANUFACTURING_PACKING_LIST_SECTION,
  VIEW_MANUFACTURING_PLAN_FACT_SECTION,
  VIEW_MANUFACTURING_PROJECT,
  VIEW_MANUFACTURING_PROJECT_ADD_FILES,
  VIEW_MANUFACTURING_PROJECT_DELETE_FILES,
  VIEW_MANUFACTURING_PROJECT_PLAN,
  VIEW_MANUFACTURING_PROJECT_PLAN_ADD_WORK,
  VIEW_MANUFACTURING_PROJECT_PROGRESS,
  VIEW_MANUFACTURING_PROJECT_PROGRESS_ADD_WORK,
  VIEW_MANUFACTURING_REQUISITION_ADD_APPROVERS,
  VIEW_MANUFACTURING_REQUISITION_ADD_NEW_PROVIDER,
  VIEW_MANUFACTURING_REQUISITION_ADD_VIEWERS,
  VIEW_MANUFACTURING_REQUISITION_ASSIGN_EXECUTOR,
  VIEW_MANUFACTURING_REQUISITION_CANCEL_APPROVING,
  VIEW_MANUFACTURING_REQUISITION_COMPLETE,
  VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_ADDITIONAL,
  VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_OUT_OF_ESTIMATE,
  VIEW_MANUFACTURING_REQUISITION_CREATING_REQUISITION,
  VIEW_MANUFACTURING_REQUISITION_DELETE_APPROVERS,
  VIEW_MANUFACTURING_REQUISITION_DELETE_PRODUCT_FILES,
  VIEW_MANUFACTURING_REQUISITION_DELETE_VIEWERS,
  VIEW_MANUFACTURING_REQUISITION_HANDLING_MODE,
  VIEW_MANUFACTURING_REQUISITION_LOGS,
  VIEW_MANUFACTURING_REQUISITION_PAGE,
  VIEW_MANUFACTURING_REQUISITION_PROCESS,
  VIEW_MANUFACTURING_REQUISITION_PROCESS_ORDERS,
  VIEW_MANUFACTURING_REQUISITION_PROCESS_PAYMENTS,
  VIEW_MANUFACTURING_REQUISITION_REASSIGN_EXECUTOR,
  VIEW_MANUFACTURING_REQUISITION_SECTION,
  VIEW_MANUFACTURING_REQUISITION_TAKE_TO_WORK,
  VIEW_MANUFACTURING_SECTION,
  VIEW_MANUFACTURING_SHARE_PLAN_WORK,
  VIEW_MANUFACTURING_SHARE_PROGRESS_WORK,
  VIEW_MANUFACTURING_WORKERS,
} from "../../../../constants/permissions/manufacturingPermissions";

import { useLastObjectId } from "../../../../utils/hooks/uselastObjectId";

import RequisitionsList from "../../../pages/RequisitionsList/RequisitionsList";
import Workers from "../../../pages/Workers/Workers";
import PlanFact from "../../../pages/Constructing/Finance/PlanFact";
import Calendar from "../../../pages/Calendar";
import PaymentsList from "../../../pages/PaymentsList";
import SuppliesList from "../../../pages/SuppliesList";
import TemplatedOrder from "../../../pages/Order/TemplatedOrder";
import TemplatedRequisition from "../../../pages/Requisition/TemplatedRequisition";
import TemplatedBill from "../../../pages/Documents/Bills/Bill/TemplatedBill";
import TemplatedPackingList from "../../../pages/PackingList/TemplatedPackingList";
import Building from "../../../pages/Building/Building";

import ForbiddenPage from "../ForbiddenPage/ForbiddenPage";
import SettingsRoutes from "../SettingsRoutes/SettingsRoutes";

import Chat from "components/UI/organism/Chat/ChatV2";
import { chatV2Rooms } from "components/UI/organism/Chat/constants";
import useValidateCurrentBuildingAvailable from "./hooks/useValidateCurrentBuildingAvailable";
import ServiceAct from "components/pages/ServiceAct/ServiceAct";
import FilesStorage from "components/pages/FilesStorage/FilesStorage";

const ManufacturingRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const haveViewSettingsSectionPermission = usePermission(
    VIEW_SETTINGS_SECTION
  );

  useLastObjectId();
  useValidateCurrentBuildingAvailable(match);

  const requisitionPermissions = useMemo(
    () => ({
      viewProcessRequisition: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_PROCESS),
      viewProcessOrders: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_PROCESS_ORDERS),
      viewProcessPayments: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_PROCESS_PAYMENTS),
      viewAddOutOfEstimateProducts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_OUT_OF_ESTIMATE),
      viewAddAdditionalProducts: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_CREATING_PRODUCT_ADDITIONAL),
      viewEditRequisitionBeforeApprove: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_EDIT_REQUISITION_BEFORE_APPROVE),
      viewAssignRequisitionExecutor: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_ASSIGN_EXECUTOR),
      viewRequisitionTakeToWork: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_TAKE_TO_WORK),
      viewRequisitionReassign: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_REASSIGN_EXECUTOR),
      viewAddRequisitionApprovers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_ADD_APPROVERS),
      viewAddRequisitionViewers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_ADD_VIEWERS),
      viewDeleteRequisitionApprovers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_DELETE_APPROVERS),
      viewDeleteRequisitionViewers: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_DELETE_VIEWERS),
      viewRequisitionUnApprove: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_CANCEL_APPROVING),
      viewRequisitionLogs: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_LOGS),
      viewRequisitionInProcessingMode: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_HANDLING_MODE),
      viewDeleteRequisitionProductsFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_DELETE_PRODUCT_FILES),
      viewAddRequisitionProductsFiles: true,
      viewRequisitionProductsComments: true,
      viewAddRequisitionProductsComments: true,
      viewCreateRequisitionProductsProvider: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_ADD_NEW_PROVIDER),
      viewOrderInvoiceDifference: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE),
      viewAddOrderRequestsFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH),
      viewDeleteOrderRequestsFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE),
      viewCompleteRequisition: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_COMPLETE),
    }),
    []
  );

  const requisitionsListPermissions = useMemo(
    () => ({
      viewCreateRequisition: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_REQUISITION_CREATING_REQUISITION),
    }),
    []
  );

  const orderPermissions = useMemo(
    () => ({
      viewOrderInvoiceDifference: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_ORDER_INVOICE_DIFFERENCE),
      autoTransitionToPayment: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_ORDER_AUTOMATIC_AGREEMENT_TRANSITION),
      addFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_ORDER_FILES_ATTACH),
      deleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_ORDER_FILES_DELETE),
      viewAddOrderRequestsFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_ATTACH),
      viewDeleteOrderRequestsFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_ORDER_PRODUCT_FILE_DELETE),
    }),
    []
  );

  const billPermissions = useMemo(
    () => ({
      viewBillInEdit: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_BILL_EDITING),
      addFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_BILL_ATTACH_FILES),
      deleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_BILL_DELETE_FILES),
      viewAddBillPaymentsFile: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_BILL_ATTACH_PAYMENT_DOC),
      viewBillPaymentsMakePaid: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_BILL_MARK_PAYMENT_AS_PAYED),
    }),
    []
  );

  const packingListPermissions = useMemo(
    () => ({
      viewPackingListAccept: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_PACKING_LIST_ACCEPT),
      addFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_PACKING_LIST_ATTACH_FILES),
      deleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_PACKING_LIST_DELETE_FILES),
    }),
    []
  );

  const projectPermissions = useMemo(
    () => ({
      canViewPlan: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_PROJECT_PLAN),
      canViewProgress: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_PROJECT_PROGRESS),
      canAddPlan: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_PROJECT_PLAN_ADD_WORK),
      canAddProgress: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_PROJECT_PROGRESS_ADD_WORK),
      canSharePlan: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_SHARE_PLAN_WORK),
      canShareProgress: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_SHARE_PROGRESS_WORK),
      canAddFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_PROJECT_ADD_FILES),
      canDeleteFiles: compose(
        dispatch,
        checkUserHavePermission
      )(VIEW_MANUFACTURING_PROJECT_DELETE_FILES),
    }),
    []
  );

  const haveViewManufacturingSectionPermission = usePermission(
    VIEW_MANUFACTURING_SECTION
  );
  const haveViewChartPermission = usePermission(VIEW_MANUFACTURING_CHART);
  const haveViewProjectPermission = usePermission(VIEW_MANUFACTURING_PROJECT);
  const haveViewJournalPermission = usePermission(VIEW_MANUFACTURING_JOURNAL);
  const haveViewWorkersPermission = usePermission(VIEW_MANUFACTURING_WORKERS);
  const haveViewRequisitionPermission = usePermission(
    VIEW_MANUFACTURING_REQUISITION_SECTION
  );
  const haveViewOrderPermission = usePermission(
    VIEW_MANUFACTURING_ORDER_SECTION
  );
  const haveViewBillPermission = usePermission(VIEW_MANUFACTURING_BILL_SECTION);
  const haveViewPackingListPermission = usePermission(
    VIEW_MANUFACTURING_PACKING_LIST_SECTION
  );
  const haveViewPlanFactPermission = usePermission(
    VIEW_MANUFACTURING_PLAN_FACT_SECTION
  );
  const haveViewMaterialsPermission = usePermission(
    VIEW_MANUFACTURING_MATERIALS_SECTION
  );
  const haveViewRequisitionPagePermission = usePermission(
    VIEW_MANUFACTURING_REQUISITION_PAGE
  );

  const ableTab = useMemo(() => {
    if (haveViewProjectPermission) return `${match.url}/estimate`;
    if (haveViewChartPermission) return `${match.url}/manufacturing`;
    return `${match.url}/stocks`;
  }, [haveViewChartPermission, haveViewProjectPermission, match.url]);

  if (!haveViewManufacturingSectionPermission) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() =>
            ableTab ? <Redirect replace to={ableTab} /> : <ForbiddenPage />
          }
        />
        <Route
          exact
          path={`${match.path}/estimate`}
          render={(props) =>
            haveViewProjectPermission ? (
              <Building {...props} permissions={projectPermissions} />
            ) : (
              <ForbiddenPage />
            )
          }
        />
        <Route
          exact
          path={`${match.path}/manufacturing`}
          component={
            haveViewChartPermission ? ManufacturingProduct : ForbiddenPage
          }
        />
        <Route
          exact
          path={[`${match.path}/stocks`, `${match.path}/stocks/:tab`]}
          component={Stock}
        />
        <Route
          exact
          path={`${match.path}/events/calendar`}
          component={Calendar}
        />
        <Route
          exact
          path={`${match.path}/events/payments`}
          component={PaymentsList}
        />
        <Route
          exact
          path={`${match.path}/events/supplies`}
          component={SuppliesList}
        />
        <Route exact path={`${match.path}/profile`} component={ProfilePage} />
        <Route
          path={`${match.path}/journal`}
          component={haveViewJournalPermission ? Journal : ForbiddenPage}
        />
        <Route
          path={`${match.path}/workers`}
          component={haveViewWorkersPermission ? Workers : ForbiddenPage}
        />
        <Route
          exact
          path={`${match.path}/materials`}
          component={haveViewMaterialsPermission ? Materials : ForbiddenPage}
        />
        <Route
          path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT}
          component={haveViewPlanFactPermission ? PlanFact : ForbiddenPage}
        />
        <Route
          exact
          path={`${match.path}/requisitions/:activeTab/:requisitionId`}
          render={(props) =>
            haveViewRequisitionPermission &&
            haveViewRequisitionPagePermission ? (
              <TemplatedRequisition
                {...props}
                permissions={requisitionPermissions}
              />
            ) : (
              <ForbiddenPage />
            )
          }
        />
        <Route
          path={`${match.path}/requisitions`}
          render={(props) =>
            haveViewRequisitionPermission ? (
              <RequisitionsList
                {...props}
                permissions={requisitionsListPermissions}
              />
            ) : (
              <ForbiddenPage />
            )
          }
        />
        <Route
          exact
          path={`${match.path}/orders/:orderId`}
          render={(props) =>
            haveViewOrderPermission ? (
              <TemplatedOrder {...props} permissions={orderPermissions} />
            ) : (
              <ForbiddenPage />
            )
          }
        />
        <Route
          exact
          path={`${match.path}/documents/bills/:idBill`}
          render={(props) =>
            haveViewBillPermission ? (
              <TemplatedBill {...props} permissions={billPermissions} />
            ) : (
              <ForbiddenPage />
            )
          }
        />
        <Route
          exact
          path={`${match.path}/documents/packing-lists/:packingListId`}
          render={(props) =>
            haveViewPackingListPermission ? (
              <TemplatedPackingList
                {...props}
                permissions={packingListPermissions}
              />
            ) : (
              <ForbiddenPage />
            )
          }
        />
        <Route
          exact
          path={`${match.path}/documents/service-acts/:actId`}
          render={() =>
            haveViewPackingListPermission ? <ServiceAct /> : <ForbiddenPage />
          }
        />
        {haveViewSettingsSectionPermission && (
          <Route path={`${match.path}/settings`} component={SettingsRoutes} />
        )}
      </Switch>
      <Route exact path={`${match.path}/files/`} component={FilesStorage} />
      <Route path={`${match.path}/journal`}>
        {haveViewJournalPermission && (
          <Chat
            room={chatV2Rooms.FACT_WORK}
            building_id={match.params.objectId}
          />
        )}
      </Route>
      <Route path={`${match.path}/workers`}>
        {haveViewWorkersPermission && (
          <Chat
            room={chatV2Rooms.EMPLOYEES}
            building_id={match.params.objectId}
          />
        )}
      </Route>
      <Route path={`${match.path}/estimate`}>
        {haveViewProjectPermission && (
          <Chat
            room={chatV2Rooms.PROJECT}
            building_id={match.params.objectId}
          />
        )}
      </Route>
      <Route path={`${match.path}/manufacturing`}>
        {haveViewChartPermission && (
          <Chat
            room={chatV2Rooms.SCHEDULE}
            building_id={match.params.objectId}
          />
        )}
      </Route>
      <Route path={`${match.path}/stocks`}>
        {haveViewChartPermission && (
          <Chat room={chatV2Rooms.STOCK} building_id={match.params.objectId} />
        )}
      </Route>
    </>
  );
};

export default React.memo(ManufacturingRoutes);
