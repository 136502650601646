import { createSelector } from "reselect";

import { IJournalInitialState } from "./types";

export const stateSelector = (state: any): IJournalInitialState =>
  state.journal;

export const stockUsingMaterialsPriceListSelector = createSelector(
  stateSelector,
  (state) => state.stockUsingMaterialsPriceList
);

export const stockUsingMaterialsPriceUpdateIsSuccessSelector = createSelector(
  stateSelector,
  (state) => state.stockUsingMaterialsPriceUpdateIsSuccess
);