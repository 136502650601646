import { createSelector } from "reselect";

import { RootState } from "redux/rootReducer";

import { IFileStorageState } from "./types";

const stateSelector = (state: RootState): IFileStorageState =>
  state.fileStorage;

export const filesByObjectIdSelector = createSelector(
  stateSelector,
  (state) => state.byObjectId
);

export const filesByFolderIdSelector = createSelector(
  stateSelector,
  (state) => state.byFolderId
);

export const filesCountsSelector = createSelector(
  stateSelector,
  (state) => state.countByObjectId
);
