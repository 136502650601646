import axios from 'axios'
import '../UI/alert/Alert.scss'
import '../ProfilePage.scss'

export const profileUser = async (profile) => {

    try {
        const response = await axios.patch(`/users/current`, profile)
        if(response.status === 200) {
            const alert = document.querySelector('#alert')
            alert.classList.add('show')
            setTimeout(() => {
                alert.classList.remove('show')
            }, 3000)
        } 
        return response.data 
    } catch(error) {
        const oldPassword = document.querySelector('#oldPassword')
        oldPassword.classList.add('active')
    }
}