import { message } from "antd";
import Axios from "axios";
import { createSelector } from "reselect";

import { errorCatcher } from "../../../../utils/errorCatcher";

import {
  apiLoadProducts,
  apiLoadProductsSection,
  apiSummaryIndicators,
  loadPlanIntervals,
  loadProgressIntervals
} from "./processApi";

export const moduleName = "process";
export const LOAD_PROCESS = `${moduleName}/LOAD_PROCESS`;
export const SET_PROCESS = `${moduleName}/SET_PROCESS`;
export const LOAD_COUNT_PROCESS = `${moduleName}/LOAD_COUNT_PROCESS`;
export const SET_PROCESS_SECTIONS = `${moduleName}/SET_PROCESS_SECTIONS`;
export const SET_INTERVALS = `${moduleName}/SET_INTERVALS`;
export const ADD_INTERVAL = `${moduleName}/ADD_INTERVAL`;
export const CLEAR_INTERVALS = `${moduleName}/CLEAR_INTERVALS`;
export const SET_LOADING_INTERVALS = `${moduleName}/SET_LOADING_INTERVALS`;
export const SET_OVERVIEW = `${moduleName}/SET_OVERVIEW`;
export const ADD_SELECTED_PRODUCTS = `${moduleName}/ADD_SELECTED_PRODUCTS`;
export const DELETE_SELECTED_PRODUCTS = `${moduleName}/DELETE_SELECTED_PRODUCTS`;
export const ADD_SELECTED_SUBSECTIONS = `${moduleName}/ADD_SELECTED_SUBSECTIONS`;
export const DELETE_SELECTED_SUBSECTIONS = `${moduleName}/DELETE_SELECTED_SUBSECTIONS`;
export const ADD_SELECTED_SECTIONS = `${moduleName}/ADD_SELECTED_SECTIONS`;
export const DELETE_SELECTED_SECTIONS = `${moduleName}/DELETE_SELECTED_SECTIONS`;
export const CLEAR_SELECTEDS = `${moduleName}/CLEAR_SELECTEDS`;
export const SET_MEASURE = `${moduleName}/SET_MEASURE`

export const initialState = {
  process: null,
  count: null,
  countLoading: true,
  isLoading: true,
  loadingSave: false,
  intervals: {},
  isLoadingIntervals: false,
  productsOverView: {},
  selectedProducts: [],
  selectedSubsections: [],
  measure: 1
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_PROCESS:
      return {
        ...state,
        isLoading: true
      };
    case SET_PROCESS:
      return {
        ...state,
        process: payload,
        isLoading: false
      };
    case SET_PROCESS_SECTIONS:
      return {
        ...state,
        processSections: payload,
        isLoading: false
      };
    case LOAD_COUNT_PROCESS:
      return {
        ...state,
        count: payload,
        countLoading: false
      };
    case SET_INTERVALS:
      return {
        ...state,
        intervals: {
          ...state.intervals,
          [payload.productId]: payload.data[payload.productId],
          startAt: payload.startAt,
          endAt: payload.endAt
        }
      };
    case ADD_INTERVAL:
      return {
        ...state,
        intervals: {
          ...state.intervals,
          [payload.productId]: payload.data
        }
      };
    case CLEAR_INTERVALS: {
      return {
        ...state,
        intervals: {}
      };
    }
    case SET_LOADING_INTERVALS: {
      return {
        ...state,
        isLoadingIntervals: payload
      };
    }
    case SET_OVERVIEW: {
      return {
        ...state,
        productsOverView: {
          ...state.productsOverView,
          [payload.objectId]: payload.data
        }
      };
    }

    case ADD_SELECTED_PRODUCTS: {
      return {
        ...state,
        selectedProducts: [...state.selectedProducts, payload]
      };
    }
    case DELETE_SELECTED_PRODUCTS: {
      const tempProducts = [...state.selectedProducts];
      const index = tempProducts.indexOf(payload);
      tempProducts.splice(index, 1);
      return {
        ...state,
        selectedProducts: tempProducts
      };
    }
    case DELETE_SELECTED_SUBSECTIONS: {
      const tempSubsections = [...state.selectedSubsections];
      const index = tempSubsections.indexOf(payload);
      tempSubsections.splice(index, 1);
      return {
        ...state,
        selectedSubsections: tempSubsections
      };
    }
    case ADD_SELECTED_SUBSECTIONS: {
      return {
        ...state,
        selectedSubsections: [...state.selectedSubsections, payload]
      };
    }
    case CLEAR_SELECTEDS: {
      return {
        ...state,
        selectedSubsections: [],
        selectedProducts: []
      };
    }
    case SET_MEASURE: {
      return {
        ...state,
        measure: payload
      }
    }

    default:
      return state;
  }
};

export const stateSelector = state => state[moduleName];
export const processSelector = createSelector(stateSelector, state => state.process);
export const processSectionsSelector = createSelector(stateSelector, state => state.processSections);
export const isLoadingProcessSelector = createSelector(stateSelector, state => state.isLoading);
export const countSelector = createSelector(stateSelector, state => state.count);
export const countLoadingSelector = createSelector(stateSelector, state => state.countLoading);
export const subSectionsSelector = createSelector(stateSelector, state => state.subSections);
export const intervalsLoadingSelector = createSelector(stateSelector, state => state.isLoadingIntervals);
export const productsOverviewSelector = createSelector(stateSelector, state => state.productsOverView);
export const selectedSubsectionsSelector = createSelector(stateSelector, state => state.selectedSubsections);
export const selectedProductsSelector = createSelector(stateSelector, state => state.selectedProducts);
export const estimateMeasureSelector = createSelector(stateSelector, state => state.measure);


export const clearSelecteds = () => ({
  type: CLEAR_SELECTEDS
});

export const clearIntervals = () => ({
  type: CLEAR_INTERVALS
});

export const setLoadingIntervals = (payload) => ({
  type: SET_LOADING_INTERVALS,
  payload: payload
});

export const setProcess = (payload) => ({
  type: SET_PROCESS,
  payload: payload
});

export const loadProcess = (params) => {
  return async dispatch => {
    dispatch({
      type: LOAD_PROCESS
    });
    const data = await apiLoadProducts(params.id, params.isMaterials, params.editable);
    await dispatch(setProcess(data));
  };
};

export const loadProcessSections = (params) => {
  return async dispatch => {
    const data = await apiLoadProductsSection(params.objectId, params?.sectionId, params.editable, params.isMaterials);
    await dispatch({
      type: SET_PROCESS_SECTIONS,
      payload: data
    });
  };
};

export const loadCountProcess = (id) => {
  return async dispatch => {
    const data = await apiSummaryIndicators(id);
    await dispatch({
      type: LOAD_COUNT_PROCESS,
      payload: data
    });
  };
};

export const loadIntervals = (
  buildingId,
  subSectionId,
  type = "progress",
  startAt,
  endAt,
  all
) => async (dispatch, getState) => {
  try {
    if (getState().process.intervals.startAt !== startAt || getState().process.intervals.endAt !== endAt) {
      dispatch(clearIntervals());
    }

    let data;
    if (type === "progress") {
      data = await loadProgressIntervals(buildingId, subSectionId, startAt, endAt, all);
    } else {
      data = await loadPlanIntervals(buildingId, subSectionId, startAt, endAt, all);
    }
    if (!data) return;

    let tempData = {};
    for (let arr in data) {
      Array.isArray(data[arr]) && data[arr].forEach(el => { //data{key:[]} key = plan/fact/donned
        if (!tempData[el.expenditure_id]) {
          tempData[el.expenditure_id] = { plans: [], facts: [], donned: [], received: [], approveds: [] };
        }
        tempData[el.expenditure_id][arr].push(el);
      });
    }

    for (let productId in tempData) {
      dispatch({
        type: SET_INTERVALS,
        payload: { productId, data: tempData, startAt, endAt }
      });
    }
  } catch (e) {
    errorCatcher(e);
  } finally {
    dispatch(setLoadingIntervals(false));
  }
};

export const getProccessOverview = (objectId) => (dispatch) => {
  Axios.get(`/building/${objectId}/estimate/expenditures/?limit=500`)
    .then(resp => {
      dispatch({
        type: SET_OVERVIEW,
        payload: { objectId, data: resp.data.results }
      });
    })
    .catch(errorCatcher);
};

export const shareEstimateItems = (objectId, entity_ids) => (dispatch, getState) => {
  Axios.post(`/building/${objectId}/estimate/items/entities/`, {
    entity_ids: [entity_ids],
    estimate_item_ids: [...getState().process.selectedProducts, ...getState().process.selectedSubsections]
  }).then(resp => {
    message.success("Успешно передано");
    dispatch(clearSelecteds());
  })
    .catch(errorCatcher);
};