import React from 'react'
import { editPlanInterval } from 'redux/modules/common/building/processApi'
import { errorCatcher } from 'utils/errorCatcher'

export const useApprovePlan = (buildingId: number, expenditureId: number, intervalId: number, callback?: (id: number) => void) => {
    const approveHandler = () => {
        editPlanInterval({ buildingId, expenditureId, intervalId, data: { confirmed: true } })
            .then(() => callback && callback(intervalId))
            .catch(errorCatcher)
    }

    return approveHandler
}
