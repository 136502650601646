import { ITab } from "elements/navigation/TabBar/TabBarNotLinks";
import styles from "./index.module.scss"

export const CALENDAR_MODAL_TABS = { EVENTS_BY_TYPES: "eventsByTypes", ALL_EVENTS: "allEvents" };

export const calendarModalTabs: ITab[] = [
    {
        id: CALENDAR_MODAL_TABS.ALL_EVENTS,
        text: "Все события",
        className: styles.tab
    },  
    {
        id: CALENDAR_MODAL_TABS.EVENTS_BY_TYPES,
        text: "События по типам",
        className: styles.tab
    },
]    

export const EVENT_TYPES = [
    { name: "Поставка", id: "supply" },
    { name: "Оплата", id: "payment" },
    {
        name: "Задача",
        id: "task",
    },
];