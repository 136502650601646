import getShortFullName from 'utils/getShortFullName'

export const prepareTableData = (data) => {
  return data.map((item) => ({
    id: item.id,
    status: item.status,
    name: item.recipient_entity.name,
    itn: item.recipient_entity.itn,
    iec: item.recipient_entity.iec,
    managerName: getShortFullName(
      item.recipient_manager.last_name,
      item.recipient_manager.first_name,
      item.recipient_manager.middle_name
    ),
    managerNumber: item.recipient_manager.phone_number,
    managerEmail: item.recipient_manager.email
  }))
}

export const removeInvitesByIds = (invites, ids) => {
  const filteredInvites = invites.list.filter((invite) => !ids.includes(invite.id))

  const newInvites = {
    count: invites.count - ids.length,
    list: filteredInvites
  }
  return newInvites
}
