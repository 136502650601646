import React, { FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { FieldInputProps, FieldMetaState } from "react-final-form";

import useEscapeHandler from "utils/hooks/useEscapeHandler";

import arrowDownSelect from "images/icons/arrowDownSelect.svg";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";

import Icon from "../../Icon";

import styles from "./index.module.scss";

const INITIAL_OPTIONS: IOption[] = [];

export enum COLORS {
  BLUE = "blue",
}

export enum SIZES {
  SMALL = "small",
}

const getOptionById = (options: IOption[], id?: IOption["id"]) => options.find((option) => option.id === id);

export interface IOption {
  id: number | string;
  name: string | number;
  description?: string;
  isHidden?: boolean;
}

export interface ISelectProps {
  options: IOption[];
  value?: IOption["id"];
  color?: COLORS;
  size?: SIZES;
  labelColor?: string;
  placeholder?: string;
  onChange?: (optionId: IOption["id"]) => void;
  disabled?: boolean;
  className?: string;
  selectClassName?: string;
  label?: string;
  meta?: FieldMetaState<IOption["id"]>;
  onOpen?: () => void;
  onClose?: () => void;
  icon?: string;
  input?: FieldInputProps<IOption["id"]>;
  testId?: string;
  hideMoreThanOptions?: number;
  isLoading?: boolean;
  isScrolledToDefaultOption?: boolean;
}

const Select: FC<ISelectProps> = ({
  options = INITIAL_OPTIONS,
  value,
  color,
  size,
  labelColor,
  placeholder = "Выберите",
  onChange,
  disabled,
  className,
  selectClassName,
  label,
  meta,
  onOpen,
  onClose,
  icon = arrowDownSelect,
  input, // for react-final-form Field
  testId,
  hideMoreThanOptions,
  isLoading,
  isScrolledToDefaultOption
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const optionsBlockRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [localValueId, setLocalValueId] = useState<IOption["id"] | undefined>((input && input.value) || value);
  const [isOptionsHidden, setIsOptionsHidden] = useState<boolean>(!!hideMoreThanOptions);

  const handleOpening: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      if (disabled) return;

      setIsOpen((prevState) => !prevState);
    },
    [disabled]
  );

  const selectOption = useCallback(
    (optionId: IOption["id"]) => {
      setLocalValueId(optionId);
      if (input && input.onChange) {
        input.onChange(optionId);
        return;
      }
      if (onChange) onChange(optionId);
    },
    [onChange, input?.onChange]
  );

  const someOptionsHaveDescription = useMemo(
    () => options.some((option) => option.description !== undefined),
    [options]
  );

  const selectedOption = useMemo(() => getOptionById(options, localValueId), [options, localValueId]);

  const displayingOptions = useMemo(() => {
    if (!options) return [];
    if (hideMoreThanOptions && isOptionsHidden) return options.slice(0, hideMoreThanOptions);
    return options.filter((option) => !option.isHidden);
  }, [hideMoreThanOptions, isOptionsHidden, options]);

  const showAllOptions: MouseEventHandler = useCallback((e) => {
    e.stopPropagation();
    setIsOptionsHidden(false);
  }, []);

  useEscapeHandler(() => {
    onClose && onClose();
    setIsOpen(false);
  });

  useEffect(() => {
    setLocalValueId(input && input.value ? input.value : value);
  }, [value, input?.value]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) setIsOpen(false);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  useEffect(() => {
    setLocalValueId(value);
  }, [value]);

  useEffect(() => {
    if (isOpen) {
      if (onOpen) onOpen();
    } else {
      setIsOptionsHidden(!!hideMoreThanOptions);
      if (onClose) onClose();
    }
  }, [isOpen]);

  useEffect(() => {
    if(!isOpen || !isScrolledToDefaultOption || !selectedOption || !optionsBlockRef.current) return
    optionsBlockRef.current.scrollTop = (optionsBlockRef.current.querySelector(`div[data-optionid='${selectedOption.id}']`) as HTMLElement)?.offsetTop
  }, [isOpen, isScrolledToDefaultOption, selectedOption, optionsBlockRef.current])

  return (
    <>
      {label && <label className={styles.label}>{label}</label>}
      <div className={cn(styles.selectContainer, className)} onClick={handleOpening} ref={selectRef}>
        <div
          className={cn(
            styles.select,
            selectClassName,
            color && styles[color],
            size && styles[size],
            labelColor && styles[labelColor],
            {
              [styles.isOpen]: isOpen && !disabled,
              [styles.disabled]: disabled,
            }
          )}
        >
          <span className={cn(styles.title)} data-testid={testId}>
            {selectedOption ? selectedOption.name : placeholder}
          </span>
          {!disabled && (
            <Icon icon={icon} className={cn(styles.arrow, { [styles.arrowReverse]: isOpen && !disabled })} />
          )}
        </div>
        {isOpen && (
          <div
            className={cn(styles.optionsBlock, {
              [styles.withDescription]: someOptionsHaveDescription,
              [styles.hiddenOptions]: hideMoreThanOptions,
            })}
            ref={optionsBlockRef}
          >
            {!isLoading &&
              displayingOptions.map((option) => (
                <div
                  className={cn(styles.option, size && styles[size], { [styles.selected]: option.id === localValueId })}
                  onClick={() => selectOption(option.id)}
                  key={option.id}
                  data-optionid={option.id}
                >
                  <span className={styles.name}>{option.name}</span>
                  {option.description && <span>{option.description}</span>}
                </div>
              ))}
            {!isLoading && (!options || options.length === 0) && <div className={styles.option}>Нет данных</div>}
            {!isLoading && hideMoreThanOptions && isOptionsHidden && (
              <ButtonBase className={styles.moreButton} onClick={showAllOptions} small primary>
                Еще
              </ButtonBase>
            )}
            {isLoading && (
              <div className={styles.spinner}>
                <Spinner isSmall />
              </div>
            )}
          </div>
        )}
      </div>
      {meta?.touched && meta?.error && <div className={cn(styles.errorMessage)}>{meta.error}</div>}
    </>
  );
};

export default React.memo(Select);
