import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useCallback,
  useState,
} from "react";
import cn from "classnames";

import { ISectionPlan } from "redux/modules/common/building/processTypes";

import Checkbox from "components/UI/Checkbox";
import InputBase from "components/UI/atoms/InputBase";
import Actions, { IActionsProps } from "components/UI/atoms/Actions/Actions";
import ConfirmSelect from "components/UI/atoms/ConfirmSelect";
import Amount from "components/UI/atoms/Amount/Amount";
import AddButton from "components/UI/atoms/AddButton/AddButton";
import EstimateStateMark from "./components/EstimateStateMark/EstimateStateMark";
import Amounts, { AmountsType } from "./components/Amounts/Amounts";
import PlanSectionMark from "./components/PlanSectionMark/PlanSectionMark";
import { IInterval } from "components/pages/Manufacturing/types";

import ISection from "types/interfaces/Section";

import { stopEventPropagation } from "utils/stopEventPropagation";

import ArrowFill from "images/icons/ArrowFill";

import styles from "./EstimateSection.module.scss";

export interface IActions extends IActionsProps {
  onSaveEditName: (name: string) => void;
  canConfirm: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  disabled: boolean;
}

export interface IPlanSectionMarkProps {
  isExists: boolean;
  onHover?: () => void;
  onClick?: () => void;
}

export enum ESTIMATE_SECTION_VARIANTS {
  primary = "primary",
  secondary = "secondary",
}

export interface IEstimateSectionProps {
  isChecked?: boolean;
  onCheck: (checked: boolean) => void;
  canCheck?: boolean;
  section: ISection;
  actions: IActions;
  amounts: AmountsType;
  isDisplayAmounts?: boolean;
  isDisplayEstimateStateMark?: boolean;
  variant?: ESTIMATE_SECTION_VARIANTS;
  isDisplayAddButton?: boolean;
  onAddButtonClick?: () => void;
  sectionPlanData?: ISectionPlan | null;
}

const EstimateSection: FC<IEstimateSectionProps> = ({
  isChecked,
  onCheck,
  canCheck,
  section,
  actions,
  amounts,
  isDisplayAmounts,
  isDisplayEstimateStateMark,
  variant = ESTIMATE_SECTION_VARIANTS.primary,
  isDisplayAddButton,
  onAddButtonClick,
  sectionPlanData,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [changedName, setChangedName] = useState(section.name);

  const showEdit = useCallback(() => setIsEdit(true), []);

  const onChangeNameInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => setChangedName(e.target.value),
    []
  );

  const onSubmitEditSectionName = useCallback(() => {
    setIsEdit(false);
    if (changedName === section.name) return;
    actions.onSaveEditName(changedName);
  }, [actions, changedName, section.name]);

  const onKeyDownNameInput: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      (e) => {
        if (e.key !== "Enter") return;
        onSubmitEditSectionName();
      },
      [onSubmitEditSectionName]
    );

  const handleOnCheck = useCallback(
    () => onCheck(!isChecked),
    [onCheck, isChecked]
  );

  // @ts-ignore
  // @ts-ignore
  return (
    <div className={cn(styles.sectionRow, styles[variant])}>
      <div className={styles.titleContainer}>
        {canCheck && (
          <div onClick={stopEventPropagation}>
            <Checkbox
              checked={!!isChecked}
              onChange={handleOnCheck}
              className={styles.checkboxContainer}
              checkboxClassName={styles.checkbox}
            />
          </div>
        )}
        {isEdit ? (
          <div className={styles.inputContainer} onClick={stopEventPropagation}>
            <InputBase
              classNameInput={styles.input}
              value={changedName}
              onChange={onChangeNameInput}
              onKeyDown={onKeyDownNameInput}
              placeholder="Введите наименование раздела"
              input={undefined}
              className={undefined}
              label={undefined}
            />
          </div>
        ) : (
          <span className={styles.title} title={changedName}>
            {changedName}
          </span>
        )}
      </div>
      <div className={styles.leftSide}>
        {sectionPlanData && <PlanSectionMark {...sectionPlanData} />}
        {!sectionPlanData && isDisplayAddButton && (
          <AddButton className={styles.addBtn} onClick={onAddButtonClick} />
        )}
        {actions && (
          <div className={styles.actions} onClick={stopEventPropagation}>
            <Actions
              className={styles.actionBlock}
              canSave={isEdit}
              onSave={onSubmitEditSectionName}
              canEdit={!isEdit}
              onEdit={showEdit}
              canRemove={actions.canRemove}
              onRemove={actions.onRemove}
            />
            {actions.canConfirm && (
              <ConfirmSelect
                className={styles.confirmSelect}
                onConfirm={actions.onConfirm}
                onCancel={actions.onCancel}
                isLoading={actions.isLoading}
                disabled={actions.disabled}
              />
            )}
          </div>
        )}
        {isDisplayEstimateStateMark && section.current_state && (
          <EstimateStateMark state={section.current_state} />
        )}
        {isDisplayAmounts && <Amounts amounts={amounts} />}
        {(isDisplayAmounts || isDisplayEstimateStateMark || actions) && (
          <div className={styles.divider} />
        )}
        <div className={styles.budgetCol}>
          <Amount
            titleClassName={styles.budgetTitle}
            title="Бюджет"
            value={amounts.all}
            className={undefined}
            measure={amounts.measure}
            isBold
          />
        </div>
        <ArrowFill className={styles.arrowIcon} rotate="-90" />
      </div>
    </div>
  );
};

export default React.memo(EstimateSection);
