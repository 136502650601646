import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { authorizeUser, setCurrentUser, updateUserEntities } from "redux/modules/common/auth";

import { errorCatcher } from "../../../../utils/errorCatcher";

import styles from "./TasksLanding.module.scss";
import axios from 'axios'
import {message} from 'antd'

const TasksLanding: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Object.defineProperty(window, "handleSignIn", {
      value: async (email: string, password: string) => {
        try {
          const formData = new FormData()
          formData.append("email", email);
          formData.append("password", password);
          await dispatch(authorizeUser(formData));
          await dispatch(setCurrentUser());
          dispatch(updateUserEntities());
        } catch (e) {
          errorCatcher(e);
        }
      },
      configurable: true,
      enumerable: true,
      writable: true,
    });
    Object.defineProperty(window, "handleResetPassword", {
      value: async (email: string) => {
          try {
            const formData = new FormData()
            formData.append("email", email);
            await axios.post("/auth/password/reset/", formData).then(() => message.success("Успешно. Проверьте E-mail"));
          } catch (e) {
            errorCatcher(e);
          }
      },
      configurable: true,
      enumerable: true,
      writable: true,
    })
  });

  return <iframe src={"/task_landing/page30100779.html"} className={styles.frame} seamless />;
};
export default TasksLanding;
