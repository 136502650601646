import React from "react";
import SliderModal from "components/UI/atoms/SliderModal";

import styles from "./ProductIntervalCard.module.scss";
import { useSelectingWorkers } from "../../../../../../UI/organism/AddingListInModal/hooks/useSelectingWorkers";
import { useSelectingMaterials } from "../../../../../../UI/organism/AddingListInModal/hooks/useSelectingMaterials";
import { useProductIntervalForm } from "./useProductIntervalForm";
import ProductIntervalCardContent from "./ProductIntervalCardContent";

const ProductIntervalCard = ({ isOpen, handleClose, objectId }) => {
  const handleCloseModal = () => {
    handleClose();
    clearWorkers();
    clearMaterials();
    clearForm();
  };

  const [files, setFiles] = React.useState([]);

  const { addedUsers, clearWorkers } = useSelectingWorkers();

  const { addedMaterials, clearMaterials } = useSelectingMaterials();

  const { clearForm } = useProductIntervalForm({
    addedMaterials,
    addedUsers,
    objectId,
    clearMaterials,
    clearWorkers,
    handleClose,
    files,
  });

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={handleCloseModal} className={styles.sliderClassName}>
        <div className={styles.container}>
          <div className={styles.contentWrapper}>
            <ProductIntervalCardContent 
              handleClose={handleClose} 
              objectId={objectId} 
              isOpen={isOpen}
            />
          </div>
        </div>
      </SliderModal>
    </>
  );
};

export default ProductIntervalCard;
