import moment from "moment";
import { Dispatch } from "redux";
import { errorCatcher } from "utils/errorCatcher";
import { chatApi } from "./chatApi";
import { ADD_CHAT_ITEM, ADD_PREV_CHAT_ITEMS, SET_FALLBACK_MESSAGES, SET_CHAT_IS_LOADING, SET_CHAT_ITEMS } from "./reducers";
import { IgetChatMessagesParams } from "./types";

export const getMessages = (params: IgetChatMessagesParams) => (dispatch: Dispatch, getState: () => any) => {
    if (!getState().chatV2?.allMessages?.length) {
        dispatch({
            type: SET_CHAT_IS_LOADING,
            payload: true
        })
    }
    chatApi.getMessages(params)
        .then(({ data }) => {
            dispatch({
                type: SET_CHAT_ITEMS,
                payload: data
            })
        })
        .finally(() => dispatch({
            type: SET_CHAT_IS_LOADING,
            payload: false
        }))
}

export const getMoreMessages = (params: IgetChatMessagesParams, offset: number) => (dispatch: Dispatch, getState: () => any) => {
    dispatch({
        type: SET_CHAT_IS_LOADING,
        payload: true
    })
    chatApi.getMessages(params, { offset })
        .then(({ data }) => {
            dispatch({
                type: ADD_PREV_CHAT_ITEMS,
                payload: data
            })
        })
        .finally(() => dispatch({
            type: SET_CHAT_IS_LOADING,
            payload: false
        }))
}

export const sendMessage = (params: IgetChatMessagesParams, text: string, mentions: number[]) => (dispatch: Dispatch, getState: () => any) => {
    const fallbackState = getState().chatReducerV2?.allMessages ? [...getState().chatReducerV2?.allMessages] : []
    const generatedTempId = Math.random()
    const author = getState().auth.user
    dispatch({
        type: ADD_CHAT_ITEM,
        payload: {
            text,
            id: generatedTempId,
            author: { id: author.id, full_name: `${author.first_name} ${author.last_name}`, roles: author.roles },
            created_at: moment().toISOString()
        }
    })
    chatApi.postMessage(params, text, mentions)
        .catch((e: any) => {
            errorCatcher(e)
            dispatch({
                type: SET_FALLBACK_MESSAGES,
                payload: fallbackState
            })
        })
}