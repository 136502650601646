import ButtonBase from "components/UI/atoms/ButtonBase";
import Checkbox from "components/UI/atoms/Checkbox/Checkbox";
import InputBase from "components/UI/atoms/InputBase";
import ExpandableFileInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import NavBar from "components/UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";
import React from "react";
import ReactTextareaAutosize from "react-textarea-autosize";
import WorkSharingInfoItem from "./WorkSharingInfoItem/WorkSharingInfoItem";
import styles from "./WorkSharingModalContent.module.scss";
import sendEmailImage from "images/sendEmail.png";
import { Spinner } from "components/UI/Spinner/Spinner";
import CheckIcon from "components/UI/organism/WorkOrMaterialsModals/components/CheckIcon/CheckIcon";
import { IFactInterval } from "types/interfaces/FactInterval";
import { useReviewSharedWork } from "./useReviewSharedWork";
import declinePng from "./decline.png";
import cn from "classnames";
import { IFileWithOriginalName } from "types/interfaces/Files";

interface IProps {
  onClose: () => void;
  onCloseWholeModal: () => void;
  interval: Pick<
    IFactInterval,
    | "id"
    | "count"
    | "measure"
    | "interval_organisation"
    | "provider_feedback_msg"
  >;
  onIntervalReload: (id: number) => void;
  objectId: number | string;
  files: IFileWithOriginalName[];
  onAddFile: (file: File) => void;
  onDeleteFile: (fileId: number) => void;
}

const WorkApprovingSharedModalContent: React.FC<IProps> = ({
  onClose,
  onCloseWholeModal,
  interval,
  onIntervalReload,
  objectId,
  files,
  onAddFile,
  onDeleteFile,
}) => {
  const {
    count,
    countHandler,
    approveHandler,
    isApproving,
    isLoading,
    isDeclining,
    isSuccessfullyDeclined,
    declineHandler,
    setIsDeclining,
    commentHandler,
    comment,
  } = useReviewSharedWork(interval, onIntervalReload, objectId);

  if (isSuccessfullyDeclined)
    return (
      <>
        <NavBar text="Отклонение работы" onClick={onClose} isExist />
        <img src={declinePng} className={styles.img} />
        <div className={styles.result}>
          <div className={cn(styles.resultImg, styles.decline)}>
            {isLoading ? <Spinner className={styles.spinner} /> : <CheckIcon />}
          </div>
          <div className={styles.resultText}>
            {!isLoading && "Работа отклонена"}
          </div>
        </div>
        <BottomControls isExists isDoubleBtns>
          <ButtonBase tertiary onClick={onCloseWholeModal}>
            Закрыть
          </ButtonBase>
        </BottomControls>
      </>
    );

  if (isDeclining)
    return (
      <>
        <NavBar
          text="Отклонение работы"
          onClick={() => setIsDeclining(false)}
          isExist
        />
        <img src={declinePng} className={styles.declineImg} />
        <div className={styles.declineText}>
          Вы отклоняете работу. Введите обоснование.
        </div>
        <h2 className={styles.title}>Обоснование отклонения:</h2>
        <div className={styles.label}>*Укажие причину отклонения</div>
        <ReactTextareaAutosize
          onChange={commentHandler}
          placeholder={"Введите текст комментария с обоснованием"}
          value={comment}
          minRows={3}
          maxRows={10}
        />
        {/* <div className={styles.checkbox}>
                <Checkbox checked onCheck={() => null}>уведомить по email</Checkbox>
            </div> */}
        <ExpandableFileInput
          title="Вложения (поясняющие документы):"
          files={files}
          canExtractFiles={false}
          onRemoveFileDirectly={onDeleteFile}
          onUploadFileDirectly={onAddFile}
        />
        <BottomControls isExists isDoubleBtns>
          <ButtonBase tertiary onClick={() => setIsDeclining(false)}>
            Отменить
          </ButtonBase>
          <ButtonBase
            className={styles.dangerBtn}
            primary
            onClick={declineHandler}
          >
            Подтвердить
          </ButtonBase>
        </BottomControls>
      </>
    );

  if (isApproving) {
    return (
      <>
        <NavBar text="Принятие работы" onClick={onClose} isExist />
        <img src={sendEmailImage} className={styles.img} />
        <div className={styles.result}>
          <div className={styles.resultImg}>
            {isLoading ? <Spinner className={styles.spinner} /> : <CheckIcon />}
          </div>
          <div className={styles.resultText}>
            {!isLoading && "Работа успешно принята"}
          </div>
        </div>
        <BottomControls isExists isDoubleBtns>
          <ButtonBase tertiary onClick={onCloseWholeModal}>
            Закрыть
          </ButtonBase>
        </BottomControls>
      </>
    );
  }

  return (
    <>
      <NavBar text="Сведения о работе" onClick={onClose} isExist />
      <h2 className={styles.title}>От кого:</h2>
      <div className={styles.pair}>
        <WorkSharingInfoItem
          name="Наименование компании"
          value={interval?.interval_organisation?.name || "Не указано"}
        />
        <WorkSharingInfoItem
          name="ФИО контактного лица"
          value={
            interval?.interval_organisation?.contact_full_name || "Не указано"
          }
        />
      </div>
      <div className={styles.pair}>
        <WorkSharingInfoItem
          name="ИНН"
          value={interval?.interval_organisation?.itn || "Не указано"}
        />
        <WorkSharingInfoItem
          name="Email"
          value={interval?.interval_organisation?.email || "Не указано"}
        />
      </div>
      <h2 className={styles.title}>Что проверить:</h2>
      <WorkSharingInfoItem
        name={`Кол-во ${interval?.measure}:`}
        className={styles.pairLine}
        customInnerComponent={
          /* @ts-ignore */
          <InputBase value={count} onChange={countHandler} />
        }
      />
      <h2 className={styles.title}>Обоснование передачи:</h2>
      <div className={styles.comment}>{interval?.provider_feedback_msg}</div>
      <ExpandableFileInput /* files.length */
        title="Дополнительные вложения (документы):"
        files={files}
        canExtractFiles={true}
        canUploadFiles={false}
        canDeleteFiles={false}
      />
      <BottomControls isExists isDoubleBtns>
        <ButtonBase tertiary onClick={() => setIsDeclining(true)}>
          Отклонить
        </ButtonBase>
        <ButtonBase primary onClick={approveHandler}>
          Принять
        </ButtonBase>
      </BottomControls>
    </>
  );
};

export default WorkApprovingSharedModalContent;
