import React from "react";
import { useDispatch, useSelector } from "react-redux";

import TabBar from "../../../../../elements/navigation/TabBar/TabBarNotLinks";
import { estimateMeasureSelector, SET_MEASURE } from "../../../../../redux/modules/common/building/process";
import { measureSwitcher } from "../../../../../utils/measureSwitcher";

import MonthsYearsSlider from "../../../../UI/molecules/MonthsYearsSlider/MonthsYearsSlider";
import MeasureBtn from "../../../../UI/atoms/MeasureBtn/MeasureBtn";

import ProjectSettingsMenu from "./ProjectSettingsMenu/ProjectSettingsMenu";
import styles from "./ProjectNavbar.module.scss";

function ProjectNavbar({
  isTabs,
  onTabChange,
  activeTab,
  timeSliderDate,
  onChangeTimeSlider,
  filters,
  setFilters,
  selectingMonthsCount,
  setSelectingMonthsCount,
  permissions,
}) {
  const tabs = React.useMemo(() => {
    const res = [];
    if (permissions.canViewProgress) {
      res.push({
        text: "Прогресс",
        id: "progress",
      });
    }
    if (permissions.canViewPlan) {
      res.push({
        text: "Планирование",
        id: "plan",
      });
    }
    return res;
  }, [permissions.canViewPlan, permissions.canViewProgress]);

  const measure = useSelector(estimateMeasureSelector);
  const dispatch = useDispatch();
  const measureSwitchHandler = React.useCallback(() => {
    dispatch({
      type: SET_MEASURE,
      payload: measureSwitcher(measure),
    });
  }, [measure]);

  return (
    <div className={styles.container}>
      {isTabs && (permissions.canViewPlan || permissions.canViewProgress) && (
        <TabBar tabs={tabs} activeId={activeTab} onClick={onTabChange} />
      )}
      <MonthsYearsSlider
        date={timeSliderDate}
        onChange={onChangeTimeSlider}
        className={styles.timeslider}
        selectingMonthsCount={selectingMonthsCount}
      />
      <ProjectSettingsMenu
        filters={filters}
        setFilters={setFilters}
        selectingMonthsCount={selectingMonthsCount}
        setSelectingMonthsCount={setSelectingMonthsCount}
      />
      <div className={styles.parametr}>
        <MeasureBtn measure={measure} onMeasureChange={measureSwitchHandler} />
      </div>
    </div>
  );
}

export default React.memo(ProjectNavbar);
