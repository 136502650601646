import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import cn from "classnames";

import { hideNotification, readNotification } from 'redux/modules/common/notifications';

import formatNotificationMessage from '../FormatNotificationMessage/FormatNotificationMessage';

import { INotification } from '../../types/types';

import closeNoty from 'images/icons/closeNotyItem.svg';

import styles from './NotificationItem.module.scss';
import NotificationDeleted from '../NotificationDeleted/NotificationDeleted';


type propsType = {
  notification: INotification;
}

const NotificationItem = ({ notification }: propsType) => {
  const dispatch = useDispatch();

  const [isDeleted, setIsDeleted] = useState(false);

  const toggleNotificationHandler = useCallback((event: React.MouseEvent, value: boolean) => {
    event.stopPropagation();

    setIsDeleted(value);
    dispatch(hideNotification(notification.id, value));
  }, [dispatch, notification.id]);

  const readHandler = useCallback(() => {
    if (!notification.is_read) {
      dispatch(readNotification(notification.id));
    }
  }, [dispatch, notification.id, notification.is_read]);

  return (
    <div className={styles.noteItem} onClick={readHandler}>
      <div className={cn({ [styles.displayNone]: isDeleted })}>
        {!notification.is_read && <div className={styles.readCheck} />}
        <div className={styles.innerNoteItem}>
          <div className={styles.dateFlexAndCloseBtn}>
            <div className={styles.objectFlexAndDate}>
              <div className={styles.flexObjectText}>
                <div className={styles.objectCircle}></div>
                <div className={styles.messageInner}>{formatNotificationMessage(notification)}</div>
              </div>
              <div className={styles.dateReceived}>{moment(notification.created_at).format('DD.MM.YY')}</div>
            </div>
            <div onClick={(event) => toggleNotificationHandler(event, true)} className={styles.closeNotyBtn}>
              <img src={closeNoty} alt="иконка удаления" />
            </div>
          </div>
        </div>
      </div>

      {isDeleted && (
        <NotificationDeleted 
          handleClick={toggleNotificationHandler} 
        />
      )}
    </div>
  )
};

export default React.memo(NotificationItem);
