import React from "react"
import { useDispatch } from "react-redux"
import { tasksIsSectionsLoadingSelector, tasksSectionFilterSelector, tasksSectionsForBuildingsSelector } from "redux/modules/purchaser/Tasks/selectors"
import { getFilteredTasksBySection, getSectionsForBuilding } from "redux/modules/purchaser/Tasks/thunks"
import { tasksListFilterType } from "redux/modules/purchaser/Tasks/types"
import { useTypedSelector } from "redux/typedUseSelector"
import { TASK_LISTS_TYPES } from "../constants"

export const useFilterTasksBySection = (objectId: number, filters: tasksListFilterType, type: TASK_LISTS_TYPES) => {
    const dispatch = useDispatch()
    const sections = useTypedSelector(tasksSectionsForBuildingsSelector)
    const sectionTaskFilter = useTypedSelector(tasksSectionFilterSelector)
    const isIndicatorsloading = useTypedSelector(tasksIsSectionsLoadingSelector)

    const loadSectionsHandler = React.useCallback(() => {
        if (!objectId) return
        dispatch(getSectionsForBuilding(objectId))
    }, [objectId])

    const submitFilterBySectionHandler = React.useCallback((id: number) => {
        if (!objectId) return
        dispatch(getFilteredTasksBySection({
            ...filters,
            building: String(objectId),
            section_id: id,
            query_filter: type
        }))
    }, [objectId, filters, type])

    return {
        fields: sections[objectId] || [],
        loadSectionsHandler,
        submitFilterBySectionHandler,
        sectionTaskFilter: sectionTaskFilter[objectId] ? +sectionTaskFilter[objectId] : undefined,
        isSectionsLoading: isIndicatorsloading[objectId] || false
    }
}