import { message } from "antd";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createUser,
  getRoles,
  rolesSelector,
  setIsSuccess,
  setUser,
  userSelector,
  userSuccessSelector,
} from "redux/modules/common/userRedux";

import ButtonBase from "components/UI/atoms/ButtonBase";
import InputBase from "components/UI/atoms/InputBase";
import Select from "components/UI/atoms/Select";
import SliderModal from "components/UI/atoms/SliderModal";

import { Field, Form } from "react-final-form";

import useEscapeHandler from "utils/hooks/useEscapeHandler";
import useFormFocus from "utils/hooks/useFormFocus";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";
import { composeFieldValidators, maxLength, required } from "utils/validations";

import arowSelectSmallBlue from "images/icons/arowSelectSmallBlue.svg";
import sendEmailImage from "images/sendEmail.png";

import styles from "./UserModal.module.scss";

const UserModal = ({ isOpen, close, pushNewUser, isEditable = true }) => {
  const formRef = useRef(null);
  const inputRootRef = useFormFocus();

  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const roles = useSelector(rolesSelector);
  const isSuccess = useSelector(userSuccessSelector);

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  const rolesList = useMemo(() => {
    return (
      roles?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      }) || []
    );
  }, [roles]);

  const normalizePhone = (value) => value && value.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "$1($2)-$3-$4-$5");

  const closeModal = () => {
    dispatch(setUser(null));
    close();
  };

  useEscapeHandler(closeModal);

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      Object.keys(user).length !== 0 && pushNewUser(user);
      closeModal();
      message.success("Пользователь успешно добавлен");
    }
  }, [user]);

  useEffect(() => {
    if (!formRef.current) return;

    if (isSuccess) {
      formRef.current.dispatchEvent(new Event("reset", { cancelable: true, bubbles: true }));
      dispatch(setIsSuccess(false));
    }
  }, [dispatch, isSuccess]);

  const submitRequisit = async (value) => {
    console.log('Delete this console.log when you see it')

    const objectValues = {
      ...value,
      user_type: value.roles ? roles.filter((item) => item.id === value.roles)[0].user_type : "FUNCTIONAL_ROLE",
      roles: value.roles ? [value.roles] : [roles[0].id],
      permissions: user.permissions,
      is_blocked: user.is_blocked,
    };

    dispatch(createUser(objectValues));
  };

  return (
    <SliderModal isOpen={isOpen} closeHandler={closeModal}>
      <div className={styles.container}>
        <Form
          onSubmit={submitRequisit}
          initialValues={user}
          render={({ handleSubmit, hasValidationErrors, form }) => (
            <form ref={formRef} onSubmit={handleSubmit} onReset={() => form.restart()}>
              <div className={styles.header}>
                <div className={styles.title}> Добавить сотрудника</div>
                <div className={styles.subtitle} style={{ fontSize: "1rem" }}>
                  Введите данные нового сотрудника. На его e-mail будет выслано приглашение в OSLA. После регистрации вы
                  будете видеть его в списке сотрудников
                </div>
              </div>

              <div className={styles.topSide} ref={inputRootRef}>
                <div className={styles.rowItem}>
                  <Field
                    name="last_name"
                    label="Фамилия"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255))}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                    style={{ fontSize: "14px" }}
                    initialValue={user.last_name}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Field
                    name="first_name"
                    label="Имя"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255))}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                    style={{ fontSize: "14px" }}
                    initialValue={user.first_name}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Field
                    name="middle_name"
                    label="Отчество"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255))}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                    initialValue={user.middle_name}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Field
                    name="phone_number"
                    label="Телефон"
                    parse={normalizePhone}
                    placeholder="+7(911)111-11-11"
                    component={InputBase}
                    validate={maxLength(17)}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                    initialValue={user.phone_number}
                  />
                </div>
                <div className={styles.rowItem}>
                  <Field
                    name="email"
                    label="Почта"
                    component={InputBase}
                    validate={composeFieldValidators(required(), maxLength(255))}
                    classNameInput={styles.colorInput}
                    disabled={!isEditable}
                    initialValue={user.email}
                  />
                </div>
                {isAllModulesAllowed() && (
                  <div className={styles.rowItem}>
                    <Field
                      name="roles"
                      label="Роль"
                      component={Select}
                      validate={required()}
                      icon={arowSelectSmallBlue}
                      options={rolesList}
                      disabled={!isEditable}
                      style={{ marginTop: 0 }}
                      initialValue={user.roles}
                    />
                  </div>
                )}
              </div>
              <div className={styles.bottomSide}>
                <img src={sendEmailImage} className={styles.img} alt="email" />
              </div>
              <div className={styles.actionBlock}>
                <ButtonBase className={styles.btnSave} primary medium type="submit" disabled={hasValidationErrors}>
                  Отправить приглашение
                </ButtonBase>
              </div>
            </form>
          )}
        />
      </div>
    </SliderModal>
  );
};

export default UserModal;
