import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import TemplateBase from "components/UI/templates/TemplateBase";
import Users from "./components/Users";
import Profiles from "./components/Profile";
import ProjectSettings from "./ProjectsSettings/ProjectSettings";
import TabBarNotLinks from "../../../elements/navigation/TabBar/TabBarNotLinks";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import styles from "./Settings.module.scss";


const Settings = ({ match }) => {
  const history = useHistory();
  const matchTab = useRouteMatch(`${match.path}/:tab`);
  const tab = matchTab && matchTab.params.tab;

  const isShowTabsWithProjects = isAllModulesAllowed();

  const switchTab = (tabName) => history.push(`${match.url}/${tabName}`);

  return (
    <TemplateBase>
      <div className={styles.container}>
        <div className={styles.titleRow}>
          <TabBarNotLinks
            activeId={tab}
            onClick={switchTab}
            className={styles.tabs}
            tabs={
              isShowTabsWithProjects ? 
                [
                  { id: "profiles", text: "Профиль" },
                  { id: "users", text: "Сотрудники" },
                  { id: "projects", text: "Проекты" },
                ]
              :
                [
                  { id: "profiles", text: "Профиль" },
                  { id: "users", text: "Сотрудники" },
                ]
            }
          />
        </div>
        <Switch>
          <Route exact path={match.path}>
            <Redirect to={`${match.url}/profiles`} />
          </Route>
          <Route exact path={`${match.path}/profiles`} component={Profiles} />
          <Route exact path={`${match.path}/users`} component={Users} />
          <Route
            exact
            path={`${match.path}/projects`}
            component={ProjectSettings}
          />
        </Switch>
      </div>
    </TemplateBase>
  );
};

export default Settings;
