import React from "react";
import cn from "classnames";

import { hexToRGB } from "../../../../../../../utils/hexToRGB";

import styles from "./HandlerIndicatorItem.module.scss";

function HandlerIndicatorItem({ color = "#4FB2ED", isDropTarget, children, isActive }) {
  const inlineStyles = React.useMemo(() => {
    return { borderColor: color, background: isDropTarget ? hexToRGB(color, 0.25) : "none" };
  }, [color, isDropTarget]);

  return (
    <div className={cn(styles.container, { [styles.active]: isActive })} style={inlineStyles}>
      {children}
    </div>
  );
}

export default React.memo(HandlerIndicatorItem);
