import React, { useMemo } from "react";

import ButtonBack from "components/UI/atoms/ButtonBack";
import ButtonBase from "components/UI/atoms/ButtonBase";

import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";
import { useLocationArray } from "utils/hooks/useLocationArray";

import styles from "./EditProjectHeadline.module.scss";


function EditProjectHeadline({ name = "Создание проекта", isDirty, submit, isNew, resetForm }) {
  const fullUrlModule = useUrlModuleWithObjectId();
  const locationArray = useLocationArray();

  const linkForBack = useMemo(() => {
    if (locationArray.includes("settings")) {
      return `/${fullUrlModule}/settings/projects`;
    }

    return `/${fullUrlModule}/projects`;
  }, [fullUrlModule, locationArray]);

  return (
    <div className={styles.headline}>
      <ButtonBack link={linkForBack}/>
      <h1>{name}</h1>
      <div className={styles.btns}>
        {isDirty && !isNew && (
          <ButtonBase buttonPrimaryEmpty className={styles.cancel} onClick={resetForm}>
            Отменить
          </ButtonBase>
        )}
        {isDirty && <ButtonBase onClick={submit}>Сохранить</ButtonBase>}
      </div>
    </div>
  );
}

export default EditProjectHeadline;
