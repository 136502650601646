import { createSelector } from "reselect";

import { moduleName } from "./manufacturing";

export const stateSelector = (state) => state[moduleName];
export const treeSelector = createSelector(stateSelector, (state) => state.tree);
export const materialsTreeSelector = createSelector(stateSelector, (state) => state.materialsTree);
export const planSelector = createSelector(stateSelector, (state) => state.plan);
export const weekPlanSelector = createSelector(stateSelector, (state) => state.weekPlan);
export const materialsSelector = createSelector(stateSelector, (state) => state.materials);
export const weekMaterialsSelector = createSelector(stateSelector, (state) => state.weekMaterials);
export const expenditureSelector = createSelector(stateSelector, (state) => state.expenditure);
export const chartViewModeSelector = createSelector(stateSelector, (state) => state.chartViewMode);
export const manufacturingTabSelector = createSelector(stateSelector, (state) => state.tab);
export const projectsSelector = createSelector(stateSelector, (state) => state.projects);
export const intervalSelector = createSelector(stateSelector, (state) => state.interval);
export const materialIntervalSelector = createSelector(stateSelector, (state) => state.materialInterval);
export const modalIntervalListSelector = createSelector(stateSelector, (state) => state.modal.modalIntervalList);
export const modalIntervalSelector = createSelector(stateSelector, (state) => state.modal.modalInterval);
export const modalFilesSelector = createSelector(stateSelector, (state) => state.modal.modalFiles);
export const modalMaterialsSelector = createSelector(stateSelector, (state) => state.modal.modalMaterials);
export const modalIsLoadingSelector = createSelector(stateSelector, (state) => state.modal.isLoading);
export const projectDataSelector = createSelector(stateSelector, (state) => state.projectData);
export const materialDataSelector = createSelector(stateSelector, (state) => state.materialData);
export const projectWeekDataSelector = createSelector(stateSelector, (state) => state.projectWeekData);
export const materialWeekDataSelector = createSelector(stateSelector, (state) => state.materialWeekData);
export const projectEstimateSelector = createSelector(stateSelector, (state) => state.projectEstimate);
export const isLoadingChartDataSelector = createSelector(stateSelector, (state) => state.isLoadingChartData);
export const manufacturingMonthMarkersSelector = createSelector(stateSelector, (state) => state.monthMarkers);
export const isLoadingChartTreeSelector = createSelector(stateSelector, (state) => state.isLoadingChartTree);
export const activeBranchSelector = createSelector(stateSelector, (state) => state.activeBranch);
export const diagramFiltersSelector = createSelector(stateSelector, (state) => state.diagramFilters);

export const isEnabledDiagramLinkingSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.isEnabledLinking
);

export const isBeingDraggedDiagramIntervalSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.isBeingDragged
);

export const diagramIntervalArrowsSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.arrows
);

export const highlightedIntervalsSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.highlightedIntervals
);

export const highlightedArrowsSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.highlightedArrows
);

export const arrowHashSelector = createSelector(
  stateSelector,
  (state) => state.diagramIntervalLinks.hash
);

export const relationsFromCurrentIntervalSelector = createSelector(
  stateSelector,
  (state) => state.modal.relationsFromCurrentInterval
);

export const relationsToCurrentIntervalSelector = createSelector(
  stateSelector,
  (state) => state.modal.relationsToCurrentInterval
);

export const relationAvailableSectionsSelector = createSelector(
  stateSelector,
  (state) => state.modal.availableSections
);

export const relationAvailableIntervalsSelector = createSelector(
  stateSelector,
  (state) => state.modal.availableIntervals
);
