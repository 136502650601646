import { MATCHED_CHILD_PERMISSIONS } from "../../../../../../../constants/permissions/matchedChildPermissions";

export const matchPermissionsLabels = (allPermissions) => {
  const wrapper = (allPermissions, matchedPermissions) => {
    const permissionsByNames = {};
    allPermissions.forEach((permission) => (permissionsByNames[permission.name] = permission));

    return matchedPermissions
      .filter((permission) => permissionsByNames[permission.name])
      .map((permission) => {
      const matchedPermission = { ...permission, ...permissionsByNames[permission.name] };
      if (permission.children) matchedPermission.children = wrapper(allPermissions, permission.children);
      return matchedPermission;
    });
  };

  return wrapper(allPermissions, MATCHED_CHILD_PERMISSIONS);
};
