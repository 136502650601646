import styles from "./index.module.scss"

export const TRANSFERRED_STATUS_OPTIONS = [
  {
    name: (
      <div className={styles.statusOption}>
        <span>Принять</span>
        <svg
          width="25"
          height="26"
          viewBox="0 0 25 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.878 1.63872C10.6308 1.63892 8.4342 2.30546 6.56586 3.55405C4.69752 4.80264 3.24138 6.57721 2.38157 8.65336C1.52176 10.7295 1.29689 13.014 1.73541 15.2179C2.17393 17.4219 3.25614 19.4463 4.84518 21.0352C6.43423 22.6241 8.45874 23.7062 10.6627 24.1445C12.8667 24.5828 15.1512 24.3577 17.2273 23.4977C19.3033 22.6378 21.0778 21.1815 22.3262 19.313C23.5746 17.4446 24.241 15.2479 24.241 13.0007C24.2452 11.5074 23.9542 10.0279 23.3846 8.64745C22.815 7.26697 21.9782 6.01271 20.9222 4.9568C19.8662 3.90089 18.6118 3.06415 17.2313 2.49471C15.8508 1.92527 14.3713 1.63436 12.878 1.63872ZM11.329 17.8557L7.35197 13.8787L8.07497 13.1557L11.329 16.4097L19.229 8.50972L19.952 9.23272L11.329 17.8557Z"
            fill="#00DEC5"
            stroke="white"
            strokeWidth="1.4"
          />
        </svg>
      </div>
    ),
    id: "accepted",
  },
  {
    name: (
      <div className={styles.statusOption}>
        <span>Отклонить</span>
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.879 23.4822C10.259 23.4749 7.72281 22.5598 5.7017 20.8927C3.6806 19.2256 2.29964 16.9097 1.79399 14.339C1.28833 11.7684 1.68914 9.10194 2.92827 6.79358C4.1674 4.48523 6.16846 2.67762 8.59038 1.6785C11.0123 0.679381 13.7057 0.550472 16.212 1.31373C18.7182 2.07698 20.8825 3.68523 22.3365 5.86469C23.7904 8.04415 24.4439 10.6601 24.1861 13.2673C23.9282 15.8745 22.7746 18.3118 20.9219 20.1642C19.8672 21.2216 18.6135 22.0592 17.233 22.6288C15.8524 23.1983 14.3724 23.4884 12.879 23.4822ZM12.501 16.5002V17.5002H13.501V16.5002H12.501ZM12.501 6.50025V13.5002H13.501V6.50025H12.501Z"
            fill="#EB70B7"
            stroke="white"
            strokeWidth="1.4"
          />
        </svg>
      </div>
    ),
    id: "canceled",
  },
]