import React, { useEffect, useState, useMemo } from "react";

import { MdOutlineCancel } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import InputMask from "react-input-mask";

import { Alert } from "./UI/alert/Alert";
import CustomInput from "components/pages/Settings/components/Users/UserPage/UI/CustomInput/CustomInput";
import ButtonBase from "components/UI/atoms/ButtonBase";
import Checkbox from "components/UI/Checkbox/index";
import TemplateBase from "components/UI/templates/TemplateBase/index";
import Select from "components/UI/atoms/Select";

import { profileUser } from "./request/request";

import * as Styles from './profilePage-styling';
import "../ProfilePage/UI/alert/Alert.scss";
import "./ProfilePage.scss";


export const ProfilePage = () => {
  const buttonDispay = document.querySelector(".buttonDisplay");
  const savedProfile = JSON.parse(window.localStorage.getItem("redux"));

  const [profile, setProfile] = useState({});
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    middle_name: ""
  });

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");

  const initialRoleId = useMemo(() => savedProfile.auth.user.roles.map(({ id }) => id).join(''), [savedProfile.auth.user.roles]);

  useEffect(() => {
    setProfile(savedProfile.auth.user);
    setProfileData({
      first_name: savedProfile.auth.user.first_name,
      last_name: savedProfile.auth.user.last_name,
      middle_name: savedProfile.auth.user.middle_name
    });
  }, []);

  const submitAllNewData = async () => {
    try {
      if (!!oldPassword && !!newPassword && !!newPasswordRepeat) {
        profile.old_password = oldPassword;
        profile.new_password = newPassword;
        profile.repeat_password = newPasswordRepeat;
      }

      const updatedUser = await profileUser(profile);
      savedProfile.auth.user.first_name = updatedUser.first_name;
      savedProfile.auth.user.last_name = updatedUser.last_name;
      savedProfile.auth.user.middle_name = updatedUser.middle_name;
      savedProfile.auth.user.phone_number = updatedUser.phone_number;
      localStorage.setItem("redux", JSON.stringify(savedProfile));
    } catch (error) {
      console.log(error);
    }
  };

  //validation

  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [lastNameDirty, setLastNameDirty] = useState(false);
  const [middleNameDirty, setMiddleNameDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [numberDirty, setNumberDirty] = useState(false);
  const [newPasswordDirty, setNewPasswordDirty] = useState(false);
  const [newPasswordRepeatDirty, setNewPasswordRepeatDirty] = useState(false);

  const [checkIcon, setCheckIcon] = useState(false);
  const [checkIconName, setCheckIconName] = useState(false);
  const [checkIconMiddleName, setCheckIconMiddleName] = useState(false);
  const [checkIconNumber, setCheckIconNumer] = useState(false);
  const [checkIconEmail, setCheckIconEmail] = useState(false);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordRepeatError, setNewPasswordRepeatError] = useState("");
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (
      newPasswordRepeatError ||
      (newPassword.length != 0 && newPasswordRepeat.length === 0) ||
      firstNameError ||
      lastNameError ||
      middleNameError ||
      numberError
    ) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [newPasswordRepeat, newPassword, firstNameError, lastNameError, middleNameError, numberError]);

  useEffect(() => {
    if (newPassword.length === 0 && newPasswordRepeat.length === 0) {
      setFormValid(true);
    }
  }, [newPasswordRepeat, newPassword]);

  const firstNameHandler = (event) => {
    setProfile({ ...profile, first_name: event.target.value });
    const regex = /[\wа-я]+/gi;
    if (!event.target.value.match(regex)) {
      setFirstNameError("Фамилия не может быть пустой либо содержать некоректные символы");
      setCheckIcon(false);
    } else {
      buttonDispay.classList.add("addDisplay");
      setFirstNameError("");
      setCheckIcon(true);
    }
  };

  const lastNameHandler = (event) => {
    setProfile({ ...profile, last_name: event.target.value });
    const regex = /[\wа-я]+/gi;
    if (!event.target.value.match(regex)) {
      setLastNameError("Имя не может быть пустым либо содержать некоректные символы");
      setCheckIconName(false);
    } else {
      buttonDispay.classList.add("addDisplay");
      setLastNameError("");
      setCheckIconName(true);
    }
  };

  const middleNameHandler = (event) => {
    setProfile({ ...profile, middle_name: event.target.value });
    const regex = /[\wа-я]+/gi;
    if (!event.target.value.match(regex)) {
      setMiddleNameError("Отчество не может быть пустым либо содержать некоректные символы");
      setCheckIconMiddleName(false);
    } else {
      buttonDispay.classList.add("addDisplay");
      setMiddleNameError("");
      setCheckIconMiddleName(true);
    }
  };

  const numberHandler = (event) => {
    setProfile({ ...profile, phone_number: event.target.value });
    const regex = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
    if (!event.target.value.match(regex)) {
      setNumberError("Номер введен неккоректно");
      setCheckIconNumer(false);
    } else {
      buttonDispay.classList.add("addDisplay");
      setNumberError("");
      setCheckIconNumer(true);
    }
  };

  const emailHandler = (event) => {
    setProfile({ ...profile, email: event.target.value });
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!String(event.target.value).toLowerCase().match(regex)) {
      setEmailError("Email введен неккоректно");
      setCheckIconEmail(false);
    } else {
      buttonDispay.classList.add("addDisplay");
      setEmailError("");
      setCheckIconEmail(true);
    }
  };

  const oldPasswordHandler = (e) => {
    setOldPassword(e.target.value);
  };

  const newPasswordHandler = (event) => {
    setNewPassword(event.target.value);
    const newPassword = event.target.value;
    if (
      newPassword.length < 6 ||
      newPassword === profile.first_name ||
      newPassword === profile.last_name ||
      newPassword === profile.middle_name
    ) {
      setNewPasswordError(
        "Ваш пароль не должен совпадать с вашим именем или другой персональной информацией или быть слишком похожим на нее"
      );
    } else {
      buttonDispay.classList.add("addDisplay");
      setNewPasswordError("");
    }
  };

  const newPasswordHandlerRepeat = (event) => {
    setNewPasswordRepeat(event.target.value);
    const newPasswordRepeat = event.target.value;
    if (newPasswordRepeat != newPassword) {
      setNewPasswordRepeatError("Пароль должен совпадать с ранее введенным");
    } else {
      setNewPasswordRepeatError("");
    }
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case "first_name":
        setFirstNameDirty(true);
        break;
      case "last_name":
        setLastNameDirty(true);
        buttonDispay.classList.add("addDisplay");
        break;
      case "middle_name":
        setMiddleNameDirty(true);
        buttonDispay.classList.add("addDisplay");
        break;
      case "email":
        setEmailDirty(true);
        break;
      case "number":
        setNumberDirty(true);
        break;
      case "new_password":
        setNewPasswordDirty(true);
        break;
      case "new_password_repeat":
        setNewPasswordRepeatDirty(true);
        break;
      default: 
        break;
    }
  };

  return (
    <TemplateBase>
      <Styles.Wrapper>
        <Styles.Container className="container">
          <Alert />
          <Styles.NavigationInfo>
            <Styles.MidText $fontSize={1.375}>
              {profileData.last_name} {profileData.first_name} {profileData.middle_name}
            </Styles.MidText>
            <Styles.NavigationTitle>редактирование профиля</Styles.NavigationTitle>
          </Styles.NavigationInfo>
          <Styles.MidText>Контактные данные</Styles.MidText>
          <Styles.InputForm>
            <Styles.AdaptiveSize>
              <Styles.FlexInput>
                <Styles.Label>*Фамилия</Styles.Label>
                {lastNameDirty && lastNameError && <MdOutlineCancel className="badIcon" />}
                {checkIconName ? <AiOutlineCheckCircle className="goodIcon" /> : null}
                <CustomInput
                  onBlur={blurHandler}
                  name="last_name"
                  type="text"
                  placeholder="Введите фамилию"
                  value={profile.last_name}
                  onChange={lastNameHandler}
                />
              </Styles.FlexInput>

              <Styles.FlexInput>
                <Styles.Label>Отчество</Styles.Label>
                {middleNameDirty && middleNameError && <MdOutlineCancel className="badIcon" />}
                {checkIconMiddleName ? <AiOutlineCheckCircle className="goodIcon" /> : null}
                <CustomInput
                  onBlur={blurHandler}
                  name="middle_name"
                  type="text"
                  placeholder="Введите отчество"
                  value={profile.middle_name}
                  onChange={middleNameHandler}
                />
              </Styles.FlexInput>

              <Styles.FlexInput $mbNone>
                <Styles.Label>E-mail</Styles.Label>
                {emailDirty && emailError && <MdOutlineCancel className="badIcon" />}
                {checkIconEmail ? <AiOutlineCheckCircle className="goodIcon" /> : null}
                <CustomInput
                  readOnly={true}
                  name="email"
                  onBlur={blurHandler}
                  placeholder="Введите e-mail"
                  value={profile.email}
                  onChange={emailHandler}
                />
              </Styles.FlexInput>
            </Styles.AdaptiveSize>

            <Styles.AdaptiveSize>
              <Styles.FlexInput>
                <Styles.Label>*Имя</Styles.Label>
                {firstNameDirty && firstNameError && <MdOutlineCancel className="badIcon" />}
                {checkIcon ? <AiOutlineCheckCircle className="goodIcon" /> : null}
                <CustomInput
                  onBlur={blurHandler}
                  name="first_name"
                  type="text"
                  placeholder="Введите имя"
                  value={profile.first_name}
                  onChange={firstNameHandler}
                />
              </Styles.FlexInput>

              <Styles.FlexInput>
                <Styles.Label>Номер телефона</Styles.Label>
                {numberDirty && numberError && <MdOutlineCancel className="badIcon" />}
                {checkIconNumber ? <AiOutlineCheckCircle className="goodIcon" /> : null}
                <InputMask
                  className="cleave-input"
                  mask="+7 (999) 999-99-99"
                  name="number"
                  onBlur={blurHandler}
                  value={profile.phone_number}
                  onChange={numberHandler}
                />
              </Styles.FlexInput>

              <Styles.FlexInput $mbNone>
                <Select
                  name="roles"
                  label="Должность"
                  disabled
                  color="baseColor"
                  options={savedProfile.auth.user.roles}
                  value={Number(initialRoleId)}
                />
              </Styles.FlexInput>
            </Styles.AdaptiveSize>
          </Styles.InputForm>

          <Styles.PasswordForm>
            <Styles.MidText>Обновить пароль</Styles.MidText>
            <Styles.FlexPassword>
              <Styles.AdaptiveSize>
                <Styles.Label>Старый пароль</Styles.Label>
                <CustomInput
                  onChange={oldPasswordHandler}
                  value={oldPassword}
                  name="old_password"
                  placeholder="Введите cтарый пароль"
                  type="password"
                />
                <Styles.ErrorMessage
                  id="oldPassword"
                  className="oldPassword"
                >
                  Пароль должен совпадать с ранее введенным
                </Styles.ErrorMessage>
              </Styles.AdaptiveSize>
              <Styles.AdaptiveSize>
                <Styles.Label>Новый пароль</Styles.Label>
                <CustomInput
                  onBlur={blurHandler}
                  name="new_password"
                  value={newPassword}
                  onChange={newPasswordHandler}
                  placeholder="Введите новый пароль"
                  type="password"
                />
                {newPasswordDirty && newPasswordError && (
                  <Styles.ErrorMessage>{newPasswordError}</Styles.ErrorMessage>
                )}
              </Styles.AdaptiveSize>
            </Styles.FlexPassword>
            <Styles.FlexPassword style={{ alignItems: "center", justifyContent: "flex-end" }}>
              <Styles.AdaptiveSize>
                <Styles.Label>Подтверждение пароля</Styles.Label>
                <CustomInput
                  onBlur={blurHandler}
                  name="new_password_repeat"
                  value={newPasswordRepeat}
                  onChange={newPasswordHandlerRepeat}
                  placeholder="Повторите ввод нового пароля"
                  type="password"
                />
                {newPasswordRepeatDirty && newPasswordRepeatError && (
                  <Styles.ErrorMessage>{newPasswordRepeatError}</Styles.ErrorMessage>
                )}
              </Styles.AdaptiveSize>
            </Styles.FlexPassword>
          </Styles.PasswordForm>
          <Styles.ButtonContainer>
            <ButtonBase
              onClick={submitAllNewData}
              type="submit"
              medium
              primary
              disabled={!formValid}
              className="buttonDisplay"
            >
              Сохранить
            </ButtonBase>
          </Styles.ButtonContainer>
        </Styles.Container>
      </Styles.Wrapper>
    </TemplateBase>
  );
};
