import { sliceTextByConstraint } from "./sliceTextByConstraint";

const getShortName = (name) => {
  if (!name || typeof name !== 'string') return '';

  const firstLetter = name.slice(0, 1);
  return `${firstLetter.toUpperCase()}.`;
}

export default function getShortFullName(lastName, firstName, middleName, lastNameConstraint = 0) {
  if (typeof lastName === "object" && lastName !== null) {
    return getShortFullName(lastName?.last_name, lastName?.first_name, lastName?.middle_name);
  }

  const shortFirstName = getShortName(firstName);
  const shortMiddleName = getShortName(middleName);

  if (!lastName||!firstName) return "Имя не указано";

  return `${lastNameConstraint > 0 ? sliceTextByConstraint(lastName, lastNameConstraint) : lastName} ${shortFirstName} ${shortMiddleName}`;
}
