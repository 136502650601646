import React from 'react';


export interface IProps {
  icon?: string;
  iconAlt?: string;
  text: string;
  buttonText: string;
  onClick: () => void;
}

const ConfirmPanel = ({ icon, iconAlt, text, buttonText, onClick }: IProps) => {
  return (
    <div className="authorization__confirm-panel">
      {icon && (
        <div className="authorization__img">
          <img src={icon} alt={iconAlt} />
        </div>
      )}
      <div className="authorization__text">{text}</div>
      <div className="authorization__buttons">
        <button
          className="authorization__btn"
          onClick={onClick} 
        >
          {buttonText}
        </button>
      </div>
    </div>
  )
}

export default React.memo(ConfirmPanel);
