import cn from "classnames";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setIsAutoRegisterSuccess } from "redux/modules/common/registrations/actions";
import { isAutoRegisterSuccessSelector } from "redux/modules/common/registrations/selectors";
import { autoRegisterUser } from "redux/modules/common/registrations/thunks";
import { IAutoRegistrationValues } from "redux/modules/common/registrations/types";

import ConfirmPanel from "../components/ConfirmPanel";
import ButtonBase from "components/UI/atoms/ButtonBase";
import FieldErrorMessage from "components/UI/atoms/FieldErrorMessage/FieldErrorMessage";

import { Field, Form } from "react-final-form";
import InputMask from "react-input-mask";

import { parseInputMask } from "utils/parseInputMaskInField";
import { composeFieldValidators, email, maxLength, minLength, mustBeNumber, required } from "utils/validations";

import styles from "./AuthTabs.module.scss";

export interface IProps {
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const SignUpTasks = ({ setActiveTab }: IProps) => {
  const dispatch = useDispatch();

  const isSuccess = useSelector(isAutoRegisterSuccessSelector);

  const onSubmit = useCallback(
    (values: IAutoRegistrationValues) => {
      dispatch(autoRegisterUser(values));
    },
    [dispatch]
  );

  const backToSignInHandler = useCallback(() => {
    dispatch(setIsAutoRegisterSuccess(false));
    setActiveTab("signin");
  }, [dispatch, setActiveTab]);

  return (
    <div>
      {!isSuccess ? (
        <div className="authorization__form-container">
          <div className="authorization__form authorization__controls">
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, hasValidationErrors }) => (
                <form onSubmit={handleSubmit}>
                  <div className="authorization__form-item">
                    <Field name="first_name" validate={composeFieldValidators(required(), maxLength(20))}>
                      {({ input, meta }) => (
                        <div>
                          <label className="authorization__label" htmlFor="first_name">
                            <span className="authorization__input-icon authorization__input-icon_mail"></span>
                            <input
                              {...input}
                              id="first_name"
                              name="first_name"
                              className={cn("authorization__input form__input", styles.input)}
                              placeholder="Имя"
                            />
                          </label>
                          {(meta.error || meta.submitError) && meta.touched && (
                            <FieldErrorMessage errorMessage={meta.error || meta.submitError} />
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="authorization__form-item">
                    <Field name="last_name" validate={composeFieldValidators(required(), maxLength(20))}>
                      {({ input, meta }) => (
                        <div>
                          <label className="authorization__label" htmlFor="last_name">
                            <span className="authorization__input-icon authorization__input-icon_mail"></span>
                            <input
                              {...input}
                              id="last_name"
                              name="last_name"
                              className={cn("authorization__input form__input", styles.input)}
                              placeholder="Фамилия"
                            />
                          </label>
                          {(meta.error || meta.submitError) && meta.touched && (
                            <FieldErrorMessage errorMessage={meta.error || meta.submitError} />
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="authorization__form-item">
                    <Field name="middle_name" validate={maxLength(20)}>
                      {({ input, meta }) => (
                        <div>
                          <label className="authorization__label" htmlFor="middle_name">
                            <span className="authorization__input-icon authorization__input-icon_mail"></span>
                            <input
                              {...input}
                              id="middle_name"
                              name="middle_name"
                              className={cn("authorization__input form__input", styles.input)}
                              placeholder="Отчество"
                            />
                          </label>
                          {(meta.error || meta.submitError) && meta.touched && (
                            <FieldErrorMessage errorMessage={meta.error || meta.submitError} />
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="authorization__form-item">
                    <Field name="organization_name" validate={composeFieldValidators(required(), maxLength(255))}>
                      {({ input, meta }) => (
                        <div>
                          <label className="authorization__label" htmlFor="organization_name">
                            <span className="authorization__input-icon authorization__input-icon_mail"></span>
                            <input
                              {...input}
                              id="organization_name"
                              name="organization_name"
                              className={cn("authorization__input form__input", styles.input)}
                              placeholder="Имя организации"
                            />
                          </label>
                          {(meta.error || meta.submitError) && meta.touched && (
                            <FieldErrorMessage errorMessage={meta.error || meta.submitError} />
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="authorization__form-item">
                    <Field
                      name="itn"
                      validate={composeFieldValidators(required(), maxLength(10), mustBeNumber, minLength(10))}
                    >
                      {({ input, meta }) => (
                        <div>
                          <label className="authorization__label" htmlFor="itn">
                            <span className="authorization__input-icon authorization__input-icon_mail"></span>
                            <input
                              {...input}
                              id="itn"
                              name="itn"
                              className={cn("authorization__input form__input", styles.input)}
                              placeholder="ИНН"
                            />
                          </label>
                          {(meta.error || meta.submitError) && meta.touched && (
                            <FieldErrorMessage errorMessage={meta.error || meta.submitError} />
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="authorization__form-item">
                    <Field
                      name="phone"
                      validate={composeFieldValidators(required(), maxLength(20), mustBeNumber)}
                      parse={parseInputMask}
                      render={({ input, meta }) => (
                        <div>
                          <label className="authorization__label" htmlFor="phone">
                            <span className="authorization__input-icon authorization__input-icon_mail"></span>
                            <InputMask
                              {...input}
                              id="phone"
                              name="phone"
                              className={cn("authorization__input form__input", styles.input)}
                              mask="+7 (999) 999-99-99"
                              placeholder="Номер телефона"
                            />
                          </label>
                          {(meta.error || meta.submitError) && meta.touched && (
                            <FieldErrorMessage errorMessage={meta.error || meta.submitError} />
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="authorization__form-item">
                    <Field name="email" validate={composeFieldValidators(required(), email)}>
                      {({ input, meta }) => (
                        <div>
                          <label className="authorization__label" htmlFor="email">
                            <span className="authorization__input-icon authorization__input-icon_mail"></span>
                            <input
                              {...input}
                              id="email"
                              name="email"
                              className={cn("authorization__input form__input", styles.input)}
                              placeholder="Email"
                            />
                          </label>
                          {(meta.error || meta.submitError) && meta.touched && (
                            <FieldErrorMessage errorMessage={meta.error || meta.submitError} />
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="authorization__form-item">
                    <div className="authorization__confirm">
                      Нажимая "Отправить", я соглашаюсь с условиями пользовательского соглашения
                    </div>
                  </div>
                  <div className="authorization__buttons">
                    <ButtonBase
                      className={cn("btn btn-main", styles.submit)}
                      tertiary
                      type="submit"
                      disabled={hasValidationErrors}
                    >
                      Отправить
                    </ButtonBase>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      ) : (
        <ConfirmPanel
          text="Заявка успешно отправлена. Аккаунт будет активирован после прохождения проверки."
          buttonText="Ок!"
          onClick={backToSignInHandler}
        />
      )}
    </div>
  );
};

export default React.memo(SignUpTasks);
