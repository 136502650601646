import React from "react";
import { NavLink } from 'react-router-dom';
import cn from "classnames";

import styles from './index.module.scss';

const MenuItemModule = ({ name, link, title, icon, classname = '' }) => {

  return (
    <NavLink
      to={link}
      className={styles.link}
      activeClassName={styles.active}
    >
      <div className={cn(styles.container)}>
        <img src={icon} alt={name} className={styles.icon} />
        <span className={styles.title}>{title}</span>
      </div>
    </NavLink>
  )
}

export default MenuItemModule;
