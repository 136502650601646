import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HALF_MONTH, MONTH, WEEK } from "redux/modules/common/building/manufacturing/manufacturing";
import { chartViewModeSelector } from "redux/modules/common/building/manufacturing/selectors";
import { setChartViewMode } from "redux/modules/common/building/manufacturing/thunks";

export const useChartViewModeTabs = () => {
  const chartViewMode = useSelector(chartViewModeSelector);
  const dispatch = useDispatch();

  const setViewTab = useCallback(
    (newView: string) => {
      newView =
        chartViewMode === MONTH && newView === MONTH
          ? HALF_MONTH
          : chartViewMode === HALF_MONTH && newView === HALF_MONTH
          ? MONTH
          : newView;
      dispatch(setChartViewMode(newView));
    },
    [chartViewMode]
  );

  const chartViewModeTabs = useMemo(
    () => [
      {
        id: chartViewMode === HALF_MONTH ? HALF_MONTH : MONTH,
        text: `Дни | ${chartViewMode === HALF_MONTH ? 15 : 30}`,
      },
      {
        id: WEEK,
        text: "Недели",
      },
      // {
      //   id: YEAR,
      //   text: "Месяцы",
      // },
    ],
    [chartViewMode]
  );

  return {
    chartViewMode,
    setViewTab,
    chartViewModeTabs,
  };
};
