import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  attachFilesToBill,
  changeBillDate,
  deleteFileFromBill,
  loadBill,
  openedBillLoadingSelector,
  openedBillSelector,
  saveBillNumber,
} from "../../../../../redux/modules/common/building/documents/bills";

import useDebounce from "./../../../../../utils/hooks/useDebouncedValue";

import BackNavigationBar from "../../../../UI/atoms/BackNavigationBar/BackNavigationBar";
import Paper from "../../../../UI/templates/Paper/Paper";
import { Spinner } from "../../../../UI/Spinner/Spinner";

import BillPaymentList from "./components/BillPayment/BillPaymentList";
import BillTable from "./components/BillTable/BillTable";
import Header from "./components/Header/Header";
import styles from "./Bill.module.scss";

function Bill({ permissions, isSimplified }) {
  const { idBill } = useParams();
  const history = useHistory();
  const from = new URLSearchParams(history.location.search).get("from");

  const dispatch = useDispatch();
  const openedBill = useSelector(openedBillSelector);
  const openedBillLoading = useSelector(openedBillLoadingSelector);

  const handleAddFiles = useCallback(
    (addedFiles) => {
      dispatch(attachFilesToBill(idBill, Array.from(addedFiles)));
    },
    [idBill]
  );

  const handleDeleteFile = useCallback(
    (deletedFileId) => {
      if (!deletedFileId) return;
      dispatch(deleteFileFromBill(idBill, deletedFileId));
    },
    [idBill]
  );

  const [numberBill, setNumberBill] = React.useState();
  const debouncedNumberBill = useDebounce(numberBill, 500);

  const onChangeDate = useCallback(
    (changedDate) => {
      dispatch(changeBillDate(idBill, moment(changedDate).format("YYYY-MM-DD")));
    },
    [idBill]
  );

  React.useEffect(() => {
    setNumberBill(openedBill?.number);
  }, [openedBill]);

  React.useEffect(() => {
    if (!debouncedNumberBill || !openedBill || openedBill.number === debouncedNumberBill) return;
    dispatch(saveBillNumber(idBill, debouncedNumberBill));
  }, [debouncedNumberBill, openedBill]);

  React.useEffect(() => {
    if (!idBill) return;
    dispatch(loadBill(idBill));
  }, [idBill]);

  if (openedBillLoading) return <Spinner />;

  return (
    <div className={styles.container}>
      {!isSimplified && (
        <BackNavigationBar title="Документы" backLink={from} rightSideText={openedBill.building.name} />
      )}
      <Paper>
        <div className={styles.content}>
          <Header
            bill={openedBill}
            number={numberBill}
            onChangeNumber={setNumberBill}
            onChangeDate={onChangeDate}
            addFiles={handleAddFiles}
            deleteFile={handleDeleteFile}
            permissions={permissions}
          />
          <BillTable
            data={openedBill?.items}
            amount={openedBill?.calculation?.amount}
            delivery={openedBill?.calculation?.amount_delivery}
            vat={openedBill?.calculation?.amount_vat}
            total={openedBill?.calculation?.amount_total}
            comment={openedBill?.comment}
          />
          <BillPaymentList
            parentId={idBill}
            payments={openedBill?.payments}
            permissions={permissions}
            isSimplified={isSimplified}
          />
        </div>
      </Paper>
    </div>
  );
}

export default React.memo(Bill);
