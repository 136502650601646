import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './index.module.scss';

import CrossRoundedIcon from 'images/icons/CrossRoundedIcon';
import BottomSliderMaterials from '../../../BottomSliderMaterials';
import Chapter from '../../../Chapter';
import ModalDelete from '../../../ModalDelete';
import { deleteSubSection } from 'redux/modules/common/building/materials/specification';

const SubSection = ({ data, objectId, blockOpenExpenditure, canEdit }) => {
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const openModalDeleteChapter = (e) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };
  const closeModalAddChapter = (e) => {
    e?.stopPropagation();
    setIsAddModalOpen(false);
  };
  const removeSubSection = (id) => {
    dispatch(deleteSubSection(objectId, id));
    setIsDeleteModalOpen(false);
  };

  return (
    <div onClick={() => blockOpenExpenditure(data)}>
      <BottomSliderMaterials isOpen={isAddModalOpen} closeFunc={closeModalAddChapter}>
        <Chapter
          name={data.name}
          id={data.id}
          objId={objectId}
          label="подраздел"
          close={(e) => closeModalAddChapter(e)}
        />
      </BottomSliderMaterials>
      {isDeleteModalOpen && (
        <ModalDelete
          id={data.id}
          title={data.name}
          deleteSection={removeSubSection}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
        />
      )}
      <div className={styles.container}>
        <div className={styles.name}>{data?.name}</div>
        <div className={styles.count}>
          {/*<Line height='3rem'/>*/}
          <span>Материалы: </span>
          {data?.products_count}
        </div>
        {canEdit && <div className={styles.actions} onClick={openModalDeleteChapter}>
          <div>
            <CrossRoundedIcon />
          </div>
        </div>}
      </div>
    </div>
  );
};

export default SubSection;
