import { Spinner } from "components/UI/Spinner/Spinner";
import React, { useCallback } from "react";
import styles from "./Chat.module.scss";
import { useChat } from "./useChat";
import SendIcon from "images/icons/circle-arrow-up.svg";
import { chatRoomTypes } from "redux/modules/common/chatV2/types";
import Draggable from "react-draggable";
import MessageV2 from "./MessageV2/MessageV2";
import { useSelector } from "react-redux";
import { userSelector } from "redux/modules/common/auth";
import ButtonBase from "components/UI/atoms/ButtonBase";
import moment from "moment";
import { useChatMentions } from "./useChatMentions";
import TextareaAutosize from "react-textarea-autosize";
import SuggestedUser from "./SuggestedUser/SuggestedUser";
import { useSendChatMessage } from "./useSendChatMessage";
import { useChatPosition } from "./useChatPosition";
import { CHAT_BLOCK_BOUNDS } from "./utils";
import cn from 'classnames'

type propsType = {
  room: chatRoomTypes;
  building_id: number;
  subTitle: string;
};

function Chat({ room, building_id, subTitle }: propsType) {
  const userInfo = useSelector(userSelector);
  const {
    chatState,
    isMessagesLoading,
    messagesCount,
    messages,
    chatVisibilityHandler,
    sendMessageHandler,
    inputRef,
    chatListRef,
    loadMoreMessagesHandler,
    unreadCount,
  } = useChat({ room, building_id });
  const { suggestedUsers, inputMentionsHandler, selectUserHandler, mentions, setMentions } = useChatMentions(
    building_id,
    inputRef
  );
  const { submitMessageHandler } = useSendChatMessage(
    mentions,
    setMentions,
    inputRef,
    sendMessageHandler,
    chatState.show
  );

  const { position, handleDrag, isDrag, allowOpenChat } = useChatPosition({
    x: 0,
    y: 0,
  });

  const chatBlockClickHandler = useCallback(() => {
    if (isDrag) {
      allowOpenChat();
      return;
    }
    chatVisibilityHandler();
  }, [isDrag, chatVisibilityHandler]);

  return (
    <>
      {chatState.show && (
        <Draggable defaultPosition={{ x: 0, y: 0 }} handle={"#chatTitleHandle"}>
          <div className={styles.dragContainer}>
            <div className={styles.chatWindow}>
              <div className={styles.handle}>
                <div id={"chatTitleHandle"} className={styles.handleContent}>
                  <div className={styles.title}>Чат компании</div>
                  <div className={styles.subTitle}>{subTitle}</div>
                  <div onClick={chatVisibilityHandler} className={styles.minimizeButton} />
                </div>
              </div>
              <div ref={chatListRef} className={styles.messagesContainer}>
                {isMessagesLoading && !!messages.length && (
                  <div className={styles.loadMorePlate}>
                    <Spinner isSmall />
                  </div>
                )}
                {!isMessagesLoading && messagesCount > messages.length && (
                  <div className={styles.loadMoreButton}>
                    <ButtonBase secondary onClick={loadMoreMessagesHandler}>
                      Показать больше
                    </ButtonBase>
                  </div>
                )}
                {isMessagesLoading && !messages.length && (
                  <div className={styles.loadingPlate}>
                    <Spinner />
                  </div>
                )}
                {messages.map((el, i, arr) => (
                  <MessageV2
                    {...el}
                    key={el.id}
                    isMe={Number(el.author.id) === Number(userInfo.id)}
                    text={el.text}
                    isFirstByAuthor={i === 0 || el.author.id !== arr?.[i - 1]?.author?.id}
                    isFirstByDate={
                      i === 0 ||
                      moment(el.created_at).format("YYYY.MM.DD") !==
                        moment(arr?.[i - 1]?.created_at).format("YYYY.MM.DD")
                    }
                  />
                ))}
                {!isMessagesLoading && messages.length === 0 && (
                  <span className={styles.emptyMessages}>История сообщений пуста</span>
                )}
              </div>
              <div className={styles.controls}>
                {suggestedUsers.length > 0 && (
                  <div className={styles.usersSuggestionsContainer}>
                    <div className={styles.usersSuggestions}>
                      {suggestedUsers.map((user) => (
                        <SuggestedUser key={user.id} user={user} onSelectUser={selectUserHandler} />
                      ))}
                    </div>
                  </div>
                )}
                <TextareaAutosize
                  maxLength={510}
                  onChange={inputMentionsHandler}
                  placeholder={"Введите текст сообщения"}
                  ref={inputRef}
                  minRows={1}
                  maxRows={3}
                />
                <img onClick={submitMessageHandler} alt={"Отправить"} src={SendIcon} />
              </div>
            </div>
          </div>
        </Draggable>
      )}
      <Draggable defaultPosition={{ x: 0, y: 0 }} position={position} onStop={handleDrag} bounds={CHAT_BLOCK_BOUNDS}>
        <div onClick={chatBlockClickHandler} className={cn(styles.chatContainer, styles.openChatBlock)}>
          <div className={styles.hiddenChat}>
            {
              <div className={styles.tabsCounter}>
                <div className={styles.tabsCounterCircle}>{messagesCount}</div>
              </div>
            }
          </div>
          <div className={styles.openChatText}>{chatState.show ? "Закрыть чат" : "Чат компании"}</div>
        </div>
      </Draggable>
    </>
  );
}

export default Chat;
