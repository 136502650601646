import axios from "axios";
import { createSelector } from "reselect";
import { message } from "antd";

import { messageErrorHandler } from "utils/errorHandler";

import { suppliersStateSelector } from "../suppliers";
import {
  filterNotConfirmedTerms
} from "../../../../../../../components/pages/Constructing/Counterparties/utils/filterNotConfirmedTerms";

const initialState = {
  isLoading: true,
  supplier: null,
  form: {
    data: null
  },
  deleteModalOpen: false
};

const moduleName = "item";
const actionsPrefix = "constructing/counterparties/suppliers/item";

const SET_LOADING = `${actionsPrefix}_SET_LOADING`;
const SET_SUPPLIER = `${actionsPrefix}_SET_SUPPLIER`;
const SET_FORM_INIT_DATA = `${actionsPrefix}_SET_FORM_INIT_DATA`;
const SET_FORM_DATA = `${actionsPrefix}_SET_FORM_DATA`;
const SET_DELETE_MODAL_STATE = `${actionsPrefix}_SET_DELETE_MODAL_STATE`;
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`;

export const stateSelector = createSelector(suppliersStateSelector, (state) => state[moduleName]);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const supplierSelector = createSelector(stateSelector, (state) => state.supplier);
export const formDataSelector = createSelector(stateSelector, (state) => state.form.data);
export const deleteModalOpenSelector = createSelector(
  stateSelector,
  (state) => state.deleteModalOpen
);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SUPPLIER:
      return {
        ...state,
        supplier: payload
      };
    case SET_FORM_INIT_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          data: payload
        }
      };
    case SET_FORM_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            [payload.field]: payload.value
          }
        }
      };
    case SET_DELETE_MODAL_STATE:
      return {
        ...state,
        deleteModalOpen: payload
      };
    case CLEAR_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data
});

export const setSupplier = (data) => ({
  type: SET_SUPPLIER,
  payload: data
});

export const setFormInitData = (data) => ({
  type: SET_FORM_INIT_DATA,
  payload: data
});

export const setFormData = (data) => ({
  type: SET_FORM_DATA,
  payload: data
});

export const loadSupplier = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await axios.get(`/providers/${id}/`);
      dispatch(setSupplier(response.data));
      dispatch(setFormInitData(response.data));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const setDeleteModalState = (data) => ({
  type: SET_DELETE_MODAL_STATE,
  payload: data
});

export const updateSupplier = () => {
  return async (dispatch, getState) => {
    const supplierFormData = formDataSelector(getState());
    const preparedSupplierFormData = {
      ...supplierFormData,
      delivery_terms: filterNotConfirmedTerms(supplierFormData.delivery_terms)
    };

    try {
      dispatch(setLoading(true));
      const response = await axios.put(`/providers/${supplierFormData.id}/`, preparedSupplierFormData);
      dispatch(setSupplier(response.data));
      dispatch(setFormInitData(response.data));
      message.success("Успешно сохранено");
    } catch (e) {
      messageErrorHandler(e.response.data.errors, true);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const changeManager = (managerId) => {
  return async (_, getState) => {
    const supplierFormData = formDataSelector(getState());
    try {
      await axios.post(`/providers/${supplierFormData.id}/change_manager/`, {
        manager_id: managerId
      });
      message.success("Успешно сохранено");
    } catch (e) {
      messageErrorHandler(e.response.data.errors, true);
    }
  };
};

export const deleteSupplier = () => {
  return async (_, getState) => {
    const supplier = supplierSelector(getState());
    try {
      await axios.delete(`/providers/${supplier.id}/`);
    } catch (e) {
      console.error(e);
    }
  };
};

export const clearData = () => ({
  type: CLEAR_DATA
});
