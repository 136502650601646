import React from "react";
import { useParams } from "react-router-dom";

import { useUrlModule } from "../../../../../utils/hooks/useUrlModule";
import { getLocalizedDate } from "../../../../../utils/getLocalizedDate";
import calendarBlueIcon from "../../../../../images/icons/calendarBlue.svg";

import { NavLinkIcon } from "../../../../UI/NavLinkIcon/NavLinkIcon";
import OpeningInfo from "../../../../UI/molecules/OpeningInfo";
import StatusFormatter from "../../../../UI/atoms/StatusFormatter";

import ProviderContacts from "./components/ProviderContacts/ProviderContacts";
import styles from "./index.module.scss";

const Info = ({ order, isSimplified }) => {
  const { objectId } = useParams();
  const urlAdapter = useUrlModule();
  const urlPrefix = () => {
    if (urlAdapter !== "objects") return urlAdapter;
    return `${urlAdapter}/${objectId}`;
  };

  return (
    <div className={styles.info}>
      <div className={styles.title}>Заказ №{order.number}</div>
      <div className={styles.mainInfo}>
        <div className={styles.mainInfoBlock}>
          <div>от:</div>
          <div>
            {getLocalizedDate(order.created_at)}
            <img className={styles.calendarIcon} src={calendarBlueIcon} alt="Иконка календаря" />
          </div>
        </div>
        {!isSimplified && (
          <>
            <div className={styles.mainInfoBlock}>
              <div>заявка:</div>
              <NavLinkIcon
                to={`/${urlPrefix()}/requisitions/in-work/${order.requisition.id}`}
                title={`№ ${order.requisition.number || "ожидается"}`}
              />
            </div>
            {order.invoice && (
              <div className={styles.mainInfoBlock}>
                <div>счет:</div>
                <NavLinkIcon
                  to={`/${urlPrefix()}/documents/bills/${order.invoice.id}`}
                  title={`№ ${order.invoice.number || "ожидается"}`}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.columns}>
        <div className={styles.column}>
          <div className={styles.row}>
            <div className={styles.titleRow}>Статус:</div>
            <div>{StatusFormatter(order.status_name, { flow: "in" })}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.titleRow}>Поставщик:</div>
            <div>
              <ProviderContacts orderId={order.id} info={order.executor_provider} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.titleRow}>Адрес доставки:</div>
            <div>{order.building?.address || "Не указан"}</div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.row}>
            <div className={styles.titleRow}>Заказчик:</div>
            <div>
              <OpeningInfo info={order.executor_purchaser} title="Контакты заказчика" />
            </div>
          </div>
          {!isSimplified && order.packinglists && order.packinglists.length !== 0 && (
            <div className={styles.row}>
              <div className={styles.titleRow}>УПД:</div>
              <div>
                {order.packinglists.map((packingList) => (
                  <NavLinkIcon
                    to={`/${urlPrefix()}/documents/packing-lists/${packingList.id}`}
                    title={`${packingList.number ? `УПД № ${packingList.number}` : "УПД"}`}
                    key={packingList.id}
                  />
                ))}
              </div>
            </div>
          )}
          {!isSimplified && order.acts && order.acts.length !== 0 && (
            <div className={styles.row}>
              <div className={styles.titleRow}>Акт:</div>
              <div>
                {order.acts.map((act) => (
                  <NavLinkIcon
                    to={`/${urlPrefix()}/documents/service-acts/${act.id}`}
                    title={`${act.number ? `Акт № ${act.number}` : "Акт"}`}
                    key={act.id}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Info);
