import React from "react";

import { RUBLE } from "utils/constant";
import { splitThousands } from "utils/splitThousands";

import styles from "./index.module.scss";

const Amounts = ({ productsAmount, taxAmount, totalAmount, deliveryAmount, taxIsInclude }) => {

  return (
    <div className={styles.amounts}>
      <div className={styles.amountContainer}>
        <div className={styles.amountName}>
          <span className={styles.amountRow}>Товары:</span>
          {taxIsInclude && (
            <span className={styles.amountRow}>В том числе НДС:</span>
          )}
          <span className={styles.amountRow}>Доставка:</span>
          <span className={styles.amountRow}>Итого:</span>
        </div>

        <div className={styles.amount}>
          <span className={styles.amountRow}>{splitThousands(productsAmount, false, false)}</span>
          {taxIsInclude && (
            <span className={styles.amountRow}>{splitThousands(taxAmount, false, false)}</span>
          )}
          <span className={styles.amountRow}>{splitThousands(deliveryAmount, false, false)}</span>
          <span className={styles.amountRow}>{splitThousands(totalAmount, false, false)}</span>
        </div>
        <div className={styles.amountCurrency}>
          <span className={styles.amountRow}>{RUBLE}</span>
          {taxIsInclude && (
            <span className={styles.amountRow}>{RUBLE}</span>
          )}
          <span className={styles.amountRow}>{RUBLE}</span>
          <span className={styles.amountRow}>{RUBLE}</span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Amounts);