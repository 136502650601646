import React from 'react'
import { useParams } from 'react-router-dom'
import { useMaterialsPlanList } from '../../../../hooks/useMaterialsPlanList'
import AddButton from '../../atoms/AddButton/AddButton'
import EmptyPlaceholder from '../../atoms/EmptyPlaceholder/EmptyPlaceholder'
import Expandable from '../../atoms/Expandable/Expandable'
import { Spinner } from '../../Spinner/Spinner'
import styles from "./AddingListInModal.module.scss"
import ConfirmBlock from "./components/ConfirmBlock/ConfirmBlock"
import MoreButton from './components/MoreButton/MoreButton'
import materials from "images/icons/navigation/materials.svg";
import cn from "classnames"
import PlanMaterialsTableRow from './components/TableRow/PlanMaterialsTableRow'
import { IRouterParamsWithObjectId } from 'types/routerTypes'
import PlanMaterialsTableHeader from './components/TableHeader/PlanMaterialsTableHeader'
import { IMaterialInAddingInPlan, ISerializedMaterialInPlan } from 'types/interfaces/Materials'
import { filterRestPlannedMaterials } from 'components/pages/Manufacturing/components/ManufacturingModal/PlanEditing/utils'

interface IProps {
    addedMaterials: ISerializedMaterialInPlan[],
    selectedUsers: ISerializedMaterialInPlan[],
    onAccept: () => void,
    onDecline: () => void,
    onChangeCount: (id: number, value: string, type: "added" | "selected") => void,
    onDelete: (id: number) => void,
    onSelect: (e: React.ChangeEvent<HTMLInputElement>, item: ISerializedMaterialInPlan) => void,
    selectedIds: number[],
    addedIds: number[],
    expenditureId: number,
    presettedPercentage?: number,
    onAddPresettedMaterials?: (materials: ISerializedMaterialInPlan[]) => void;
    wholeWorkCount?: number
}

function AddPlanMaterialsInModal({
    addedMaterials,
    selectedUsers,
    onAccept,
    onDecline,
    onChangeCount,
    onDelete,
    onSelect,
    selectedIds,
    addedIds,
    expenditureId,
    presettedPercentage,
    onAddPresettedMaterials,
    wholeWorkCount
}: IProps) {
    const { objectId } = useParams<IRouterParamsWithObjectId>()
    const {
        allMaterials,
        isLoading,
        loadMoreHandler,
        count,
        filterHandler,
        presettedPlanMaterials
    } = useMaterialsPlanList(Number(objectId), expenditureId, presettedPercentage)

    const [isAdding, setIsAdding] = React.useState(false)

    React.useEffect(() => {
        if (!presettedPlanMaterials?.length) return
        onAddPresettedMaterials?.(presettedPlanMaterials)
    }, [presettedPlanMaterials])

    const allMaterialsWithoutAdded = React.useMemo(() => {
        return filterRestPlannedMaterials(allMaterials, addedIds)
    }, [allMaterials, addedIds])

    const canAddMoreMaterials = addedMaterials?.length < count

    const commonClickHandler = React.useCallback(() => {
        setIsAdding(false)
    }, [])

    return (
        <Expandable title={`Материалы: ${addedMaterials?.length || "-"}`} className={styles.wrapper}>
            {!!addedMaterials?.length && <div className={styles.table}>
                <PlanMaterialsTableHeader />
                {addedMaterials?.map((el, i) => (
                    <PlanMaterialsTableRow
                        key={el.id + el.count}
                        material={el}
                        canDelete
                        onChangeCount={e => onChangeCount(el.id, e.target.value, "added")}
                        onDelete={() => onDelete(el.id)}
                        canBeChecked={false}
                        isPrefilled
                        wholeWorkCount={wholeWorkCount}
                    />
                ))}
            </div>}
            {canAddMoreMaterials && <div className={styles.subtitle}>Добавить {!!addedMaterials?.length ? "еще " : ""}материал</div>}
            {canAddMoreMaterials && !isAdding && <AddButton onClick={() => setIsAdding(true)} />}
            {canAddMoreMaterials && isAdding && <ConfirmBlock count={selectedUsers?.length} onAccept={onAccept} onDecline={onDecline} onCommonClick={commonClickHandler} />}
            {canAddMoreMaterials && isAdding && <div className={cn(styles.table, styles.bordered)}>
                <PlanMaterialsTableHeader onFiltersChange={filterHandler} className={styles.header} />
                {isLoading && <Spinner />}
                {!!(!isLoading && allMaterials?.length) && allMaterialsWithoutAdded.map((el, i) => (
                    <PlanMaterialsTableRow
                        key={el.id}
                        material={el}
                        onChangeCount={e => onChangeCount(el.id, e.target.value, "selected")}
                        onCheck={onSelect}
                        isSelected={selectedIds.includes(el.id)}
                        wholeWorkCount={wholeWorkCount}
                    />
                ))}
                {!!(!isLoading && !allMaterials?.length) && <EmptyPlaceholder img={materials} />}
                <MoreButton isLoading={isLoading} allCount={count} existingCount={allMaterials?.length} onLoadMore={loadMoreHandler} />
            </div>}
        </Expandable>
    )
}

export default AddPlanMaterialsInModal