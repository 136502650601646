import React, { useState,useEffect } from 'react';
import cn from 'classnames';

import { 
  apiLoadBuildingMaterialSection
} from '../../../../../../../../redux/modules/common/building/sections/sectionsApi';
import ProductTable from '../ProductTable';

import styles from './index.module.scss';

const SectionChild = ({
  data, 
  objectId, 
  type,
  products,
  setProducts
}) => {
  const [section, setSection] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    data && loadSubSections();
  }, [data]);

  
  const loadSubSections = async (sectionId) => {
    const section = await apiLoadBuildingMaterialSection(objectId, data?.id, type);
    await setSection(section);
  }

  return (
    <div className={styles.container}>
      <div 
        className={cn(styles.header, {[styles.active]: isOpen})} 
        onClick={() => data?.products_count > 0 && setIsOpen(!isOpen)}
      >
        <span className={styles.title}>{data?.name}</span>
        <span className={styles.materialTitle}>{type !== "services" ? "Материалы:" : "Услуги:"}</span>
        <span className={styles.count}>{data?.products_count}</span>
      </div>
      {isOpen &&
        <ProductTable section={section} type={type} products={products} setProducts={setProducts}/>
      }
    </div>
  
  );
}

export default SectionChild;