import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { loadNewRequisitions, newRequisitionsSelector } from "../../../../redux/modules/common/building/requisitions";

import { TABS, TABS_LINKS } from "../constants";

const saveTabLinkToStorage = (buildingId, tabLink) => {
  let value = buildingId ? `${buildingId}_${tabLink}` : tabLink;
  localStorage.setItem("lastRequisitionActiveTabLink", value);
};

const parseValueFromStorage = (value) => {
  const splitValue = value.split("_");
  if (splitValue.length === 1) return { tabLink: value };
  return { tabLink: splitValue[1], buildingId: splitValue[0] };
};

const getTabLinkFromStorage = (buildingId) => {
  const valueFromStorage = localStorage.getItem("lastRequisitionActiveTabLink");
  if (!valueFromStorage) return null;

  const { tabLink, buildingId: parsedBuildingId } = parseValueFromStorage(valueFromStorage);
  if (buildingId && buildingId !== parsedBuildingId) return null;
  return tabLink;
};

const useTabs = (baseUrl, buildingId, activeTab) => {
  const dispatch = useDispatch();
  const newTabRequisitions = useSelector(newRequisitionsSelector);

  const history = useHistory();

  const [tabs, setTabs] = useState();

  const setInitialTab = useCallback((buildingId, requisitionsCount) => {
    if (requisitionsCount === 0) {
      history.replace(`${baseUrl}/${TABS_LINKS.IN_WORK_REQUISITIONS}`);
      return;
    }
    const tabLinkFromStorage = getTabLinkFromStorage(buildingId);
    if (tabLinkFromStorage) {
      history.replace(`${baseUrl}/${tabLinkFromStorage}`);
    } else {
      history.replace(`${baseUrl}/${TABS_LINKS.NEW_REQUISITIONS}`);
    }
  }, [history, baseUrl]);

  useEffect(() => {
    dispatch(loadNewRequisitions({ building_id: buildingId })).then((responseRequisitions) => {
      setInitialTab(buildingId, responseRequisitions.count);
    });
  }, [buildingId]);

  useEffect(() => {
    if (!activeTab) return;
    saveTabLinkToStorage(buildingId, activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (activeTab || !newTabRequisitions) return;
    setInitialTab(buildingId, newTabRequisitions.count);
  }, [activeTab]);

  useEffect(() => {
    if (!newTabRequisitions) return;
    setTabs(newTabRequisitions.count !== 0 ? TABS : TABS.slice(1, 3));
  }, [newTabRequisitions]);

  return { tabs };
};

export default useTabs;