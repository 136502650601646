import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import moment from 'moment';

import styles from './ExpenseProduct.module.scss';

import getShortFullName from '../../../../../../../utils/getShortFullName';
import DateCell from '../../../../../../UI/atoms/DateCell/DateCell';

const ExpenseProduct = ({data, onClick, measure}) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.field1}><div className={styles.dot}></div></div>
      <div className={styles.field2} title={data?.stock_product?.product_building?.name}>{data?.stock_product?.product_building?.name}</div>
      <div className={styles.center}>{getShortFullName(data?.stock_using?.responsible)}</div>
      <div className={cn(styles.center, styles.date)}><DateCell date={moment(data?.received_at).format('DD.MM.YYYY')} /></div> 
      <div className={styles.center}>{getShortFullName(data?.stock_using?.user||data?.stock_using?.worker)}</div>
      <div className={styles.center}>{data?.stock_product?.product_building?.measure}</div>
      <div className={styles.center}>{data?.count}</div>
    </div>
  )
}

export default ExpenseProduct;