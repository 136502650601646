import React from "react";

import App from "components/App";
import AppTasks from "components/AppTasks";
import ErrorBoundary from "components/ErrorBoundary";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

const Root: React.FC<void> = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <ErrorBoundary>{isAllModulesAllowed() ? <App /> : <AppTasks />}</ErrorBoundary>
    </DndProvider>
  );
};

export default Root;
