import React from 'react';
import cn from 'classnames';
import moment from 'moment';

import { getShortPrice } from 'utils/helpers';

import styles from './HistoryItem.module.scss';

const HistoryItem = ({data}) => {
  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <div className={styles.itemTitle}>Подано</div>
        <div className={styles.row}>
          <div className={styles.titleRow}>ФИО:</div>
          <div className={styles.valueRow}>
            {data?.fact_interval?.author?.last_name}{' '}
            {data?.fact_interval?.author?.first_name[0]}.
            {data?.fact_interval?.author?.middle_name[0]}.
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.titleRow}>Кол-во:</div>
          <div className={cn(styles.valueRow, styles.background)}>{(+data?.fact_interval?.count).toFixed(2)} {data?.measure}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.titleRow}>Дата:</div>
          <div className={cn(styles.valueRow, styles.background)}>{moment(data?.fact_interval?.created_at).format('DD.MM.YYYY')}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.titleRow}>Сумма:</div>
          <div className={styles.valueRow}>{getShortPrice(data?.fact_interval?.sum_works)}</div>
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.itemTitle}>Принято</div>
        <div className={styles.itemValue}>
          {data?.author?.last_name}{' '}
          {data?.author?.first_name[0]}.
          {data?.author?.middle_name[0]}.
        </div>
        <div className={cn(styles.itemValue, styles.background)}>{(+data?.count).toFixed(2)} {data?.measure}</div>
        <div className={cn(styles.itemValue, styles.background)}>{moment(data?.created_at).format('DD.MM.YYYY')}</div>
        <div className={styles.itemValue}>{getShortPrice(data?.sum_works)}</div>
      </div>
    </div>
  )
}

export default HistoryItem;