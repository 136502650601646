import TableReusableHead, { TableReusableHeaderCell } from 'components/UI/atoms/TableReusable/TableReusableHead';
import React from 'react'
import styles from "./ObjectsRow.module.scss"

interface IProps {
    onChangeFilter: (name: string, value: string) => void;
}

const ObjectsHeaderRow: React.FC<IProps> = ({
    onChangeFilter
}) => {
    return (
        <TableReusableHead className={styles.row} isSticky>
            <TableReusableHeaderCell
                filterTitle="Наименование проекта"
                filterType="text"
                filterField="name"
                onChangeFilter={onChangeFilter}
            >
                Наименование проекта
            </TableReusableHeaderCell>
            <TableReusableHeaderCell
                filterTitle="Организация"
                filterType="text"
                filterField="entity_name"
                onChangeFilter={onChangeFilter}
            >
                Организация
            </TableReusableHeaderCell>
            <TableReusableHeaderCell
                filterTitle="Заказчик"
                filterType="text"
                filterField="customer"
                onChangeFilter={onChangeFilter}
            >
                Заказчик
            </TableReusableHeaderCell>
            <div />
        </TableReusableHead>
    )
}

export default React.memo(ObjectsHeaderRow)