import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { addLoadDocument, loadDocumentsV2 } from "../../../redux/modules/common/documents/documents";
import { DEFAULT_LIMIT_DOCUMENT } from "../../../redux/modules/common/documents/constants";
import { documentsSelector, loadingStateSelector } from "../../../redux/modules/common/documents/reducer";

import { entityIdSelector } from "redux/modules/common/auth";
import { TableHeader } from "components/UI/TotoRowTable/TableHeader/TableHeader";
import TemplateBase from "components/UI/templates/TemplateBase";
import { transformDigitToFinancial } from "../../../utils/transformDigitToFinancial";
import SearchFilterTable from "components/UI/atoms/SearchFilterTable";
import SearchFilterSum from "components/UI/atoms/SearchFilterSum";

import styles from "./index.module.scss";
import OpenLink from "images/icons/OpenLink";
import SearchRangeDateFilter from "components/UI/atoms/SearchRangeDateFilter";
import { TableOslaLocal } from "components/UI/TableOslaCustom/TableOslaLocal";
import CalendarEvents from "images/icons/navigation/CalendarEvents";
import { Events } from "../Calendar/Events/Events";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

class Supplies extends Component {
  state = {
    filters: {
      limit: DEFAULT_LIMIT_DOCUMENT,
    },
  };

  componentDidMount() {
    this.props.loadDocumentsV2(null, null, null, this.state.filters, null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.filters !== this.state.filters) {
      this.props.loadDocumentsV2(null, null, null, this.state.filters, null);
    }
  }

  handleAdd = () => {
    this.props.addLoadDocument(
      null,
      {
        limit: DEFAULT_LIMIT_DOCUMENT,
        offset: this.props.documents?.results.length,
      },
      this.state.filters
    );
  };
  onRowClick = (row) => {
    const { history, location, match } = this.props;
    let formattedPathName = location.pathname.split("/")[1];
    if (formattedPathName === "objects") {
      history.push(`/${formattedPathName}/${match.params.objectId}/documents/packing-lists/${row.id}`);
    } else {
      history.push(`/${formattedPathName}/documents/packing-lists/${row.id}`);
    }
  };

  hasNextPage = () => this.props.documents?.count > this.props.documents?.results.length;

  changeFilters = (value, name) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [name]: value,
      },
    }));
  };

  render() {
    const { documents, loadDocumentsV2, loadingState, entityId } = this.props;

    const { filters } = this.state;

    return (
      <div style={{ position: "relative" }}>
        <div className={styles.overFlowLine}></div>
        <TemplateBase>
          <div className={styles.stickyTabs}>
            <Events />
          </div>
          <TableOslaLocal
            data={documents?.results}
            onRowClick={this.onRowClick}
            paginationHide={true}
            dataLoaderFilters={filters}
            dataLoader={loadDocumentsV2}
            dataLoaderArguments={[entityId]}
            rowClass={styles.tableRowClass}
            headerClass={styles.tableHeaderClass}
            isLoading={loadingState}
            tableClass={styles.tables}
            emptyPlaceholder={<EmptyPlaceholder svgJsx={<CalendarEvents />} />}
          >
            <TableHeader
              data={"number"}
              cellStyle={{
                width: "10%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              formatter={(cell) =>
                cell ? (
                  <div className={styles.icon}>
                    {cell}
                    <OpenLink width={"1rem"} color={"#868686"} />
                  </div>
                ) : (
                  <div className={styles.icon}>
                    Ожидается
                    <OpenLink width={"1rem"} color={"#868686"} />
                  </div>
                )
              }
            >
              <div className={styles.titleBlock}>
                <div className={styles.title}>№</div>
                <SearchFilterTable title="УПД" setFilter={(value) => this.changeFilters(value, "number")} />
              </div>
            </TableHeader>

            <TableHeader data={"provider"} formatter={(cell) => cell?.name} cellStyle={{ width: "22%" }}>
              <div className={styles.titleBlock}>
                <div className={styles.title}>Поставщик</div>
                <SearchFilterTable
                  title=" Поставщик"
                  setFilter={(value) => this.changeFilters(value, "provider_name")}
                />
              </div>
            </TableHeader>
            <TableHeader
              data={"amount_total"}
              formatter={(cell) => transformDigitToFinancial(cell)}
              cellStyle={{ width: "15%", textAlign: "right", paddingRight: "2.5rem" }}
            >
              <div className={styles.titleBlockSum}>
                <div className={styles.title}>Сумма ₽</div>
                <SearchFilterSum
                  title="Сумма ₽"
                  setFilterStart={(value) => {
                    this.changeFilters(value, "amount_total_min");
                  }}
                  setFilterEnd={(value) => {
                    this.changeFilters(value, "amount_total_max");
                  }}
                />
              </div>
            </TableHeader>
            <TableHeader
              data={"delivery_date"}
              formatter={(cell) => (cell ? moment(cell).format("DD.MM.YY") : "")}
              cellStyle={{ width: "9%", paddingLeft: "1.2rem", marginRight: "1rem" }}
            >
              <div className={styles.titleBlock}>
                <div className={styles.title}>Поставка</div>
                <SearchRangeDateFilter
                  title="Поставка"
                  setFilterStart={(value) => {
                    this.changeFilters(moment(value).format("YYYY-MM-DD"), "delivery_date_after");
                  }}
                  setFilterEnd={(value) => {
                    this.changeFilters(moment(value).format("YYYY-MM-DD"), "delivery_date_before");
                  }}
                />
              </div>
            </TableHeader>
            <TableHeader 
              data={"building"} 
              formatter={(cell) => cell?.address} 
              cellStyle={{ width: "24%" }} 
              style={{ textAlign: "left" }}
            >
              Адрес
            </TableHeader>
            <TableHeader
              data={"purchaser"}
              formatter={(cell) => cell?.name}
              style={{ textAlign: "left" }}
              cellStyle={{
                width: "20%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Заказчик
            </TableHeader>
          </TableOslaLocal>
          {this.hasNextPage() && (
            <ShowMoreButton
              isExists={!loadingState}
              handleAdd={this.handleAdd}
              showedCount={this.props.documents?.results?.length}
              allCount={this.props.documents?.count}
            />
          )}
        </TemplateBase>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    entityId: entityIdSelector(state),
    documents: documentsSelector(state),
    loadingState: loadingStateSelector(state),
    isPurchaser: state.auth.entity.type === "pcr",
  }),
  { loadDocumentsV2, addLoadDocument }
)(Supplies);
