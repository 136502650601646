import axios from 'axios';
import { createSelector } from 'reselect';

const moduleName = 'log';
const LOAD_LOG = `${moduleName}/LOAD_LOG`;
const SET_LOG = `${moduleName}/SET_LOG`;

const initialState = {
  log: null,
  isLoading: true,
};

export const stateSelector = state => state[moduleName];
export const logSelector = createSelector(stateSelector, state => state.log);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_LOG:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LOG:
      return {
        ...state,
        log: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const logAction = (response) => ({
  type: SET_LOG,
  payload: response.data
});

export const loadLog = (requisitionId) => {
  return dispatch => axios.get(`/user_logging/requisition/${requisitionId}/?limit=1000`).then(
    (response) => {
      dispatch({
        type: SET_LOG,
        payload: response.data,
      })
    },
    (error) => {}
  )
  .catch(err => console.error(err));
};