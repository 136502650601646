import axios from "axios";

import {
  IeditPlanIntervalProps,
  IgetIntervalProps,
  IgetIntervalsListProps,
  IpostIntervalFileProps,
  IdeleteIntervalFileProps,
  IplanIntervalResponse,
  IplanIntervalListItemResponse,
  ISectionPlan,
} from "./processTypes";

import { IMaterialInAddingInPlan } from "types/interfaces/Materials";
import { IList } from "types/ListResponse";

import { errorCatcher } from "../../../../utils/errorCatcher";

export function apiLoadProducts(
  id: number,
  isMaterials: boolean,
  editable: boolean
) {
  return axios
    .get(
      `/building/${id}/${isMaterials ? "materials/" : ""}estimate/${
        !editable ? "draft/" : ""
      }sections/` // items
    )
    .then(({ data }) => data.results)
    .catch(errorCatcher);
}

export function apiLoadProductsSection(
  id: number,
  sectionId: number,
  editable: boolean,
  isMaterials = false
) {
  return axios
    .get(
      `/building/${id}/${isMaterials ? "materials/" : ""}estimate/${
        !editable ? "draft/" : ""
      }items/${sectionId}/` // /, {params: {parent: sectionId}}sections
    )
    .then(({ data }) => data)
    .catch(errorCatcher);
}

interface IaddIntervalConfig {
  successCall: (response: unknown) => void;
  files: unknown[];
}

export function apiAddInterval(
  objectId: number,
  itemId: number,
  data: Record<string, unknown>,
  isMaterials = false,
  { successCall, files }: IaddIntervalConfig
) {
  return axios
    .post<unknown>(
      `/building/${objectId}/${
        isMaterials ? "materials/" : ""
      }estimate/items/${itemId}/intervals/`,
      {
        ...data,
      }
    )
    .then((response) => {
      if (successCall) {
        successCall(response);
      }
      if (files?.length) {
        const formData = new FormData(); // @ts-ignore
        files?.forEach((file) => formData.append("file", file.file));
        axios.post(
          // @ts-ignore
          `/building/${objectId}/estimate/items/${response.data.expenditure_id}/intervals/${response.data.id}/files/`,
          formData
        );
      }
    })
    .catch(errorCatcher);
}

export function apiAddFactInterval(
  objectId: number,
  itemId: number,
  data: Record<string, unknown>,
  isMaterials = false,
  { successCall, files }: IaddIntervalConfig
) {
  return axios
    .post(
      `/building/${objectId}/${
        isMaterials ? "materials/" : ""
      }estimate/items/${itemId}/fact-intervals/`,
      {
        ...data,
      }
    )
    .then((response) => {
      if (successCall) {
        successCall(response);
      }
      if (files?.length) {
        const formData = new FormData(); // @ts-ignore
        files?.forEach((file) => formData.append("file", file.file)); // @ts-ignore
        axios.post(
          `/building/${objectId}/estimate/items/${response.data.expenditure_id}/fact-intervals/${response.data.id}/files/`,
          formData
        );
      }
    })
    .catch(errorCatcher);
}

export function apiGetFactInterval(
  objectId: number,
  expenditureId: number,
  factIntervalId: number,
  isMaterials = false
) {
  return axios
    .get(
      `/building/${objectId}/${
        isMaterials ? "materials/" : ""
      }estimate/items/${expenditureId}/fact-intervals/${factIntervalId}/`
    )
    .then((response) => {
      return response;
    })
    .catch(errorCatcher);
}

export function apiGetPlanInterval(
  objectId: number,
  expenditureId: number,
  factIntervalId: number,
  isMaterials = false
) {
  return axios
    .get(
      `/building/${objectId}/${
        isMaterials ? "materials/" : ""
      }estimate/items/${expenditureId}/intervals/${factIntervalId}/`
    )
    .then((response) => response)
    .catch(errorCatcher);
}

export function apiSummaryIndicators(objectId: number) {
  return axios
    .get(`/building/${objectId}/estimate/count/`)
    .then(({ data }) => data)
    .catch(errorCatcher);
}

export const loadPlanIntervals = (
  buildingId: string,
  sectionId: string,
  start_at: string,
  end_at: string,
  all: unknown
) => {
  return axios
    .get(`/building/${buildingId}/estimate/plan/sections/${sectionId}/week/`, {
      params: { start_at, end_at, all },
    })
    .then(({ data }) => data)
    .catch(errorCatcher);
};

export const loadProgressIntervals = (
  buildingId: number,
  sectionId: number,
  start_at: string,
  end_at: string,
  all: unknown
) => {
  return axios
    .get(
      `/building/${buildingId}/estimate/progress/sections/${sectionId}/week/`,
      { params: { start_at, end_at, all } }
    )
    .then(({ data }) => data)
    .catch(errorCatcher);
};

export const editPlanInterval = (props: IeditPlanIntervalProps) => {
  return axios.patch(
    `/building/${props.buildingId}/estimate/items/${props.expenditureId}/intervals/${props.intervalId}/`,
    props.data
  );
};

export const getIntervalsList = ({
  buildingId,
  expenditureId,
  activeModule,
  dateStart,
  dateEnd,
}: IgetIntervalsListProps) => {
  return axios.get<IplanIntervalListItemResponse[]>(
    `/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/`,
    {
      params: { start_at__gte: dateStart, start_at__lte: dateEnd },
    }
  );
};

export const getIntervalFiles = ({
  buildingId,
  expenditureId,
  activeModule,
  intervalId,
}: IgetIntervalProps) => {
  return axios.get(
    `/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/files/`
  );
};

export const getIntervalData = ({
  buildingId,
  expenditureId,
  activeModule,
  intervalId,
}: IgetIntervalProps) => {
  return axios.get<IplanIntervalResponse>(
    `/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/`
  );
};

export const postIntervalFile = ({
  buildingId,
  expenditureId,
  activeModule,
  intervalId,
  file,
}: IpostIntervalFileProps) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(
    `/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/files/`,
    formData
  );
};

export const deleteIntervalFile = ({
  buildingId,
  expenditureId,
  activeModule,
  intervalId,
  fileId,
}: IdeleteIntervalFileProps) => {
  return axios.delete(
    `/building/${buildingId}/estimate/items/${expenditureId}/${activeModule}/${intervalId}/files/${fileId}/`
  );
};

export const getPlanMaterials = (
  objectId: number,
  expenditureId: number,
  filters: Record<string, string> | null
) => {
  return axios.get<IList<IMaterialInAddingInPlan>>(
    `/building/${objectId}/materials/relates/${expenditureId}/`,
    { params: { ...filters, limit: 10 } }
  );
};

export const createSectionPlan = (
  objectId: string,
  data: Record<string, string>
) => {
  return axios.post(
    `/building/${objectId}/estimate/sections/plan-intervals/`,
    data
  );
};

export const getSectionPlan = (objectId: string, section_id: number) => {
  return axios.get<ISectionPlan>(
    `/building/${objectId}/estimate/sections/plan-intervals/${section_id}/`
  );
};

export const editSectionPlan = (
  objectId: string,
  section_id: number,
  data: { start_at?: string; end_at?: string }
) => {
  return axios.patch<ISectionPlan>(
    `/building/${objectId}/estimate/sections/plan-intervals/${section_id}/`,
    data
  );
};

export const approveSectionPlan = (objectId: string, section_id: number) => {
  return axios.patch(
    `/building/${objectId}/estimate/sections/plan-intervals/${section_id}/confirm/`,
    { confirm: true }
  );
};
