import React, { useCallback } from "react";
import cn from "classnames";
import moment, { Moment } from "moment";

import Select, { SIZES } from "../Select";
import ButtonArrow from "../ButtonArrow/ButtonArrow";

import styles from './SelectYearWithArrwos.module.scss'

export interface ISelectYearWithArrowsProps {
  className?: string;
  selectClassName?: string
  date: Moment;
  onChange: (newMoment: Moment) => void;
}

const SelectYearWithArrows: React.FC<ISelectYearWithArrowsProps> = ({ className, selectClassName, date, onChange }) => {
  const year = date.year();

  const generatedYears = React.useMemo(() => {
    const res = [];
    for (let i = moment().year(); i >= year - 10; i--) {
      res.push({ id: i, name: i, label: i });
    }
    return res;
  }, [year]);

  const changeYear = useCallback((year: number) => onChange(moment(new Date(year, 0, 1))), [onChange]);

  const decreaseYear = useCallback(() => changeYear(year - 1), [year]);

  const increaseYear = useCallback(() => changeYear(year + 1), [year]);

  return (
    <div className={cn(styles.container, className)}>
      <ButtonArrow direction="left" onClick={decreaseYear} />
      <Select size={SIZES.SMALL} options={generatedYears} value={year} onChange={changeYear} className={selectClassName} />
      <ButtonArrow direction="right" onClick={increaseYear} />
    </div>
  );
};

export default SelectYearWithArrows;
