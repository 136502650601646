import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import validator from 'validator';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import styles from "./AuthTabs.module.scss"
import { authorizeUser, setCurrentUser, updateUserEntities } from 'redux/modules/common/auth';

import PasswordRecovery from './PasswordRecovery';
import ButtonBase from 'components/UI/atoms/ButtonBase';

export class SignIn extends Component {
  state = {
    showPassword: false,
    forgotPassword: false,
  };

  forgotPasswordToggle = () => {
    this.setState(prevState => ({
      forgotPassword: !prevState.forgotPassword,
    }));
  };

  submitHandler = async (formData, form, submitErrorsCallback) => {
    const { authorizeUser, updateUserEntities, setCurrentUser, history } = this.props;

    try {
      await authorizeUser(formData);
      await setCurrentUser();
      updateUserEntities();

      const lastRoutes = sessionStorage.getItem('lastRoutes');
      
      if (lastRoutes) {
        history.push(lastRoutes);
        sessionStorage.removeItem('lastRoutes');
      }

      return submitErrorsCallback(null);
    } catch (err) {
      if (err.response && err.response.data.errors) {
        return submitErrorsCallback({
          [FORM_ERROR]: err.response.data.errors.map(item => item.message),
        });
      } else {
        //message.error('Ошибка');
      }

      return submitErrorsCallback(null);
    }
  };

  validate = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Заполните Email';
    } else if (!validator.isEmail(values.email)) {
      errors.email = 'Неправильный формат почты';
    }

    if (!values.password) {
      errors.password = 'Заполните пароль';
    }

    return errors;
  };

  showPassToggle = e => {
    e.preventDefault();

    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  };

  getPasswordField = () => {
    return (
      <Field name="password">
        {({ input, meta }) => (
          <div className="authorization__form-item">
            {/*ДОБАВЛЯТЬ КЛАСС ERROR В AUTHORIZATION__LABEL ПРИ ВАЛИДАЦИИ*/}
            <label className="authorization__label">
              <span className="authorization__input-icon authorization__input-icon_password" />
              <input
                placeholder="Пароль"
                type={this.state.showPassword ? 'input' : 'password'}
                {...input}
                className={cn("authorization__input form__input password", styles.input)}
              />
            </label>
            {(meta.error || meta.submitError) &&
              (meta.touched || meta.submitError) && (
                <span className="help-text error is-visible">{meta.error || meta.submitError}</span>
              )}
          </div>
        )}
      </Field>
    );
  };

  render() {
    const { forgotPassword } = this.state;

    if (forgotPassword) {
      return <PasswordRecovery forgotPasswordClick={this.forgotPasswordToggle} />;
    }

    return (
      <div className="authorization__form-container">
        <Form
          onSubmit={this.submitHandler}
          validate={this.validate}
          render={({ handleSubmit, submitting, pristine, submitSucceeded, submitError }) => (
            <form className="authorization__form" onSubmit={handleSubmit}>
              <div className="authorization__controls">
                <Field name="email">
                  {({ input, meta }) => (
                    <div className="authorization__form-item">
                      <label className="authorization__label">
                        <span className="authorization__input-icon authorization__input-icon_mail">
                        </span>
                        <input
                          {...input}
                          placeholder="E-mail"
                          className={cn('authorization__input form__input', styles.input)}
                        />
                      </label>
                      {(meta.error || meta.submitError) &&
                        (meta.touched || meta.submitError) && (
                          <span className="help-text error is-visible">
                            {meta.error || meta.submitError}
                          </span>
                        )}
                    </div>
                  )}
                </Field>
                {this.getPasswordField()}
                {submitError && (
                  <div className="help-text error is-visible">
                    {submitError.map(err => <span key={err}>{err}</span>)}
                  </div>
                )}
              </div>
              <div className="authorization__buttons">
                <ButtonBase secondary type="submit" disabled={submitting} className={cn("btn btn-main", styles.submit)}>
                  Войти
                </ButtonBase>
                <a
                  onClick={this.forgotPasswordToggle}
                  className="authorization__link"
                  data-target="#enter-email"
                >
                  Забыли пароль&nbsp;(?)
                </a>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withRouter(connect(
  state => ({
    auth: state.auth,
  }),
  {
    authorizeUser,
    setCurrentUser,
    updateUserEntities,
  }
)(SignIn));
