import ProductInfoModal from 'components/UI/molecules/ProductInfoModal/ProductInfoModal';
import AddRelationToProduct from 'components/UI/organism/AddRelationToProduct';
import React from 'react'
import IExpenditure from 'types/interfaces/Expenditure';
import MatchingIcon from "../MatchingIcon/MatchingIcon";
import { getMatchingStatus } from './utils';

export interface IMatchingInfo {
    estimate_expenditure: IExpenditure,
    created_from_estimate: boolean,
    is_removed: boolean
}

interface IProps {
    info: IMatchingInfo,
    objectId: string,
    updateProducts: () => void,
    readOnly: boolean,
    type: string
}

const MatchingModal: React.FC<IProps> = ({
    info,
    readOnly,
    objectId,
    type,
    updateProducts
}) => {
    const [matchingModalOpen, setMatchingModalOpen] = React.useState(false)
    const [matchingOpen, setMatchingOpen] = React.useState(false)
    const [chosenMatchingProduct, setChosenMatchingProduct] = React.useState<IMatchingInfo | null>(null)

    const openMatchingModal = (info: IMatchingInfo) => {
        setMatchingModalOpen(true)
        setChosenMatchingProduct(info)
    }

    const closeMatchingModal = () => {
        setMatchingModalOpen(false)
    }

    const openMatching = (info: IMatchingInfo) => {
        setMatchingModalOpen(false)
        setMatchingOpen(true)
        setChosenMatchingProduct(info)
    }

    const closeMatching = () => {
        setMatchingOpen(false)
    }

    const onClickByMatchingIcon = () => {
        if (info.estimate_expenditure) {
            openMatchingModal(info);
        } else if (!readOnly) {
            openMatching(info);
        }
    }

    const onOpenEditMatching = () => {
        if (!chosenMatchingProduct) return
        openMatching(chosenMatchingProduct);
    }

    return (
        <div>
            {!readOnly && matchingOpen && (
                <AddRelationToProduct
                    idAddRelation={info}
                    type={type} /* @ts-ignore */
                    chosenMatchingProduct={chosenMatchingProduct}
                    updateProducts={updateProducts}
                    handleClose={closeMatching}
                    objectId={objectId}
                    editable={true}
                />
            )}
            <ProductInfoModal
                isOpen={matchingModalOpen}
                onClose={closeMatchingModal}
                onOpenEditing={onOpenEditMatching}
                canEdit={!info.created_from_estimate && !readOnly}
                section={chosenMatchingProduct?.estimate_expenditure?.section}
                number={chosenMatchingProduct?.estimate_expenditure?.number}
                justification={chosenMatchingProduct?.estimate_expenditure?.justification}
                expenditureName={chosenMatchingProduct?.estimate_expenditure?.name}
                price={chosenMatchingProduct?.estimate_expenditure?.price}
                count={chosenMatchingProduct?.estimate_expenditure?.count}
                isLoading={false}
            />
            <MatchingIcon
                onClick={onClickByMatchingIcon}
                status={getMatchingStatus(info)}
                disabled={info.is_removed}
            />
        </div>
    )
}

export default MatchingModal