import axios from 'axios'
import { createSelector } from 'reselect'
import moment from 'moment'

import { financeStateSelector, stateSelector as indexStateSelector } from '../finance'
import {
  prepareSections,
  addSectionChildren,
  prepareSectionExpenditures,
  setSectionIsOpen,
  prepareTableSections,
  getScrollPositionPercent,
  prepareSectionsAllObjects
} from './utils'
import { SCROLL_EVENT_TARGETS } from './constants'

const initialState = {
  year: +moment().year(),
  sections: [],
  isLoading: true,
  tableScrollPosition: {
    percent: 0,
    eventFrom: SCROLL_EVENT_TARGETS.TABLE
  },
  tableViewWidthPercent: 0,
  tableHighlighting: {
    positive: false,
    negative: true
  }
}

const moduleName = 'summary'
const actionsPrefix = 'constructing/finance/summary'

const SET_YEAR = `${actionsPrefix}_SET_YEAR`
const SET_SECTIONS = `${actionsPrefix}_SET_SECTIONS`
const SET_LOADING = `${actionsPrefix}_SET_LOADING`
const SET_SECTION_CHILDREN = `${actionsPrefix}_SET_SECTION_CHILDREN`
const SET_TABLE_SCROLL_POSITION = `${actionsPrefix}_SET_TABLE_SCROLL_POSITION`
const SET_TABLE_VIEW_WIDTH = `${actionsPrefix}_SET_TABLE_VIEW_WIDTH`
const SET_TABLE_HIGHLIGHTING = `${actionsPrefix}_SET_TABLE_HIGHLIGHTING`
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`

export const stateSelector = createSelector(financeStateSelector, (state) => state[moduleName])
export const objectIdSelector = createSelector(indexStateSelector, (state) => state.objectId)
export const yearSelector = createSelector(stateSelector, (state) => state.year)
export const sectionsSelector = createSelector(stateSelector, (state) => state.sections)
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading)
export const tableDataSelector = createSelector(
  sectionsSelector,
  yearSelector,
  prepareTableSections
)
export const tableScrollPositionSelector = createSelector(
  stateSelector,
  (state) => state.tableScrollPosition
)
export const tableViewWidthPercentSelector = createSelector(
  stateSelector,
  (state) => state.tableViewWidthPercent
)
export const tableHighlightingSelector = createSelector(
  stateSelector,
  (state) => state.tableHighlighting
)

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_YEAR:
      return {
        ...state,
        year: payload
      }
    case SET_SECTIONS:
      return {
        ...state,
        sections: payload
      }
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case SET_SECTION_CHILDREN:
      return {
        ...state,
        sections: payload
      }
    case SET_TABLE_SCROLL_POSITION:
      return {
        ...state,
        tableScrollPosition: {
          percent: getScrollPositionPercent(payload),
          eventFrom: payload.eventFrom
        }
      }
    case SET_TABLE_VIEW_WIDTH:
      return {
        ...state,
        tableViewWidthPercent: payload
      }
    case SET_TABLE_HIGHLIGHTING:
      return {
        ...state,
        tableHighlighting: {
          ...state.tableHighlighting,
          [payload.field]: payload.value
        }
      }
    case CLEAR_DATA:
      return {
        ...initialState,
        year: state.year,
      }
    default:
      return state
  }
}

export const setYear = (year) => ({
  type: SET_YEAR,
  payload: year
})

export const setSections = (data) => ({
  type: SET_SECTIONS,
  payload: data
})

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data
})

export const loadSectionChildren = (parentId, buildingId) => {
  return async (dispatch, getState) => {
    const objectId = objectIdSelector(getState())
    const year = yearSelector(getState())
    const sections = sectionsSelector(getState())
    const urlId = objectId != 0 ? objectId : buildingId
    try {
      const response = await axios.get(`/building/${urlId}/plan-fact/sections/${year}/`, {
        params: {
          parent: parentId,
          limit: 500 // TODO: add list pagination
        }
      })
      const newSections = JSON.parse(JSON.stringify(sections))
      addSectionChildren(newSections, parentId, response.data.results)
      dispatch(setSections(newSections))
    } catch (e) {
      console.error(e)
    }
  }
}

export const loadSectionExpenditures = (parentId, buildingId) => {
  return async (dispatch, getState) => {
    const objectId = objectIdSelector(getState())
    const year = yearSelector(getState())
    const sections = sectionsSelector(getState())
    const urlId = objectId != 0 ? objectId : buildingId
    try {
      const response = await axios.get(`/building/${urlId}/plan-fact/sections/${year}/`, {
        params: {
          expenditures_for: parentId,//.replace('_virtual', '')
          limit: 500 // TODO: add list pagination
        }
      })
      const newSections = JSON.parse(JSON.stringify(sections))
      const preparedSections = prepareSectionExpenditures(response.data.results, parentId)
      addSectionChildren(newSections, parentId, preparedSections)
      dispatch(setSections(newSections))
    } catch (e) {
      console.error(e)
    }
  }
}

export const closeSection = (sectionId) => {
  return async (dispatch, getState) => {
    const sections = sectionsSelector(getState())
    const newSections = JSON.parse(JSON.stringify(sections))
    setSectionIsOpen(newSections, sectionId, false)
    dispatch(setSections(newSections))
  }
}

export const openSection = (sectionId) => {
  return async (dispatch, getState) => {
    const sections = sectionsSelector(getState())
    const newSections = JSON.parse(JSON.stringify(sections))
    setSectionIsOpen(newSections, sectionId, true)
    dispatch(setSections(newSections))
  }
}

export const setTableScrollPositionAction = (data) => ({
  type: SET_TABLE_SCROLL_POSITION,
  payload: data
})

export const setTableViewWidth = (data) => ({
  type: SET_TABLE_VIEW_WIDTH,
  payload: data
})

export const setTableHighlighting = ({field, value}) => ({
  type: SET_TABLE_HIGHLIGHTING,
  payload: {field, value}
})

export const clearData = () => ({
  type: "CLEAR_DATA_DISABLED_ACTION"
})

export const loadBuildingSectionChildren = (objectId) => {
  return async (dispatch, getState) => {
    const sections = sectionsSelector(getState())
    //const date = dateSelector(getState())
    const year = yearSelector(getState())
    try {
      const response = await axios.get(`/building/${objectId}/plan-fact/sections/${year}/`, {
        params: {
          limit: 500 // TODO: add list pagination
        }
      })
      const newSections = JSON.parse(JSON.stringify(sections))
      addSectionChildren(newSections, objectId, response.data)
      dispatch(setSections(newSections))
    } catch (e) {
      console.error(e)
    }
  }
}

export const loadSections = () => {
  return async (dispatch, getState) => {
    const objectId = objectIdSelector(getState())
    const year = yearSelector(getState())
    if (objectId != 0) {
      try {
        dispatch(setLoading(true))
        const response = await axios.get(`/building/${objectId}/plan-fact/sections/${year}/`, {
          params: {
            limit: 500 // TODO: add list pagination
          }
        })
        const preparedSections = prepareSections(response.data.results)
        dispatch(setSections(preparedSections))
        dispatch(innerListsAutoOpener(preparedSections))
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    } else {
      try {
        dispatch(setLoading(true))
        const response = await axios.get(`/plan-fact/buildings/${year}/`, {
          params: {
            limit: 500
          }
        })
        dispatch(setSections(prepareSectionsAllObjects(response.data)))
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    }

  }
}

export const innerListsAutoOpener = (data) => (dispatch, getState) => {
  const objectId = objectIdSelector(getState())
  const sections = sectionsSelector(getState())
  const year = yearSelector(getState())
  let count = data?.length
  if (data?.length < 1000) {
    const dataPromises = []
    data.forEach(el => {
      const urlId = objectId != 0 ? objectId : el.building_id
      count = count + el.subsection_count
      dataPromises.push(axios.get(`/building/${urlId}/plan-fact/sections/${year}/`, {
        params: {
          parent: el.id,
        }
      }))
    })
    Promise.all(dataPromises).then(dataResponses => {
      dataResponses.forEach((resp, idx) => {
        const sections = sectionsSelector(getState())
        const urlId = objectId != 0 ? objectId : data[idx].building_id
        const newSections = JSON.parse(JSON.stringify(sections))
        addSectionChildren(newSections, data[idx].id, resp.data.results)
        dispatch(setSections(newSections))
        const promisesArr = []
        resp.data.results.forEach(ns => {
          promisesArr.push(axios.get(
            `/building/${urlId}/plan-fact/sections/${year}/`, {params: {expenditures_for: ns.id, limit: 500, offset: 0}}))
        }) 
        Promise.all(promisesArr).then(allResp => {
          allResp.forEach((response, i) => {
            const sections = sectionsSelector(getState())
            const newSections = JSON.parse(JSON.stringify(sections))
            const preparedSections = prepareSectionExpenditures(response.data.results, resp.data.results[i].id)
            addSectionChildren(newSections, resp.data.results[i].id, preparedSections)
            dispatch(setSections(newSections))
          })
        }).catch(err => console.log(err))
      })
    }).catch(err => console.log(err))
  }
}