import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  objectsBreadcrumbsLoadingSelector,
  objectsBreadcrumbsSelector,
} from "redux/modules/common/building/objects";

import SelectMenu from "components/UI/atoms/SelectMenu";
import { TASK_LISTS_TYPES } from "../../../../../pages/Tasks/constants";

import { hasOnlyDigits } from "utils/helpers";
import { isAllModulesAllowed } from "utils/isAllModulesAllowed";
import breadcrumbs from "utils/breadcrumbs";

import Line from "images/icons/Line";

import styles from "./Breadcrumbs.module.scss";


const Breadcrumbs = () => {
  const location = useLocation();

  const objects = useSelector(objectsBreadcrumbsSelector);
  const isObjectsLoading = useSelector(objectsBreadcrumbsLoadingSelector);
  const locationArray = location.pathname.split("/").filter((item) => item);
  const activeModule = breadcrumbs.find((item) => item.name === locationArray[0]);

  const isOnlyTaskModule = !isAllModulesAllowed();

  useEffect(() => isOnlyTaskModule && localStorage.setItem("lastObjectId", objects?.results[0]?.id), [isOnlyTaskModule, objects?.results]);

  const activeSubModule = activeModule?.children?.find(
    (item) => item.name === (["objects"].includes(activeModule.name) ? locationArray[2] : locationArray[1])
  );

  const manufacturingSelOption = useMemo(() => {
    if (!objects?.results) return;
    if (!objects.results?.length) return;

    if (locationArray[2] && Number.isInteger(+locationArray[2]) && objects.results.length > 1) {
      return objects.results.find((item) => +item.id === +locationArray[2])?.name || "Все проекты";
    } else if (
      locationArray[1] !== "manufacturing" &&
      locationArray[1] !== "projects" &&
      objects.results?.length === 1
    ) {
      locationArray.push(objects.results[0]?.id);
      return objects.results[0]?.name;
    }

    return objects.results.find((item) => item.id.toString() === locationArray[2]?.toString())?.name || "Все проекты";
  }, [locationArray, objects?.results]);

  const objectsSelectIsActive = useMemo(
    () =>
      (activeModule?.name === "tasks" && TASK_LISTS_TYPES[locationArray[1]] && hasOnlyDigits(locationArray[2])) ||
      (["objects", "constructing"].includes(activeModule?.name) &&
        (hasOnlyDigits(locationArray[1]) || hasOnlyDigits(locationArray[2])) &&
        activeModule?.name !== "tasks") ||
      locationArray[1] === "manufacturing",
    [activeModule?.name, locationArray]
  );

  const objectsSelect = useMemo(() => {
    if (!objects) return [];

    if ((locationArray[1] === "finance" || locationArray[0] === "tasks" || locationArray[1] === "files") && objects?.results.length > 1) {
      return [{ id: 0, name: "Все проекты" }, ...objects.results];
    } else if (
      locationArray[1] === "manufacturing" &&
      (activeModule?.name === "constructing" || activeModule?.name === "purchases")
    ) {
      return [{ id: "", name: "Все проекты" }, ...objects.results];
    } else {
      return objects.results;
    }
  }, [location, objects]);

  return (
    <div className={styles.container}>
      {activeModule && (
        <div className={styles.selectClassNameNew}>
          <div className={styles.breadcrumbsTitle}>{activeModule.title}</div>
          <Line width={"0.125rem"} height={"2.313rem"} />
        </div>
      )}

      {activeSubModule && (
        <SelectMenu
          selectedOption={activeSubModule?.title}
          titleClassName={styles.breadcrumbsTitleSmall}
          selectClassName={styles.selectClassName}
          optionsBlockClassName={styles.optionsBlockClassName}
        ></SelectMenu>
      )}

      {!isObjectsLoading &&
        objectsSelectIsActive &&
        locationArray[2] !== "settings" &&
        locationArray[2] !== "events" &&
        locationArray[2] !== "profile" &&
        locationArray[1] !== "finance" &&
        !hasOnlyDigits(locationArray[2]) &&
        locationArray[1] !== "manufacturing" && (
          <SelectMenu
            selectedOption={
              objects?.results.length > 0 && objects?.results?.find((item) => +item.id === +locationArray[1])?.name
            }
            titleClassName={styles.breadcrumbsTitleSmall}
            selectClassName={styles.selectClassName}
            optionsBlockClassName={styles.optionsBlockClassName}
            lastEl
          >
            {objectsSelect.length < 2
              ? null
              : objectsSelect
                ?.filter((item) => item.id !== locationArray[1])
                ?.map((item) => (
                  <Link key={item.id} to={`${activeModule.link}/${item.id}/${locationArray[2]}`}>
                    <div className={styles.breadcrumbsItem} title={item.name}>
                      {item.name}
                    </div>
                  </Link>
                ))
            }
          </SelectMenu>
        )}
      {!isObjectsLoading &&
        ((["objects", "constructing"].includes(activeModule?.name) && hasOnlyDigits(locationArray[2])) ||
          locationArray[1] === "manufacturing") && (
          <SelectMenu
            selectedOption={manufacturingSelOption}
            titleClassName={styles.breadcrumbsTitleSmall}
            selectClassName={styles.selectClassName}
            optionsBlockClassName={styles.optionsBlockClassName}
            lastEl
          >
            {objectsSelect
              ?.filter((item) => item.id.toString() !== locationArray[2])
              ?.map((item) => (
                <Link
                  key={item.id}
                  to={
                    locationArray[3]
                      ? `${activeModule.link}/${locationArray[1]}/${item.id}/${locationArray[3]}`
                      : `${activeModule.link}/${locationArray[1]}/${item.id}`
                  }
                >
                  <div className={styles.breadcrumbsItem} title={item.name}>
                    {item.name}
                  </div>
                </Link>
              ))}
          </SelectMenu>
        )}
      {!isObjectsLoading &&
        locationArray[0] === "tasks" &&
        locationArray[1] !== "lists" &&
        locationArray[1] !== "settings" &&
        locationArray[1] !== "events" &&
        locationArray[1] !== "profile" &&
        hasOnlyDigits(locationArray[2]) && 
        !isOnlyTaskModule && (
          <SelectMenu
            selectedOption={
              objects?.results.length > 0 && objects?.results?.find((item) => +item.id === +locationArray[2])?.name
            }
            titleClassName={styles.breadcrumbsTitleSmall}
            selectClassName={styles.selectClassName}
            optionsBlockClassName={styles.optionsBlockClassName}
            lastEl
            isDisabled={objectsSelect?.length < 2}
          >
            {objectsSelect?.length > 1 && 
              objectsSelect
                ?.filter((item) => +item.id !== +locationArray[1])
                ?.map((item) => (
                  <Link
                    key={item.id}
                    to={
                      locationArray[3]
                        ? `${activeModule.link}/${locationArray[1]}/${item.id}/${locationArray[3]}`
                        : `${activeModule.link}/${locationArray[1]}/${item.id}`
                    }
                  >
                    <div className={styles.breadcrumbsItem} title={item.name}>
                      {item.name}
                    </div>
                  </Link>
                ))
            }
          </SelectMenu>
        )}
      {!isObjectsLoading &&
        locationArray[1] === "files" &&
        !["objects", "constructing", "tasks"].includes(locationArray[0]) && (
          <SelectMenu
            selectedOption={
              objects?.results.length > 0 && objects?.results?.find((item) => +item.id === +locationArray[2])?.name
            }
            titleClassName={styles.breadcrumbsTitleSmall}
            selectClassName={styles.selectClassName}
            optionsBlockClassName={styles.optionsBlockClassName}
            lastEl
          >
            {objectsSelect
              ?.filter((item) => item.id !== locationArray[2])
              ?.map((item) => (
                <Link
                  key={item.id}
                  to={
                    locationArray[3]
                      ? `${activeModule.link}/${locationArray[1]}/${item.id}/${locationArray[3]}`
                      : `${activeModule.link}/${locationArray[1]}/${item.id}`
                  }
                >
                  <div className={styles.breadcrumbsItem} title={item.name}>
                    {item.name}
                  </div>
                </Link>
              ))}
          </SelectMenu>
        )}
    </div>
  );
};

export default Breadcrumbs;
