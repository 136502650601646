import React from 'react'

export default function ContactsIcon({color = '#000', width = '24'}) {
  return (
    <svg
      width={width}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3,18.5c-0.7-0.9-1.7-1.6-2.9-1.9l-3.1-0.8L15.1,15c0.8-0.9,1.4-2.1,1.6-3.3c0.3-0.1,0.5-0.3,0.6-0.5
	c0.2-0.2,0.3-0.5,0.3-0.8l0.2-1.8c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.3-0.6C17.4,7.1,17.2,7,17,6.9l0.1-1.3l0.2-0.3
	c0.4-0.4,0.6-1,0.6-1.6c0-0.6-0.2-1.2-0.6-1.6c-0.4-0.6-0.9-1-1.6-1.3c-0.6-0.3-1.3-0.4-2-0.4c-1.2,0-2.4,0.3-3.4,0.9
	C6.5,1.4,6,3.5,6,5.2c0,0.4,0.1,1.1,0.1,1.6C6,7,5.8,7.1,5.7,7.2C5.5,7.4,5.4,7.6,5.3,7.9C5.3,8.1,5.3,8.3,5.3,8.6l0.2,1.8
	c0,0.3,0.2,0.6,0.4,0.9c0.2,0.2,0.4,0.4,0.7,0.5c0.2,1.2,0.7,2.3,1.5,3.2l-0.2,0.9l-3.1,0.8c-1.1,0.3-2.2,1-2.9,1.9
	c-0.7,0.9-1.1,2.1-1.1,3.3c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1h20c0.2,0,0.4-0.1,0.5-0.2
	c0.1-0.1,0.2-0.3,0.2-0.5C22.3,20.6,22,19.5,21.3,18.5z M9.1,17c0.1-0.1,0.2-0.2,0.2-0.4l0.4-1.8c0-0.1,0-0.3,0-0.4
	c0-0.1-0.1-0.3-0.2-0.4C8.6,13.3,8.1,12.2,8,11c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2H7.1c0,0,0,0,0,0c0,0,0,0-0.1,0
	c0,0,0,0,0-0.1L6.8,8.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2
	c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3C7.7,6.9,7.6,5.6,7.6,5.2c0-1.1,0-2.3,3.2-2.4c0.2,0,0.3-0.1,0.4-0.1
	c0.8-0.5,1.8-0.8,2.7-0.8c0.4-0.1,0.9,0,1.3,0.2c0.4,0.2,0.7,0.5,1,0.8c0.5,0.8,0.2,1.1,0.1,1.3l-0.4,0.4c-0.1,0.1-0.2,0.3-0.2,0.5
	l-0.1,2.2c0,0.2,0.1,0.4,0.2,0.6c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	l-0.2,1.8c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c-0.1,1.2-0.6,2.3-1.5,3.2
	c-0.1,0.1-0.2,0.2-0.2,0.4s0,0.3,0,0.4l0.4,1.7c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2l3.6,0.9c0.7,0.2,1.3,0.6,1.8,1.1
	c0.5,0.5,0.8,1.1,0.9,1.8L2.4,21c0.1-0.7,0.4-1.3,0.9-1.8c0.5-0.5,1.1-0.9,1.8-1.1l3.6-0.9C8.9,17.2,9,17.1,9.1,17z"
        fill={color}
      />
    </svg>
  )
}
