import axios from 'axios';
import { createSelector } from 'reselect';

const LOAD_PRODUCTS_LIST = 'products/LOAD_PRODUCTS_LIST';
const LOAD_PRODUCTS_IN_SELECT = 'products/LOAD_PRODUCTS_IN_SELECT';
const PRODUCTS_SELECT_SCROLL_TO_BOTTOM = 'products/PRODUCTS_SELECT_SCROLL_TO_BOTTOM';
const CLEAR_PRODUCTS_IN_SELECT = 'products/CLEAR_PRODUCTS_IN_SELECT';

const initialState = {
  productsList: {},
  productsInSelect: {
    products: [],
    next: null,
  },
};

export default (productsState = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_PRODUCTS_LIST: {
      return {
        ...productsState,
        productsList: payload,
      };
    }

    case LOAD_PRODUCTS_IN_SELECT: {
      return {
        ...productsState,
        productsInSelect: {
          next: payload.next,
          products: [...payload.results],
        },
      };
    }

    case CLEAR_PRODUCTS_IN_SELECT: {
      return {
        ...productsState,
        productsInSelect: {
          next: null,
          products: [],
        },
      };
    }

    case PRODUCTS_SELECT_SCROLL_TO_BOTTOM: {
      return {
        ...productsState,
        productsInSelect: {
          next: payload.next || null,
          products: [...productsState.productsInSelect.products, ...payload.results],
        },
      };
    }

    default:
      return productsState;
  }
};

/*
 Selectors
*/

export const stateSelector = state => state.products;
export const productsInSelectSelector = createSelector(
  stateSelector,
  state => state.productsInSelect
);
export const productsListSelector = createSelector(stateSelector, state => state.productsList);

/*
 Thunks
*/

export const loadProductsList = (entityId, paginationParams, filterParams) => {
  const paginationQuery = paginationParams || { limit: 10, offset: 0 };

  const config = {
    params: {
      ...paginationQuery,
      ...filterParams,
    },
  };

  return (dispatch, getState) => {
    const role = getState().auth.user.account_type === 'pcr' ? 'purchasers' : 'providers';
    axios.get(`/${role}/${entityId}/products/`, config).then(response =>
      dispatch({
        type: LOAD_PRODUCTS_LIST,
        payload: response.data,
      })
    );
  };
};

export const loadProductsInSelect = (entityId, filterParams) => {
  const config = {
    params: {
      ...filterParams,
    },
  };

  return (dispatch, getState) => {
    const role = getState().auth.user.account_type === 'pcr' ? 'purchasers' : 'providers';

    axios
      .get(`/${role}/${entityId}/products/`, config)
      .then(res => dispatch({ type: LOAD_PRODUCTS_IN_SELECT, payload: res.data }));
  };
};

export const onProductsSelectScrollToBottom = next => {
  const api = next.slice(0, 4) + 's' + next.slice(4); // http to https (needs fix on backend(?))

  return dispatch => {
    axios
      .get(api)
      .then(res => dispatch({ type: PRODUCTS_SELECT_SCROLL_TO_BOTTOM, payload: res.data }));
  };
};
