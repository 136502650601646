import { message } from "antd";
import {
  apiBulkCreateRelations,
  apiCreatePlanRelation,
  apiDeletePlanRelation,
  apiEstimateTree,
  apiGetExpenditure,
  apiGetExpenditurePlan,
  apiGetInterval,
  apiGetIntervalFiles,
  apiGetIntervalList,
  apiGetMaterialInterval,
  apiGetMaterials,
  apiGetPlan,
  apiGetPlanRelations,
  apiGetWeekMaterials,
  apiGetWeekPlan,
  apiGetWorkMaterials,
  apiPatchRelation,
  apiRemoveFile,
  apiUploadFile,
  loadIntervalsForRelations,
  loadSectionsForRelations,
} from "./manufacturingApi";
import {
  MATERIALS_TAB_ID,
  WORKS_TAB_ID,
} from "../../../../../components/pages/Manufacturing/constants";
import {
  FACT_INTERVALS,
  getInitialMaterialData,
  getInitialProjectData,
  INTERVALS,
} from "./manufacturing";
import {
  addIntervalLinkAction,
  deleteArrowAction,
  disableIntervalLinkingAction, dropHighlightRelatedIntervalsAction,
  dropLoadedChartMonthsAction,
  enableIntervalLinkingAction,
  getEstimateMaterialsTreeAction,
  getEstimateTreeAction,
  getExpenditureAction,
  getIntervalAction,
  getMaterialIntervalAction,
  getMaterialsAction,
  getModalIntervalAction,
  getModalIntervalFilesAction,
  getModalIntervalListAction,
  getModalIntervalMaterialsAction,
  getPlanAction,
  getWeekMaterialsAction,
  getWeekPlanAction, highlightRelatedIntervalsAction,
  loadAvailableIntervalsAction,
  loadAvailableSectionsAction,
  markConstructingYearMonthAction,
  markManufacturingYearMonthAction,
  projectEstimateSetAction,
  pushFromRelationsAction,
  pushToRelationsAction,
  removeFromRelationAction,
  removeToRelationAction,
  setActiveBranchAction, setArrowHashAction,
  setArrowsAction,
  setChartViewModeAction,
  setIsLoadingChartDataAction,
  setManufacturingMonthMarkersAction,
  setManufacturingTabAction,
  setMaterialDataAction,
  setMaterialWeekDataAction,
  setModalIntervalDataAction,
  setProjectDataAction,
  setProjectWeekDataAction,
  setRelationsFromCurrentIntervalAction,
  setRelationsToCurrentIntervalAction,
  startDragIntervalAction,
  startLoadingChartTreeAction,
  startLoadingModalAction,
  stopDragIntervalAction,
  stopLoadingChartTreeAction,
  stopLoadingModalAction,
  updateArrowAction,
  updateDiagramFiltersAction,
  updateFromRelationAction,
  updateToRelationAction
} from './actions'
import {
  getRelationsForBulkCreate,
  pushMaterialsToAcc,
  pushWorksToAcc,
} from "./utils";
import { RelationFromTypes } from "../../../../../components/pages/Manufacturing/components/ManufacturingModal/IntervalRelationsContent/types";
import { getFetchYearsMonths } from "../../../../../components/pages/Manufacturing/utils";

export const loadTree = (projectId) => {
  return async (dispatch) => {
    dispatch(startLoadingChartTreeAction());
    let tree;
    let materialsTree;

    await Promise.allSettled([
      apiEstimateTree(projectId, WORKS_TAB_ID).then((res) => (tree = res)),
      apiEstimateTree(projectId, MATERIALS_TAB_ID).then(
        (res) => (materialsTree = res)
      ),
    ]);

    if (!tree && !materialsTree) return;

    if (tree) dispatch(getEstimateTreeAction(tree));
    if (materialsTree) dispatch(getEstimateMaterialsTreeAction(materialsTree));
    dispatch(stopLoadingChartTreeAction());
  };
};

export const loadPlan = (projectId, year, month) => {
  return async (dispatch, getState) => {
    const { loadedManufacturingChartData } = getState().manufacturing;

    const fetchedDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedManufacturingChartData,
      tabId: WORKS_TAB_ID,
    });

    let data = await Promise.allSettled(
      fetchedDates.map((yearMonth) =>
        apiGetPlan(projectId, ...yearMonth.split("-").map((x) => Number(x)))
      )
    );

    if (!data) return;

    let newData = getInitialProjectData();

    if (newData)
      data
        .filter((x) => x.status === "fulfilled")
        .forEach(({ value }) => pushWorksToAcc(newData, value));

    dispatch(getPlanAction(newData));

    dispatch(
      markManufacturingYearMonthAction({
        projectId,
        fetchedDates,
        dataType: WORKS_TAB_ID,
      })
    );
  };
};

export const loadMaterials = (projectId, year, month) => {
  return async (dispatch, getState) => {
    const { loadedManufacturingChartData } = getState().manufacturing;

    const fetchedDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedManufacturingChartData,
      tabId: MATERIALS_TAB_ID,
    });

    let data = await Promise.allSettled(
      fetchedDates.map((yearMonth) =>
        apiGetMaterials(
          projectId,
          ...yearMonth.split("-").map((x) => Number(x))
        )
      )
    );

    let newMaterialsData = getInitialMaterialData();

    data
      .filter((x) => x.status === "fulfilled")
      .forEach(({ value }) => pushMaterialsToAcc(newMaterialsData, value));

    dispatch(getMaterialsAction(newMaterialsData));

    dispatch(
      markManufacturingYearMonthAction({
        projectId,
        dataType: MATERIALS_TAB_ID,
        fetchedDates,
      })
    );
  };
};

export const loadWeekPlan = (
  objectid,
  start_week__gte,
  end_week__gte,
  year
) => {
  return async (dispatch) => {
    const weekPlan = await apiGetWeekPlan(
      objectid,
      start_week__gte,
      end_week__gte,
      year
    );
    dispatch(getWeekPlanAction(weekPlan));
  };
};

export const loadWeekMaterials = (
  objectid,
  start_week__gte,
  end_week__gte,
  year
) => {
  return async (dispatch) => {
    const weekMaterials = await apiGetWeekMaterials(
      objectid,
      start_week__gte,
      end_week__gte,
      year
    );
    dispatch(getWeekMaterialsAction(weekMaterials));
  };
};

export const loadInterval = (start_moment, end_moment) => {
  return async (dispatch) => {
    const interval = await apiGetInterval(
      start_moment.format("YYYY-MM-DD"),
      end_moment.format("YYYY-MM-DD")
    );
    dispatch(getIntervalAction(interval));
  };
};

export const loadMaterialInterval = (start_moment, end_moment) => {
  return async (dispatch) => {
    const materialInterval = await apiGetMaterialInterval(
      start_moment.format("YYYY-MM-DD"),
      end_moment.format("YYYY-MM-DD")
    );
    dispatch(getMaterialIntervalAction(materialInterval));
  };
};

export const setChartViewMode = (chartViewMode) => (dispatch) => {
  dispatch(setChartViewModeAction(chartViewMode));
};

export const setManufacturingTab = (manufacturingTab) => (dispatch) => {
  dispatch(setManufacturingTabAction(manufacturingTab));
};

export const getModalIntervalList = (
  projectId,
  expenditureId,
  activeModule,
  date_start,
  date_end,
  modalType
) => {
  return async (dispatch) => {
    dispatch(startLoadingModalAction());

    const intervalList = await apiGetIntervalList(
      projectId,
      expenditureId,
      activeModule,
      date_start,
      date_end,
      modalType
    );

    dispatch(getModalIntervalListAction(intervalList));

    if (intervalList?.length === 1) {
      dispatch(
        getModalInterval(
          projectId,
          activeModule,
          intervalList[0]?.expenditure_id,
          intervalList[0]?.id
        )
      );
      dispatch(
        getModalFiles(
          projectId,
          intervalList[0].expenditure_id,
          activeModule,
          intervalList[0].id
        )
      );
    } else {
      dispatch(stopLoadingModalAction());
    }
  };
};

export const getModalInterval = (
  projectId,
  activeModule,
  expenditureId,
  intervalId
) => {
  return async (dispatch) => {
    let modalInterval;

    if (activeModule === FACT_INTERVALS)
      modalInterval = await apiGetExpenditure(
        projectId,
        expenditureId,
        intervalId
      );
    if (activeModule === INTERVALS)
      modalInterval = await apiGetExpenditurePlan(
        projectId,
        expenditureId,
        intervalId
      );

    if (modalInterval) dispatch(getModalIntervalAction(modalInterval));

    dispatch(stopLoadingModalAction());
  };
};

export const getModalFiles = (
  projectId,
  expenditureId,
  activeModule,
  intervalId
) => {
  return async (dispatch) => {
    const modalIntervalFiles = await apiGetIntervalFiles(
      projectId,
      expenditureId,
      activeModule,
      intervalId
    );
    dispatch(getModalIntervalFilesAction(modalIntervalFiles));
  };
};

export const getModalWorkMaterials = (projectId, expenditureId, intervalId) => {
  return async (dispatch) => {
    const modalIntervalMaterials = await apiGetWorkMaterials(
      projectId,
      expenditureId,
      intervalId
    );
    dispatch(getModalIntervalMaterialsAction(modalIntervalMaterials));
  };
};

export const uploadModalFile = (
  projectId,
  expenditureId,
  activeModule,
  intervalId,
  formData
) => {
  return async (dispatch) => {
    const modalIntervalFiles = await apiUploadFile(
      projectId,
      expenditureId,
      activeModule,
      intervalId,
      formData
    );
    dispatch(getModalIntervalFilesAction(modalIntervalFiles));
  };
};

export const removeModalFile = (
  projectId,
  expenditureId,
  activeModule,
  intervalId,
  fileId
) => {
  return async (dispatch) => {
    const modalIntervalFiles = await apiRemoveFile(
      projectId,
      expenditureId,
      activeModule,
      intervalId,
      fileId
    );
    dispatch(getModalIntervalFilesAction(modalIntervalFiles));
  };
};

export const clearModalData = () => (dispatch) => {
  dispatch(setModalIntervalDataAction({}));
};

export const clearExpenditure = () => (dispatch) => {
  dispatch(getExpenditureAction({}));
};

export const clearModalInterval = () => (dispatch) => {
  dispatch(getModalIntervalAction({}));
};

export const setIsLoadingChartData = (isLoading) => (dispatch) => {
  dispatch(setIsLoadingChartDataAction(isLoading));
};

export const mergeData = (projectId, year, month) => {
  return async (dispatch, getState) => {
    const { loadedConstructingChartData, tab } = getState().manufacturing;

    const fetchedWorkDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedConstructingChartData,
      tabId: WORKS_TAB_ID,
    });

    const fetchedMaterialDates = getFetchYearsMonths({
      projectId,
      year,
      month,
      loadedChartData: loadedConstructingChartData,
      tabId: MATERIALS_TAB_ID,
    });

    let data =
      tab === WORKS_TAB_ID &&
      (await Promise.allSettled(
        fetchedWorkDates.map((yearMonth) =>
          apiGetPlan(projectId, ...yearMonth.split("-").map((x) => Number(x)))
        )
      ));

    let materialData =
      tab === MATERIALS_TAB_ID &&
      (await Promise.allSettled(
        fetchedMaterialDates.map((yearMonth) =>
          apiGetMaterials(
            projectId,
            ...yearMonth.split("-").map((x) => Number(x))
          )
        )
      ));

    if (!data && !materialData) return;

    if (data) {
      let newData = getInitialProjectData();

      if (newData)
        data
          .filter((x) => x.status === "fulfilled")
          .forEach(({ value }) => pushWorksToAcc(newData, value));

      dispatch(setProjectDataAction(newData));
      dispatch(
        markConstructingYearMonthAction({
          projectId,
          dataType: WORKS_TAB_ID,
          fetchedDates: fetchedWorkDates,
        })
      );
    }

    if (materialData) {
      let newMaterialData = getInitialMaterialData();
      materialData
        .filter((x) => x.status === "fulfilled")
        .forEach(({ value }) => pushMaterialsToAcc(newMaterialData, value));

      dispatch(setMaterialDataAction(newMaterialData));
      dispatch(
        markConstructingYearMonthAction({
          projectId,
          dataType: MATERIALS_TAB_ID,
          fetchedDates: fetchedMaterialDates,
        })
      );
    }
  };
};

export const mergeWeekData = (projectId, start_week, end_week, year) => {
  return async (dispatch, getState) => {
    const { tab } = getState().manufacturing;

    let weekData;
    let materialWeekData;

    await Promise.allSettled([
      tab === WORKS_TAB_ID &&
        apiGetWeekPlan(projectId, start_week, end_week, year).then(
          (res) => (weekData = res)
        ),
      tab === MATERIALS_TAB_ID &&
        apiGetWeekMaterials(projectId, start_week, end_week, year).then(
          (res) => (materialWeekData = res)
        ),
    ]);

    if (!weekData && !materialWeekData) return;

    if (weekData) dispatch(setProjectWeekDataAction(weekData));
    if (materialWeekData) dispatch(setMaterialWeekDataAction(materialWeekData));
  };
};

export const projectEstimateSet = (projectId) => {
  return async (dispatch, getState) => {
    const { tab } = getState().manufacturing;

    let projectTree;
    let projectMaterialsTree;
    let projectPlanRelations;

    await Promise.allSettled([
      tab === WORKS_TAB_ID &&
        apiEstimateTree(projectId, WORKS_TAB_ID).then(
          (res) => (projectTree = res)
        ),
      tab === MATERIALS_TAB_ID &&
        apiEstimateTree(projectId, MATERIALS_TAB_ID).then(
          (res) => (projectMaterialsTree = res)
        ),
      apiGetPlanRelations({ building_id: projectId }).then(
        (res) => (projectPlanRelations = res.results)
      ),
    ]);

    if (!projectTree && !projectMaterialsTree) return;

    if (projectTree)
      dispatch(
        projectEstimateSetAction({
          key: `${projectId}_${WORKS_TAB_ID}`,
          value: projectTree,
        })
      );

    if (projectMaterialsTree)
      dispatch(
        projectEstimateSetAction({
          key: `${projectId}_${MATERIALS_TAB_ID}`,
          value: projectMaterialsTree,
        })
      );

    if (projectPlanRelations)
      dispatch(
        addIntervalLinkAction({ projectId, arrows: projectPlanRelations })
      );
  };
};

export const dropLoadedChartMonths = () => (dispatch) => {
  dispatch(dropLoadedChartMonthsAction());
};

export const setManufacturingMonthMarkers = (markers) => (dispatch) => {
  dispatch(setManufacturingMonthMarkersAction(markers));
};

export const setActiveBranch =
  ({ index, eventFrom }) =>
  (dispatch) => {
    dispatch(setActiveBranchAction({ index, eventFrom }));
  };

export const updateDiagramFilters =
  ({ name, value }) =>
  (dispatch) => {
    dispatch(updateDiagramFiltersAction({ name, value }));
  };

export const startDragInterval =
  ({ projectId, intervalId, startDate }) =>
  (dispatch) => {
    dispatch(startDragIntervalAction({ projectId, intervalId, startDate }));
  };

export const stopDragInterval =
  ({ projectId, intervalId }) =>
  (dispatch) => {
    dispatch(stopDragIntervalAction({ projectId, intervalId }));
  };

export const enableIntervalLinking = () => (dispatch) => {
  dispatch(enableIntervalLinkingAction());
};

export const disableIntervalLinking = () => (dispatch) => {
  dispatch(disableIntervalLinkingAction());
};

export const addIntervalLink =
  (projectId, { from_interval, to_interval }) =>
  async (dispatch) => {
    const tempId = Math.random();
    dispatch(
      addIntervalLinkAction({
        projectId,
        arrows: { from_interval, to_interval, id: tempId },
      })
    );
    const newRelationResponse = await apiCreatePlanRelation(projectId, {
      from_interval,
      to_interval,
    });
    if (!newRelationResponse) {
      dispatch(deleteArrowAction({ projectId, arrowId: tempId }));
    } else {
      dispatch(
        updateArrowAction({
          projectId,
          arrowId: tempId,
          data: newRelationResponse,
        })
      );
    }
  };

export const loadArrows = (projectId) => async (dispatch) => {
  const arrows = await apiGetPlanRelations({ building_id: projectId });
  if (!arrows || !arrows?.results) return;
  dispatch(setArrowsAction({ projectId, arrows: arrows.results }));
};

export const deleteArrow =
  ({ projectId, arrowId, type }) =>
  async (dispatch) => {
    const deleteRelationOk = await apiDeletePlanRelation(arrowId);
    if (deleteRelationOk) {
      dispatch(deleteArrowAction({ projectId, arrowId }));
      if (type === RelationFromTypes.to) {
        dispatch(removeToRelationAction(arrowId));
      }
      if (type === RelationFromTypes.from) {
        dispatch(removeFromRelationAction(arrowId));
      }
      message.success("Связь удалена");
    }
    return deleteRelationOk;
  };

export const loadIntervalRelations =
  ({ projectId, intervalId }) =>
  async (dispatch) => {
    let relationsFromCurrentInterval;
    let relationsToCurrentInterval;

    await Promise.allSettled([
      apiGetPlanRelations({
        from_interval: intervalId,
        building_id: projectId,
      }).then((res) => (relationsFromCurrentInterval = res)),
      apiGetPlanRelations({
        to_interval: intervalId,
        building_id: projectId,
      }).then((res) => (relationsToCurrentInterval = res)),
    ]);

    if (relationsFromCurrentInterval?.results)
      dispatch(
        setRelationsFromCurrentIntervalAction(
          relationsFromCurrentInterval.results
        )
      );
    if (relationsToCurrentInterval?.results)
      dispatch(
        setRelationsToCurrentIntervalAction(relationsToCurrentInterval.results)
      );
  };

export const clearLoadedIntervalRelations = () => (dispatch) => {
  dispatch(setRelationsFromCurrentIntervalAction([]));
  dispatch(setRelationsToCurrentIntervalAction([]));
  dispatch(loadAvailableSectionsAction([]));
  dispatch(loadAvailableIntervalsAction([]));
};

export const loadRelationAvailableSections =
  (projectId) => async (dispatch) => {
    const sections = await loadSectionsForRelations(projectId);
    if (!sections) return [];
    dispatch(loadAvailableSectionsAction(sections));
  };

export const loadRelationAvailableIntervals =
  ({ projectId, sectionId, start_at__gte, start_at__lte, intervalId }) =>
  async (dispatch) => {
    const intervals = await loadIntervalsForRelations({
      projectId,
      sectionId,
      start_at__gte,
      start_at__lte,
    });
    if (!intervals?.results) return [];
    dispatch(
      loadAvailableIntervalsAction(
        intervals.results.filter((x) => +x.id !== +intervalId)
      )
    );
  };

export const clearRelationAvailableIntervals = () => (dispatch) =>
  dispatch(loadAvailableIntervalsAction([]));

export const bulkCreateRelations =
  ({ intervalId, relationCandidates, type, projectId }) =>
  async (dispatch) => {
    const response = await apiBulkCreateRelations(
      getRelationsForBulkCreate({ intervalId, relationCandidates, type })
    );
    if (!response) return;
    if (type === RelationFromTypes.to) {
      dispatch(pushToRelationsAction(response));
    }
    if (type === RelationFromTypes.from) {
      dispatch(pushFromRelationsAction(response));
    }
    dispatch(addIntervalLinkAction({ projectId, arrows: response }));
    message.success("Связи успешно добавлены");
  };

export const updateRelationDayDelay =
  ({ relationId, delayDay, type, projectId }) =>
  async (dispatch) => {
    const response = await apiPatchRelation({
      relationId,
      data: { delay_day: delayDay },
    });
    if (!response) return;
    if (type === RelationFromTypes.to) {
      dispatch(updateToRelationAction({ relationId, data: response }));
    }
    if (type === RelationFromTypes.from) {
      dispatch(updateFromRelationAction({ relationId, data: response }));
    }
    dispatch(
      updateArrowAction({ projectId, arrowId: relationId, data: response })
    );
    message.success("Перерыв успешно обновлён");
  };

export const highlightRelatedIntervals = ({ intervalId, projectId }) => (dispatch) => {
  dispatch(highlightRelatedIntervalsAction({ intervalId, projectId }))
}

export const dropHighlightRelatedIntervals = () => (dispatch) => {
  dispatch(dropHighlightRelatedIntervalsAction())
}
export const setArrowHash = (hash) => (dispatch) => {
  dispatch(setArrowHashAction(hash))
}