import React from "react";
import styles from "../../../../Manufacturing.module.scss";
import moment from "moment";
import {ICalendarBackgroundProps} from '../../CalendarBackground'
import {useUnitMultiplier} from '../../../../hooks/useUnitMultiplier'

const YearBackground: React.FC<ICalendarBackgroundProps> = ({ year, unitHeight }) => {
  const unitMultiplier = useUnitMultiplier()
  return (
    <div className={styles.weeksLine} style={{ height: `${unitHeight * 3}rem` }}>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((monthId, index) => {
        const monthMoment = moment()
          .year(+year)
          .month(monthId);

        const today = moment().isSame(monthMoment, 'month')
          ? moment().date()
          : undefined;

        return (
          <React.Fragment key={index}>
            <div
              className={styles.weekBackground}
              style={{
                left: `${index * unitMultiplier}rem`,
                width: `${unitMultiplier}rem`,
                backgroundColor: index % 2 === 0 ? "rgba(235, 235, 235, 0.3)" : "transparent",
              }}
            />
            {today !== undefined && (
              <div
                className={styles.currentLine}
                style={{
                  left: `${(index + today / monthMoment.daysInMonth()) * unitMultiplier}rem`,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(YearBackground);
