export const KS_FILTER_STATUS_PAYMENT = "pay";
export const KS_FILTER_STATUS_TO_DELIVERY = "dly";

export enum KsStatusesEnum {
  "payment" = "К оплате",
  "to delivery" = "К сдаче",
}

export const KS_STATUSES_FILTER_OPTIONS = [
  {
    id: KS_FILTER_STATUS_PAYMENT,
    name: KS_FILTER_STATUS_PAYMENT,
    label: KsStatusesEnum["payment"],
  },
  {
    id: KS_FILTER_STATUS_TO_DELIVERY,
    name: KS_FILTER_STATUS_TO_DELIVERY,
    label: KsStatusesEnum["to delivery"],
  },
];
