import React, { useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";

import styles from "./TasksPersonsSelect.module.scss";

import Icon from "../../../../UI/Icon";
import arrowDownSelect from "images/icons/arrowDownSelect.svg";
import { IIdAndName } from "../../../../../types/idAndName";
import {IdNameLabelType} from '../../../../../types/IdNameLabelType'

export interface ITasksPersonsSelectProps {
  options: IdNameLabelType[];
  defaultValue?: string;
  name: string;
  placeholder?: string;
  input: any;
  className?: string;
  optionsBlockClassName?: string;
  label: string;
  disabled?: boolean;
  meta?: any;
  icon?: any;
}

const TasksPersonsSelect: React.FC<ITasksPersonsSelectProps> = ({
  options = [],
  defaultValue = "",
  name,
  placeholder = "",
  input,
  className,
  optionsBlockClassName,
  label,
  disabled = false,
  meta,
  icon = arrowDownSelect,
}) => {
  const wrapperRef = useRef<HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState();
  const [filteredOptions, setFilteredOptions] = useState(options);
  const clearFilter = useCallback(() => {
    setFilter(undefined);
    setFilteredOptions(options);
  }, []);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options, input?.value]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as HTMLElement)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleFilterChange = (e) => {
    const newFilter = e.target.value;
    setFilter(newFilter);
    setFilteredOptions(options.filter((item) => item.name.toLowerCase().indexOf(newFilter?.toLowerCase()) !== -1));
  };

  const handleElementClick = (item: IIdAndName) => {
    let newState = input.value;
    if (newState.indexOf(`,${item.id},`) === -1) {
      newState = newState.concat(`,${item.id},`);
    } else {
      newState = newState.replace(`,${item.id},`, "");
    }

    input && input.onChange(newState);
  };

  const removeSelected = (item: IdNameLabelType) => {
    input && input.onChange(input.value.replace(`,${item.id},`, ""));
  };

  const setToggle = () => setIsOpen((prevState) => !prevState);

  return (
    <div>
      {label && <label>{label}</label>}
      {input.value.length > 0 && (
        <div className={styles.bubbles}>
          {options.map(
            (item) =>
              input.value.indexOf(`,${item.id},`) !== -1 && (
                <div className={styles.bubble} key={item.id}>
                  <span>{item.name}</span>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={styles.removeIcon}
                    onClick={() => removeSelected(item)}
                  >
                    <path
                      d="M6.33789 7.75903L2.50391 11.593L0.85498 9.94409L4.68994 6.11011L0.85498 2.27612L2.50391 0.626709L6.33789 4.46069L10.1719 0.628174L11.821 2.27612L7.98706 6.11011L11.821 9.94409L10.1719 11.593L6.33789 7.75903Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )
          )}
        </div>
      )}
      <div
        className={cn(styles.select, className, {
          [styles.isOpen]: isOpen && !disabled,
          [styles.disabled]: disabled,
        })}
        onClick={() => setIsOpen(true)}
        ref={wrapperRef}
      >
        <input
          type=""
          placeholder={placeholder}
          value={filter || ""}
          onChange={handleFilterChange}
          className={styles.filterInput}
        />
        <div className={styles.controls}>
          {filter?.length > 0 && (
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.clearFilterIcon}
              onClick={clearFilter}
            >
              <path
                d="M5.77051 5.69238L0.577148 10.8828L5.77051 5.69238ZM5.77051 5.69238L0.577148 0.5L5.77051 5.69238ZM5.77051 5.69238L10.9629 0.5L5.77051 5.69238ZM5.77051 5.69238L10.9629 10.8828L5.77051 5.69238Z"
                stroke="#707070"
              />
            </svg>
          )}
          <div onClick={setToggle}>
            <Icon icon={icon} className={cn(styles.arrow, { [styles.arrowReverse]: isOpen && !disabled })} />
          </div>
        </div>
        {!disabled && (
          <div
            className={cn(styles.optionsBlock, optionsBlockClassName, {
              [styles.isOpen]: isOpen,
            })}
          >
            {filteredOptions &&
              filteredOptions.length > 0 &&
              filteredOptions.map((item) => (
                <div key={item.id} className={styles.option} onClick={() => handleElementClick(item)}>
                  <div className={styles.checkbox}>
                    {input.value?.indexOf(`,${item.id},`) !== -1 && (
                      <svg
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.check}
                      >
                        <path d="M1.49023 7.93304L6.50723 12.95L14.5072 1.05005" stroke="#707070" strokeWidth="1.6" />
                      </svg>
                    )}
                  </div>
                  <span>{item.name}</span>
                </div>
              ))}
            {(!filteredOptions || (filteredOptions && filteredOptions.length === 0)) && (
              <div className={styles.option}>Нет данных</div>
            )}
          </div>
        )}
      </div>
      {meta?.touched && meta?.error && <div className={cn(styles.errorMessage)}>{meta.error}</div>}
    </div>
  );
};

export default React.memo(TasksPersonsSelect);
