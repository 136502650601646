import axios from 'axios';
import { message } from 'antd';

export function apiGetAggregations(objectId) {
  return axios
    .get(`building/${objectId}/estimate/aggregations/`)
    .then(
      ({ data }) => data,
      error => {
        console.log(error);
      },
    ).catch(e => console.log(e));
}
export function apiChangeStatusSections(objectId, data) {
 return axios.post(`/building/${objectId}/estimate/change_status/`, data)
    .then((res) => {
      console.log(res);
      message.success(`Выбранные разделы ${data?.status === 'confirmed' ? 'утверждены' : 'отклонены'}`);
    }).catch(e => console.error(e));
}