import axios from "axios";
import { createSelector } from "reselect";
import { message } from "antd";
import { messageErrorHandler } from "utils/errorHandler";
import { compose } from "redux";
import { errorCatcher } from "../../../../../utils/errorCatcher";

const moduleName = "bills";
const GET_LIST = `${moduleName}/GET_LIST`;
const SET_LIST = `${moduleName}/SET_LIST`;
const LOAD_BILL = `${moduleName}/LOAD_BILL`;
const GET_OPENED_BILL = `${moduleName}/GET_OPENED_BILL`;
const SET_OPENED_BILL = `${moduleName}/SET_OPENED_BILL`;
const SET_MORE_BILLS = `${moduleName}/SET_MORE_BILLS`;
const CHANGE_BILL_DATE = "bills/CHANGE_BILL_DATE";
const CHANGE_PAYMENT_DATE = "bills/CHANGE_PAYMENT_DATE";
const ADD_FILE = "bills/ADD_FILE";
const DELETE_FILE = "bills/DELETE_FILE";
const CHANGE_BILL_NUMBER = "bills/CHANGE_BILL_NUMBER";
const SET_LIST_LOADING = "bills/SET_LIST_LOADING";
const CHANGE_BILL_PAYMENT = "bills/CHANGE_BILL_PAYMENT";

export const DEFAULT_LIMIT_BILL = 10;

const initialState = {
  list: null,
  listLoading: true,
  openedBill: null,
  openedBillLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_BILL:
      return {
        ...state,
        listLoading: true,
      };
    case GET_OPENED_BILL:
      return {
        ...state,
        openedBill: null,
        openedBillLoading: true,
      };

    case SET_OPENED_BILL:
      return {
        ...state,
        openedBill: payload,
        openedBillLoading: false,
      };

    case GET_LIST:
      return {
        ...state,
        openedBill: null,
        openedBillLoading: true,
      };

    case SET_LIST:
      return {
        ...state,
        list: payload,
      };
    case SET_MORE_BILLS:
      return {
        ...state,
        list: {
          ...state?.list,
          results: [...state?.list?.results, ...payload?.results],
        },
      };
    case CHANGE_BILL_DATE:
      return {
        ...state,
        openedBill: {
          ...state.openedBill,
          date: payload,
        },
      };
    case CHANGE_BILL_NUMBER:
      return {
        ...state,
        openedBill: {
          ...state.openedBill,
          number: payload,
        },
      };
    case CHANGE_PAYMENT_DATE:
      const tempPayments = [...state.openedBill.payments];
      const index = tempPayments.findIndex((el) => el.id == payload.id);
      tempPayments[index] = {
        ...tempPayments[index],
        date: payload.date,
      };
      return {
        ...state,
        openedBill: {
          ...state.openedBill,
          payments: tempPayments,
        },
      };
    case ADD_FILE:
      return {
        ...state,
        openedBill: {
          ...state.openedBill,
          files: [...state.openedBill.files, ...payload],
        },
      };
    case DELETE_FILE:
      const tempFiles = [...state.openedBill.files];
      const filesIndex = tempFiles.findIndex((el) => el.id == payload);
      tempFiles.splice(filesIndex, 1);
      return {
        ...state,
        openedBill: {
          ...state.openedBill,
          files: tempFiles,
        },
      };

    case SET_LIST_LOADING:
      return {
        ...state,
        listLoading: payload,
      };
    case CHANGE_BILL_PAYMENT:
      return {
        ...state,
        openedBill: {
          ...state.openedBill,
          payments: state.openedBill.payments.map((payment) => (payment.id === payload.id ? payload : payment)),
        },
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const billsListSelector = createSelector(stateSelector, (state) => state.list);
export const billsListLoadingSelector = createSelector(stateSelector, (state) => state.listLoading);
export const openedBillSelector = createSelector(stateSelector, (state) => state.openedBill);
export const openedBillLoadingSelector = createSelector(stateSelector, (state) => state.openedBillLoading);

const setList = (list) => ({
  type: SET_LIST,
  payload: list,
});

const setOpenedList = (bill) => ({
  type: SET_OPENED_BILL,
  payload: bill,
});

const changeBillPayment = (payload) => ({ type: CHANGE_BILL_PAYMENT, payload });

export function apiGetLoadBills(config) {
  return axios.get(`/purchaser/invoices/`, config);
}

export const loadBills = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: DEFAULT_LIMIT_BILL,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };
    return async (dispatch, getState) => {
      if (!getState().bills?.list?.results?.length) {
        dispatch({ type: SET_LIST_LOADING, payload: true });
      }

      try {
        const { data } = await apiGetLoadBills(config);
        await dispatch(setList(data));
      } catch (error) {
        messageErrorHandler(error?.response?.data?.errors);
      } finally {
        dispatch({ type: SET_LIST_LOADING, payload: false });
      }
    };
  };
})();

export const loadBill = (id) => (dispatch) =>
  axios
    .get(`/purchaser/invoices/${id}/`)
    .then((res) => dispatch(setOpenedList(res.data)))
    .catch(errorCatcher);

export const saveBillNumber = (id, number) => (dispatch) => {
  axios
    .post(`/purchaser/invoices/${id}/set-number/`, { number })
    .then(() => {
      dispatch({ type: CHANGE_BILL_NUMBER, payload: number });
      message.success("Номер успешно сохранен");
    })
    .catch(errorCatcher);
};

export const markAsPaid = (invoiceId, paymentId) => (dispatch) => {
  axios
    .post(`/purchaser/invoices/${invoiceId}/payments/${paymentId}/pay/`)
    .then(() => {
      dispatch(loadBill(invoiceId));
      message.success("Платеж успешно помечен оплаченным");
    })
    .catch(errorCatcher);
};
export const loadMoreBills = (params) => {
  const config = { params: { ...params, limit: DEFAULT_LIMIT_BILL } };

  return (dispatch) => {
    axios
      .get(`purchaser/invoices`, config)
      .then((response) =>
        dispatch({
          type: SET_MORE_BILLS,
          payload: response.data,
        })
      )
      .catch((error) => console.error(error));
  };
};

export const changeBillDate = (invoiceId, date) => (dispatch) => {
  axios
    .patch(`/purchaser/invoices/${invoiceId}/change-date/`, {
      date,
    })
    .then((resp) => {
      message.success("Дата счета успешно изменена");
      dispatch({
        type: CHANGE_BILL_DATE,
        payload: date,
      });
    })
    .catch(() => {
      message.error("Дата счета не изменена");
    });
};

export const changePaymentDate = (invoiceId, paymentId, date) => (dispatch) => {
  axios
    .patch(`/purchaser/invoices/${invoiceId}/payments/${paymentId}/change-date/`, { date })
    .then((resp) => {
      message.success("Дата платежа успешно изменена");
      dispatch({
        type: CHANGE_PAYMENT_DATE,
        payload: { date, id: paymentId },
      });
    })
    .catch(() => {
      message.error("Дата платежа не изменена");
    });
};

export const addPaymentFile = (invoiceId, paymentId, file) => (dispatch) => {
  if (!file) return;
  const data = new FormData();
  data.append("file", file);
  axios
    .patch(`/purchaser/invoices/${invoiceId}/payments/${paymentId}/change-file/`, data, {
      headers: {
        "content-type": "miltipart/form-data",
      },
    })
    .then((response) => {
      message.success("Файл прикреплен");
      compose(dispatch, changeBillPayment)(response.data);
    })
    .catch(() => message.error("Файл не прикреплен"));
};

export const payPayment = (invoiceId, paymentId, callback) => () => {
  axios
    .post(`/purchaser/invoices/${invoiceId}/payments/${paymentId}/pay/`)
    .then((resp) => {
      callback && callback();
      message.success("Отмечено оплаченным");
    })
    .catch(() => message.error("Ошибка"));
};

export const attachFilesToBill = (invoiceId, files) => (dispatch) => {
  if (!files) return;
  const data = new FormData();
  files.forEach((file) => {
    data.append("files", file);
  });
  message.success("Загрузка");
  axios
    .post(`/purchaser/invoices/${invoiceId}/files/`, data, {
      headers: {
        "content-type": "miltipart/form-data",
      },
    })
    .then((resp) => {
      message.success("Файл прикреплен");
      dispatch({
        type: ADD_FILE,
        payload: resp.data,
      });
    })
    .catch(() => message.error("Файл не прикреплен"));
};

export const deleteFileFromBill = (invoiceId, idFile) => (dispatch) => {
  dispatch({
    type: DELETE_FILE,
    payload: idFile,
  });
  axios
    .delete(`/purchaser/invoices/${invoiceId}/files/${idFile}/`)
    .then(() => {
      message.success("Файл удален");
    })
    .catch(() => message.error("Файл не удален"));
};
