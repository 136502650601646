import React from 'react'
import { deleteIntervalFile, getIntervalData, getIntervalFiles, getIntervalsList, postIntervalFile } from 'redux/modules/common/building/processApi';
import { intervalModulesTypes, IplanIntervalListItemResponse, IplanIntervalResponse } from 'redux/modules/common/building/processTypes';
import { errorCatcher } from 'utils/errorCatcher';

interface Iprops {
    isOpen: boolean;
    buildingId: number;
    expenditureId: number;
    dateStart: string;
    dateEnd: string;
    activeModule: intervalModulesTypes;
}

export const useModalIntervalData = ({
    isOpen,
    buildingId,
    expenditureId,
    dateEnd,
    dateStart,
    activeModule
}: Iprops) => {
    const [list, setList] = React.useState<IplanIntervalListItemResponse[] | null>(null);
    const [intervalId, setIntervalId] = React.useState<null | number>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState<IplanIntervalResponse | null>(null);
    const [files, setFiles] = React.useState([]);

    React.useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            getIntervalsList({ buildingId, expenditureId, activeModule, dateEnd, dateStart })
                .then((resp) => {
                    setList(resp.data);
                    setIsLoading(false);
                });
        }
    }, [isOpen, expenditureId, buildingId]);

    const intervalClickHandler = (id: number) => {
        setIsLoading(true);
        setIntervalId(id);
        getIntervalFiles({ buildingId, expenditureId, intervalId: id, activeModule }).then((resp) => {
            setFiles(resp.data.results);
        });
        intervalDataFetcher(id)
    };

    React.useEffect(() => {
        if (list && list.length === 1) {
            setIsLoading(true);
            setIntervalId(list[0].id);
            getIntervalFiles({ buildingId, expenditureId, intervalId: list[0].id, activeModule }).then(
                (resp) => {
                    setFiles(resp.data.results);
                }
            );
            intervalDataFetcher(list[0].id)
        }
    }, [list]);

    const intervalDataFetcher = (intervalId: number) => {
        getIntervalData({ buildingId, expenditureId, intervalId, activeModule })
            .then((resp) => {
                setData(resp.data);
            })
            .catch(errorCatcher)
            .finally(() => setIsLoading(false));
    }

    const clearIntervalDataHandler = () => {
        setData(null);
        setIntervalId(null);
        setList(null);
        setFiles([]);
    };

    const backToIntervalsListHandler = () => {
        setData(null);
        setIntervalId(null);
    }

    const fileUploadHandler = (file: File) => {
        postIntervalFile({ buildingId, expenditureId, activeModule, intervalId: data?.id!, file })
            .then((resp) => {
                getIntervalFiles({ buildingId, expenditureId, intervalId: data?.id!, activeModule }).then(
                    (resp) => {
                        setFiles(resp.data.results);
                    }
                );
            });
    };

    const fileRemoveHandler = (fileId: number) => {
        deleteIntervalFile({ buildingId, expenditureId, activeModule, intervalId: data?.id!, fileId }).then((resp) => {
            getIntervalFiles({ buildingId, expenditureId, intervalId: data?.id!, activeModule }).then(
                (resp) => {
                    setFiles(resp.data.results);
                }
            );
        });
    };

    return {
        fileRemoveHandler,
        fileUploadHandler,
        clearIntervalDataHandler,
        intervalDataFetcher,
        intervalClickHandler,
        backToIntervalsListHandler,
        list,
        intervalId,
        isLoading,
        data,
        files
    }
}
