import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { objectsSelector } from "../../../../redux/modules/common/building/projects";

import ButtonBase from "components/UI/atoms/ButtonBase";
import ProjectsTable from "./ProjectsTable/ProjectsTable";

import styles from "./ProjectsSettings.module.scss";


function ProjectSettings() {
  const objects = useSelector(objectsSelector);
  const history = useHistory();

  return (
    <>
      <div className={styles.headline}>
        <div className={styles.counter}>Проектов: {objects?.count}</div>
        <ButtonBase
          buttonPrimaryEmpty
          className={styles.addButtonClass}
          onClick={() => history.push(`project/new/passport`)}
        >
          Создать проект
        </ButtonBase>
      </div>
      <ProjectsTable />
    </>
  );
}

export default ProjectSettings