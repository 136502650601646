import { generateRandomId } from "../../../../../../utils/generateRandomId";
import { parseFileExtension } from "../../../../../../utils/parseFileFormat";

export const serializeFile = (file, monthName, year) => {
  const fileExtension = file.extension || parseFileExtension(new URL(file.url).pathname);
  return {
    id: generateRandomId(),
    name: `Табель за ${monthName} ${year}.${fileExtension}`,
    file: file.url,
  };
};