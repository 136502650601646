import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import cn from "classnames";

import { HALF_MONTH, MONTH, YEAR, WEEK } from "redux/modules/common/building/manufacturing/manufacturing";
import {
  chartViewModeSelector,
  materialsTreeSelector,
  treeSelector,
  isLoadingChartTreeSelector,
} from "../../../redux/modules/common/building/manufacturing/selectors";

import ManufacturingControls from "../Manufacturing/components/ManufacturingControls/ManufacturingControls";
import ProjectsTree from "../Manufacturing/components/ProjectsTree/ProjectsTree";
import BigLegenda from "../Manufacturing/components/BigLegenda";
import Month from "../Manufacturing/components/Month/Month";
import CalendarBackground from "../Manufacturing/components/CalendarBackground/CalendarBackground";
import CalendarDateLine from "../Manufacturing/components/CalendarDateLine/CalendarDateLine";
import EmptyPlaceholder from "../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import TemplateSimple from "../../UI/templates/TemplateSimple";
import { Spinner } from "../../UI/Spinner/Spinner";
import ForbiddenPage from "components/routes/components/ForbiddenPage/ForbiddenPage";
import DiagramActions from "../Manufacturing/components/DiagramActions/DiagramActions";

import { WORKS_TAB_ID } from "../Manufacturing/constants";
import {
  VIEW_MANUFACTURING_CHART_MATERIALS,
  VIEW_MANUFACTURING_CHART_WORKS,
} from "constants/permissions/manufacturingPermissions";

import { useUnitMultiplier } from "../Manufacturing/hooks/useUnitMultiplier";
import { useProjectTree } from "./hooks/useProjectTree";
import {
  spittingTreeGenerationFn,
  checkIsMonthBranchShownAsSection,
  checkIsMonthBranchShownAsExpenditure,
  checkIsShownSectionPlan,
} from "./utils";
import { getDaysInYear, getMonthInfo, getWeeksInYear } from "../Manufacturing/utils";
import { useCalendarScroll } from "../Manufacturing/hooks/useCalendarScroll";
import { useTabsWithPermissions } from "../Manufacturing/hooks/useTabsWithPermissions";
import { useSpittingTree } from "../Manufacturing/hooks/useSpittingTree";
import usePermission from "hooks/usePermission";

import scheduleIcon from "../../../images/icons/navigation/scheduleIcon.svg";

import styles from "./ManufacturingProduct.module.scss";
import {Xwrapper} from 'react-xarrows'

const ManufacturingProduct = () => {
  const haveViewWorksTabPermission = usePermission(VIEW_MANUFACTURING_CHART_WORKS);
  const haveViewMaterialsTabPermission = usePermission(VIEW_MANUFACTURING_CHART_MATERIALS);
  const chartViewMode = useSelector(chartViewModeSelector);
  const isLoadingChartTree = useSelector(isLoadingChartTreeSelector);
  const currentDay = moment().date();
  const currentMonth = moment().month();
  const { objectId: projectId } = useParams();
  const worksTree = useSelector(treeSelector);
  const materialsTree = useSelector(materialsTreeSelector);

  const { tab } = useTabsWithPermissions({ haveViewWorksTabPermission, haveViewMaterialsTabPermission });

  const projectTree = tab === WORKS_TAB_ID ? worksTree : materialsTree;

  const [expandedBranches, setExpandedBranches] = useState(new Set());
  const [month, setMonth] = useState(() => getMonthInfo(currentMonth));
  const [year, setYear] = useState(moment().format("YYYY"));

  const startWeek = 1;
  const endWeek = getWeeksInYear(year);

  const unitMultiplier = useUnitMultiplier();

  const daysInYear = getDaysInYear(year);
  const weeksInYear = getWeeksInYear(year);

  const { generateSpittingTree, isGeneratingTree, spittingTree } = useSpittingTree({
    spittingTreeGenerationFn,
    spittingTreeFnArgs: { projectTree, tab, expandedBranches },
  });

  const { toggleBranch, checkIsExpandedBranchId } = useProjectTree({
    tab,
    projectId,
    year,
    month,
    startWeek,
    endWeek,
    expandedBranches,
    setExpandedBranches,
    generateSpittingTree,
  });

  const containerRef = useRef(null);
  const calendarRef = useRef(null);

  const { computedMonthMarkers, handleDateChange, scrollCalendar } = useCalendarScroll({
    calendarRef,
    month,
    year,
    setMonth,
    setYear,
    projectId,
  });

  useEffect(generateSpittingTree, [projectTree]);

  const checkIsCheckboxTrue = useCallback((branch) => checkIsExpandedBranchId(branch.id), [checkIsExpandedBranchId]);

  const checkIsCheckboxVisible = useCallback((branch) => branch.lvl < 3 && branch.childCount > 0, []);

  const runningLineWidthRem =
    (chartViewMode === WEEK
      ? weeksInYear * 7
      : chartViewMode === MONTH || chartViewMode === HALF_MONTH
        ? daysInYear
        : chartViewMode === YEAR
          ? 12
          : 0) * unitMultiplier;

  if (!haveViewWorksTabPermission && !haveViewMaterialsTabPermission) {
    return (
      <TemplateSimple>
        <ForbiddenPage />
      </TemplateSimple>
    );
  }

  return (
    <TemplateSimple contentClassName={styles.template}>
      {!isLoadingChartTree && !isGeneratingTree && projectTree?.length === 0 ? (
        <EmptyPlaceholder img={scheduleIcon} />
      ) : isGeneratingTree || isLoadingChartTree ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          <ManufacturingControls
            year={year}
            month={month}
            handleDateChange={handleDateChange}
            scrollCalendar={scrollCalendar}
            haveViewWorksTabPermission={haveViewWorksTabPermission}
            haveViewMaterialsTabPermission={haveViewMaterialsTabPermission}
          />
          <div className={styles.content} ref={containerRef}>
            <div
              ref={calendarRef}
              className={cn(styles.calendar, "diagram_calendar")}
              style={{ height: `${spittingTree.length * 3}rem` }}
            >
              <CalendarDateLine year={year} />
              <ProjectsTree
                spittingTree={spittingTree}
                checkIsExpandedBranchId={checkIsExpandedBranchId}
                toggleBranch={toggleBranch}
                type={tab}
                checkIsCheckboxTrue={checkIsCheckboxTrue}
                checkIsCheckboxVisible={checkIsCheckboxVisible}
              />
              <CalendarBackground year={year} unitHeight={spittingTree.length} />
              <Xwrapper>
                <Month
                  tree={spittingTree}
                  year={year}
                  month={month}
                  today={currentMonth === month.id ? currentDay : -1}
                  startWeek={startWeek}
                  endWeek={endWeek}
                  monthMarkers={computedMonthMarkers}
                  type={tab}
                  checkIsMonthBranchShownAsSection={checkIsMonthBranchShownAsSection}
                  checkIsMonthBranchShownAsExpenditure={checkIsMonthBranchShownAsExpenditure}
                  checkIsShownSectionPlan={checkIsShownSectionPlan}
                  isGeneratingSpittingTree={isGeneratingTree}
                />
              </Xwrapper>
              <div className={styles.legendaWrapper}>
                <div className={styles.legenda}>
                  <BigLegenda type={tab} />
                </div>
              </div>
              {tab === WORKS_TAB_ID && (
                <div className={styles.diagramActionsWrapper}>
                  <DiagramActions />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </TemplateSimple>
  );
};

export default ManufacturingProduct;
