import { MatchingStatusesEnum } from "../MatchingIcon/MatchingIcon";
import { IMatchingInfo } from "./MatchingModal";

export const getMatchingType = (type: string) => {
    if (type === "service") return "services";
    if (type === "out_of_estimate") return "out-of-estimate";
    return type;
};

export const getMatchingStatus = (info: IMatchingInfo) => {
    if (!info.estimate_expenditure) return MatchingStatusesEnum.outOfEstimate;
    if (info.created_from_estimate) return MatchingStatusesEnum.auto;
    return MatchingStatusesEnum.manual;
}