import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import {
  changeExpenditure,
  createExpenditure,
} from "../../../../../../../../../redux/modules/common/building/sections/sections";

import Modal from "../../../../../../../../UI/atoms/Modal";
import AddExpenditureForm from "../../../../../../../../UI/ExpenditureForm/ExpenditureForm";

import styles from "./ManageExpenditureModal.module.scss";
import { ESTIMATE_STATES_IDS } from "../../../../../../constants";
import { loadContracts } from "../../../../../../../../../redux/modules/common/building/aggregations";

const serializeFormValues = ({ count, ...values }) => ({
  ...values,
  draft_count: count,
});

const serializeInitialValues = ({ draft_count, ...values }) => ({
  ...values,
  count: draft_count,
});

export const VARIANTS = {
  EDIT: "edit",
  ADD: "add",
};

const ManageExpenditureModal = ({
  isOpen,
  onClose,
  buildingId,
  sectionId,
  activeEstimateStateId,
  variant,
  initialValues,
}) => {
  const dispatch = useDispatch();

  const submitAddExpenditureForm = useCallback(
    (values) => {
      onClose();

      if (variant === VARIANTS.ADD) {
        compose(dispatch, createExpenditure)(
          buildingId,
          sectionId,
          serializeFormValues(values),
          activeEstimateStateId
        ).then(() => compose(dispatch, loadContracts)(buildingId));
        return;
      }

      compose(dispatch, changeExpenditure)(
        {
          building: buildingId,
          section: sectionId,
          estimateState:
            activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION ? values.current_state : activeEstimateStateId,
        },
        serializeFormValues(values)
      );
    },
    [onClose, sectionId, activeEstimateStateId, variant]
  );

  const serializedInitialValues = useMemo(
    () => (initialValues ? serializeInitialValues(initialValues) : null),
    [initialValues]
  );

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={onClose}
      title={`${variant === VARIANTS.ADD ? "Добавить" : "Редактировать"} позицию`}
    >
      <div className={styles.content}>
        <AddExpenditureForm submit={submitAddExpenditureForm} initialValues={serializedInitialValues} isEstimate />
      </div>
    </Modal>
  );
};

export default React.memo(ManageExpenditureModal);
