import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";

import DynamicInput from "../DynamicInput";

import styles from "./DynamicTitleInput.module.scss";

export const SIZES = { SMALL: "small", NORMAL: "normal", BIG: "big" };

const DynamicTitleInput = ({ title, onChange, size = SIZES.NORMAL, placeholder = "Введите название", isDisabled }) => {
  const [changedName, setChangedName] = useState("");

  const handleSetChangedName = useCallback((e) => setChangedName(e.target.value), []);
  const onBlur = useCallback((e) => onChange(e.target.value), [onChange]);

  useEffect(() => {
    setChangedName(title);
  }, [title]);

  return (
    <DynamicInput
      placeholder={placeholder}
      className={cn("autosize-input", styles.dynamicInput, styles[size])}
      maxLength={100}
      value={changedName}
      onChange={handleSetChangedName}
      onBlur={onBlur}
      disabled={isDisabled}
    />
  );
};

export default React.memo(DynamicTitleInput);
