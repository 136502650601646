import React, { useState } from "react";
import styles from "./RublePopover.module.scss";
import { splitThousands } from "../../../../../../utils/splitThousands";
import { COLORS_RUBLE as COLORS } from "../constants";
import PopoverChart from "../PopoverChart/PopoverChart";
import {WORKS_TAB_ID} from '../../../constants'

const RublePopover = ({ indicators, type }) => {
  let finalAmount;
  let finalCompleted;
  let finalAccepted;
  let finalToPay;

  if (type === WORKS_TAB_ID) {
    const { estimate_amount, work_accepted, work_completed, to_payed_amount } = indicators;
    finalAmount = Number(estimate_amount) || 0;
    finalCompleted = Number(work_completed) || 0;
    finalAccepted = Number(work_accepted) || 0;
    finalToPay = Number(to_payed_amount) || 0;
  } else {
    const {
      total_sum_using_amount,
      confirm_using_closed_amount,
      confirm_sum_using_amount,
      estimate_amount,
    } = indicators;
    finalAmount = Number(estimate_amount) || 0;
    finalCompleted = Number(total_sum_using_amount) || 0;
    finalAccepted = Number(confirm_sum_using_amount) || 0;
    finalToPay = Number(confirm_using_closed_amount) || 0;
  }

  const dataAll = {
    name: "Бюджет",
    value: finalAmount,
  };


  const data = [
    {
      name: "Выполнено",
      value: finalCompleted,
      key: "work_completed",
      percent: dataAll.value && Math.round((finalCompleted * 100) / dataAll.value),
    },
    {
      name: "Принято",
      value: finalAccepted,
      key: "work_accepted",
      percent: dataAll.value && Math.round((finalAccepted * 100) / dataAll.value),
    },
    {
      name: "К оплате",
      value: finalToPay,
      key: "to_payed_amount",
      percent: dataAll.value && Math.round((finalToPay * 100) / dataAll.value),
    },
  ];

  const [hover, setHover] = useState(false);
  const [Y, setY] = useState(0);

  const onHover = (e) => {
    setHover(true);
    setY(e?.nativeEvent?.y || 0);
  };

  const popupStyles =
    window.innerHeight - (Y + 336) < 0
      ? { transform: "translateY(-51%)", paddingBottom: "1rem" }
      : { transform: "translateY(54%)", paddingTop: ".5rem" };

  return (
    <div className={styles.ruble} onMouseEnter={onHover} onMouseLeave={() => setHover(false)}>
      ₽
      {hover && (
        <div
          className={styles.rublePopover}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={popupStyles}
        >
          <div className={styles.rublePopoverContainer}>
            <span className={styles.rublePopoverTitle}>Прогресс выполнения</span>
            <div className={styles.rublePopoverItem}>
              <span>Бюджет:</span>
              <span>{splitThousands(finalAmount)}</span>
            </div>
            <PopoverChart dataAll={dataAll} chartData={data} colors={COLORS} />
            <div className={styles.legend}>
              {data.map((x, index) => (
                <React.Fragment key={index}>
                  <div className={styles.rublePopoverItemMarker}>
                    <div className={styles.square} style={{ backgroundColor: COLORS[x.key] }} />
                    <span>{x.name}:</span>
                  </div>
                  <span>{`${x.value > 0 && x.percent < 1 ? '<1' : x.percent}%`}</span>
                  <span>{splitThousands(x.value)}</span>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RublePopover;
