import React from "react";

import ButtonBase from "components/UI/atoms/ButtonBase";
import Amounts from "components/UI/molecules/Amounts";

import styles from "./index.module.scss";
import ReactTextareaAutosize from "react-textarea-autosize";

const Footer = ({ canEditPackingList, productsHaveChanges, onAccept, packingList, commentChangeHandler, comment }) => {

  return (
    <footer className={styles.footer}>
      {packingList.calculation && <Amounts
        productsAmount={packingList.calculation?.amount}
        taxAmount={packingList.calculation?.amount_vat}
        totalAmount={packingList.calculation?.amount_total}
        deliveryAmount={packingList.calculation?.amount_delivery}
        taxIsInclude={!!packingList.calculation?.amount_vat}
      />}
      <ReactTextareaAutosize
        onChange={commentChangeHandler}
        value={comment}
        className={styles.commentTextarea}
        disabled={!canEditPackingList}
        maxLength={510}
        minRows={3}
        maxRows={10}
        placeholder={"Комментарий закупщика"}
      />
      {canEditPackingList && (
        <div className={styles.buttons}>
          <ButtonBase medium onClick={onAccept}>
            Принять {productsHaveChanges && "с изменениями"}
          </ButtonBase>
        </div>
      )}
    </footer>
  );
};

export default React.memo(Footer);