import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  dataStateSelector,
  deleteUser,
  getUsersList,
  loadUsers,
  loadingStateSelector,
  lockUser,
  paginationStateSelector,
  pushNewUser,
  setPaginationState,
  setUsersState,
} from "redux/modules/common/settings/ourCompanyTabs/employees";

import AlertPopup from "./../../../../../elements/popups/AlertPopup/AlertPopup";
import Icon from "components/UI/Icon";
import { TableOslaLocal } from "components/UI/TableOslaCustom/TableOslaLocal";
import { TableHeader } from "components/UI/TotoRowTable/TableHeader/TableHeader";
import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import LabeledSwitch from "components/UI/atoms/LabeledSwitch";
import SearchFilterTable from "components/UI/atoms/SearchFilterTable";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";

import UserModal from "./UserModal";
import { alertIcon } from "./alertIcon";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import EditIcon from "images/icons/EditIcon";
import Line from "images/icons/Line";
import lockIcon from "images/icons/lock-gray-icon.svg";
import settingGear from "images/icons/navigation/settingGear.svg";
import unlockIcon from "images/icons/unlock-gray-icon.svg";

import styles from "./index.module.scss";

const Users = ({
  getUsersList,
  setPaginationState,
  loadUsers,
  lockUser,
  pushNewUser,
  entity,
  pagination,
  data,
  loadingState,
  location,
  history,
}) => {
  const dispatch = useDispatch();

  const [blockingModal, setBlockingModal] = useState(false);
  const [blockingUser, setBlockingUser] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalOpenIsEditable, setEditModalOpenIsEditable] = useState(false);

  const isLoading = Boolean(loadingState && !data.results?.length);

  useEffect(() => {
    return () => {
      dispatch(
        setUsersState(
          {
            count: 1,
            list: [],
            results: [],
          },
          true
        )
      );
    };
  }, []);

  const openEditModal = (id) => {
    setEditModalOpen(true);
    setEditModalOpenIsEditable(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const onRowClick = (row, edit = false) => {
    let formattedPathName = location.pathname;
    history.push({
      pathname: `${formattedPathName}/${row.id}`,
      state: { row: row, edit: edit },
    });
  };

  const blockHandler = (row) => {
    setBlockingModal(true);
    setBlockingUser({ id: row.id, status: !row.is_blocked });
  };

  const submitBlockHandler = () => {
    lockUser(blockingUser.id, blockingUser.status);
    closeBlockingModal();
  };

  const closeBlockingModal = () => {
    setBlockingModal(false);
    setBlockingUser({});
  };

  const deleteColumnFormatter = (cellData, row) => (
    <div className={styles.btnChange}>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRowClick(row, true);
        }}
      >
        <EditIcon />
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          blockHandler(row);
        }}
      >
        {row.is_blocked ? <Icon icon={unlockIcon} /> : <Icon icon={lockIcon} />}
      </button>
    </div>
  );

  const numberphone = (cell) => cell && cell.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "$1($2)-$3-$4-$5");

  const handleAdd = () => {
    loadUsers(null, {
      ...pagination.params,
      offset: data?.results.length,
    });
  };

  useEffect(() => {
    getUsersList(null, pagination.params);
  }, []);

  const filterHandler = async (value, field) => {
    const newPaginationParams = {
      ...pagination.params,
      [field]: value,
    };
    setPaginationState(newPaginationParams, pagination.page);
    await getUsersList(null, newPaginationParams);
  };

  const getUsersListWithPaginationParams = () => getUsersList(null, pagination.params);

  const usersResults = React.useMemo(() => {
    if (!pagination.params.is_blocked) {
      return data.results.filter((el) => !el.is_blocked);
    } else {
      return data.results.filter((el) => el.is_blocked);
    }
  }, [pagination.params.is_blocked, data.results]);

  return (
    <>
      <>
        <UserModal
          isOpen={editModalOpen}
          pushNewUser={pushNewUser}
          close={closeEditModal}
          isEditable={editModalOpenIsEditable}
        />
        <div className={`${styles.controls} ${styles.flexRow}`}>
          <div className={styles.showBlockUserSwitch}>
            <LabeledSwitch
              label="Показать заблокированных"
              onChange={() => filterHandler(!pagination.params.is_blocked, "is_blocked")}
              value={pagination.params.is_blocked}
            />
          </div>

          <ButtonBase buttonPrimaryEmpty className={styles.addButtonClass} onClick={openEditModal}>
            Добавить сотрудника
          </ButtonBase>
        </div>

        <TableOslaLocal
          data={usersResults}
          dataLoader={getUsersListWithPaginationParams}
          dataLoaderArguments={[entity.id]}
          isLoading={isLoading}
          isStaticLoader
          paginationHide={true}
          onRowClick={onRowClick}
          rowClass={styles.tableRowClass}
          tableClass={styles.tables}
          emptyPlaceholder={<EmptyPlaceholder text="Нет сотрудников" img={settingGear} />}
        >
          <TableHeader
            data={"fio"}
            formatter={(cell, row) => {
              return (
                <span>
                  {row?.last_name} {row?.first_name} {row?.middle_name}
                </span>
              );
            }}
            cellStyle={{
              fontWeight: "400",
              width: "35%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={`${styles.title} ${styles.extraTitle}`}>ФИО</div>
              <div>
                <SearchFilterTable
                  paddingClass={styles.paddingTopIcon}
                  title="ФИО"
                  setFilter={(value) => {
                    filterHandler(value, "full_name");
                  }}
                />
              </div>
            </div>
          </TableHeader>

          <TableHeader
            data={"roles"}
            formatter={(cell, row) =>
              cell[0]?.name ? (
                <div className={styles.block}>
                  {isAllModulesAllowed() ? cell[0].name : row.is_staff ? "Администратор" : "Пользователь"}
                  <Line height={29} width={2} color={"#707070"} />
                </div>
              ) : (
                <div className={styles.block}>
                  {isAllModulesAllowed() ? "Не назначена" : row.is_staff ? "Администратор" : "Пользователь"}
                  <Line height={29} width={2} color={"#707070"} />
                </div>
              )
            }
            cellStyle={{
              width: "20%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>{isAllModulesAllowed() ? "Должность" : "Роль"}</div>
            </div>
          </TableHeader>
          <TableHeader
            data={"email"}
            formatter={(cell) => (
              <div className={styles.block}>
                {cell}
                <Line height={29} width={2} color={"#707070"} />
              </div>
            )}
            cellStyle={{
              width: "20%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Почта</div>
              <SearchFilterTable
                title="Почта"
                setFilter={(value) => {
                  filterHandler(value, "email");
                }}
              />
            </div>
          </TableHeader>
          <TableHeader
            data={"phone_number"}
            formatter={numberphone}
            cellStyle={{
              width: "14%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center",
              paddingLeft: 0,
            }}
          >
            <div className={styles.table__titleBlock}>
              <div className={styles.title}>Телефон</div>
            </div>
          </TableHeader>

          <TableHeader data={"delete"} formatter={deleteColumnFormatter} cellStyle={{ width: "8%" }} />
        </TableOslaLocal>
        <ShowMoreButton
          handleAdd={handleAdd}
          allCount={data?.count}
          showedCount={data?.results?.length}
          isExists={!isLoading}
        />
      </>
      {blockingModal && (
        <AlertPopup onClose={closeBlockingModal} title="Подтвердите действие">
          <div className={styles.alert}>
            {alertIcon}
            <p>Вы действительно хотите {blockingUser.status ? "заблокировать" : "разблокировать"} сорудника?</p>
          </div>
          <div className={styles.btns}>
            <button onClick={submitBlockHandler}>Подтвердить</button>
            <button onClick={closeBlockingModal}>Отменить</button>
          </div>
        </AlertPopup>
      )}
    </>
  );
};

export default withRouter(
  connect(
    (state) => ({
      entity: state.auth.entity,
      pagination: paginationStateSelector(state),
      data: dataStateSelector(state),
      loadingState: loadingStateSelector(state),
      isPurchaser: state.auth.entity.type === "pcr",
    }),
    { getUsersList, setPaginationState, deleteUser, loadUsers, lockUser, pushNewUser }
  )(Users)
);
