import React, { useCallback } from "react";

import { Switch } from "../Switch/Switch";
import styles from "./index.module.scss";

const LabeledSwitch = ({ onChange, label, labelRight, value: isActive }) => {
  const handleActive = useCallback(() => {
    onChange(!isActive);
  }, [isActive, onChange]);

  return (
    <div className={styles.switchBlock} onClick={handleActive}>
      {label && <span className={styles.text}>{label}</span>}
      <div style={{ alignSelf: "flex" }}>
        <Switch checked={isActive} />
      </div>
      {labelRight && <span className={styles.textRight}>{labelRight}</span>}
    </div>
  );
};

export default React.memo(LabeledSwitch);
