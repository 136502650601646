import React, { useCallback, useMemo, useState } from "react";
import { compose } from "redux";

import { apiLoadBuildingMaterialSection } from "redux/modules/common/building/sections/sectionsApi";

import { InputSearchRound } from "components/UI/InputSearchRound";
import NavBar from "components/UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";
import Section from "../../../../atoms/Section";
import ButtonBase from "./../../../../../UI/atoms/ButtonBase";
import SectionChild from "./components/SectionChild";
import ProductTable from "./components/ProductTable";

import styles from "./index.module.scss";


const filterSectionsBySearch = (sections, search) =>
  sections.filter((section) => section.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
const filterEmptySections = (sections) => sections.filter((section) => section.products_count !== 0);

const Materials = ({ sections, objectId, type, products, setProducts, handleAddProducts }) => {
  const [search, setSearch] = useState("");
  const [section, setSection] = useState("");

  const loadSubSections = useCallback(
    async (sectionId) => {
      const section = await apiLoadBuildingMaterialSection(objectId, sectionId, type);
      await setSection(section);
    },
    [objectId, type]
  );

  const handleSetSection = useCallback(
    (section) => {
      loadSubSections(section.id);
    },
    [loadSubSections]
  );
  
  const filteredSections = useMemo(() => {
    return compose(filterEmptySections, filterSectionsBySearch)(sections, search);
  }, [sections, search]);
  
  const filteredSubsections = useMemo(() => {
    if (!section.subsections) return [];
    return compose(filterEmptySections, filterSectionsBySearch)(section.subsections, search);
  }, [search, section.subsections]);

  const count = filteredSubsections?.length > 0 ? filteredSubsections?.length : filteredSections.products?.length;

  return (
    <div className={styles.container}>
      <div className={styles.searchBlock}>
        <InputSearchRound
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={styles.inputSearchClassName}
        />
        <ButtonBase onClick={() => handleAddProducts()} disabled={products.length === 0} primary>
          Добавить
        </ButtonBase>
      </div>
      {!section ? (
        filteredSections.map((item) => <Section data={item} onClick={handleSetSection} key={item.id} type={type}/>)
      ) : (
        <div>
          <div className={styles.titleTable}>
            <span className={styles.button} onClick={() => setSection(false)}>
              <NavBar isExist text={section.name} />
            </span>
            <span className={styles.countSection}>всего записей: {count}</span>
          </div>
          {filteredSubsections.map((item) => (
            <SectionChild
              data={item}
              objectId={objectId}
              type={type}
              products={products}
              setProducts={setProducts}
              key={item.id}
            />
          ))}
          {section.subsections?.length === 0 && section.products?.length > 0 && (
            <ProductTable section={section} type={type} products={products} setProducts={setProducts} />
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(Materials);
