import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  loadTasksObjects, objectsTasksLoadingSelector,
  objectsTasksSelector
} from 'redux/modules/common/building/objects'
import { tasksFieldsSelector } from "redux/modules/purchaser/Tasks/selectors";
import { loadTasksFields } from "redux/modules/purchaser/Tasks/thunks";
import { IIdAndName } from "types/idAndName";

export interface IUseTaskObjectsSectionsUsers {
  building_id?: number | undefined;
  buildingFilterParams?: { name?: string };
}

export interface ITaskObject extends IIdAndName {
  entity_name: string;
}

export const useTaskObjectsSectionsUsers = ({ building_id, buildingFilterParams }: IUseTaskObjectsSectionsUsers) => {
  const dispatch = useDispatch();
  const objects = useSelector(objectsTasksSelector);
  const isLoadingObjects = useSelector(objectsTasksLoadingSelector);
  const { section, users } = useSelector(tasksFieldsSelector);

  useEffect(() => {
    dispatch(loadTasksObjects({ limit: 100 }, buildingFilterParams));
  }, [buildingFilterParams]);

  useEffect(() => {
    building_id && dispatch(loadTasksFields({ limit: 100, building_id }));
  }, [building_id]);

  return {
    objects: objects
      ? objects.results.map((x: ITaskObject) => ({
          id: x.id,
          name: x.name,
          label: x.name,
          entity_name: x.entity_name,
        }))
      : [],
    section: section.map((x) => ({ ...x, label: x.name })),
    users: users.map((x) => ({
      id: x.id,
      name: `${x.first_name} ${x.last_name}`,
      label: `${x.first_name} ${x.last_name}`,
    })),
    isLoadingObjects,
  };
};
