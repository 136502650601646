import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Route, Switch } from "react-router-dom";
import moment from "moment";
import cn from "classnames";

import { useTypedSelector } from "redux/typedUseSelector";
import { userSelector } from "redux/modules/common/auth";

import { Spinner } from "components/UI/Spinner/Spinner";
import InputBase from "components/UI/atoms/InputBase";
import OldSelect from "components/UI/atoms/Select/OldSelect";
import TemplateBase from "components/UI/templates/TemplateBase";
import TabBar from "./../../../../elements/navigation/TabBar/TabBar";
import EditProjectHeadline from "./components/EditProjectHeadline/EditProjectHeadline";
import EditProjectPassport from "./EditProjectPassport";
import EditProjectUsers from "./EditProjectUsers";
import EditProjectApprovals from "./EditProjectApprovals";
import EditProjectContractors from "./EditProjectContractors";

import { useEditProject } from "./hooks/useEditProject";
import { useProjectApprovals } from "./hooks/useProjectApprovals";
import { useProjectEmployees } from "./hooks/useProjectEmployees";

import { dirtySubmitBtnSwitcher, dirtySubmitBtnSwitcherForNewProject } from "./utils";
import { useLocationArray } from "utils/hooks/useLocationArray";

import styles from "./EditProject.module.scss";

const EditProject = ({ match }) => {
  const userInfo = useTypedSelector(userSelector)
  const locationArray = useLocationArray();

  const [dateStart, setDefaultDateStart] = useState(moment());
  const [dateEnd, setDefaultDateEnd] = useState(moment());

  const baseUrl = useMemo(() => {
    const splitUrl = match.url.split("/");
    splitUrl.pop();
    return splitUrl.join("/");
  }, [match.url]);

  const { entitiesList, object, urlId, isNew, editPassportHandler, localForm, localFormHandler, isLoading, resetForm } =
    useEditProject(baseUrl);

  const {
    employees: approvals,
    handleAddChainEmployee,
    deleteAgreementsEmployee,
    handleChangeAgreementsEmployees,
    getValues: getApprovalsValues,
  } = useProjectApprovals(urlId, isNew, object);

  const {
    payment_decision_employee,
    pto_employee,
    employees,
    responsible_employee,
    deleteEmployeeHandler,
    addEmployeeHandler,
    getValues: getEmployeesValues,
    timesheetResponsibleEmployee,
    stockResponsibleEmployee,
    stocksEmployeeHandler,
    stockData,
    estimateResponsibleEmployee
  } = useProjectEmployees(urlId, isNew, object, !isLoading);

  const isObjectSharedAndAccepted = useMemo(() => object?.is_shared && object?.shared_status === "accepted", [object?.is_shared, object?.shared_status]);
  const isObjectSharedAndTransferred = useMemo(() => object?.is_shared && object?.shared_status === "transferred", [object?.is_shared, object?.shared_status]);

  const getEntities = () => entitiesList?.map((item) => ({ id: item.id, name: item.id, label: item.name }));

  const handleSubmit = useCallback((values) => {
    editPassportHandler({
      vals: { ...values, ...getApprovalsValues(), ...getEmployeesValues() },
      urlId,
      dateStart,
      dateEnd,
    });
  }, [urlId, dateEnd, dateStart, getApprovalsValues, editPassportHandler, getEmployeesValues]);

  const isNewProject = useMemo(() => locationArray.includes("new"), [locationArray])

  const tabs = useMemo(() => {
    const tabsContent = [
      {
        link: `${match.url}/passport`,
        text: "Паспорт объекта",
      },
      {
        link: `${match.url}/users`,
        text: "Сотрудники",
      },
      {
        link: `${match.url}/approvals`,
        text: "Согласования",
      },
      {
        link: `${match.url}/contractors`,
        text: "Подрядчики",
      },
    ];
    if (userInfo.tariff === "BASIC") {
      return tabsContent.filter(el => el.text !== "Подрядчики")
    }
    return tabsContent
  }, [match.url, userInfo])

  useEffect(() => {
    if (object?.construction_date_start) {
      setDefaultDateStart(object?.construction_date_start);
    }
    if (object?.construction_date_end) {
      setDefaultDateEnd(object?.construction_date_end);
    }
  }, [object]);

  return (
    <TemplateBase>
      <div className={cn({ [styles.container]: !isLoading })}>
        {!isLoading ? (
          <Form
            onSubmit={handleSubmit}
            initialValues={object}
            render={({ handleSubmit, dirty, form }) => (
              <>
                <EditProjectHeadline
                  isDirty={
                    isNewProject ?
                      dirtySubmitBtnSwitcherForNewProject({ localForm, dirty })
                      :
                      dirtySubmitBtnSwitcher({
                        localForm,
                        dirty,
                        object,
                        stockData,
                        employees,
                        responsible_employee,
                        stockResponsibleEmployee,
                        timesheetResponsibleEmployee,
                        payment_decision_employee,
                        pto_employee,
                        approvals,
                        dateStart,
                        dateEnd,
                        estimateResponsible: estimateResponsibleEmployee
                      })
                  }
                  name={object?.name}
                  submit={handleSubmit}
                  isNew={isNew}
                  resetForm={() => {
                    form.restart()
                    resetForm()
                  }}
                />
                <div className={styles.wrapper}>
                  <div className={styles.row}>
                    <InputBase
                      label="*Наименование проекта"
                      meta={{ error: !localForm.name && "Заполните поле", touched: localForm.nameTouched }}
                      onChange={(e) => localFormHandler(e.target.value, "name")}
                      value={localForm.name}
                      onFocus={() => localFormHandler(true, "nameTouched")}
                      disabled={isObjectSharedAndTransferred}
                    />
                    <div>
                      <OldSelect
                        label="*Организация"
                        defaultValue={localForm.entity}
                        options={getEntities() || []}
                        onFocus={() => localFormHandler(true, "entityTouched")}
                        onClick={(id) => localFormHandler(id, "entity")}
                        meta={{ error: !localForm.entity && "Заполните поле", touched: localForm.entityTouched }}
                        disabled={isObjectSharedAndTransferred}
                      />
                    </div>
                  </div>
                  <TabBar tabs={tabs} className={styles.mb32} disabled={isNew} />
                  <Switch>
                    <Route exact path={`${match.url}/passport`}>
                      <EditProjectPassport
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        setDefaultDateEnd={setDefaultDateEnd}
                        setDefaultDateStart={setDefaultDateStart}
                        isDisabled={isObjectSharedAndAccepted || isObjectSharedAndTransferred}
                      />
                    </Route>
                    <Route exact path={`${match.url}/users`}>
                      <EditProjectUsers
                        responsible_employee={responsible_employee}
                        pto_employee={pto_employee}
                        payment_decision_employee={payment_decision_employee}
                        employees={employees}
                        timesheetResponsibleEmployee={timesheetResponsibleEmployee}
                        deleteEmployeeHandler={deleteEmployeeHandler}
                        addEmployeeHandler={addEmployeeHandler}
                        object={object}
                        stockResponsibleEmployee={stockResponsibleEmployee}
                        stockResponsibleEmployeeHandler={stocksEmployeeHandler}
                        estimateResponsibleEmployee={estimateResponsibleEmployee}
                        isDisabled={isObjectSharedAndTransferred}
                      />
                    </Route>
                    <Route exact path={`${match.url}/approvals`}>
                      <EditProjectApprovals
                        approvals={approvals}
                        deleteAgreementsEmployee={deleteAgreementsEmployee}
                        handleAddChainEmployee={handleAddChainEmployee}
                        urlId={urlId}
                        handleChangeAgreementsEmployees={handleChangeAgreementsEmployees}
                        isDisabled={isObjectSharedAndTransferred}
                      />
                    </Route>
                    <Route exact path={`${match.url}/contractors`}>
                      <EditProjectContractors buildingId={urlId} isDisabled={isObjectSharedAndTransferred} />
                    </Route>
                  </Switch>
                </div>
              </>
            )}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </TemplateBase>
  );
};

export default React.memo(EditProject);
