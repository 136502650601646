import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import { IFactInterval } from "types/interfaces/FactInterval";
import { IRouterParamsWithObjectId } from "types/routerTypes";
import { errorCatcher } from "utils/errorCatcher";

export const useReviewSharedWork = (
    interval: Pick<IFactInterval, "id" | "count" | "measure" | "from_organisation" | "provider_feedback_msg"> | null,
    onIntervalReload: (id: number) => void,
    objectId: number | string
    ) => {
    const [count, setCount] = React.useState(interval?.count)
    const [isApproving, setIsApproving] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSuccessfullyDeclined, setIsSuccessfullyDeclined] = React.useState(false)
    const [isDeclining, setIsDeclining] = React.useState(false)
    const [comment, setComment] = React.useState("")

    const commentHandler = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value)
    }, [])

    const countHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCount(e.target.value)
    }, [])

    const approveHandler = React.useCallback(() => {
        const data = {
            status: "confirmed",
            count
        }
        setIsLoading(true)
        setIsApproving(true)
        axios
            .post(`/building/${objectId}/fact-intervals/${interval?.id}/share/feedback/`, data)
            .then(() => onIntervalReload(interval!.id))
            .catch(err => {
                errorCatcher(err)
                setIsApproving(false)
            })
            .finally(() => setIsLoading(false))
    }, [objectId, interval?.id, count])

    const declineHandler = React.useCallback(() => {
        const data = {
            status: "canceled",
            reason_msg: comment
        }
        setIsLoading(true)
        setIsDeclining(true)
        axios
            .post(`/building/${objectId}/fact-intervals/${interval?.id}/share/feedback/`, data)
            .then(() => {
                setIsSuccessfullyDeclined(true)
                onIntervalReload(interval!.id)
            })
            .catch(err => {
                errorCatcher(err)
                setIsDeclining(false)
            })
            .finally(() => setIsLoading(false))
    }, [objectId, interval?.id, comment])

    return {
        count,
        countHandler,
        approveHandler,
        isApproving,
        isLoading,
        declineHandler,
        isDeclining,
        isSuccessfullyDeclined,
        setIsDeclining,
        commentHandler,
        comment
    }
}