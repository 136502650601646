import React from "react";
import { useDispatch } from "react-redux";
import { userSelector } from "redux/modules/common/auth";
import { detailDataSelector, getDetail } from "redux/modules/common/building/object/nowObject";
import { useTypedSelector } from "redux/typedUseSelector";

export const useCanEditPlan = (isActive: boolean, objectId: string) => {
  const dispatch = useDispatch()
  const userInfo = useTypedSelector(userSelector);
  const buildingInfo = useTypedSelector(detailDataSelector);

  React.useEffect(() => {
    if (!isActive) return
    if (buildingInfo?.id === Number(objectId)) return
    
    dispatch(getDetail(objectId))
  }, [isActive, objectId, buildingInfo])

  if (!buildingInfo) return false;
  return userInfo.id === buildingInfo.responsible_employee.id;
};
