import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { resetPasswordId } from "redux/modules/common/userRedux";

import { MiniModal } from "../UI/minimodal/MiniModal";
import ButtonBack from "components/UI/atoms/ButtonBack/index";
import ButtonBase from "components/UI/atoms/ButtonBase";
import { Switch } from "components/UI/atoms/Switch/Switch";

import { RiCloseFill } from "react-icons/ri";

const NavContent = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;
const FlexAction = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 25px 0 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 35px;
`;
const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
`;
const FlexText = styled.div`
  display: flex;
`;

export const Navigation = ({ location, block, setBlock, innerInput, setInnerInput, headerData }) => {
  const [modalPassword, setModalPassword] = useState(false);

  const checkHandler = (event) => {
    setInnerInput({ ...innerInput, is_blocked: event.target.checked });
  };

  const resetPasswordHandler = async () => {
    await resetPasswordId(innerInput.id, innerInput);
    setModalPassword(!modalPassword);
  };

  return (
    <header>
      <NavContent>
        <MiniModal visible={modalPassword} setVisible={setModalPassword}>
          <FlexAction>
            <div style={{ fontSize: "22px", fontWeight: "500", color: "#000" }}>Подтвердите действие</div>
            <RiCloseFill
              size="25"
              style={{ cursor: "pointer", color: "#707070" }}
              onClick={() => setModalPassword(!modalPassword)}
            />
          </FlexAction>
          <div>
            <div style={{ padding: "0 20px", marginBottom: "17px" }}>
              <Text style={{ color: "#000", fontSize: "18px" }}>Обновить пароль пользователя?</Text>
            </div>
            <div
              style={{
                borderTop: "1px solid #d3d3d3",
                padding: "10px 19px 11px 0px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonBase
                style={{ marginRight: "12.5px" }}
                onClick={() => setModalPassword(!modalPassword)}
                medium
                secondary
              >
                Отменить
              </ButtonBase>
              <ButtonBase onClick={resetPasswordHandler} medium primary>
                Да
              </ButtonBase>
            </div>
          </div>
        </MiniModal>
        <FlexText style={{ alignItems: "baseline" }}>
          <div>
            <ButtonBack />
          </div>
          <Text style={{ marginLeft: "30px" }}>
            {headerData.last_name} {headerData.first_name} {headerData.middle_name}
          </Text>
        </FlexText>
        <FlexText style={{ alignItems: "baseline" }}>
          {location.state.edit && (
            <FlexText style={{ marginRight: "45px" }}>
              <Text style={{ marginRight: "20px" }}>{!block ? "Заблокировать" : "Разблокировать"}</Text>
              <Switch checked={block} onChange={checkHandler} onClick={() => setBlock(!block)} />
            </FlexText>
          )}
          {location.state.edit && (
            <div>
              <ButtonBase onClick={() => setModalPassword(!modalPassword)} buttonPrimaryEmpty>
                Обновить пароль
              </ButtonBase>
            </div>
          )}
        </FlexText>
      </NavContent>
    </header>
  );
};
