import React from 'react'

export default function SuppliersIcon({color = '#000', width = '24'}) {
  return (
    <svg
      width={width}
      height="27"
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8,10.1c-1.1,0-2.2,0.2-3.2,0.7c-1-0.6-2-0.9-3.2-1c1-0.1,2-0.6,2.7-1.3c0.9-0.9,1.4-2,1.4-3.3 c0-0.9-0.3-1.8-0.8-2.6c-0.5-0.8-1.2-1.4-2.1-1.7C8.8,0.6,7.9,0.5,7,0.7C6.1,0.9,5.2,1.3,4.6,2C3.9,2.6,3.5,3.4,3.3,4.3 C3.2,5.2,3.2,6.1,3.6,7c0.4,0.8,0.9,1.6,1.7,2.1c0.6,0.4,1.3,0.7,2,0.7c-1.1,0.1-2.1,0.4-3,0.9c-1.2,0.6-2.1,1.6-2.8,2.7 c-0.7,1.1-1,2.4-1,3.8c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.3,0.8,2,0.8h3.3c0.7,3.8,4.1,6.7,8.2,6.7c4.6,0,8.3-3.7,8.3-8.3 C23.1,13.9,19.4,10.1,14.8,10.1z M6,8.1C5.4,7.7,5,7.2,4.7,6.5c-0.3-0.6-0.3-1.3-0.2-2C4.6,3.9,5,3.3,5.4,2.8 C5.9,2.3,6.5,2,7.2,1.9c0.2,0,0.4-0.1,0.7-0.1c0.4,0,0.9,0.1,1.3,0.3c0.6,0.3,1.2,0.7,1.5,1.3c0.4,0.6,0.6,1.2,0.6,1.9 c0,0.9-0.4,1.8-1,2.4c-0.6,0.6-1.5,1-2.4,1c0,0,0,0,0,0C7.2,8.6,6.5,8.4,6,8.1z M3.3,18.9c-0.4,0-0.9-0.2-1.2-0.5 c-0.3-0.3-0.5-0.7-0.5-1.2c0-1.1,0.3-2.2,0.9-3.2c0.6-1,1.4-1.7,2.4-2.3c0.9-0.5,1.9-0.8,3-0.8C7.9,11,8,11,8,11 c0.8,0,1.5,0.2,2.2,0.5c-2.3,1.5-3.8,4.1-3.8,7c0,0.1,0,0.3,0,0.4H3.3z M14.8,25.6c-3.5,0-6.3-2.5-7-5.7l0.2-0.3l0-0.2 c0-0.3-0.1-0.6-0.1-0.9c0-1.3,0.4-2.6,1.1-3.8c0.7-1.1,1.6-2,2.7-2.6c1-0.5,2-0.7,3.1-0.7c3.9,0,7.1,3.2,7.1,7.1 S18.7,25.6,14.8,25.6z"
        fill={color}
      />
      <path
        d="M19.9,20l-1.4-4.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.4-0.1-0.6,0L13,16.6 c-0.2,0.1-0.4,0.2-0.4,0.4c-0.1,0.2-0.1,0.4,0,0.6l1.4,4.3c0,0.2,0.1,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.5,0.2l4.6-1.5 c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.2C19.9,20.4,19.9,20.2,19.9,20z M14.9,21.4l-1.3-3.9l4-1.3l1.3,3.9L14.9,21.4z"
        fill={color}
      />
      <path
        d="M12,16.4c0,0.1,0.1,0.1,0.1,0.2c0.1,0,0.1,0.1,0.2,0.1l2.1-0.7c0,0,0.1,0,0.1-0.1l0.1-0.1c0,0,0-0.1,0-0.1 l0-0.1l-0.7-2c0,0,0-0.1-0.1-0.1l-0.1-0.1c0,0-0.1,0-0.1,0L11.6,14c0,0-0.1,0-0.1,0.1l-0.1,0.1c0,0,0,0.1,0,0.1L12,16.4L12,16.4z"
        fill={color}
      />
      <path
        d="M12.7,20.4l-0.2-0.2c-0.1,0-0.1,0-0.2-0.1l-2.2,0.7l-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.2l-0.1,0.3l0.7,2l0.1,0.2 l0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.1l2.1-0.7l0.1-0.1l0.1-0.1c0,0,0.1-0.1,0.1-0.1l0.1-0.1l0-0.2l-0.7-2L12.7,20.4z"
        fill={color}
      />
    </svg>
  )
}
