import { IFileStorageActions } from "./fileStorageActions";
import {
  deleteFileFromStorage,
  deleteFolderFromStorage,
  setFileStorageIsLoading,
  setMergedFoldersAndFiles,
  renameItemInAllStorage,
} from "./reducers";

import { IFileStorageState } from "./types";

const initialFileStorageState: IFileStorageState = {
  countByObjectId: {},
  byFolderId: {},
  byObjectId: {},
};

const fileStorageReducer = (
  state = initialFileStorageState,
  action: IFileStorageActions
): IFileStorageState => {
  const { type, payload } = action;
  switch (type) {
    case "storage/SET_RECENT":
      return {
        ...state,
        byObjectId: {
          ...state.byObjectId,
          [payload.objectId || "0"]: {
            ...(state.byObjectId[payload.objectId || "0"] || []),
            recentFiles: payload.results,
          },
        },
      };
    case "storage/SET_FOLDERS_AND_FILES":
      return setMergedFoldersAndFiles(state, payload);
    case "storage/DELETE_FILE":
      return deleteFileFromStorage(state, payload);
    case "storage/DELETE_FOLDER":
      return deleteFolderFromStorage(state, payload);
    case "storage/SET_IS_LOADING":
      return setFileStorageIsLoading(state, payload);
    case "storage/RENAME_FILE":
      return renameItemInAllStorage(state, { ...payload, isFolder: false });
    case "storage/RENAME_FOLDER":
      return renameItemInAllStorage(state, { ...payload, isFolder: true });
    case "storage/SET_COUNTS":
      return {
        ...state,
        countByObjectId: {
          ...state.countByObjectId,
          [payload.objectId]: payload.counts,
        },
      };
    default:
      return state;
  }
};

export default fileStorageReducer;
