import React from "react";
import cn from "classnames";

import NavigationBar from "../../molecules/NavigationBar";
import Header from "components/UI/molecules/Header";

import styles from "./index.module.scss";

export interface ITemplateBaseProps {
  children: React.ReactNode;
  contentClassName?: string;
}

const TemplateBase: React.FC<ITemplateBaseProps> = ({ children, contentClassName }) => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <NavigationBar />
        <div className={cn(styles.content, contentClassName)}>{children}</div>
      </div>
    </>
  );
};

export default React.memo(TemplateBase);
