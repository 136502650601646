import axios from "axios";
import { message } from "antd";
import { compose } from "redux";
import { errorCatcher } from "../../../../utils/errorCatcher";

import {
  ADD_DOCUMENT,
  ADD_PACKING_LIST_FILE,
  DEFAULT_LIMIT_DOCUMENT,
  DELETE_PACKING_LIST_FILE,
  LOAD_DOCUMENTS,
  SET_DOCUMENTS,
  SET_MORE_DOCUMENT,
  SET_PACKING_LIST,
  SET_PACKING_LIST_ERROR,
  SET_PACKING_LIST_LOADING,
} from "./constants";

export const setPackingList = (data) => {
  return {
    type: SET_PACKING_LIST,
    payload: data,
  };
};

export const setPackingListLoading = () => ({ type: SET_PACKING_LIST_LOADING });

export const setPackingListError = () => ({ type: SET_PACKING_LIST_ERROR });

export const setDocuments = (payload) => {
  return {
    type: SET_DOCUMENTS,
    payload: payload,
  };
};

export const addPackingListFile = (payload) => ({ type: ADD_PACKING_LIST_FILE, payload });
export const deletePackingListFile = (payload) => ({ type: DELETE_PACKING_LIST_FILE, payload });

export const sendToPurchaser = (id, callback = null) => {
  return () => {
    axios.post(`/purchaser/packinglists/${id}/shipment/`).then((response) => callback?.(response.data));
  };
};

export const acceptPackingList = (id, data, callback = null) => {
  return (dispatch) => {
    axios
      .post(`/purchaser/packinglists/${id}/accept/`, data)
      .then((response) => {
        if (callback) callback(response.data);
        compose(dispatch, setPackingList)(response.data);
        message.success("УПД успешно принята");
      })
      .catch(errorCatcher);
  };
};

export const acceptProviderPackingList = (id, callback = null) => {
  return (dispatch) => {
    axios.post(`/provider/packinglists/${id}/confirm/`).then((response) => {
      callback?.(response.data);
      message.success("Изменения в УПД успешно приняты");
    });
  };
};

export const loadDocumentsV2 = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, isPurchaser, paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: DEFAULT_LIMIT_DOCUMENT,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      dispatch({
        type: LOAD_DOCUMENTS,
      });
      axios
        .get(`/${getState().auth.entity.type === "pcr" ? "purchaser" : "provider"}/packinglists/`, config)
        .then((response) => dispatch(setDocuments(response.data)));
    };
  };
})();

export const addLoadDocument = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (entityId, paginationParams, filterParams = null, sorting = null) => {
    if (ge_cancel) ge_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        limit: DEFAULT_LIMIT_DOCUMENT,
      },
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      axios.get(`/${getState().auth.entity.type === "pcr" ? "purchaser" : "provider"}/packinglists/`, config).then(
        (res) => {
          dispatch({
            type: ADD_DOCUMENT,
            payload: res.data,
          });
        },
        (err) => {
          console.error(err);
        }
      );
    };
  };
})();

export const loadDocuments = (params) => {
  const config = {
    params,
  };

  return (dispatch) =>
    axios
      .get(`/purchaser/packinglists/`, config)
      .then((response) => dispatch(setDocuments(response.data)), errorCatcher);
};

export const loadPackingListV2 = (docId) => {
  return (dispatch) => {
    compose(dispatch, setPackingListLoading)();

    axios({
      method: "get",
      url: `/purchaser/packinglists/${docId}/`,
    }).then(
      (response) => compose(dispatch, setPackingList)(response.data),
      () => compose(dispatch, setPackingListError)()
    );
  };
};

export const changePackingListDate = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;

  return (packingListId, data) => {
    if (ge_cancel) ge_cancel();

    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
    };

    return (dispatch) => {
      axios
        .patch(`/purchaser/packinglists/${packingListId}/change-date/`, { delivery_date: data }, config)
        .then((response) => {
          compose(dispatch, setPackingList)(response.data);
          message.success("Дата поставки успешно изменена");
        })
        .catch(errorCatcher);
    };
  };
})();

export const loadMoreDocuments = (params) => {
  const config = { params: { ...params, limit: DEFAULT_LIMIT_DOCUMENT } };

  return (dispatch) => {
    axios
      .get(`/purchaser/packinglists/`, config)
      .then((response) =>
        dispatch({
          type: SET_MORE_DOCUMENT,
          payload: response.data,
        })
      )
      .catch(errorCatcher);
  };
};

export const addFileInPackingList = (packingListId, file) => (dispatch) => {
  const formData = new FormData();
  formData.append("files", file);

  message.info("Загрузка");

  axios
    .post(`/purchaser/packinglists/${packingListId}/files/`, formData)
    .then((response) => {
      compose(dispatch, addPackingListFile)(response.data[0]);
      message.success("Файл успешно прикреплен");
    })
    .catch(errorCatcher);
};

export const deleteFileFromPackingList = (packingListId, removedFileId) => (dispatch) => {
  axios
    .delete(`/purchaser/packinglists/${packingListId}/files/${removedFileId}/`)
    .then(() => {
      compose(dispatch, deletePackingListFile)(removedFileId);
      message.success("Файл успешно удален");
    })
    .catch(errorCatcher);
};
