import moment, { Moment } from "moment";
import { xarrowPropsType } from "react-xarrows";

import {
  IDiagramMaterial,
  IDiagramMaterialsData,
  IDiagramWeekMaterialsData,
  IDiagramWeekWorksData,
  IDiagramWorksData,
  IIntervalBase,
  IMaterialInterval,
  IntervalDaysMapType,
  IPlanInterval,
  IProcessedBranch,
  IProcessedBranchElement,
  IProject,
  IProjectIntervalBase,
  IProjectMaterialsDataInterval,
  IProjectWorksDataInterval,
  ISpittingTreeElement,
  IWorkStatusPercentage,
  ManufacturingTabsType,
  MaterialsMapType,
  MaterialType,
  PlanMapType,
  WorkMapType,
} from "./types";

import {
  applyProcessProjectIntervalFnIfExists,
  assignWorkStatusesCountToBunch,
  generateMaterialItemKeys,
  processDiagramPlan,
  processDiagramSectionPlan,
  processDiagramWeekPlan,
  processDiagramWeekWork,
  processDiagramWork,
} from "./utils";
import { isNanChecker } from "../../../utils/isNanChecker";

export const MONTH_COLOR_MAP = {
  default: "rgb(222, 222, 222)",
  confirmed: "#4FB1EB",
  doned: "",
  received: "#D5C9DF",
  paid: "#6FC79B",
  topay: "#4FB1EB",
  defaultBorder: "rgb(222, 222, 222)",
  confirmedBorder: "#D5C9DF",
  donedBorder: "#D5C9DF",
  receivedBorder: "#D5C9DF",
  paidBorder: "#6FC79B",
  topayBorder: "#4FB1EB",
};

export const MONTH_IDS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export const ACCEPTED_MATERIAL: MaterialType = "accepted";
export const ON_STOCK_MATERIAL: MaterialType = "on_stock";
export const PAYED_MATERIAL: MaterialType = "payed";
export const PLANS_MATERIAL: MaterialType = "plans";
export const PURCHASES_MATERIAL: MaterialType = "purchases";
export const STOCKLESS_MATERIAL: MaterialType = "stockless";

export enum DiagramFilters {
  confirmed_highlight = "confirmed_highlight",
  moderation_highlight = "moderation_highlight",
  canceled_highlight = "canceled_highlight",
  linking_enabled = "linking_enabled",
}

export enum SharedBraceStatuses {
  processed = "processed",
  canceled = "canceled",
  moderation = "moderation",
  confirmed = "confirmed",
}

export const SharedBraceColors = {
  [SharedBraceStatuses.confirmed]: "#00C1AB",
  [SharedBraceStatuses.moderation]: "#EB7F00",
  [SharedBraceStatuses.canceled]: "#E8527A",
  [SharedBraceStatuses.processed]: "transparent",
};

export const MATERIALS_COLOR_MAP = {
  [ACCEPTED_MATERIAL]: "#6FC79B",
  [ON_STOCK_MATERIAL]: "#9870D0",
  [PAYED_MATERIAL]: "#4FB1EB",
  [PLANS_MATERIAL]: "#BABABA",
  [PURCHASES_MATERIAL]: "#AF9AD0",
  [STOCKLESS_MATERIAL]: "#9EDDDB",
  default: "transparent",
};

export const MATERIALS_STATUSES = {
  [PLANS_MATERIAL]: {
    name: "Запланирован",
  },
  [PURCHASES_MATERIAL]: {
    name: "Заказан",
  },
  [ON_STOCK_MATERIAL]: {
    name: "На складе",
  },
  [STOCKLESS_MATERIAL]: {
    name: "Выдан",
  },
  [ACCEPTED_MATERIAL]: {
    name: "Принят",
  },
  [PAYED_MATERIAL]: {
    name: "К оплате",
  },
};

export enum INTERVAL_TYPES {
  work = "work",
  plan = "plan",
  material = "material",
}

export enum WORK_STATUSES {
  paid = "paid",
  topay = "topay",
  confirmed = "confirmed",
  received = "received",
  doned = "doned",
  default = "default",
}

export const TAILS_OFFSET_COMPENSATION_REM = 0.18;
export const TAILS_WIDTH_COMPENSATION_REM = 0.3;

export const WORKS_TAB_ID: ManufacturingTabsType = "work";
export const MATERIALS_TAB_ID: ManufacturingTabsType = "material";

export const getWorkStatus = (percentage: IWorkStatusPercentage) => {
  const { received, confirmed, doned, paid, topay } = percentage;
  return paid > 0
    ? WORK_STATUSES.paid
    : topay > 0
    ? WORK_STATUSES.topay
    : confirmed > 0
    ? WORK_STATUSES.confirmed
    : received > 0
    ? WORK_STATUSES.received
    : doned > 0
    ? WORK_STATUSES.doned
    : WORK_STATUSES.default;
};

export const getHighestMaterialStatus = (data: MaterialType[]) => {
  return data.indexOf(PAYED_MATERIAL) !== -1
    ? PAYED_MATERIAL
    : data.indexOf(ACCEPTED_MATERIAL) !== -1
    ? ACCEPTED_MATERIAL
    : data.indexOf(STOCKLESS_MATERIAL) !== -1
    ? STOCKLESS_MATERIAL
    : data.indexOf(ON_STOCK_MATERIAL) !== -1
    ? ON_STOCK_MATERIAL
    : data.indexOf(PURCHASES_MATERIAL) !== -1
    ? PURCHASES_MATERIAL
    : PLANS_MATERIAL;
};

export const WEEK_SCROLL_LENGTH = 7;
export const MT_REM = 2.81;
export const WEEKDAY_BUBBLE_WIDTH_REM = 1.6875;

export const INTERVAL_MAX_Z_INDEX = 89;

export const DIAGRAM_ARROW_CONFIG: Pick<
  xarrowPropsType,
  | "lineColor"
  | "headColor"
  | "strokeWidth"
  | "headSize"
  | "path"
  | "startAnchor"
  | "endAnchor"
  | "showTail"
  | "tailSize"
  | "tailColor"
  | "tailShape"
  | "zIndex"
  | "gridBreak"
  | "arrowBodyProps"
  | "_extendSVGcanvas"
  | "gridRadius"
> = {
  lineColor: "#707070",
  headColor: "#707070",
  strokeWidth: 1.5,
  headSize: 4,
  path: "grid",
  gridBreak: "0%20",
  gridRadius: 10,
  arrowBodyProps: {
    strokeLinejoin: "round",
  },
  startAnchor: "right",
  endAnchor: "left",
  showTail: true,
  tailSize: 4,
  tailColor: "#4FB2ED",
  tailShape: "circle",
  zIndex: 0,
  _extendSVGcanvas: 5,
};

export const HIGHLIGHT_COLOR = "#7061ea";

export enum DAYS_ABBRS {
  "ПН",
  "ВТ",
  "СР",
  "ЧТ",
  "ПТ",
  "СБ",
  "ВС",
}

export const getExpenditureCompletedPercent = (expenditure: {
  indicators: {
    completed_percent?: string;
    tickets_count?: string;
    count?: string;
  };
}) => {
  if (!expenditure.indicators) return;
  const { completed_percent, tickets_count, count } = expenditure.indicators;
  return (
    completed_percent ||
    (!!tickets_count && !!count && +count
      ? isNanChecker(+tickets_count / +count)
      : undefined)
  );
};

export const enumerateDaysBetweenDates = (
  startDate: Moment,
  endDate: Moment
) => {
  const now = startDate.clone();
  const dates = [];
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("YYYY-MM-DD"));
    now.add(1, "days");
  }
  return dates;
};

const crossroads = (
  plan: IPlanInterval,
  workDays: IntervalDaysMapType,
  id: number
) => {
  if (plan.days > 1) {
    const days = enumerateDaysBetweenDates(
      moment(plan.data.start_at),
      moment(plan.data.end_at)
    );
    return !days.every((day) => !workDays.has(`${id}_${day}`));
  }

  return workDays.has(`${id}_${plan.data.start_at}`);
};

export const getLocalTree = (
  tree: ISpittingTreeElement[],
  maxDay: number,
  year: number,
  monthNumber: number,
  offsetLeft: number,
  workMap: WorkMapType,
  planMap: PlanMapType,
  workDaysMap: IntervalDaysMapType,
  planDaysMap: IntervalDaysMapType
) => {
  const localTree: IProcessedBranch[] = [];
  let localObjectId = -1;
  tree.map((item) => {
    if (item.lvl === 1) {
      localObjectId = item.id;
    }
    let elements: IProcessedBranchElement[] = [];
    let worksToUpdate: string[] = [];
    let plansToUpdate: string[] = [];
    let localWorkIntervalStartDate = "";
    let localWorkIntervalEndDate = "";
    const updateWorks = () => {
      worksToUpdate.forEach((workKey) => {
        const candidate = workMap.get(workKey);
        if (!candidate) return;
        candidate["interval_start_date"] = localWorkIntervalStartDate;
        candidate["interval_end_date"] = localWorkIntervalEndDate;
        workMap.set(workKey, candidate);
      });
      elements = elements.map((el) => {
        return {
          ...el,
          work: el.work && workMap.get(`${el.parentId}_${el.work.start}`),
        };
      });
      worksToUpdate = [];
      localWorkIntervalStartDate = "";
      localWorkIntervalEndDate = "";
    };

    let localPlanIntervalStartDate = "";
    let localPlanIntervalEndDate = "";
    const updatePlans = () => {
      plansToUpdate.forEach((planKey) => {
        const candidate = planMap.get(planKey);
        if (!candidate) return;
        candidate["interval_start_date"] = localPlanIntervalStartDate;
        candidate["interval_end_date"] = localPlanIntervalEndDate;
        planMap.set(planKey, candidate);
      });
      elements = elements.map((el) => {
        return {
          ...el,
          plan: el.plan && planMap.get(`${el.parentId}_${el.plan.start}`),
        };
      });
      plansToUpdate = [];
      localPlanIntervalEndDate = "";
      localPlanIntervalStartDate = "";
    };

    for (let i = 1; i <= maxDay; i++) {
      const localMoment = moment(`${year}-${monthNumber}-${i}`, "YYYY-MM-DD");
      const startDateKey = localMoment.format("YYYY-MM-DD");
      const itemKey = `${item.id}_${startDateKey}`;
      const currentDay = localMoment.day();
      const checkPlan = planMap.get(itemKey);
      const checkWork = workMap.get(itemKey);

      if (checkPlan) {
        if (crossroads(checkPlan, workDaysMap, item.id)) {
          checkPlan.type = "brace";
          planMap.set(itemKey, checkPlan);
        }
      }

      if (checkPlan || checkWork) {
        elements.push({
          day: i,
          weekend: currentDay === 0 || currentDay === 6,
          plan: checkPlan,
          work: checkWork,
          parentId: item.id,
        });
      }

      if (checkWork) {
        const hasNextWork = workDaysMap.get(
          `${item.id}_${moment(checkWork.end)
            .add(1, "days")
            .format("YYYY-MM-DD")}`
        );
        if (!localWorkIntervalStartDate && hasNextWork) {
          localWorkIntervalStartDate = checkWork.start;
        }
        localWorkIntervalStartDate && worksToUpdate.push(itemKey);
        if (!hasNextWork && localWorkIntervalStartDate) {
          localWorkIntervalEndDate = checkWork.end;
          updateWorks();
        }
      }

      if (checkPlan) {
        const hasNextPlan = planDaysMap.get(
          `${item.id}_${moment(checkPlan.end)
            .add(1, "days")
            .format("YYYY-MM-DD")}`
        );
        if (!localPlanIntervalStartDate && hasNextPlan) {
          localPlanIntervalStartDate = checkPlan.start;
        }
        localPlanIntervalStartDate && plansToUpdate.push(itemKey);
        if (!hasNextPlan && localPlanIntervalStartDate) {
          localPlanIntervalEndDate = checkPlan.end;
          updatePlans();
        }
      }
    }
    localTree.push({
      id: item.id,
      days: elements,
      lvl: item.lvl,
      collapsed: item.collapsed,
      offsetLeft: offsetLeft,
      objectId: localObjectId,
      completedPercent: getExpenditureCompletedPercent(item.data),
    });
  });
  return localTree;
};

export const getLocalMaterialsTree = (
  tree: ISpittingTreeElement[],
  maxDay: number,
  year: number,
  monthNumber: number,
  offsetLeft: number,
  on_stock: MaterialsMapType,
  plans: MaterialsMapType,
  purchases: MaterialsMapType,
  stockless: MaterialsMapType,
  accepted: MaterialsMapType,
  payed: MaterialsMapType
) => {
  const localTree: IProcessedBranch[] = [];
  let localObjectId = -1;
  tree?.map((item) => {
    if (item.lvl === 1) {
      localObjectId = item.id;
    }
    const elements: IProcessedBranchElement[] = [];
    for (let i = 1; i <= maxDay; i++) {
      const currentMoment = moment(`${year}-${monthNumber}-${i}`, "YYYY-MM-DD");
      const currentDay = currentMoment.day();
      const startDateKey = currentMoment.format("YYYY-MM-DD");
      const itemKey = `${item.id}_${startDateKey}`;
      const data: Record<MaterialType, undefined | IMaterialInterval> = {
        on_stock: undefined,
        plans: undefined,
        purchases: undefined,
        stockless: undefined,
        accepted: undefined,
        payed: undefined,
      };

      const checkFn = (x: { name: MaterialType; map: MaterialsMapType }) => {
        if (x.map.has(itemKey)) {
          data[x.name] = x.map.get(itemKey);
        } else {
          delete data[x.name];
        }
      };

      [
        { name: ON_STOCK_MATERIAL, map: on_stock },
        { name: PLANS_MATERIAL, map: plans },
        { name: PURCHASES_MATERIAL, map: purchases },
        { name: STOCKLESS_MATERIAL, map: stockless },
        { name: ACCEPTED_MATERIAL, map: accepted },
        { name: PAYED_MATERIAL, map: payed },
      ].map(checkFn);

      if (Object.values(data).length > 0) {
        elements.push({
          day: i,
          weekend: currentDay === 0 || currentDay === 6,
          material: data,
        });
      }
    }
    localTree.push({
      id: item.id,
      days: elements,
      lvl: item.lvl,
      collapsed: item.collapsed,
      offsetLeft: offsetLeft,
      objectId: localObjectId,
      completedPercent: getExpenditureCompletedPercent(item.data),
    });
  });
  return localTree;
};

export const getWorksMaps = (
  data: IDiagramWorksData,
  projects?: IProject[],
  projectInterval?: IProjectWorksDataInterval
) => {
  const workMap = new Map();
  const planMap = new Map();
  const workDaysMap = new Map();
  const planDaysMap = new Map();

  if (projects?.length && projectInterval) {
    projects.map((project) => {
      if (projectInterval.plans?.[project.id]) {
        projectInterval.plans[project.id].map((plan) =>
          processDiagramPlan({
            plan,
            planMap,
            planDaysMap,
            entityId: plan.building_id,
          })
        );
      }

      if (projectInterval.works?.[project.id]) {
        projectInterval.works[project.id].map((work) =>
          processDiagramWork({
            work,
            workMap,
            workDaysMap,
            entityId: work.building_id,
          })
        );
      }
    });
  }

  data?.works?.map((work) =>
    processDiagramWork({
      work,
      workMap,
      workDaysMap,
      entityId: work.expenditure_id,
    })
  );

  data?.plans?.map((plan) =>
    processDiagramPlan({
      plan,
      planMap,
      planDaysMap,
      entityId: plan.expenditure_id,
    })
  );

  data?.sections?.map((section) => {
    section.works.map((work) =>
      processDiagramWork({ work, workMap, workDaysMap, entityId: section.id })
    );
    section.plans.map((plan) =>
      processDiagramPlan({ plan, planMap, planDaysMap, entityId: section.id })
    );
  });

  data?.planned_sections?.map((plan) => {
    processDiagramSectionPlan({
      plan,
      planMap,
      planDaysMap,
      entityId: plan.section_id,
    });
    processDiagramSectionPlan({
      plan,
      planMap,
      planDaysMap,
      entityId: plan.parent_id,
    });
  });

  return {
    work: workMap,
    plan: planMap,
    workDays: workDaysMap,
    planDays: planDaysMap,
  };
};

export const getMaterialsMaps = (
  data: IDiagramMaterialsData,
  projects?: IProject[],
  projectInterval?: IProjectMaterialsDataInterval
) => {
  const acceptedMap: MaterialsMapType = new Map();
  const onStockMap: MaterialsMapType = new Map();
  const payedMap: MaterialsMapType = new Map();
  const plansMap: MaterialsMapType = new Map();
  const purchasesMap: MaterialsMapType = new Map();
  const stocklessMap: MaterialsMapType = new Map();

  const pushItem = (
    keysArr: any[],
    map: MaterialsMapType,
    data: IMaterialInterval
  ) => {
    keysArr
      .filter((x) => x)
      .forEach((key) => {
        const currentItem = map.get(key);
        if (!currentItem || currentItem.days < data.days) {
          map.set(key, data);
        }
      });
  };

  const processProjectInterval = (
    item: IProjectIntervalBase,
    type: MaterialType,
    map: MaterialsMapType
  ) => {
    const startDateKey = moment(item.start_at).format("YYYY-MM-DD");
    const itemKey = `${item.building_id}_${startDateKey}`;
    const days =
      moment(item.end_at).diff(moment(item.start_at), "days") + 1 || 1;
    pushItem([itemKey], map, {
      days,
      data: item,
      actualItem: item,
      type: type,
    });
  };

  applyProcessProjectIntervalFnIfExists({
    projects,
    projectInterval,
    processProjectInterval,
    acceptedMap,
    payedMap,
    onStockMap,
    plansMap,
    purchasesMap,
    stocklessMap,
  });

  const processDataElement = (
    item: IIntervalBase & { confirm_date?: string },
    parent: IDiagramMaterial,
    type: MaterialType,
    map: MaterialsMapType
  ) => {
    const startDateKey = moment(item.start_at || item.confirm_date).format(
      "YYYY-MM-DD"
    );
    const { itemKey, itemSectionKey, itemParentKey } = generateMaterialItemKeys(
      parent,
      startDateKey
    );
    const days =
      moment(item.end_at).diff(moment(item.start_at), "days") + 1 || 1;
    pushItem([itemKey, itemSectionKey, itemParentKey], map, {
      days,
      data: parent,
      actualItem: item,
      type,
    });
  };

  data?.accepted?.map((accept) =>
    accept.using_data?.map((acceptedUsing) => {
      const startDateKey = moment(acceptedUsing.confirm_date).format(
        "YYYY-MM-DD"
      );
      const { itemKey, itemSectionKey, itemParentKey } =
        generateMaterialItemKeys(accept, startDateKey);
      pushItem([itemKey, itemSectionKey, itemParentKey], acceptedMap, {
        days: 1,
        data: accept,
        actualItem: acceptedUsing,
        type: ACCEPTED_MATERIAL,
      });
    })
  );

  data?.payed?.map((pay) =>
    pay.using_data?.map((payedUsing) =>
      processDataElement(payedUsing, pay, PAYED_MATERIAL, payedMap)
    )
  );

  data?.on_stock?.map((onStockItem) =>
    onStockItem.packingitems?.map((packingItem) =>
      processDataElement(
        packingItem,
        onStockItem,
        ON_STOCK_MATERIAL,
        onStockMap
      )
    )
  );

  data?.plans?.map((plan) =>
    plan.planned_works?.map((plan_work) => {
      if (
        plan_work.workinterval?.start_at === undefined ||
        plan_work.workinterval?.end_at === undefined
      )
        return;
      const startDateKey = moment(plan_work.workinterval.start_at).format(
        "YYYY-MM-DD"
      );
      const { itemKey, itemSectionKey, itemParentKey } =
        generateMaterialItemKeys(plan, startDateKey);
      const days =
        moment(plan_work.workinterval.end_at).diff(
          moment(plan_work.workinterval.start_at),
          "days"
        ) + 1 || 1;
      pushItem([itemKey, itemSectionKey, itemParentKey], plansMap, {
        days,
        data: plan,
        actualItem: plan_work,
        type: PLANS_MATERIAL,
      });
    })
  );

  data?.purchases?.map((purchase) =>
    purchase.orderrequest_set?.map((orderRequest) =>
      processDataElement(
        orderRequest,
        purchase,
        PURCHASES_MATERIAL,
        purchasesMap
      )
    )
  );

  data?.stockless?.map((stocklessItem) =>
    stocklessItem.using_data?.map((stocklessUsing) =>
      processDataElement(
        stocklessUsing,
        stocklessItem,
        STOCKLESS_MATERIAL,
        stocklessMap
      )
    )
  );

  return {
    accepted: acceptedMap,
    payed: payedMap,
    on_stock: onStockMap,
    plans: plansMap,
    purchases: purchasesMap,
    stockless: stocklessMap,
  };
};

export const getWeekWorksMaps = ({
  data,
  projectInterval,
  projects,
  year,
}: {
  data: IDiagramWeekWorksData;
  year: number;
  projectInterval?: IProjectWorksDataInterval;
  projects?: IProject[];
}) => {
  const workMap = new Map();
  const planMap = new Map();
  const workDaysMap = new Map();
  const planDaysMap = new Map();

  if (projects?.length && projectInterval) {
    projects?.map((project) => {
      if (projectInterval.plans?.[project.id]) {
        projectInterval.plans[project.id].map((plan) =>
          processDiagramPlan({
            plan,
            planMap,
            planDaysMap,
            entityId: plan.building_id,
          })
        );
      }
      if (projectInterval.works?.[project.id]) {
        projectInterval.works[project.id].map((work) =>
          processDiagramWork({
            work,
            workMap,
            workDaysMap,
            entityId: work.building_id,
          })
        );
      }
    });
  }

  data?.works?.forEach((work) =>
    processDiagramWeekWork({
      work,
      workMap,
      workDaysMap,
      year,
      entityId: work.expenditure_id,
    })
  );

  data?.plans?.forEach((plan) =>
    processDiagramWeekPlan({
      plan,
      planMap,
      planDaysMap,
      entityId: plan.expenditure_id,
      year,
    })
  );

  data?.sections?.forEach((section) => {
    section.works.forEach((work) =>
      processDiagramWeekWork({
        work,
        workMap,
        workDaysMap,
        year,
        entityId: section.id,
      })
    );
    section.plans.forEach((plan) =>
      processDiagramWeekPlan({
        plan,
        planMap,
        planDaysMap,
        year,
        entityId: section.id,
      })
    );
  });

  data?.planned_sections?.map((plan) => {
    processDiagramSectionPlan({
      plan,
      planMap,
      planDaysMap,
      entityId: plan.section_id,
    });
    processDiagramSectionPlan({
      plan,
      planMap,
      planDaysMap,
      entityId: plan.parent_id,
    });
  });

  return { workMap, planMap, workDaysMap, planDaysMap };
};

const weekCrossroads = (
  plan: IPlanInterval,
  workDays: IntervalDaysMapType,
  id: number | string
) => {
  if (plan.days > 1) {
    const days = enumerateDaysBetweenDates(
      moment(plan.start),
      moment(plan.end)
    );
    return !days.every((day) => !workDays.has(`${id}_${day}`));
  }

  return workDays.has(`${id}_${moment(plan.start).format("YYYY-MM-DD")}`);
};

export const getWeekWorksTree = ({
  tree,
  workMap,
  planMap,
  startWeek,
  endWeek,
  year,
  workDaysMap,
  planDaysMap,
}: {
  tree: ISpittingTreeElement[];
  year: number | string;
  workMap: WorkMapType;
  planMap: PlanMapType;
  workDaysMap: IntervalDaysMapType;
  planDaysMap: IntervalDaysMapType;
  startWeek: number;
  endWeek: number;
}) => {
  const localTree: IProcessedBranch[] = [];
  let localObjectId: null | number = null;
  tree.map((item) => {
    if (item.lvl === 1) {
      localObjectId = item.id;
    }
    let elements: IProcessedBranchElement[] = [];
    let worksToUpdate: string[] = [];
    let plansToUpdate: string[] = [];
    let localWorkIntervalStartDate: string | undefined = undefined;
    let localWorkIntervalEndDate: string | undefined = undefined;

    const updateWorks = () => {
      worksToUpdate.forEach((workKey) => {
        const candidate = workMap.get(workKey);
        if (!candidate) return;
        candidate["interval_start_date"] = localWorkIntervalStartDate;
        candidate["interval_end_date"] = localWorkIntervalEndDate;
        workMap.set(workKey, candidate);
      });
      elements = elements.map((el) => {
        return {
          ...el,
          work:
            el.work &&
            workMap.get(
              `${el.parentId}_${moment(el.work.start).format("YYYY-MM-DD")}`
            ),
        };
      });
      worksToUpdate = [];
      localWorkIntervalStartDate = undefined;
      localWorkIntervalEndDate = undefined;
    };

    let localPlanIntervalStartDate: string | undefined = undefined;
    let localPlanIntervalEndDate: string | undefined = undefined;

    const updatePlans = () => {
      plansToUpdate.forEach((planKey) => {
        const candidate = planMap.get(planKey);
        if (!candidate) return;
        candidate["interval_start_date"] = localPlanIntervalStartDate;
        candidate["interval_end_date"] = localPlanIntervalEndDate;
        planMap.set(planKey, candidate);
      });
      elements = elements.map((el) => {
        return {
          ...el,
          plan:
            el.plan &&
            planMap.get(
              `${el.parentId}_${moment(el.plan.start).format("YYYY-MM-DD")}`
            ),
        };
      });
      plansToUpdate = [];
      localPlanIntervalEndDate = undefined;
      localPlanIntervalStartDate = undefined;
    };
    for (let w = startWeek; w <= endWeek; w++) {
      for (let d = 1; d <= 7; d++) {
        const startDateKey = moment()
          .year(+year)
          .week(w)
          .day(d)
          .format("YYYY-MM-DD");
        const itemKey = `${item.id}_${startDateKey}`;
        const checkPlan = planMap.get(itemKey);
        const checkWork = workMap.get(itemKey);

        if (checkPlan) {
          if (weekCrossroads(checkPlan, workDaysMap, item.id)) {
            checkPlan.type = "brace";
            planMap.set(itemKey, checkPlan);
          }
        }

        if (checkPlan || checkWork) {
          elements.push({
            day: d + (w - 1) * 7,
            plan: checkPlan,
            work: checkWork,
            parentId: item.id,
          });
        }

        if (checkWork) {
          const hasNextWork = workDaysMap.get(
            `${item.id}_${moment(checkWork.end)
              .add(1, "days")
              .format("YYYY-MM-DD")}`
          );
          if (!localWorkIntervalStartDate && hasNextWork) {
            localWorkIntervalStartDate = moment(checkWork.start).format(
              "YYYY-MM-DD"
            );
          }
          localWorkIntervalStartDate &&
            worksToUpdate.push(`${item.id}_${startDateKey}`);
          if (!hasNextWork && localWorkIntervalStartDate) {
            localWorkIntervalEndDate = moment(checkWork.end).format(
              "YYYY-MM-DD"
            );
            updateWorks();
          }
        }

        if (checkPlan) {
          const hasNextPlan = planDaysMap.get(
            `${item.id}_${moment(checkPlan.end)
              .add(1, "days")
              .format("YYYY-MM-DD")}`
          );
          if (!localPlanIntervalStartDate && hasNextPlan) {
            localPlanIntervalStartDate = moment(checkPlan.start).format(
              "YYYY-MM-DD"
            );
          }
          localPlanIntervalStartDate &&
            plansToUpdate.push(`${item.id}_${startDateKey}`);
          if (!hasNextPlan && localPlanIntervalStartDate) {
            localPlanIntervalEndDate = moment(checkPlan.end).format(
              "YYYY-MM-DD"
            );
            updatePlans();
          }
        }
      }
    }
    localTree.push({
      id: item.id,
      days: elements,
      lvl: item.lvl,
      collapsed: item.collapsed,
      objectId: localObjectId,
    });
  });
  return localTree;
};

export const getMaterialsWeekMaps = ({
  projects,
  projectInterval,
  year,
  data,
}: {
  projects?: IProject[];
  projectInterval?: IProjectMaterialsDataInterval;
  year: number | string;
  data: IDiagramWeekMaterialsData;
}) => {
  const acceptedMap = new Map();
  const onStockMap = new Map();
  const payedMap = new Map();
  const plansMap = new Map();
  const purchasesMap = new Map();
  const stocklessMap = new Map();

  const pushItem = (
    keysArr: string[],
    map: MaterialsMapType,
    data: IMaterialInterval
  ) => {
    keysArr
      .filter((x) => x)
      .forEach((key) => {
        const currentItem = map.get(key);
        if (!currentItem || currentItem.days < data.days) {
          map.set(key, data);
        }
      });
  };

  const processProjectInterval = (
    item: IProjectIntervalBase,
    type: MaterialType,
    map: MaterialsMapType
  ) => {
    const itemKey = `${item.building_id}_${item.start_at}`;
    const days =
      moment(item.end_at).diff(moment(item.start_at), "days") + 1 || 1;
    pushItem([itemKey], map, {
      days,
      data: item,
      actualItem: item,
      type: type,
    });
  };

  applyProcessProjectIntervalFnIfExists({
    projects,
    projectInterval,
    processProjectInterval,
    acceptedMap,
    payedMap,
    onStockMap,
    plansMap,
    purchasesMap,
    stocklessMap,
  });

  const processDataElement = (
    item: any,
    parent: any,
    type: MaterialType,
    map: MaterialsMapType
  ) => {
    if (
      !item.start_week ||
      !item.start_day_week ||
      !item.end_week ||
      !item.end_day_week
    )
      return;
    const startMoment = moment()
      .year(+year)
      .week(item.start_week)
      .day(item.start_day_week);
    const endMoment = moment()
      .year(+year)
      .week(item.end_week)
      .day(item.end_day_week);
    const startDateKey = startMoment.format("YYYY-MM-DD");

    const itemKey =
      parent.estimate_expenditure_id &&
      `${parent.estimate_expenditure_id}_${startDateKey}`;
    const itemSectionKey =
      parent.expenditure_section_id &&
      `${parent.expenditure_section_id}_${startDateKey}`;
    const itemParentKey =
      parent.expenditure_parent_id &&
      `${parent.expenditure_parent_id}_${startDateKey}`;

    const days = endMoment.diff(startMoment, "days") + 1 || 1;
    pushItem(
      [itemKey, itemSectionKey, itemParentKey].filter((x) => x),
      map,
      {
        days,
        data: parent,
        actualItem: {
          ...item,
          start_at: startMoment.format("YYYY-MM-DD"),
          end_at: endMoment.format("YYYY-MM-DD"),
        },
        type,
      }
    );
  };

  data?.accepted?.map((accept) =>
    accept.using_data?.map((acceptedUsing) =>
      processDataElement(acceptedUsing, accept, ACCEPTED_MATERIAL, acceptedMap)
    )
  );
  data?.payed?.map((pay) =>
    pay.using_data?.map((payedUsing) =>
      processDataElement(payedUsing, pay, PAYED_MATERIAL, payedMap)
    )
  );
  data?.on_stock?.map((onStockItem) =>
    onStockItem.packingitems?.map((packingItem) =>
      processDataElement(
        packingItem,
        onStockItem,
        ON_STOCK_MATERIAL,
        onStockMap
      )
    )
  );
  data?.plans?.map((plan) =>
    plan.planned_works?.map((plan_work) =>
      processDataElement(plan_work, plan, PLANS_MATERIAL, plansMap)
    )
  );
  data?.purchases?.map((purchase) =>
    purchase.orderrequest_set?.map((orderRequest) =>
      processDataElement(
        orderRequest,
        purchase,
        PURCHASES_MATERIAL,
        purchasesMap
      )
    )
  );
  data?.stockless?.map((stocklessItem) =>
    stocklessItem.using_data?.map((stocklessUsing) =>
      processDataElement(
        stocklessUsing,
        stocklessItem,
        STOCKLESS_MATERIAL,
        stocklessMap
      )
    )
  );

  return {
    accepted: acceptedMap,
    payed: payedMap,
    on_stock: onStockMap,
    plans: plansMap,
    purchases: purchasesMap,
    stockless: stocklessMap,
  };
};

export const getMaterialsWeekTree = ({
  tree,
  startWeek,
  endWeek,
  year,
  on_stock,
  plans,
  purchases,
  stockless,
  accepted,
  payed,
}: {
  tree: ISpittingTreeElement[];
  startWeek: number;
  endWeek: number;
  year: number | string;
  on_stock: MaterialsMapType;
  plans: MaterialsMapType;
  purchases: MaterialsMapType;
  stockless: MaterialsMapType;
  accepted: MaterialsMapType;
  payed: MaterialsMapType;
}) => {
  const localTree: IProcessedBranch[] = [];
  let localObjectId: number | null = null;
  tree?.map((item) => {
    if (item.lvl === 1) {
      localObjectId = item.id;
    }
    const elements = [];
    for (let w = startWeek; w <= endWeek; w++) {
      for (let d = 1; d <= 7; d++) {
        const currentMoment = moment()
          .year(+year)
          .week(w)
          .day(d);
        const startDateKey = currentMoment.format("YYYY-MM-DD");
        const itemKey = `${item.id}_${startDateKey}`;
        const data = {
          on_stock: undefined,
          plans: undefined,
          purchases: undefined,
          stockless: undefined,
          accepted: undefined,
          payed: undefined,
        };

        const checkFn = (x: { name: MaterialType; map: MaterialsMapType }) => {
          const candidate = x.map.get(itemKey);
          if (candidate) {
            data[x.name] = candidate;
          } else {
            delete data[x.name];
          }
        };

        [
          { name: ON_STOCK_MATERIAL, map: on_stock },
          { name: PLANS_MATERIAL, map: plans },
          { name: PURCHASES_MATERIAL, map: purchases },
          { name: STOCKLESS_MATERIAL, map: stockless },
          { name: ACCEPTED_MATERIAL, map: accepted },
          { name: PAYED_MATERIAL, map: payed },
        ].map(checkFn);

        if (Object.values(data).length > 0) {
          elements.push({
            day: d + (w - 1) * 7,
            weekend: d === 0 || d === 6,
            material: data,
          });
        }
      }
    }
    localTree.push({
      id: item.id,
      days: elements,
      lvl: item.lvl,
      collapsed: item.collapsed,
      offsetLeft: 0,
      objectId: localObjectId,
      completedPercent: getExpenditureCompletedPercent(item.data),
    });
  });
  return localTree;
};

export const getMonthsWorksTree = ({
  tree,
  workMap,
  planMap,
  year,
}: {
  tree: ISpittingTreeElement[];
  year: number | string;
  workMap: WorkMapType;
  planMap: PlanMapType;
}) => {
  const localTree: IProcessedBranch[] = [];
  let localObjectId: null | number = null;
  tree.map((item) => {
    if (item.lvl === 1) {
      localObjectId = item.id;
    }
    const elements: IProcessedBranchElement[] = [];
    for (let m = 0; m < 12; m++) {
      const localDaysInMonth = moment().month(m).daysInMonth();
      const elementsCandidate = {
        worksBunch: {
          works: [],
          plans: [],
          statuses: {},
        },
        day: m,
        parentId: item.id,
      };
      for (let d = 1; d <= localDaysInMonth; d++) {
        const startDateKey = moment()
          .year(+year)
          .month(m)
          .date(d)
          .format("YYYY-MM-DD");

        const itemKey = `${item.id}_${startDateKey}`;

        const checkPlan = planMap.get(itemKey);
        const checkWork = workMap.get(itemKey);

        if (checkPlan) elementsCandidate.worksBunch.plans.push(checkPlan);
        if (checkWork) {
          assignWorkStatusesCountToBunch({
            checkWork,
            statuses: elementsCandidate.worksBunch.statuses,
          });
          elementsCandidate.worksBunch.works.push(checkWork);
        }
      }
      elements.push(elementsCandidate);
    }
    localTree.push({
      id: item.id,
      days: elements,
      lvl: item.lvl,
      collapsed: item.collapsed,
      objectId: localObjectId,
    });
  });
  return localTree;
};
