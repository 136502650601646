export const MODULE_NAME = "permissions"

export const SET_ALL_PERMISSIONS_DATA = `${MODULE_NAME}/SET_ALL_PERMISSIONS_DATA`;
export const SET_ALL_PERMISSIONS_LOADING = `${MODULE_NAME}/SET_ALL_PERMISSIONS_LOADING`;

export const SET_CURRENT_USER_PERMISSIONS_DATA = `${MODULE_NAME}/SET_CURRENT_USER_PERMISSIONS_DATA`;
export const SET_CURRENT_USER_PERMISSIONS_LOADING = `${MODULE_NAME}/SET_CURRENT_USER_PERMISSIONS_LOADING`;

export const SET_USER_PERMISSIONS_DATA = `${MODULE_NAME}/SET_USER_PERMISSIONS_DATA`;
export const SET_USER_PERMISSIONS_LOADING = `${MODULE_NAME}/SET_USER_PERMISSIONS_LOADING`;
export const UPDATE_USER_PERMISSIONS_DATA = `${MODULE_NAME}/UPDATE_USER_PERMISSIONS_DATA`;