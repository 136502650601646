import React from "react"

export const eventsBtnSvg = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <defs>
        <clipPath id="clip-OSLA_Event_28x28_v1">
            <rect width="28" height="28" />
        </clipPath>
    </defs>
    <g id="OSLA_Event_28x28_v1" clip-path="url(#clip-OSLA_Event_28x28_v1)">
        <g  transform="translate(-1770 -232.11)">
            <g id="group" transform="translate(1768.064 233.06)">
                <path id="path" d="M5628.207,3460.74h4.9V3485.1H5607V3460.74h4.894V3459h1.63v1.74h13.052V3459h1.63Zm-16.312,1.74h-3.263v3.48h22.839v-3.48h-3.264v1.74h-1.63v-1.74h-13.052v1.74h-1.63Zm-3.263,20.881h22.839V3467.7h-22.839Z" transform="translate(-5604.115 -3459)" fill="#707070" />
            </g>
            <g>
                <path d="M1.5,1.5v5h8L8.543,4.077,9.5,1.5h-8M0,0H13L10.5,4.077,13,8H0Z" transform="translate(1778 243)" fill="#707070" />
                <rect width="2" height="13" transform="translate(1778 243)" fill="#fff" />
                <path d="M1,1V1M0,0H2V13H0Z" transform="translate(1778 243)" fill="#707070" />
            </g>
        </g>
    </g>
</svg>
