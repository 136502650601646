import React, { useCallback, useState } from "react";
import cn from "classnames";
import { compose } from "redux";

import statusCanceled from "../../../../images/icons/statusCanceled.svg";
import statusConfirmed from "../../../../images/icons/statusConfirmed.svg";
import arrowDownSelect from "../../../../images/icons/arrowDownSelect.svg";
import Icon from "../../Icon";

import styles from "./index.module.scss";
import { Spinner } from "../../Spinner/Spinner";

const ConfirmSelect = ({ className, onConfirm, onCancel, isLoading, disabled }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const closeOptions = useCallback(() => setIsOptionsOpen(false), []);

  const isActive = !isLoading && !disabled;

  const handleOptionsOpen = useCallback(() => isActive && setIsOptionsOpen((prevState) => !prevState), [isActive]);

  const onApprove = useCallback(() => {
    if (!isActive) return;
    compose(onConfirm, closeOptions)();
  }, [closeOptions, isActive]);

  const handleOnCancel = useCallback(() => {
    if (!isActive) return;
    compose(onCancel, closeOptions)();
  }, [isActive, closeOptions]);

  return (
    <div
      className={cn(
        styles.selectedBlock,
        { [styles.selectedBlockBorder]: isOptionsOpen},
        className
      )}
    >
      <div className={styles.selectedBox1}>
        {isLoading && (
          <div className={styles.loading}>
            <Spinner isSmallGray />
          </div>
        )}
        <div className={styles.approve} onClick={onApprove}>
          <span>Утвердить </span>
          <Icon icon={statusConfirmed} className={styles.iconStatus} />
        </div>
        <div className={styles.arrowContainer} onClick={handleOptionsOpen}>
          <Icon icon={arrowDownSelect} className={cn(styles.arrow, { [styles.arrowReverse]: isOptionsOpen })} />
        </div>
      </div>
      {isOptionsOpen && (
        <div className={styles.selectedBox2} onClick={handleOnCancel}>
          <div className={styles.cancel}>
            <span>Отклонить </span>
            <Icon className={styles.iconStatus} icon={statusCanceled} />
          </div>
          <div className={styles.div1}></div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ConfirmSelect);
