import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { tasksRouteParamsType } from "redux/modules/purchaser/Tasks/types";

import ControlsBar from "./components/ControlsBar/ControlsBar";
import TaskList from "./components/TaskList/TaskList";
import TaskListAllObjects from "./components/TaskListAllObjects/TaskListAllObjects";
import TaskTableHead from "./components/TaskTableHead/TaskTableHead";
import TasksIndicators from "./components/TasksIndicators/TasksIndicators";
import TasksModal from "./components/TasksModal/TasksModal";
import TemplateBase from "components/UI/templates/TemplateBase";

import { TASK_LISTS_TYPES } from "./constants";

import { useTasksFilter } from "./hooks/useTasksFilter";
import { useTasksModal } from "./hooks/useTasksModal";
import { useLocationArray } from "utils/hooks/useLocationArray";

import styles from "./Tasks.module.scss";

function Tasks() {
  const { objectId } = useParams<tasksRouteParamsType>();
  const tasksListType = useLocationArray()[1] as TASK_LISTS_TYPES;

  const { filters, filtersHandler, dateRangeHandler, changeSectionHandler } = useTasksFilter();
  const { openAddModal } = useTasksModal();

  const [isIndicatorsOpen, setIsIndicatorsOpen] = useState(true);

  const newTaskPlacementType = objectId === "0" ? "byAllBuildings" : "byCertainBuilding";

  const handleOpenAddTask = useCallback(() => {
    openAddModal({
      placementType: newTaskPlacementType,
      listType: tasksListType as TASK_LISTS_TYPES,
    });
  }, [newTaskPlacementType, tasksListType]);

  return (
    <TemplateBase>
      <TasksIndicators
        isOpen={isIndicatorsOpen}
        setIsOpen={setIsIndicatorsOpen}
        type={tasksListType}
        objectId={objectId}
      />
      <ControlsBar
        onDateChange={dateRangeHandler}
        filters={filters}
        onChangeFilters={filtersHandler}
        variant="tasks"
        onAddNew={handleOpenAddTask}
      />
      <TaskTableHead onChangeFilters={filtersHandler} className={styles.head} />
      {objectId && objectId !== "0" && (
        <TaskList filters={filters} objectId={objectId} type={tasksListType as TASK_LISTS_TYPES} />
      )}
      {objectId === "0" && (
        <TaskListAllObjects
          onChangeSection={changeSectionHandler}
          filters={filters}
          type={tasksListType as TASK_LISTS_TYPES}
        />
      )}
      <TasksModal presetBuildingId={objectId ? +objectId : 0} />
    </TemplateBase>
  );
}

export default React.memo(Tasks);
