import React, { useCallback, useState } from "react";
import cn from "classnames";

import { stopEventPropagation } from "utils/stopEventPropagation";

import submitIcon from "images/icons/fillTick.svg";
import EditIcon from "images/icons/EditIcon";
import CrossRoundedIcon from "images/icons/CrossBoldRoundedIcon";

import ConfirmationModal from "../../molecules/ConfirmationModal";

import styles from "./Actions.module.scss";

export interface IActionsProps {
  className?: string;
  canSave?: boolean;
  onSave?: () => void;
  canEdit?: boolean;
  onEdit?: () => void;
  canRemove?: boolean;
  onRemove?: () => void;
  onRemoveDirectly?: () => void;
}

const Actions = ({
  className,
  canSave,
  onSave,
  canEdit,
  onEdit,
  canRemove,
  onRemove,
  onRemoveDirectly,
}: IActionsProps) => {
  const [isOpenRemoveConfirmationModal, setIsOpenRemoveConfirmationModal] = useState(false);

  const openRemoveConfirmationModal = useCallback(() => {
    if (onRemoveDirectly) {
      onRemoveDirectly();
    } else {
      setIsOpenRemoveConfirmationModal(true);
    }
  }, []);
  const closeRemoveConfirmationModal = useCallback(() => setIsOpenRemoveConfirmationModal(false), []);

  const confirmRemove = useCallback(() => {
    if (onRemove) onRemove();
    closeRemoveConfirmationModal();
  }, [onRemove, closeRemoveConfirmationModal]);

  return (
    <div className={cn(className)} onClick={stopEventPropagation}>
      <div className={styles.actions}>
        {canSave && (
          <div className={styles.button} title="Сохранить" onClick={onSave}>
            <img className={styles.icon} src={submitIcon} alt="Сохранить" />
          </div>
        )}
        {canEdit && (
          <div className={styles.button} title="Редактировать" onClick={onEdit}>
            <EditIcon width={"16"} />
          </div>
        )}
        {canRemove && (
          <>
            <div className={styles.button} title="Удалить" onClick={openRemoveConfirmationModal}>
              <CrossRoundedIcon width={"16"} color="#606060" />
            </div>
            <ConfirmationModal
              variant="secondary"
              isOpen={isOpenRemoveConfirmationModal}
              action={confirmRemove}
              onClose={closeRemoveConfirmationModal}
            >
              Подтвердить удаление?
            </ConfirmationModal>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(Actions);
