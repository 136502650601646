import axios from "axios";
import { compose } from "redux";

import { setAllPermissionsDataAction, setAllPermissionsLoadingAction } from "../actions";

import { errorCatcher } from "../../../../../utils/errorCatcher";

export const getAllPermissions = () => async (dispatch) => {
  compose(dispatch, setAllPermissionsLoadingAction)(true);

  await axios
    .get("/permissions/")
    .then((response) => compose(dispatch, setAllPermissionsDataAction)(response.data))
    .catch(errorCatcher);

  compose(dispatch, setAllPermissionsLoadingAction)(false);
};
