import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "redux";

import { checkUserHavePermission } from "redux/modules/common/permissions/thunks/checkUserHavePermission";

import { ProfilePage } from "../../../UI/molecules/Header/components/Profile/ProfilePage/ProfilePage";
import Calendar from "../../../pages/Calendar";
import PaymentsList from "../../../pages/PaymentsList";
import SuppliesList from "../../../pages/SuppliesList";
import FilesStorage from "components/pages/FilesStorage/FilesStorage";
import Tasks from "components/pages/Tasks/Tasks";
import TasksLists from "components/pages/Tasks/TasksLists";
import { TASK_LISTS_TYPES } from "components/pages/Tasks/constants";

import DocumentsRoutes from "../DocumentsRoutes/DocumentsRoutes";
import SettingsRoutes from "../SettingsRoutes/SettingsRoutes";

import {
  VIEW_ADD_BILL_FILES,
  VIEW_ADD_BILL_PAYMENTS_FILE,
  VIEW_ADD_CONTRACT_FILES,
  VIEW_ADD_PACKING_LIST_FILES,
  VIEW_BILL,
  VIEW_BILLS_LIST,
  VIEW_BILL_IN_EDIT,
  VIEW_BILL_PAYMENTS_MAKE_PAID,
  VIEW_CONTRACTS_LIST,
  VIEW_DELETE_BILL_FILES,
  VIEW_DELETE_CONTRACT,
  VIEW_DELETE_CONTRACT_FILES,
  VIEW_DELETE_EXPORTS,
  VIEW_DELETE_PACKING_LIST_FILES,
  VIEW_DOCUMENTS_SECTION,
  VIEW_EXPORT_LIST,
  VIEW_PACKING_LISTS,
  VIEW_PACKING_LISTS_VAT_CALCULATION,
  VIEW_PACKING_LIST_ACCEPT,
  VIEW_PACKING_LIST_PAGE,
} from "constants/permissions/constructingPermissions";
import { VIEW_SETTINGS_SECTION } from "constants/permissions/generalPermissions";

import usePermission from "hooks/usePermission";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

function TasksRoutes() {
  const dispatch = useDispatch();

  const isShowWithoutPermission = !isAllModulesAllowed();

  const haveViewDocumentsPermission = usePermission(VIEW_DOCUMENTS_SECTION);
  const haveViewSettingsPermission = usePermission(VIEW_SETTINGS_SECTION);

  const documentsPermissions = useMemo(
    () => ({
      viewBillsList: compose(dispatch, checkUserHavePermission)(VIEW_BILLS_LIST),
      viewBill: compose(dispatch, checkUserHavePermission)(VIEW_BILL),
      viewPackingLists: compose(dispatch, checkUserHavePermission)(VIEW_PACKING_LISTS),
      viewPackingListPage: compose(dispatch, checkUserHavePermission)(VIEW_PACKING_LIST_PAGE),
      viewPackingListsVatCalculation: compose(dispatch, checkUserHavePermission)(VIEW_PACKING_LISTS_VAT_CALCULATION),
      viewContractsList: compose(dispatch, checkUserHavePermission)(VIEW_CONTRACTS_LIST),
      viewDeleteContract: compose(dispatch, checkUserHavePermission)(VIEW_DELETE_CONTRACT),
      viewAddContractFiles: compose(dispatch, checkUserHavePermission)(VIEW_ADD_CONTRACT_FILES),
      viewDeleteContractFiles: compose(dispatch, checkUserHavePermission)(VIEW_DELETE_CONTRACT_FILES),
      viewExportList: compose(dispatch, checkUserHavePermission)(VIEW_EXPORT_LIST),
      viewDeleteExports: compose(dispatch, checkUserHavePermission)(VIEW_DELETE_EXPORTS),
    }),
    []
  );

  const billPermissions = useMemo(
    () => ({
      viewBillInEdit: compose(dispatch, checkUserHavePermission)(VIEW_BILL_IN_EDIT),
      addFiles: compose(dispatch, checkUserHavePermission)(VIEW_ADD_BILL_FILES),
      deleteFiles: compose(dispatch, checkUserHavePermission)(VIEW_DELETE_BILL_FILES),
      viewAddBillPaymentsFile: compose(dispatch, checkUserHavePermission)(VIEW_ADD_BILL_PAYMENTS_FILE),
      viewBillPaymentsMakePaid: compose(dispatch, checkUserHavePermission)(VIEW_BILL_PAYMENTS_MAKE_PAID),
    }),
    []
  );

  const packingListPermissions = useMemo(
    () => ({
      viewPackingListAccept: compose(dispatch, checkUserHavePermission)(VIEW_PACKING_LIST_ACCEPT),
      addFiles: compose(dispatch, checkUserHavePermission)(VIEW_ADD_PACKING_LIST_FILES),
      deleteFiles: compose(dispatch, checkUserHavePermission)(VIEW_DELETE_PACKING_LIST_FILES),
    }),
    []
  );

  return (
    <>
      <Switch>
        {haveViewDocumentsPermission && (
          <Route
            path={"/tasks/documents"}
            render={(props) => (
              <DocumentsRoutes
                {...props}
                documentsPermissions={documentsPermissions}
                billPermissions={billPermissions}
                packingListPermissions={packingListPermissions}
              />
            )}
          />
        )}

        <Route exact path={"/tasks/profile"} component={ProfilePage} />

        <Route path={"/tasks/events/calendar"} component={Calendar} />
        <Route path={"/tasks/events/payments"} component={PaymentsList} />
        <Route path={"/tasks/events/supplies"} component={SuppliesList} />

        {(haveViewSettingsPermission || isShowWithoutPermission) && (
          <Route path={"/tasks/settings"} component={SettingsRoutes} />
        )}

        <Route exact path={`/tasks/${TASK_LISTS_TYPES.ALL}/:objectId/:taskId`} component={Tasks} />
        <Route exact path={`/tasks/${TASK_LISTS_TYPES.ALL}/:objectId`} component={Tasks} />
        <Route exact path={`/tasks/${TASK_LISTS_TYPES.MY}/:objectId`} component={Tasks} />
        <Route exact path={`/tasks/${TASK_LISTS_TYPES.ASSIGNED}/:objectId`} component={Tasks} />
        <Route exact path={`/tasks/${TASK_LISTS_TYPES.WATCHING}/:objectId`} component={Tasks} />
        <Route exact path={`/tasks/${TASK_LISTS_TYPES.LISTS}/:objectId`} component={TasksLists} />
        {isAllModulesAllowed() && (
          <Route exact path={`/tasks/${TASK_LISTS_TYPES.FILES}/:objectId/`} component={FilesStorage} />
        )}

        <Redirect to={`/tasks/${TASK_LISTS_TYPES.ALL}/0`} />
      </Switch>
    </>
  );
}

export default TasksRoutes;
