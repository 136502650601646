import React from 'react'
import Indicators from 'components/UI/molecules/Indicators';
import styles from "./EstimateIndicators.module.scss"
import cn from "classnames"
import { useSelector } from 'react-redux';
import { estimateMeasureSelector } from '../../../../../redux/modules/common/building/process';

function EstimateIndicators({ id, isOpen, setIsOpen }) {
    const measure = useSelector(estimateMeasureSelector)

    return (
        <div className={cn(styles.container, {[styles.open]: isOpen})}>
            <button onClick={() => setIsOpen(prev => !prev)} className={cn(styles.btn, {[styles.activeBtn]: isOpen})}>
                <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.592072 9L6.13306 4.5L0.592072 0" fill="black" />
                </svg>
            </button>
            <div className={styles.content}>
                <Indicators id={id} measure={measure} />
            </div>
        </div>
    )
}

export default EstimateIndicators