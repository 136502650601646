import React, { useState } from "react";
import styles from "./index.module.scss";
import { Field, Form } from "react-final-form";
import ButtonBase from "components/UI/atoms/ButtonBase";
import OldSelect from "components/UI/atoms/Select/OldSelect";
import arowSelectSmallBlue from "images/icons/arowSelectSmallBlue.svg";
import CalendarRange from "../../../UI/molecules/CalendarRange";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { splitThousands } from "../../../../utils/splitThousands";
import Modal from "components/UI/atoms/Modal";
import { objectsBreadcrumbsSelector } from "../../../../redux/modules/common/building/objects";
import { errorCatcher } from "utils/errorCatcher";

const ModalCalculation = ({ close, isOpen }) => {
  const projects = useSelector(objectsBreadcrumbsSelector);

  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const [sum, setSum] = useState(null);
  const numberAllObjects = -1;

  const objSelectArr = () =>
    projects?.results.map((el) => ({
      id: el.id,
      name: el.id,
      label: el.name,
    })) || [];

  const newObj = [
    {
      id: numberAllObjects,
      name: numberAllObjects,
      label: "Все обьекты",
    },
    ...objSelectArr(),
  ];

  const submitRequisite = async (value) => {
    const buildingId = newObj.find((item) => +item.id === +value.building_id);
    try {
      const { data } = await axios.get(`/purchaser/packinglists/summary/`, {
        params: {
          building_id: !buildingId || buildingId?.id === numberAllObjects ? null : buildingId.id,
          delivery_date_after: moment(dateStart).format("YYYY-MM-DD"),
          delivery_date_before: moment(dateEnd).format("YYYY-MM-DD"),
        },
      });
      setSum(data?.total_vat);
    } catch (err) {
      errorCatcher(err)
    }
  };

  return (
    <Modal title="Подсчет НДС" onClose={close} className={styles.container} isOpen={isOpen}>
      <Form
        onSubmit={(values) => submitRequisite(values)}
        render={({ handleSubmit, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.topSide}>
              <div className={styles.blockSelects}>
                <div className={styles.field1}>
                  <Field
                    name="building_id"
                    label="Объект"
                    component={OldSelect}
                    icon={arowSelectSmallBlue}
                    options={newObj}
                    placeholder="Все объекты"
                  />
                </div>
                <div className={styles.field2}>
                  <Field
                    name="calendar"
                    component={CalendarRange}
                    defaultDateStart={dateStart}
                    setDefaultDateStart={setDateStart}
                    defaultDateEnd={dateEnd}
                    setDefaultDateEnd={setDateEnd}
                  />
                </div>
              </div>
              {sum !== null && (
                <div className={styles.value}>
                  Суммарный НДС: <span>{sum ? splitThousands(sum.toFixed(2), false, false) : "0.00"} ₽</span>
                </div>
              )}
            </div>
            <div className={styles.actionBlock}>
              <ButtonBase primary big type="submit">
                Посчитать
              </ButtonBase>
            </div>
          </form>
        )}
      />
    </Modal>
  );

  return <div className={styles.container}></div>;
};

export default ModalCalculation;
