import React, { useCallback } from "react";
import { memoize, partial } from "lodash";

import CrossInCircle from "images/icons/CrossInCircle";

import { PAYMENT_TYPE } from "utils/constant";
import { stringifyArgs } from "utils/stringifyArgs";

import InputBase, { VALUE_TYPES } from "../../../../atoms/InputBase/InputBaseOrder";
import Select from "../../../../atoms/Select";

import styles from "./index.module.scss";

const PaymentTerm = ({ term, disabled, terms, changeTerm, onChangePercent, onDeleteTerm, index, canDeleteTerm }) => {
  const onChangeTerms = useCallback(
    memoize((changedTermType) => (value) => changeTerm(changedTermType, "payment_type", value), stringifyArgs),
    [changeTerm]
  );

  const memoizedPartialValue = useCallback(
    (callback, value) => memoize(partial(callback, value), stringifyArgs),
    []
  )

  return (
    <div className={styles.paymentTerm}>
      <div className={styles.fields}>
        <Select
          value={term.payment_type}
          className={styles.select}
          placeholder="Вариант"
          onChange={onChangeTerms(index)}
          disabled={disabled}
          options={terms}
        />
        <InputBase
          className={styles.input}
          value={term.percent}
          valueType={VALUE_TYPES.NUMBER}
          disabled={disabled}
          onChange={memoizedPartialValue(onChangePercent, index)}
        />
        {term.payment_type === PAYMENT_TYPE.DELAY && (
          <InputBase
            className={styles.input}
            value={term.delay_days}
            valueType={VALUE_TYPES.NUMBER}
            disabled={disabled}
            onChange={(e) => changeTerm(index, "delay_days", e.target.value)}
          />
        )}
      </div>
      {!disabled && canDeleteTerm && (
        <button className={styles.deleteButton} onClick={onDeleteTerm}>
          <CrossInCircle />
        </button>
      )}
    </div>
  );
};

export default React.memo(PaymentTerm);