import cn from "classnames";
import React from "react";

import PopoverOverlay from "../../../../../../UI/molecules/PopoverOverlay/PopoverOverlay";
import MaterialStatusBlock from "./components/MaterialStatusBlock/MaterialStatusBlock";
import StatusBlock from "./components/StatusBlock/StatusBlock";

import { INTERVAL_TYPES, WORK_STATUSES, getHighestMaterialStatus, getWorkStatus } from "../../../../constants";

import styles from "./InfoPopup.module.scss";

const INTERVAL_NAME_MAP = {
  work: "Работа:",
  plan: "План:",
  material: "Материал:",
};

const InfoPopup = ({
  data,
  type,
  interval,
  setOpenPopup,
  setOpenModal,
  estimateCompletedPercent,
  children,
  isOpen,
}) => {
  const workStatus =
    type === INTERVAL_TYPES.work && data.percentage ? getWorkStatus(data.percentage) : WORK_STATUSES.default;
  const materialStatus = type === INTERVAL_TYPES.material ? getHighestMaterialStatus(data) : "";

  const workMeasure = type === INTERVAL_TYPES.work ? data.measure : "";

  const isEstimateDonedPercentShown = type === INTERVAL_TYPES.work && estimateCompletedPercent !== undefined;

  const estimateDonedPercent = isEstimateDonedPercentShown && Math.round(+estimateCompletedPercent * 100);

  const estimateDonedPercentDisplayedValue =
    +estimateCompletedPercent > 0 && estimateDonedPercent < 1 ? "<1%" : `${estimateDonedPercent}%`;

  const isWorkIndicatorsShown =
    type === INTERVAL_TYPES.work && !!data.count_indicators && workStatus !== WORK_STATUSES.default;

  const isMaterialStatusesShown = type === INTERVAL_TYPES.material && data?.length;

  return (
    <PopoverOverlay
      openType="hover"
      isOpen={isOpen}
      className={styles.popoverContainer}
      placement="bottom"
      popoverBorderColor={type === INTERVAL_TYPES.material ? `${materialStatus}Material` : workStatus}
      content={
        <div onMouseLeave={() => setOpenPopup(false)}>
          <div>
            <div className={styles.header}>
              <span>{INTERVAL_NAME_MAP[type]}</span>
              <span className={styles.interval}>{interval}</span>
            </div>

            {isWorkIndicatorsShown && (
              <div className={cn(styles.item, styles.statuses)}>
                {Object.entries(data.count_indicators)?.map(
                  ([status, count]) =>
                    +count > 0 && <StatusBlock measure={workMeasure} count={+count} status={status} key={status} />
                )}
              </div>
            )}

            {isMaterialStatusesShown && (
              <div className={cn(styles.item, styles.statuses)}>
                {data.map((status, index) => (
                  <MaterialStatusBlock status={status} key={index} />
                ))}
              </div>
            )}

            {isEstimateDonedPercentShown && (
              <div className={styles.item}>Итого выполнение: {estimateDonedPercentDisplayedValue}</div>
            )}

            <div className={styles.buttons}>
              <div onClick={() => setOpenModal(true)} className={styles.cardButton}>
                Посмотреть
              </div>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </PopoverOverlay>
  );
};

export default React.memo(InfoPopup);
