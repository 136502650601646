import React from 'react';
import { useSelector } from "react-redux";
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { eventCountSelector } from "redux/modules/common/notifications";

import { useUrlModuleWithObjectId } from 'utils/hooks/useUrlModuleWithObjectId';

import CalendarEvents from "images/icons/navigation/CalendarEvents";

import styles from "./Calendar.module.scss";


const Calendar = () => {
  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const fullUrlModule = useUrlModuleWithObjectId();

  const eventCount = useSelector(eventCountSelector);

  return (
    <NavLink
      to={`/${fullUrlModule}/events/calendar`}
      className={cn(styles.link, {[styles.active]: locationArray.includes("events")})}
    >
      <div 
        className={styles.notyCounterBlock} 
      >
        {eventCount > 0 && (
          <div
            className={cn({ 
              [styles.notyCounterLess]: eventCount < 99, 
              [styles.notyCounter]: eventCount > 99  
            })}
          >
            {eventCount}
          </div>
        )}
        <div className={styles.container}>
          <CalendarEvents />
        </div>
      </div>
    </NavLink>
  )
}

export default React.memo(Calendar);
