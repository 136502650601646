import {
  COMPLETED, PAYMENT_DECISION, PCR_NEED_APPROVE,
  PCR_REJECTED,
  PVR_REJECTED,
  WAITING_PAYMENT,
  WAITING_SHIPMENT
} from "../../../../utils/constant";

import { APPROVAL_TYPES } from "../../../../redux/modules/common/orderCard/approval/constants";

export const checkOrderStatusForApprove = (orderStatus, approvalType) => {
  if (approvalType === APPROVAL_TYPES.payment) return orderStatus === PAYMENT_DECISION;
  if (approvalType === APPROVAL_TYPES.order) return orderStatus === PCR_NEED_APPROVE;

  return (
    orderStatus !== COMPLETED
    && orderStatus !== WAITING_SHIPMENT
    && orderStatus !== WAITING_PAYMENT
    && orderStatus !== PCR_REJECTED
    && orderStatus !== PVR_REJECTED
  );
};