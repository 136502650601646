import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import ProgressBar from "components/UI/atoms/ProgressBar";
import TableReusableRow, { TableReusableCell } from "components/UI/atoms/TableReusable/TableReusableRow";
import ChartPopup from "components/pages/Building/components/ChartPopup/ChartPopup";

import SelectStatus from "../SelectStatus/SelectStatus";

import { TRANSFERRED_STATUS_OPTIONS } from "../../constants";
import { IBuildingInList } from "types/interfaces/Building";

import { stopEventPropagation } from "utils/stopEventPropagation";

import SettingGear from "images/SettingGear";
import EstimateIcon from "images/icons/EstimateIcon";

import styles from "./ObjectsRow.module.scss";

interface IProps {
  object: IBuildingInList;
  viewHandlerPermission: boolean;
  viewEditBuildingPermission: boolean;
  viewBuildingPermission: boolean;
  isTransferred?: boolean;
  onStatusChange?: (object: IBuildingInList, status: string) => void;
}

const ObjectsRow: React.FC<IProps> = ({
  object,
  viewEditBuildingPermission,
  viewHandlerPermission,
  viewBuildingPermission,
  isTransferred,
  onStatusChange,
}) => {
  const history = useHistory();

  const onSettingsClick = useCallback(() => {
    history.push(`projects/edit-project/${object.id}/passport`);
  }, [history, object.id]);

  const onEstimateClick = useCallback(() => {
    history.push(`/constructing/handler/${object.id}`);
  }, [history, object.id]);

  const onRowClick = useCallback(() => {
    if (viewBuildingPermission) history.push(`/constructing/projects/${object.id}`);
  }, [viewBuildingPermission, object.id, history]);

  const statusChangeHandler = useCallback(
    (status: string) => {
      onStatusChange?.(object, status);
    },
    [object, onStatusChange]
  );

  return (
    <TableReusableRow className={styles.row} onClick={onRowClick}>
      <TableReusableCell isNoBreak>{object.name}</TableReusableCell>
      <TableReusableCell isNoBreak>{object.entity_name}</TableReusableCell>
      <TableReusableCell isNoBreak>{object.customer}</TableReusableCell>
      <TableReusableCell className={styles.actionsContainer}>
        {!isTransferred && (
          <ProgressBar
            completed={Math.round(+object?.calculation?.complete_status_percent * 100 || 0)}
            className={styles.progress}
            direction={undefined}
          />
        )}{" "}
        {/* @ts-ignore */}
        {!isTransferred && (
          <ChartPopup
            all={Number(object?.calculation?.budget || 0)}
            completed={Number(object?.calculation?.doned || 0)}
            accepted={Number(object?.calculation?.received || 0)}
            containerClassName={styles.textSize}
            type="rouble"
            popupClassName={undefined}
            onHover={undefined}
            isLast={undefined}
          />
        )}
        {isTransferred && (
          <div onClick={stopEventPropagation}>
            <SelectStatus options={TRANSFERRED_STATUS_OPTIONS} onChange={statusChangeHandler} value={"accepted"} />
          </div>
        )}
        <div className={styles.buttonGroup} onClick={stopEventPropagation}>
          {viewHandlerPermission && (
            <div className={styles.actionButton} onClick={onEstimateClick}>
              Смета
              <EstimateIcon />
            </div>
          )}
          {!isTransferred && viewEditBuildingPermission && (
            <div className={styles.actionButton} onClick={onSettingsClick}>
              <SettingGear color="#fff" />
            </div>
          )}
        </div>
      </TableReusableCell>
    </TableReusableRow>
  );
};

export default ObjectsRow;
