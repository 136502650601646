import React from 'react';
import cn from "classnames";

import styles from './CustomInput.module.scss';


interface ICustomInputProps {
  readOnly?: boolean;
  name: string;
  type?: string;
  placeholder: string;
  value: string;
  className?: string;
  onBlur?: () => void;
  onChange: () => void;
}

const CustomInput: React.FC<ICustomInputProps> = ({ readOnly, name, type, placeholder, value, className, onBlur, onChange }) => {
  return (
    <input
      readOnly={readOnly}
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      className={cn(styles.input, className)}
    />
  )
};

export default CustomInput;
export type { ICustomInputProps };
