import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import {
  HALF_MONTH,
  MONTH,
  WEEK,
  YEAR,
} from "redux/modules/common/building/manufacturing/manufacturing";
import {chartViewModeSelector} from 'redux/modules/common/building/manufacturing/selectors'
import {setManufacturingMonthMarkers} from 'redux/modules/common/building/manufacturing/thunks'

import WeeksLine from "./components/WeeksLine/WeeksLine";
import DaysLine from "./components/DaysLine/DaysLine";
import MonthsLine from "./components/MonthsLine/MonthsLine";

import { monthMarkers } from "../../utils";
import {useRem} from '../../hooks/useRem'
import {useUnitMultiplier} from '../../hooks/useUnitMultiplier'

export interface ICalendarDateLineProps {
  year: number | string;
}

const CalendarDateLine: React.FC<ICalendarDateLineProps> = ({ year }) => {
  const unitMultiplier = useUnitMultiplier()
  const dispatch = useDispatch();
  const chartViewMode = useSelector(chartViewModeSelector);
  const lineRef = useRef<HTMLDivElement>(null);
  const {REM} = useRem();

  useEffect(() => {
    if (!lineRef.current) return;
    const startMonthElements = Array.from(lineRef.current.querySelectorAll(".startMonth"));
    if (startMonthElements.length > 0) {
      compose(dispatch, setManufacturingMonthMarkers, monthMarkers)(startMonthElements);
    }
  }, [lineRef.current, REM, unitMultiplier, year]);

  if (chartViewMode === WEEK) return <WeeksLine year={year} ref={lineRef} />;
  if (chartViewMode === MONTH || chartViewMode === HALF_MONTH)
    return <DaysLine year={year} ref={lineRef} />;
  if (chartViewMode === YEAR) return <MonthsLine year={year} ref={lineRef} />;
  return null;
};

export default React.memo(CalendarDateLine);
