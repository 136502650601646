type propsType = {
    isPlan?: boolean;
    status?: 'planed' | 'actived' | 'completed' | 'accepted' | 'confirmed' | 'to_close' | 'to_payed' | 'payed';
    sharingStatus?: "processed" | "moderation" | "canceled" | "confirmed"
}

type returnedType = {
    text?: string;
    color?: "gray" | "green" | "blue" | "orange" | "violet" | 'red';
    icon?: 'dots' | 'check' | 'triangle'
}

export const manufacturingModalStatusSwitcher = ({
    status,
    isPlan,
    sharingStatus
}: propsType): returnedType => {
    if (!status) {
        return {}
    }
    if (sharingStatus) {
        switch (sharingStatus) {
            case "moderation":
                return {
                    text: "На проверке",
                    color: "orange",
                    icon: "dots"
                }
            case "canceled":
                return {
                    text: "Отклонено",
                    color: "red",
                    icon: "triangle"
                }
            case "confirmed":
                return {
                    text: "Принятo",
                    color: "violet",
                    icon: "check"
                }
        }
    }
    if (isPlan) {
        switch (status) {
            case "actived":
                return {
                    text: "В производстве",
                    color: "gray"
                }
            case "completed":
                return {
                    text: "План",
                    color: "blue"
                }
            case "planed":
                return {
                    text: "План",
                    color: "gray"
                }
        }
    } else {
        switch (status) {
            case "completed":
                return {
                    text: "Bыполнено",
                    color: "blue"
                }
            case "accepted":
                return {
                    text: "Принято",
                    color: "green"
                }
            case "confirmed":
                return {
                    text: "Подтверждено",
                    color: "gray"
                }
            case "to_close":
                return {
                    text: "Сдано",
                    color: "gray"
                }
            case "to_payed":
                return {
                    text: "К оплате",
                    color: "blue"
                }
            case "payed":
                return {
                    text: "Оплачено",
                    color: "green"
                }
        }
    }
    return {}
}

export const manufacturingMaterialsStatusSwitcher = (interval: any, materialIndex: number) => {
    if (interval?.work_status === "to_payed") {
        return {
            text: "К оплате",
            color: "blue"
        }
    }

    if (interval?.stock_using_materials[materialIndex] && !!interval.stock_using_materials[materialIndex].confirm_count) {
        return {
            text: "Подтверждено",
            color: "gray"
        }
    }

    return {
        text: "Выдан со склада",
        color: "gray"
    }
}