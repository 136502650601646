import React from 'react';
import cn from "classnames";

import SliderModal from 'components/UI/atoms/SliderModal';
import CheckIcon from '../CheckIcon/CheckIcon';

import styles from "./ModalContainer.module.scss";
import DotsIcon from '../CheckIcon/DotsIcon';
import TicketSharedCircle from '../../../../../../images/TicketSharedCircle'
import TicketAcceptedCircle from '../../../../../../images/TicketAcceptedCircle'
import TicketDeniedTriangle from '../../../../../../images/TicketDeniedTriangle'


type propsType = {
  isOpen: boolean;
  name?: string;
  onClose: () => void;
  justification?: string;
  children: React.ReactNode;
  statusColor?: "gray" | "green" | "blue" | "orange" | "violet";
  statusText?: string;
  customHeadline?: React.ReactNode;
  headlineClassName?: string
  statusIcon?: 'dots' | 'check' | 'triangle'
}

function ModalContainer({ children, isOpen, onClose, justification, name, statusColor = "green", statusText, customHeadline, headlineClassName, statusIcon }: propsType) {
  return (
    <SliderModal isOpen={isOpen} closeHandler={onClose} className="">
      <div className={cn(styles.headline, headlineClassName)}>
        <h2 className={styles.title}>{name}</h2>
        {(justification && !statusText) && <div className={styles.justification}>{justification}</div>}
        {(justification && statusText) && (
          <div className={styles.subheader}>
              <div className={cn(styles.justification, styles.ml0)}>{justification}</div>
              <div className={cn(styles.status, styles[statusColor])}>
                {statusIcon === 'dots' && <TicketSharedCircle backgroundColor={'white'} signColor={'#E8527A'}/>}
                {statusIcon === 'check' && <TicketAcceptedCircle backgroundColor={'black'} signColor={'#D5C9DF'}/>}
                {statusIcon === 'triangle' && <TicketDeniedTriangle backgroundColor={'white'} signColor={'#E8527A'}/>}
                {!statusIcon && (statusColor !== "orange" ? <CheckIcon /> : <DotsIcon />)}
                {statusText}
              </div>
          </div>
        )}
        {customHeadline} 
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </SliderModal>
  )
}

export default React.memo(ModalContainer);