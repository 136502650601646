import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { compose } from "redux";
import styled from "styled-components";

import {
  allPermissionsDataSelector,
  allPermissionsLoadingSelector,
  userPermissionsDataSelector,
  userPermissionsLoadingSelector,
} from "redux/modules/common/permissions/reducer";
import { getAllPermissions } from "redux/modules/common/permissions/thunks/getAllPermissions";
import { getUserPermissions } from "redux/modules/common/permissions/thunks/getUserPermissions";
import { updateUserPermissions } from "redux/modules/common/permissions/thunks/updateUserPermissions";
import { getRoles, updateUser } from "redux/modules/common/userRedux";

import { Alert } from "./UI/alert/Alert";
import { InputData } from "./components/InputData";
import { Navigation } from "./components/Navigation";
import PermissionsSettings from "./components/PermissionsSettings/PermissionsSettings";
import { Spinner } from "components/UI/Spinner/Spinner";
import ButtonBase from "components/UI/atoms/ButtonBase";
import TemplateBase from "components/UI/templates/TemplateBase/index";

import { PermissionPropertiesEnum } from "./enums";
import { MATCHED_CHILD_PERMISSIONS } from "constants/permissions/matchedChildPermissions";

import useRelatesChecker from "hooks/useRelatesChecker";

import { buildRelatesPermissions } from "./utils/buildRelatesPermissions";
import { matchPermissionsLabels } from "./utils/matchPermissionsLabels";
import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import "./UserPage.scss";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  position: relative;
`;
const Text = styled.div`
  font-size: 18px;
  font-weight: 400;
`;
const InnerData = styled.div`
  margin-top: 17px;
`;

export const UserPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userPermissions = useSelector(userPermissionsDataSelector);
  const userPermissionsAreLoading = useSelector(userPermissionsLoadingSelector);
  const allPermissions = useSelector(allPermissionsDataSelector);
  const allPermissionsAreLoading = useSelector(allPermissionsLoadingSelector);

  const [headerData, setHeaderData] = useState(props.location.state?.row);

  const [block, setBlock] = useState(props.location.state?.row?.is_blocked);

  const [changedUser, setChangedUser] = useState(props.location.state?.row);

  const [formValid, setFormValid] = useState(false);

  const isWithoutPermissions = !isAllModulesAllowed();

  const onCheckUserFullAccess = useCallback(
    (e) =>
      setChangedUser((prevState) => ({
        ...prevState,
        full_access: e.target.checked,
      })),
    []
  );

  const relatesPermissions = useMemo(() => buildRelatesPermissions(MATCHED_CHILD_PERMISSIONS), []);

  const defaultPermissions = useMemo(() => {
    if (!userPermissions) return {};

    const result = {};
    userPermissions.forEach((permission) => {
      result[permission.name] =
        permission.property === PermissionPropertiesEnum.optionalOn ||
        permission.property === PermissionPropertiesEnum.on;
    });
    return result;
  }, [userPermissions]);
  const relatesChecker = useRelatesChecker(relatesPermissions, defaultPermissions);

  const onChangeRelatesChecked = useCallback(
    (permissionName, isChecked) => relatesChecker.changeChecked(permissionName, isChecked),
    [relatesChecker.changeChecked]
  );

  const checkHavePermissionsChanges = useCallback(
    (checkedPermissions) =>
      Object.entries(checkedPermissions).some(([permission, isChecked]) => {
        const permissionIsOnAndNotDefault = isChecked && defaultPermissions[permission] === undefined;
        const permissionIsDefaultAndChanged =
          defaultPermissions[permission] !== undefined && defaultPermissions[permission] !== isChecked;
        return permissionIsOnAndNotDefault || permissionIsDefaultAndChanged;
      }),
    [defaultPermissions]
  );

  const filterNotChangedPermissions = useCallback(
    (checkedPermissions) => {
      const userPermissionsByNames = {};
      userPermissions.forEach((permission) => (userPermissionsByNames[permission.name] = permission));

      const filteredPermissions = {};
      Object.entries(checkedPermissions).forEach(([name, isChecked]) => {
        if (!userPermissionsByNames[name]) return;

        const property = userPermissionsByNames[name].property;
        if (property === PermissionPropertiesEnum.on || property === PermissionPropertiesEnum.off) return;

        filteredPermissions[name] = isChecked;
      });
      return filteredPermissions;
    },
    [userPermissions]
  );

  const saveUserChanges = useCallback(() => {
    if (checkHavePermissionsChanges(relatesChecker.items)) {
      compose(dispatch, updateUserPermissions)(changedUser.id, filterNotChangedPermissions(relatesChecker.items));
    }

    compose(dispatch, updateUser)(changedUser.id, {
      ...changedUser,
      roles: changedUser.roles.map((role) => role.id),
    }).then((updatedUser) => setHeaderData(updatedUser));

    history.replace({
      pathname: props.location.pathname,
      state: { row: changedUser, edit: true },
    });
  }, [
    checkHavePermissionsChanges,
    relatesChecker.items,
    dispatch,
    changedUser,
    props.location.pathname,
    history,
    filterNotChangedPermissions,
  ]);

  const matchedWithUserAllPermissions = useMemo(() => {
    if (!allPermissions || !userPermissions || isWithoutPermissions) return [];

    const permissionsProperties = {};
    userPermissions.forEach((permission) => (permissionsProperties[permission.name] = permission.property));

    const existedUserPermissionsWithProperties = allPermissions
      .filter(({ name }) => permissionsProperties[name])
      .map((permission) => ({
        ...permission,
        property: permissionsProperties[permission.name],
      }));
    return matchPermissionsLabels(existedUserPermissionsWithProperties);
  }, [allPermissions, isWithoutPermissions, userPermissions]);

  useEffect(() => {
    setBlock(props.location.state?.row?.is_blocked);
  }, [props.location.state?.row?.is_blocked]);

  useEffect(() => {
    if (isWithoutPermissions) return;

    compose(dispatch, getUserPermissions)(props.location.state?.row?.id);
  }, [props.location.state?.row?.id]);

  useEffect(() => {
    compose(dispatch, getRoles)();
    compose(dispatch, getAllPermissions)();
  }, []);

  if ((userPermissionsAreLoading || allPermissionsAreLoading) && !isWithoutPermissions)
    return (
      <TemplateBase>
        <Spinner />
      </TemplateBase>
    );

  return (
    <TemplateBase>
      <Wrapper>
        <Navigation
          block={block}
          setBlock={setBlock}
          innerInput={changedUser}
          setInnerInput={setChangedUser}
          location={props.location}
          headerData={headerData}
        />
        <Container className="container">
          <Alert />
          <InnerData>
            <Text>Основные сведения</Text>
            <InputData
              innerInput={changedUser}
              setInnerInput={setChangedUser}
              setFormValid={setFormValid}
              isEditable={props.location.state.edit}
            />
            {!isWithoutPermissions && (
              <PermissionsSettings
                allPermissions={matchedWithUserAllPermissions}
                allPermissionsCount={userPermissions.length}
                checkedUserPermissions={relatesChecker.items}
                onChangeCheckedUserPermissions={onChangeRelatesChecked}
                onCheckUserPermission={relatesChecker.changeChecked}
                canEdit={props.location.state.edit}
              />
            )}
            <footer className="user-page__footer">
              {props.location.state.edit && (
                <ButtonBase onClick={saveUserChanges} disabled={!formValid} primary medium>
                  Сохранить
                </ButtonBase>
              )}
            </footer>
          </InnerData>
        </Container>
      </Wrapper>
    </TemplateBase>
  );
};
