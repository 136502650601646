import { message } from "antd";
import { Dispatch } from "redux";

import { 
  ADD_MORE_SERVICE_ACT_FILES, 
  CUT_OFF_SERVICE_ACT_FILE, 
  SET_SERVICE_ACT, 
  SET_SERVICE_ACT_FILES,
  SET_SERVICE_ACTS_LIST_LOADING,
  SET_SERVICE_ACT_LOADING, 
  setServiceActsList
} from "./actions";
import { serviceActsApi } from "./api";
import { IServiceAct } from "types/interfaces/ServiceAct";

import { errorCatcher } from "utils/errorCatcher";
import { IActsListFilter } from "./types";


export const getServiceActsList = (params: IActsListFilter) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SERVICE_ACTS_LIST_LOADING,
    payload: true
  });

  serviceActsApi
    .getActsList(params)
    .then(({ data }) => {
      dispatch(setServiceActsList(data))
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch({
        type: SET_SERVICE_ACTS_LIST_LOADING,
        payload: false
      })
    })
};

export const getServiceAct = (id: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SERVICE_ACT_LOADING,
    payload: true
  });

  serviceActsApi
    .getAct(id)
    .then(({ data }) => {
      dispatch({
        type: SET_SERVICE_ACT,
        payload: data
      })
    })
    .catch(errorCatcher)
    .finally(() => {
      dispatch({
        type: SET_SERVICE_ACT_LOADING,
        payload: false
      })
    });
};

export const changeServiceActNumber = (actId: string, number: string) => (dispatch: Dispatch) => {
  if (!number) return;

  serviceActsApi
    .changeServiceActNumber(number, actId)
    .then(() => {
      message.success("Номер акта успешно изменен");
    })
    .catch(errorCatcher);
};

export const changeAct = (actId: string, data: Partial<IServiceAct>, successMsg?: string) => (dispatch: Dispatch) => {
  serviceActsApi
    .changeAct(actId, data)
    .then(({ data }) => {
      successMsg && message.success(successMsg);

      dispatch({
        type: SET_SERVICE_ACT,
        payload: data
      })
    })
    .catch(errorCatcher);
};

export const acceptServiceAct = (actId: string, data: any) => (dispatch: Dispatch) => {
  serviceActsApi
    .acceptAct(actId, data)
    .then(({ data }) => {
      message.success("Акт успешно принят");

      dispatch({
        type: SET_SERVICE_ACT,
        payload: data
      })
    })
    .catch(errorCatcher);
};

export const getServiceActFiles = (actId: string) => (dispatch: Dispatch) => {
  serviceActsApi
    .getActFiles(actId)
    .then(({data}) => {
      dispatch({
        type: SET_SERVICE_ACT_FILES,
        payload: data.results
      })
    });
};

export const postServiceActFiles = (actId: string, files: File[]) => (dispatch: Dispatch) => {
  serviceActsApi
    .postActFiles(actId, files)
    .then(({data}) => {
      dispatch({
        type: ADD_MORE_SERVICE_ACT_FILES,
        payload: data
      })
    })
    .catch(errorCatcher);
};

export const deleteServiceActFile = (actId: string, fileId: number) => (dispatch: Dispatch) => {
  serviceActsApi
    .deleteActFile(actId, fileId)
    .then(() => {
      dispatch({
        type: CUT_OFF_SERVICE_ACT_FILE,
        payload: fileId
      })
    })
    .catch(errorCatcher);
};