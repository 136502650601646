import React from "react";
import styles from "../../../../Manufacturing.module.scss";
import moment from "moment";
import { WEEKDAY_BUBBLE_WIDTH_REM } from "../../../../constants";
import {generateWeeksForLine} from '../../../../utils'
import {ICalendarBackgroundProps} from '../../CalendarBackground'
import {useUnitMultiplier} from '../../../../hooks/useUnitMultiplier'

const WeeksBackground: React.FC<ICalendarBackgroundProps> = ({ year, unitHeight }) => {
  const unitMultiplier = useUnitMultiplier()
  const weeks = generateWeeksForLine(+year)
  return (
    <div className={styles.weeksLine} style={{ height: `${unitHeight * 3}rem` }}>
      {weeks.map((item, index) => {

        const currentDayLineOffset = moment().isBetween(moment(item.week.day(0)), moment(item.week.day(6)))
          ? moment().weekday()
          : undefined;

        return (
          <React.Fragment key={index}>
            <div
              className={styles.weekBackground}
              style={{
                left: `${index * 7 * unitMultiplier}rem`,
                width: `${7 * unitMultiplier}rem`,
                backgroundColor: index % 2 === 0 ? "rgba(235, 235, 235, 0.3)" : "transparent",
              }}
            />
            {currentDayLineOffset !== undefined && (
              <div
                className={styles.currentLine}
                style={{
                  left: `${(index * 7 + currentDayLineOffset) * unitMultiplier + WEEKDAY_BUBBLE_WIDTH_REM / 2}rem`,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default React.memo(WeeksBackground);
