import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { objectsBreadcrumbsSelector } from "../../../redux/modules/common/building/objects";
import {
  getRangedEvents,
  getTimeline,
  rangedEventsSelector,
  timeLineSelector,
} from "../../../redux/modules/purchaser/events";

import TemplateBase from "../../UI/templates/TemplateBase";

import CalendarHeader from "./CalendarHeader/CalendarHeader";
import CalendarMain from "./CalendarMain/CalendarMain";
import CalendarBottom from "./CalendarBottom";
import SliderModalCalendar from "./SliderModalCalendar";

import styles from "./index.module.scss";

const Calendar = () => {
  const [date, setDate] = useState(moment());
  const [dateDays, setDateDays] = useState(null);
  const [eventSelect, setEventSelect] = useState(null);
  const [objSelect, setObjSelect] = useState(null);
  const [periodSelect, setPeriodSelect] = useState(null);
  const [isSlideModalOpen, setIsSlideModalOpen] = useState(false);
  const [dateAfter, setDateAfter] = useState(moment(date).startOf("month"));
  const [dateBefore, setDateBefore] = useState(moment(date).endOf("month"));

  const targetDay = moment({
    day: 1,
    month: moment(date).month(),
    year: moment(date).year(),
  });

  const rangedEvents = useSelector(rangedEventsSelector);
  const timeLine = useSelector(timeLineSelector);

  const targetYear = moment(date).year();
  const targetMonth = moment(date).month();

  const dispatch = useDispatch();
  const projects = useSelector(objectsBreadcrumbsSelector);

  const selectPeriodArr = [
    {
      id: "month",
      name: "month",
      label: "Месяц",
    },
    {
      id: "week",
      name: "week",
      label: "Неделя",
    },
    {
      id: "all",
      name: "all",
      label: "Весь период",
    },
  ];

  const objSelectArr = () =>
    projects?.results.map((el) => ({
      id: el.id,
      name: el.id,
      label: el.name,
    })) || [];

  const newObj = [
    {
      id: "projects",
      name: "Все проекты",
      label: "Все проекты",
    },
    ...objSelectArr(),
  ];

  useEffect(() => {
    dispatch(
      getRangedEvents({
        date_after: dateAfter.format("YYYY-MM-DD"),
        date_before: dateBefore.format("YYYY-MM-DD"),
        events: eventSelect === "events" ? null : eventSelect,
        building_id: isNaN(Number(objSelect)) ? null : objSelect,
      })
    );
  }, [dateAfter, dateBefore, eventSelect, objSelect, periodSelect]);

  useEffect(() => {
    periodSelect === "all" &&
      dispatch(
        getTimeline({
          events: eventSelect === "Событие" ? null : eventSelect,
          building_id: objSelect === "Обьект" ? null : objSelect,
        })
      );
  }, [dateAfter, dateBefore, eventSelect, objSelect, periodSelect]);

  return (
    <TemplateBase contentClassName={styles.templateContent}>
      <SliderModalCalendar
        isOpen={isSlideModalOpen}
        closeHandler={() => setIsSlideModalOpen(false)}
        dateAfterDefault={isSlideModalOpen === "isSingle" ? dateDays : moment(date).startOf("month")}
        dateBeforeDefault={isSlideModalOpen === "isSingle" ? dateDays : moment(date).endOf("month")}
      />
      <div className={styles.container}>
        <CalendarHeader
          date={date}
          setDate={setDate}
          setEventSelect={setEventSelect}
          setObjSelect={setObjSelect}
          objSelect={objSelect}
          setIsSlideModal={setIsSlideModalOpen}
          objSelectArr={newObj}
          setDateAfter={setDateAfter}
          setDateBefore={setDateBefore}
          eventSelect={eventSelect}
          periodSelect={periodSelect}
          setPeriodSelect={setPeriodSelect}
          selectPeriodArr={selectPeriodArr}
        />
        <CalendarMain
          setDateDays={setDateDays}
          targetDay={targetDay}
          targetMonth={targetMonth}
          targetYear={targetYear}
          setIsSlideModal={setIsSlideModalOpen}
          rangedEvents={rangedEvents}
          periodSelect={periodSelect}
        />
        <CalendarBottom
          date={date}
          targetDay={targetDay}
          targetMonth={targetMonth}
          targetYear={targetYear}
          periodSelect={periodSelect}
          timeLine={timeLine}
          building_id={objSelect}
          events={eventSelect}
        />
      </div>
    </TemplateBase>
  );
};

export default Calendar;
