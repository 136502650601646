import React, { useCallback, useEffect, useMemo } from "react";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import {
  ADD_SELECTED_PRODUCTS,
  ADD_SELECTED_SUBSECTIONS,
  DELETE_SELECTED_PRODUCTS,
  DELETE_SELECTED_SUBSECTIONS,
  estimateMeasureSelector,
  loadIntervals,
  productsOverviewSelector,
  selectedProductsSelector,
  selectedSubsectionsSelector,
} from "redux/modules/common/building/process";
import {
  expendituresBySectionsSelector,
  expendituresSubMaterialsSelector,
  getExpendituresBySection,
  resetExpendituresSubMaterialsAction,
} from "redux/modules/common/building/sections/sections";

import { TabsEnum } from "components/pages/Building/enums";
import { ESTIMATE_STATES_IDS } from "components/pages/Handler/constants";
import SubSection from "components/pages/Building/components/SubSection/SubSection";
import { useGetSectionPlan } from "components/modals/ManufacturingPlanSectionModal/useGetSectionPlan";
import { useSectionPlan } from "./useSectionPlan";

import IExpenditure from "types/interfaces/Expenditure";
import ISection from "types/interfaces/Section";
import { IExpenditureTypesFiltersInBuilding } from "../../types";

import { EXPENDITURE_TYPES } from "utils/constant";

export interface ISubSectionContainerProps {
  objectId: number;
  section: ISection;
  editable: boolean;
  activeTab: TabsEnum;
  date_start: string;
  date_end: string;
  isShared: boolean;
  isIndicatorsOpen: boolean;
  canSharing: boolean;
  permissions: {
    canAddFiles: boolean;
    canDeleteFiles: boolean;
    canAddPlan: boolean;
    canAddProgress: boolean;
  };
  filters: IExpenditureTypesFiltersInBuilding;
}

const SubSectionContainer = ({
  objectId,
  section,
  date_start,
  date_end,
  activeTab,
  editable,
  filters,
  ...props
}: ISubSectionContainerProps) => {
  const dispatch = useDispatch();
  const measure = useSelector(estimateMeasureSelector);
  const selectedProducts = useSelector(selectedProductsSelector);
  const intervals = useSelector((state: any) => state.process.intervals);
  const productsOverview = useSelector(productsOverviewSelector);
  const selectedSubsections = useSelector(selectedSubsectionsSelector);

  const expendituresBySections = useSelector(expendituresBySectionsSelector);
  const expenditures = expendituresBySections[section.id];
  const expendituresSubMaterials = useSelector(
    expendituresSubMaterialsSelector
  );

  const onCheck = useCallback(
    (isChecked: boolean, itemId: number) => {
      if (itemId !== section.id) {
        dispatch({
          type: isChecked ? ADD_SELECTED_PRODUCTS : DELETE_SELECTED_PRODUCTS,
          payload: itemId,
        });
      } else {
        dispatch({
          type: isChecked
            ? ADD_SELECTED_SUBSECTIONS
            : DELETE_SELECTED_SUBSECTIONS,
          payload: itemId,
        });
      }
    },
    [section.id]
  );

  const typeFilters = React.useMemo(() => {
    const res = new URLSearchParams({
      expenditure_type: EXPENDITURE_TYPES.WORK,
    });
    if (!filters) return res;
    for (const key in filters) {
      // @ts-ignore
      if (filters[key]) {
        res.append("expenditure_type", key);
      }
    }
    return res;
  }, [filters]);

  useEffect(() => {
    if (!section) return;
    compose<any>(dispatch, getExpendituresBySection)(
      {
        building: objectId,
        section: section.id,
        estimateState: ESTIMATE_STATES_IDS.PRODUCTION,
      },
      typeFilters
    );
  }, [objectId, section?.id, typeFilters]);

  useEffect(() => {
    return () => {
      compose(dispatch, resetExpendituresSubMaterialsAction)();
    };
  }, []);

  useEffect(() => {
    dispatch(
      loadIntervals(objectId, section.id, activeTab, date_start, date_end)
    );
  }, [objectId, section.id, activeTab, date_start, date_end, dispatch]);

  const { planData, refetchSectionPlan } = useSectionPlan(
    section.id,
    activeTab === TabsEnum.plan
  );

  if (!expenditures || !section) return null;

  return (
    <SubSection
      expenditures={expenditures.results || []}
      objectId={objectId}
      section={section}
      measure={measure}
      activeTab={activeTab}
      onCheck={onCheck}
      expendituresSubMaterials={expendituresSubMaterials}
      checkedExpenditures={selectedProducts}
      checkedSections={selectedSubsections}
      intervals={intervals}
      expendituresOverview={productsOverview}
      loadProduct={() =>
        dispatch(
          loadIntervals(objectId, section.id, activeTab, date_start, date_end)
        )
      }
      sectionPlanData={planData}
      onReloadSubSections={refetchSectionPlan}
      {...props}
    />
  );
};

export default React.memo(SubSectionContainer);
