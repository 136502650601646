import update from 'immutability-helper';
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { createSelector } from 'reselect';

export const moduleName = 'matrixPurchaser';

const LOAD_MATRIX = `${moduleName}/LOAD_MATRIX`;
const LOAD_ADD_PRODUCT_MATRIX = `${moduleName}/LOAD_ADD_PRODUCT_MATRIX`;
const LOAD_PRODUCTS_MATRIX = `${moduleName}/LOAD_PRODUCTS_MATRIX`;
const LOAD_PRODUCT_START = `${moduleName}/LOAD_PRODUCT_START`;
const LOAD_PRODUCT_SUCCESS = `${moduleName}/LOAD_PRODUCT_SUCCESS`;
const CLEAR_CURRENT_PRODUCT = `${moduleName}/CLEAR_CURRENT_PRODUCT`;
const SET_STOCK_FILTER = `${moduleName}/SET_STOCK_FILTER`;
const SET_PARTNER_FILTER = `${moduleName}/SET_PARTNER_FILTER`;
const CLEAR_FILTERS = `${moduleName}/CLEAR_FILTERS`;
const UPDATE_SEARCH_INPUT_VALUE = `${moduleName}/UPDATE_SEARCH_INPUT_VALUE`;
const UPDATE_SYNC_PRODUCT = `${moduleName}/UPDATE_SYNC_PRODUCT`;
const SET_CURRENT_SYNCED_PRODUCTS = `${moduleName}/SET_CURRENT_SYNCED_PRODUCTS`;
const UPDATE_PRODUCT_IN_MATRIX = `${moduleName}/UPDATE_PRODUCT_IN_MATRIX`;
const LOAD_ABSTRACT_PRODUCTS_IN_SELECT = `${moduleName}/LOAD_ABSTRACT_PRODUCTS_IN_SELECT`;
const ABSTRACT_PRODUCTS_SELECT_SCROLL_TO_BOTTOM = `${moduleName}/ABSTRACT_PRODUCTS_SELECT_SCROLL_TO_BOTTOM`;
const CLEAR_ABSTRACT_PRODUCTS_IN_SELECT = `${moduleName}/CLEAR_ABSTRACT_PRODUCTS_IN_SELECT`;
const SET_PAGINATION_STATE = `${moduleName}/SET_PAGINATION_STATE`;
const UPDATE_CATEGORY_VALUE = `${moduleName}/UPDATE_CATEGORY_VALUE`;
const PROVIDER_PRODUCTS_SEARCH_RESULT = `${moduleName}/PROVIDER_PRODUCTS_SEARCH_RESULT`;
const SET_PRICE_SORT = `matrixProvider/SET_PRICE_SORT`;
const SET_FREQ_SORT = `matrixProvider/SET_FREQ_SORT`;
const SET_NEW_SORTING = `matrixProvider/SET_NEW_SORTING`;


const initialState = {
  productsMatrix: {
    entities: {
      products: {},
    },
    results: [],
  },
  currentSyncedProducts: {},
  currentProduct: {},
  filtersState: {
    search: '',
    partner: '',
    stock: null,
    category: null,
  },
  pagination: {
    params: { limit: 50, offset: 0 },
    page: 1,
  },
  abstractProductsInSelect: {
    products: [],
    next: null,
  },
  sorting: null,
  loading: true,
  addProductLoading: true
};

/*
  Reducer
*/

export default (matrixState = initialState, action) => {
  const { payload, type } = action;

  switch (type) {

    case LOAD_MATRIX:
      return {
        ...matrixState,
        loading: true
      };

    case LOAD_ADD_PRODUCT_MATRIX:
      return {
        ...matrixState,
        addProductLoading: true
      };

    case LOAD_PRODUCTS_MATRIX:
      return {
        ...matrixState,
        productsMatrix: payload,
        loading: false
      };

    case LOAD_PRODUCT_START:
      return {
        ...matrixState,
        currentProduct: {
          isLoading: true,
        },
      };

    case LOAD_PRODUCT_SUCCESS:
      return {
        ...matrixState,
        currentProduct: {
          isLoading: false,
          ...payload,
        },
      };

    case LOAD_ABSTRACT_PRODUCTS_IN_SELECT:
      return {
        ...matrixState,
        abstractProductsInSelect: {
          next: payload.next,
          products: [...payload.results],
        },
      };

    case CLEAR_ABSTRACT_PRODUCTS_IN_SELECT:
      return {
        ...matrixState,
        abstractProductsInSelect: {
          next: null,
          products: [],
        },
      };

    case ABSTRACT_PRODUCTS_SELECT_SCROLL_TO_BOTTOM:
      return {
        ...matrixState,
        abstractProductsInSelect: {
          next: payload.next || null,
          products: [...matrixState.abstractProductsInSelect.products, ...payload.results],
        },
      };

    case CLEAR_CURRENT_PRODUCT:
      return {
        ...matrixState,
        currentProduct: {},
      };

    case SET_CURRENT_SYNCED_PRODUCTS:
      return {
        ...matrixState,
        currentSyncedProducts: payload,
      };

    case UPDATE_SYNC_PRODUCT:
      return {
        ...matrixState,
        currentSyncedProducts: {
          ...matrixState.currentSyncedProducts,
          [payload.productId]: payload.selectedValue,
        },
      };

    case UPDATE_PRODUCT_IN_MATRIX:
      return update(matrixState, {
        productsMatrix: { entities: { products: { [payload.id]: { $set: payload } } } },
      });

    case SET_STOCK_FILTER:
      return update(matrixState, { filtersState: { stock: { $set: payload } } });

    case SET_PARTNER_FILTER:
      return update(matrixState, { filtersState: { partner: { $set: payload } } });

    case SET_PRICE_SORT: {
      return update(matrixState, { filtersState: { order_price: { $set: payload } } });
    }

    case SET_FREQ_SORT: {
      return update(matrixState, { filtersState: { order_freq: { $set: payload } } });
    }

    case CLEAR_FILTERS:
      return {
        ...matrixState,
        filtersState: {
          ...matrixState.filtersState,
          search: '',
        },
      };

    case UPDATE_SEARCH_INPUT_VALUE:
      return update(matrixState, { filtersState: { search: { $set: payload } } });

    case UPDATE_CATEGORY_VALUE:
      return update(matrixState, { filtersState: { category: { $set: payload } } });

    case SET_PAGINATION_STATE:
      return {
        ...matrixState,
        pagination: {
          params: payload.params,
          page: payload.page,
        },
      };

    case PROVIDER_PRODUCTS_SEARCH_RESULT:
      return {
        ...matrixState,
        providersProducts: payload,
        addProductLoading: false
      };

    case SET_NEW_SORTING:
      return update(matrixState, {
        sorting: { $set: payload }
      });

    default:
      return matrixState;
  }
};

/*
  Selectors
*/

export const stateSelector = state => state[moduleName];
export const productsMatrixSelector = createSelector(stateSelector, state => state.productsMatrix);
export const productsSelector = createSelector(
  productsMatrixSelector,
  productsMatrix => productsMatrix.entities.products,
);
export const currentSyncedProductsSelector = createSelector(
  stateSelector,
  state => state.currentSyncedProducts,
);
export const filtersStateSelector = createSelector(stateSelector, state => state.filtersState);
export const paginationStateSelector = createSelector(stateSelector, state => state.pagination);
export const abstractProductsInSelectSelector = createSelector(
  stateSelector,
  state => state.abstractProductsInSelect,
);
export const currentProductSelector = createSelector(stateSelector, state => state.currentProduct);
export const loadingStateSelector = createSelector(stateSelector, state => state.loading);
export const loadingAddProductStateSelector = createSelector(stateSelector, state => state.addProductLoading);
export const sortingStateSelector = createSelector(stateSelector, state => state.sorting);


/*
  Action creators
*/

export const clearAbstractProductsInSelect = () => ({ type: CLEAR_ABSTRACT_PRODUCTS_IN_SELECT });

export const updateSyncProduct = product => ({
  type: UPDATE_SYNC_PRODUCT,
  payload: product,
});

export const clearCurrentProduct = () => ({ type: CLEAR_CURRENT_PRODUCT });

export const setStockFilter = stock => ({
  type: SET_STOCK_FILTER,
  payload: stock,
});

export const setPriceSort = stock => ({
  type: SET_PRICE_SORT,
  payload: stock,
});

export const setFreqSort = stock => ({
  type: SET_FREQ_SORT,
  payload: stock,
});

export const updateSearchInputValue = value => ({
  type: UPDATE_SEARCH_INPUT_VALUE,
  payload: value,
});

export const updateCategoryValue = value => ({
  type: UPDATE_CATEGORY_VALUE,
  payload: value,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});

export const setPaginationState = (params, page) => ({
  type: SET_PAGINATION_STATE,
  payload: { params, page },
});

export const sortTableTab = (tab) => ({
  type: SET_NEW_SORTING,
  payload: tab
});

/*
  Thunks
*/

export const cleanFilters = () => {
  return dispatch => dispatch(clearFilters());
};

export const updateProductInMatrix = (entityId, productId, data) => {
  return dispatch => {
    axios.put(`/purchasers/${entityId}/products/${productId}/`, data).then(res =>
      dispatch({
        type: UPDATE_PRODUCT_IN_MATRIX,
        payload: res.data,
      }),
    );
  };
};

export const loadMatrix = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (entityId, paginationParams, filterParams, search = null) => {
    if (ge_cancel) ge_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: search
      },
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      })
    };

    return dispatch => {
      dispatch({
        type: LOAD_MATRIX
      });
      axios.get(`/entities/${entityId}/products-purchaser/`, config).then(response => {
        const productSchema = new schema.Entity('products');
        const productListSchema = new schema.Array(productSchema);
        const normalizedData = normalize(response.data.results, productListSchema);
        const result = {count: response.data.count, ...normalizedData, results: response.data.results};

        dispatch({
          type: LOAD_PRODUCTS_MATRIX,
          payload: result,
        });
      });
    };
  };
})();

export const setPartnerFilter = (partnerId) => dispatch => dispatch({
  type: SET_PARTNER_FILTER,
  payload: partnerId,
});

export const loadPvrMatrix = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, partner, paginationParams, filterParams, search = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        entity_id: partner,
        // partner: data.partner.id,
        ordering: search
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      })
    };
    return dispatch => {
      dispatch({
        type: LOAD_MATRIX
      });
      axios.get(`/entities/${entityId}/organisations/partners/products/`, config).then(
        response => {
          const productSchema = new schema.Entity('products');
          const productListSchema = new schema.Array(productSchema);
          const normalizedData = normalize(response.data.results, productListSchema);
          const result = {count: response.data.count, ...normalizedData, results: response.data.results};

          dispatch({
            type: LOAD_PRODUCTS_MATRIX,
            payload: result,
          });
        },
      );
    };
  }
})();

export const loadAbstractProductsInSelect = filterParams => {
  const config = {
    params: {
      ...filterParams,
    },
  };

  return dispatch => {
    axios
      .get('/catalog/products/', config)
      .then(res => dispatch({ type: LOAD_ABSTRACT_PRODUCTS_IN_SELECT, payload: res.data }));
  };
};

export const onAbstractProductsSelectScrollToBottom = next => {
  const api = next.slice(0, 4) + 's' + next.slice(4); // http to https (needs fix on backend(?))

  return dispatch => {
    axios
      .get(api)
      .then(res =>
        dispatch({ type: ABSTRACT_PRODUCTS_SELECT_SCROLL_TO_BOTTOM, payload: res.data }),
      );
  };
};

export const setCurrentSyncedProducts = products => {
  const syncProducts = Object.keys(products).reduce(
    (acc, productId) => ({
      ...acc,
      [productId]: products[productId].abstract_product
        ? {
          value: products[productId].abstract_product.id,
          label: products[productId].abstract_product.name,
        }
        : null,
    }),
    {},
  );

  return {
    type: SET_CURRENT_SYNCED_PRODUCTS,
    payload: syncProducts,
  };
};

export const loadProduct = (entityId, productId) => {
  return dispatch => {
    dispatch({
      type: LOAD_PRODUCT_START,
    });

    axios
      .get(`/purchasers/${entityId}/products/${productId}/`)
      .then(response =>
        dispatch({
          type: LOAD_PRODUCT_SUCCESS,
          payload: response.data,
        }),
      )
      .catch(error => console.error(error));
  };
};

export const loadMyProvidersProducts = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (entityId, paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: sorting,
      },
      cancelToken: new CancelToken((c) => {
        ld_cancel = c;
      })
    };
    return dispatch => {
      dispatch({
        type: LOAD_ADD_PRODUCT_MATRIX,
      });
      axios
        .get(`/entities/${entityId}/organisations/partners/products/`, config)
        .then(response =>
          dispatch({
            type: PROVIDER_PRODUCTS_SEARCH_RESULT,
            payload: response.data,
          }),
        )
        .catch(error => console.error(error));
    };
  }
})();

export const clearMyProvidersProducts = () => dispatch => dispatch({
  type: PROVIDER_PRODUCTS_SEARCH_RESULT,
  payload: [],
});