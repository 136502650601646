import { IchatMessageResponse } from 'redux/modules/common/chatV2/types';
import store from '../../../store';

let socket: WebSocket | null = null;
let toBeKilled = false;

const socketHandlersMap:Record<string, (data: IchatMessageResponse) => void> = {};

export const socketAddHandler = (name: string, handler: (data: IchatMessageResponse) => void) => {
      socketHandlersMap[name] = handler;
};

export const socketRemoveHandler = (name: string) => {
  if (socketHandlersMap[name]) {
    delete socketHandlersMap[name]
  } 
};

export const socketConnectV2 = (authKey?: string) => {
    try {
      const authKey = store.getState().auth.user.key;
      if (socket) {
        socketKill();
      }//@ts-ignore
      socket = new WebSocket(`${window.externalConfig._websocketV2URL}?token=${authKey}`);
  
      socket.onmessage = event => {
        const data = JSON.parse(event.data);
        socketHandlersMap[data.room]?.(data)
      };
  
      socket.onclose = () => {
        if (!toBeKilled) {
          socket = null;
          socketConnectV2(authKey);
        }
      };
    } catch (error) {
      console.log(error);
    }
  };
  
  export const socketKill = () => {
    toBeKilled = true;
    socket && socket.close();
    socket = null;
    toBeKilled = false;
  };
  