import React, { useRef } from "react";
import styles from "./Ks6aTable.module.scss";
import { MonthArray } from "../../../../../../../utils/constant";
import { useSelector } from "react-redux";
import { ks6aListSelector } from "../../../../../../../redux/modules/common/ks6a/ks6a";
import Ks6aWorks from "./components/Ks6aWorks/Ks6aWorks";
import Ks6aMonth from "./components/Ks6aMonth/Ks6aMonth";
import Ks6aYearTotal from "./components/Ks6aYearTotal/Ks6aYearTotal";
import useResize from "./useResize";
import useTableScroll from "./useTableScroll";
import cn from "classnames";
import monthStyles from "./components/Ks6aMonth/Ks6aMonth.module.scss";
export interface IKs6aTableProps {
  year: number;
}

const Ks6aTable: React.FC<IKs6aTableProps> = ({ year }) => {
  const list = useSelector(ks6aListSelector);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  useResize(scrollableContainerRef);
  useTableScroll(scrollableContainerRef);

  return (
    <>
      <span className={styles.worksDoneLabel}>Выполнено работ</span>
      <div className={cn(styles.container, "ks6a_table")} ref={scrollableContainerRef}>
        <Ks6aWorks list={list} />
        {MonthArray.map(({ id, label }) => (
          <div className={monthStyles.monthHeader} key={id}>
            <div className={monthStyles.monthName}>{label}</div>
            <div className={monthStyles.monthCount}>Кол-во</div>
            <div className={monthStyles.monthCost}>Стоимость ₽</div>
            <div className={monthStyles.monthCostFact}>Стоимость факт. вып. работ с нач. строительства ₽</div>
          </div>
        ))}
        <div className={styles.months}>
          {MonthArray.map(({ id, label }) => (
            <Ks6aMonth key={id} label={label} monthId={id} list={list} />
          ))}
        </div>
        <Ks6aYearTotal year={year} list={list} />
      </div>
    </>
  );
};

export default React.memo(Ks6aTable);
