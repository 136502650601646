import { compose } from "redux";
import axios from "axios";

import { errorCatcher } from "../../../../../utils/errorCatcher";

import { setCurrentUserPermissionsDataAction, setCurrentUserPermissionsLoadingAction } from "../actions";
import { userSelector } from "../../auth";
import { PermissionPropertiesEnum } from "../../../../../components/pages/Settings/components/Users/UserPage/enums";

const getPermissionsNames = (permissions) => permissions.map((permission) => permission.name);
const filterNotActivePermissions = (permissions) =>
  permissions.filter(
    (permission) =>
      permission.property === PermissionPropertiesEnum.optionalOn || permission.property === PermissionPropertiesEnum.on
  );

export const getCurrentUserPermissions = (userId) => async (dispatch, getState) => {
  compose(dispatch, setCurrentUserPermissionsLoadingAction)(true);

  if (!userId) userId = userSelector(getState()).id;
  await axios
    .get("/user/permissions/", { params: { user_id: userId } })
    .then((response) =>
      compose(
        dispatch,
        setCurrentUserPermissionsDataAction,
        getPermissionsNames,
        filterNotActivePermissions
      )(response.data)
    )
    .catch(errorCatcher);

  compose(dispatch, setCurrentUserPermissionsLoadingAction)(false);
};
