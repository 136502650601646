import React from "react";
import cn from "classnames";

import {percentLabel} from '../../../../../utils/percentLabel'
import styles from "./index.module.scss";

const ProgressBar = ({ completed, className, fillerClassName, displayValue }) => {
  const percent = Number(completed)
  const percentToDisplay = displayValue || percentLabel(percent)

  return (
    <div className={styles.blockStyles}>
      <span className={cn(styles.labelStyles, className)}>{percentToDisplay}</span>
      <div className={styles.containerStyles}>
        <div
          className={cn(styles.fillerStyles, fillerClassName, className)}
          style={{ width: `${percent}%` }}>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProgressBar);
