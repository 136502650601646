interface IQualifyList {
  id: string;
  name: string;
}


export const QUALIFY_LIST: IQualifyList[] = [
  {
    id: '1',
    name: 'I',
  },
  {
    id: '2',
    name: 'II',
  },
  {
    id: '3',
    name: 'III',
  },
  {
    id: '4',
    name: 'IV',
  },
  {
    id: '5',
    name: 'V',
  },
  {
    id: '6',
    name: 'VI',
  },
  {
    id: '7',
    name: 'VII',
  },
  {
    id: '8',
    name: 'VIII',
  },
  {
    id: '9',
    name: 'IX',
  },
  {
    id: '10',
    name: 'X',
  },
  {
    id: '11',
    name: 'XI',
  },
  {
    id: '12',
    name: 'XII',
  },
]