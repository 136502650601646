import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import cn from "classnames";

import FilesList from "../../molecules/FilesList/FilesList";
import FilesGrid from "../../molecules/FilesGrid/FilesGrid";
import AddFile, {
  VARIANTS as ADD_FILE_VARIANTS,
} from "./components/AddFile/AddFile";

import { LAPTOP_WIDTH } from "constants/dimensions";
import { IFileWithOriginalName } from "types/interfaces/Files";

import { generateRandomId } from "../../../../utils/generateRandomId";

import GridIcon from "../../../../images/GridIcon";
import RowsIcon from "../../../../images/icons/RowsIcon";
import ArrowDownSelect from "../../../../images/icons/ArrowDownSelect";

import styles from "./InputFiles.module.scss";

export const getFileColorByExtension = (extension: string) => {
  switch (extension) {
    case "pdf":
      return "#FFDBDB";
    default:
      return "#C5FAD3";
  }
};

const VARIANTS = { GRID: "grid", ROWS: "rows" };

const getAddFileVariantByInputVariant = (inputVariant: string) => {
  switch (inputVariant) {
    case VARIANTS.GRID:
      return ADD_FILE_VARIANTS.BOOK;
    case VARIANTS.ROWS:
      return ADD_FILE_VARIANTS.BUTTON;
    default:
  }
};

export const MAX_DISPLAYING_FILES_COUNT =
  window.innerWidth <= LAPTOP_WIDTH ? 2 : 3;

interface IProps {
  value: IFileWithOriginalName[] | File[];
  setValue?: React.Dispatch<SetStateAction<IFileWithOriginalName[]>>;
  uploadFilesDirectly?: (file: File) => void;
  removeFileDirectly?: (id: number) => void;
  canExpand?: boolean;
  permissions?: { addFiles: boolean; deleteFiles: boolean };
  disabled?: boolean;
  canExport?: boolean;
  isReset?: boolean;
}

const InputFiles = ({
  value: files,
  setValue: setFiles,
  uploadFilesDirectly,
  removeFileDirectly,
  canExpand,
  permissions = { addFiles: true, deleteFiles: true },
  disabled,
  canExport = true,
  isReset = false,
}: IProps) => {
  const [variant, setVariant] = useState(VARIANTS.GRID);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = useCallback(
    () => setIsExpanded((prevState) => !prevState),
    []
  );

  const addFile = useCallback(
    ([addedFile]: [File]) => {
      if (!addedFile) return;
      !uploadFilesDirectly && // @ts-ignore
        setFiles([
          ...files,
          { id: generateRandomId(), file: addedFile },
        ] as File[]);
      uploadFilesDirectly && uploadFilesDirectly(addedFile);
    },
    [setFiles, files, uploadFilesDirectly]
  );

  const removeFile = useCallback(
    (id: number) => {
      // @ts-ignore
      !removeFileDirectly && setFiles(files.filter((file) => file.id !== id));
      removeFileDirectly && removeFileDirectly(id);
    },
    [files, removeFileDirectly, setFiles]
  );

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    // @ts-ignore
    onDrop: addFile,
    multiple: false,
    noClick: true,
  });

  useEffect(() => {
    if (!isReset || !inputRef.current) return;
    inputRef.current.value = "";
  }, [inputRef, isReset]);

  const openFileDialog = useCallback(
    () => !disabled && inputRef.current?.click(),
    [inputRef.current, disabled]
  );

  return (
    <div className={styles.inputFiles}>
      {files.length !== 0 && (
        <div className={styles.variantsSwitcher}>
          <RowsIcon
            className={styles.variantIcon}
            style={{ opacity: variant === VARIANTS.ROWS ? 1 : 0.5 }}
            onClick={() => setVariant(VARIANTS.ROWS)}
          />
          <GridIcon
            className={styles.variantIcon}
            style={{ opacity: variant === VARIANTS.GRID ? 1 : 0.5 }}
            onClick={() => setVariant(VARIANTS.GRID)}
          />
        </div>
      )}
      <div
        className={cn(styles.inputFilesList, styles[variant], {
          [styles.hidden]: canExpand && !isExpanded,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {permissions.addFiles && (
          <AddFile
            variant={getAddFileVariantByInputVariant(variant)}
            openFileDialog={openFileDialog}
            disabled={disabled}
          />
        )}
        {variant === VARIANTS.GRID && files.length !== 0 && (
          <FilesGrid
            files={files}
            removeFile={removeFile}
            maxDisplayingCount={
              canExpand && !isExpanded ? MAX_DISPLAYING_FILES_COUNT : null
            }
            isDisabled={!permissions.deleteFiles}
            canExport={canExport}
          />
        )}
        {variant === VARIANTS.ROWS && files.length !== 0 && (
          <div className={styles.filesListContainer}>
            {" "}
            {/* @ts-ignore */}
            <FilesList
              files={files}
              removeFile={removeFile}
              maxDisplayingCount={
                canExpand && !isExpanded ? MAX_DISPLAYING_FILES_COUNT : null
              }
              isDisabled={!permissions.deleteFiles}
              canExport={canExport}
            />
          </div>
        )}
        {!permissions.addFiles && files.length === 0 && "Нет файлов"}
      </div>
      {canExpand && (
        <div className={styles.filesExpander} onClick={handleExpand}>
          <ArrowDownSelect
            className={styles.icon}
            color="#fff"
            rotate={isExpanded ? "180" : "0"}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(InputFiles);
