import React, {useCallback, useMemo, useState} from 'react'
import cn from 'classnames'

import { IIdAndName } from "../../../../../../../types/idAndName";

import estimateIcon from "../../../../../../../images/icons/navigation/estimateIcon.svg";
import newTaskCheck from "../../../../../../../images/icons/newTaskCheck.svg";

import TableReusableHead, { TableReusableHeaderCell } from "../../../../../../UI/atoms/TableReusable/TableReusableHead";
import SearchFilterTable from "../../../../../../UI/atoms/SearchFilterTable";
import EmptyPlaceholder from "../../../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import BannerWithIcon from "../../../../../../UI/atoms/BannerWithIcon/BannerWithIcon";

import { Spinner } from "../../../../../../UI/Spinner/Spinner";

import { ITaskObject, useTaskObjectsSectionsUsers } from "../../../../hooks/useTaskObjectsSectionsUsers";

import styles from "./AddTaskByBuilding.module.scss";

export interface IAddTaskByBuilding {
  setBuilding: (newBuilding: IIdAndName) => void;
  isHidden?: boolean
}

const AddTaskByBuilding: React.FC<IAddTaskByBuilding> = ({
  setBuilding,
  isHidden,
}) => {
  const [buildingNameFilter, setBuildingNameFilter] = useState<string>("");

  const filters = useMemo(() => ({ name: buildingNameFilter }), [buildingNameFilter]);

  const handleBuildingNameFilter = useCallback((newFilter: string) => setBuildingNameFilter(newFilter), []);

  const { objects, isLoadingObjects } = useTaskObjectsSectionsUsers({ buildingFilterParams: filters });

  return (
    <div className={cn(styles.container, {[styles.isHidden]: isHidden})}>
      <BannerWithIcon
        url={newTaskCheck}
        title={"Выберите проект чтобы продолжить создание задачи"}
      />
      <TableReusableHead className={styles.objectsHead}>
        <TableReusableHeaderCell>
          Наименование проекта
          <SearchFilterTable title="Наименование задачи" setFilter={handleBuildingNameFilter} />
        </TableReusableHeaderCell>
      </TableReusableHead>
      {isLoadingObjects && !objects.length ? (
        <Spinner />
      ) : objects.length > 0 ? (
        objects?.map((object: ITaskObject) => (
          <div key={object.id} className={styles.row} onClick={() => setBuilding({ id: object.id, name: object.name })}>
            <span className={styles.objectName}>{object.name}</span>
            {object.entity_name && (
              <>
                <span className={styles.entity}>Организация:</span>
                <span className={styles.entityName}>{object.entity_name}</span>
              </>
            )}
          </div>
        ))
      ) : (
        <EmptyPlaceholder text={"Нет проектов"} img={estimateIcon} className={styles.emptyPlaceholder}/>
      )}
    </div>
  );
};

export default AddTaskByBuilding;
