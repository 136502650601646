import { IRegistrationInitialState } from "./types";


const moduleName = 'registration';
export const SET_IS_AUTO_REGISTER_SUCCESS = `${moduleName}/SET_IS_AUTO_REGISTER_SUCCESS`;


const initialState: IRegistrationInitialState = {
  isAutoRegisterSuccess: false
};


export default (state = initialState, action: any): IRegistrationInitialState => {
  const { payload, type } = action;

  switch (type) {
    case SET_IS_AUTO_REGISTER_SUCCESS:
      return {
        ...state,
        isAutoRegisterSuccess: payload,
      };
    default:
      return state;
  }
};