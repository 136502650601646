import React from 'react';
import cn from "classnames";

import Icon from "components/UI/Icon";

import { stopEventPropagation } from 'utils/stopEventPropagation';

import arrowSmallLeftWhite from "images/icons/arrowSmallLeftWhite.svg";

import styles from "./NavBar.module.scss";


type propsType = {
  isExist?: boolean;
  onClick: (e?: React.MouseEvent) => void;
  text?: string;
  className?: string;
  textFontSize?: number;
  textFontWeight?: number;
  textPaddingLeft?: number;
}

function NavBar({ isExist, onClick, text = "Все работы", className, textFontSize = 1.125, textFontWeight = 400, textPaddingLeft = 1 }: propsType) {
  if (!isExist) return null;

  return (
    <div className={cn(styles.navbar, className)}>
      <div className={styles.button} onClick={onClick}>
        <Icon
          icon={arrowSmallLeftWhite}
          className={styles.arrowLeft}
          width={9}
          height={15}
        />
      </div>
      <span
        onClick={stopEventPropagation}
        style={{ 
          fontSize: `${textFontSize}rem`, 
          fontWeight: textFontWeight,
          paddingLeft: `${textPaddingLeft}rem`
        }}
      >
        {text}
      </span>
    </div>
  )
}

export default NavBar;