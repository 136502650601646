import React, { useRef, useState } from "react";
import cn from "classnames";

import useOnClickOutside from "hooks/useOnClickOutside";
import usePermission from "hooks/usePermission";

import { VIEW_CONSTRUCTING_SECTION } from "constants/permissions/constructingPermissions";
import { VIEW_PURCHASES_SECTION } from "constants/permissions/purchasesPermissions";
import { VIEW_MANUFACTURING_SECTION } from "constants/permissions/manufacturingPermissions";

import MenuItemModule from "./components/MenuItemModule";
import Icon from "components/UI/Icon";

import oslaLogo from "images/icons/oslaLogo.svg";
import checkIcon from "images/icons/moduleMenuIcon/checkIcons.svg";
import taskIcon from "images/icons/moduleMenuIcon/checkIcon.svg";
import fiveSquareIcon from "images/icons/moduleMenuIcon/fiveSquareIcon.svg";
import purchases from "images/icons/navigation/purchases.svg";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import styles from "./index.module.scss";


const Menu = () => {
  const ref = useRef();

  const [isOpenModuleMenu, setIsOpenModuleMenu] = useState(false);
  
  const isDisabled = !isAllModulesAllowed();

  useOnClickOutside(ref, () => {
    setIsOpenModuleMenu(false);
  });

  const userHaveViewPurchasesPermission = usePermission(VIEW_PURCHASES_SECTION);
  const userHaveViewConstructingPermission = usePermission(VIEW_CONSTRUCTING_SECTION);
  const userHaveViewManufacturingPermission = usePermission(VIEW_MANUFACTURING_SECTION);

  return (
    <div 
      ref={ref} 
      className={cn(styles.container, { [styles.disabled]: isDisabled })}
    >
      <div
        className={cn(styles.menuIcon, { [styles.active]: isOpenModuleMenu })}
        onClick={() => setIsOpenModuleMenu(!isOpenModuleMenu)}
      >
        <Icon icon={oslaLogo} />
      </div>
      <div className={cn(styles.moduleMenuPopup, { [styles.active]: isOpenModuleMenu })}>
        {userHaveViewConstructingPermission && (
          <MenuItemModule title="Строительство" name="constructing" link="/constructing" icon={checkIcon} />
        )}
        {userHaveViewManufacturingPermission && (
          <MenuItemModule title="Производство" name="production" link="/objects" icon={fiveSquareIcon} />
        )}
        {userHaveViewPurchasesPermission && (
          <MenuItemModule title="Закупки" name="purchase" link="/purchases" icon={purchases} />
        )}
        <MenuItemModule title="Задачи" name="tasks" link="/tasks" icon={taskIcon} />
      </div>
    </div>
  );
};

export default React.memo(Menu);
