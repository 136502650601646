import React, { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import cn from "classnames";

import { SECTIONS_STATUS } from "utils/constant";

import { ESTIMATE_STATES_IDS } from "../../../../constants";
import { transformDigitToFinancial } from "../../../../../../../utils/transformDigitToFinancial";

import ApproveModal from "../ApproveModal/ApproveModal";
import HandlerIndicatorItem from "../HandlerIndicatorItem/HandlerIndicatorItem";

import styles from "./ProductionIndicator.module.scss";
import { splitThousands } from "utils/splitThousands";
import { getAddingToApproveAmount } from "components/pages/Handler/utils/getAddingToApproveAmount";
import HandlerStateCount from "../HandlerStateCount/HandlerStateCount";

const ProductionIndicator = ({ sumEstimateAmount, changeEstimateItemsState, onClick, activeEstimateStateId, count }) => {
  const amount = transformDigitToFinancial(sumEstimateAmount || 0, { withFloat: false, withRuble: true });
  const [isDropTarget, setIsDropTarget] = useState(false);

  const [{ canDrop, didDrop, droppedItem }, drop] = useDrop(() => ({
    accept: "box",
    drop: () => ({ name: ESTIMATE_STATES_IDS.PRODUCTION }),
    collect: (monitor) => {
      const itemState = monitor?.getItem()?.current_state;
      const status = monitor?.getItem()?.status;

      const canDrop =
        [ESTIMATE_STATES_IDS.LOCALE, ESTIMATE_STATES_IDS.OBJECT, ESTIMATE_STATES_IDS.CONSOLIDATE].indexOf(itemState) !==
          -1 && status === SECTIONS_STATUS.CONFIRMED;

      return {
        isOver: monitor.isOver(),
        canDrop: canDrop,
        didDrop: monitor.getDropResult(),
        droppedItem: monitor.getItem(),
      };
    },
  }));

  if (didDrop?.name === ESTIMATE_STATES_IDS.PRODUCTION && droppedItem && !isDropTarget) {
    setIsDropTarget({ status: ESTIMATE_STATES_IDS.PRODUCTION, item: droppedItem });
  }

  const onApprove = useCallback((isApprove, state) => {
    setIsDropTarget(false);

    if (isApprove) {
      changeEstimateItemsState(
        state,
        isDropTarget?.item?.map((item) => item?.id)
      );
    }
  }, [changeEstimateItemsState, isDropTarget?.item]);

  const handleOnClick = useCallback(() => onClick(ESTIMATE_STATES_IDS.PRODUCTION), [onClick]);

  const [addingSum, setAddingSum] = React.useState(null)

  React.useEffect(() => {
    if (droppedItem) {
      setAddingSum(splitThousands(getAddingToApproveAmount(droppedItem)))
    }
  }, [droppedItem])

  return (
    <HandlerIndicatorItem
      color="#8472E6"
      isActive={activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION}
      isDropTarget={isDropTarget}
    >
      <div className={cn(styles.container, { [styles.canDrop]: canDrop })} ref={drop} onClick={handleOnClick}>
        {!isDropTarget && <div className={styles.title}>Выдано в работу</div>}
        <HandlerStateCount 
          count={count}
          amount={amount}
          plus={isDropTarget && addingSum}
          title={"В производстве"}
        />
        <ApproveModal
          activeEstimateStateId={activeEstimateStateId}
          targetEstimateStateId={ESTIMATE_STATES_IDS.PRODUCTION}
          isOpen={isDropTarget?.status === ESTIMATE_STATES_IDS.PRODUCTION}
          onApprove={onApprove}
          items={isDropTarget?.item}
        />
      </div>
    </HandlerIndicatorItem>
  );
};

export default React.memo(ProductionIndicator);
