import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { lockOrganization } from '../../../../../redux/modules/common/settings/organization'
import { useUrlModule } from '../../../../../utils/hooks/useUrlModule'
import styles from "./SettingsTable.module.scss"

function SettingOrgRow({ id, name, inn, kpp, isLocked, objectId }) {
    const history = useHistory()
    const dispatch = useDispatch()

    const rowClickHandler = () => {
        history.push(`organization/${id}?edit=false`)
    }

    const editHandler = (evt) => {
        evt.stopPropagation()
        history.push(`organization/${id}?edit=true`)
    }

    const lockHandler = (evt) => {
        evt.stopPropagation()
        dispatch(lockOrganization(id, !isLocked))
    }

    return (
        <div className={styles.orgRow} onClick={rowClickHandler}>
            <div className="custom-table__cell">
                <div className={styles.orgName}>{name}</div>
            </div>
            <div className="custom-table__cell">
                <div className={styles.orgInn}>
                    ИНН {inn}
                    <svg width="3" height="29" viewBox="0 0 2 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.66602 1C2.66602 0.447715 2.2183 0 1.66602 0C1.11373 0 0.666016 0.447715 0.666016 1V36C0.666016 36.5523 1.11373 37 1.66602 37C2.2183 37 2.66602 36.5523 2.66602 36V1Z" fill="#707070"></path>
                    </svg>
                </div>
            </div>
            <div className="custom-table__cell">
                <div className={styles.orgKpp}>КПП {kpp}<svg width="3" height="29" viewBox="0 0 2 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.66602 1C2.66602 0.447715 2.2183 0 1.66602 0C1.11373 0 0.666016 0.447715 0.666016 1V36C0.666016 36.5523 1.11373 37 1.66602 37C2.2183 37 2.66602 36.5523 2.66602 36V1Z" fill="#707070"></path>
                </svg>
                </div>
            </div>
            <div className="custom-table__cell">
                <div className={styles.btns}>
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={editHandler}>
                        <path d="M5 16.9997C3.67432 16.9983 2.40332 16.4711 1.46593 15.5337C0.528533 14.5963 0.00132369 13.3253 0 11.9997L0 6.22766C0.00158786 4.90206 0.528882 3.63121 1.46622 2.69388C2.40356 1.75654 3.67441 1.22924 5 1.22766H10.444C10.692 1.22747 10.9397 1.24585 11.185 1.28266L9.132 3.38266H5.106C4.31035 3.38266 3.54729 3.69873 2.98468 4.26134C2.42207 4.82394 2.106 5.58701 2.106 6.38266V11.8537C2.106 12.6493 2.42207 13.4124 2.98468 13.975C3.54729 14.5376 4.31035 14.8537 5.106 14.8537H10.339C11.1346 14.8537 11.8977 14.5376 12.4603 13.975C13.0229 13.4124 13.339 12.6493 13.339 11.8537V7.89366L15.423 5.76566C15.438 5.92566 15.445 6.08066 15.445 6.22866V12.0017C15.4437 13.3273 14.9165 14.5983 13.9791 15.5357C13.0417 16.4731 11.7707 17.0003 10.445 17.0017L5 16.9997ZM7.833 6.82366L13.911 0.616656C14.0726 0.460594 14.2638 0.338484 14.4734 0.257532C14.6829 0.17658 14.9066 0.138427 15.1311 0.145324C15.3557 0.152222 15.5765 0.20403 15.7807 0.297692C15.9849 0.391353 16.1683 0.52497 16.32 0.690656C16.6498 1.01109 16.8418 1.44741 16.8553 1.90706C16.8687 2.3667 16.7025 2.8135 16.392 3.15266L10.315 9.35866L6.97 10.2437L7.833 6.82366Z" fill="#606060" />
                    </svg>
                    {!isLocked ? <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={lockHandler}>
                        <path d="M2 17C1.47013 16.9982 0.962564 16.7868 0.587891 16.4121C0.213217 16.0374 0.00184486 15.5299 0 15V6C0 5.46957 0.210865 4.96101 0.585938 4.58594C0.96101 4.21086 1.46957 4 2 4H3V1C3 0.734784 3.10543 0.480505 3.29297 0.292969C3.48051 0.105432 3.73478 0 4 0H12C12.2652 0 12.5195 0.105432 12.707 0.292969C12.8946 0.480505 13 0.734784 13 1V4H14C14.5294 4.00159 15.0364 4.21299 15.4102 4.58789C15.7839 4.96279 15.9941 5.4706 15.9941 6V15C15.9926 15.5289 15.7821 16.0356 15.4087 16.4102C15.0353 16.7847 14.5289 16.9968 14 17H2ZM2 7V14C2 14.2652 2.10543 14.5195 2.29297 14.707C2.48051 14.8946 2.73478 15 3 15H13C13.2652 15 13.5195 14.8946 13.707 14.707C13.8946 14.5195 14 14.2652 14 14V7C14 6.73478 13.8946 6.4805 13.707 6.29297C13.5195 6.10543 13.2652 6 13 6H3C2.73478 6 2.48051 6.10543 2.29297 6.29297C2.10543 6.4805 2 6.73478 2 7ZM11 4V2H5V4H11ZM7 12V10C7 9.73478 7.10543 9.4805 7.29297 9.29297C7.4805 9.10543 7.73478 9 8 9C8.26522 9 8.5195 9.10543 8.70703 9.29297C8.89457 9.4805 9 9.73478 9 10V12C9 12.2652 8.89457 12.5195 8.70703 12.707C8.5195 12.8946 8.26522 13 8 13C7.73478 13 7.4805 12.8946 7.29297 12.707C7.10543 12.5195 7 12.2652 7 12Z" fill="#606060" />
                    </svg> :
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={lockHandler}>
                            <path d="M2 17C1.47013 16.9982 0.962564 16.7868 0.587891 16.4121C0.213217 16.0374 0.00184486 15.5299 0 15V6C0 5.46957 0.210865 4.96101 0.585938 4.58594C0.96101 4.21086 1.46957 4 2 4H12V1C12 0.734784 12.1054 0.480505 12.293 0.292969C12.4805 0.105432 12.7348 0 13 0H21C21.2652 0 21.5195 0.105432 21.707 0.292969C21.8946 0.480505 22 0.734784 22 1V4C22 4.26522 21.8946 4.5195 21.707 4.70703C21.5195 4.89457 21.2652 5 21 5C20.7348 5 20.4805 4.89457 20.293 4.70703C20.1054 4.5195 20 4.26522 20 4V2H14V4C14.5294 4.00159 15.0364 4.21299 15.4102 4.58789C15.7839 4.96279 15.9941 5.4706 15.9941 6V15C15.9926 15.5289 15.7821 16.0356 15.4087 16.4102C15.0353 16.7847 14.5289 16.9968 14 17H2ZM2 7V14C2 14.2652 2.10543 14.5195 2.29297 14.707C2.48051 14.8946 2.73478 15 3 15H13C13.2652 15 13.5195 14.8946 13.707 14.707C13.8946 14.5195 14 14.2652 14 14V7C14 6.73478 13.8946 6.4805 13.707 6.29297C13.5195 6.10543 13.2652 6 13 6H3C2.73478 6 2.48051 6.10543 2.29297 6.29297C2.10543 6.4805 2 6.73478 2 7ZM7 12V10C7 9.73478 7.10543 9.4805 7.29297 9.29297C7.4805 9.10543 7.73478 9 8 9C8.26522 9 8.5195 9.10543 8.70703 9.29297C8.89457 9.4805 9 9.73478 9 10V12C9 12.2652 8.89457 12.5195 8.70703 12.707C8.5195 12.8946 8.26522 13 8 13C7.73478 13 7.4805 12.8946 7.29297 12.707C7.10543 12.5195 7 12.2652 7 12Z" fill="#606060" />
                        </svg>
                    }

                </div>
            </div>
        </div>
    )
}

export default SettingOrgRow