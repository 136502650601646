import axios from "axios";
import { createSelector } from "reselect";
import update from "immutability-helper";
import { errorCatcher } from "../../../utils/errorCatcher";

const moduleName = "events";
const GET_EVENTS = `${moduleName}/GET_EVENTS`;
const SET_EVENTS = `${moduleName}/SET_EVENTS`;
const SET_TIMELINE = `${moduleName}/SET_TIMELINE`;
const GET_RANGED_EVENTS = `${moduleName}/GET_RANGED_EVENTS`;
const SET_RANGED_EVENTS = `${moduleName}/SET_RANGED_EVENTS`;

const initialState = {
  events: [],
  timeLine: [],
  rangedEvents: [],
  rangedEventsIsLoading: false,
  isLoading: true
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {

    case GET_EVENTS:
      return update(state, {
        isLoading: { $set: true }
      });

    case SET_EVENTS:
      return update(state, {
        events: { $set: payload },
        isLoading: { $set: false }
      });

    case GET_RANGED_EVENTS:
      return update(state, {
        rangedEventsIsLoading: { $set: true }
      });

    case SET_RANGED_EVENTS:
      return update(state, {
        rangedEvents: { $set: payload },
        rangedEventsIsLoading: { $set: false }
      });

    case SET_TIMELINE:
      return update(state, {
        timeLine: { $set: payload }
      });

    default:
      return state;
  }
};

export const stateSelector = state => state[moduleName];
export const eventsSelector = createSelector(stateSelector, state => state.events);
export const timeLineSelector = createSelector(stateSelector, state => state.timeLine);
export const rangedEventsSelector = createSelector(stateSelector, state => state.rangedEvents);
export const rangedEventsIsLoadingSelector = createSelector(stateSelector, state => state.rangedEventsIsLoading);
export const isLoadingEventsSelector = createSelector(stateSelector, state => state.isLoading);

export const getEvents = (() => {
  let cancel;
  return (params) => {
    const filterParams = { ...params, events: (params.events === "events") ? null : params.events };
    if (cancel) cancel();

    return (dispatch) => {
      dispatch({ type: GET_EVENTS });
      axios.get(`/events/`, {
        cancelToken: new axios.CancelToken((c) => cancel = c),
        params: { ...filterParams, detail: true }
      })
        .then((response) => dispatch({ type: SET_EVENTS, payload: response.data }))
        .catch(errorCatcher);
    };
  };
})();

export const getRangedEvents = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (params) => {
    if (ge_cancel) ge_cancel();
    const filteredParams = {
      ...params,
      events: (params.events === "events") ? null : params.events
    };
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
      params: {
        ...filteredParams,
        detail: true
      }
    };

    return dispatch => {
      dispatch({
        type: GET_RANGED_EVENTS
      });
      axios.get(
        `/events/`,
        config
      ).then(res => {
        dispatch({
          type: SET_RANGED_EVENTS,
          payload: res.data
        });
      });
    };
  };
})();


export const getTimeline = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (params) => {
    if (ge_cancel) ge_cancel();
    const config = {
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      }),
      params
    };

    return dispatch => {
      axios.get(
        `/events/timeline/`,
        config
      ).then(res => {
        dispatch({
          type: SET_TIMELINE,
          payload: res.data
        });
      });
    };
  };
})();

