import axios from 'axios';
import { message } from 'antd';
import { createSelector } from 'reselect';

const moduleName = 'accounts';
const LOAD_ACCOUNTS = `${moduleName}/LOAD_ACCOUNTS`;
const SET_ACCOUNTS = `${moduleName}/SET_ACCOUNTS`;
const LOAD_ACCOUNT_DETAIL = `${moduleName}/LOAD_ACCOUNT_DETAIL`;
const SET_ACCOUNT_DETAIL = `${moduleName}/SET_ACCOUNT_DETAIL`;

const initialState = {
  accounts: null,
  isLoading: true,
  accountDetail: {},
  accountDetailIsLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_ACCOUNTS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
        isLoading: false,
      };
    case LOAD_ACCOUNT_DETAIL:
      return {
        ...state,
        accountDetailIsLoading: true,
      };
    case SET_ACCOUNT_DETAIL:
      return {
        ...state,
        accountDetail: payload,
        accountDetailIsLoading: false,
      };
    default:
      return state;
  }
};

export const stateSelector = state => state[moduleName];
export const accountsSelector = createSelector(stateSelector, state => state.accounts);
export const accountsLoadingSelector = createSelector(stateSelector, state => state.isLoading);
export const accountDetailSelector = createSelector(stateSelector, state => state.accountDetail);
export const accountDetailLoadingSelector = createSelector(stateSelector, state => state.accountDetailIsLoading);

export const loadAccounts = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (paginationParams, filterParams, sorting = null) => {
    if (ld_cancel) ld_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
        ordering: sorting,
      },
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };

    return dispatch => {
      dispatch({
        type: LOAD_ACCOUNTS,
      });
      axios.get(`/admin/accounts/`, config).then(response =>
        dispatch({
          type: SET_ACCOUNTS,
          payload: response.data,
        })
      );
    };
  };
})();

export const loadAccountDetail = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (id) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };

    return dispatch => {
      dispatch({
        type: LOAD_ACCOUNT_DETAIL,
      });
      axios.get(`/admin/accounts/${id}/`, config).then(response =>
        dispatch({
          type: SET_ACCOUNT_DETAIL,
          payload: response.data,
        })
      );
    };
  };
})();

export const createAccount = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (body, callback) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };

    return dispatch => {
      dispatch({
        type: LOAD_ACCOUNT_DETAIL,
      });
      axios.post(`/admin/accounts/`, body, config).then(response =>
          callback?.(response.data),
        error => {
        }
      );
    };
  };
})();

export const editAccount = (() => {
  const CancelToken = axios.CancelToken;
  let ld_cancel;
  return (body, callback) => {
    if (ld_cancel) ld_cancel();
    const config = {
      cancelToken: new CancelToken(c => {
        ld_cancel = c;
      }),
    };

    return (dispatch, getState) => {
      dispatch({
        type: LOAD_ACCOUNT_DETAIL,
      });
      axios.patch(`/admin/accounts/${getState().accounts.accountDetail.id}/`, body, config).then(response =>
        dispatch({
          type: SET_ACCOUNT_DETAIL,
          payload: response.data,
        }),
        error => {
        }
      );
    };
  };
})();

