import { createSelector } from 'reselect';
import { apiGetAggregations } from './aggregationsApi';

const moduleName = 'aggregations';
const LOAD_AGGREGATIONS = `${moduleName}/LOAD_AGGREGATIONS`;
const SET_AGGREGATIONS = `${moduleName}/SET_AGGREGATIONS`;

const initialState = {
  aggregation: null,
  isLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_AGGREGATIONS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_AGGREGATIONS:
      return {
        ...state,
        aggregation: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const stateSelector = state => state[moduleName];
export const aggregationSelector = createSelector(stateSelector, state => state.aggregation);
export const aggregationLoadingSelector = createSelector(stateSelector, state => state.isLoading);

export const loadContracts =  (objectid)=> {
  return async dispatch => {
    const data = await apiGetAggregations(objectid);
    await dispatch({
      type: SET_AGGREGATIONS,
      payload: data,
    })
  }
}