import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from "classnames"

import styles from "./TabBar.module.scss"

function TabBar({ tabs, className, disabled, ...rest }) { //tabs: {text, link}[]
  return (
    <div className={`${className||""} ${disabled?styles.disabled:""} ${styles.tabBar}`} {...rest}>
        {Array.isArray(tabs) && tabs.map(el => (
            <NavLink activeClassName={styles.active} className={cn(styles.tab, el.className)} to={el.link} key={el.text}>
              {el.text}
            </NavLink>
        ))}
    </div>
  )
}

export default TabBar