import axios from "axios";
import moment from "moment";
import update from "immutability-helper";
import { createSelector } from "reselect";
import { message } from "antd";
import { compose } from "redux";

import { messageErrorHandler } from "utils/errorHandler";

import { transformOrderForFrontend } from "../../../helpers/transformOrderForFrontend";
import { errorCatcher } from "../../../../utils/errorCatcher";

const moduleName = "orders";

const LOAD_PURCHASE_LIST_PRODUCTS = `${moduleName}/LOAD_PURCHASE_LIST_PRODUCTS`;
const LOAD_ORDERS = `${moduleName}/LOAD_ORDERS`;
const LOAD_PROVIDERS_LIST = `${moduleName}/LOAD_PROVIDERS_LIST`;
const SET_ORDERS = `${moduleName}/SET_ORDERS`;
const ORDER_CREATE_RESPONSE = `${moduleName}/ORDER_CREATE_RESPONSE`;

const SET_CURRENT_ORDER_DATA = `${moduleName}/SET_CURRENT_ORDER_DATA`;
const SET_CURRENT_ORDER_LOADING = `${moduleName}/SET_CURRENT_ORDER_LOADING`;
const SET_CURRENT_ORDER_ERROR = `${moduleName}/SET_CURRENT_ORDER_ERROR`;

const LOAD_ORDERS_BY_DATES_START = `${moduleName}/LOAD_ORDERS_BY_DATES_START`;
const LOAD_ORDERS_BY_DATES_SUCCESS = `${moduleName}/LOAD_ORDERS_BY_DATES_SUCCESS`;
const LOAD_ORDERS_BY_DATES_FAIL = `${moduleName}/LOAD_ORDERS_BY_DATES_FAIL`;
const SET_ORDER_EDITOR = `${moduleName}/SET_ORDER_EDITOR`;
const SET_PURCHASE_LIST = `${moduleName}/SET_PURCHASE_LIST`;
const SET_PURCHASE_LIST_CACHE = `${moduleName}/SET_PURCHASE_LIST_CACHE`;
const SET_PAGINATION_STATE = `${moduleName}/SET_PAGINATION_STATE`;

const UPDATE_SEARCH_INPUT_VALUE = `${moduleName}/UPDATE_SEARCH_INPUT_VALUE`;
const SET_ORDER_STATUS_FILTER = `${moduleName}/SET_ORDER_STATUS_FILTER`;
const SET_ORDER_PROVIDER_FILTER = `${moduleName}/SET_ORDER_PROVIDER_FILTER`;
const SET_ORDER_EXECUTOR_FILTER = `${moduleName}/SET_ORDER_EXECUTOR_FILTER`;
const SET_IS_ORDER_CHECKED_FILTER = `${moduleName}/SET_IS_ORDER_CHECKED_FILTER`;
const CLEAR_FILTERS = `${moduleName}/CLEAR_FILTERS`;
const SET_DATE_RANGE_FILTER = `${moduleName}/SET_DATE_RANGE_FILTER`;
const ORDER_UPDATE_RESPONSE = `${moduleName}/ORDER_UPDATE_RESPONSE`;

const SET_PROVIDER_REQUISITES_DATA = `${moduleName}/SET_PROVIDER_REQUISITES_DATA`;
const SET_PROVIDER_REQUISITES_LOADING = `${moduleName}/SET_PROVIDER_REQUISITES_LOADING`;
const RESET_PROVIDER_REQUISITES_TO_INITIAL = `${moduleName}/RESET_PROVIDER_REQUISITES_TO_INITIAL`;
const SET_PROVIDER_REQUISITES_MODAL_OPEN = `${moduleName}/SET_PROVIDER_REQUISITES_MODAL_OPEN`;

const SET_NEW_SORTING = `${moduleName}/SET_NEW_SORTING`;

const SET_PURCHASER_ADDRESSES = `${moduleName}/SET_PURCHASER_ADDRESSES`;

const INITIAL_STATE = {
  currentOrder: {
    isLoading: false,
    isError: false,
    data: {}
  },
  orders: [],
  filteredOrders: {
    ordersByDates: []
  },
  purchaseListProducts: [],
  addressList: [],
  pagination: {
    params: { limit: 50, offset: 0 },
    page: 1
  },
  myOrdersFilters: {
    q: "",
    status: null,
    is_check: null,
    delivery_date_after: null,
    delivery_date_before: null,
    provider: null,
    executor_id: null
  },
  providers: null,
  sorting: null,
  loading: true,
  providerRequisites: {
    data: {},
    isLoading: true,
    isModalOpen: false
  }
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOAD_PURCHASE_LIST_PRODUCTS:
      return {
        ...state,
        purchaseListProducts: payload
      };

    case SET_PURCHASE_LIST:
      return {
        ...state,
        purchaseListProducts: payload
      };

    case LOAD_ORDERS:
      return {
        ...state,
        loading: true
      };

    case SET_ORDERS:
      return {
        ...state,
        orders: payload,
        loading: false
      };

    case ORDER_CREATE_RESPONSE:
      return {
        ...state,
        createdOrder: payload
      };
    case SET_CURRENT_ORDER_ERROR:
      return update(state, { currentOrder: { isError: { $set: payload } } });
    case SET_CURRENT_ORDER_LOADING:
      return update(state, { currentOrder: { isLoading: { $set: payload } } });
    case SET_CURRENT_ORDER_DATA:
      return update(state, { currentOrder: { data: { $set: transformOrderForFrontend(payload) } } });

    case ORDER_UPDATE_RESPONSE:
      return {
        ...state,
        orderUpdateResponse: payload
      };

    case LOAD_ORDERS_BY_DATES_START:
      return {
        ...state,
        filteredOrders: {
          ...state.filteredOrders,
          ordersByDatesLoading: true
        }
      };

    case LOAD_ORDERS_BY_DATES_SUCCESS:
      return {
        ...state,
        filteredOrders: {
          ...state.filteredOrders,
          ordersByDatesLoading: false,
          ordersByDates: payload
        }
      };

    case LOAD_ORDERS_BY_DATES_FAIL:
      return {
        ...state,
        filteredOrders: {
          ...state.filteredOrders,
          ordersByDatesLoading: false
        }
      };

    case LOAD_PROVIDERS_LIST:
      return {
        ...state,
        providers: payload
      };

    case SET_ORDER_EDITOR:
      return {
        ...state,
        orderEditor: action.payload
      };

    case SET_PURCHASE_LIST_CACHE:
      return {
        ...state,
        cache: {
          ...state.cache,
          purchaseLists: payload
        }
      };

    case SET_PAGINATION_STATE:
      return {
        ...state,
        pagination: {
          params: payload.params,
          page: payload.page
        }
      };

    case UPDATE_SEARCH_INPUT_VALUE:
      return update(state, { myOrdersFilters: { q: { $set: payload } } });

    case SET_ORDER_STATUS_FILTER:
      return update(state, { myOrdersFilters: { status: { $set: payload.status } } });

    case SET_ORDER_PROVIDER_FILTER:
      return update(state, { myOrdersFilters: { provider: { $set: payload.provider } } });

    case SET_ORDER_EXECUTOR_FILTER:
      return update(state, { myOrdersFilters: { executor_id: { $set: payload.executor } } });

    case SET_IS_ORDER_CHECKED_FILTER:
      return update(state, { myOrdersFilters: { is_check: { $set: payload.checked } } });

    case CLEAR_FILTERS:
      return update(state, {
        myOrdersFilters: {
          $set: {
            q: "",
            status: null,
            is_check: null,
            delivery_date_after: null,
            delivery_date_before: null,
            provider: null,
            executor_id: null
          }
        }
      });

    case SET_DATE_RANGE_FILTER:
      return update(state, {
        myOrdersFilters: {
          delivery_date_after: { $set: payload.delivery_date_after },
          delivery_date_before: { $set: payload.delivery_date_before }
        }
      });

    case SET_NEW_SORTING:
      return update(state, {
        sorting: { $set: payload }
      });

    case SET_PURCHASER_ADDRESSES:
      return {
        ...state,
        addressList: payload
      };
    case SET_PROVIDER_REQUISITES_DATA:
      return update(state, { providerRequisites: { data: { $set: payload } } });
    case SET_PROVIDER_REQUISITES_LOADING:
      return update(state, { providerRequisites: { isLoading: { $set: payload } } });
    case RESET_PROVIDER_REQUISITES_TO_INITIAL:
      return update(state, { providerRequisites: { $set: INITIAL_STATE.providerRequisites } });
    case SET_PROVIDER_REQUISITES_MODAL_OPEN:
      return update(state, { providerRequisites: { isModalOpen: { $set: payload} } });
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const filtersStateSelector = createSelector(stateSelector, (state) => state.myOrdersFilters);
export const ordersSelector = createSelector(stateSelector, (state) => state.orders);
export const paginationStateSelector = createSelector(stateSelector, (state) => state.pagination);
export const sortingStateSelector = createSelector(stateSelector, (state) => state.sorting);
export const loadingStateSelector = createSelector(stateSelector, (state) => state.loading);
export const providersListSelector = createSelector(stateSelector, (state) => state.providers);
export const currentOrderSelector = createSelector(stateSelector, (state) => state.currentOrder);
export const providerRequisitesSelector = createSelector(stateSelector, (state) => state.providerRequisites);

export const savePurchaseList = (inputList) => (dispatch) => {
  dispatch({
    type: SET_PURCHASE_LIST_CACHE,
    payload: inputList
  });
};

export const updatePurchaseList = (inputList) => (dispatch) => {
  dispatch({
    type: SET_PURCHASE_LIST,
    payload: inputList
  });
};

export const setCurrentOrderLoading = (payload) => ({ type: SET_CURRENT_ORDER_LOADING, payload });
export const setCurrentOrderError = (payload) => ({ type: SET_CURRENT_ORDER_ERROR, payload });
export const setCurrentOrderData = (payload) => ({ type: SET_CURRENT_ORDER_DATA, payload });

const setProviderRequisitesDataAction = (payload) => ({ type: SET_PROVIDER_REQUISITES_DATA, payload });
const setProviderRequisitesLoadingAction = (payload) => ({ type: SET_PROVIDER_REQUISITES_LOADING, payload });
export const resetProviderRequisitesToInitialAction = () => ({ type: RESET_PROVIDER_REQUISITES_TO_INITIAL });
export const setProviderRequisitesModalOpenAction = (payload) => ({ type: SET_PROVIDER_REQUISITES_MODAL_OPEN, payload });

export const setCurrentOrders = (orders) => ({
  type: SET_ORDERS,
  payload: orders
});

export const updateOrderEditor = (data) => ({
  type: SET_ORDER_EDITOR,
  payload: data
});

export const updateSearchInputValue = (value) => ({
  type: UPDATE_SEARCH_INPUT_VALUE,
  payload: value
});

export const setOrderStatusFilter = (status) => ({
  type: SET_ORDER_STATUS_FILTER,
  payload: { status }
});

export const setOrderProviderFilter = (provider) => ({
  type: SET_ORDER_PROVIDER_FILTER,
  payload: { provider }
});

export const setOrderExecutorFilter = (executor) => ({
  type: SET_ORDER_EXECUTOR_FILTER,
  payload: { executor }
});

export const setIsOrderCheckedFilter = (checked) => ({
  type: SET_IS_ORDER_CHECKED_FILTER,
  payload: { checked }
});

export const setDateRangeFilter = (range) => ({
  type: SET_DATE_RANGE_FILTER,
  payload: range
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS
});

export const setPaginationState = (params, page) => ({
  type: SET_PAGINATION_STATE,
  payload: { params, page }
});

export const sortTableTab = (tab) => ({
  type: SET_NEW_SORTING,
  payload: tab
});

/*
  Thunks
*/

export const loadOrders = (entityId, paginationParams, filterParams) => {
  const config = {
    params: {
      ...paginationParams,
      ...filterParams
    }
  };
  return (dispatch) =>
    axios.get(`/orders/`, config).then(
      (res) => {
        dispatch(setCurrentOrders(res.data));
      },
      (error) => {
        console.error(error);
        dispatch(setCurrentOrders({}));
      }
    );
};

export const loadOrders_v2 = (entityId, paginationParams, filterParams, sorting = null) => {
  const config = {
    params: {
      ...paginationParams,
      ...filterParams,
      ordering: sorting
    }
  };

  return (dispatch) => {
    dispatch({
      type: LOAD_ORDERS
    });
    axios({
      method: "get",
      url: `/orders/`,
      params: config.params
    }).then(
      (res) => {
        dispatch(setCurrentOrders(res.data));
      },
      (error) => {
        console.error(error);
        dispatch(setCurrentOrders({}));
      }
    );
  };
};

export const createOrder = (entityId, data) => {
  return async (dispatch) =>
    await axios({
      method: "post",
      url: `/orders/create/`,
      data: data
    }).then(
      async (response) => {
        await dispatch({
          type: ORDER_CREATE_RESPONSE,
          payload: response.data
        });
      },
      (error) => {
        console.error(error);
      }
    );
};

export const loadProvidersList = (entityId) => {
  return (dispatch) =>
    axios({
      method: "get",
      url: `/entities/${entityId}/organisations/partners/`
    }).then(
      (res) => {
        dispatch({
          type: LOAD_PROVIDERS_LIST,
          payload: res.data.results
        });
      },
      (error) => {
        console.log(error);
      }
    );
};

export const loadPurchaseListProducts = (entityId, filterParams) => {
  const config = {
    params: {
      ...filterParams
    }
  };

  return (dispatch) => {
    return axios.get(`/purchaselists/${entityId}/common/`, config).then((response) => {
      dispatch({
        type: LOAD_PURCHASE_LIST_PRODUCTS,
        payload: response.data.results
      });
    });
  };
};

export const loadOrdersByDates = (entityId, fromDate) => {
  const config = {
    params: {
      status: "ip",
      delivery_date_after: fromDate,
      delivery_date_before: moment(fromDate)
        .add(6, "days")
        .set({ hour: 23, minute: 59, second: 59 })
        .format()
    }
  };

  const amountOfDays = 7;
  const dates = [...Array(amountOfDays).keys()].reduce(
    (acc, day) => {
      return {
        currentDay: moment(acc.currentDay)
          .add(1, "days")
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        dates: acc.dates.concat(moment(acc.currentDay).format())
      };
    },
    {
      currentDay: fromDate,
      dates: []
    }
  ).dates;

  return async (dispatch) => {
    dispatch({
      type: LOAD_ORDERS_BY_DATES_START
    });

    try {
      const res = await axios.get(`/orders/`, config);

      const result = dates.reduce((acc, day) => {
        const orders = res.data.results.reduce((ordersAcc, order) => {
          if (moment(order.delivery_date).isSame(day, "day")) {
            return ordersAcc.concat(order);
          }

          return ordersAcc;
        }, []);

        return { ...acc, [day]: orders };
      }, {});

      dispatch({
        type: LOAD_ORDERS_BY_DATES_SUCCESS,
        payload: result
      });
    } catch (err) {
      console.error(err);

      dispatch({
        type: LOAD_ORDERS_BY_DATES_FAIL,
        payload: err
      });
    }
  };
};

//Orders
export const cleanOrders = () => {
  return (dispatch) => dispatch(setCurrentOrders({}));
};

//Order card
export const loadOrderDetails = (entityId, orderId, callback) => (dispatch) =>
  axios.get(`/orders/${orderId}/`).then(
    (response) => {
      dispatch(setCurrentOrderData(response.data));
      callback();
    },
    (err) => console.log("order is not exist")
  );

export const getOrder = (orderId, { userType, withLoading }) => async (dispatch) => {
  if (withLoading) compose(dispatch, setCurrentOrderLoading)(true);
  compose(dispatch, setCurrentOrderError)(false);

  await axios.get(`/${userType}/orders/${orderId}/`)
    .then((response) => compose(dispatch, setCurrentOrderData)(response.data))
    .catch((error) => {
      errorCatcher(error);
      compose(dispatch, setCurrentOrderError)(true);
    });

  compose(dispatch, setCurrentOrderLoading)(false);
};

export const updateOrderV2 = (orderId, data, success, fail) => {
  return (dispatch) =>
    axios({
      method: "post",
      url: `/provider/orders/${orderId}/to-pcr-approve/`,
      data: data
    })
      .then((response) => {
        dispatch(setCurrentOrderData(response.data));
        dispatch({
          type: ORDER_UPDATE_RESPONSE,
          payload: response.status
        });
        message.success("Заказ успешно сформирован");
        success?.();
      })
      .catch((error) => {
        messageErrorHandler(error.response.data.errors);
        fail?.();
      });
};

export const cancelOrderV2 = (id, callback) => (dispatch, getState) => {
  const userType = getState().auth.entity.type === "pcr" ? "purchaser" : "provider";

  axios.post(`/${userType}/orders/${id}/reject/`)
    .then((response) => {
      message.success("Заказ успешно отменен");
      compose(dispatch, setCurrentOrderData)(response.data);
      if (callback) callback(response);
    })
    .catch(errorCatcher);
};

export const declineOrder = (entityId, id) => {
  const data = {
    confirm_provider: false
  };
  return () => axios.put(`/orders/${id}/cancel/`, data);
};

export const sendOrder = (entityId, data, orderEditor) => () => axios.post(`/orders/`, data);

export const loadAddresses = (callback) => {
  return (dispatch) =>
    axios.get(`/profile/address/`).then(
      (response) => {
        dispatch({
          type: SET_PURCHASER_ADDRESSES,
          payload: response.data
        });
        if (response.data) callback();
      },
      ({ err }) => {
        console.log(err);
      }
    );
};

export const returnToProcessing = (id, callback = null) => {
  return (dispatch) =>
    axios.post(`/purchaser/orders/${id}/return-to-processing/`).then(
      (response) => {
        callback?.();
        message.success("Заказ успешно отправлен назад в обработку");
      },
      ({ err }) => {
        console.log(err);
      }
    );
};

export const duplicateOrder = (id, data, callback = null) => {
  return (dispatch) =>
    axios.post(`/orders/${id}/duplicate/`, data).then(
      (response) => {
        callback?.(response.data);
        message.success("Заказ успешно дублирован");
      },
      ({ err }) => {
        console.log(err);
      }
    );
};

export const sendToPayment = (id, callback) => (dispatch) => {
  axios.post(`/purchaser/orders/${id}/to-payment-decision/`)
    .then((response) => {
      dispatch(setCurrentOrderData(response.data));
      message.success("Заказ успешно отправлен в решение по оплате");
      if (callback) callback();
    })
    .catch(errorCatcher);
};

export const updateExecutor = (id, callback) => {
  return (dispatch, getState) => {
    axios
      .patch(`/purchaser/orders/${getState().orders.currentOrder.id}/change-executor/`, {
        executor_id: id
      })
      .then(
        (response) => {
          callback?.();
        },
        (error) => {
          message.error(error.response.data.errors[0].message);
        }
      );
  };
};

export const saveOrder = (userType, orderId, order) => (dispatch) => {
  axios.put(`/${userType}/orders/${orderId}/save/`, order)
    .then((response) => {
      compose(dispatch, setCurrentOrderData)(response.data);
      message.success("Заказ успешно сохранен");
    })
    .catch(errorCatcher);
};

export const getProviderRequisites = (orderId) => async (dispatch) => {
  await axios.get(`/purchaser/orders/${orderId}/provider-requisite/`)
    .then((response) => compose(dispatch, setProviderRequisitesDataAction)(response.data))
    .catch(errorCatcher);
  compose(dispatch, setProviderRequisitesLoadingAction)(false);
};

export const updateProviderRequisites = (orderId, updatedRequisites) => (dispatch) => {
  axios.patch(`/purchaser/orders/${orderId}/provider-requisite/`, updatedRequisites)
    .then((response) => {
      compose(dispatch, setProviderRequisitesDataAction)(response.data);
      message.success("Реквизиты поставщика успешно сохранены");
    })
    .catch(errorCatcher);
};
