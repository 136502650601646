import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  deleteExpenditure,
  updateExpenditure,
} from 'redux/modules/common/building/materials/specification';

import { TableHeader } from 'components/UI/TotoRowTable/TableHeader/TableHeader';
import { TableOslaLocal } from 'components/UI/TableOslaCustom/TableOslaLocal';
import ProductInfo from 'components/UI/organism/ProductInfo';
import AddRelationToProduct from 'components/UI/organism/AddRelationToProduct';
import AddFile from '../../../AddFile';
import MatchingModal from '../../../../../../UI/atoms/MatchingModal/MatchingModal';
import ConfirmationModal from '../../../../../../UI/molecules/ConfirmationModal';
import ProductFilesModal from '../../../../../Requisition/components/ProductFilesModal';
import { getMatchingType } from 'components/UI/atoms/MatchingModal/utils';
import RequiredDays from './components/RequiredDays/RequiredDays';

import Delete from 'images/icons/Delete';

import styles from './Expenditure.module.scss';

const Expenditure = ({ data, objectId, typeUrl, canEdit }) => {
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [countValue, setCountValue] = useState('count');
  const [priceValue, setPriceValue] = useState('price');
  const [expenditure, setExpenditure] = useState(null);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [idAddRelation, setIdAddRelation] = useState(false);
  const [sliderAddFile, setSliderAddFile] = useState(false);

  const countUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, typeUrl, id, { count: data }));
  };

  const priceUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, typeUrl, id, { price: data }));
  };

  const handleRemoveExpenditure = (id) => {
    dispatch(deleteExpenditure(objectId, typeUrl, id));
    setIsDeleteModalOpen(false);
  };

  const closeConfirmationModal = () => {
    setIsDeleteModalOpen(false)
  }

  const nameBlock = (cell, row) => {
    return typeUrl === 'specification' ? (
      <div>
        <span style={styles.quontityCell}>{cell}</span>
        <div className={styles.labelBox}>
          <div className={styles.blueLabel}>Тип: {row?.brand}</div>
          <ProductFilesModal 
            files={row.files}
            permissions={{
              addFiles: canEdit,
              deleteFiles: canEdit
            }}
          />
        </div>
      </div>
    ) : (
      <div className={styles.contentBox}>
        <div className={styles.box1}>
          <span>{cell}</span>
          <div className={styles.blueLabel}>Тип: {row?.brand}</div>
        </div>
    
        <ProductFilesModal 
          files={row.files}
          permissions={{
            addFiles: canEdit,
            deleteFiles: canEdit
          }}
        />
      </div>
    );
  };

  const countBlock = (cell, row) => {
    return (
      <input
        disabled={!canEdit}
        className={styles.input}
        value={cell}
        onBlur={(e) => {
          countUpdate(row.id, e.target.value);
        }}
        onChange={(e) => {
          setCountValue(e.target.value);
        }}
      />
    );
  };

  const priceBlock = (cell, row) => {
    return (
      <div className={styles.inputBox}>
        <input
          disabled={!canEdit}
          className={styles.input}
          value={cell}
          onBlur={(e) => priceUpdate(row.id, e.target.value)}
          onChange={(e) => {
            setPriceValue(e.target.value);
          }}
        />
        {typeUrl === 'specification' && (
          <>
            <MatchingModal 
              info={row}
              type={getMatchingType(row.type)}
              updateProducts={setExpenditure}
              objectId={setIdAddRelation}
            />
            <RequiredDays
              objectId={objectId}
              itemId={row?.id}
              delayDays={row?.delay_days} 
            />
          </>
        )}
        {canEdit && <div
          className={styles.checkFile}
          onClick={() => {
            setExpenditure(row);
            setIsDeleteModalOpen(true);
          }}
        >
          <Delete />
        </div>}
      </div>
    );
  };

  return (
    <div className={styles.tableWrapper}>
      <TableOslaLocal
        data={data}
        pagination={false}
        rowClass={styles.tableRowClass}
        headerClass={styles.tableHeaderClass}
      >
        <TableHeader
          style={{ width: '50%', textAlign: 'left', padding: '0 3.88rem' }}
          cellStyle={{ textAlign: 'left', width: '50%', padding: '1rem 3.88rem'}}
          data={'name'}
          formatter={nameBlock} 
        >
          <div className={styles.tableTextBold}>
            Наименование
          </div>
        </TableHeader>
        <TableHeader 
          data={'measure'} 
          style={{ width: '5%', textAlign: 'center' }}
          cellStyle={{ textAlign: 'center', width: '5%'}}
        >
          <div className={styles.tableTextBold}>
            Ед. изм.
          </div>
        </TableHeader>
        <TableHeader
          data={countValue} 
          formatter={countBlock}
          style={{ width: '6.5%', textAlign: 'left' }}
          cellStyle={{ textAlign: 'left', width: '6.5%'}}
        >
          <div className={styles.tableTextBold}>
            Количество
          </div>
        </TableHeader>
        <TableHeader 
          data={priceValue} 
          formatter={priceBlock}
          style={{ width: '15%', textAlign: 'left' }}
          cellStyle={{ textAlign: 'left', width: '15%'}}
        >
          <div className={styles.tableTextBold}>
            Цена&nbsp;₽
          </div>
        </TableHeader>
      </TableOslaLocal>
      {isDeleteModalOpen && (
        <ConfirmationModal 
          isOpen={isDeleteModalOpen}
          onClose={closeConfirmationModal}
          action={() => handleRemoveExpenditure(expenditure.id)}
        >
          Удалить {expenditure.name}?
        </ConfirmationModal>
      )}
      <AddRelationToProduct
        idAddRelation={idAddRelation}
        handleClose={() => setIdAddRelation(false)}
        objectId={objectId}
        editable={idAddRelation.editable}
      />
      <ProductInfo
        isOpen={isInfoModalOpen}
        closeModal={() => setIsInfoModalOpen(false)}
        objectId={objectId}
        chosenMatchingProduct={expenditure}
        openRelationToProduct={() => {
          setIdAddRelation({ id: isInfoModalOpen?.id });
        }}
      />
      <AddFile
        objectId={objectId}
        isOpen={sliderAddFile}
        closeFunModal={() => setSliderAddFile(false)}
        obj={expenditure}
      />
    </div>
  );
};

export default Expenditure;