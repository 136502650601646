import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  loadUsing,
  addUsing,
  usingSelector,
  usingLoadingSelector
} from 'redux/modules/common/building/stocks';

import warehouses from "images/icons/navigation/warehouses.svg";
import EmptyPlaceholder from "../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder"
import Tableheader from "./../common/TableHeader/TableHeader"
import ExpenseTableRow from './ExpenseTableRow/ExpenseTableRow';
import { stockMeasureSelector } from '../../../../../redux/modules/common/building/stocks';
import { Spinner } from '../../../../UI/Spinner/Spinner';
import ShowMoreButton from '../../../../UI/atoms/ShowMoreButton';

const Expense = ({ stockId, objectId }) => {
  const measure = useSelector(stockMeasureSelector)
  const dispatch = useDispatch();
  const using = useSelector(usingSelector);
  const isLoading = useSelector(usingLoadingSelector)
  const [filters, setFilters] = useState({ limit: 10, offset: 0 });

  useEffect(() => {
    stockId && loadUseings();
  }, [filters, stockId, objectId]);

  const loadUseings = () => {
    dispatch(loadUsing(stockId, filters));
  }

  const changeFilters = (name, value) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAdd = () => {
    dispatch(addUsing(stockId, {offset: using?.results?.length}))
  }

  return (
    <>
      <Tableheader
        onFiltersChange={changeFilters}
        lastColName="Принято"
        preLastColName="В производстве"
        prePreLastColName="Выдано"
        filter_name='name'
        filter_section='section_name'
      />
      {isLoading && <Spinner />}
      {(!isLoading && !!using?.results?.length) ? using?.results?.map(el => <ExpenseTableRow key={el.id} row={el} measure={measure} />) : null}
      {(!isLoading && !using?.results?.length) ? <EmptyPlaceholder img={warehouses} /> : null}
      <ShowMoreButton 
        handleAdd={handleAdd}
        isExists={!isLoading && (using?.count > using?.results.length)}
        allCount={using?.count}
        showedCount={using?.results.length}
      />
    </>
  );
};

export default Expense;