import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './index.module.scss';
import { TableOslaLocal } from 'components/UI/TableOslaCustom/TableOslaLocal';
import { TableHeader } from 'components/UI/TotoRowTable/TableHeader/TableHeader';
import Select from 'components/UI/atoms/Select/OldSelect';
import { measuresMaterials } from 'utils/constant';
import UpdateExpenditureModal from '../UpdateExpenditureModal';
import BottomSliderMaterials from '../../../BottomSliderMaterials';
import { updateExpenditure } from 'redux/modules/common/building/materials/materials';
import Icon from 'components/UI/Icon';
import settings from 'images/icons/settings.svg';

const Expenditure = ({ materialArray, isSwitch, objectId, data }) => {
  const dispatch = useDispatch();
  const [section, setSection] = useState(null);
  const [countValue, setCountValue] = useState(null);
  const [priceValue, setPriceValue] = useState(null);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [measureValue, setMeasureValue] = useState('measure');

  const nameBlock = (row, cell) => {
    return (
      <div>
        <span>{row}</span>
        <div className={styles.labelBox}>
          <div className={styles.blueLabel}>{cell.justification || 'Цена поставщика'}</div>
        </div>
      </div>
    );
  };
  const measureUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, id, { measure: data.name }));
  };
  const countUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, id, { count: data }));
  };
  const priceUpdate = (id, data) => {
    dispatch(updateExpenditure(objectId, id, { price: data }));
  };

  const measureBlock = (cell, row) => {
    if (isSwitch) {
      return (
        <Select
          options={measuresMaterials}
          defaultValue={row.measure}
          onClick={(id) => {
            setMeasureValue(measuresMaterials.find((item) => item?.id === id));
            measureUpdate(
              row.id,
              measuresMaterials.find((item) => item?.id === id)
            );
          }}
        />
      );
    } else {
      return <span>{row.measure}</span>;
    }
  };
  const countBlock = (cell, row) => {
    if (isSwitch) {
      return (
        <input
          className={styles.input}
          value={countValue || cell}
          onBlur={(e) => {
            countUpdate(row.id, e.target.value);
          }}
          onChange={(e) => {
            setCountValue(e.target.value);
          }}
        />
      );
    } else {
      return <span>{Number(row.count).toFixed(2)}</span>;
    }
  };
  const priceBlock = (cell, row) => {
    if (isSwitch) {
      return (
        <div className={styles.lastRow}>
          <input
            className={styles.input}
            value={priceValue || cell}
            onBlur={(e) => priceUpdate(row.id, e.target.value)}
            onChange={(e) => {
              setPriceValue(e.target.value);
            }}
          />
          <div
            className={styles.iconBox}
            onClick={() => {
              setSection(row);
              setIsModalUpdate(true);
            }}
          >
            <Icon icon={settings} />
          </div>
          <div>&nbsp;&nbsp;</div>
        </div>
      );
    } else {
      return <span>{row.price}</span>;
    }
  };
  const closePositionEditModal = () => {
    setIsModalUpdate(false);
  };
  return (
    <div className={styles.tableWrapper}>
      <BottomSliderMaterials isOpen={isModalUpdate} closeFunc={closePositionEditModal}>
        <UpdateExpenditureModal data={data} objId={objectId} section={section} close={closePositionEditModal} />
      </BottomSliderMaterials>
      <TableOslaLocal
        data={materialArray}
        pagination={false}
        rowClass={styles.tableRowClass}
        headerClass={styles.tableHeaderClass}
        
      >

          <TableHeader 
              style={{ width: '2%', textAlign: 'left', padding: '0 3.88rem' }}
              cellStyle={{ textAlign: 'left', width: '2%', padding: '1rem 3.88rem'}}
              data={'number'}
          >
              <div className={styles.tableTextBold}>
                  №
              </div>
          </TableHeader>
          
          <TableHeader 
              data={'name'} 
              formatter={nameBlock} 
              style={{ width: '35%', textAlign: 'left', padding: '0 3.88rem' }}
              cellStyle={{ textAlign: 'left', width: '35%', padding: '1rem 3.88rem'}}
          >
              <div className={styles.tableTextBold}>
                Наименование
              </div>
          </TableHeader>

          <TableHeader 
              data={measureValue} 
              formatter={measureBlock} 
              style={{ width: '5%', textAlign: 'center'}}
              cellStyle={{ textAlign: 'center', width: '5%'}}
            >
            <div className={styles.tableTextBold}>
              Ед. изм.
            </div>
          </TableHeader>
          <TableHeader 
              data={'count'} 
              formatter={countBlock} 
              style={{ width: '7%', textAlign: 'left', paddingLeft: '4.88rem' }}
              cellStyle={{ textAlign: 'left', width: '7%', padding: '1rem 5.2rem'}}
          >
            <div className={styles.tableTextBold}>
              Количество
            </div>
          </TableHeader>
          <TableHeader 
              data={'price'} 
              formatter={priceBlock} 
              style={{ width: '13%', textAlign: 'left', paddingLeft: '4.88rem' }}
              cellStyle={{ textAlign: 'left', width: '13%', padding: '1rem 5.2rem'}}
          >
            <div className={styles.tableTextBold}>
              Цена&nbsp;₽
            </div>
          </TableHeader>
        
      </TableOslaLocal>
    </div>
  );
};

export default Expenditure;
