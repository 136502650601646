import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { eventsSelector, getEvents, isLoadingEventsSelector } from "../../../../redux/modules/purchaser/events";
import { objectsBreadcrumbsSelector } from "../../../../redux/modules/common/building/objects";

import SliderModal from "components/UI/atoms/SliderModal";
import CalendarRange from "components/UI/molecules/CalendarRange";
import EventsList from "./components/EventsList/EventsList";
import OldSelect from "../../../UI/atoms/Select/OldSelect";
import EmptyPlaceholder from '../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder';

import { eventsBtnSvg } from '../CalendarHeader/eventsBtnSvg';
import arowSelectSmallBlue from "images/icons/arowSelectSmallBlue.svg";
import deleteBtnSelected from "../../../../images/icons/deleteBtnSelected.png";

import styles from "./index.module.scss";
import TabBarNotLinks from "elements/navigation/TabBar/TabBarNotLinks";
import { CALENDAR_MODAL_TABS, EVENT_TYPES, calendarModalTabs } from "./constants";

const SliderModalCalendar = ({ isOpen, closeHandler, dateBeforeDefault, dateAfterDefault }) => {
  const dispatch = useDispatch();
  const projects = useSelector(objectsBreadcrumbsSelector);

  const events = useSelector(eventsSelector);
  const isEventsLoading = useSelector(isLoadingEventsSelector);

  const [tab, setTab] = useState(CALENDAR_MODAL_TABS.ALL_EVENTS);
  const [objSelect, setObjSelect] = useState(null);
  const [dateAfter, setDateAfter] = useState(dateAfterDefault);
  const [dateBefore, setDateBefore] = useState(dateBeforeDefault);
  const [filteredEventTypesIds, setFilteredEventTypesIds] = useState([]);
  const [showType, setShowType] = useState(null);

  const objSelectArr = () =>
    projects?.results.map((el) => ({
      id: el.id,
      name: el.name,
      label: el.name,
    })) || [];

  const newObj = [
    ...objSelectArr(),
  ];

  const newTypesHandler = () =>
    EVENT_TYPES.map((event) => {
      if (filteredEventTypesIds.includes(event.id)) {
        return null;
      } else {
        return {
          id: event.id,
          name: event.name,
          label: event.name,
        };
      }
    }).filter((event) => event) || [];

  const newTypesArray = [
    {
      id: 0,
      name: `Типы событий: ${filteredEventTypesIds.length}/${EVENT_TYPES.length}`,
      label: `Типы событий: ${filteredEventTypesIds.length}/${EVENT_TYPES.length}`,
    },
    ...newTypesHandler(),
  ];

  const displaySelectedEvents = (id) => {
    const foundEvent = EVENT_TYPES.find((eventType) => eventType.id === id);
    return !!foundEvent ? foundEvent.name : null;
  };

  const handleEventsSelect = (val) => {
    if (filteredEventTypesIds.includes(val)) {
    } else {
      const newArr = filteredEventTypesIds.slice(0);
      newArr.push(val);
      setFilteredEventTypesIds(newArr);
      setShowType(newTypesArray[0].label);
    }
  };

  const deleteSelectedItem = (val) => {
    const newArr = filteredEventTypesIds.filter((item) => item !== val);
    setFilteredEventTypesIds(newArr);
  };

  const resetStatesToInitial = () => {
    setTab(CALENDAR_MODAL_TABS.ALL_EVENTS);
    setObjSelect(null);
    setFilteredEventTypesIds([]);
  };

  useEffect(() => {
    if (tab === CALENDAR_MODAL_TABS.ALL_EVENTS) setFilteredEventTypesIds([]);
  }, [tab]);

  useEffect(() => {
    setDateAfter(dateAfterDefault);
    setDateBefore(dateBeforeDefault);
  }, [dateAfterDefault, dateBeforeDefault]);

  useEffect(() => {
    if (!isOpen) return;
    dispatch(
      getEvents({
        date_after: dateAfter.format("YYYY-MM-DD"),
        date_before: dateBefore.format("YYYY-MM-DD"),
        building_id: objSelect === "Наименование объекта" ? null : objSelect,
        events: filteredEventTypesIds.length === 1 ? filteredEventTypesIds[0] : "events",
      })
    );
  }, [dateAfter, dateBefore, objSelect, filteredEventTypesIds]);

  useEffect(() => {
    if (isOpen) return;
    setTimeout(resetStatesToInitial, 500);
  }, [isOpen]);

  const eventsByTypesList = useCallback(() => {
    if (filteredEventTypesIds.length === 0) {
      return (
        <EmptyPlaceholder 
          svgJsx={eventsBtnSvg} 
          text={'Выберите тип события'}
          className={styles.placeholder}
        />
      )
    }

    return (
      <EventsList 
        events={events} 
        isEventsLoading={isEventsLoading} 
      />
    )
  }, [events, filteredEventTypesIds.length, isEventsLoading]);

  return (
    <>
      <SliderModal isOpen={isOpen} closeHandler={() => closeHandler()}>
        <div className={styles.fixedHeader}>
          <header className={styles.headerContent}>
            <div className={styles.container2}>
              <div className={styles.eventsWithDataRange}>
                <div className={styles.boxTopTitle}>События за</div>
                <div className={styles.calendarRange}>
                  <CalendarRange
                    defaultDateStart={dateAfter}
                    setDefaultDateStart={setDateAfter}
                    defaultDateEnd={dateBefore}
                    setDefaultDateEnd={setDateBefore}
                  />
                </div>
              </div>
              <div className={styles.dataAndObjectFilters}>
                <div className={styles.selectCode}>
                  <OldSelect
                    className={styles.select}
                    defaultValue={objSelect}
                    icon={arowSelectSmallBlue}
                    options={newObj}
                    onClick={(val) => setObjSelect(val)}
                    placeholder="Проект"
                  />
                </div>
                <div className={styles.boxTopDateCount}>Всего: {events?.length}</div>
              </div>
              <div className={styles.tabsContainer}>
                <TabBarNotLinks tabs={calendarModalTabs} onClick={setTab} activeId={tab} className={styles.tabs} />
              </div>
              {tab === CALENDAR_MODAL_TABS.EVENTS_BY_TYPES && (
                <div style={{ width: "100%" }}>
                  <div className={styles.typesTitle}>Укажите типы событий:</div>
                  <div className={styles.selectedBlock}>
                    {filteredEventTypesIds.map((item) => (
                      <div key={item} className={styles.selectedItem}>
                        <div className={styles.selectedItemText}>{displaySelectedEvents(item)}</div>
                        <div onClick={() => deleteSelectedItem(item)} style={{ cursor: "pointer" }}>
                          <img src={deleteBtnSelected} alt="" />
                        </div>
                      </div>
                    ))}
                  </div>

                  <OldSelect
                    id={styles.eventsSelect}
                    className={styles.select}
                    defaultValue={showType}
                    icon={arowSelectSmallBlue}
                    options={newTypesArray}
                    onClick={(val) => handleEventsSelect(val)}
                    placeholder={`Типы событий: ${filteredEventTypesIds.length}/${EVENT_TYPES.length}`}
                  />
                </div>
              )}
            </div>
          </header>
        </div>
        <div className={styles.positionTypesEvents}>
          {tab === CALENDAR_MODAL_TABS.EVENTS_BY_TYPES && eventsByTypesList()}
        </div>
        <div className={styles.scrollEventsPosition}>
          {tab === CALENDAR_MODAL_TABS.ALL_EVENTS && (
            <EventsList 
              events={events} 
              isEventsLoading={isEventsLoading} 
            />
          )}
        </div>
      </SliderModal>
    </>
  );
};

export default React.memo(SliderModalCalendar);
