export const VIEW_PURCHASES_SECTION = "viewPurchasesSection";
export const VIEW_REQUISITIONS_LIST = "viewRequisitionsSection";
export const VIEW_REQUISITION = "viewRequisition";
export const VIEW_PROCESS_REQUISITION = "viewProcessRequisition";
export const VIEW_PROCESS_ORDERS = "viewProcessOrders";
export const VIEW_PROCESS_PAYMENTS = "viewProcessPayments";
export const VIEW_CREATE_REQUISITION = "viewCreateRequisition";
export const VIEW_ADD_OUT_OF_ESTIMATE_PRODUCTS = "viewAddOutOfEstimateProducts";
export const VIEW_ADD_ADDITIONAL_PRODUCTS = "viewAddAdditionalProducts";
export const VIEW_EDIT_REQUISITION_BEFORE_APPROVE = "viewAddProducts";
export const VIEW_ASSIGN_REQUISITION_EXECUTOR = "viewAssignRequisitionExecutor";
export const VIEW_REQUISITION_TAKE_TO_WORK = "viewRequisitionTakeToWork";
export const VIEW_REQUISITION_REASSIGN = "viewRequisitionReassign";
export const VIEW_ADD_REQUISITION_APPROVERS = "viewAddRequisitionApprovers";
export const VIEW_ADD_REQUISITION_VIEWERS = "viewAddRequisitionViewers";
export const VIEW_DELETE_REQUISITION_APPROVERS = "viewDeleteRequisitionApprovers";
export const VIEW_DELETE_REQUISITION_VIEWERS = "viewDeleteRequisitionViewers";
export const VIEW_REQUISITION_UN_APPROVE = "viewRequisitionUnApprove";
export const VIEW_REQUISITION_LOGS = "viewRequisitionLogs";
export const VIEW_REQUISITION_IN_PROCESSING_MODE = "viewRequisitionInProcessingMode";
export const VIEW_DELETE_REQUISITION_PRODUCTS_FILES = "viewDeleteRequisitionProductsFiles";
export const VIEW_ADD_REQUISITION_PRODUCTS_FILES = "viewAddRequisitionProductsFiles";
export const VIEW_REQUISITION_PRODUCTS_COMMENTS = "viewRequisitionProductsComments";
export const VIEW_ADD_REQUISITION_PRODUCTS_COMMENTS = "viewAddRequisitionProductsComments";
export const VIEW_CREATE_REQUISITION_PRODUCTS_PROVIDER = "viewCreateRequisitionProductsProvider";
export const VIEW_PRODUCTS_REQUISITION_COMPLETE = "viewProductsRequisitionComplete";

export const VIEW_ORDER_INVOICE_DIFFERENCE = "viewOrderInvoiceDifference";
export const ORDERS_SECTION_VIEW = "viewOrdersSection";
export const ORDER_FILES_ATTACH = "viewOrderAttachFile";
export const ORDER_FILES_DELETE = "viewDeleteOrderAttachedFile";
export const ORDER_PRODUCT_FILE_ATTACH = "viewOrderMaterialAttachFile";
export const ORDER_PRODUCT_FILE_DELETE = "viewDeleteOrderMaterialAttachedFile";
export const ORDER_AUTOMATIC_AGREEMENT_TRANSITION = "automaticTransitionByAgreement";

export const VIEW_DOCUMENTS_SECTION = "viewDocumentsSection";
export const VIEW_BILLS_LIST = "viewBillsList";
export const VIEW_BILL = "viewPurchasesBill";
export const VIEW_BILL_IN_EDIT = "viewPurchasesBillInEdit";
export const VIEW_ADD_BILL_FILES = "viewAddBillFiles";
export const VIEW_DELETE_BILL_FILES = "viewDeleteBillFiles";
export const VIEW_ADD_BILL_PAYMENTS_FILE = "viewAddBillPaymentsFile";
export const VIEW_BILL_PAYMENTS_MAKE_PAID = "viewBillPaymentsMakePaid";
export const VIEW_PACKING_LISTS = "viewPackingLists";
export const VIEW_PACKING_LISTS_VAT_CALCULATION = "viewPackingListsVatCalculation";
export const VIEW_PACKING_LIST_PAGE = "viewPackingListPage";
export const VIEW_PACKING_LIST_ACCEPT = "viewPackingListAccept";
export const VIEW_ADD_PACKING_LIST_FILES = "viewAddPackingListFiles";
export const VIEW_DELETE_PACKING_LIST_FILES = "viewDeletePackingListFiles";
export const VIEW_CONTRACTS_LIST = "viewContractsList";
export const VIEW_DELETE_CONTRACT = "viewDeleteContract";
export const VIEW_ADD_CONTRACT_FILES = "viewAddContractFiles";
export const VIEW_DELETE_CONTRACT_FILES = "viewDeleteContractFiles";
export const VIEW_EXPORT_LIST = "viewDocumentsExportList";
export const VIEW_DELETE_EXPORTS = "viewDeleteDocumentsExports";

export const VIEW_COUNTERPARTIES_SECTION = "viewCounterpartiesSection";
export const VIEW_CONTACTS_LIST = "viewContactsList";
export const VIEW_ADD_CONTACT = "viewAddContact";
export const VIEW_DELETE_CONTACTS = "viewDeleteContacts";
export const VIEW_EDIT_CONTACT = "viewEditContact";
export const VIEW_SUPPLIERS_LIST = "viewSuppliersList";
export const VIEW_INVITE_SUPPLIER = "viewInviteSupplier";
export const VIEW_DELETE_SUPPLIERS = "viewDeleteSuppliers";
export const VIEW_ADD_SUPPLIERS_CONTRACTS = "viewAddSuppliersContracts";
export const VIEW_DELETE_SUPPLIERS_CONTRACTS = "viewDeleteSuppliersContracts";
export const VIEW_EDIT_SUPPLIER_MANAGER = "viewAddSupplierManager";
export const VIEW_ADD_SUPPLIERS_CONTRACTS_FILES = "viewAddSuppliersContractsFiles";
export const VIEW_DELETE_SUPPLIERS_CONTRACTS_FILES = "viewDeleteSuppliersContractsFiles";
export const VIEW_INVITES_LIST = "viewCounterpartiesInvitesList";
export const VIEW_INVITES_LIST_ACTIONS = "viewCounterpartiesInvitesListActions";

export const VIEW_PURCHASES_CHART = "viewPurchasesChart";
export const VIEW_PURCHASES_CHART_MATERIALS = "viewPurchasesChartMaterials";
