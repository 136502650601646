import React, { useCallback, useState } from "react";
import { Route, Switch } from "react-router-dom";

import { ROUTES } from "../../../../../constants/routes";

import PlanFactList from "./List/index";
import PlanFactItem from "./Item/index";

import styles from "./PlanFact.module.scss";
import TemplateBase from "../../../../UI/templates/TemplateBase";
import { measureSwitcher } from "../../../../../utils/measureSwitcher";

export const PlanFactMeasureContext = React.createContext({
  measure: 1,
  setMeasure: () => {},
  toggleMeasure: () => {},
});

const PlanFact = (props) => {
  const { children } = props;
  const [measure, setMeasure] = useState(1);
  const toggleMeasure = useCallback(
    () => setMeasure((prevState) => measureSwitcher(prevState)),
    []
  );
  return (
    <TemplateBase>
      <PlanFactMeasureContext.Provider value={{ measure, setMeasure, toggleMeasure }}>
        <div className={styles.container}>
          <Switch>
            <Route
              exact
              path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT}
              component={PlanFactList}
            />
            <Route
              exact
              path={ROUTES.CONSTRUCTING_FINANCE_PLAN_FACT_ITEM}
              component={PlanFactItem}
            />
          </Switch>
        </div>
      </PlanFactMeasureContext.Provider>
    </TemplateBase>
  );
};

export default PlanFact;
