import ButtonBase from 'components/UI/atoms/ButtonBase';
import InputNumber from 'components/UI/atoms/InputNumber';
import CalendarRange from 'components/UI/molecules/CalendarRange';
import BottomControls from 'components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls';
import ModalContainer from 'components/UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer'
import React from 'react'
import styles from "./SectionPlanModal.module.scss"
import { useSectionPlan } from './useSectionPlan';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    sectionName: string;
    sectionId: number
}

const SectionPlanModal: React.FC<IProps> = ({
    isOpen,
    onClose,
    sectionName,
    sectionId
}) => {
    const { dateEnd, dateStart, setDateEnd, setDateStart, submitHandler } = useSectionPlan(sectionId, onClose)

    return (
        <ModalContainer
            headlineClassName={styles.headline}
            isOpen={isOpen}
            onClose={onClose}
            name="Добавить в план"
            customHeadline={<div className={styles.subtitle}>{sectionName}</div>}
        >
            <div className={styles.field}>{/* @ts-ignore */}
                <CalendarRange
                    label="Укажите период"
                    defaultDateEnd={dateEnd}
                    defaultDateStart={dateStart}
                    setDefaultDateEnd={setDateEnd}
                    setDefaultDateStart={setDateStart}
                />
            </div>
            <BottomControls isExists isDoubleBtns>
                <ButtonBase secondary onClick={onClose}>Отменить</ButtonBase>
                <ButtonBase primary onClick={submitHandler}>Сохранить</ButtonBase>
            </BottomControls>
        </ModalContainer>
    )
}

export default SectionPlanModal