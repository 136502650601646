import React from "react";

import { splitThousands } from "../../../../../../utils/splitThousands";

import Modal from "components/UI/atoms/Modal";
import Button from "components/UI/atoms/ButtonBase";

import { IKs3Row, IKsModalProps, KS_TYPES } from "../../types";

import styles from "../../Ks23Modal.module.scss";
import { useKs23 } from "../../useKs23";

const Ks3Modal: React.FC<IKsModalProps> = ({ isOpen, onClose, expenditureId, buildingId, year, month, isPrefetch }) => {
  const {
    ks: ks3,
    handleDownloadFile,
    handleClose,
  } = useKs23({ expenditureId, buildingId, isPrefetch, year, month, type: KS_TYPES.ks3, onClose });
  const hasRows = ks3?.rows && ks3.rows.length > 0;

  return (
    <Modal isOpen={isOpen && hasRows} onClose={handleClose} className={styles.ksModal}>
      <div className={styles.ksContent}>
        <h1>
          СПРАВКА <br /> О СТОИМОСТИ ВЫПОЛНЕННЫХ РАБОТ И ЗАТРАТ
        </h1>
        <table className={styles.table}>
          <thead>
            <tr>
              <td rowSpan={2}>Номер по порядку</td>
              <td rowSpan={2}>
                Наименование пусковых комплексов, этапов, объектов, видов выполненных работ, оборудования, затрат
              </td>
              <td rowSpan={2}>Код</td>
              <td colSpan={3}>Стоимость выполненных работ и затрат, руб.</td>
            </tr>
            <tr>
              <td>с начала проведения работ</td>
              <td>с начала года</td>
              <td>в том числе за отчетный период</td>
            </tr>
          </thead>
          <tbody>
            {ks3?.rows?.map((row: IKs3Row, index: number) => (
              <tr key={row.justification + index}>
                <td>{index + 1}</td>
                <td>{row.name}</td>
                <td>{row.justification}</td>
                <td>{splitThousands(row.total_price, false, false)}</td>
                <td>{splitThousands(row.month_price, false, false)}</td>
                <td>{splitThousands(row.year_price, false, false)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.actionBlock}>
          <Button primary onClick={handleDownloadFile}>
            Выгрузить
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(Ks3Modal);
