import React, { useCallback, useState } from "react";

export const useBranchHoverZIndex = (
  wrapperRef: React.RefObject<HTMLElement>
) => {
  const [isBranchZIndexIncreased, setIsBranchZIndexIncreased] = useState(false);

  const increaseBranchZIndex = useCallback(() => {
    if (!wrapperRef.current) return;
    const branchCandidate = wrapperRef.current?.closest(".calendar_branch");
    if (branchCandidate) {
      (branchCandidate as HTMLElement).style.zIndex = "80";
      setIsBranchZIndexIncreased(true);
    }
  }, [wrapperRef.current]);

  const decreaseBranchZIndex = useCallback(() => {
    if (!wrapperRef.current) return;
    const branchCandidate = wrapperRef.current?.closest(".calendar_branch");
    if (branchCandidate) {
      (branchCandidate as HTMLElement).style.zIndex = "";
      setIsBranchZIndexIncreased(false);
    }
  }, [wrapperRef.current]);

  return {
    increaseBranchZIndex,
    decreaseBranchZIndex,
    isBranchZIndexIncreased,
  };
};
