import React from 'react'
import { personFullNameWithId } from 'types/personsTypes'
import styles from "./ManufacturingModal.module.scss"
import ManufacturingModalDivider from '../../../../UI/organism/WorkOrMaterialsModals/components/ManufacturingModalDivider/ManufacturingModalDivider'
import DayWorkerAndEvent from 'components/UI/organism/WorkOrMaterialsModals/components/DayWorkerAndEvent/DayWorkerAndEvent'
import PeriodOfExecution from 'components/UI/organism/WorkOrMaterialsModals/components/PeriodOfExecution/PeriodOfExecution'
import PairOfCountAndAmount from 'components/UI/organism/WorkOrMaterialsModals/components/PairOfInfoItems/PairOfCountAndAmount'

type propsType = {
    planCreatingDate?: string,
    planCreator?: personFullNameWithId,
    planApprovingDate?: string;
    planApprover?: personFullNameWithId;
    planIsInWork?: boolean;
    planCount?: string;

    projectAmount?: string;
    projectFullAmount?: string;
    projectFullCount?: string;
    measure: string;
    dateStart: string;
    dateEnd: string;
}

function ManufacturingModalContentPlan({
    planCreatingDate,
    planCreator,
    planApprovingDate,
    planApprover,
    planIsInWork,
    planCount,
    measure,
    dateEnd,
    dateStart,
    projectAmount,
    projectFullAmount,
    projectFullCount
}: propsType) {

    return (
        <>
            {planIsInWork && <ManufacturingModalDivider type="plan" className={styles.mt0} />}
            <DayWorkerAndEvent date={planCreatingDate!} author={planCreator!} label="Создал:" />
            <DayWorkerAndEvent date={planApprovingDate!} author={planApprover!} label="Согласовал:" />
            <PairOfCountAndAmount
                count={planCount!}
                amount={projectAmount!}
                labelAmount="Стоимость (по проекту):"
                labelCount="Кол-во (план):"
                measure={measure}
            />
            <PeriodOfExecution dateEnd={dateEnd} dateStart={dateStart} />
            <PairOfCountAndAmount
                count={projectFullCount!}
                amount={projectFullAmount!}
                labelAmount="Бюджет (по проекту):"
                labelCount="Всего (по проекту):"
                measure={measure}
            />
        </>
    )
}

export default ManufacturingModalContentPlan