import React from "react";
import cn from "classnames";

import ButtonBase from "components/UI/atoms/ButtonBase";
import Modal from "components/UI/atoms/Modal";

import DangerIcon from "../../../../images/icons/DangerIcon";

import styles from "./index.module.scss";

const ConfirmModal = (props) => {
  const {
    isOpen,
    children,
    onClose,
    action,
    acceptButtonText = "Да",
    cancelButtonText = "Отменить",
    title = "Подтвердите действие",
    variant = "primary"
  } = props;

  return (
    <Modal className={cn(styles.confirmationModal, styles[variant])} isOpen={isOpen} title={title} onClose={onClose}>
      <div className={styles.content}>
        {variant === "secondary" && <DangerIcon />}
        <p className={styles.text}>{children}</p>
      </div>
      <div className={styles.actionBlock}>
        {variant === "primary" && (
          <>
            <ButtonBase medium secondary onClick={onClose}>
              {cancelButtonText}
            </ButtonBase>
            <ButtonBase medium primary onClick={action}>
              {acceptButtonText}
            </ButtonBase>
          </>
        )}
        {variant === "secondary" && (
          <>
            <ButtonBase error onClick={action}>
              {acceptButtonText}
            </ButtonBase>
            <ButtonBase tertiary onClick={onClose}>
              {cancelButtonText}
            </ButtonBase>
          </>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(ConfirmModal);