import React, { useCallback, useMemo, useState } from "react";

import Input from "components/UI/atoms/Input";
import Checkbox from "components/UI/Checkbox";

import colsStyles from "../index.module.scss";
import styles from "./index.module.scss";

const ProductRow = ({ data, type, products, setProducts }) => {
  const [requiredCount, setRequiredCount] = useState("");

  const isChecked = useMemo(
    () => products?.length > 0 && products.some((item) => item.id === data.id),
    [products, data.id]
  );

  const handleChangeInput = useCallback(
    (value) => {
      setRequiredCount(value);
      data.required_count = value;
      setProducts(products.map((item) => (item?.id === data.id ? data : item)));
    },
    [products, data]
  );

  const onClickCheckbox = useCallback(() => {
    if (!products) return;

    data.required_count = requiredCount;

    if (!isChecked) {
      setProducts([...products, data]);
      return;
    }
    setProducts(products.filter((item) => item.id !== data.id));
  }, [products, data, isChecked]);

  return (
    <div className={styles.container}>
      <div className={colsStyles.checkboxCol}>
        <Checkbox onChange={onClickCheckbox} checked={isChecked} />
      </div>
      <div className={colsStyles.nameCol} onClick={onClickCheckbox}>
        {data.name}
      </div>
      <div className={colsStyles.countCol}>
        <Input
          className={styles.input}
          classNameInput={styles.inputInner}
          value={requiredCount}
          setValue={handleChangeInput}
        />
      </div>
      <div className={colsStyles.measureCol}>{data.measure}</div>
      {type !== "out-of-estimate" && (
        <div className={colsStyles.projectCountCol} title={data.balance_count < 0 ? "Заказано больше, чем в смете" : undefined}>
          {data.balance_count}
        </div>
      )}
    </div>
  );
};

export default React.memo(ProductRow);
