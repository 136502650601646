import moment from "moment";
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import ChartMaterialModal from "components/UI/organism/WorkOrMaterialsModals/ChartMaterialModal/ChartMaterialModal";

import DiagramInterval from "../DiagramInterval/DiagramInterval";
import InfoPopup from "../InfoPopup/InfoPopup";

import { INTERVAL_TYPES, MATERIALS_COLOR_MAP } from "../../../../constants";

import { useUnitMultiplier } from "../../../../hooks/useUnitMultiplier";

import { getIntervalDatesLabel } from "../../../../utils";

import TicketTail from "../../../../../../../images/TicketTail";

import styles from "../../Month.module.scss";

const getMaterialStartDate = (material) =>
  material.actualItem.start_at ||
  material.actualItem.workinterval?.start_at ||
  moment(material.actualItem.confirm_date).format("YYYY-MM-DD");

const getMaterialEndDate = (material) =>
  material.actualItem.end_at ||
  material.actualItem.workinterval?.end_at ||
  moment(material.actualItem.confirm_date).format("YYYY-MM-DD");

const Material = ({ branch, isSection = false, offsetLeft, objectId }) => {
  const unitMultiplier = useUnitMultiplier();
  const [hover, setHover] = useState(false);
  const { objectId: paramsObjectId } = useParams();
  if (!objectId) objectId = paramsObjectId;

  const materialWrapperRef = useRef(null);

  const { plans, purchases, on_stock, stockless, accepted, payed } =
    branch.material;

  let actualMaterial;
  if (plans) actualMaterial = plans;
  if (purchases) actualMaterial = purchases;
  if (on_stock) actualMaterial = on_stock;
  if (stockless) actualMaterial = stockless;
  if (accepted) actualMaterial = accepted;
  if (payed) actualMaterial = payed;

  const fillColor = MATERIALS_COLOR_MAP[actualMaterial.type];

  const onHover = (e) => {
    const containerNode = e.target?.closest(".calendar_node");
    if (!containerNode) return;
  };

  const leftTailProps = {
    fillColor,
    borderColor: fillColor,
    lineColor: "none",
  };
  const rightTailProps = {
    ...leftTailProps,
    isRight: true,
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const closeHandler = () => {
    setIsModalOpen(false);
    setHover(false);
  };

  if (!actualMaterial) return null;

  const startDate = getMaterialStartDate(actualMaterial);
  const endDate = getMaterialEndDate(actualMaterial);

  const currentStatuses = [
    plans,
    purchases,
    on_stock,
    stockless,
    accepted,
    payed,
  ]
    .map((material) => {
      return (
        material &&
        (moment(getMaterialStartDate(material)).isSame(
          moment(startDate),
          "days"
        ) ||
          moment(getMaterialEndDate(material)).isSame(
            moment(endDate),
            "days"
          )) &&
        material.type
      );
    })
    .filter((x) => x);

  const interval = getIntervalDatesLabel(startDate, endDate);

  const materialWrapperStyles = {
    zIndex: actualMaterial === "plans" ? 0 : 1,
  };

  const materialStyles = {
    borderTop: `1px solid ${fillColor}`,
    borderBottom: `1px solid ${fillColor}`,
    backgroundColor: fillColor,
  };

  return (
    <DiagramInterval
      day={branch.day}
      daysLength={Math.max(actualMaterial.days, 1)}
      unitMultiplier={unitMultiplier}
      onMouseEnter={onHover}
      wrapperStyles={materialWrapperStyles}
      offsetLeft={offsetLeft}
      hover={hover}
      setHover={setHover}
      intervalWrapperRef={materialWrapperRef}
      isCardOpen={isModalOpen}
      type={INTERVAL_TYPES.material}
    >
      <TicketTail {...leftTailProps} />
      <div className={styles.material} style={materialStyles} />
      <TicketTail {...rightTailProps} />
      {(hover || isModalOpen) && (
        <InfoPopup
          isOpen
          objectId={objectId}
          setOpenPopup={setHover}
          interval={interval}
          data={currentStatuses}
          type={INTERVAL_TYPES.material}
          setOpenModal={setIsModalOpen}
        >
          <div className={styles.popupAnchor} />
        </InfoPopup>
      )}
      <ChartMaterialModal
        isOpen={isModalOpen}
        onClose={closeHandler}
        name={actualMaterial?.data?.name}
        objectId={objectId}
        expId={actualMaterial.data.estimate_expenditure_id}
        start_date={startDate}
        end_date={endDate}
      />
    </DiagramInterval>
  );
};

export default React.memo(Material);
