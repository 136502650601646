import { IJournalInitialState } from "./types";

const moduleName = "journal";

export const SET_STOCK_USING_MATERIALS_PRICE_LIST = `${moduleName}/SET_STOCK_USING_MATERIALS_PRICE_LIST`;
export const SET_IS_STOCK_USING_MATERIALS_PRICE_UPDATE_SUCCESS = `${moduleName}/SET_IS_STOCK_USING_MATERIALS_PRICE_UPDATE_SUCCESS`;

const initialState: IJournalInitialState = {
  stockUsingMaterialsPriceList: null,
  stockUsingMaterialsPriceUpdateIsSuccess: false,
};

export default (state = initialState, action: any): IJournalInitialState => {
  const { payload, type } = action;

  switch (type) {
    case SET_STOCK_USING_MATERIALS_PRICE_LIST:
      return {
        ...state,
        stockUsingMaterialsPriceList: payload,
      };
    case SET_IS_STOCK_USING_MATERIALS_PRICE_UPDATE_SUCCESS:
      return {
        ...state,
        stockUsingMaterialsPriceUpdateIsSuccess: payload,
      };
    default:
      return state;
  }
};
