import { message } from "antd";
import { createSelector } from "reselect";

import {
  apiAddFile,
  apiDeleteExpenditures,
  apiDeleteFile,
  apiDeleteSections,
  apiGetSections,
  apiGetSubSections,
  apiPostExpenditures,
  apiPostSubSections,
  apiUpdateExpenditure,
  apiUpdateSpecificationDetail,
} from "./specificationApi";

import { errorCatcher } from "utils/errorCatcher";

const moduleName = "specification";

const GET_SPECIFICATION = `${moduleName}/GET_SPECIFICATION`;
const SET_SPECIFICATION_SECTIONS = `${moduleName}/SET_SPECIFICATION_SECTIONS`;
const ADD_SPECIFICATION_SECTIONS = `${moduleName}/ADD_SPECIFICATION_SECTIONS`;
const SET_SPECIFICATION_SUB_SECTIONS = `${moduleName}/SET_SPECIFICATION_SUB_SECTIONS`;
const ADD_SPECIFICATION_SUB_SECTIONS = `${moduleName}/ADD_SPECIFICATION_SUB_SECTIONS`;
const ADD_SPECIFICATION_EXPENDITURES = `${moduleName}/ADD_SPECIFICATION_EXPENDITURES`;
const SET_SPECIFICATION_EXPENDITURES = `${moduleName}/SET_SPECIFICATION_EXPENDITURES`;
const UPDATE_SPECIFICATION_EXPENDITURES = `${moduleName}/UPDATE_SPECIFICATION_EXPENDITURES`;
const REMOVE_SPECIFICATION_EXPENDITURES = `${moduleName}/REMOVE_SPECIFICATION_EXPENDITURES`;
const REMOVE_SPECIFICATION_SECTIONS = `${moduleName}/REMOVE_SPECIFICATION_SECTIONS`;
const REMOVE_SPECIFICATION_SUB_SECTIONS = `${moduleName}/REMOVE_SPECIFICATION_SUB_SECTIONS`;
const ADD_FILES = `${moduleName}/ADD_FILES`;
const REMOVE_FILES = `${moduleName}/REMOVE_FILES`;
const SHOW_LOADER = `${moduleName}/SHOW_LOADER`;
const HIDE_LOADER = `${moduleName}/HIDE_LOADER`;
const CHANGE_SPECIFICATION_DELAY_DAYS = `${moduleName}/CHANGE_SPECIFICATION_DELAY_DAYS`;

export const MATERIALS_TYPES = {
  SPECIFICATION: "specification",
  OUT_OF_ESTIMATE: "out_of_estimate",
  ADDITIONAL: "additional",
  MIMS: "service",
};

const initialState = {
  specification_sections: [],
  specification_sub_sections: {},
  specification_expenditures: {},
  isLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload, id, fileId } = action;
  switch (type) {
    case SHOW_LOADER:
      return { ...state, isLoading: true };
    case HIDE_LOADER:
      return { ...state, isLoading: false };
    case GET_SPECIFICATION:
      return { ...state };
    case SET_SPECIFICATION_SECTIONS:
      return {
        ...state,
        specification_sections: payload,
      };
    case ADD_SPECIFICATION_SECTIONS:
      return {
        ...state,
        specification_sections: [payload, ...state.specification_sections],
      };
    case REMOVE_SPECIFICATION_SECTIONS:
      return {
        ...state,
        specification_sections: [
          ...state.specification_sections.filter((el) => el.id !== payload),
        ],
      };

    case SET_SPECIFICATION_SUB_SECTIONS:
      return {
        ...state,
        specification_sub_sections: payload,
      };
    case ADD_SPECIFICATION_SUB_SECTIONS:
      return {
        ...state,
        specification_sub_sections: {
          ...state.specification_sub_sections,
          subsections: [
            payload,
            ...state.specification_sub_sections.subsections,
          ],
        },
      };
    case ADD_SPECIFICATION_EXPENDITURES:
      return {
        ...state,
        specification_sub_sections: {
          ...state.specification_sub_sections,
          subsections: [
            payload,
            ...state.specification_sub_sections.subsections,
          ],
        },
      };
    case REMOVE_SPECIFICATION_SUB_SECTIONS:
      return {
        ...state,
        specification_sub_sections: {
          ...state.specification_sub_sections,
          subsections: [
            ...state.specification_sub_sections.subsections.filter(
              (el) => el.id !== payload
            ),
          ],
        },
      };

    case SET_SPECIFICATION_EXPENDITURES:
      return {
        ...state,
        specification_expenditures: payload,
      };
    case UPDATE_SPECIFICATION_EXPENDITURES:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [
            ...state.specification_expenditures.products.map((el) =>
              el.id === payload.id ? payload : el
            ),
          ],
        },
      };
    case REMOVE_SPECIFICATION_EXPENDITURES:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [
            ...state.specification_expenditures.products.filter(
              (el) => el.id !== payload
            ),
          ],
        },
      };
    case ADD_FILES:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [
            ...state.specification_expenditures.products.map((el) =>
              el.id === id ? { ...el, files: payload } : el
            ),
          ],
        },
      };
    case REMOVE_FILES:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [
            ...state.specification_expenditures.products.map((el) => {
              if (el.id === id) {
                return {
                  ...el,
                  files: el.files.filter((file) => file.id !== fileId),
                };
              } else {
                return el;
              }
            }),
          ],
        },
      };
    case CHANGE_SPECIFICATION_DELAY_DAYS:
      return {
        ...state,
        specification_expenditures: {
          ...state.specification_expenditures,
          products: [
            ...state.specification_expenditures.products.map((item) =>
              item.id === payload?.id ? { ...item, delay_days: payload?.delay_days } : item
            ),
          ],
        },
      };

    default:
      return state;
  }
};
export const stateSelector = (state) => state[moduleName];

export const specificationSectionsSelector = createSelector(
  stateSelector,
  (state) => state.specification_sections
);
export const specificationSubSectionsSelector = createSelector(
  stateSelector,
  (state) => state.specification_sub_sections
);
export const specificationExpendituresSelector = createSelector(
  stateSelector,
  (state) => state.specification_expenditures
);

export const isLoadingSpecificationSelector = createSelector(
  stateSelector,
  (state) => state.isLoading
);

const showLoaderActionSpecification = () => ({ type: SHOW_LOADER });
const hideLoaderActionSpecification = () => ({ type: HIDE_LOADER });

export const getSpecificationsSections =
  (objectId, type) => async (dispatch) => {
    try {
      dispatch(showLoaderActionSpecification());
      const { data } = await apiGetSections(objectId, type);
      await dispatch({
        type: SET_SPECIFICATION_SECTIONS,
        payload: data,
      });
    } catch (error) {
      errorCatcher(error);
    }

    dispatch(hideLoaderActionSpecification());
  };

export const getSpecificationsSubSections = (objectId, type, Id) => {
  return async (dispatch) => {
    try {
      dispatch(showLoaderActionSpecification());
      const { data } = await apiGetSubSections(objectId, type, Id);
      await dispatch({
        type: SET_SPECIFICATION_SUB_SECTIONS,
        payload: data,
      });
      dispatch(hideLoaderActionSpecification());
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const getSpecificationsExpenditures =
  (objectId, type, Id) => async (dispatch) => {
    try {
      dispatch(showLoaderActionSpecification());
      const { data } = await apiGetSubSections(objectId, type, Id);
      await dispatch({
        type: SET_SPECIFICATION_EXPENDITURES,
        payload: data,
      });
    } catch (error) {
      errorCatcher(error);
    }
    dispatch(hideLoaderActionSpecification());
  };

export const addSection = (objectId, config) => {
  return async (dispatch) => {
    try {
      const { data } = await apiPostSubSections(objectId, config);
      await dispatch({
        type: ADD_SPECIFICATION_SECTIONS,
        payload: data,
      });
      await message.success("Сохранено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const addSubSection = (objectId, config) => {
  return async (dispatch) => {
    try {
      const { data } = await apiPostSubSections(objectId, config);
      await dispatch({
        type: ADD_SPECIFICATION_SUB_SECTIONS,
        payload: data,
      });
      await message.success("Сохранено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const addExpenditure = (objectId, type, config) => {
  return async (dispatch) => {
    try {
      await apiPostExpenditures(objectId, type, config);
      await dispatch({
        type: GET_SPECIFICATION,
      });
      await message.success("Сохранено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const deleteSection = (objectId, id) => {
  return async (dispatch) => {
    try {
      await apiDeleteSections(objectId, id);
      await dispatch({
        type: REMOVE_SPECIFICATION_SECTIONS,
        payload: id,
      });
      await message.success(`Раздел удален из списка`);
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const deleteSubSection = (objectId, id) => {
  return async (dispatch) => {
    try {
      await apiDeleteSections(objectId, id);
      await dispatch({
        type: REMOVE_SPECIFICATION_SUB_SECTIONS,
        payload: id,
      });
      await message.success(`Подраздел удален из списка`);
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const deleteExpenditure = (objectId, typeUrl, Id) => {
  return async (dispatch) => {
    try {
      await apiDeleteExpenditures(objectId, typeUrl, Id);
      await dispatch({
        type: REMOVE_SPECIFICATION_EXPENDITURES,
        payload: Id,
      });
      await message.success(`Позиция удалена из списка`);
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const updateExpenditure = (objectId, typeUrl, Id, config) => {
  return async (dispatch) => {
    try {
      const { data } = await apiUpdateExpenditure(
        objectId,
        typeUrl,
        Id,
        config
      );
      await dispatch({
        type: UPDATE_SPECIFICATION_EXPENDITURES,
        payload: data,
      });
      await message.success("Обновлено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const addFile = (objectId, Id, config) => {
  return async (dispatch) => {
    try {
      const { data } = await apiAddFile(objectId, Id, config);
      await dispatch({
        type: ADD_FILES,
        id: Id,
        payload: data,
      });
      await message.success("Добавленно");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const deleteFile = (objectId, Id, fileId, config) => {
  return async (dispatch) => {
    try {
      await apiDeleteFile(objectId, Id, fileId, config);
      await dispatch({
        type: REMOVE_FILES,
        id: Id,
        fileId: fileId,
      });
      await message.success("Удалено");
    } catch (error) {
      errorCatcher(error);
    }
  };
};

export const updateSpecificationDetail = (objectId, specificationId, delayDays) => {
  return async (dispatch) => {
    try {
      const { data } = await apiUpdateSpecificationDetail(
        objectId,
        specificationId,
        delayDays
      );
      dispatch({
        type: CHANGE_SPECIFICATION_DELAY_DAYS,
        payload: data,
      });
      message.success("Количество дней успешно указано");
    } catch (error) {
      errorCatcher(error);
    }
  };
};