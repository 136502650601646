import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSections,
  materialSectionsSelector,
  getMaterialsSubSections,
  materialSubSectionsSelector,
  getMaterialsExpenditures,
  materialExpendituresSelector,
  isLoadingSectionSelector,
} from '../../../../../redux/modules/common/building/materials/materials';
import { Spinner } from '../../../../UI/Spinner/Spinner';
import EmptyPlaceholder from '../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder';
import Section from './components/Section';
import styles from './Estimate.module.scss';
import Icon from 'components/UI/Icon';
import materialsIcon from '../../../../../images/icons/navigation/materials.svg'
import arrowSmallBack from 'images/icons/arrowSmallBack.svg';
import SubSection from './components/SubSection';
import Expenditure from './components/Expenditure';
import cn from 'classnames';
import { CLEAR_MATERIAL_EXPENDITURES, CLEAR_MATERIAL_SUBSECTIONS } from '../../../../../redux/modules/common/building/materials/materials';

const Estimate = (props) => {

  const {objectId, isSwitch} = props

  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSectionSelector)
  const materialSections = useSelector(materialSectionsSelector);
  const materialSubSections = useSelector(materialSubSectionsSelector);
  const materialSubExpenditures = useSelector(materialExpendituresSelector);
  const [isVisibleSection, setIsVisibleSection] = useState(true);
  const [isVisibleExpenditure, setIsVisibleExpenditure] = useState(false)

  const [isVisibleExpenditures, setIsVisibleExpenditures] = useState(false);
  const [isVisibleSubSections, setIsVisibleSubSections] = useState(false)

  useEffect(() => {
    dispatch(getSections(objectId))
    setIsVisibleSection(true)
    setIsVisibleExpenditures(false)
  }, [objectId]);

  const loadSubSection = subSectionId => {
    dispatch(getMaterialsSubSections(objectId, subSectionId))
  }

  const loadSubExpenditures = subSectionId => {
    dispatch(getMaterialsExpenditures(objectId, subSectionId))
  }

  const blockOpenSubSections = item => {
    loadSubSection(item.id)
    setIsVisibleSection(false)
    setIsVisibleSubSections(true)
  }

  const blockOpenExpenditure = subSection => {
    loadSubExpenditures(subSection.id)
    setIsVisibleExpenditure(true)
    setIsVisibleSubSections(false)
  }

  const backToEstimate = () => {
    setIsVisibleSubSections(false)
    setIsVisibleSection(true)
  }

  const backToSubSections = () => {
    setIsVisibleExpenditure(false)
    setIsVisibleSubSections(true)
  }

  React.useEffect(() => {
    if (isVisibleSection && !isVisibleExpenditures) {
      dispatch({type: CLEAR_MATERIAL_SUBSECTIONS})
    }
  }, [isVisibleSection])

  React.useEffect(() => {
    if (!isVisibleExpenditures) {
      dispatch({type: CLEAR_MATERIAL_EXPENDITURES})
    }
  }, [isVisibleExpenditures])

  return (
    <div
      className={cn(styles.container, {
        [styles.active]: isVisibleExpenditures,
      })}
    >
      {materialSections.length === 0 
        ? <EmptyPlaceholder img={materialsIcon} />
        :
        <>
          {isVisibleSection &&
            materialSections?.map((item) => (
              <div
                key={item.id}
              >
                <Section blockOpenSubSections={blockOpenSubSections} data={item} />
              </div>
          ))}
        </>
      }
        {
          isLoading 
          ? <Spinner /> 
          : 
          <>
              {
                isVisibleSubSections && 
                <>
                  <div className={styles.containerBtn} onClick={backToEstimate}>
                    <div className={styles.button}>
                      <Icon icon={arrowSmallBack} className={styles.arrowLeft} />
                      Назад
                    </div>
                    <span>{materialSubSections.name}</span>
                  </div>
                  {materialSubSections.length === 0 
                    ? <EmptyPlaceholder img={materialsIcon}/>
                    : 
                    <>
                      {materialSubSections.subsections && materialSubSections.subsections.map((subSection) => (
                        <div key={subSection.id}>
                          <SubSection blockOpenExpenditure={blockOpenExpenditure} objectId={objectId} data={subSection} key={subSection.id} />
                        </div>
                      ))}
                    </>
                  }
                </>
              }
              {
                isVisibleExpenditure && (
                  <>
                    <div className={styles.containerBtnActive} onClick={backToSubSections}>
                      <div className={styles.button}>
                        <Icon icon={arrowSmallBack} className={styles.arrowLeft} />
                        Назад
                      </div>
                      <span>{materialSubExpenditures?.name}</span>
                    </div>
                    {materialSubExpenditures.expenditure_count === 0 
                      ? <EmptyPlaceholder img={materialsIcon} />
                      : 
                      <Expenditure
                        id={materialSubExpenditures.id}
                        objectId={objectId}
                        materialArray={materialSubExpenditures.expenditures}
                        isSwitch={isSwitch}
                        data={materialSections}
                      />
                    }
                  </>
                )
              }
          </>
      }


    </div>
  );
};
export default Estimate;
