import React, {MouseEventHandler} from 'react'
import cn from "classnames";

import ArrowSmallUpGrey from "../../../../images/icons/ArrowSmallUpGrey";
import styles from "./ButtonArrow.module.scss";

export type ButtonArrowDirectionType = 'right' | 'left'

const getRotate = (direction: ButtonArrowDirectionType) => {
  switch (direction) {
    case "right":
      return 90;
    case "left":
    default:
      return -90;
  }
};

export interface IButtonArrowProps{
  direction?: ButtonArrowDirectionType
  className?: string
  onClick?: MouseEventHandler;
}

const ButtonArrow: React.FC<IButtonArrowProps> = ({ direction = 'left', className, ...props }) => {
  return (
    <button className={cn(styles.buttonArrow, styles[direction], className)} {...props}>
      <ArrowSmallUpGrey width="1rem" height="0.5rem" rotate={getRotate(direction).toString()} />
    </button>
  );
};

export default React.memo(ButtonArrow);