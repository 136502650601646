import React, { useCallback } from "react";
import { useSelector } from "react-redux";

import Checkbox from "../../../../UI/Checkbox";
import PaymentTerms from "../../../../UI/molecules/PaymentTerms";
import DeliveryTerms from "../../../../UI/molecules/DeliveryTerms";
import Amounts from "../../../../UI/molecules/Amounts";

import styles from "./Footer.module.scss";

const OrderFooter = ({ order, changeOrder, canEdit }) => {
  const authEntityType = useSelector((state) => state.auth.entity.type);
  const isPurchaser = authEntityType === "pcr";

  const onChangeTaxIncluding = useCallback(
    (e) => changeOrder({ ...order, including_vat: e.target.checked }),
    [order, changeOrder]
  );

  const changePaymentTerms = useCallback(
    (changedPaymentTerms) => changeOrder({ ...order, payment_terms: changedPaymentTerms.map(({ id, ...term }) => term) }),
    [changeOrder, order]
  );

  const changeDeliveryTerms = useCallback(
    (changedTerms) => changeOrder({ ...order, delivery_terms: changedTerms.map(({ id, ...term }) => term) }),
    [order, changeOrder]
  );

  const disagreeDeliveryChange = useCallback(
    (val) => changeOrder({ ...order, disagree_delivery: val }),
    [order, changeOrder]
  );

  return (
    <footer className={styles.footer}>
      <div className={styles.termsContainer}>
        <PaymentTerms
          status={order.status}
          selectedTerms={order.payment_terms}
          isPurchaser={isPurchaser && !order.is_internal}
          changeSelectedTerms={changePaymentTerms}
          disabled={!canEdit}
        />
        <DeliveryTerms
          status={order.status}
          terms={order.delivery_terms}
          termChangeHandler={changeDeliveryTerms}
          disagree_delivery={order.disagree_delivery}
          disagreeDeliveryChange={disagreeDeliveryChange}
          isPurchaser={isPurchaser}
          isInternal={order.is_internal}
          disabled={!canEdit}
        />
      </div>
      <div className={styles.amountsContainer}>
        <div className={styles.checkbox}>
          {(!isPurchaser || order.is_internal) && (
            <Checkbox checked={order.including_vat} onChange={onChangeTaxIncluding} disabled={!canEdit}>
              Включая НДС
            </Checkbox>
          )}
        </div>
        <Amounts
          productsAmount={order.calculation?.amount}
          taxAmount={order.calculation?.amount_vat}
          deliveryAmount={order.calculation?.amount_delivery}
          totalAmount={order.calculation?.amount_total}
          taxIsInclude={order.including_vat}
        />
      </div>
    </footer>
  );
};

export default React.memo(OrderFooter);