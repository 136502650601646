import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from "classnames";

import { patchTicket } from 'redux/modules/common/building/tickets';

import { ITicketJournalDelivery } from 'types/interfaces/Tickets';

import { stopEventPropagation } from "utils/stopEventPropagation";

import ArrowWithLine from 'images/icons/ArrowWithLine';

import styles from "./JournalAprovingCountCell.module.scss";


interface IProps {
  haveApproveTicketPermission: boolean;
  ticket: ITicketJournalDelivery;
  objectId: string;
}

const JournalAprovingCountCell: React.FC<IProps> = ({ haveApproveTicketPermission, ticket, objectId }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState("");

  const sum = ticket.sum_count_aproved;

  const handleClick = () => {
    dispatch(patchTicket(objectId, ticket.id, { count_accepted_work: count }));
  };

  if (!!sum)
    return (
      <div title={sum} className={styles.container}>
        {sum}
        <span>
          <ArrowWithLine color={"#CCECFE"} />
        </span>
      </div>
    );

  if (!!ticket.expenditure_id && !ticket.exists_accepted && !sum)
    return haveApproveTicketPermission ? (
      <div onClick={stopEventPropagation} className={styles.container}>
        <input
          name={`count${objectId}-${ticket.id}`}
          className={cn(styles.inputCount, {
            [styles.inputActive]: count,
          })}
          onChange={(e) => setCount(e.target.value)}
          value={count}
        />
        <span className={cn(styles.arrow, { [styles.active]: count })} onClick={handleClick}>
          <ArrowWithLine />
        </span>
      </div>
    ) : (
      <span>0</span>
    );
  return null
}

export default React.memo(JournalAprovingCountCell);
