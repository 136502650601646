import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";

import ProductFilesModal from "../../../../pages/Requisition/components/ProductFilesModal";
import MatchingModal from "../../../atoms/MatchingModal/MatchingModal";
import DeleteIcon from "../../../../../images/icons/DeleteIcon";
import splitIcon from "../../../../../images/icons/call-split.svg";
import { getLocalizedDate } from "../../../../../utils/getLocalizedDate";
import { getMatchingType } from 'components/UI/atoms/MatchingModal/utils';
import ForkProducts from "../../../ForkProducts/ForkProducts";
import ConfirmationModal from "../../../molecules/ConfirmationModal";
import Checkbox from "../../../Checkbox";

import styles from "../../index.module.scss";
import { getIdentifier } from "../../../../../utils/getIdentifier";

const OrderRequest = (props) => {
  const {
    request,
    isChecked,
    checkRequest,
    number,
    buildingId,
    updateOrder,
    permissions,
    deleteRequest,
    forkRequest,
    isKit,
    removeFromKit,
  } = props;

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const openConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(true), []);
  const closeConfirmDeleteModal = useCallback(() => setIsConfirmDeleteModalOpen(false), []);

  const handleForkRequest = useCallback(
    (count, date) => forkRequest({ count, date }, request),
    [forkRequest, request]
  );

  const requestIdentifier = useMemo(() => getIdentifier(request), [request]);

  const handleDeleteRequest = useCallback(() => deleteRequest(requestIdentifier), [requestIdentifier, deleteRequest]);

  return (
    <div className={cn(styles.request, { [styles.kitRequest]: isKit })}>
      <div className={styles.columns}>
        <div className={styles.numberColumn}>{number}</div>
        {!isKit ? permissions.editRequest && (
          <div className={styles.checkboxColumn}>
            <Checkbox checked={isChecked} onChange={checkRequest} />
          </div>
        ) : (
          <div className={styles.checkboxColumn}/>
        )}
      </div>
      <div className={styles.columns}>
        <div className={styles.nameColumn}>
          <span>{request.name}</span>
          <div className={styles.nameColumnFooter}>
            {request.product_building.brand && (
              <span className={styles.brand}>
              Марка: {request.product_building.brand}
            </span>
            )}
            <ProductFilesModal
              files={request.product_building.files}
              buildingId={buildingId}
              itemId={request.product_building.id}
              permissions={permissions}
              variant="secondary"
            />
          </div>
        </div>
        <div className={cn(styles.dateColumn, styles.dateColumnWithMatching)}>
          <MatchingModal
            info={request.product_building}
            objectId={buildingId}
            updateProducts={updateOrder}
            required_count={request.required_count}
            type={getMatchingType(request.product_building?.type)}
          />
          {getLocalizedDate(request.delivery_date)}
        </div>
        <div className={styles.countColumn}>{request.count}</div>
        <div className={styles.measureColumn}>{request.measure}</div>
        <div className={styles.actionsColumn}>
          {permissions.editRequest && (
            <>
              <div className={styles.fork}>
                <ForkProducts fork={handleForkRequest} disabledDate={request.delivery_date} />
              </div>
              {isKit && (
                <div className={styles.split}>
                  <img src={splitIcon} alt="Иконка разбития комплекта" onClick={removeFromKit} />
                </div>
              )}
              {permissions.deleteRequest && !isKit && (
                <div className={styles.delete}>
                  <DeleteIcon onClick={openConfirmDeleteModal} />
                  <ConfirmationModal
                    isOpen={isConfirmDeleteModalOpen}
                    onClose={closeConfirmDeleteModal}
                    action={handleDeleteRequest}
                  >
                    Удалить позицию?
                  </ConfirmationModal>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(OrderRequest);