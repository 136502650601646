import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import {
  HALF_MONTH,
  MONTH,
  WEEK,
  YEAR,
} from "redux/modules/common/building/manufacturing/manufacturing";

import { chartViewModeSelector } from "redux/modules/common/building/manufacturing/selectors";

import { WORKS_TAB_ID } from "../../constants";

import Branch from "./components/Branch/Branch";

import { getDaysInYear, getWeeksInYear } from "../../utils";
import { useChartTrees } from "../../hooks/useChartTrees";

import styles from "./Month.module.scss";
import { useUnitMultiplier } from "../../hooks/useUnitMultiplier";
import RunningLine from "../RunningLine/RunningLine";
import { useRunningLine } from "../../hooks/useRunningLine";
import { useIntervalLinks } from "./useIntervalLinks";

const Month = ({
  tree,
  year,
  month,
  projects,
  monthMarkers,
  type = WORKS_TAB_ID,
  startWeek,
  endWeek,
  checkIsMonthBranchShownAsSection,
  checkIsMonthBranchShownAsExpenditure,
  isGeneratingSpittingTree,
  checkIsShownSectionPlan,
}) => {
  const unitMultiplier = useUnitMultiplier();
  const { objectId } = useParams();
  const chartViewMode = useSelector(chartViewModeSelector);

  const daysInYear = getDaysInYear(+year);
  const weeksInYear = getWeeksInYear(+year);

  const { calcTrees } = useChartTrees({
    tree,
    year: +year,
    month,
    projects,
    startWeek,
    endWeek,
    monthMarkers,
    type,
    isGeneratingSpittingTree,
  });

  const monthContainerStyle = {
    minHeight: `${3 * tree.length}rem`,
    width:
      chartViewMode === WEEK
        ? `${weeksInYear * 7 * unitMultiplier}rem`
        : chartViewMode === MONTH || chartViewMode === HALF_MONTH
        ? `${daysInYear * unitMultiplier}rem`
        : chartViewMode === YEAR
        ? `${12 * unitMultiplier}rem`
        : "100%",
  };

  const calendarNodeStyle =
    chartViewMode === WEEK
      ? {
          maxWidth: `${weeksInYear * 7 * unitMultiplier}rem`,
        }
      : chartViewMode === YEAR
      ? { maxWidth: `${12 * unitMultiplier}rem` }
      : {
          maxWidth: `0`,
        };

  const monthContainerRef = useRef(null);
  const { runningLineRef, releaseRunningLine, blockRunningLine } =
    useRunningLine({
      containerElement: monthContainerRef.current,
      calendarElement: monthContainerRef.current,
    });

  const runningLineWidthRem =
    (chartViewMode === WEEK
      ? weeksInYear * 7
      : chartViewMode === MONTH || chartViewMode === HALF_MONTH
      ? daysInYear
      : chartViewMode === YEAR
      ? 12
      : 0) * unitMultiplier;

  const { isLinkingEnabled } = useIntervalLinks({});

  return (
    <div
      className={styles.container}
      style={monthContainerStyle}
      ref={monthContainerRef}
    >
      <RunningLine widthREM={runningLineWidthRem} ref={runningLineRef} />
      {calcTrees.map((currentTree, idx) => {
        return (
          <div
            className={`${styles.tree} calendar_node`}
            key={idx}
            style={calendarNodeStyle}
          >
            {currentTree.map(
              (branch, index) =>
                branch.days.length > 0 && (
                  <Branch
                    offsetLeft={branch.offsetLeft}
                    branch={branch}
                    key={`${idx}_${index}`}
                    index={index}
                    objectId={objectId || branch.objectId}
                    isShownAsSection={checkIsMonthBranchShownAsSection(branch)}
                    isShownSectionPlan={
                      checkIsShownSectionPlan &&
                      checkIsShownSectionPlan(branch) &&
                      !isLinkingEnabled
                    }
                    isShownAsExpenditure={checkIsMonthBranchShownAsExpenditure(
                      branch
                    )}
                    blockRunningLine={blockRunningLine}
                    releaseRunningLine={releaseRunningLine}
                  />
                )
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Month);
