import React, { useRef, useState } from "react";
import cn from "classnames";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { compose } from "redux";

import {
  expendituresKsSelector,
  getExpenditureKs,
  resetExpenditureKsAction,
} from "../../../../../../../../redux/modules/common/building/sections/sections";
import downloadFileByUrl from "../../../../../../../../utils/downloadFileByUrl";

import useOnClickOutside from "../../../../../../../../hooks/useOnClickOutside";
import xlsIcon from "../../../../../../../../images/icons/xls.svg";
import ksIcon from "../../../../../../../../images/icons/ks.svg";

import ArrowDownSelect from "../../../../../../../../images/icons/ArrowDownSelect";

import { Spinner } from "../../../../../../Spinner/Spinner";
import styles from "./Ks.module.scss";

export const KS_TYPES = {
  KS2: "ks2",
  KS3: "ks3",
};

function Ks({ className, count, expenditureId, type, buildingId }) {
  //type: "ks2"|"ks3"
  const fileType = `${type}_file`;
  const [isOpen, setIsOpen] = useState(false);

  const ks = useSelector(expendituresKsSelector);
  const dispatch = useDispatch();

  const outSideRef = useRef();

  const clickHandler = () => {
    if (!count || !expenditureId) {
      message.error("КС еще не сформирована");
      return;
    }

    compose(dispatch, resetExpenditureKsAction)();
    compose(dispatch, getExpenditureKs)(buildingId, expenditureId);
    setIsOpen((prev) => !prev);
  };

  const getTextByType = () => {
    if (type === KS_TYPES.KS2) return "КС2";
    if (type === KS_TYPES.KS3) return "КС3";
  };

  useOnClickOutside(outSideRef, () => setIsOpen(false));

  return (
    <div className={cn(styles.wrapper, className)} ref={outSideRef}>
      <div className={cn(styles.container, { [styles.disabled]: !count })} onClick={clickHandler}>
        <span>{getTextByType()}</span>
        <div className={styles.icon}>
          <img src={ksIcon} alt="Иконка КС" />
          {count ? <div className={styles.counter}>{count}</div> : null}
        </div>
        {count ? <ArrowDownSelect className={styles.arrow} color="#707070" /> : null}
      </div>
      {isOpen && (
        <div className={styles.popup}>
          {ks ? (
            ks.map((ks) => (
              <div
                className={styles.file}
                onClick={() =>
                  downloadFileByUrl(ks[fileType], `${getTextByType()} ${moment(ks.created_at).format("DD-MM-YYYY")}`)
                }
                key={ks.id}
              >
                {moment(ks.created_at).format("DD-MM-YYYY")}
                <img src={xlsIcon} alt="Иконка xls" />
              </div>
            ))
          ) : (
            <Spinner isSmall />
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(Ks);
