import React, { useState, useCallback, useMemo } from 'react';
import cn from "classnames";

import { tasksListFilterType } from 'redux/modules/purchaser/Tasks/types';
import { useTypedSelector } from 'redux/typedUseSelector';
import { userSelector } from 'redux/modules/common/auth';

import { TASK_LISTS_TYPES } from 'components/pages/Tasks/constants';
import TableReusableRow, { TableReusableCell } from 'components/UI/atoms/TableReusable/TableReusableRow';
import Select from 'components/UI/atoms/Select';
import ExpandIcon from '../../ExpandIcon/ExpandIcon';

import { IIdAndName } from 'types/idAndName';
import { useFilterTasksBySection } from 'components/pages/Tasks/hooks/useFilterTaskBySection';
import { useTaskIndicatorsByBuilding } from 'components/pages/Tasks/hooks/useTaskIndicatorsByBuilding';
import { useLocationArray } from 'utils/hooks/useLocationArray';
import { isAllModulesAllowed } from 'utils/isAllModulesAllowed';

import styles from "./TaskSubheaderByObject.module.scss";


export interface ITaskSubheaderByObjectProps {
  building: IIdAndName;
  onExpand: (id: number) => void;
  isExpanded: boolean;
  onChangeSection: (id: number) => void;
  filters: tasksListFilterType
}

const TaskSubheaderByObject: React.FC<ITaskSubheaderByObjectProps> = ({
  building,
  onExpand,
  isExpanded,
  filters
}) => {
  const userInfo = useTypedSelector(userSelector);
  const type = useLocationArray()[1];

  const isShowSectionSelect = isAllModulesAllowed();

  const { loadSectionsHandler, fields, submitFilterBySectionHandler, sectionTaskFilter, isSectionsLoading } = useFilterTasksBySection(building?.id, filters, type as TASK_LISTS_TYPES)

  const [isSelectOpened, setIsSelectOpened] = useState(false);

  const expandHandler = useCallback(() => {
    building?.id && onExpand(building.id);
  }, [building?.id, onExpand]);

  const openSelectHandler = useCallback(() => {
    setIsSelectOpened(true);
    loadSectionsHandler();
  }, []);

  const closeSelectHandler = useCallback(() => {
    setIsSelectOpened(false);
  }, []);

  const filterBySectionHandler = useCallback((sectionId: number | string) => {
    submitFilterBySectionHandler(Number(sectionId));
  }, []);

  const filtersForBuildingIndicators = useMemo(() => {
    return {
      ...filters,
      section_id: sectionTaskFilter,
      executor: type === TASK_LISTS_TYPES.MY ? userInfo.id : undefined,
      viewer: type === TASK_LISTS_TYPES.WATCHING ? userInfo.id : undefined,
      creator: type === TASK_LISTS_TYPES.ASSIGNED ? userInfo.id : undefined,
    }
  }, [filters, sectionTaskFilter]);

  const indicators = useTaskIndicatorsByBuilding(building?.id, filtersForBuildingIndicators);

  return (
    <TableReusableRow
      containerClassName={cn(styles.container, { [styles.zIndexIncreased]: isSelectOpened })}
      onClick={expandHandler}
      isHoverable={false}
      className={styles.grid}
    >
      <TableReusableCell>
        <ExpandIcon isExpanded={isExpanded} />
      </TableReusableCell>
      <TableReusableCell className={styles.name}>{building?.name}</TableReusableCell>
      <TableReusableCell isCentered>
        <div className={cn(styles.mark, styles.expired)} />
        <span className={styles.hidden}>Просрочено&nbsp;</span>
        {indicators?.expired_count || 0} из {indicators?.tasks_count || 0}
      </TableReusableCell>
      <TableReusableCell isCentered>
        <div className={cn(styles.mark, styles.expiring)} />
        <span className={styles.hidden}>Срок истекает&nbsp;</span>
        {indicators?.expires_count || 0} из {indicators?.tasks_count || 0}
      </TableReusableCell>
      <TableReusableCell>{/* @ts-ignore */}
        {isShowSectionSelect && (
          <Select
            value={sectionTaskFilter}
            onOpen={openSelectHandler}
            onClose={closeSelectHandler}
            options={fields}
            onChange={filterBySectionHandler}
            className={styles.sections}
            placeholder="Раздел"
            isLoading={isSectionsLoading}
          />
        )}
      </TableReusableCell>
      <TableReusableCell isCentered>
        Задач: {indicators?.tasks_count || 0}
      </TableReusableCell>
    </TableReusableRow>
  )
}

export default React.memo(TaskSubheaderByObject);