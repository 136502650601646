import React, { useCallback, useMemo, useState } from "react";

import Checkbox from "components/UI/Checkbox";
import { AddButton } from "components/UI/AddButton/AddButton";

import ManagePermissionsModal from "./components/ManagePermissionsModal/ManagePermissionsModal";
import styles from "./PermissionsSettings.module.scss";


const PermissionsSettings = ({
  canEdit,
  allPermissions,
  allPermissionsCount,
  checkedUserPermissions,
  onChangeCheckedUserPermissions,
}) => {
  const [isManagePermissionsModalOpen, setIsManagePermissionsModalOpen] = useState(false);
  const openManagePermissionsModal = useCallback(() => setIsManagePermissionsModalOpen(true), []);
  const closeManagePermissionsModal = useCallback(() => setIsManagePermissionsModalOpen(false), []);

  const checkedUserPermissionsCount = useMemo(() => {
    return Object.entries(checkedUserPermissions).filter(([_, isChecked]) => isChecked).length;
  }, [checkedUserPermissions]);

  return (
    <>
      <AddButton className={styles.addButton} onClick={openManagePermissionsModal}>
        Настройка прав доступов ({checkedUserPermissionsCount} из {allPermissionsCount})
      </AddButton>
      <ManagePermissionsModal
        permissions={allPermissions}
        checkedPermissions={checkedUserPermissions}
        onChangeChecked={onChangeCheckedUserPermissions}
        isOpen={isManagePermissionsModalOpen}
        onClose={closeManagePermissionsModal}
        canEdit={canEdit}
      />
    </>
  );
};

export default React.memo(PermissionsSettings);
