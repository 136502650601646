export const prepareFiles = (files) => {
  if (!files) return []
  return files.map((file) => {
    if (typeof file.file === 'string') {
      return {
        id: file.id,
        file: {
          name: file.name || file.file_name,
          link: file.file
        }
      }
    }
    return file
  })
}
