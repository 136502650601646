import React from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import styles from "./index.module.scss";


const MenuItem = ({ isExists = true, link, title, icon, className = "", exact = false, isDirectlyActive = false }) => {
  if (!isExists) return null

  return (
    <NavLink
      to={link}
      activeClassName={styles.active}
      exact={exact}
      className={cn({[styles.active]: isDirectlyActive})}
    >
      <div className={cn(styles.container, className)}>
        <img src={icon} alt={title} className={styles.icon} />
        <div className={styles.titlePopup}>
          {title}
        </div>
      </div>
    </NavLink>
  );
};

export default MenuItem;
