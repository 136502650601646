import React, { useState, useEffect } from 'react';

import SliderModal from 'components/UI/atoms/SliderModal';
import { Spinner } from "./../../../../../../../../../../UI/Spinner/Spinner"
import EmptyPlaceholder from "./../../../../../../../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder"
import { apiGetTicketHistory } from 'redux/modules/common/building/shedulesApi';
import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";
import HistoryItem from './HistoryItem';

import styles from './HistoryModal.module.scss';

const HistoryModal = ({ objectId, expenditure, isOpen, handleCloseModal }) => {
  const [data, setData] = useState([]);
  const [isLoading , setIsLoading] = React.useState(false)

  const loadEx = async (objectId, expenditureId) => {
    setIsLoading(true)
    const ex = await apiGetTicketHistory(objectId, expenditureId);
    await setData(ex?.results);
    setIsLoading(false)
  };

  useEffect(() => {
    isOpen && loadEx(objectId, expenditure?.id);
  }, [isOpen]);

  return (
    <>
      <SliderModal
        isOpen={isOpen}
        closeHandler={handleCloseModal}
        className={styles.sliderClassName}
      >
        <div className={styles.container}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>История выполнения</div>
            <div className={styles.justification}>{expenditure?.justification}</div>
          </div>
          <div className={styles.subTitle}>{expenditure?.name}</div>
          <div className={styles.content}>
            {isLoading && <Spinner />}
            {(!isLoading && data?.length) ? data?.map((item) => (
              <HistoryItem key={item.id} data={item} />
            )) : null}
            {(!isLoading && !data?.length) ? <EmptyPlaceholder text="Нет сведений" img={jurnalIcon} size="small" /> : null}
          </div>
        </div>
      </SliderModal>
    </>
  );
};

export default HistoryModal;
