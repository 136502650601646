import React, { useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import {
  getStockUsingMaterialsPrice,
  updateStockUsingMaterialsPrice,
} from "redux/modules/common/building/journal/thunks";
import {
  stockUsingMaterialsPriceListSelector,
  stockUsingMaterialsPriceUpdateIsSuccessSelector,
} from "redux/modules/common/building/journal/selectors";
import { setIsStockUsingMaterialsPriceUpdateSuccess } from "redux/modules/common/building/journal/actions";

import ButtonBase from "components/UI/atoms/ButtonBase";
import Radio from "components/UI/atoms/Radio/Radio";
import InputBase from "components/UI/atoms/InputBase";

import { splitThousands } from "utils/splitThousands";

import styles from "./PopoverContent.module.scss";

export enum COUNTS_ENUM {
  FACT = "fact",
  PROJECT = "project",
}

export interface IProps {
  setIsOpenPopover: React.Dispatch<React.SetStateAction<boolean>>;
  buildingId: number;
  expenditureId: number;
  stockId: number;
  loadFilteredEx: () => void;
}

const PopoverContent = ({
  setIsOpenPopover,
  buildingId,
  expenditureId,
  stockId,
  loadFilteredEx,
}: IProps) => {
  const dispatch = useDispatch();

  const priceList = useSelector(stockUsingMaterialsPriceListSelector);
  const isSuccess = useSelector(
    stockUsingMaterialsPriceUpdateIsSuccessSelector
  );

  const [estimatePrice, setEstimatePrice] = useState<string | number>();
  const [packingListPrice, setPackingListPrice] = useState<string | number>();
  const [activeCountName, setActiveCountName] = useState(COUNTS_ENUM.FACT);

  const activeAmount = useMemo(
    () =>
      activeCountName === COUNTS_ENUM.FACT ? packingListPrice : estimatePrice,
    [activeCountName, estimatePrice, packingListPrice]
  );

  useEffect(() => {
    dispatch(getStockUsingMaterialsPrice(buildingId, expenditureId, stockId));
  }, []);

  useEffect(() => {
    setEstimatePrice(priceList?.estimate_price || 0);
    setPackingListPrice(priceList?.packing_list_price || 0);
  }, [priceList]);

  useEffect(() => {
    if (!isSuccess) return;
    setIsOpenPopover(false);
    loadFilteredEx();
    dispatch(setIsStockUsingMaterialsPriceUpdateSuccess(false));
  }, [isSuccess]);

  const handleSubmit = () => {
    dispatch(
      updateStockUsingMaterialsPrice(
        buildingId,
        expenditureId,
        stockId,
        Number(activeAmount)
      )
    );
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>Выберите стоимость - фактическую или проектную</h1>
      </header>
      <div className={styles.divider} />
      <section className={styles.section}>
        <div
          onClick={() => setActiveCountName(COUNTS_ENUM.FACT)}
          className={cn(styles.item, styles.flexRow, {
            [styles.active]: activeCountName === COUNTS_ENUM.FACT,
          })}
        >
          <Radio isActive={activeCountName === COUNTS_ENUM.FACT} />
          <span className={styles.radioName}>Фактическая стоимость, ₽</span>
          <InputBase
            classNameInput={styles.input}
            variant="brandColorWhite"
            disabled
            input={undefined}
            className={undefined}
            label={undefined}
            onChange={undefined}
            value={splitThousands(
              packingListPrice,
              false,
              false
            )}
          />
        </div>
        <div
          onClick={() => setActiveCountName(COUNTS_ENUM.PROJECT)}
          className={cn(styles.item, styles.flexRow, {
            [styles.active]: activeCountName === COUNTS_ENUM.PROJECT,
          })}
        >
          <Radio isActive={activeCountName === COUNTS_ENUM.PROJECT} />
          <div className={styles.projectContent}>
            <div className={styles.flexRow}>
              <span className={styles.radioName}>Проектная стоимость, ₽</span>
              <InputBase
                classNameInput={styles.input}
                variant="brandColorWhite"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEstimatePrice(event.target.value)}
                input={undefined}
                className={undefined}
                label={undefined}
                value={estimatePrice}
              />
            </div>
          </div>
        </div>
      </section>
      <footer className={styles.buttons}>
        <ButtonBase secondary onClick={() => setIsOpenPopover(false)}>
          Отменить
        </ButtonBase>
        <ButtonBase type="submit" primary onClick={handleSubmit}>
          Сохранить
        </ButtonBase>
      </footer>
    </div>
  );
};

export default React.memo(PopoverContent);
