import ButtonBase from "components/UI/atoms/ButtonBase";
import BottomControls from "components/UI/organism/WorkOrMaterialsModals/components/BottomControls/BottomControls";
import React from "react";
import NavBar from "components/UI/organism/WorkOrMaterialsModals/components/NavBar/NavBar";
import styles from "./WorkSharingModalContent.module.scss";
import WorkSharingInfoItem from "./WorkSharingInfoItem/WorkSharingInfoItem";
import ReactTextareaAutosize from "react-textarea-autosize";
import Checkbox from "components/UI/atoms/Checkbox/Checkbox";
import ExpandableFileInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput";
import sendEmailImage from "images/sendEmail.png";
import { Spinner } from "components/UI/Spinner/Spinner";
import CheckIcon from "components/UI/organism/WorkOrMaterialsModals/components/CheckIcon/CheckIcon";
import IContractor from "types/interfaces/Contractor";
import getShortFullName from "utils/getShortFullName";
import IExpenditure from "types/interfaces/Expenditure";
import { useShareCompletedWork } from "./useShareCompletedWork";
import { IFactInterval } from "types/interfaces/FactInterval";
import { IFileWithOriginalName } from "types/interfaces/Files";

interface IProps {
  onClose: () => void;
  interval: Pick<
    IFactInterval,
    "id" | "accepted" | "measure" | "from_organisation"
  >;
  onIntervalReload: (id: number) => void;
  objectId: number | string;
  files: IFileWithOriginalName[];
  onAddFile: (file: File) => void;
  onDeleteFile: (fileId: number) => void;
}

const WorkSharingModalContent: React.FC<IProps> = ({
  onClose,
  interval,
  onIntervalReload,
  objectId,
  files,
  onAddFile,
  onDeleteFile,
}) => {
  const { isLoading, isSended, comment, commentHanlder, submitHandler } =
    useShareCompletedWork({
      intervalId: interval.id,
      onIntervalReload,
      objectId,
    });

  if (!interval) return null;

  if (isSended) {
    return (
      <>
        <img src={sendEmailImage} className={styles.img} />
        <div className={styles.result}>
          <div className={styles.resultImg}>
            {isLoading ? <Spinner className={styles.spinner} /> : <CheckIcon />}
          </div>
          <div className={styles.resultText}>
            {isLoading ? "Передача на проверку…" : "Работа успешно передана"}
          </div>
        </div>
        <BottomControls isExists isDoubleBtns>
          <ButtonBase tertiary onClick={onClose}>
            Закрыть
          </ButtonBase>
        </BottomControls>
      </>
    );
  }

  return (
    <>
      <NavBar text="Сведения о работе" onClick={onClose} isExist />
      <h2 className={styles.title}>Кому отчитаться:</h2>
      <div className={styles.pair}>
        <WorkSharingInfoItem
          name="Наименование заказчика"
          value={interval?.from_organisation?.name || "Не указано"}
        />
        <WorkSharingInfoItem
          name="ФИО контактного лица"
          value={interval?.from_organisation?.contact_full_name || "Не указано"}
        />
      </div>
      <div className={styles.pair}>
        <WorkSharingInfoItem
          name="ИНН"
          value={interval?.from_organisation?.itn || "Не указано"}
        />
        <WorkSharingInfoItem
          name="Email"
          value={interval?.from_organisation?.email || "Не указано"}
        />
      </div>
      <h2 className={styles.title}>Что передать:</h2>
      <WorkSharingInfoItem
        name={`Кол-во (${interval.measure}):`}
        value={interval.accepted[0]?.count}
        className={styles.pairLine}
      />
      <h2 className={styles.title}>Обоснование передачи:</h2>
      <div className={styles.label}>Комментарий к передаваемой работе</div>
      <ReactTextareaAutosize
        onChange={commentHanlder}
        placeholder={"Введите текст сообщения"}
        minRows={3}
        maxRows={10}
        value={comment}
      />
      {/* <div className={styles.checkbox}>
                <Checkbox checked onCheck={() => null}>уведомить по email</Checkbox>
            </div> */}
      <ExpandableFileInput
        title="Дополнительные вложения (документы):"
        files={files}
        canExtractFiles={false}
        onRemoveFileDirectly={onDeleteFile}
        onUploadFileDirectly={onAddFile}
      />
      <BottomControls isExists isDoubleBtns>
        <ButtonBase tertiary onClick={onClose}>
          Отменить
        </ButtonBase>
        <ButtonBase primary onClick={submitHandler}>
          Передать
        </ButtonBase>
      </BottomControls>
    </>
  );
};

export default React.memo(WorkSharingModalContent);
