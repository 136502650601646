import moment from "moment";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { compose } from "redux";

import { userSelector } from "../../../../redux/modules/common/auth";
import { closeTaskModal } from "../../../../redux/modules/purchaser/Tasks/actions";
import { createTask } from "../../../../redux/modules/purchaser/Tasks/thunks";
import { IcreateTask, TaskPlacementType } from "../../../../redux/modules/purchaser/Tasks/types";
import { useTypedSelector } from "../../../../redux/typedUseSelector";

import { IIdAndName } from "../../../../types/idAndName";
import { TASK_LISTS_TYPES } from "../constants";

export interface IuseCreateTaskProps {
  placementType: TaskPlacementType;
  listType?: TASK_LISTS_TYPES;
  list_id?: number;
}

export interface IcreateFormValues extends Omit<IcreateTask, "executor" | "viewers"> {
  executor: string;
  viewers: string;
}

export const useCreateTask = ({ placementType, listType, list_id }: IuseCreateTaskProps) => {
  const dispatch = useDispatch();
  const [building, setBuilding] = useState<IIdAndName>({
    id: 0,
    name: "",
  });
  const [section_id, setSectionId] = useState<number | undefined>();
  const [executor, setExecutor] = useState<string>("");
  const [viewers, setViewers] = useState<string>("");
  const creator = useTypedSelector(userSelector);

  const clearLocalState = useCallback(() => {
    setBuilding({
      id: 0,
      name: "",
    });
    setSectionId(undefined);
    setExecutor("");
    setViewers("");
  }, []);

  const onBuildingChange = useCallback((building: IIdAndName) => {
    setBuilding(building);
    setExecutor("");
    setViewers("");
  }, []);

  const onSubmit = (values: IcreateFormValues) => {
    const { name, description, priority, deadline, executor, viewers, remember_task, repeat_task } = values;
    let momentDeadline = moment(deadline);
    momentDeadline.set({ h: 2, m: 59, s: 59 });

    const data: IcreateTask = {
      name,
      description,
      priority,
      deadline: momentDeadline,
      building: building?.id,
      section: section_id,
      executor: Number(executor.split(",").filter((x) => x.length)[0]),
      viewers:
        viewers
          ?.split(",")
          .filter((x) => x.length)
          .map((x) => Number(x)) || [],
      creator: creator.id,
      lists: list_id,
    };
    if (remember_task) data["remember_task"] = [remember_task];
    if (repeat_task) data["repeat_task"] = repeat_task;
    compose(
      dispatch,
      createTask
    )({
      data,
      variant: placementType,
      building_id: data.building,
      type: listType,
      list_id: data.lists,
    });
    clearLocalState();
  };

  return {
    onSubmit,
    building,
    setBuilding: onBuildingChange,
    section_id,
    setSectionId,
    executor,
    setExecutor,
    viewers,
    setViewers,
  };
};
