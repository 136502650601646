import TableReusableRow, { TableReusableCell } from 'components/UI/atoms/TableReusable/TableReusableRow'
import moment from 'moment';
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { IRequisitionInList } from 'types/interfaces/Requisition';
import { Iemployee } from 'types/personsTypes';
import getShortFullName from 'utils/getShortFullName';
import { isAgreed } from '../../utils';
import RequisitionsRowProducts from '../RequisitionsRowProducts/RequisitionsRowProducts';
import styles from "./RequisitionsRow.module.scss"
import { useRequisitionRowProducts } from './useRequisitionRowProducts';

interface Iprops {
    requisition: IRequisitionInList;
    objectId?: string;
}

const RequisitionsRow: React.FC<Iprops> = ({ requisition, objectId }) => {
    const isCompleted = requisition.status === "completed"
    const { loadProductsHandler, products } = useRequisitionRowProducts(requisition.id)
    const history = useHistory();
    const location = useLocation();
  
    const rowClickHandler = () => {
      history.push(`${location.pathname}/${requisition.id}`);
    };

    return (
        <TableReusableRow
            isHoverable={false}
            className={styles.row}
            isExpandable={!!requisition.count_items}
            innerContent={<RequisitionsRowProducts products={products} loadProductsHandler={loadProductsHandler} buildingId={requisition.building.id} />}
            isEarGreen={(requisition.count_processed_items > 0 && requisition.count_items === requisition.approved_count) || isCompleted}
            isEarBlue={requisition.count_processed_items > 0 && !isCompleted}
            onExpand={loadProductsHandler}
            onClick={rowClickHandler}
        >
            <TableReusableCell>
                {requisition.number}
            </TableReusableCell>
            <TableReusableCell isNoBreak>
                {requisition.name || "Ожидается"}
            </TableReusableCell>
            {!objectId ? <TableReusableCell isNoBreak>
                {requisition.building?.name}
            </TableReusableCell> : <div />}
            <TableReusableCell isCentered>
                {requisition.count_processed_items} / {requisition.count_items}
            </TableReusableCell>
            <TableReusableCell isCentered>
                {requisition.created_at ? moment(requisition.created_at).format("DD.MM.YY") : ""}
            </TableReusableCell>
            <TableReusableCell>
                {getShortFullName(requisition.executor)}
            </TableReusableCell>
            <TableReusableCell>
                {isAgreed(requisition.is_agreed)}
            </TableReusableCell>
            <TableReusableCell>
                {requisition.status_name}
            </TableReusableCell>
        </TableReusableRow>
    )
}

export default RequisitionsRow