import React, { useRef } from "react";
import styles from "./ModalDelete.module.scss";
import cn from "classnames";
import Icon from "components/UI/Icon";
import CrossIcon from "images/icons/CrossIcon";
import logoIcon from "images/icons/logoIcon.svg";
import useEscapeHandler from "../../../../../utils/hooks/useEscapeHandler";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";

const ModalDelete = ({ title, className, setIsDeleteModalOpen, deleteSection, id }) => {
  const ref = useRef();

  useEscapeHandler(() => setIsDeleteModalOpen(false));
  useOnClickOutside(ref, () => setIsDeleteModalOpen(false));

  return (
    <div className={styles.shadow}>
      <div className={cn(styles.container)} ref={ref}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Icon icon={logoIcon} className={styles.logo} />
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(false);
              }}
            >
              <div className={styles.crossIcon}>
                <CrossIcon />
              </div>
            </div>
          </div>
          <span>
            <div>Вы уверенны что хотите удалить {title}</div>
          </span>
          <div className={styles.actionBlock}>
            <button
              className={styles.btn}
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteModalOpen(false);
              }}
            >
              отмена
            </button>
            <button
              className={styles.btn}
              onClick={(e) => {
                e.stopPropagation();
                deleteSection(id);
              }}
            >
              удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDelete;
