import React, { useState, useEffect } from "react";
import moment from "moment";
import cn from "classnames";
import TextareaAutosize from "react-textarea-autosize";

import Button from "components/UI/atoms/ButtonBase";
import CalendarRange from "components/UI/molecules/CalendarRange";
import InputNumber from "components/UI/atoms/InputNumber";
import Select from "components/UI/atoms/Select";
import ExpandableFileInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput";
import AddWorkersInModal from "components/UI/organism/AddingListInModal/AddWorkersInModal";
import { useSelectingWorkers } from "components/UI/organism/AddingListInModal/hooks/useSelectingWorkers";
import { useSelectingMaterials } from "components/UI/organism/AddingListInModal/hooks/useSelectingMaterials";
import AddMaterialsInModal from "components/UI/organism/AddingListInModal/AddMaterialsInModal";

import { useProductIntervalForm } from "./useProductIntervalForm";
import { IMaterialInAddingInFact } from "types/interfaces/Materials";

import styles from "./ProductIntervalCard.module.scss";

type propsType = {
  handleClose: () => void;
  objectId: number;
  directlySection?: number;
  directlyWork?: number;
  isOpen?: boolean;
};

function ProductIntervalCardContent({
  handleClose,
  objectId,
  directlySection,
  directlyWork,
  isOpen,
}: propsType) {
  const [files, setFiles] = useState([]);

  const handleCloseModal = () => {
    handleClose();
    clearWorkers();
    clearMaterials();
    clearForm();
  };

  useEffect(() => {
    if (!isOpen) {
      clearWorkers();
      clearMaterials();
      clearForm();
      setFiles([]);
    }
  }, [isOpen]);

  const {
    confirmHandler,
    cancelHandler,
    changeCountHandler,
    validateSubmitting,
    deleteAddedHandler,
    selectHandler,
    addedUsers,
    selectedUsers,
    selectedUsersIds,
    addedUsersIds,
    clearWorkers,
  } = useSelectingWorkers();

  const {
    confirmMaterialsHandler,
    cancelMaterialsHandler,
    changeCountMaterialsHandler,
    validateSubmittingMaterials,
    deleteAddedHandler: deleteAddedMaterial,
    selectMaterialsHandler,
    addedMaterials,
    selectedMaterials,
    selectedMaterialsIds,
    addedMaterialsIds,
    clearMaterials,
  } = useSelectingMaterials();

  const {
    handleChangeWorks,
    handleChangeSection,
    getWorksList,
    getObjectList,
    clearForm,
    handleSubmit,
    dateEnd,
    dateStart,
    setDateEnd,
    setDateStart,
    count,
    setCount,
    user,
    activeSection,
    activeWork,
    isChooseWork,
    setIsChooseWork,
    title,
    setTitle,
  } = useProductIntervalForm({
    addedMaterials,
    addedUsers,
    objectId,
    clearMaterials,
    clearWorkers,
    handleClose,
    files,
    directlySection,
    directlyWork,
  });

  return (
    <>
      <div className={styles.titleBlock}>
        <div className={styles.title}>Новая запись</div>
        <div className={styles.dateTitle}>{moment().format("DD.MM.YYYY")}</div>
        <div className={styles.userTitle}>
          от {user?.last_name} {user?.first_name?.[0]}.
        </div>
      </div>
      <div className={styles.content}>
        {!directlySection && (
          <>
            <div className={styles.fieldTitle}>Выберите раздел</div>
            <div className={styles.fieldValue}>
              {isOpen && (
                <Select
                  options={getObjectList()}
                  onChange={(id: number | string) => handleChangeSection(id)}
                  className={styles.classNameSelect}
                  value={0}
                />
              )}
            </div>
          </>
        )}
        {!directlyWork && (
          <>
            <div className={styles.fieldTitle}>
              Выберите работу
              <button
                className={styles.subTitleButton}
                onClick={() => setIsChooseWork(!isChooseWork)}
                disabled={!activeSection}
              >
                {isChooseWork ? "+ введите название" : "+ выбрать из сметы"}
              </button>
            </div>
            <div className={cn(styles.fieldValue, styles.fixedHeightBox)}>
              {isChooseWork && isOpen ? (
                <Select
                  options={getWorksList()}
                  onChange={(id: number | string) => handleChangeWorks(id)}
                  disabled={!activeSection}
                  className={styles.classNameSelect}
                  selectClassName={styles.select}
                />
              ) : (
                <TextareaAutosize
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Введите название"
                  className={styles.textarea}
                />
              )}
            </div>
          </>
        )}
        <div className={styles.fieldRow}>
          <div className={styles.fieldBlock}>
            <div className={styles.fieldTitle}>Введите объем (кол-во)</div>
            <div className={styles.fieldValue}>
              {" "}
              {/* @ts-ignore */}
              <InputNumber value={count} setValue={setCount} />
            </div>
          </div>
          <div className={styles.fieldBlock}>
            <div className={styles.fieldTitle}>Укажите период</div>
            <div className={styles.fieldRow}>
              <div className={styles.fieldCalendar}>
                {/* @ts-ignore */}
                <CalendarRange
                  defaultDateStart={dateStart}
                  setDefaultDateStart={setDateStart}
                  defaultDateEnd={dateEnd}
                  setDefaultDateEnd={setDateEnd}
                  isTooRight
                />
              </div>
            </div>
          </div>
        </div>
        <AddWorkersInModal
          addedUsers={addedUsers}
          selectedUsers={selectedUsers}
          onAccept={confirmHandler}
          onDecline={cancelHandler}
          onChangeCount={changeCountHandler}
          onSelect={selectHandler}
          selectedIds={selectedUsersIds}
          onDelete={deleteAddedHandler}
          addedIds={addedUsersIds}
        />
        <AddMaterialsInModal
          addedMaterials={addedMaterials}
          selectedUsers={selectedMaterials as IMaterialInAddingInFact[]}
          onAccept={confirmMaterialsHandler}
          onDecline={cancelMaterialsHandler}
          onChangeCount={changeCountMaterialsHandler}
          onSelect={selectMaterialsHandler}
          selectedIds={selectedMaterialsIds}
          onDelete={deleteAddedMaterial}
          addedIds={addedMaterialsIds}
          isDisabled={!activeSection || (!activeWork && !title)}
          activeSection={activeSection}
          activeWork={activeWork}
        />{" "}
        {activeWork && (
          <ExpandableFileInput
            files={files || []}
            setFiles={setFiles}
            canExtractFiles={false}
          />
        )}
      </div>
      <div className={styles.actionBlock}>
        {/* @ts-ignore */}
        <Button
          type="button"
          medium
          secondary
          disabled={false}
          onClick={handleCloseModal}
        >
          Отменить
        </Button>
        &nbsp;&nbsp;&nbsp; {/* @ts-ignore */}
        <Button
          medium
          primary
          onClick={() =>
            validateSubmittingMaterials(() => validateSubmitting(handleSubmit))
          }
          disabled={!(title || activeWork || directlyWork)}
        >
          Сохранить
        </Button>
      </div>
    </>
  );
}

export default React.memo(ProductIntervalCardContent);
