import React from "react";

import { getSectionPlan } from "redux/modules/common/building/processApi";
import { ISectionPlan } from "redux/modules/common/building/processTypes";

export const useGetSectionPlan = (
  objectId: string,
  sectionId: number,
  isOpen: boolean
) => {
  const [planData, setPlanData] = React.useState<ISectionPlan | null>(null);

  const getSectionPlanFetcher = React.useCallback(() => {
    getSectionPlan(objectId, sectionId).then((resp) => {
      setPlanData(resp.data);
    });
  }, [objectId, sectionId]);

  React.useEffect(() => {
    if (!isOpen) return;
    getSectionPlanFetcher();
  }, [objectId, sectionId, isOpen, getSectionPlanFetcher]);

  return {
    planData,
    getSectionPlanFetcher,
  };
};
