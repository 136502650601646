import Validator from "../../../../domain/Validator";

class PackingListValidator extends Validator {
  validateProducts = (products) => products.forEach((product) => {
    if (+product.count_get <= 0) this.errors.add("Факт. Кол-во не может быть отрицательным или нулем");
    if (+product.amount <= 0) this.errors.add("Сумма с НДС не может быть отрицательной или нулем");
    if (+product.count_get > +product.count) this.errors.add("Количество больше заявленого")
  });

  validateNumber = (number) => {
    if (number === "") return this.errors.add("Введите номер");
  };

  validate = (number, products) => {
    this.validateNumber(number);
    this.validateProducts(products);

    if (this.errors.size === 0) return true;

    this.onError(this.errors);
    return false;
  };
}

export default PackingListValidator;