import React from "react";
import moment from "moment";
import cn from "classnames";

import styles from "./index.module.scss";

const DatePicker = ({ date, setDate, onDirectlyChangeDate }) => {
  const monthDays = moment(date, "YYYY-MM").daysInMonth();

  const getWeekdays = (day) => moment(date).set("date", day).isoWeekday();

  const handleActiveDay = (day) => {
    setDate(moment(date).date(day));
    setTimeout(() => {
      onDirectlyChangeDate && onDirectlyChangeDate(moment(date).date(day));
    }, 10);
  };

  return (
    <div className={styles.container}>
      <div className={styles.dayNameBlock}>
        <div className={styles.dayName}>Пн</div>
        <div className={styles.dayName}>Вт</div>
        <div className={styles.dayName}>Ср</div>
        <div className={styles.dayName}>Чт</div>
        <div className={styles.dayName}>Пт</div>
        <div className={styles.dayName}>Сб</div>
        <div className={styles.dayName}>Вс</div>
        {Array.from({ length: moment(date).date(0).day() }, (v, k) => k + 1).map((item, index) => (
          <div key={index} className={styles.dayNumber}></div>
        ))}
        {Array.from({ length: monthDays }, (v, k) => k + 1).map((item, index) => (
          <div
            key={index}
            className={cn(styles.dayNumber, {
              [styles.holidays]: [6, 7].includes(getWeekdays(item)),
            })}
            onClick={() => handleActiveDay(item)}
          >
            <div
              className={cn({
                [styles.activeDate]: moment(date).date() === item,
              })}
            >
              {item}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatePicker;