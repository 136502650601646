import React, { useEffect } from "react";
import { Field } from "react-final-form";
import cn from "classnames";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";

import Calendar from "components/UI/molecules/Calendar";
import InputBase from "components/UI/atoms/InputBase";
import InputSelect from "components/UI/atoms/InputSelect";
import TasksPersonsSelect from "../../../TaskPersonsSelect/TasksPersonsSelect";
import PriorityRange from "../../../PriorityRange/PriorityRange";

import { useTaskObjectsSectionsUsers } from "../../../../hooks/useTaskObjectsSectionsUsers";
import { composeFieldValidators, maxLength, minLength, required } from "utils/validations";
import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import styles from "./ModalAddTask.module.scss";


const ModalAddTaskMainFields: React.FC<any> = ({
  presetBuildingId,
  building,
  setBuilding,
  section_id,
  setSectionId,
  executor,
  setExecutor,
  viewers,
  setViewers,
  isVisible = true,
}) => {
  const { objects, section, users } = useTaskObjectsSectionsUsers({building_id: presetBuildingId || building.id});

  const isShowSectionField = isAllModulesAllowed();

  useEffect(() => {
    if (!objects || !presetBuildingId) return
    presetBuildingId && building.id !== presetBuildingId && setBuilding(objects.find((x) => x.id === presetBuildingId) || ({id: presetBuildingId, name: ''}));
  }, [presetBuildingId, objects]);

  return (
    <div
      className={cn(styles.fields, {
        [styles.isVisible]: isVisible,
      })}
    >
      <Field
        name={"name"}
        label={"*Название"}
        placeholder={"Название новой задачи"}
        component={InputBase}
        validate={composeFieldValidators(
          required(),
          maxLength(255, "Не более 255 символов"),
          minLength(1, "Введите название задачи")
        )}
        classNameInput={styles.fieldInput}
        className={styles.outView}
      />
      <Field
        name={"description"}
        validate={maxLength(255, "Не более 255 символов")}
        render={({ input, meta }) => (
          <div className={styles.outView}>
            <label className={styles.label}>Описание задачи</label>
            <TextareaAutosize {...input} placeholder={"Описание задачи"} minRows={3} />
            {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
          </div>
        )}
      />
      <div className={styles.pair}>
        <Field
          name={"deadline"}
          validate={required()}
          render={({ input, meta }) => (
            <div className={styles.outView}>
              <label className={styles.label}>*Контрольная дата</label>
              <Calendar
                value={input.value || meta.initial}
                setValue={input.onChange}
                classNameSelect={""}
                classNameOptions={styles.calendarOptions}
                label={input.label}
                placeholder={moment().format("DD/MM/YY")}
                format={"DD/MM/YY"}
              />
              {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
            </div>
          )}
        />
        <Field
          name={"priority"}
          render={({ input, meta }) => (
            <>
              <PriorityRange
                onChange={input.onChange}
                value={input.value}
                defaultValue={meta.initial}
                className={styles.range}
                label={'*Приоритет:'}
              />
              {meta?.touched && meta?.error && <span className={styles.errorMessage}>{meta.error}</span>}
            </>
          )}
        />
      </div>
      {isShowSectionField && (
        <Field
          name={"section"}
          value={section_id}
          render={({ input, meta }) => (
            <div className={styles.outView}>
              <InputSelect
                className={styles.fieldInput}
                optionsBlockClassName={styles.options}
                name={"section"}
                label={"Раздел"}
                placeholder={"Введите или укажите наименование раздела"}
                meta={meta}
                defaultValue={section[0]}
                selectedAction={undefined}
                input={{
                  ...input,
                  onChange: (value: number) => {
                    input.onChange(value);
                    setSectionId(value);
                  },
                  value: section_id,
                }}
                onClick={setSectionId}
                options={section}
              />
            </div>
          )}
        />
      )}
      <Field
        name={"executor"}
        validate={required()}
        multiple
        render={({ input, meta }) => (
          <TasksPersonsSelect
            name={"executor"}
            input={{
              ...input,
              onChange: (value: string) => {
                input.onChange(value);
                setExecutor(value);
              },
              value: executor,
            }}
            options={users}
            label={"*Исполнители"}
            placeholder={"Введите или укажите ФИО исполнителя"}
            meta={meta}
          />
        )}
      />
      <Field
        name={"viewers"}
        render={({ input, meta }) => (
          <TasksPersonsSelect
            name={"viewers"}
            input={{
              ...input,
              onChange: (value: string) => {
                input.onChange(value);
                setViewers(value);
              },
              value: viewers,
            }}
            options={users}
            label={"Контролеры"}
            placeholder={"Введите или укажите ФИО контролера"}
            meta={meta}
          />
        )}
      />
    </div>
  );
};

export default React.memo(ModalAddTaskMainFields);
