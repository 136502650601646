import axios from "axios";
import { createSelector } from "reselect";
import { errorCatcher } from "../../../../utils/errorCatcher";


const moduleName = "ticketsEstimate";
const LOAD_TICKETS = `${moduleName}/LOAD_TICKETS`;
const SET_TICKETS = `${moduleName}/SET_TICKETS`;
const SET_TICKET_STATUS = `${moduleName}/SET_TICKET_STATUS`;

const initialState = {
  tickets: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_TICKETS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_TICKETS:
      return {
        ...state,
        tickets: payload,
        isLoading: false,
      };
    case SET_TICKET_STATUS:
      return {
        ...state,
        tickets: {
          results: state?.tickets?.results?.map((item) => {
            if (item.id === payload?.id) return { ...item, status: payload.status };
            return item;
          }),
        },
        isLoading: false,
      };
    default:
      return state;
  }
};

export const stateSelector = (state) => state[moduleName];
export const ticketsEstimateSelector = createSelector(stateSelector, (state) => state.tickets);
export const ticketsLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);

export const setTicketStatus = (data) => {
  return {
    type: SET_TICKET_STATUS,
    payload: data,
  };
};

export const loadEstimateTickets = (objectId, params = {}) => {
  const config = {
    params: { ...params, limit: 100 },
  };

  return (dispatch) => {
    dispatch({
      type: LOAD_TICKETS,
    });
    axios
      .get(`/building/${objectId}/estimate/tickets/`, config)
      .then((response) => {
        dispatch({
          type: SET_TICKETS,
          payload: response.data,
        });
      })
      .catch(errorCatcher);
  };
};

export const patchConfirmCount = (objectId, id, params) => {
  const config = {
    ...params,
  };

  return (dispatch) => {
    axios.patch(`/building/${objectId}/materials/confirmcount/${id}/`, config).catch(errorCatcher);
  };
};