import React, { useCallback, useMemo } from "react";
import { compose } from "redux";
import { useDispatch } from "react-redux";

import {
  cancelExpenditure,
  confirmExpenditure,
  deleteExpenditure,
} from "../../../../../../../../../redux/modules/common/building/sections/sections";
import { loadContracts } from "../../../../../../../../../redux/modules/common/building/aggregations";

import cancelIcon from "../../../../../../../../../images/icons/statusCanceled.svg";
import confirmIcon from "../../../../../../../../../images/icons/statusConfirmed.svg";
import editIcon from "../../../../../../../../../images/icons/editIcon.svg";
import removeIcon from "../../../../../../../../../images/icons/closeNotyItem.svg";

import UIExpenditure from "../../../../../../../../UI/molecules/Expenditure/Expenditure";

import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../../../constants";

import DraggableExpenditure from "./components/DraggableExpenditure/DraggableExpenditure";

const Expenditure = ({
  expenditure,
  onEdit,
  activeEstimateStateId,
  isChecked,
  isParentSectionChecked,
  withActions,
  buildingId,
  isShared,
  ...props
}) => {
  const dispatch = useDispatch();

  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;
  const isProduction = activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

  const handleConfirmExpenditure = useCallback(async () => {
    await compose(
      dispatch,
      confirmExpenditure
    )({
      buildingId,
      expenditureId: expenditure.id,
      fromState: activeEstimateStateId,
    });

    compose(dispatch, loadContracts)(buildingId);
  }, [activeEstimateStateId, buildingId, expenditure.id]);

  const handleCancelExpenditure = useCallback(() => {
    compose(
      dispatch,
      cancelExpenditure
    )({
      buildingId,
      expenditureId: expenditure.id,
      fromState: activeEstimateStateId,
    });
  }, [activeEstimateStateId, buildingId, expenditure.id]);

  const expenditureConfirmActions = useMemo(
    () => [
      { name: "Утвердить", action: handleConfirmExpenditure, icon: confirmIcon },
      { name: "Отклонить", action: handleCancelExpenditure, icon: cancelIcon },
    ],
    [handleConfirmExpenditure, handleCancelExpenditure]
  );

  const partialOnEdit = useCallback(() => onEdit(expenditure.id), [expenditure.id, onEdit]);

  const onDeleteExpenditure = useCallback(() => {
    compose(
      dispatch,
      deleteExpenditure
    )({
      building: buildingId,
      section: expenditure.section_id,
      expenditure: expenditure.id,
      estimateState: isProduction ? expenditure.current_state : activeEstimateStateId,
    });
  }, [
    buildingId,
    expenditure.section_id,
    expenditure.id,
    expenditure.current_state,
    isProduction,
    activeEstimateStateId,
  ]);

  const expenditureActions = useMemo(() => {
    if (!withActions) return [];
    const confirmActionsAreAvailable = expenditure.status === ESTIMATE_ITEM_STATUSES.NEW && !isDraft && !isProduction;

    return [
      { name: "Редактировать", action: partialOnEdit, icon: editIcon },
      {
        name: "Удалить",
        action: onDeleteExpenditure,
        icon: removeIcon,
      },
      ...(confirmActionsAreAvailable ? expenditureConfirmActions : []),
    ];
  }, [
    expenditure.status,
    expenditureConfirmActions,
    isDraft,
    isProduction,
    onDeleteExpenditure,
    partialOnEdit,
    withActions,
  ]);

  const newExpenditureNotInDraft = expenditure.status === ESTIMATE_ITEM_STATUSES.NEW && !isDraft;

  if (isProduction || newExpenditureNotInDraft || isShared)
    return (
      <UIExpenditure
        buildingId={buildingId}
        expenditure={expenditure}
        actions={expenditureActions}
        isChecked={isChecked || isParentSectionChecked}
        isShared={isShared}
        {...props}
      />
    );

  return (
    <DraggableExpenditure
      buildingId={buildingId}
      expenditure={expenditure}
      actions={expenditureActions}
      isChecked={isChecked || isParentSectionChecked}
      isParentSectionChecked={isParentSectionChecked}
      isShared={isShared}
      canCheck
      {...props}
    />
  );
};

export default React.memo(Expenditure);
