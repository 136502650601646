import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import axios from 'axios'
import {ConfigProvider, message} from 'antd'

import store from './redux/store'

import './scss/main.scss'
import 'moment/locale/ru'
import ru_RU from 'antd/lib/locale-provider/ru_RU'

import {MediaContextProvider} from 'components/media'
import Root from './Root'

import * as Sentry from '@sentry/browser'

import {unregister} from './registerServiceWorker'

import {SET_USER_LOGOUT} from './redux/modules/common/auth'

import {localStorageLogoutCleanUp} from './utils/localStorageLogoutCleanUp'
import {isAllModulesAllowed} from './utils/isAllModulesAllowed'

Sentry.init({
  dsn: "https://7826aa91ad18493f8d9c447a52cdf28e@sentry.ddosla.ru/4",
  environment: process.env.NODE_ENV,
});

axios.defaults.baseURL = window.externalConfig._backendURL; // do not use this process.env.REACT_APP_API;

const requestInterceptor = (request) => {
  const storeToken = localStorage.getItem("authToken");

  if (storeToken) {
    request.headers.Authorization = `Token ${storeToken}`;
  }

  request.headers["Cache-Control"] = "no-cache";

  return request;
};

axios.interceptors.request.use(requestInterceptor);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch({ type: SET_USER_LOGOUT });
      window.location.href = isAllModulesAllowed() ? "/auth" : '/';
      localStorageLogoutCleanUp();
      return;
    }

    if (error.response && error.response.status === 500) {
      message.error("Ошибка сервера");
    }

    return Promise.reject(error);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider locale={ru_RU}>
        <MediaContextProvider>
          <Root />
        </MediaContextProvider>
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

unregister();
