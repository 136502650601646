import axios from "axios";
import { createSelector } from "reselect";
import { message } from "antd";

import { INVITE_STATUSES } from "utils/constant";
import { messageErrorHandler } from "utils/errorHandler";

import { invitesStateSelector } from "../invites";
import { LOAD_LIMIT } from "./constants";
import { prepareTableData, removeInvitesByIds } from "./utils";

const initialState = {
  isLoading: true,
  invites: {
    count: 0,
    list: []
  },
  tableFilters: {},
  selectedInvites: []
};

const moduleName = "pending";
const actionsPrefix = "constructing/counterparties/invites/pending";

const SET_LOADING = `${actionsPrefix}_SET_LOADING`;
const SET_INVITES = `${actionsPrefix}_SET_INVITES`;
const SET_MORE_INVITES = `${actionsPrefix}_SET_MORE_INVITES`;
const SET_TABLE_FILTER = `${actionsPrefix}_SET_TABLE_FILTER`;
const SET_SELECTED_INVITES = `${actionsPrefix}_SET_SELECTED_INVITES`;
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`;

export const stateSelector = createSelector(invitesStateSelector, (state) => state[moduleName]);
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading);
export const invitesSelector = createSelector(stateSelector, (state) => state.invites);
export const tableFiltersSelector = createSelector(stateSelector, (state) => state.tableFilters);
export const selectedInvitesSelector = createSelector(
  stateSelector,
  (state) => state.selectedInvites
);

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_INVITES:
      return {
        ...state,
        invites: {
          count: payload.count,
          list: payload.list
        }
      };
    case SET_MORE_INVITES:
      return {
        ...state,
        invites: {
          ...state.invites,
          list: [...state.invites.list, ...payload.list]
        }
      };
    case SET_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...payload
        }
      };
    case SET_SELECTED_INVITES:
      return {
        ...state,
        selectedInvites: payload
      };
    case CLEAR_DATA:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data
});

export const setInvites = (data) => ({
  type: SET_INVITES,
  payload: data
});

export const setMoreInvites = (data) => ({
  type: SET_MORE_INVITES,
  payload: data
});

export const loadInvites = () => {
  return async (dispatch, getState) => {
    const tableFilters = tableFiltersSelector(getState());
    try {
      dispatch(setLoading(true));
      const response = await axios.get("/partnership/invitations/", {
        params: {
          offset: 0,
          limit: LOAD_LIMIT,
          status: INVITE_STATUSES.NEW,
          ...tableFilters
        }
      });
      const preparedData = {
        count: response.data.count,
        list: prepareTableData(response.data.results)
      };
      dispatch(setInvites(preparedData));
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const loadMoreInvites = () => {
  return async (dispatch, getState) => {
    const invites = invitesSelector(getState());
    const tableFilters = tableFiltersSelector(getState());
    try {
      const response = await axios.get("/partnership/invitations/", {
        params: {
          offset: invites.list.length,
          limit: LOAD_LIMIT,
          status: INVITE_STATUSES.NEW,
          ...tableFilters
        }
      });
      const preparedData = {
        count: response.data.count,
        list: prepareTableData(response.data.results)
      };
      dispatch(setMoreInvites(preparedData));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setTableFilter = (data) => ({
  type: SET_TABLE_FILTER,
  payload: data
});

export const acceptInvitations = (ids) => {
  return async (dispatch, getState) => {
    const invites = invitesSelector(getState());
    try {
      await axios.post("/invitations/accepts/", {
        invations: ids
      });

      const newInvites = removeInvitesByIds(invites, ids);
      dispatch(setInvites(newInvites));

      message.success("Успешно принято");
    } catch (e) {
      messageErrorHandler(e.response.data.errors, true);
    }
  };
};

export const rejectInvitations = (ids) => {
  return async (dispatch, getState) => {
    const invites = invitesSelector(getState());
    try {
      await axios.post("/invitations/rejects/", {
        invations: ids
      });

      const newInvites = removeInvitesByIds(invites, ids);
      dispatch(setInvites(newInvites));

      message.success("Успешно отклонено");
    } catch (e) {
      console.log(e);
      messageErrorHandler(e.response.data.errors, true);
    }
  };
};

export const setSelectedInvites = (data) => ({
  type: SET_SELECTED_INVITES,
  payload: data
});

export const selectInvite = (id) => {
  return (dispatch, getState) => {
    const selectedInvites = selectedInvitesSelector(getState());
    const newSelectedInvites = [...selectedInvites, id];
    dispatch(setSelectedInvites(newSelectedInvites));
  };
};

export const unselectInvite = (id) => {
  return (dispatch, getState) => {
    const selectedInvites = selectedInvitesSelector(getState());
    const newSelectedInvites = selectedInvites.filter((item) => item !== id);
    dispatch(setSelectedInvites(newSelectedInvites));
  };
};

export const clearData = () => ({
  type: CLEAR_DATA
});
