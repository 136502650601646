import axios from "axios";
import { createSelector } from "reselect";
import { apiDeleteRequisite } from "./profileApi";
import { compose } from "redux";
import { message } from "antd";

const moduleName = "profile";
const SET_LOADING = `${moduleName}/SET_LOADING`;
const SET_PROFILE = `${moduleName}/SET_PROFILE`;
const SET_ERRORS = `${moduleName}/SET_ERRORS`;
const UPDATE_PROFILE = `${moduleName}/UPDATE_PROFILE`;
const REMOVE_REQUISITE = `${moduleName}/REMOVE_REQUISITE`;

const initialState = {
  info: {
    general_entity: {
      requisite_set: []
    }
  },
  taxes: [],
  errors: {},
  nowAddress: null,
  isLoading: true
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_PROFILE: {
      return {
        ...state,
        info: {
          ...payload.info
        },
        taxes: payload.taxes,
        isLoading: false
      };
    }
    case REMOVE_REQUISITE:
      return {
        ...state,
        info: {
          ...state.info,
          general_entity: {
            ...state.info.general_entity,
            requisite_set: [
              ...state.info.general_entity.requisite_set.filter(tl => tl.pk !== payload)]
          },
        },
      }
    case UPDATE_PROFILE: {
      return {
        ...state,
        info: {
          ...payload.info
        },
        taxes: payload.taxes
      };
    }

    case SET_ERRORS: {
      return {
        ...state,
        errors: {
          ...payload
        }
      };
    }

    case SET_LOADING:
      return { ...state, isLoading: true };

    default: {
      return state;
    }
  }
};

export const stateSelector = state => state.profile;
export const loadingStateSelector = createSelector(stateSelector, state => state.isLoading);
export const sortingStateSelector = createSelector(stateSelector, state => state.sorting);
export const infoStateSelector = createSelector(stateSelector, state => state.info);
export const generalStateSelector = createSelector(stateSelector, state => state.info.general_entity);
export const taxesStateSelector = createSelector(stateSelector, state => state.taxes);
export const errorsStateSelector = createSelector(stateSelector, state => state.errors);


export const setProfileState = value => {
  return {
    type: SET_PROFILE,
    payload: value
  };
};
export const updateProfileState = value => {
  return {
    type: UPDATE_PROFILE,
    payload: value
  };
};

export const setErrorsState = value => {
  return {
    type: SET_ERRORS,
    payload: value
  };
};

export const removeRequisition = value => {
  return {
    type: REMOVE_REQUISITE,
    payload: value
  };
};

export const setLoading = () => ({ type: SET_LOADING });

export const getProfile = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;

  return () => {
    if (gu_cancel) gu_cancel();

    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return (dispatch) => {
      compose(dispatch, setLoading)();

      axios.get(`/profile/`, config).then(
        (res) => compose(dispatch, setProfileState)({ info: res.data }),
        (err) => console.error(err)
      );
    };
  };
})();

export const updateProfile = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;

  return (data, callback = null) => {
    if (gu_cancel) gu_cancel();

    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return () =>
      axios.put(`/profile/`, data, config).then(
        () => {
          if (callback) callback();
        },
        (err) => {
          console.error(err);
        }
      );
  };
})();

export const patchProfile = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;

  return (data) => {
    if (gu_cancel) gu_cancel();

    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return (dispatch) => axios.patch(`/profile/`, data, config).then(
      (res) => {
        dispatch(updateProfileState({ info: res.data }));
        message.success("Профиль успешно сохранен")
      },
      (err) => {
        console.error(err);
      }
    );
  };
})();

export const deleteRequisitesProfile = (() => {
  let gu_cancel;

  return (id) => {
    if (gu_cancel) gu_cancel();

    return (dispatch) => {
      apiDeleteRequisite(id)
        .then(() => {
          dispatch(removeRequisition(id));
        });
    };
  };
})();

export const deleteAddress = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;

  return (id, callback = null) => {
    if (gu_cancel) gu_cancel();

    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return () => axios.delete(`/profile/address/${id}/`, config).then(
      () => {
        if (callback) callback();
      },
      err => {
        console.error(err);
      }
    );
  };
})();

export const setNewAddress = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;

  return (data, callback = null) => {
    if (gu_cancel) gu_cancel();

    const params = {
      ...data
    };

    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return () => axios.post(`/profile/address/`, params, config).then(
      () => {
        console.log("done");
        if (callback) callback();
      },
      err => {
        console.error(err);
      }
    );
  };
})();

export const updateAddress = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;

  return (id, data, callback = null) => {
    if (gu_cancel) gu_cancel();

    const params = {
      ...data
    };

    const config = {
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      })
    };

    return () => axios.put(`/profile/address/${id}/`, params, config).then(
      () => {
        console.log("done");
        if (callback) callback();
      },
      err => {
        console.error(err);
      }
    );
  };
})();
