import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";

import AddEmployeeSlide from "components/UI/molecules/AddEmployeeSlide";

import CrossIcon from "images/icons/CrossIcon";
import PlusIcon from "images/icons/PlusIcon";
import CheckBoldIcon from "images/icons/CheckBold";

import { localizeServerDateInUserTimezone } from "../../../../utils/localizeServerDateInUserTimezone";

import styles from "./index.module.scss";

const EmployeesElem = ({
  className = "",
  employee,
  deleteEmployee,
  editPermission,
  addChainEmployee,
  nextEmployee,
  isChain,
  isCanChain,
  index,
  buildingId,
  isApproved,
  isShownRole = true,
  isShownApproveDate,
  employees,
  approvedAt,
  objectOnly,
  isInApprovals,
  isShownContacts,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAddEmployee = useCallback(
    (val) => {
      if (addChainEmployee) addChainEmployee(val);

      setIsOpen(false);
    },
    [addChainEmployee]
  );

  const isShownAddChain = useMemo(() => {
    if (employee.position && nextEmployee?.position) return false;
    if (isChain && !employee.position) return false;

    return isCanChain;
  }, [employee.position, nextEmployee?.position, isChain, isCanChain]);

  const isShownEmployeePosition = !(employee.position === 1 && !nextEmployee);

  return (
    <div key={employee?.id} className={cn(styles.container, className)}>
      <div className={styles.blueArea}>
        {isShownEmployeePosition && <div className={styles.positionCount}>{employee.position}</div>}
        {editPermission && !isApproved && (
          <div className={styles.delete} onClick={() => deleteEmployee(employee)}>
            <CrossIcon width="12" color="#fff" />
          </div>
        )}
        {isApproved && (
          <div>
            <CheckBoldIcon width="12" color="#fff" />
          </div>
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.name}>
          {employee?.name ? employee?.name : `${employee?.last_name} ${employee?.first_name} ${employee?.middle_name}`}
        </div>
        {isShownRole && (
          <div className={styles.type}>
            {employee?.roles?.map((role) => (
              <span key={role.id}>{role.name} </span>
            ))}
            {employee?.roles?.length === 0 && <span className={styles.noRole}>Должность не назначена</span>}
          </div>
        )}
        {isShownContacts && (
          <div className={styles.contacts}>
            <div className={styles.email}>{employee.email}</div>
            <div>{employee.phone_number}</div>
          </div>
        )}
        {isShownApproveDate && (
          <div className={styles.date}>{localizeServerDateInUserTimezone(approvedAt, "DD.MM.YY\tHH:mm")}</div>
        )}
      </div>
      {isShownAddChain && (
        <div className={styles.addIcon} onClick={() => setIsOpen(true)}>
          <PlusIcon color="#4FB2ED" width="14" />
        </div>
      )}
      {employee.position && nextEmployee?.position && (index + 1) % 3 !== 0 && <div className={styles.chainLine} />}
      {isShownAddChain && (
        <AddEmployeeSlide
          employees={!isInApprovals ? employees : undefined}
          objectEmployees={employees}
          returnOneData={true}
          isOpen={isOpen}
          closeAddPEmployeeBlock={() => setIsOpen(false)}
          addEmployees={handleAddEmployee}
          buildingId={buildingId}
          objectOnly={objectOnly}
        />
      )}
    </div>
  );
};

export default React.memo(EmployeesElem);
