import axios from 'axios';

export function apiGetProduct( expenditureId) {
  return axios.get(`/requisition/${expenditureId}/products/`)
    .then(
      ({ data }) => data,
      error => {
        console.log(error)
      }
    ).catch(e => console.log(e));
}