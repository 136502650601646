import React from 'react'
import getShortFullName from '../../../../../../utils/getShortFullName'
import Avatar from '../../../../atoms/Avatar/Avatar'
import Checkbox from '../../../../atoms/Checkbox/Checkbox'
import styles from "./TableRow.module.scss"
import Actions from "./../../../../atoms/Actions/Actions"
import cn from 'classnames'
import { stopEventPropagation } from "./../../../../../../utils/stopEventPropagation"

function WorkerTableRow({ number, worker, onCheck, onChangeCount, canBeChecked = true, onDelete, canDelete, isSelected }) {
  const [localValue, setLocalValue] = React.useState(worker.count)
  const ref = React.useRef(null)
  const changeHandler = (e) => {
    onChangeCount(e)
    setLocalValue(e.target.value)
  }

  const clickDrowningHandler = () => {
    ref.current?.click()
  }

  return (
    <div className={cn(styles.row, { [styles.selected]: isSelected })} onClick={clickDrowningHandler}>
      <div className={styles.checkbox}>
        {canBeChecked && 
        <Checkbox onCheck={e => onCheck(e, { ...worker, count: localValue })} checked={isSelected} >
          <div ref={ref} />
        </Checkbox>}
      </div>
      <div className={styles.number}>{number}</div>
      <div className={styles.avatar}><Avatar person={worker} img={worker?.avatar} /></div>
      <div className={styles.name} title={getShortFullName(worker)}>{getShortFullName(worker)}</div>
      <div onClick={stopEventPropagation}>
        <input className={styles.input} onChange={changeHandler} value={worker.count} placeholder="Кол-во" />
      </div>
      <div>
        {canDelete && <Actions canRemove={canDelete} onRemove={onDelete} className={styles.delete} />}
      </div>
    </div>
  )
}

export default WorkerTableRow