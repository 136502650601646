import axios from 'axios'
import { createSelector } from 'reselect'

import { contactsStateSelector } from '../contacts'
import { moduleName as parentModuleName } from './index'
import { LOAD_LIMIT } from './constants'


const initialState = {
  isLoading: true,
  contacts: {
    count: 0,
    list: []
  },
  tableFilters: {}
}

const moduleName = 'index'
const actionsPrefix = 'constructing/counterparties/contacts/list'

const SET_LOADING = `${actionsPrefix}_SET_LOADING`
const SET_CONTACTS = `${actionsPrefix}_SET_CONTACTS`
const SET_CONTACTS_COUNT = `${actionsPrefix}_SET_CONTACTS_COUNT`
const SET_MORE_CONTACTS = `${actionsPrefix}_SET_MORE_CONTACTS`
const SET_TABLE_FILTER = `${actionsPrefix}_SET_TABLE_FILTER`
const CLEAR_DATA = `${actionsPrefix}_CLEAR_DATA`

export const listStateSelector = createSelector(
  contactsStateSelector,
  (state) => state[parentModuleName]
)
export const stateSelector = createSelector(listStateSelector, (state) => state[moduleName])
export const isLoadingSelector = createSelector(stateSelector, (state) => state.isLoading)
export const contactsSelector = createSelector(stateSelector, (state) => state.contacts)
export const tableFiltersSelector = createSelector(stateSelector, (state) => state.tableFilters)

export default (state = initialState, action) => {
  const {type, payload} = action
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload
      }
    case SET_CONTACTS:
      return {
        ...state,
        contacts: {
          count: payload.count,
          list: payload.results
        }
      }
    case SET_CONTACTS_COUNT:
      return {
        ...state,
        contacts: {
          count: payload,
          list: state.contacts.list
        }
      }
    case SET_MORE_CONTACTS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          list: [...state.contacts.list, ...payload]
        }
      }
    case SET_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          ...payload
        }
      }
    case CLEAR_DATA:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data
})

export const setContacts = (data) => ({
  type: SET_CONTACTS,
  payload: data
})

export const setMoreContacts = (data) => ({
  type: SET_MORE_CONTACTS,
  payload: data
})

export const setContactsCount = (count) => ({
  type: SET_CONTACTS_COUNT,
  payload: count
})

export const loadContacts = () => {
  return async (dispatch, getState) => {
    const tableFilters = tableFiltersSelector(getState())
    try {
      dispatch(setLoading(true))
      const response = await axios.get('/partnership/virtual-providers/', {
        params: {
          offset: 0,
          limit: LOAD_LIMIT,
          ...tableFilters
        }
      })
      dispatch(setContacts(response.data))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const loadMoreContacts = () => {
  return async (dispatch, getState) => {
    const contacts = contactsSelector(getState())
    const tableFilters = tableFiltersSelector(getState())
    try {
      const response = await axios.get('/partnership/virtual-providers/', {
        params: {
          offset: contacts.list.length,
          limit: LOAD_LIMIT,
          ...tableFilters
        }
      })
      dispatch(setMoreContacts(response.data.results))
    } catch (e) {
      console.error(e)
    }
  }
}

export const deleteContact = (id) => {
  return async (dispatch, getState) => {
    const contacts = contactsSelector(getState())
    try {
      await axios.delete(`/partnership/virtual-providers/${id}/`)
      const newContacts = {
        count: contacts.count - 1,
        results: contacts.list.filter((contact) => contact.id !== id)
      }
      dispatch(setContacts(newContacts))
    } catch (e) {
      console.error(e)
    }
  }
}

export const setTableFilter = (data) => ({
  type: SET_TABLE_FILTER,
  payload: data
})

export const clearData = () => ({
  type: CLEAR_DATA
})
