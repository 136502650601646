import React from "react";
import cn from "classnames";

import { transformDigitToFinancial } from "../../../../../utils/transformDigitToFinancial";

import styles from "./ChartPopup.module.scss";

const TRANSFORM_OPTIONS = { withFloat: false, withRuble: true };

function ChartPopup({
  title = "Прогресс выполнения",
  type = "rouble", //"info"|"rouble"
  popupClassName,
  containerClassName,
  all = 0,
  completed = 0,
  accepted = 0,
  onHover,
  isLast
}) {
  const [isActive, setIsActive] = React.useState(false);

  const COLORS = ["#72E2E6", "#8FE52B", "#FFE86A"];

  const hoverHandler = () => {
    onHover && onHover();
    setIsActive(true);
  };

  return (
    <div className={cn(styles.container, containerClassName)} onMouseEnter={hoverHandler}>
      <div className={styles[type]}>
        {type === "rouble" &&
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.71533 7.92773C9.70633 7.92773 11.5513 6.57531 11.5513 4.07031C11.5513 1.58131 9.70633 0.230469 6.71533 0.230469H2.14551V8.90039H0.445312V9.83936H2.14551V11.7686H3.82471V9.83936H7.51367V8.90039H3.82471V7.92773H6.71533ZM9.87354 4.08643C9.87354 5.65243 8.74233 6.55957 6.71533 6.55957H3.82373V1.65967H6.71533C8.74233 1.66467 9.87354 2.52043 9.87354 4.08643Z"
              fill="black" />
          </svg>}
        {type === "info" &&
          <svg width="2" height="14" viewBox="0 0 2 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.65454 13.7019H0.343538V4.68787H1.65354L1.65454 13.7019ZM1.92454 1.22087C1.92796 1.34597 1.90384 1.47028 1.8539 1.58502C1.80396 1.69977 1.72942 1.80213 1.63554 1.88487C1.4645 2.04955 1.23695 2.14257 0.999538 2.14487C0.762121 2.14257 0.534577 2.04955 0.363538 1.88487C0.269659 1.80213 0.195113 1.69977 0.145172 1.58502C0.0952319 1.47028 0.0711183 1.34597 0.0745377 1.22087C0.0711183 1.09578 0.0952319 0.971468 0.145172 0.856726C0.195113 0.741984 0.269659 0.639619 0.363538 0.556874C0.534577 0.3922 0.762121 0.299179 0.999538 0.296875C1.23695 0.299179 1.4645 0.3922 1.63554 0.556874C1.72942 0.639619 1.80396 0.741984 1.8539 0.856726C1.90384 0.971468 1.92796 1.09578 1.92454 1.22087Z"
              fill="black" />
          </svg>}
      </div>
      {isActive && (
        <div className={cn(styles.popup, popupClassName, { [styles.last]: isLast })}>
          <p className={styles.title}>{title}</p>
          <div className={styles.pair}>
            <div className={styles.item}>
              <div className={styles.legend} style={{ background: COLORS[0] }} />
              <p className={styles.sum}>
                {type === "rouble" ? (
                  <span>{transformDigitToFinancial(completed, TRANSFORM_OPTIONS)}</span>
                ) : completed}
              </p>
              <p className={styles.fieldName}>Израсходовано</p>
            </div>
            <div className={styles.item}>
              <div className={styles.legend} style={{ background: COLORS[1] }} />
              <p className={styles.sum}>
                {type === "rouble" ? (
                  <span>{transformDigitToFinancial(accepted, TRANSFORM_OPTIONS)}</span>
                ) : accepted}
              </p>
              <p className={styles.fieldName}>Принято</p>
            </div>
          </div>
          <div className={cn(styles.item, styles.final)}>
            <p className={styles.sum}>
              {type === "rouble" ? (
                <span>{transformDigitToFinancial(all, TRANSFORM_OPTIONS)}</span>
              ) : all}
            </p>
            <p className={styles.fieldName}>{type === "rouble" ? "Бюджет" : "Кол-во по смете"}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(ChartPopup);