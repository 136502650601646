import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import update from 'immutability-helper';
import { createSelector } from 'reselect';

const moduleName = 'providerOrders';

const SET_CURRENT_ORDER = `providerOrders/SET_CURRENT_ORDER`;
const LOAD_ORDERS = `${moduleName}/LOAD_ORDERS`;
const SET_ORDERS = `providerOrders/SET_ORDERS`;
const LOAD_ORDERS_BY_DATES_START = `providerOrders/LOAD_ORDERS_BY_DATES_START`;
const LOAD_ORDERS_BY_DATES_SUCCESS = `providerOrders/LOAD_ORDERS_BY_DATES_SUCCESS`;
const LOAD_ORDERS_BY_DATES_FAIL = `providerOrders/LOAD_ORDERS_BY_DATES_FAIL`;
const SET_PAGINATION_STATE = `providerOrders/SET_PAGINATION_STATE`;
const LOAD_CLIENTS_LIST = `${moduleName}/LOAD_CLIENTS_LIST`;

const UPDATE_SEARCH_INPUT_VALUE = `${moduleName}/UPDATE_SEARCH_INPUT_VALUE`;
const SET_ORDER_STATUS_FILTER = `${moduleName}/SET_ORDER_STATUS_FILTER`;
const SET_ORDER_CLIENT_FILTER = `${moduleName}/SET_ORDER_CLIENT_FILTER`;
const SET_ORDER_EXECUTOR_FILTER = `${moduleName}/SET_ORDER_EXECUTOR_FILTER`;
const SET_IS_ORDER_CHECKED_FILTER = `${moduleName}/SET_IS_ORDER_CHECKED_FILTER`;
const CLEAR_FILTERS = `${moduleName}/CLEAR_FILTERS`;
const SET_DATE_RANGE_FILTER = `${moduleName}/SET_DATE_RANGE_FILTER`;

// move documents into common modules
const SET_DOCUMENTS = 'documents/SET_DOCUMENTS';
const SET_PACKING_LIST = 'documents/SET_PACKING_LIST';

const SET_PROVIDER_PRODUCTS = `${moduleName}/SET_PROVIDER_PRODUCTS`;

const SET_NEW_SORTING = `${moduleName}/SET_NEW_SORTING`;

const initialState = {
  currentOrder: {},
  ordersList: {
    filters: {
      search: '',
      status: null,
      is_check: null,
      delivery_date_after: null,
      delivery_date_before: null,
      purchaser: null,
      executor_id: null
    },
  },
  filteredOrders: {
    ordersByDatesLoading: false,
    ordersByDates: [],
  },
  pagination: {
    params: { limit: 50, offset: 0 },
    page: 1,
  },
  providerProductsList: {},
  sorting: null,
  loading: true,
  clients: null
};

/*
  Reducer
*/

export default (ordersState = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_CURRENT_ORDER:
      return {
        ...ordersState,
        currentOrder: payload,
      };

    case LOAD_ORDERS:
      return {
        ...ordersState,
        loading: true
      };

    case SET_ORDERS:
      return {
        ...ordersState,
        ordersList: { ...ordersState.ordersList, ...payload },
        loading: false
      };

    case SET_PROVIDER_PRODUCTS:
      return {
        ...ordersState,
        providerProductsList: payload,
      };

    case LOAD_ORDERS_BY_DATES_START:
      return {
        ...ordersState,
        filteredOrders: {
          ...ordersState.filteredOrders,
          ordersByDatesLoading: true,
        },
      };

    case LOAD_ORDERS_BY_DATES_SUCCESS:
      return {
        ...ordersState,
        filteredOrders: {
          ...ordersState.filteredOrders,
          ordersByDatesLoading: false,
          ordersByDates: payload,
        },
      };

    case LOAD_ORDERS_BY_DATES_FAIL:
      return {
        ...ordersState,
        filteredOrders: {
          ...ordersState.filteredOrders,
          ordersByDatesLoading: false,
        },
      };

    case LOAD_CLIENTS_LIST:
      return {
        ...ordersState,
        clients: payload
      };

    case SET_PAGINATION_STATE:
      return {
        ...ordersState,
        pagination: {
          params: payload.params,
          page: payload.page,
        },
      };

    case UPDATE_SEARCH_INPUT_VALUE:
      return update(ordersState, { ordersList: { filters: { search: { $set: payload.value } } } });

    case SET_ORDER_STATUS_FILTER:
      return update(ordersState, { ordersList: { filters: { status: { $set: payload.status } } } });

    case SET_ORDER_EXECUTOR_FILTER:
      return update(ordersState, { ordersList: { filters: { executor_id: { $set: payload.executor } } } });

    case SET_IS_ORDER_CHECKED_FILTER:
      return update(ordersState, {
        ordersList: { filters: { is_check: { $set: payload.checked } } },
      });

    case CLEAR_FILTERS:
      return update(ordersState, {
        ordersList: {
          filters: {
            $set: {
              search: '',
              status: null,
              is_check: null,
              delivery_date_after: null,
              delivery_date_before: null,
              executor_id: null
            },
          },
        }
      });

    case SET_DATE_RANGE_FILTER:
      return update(ordersState, {
        ordersList: {
          filters: {
            delivery_date_after: { $set: payload.delivery_date_after },
            delivery_date_before: { $set: payload.delivery_date_before },
          },
        },
      });

    case SET_ORDER_CLIENT_FILTER:
      return update(ordersState, {
        ordersList: {
          filters: {
            purchaser: { $set: payload.provider }
          }
        }
      });

    case SET_NEW_SORTING:
      return update(ordersState, {
        sorting: { $set: payload }
      });

    default:
      return ordersState;
  }
};

/*
  Selectors
*/

export const stateSelector = state => state.ordersProvider;
export const ordersSelector = createSelector(stateSelector, state => state.ordersList);
export const paginationStateSelector = createSelector(stateSelector, state => state.pagination);
export const filtersStateSelector = createSelector(
  ordersSelector,
  ordersList => ordersList.filters
);
export const sortingStateSelector = createSelector(stateSelector, state => state.sorting);
export const loadingStateSelector = createSelector(stateSelector, state => state.loading);
export const clientsListSelector = createSelector(stateSelector, state => state.clients);
/*
  Action creators
*/

export const setPaginationState = (params, page) => ({
  type: SET_PAGINATION_STATE,
  payload: { params, page },
});

export const updateSearchInputValue = value => ({
  type: UPDATE_SEARCH_INPUT_VALUE,
  payload: { value },
});

export const setOrderStatusFilter = status => ({
  type: SET_ORDER_STATUS_FILTER,
  payload: { status },
});

export const setIsOrderCheckedFilter = checked => ({
  type: SET_IS_ORDER_CHECKED_FILTER,
  payload: { checked },
});

export const setDateRangeFilter = range => ({
  type: SET_DATE_RANGE_FILTER,
  payload: range,
});

export const setOrderClientsFilter = provider => ({
  type: SET_ORDER_CLIENT_FILTER,
  payload: { provider },
});

export const setOrderExecutorFilter = executor => ({
  type: SET_ORDER_EXECUTOR_FILTER,
  payload: { executor },
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
});

export const sortTableTab = (tab) => ({
  type: SET_NEW_SORTING,
  payload: tab
});

/*
  Thunks
*/

export const loadProviderOrders = (entityId, paginationParams, filtersState) => {
  const config = {
    params: { ...paginationParams, ...filtersState },
  };

  return dispatch => {
    axios.get(`/orders/`, config).then(response =>
      dispatch({
        type: SET_ORDERS,
        payload: response.data,
      })
    );
  };
};

export const loadProviderOrdersV2 = (() => {
  const CancelToken = axios.CancelToken;
  let ge_cancel;
  return (entityId, paginationParams, filtersState, sorting = null) => {
    if (ge_cancel) ge_cancel();
    const config = {
      params: {...paginationParams, ...filtersState, ordering: sorting},
      cancelToken: new CancelToken((c) => {
        ge_cancel = c;
      })
    };



    return dispatch => {
      dispatch({
        type: LOAD_ORDERS
      });
      axios.get(`/orders/`, config)
        .then(
          res => {
            dispatch({
              type: SET_ORDERS,
              payload: res.data,
            });
          },
          error => {
            console.error(error);
          }
        );
    }
  };
})();

export const loadClientsList = (entityId) => {
  return dispatch => axios({
    method: 'get',
    url: `/entities/${entityId}/organisations/partners/`,
  }).then(
    res => {
      dispatch({
        type: LOAD_CLIENTS_LIST,
        payload: res.data.results,
      });
    },
    error => {
      console.log(error);
    },
  );
};

export const loadProviderProducts = (purchaserId, entityId) => {
  const config = {
    params: {
      partner: entityId,
    },
  };

  return async dispatch => {
    const result = await axios.get(`/purchasers/${purchaserId}/providers/products/`, config);
    await dispatch({
      type: SET_PROVIDER_PRODUCTS,
      payload: result.data,
    });
  };
};

export const loadOrderDetails = (entityId, orderId) => {
  return dispatch => {
    return axios.get(`/orders/${orderId}/`).then(response =>
      dispatch({
        type: SET_CURRENT_ORDER,
        payload: response.data,
      })
    );
  };
};

export const loadOrderDetailsV2 = (entityId, orderId, callback) => {

  
  return dispatch =>
    axios({
      method: 'get',
      url: `/orders/${orderId}/`,
    }).then(
      response => {
        dispatch({
          type: SET_CURRENT_ORDER,
          payload: response.data,
        });
        callback();
      },
      error => {
        console.error(error);
      }
    );
};

export const orderViewed = (entityId, orderId) => {

  return axios({
    method: 'get',
    url: `/orders/${orderId}/`,
  });
};

export const loadOrdersByDates = (entityId, fromDate) => {
  const config = {
    params: {
      status: 'ip',
      delivery_date_after: fromDate,
      delivery_date_before: moment(fromDate)
        .add(6, 'days')
        .set({ hour: 23, minute: 59, second: 59 })
        .format(),
    },
  };

  const amountOfDays = 7;
  const dates = [...Array(amountOfDays).keys()].reduce(
    (acc, day) => {
      return {
        currentDay: moment(acc.currentDay)
          .add(1, 'days')
          .set({ hour: 0, minute: 0, second: 0 })
          .format(),
        dates: acc.dates.concat(moment(acc.currentDay).format()),
      };
    },
    {
      currentDay: fromDate,
      dates: [],
    }
  ).dates;

  return async dispatch => {
    dispatch({
      type: LOAD_ORDERS_BY_DATES_START,
    });

    try {
      const res = await axios.get(`/orders/`, config);
      const result = dates.reduce((acc, day) => {
        const orders = res.data.results.reduce((ordersAcc, order) => {
          if (moment(order.delivery_date).isSame(day, 'day')) {
            return ordersAcc.concat(order);
          }

          return ordersAcc;
        }, []);

        return { ...acc, [day]: orders };
      }, {});

      dispatch({
        type: LOAD_ORDERS_BY_DATES_SUCCESS,
        payload: result,
      });
    } catch (err) {
      console.error(err);

      dispatch({
        type: LOAD_ORDERS_BY_DATES_FAIL,
        payload: err,
      });
    }
  };
};

export const updateOrder = (entityId, orderId, data) => {
  return () => axios.put(`/orders/${orderId}/`, data);
};

export const updateOrderV2 = (entityId, orderId, data) => {


  return () =>
    axios({
      method: 'put',
      url: `/orders/${orderId}/`,
      data: data,
    });
};

export const cancelOrderV2 = (entityId, orderId) => {


  return () =>
    axios({
      method: 'put',
      url: `/orders/${orderId}/cancel/`,
    });
};

export const confirmOrder = (entityId, id) => {
  const data = {
    confirm_provider: true,
  };
  return () => axios.put(`/orders/${id}/confirm/`, data);
};

export const declineOrder = (entityId, id) => {
  const data = {
    confirm_provider: false,
  };
  return () => axios.put(`/orders/${id}/cancel/`, data);
};

export const setPackingList = data => {
  return {
    type: SET_PACKING_LIST,
    payload: data,
  };
};

export const setDocuments = data => {
  return {
    type: SET_DOCUMENTS,
    payload: data,
  };
};

export const loadDocuments = (userKey, entityId, orderId) => {
  const config = {
    headers: {
      Authorization: `Token ${userKey}`,
      'Cache-Control': 'no-cache',
    },
  };
  return dispatch =>
    axios.get(`/providers/${entityId}/orders/packinglist/`, config).then(
      response => {
        console.log('documents loaded', ...response.data);
        //hack temp
        const docs = response.data.results;
        const packingList = _.find(docs, {
          order: orderId,
        });
        dispatch(setPackingList(packingList));
        //end of hack
        dispatch(setDocuments(response.data.results));
      },
      err => {
        console.log('docs not loaded');
      }
    );
};

export const loadPackingList = (userKey, entityId, orderId) => {
  const config = {
    headers: {
      Authorization: `Token ${userKey}`,
      'Cache-Control': 'no-cache',
    },
  };
  return dispatch =>
    axios.get(`/providers/${entityId}/orders/${orderId}/packinglist/`, config).then(
      response => {
        console.log('packing list loaded', ...response.data);
        dispatch(setPackingList(response.data));
      },
      error => {
        console.log('packing list didnt loaded');
        dispatch(setPackingList({}));
      }
    );
};
