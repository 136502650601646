import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import moment from "moment";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { debounce, memoize, partial } from "lodash";

import { createRequisitionComment } from "../../../../../../redux/modules/common/comments/thunks/createRequisitionComment";
import {
  compareProduct,
  deleteProduct,
  editProduct,
  editProductSave,
  getProducts,
} from "../../../../../../redux/modules/common/building/requisition/reducer";
import { stringifyArgs } from "../../../../../../utils/stringifyArgs";

import CrossIcon from "../../../../../../images/icons/CrossIcon";
import CrossBoldRoundedIcon from "../../../../../../images/icons/CrossBoldRoundedIcon";
import Line from "../../../../../../images/icons/Line";
import MessageIcon from "../../../../../../images/icons/MessageIcon";

import Checkbox from "../../../../../UI/Checkbox";
import CommentPopup from "../../../../../UI/molecules/CommentPopup";
import Calendar from "../../../../../UI/molecules/Calendar";
import InputBase from "../../../../../UI/atoms/InputBase";
import MatchingModal from "../../../../../UI/atoms/MatchingModal/MatchingModal";
import { getMatchingType } from 'components/UI/atoms/MatchingModal/utils';
import OrdersCell from "../../OrdersCell/OrdersCell";
import ProductFilesModal from "../../ProductFilesModal";

import styles from "../index.module.scss";

const EMPTY_ARRAY = [];

const Table = ({
  products,
  comments,
  requisition,
  isRequisitionDraft,
  isViewModeMonitoring,
  productsChecker,
  permissions,
}) => {
  const dispatch = useDispatch();

  const haveOrders = useMemo(() => {
    return products.filter((el) => el.in_orders).length !== 0;
  }, [products]);

  const updateProducts = useCallback(() => compose(dispatch, getProducts)(requisition.id, false), [requisition.id]);

  const onCheckAllProducts = useCallback((e) => productsChecker.checkAll(e.target.checked), [productsChecker.checkAll]);

  const onCheckProduct = useCallback(
    (id, e) => productsChecker.checkOnce(id, e.target.checked),
    [productsChecker.checkOnce]
  );
  const partialOnCheckProduct = useCallback((id) => partial(onCheckProduct, [id]), [onCheckProduct]);
  const memoizedPartialOnCheckProduct = useMemo(() => memoize(partialOnCheckProduct), [partialOnCheckProduct]);

  const editCalendarSavedDebounced = useCallback(
    debounce((requisitionId, productId, val, prop) => {
      dispatch(editProductSave(requisitionId, productId, prop, val));
    }, 0),
    []
  );

  const handleEditProduct = (requisitionId, productId, value, prop) => {
    dispatch(editProduct(productId, prop, value));
    editProductSaveDebounced(requisitionId, productId, value, prop);
  };

  const handleDelete = (product) => {
    dispatch(deleteProduct(requisition.id, product));
  };

  const editProductSaveDebounced = useCallback(
    debounce((requisitionId, productId, val, prop) => {
      dispatch(editProductSave(requisitionId, productId, prop, val));
    }, 500),
    []
  );

  const handleRemoveProvider = useCallback(
    (productId, providerId) => {
      const prov = products
        ?.find((product) => product.id === productId)
        ?.requested_providers?.filter((item) => item.id !== providerId)
        ?.map((item) => item?.id);
      dispatch(compareProduct(requisition.id, productId, { requested_providers_ids: prov }));
    },
    [products, requisition.id]
  );

  const sectionsWithProducts = useMemo(() => {
    const result = {};

    products.forEach((item) => {
      if (result[item.section.id]) {
        result[item.section.id].items.push(item);
      } else {
        result[item.section.id] = {
          name: item.section.name,
          items: [item],
        };
      }
    });

    return result;
  }, [products]);

  const handleCreateComment = useCallback(
    (productId, comment) => compose(dispatch, createRequisitionComment)(requisition.id, productId, comment),
    [requisition.id]
  );

  const partialHandleCreateComment = useMemo(
    () => memoize((productId) => partial(handleCreateComment, productId), stringifyArgs),
    [handleCreateComment]
  );

  const filesPermissions = useMemo(
    () => ({
      addFiles: permissions.viewAddRequisitionProductsFiles,
      deleteFiles: permissions.viewDeleteRequisitionProductsFiles,
    }),
    [permissions.viewDeleteRequisitionProductsFiles, permissions.viewAddRequisitionProductsFiles]
  );

  const agreedRequisitionWithoutEditPermission = requisition.is_agreed && !permissions.viewEditRequisitionBeforeApprove;
  const modeNotMonitoringOrIsDraft = !isViewModeMonitoring || isRequisitionDraft;

  return (
    <div
      className={cn(styles.productsTable, {
        [styles.isDraft]: isRequisitionDraft,
        [styles.viewModeMonitoring]: isViewModeMonitoring,
      })}
    >
      <header className={cn(styles.headerTable, { [styles.withOrders]: haveOrders })}>
        {!isRequisitionDraft && !isViewModeMonitoring && (
          <div className={styles.checkboxCol}>
            <Checkbox
              checked={productsChecker.allCount === productsChecker.checkedCount}
              onChange={onCheckAllProducts}
            />
          </div>
        )}
        <div className={styles.titleCol}>Наименование</div>
        <div className={styles.matchingCol} />
        {haveOrders && <div className={styles.orderCol}>Заказ</div>}
        {isViewModeMonitoring && <div className={styles.planFactDiffCol}>Остаток по плану</div>}
        <div className={styles.requestCountCol}>Запрос</div>
        <div className={styles.measureCol}>Ед. изм.</div>
        {modeNotMonitoringOrIsDraft && <div className={styles.dateCol}>Поставка</div>}
        {!isRequisitionDraft && !isViewModeMonitoring && <div className={styles.providerCol}>Поставщик</div>}
        <div className={styles.actionsCol}>Действия</div>
      </header>
      {Object.values(sectionsWithProducts).map((product) => (
        <div key={product.id}>
          <div className={styles.section}>{product.name}</div>
          {product.items.map((productItem) => (
            <div key={productItem.id} className={cn(styles.productRow)}>
              {!isRequisitionDraft && !isViewModeMonitoring && (
                <div className={styles.checkboxCol}>
                  <Checkbox
                    checked={productsChecker.items[productItem.id]}
                    onChange={memoizedPartialOnCheckProduct(productItem.id)}
                  />
                </div>
              )}
              <div className={styles.titleCol} title={productItem.product_building.name}>
                {productItem.product_building.name}
              </div>
              <div className={styles.matchingCol}>
                <MatchingModal
                  info={productItem.product_building}
                  objectId={requisition.building.id}
                  updateProducts={updateProducts}
                  type={getMatchingType(productItem.product_building.type)}
                />
              </div>
              {haveOrders && productItem.orders && (
                <OrdersCell className={styles.orderCol} orders={productItem.orders} />
              )}
              {isViewModeMonitoring && <div className={styles.planFactDiffCol}>{productItem.plan_fact_diff}</div>}
              <div className={styles.requestCountCol}>
                {modeNotMonitoringOrIsDraft && !agreedRequisitionWithoutEditPermission ? (
                  <InputBase
                    className={styles.input}
                    classNameInput={styles.inputInner}
                    value={productItem.required_count}
                    onChange={(e) =>
                      handleEditProduct(requisition.id, productItem.id, e.target.value, "required_count")
                    }
                  />
                ) : (
                  productItem.required_count
                )}
              </div>
              <div className={styles.measureCol}>{productItem.product_building.measure}</div>
              {modeNotMonitoringOrIsDraft && (
                <div className={styles.dateCol}>
                  <Calendar
                    classNameSelect={cn(styles.calendar, { [styles.disabled]: agreedRequisitionWithoutEditPermission })}
                    value={moment(productItem.delivery_date)}
                    disabled={agreedRequisitionWithoutEditPermission}
                    setValue={(val) =>
                      editCalendarSavedDebounced(
                        requisition.id,
                        productItem.id,
                        moment(val).format("YYYY-MM-DD"),
                        "delivery_date"
                      )
                    }
                  />
                </div>
              )}
              {!isRequisitionDraft && !isViewModeMonitoring && (
                <div className={styles.providerCol}>
                  <div className={styles.providerBlock}>
                    {!!productItem.requested_providers?.length &&
                      productItem.requested_providers.map((provider) => (
                        <div className={styles.providerItem} key={`${productItem.id}_${provider.id}`}>
                          <span className={styles.providerName}>{provider.name}</span>
                          <span
                            className={styles.crossIcon}
                            onClick={() => handleRemoveProvider(productItem.id, provider.id)}
                          >
                            <CrossIcon color="#707070" />
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <div className={cn(styles.actionsCol, styles.actionBlock)}>
                {permissions.viewRequisitionProductsComments && (
                  <div className={styles.action}>
                    <CommentPopup
                      comments={comments[productItem.id] || EMPTY_ARRAY}
                      onCreateComment={partialHandleCreateComment(productItem.id)}
                      canAdd={permissions.viewAddRequisitionProductsComments}
                    >
                      {comments[productItem.id] && comments[productItem.id].length !== 0 ? (
                        <div className={styles.comment}>
                          <MessageIcon width="28" />
                          <div className={styles.commentCount}>{comments[productItem.id].length}</div>
                        </div>
                      ) : (
                        <MessageIcon width="28" />
                      )}
                    </CommentPopup>
                  </div>
                )}
                <div className={styles.action}>
                  <ProductFilesModal
                    files={productItem.product_building.files}
                    buildingId={requisition.building.id}
                    itemId={productItem.product_building.id}
                    permissions={filesPermissions}
                  />
                </div>
                {!agreedRequisitionWithoutEditPermission && (
                  <>
                    <div className={styles.separator}>
                      <Line width="3" height="1.88rem" color="#E0E0E0" />
                    </div>
                    <div className={styles.action}>
                      <div className={styles.crossIcon} onClick={() => handleDelete(productItem)}>
                        <CrossBoldRoundedIcon />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default React.memo(Table);
