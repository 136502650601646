import React from "react";

import Amounts from "../../../../../../UI/molecules/Amounts";

import BillTableRow from "./BillTableRow";
import styles from "./BillTable.module.scss";

function BillTable({ data, amount, delivery, vat, total, comment }) {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.thead}>
          <div className={styles.numberCol}>№</div>
          <div className={styles.nameCol}>Наименование</div>
          <div className={styles.countCol}>Кол-во</div>
          <div className={styles.measureCol}>Ед. изм.</div>
          <div className={styles.retailPriceCol}>Розничная цена ₽</div>
          <div className={styles.priceCol}>Цена ₽</div>
          <div className={styles.amountCol}>Сумма ₽</div>
        </div>
        {Array.isArray(data) &&
          data.map((el, i) => (
            <BillTableRow
              number={i + 1}
              name={el.name}
              count={el.count}
              amount={el.amount}
              measure={el.measure}
              price={el.price}
              retailPrice={el.price_retail}
              key={el.id}
            />
          ))}
      </div>
      <footer className={styles.footer}>
        {comment && (
          <div className={styles.comment}>
            <div className={styles.commentTitle}>Комментарий:</div>
            {comment}
          </div>
        )}
        <div className={styles.amountsContainer}>
          <Amounts
            productsAmount={amount}
            taxAmount={vat}
            totalAmount={total}
            deliveryAmount={delivery}
            taxIsInclude={vat !== 0}
          />
        </div>
      </footer>
    </div>
  );
}

export default React.memo(BillTable);
