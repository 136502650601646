import React from "react";

export default function ArrowWithLine({color = '#4FB2ED', rotate = '0'}) {
  return (
    <svg 
      width="28" 
      height="30" 
      viewBox="0 0 28 30" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
    <path 
      d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1V29C0 29.5523 0.447715 30 1 30C1.55228 30 2 29.5523 2 29V1Z" 
      fill={color}
    />
    <path 
      d="M7.94513 16.0552H24.3771L18.7971 21.6352C18.5994 21.8333 18.4885 22.1018 18.4888 22.3817C18.489 22.6616 18.6005 22.93 18.7986 23.1277C18.9967 23.3254 19.2653 23.4363 19.5452 23.436C19.8251 23.4358 20.0934 23.3243 20.2911 23.1262L27.6711 15.7462C27.7691 15.6483 27.8468 15.5321 27.8998 15.4042C27.9528 15.2762 27.98 15.1391 27.98 15.0007C27.98 14.8622 27.9528 14.7251 27.8998 14.5972C27.8468 14.4693 27.7691 14.3531 27.6711 14.2552L20.2911 6.87518C20.1932 6.77728 20.077 6.69962 19.9491 6.64664C19.8212 6.59365 19.6841 6.56638 19.5456 6.56638C19.4072 6.56638 19.2701 6.59365 19.1422 6.64664C19.0142 6.69962 18.898 6.77728 18.8001 6.87518C18.7022 6.97308 18.6246 7.08931 18.5716 7.21722C18.5186 7.34513 18.4913 7.48222 18.4913 7.62068C18.4913 7.75913 18.5186 7.89623 18.5716 8.02414C18.6246 8.15205 18.7022 8.26828 18.8001 8.36618L24.3801 13.9462L7.94513 13.9462C7.66545 13.9462 7.39724 14.0573 7.19948 14.255C7.00172 14.4528 6.89062 14.721 6.89063 15.0007C6.89062 15.2803 7.00172 15.5486 7.19948 15.7463C7.39724 15.9441 7.66545 16.0552 7.94513 16.0552Z" 
      fill={color}
    />
    </svg>
  )
}