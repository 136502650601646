import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { compose } from "redux";
import cn from "classnames";

import { becomeExecutor, updateExecutor } from "../../../../../redux/modules/common/building/requisition/reducer";
import ButtonBase from "components/UI/atoms/ButtonBase";
import Icon from "components/UI/Icon";
import ConfirmationModal from "components/UI/molecules/ConfirmationModal";
import Calendar from "components/UI/molecules/Calendar";
import OpeningInfo from "components/UI/molecules/OpeningInfo";

import calendarBlue from "images/icons/calendarBlue.svg";

import { getLocalizedDate } from "../../../../../utils/getLocalizedDate";
import { REQUISITION_STATUS } from "../../../../../utils/constant";
import { localizeServerDateInUserTimezone } from "../../../../../utils/localizeServerDateInUserTimezone";

import Chat from "../../../../UI/organism/Chat";
import AddEmployeeSlide from "../../../../UI/molecules/AddEmployeeSlide";
import StatusFormatter from "../../../../UI/atoms/StatusFormatter";
import DynamicTitleInput, { SIZES } from "../../../../UI/atoms/DynamicTitleInput/DynamicTitleInput";
import DownloadButton from "../../../../UI/atoms/DownloadButton/DownloadButton";

import Logs from "../Logs";

import styles from "./index.module.scss";

const RequisitionInfo = ({ requisition, changeExecutionDate, changeRequisitionName, saveExcel, permissions }) => {
  const isDraft = requisition.status === REQUISITION_STATUS.DRAFT;
  const dispatch = useDispatch();

  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [isOpenTakeToWorkModal, setIsOpenTakeToWorkModal] = useState(false);
  const [isOpenAddEmployerSlider, setIsOpenAddEmployerSlider] = useState(false);

  const toggleOpenLogModal = useCallback(() => setIsLogsModalOpen((prevState) => !prevState), []);

  const handleTakeToWork = useCallback(() => {
    compose(dispatch, becomeExecutor)();
    setIsOpenTakeToWorkModal(false);
  }, []);

  const handleAddEmployees = useCallback((addedEmployees) => {
    compose(dispatch, updateExecutor)(addedEmployees[0]?.id);
    setIsOpenAddEmployerSlider(false);
  }, []);

  const openAddEmployeesSlider = useCallback(() => setIsOpenAddEmployerSlider(true), []);
  const closeAddEmployerSlide = useCallback(() => setIsOpenAddEmployerSlider(false), []);

  const openTakeToWorkModal = useCallback(() => setIsOpenTakeToWorkModal(true), []);
  const closeTakeToWorkModal = useCallback(() => setIsOpenTakeToWorkModal(false), []);

  return (
    <div className={styles.container}>
      <DownloadButton className={styles.downloadButton} onDownload={saveExcel} />
      <DynamicTitleInput title={requisition.name} onChange={changeRequisitionName} size={SIZES.BIG} />
      <div className={styles.mainInfo}>
        <div className={styles.mainInfoBlock}>
          <div>Дата исполнения:</div>
          <Calendar
            icon={calendarBlue}
            classNameSelect={styles.calendar}
            value={moment(requisition.date)}
            setValue={changeExecutionDate}
            disabled={!isDraft}
          />
        </div>
        {!isDraft && (
          <div className={styles.mainInfoBlock}>
            <div className={styles.takenAtTitle}>в работе от:</div>
            <div className={styles.dateWrapper}>
              {requisition.taken_at ? (
                localizeServerDateInUserTimezone(requisition.taken_at, "DD.MM.YY HH:mm")
              ) : (
                <div className={styles.emptyBox} />
              )}
              <Icon icon={calendarBlue} className={styles.calendarIcon} />
            </div>
          </div>
        )}
      </div>
      <div className={styles.boxTitle}>
        <div>
          <div className={styles.titleRow}>
            <div className={styles.title}>Статус:</div>
            <div className={styles.value}>{StatusFormatter(requisition.status_name, { flow: "in" })}</div>
          </div>
          {!isDraft && (
            <div className={styles.titleRow}>
              <div className={styles.title}>Исполнитель:</div>
              <div className={styles.value}>
                {!requisition.executor && (
                  <div className={styles.buttons}>
                    {permissions.viewRequisitionTakeToWork && (
                      <ButtonBase primary medium onClick={openTakeToWorkModal}>
                        Взять в работу
                      </ButtonBase>
                    )}
                    {permissions.viewAssignRequisitionExecutor && (
                      <ButtonBase secondary medium onClick={openAddEmployeesSlider}>
                        Назначить
                      </ButtonBase>
                    )}
                  </div>
                )}
                {!requisition.executor &&
                  !permissions.viewRequisitionTakeToWork &&
                  !permissions.viewAssignRequisitionExecutor &&
                  "-"}
              </div>
              {requisition.executor && (
                <div className={styles.executor}>
                  <OpeningInfo info={requisition.executor} title="Контакты исполнителя" />
                  {permissions.viewRequisitionReassign && (
                    <ButtonBase className={styles.executorButton} onClick={openAddEmployeesSlider} secondary medium>
                      Передать
                    </ButtonBase>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className={cn(styles.titleRow, styles.createInfo)}>
          <div className={styles.value}>
            {requisition.created_at && (
              <div className={styles.dateWrapper}>
                <Icon icon={calendarBlue} className={styles.calendarIcon} />
                {getLocalizedDate(requisition.created_at)}
              </div>
            )}
          </div>
          <OpeningInfo info={requisition.creator} title="Контакты заказчика" />
        </div>
      </div>
      <div className={styles.rightSide}>
        {!isDraft && requisition.building.id && (
          <div className={styles.chatsBlock}>
            <Chat
              docType={"requisition"}
              urlType={"requisition"}
              docId={requisition.id}
              title={`Заявка ${requisition.number}`}
              subTitle={requisition.status_name}
              buildingId={requisition.building.id}
              openText={"Чат компании"}
              closeText={"Закрыть чат"}
            />
          </div>
        )}
        {permissions.viewRequisitionLogs && (
          <ButtonBase secondary medium onClick={toggleOpenLogModal}>
            История действий
          </ButtonBase>
        )}
      </div>
      {permissions.viewRequisitionLogs && (
        <Logs
          isOpen={isLogsModalOpen}
          closeLogsBlock={() => setIsLogsModalOpen(false)}
          requisitionId={requisition.id}
        />
      )}
      {isOpenTakeToWorkModal && (
        <ConfirmationModal isOpen={isOpenTakeToWorkModal} action={handleTakeToWork} onClose={closeTakeToWorkModal}>
          <div style={{ fontSize: "1.125rem" }}>Взять заявку в работу?</div>
        </ConfirmationModal>
      )}
      {isOpenAddEmployerSlider && (
        <AddEmployeeSlide
          isOpen={isOpenAddEmployerSlider}
          closeAddPEmployeeBlock={closeAddEmployerSlide}
          addEmployees={handleAddEmployees}
          buttonText={requisition.executor ? "Передать" : "Назначить"}
          returnOneData
        />
      )}
    </div>
  );
};

export default React.memo(RequisitionInfo);
