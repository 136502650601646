import React, { SetStateAction, useState } from "react";
import Axios from "axios";
import cn from "classnames";

import PopoverOverlay from "components/UI/molecules/PopoverOverlay/PopoverOverlay";
import PopoverContent from "./components/PopoverContent/PopoverContent";

import { VIEW_MANUFACTURING_JOURNAL_PROGRESS_APPROVE } from "constants/permissions/manufacturingPermissions";
import { IStockUsingMaterial } from "types/interfaces/Materials";

import usePermission from "hooks/usePermission";
import { splitThousands } from "utils/splitThousands";
import getShortFullName from "utils/getShortFullName";

import Check from "images/icons/Check";
import CheckFillRound from "images/icons/CheckFillRound";

import styles from "./RelatedMaterial.module.scss";

interface IProps {
  data: IStockUsingMaterial;
  objectId: string;
  loadEx: () => void;
  loadFilteredEx: () => void;
  expenditureId: number;
  setLocalAddedAmount: React.Dispatch<SetStateAction<number>>;
  increaseLocalApprovals: () => void;
}

const RelatedMaterial = ({
  data,
  objectId,
  loadEx,
  loadFilteredEx,
  expenditureId,
  setLocalAddedAmount,
  increaseLocalApprovals,
}: IProps) => {
  const haveApproveJournalProgressPermission = usePermission(
    VIEW_MANUFACTURING_JOURNAL_PROGRESS_APPROVE
  );

  const [count, setCount] = useState(
    data?.confirm_count ? +data?.confirm_count : 0
  );
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const countHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      isNaN(+event.target.value) &&
      event.target.value[event.target.value.length - 1] !== "."
    )
      return;
    setCount(+event.target.value);
  };

  const handleApprove = async () => {
    Axios.patch(
      `/building/${objectId}/estimate/items/${expenditureId}/stockusingmaterials/${data.id}/`,
      {
        confirm_count: count,
      }
    ).then(() => {
      loadEx();
      setLocalAddedAmount((prev) => prev + (data.amount / +data.count) * count);
      setCount(data?.confirm_count ? +data?.confirm_count : 0);
      increaseLocalApprovals();
    });
  };

  const computedConfirmedPrice = Number(data?.confirm_estimate_amount) || 0;

  if (!data) return null;

  return (
    <div className={styles.container}>
      <div className={cn(styles.rowItem, styles.nameBlock)}>
        <div
          className={styles.name}
          title={data.stock_using_material?.product_building.name}
        >
          {data.stock_using_material?.product_building.name}
        </div>
      </div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>
        {data.stock_using_material?.product_building.measure}
      </div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>
        {data.count}
      </div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>
        {Number(data?.confirm_count) ? (
          data.confirm_count
        ) : haveApproveJournalProgressPermission ? (
          <input
            name={``}
            className={cn(styles.input, {
              [styles.inputActive]: Number(count),
            })}
            value={count}
            onChange={countHandler}
          />
        ) : (
          "0"
        )}
      </div>
      <div className={cn(styles.rowItem, styles.centeredContent)}>
        {Number(data.confirm_count) ? (
          <>{getShortFullName(data.confirm_user)}</>
        ) : null}
        {!Number(data.confirm_count) && Number(count) ? (
          <div className={styles.approveButton} onClick={handleApprove}>
            <Check />
            Принять
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={cn(styles.rowItem, styles.price)}>
        {(Number(data?.confirm_estimate_amount) && !haveApproveJournalProgressPermission) ? (
          <span className={styles.priceCentered}>
            {splitThousands(computedConfirmedPrice, false, false)}
          </span>
        ) : (Number(data?.confirm_estimate_amount) && haveApproveJournalProgressPermission) ? (
          <PopoverOverlay
            isOpen={isOpenPopover}
            onInteraction={(event) => setIsOpenPopover(event)}
            content={
              <PopoverContent
                setIsOpenPopover={setIsOpenPopover}
                buildingId={+objectId}
                expenditureId={expenditureId}
                stockId={data.id}
                loadFilteredEx={loadFilteredEx}
              />
            }
            placement={"top"}
            popoverBorderColor={"primary"}
          >
            <span className={styles.priceCentered}>
              {splitThousands(computedConfirmedPrice, false, false)}
            </span>
          </PopoverOverlay>
        ) : (
          "-"
        )}
        {data.estimate_amount && (
          <CheckFillRound width="22" color={"#8FE532"} colorFill={"none"} />
        )}
        {!data.estimate_amount && !Number(computedConfirmedPrice) && (
          <CheckFillRound width="22" color={"#888888"} colorFill={"none"} />
        )}
        {!data.estimate_amount && !!Number(computedConfirmedPrice) && (
          <CheckFillRound width="22" color={"#00C4FF"} colorFill={"none"} />
        )}
      </div>
    </div>
  );
};

export default RelatedMaterial;
