import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import { Spinner } from "components/UI/Spinner/Spinner";
import React, { useCallback } from "react";
import { useCurrentTasksList } from "../../hooks/useCurrentTasksListData";
import { useDeleteTask } from "../../hooks/useDeleteTask";
import { useTasksFilter } from "../../hooks/useTasksFilter";
import EmptyTasksList from "../EmptyTasksList/EmptyTasksList";
import TaskRow from "../TaskRow/TaskRow";
import TaskTableHead from "../TaskTableHead/TaskTableHead";
import styles from "./ListOfTasks.module.scss";
import { useTasksModal } from "../../hooks/useTasksModal";

type propsType = {
  listId: number;
  deadline_end?: string;
  deadline_start?: string;
  building_id?: string;
};

function ListOfTasks({ listId, deadline_end, deadline_start }: propsType) {
  const { filters, filtersHandler } = useTasksFilter();

  const memoizedFilters = React.useMemo(() => {
    return { ...filters, deadline_end, deadline_start };
  }, [filters, deadline_end, deadline_start]);

  const { tasksByCurrentList, loadMoreTasksByListHandler, isCurrentTasksLoading } = useCurrentTasksList({
    listId,
    filters: memoizedFilters,
  });

  const { deleteTaskHandler } = useDeleteTask({ variant: "inLists", list_id: listId });

  const { openEditModal, openViewModal } = useTasksModal();

  const handleOpen = useCallback(
    (id: number) => {
      openViewModal({
        placementType: "inLists",
        list_id: listId,
        id,
      });
    },
    [listId]
  );

  const handleEditTask = useCallback(
    (id: number) => {
      openEditModal({
        placementType: "inLists",
        list_id: listId,
        id,
      });
    },
    [listId]
  );

  return (
    <>
      <TaskTableHead onChangeFilters={filtersHandler} className={styles.head} />
      {isCurrentTasksLoading && <Spinner isSmall className={styles.spinner} />}
      {!isCurrentTasksLoading && !tasksByCurrentList.results.length && <EmptyTasksList />}
      {!!(!isCurrentTasksLoading && tasksByCurrentList.results.length) &&
        tasksByCurrentList.results.map((el) => (
          <TaskRow
            key={el.id}
            {...el}
            executor={el.executor_user}
            dateEnd={el.deadline}
            dateStart={el.created_at}
            commentsCount={el.task_comments?.length}
            onDelete={deleteTaskHandler}
            onEdit={handleEditTask}
            handleOpen={() => handleOpen(el.id)}
          />
        ))}
      <ShowMoreButton
        handleAdd={loadMoreTasksByListHandler}
        showedCount={tasksByCurrentList.results.length}
        isExists={!isCurrentTasksLoading}
        allCount={tasksByCurrentList.count}
      />
    </>
  );
}

export default React.memo(ListOfTasks);
