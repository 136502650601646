import { useCallback, useEffect, useMemo, useState } from "react";
import { compose } from "redux";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import {
  loadSections,
  loadSectionsWithConfirmedChildStatus,
  loadSectionsWithNewChildStatus,
  resetSectionsAction,
  sectionsSelector,
  sectionsWithConfirmedChildStatusSelector,
  sectionsWithNewChildStatusSelector,
} from "../../../../redux/modules/common/building/sections/sections";
import { userSelector } from "../../../../redux/modules/common/auth";
import { detailDataSelector } from "../../../../redux/modules/common/building/object/nowObject";
import { errorCatcher } from "../../../../utils/errorCatcher";

import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../constants";

const useGetSections = ({ buildingId, estimateStateId, sectionId, subsectionId, status, chapterId }) => {
  const dispatch = useDispatch();
  const sectionsWithNewChildStatus = useSelector(sectionsWithNewChildStatusSelector);
  const sectionsWithConfirmedChildStatus = useSelector(sectionsWithConfirmedChildStatusSelector);
  const sections = useSelector(sectionsSelector);

  const user = useSelector(userSelector);
  const building = useSelector(detailDataSelector);
  const userIsResponsibleEmployee = user?.id === building?.responsible_estimate?.id;

  const consolidateStateWithoutChapter = estimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE && !chapterId;

  const [areLoading, setAreLoading] = useState(true);

  const requestParams = useMemo(() => {
    const result = { buildingId, estimateStateId, parentId: sectionId };
    if (estimateStateId === ESTIMATE_STATES_IDS.CONSOLIDATE) result.chapterId = chapterId;
    return result;
  }, [buildingId, chapterId, estimateStateId, sectionId]);

  const getSectionsByStatus = useCallback(async () => {
    if (status === ESTIMATE_ITEM_STATUSES.NEW) {
      await compose(dispatch, loadSectionsWithNewChildStatus)(requestParams);
    } else if (status === ESTIMATE_ITEM_STATUSES.CONFIRMED) {
      await compose(dispatch, loadSectionsWithConfirmedChildStatus)(requestParams);
    }
  }, [status, requestParams]);

  const getSections = useCallback(async () => {
    const estimateStateIsDraftOrProduction =
      estimateStateId === ESTIMATE_STATES_IDS.DRAFT || estimateStateId === ESTIMATE_STATES_IDS.PRODUCTION;

    if (estimateStateIsDraftOrProduction || !userIsResponsibleEmployee) {
      await compose(dispatch, loadSections)(requestParams);
    } else if (status) {
      await getSectionsByStatus();
    } else {
      await compose(dispatch, loadSectionsWithNewChildStatus)(requestParams);
      await compose(dispatch, loadSectionsWithConfirmedChildStatus)(requestParams);
    }
  }, [estimateStateId, userIsResponsibleEmployee, status, requestParams, getSectionsByStatus]);

  const notThrowedGetSections = useCallback(() => {
    return getSections().catch(errorCatcher);
  }, [getSections]);

  const resetStates = useCallback(() => {
    setAreLoading(true);
    compose(dispatch, resetSectionsAction)();
  }, []);

  const allSections = useMemo(() => {
    if (sections) return sections;

    const result = [];
    if (sectionsWithConfirmedChildStatus) result.push(...sectionsWithConfirmedChildStatus);
    if (sectionsWithNewChildStatus) result.push(...sectionsWithNewChildStatus);
    return result;
  }, [sectionsWithNewChildStatus, sectionsWithConfirmedChildStatus, sections]);

  useEffect(() => {
    if (subsectionId || !estimateStateId || !user || !building || consolidateStateWithoutChapter) return;
    resetStates();
    getSections()
      .then(() => setAreLoading(false))
      .catch((e) => {
        if (!e instanceof axios.Cancel) errorCatcher(e);
      });
  }, [sectionId, status, estimateStateId, buildingId, subsectionId, building, chapterId, user]);

  return { areLoading, refreshSections: notThrowedGetSections, allSections };
};

export default useGetSections;
