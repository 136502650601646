import Axios from 'axios'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectedProductsSelector, selectedSubsectionsSelector, shareEstimateItems } from '../../../../../redux/modules/common/building/process'
import OldSelect from '../../../../UI/atoms/Select/OldSelect'
import ButtonBase from '../../../../UI/atoms/ButtonBase'
import SliderModal from '../../../../UI/atoms/SliderModal'
import InputBase from '../../../../UI/atoms/InputBase'
import styles from "./ProjectSharingBar.module.scss"
import arrow from "./arrow.svg"
import cn from "classnames"
import TabBarNotLinks from '../../../../../elements/navigation/TabBar/TabBarNotLinks'
import { message } from 'antd'

function ProjectSharingBar({ isExists }) {
    const { objectId } = useParams()
    const dispatch = useDispatch()
    const [isModal, setIsModal] = React.useState(false)
    const [typeOfContractors, setTypeOfContractors] = React.useState("outer")//"inner"|"outer"
    const [selectedCOntractor, setSelectedContractor] = React.useState(null)

    const [contractors, setContractors] = React.useState([])
    const formattedContractors = React.useMemo(() => {
        return contractors?.map(el => ({
            id: el.id,
            name: el.id,
            label: el.name
        }))
    }, [contractors, typeOfContractors])

    const selectedProducts = useSelector(selectedProductsSelector)
    const selectedSubsections = useSelector(selectedSubsectionsSelector)
    const selectedCount = React.useMemo(() => {
        return selectedSubsections.length + selectedProducts.length
    }, [selectedProducts, selectedSubsections])

    React.useEffect(() => {
        if (typeOfContractors === "outer") {
            Axios.get(`/building/${objectId}/contractors/`).then(r => setContractors(r.data.results?.map(el => ({ ...el.entity }))))
        }
        if (typeOfContractors === "inner") {
            Axios.get(`/entities/`).then(r => setContractors(r.data.results))
        }
    }, [objectId, typeOfContractors])

    React.useEffect(() => {
        setSelectedContractor(null)
    }, [typeOfContractors])

    const submitHandler = () => {
        dispatch(shareEstimateItems(objectId, selectedCOntractor?.id))
        setIsModal(false)
    }

    const selectSubmitHandler = (id) => {
        dispatch(shareEstimateItems(objectId, id))
    }

    const selectHandler = (id) => {
        const index = contractors.findIndex(el => el.id == id)
        setSelectedContractor(contractors[index])
    }

    const emptyClickHandler = (e) => {
        e.preventDefault()
        if (!formattedContractors?.length) {
            message.error("Сначала добавьте подрядчика")
        }
    }

    if (!isExists) return null

    return (
        <div className={cn(styles.bar, { [styles.activeBar]: selectedCount })}>
            <span>Выбрано разделов: {selectedCount ? <span className={styles.selectedCount}>{selectedSubsections.length}</span> : "-"}</span>
            <span>Выбрано записей: {selectedCount ? <span className={styles.selectedCount}>{selectedProducts.length}</span> : "-"}</span>
            <div>
                <span>Действия над выбранными:</span>
                <div className={styles.selectWrapper} onClick={emptyClickHandler}>
                    <OldSelect
                        className={cn(styles.select, { [styles.disabledSelect]: !selectedCount })}
                        options={formattedContractors}
                        placeholder="Передать подрядчику"
                        disabled={!selectedCount}
                        onClick={selectSubmitHandler}
                        optionsBlockClassName={styles.optionBlock}
                        icon={arrow}
                    />
                </div>

                {/* <ButtonBase
                    disabled={!selectedCount}
                    onClick={() => setIsModal(true)}
                    className={cn(styles.trigger, { [styles.disabledSelect]: !selectedCount })}
                >
                    Передать исполнителю
                </ButtonBase> */}
                {/* <SliderModal
                    isOpen={isModal}
                    closeHandler={() => setIsModal(false)}
                >
                    <div className={styles.modal}>
                        <h2 className={styles.title}>Передать исполнителю</h2>
                        <p className={styles.subtitle}>Выберите компанию/организацию, которой вы передаете выбранные позиции из сметы работ</p>
                        <TabBarNotLinks
                            tabs={[{ text: "Внешние", id: "outer" }, { text: "Внутренние", id: "inner" }]}
                            activeId={typeOfContractors}
                            onClick={setTypeOfContractors}
                            className={styles.tabs}
                        />
                        <p className={styles.choose}>Выбрать компанию</p>
                        <OldSelect
                            className={styles.input}
                            label="Наименование компании"
                            options={formattedContractors}
                            placeholder="Выберите исполнителя"
                            disabled={!selectedCount}
                            onClick={selectHandler}
                            defaultValue={selectedCOntractor?.id}
                        />
                        <InputBase
                            className={styles.input}
                            disabled
                            label="ФИО контактного лица"
                            value={selectedCOntractor?.contact_manager?.first_name ? selectedCOntractor?.contact_manager?.first_name + " " + selectedCOntractor?.contact_manager?.last_name : "Не указано"}
                        />
                        <InputBase disabled label="ИНН" value={selectedCOntractor?.itn} className={styles.input} />
                        <InputBase disabled label="Email" value={selectedCOntractor?.contact_manager?.email || "Не указано"} className={styles.input} />
                        <div className={styles.btns}>
                            <ButtonBase disabled={!selectedCount || !selectedCOntractor} onClick={submitHandler}>Подтвердить назначение</ButtonBase>
                            <ButtonBase buttonPrimaryEmpty onClick={() => setIsModal(false)}>Отменить</ButtonBase>
                        </div>
                    </div>
                </SliderModal> */}
            </div>
        </div>
    )
}

export default ProjectSharingBar