import React from "react";

import Breadcrumbs from "./components/Breadcrumbs";
import Calendar from "./components/Calendar/Calendar";
import FileStorageButton from "./components/FileStorageButton/FileStorageButton";
import Menu from "./components/Menu";
import NewTaskButton from "./components/NewTaskButton/NewTaskButton";
import Notification from "./components/Notification/Notification";
import Profile from "./components/Profile";

import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import styles from "./index.module.scss";

const Header = () => {
  return (
    <div className={styles.container}>
      <Menu />
      <Breadcrumbs />
      {isAllModulesAllowed() && (
        <>
          <FileStorageButton />
          <NewTaskButton />
          <Calendar />
        </>
      )}
      <Notification />
      <Profile />
    </div>
  );
};

export default Header;
