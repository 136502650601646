import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo
} from 'react'
import Xarrow from "react-xarrows";

import {
  DIAGRAM_ARROW_CONFIG,
  HIGHLIGHT_COLOR,
  INTERVAL_MAX_Z_INDEX,
  INTERVAL_TYPES,
  SharedBraceStatuses,
  TAILS_OFFSET_COMPENSATION_REM,
  TAILS_WIDTH_COMPENSATION_REM,
} from "../../../../constants";
import SharedBrace from "./components/SharedBrace/SharedBrace";
import ConnectPointsWrapper from "./components/ConnectPointsWrapper/ConnectPointsWrapper";

import { IDiagramIntervalLink, useIntervalLinks } from "../../useIntervalLinks";
import { useBranchHoverZIndex } from "../../useBranchHoverZIndex";

import styles from "../../Month.module.scss";
import cn from "classnames";
import { useDispatch } from "react-redux";
import {
  dropHighlightRelatedIntervals,
  highlightRelatedIntervals, setArrowHash
} from '../../../../../../../redux/modules/common/building/manufacturing/thunks'

export interface IDiagramIntervalProps {
  day: number;
  daysLength: number;
  unitMultiplier: number;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  onMouseMove?: MouseEventHandler;
  children: React.ReactNode;
  wrapperStyles: Object;
  offsetLeft: number;
  hover: boolean;
  setHover: (isHover: boolean) => void;
  intervalWrapperRef: React.RefObject<HTMLDivElement>;
  workStatus?: keyof typeof SharedBraceStatuses;
  isShared?: boolean;
  isFromProvider?: boolean;
  isCardOpen?: boolean;
  type: keyof typeof INTERVAL_TYPES;
  intervalId?: number;
  projectId?: number | null;
  startDate?: string;
}

const DiagramInterval: React.FC<IDiagramIntervalProps> = ({
  day,
  daysLength,
  unitMultiplier,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  children,
  wrapperStyles,
  offsetLeft,
  hover,
  setHover,
  intervalWrapperRef,
  workStatus,
  isShared,
  isFromProvider,
  isCardOpen,
  type,
  intervalId,
  projectId,
  startDate,
}) => {
  const {
    isLinkingEnabled,
    handleIntervalClick,
    position,
    handleCreateIntervalLink,
    handleDragStart,
    handleDragging,
    arrowsStartsWithCurrentIntervalId,
    beingDraggedArrows,
    projectArrows,
    isSomeArrowBeingDragged,
    isIntervalHighlighted,
    highlightedArrows,
    arrowHash
  } = useIntervalLinks({
    intervalWrapperRef,
    toIntervalId: intervalId,
    projectId,
    startDate,
  });
  const dispatch = useDispatch();

  const isAddingArrowAllowed = type === INTERVAL_TYPES.plan && isLinkingEnabled;

  const {
    increaseBranchZIndex,
    decreaseBranchZIndex,
    isBranchZIndexIncreased,
  } = useBranchHoverZIndex(intervalWrapperRef);

  const intervalLeftRem = (day - 1) * unitMultiplier;
  const intervalWidthRem = Math.max(daysLength, 1) * unitMultiplier;

  const mergedWrapperStyles = {
    left: `calc(${
      intervalLeftRem - TAILS_OFFSET_COMPENSATION_REM
    }rem + ${offsetLeft}px)`,
    width: `${intervalWidthRem + TAILS_WIDTH_COMPENSATION_REM}rem`,
    ...wrapperStyles,
  };

  const arrowAcceptElementStyles = {
    width: `${intervalWidthRem + TAILS_WIDTH_COMPENSATION_REM - .2}rem`,
  };

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement | SVGElement, MouseEvent>) => {
      !isLinkingEnabled && !isBranchZIndexIncreased && increaseBranchZIndex();
      !hover && setHover(true);
      isLinkingEnabled &&
        dispatch(highlightRelatedIntervals({ intervalId, projectId }));
      if (onMouseEnter) onMouseEnter(e);
    },
    [
      onMouseEnter,
      hover,
      isBranchZIndexIncreased,
      increaseBranchZIndex,
      isLinkingEnabled,
    ]
  );

  const handleMouseLeave = useCallback(
    (e: React.MouseEvent<HTMLDivElement | SVGElement, MouseEvent>) => {
      !isLinkingEnabled && !isCardOpen && decreaseBranchZIndex();
      setHover(false);
      isLinkingEnabled && dispatch(dropHighlightRelatedIntervals());
      if (onMouseLeave) onMouseLeave(e);
    },
    [onMouseLeave, decreaseBranchZIndex, isCardOpen, isLinkingEnabled]
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement | SVGElement, MouseEvent>) => {
      if (!hover) setHover(true);
      if (onMouseMove) onMouseMove(e);
    },
    [onMouseMove, hover]
  );

  const handlePreventDefault = useCallback((e: Event) => {
    e.preventDefault();
  }, []);

  const isChainHighlighted = useMemo(
    () => hover || isIntervalHighlighted,
    [hover, isIntervalHighlighted]
  );

  useEffect(() => {
    dispatch(setArrowHash(Math.random()));
  }, []);

  return (
    <>
      <div
        ref={intervalWrapperRef}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        className={styles.diagramIntervalWrapper}
        style={mergedWrapperStyles}
        onDragOver={handlePreventDefault}
        onClick={handleIntervalClick}
        onMouseUp={handleIntervalClick}
      >
        <div
          className={styles.arrowAcceptElement}
          id={intervalId}
          style={arrowAcceptElementStyles}
        />
        {children}
        {workStatus && <SharedBrace status={workStatus} />}

        <ConnectPointsWrapper
          intervalId={intervalId}
          isAddingArrowAllowed={isAddingArrowAllowed}
          hover={hover}
          position={position}
          handleDragEnd={handleCreateIntervalLink}
          handleDragStart={handleDragStart}
          handleDragging={handleDragging}
          intervalWrapperRef={intervalWrapperRef}
          projectId={projectId}
          beingDraggedArrows={beingDraggedArrows}
          projectArrows={projectArrows}
          isLinkingEnabled={isLinkingEnabled}
          isSomeArrowBeingDragged={isSomeArrowBeingDragged}
          startDate={startDate}
        />
      </div>
      {isLinkingEnabled &&
        arrowsStartsWithCurrentIntervalId.filter(x => document.getElementById(x.from_interval?.toString()) && document.getElementById(x.to_interval?.toString())).map((arrow: IDiagramIntervalLink) => {
          const isArrowHighlighted = highlightedArrows.indexOf(arrow.id) !== -1;
          return (
            <Xarrow
              {...DIAGRAM_ARROW_CONFIG}
              lineColor={
                isChainHighlighted || isArrowHighlighted
                  ? HIGHLIGHT_COLOR
                  : DIAGRAM_ARROW_CONFIG.lineColor
              }
              headColor={
                isChainHighlighted || isArrowHighlighted
                  ? HIGHLIGHT_COLOR
                  : DIAGRAM_ARROW_CONFIG.headColor
              }
              zIndex={
                isChainHighlighted || isArrowHighlighted || !!arrow.delay_day
                  ? INTERVAL_MAX_Z_INDEX + 1
                  : 0
              }
              start={arrow.from_interval?.toString()}
              end={arrow.to_interval?.toString()}
              key={`${arrow.from_interval}-${arrow.to_interval}-${arrowHash}`}
              labels={{
                end: !!arrow.delay_day ? (
                  <div
                    className={cn(styles.breakBubble, {
                      [styles.breakBubbleHighlighted]:
                        isChainHighlighted || isArrowHighlighted,
                    })}
                    onMouseOver={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {arrow.delay_day}
                  </div>
                ) : undefined,
              }}
              passProps={{
                onMouseOver: handleMouseEnter,
                onMouseLeave: handleMouseLeave,
              }}
            />
          );
        })}
    </>
  );
};

export default React.memo(DiagramInterval);
