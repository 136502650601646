import React, { useCallback } from "react";
import cn from "classnames";

import AddEmployeeSlide from "./../../../UI/molecules/AddEmployeeSlide/OldVersion";
import { AddButton } from "../../../UI/AddButton/AddButton";
import EmployeesElem from "components/UI/molecules/EmployeesElem";

import styles from "./EditProject.module.scss";


function EditProjectApprovals(props) {
  const { approvals, deleteAgreementsEmployee, handleAddChainEmployee, urlId, handleChangeAgreementsEmployees } = props;

  const [isModal, setIsModal] = React.useState(false);
  const [type, setType] = React.useState(null);

  const addEmployeeOpen = useCallback((type) => {
    setType(type);
    setIsModal(true);
  }, []);

  const addEmployees = useCallback((employees) => {
    handleChangeAgreementsEmployees(employees, type);
    setIsModal(false);
  }, [handleChangeAgreementsEmployees, type]);

  return (
    <div className={cn({ [styles.disabled]: props.isDisabled })}>
      <div className={styles.apprLine}>Заявки
        <AddButton onClick={() => addEmployeeOpen("requisitionApprovalEmployees")} />
      </div>
      <div className={styles.approvals}>
        {approvals?.requisitionApprovalEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={employee => deleteAgreementsEmployee(employee, "requisitionApprovalEmployees")}
            editPermission={true}
            addChainEmployee={item => handleAddChainEmployee(item, "requisitionApprovalEmployees", employee)}
            nextEmployee={approvals?.requisitionApprovalEmployees[index + 1]}
            isChain={approvals?.requisitionApprovalEmployees?.find(item => item?.position)}
            isCanChain={true}
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <div className={styles.apprLine}>Заказы
        <AddButton onClick={() => addEmployeeOpen("orderApprovalEmployees")} />
      </div>
      <div className={styles.approvals}>
        {approvals?.orderApprovalEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={employee => deleteAgreementsEmployee(employee, "orderApprovalEmployees")}
            editPermission={true}
            addChainEmployee={item => handleAddChainEmployee(item, "orderApprovalEmployees", employee)}
            nextEmployee={approvals?.orderApprovalEmployees[index + 1]}
            isChain={approvals?.orderApprovalEmployees?.find(item => item?.position)}
            isCanChain={true}
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <div className={styles.apprLine}>Оплата
        <AddButton onClick={() => addEmployeeOpen("paymentApprovalEmployees")} />
      </div>
      <div className={styles.approvals}>
        {approvals?.paymentApprovalEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={employee => deleteAgreementsEmployee(employee, "paymentApprovalEmployees")}
            editPermission={true}
            addChainEmployee={item => handleAddChainEmployee(item, "paymentApprovalEmployees", employee)}
            nextEmployee={approvals?.paymentApprovalEmployees[index + 1]}
            isChain={approvals?.paymentApprovalEmployees?.find(item => item?.position)}
            isCanChain={true}
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <div className={styles.apprLine}>Табель
        <AddButton onClick={() => addEmployeeOpen("timesheetApprovalEmployees")} />
      </div>
      <div className={styles.approvals}>
        {approvals?.timesheetApprovalEmployees?.map((employee, index) => (
          <EmployeesElem
            key={employee.id}
            employee={employee}
            styles={styles}
            deleteEmployee={employee => deleteAgreementsEmployee(employee, "timesheetApprovalEmployees")}
            editPermission={true}
            addChainEmployee={item => handleAddChainEmployee(item, "timesheetApprovalEmployees", employee)}
            nextEmployee={approvals?.timesheetApprovalEmployees[index + 1]}
            isChain={approvals?.timesheetApprovalEmployees?.find(item => item?.position)}
            isCanChain={true}
            index={index}
            buildingId={urlId}
            objectOnly
            isInApprovals
          />
        ))}
      </div>
      <AddEmployeeSlide
        returnOneData={true}
        isOpen={isModal}
        closeAddPEmployeeBlock={() => setIsModal(false)}
        addEmployees={addEmployees}
        objectOnly
        buildingId={urlId}
      />
    </div>
  );
}

export default React.memo(EditProjectApprovals);
