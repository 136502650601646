import axios from "axios";

import { IStockUsingMaterialsResponse } from "./types";

export const journalApi = {
  getStockUsingMaterialsPrice: (
    buildingId: number,
    expenditureId: number,
    stockId: number
  ) => {
    return axios.get<IStockUsingMaterialsResponse>(
      `/building/${buildingId}/estimate/items/${expenditureId}/stockusingmaterials/${stockId}/price/`
    );
  },

  patchStockUsingMaterialsConfirmPrice: (
    buildingId: number,
    expenditureId: number,
    stockId: number,
    amount: number
  ) => {
    return axios.patch(
      `/building/${buildingId}/estimate/items/${expenditureId}/stockusingmaterials/${stockId}/confirm_price/`,
      {
        amount,
      }
    );
  },
};
