import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import cn from "classnames";

import { ks6aTableMeasureSelector, setMeasure } from "../../../redux/modules/common/ks6a/ks6a";

import JournalDelivery from "./components/JournalDelivery";
import TemplateBase from "components/UI/templates/TemplateBase";
import JournalDone from "./components/JournalDone";
import TabBarNotLinks from "../../../elements/navigation/TabBar/TabBarNotLinks";
import MonthsYearsSlider from "../../UI/molecules/MonthsYearsSlider/MonthsYearsSlider";
import ButtonBase from "../../UI/atoms/ButtonBase";
import ForbiddenPage from "components/routes/components/ForbiddenPage/ForbiddenPage";
import Ks6a from "./components/Ks6a/Ks6a";
import MeasureBtn from "../../UI/atoms/MeasureBtn/MeasureBtn";
import MonthScrollBar from "../../UI/molecules/MonthScrollBar/MonthScrollBar";
import ListPageHeaderTemplate from "components/UI/templates/ListPageHeaderTemplate/ListPageHeaderTemplate";

import {
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE,
  VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_ADD_TICKET,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS,
  VIEW_MANUFACTURING_JOURNAL_PROGRESS_KS6A,
} from "constants/permissions/manufacturingPermissions";
import { useKs6aMonthScrollBar } from "./useKs6aMonthScrollBar";
import { measureSwitcher } from "../../../utils/measureSwitcher";
import usePermission from "hooks/usePermission";

import styles from "./Journal.module.scss";


const LAPTOP_WIDTH = 1440;

const KS6A_TAB_ID = "ks6a";
const DONE_TAB_ID = "done";
const DELIVERY_TAB_ID = "delivery";

const ALLOWED_TABS_IDS = [KS6A_TAB_ID, DONE_TAB_ID, DELIVERY_TAB_ID];

const Journal = ({ location, match, history }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { objectId } = params;
  const tableMeasure = useSelector(ks6aTableMeasureSelector);
  const haveViewAcceptancePermission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE);
  const haveViewProgressPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS);
  const haveAddTicketPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_ADD_TICKET);
  const haveViewKs6Permission = usePermission(VIEW_MANUFACTURING_JOURNAL_PROGRESS_KS6A);

  useEffect(() => {
    if (!haveViewAcceptancePermission && haveViewProgressPermission) {
      setTab(DONE_TAB_ID);
    }
  }, [haveViewAcceptancePermission, haveViewProgressPermission]);

  const tabs = useMemo(() => {
    const res = [];
    if (haveViewAcceptancePermission) res.push({ text: "Приемка", id: DELIVERY_TAB_ID });
    if (haveViewProgressPermission) res.push({ text: "Выполнение", id: DONE_TAB_ID });
    return res;
  }, [haveViewAcceptancePermission, haveViewProgressPermission]);

  const activeTabId = useMemo(() => {
    const partOfSectionPath = location.pathname.replace(match.url, "");
    return ALLOWED_TABS_IDS.find((id) => `/${id}` === partOfSectionPath) || DELIVERY_TAB_ID;
  }, [location.pathname, match.url]);

  const setTab = useCallback(
    (tabId) => {
      history.push(`${match.url}/${tabId}`);
    },
    [history, match.url]
  );

  const [date, setDate] = useState(moment());
  const year = date.year();
  const month = date.month();
  const { handleYearChange: handleKs6aYearChange, setTableScrollPosition, onArrowClick, year: ks6aYear, tableViewWidthPercent,
    tableScrollPosition } = useKs6aMonthScrollBar(year);

  const [isShowAddPlan, setIsShowAddPlan] = useState(false);

  const measureSwitchHandler = useCallback(() => {
    compose(dispatch, setMeasure, measureSwitcher)(tableMeasure);
  }, [tableMeasure]);

  useEffect(() => {
    if (!activeTabId) setTab(DELIVERY_TAB_ID);
  }, []);

  if (!haveViewAcceptancePermission && !haveViewProgressPermission) {
    return (
      <TemplateBase>
        <ForbiddenPage />
      </TemplateBase>
    );
  }

  return (
    <TemplateBase
      contentClassName={cn({
        [styles.templateNarrow]: activeTabId === KS6A_TAB_ID,
      })}
    >
      <ListPageHeaderTemplate className={styles.headerTemplate}>
        <header
          className={cn(styles.header, {
            [styles.headerNarrow]: activeTabId === KS6A_TAB_ID,
          })}
        >
          {(haveViewAcceptancePermission || haveViewProgressPermission) && (
            <TabBarNotLinks activeId={activeTabId} onClick={setTab} tabs={tabs} />
          )}
          {activeTabId === KS6A_TAB_ID ? (
            <MonthScrollBar
              className={styles.dateSlider}
              year={ks6aYear}
              handleYearChange={handleKs6aYearChange}
              tableViewWidthPercent={tableViewWidthPercent}
              tableScrollPosition={tableScrollPosition}
              setTableScrollPosition={setTableScrollPosition}
              onArrowClick={onArrowClick}
              showArrows
            />
          ) : (
            <MonthsYearsSlider className={styles.dateSlider} date={date} onChange={setDate} />
          )}
          {activeTabId === KS6A_TAB_ID && <MeasureBtn measure={tableMeasure} onMeasureChange={measureSwitchHandler} className={styles.measureButton}/>}
          {[KS6A_TAB_ID, DONE_TAB_ID].includes(activeTabId) && haveViewKs6Permission && (
            <ButtonBase
              className={cn(styles.button, styles.ks6aButton)}
              small={window.innerWidth <= LAPTOP_WIDTH}
              primary={activeTabId === KS6A_TAB_ID}
              secondary={activeTabId === DONE_TAB_ID}
              onClick={() => setTab(KS6A_TAB_ID)}
            >
              КС-6а
            </ButtonBase>
          )}
          {activeTabId === DELIVERY_TAB_ID && haveAddTicketPermission && (
            <ButtonBase
              className={styles.button}
              onClick={() => setIsShowAddPlan(true)}
              small={window.innerWidth <= LAPTOP_WIDTH}
              secondary
            >
              Добавить запись
            </ButtonBase>
          )}
        </header>
      </ListPageHeaderTemplate>
      <JournalDelivery
        isOpen={activeTabId === DELIVERY_TAB_ID}
        objectId={objectId}
        month={month + 1}
        year={year}
        isShowAddPlan={isShowAddPlan}
        setIsShowAddPlan={setIsShowAddPlan}
        onClickAddPlan={() => setIsShowAddPlan(true)}
      />
      <JournalDone isOpen={activeTabId === DONE_TAB_ID} objectId={objectId} month={month + 1} year={year} />
      {activeTabId === KS6A_TAB_ID && haveViewKs6Permission && <Ks6a buildingId={objectId} year={+year} />}
    </TemplateBase>
  );
};

export default Journal;