import React, { ChangeEventHandler, FC, MutableRefObject, ReactNode, useCallback, useRef } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

export const ALIGNS = {
  RIGHT: "right",
  LEFT: "left",
};

export interface ICheckboxProps {
  checked: boolean;
  onChange?: ChangeEventHandler;
  className?: string;
  checkboxClassName?: string;
  children?: ReactNode;
  disabled?: boolean;
  align?: string;
  testId?: string;
}

const Checkbox: FC<ICheckboxProps> = ({
  checked,
  onChange,
  className,
  checkboxClassName,
  children,
  disabled,
  align = ALIGNS.RIGHT,
  testId,
}) => {
  const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const onChangeModifier: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (disabled || !onChange) return;
    onChange(e);
  };

  const onClickByLabel = useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.click();
  }, []);

  const text = <p className={cn(styles.text, styles[`${align}Align`])}>{children}</p>;

  return (
    <div className={cn(styles.checkboxLabel, className)} onClick={onClickByLabel}>
      {align === ALIGNS.LEFT && children && text}
      <div className={cn(styles.checkbox, "checkbox", checkboxClassName, { [styles.disabled]: disabled })}>
        {checked && (
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.49023 7.81004L6.50723 12.8173L14.5072 0.94043" stroke="#707070" strokeWidth="1.6" />
          </svg>
        )}
      </div>
      {align === ALIGNS.RIGHT && children && text}
      <input
        ref={inputRef}
        type="checkbox"
        className={styles.hidden}
        checked={checked}
        data-testid={testId}
        onChange={onChangeModifier}
      />
    </div>
  );
};

export default React.memo(Checkbox);
