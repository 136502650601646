import React from 'react';
import styles from './Ks6aWorks.module.scss'
import {
  ks6aTableMeasureSelector, ks6aTableOtherSelector, ks6aTableTotalSelector,
} from '../../../../../../../../../redux/modules/common/ks6a/ks6a'
import {costToRubs} from '../../../../constants'
import {useSelector} from 'react-redux'
import Ks6aWorkPopup from './components/Ks6aWorkPopup/Ks6aWorkPopup'
import {Ks6aListItemType} from '../../../../../../../../../redux/modules/common/ks6a/types'

export interface IKs6aWorksProps{
  list: Ks6aListItemType[]
}

const Ks6aWorks: React.FC<IKs6aWorksProps> = ({list}) => {
  const tableMeasure = useSelector(ks6aTableMeasureSelector)
  const tableTotal = useSelector(ks6aTableTotalSelector)
  const tableOther = useSelector(ks6aTableOtherSelector)
  return(
    <div className={styles.works}>
      <div className={styles.worksHeader}>
        <div className={styles.worksNumber}>№</div>
        <div className={styles.worksByOrderNumber}>По порядку</div>
        <div className={styles.worksByEstimateNumber}>Позиции по смете</div>
        <div className={styles.worksElements}>Конструктивные элементы и виды работ</div>
      </div>
      {list.map((work: Ks6aListItemType, index: number) => (
        <div className={styles.worksRow} key={index}>
          <div className={styles.worksRowByOrderNumber}>{index + 1}</div>
          <div className={styles.worksRowByEstimateNumber}>{work.number}</div>
          <div className={styles.worksRowElement}>
            <span>{work.name}</span>
            <Ks6aWorkPopup work={work} isHorizontal={index < 2 && list.length < 4}/>
          </div>
        </div>
      ))}
      <div className={styles.totalWrapper}>
        <div className={styles.total}>
          <div className={styles.totalLabel}>
            <span>Итого</span>
          </div>
          <div className={styles.totalCosts}>{costToRubs(tableTotal, tableMeasure)}</div>
          <div className={styles.otherLabel}>
            <span>Накладные и прочие расходы</span>
          </div>
          <div className={styles.otherCosts}>{costToRubs(tableOther, tableMeasure)}</div>
          <div className={styles.sumLabel}>
            <span>Всего</span>
          </div>
          <div className={styles.sumCosts}>{costToRubs(tableTotal + tableOther, tableMeasure)}</div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Ks6aWorks);