import React from 'react'
import styles from "./JournalTicketModal.module.scss"

function JornalDay({ day, time }) {
    return (
        <div className={styles.date}>
            <div className={styles.day}>{day}</div>
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-0.00012207 3.5H16.9999V14.5C16.9999 15.2956 16.6838 16.0587 16.1212 16.6213C15.5586 17.1839 14.7955 17.5 13.9999 17.5H2.99988C2.20423 17.5 1.44117 17.1839 0.878558 16.6213C0.315948 16.0587 -0.00012207 15.2956 -0.00012207 14.5L-0.00012207 3.5Z" fill="white" />
                <path d="M0.499878 4H16.4999V14.5C16.4999 15.163 16.2365 15.7989 15.7676 16.2678C15.2988 16.7366 14.6629 17 13.9999 17H2.99988C2.67157 17 2.34648 16.9353 2.04317 16.8097C1.73986 16.6841 1.46426 16.4999 1.23211 16.2678C0.76327 15.7989 0.499878 15.163 0.499878 14.5V4Z" stroke="#BABABA" />
                <path d="M3.99988 7H2.99988C2.72374 7 2.49988 7.22386 2.49988 7.5V8.5C2.49988 8.77614 2.72374 9 2.99988 9H3.99988C4.27602 9 4.49988 8.77614 4.49988 8.5V7.5C4.49988 7.22386 4.27602 7 3.99988 7Z" stroke="#BABABA" strokeDasharray="1 1" />
                <path d="M8.99988 6.5H7.99988C7.44759 6.5 6.99988 6.94772 6.99988 7.5V8.5C6.99988 9.05228 7.44759 9.5 7.99988 9.5H8.99988C9.55216 9.5 9.99988 9.05228 9.99988 8.5V7.5C9.99988 6.94772 9.55216 6.5 8.99988 6.5Z" fill="white" />
                <path d="M8.99988 7H7.99988C7.72374 7 7.49988 7.22386 7.49988 7.5V8.5C7.49988 8.77614 7.72374 9 7.99988 9H8.99988C9.27602 9 9.49988 8.77614 9.49988 8.5V7.5C9.49988 7.22386 9.27602 7 8.99988 7Z" stroke="#BABABA" />
                <path d="M13.9999 6.5H12.9999C12.4476 6.5 11.9999 6.94772 11.9999 7.5V8.5C11.9999 9.05228 12.4476 9.5 12.9999 9.5H13.9999C14.5522 9.5 14.9999 9.05228 14.9999 8.5V7.5C14.9999 6.94772 14.5522 6.5 13.9999 6.5Z" fill="white" />
                <path d="M13.9999 7H12.9999C12.7237 7 12.4999 7.22386 12.4999 7.5V8.5C12.4999 8.77614 12.7237 9 12.9999 9H13.9999C14.276 9 14.4999 8.77614 14.4999 8.5V7.5C14.4999 7.22386 14.276 7 13.9999 7Z" stroke="#BABABA" />
                <path d="M8.99988 11.5H7.99988C7.44759 11.5 6.99988 11.9477 6.99988 12.5V13.5C6.99988 14.0523 7.44759 14.5 7.99988 14.5H8.99988C9.55216 14.5 9.99988 14.0523 9.99988 13.5V12.5C9.99988 11.9477 9.55216 11.5 8.99988 11.5Z" fill="white" />
                <path d="M8.99988 12H7.99988C7.72374 12 7.49988 12.2239 7.49988 12.5V13.5C7.49988 13.7761 7.72374 14 7.99988 14H8.99988C9.27602 14 9.49988 13.7761 9.49988 13.5V12.5C9.49988 12.2239 9.27602 12 8.99988 12Z" stroke="#BABABA" />
                <path d="M3.99988 12H2.99988C2.72374 12 2.49988 12.2239 2.49988 12.5V13.5C2.49988 13.7761 2.72374 14 2.99988 14H3.99988C4.27602 14 4.49988 13.7761 4.49988 13.5V12.5C4.49988 12.2239 4.27602 12 3.99988 12Z" stroke="#BABABA" strokeDasharray="1 1" />
                <path d="M16.4991 4.19448H0.499878V3.42548C0.499878 2.08046 1.56767 0.980139 2.90008 0.927736V1.71188C2.90008 2.69078 3.69607 3.48718 4.67448 3.48718C5.65338 3.48718 6.44978 2.69078 6.44978 1.71188V0.925781H10.5492V1.71188C10.5492 2.69078 11.3456 3.48718 12.3245 3.48718C13.3034 3.48718 14.0998 2.69078 14.0998 1.71188V0.927771C15.4317 0.980685 16.4991 2.08079 16.4991 3.42548V4.19448Z" fill="#BABABB" />
                <path d="M15.9991 3.69448V3.42548C15.9991 2.53194 15.41 1.7734 14.5998 1.51784V1.71188C14.5998 2.5614 14.1317 3.30372 13.44 3.69448H15.9991ZM11.2089 3.69448C10.5172 3.30372 10.0492 2.5614 10.0492 1.71188V1.42578H6.94978V1.71188C6.94978 2.5614 6.48172 3.30372 5.79002 3.69448H11.2089ZM3.55938 3.69448C2.86795 3.30372 2.40008 2.5614 2.40008 1.71188V1.51756C1.5894 1.77284 0.999878 2.53161 0.999878 3.42548V3.69448H3.55938ZM16.9991 4.69448H-0.00012207V3.42548C-0.00012207 1.77144 1.34554 0.425781 2.99958 0.425781H3.40008V1.71188C3.40008 2.41508 3.97177 2.98718 4.67448 2.98718C5.37768 2.98718 5.94978 2.41508 5.94978 1.71188V0.425781H11.0492V1.71188C11.0492 2.41508 11.6213 2.98718 12.3245 2.98718C13.0277 2.98718 13.5998 2.41508 13.5998 1.71188V0.425781H13.9994C15.6534 0.425781 16.9991 1.77144 16.9991 3.42548V4.69448Z" fill="#BABABA" />
            </svg>
            <div className={styles.time}>в {time}</div>
        </div>
    )
}

export default JornalDay