import { RUBLE } from "utils/constant";
import { transformDigitToFinancial } from "./transformDigitToFinancial";
import { dropNonSignificantZeros } from "./dropNonSignificantZeros";

export const hasOnlyDigits = (value) => /^[0-9]*$/.test(value);

export const getFullNameString = (firstName, lastName, middleName) =>
  `${lastName || ""} ${firstName || ""} ${middleName || ""}`;

export const cutData = (data, num = 5) => (data ? data.slice(0, num) : null);

export const shortName = (user) => {
  let username = user?.last_name;

  if (user?.first_name && user.first_name !== "---") username += ` ${user.first_name[0]}.`;

  return username;
};

export const getShortPrice = (value) => {
  const valueNumber = Number.parseFloat(value);

  if (isNaN(valueNumber) || !value) return `0.00 ${RUBLE}`;

  if (Math.abs(value) >= 1000000000000) return `${Math.round(valueNumber / 1000000000000)} трлн.${RUBLE}`;
  if (Math.abs(value) >= 1000000000) return `${Math.round(valueNumber / 1000000000)} млрд.${RUBLE}`;
  if (Math.abs(value) >= 1000000) return `${Math.round(valueNumber / 1000000)} млн.${RUBLE}`;
  if (Math.abs(value) >= 1000) return `${Math.round(valueNumber / 1000)} тыс.${RUBLE}`;
  else return `${Number(value || 0).toFixed(2)} ${RUBLE}`;
};

export const getShortPriceObject = (value) => {
  const shortPrice = getShortPrice(value).split(" ");
  return { value: shortPrice[0], title: shortPrice[1] };
};

export const getPriceMeasureObject = (value) => {
  if (isNaN(value)) return;
  const absValue = Math.abs(value);

  let measure = 1;
  let label = `${RUBLE}`;

  if (absValue >= 1e3) {
    measure = 1e3;
    label = `тыс.${RUBLE}`;
  }
  if (absValue >= 1e6) {
    measure = 1e6;
    label = `млн.${RUBLE}`;
  }
  if (absValue >= 1e9) {
    measure = 1e9;
    label = `млрд.${RUBLE}`;
  }
  if (absValue >= 1e12) {
    measure = 1e12;
    label = `трлн.${RUBLE}`;
  }
  return { measure, label };
};

export const getUnifiedMeasurePrices = (...args) => {
  if (!args.length) return;
  const priceMeasureObjects = args.map(getPriceMeasureObject).sort((a, b) => b.measure - a.measure);
  const { measure: maxMeasure, label: maxLabel } = priceMeasureObjects[0];
  return args.map((value) => `${transformDigitToFinancial(value / maxMeasure, { withFloat: true, dropZeros: true })} ${maxLabel}`);
};

export const getShortPriceValute = (value) => {
  const valueNumber = Number.parseFloat(value);
  if (Math.abs(value) >= 1000000000) return `${(valueNumber / 1000000000).toFixed(1)} млрд.`;
  if (Math.abs(value) >= 1000000) return `${(valueNumber / 1000000).toFixed(1)} млн.`;
  if (Math.abs(value) >= 1000) return `${(valueNumber / 1000).toFixed(1)} тыс.`;
  else return Math.round(value * 10) / 10;
};
