import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { userSelector } from "redux/modules/common/auth";
import { loadExpenditure, loadShedules, shedulesSelector } from "redux/modules/common/building/shedules";
import { apiGetEstimateExpenditures } from "redux/modules/common/building/shedulesApi";
import { createTicket } from "redux/modules/common/building/tickets";
import { resetWorkersToInitialAction } from "redux/modules/common/building/workers";

import { EXPENDITURE_TYPES } from "utils/constant";
import { mapAddedToTicketMaterials } from "./utils";
import { useLocationArray } from "utils/hooks/useLocationArray";

export type materialType = {
  id: number;
  local_count?: number;
  using_ids?: number[];
};

type userType = {
  id: number;
  count?: number;
};

type submitDataType = {
  section_id: number;
  count: number;
  start_at: string;
  end_at: string;
  count_workers?: any[]; //ANY
  stock_using_materials?: any[]; //ANY
  expenditure_id?: number;
  title?: string;
};

type propsType = {
  addedMaterials?: materialType[] | any[];
  addedUsers?: userType[];
  objectId: number;
  clearMaterials: () => void;
  clearWorkers: () => void;
  handleClose: () => void;
  files?: File[];
  directlySection?: number;
  directlyWork?: number;
};

export const useProductIntervalForm = ({
  addedMaterials,
  addedUsers,
  objectId,
  clearMaterials,
  clearWorkers,
  handleClose,
  files,
  directlySection,
  directlyWork,
}: propsType) => {
  const dispatch = useDispatch();
  const locationArray = useLocationArray();

  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());
  const [count, setCount] = useState(0);
  const sections = useSelector(shedulesSelector);
  const user = useSelector(userSelector);
  const [activeSection, setActiveSection] = useState<number | null>(null);
  const [works, setWorks] = useState([]);
  const [activeWork, setActiveWork] = useState<number | null>(null);
  const [isChooseWork, setIsChooseWork] = useState(true);
  const [title, setTitle] = useState("");

  const handleSubmit = async () => {
    const data: submitDataType = {
      section_id: directlySection || (activeSection as number),
      count: count,
      start_at: dateStart.format("YYYY-MM-DD"),
      end_at: dateEnd.format("YYYY-MM-DD"),
      count_workers: [],
      stock_using_materials: mapAddedToTicketMaterials(addedMaterials),
    };
    if (activeWork) data.expenditure_id = activeWork;
    if (title) data.title = title;
    if (directlyWork) data.expenditure_id = directlyWork;

    if (addedUsers) {
      const count_workers = addedUsers.map((item) => ({ worker_id: item.id, count: item.count }));
      data.count_workers = count_workers;
    }
    await dispatch(createTicket(objectId, data, files, locationArray));
    await handleClose();
    clearForm();
  };

  const clearForm = () => {
    setActiveSection(null);
    setActiveWork(null);
    setIsChooseWork(true);
    setTitle("");
    setCount(0);
    setDateStart(moment());
    setDateEnd(moment());
    clearWorkers && clearWorkers();
    clearMaterials && clearMaterials();
  };

  const subSections =
    sections &&
    [].concat(
      ...sections?.sections?.map((section: any) => {
        //ANY
        return section?.subsections.concat(section.is_default ? section : []);
      })
    );

  const getObjectList = () => {
    return subSections && subSections.length > 0
      ? subSections.map((item: any) => ({ id: item.id, name: item.name, label: item.name })) //ANY
      : [];
  };

  const getWorksList = () => {
    return works && works.length > 0
      ? works.map((item: any) => ({ id: item.id, name: item.name, label: item.name })) //ANY
      : [];
  };

  const handleChangeSection = async (id: number | string) => {
    setActiveSection(+id);
    const works = await apiGetEstimateExpenditures({
      buildingId: objectId,
      params: { expenditure_type: "work", section: id, limit: 200 },
    });
    setWorks(works.results);
  };

  const handleChangeWorks = (id: number | string) => {
    setActiveWork(+id);
    dispatch(loadExpenditure(objectId, id));
  };

  useEffect(() => {
    dispatch(loadShedules(objectId, { expenditure_type: EXPENDITURE_TYPES.WORK }));
  }, [objectId]);

  useEffect(() => {
    return () => {
      compose(dispatch, resetWorkersToInitialAction)();
    };
  }, []);

  useEffect(() => {
    if (!isChooseWork) {
      setActiveWork(null);
    } else {
      setTitle("");
    }
  }, [isChooseWork]);

  return {
    handleChangeWorks,
    handleChangeSection,
    getWorksList,
    getObjectList,
    clearForm,
    handleSubmit,
    dateEnd,
    dateStart,
    setDateEnd,
    setDateStart,
    count,
    setCount,
    user,
    activeSection,
    activeWork,
    isChooseWork,
    setIsChooseWork,
    title,
    setTitle,
  };
};
