import React from "react";
import formatStringByPattern from "format-string-by-pattern";

import phone from "../../../../images/tel.svg";
import mail from "../../../../images/email.svg";
import { getFullNameString } from "../../../../utils/helpers";

import Modal from "../Modal";

import styles from "./ContactsModal.module.scss";

const ContactsModal = ({ isOpen, onClose, title, contacts }) => {
  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose} title={title}>
      <div className={styles.body}>
        <div className={styles.item}>
          <span>{getFullNameString(contacts.first_name, contacts.last_name, contacts.middle_name)}</span>
        </div>
        {contacts.phone_number && (
          <div className={styles.item}>
            <img src={phone} alt="иконка телефона" />
            <a className={styles.link} href={`tel:${contacts.phone_number}`}>
              {formatStringByPattern("+7(999)999-99-99")(contacts.phone_number)}
            </a>
          </div>
        )}
        {contacts.email && (
          <div className={styles.item}>
            <img src={mail} alt="иконка письма" />
            <a className={styles.link} href={`mailto:${contacts.email}`}>{contacts.email}</a>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(ContactsModal);
