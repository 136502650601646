import React from 'react'
import styles from "./SubTableRow.module.scss"
import { formatNumberWithMeasure } from "./../../../../../../../utils/formatNumberWithMeasure"

function SubTableRow({ name, measureProduct, count, amount, measureMoney }) {
    return (
        <div className={styles.container}>
            <div className={styles.field1}><div className={styles.dot}></div></div>
            <div>{name}</div>
            <div className={styles.center}>{measureProduct}</div>
            <div className={styles.center}>{count}</div>
            <div className={styles.money}>{formatNumberWithMeasure(amount, measureMoney)}</div>
        </div>
    )
}

export default SubTableRow