import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { SET_USER_LOGOUT } from "../../../../../../redux/modules/common/auth";

import useOnClickOutside from "hooks/useOnClickOutside";
import { useUrlModuleWithObjectId } from "utils/hooks/useUrlModuleWithObjectId";

import { localStorageLogoutCleanUp } from "../../../../../../utils/localStorageLogoutCleanUp";
import { isAllModulesAllowed } from "utils/isAllModulesAllowed";

import placeholderAvatar from "../../../../../../images/icons/navigation/placeholderAvatar.svg";

import styles from "./Profile.module.scss";

const Profile = ({ avatar }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = React.useRef(null);
  const history = useHistory();
  const urlAdapter = useUrlModuleWithObjectId();
  const exitPushUrl = isAllModulesAllowed() ? "/auth" : "/";

  const profileUserHandler = React.useCallback(() => {
    history.push(`/${urlAdapter}/profile`);
  }, [urlAdapter]);

  const exitProfileHandler = React.useCallback(() => {
    localStorageLogoutCleanUp();
    dispatch({ type: SET_USER_LOGOUT });
    history.push(exitPushUrl);
  }, [exitPushUrl]);

  const openCloseHandler = React.useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const closeHandler = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  useOnClickOutside(containerRef, closeHandler);

  return (
    <div onClick={openCloseHandler} className={styles.container} ref={containerRef}>
      {avatar ? (
        <img src={avatar} className={styles.avatar} alt="avatar" />
      ) : (
        <img className={styles.avatar} src={placeholderAvatar} alt="avatar" />
      )}
      {isOpen && (
        <div className={styles.popup}>
          <div onClick={profileUserHandler} className={styles.item}>
            Профиль пользователя
          </div>
          <div onClick={exitProfileHandler} className={styles.item}>
            Выйти
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Profile);
