import moment from "moment";

import { remToPx } from "utils/remToPx";
import isFullHD from "utils/isFullHD";
import { MonthArray } from "utils/constant";

import {
  ACCEPTED_MATERIAL,
  enumerateDaysBetweenDates,
  MATERIALS_TAB_ID,
  ON_STOCK_MATERIAL,
  PAYED_MATERIAL,
  PLANS_MATERIAL,
  PURCHASES_MATERIAL,
  STOCKLESS_MATERIAL,
  WORKS_TAB_ID,
} from "./constants";

import {
  ExpandedBranchesType,
  IDayElement,
  IDiagramMaterial,
  IDiagramPlannedSection,
  IDiagramPlans,
  IDiagramSectionsPlan,
  IDiagramWorks,
  IInterval,
  ILoadedChartData,
  IMonthArrayElement,
  IntervalDaysMapType,
  IProcessedBranch,
  IProcessedBranchElement,
  IProject,
  IProjectIntervalBase,
  IProjectMaterialsDataInterval,
  IProjectPlanInterval,
  IProjectWorkInterval,
  ISpittingTreeElement,
  IWeekIntervalBase,
  ManufacturingTabsType,
  ManufacturingViewType,
  MaterialsMapType,
  MaterialType,
  PlanMapType,
  ProjectEstimateType,
  WorkMapType,
} from "./types";

import {
  HALF_MONTH,
  MONTH,
  WEEK,
  YEAR,
} from "redux/modules/common/building/manufacturing/manufacturing";
import { getNearestYearsMonths } from "redux/modules/common/building/manufacturing/utils";

const REM = remToPx(1);

export interface IComputeLineYpxProps {
  clientY: number;
  calendarElement: HTMLElement;
  containerElement: HTMLElement;
}

export const computeLineYpx = ({
  clientY,
  calendarElement,
  containerElement,
}: IComputeLineYpxProps) => {
  const pxToDiscreteYPosition = (yValue: number) =>
    Math.max(Math.floor(yValue / (3 * REM)) * 3 * REM, 0);

  const Y = pxToDiscreteYPosition(
    calendarElement.scrollTop +
      clientY -
      containerElement.getBoundingClientRect().top
  );
  const availableY = pxToDiscreteYPosition(
    calendarElement.scrollHeight - 6 * REM
  );

  return Math.min(Y, availableY);
};

export interface IGetWeekBoundsProps {
  month: IMonthArrayElement;
  year: number | string;
}

export const getStartWeek = ({ month, year }: IGetWeekBoundsProps) =>
  month?.id === 0
    ? 1
    : moment()
        .year(+year)
        .month(month.id > 2 ? month.id - 2 : month.id - 1)
        .week();

export const getEndWeek = ({ month, year }: IGetWeekBoundsProps) =>
  month?.id === 11
    ? moment()
        .year(+year)
        .weeksInYear()
    : moment()
        .year(+year)
        .month(month.id < 10 ? month.id + 2 : month.id + 1)
        .week();

export const getUnitMultiplier = (chartViewMode: ManufacturingViewType) => {
  if (chartViewMode === MONTH || chartViewMode === WEEK)
    return isFullHD ? 2.5 : 2.135;
  if (chartViewMode === HALF_MONTH) return isFullHD ? 5 : 4.27;
  if (chartViewMode === YEAR) return isFullHD ? 6.24 : 5.318;
  return 2.5;
};

export const getDaysInYear = (year: number | string) =>
  moment()
    .year(+year)
    .isLeapYear()
    ? 366
    : 365;

export const getWeeksInYear = (year: number | string) =>
  moment()
    .year(+year)
    .weeksInYear();

export const monthMarkers = (startMonthElements: Node[]) => {
  const startMonth: number[] = [];
  Array.from(startMonthElements).map((item) => {
    startMonth.push((item as HTMLElement).offsetLeft / REM);
  });
  return startMonth;
};

export const getMonthInfo = (monthId: number) =>
  MonthArray.find((item: IMonthArrayElement) => item.id === monthId);

export const generateDaysForLine = (year: number | string) => {
  const elements: IDayElement[] = [];
  const data = [];
  for (let i = 0; i < 12; i++) {
    data.push({
      maxDay: moment()
        .year(+year)
        .month(i)
        .daysInMonth(),
      year: year,
      monthNumber: i + 1,
    });
  }

  data.forEach((x) => {
    for (let i = 1; i <= x.maxDay; i++) {
      const localMoment = moment(
        `${x.year}-${x.monthNumber}-${i}`,
        "YYYY-MM-DD"
      );
      const dayOfWeek = localMoment.day();
      elements.push({
        day: i,
        weekend: dayOfWeek === 0 || dayOfWeek === 6,
        today: moment().isSame(localMoment, "days"),
        month: x.monthNumber,
      });
    }
  });

  return elements;
};

export const generateWeeksForLine = (year: number) => {
  const elements = [];
  for (let i = 1; i <= moment().year(year).weeksInYear(); i++) {
    let localWeek = moment().year(year).week(i);
    let isNewMonth = false;
    if (i === 1) isNewMonth = true;
    if (moment().year(year).week(i).day(0).date() === 1) isNewMonth = true;
    if (
      moment().year(year).week(i).day(0).date() >
      moment().year(year).week(i).day(6).date()
    )
      isNewMonth = true;
    elements.push({
      week: localWeek,
      isNewMonth,
    });
  }
  return elements;
};

export const spittingTreeGenerationFn = ({
  actualProjects,
  expandedBranches,
  projectEstimate,
  tab,
}: {
  actualProjects: IProject[];
  expandedBranches: ExpandedBranchesType;
  projectEstimate: ProjectEstimateType;
  tab: ManufacturingTabsType;
}) => {
  if (!actualProjects?.length) return [];
  const newSpittingTree: ISpittingTreeElement[] = [];
  actualProjects?.map((project) => {
    newSpittingTree.push({
      id: project.id,
      name: project.name,
      lvl: 1,
      childCount: 0,
      data: project,
      collapsed: expandedBranches.has(project.id),
      count_work: project.works_count,
      count_material: project.products_count,
    });
    if (projectEstimate.has(`${project.id}_${tab}`)) {
      const projectTree = projectEstimate.get(`${project.id}_${tab}`);
      if (expandedBranches.has(project.id)) {
        projectTree.map((item) => {
          if (
            tab === WORKS_TAB_ID ||
            (tab === MATERIALS_TAB_ID && item.count_material)
          ) {
            newSpittingTree.push({
              id: item.id,
              name: item.name,
              lvl: 2,
              childCount: item.children?.length || 0,
              data: item,
              collapsed: expandedBranches.has(item.id),
              count_work: item.count_work || 0,
              count_material: item.count_material || 0,
            });
          }
          if (!expandedBranches.has(item.id)) {
            item.children?.length &&
              item.children.map((itemChild) => {
                const hasToBeShown =
                  tab === WORKS_TAB_ID ||
                  (tab === MATERIALS_TAB_ID && itemChild.count_material);
                if (!hasToBeShown) return;
                newSpittingTree.push({
                  id: itemChild.id,
                  name: itemChild.name,
                  lvl: 3,
                  childCount: itemChild.expenditures?.length || 0,
                  data: itemChild,
                  collapsed: expandedBranches.has(itemChild.id),
                  count_work: itemChild.count_work || 0,
                  count_material: itemChild.count_material || 0,
                });
                if (!expandedBranches.has(itemChild.id)) {
                  itemChild.expenditures?.map((itemExpenditure) => {
                    newSpittingTree.push({
                      id: itemExpenditure.id,
                      name: itemExpenditure.name,
                      lvl: 4,
                      childCount: 0,
                      data: itemExpenditure,
                      collapsed: false,
                    });
                  });
                }
              });
          }
        });
      }
    }
  });
  return newSpittingTree;
};

export const processDiagramWork = ({
  work,
  workMap,
  workDaysMap,
  entityId,
}: {
  work: IDiagramWorks | IProjectWorkInterval;
  workMap: WorkMapType;
  workDaysMap: IntervalDaysMapType;
  entityId: string | number;
}) => {
  let daysCount;
  if (work.start_at === work.end_at) {
    daysCount = 1;
    workDaysMap.set(`${entityId}_${work.start_at}`, true);
  } else {
    const days = enumerateDaysBetweenDates(
      moment(work.start_at),
      moment(work.end_at)
    );
    days.forEach((day) => workDaysMap.set(`${entityId}_${day}`, true));
    daysCount = days.length;
  }

  const currentItem = workMap.get(`${entityId}_${work.start_at}`);

  if (!currentItem || currentItem.days < daysCount) {
    workMap.set(`${entityId}_${work.start_at}`, {
      start: work.start_at,
      end: work.end_at,
      days: daysCount,
      data: work,
    });
  }
};

export const processDiagramPlan = ({
  plan,
  planMap,
  planDaysMap,
  entityId,
}: {
  plan:
    | IDiagramPlans
    | IDiagramSectionsPlan
    | IProjectPlanInterval
    | IDiagramPlannedSection;
  planMap: PlanMapType;
  planDaysMap: IntervalDaysMapType;
  entityId: string | number;
}) => {
  let daysCount;
  if (plan.start_at === plan.end_at) {
    daysCount = 1;
    planDaysMap.set(`${entityId}_${plan.start_at}`, true);
  } else {
    const daysInterval = enumerateDaysBetweenDates(
      moment(plan.start_at),
      moment(plan.end_at)
    );
    daysInterval.forEach((day) => planDaysMap.set(`${entityId}_${day}`, true));
    daysCount = daysInterval.length;
  }

  const currentItem = planMap.get(`${entityId}_${plan.start_at}`);

  if (!currentItem || currentItem.days < daysCount) {
    planMap.set(`${entityId}_${plan.start_at}`, {
      type: "full",
      start: plan.start_at,
      end: plan.end_at,
      days: daysCount,
      data: plan,
    });
  }
};

export const generateMaterialItemKeys = (
  element: IDiagramMaterial,
  startDateKey: string
) => {
  const itemKey =
    element.estimate_expenditure_id &&
    `${element.estimate_expenditure_id}_${startDateKey}`;
  const itemSectionKey =
    element.expenditure_section_id &&
    `${element.expenditure_section_id}_${startDateKey}`;
  const itemParentKey =
    element.expenditure_parent_id &&
    `${element.expenditure_parent_id}_${startDateKey}`;
  return { itemKey, itemSectionKey, itemParentKey };
};

export const processDiagramWeekPlan = ({
  year,
  plan,
  entityId,
  planMap,
  planDaysMap,
}: {
  year: number | string;
  plan: IWeekIntervalBase;
  entityId: number | string;
  planMap: PlanMapType;
  planDaysMap: IntervalDaysMapType;
}) => {
  if (
    [
      plan.start_week,
      plan.start_day_week,
      plan.end_week,
      plan.end_day_week,
    ].some((x) => x === undefined)
  )
    return;

  let daysCount;

  const plan_start_moment = moment()
    .year(+year)
    .week(plan.start_week)
    .day(plan.start_day_week);

  const plan_end_moment = moment()
    .year(+year)
    .week((plan as IWeekIntervalBase).end_week)
    .day((plan as IWeekIntervalBase).end_day_week);

  const itemKey = `${entityId}_${plan_start_moment.format("YYYY-MM-DD")}`;

  if (plan_start_moment.isSame(plan_end_moment)) {
    daysCount = 1;
    planDaysMap.set(
      `${entityId}_${plan_start_moment.format("YYYY-MM-DD")}`,
      true
    );
  } else {
    const days = enumerateDaysBetweenDates(plan_start_moment, plan_end_moment);
    days.forEach((day) => planDaysMap.set(`${entityId}_${day}`, true));
    daysCount = days.length;
  }

  const currentItem = planMap.get(itemKey);

  if (!currentItem || currentItem.days < daysCount) {
    planMap.set(itemKey, {
      type: "full",
      start: plan_start_moment.format("YYYY-MM-DD"),
      end: plan_end_moment.format("YYYY-MM-DD"),
      days: daysCount,
      data: plan,
    });
  }
};

export const processDiagramWeekWork = ({
  year,
  work,
  entityId,
  workMap,
  workDaysMap,
}: {
  year: number | string;
  work: IWeekIntervalBase;
  entityId: number | string;
  workMap: WorkMapType;
  workDaysMap: IntervalDaysMapType;
}) => {
  if (
    [
      work.start_week,
      work.start_day_week,
      work.end_week,
      work.end_day_week,
    ].some((x) => x === undefined)
  )
    return;
  let daysCount;

  const work_start_moment = moment()
    .year(+year)
    .week(work.start_week)
    .day(work.start_day_week);

  const work_end_moment = moment()
    .year(+year)
    .week(work.end_week)
    .day(work.end_day_week);

  const itemKey = `${entityId}_${work_start_moment.format("YYYY-MM-DD")}`;

  if (work_start_moment.isSame(work_end_moment)) {
    daysCount = 1;
    workDaysMap.set(itemKey, true);
  } else {
    const days = enumerateDaysBetweenDates(work_start_moment, work_end_moment);
    days.forEach((day) => workDaysMap.set(`${entityId}_${day}`, true));
    daysCount = days.length;
  }

  const currentItem = workMap.get(itemKey);

  if (!currentItem || currentItem.days < daysCount) {
    workMap.set(itemKey, {
      start: work_start_moment.format("YYYY-MM-DD"),
      end: work_end_moment.format("YYYY-MM-DD"),
      days: daysCount,
      data: work,
    });
  }
};

export const processDiagramSectionPlan = ({
  plan,
  entityId,
  planMap,
  planDaysMap,
}: {
  plan: IDiagramPlannedSection;
  entityId: number;
  planMap: PlanMapType;
  planDaysMap: IntervalDaysMapType;
}) => {
  if (!plan.start_at || !plan.end_at) return;
  let daysCount;

  const plan_start_moment = moment(plan.start_at);
  const plan_end_moment = moment(plan.end_at);

  const itemKey = `${entityId}_${plan_start_moment.format("YYYY-MM-DD")}`;
  if (plan_start_moment.isSame(plan_end_moment)) {
    daysCount = 1;
    planDaysMap.set(
      `${entityId}_${plan_start_moment.format("YYYY-MM-DD")}`,
      true
    );
  } else {
    const days = enumerateDaysBetweenDates(plan_start_moment, plan_end_moment);
    days.forEach((day) => planDaysMap.set(`${entityId}_${day}`, true));
    daysCount = days.length;
  }

  const currentItem = planMap.get(itemKey);

  if (!currentItem || currentItem.days < daysCount) {
    planMap.set(itemKey, {
      type: "full",
      start: plan_start_moment.format("YYYY-MM-DD"),
      end: plan_end_moment.format("YYYY-MM-DD"),
      days: daysCount,
      data: plan,
      isSectionPlan: true
    });
  }
};

export const getMonthEnumerateDataForChart = ({
  year,
  monthMarkers,
}: {
  year: number;
  monthMarkers: number[];
}) => {
  return monthMarkers.map((marker, index) => ({
    maxDay: moment().year(year).month(index).daysInMonth(),
    year: +year,
    monthNumber: index + 1,
    offsetLeft: marker,
  }));
};

export const applyProcessProjectIntervalFnIfExists = ({
  projects,
  projectInterval,
  processProjectInterval,
  acceptedMap,
  payedMap,
  onStockMap,
  plansMap,
  purchasesMap,
  stocklessMap,
}: {
  projects?: IProject[];
  projectInterval?: IProjectMaterialsDataInterval;
  processProjectInterval: (
    item: IProjectIntervalBase,
    type: MaterialType,
    map: MaterialsMapType
  ) => void;
  onStockMap: MaterialsMapType;
  plansMap: MaterialsMapType;
  purchasesMap: MaterialsMapType;
  stocklessMap: MaterialsMapType;
  acceptedMap: MaterialsMapType;
  payedMap: MaterialsMapType;
}) => {
  if (projects?.length && projectInterval) {
    projects.map((project) => {
      projectInterval?.accepted?.hasOwnProperty(project.id) &&
        projectInterval.accepted[project.id].map((accept) =>
          processProjectInterval(accept, ACCEPTED_MATERIAL, acceptedMap)
        );
      projectInterval?.payed?.hasOwnProperty(project.id) &&
        projectInterval.payed[project.id].map((pay) =>
          processProjectInterval(pay, PAYED_MATERIAL, payedMap)
        );
      projectInterval?.on_stock?.hasOwnProperty(project.id) &&
        projectInterval.on_stock[project.id].map((onStockItem) =>
          processProjectInterval(onStockItem, ON_STOCK_MATERIAL, onStockMap)
        );
      projectInterval?.plans?.hasOwnProperty(project.id) &&
        projectInterval.plans[project.id].map((plan) =>
          processProjectInterval(plan, PLANS_MATERIAL, plansMap)
        );
      projectInterval?.purchases?.hasOwnProperty(project.id) &&
        projectInterval.purchases[project.id].map((purchase) =>
          processProjectInterval(purchase, PURCHASES_MATERIAL, purchasesMap)
        );
      projectInterval?.stockless?.hasOwnProperty(project.id) &&
        projectInterval.stockless[project.id].map((stocklessItem) =>
          processProjectInterval(
            stocklessItem,
            STOCKLESS_MATERIAL,
            stocklessMap
          )
        );
    });
  }
};

export const checkIsMonthBranchShownAsSection = (branch: IProcessedBranch) =>
  (branch.lvl === 2 && branch.collapsed) ||
  (branch.lvl === 3 && branch.collapsed);

export const checkIsShownSectionPlan = (branch: IProcessedBranch) => branch.lvl === 2 && branch.collapsed || branch.lvl === 3;

export const checkIsMonthBranchShownAsExpenditure = (
  branch: IProcessedBranch
) => branch.lvl === 4 || (branch.lvl === 1 && !branch.collapsed);

export const assignWorkStatusesCountToBunch = ({
  checkWork,
  statuses,
}: {
  checkWork: IInterval;
  statuses: IProcessedBranchElement["worksBunch"]["statuses"];
}) => {
  Object.entries(checkWork.data.percentage).map(([status, value]) => {
    if (!statuses.hasOwnProperty(status)) {
      Object.defineProperty(statuses, status, {
        value: 0,
        writable: true,
        enumerable: true,
      });
      statuses[status] = 0;
    }
    if (value > 0) statuses[status] = statuses[status] + 1;
  });
};

export const getFetchYearsMonths = ({
  projectId,
  year,
  month,
  loadedChartData,
  tabId,
}: {
  projectId: number | string;
  year: number | string;
  month: number | string;
  tabId: string;
  loadedChartData: ILoadedChartData;
}) => {
  const { nextYear, nextMonth, prevYear, prevMonth } = getNearestYearsMonths(
    year,
    month
  );

  let fetchedDates = [
    `${prevYear}-${prevMonth}`,
    `${year}-${month}`,
    `${nextYear}-${nextMonth}`,
  ];

  if (loadedChartData[projectId] && loadedChartData[projectId][tabId]) {
    fetchedDates = fetchedDates.filter(
      (x) => loadedChartData[projectId][tabId].indexOf(x) === -1
    );
  }

  return fetchedDates;
};

export const getIntervalDatesLabel = (start_at: string, end_at: string) =>
  moment(start_at).isSame(moment(end_at), "days")
    ? moment(start_at).format("DD.MM.YY")
    : `${moment(start_at).format("DD.MM.YY")} - ${moment(end_at).format(
        "DD.MM.YY"
      )}`;

export const runningLineMover =
  (
    runningLineElement: HTMLElement | null,
    containerElement: HTMLElement | null,
    calendarElement: HTMLElement | null
  ) =>
  (clientY: number) => {
    if (!containerElement || !calendarElement || !runningLineElement) return;
    runningLineElement.style.top = `${computeLineYpx({
      clientY,
      calendarElement,
      containerElement,
    })}px`;
  };

export const modifyArrowPath = (pathElement: Element) => {
  if (!pathElement) return;
  try {
    const isModified = pathElement.getAttribute("data-modified") || "";
    if (isModified) return;

    const d = pathElement.getAttribute("d") || "";
    const line = d.split(" ").filter((x) => x.length > 0);

    let additionalLine = "";
    const initialXY = [+line[1], +line[2]];
    const finalXY = [+line?.at(-2), +line?.at(-1)];

    if (Math.round(initialXY[1]) === Math.round(finalXY[1])) return;

    const isFinalYGreater = finalXY[1] < initialXY[1];
    const startLineXY = [+line[4], +line[5]];

    if (!isFinalYGreater) {
      additionalLine += `${startLineXY[0]} ${startLineXY[1] + 20} ${
        startLineXY[0] - 30
      } ${startLineXY[1] + 20}`;
    } else {
      additionalLine += `${startLineXY[0]} ${startLineXY[1] - 20} ${
        startLineXY[0] - 30
      } ${startLineXY[1] - 20}`;
    }

    line.splice(6, 0, additionalLine);
    pathElement.setAttribute("d", line.join(" "));
    pathElement.setAttribute("data-modified", "true");
  } catch (e) {}
};
