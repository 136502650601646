import React from "react";
import cn from "classnames";

import IndicatorsWrapper from "components/UI/molecules/Indicators/IndicatorsWrapper/IndicatorsWrapper";
import { useFilesIndicators } from "./useFilesIndicators";
import CircleComponents from "components/UI/molecules/CircleComponents";

import declOfNum from "utils/declOfNum";
import { getPercentageOfCommonCount } from "utils/getPercentageOfCommonCount";
import styles from "./FilesIndicators.module.scss";

const FilesIndicators = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  const {
    all,
    text,
    tables,
    presentations,
    docs,
    others,
    capacityAll,
    capacityUsed,
  } = useFilesIndicators();

  return (
    <IndicatorsWrapper
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      contentClassName={cn({ [styles.loading]: all === undefined })}
    >
      <div className={styles.all}>
        <div className={styles.allCount}>
          {declOfNum(all, ["файл", "файла", "файлов"])}
        </div>
        <div className={styles.allSubtitle}>Всего занято</div>
        <div className={cn(styles.allSubtitle, styles.blue)}>
          {capacityUsed} / {capacityAll} Гб
        </div>
      </div>
      <CircleComponents
        color="#5EC5EB"
        displayingValue={text}
        progress={getPercentageOfCommonCount(all, text)}
        description="Текстовые документы"
        isProgressStringHidden
        titleClassName={styles.circleTitle}
        descriptionClassName={styles.circleDescription}
        subtitle="*.doc, .docx, .odt, .txt, .rtf"
        subtitleClassName={styles.fileTypes}
      />

      <CircleComponents
        color="#2DD941"
        displayingValue={tables}
        progress={getPercentageOfCommonCount(all, tables)}
        description="Таблицы"
        isProgressStringHidden
        titleClassName={styles.circleTitle}
        descriptionClassName={styles.circleDescription}
        subtitle="*.xls, .xlsx, .ods"
        subtitleClassName={styles.fileTypes}
      />
      <CircleComponents
        color="#FF8900"
        displayingValue={presentations}
        progress={getPercentageOfCommonCount(all, presentations)}
        description="Презентации"
        isProgressStringHidden
        titleClassName={styles.circleTitle}
        descriptionClassName={styles.circleDescription}
        subtitle="*.ppt, .pptx, .odp, "
        subtitleClassName={styles.fileTypes}
      />
      <CircleComponents
        color="#E02820"
        displayingValue={docs}
        progress={getPercentageOfCommonCount(all, docs)}
        description="Документы"
        isProgressStringHidden
        titleClassName={styles.circleTitle}
        descriptionClassName={styles.circleDescription}
        subtitle="*.ppt, .pptx, .odp, "
        subtitleClassName={styles.fileTypes}
      />
      <CircleComponents
        color="#9F9F9F"
        displayingValue={others}
        progress={getPercentageOfCommonCount(all, others)}
        description="Другие файлы"
        isProgressStringHidden
        titleClassName={styles.circleTitle}
        descriptionClassName={styles.circleDescription}
        subtitle="&nbsp;"
        subtitleClassName={styles.fileTypes}
      />
    </IndicatorsWrapper>
  );
};

export default FilesIndicators;
