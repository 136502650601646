import React from "react";

import Ks2Modal from "./components/Ks2Modal/Ks2Modal";
import Ks3Modal from "./components/Ks3Modal/Ks3Modal";

import { IKs23ModalProps, KS_TYPES } from './types'

const Ks23Modal: React.FC<IKs23ModalProps> = ({ type, ...props }) => {
  if (type === KS_TYPES.ks2) return <Ks2Modal {...props} />;
  if (type === KS_TYPES.ks3) return <Ks3Modal {...props} />;
  return null;
};

export default React.memo(Ks23Modal);
