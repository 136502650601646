import React from 'react'
import styles from "./TableHeader.module.scss"
import SearchFilterTable from "./../../../../atoms/SearchFilterTable"
import cn from "classnames"

interface IProps {
    onFiltersChange?: (value: string, type: string) => void;
    className?: string;
}

function PlanMaterialsTableHeader({ onFiltersChange, className }: IProps) {
    return (
        <div className={cn(styles.header, styles.planMaterialsHeader, className)}>
            <div />
            <div className={styles.name}>
                Материал
                {onFiltersChange && <SearchFilterTable
                    title="Материал"
                    setFilter={value => onFiltersChange(value, "estimate_item_name")}
                />}
            </div>
            <div>
                Кол-во
            </div>
            <div className={styles.centered}>
                По проекту
            </div>
        </div>
    )
}

export default React.memo(PlanMaterialsTableHeader)