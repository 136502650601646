import React, { useMemo } from "react";
import { useDrag } from "react-dnd";

import { ESTIMATE_ITEM_STATUSES, ESTIMATE_STATES_IDS } from "../../../../../../constants";
import { getSectionAmount } from "../../../../../../utils/getSectionAmount";

import { AMOUNTS_VARIANTS } from "../../../../../../../../UI/molecules/EstimateSection/components/Amounts/Amounts";
import EstimateSection from "../../../../../../../../UI/molecules/EstimateSection/EstimateSection";

const Section = ({ section, isChecked, check, activeEstimateStateId, canChangeEstimateState, actions }) => {
  const isDraft = activeEstimateStateId === ESTIMATE_STATES_IDS.DRAFT;

  const [, drag] = useDrag(
    () => ({
      type: "box",
      item: [section],
      canDrag: !!canChangeEstimateState,
    }),
    [section, canChangeEstimateState]
  );

  const isDisplayAmounts =
    !isDraft &&
    (section.status === ESTIMATE_ITEM_STATUSES.CONFIRMED || activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION);

  const amounts = useMemo(
    () => ({
      all: getSectionAmount(section, activeEstimateStateId),
      completed: section.indicators ? section.indicators.invested : 0,
      completed_percent: section.indicators ? section.indicators.completed_percent : 0,
      accepted: section.indicators ? section.indicators.amount_accepted : 0,
      invested: section.indicators ? section.indicators.invested : 0,
      variant: AMOUNTS_VARIANTS.HIDDEN,
    }),
    [activeEstimateStateId, section]
  );

  return (
    <div ref={drag}>
      <EstimateSection
        section={section}
        isChecked={isChecked}
        onCheck={check}
        canCheck={canChangeEstimateState}
        actions={actions}
        amounts={amounts}
        isDisplayAmounts={isDisplayAmounts}
        isDisplayEstimateStateMark={(isDraft && section.confirmed) || activeEstimateStateId === ESTIMATE_STATES_IDS.PRODUCTION}
      />
    </div>
  );
};

export default React.memo(Section);
