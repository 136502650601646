import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  loadTickets,
  patchTicket,
  ticketsLoadingSelector,
  ticketsSelector,
} from "redux/modules/common/building/tickets";

import ButtonBase from "components/UI/atoms/ButtonBase";
import ProductIntervalCard from "./components/ProductIntervalCard";
import AddRelationToProduct from "components/UI/organism/AddRelationToProduct";
import ProductInfo from "./components/ProductInfo";
import JournalTicketModal from "./JournalTicketModal/JournalTicketModal";
import EmptyPlaceholder from "../../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";

import usePermission from "hooks/usePermission";
import { VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_APPROVE_TICKET } from "constants/permissions/manufacturingPermissions";

import jurnalIcon from "images/icons/navigation/jurnalIcon.svg";

import styles from "./JournalDelivery.module.scss";
import { IRouterParamsWithObjectId } from "types/routerTypes";
import { ITicketJournalDelivery } from "types/interfaces/Tickets";
import JournalDeliveryRow from "./components/JournalDeliveryRow/JournalDeliveryRow";
import { Spinner } from "components/UI/Spinner/Spinner";
import JournalDeliveryTableHead from "./components/JournalDeliveryTableHead/JournalDeliveryTableHead";


interface Iprops {
  isOpen: boolean;
  year: number;
  month: number;
  isShowAddPlan: boolean;
  setIsShowAddPlan: any;
  onClickAddPlan: never;
}

const JournalDelivery: React.FC<Iprops> = ({
  isOpen,
  year,
  month,
  isShowAddPlan,
  setIsShowAddPlan,
  onClickAddPlan,
}) => {
  const dispatch = useDispatch();
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const isListLoading = useSelector(ticketsLoadingSelector);
  const tickets = useSelector(ticketsSelector);

  const [isShow, setIsShow] = useState(true);
  const [idAddRelation, setIdAddRelation] = useState<any>(false);
  const [isOpenProductInfo, setIsOpenProductInfo] = useState<any>(false);
  const [filters, setFilters] = useState({});
  const [modalData, setModalData] = useState<ITicketJournalDelivery | null>(null);

  const loadTicket = () => {
    const params = {
      year,
      month,
      ordering: "-id",
      ...filters,
    };
    //@ts-ignore
    if (!isShow) params.exists_accepted = false;

    dispatch(loadTickets(objectId, params));
  };

  const handleSetFilter = (name: string, value: string) => {
    setFilters({ ...filters, [name]: value });
  };

  const bindHandler = (objectId: string, idAddRelation: any) => {
    return (inner_id: number) => {
      dispatch(patchTicket(objectId, idAddRelation, { expenditure_id: inner_id }, undefined, "Продукт сопоставлен"));
    };
  };

  const haveApproveTicketPermission = usePermission(VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_APPROVE_TICKET);

  const matchingHandler = useCallback((event: React.MouseEvent, row: ITicketJournalDelivery) => {
    event.stopPropagation();

    if (!row.expenditure_id) {
      setIdAddRelation({ id: row?.id, editable: true });
    } else {
      setIsOpenProductInfo({
        id: row.id,
        editable: true,
      });
    }
  }, []);

  useEffect(() => {
    loadTicket();
  }, [objectId, year, month, isShow, filters]);

  if (!isOpen) return null;

  return (
    <div className={styles.container}>
      <JournalDeliveryTableHead onChangeFilter={handleSetFilter} setIsShow={setIsShow} isShow={isShow} />
      {isListLoading && <Spinner />}
      {!isListLoading && !tickets?.length && (
        <EmptyPlaceholder text="Нет сведений" img={jurnalIcon}>
          <ButtonBase primary onClick={onClickAddPlan}>
            Добавить запись
          </ButtonBase>
        </EmptyPlaceholder>
      )}
      {!isListLoading &&
        tickets?.map((el: ITicketJournalDelivery) => (
          <JournalDeliveryRow
            ticket={el}
            objectId={objectId!}
            haveApproveTicketPermission={haveApproveTicketPermission}
            onRowClick={setModalData}
            onMatchingClick={matchingHandler}
            key={el.id}
          />
        ))}
      <ProductIntervalCard isOpen={isShowAddPlan} handleClose={() => setIsShowAddPlan(false)} objectId={objectId} />
      <AddRelationToProduct
        idAddRelation={idAddRelation}
        handleClose={() => setIdAddRelation(false)}
        objectId={objectId}
        onSubmit={bindHandler(objectId!, idAddRelation?.id)}
        updateProducts={undefined}
        type={undefined}
      />
      <JournalTicketModal isOpen={!!modalData} onClose={() => setModalData(null)} ticketData={modalData} /> {/* @ts-ignore */}
      <ProductInfo
        isOpen={isOpenProductInfo}
        closeModal={() => setIsOpenProductInfo(false)}
        objectId={objectId}
        chosenMatchingProduct={tickets?.find((item: ITicketJournalDelivery) => item.id === isOpenProductInfo?.id)}
        openRelationToProduct={() => {
          setIdAddRelation({ id: isOpenProductInfo?.id, editable: true });
          setIsOpenProductInfo(false);
        }}
      />
    </div>
  );
};

export default JournalDelivery;
