import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { filesSelector } from "../../../../../redux/modules/common/orderCard/files/selectors";
import { addFile } from "../../../../../redux/modules/common/orderCard/files/thunks/addFile";
import { deleteFile } from "../../../../../redux/modules/common/orderCard/files/thunks/deleteFile";

import Chat from "../../../../UI/organism/Chat";
import FileBlock from "../../../../UI/molecules/FileBlock";

import Info from "../Info";

import styles from "./Header.module.scss";

const Header = ({ order, permissions, isSimplified }) => {
  const dispatch = useDispatch();
  const files = useSelector(filesSelector);

  const handleAddFiles = useCallback(
    (files) => files.forEach((file) => compose(dispatch, addFile)(order.id, file)),
    [order.id]
  );

  const handleDeleteFile = useCallback((fileId) => compose(dispatch, deleteFile)(order.id, fileId), [order.id]);

  return (
    <header className={styles.header}>
      <Info order={order} isSimplified={isSimplified} />
      <div>
        <div className={styles.chatContainer}>
          <Chat
            docType={"order_pcr"}
            urlType={"order-pcr"}
            docId={order.id}
            title={`Заказ ${order.number} (чат компании)`}
            subTitle={order.status_name}
            buildingId={order.building?.id}
            openText={"Чат компании"}
            closeText={"Закрыть чат"}
          />
        </div>
        <FileBlock
          className={styles.fileBlock}
          files={files.list}
          addFiles={handleAddFiles}
          deleteFile={handleDeleteFile}
          permissions={permissions}
        />
      </div>
    </header>
  );
};

export default React.memo(Header);
