import axios from 'axios';
import { createSelector } from 'reselect';
import { SET_ORGANIZATION } from 'redux/modules/common/auth';

const CHOOSE_ENTITY = 'settings/CHOOSE_ENTITY';
const SET_ADDRESSES = 'settings/SET_ADDRESSES';

const initialState = {
    editingEntity: {},
    entityAddresses: [],
}

/* 
  Reducer
*/

export default ( state = initialState, action ) => {
    switch(action.type) {
        case CHOOSE_ENTITY:
            return {
                ...state,
                editingEntity: action.payload,
            };

        case SET_ADDRESSES:
            return {
                ...state,
                entityAddresses: action.payload,
            };
        
        default:
            return state;
    }
}

/* 
  Selectors
*/

/* 
  Action creators
*/
export const setEntity = data => {
    return {
        type: CHOOSE_ENTITY,
        payload: data,
    }
}

export const setAddresses = data => {
    return {
        type: SET_ADDRESSES,
        payload: data,
    }
}

/* 
  Thunks
*/
//ENTITIES
export const updateEntity = data => {
    return dispatch => {
        dispatch(setEntity(data));
    }
}

export const performUpdateAddress = data => {
    return dispatch => {
        dispatch(setAddresses(data));
    }
}

export function createEntity(data) {
    return axios.post(`/entities/`, data).then(
    ({ data }) => data,
    error => {
        console.log(error)
    }
    ).catch(e => console.log(e));
}

export function editEntity(data, entityId) {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    return axios.put(`/entities/${entityId}/`, data, config).then(
        ({ data }) => data,
        error => {
            console.log(error)
        }
        ).catch(e => console.log(e));
}

export function deleteEntity(entityId) {
    return () => axios.delete(`/entities/${entityId}/`);
}

export function loadAddresses(entityId) {
    return dispatch => {
        return axios.get(`/entities/target/${entityId}/`).then(
            (res) => dispatch(setAddresses(res.data.results)),
            () => dispatch(setAddresses([]))
        )
    }
}

export function createAddress(entityId, newAddress) {
    return () => axios.post(`/entities/target/${entityId}/`, {target: newAddress});
}

export function updateAddress(entityId, data) {
    return () => axios.put(`/entities/target/${entityId}/${data.id}/`, {target: data.address});
}

export function deleteAddress(entityId, addressId) {
    return () => axios.delete(`/entities/target/${entityId}/${addressId}/`);
}

// ORGANIZATION
export function setCurrentOrganization(data) {
  return {
    type: SET_ORGANIZATION,
    entities: data.entities,
    members: data.members,
  };
}
export function setOrganization(key) {
  const data = {};
  const config = {
    headers: {
      Authorization: `Token ${key}`,
      'Cache-Control': 'no-cache',
    },
  };
  return dispatch =>
    axios.get(`/entities/`, config).then(
      res => {
        data.entities = res.data.results;
        return axios.get(`/users/`, config).then(
          res => {
            data.members = res.data.results;
            dispatch(setCurrentOrganization(data));
          },
        );
      }
    );
}
