import React, { useRef, useMemo } from 'react';
import cn from "classnames";

import Icon from "components/UI/Icon";

import { stopEventPropagation } from 'utils/stopEventPropagation';

import checkBlackThin from 'images/icons/check-black-thin.svg';

import styles from "./Checkbox.module.scss";


interface IProps {
  onCheck: (e: React.ChangeEvent<HTMLInputElement>) => void,
  checked: boolean,
  children?: React.ReactNode,
  className?: string,
}

function Checkbox({ onCheck, checked, children, className }: IProps) {
  const ref = useRef(null);

  const generatedId = useMemo(() => {
    return "checkbox" + Math.random()
  }, [])

  return (
    <label
      ref={ref}  
      htmlFor={generatedId} 
      className={cn(styles.container, className)} 
      onClick={stopEventPropagation}
    >
      {children}
      <input
        id={generatedId} 
        type="checkbox" 
        onChange={onCheck} 
        className={styles.checkbox} 
        checked={checked} 
      />
      <label className={cn(styles.borders, "checkbox")} htmlFor={generatedId}>
        {checked && <Icon icon={checkBlackThin} />}
      </label>
    </label>
  )
}

export default React.memo(Checkbox);
