import React from "react"
import { getPlanMaterials } from "redux/modules/common/building/processApi"
import { ISerializedMaterialInPlan } from "types/interfaces/Materials"
import { errorCatcher } from "utils/errorCatcher"

export const useMaterialsPlanList = (objectId: number, expId: number, presettedPercentage?: number) => {
    const [allMaterials, setAllMaterials] = React.useState<ISerializedMaterialInPlan[]>([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [count, setCount] = React.useState(0)

    const [filters, setFilters] = React.useState<Record<string, string> | null>(null)

    React.useEffect(() => {
        setIsLoading(true)
        getPlanMaterials(objectId, expId, filters)
            .then(resp => {
                setAllMaterials(resp.data.results)
                setCount(resp.data.count)
            })
            .catch(errorCatcher)
            .finally(() => setIsLoading(false))
    }, [objectId, expId, filters])

    const filterHandler = (value: string, fieldName: string) => {
        setFilters(prev => ({
            ...prev,
            [fieldName]: value
        }))
    }

    const loadMoreHandler = () => {
        getPlanMaterials(objectId, expId, {...filters, offset: String(allMaterials.length)})
            .then(resp => setAllMaterials(prev => [...prev, ...resp.data.results]))
    }

    const presettedPlanMaterials = React.useMemo(() => {
        if (!presettedPercentage) return []
        return allMaterials.map(el => ({
            ...el,
            local_count: (Number(el.count) * (presettedPercentage || 1)).toFixed(2)
        }))
    }, [allMaterials, presettedPercentage])

    return {
        allMaterials,
        isLoading,
        count,
        filterHandler,
        loadMoreHandler,
        presettedPlanMaterials
    }
}