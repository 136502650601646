import React from "react";
import cn from "classnames";

import styles from "./Radio.module.scss";

export interface IProps {
  className?: string;
  onClick?: () => void;
  isActive: boolean;
  isDisabled?: boolean;
}

const Radio = ({ className, onClick, isActive, isDisabled }: IProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(styles.circle, className, {
        [styles.active]: isActive,
        [styles.disabled]: isDisabled,
      })}
    />
  );
};

export default React.memo(Radio);
