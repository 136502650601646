import React, { useCallback, useState } from "react";

import { Switch } from "../../../../../../../../../../../../../UI/atoms/Switch/Switch";
import Permissions from "../../Permissions";

import { PermissionPropertiesEnum } from "../../../../../../../../enums";

import { stopEventPropagation } from "../../../../../../../../../../../../../../utils/stopEventPropagation";

import ArrowSmallUpGrey from "../../../../../../../../../../../../../../images/icons/ArrowSmallUpGrey";

import styles from "./SwitchedPermission.module.scss";

const SwitchedPermission = ({ permission, onChangeChecked, checkedPermissions, canEdit }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const onChange = useCallback(
    (e) => onChangeChecked(permission.name, e.target.checked),
    [onChangeChecked, permission.name]
  );

  const handleExpand = useCallback(() => setIsExpanded((prevState) => !prevState), []);

  const childrenAreExist = permission.children && permission.children.length !== 0;

  return (
    <>
      <div className={styles.container} onClick={handleExpand}>
        {childrenAreExist && (
          <ArrowSmallUpGrey className={styles.arrow} color="#C9C9C9" rotate={isExpanded ? 180 : 90} />
        )}
        <div className={styles.switch}>
          <span>{permission.label}</span>
          <Switch
            checked={checkedPermissions[permission.name]}
            onChange={onChange}
            onClick={stopEventPropagation}
            disabled={
              !canEdit ||
              permission.property === PermissionPropertiesEnum.on ||
              permission.property === PermissionPropertiesEnum.off
            }
          />
        </div>
      </div>
      {childrenAreExist && isExpanded && (
        <div className={styles.children}>
          <Permissions
            permissions={permission.children}
            checkedPermissions={checkedPermissions}
            onChangeChecked={onChangeChecked}
            canEdit={canEdit}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(SwitchedPermission);
