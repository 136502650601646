import React, { useMemo } from "react";
import moment from "moment";
import cn from "classnames";

import "../../Global.scss";

import styles from "./index.module.scss";

const DatePicker = ({ date, setDate, dateStart, dateEnd }) => {
  const monthDaysNumbers = useMemo(
    () => Array.from({ length: moment(date, "YYYY-MM").daysInMonth() }, (v, k) => k + 1),
    [date]
  );

  const getWeekdays = (day) => moment(date).set("date", day).isoWeekday();

  const handleActiveDay = (day) => {
    setDate(moment(date).date(day));
  };

  return (
    <div className={styles.container}>
      <div className={styles.dayNameBlock}>
        <div className={styles.dayName}>Пн</div>
        <div className={styles.dayName}>Вт</div>
        <div className={styles.dayName}>Ср</div>
        <div className={styles.dayName}>Чт</div>
        <div className={styles.dayName}>Пт</div>
        <div className={styles.dayName}>Сб</div>
        <div className={styles.dayName}>Вс</div>
        {Array.from({ length: moment(date).date(0).day() }, (v, k) => k + 1).map((item, index) => (
          <div key={index} className={styles.dayNumber}></div>
        ))}
        {monthDaysNumbers.map((dayNumber, index) => {
          const dateAtCurrentDayNumber = moment({ day: dayNumber, month: date?.month?.(), year: date?.year?.() });
          return (
            <div
              id={checkIsDateInInterval(dateAtCurrentDayNumber, dateStart, dateEnd) ? "addBgcToNumber" : null}
              className={cn(styles.dayNumber, { [styles.holidays]: [6, 7].includes(getWeekdays(dayNumber)) })}
              onClick={() => handleActiveDay(dayNumber)}
              key={index}
            >
              <div
                className={cn({
                  [styles.activeDate]: moment(dateStart).isSame(dateAtCurrentDayNumber, "day"),
                  [styles.activeDateEnd]: moment(dateEnd).isSame(dateAtCurrentDayNumber, "day"),
                })}
              >
                {dayNumber}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const checkIsDateInInterval = (date, dateStart, dateEnd) => {
  if (!moment.isMoment(date)) return false;
  return moment(date).isBetween(dateStart, dateEnd);
};

export default React.memo(DatePicker);
