import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import { Spinner } from "components/UI/Spinner/Spinner";
import React, { useCallback } from "react";
import taskIcon from "images/icons/navigation/tasksIcon.svg";
import { TASK_LISTS_TYPES } from "../../constants";
import { useTasksData } from "../../hooks/useTasksData";
import TaskRow from "../TaskRow/TaskRow";
import ButtonBase from "components/UI/atoms/ButtonBase";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import { tasksListFilterType } from "redux/modules/purchaser/Tasks/types";
import { useTasksModal } from "../../hooks/useTasksModal";
import { useDeleteTask } from "../../hooks/useDeleteTask";

type propsType = {
  filters: tasksListFilterType;
  objectId: string;
  type: TASK_LISTS_TYPES;
};

function TaskList({ filters, objectId, type }: propsType) {
  const { tasksByBuilding, isTasksLoading, loadMoreTasksHandler } = useTasksData({ objectId, type, filters });
  const { deleteTaskHandler } = useDeleteTask({ variant: "byCertainBuilding", building_id: Number(objectId), type });
  const { openAddModal, openEditModal, openViewModal } = useTasksModal();

  const handleAddTask = useCallback(
    () => {
      openAddModal({
        placementType: "byCertainBuilding",
        listType: type,
      });
    },
    [type]
  );

  const handleOpenTask = useCallback(
    (id: number) => {
      openViewModal({
        placementType: "byCertainBuilding",
        listType: type,
        id,
      });
    },
    [type]
  );

  const handleEditTask = useCallback(
    (id: number) => {
      openEditModal({
        placementType: "byCertainBuilding",
        listType: type,
        id,
      });
    },
    [type]
  );

  if (isTasksLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!!tasksByBuilding?.results?.length &&
        tasksByBuilding.results.map((el) => (
          <TaskRow
            key={el.id}
            {...el}
            dateEnd={el.deadline}
            dateStart={el.created_at}
            commentsCount={el.task_comments?.length}
            executor={el.executor_user}
            onDelete={deleteTaskHandler}
            onEdit={handleEditTask}
            handleOpen={() => handleOpenTask(el.id)}
          />
        ))}
      <ShowMoreButton
        handleAdd={loadMoreTasksHandler}
        allCount={tasksByBuilding?.count}
        showedCount={tasksByBuilding?.results?.length}
      />
      {!tasksByBuilding?.results?.length && (
        <EmptyPlaceholder text="Нет данных в разделе задач" img={taskIcon}>
          <ButtonBase primary onClick={handleAddTask}>
            Новая задача
          </ButtonBase>
        </EmptyPlaceholder>
      )}
    </>
  );
}

export default React.memo(TaskList);
