export const statusFilterDataJournalDelivery = [
    {
        id: true,
        name: true,
        label: "Показать принятые",
    },
    {
        id: false,
        name: false,
        label: "Скрыть принятые",
    },
];