import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import moment from 'moment';
import DateCell from "./../../../../../../../UI/atoms/DateCell/DateCell"

import styles from './index.module.scss';
import { formatNumberWithMeasure } from '../../../../../../../../utils/formatNumberWithMeasure';

const ProductItem = ({data, onClick, measure}) => {
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.field1}><div className={styles.dot}></div></div>
      <div className={styles.field2} title={data?.name}>{data?.name}</div>
      <div className={styles.field3}>{data?.provider?.name}</div> 
      <div className={styles.field4}><DateCell date={moment(data?.received_at).format('DD.MM.YYYY')} /></div> 
      <div className={styles.field4}>{data?.count}</div>
      <div className={styles.field6}>{formatNumberWithMeasure(data?.amount, measure)}</div>
    </div>
  )
}

export default ProductItem;