import React from "react";
import cn from "classnames";

import { MONTH_COLOR_MAP as colorMap } from "../../../../../../constants";

import styles from "../../InfoPopup.module.scss";

const STATUSES = {
  paid: {
    class: styles.paid,
    name: "К оплате",
  },
  topay: {
    class: styles.topay,
    name: "К сдаче",
  },
  confirmed: {
    class: styles.confirmed,
    name: "Подтверждено",
  },
  received: {
    class: styles.received,
    name: "Принято",
  },
  doned: {
    class: styles.doned,
    name: "Выполнено",
  },
  open: {
    class: styles.open,
    name: "Open",
  },
  default: {
    class: styles.default,
    name: "Запланировано",
  },
};

const StatusBlock = ({ status, percentage, count, measure }) => {
  if (STATUSES[status]) {
    return (
      <div className={styles.statusBlock}>
        <div
          className={cn(styles.status, STATUSES[status].class)}
          style={{ backgroundColor: colorMap[status], borderColor: colorMap[status + "Border"] }}
        />
        <div className={styles.indicators}>
          <span className={styles.statusName}>{STATUSES[status].name}</span>
          {percentage && <span className={styles.statusPercentage}>{Math.round(percentage[status] * 100)}%</span>}
          {count !== undefined && <span>{`${count} ${measure ? `(${measure})`: ''}`}</span>}
        </div>
      </div>
    );
  }
  return null;
};

export default StatusBlock;
