import React from "react";
import styles from "./JournalTicketModal.module.scss";
import JornalDay from "./JornalDay";
import moment from "moment";
import getShortFullName from "utils/getShortFullName";
import CalendarRange from "../../../../../UI/molecules/CalendarRange";
import Expandable from "../../../../../UI/atoms/Expandable/Expandable";
import cn from "classnames";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { splitThousands } from "utils/splitThousands";
import MaterialBlockInWork from "../../../../../UI/organism/WorkOrMaterialsModals/components/MaterialsInWork/MaterialBlockInWork";
import ModalContainer from "../../../../../UI/organism/WorkOrMaterialsModals/components/ModalContainer/ModalContainer";
import usePermission from "hooks/usePermission";
import { VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_TICKET_DELETE_FILE } from "constants/permissions/manufacturingPermissions";
import ExpandableFileInput from "components/UI/molecules/ExpandableFileInput/ExpandableFileInput";
import { errorCatcher } from "utils/errorCatcher";
import { sliceTextByConstraint } from "utils/sliceTextByConstraint";
import { IRouterParamsWithObjectId } from "types/routerTypes";
import {
  ITicketDetailed,
  ITicketJournalDelivery,
} from "types/interfaces/Tickets";
import { IFileWithOriginalName } from "types/interfaces/Files";
import WorkersList from "components/UI/organism/WorkOrMaterialsModals/components/WorkersList/WorkersList";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  ticketData: ITicketJournalDelivery | null;
}

function JournalTicketModal({ isOpen, onClose, ticketData }: IProps) {
  const [detailedData, setDetailedData] =
    React.useState<ITicketDetailed | null>(null);
  const [files, setFiles] = React.useState<IFileWithOriginalName[] | null>(
    null
  );
  const { objectId } = useParams<IRouterParamsWithObjectId>();
  const haveDeleteFilesPermission = usePermission(
    VIEW_MANUFACTURING_JOURNAL_ACCEPTANCE_TICKET_DELETE_FILE
  );

  React.useEffect(() => {
    if (ticketData?.id) {
      Axios.get<ITicketDetailed>(
        `/building/${objectId}/fact-intervals/${ticketData?.id}/`
      )
        .then((resp) => {
          setDetailedData(resp.data);
          setFiles(resp.data.files);
        })
        .catch(errorCatcher);
    }
  }, [ticketData]);

  const uploadFile = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    Axios.post(
      `/building/${objectId}/estimate/items/${ticketData?.expenditure_id}/fact-intervals/${ticketData?.id}/files/`,
      formData
    ).then((resp) => {
      Axios.get(
        `/building/${objectId}/estimate/items/${ticketData?.expenditure_id}/fact-intervals/${ticketData?.id}/files/`
      ).then((resp) => {
        setFiles(resp.data.results);
      });
    });
  };

  const removeFile = (fileId: number) => {
    Axios.delete(
      `/building/${objectId}/estimate/items/${ticketData?.expenditure_id}/fact-intervals/${ticketData?.id}/files/${fileId}`
    ).then((resp) => {
      Axios.get(
        `/building/${objectId}/estimate/items/${ticketData?.expenditure_id}/fact-intervals/${ticketData?.id}/files/`
      ).then((resp) => {
        setFiles(resp.data.results);
      });
    });
  };

  const closeHandler = () => {
    onClose && onClose();
    setDetailedData(null);
  };

  return (
    <ModalContainer
      onClose={closeHandler}
      isOpen={!!(isOpen && ticketData)}
      name={ticketData?.title}
    >
      <div className={styles.pair}>
        <JornalDay
          day={moment(ticketData?.created_at).format("DD/MM/YYYY")}
          time={moment(ticketData?.created_at).format("HH:mm")}
        />
        <div
          className={styles.pass}
          title={getShortFullName(ticketData?.author)}
        >
          <b>Сдал:</b>
          <span>
            {sliceTextByConstraint(getShortFullName(ticketData?.author), 18)}
          </span>
        </div>
      </div>
      <div className={styles.pair}>
        <div className={styles.info}>
          <b>Количество (к сдаче):</b>
          <span>
            {ticketData?.count}{" "}
            {ticketData?.measure && `(${ticketData?.measure})`}
          </span>
        </div>
        <div className={styles.info}>
          <b>Стоимость:</b>
          <span>{splitThousands(detailedData?.work_amount)}</span>
        </div>
      </div>
      <div className={cn(styles.pair, styles.centered)}>
        <b>Период выполнения:</b> {/* @ts-ignore */}
        <CalendarRange
          defaultDateStart={ticketData?.start_at}
          defaultDateEnd={ticketData?.end_at}
          disabled
          classNameSelect={styles.calendar}
        />
      </div>
      <WorkersList workers={detailedData?.workers} />
      {detailedData?.stock_using_materials?.length ? (
        <Expandable
          title={`Материалы: ${detailedData.stock_using_materials?.length}`}
        >
          {detailedData.stock_using_materials?.map((el) => (
            <MaterialBlockInWork
              name={el.stock_using_material?.product_building?.name}
              measure={el.stock_using_material?.product_building?.measure}
              count={el.count}
              amount={el.amount}
              key={el.id}
            />
          ))}
        </Expandable>
      ) : null}
      {ticketData?.expenditure_id && (
        <ExpandableFileInput
          files={files || []}
          onRemoveFileDirectly={removeFile}
          onUploadFileDirectly={uploadFile}
          canDeleteFiles={haveDeleteFilesPermission}
        />
      )}
    </ModalContainer>
  );
}

export default JournalTicketModal;
