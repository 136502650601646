import React from 'react'
import styles from "./MaterialBlockInWork.module.scss"
import { getShortPrice } from "utils/helpers"
import cn from "classnames"
import CheckIcon from '../CheckIcon/CheckIcon'
import { isNanChecker } from 'utils/isNanChecker'
import { dropNonSignificantZeros } from 'utils/dropNonSignificantZeros'
import { sliceTextByConstraint } from 'utils/sliceTextByConstraint'

type propsType = {
  name: string,
  count: string,
  measure: string,
  amount: number,
  statusText?: string,
  statusColor?: "green" | "blue"
}

function MaterialBlockInWork({ name, count, measure, amount, statusColor = "green", statusText }: propsType) {
  return (
    <div className={styles.container}>
      {statusText && <div className={cn(styles.status, styles[statusColor])}>
        <CheckIcon />
        {statusText}
      </div>}
      <div className={styles.name} title={name}>{name}</div>
      <div className={styles.info} title={`Количество: ${isNanChecker(+count).toFixed(2)} (${measure})`}>
        <b>Количество: </b>
        <span>{dropNonSignificantZeros(count)} ({sliceTextByConstraint(measure, 4)})</span>
      </div>
      <div className={styles.info} title={`Стоимость: ${getShortPrice(amount)}`}>
        <b>Стоимость: </b>
        <span>{getShortPrice(amount)}</span>
      </div>
    </div>
  )
}

export default MaterialBlockInWork