import { createSelector } from "reselect";

import {
  ADD_DOCUMENT,
  ADD_PACKING_ITEM_FILE,
  ADD_PACKING_LIST_FILE,
  DELETE_PACKING_ITEM_FILE,
  DELETE_PACKING_LIST_FILE,
  LOAD_DOCUMENTS,
  SET_DOCUMENTS,
  SET_MORE_DOCUMENT,
  SET_PACKING_ITEM_FILES,
  SET_PACKING_ITEMS_FILES_LOADING,
  SET_PACKING_LIST,
  SET_PACKING_LIST_ERROR,
  SET_PACKING_LIST_LOADING,
  SET_PACKING_LIST_NUMBER,
} from "./constants";

const initialState = {
  documents: {
    count: 0,
    list: [],
    results: [],
  },
  packingList: {
    data: {},
    isLoading: true,
    isError: false,
  },
  loading: true,
  agents: null,
  packingItemsFiles: {},
  packingItemsFilesAreLoading: true,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: payload,
        loading: false,
      };
    case SET_PACKING_LIST_LOADING:
      return {
        ...state,
        packingList: {
          ...state.packingList,
          isLoading: true,
        },
      };
    case SET_PACKING_LIST:
      return {
        ...state,
        packingList: {
          isLoading: false,
          isError: false,
          data: payload,
        },
      };
    case SET_PACKING_LIST_ERROR:
      return {
        ...state,
        packingList: {
          ...state.packingList,
          isError: true,
          isLoading: false,
        },
      };
    case SET_PACKING_LIST_NUMBER:
      return { ...state, packingList: { ...state.packingList, data: { ...state.packingList.data, number: payload } } };
    case ADD_DOCUMENT:
      return {
        ...state,
        documents: {
          count: action.payload.count,
          results: [...state.documents.results, ...action.payload.results],
        },
      };
    case SET_MORE_DOCUMENT:
      return {
        ...state,
        documents: {
          ...state?.documents,
          results: [...state?.documents?.results, ...payload?.results],
        },
        isLoading: false,
      };
    case ADD_PACKING_LIST_FILE: {
      const updatedPackingListData = { ...state.packingList.data, files: [...state.packingList.data.files, payload] };

      return { ...state, packingList: { ...state.packingList, data: updatedPackingListData } };
    }
    case DELETE_PACKING_LIST_FILE: {
      const updatedPackingListData = {
        ...state.packingList.data,
        files: state.packingList.data.files.filter((file) => +file.id !== +payload),
      };

      return { ...state, packingList: { ...state.packingList, data: updatedPackingListData } };
    }
    case SET_PACKING_ITEM_FILES:
      return { ...state, packingItemsFiles: { ...state.packingItemsFiles, [payload.itemId]: payload.files } };
    case ADD_PACKING_ITEM_FILE:
      return {
        ...state,
        packingItemsFiles: {
          ...state.packingItemsFiles,
          [payload.itemId]: [...state.packingItemsFiles[payload.itemId], payload.file],
        },
      };
    case DELETE_PACKING_ITEM_FILE:
      return {
        ...state,
        packingItemsFiles: {
          ...state.packingItemsFiles,
          [payload.itemId]: state.packingItemsFiles[payload.itemId].filter((file) => file.id !== payload.fileId),
        },
      };
    case SET_PACKING_ITEMS_FILES_LOADING:
      return { ...state, packingItemsFilesAreLoading: payload };
    default:
      return state;
  }
};

export const stateSelector = (state) => state.documents;
export const documentsSelector = createSelector(stateSelector, (state) => state.documents);
export const packingListSelector = createSelector(stateSelector, (state) => state.packingList.data);
export const packingListLoadingSelector = createSelector(stateSelector, (state) => state.packingList.isLoading);
export const packingListErrorSelector = createSelector(stateSelector, (state) => state.packingList.isError);
export const loadingStateSelector = createSelector(stateSelector, (state) => state.loading);

export const packingItemsFilesSelector = createSelector(stateSelector, (state) => state.packingItemsFiles);
export const packingItemsFilesLoadingSelector = createSelector(
  stateSelector,
  (state) => state.packingItemsFilesAreLoading
);
