import React, { useMemo } from "react";

import ButtonBase from "../ButtonBase";
import FieldErrorMessage from "../FieldErrorMessage/FieldErrorMessage";
import InputBase from "../InputBase";
import Modal from "../Modal";
import { Field, Form } from "react-final-form";
import InputMask from "react-input-mask";
import TextareaAutosize from "react-textarea-autosize";

import { parseInputMask } from "utils/parseInputMaskInField";
import { composeFieldValidators, email, maxLength, mustBeNumber, required } from "utils/validations";

import styles from "./TariffRequestModal.module.scss";

export interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title?: string;
  initialValues: any;
}

const TariffRequestModal = ({
  isOpen,
  onClose,
  onSubmit,
  title = "Заявка на расширенный тариф",
  initialValues,
}: IProps) => {
  const extendedInitialValues = useMemo(() => {
    return {
      last_name: initialValues?.last_name,
      first_name: initialValues?.first_name,
      middle_name: initialValues?.middle_name,
      email: initialValues?.email,
      phone_number: initialValues?.phone_number,
      comment: "",
    };
  }, [
    initialValues?.email,
    initialValues?.first_name,
    initialValues?.last_name,
    initialValues?.middle_name,
    initialValues?.phone_number,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} className={styles.modal}>
      <Form
        onSubmit={onSubmit}
        initialValues={extendedInitialValues}
        render={({ handleSubmit, hasValidationErrors }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.formContainer}>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="last_name"
                  label="Фамилия"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  validate={composeFieldValidators(required(), maxLength(20))}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="first_name"
                  label="Имя"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  validate={composeFieldValidators(required(), maxLength(20))}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="middle_name"
                  label="Отчество"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  validate={maxLength(20)}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  component={InputBase}
                  name="email"
                  label="E-mail"
                  classNameInput={styles.field}
                  variant="brandColorTransparent"
                  validate={composeFieldValidators(required(), email)}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  name="phone_number"
                  validate={composeFieldValidators(required(), maxLength(20), mustBeNumber)}
                  parse={parseInputMask}
                  render={({ input, meta }) => (
                    <div className={styles.decomposeFieldContainer}>
                      <label className={styles.label}>Телефон</label>
                      <InputMask
                        {...input}
                        id="phone_number"
                        name="phone_number"
                        className={styles.field}
                        mask="+7 (999) 999-99-99"
                        placeholder="Телефон"
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <FieldErrorMessage errorMessage={meta.error || meta.submitError} />
                      )}
                    </div>
                  )}
                />
              </div>
              <div className={styles.fieldContainer}>
                <Field
                  name="comment"
                  validate={maxLength(255, "Не более 255 символов")}
                  render={({ input, meta }) => (
                    <div className={styles.decomposeFieldContainer}>
                      <label className={styles.label}>Комментарий</label>
                      <TextareaAutosize
                        {...input}
                        minRows={3}
                        placeholder={"Текст комментария"}
                        className={styles.textarea}
                      />
                      {(meta.error || meta.submitError) && meta.touched && (
                        <FieldErrorMessage errorMessage={meta.error || meta.submitError} />
                      )}
                    </div>
                  )}
                />
              </div>
            </div>
            <footer className={styles.footer}>
              <ButtonBase onClick={onClose} secondary medium>
                Отменить
              </ButtonBase>
              <ButtonBase type="submit" primary medium disabled={hasValidationErrors}>
                Отправить
              </ButtonBase>
            </footer>
          </form>
        )}
      />
    </Modal>
  );
};

export default React.memo(TariffRequestModal);
