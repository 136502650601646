import React from 'react'
import styles from "./InfoItem.module.scss"

type propsType = {
  label: string;
  value: string;
}

function InfoItem({
  label,
  value
}: propsType) {
  return (
    <div className={styles.info}>
      <b>{label}</b>
      <span>{value}</span>
    </div>
  )
}

export default InfoItem