import React from 'react';

import { stopEventPropagation } from 'utils/stopEventPropagation';

import { IModal } from '../../types/types';
import { useModalDisplaying } from '../../hooks/useModalDisplaying';

import closeBtn from 'images/icons/closeModalBtn.svg';

import styles from './PopoverModal.module.scss';


const PopoverModal = ({ children, isVisible, setIsVisible }: IModal) => {
  const { closeModal, rootClasses } = useModalDisplaying({ isVisible, setIsVisible, styles });

  return (
    <div className={rootClasses.join(' ')} onClick={closeModal}>
      <div className={styles.myModalContent} onClick={stopEventPropagation}>
        <div className={styles.closeModalBtn} onClick={closeModal}>
          <img src={closeBtn} alt="х" />
        </div>
        {children}
      </div>
    </div>
  )
}

export default React.memo(PopoverModal);
