import React from 'react'
import { useDispatch } from 'react-redux'
import { tasksAggregationsSelector } from 'redux/modules/purchaser/Tasks/selectors'
import { getTasksAggregations } from 'redux/modules/purchaser/Tasks/thunks'
import { ItaskAggregationsParams } from 'redux/modules/purchaser/Tasks/types'
import { useTypedSelector } from 'redux/typedUseSelector'
import { tasksAggregationSectionsEnum, TASK_LISTS_TYPES } from '../constants'

export const useTaskAggregations = (type: TASK_LISTS_TYPES, building?: number) => {
    const dispatch = useDispatch()
    const aggregations = useTypedSelector(tasksAggregationsSelector)
    const section = type !== TASK_LISTS_TYPES.LISTS ? type : tasksAggregationSectionsEnum.tasks_lists
    const building_id = (!isNaN(building!) && building !== 0) ? building : undefined

    React.useLayoutEffect(() => {
        const params: ItaskAggregationsParams = {
            section: section as tasksAggregationSectionsEnum,
            building_id
        }
        dispatch(getTasksAggregations(params))
    }, [type, building])

    return aggregations[`${section}_${building_id||0}`] || {}
}