import React, { useCallback, useEffect, useState } from "react";

import RadioLookingAsCheckbox from "../../atoms/RadioLookingAsCheckbox";
import Checkbox from "../../Checkbox";
import InputBase, { VALUE_TYPES } from "../../atoms/InputBase";

import styles from "./index.module.scss";

export const termsNames = {
  pickup: "Самовывоз",
  free: "Бесплатная",
  paid: "Платная"
};

export const INITIAL_TERMS = [
  { term_type: "paid", confirmed: false },
  { term_type: "free", confirmed: false },
  { term_type: "pickup", confirmed: false }
];

const DeliveryTerms = ({ terms, disabled, termChangeHandler, disagreeDeliveryChange, multiplySelect = false }) => {
  const [localTerms, setLocalTerms] = useState();

  const onChangePaidTermCost = useCallback((e) => {
    termChangeHandler(localTerms.map((term) => term.term_type === "paid" ? { ...term, cost: e.target.value } : term));
  }, [termChangeHandler, localTerms]);

  const onSelectTerm = useCallback((selectedType, isConfirmed) => {
    termChangeHandler(localTerms.map((term) => {
      if (multiplySelect) return term.term_type === selectedType ? ({ ...term, confirmed: isConfirmed }) : term;
      return ({ ...term, confirmed: term.term_type === selectedType });
    }));

    if (isConfirmed && disagreeDeliveryChange) disagreeDeliveryChange(false);
  }, [termChangeHandler, localTerms, disagreeDeliveryChange, multiplySelect]);

  const ConfirmComponent = multiplySelect ? Checkbox : RadioLookingAsCheckbox;

  useEffect(() => {
    setLocalTerms((!terms || terms.length === 0) ? INITIAL_TERMS : terms);
  }, [terms]);

  if (!localTerms) return null;

  return (
    <div className={styles.deliveryTerms}>
      <header className={styles.header}>
        <h2 className={styles.headerText}>Условия доставки:</h2>
      </header>
      <div className={styles.content}>
        {localTerms.map((term) => (
          <div className={styles.checkRow} key={term.term_type}>
            <ConfirmComponent
              checked={term.confirmed}
              onChange={(e) => onSelectTerm(term.term_type, e.target.checked)}
              disabled={disabled}
            >
              {termsNames[term.term_type]}
            </ConfirmComponent>
            {term.term_type === "paid" && (
              <>
                <InputBase
                  valueType={VALUE_TYPES.PRICE}
                  className={styles.priceInputContainer}
                  classNameInput={styles.priceInput}
                  disabled={disabled}
                  onChange={onChangePaidTermCost}
                  value={term.cost}
                  placeholder="Цена, ₽"
                />
                <span className={styles.priceCurrency}>₽</span>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(DeliveryTerms);
