import React, { FC, useCallback, useEffect, useState } from "react";

import Modal from "components/UI/atoms/Modal";
import Check from "images/icons/Check";
import ConfirmWriteOffExpenditure, {
  ActionHandler,
  IExpenditure,
} from "components/UI/molecules/ConfirmWriteOffExpenditure/ConfirmWriteOffExpenditure";
import ButtonBase from "components/UI/atoms/ButtonBase";

import ConfirmRepository from "./domain/ConfirmRepository";
import styles from "./ConfirmDeliveryToProduction.module.scss";

export interface IConfirmDeliveryToProductionProps {
  expenditures: IExpenditure[];
  onSubmit: (expendituresIds: IExpenditure["id"][]) => void;
  onCancel: () => void;
  onChangeProductWriteOffTarget: ActionHandler;
}

const ConfirmDeliveryToProduction: FC<IConfirmDeliveryToProductionProps> = ({
  expenditures,
  onSubmit,
  onCancel,
  onChangeProductWriteOffTarget,
}) => {
  const [confirmRepository, setConfirmRepository] = useState(new ConfirmRepository<IExpenditure>(expenditures));

  const onConfirmExpenditure: ActionHandler = useCallback(
    (expenditureId) => {
      confirmRepository.confirm(expenditureId);
      setConfirmRepository(new ConfirmRepository<IExpenditure>(confirmRepository.confirmableItems));
    },
    [confirmRepository]
  );

  const onCancelExpenditure: ActionHandler = useCallback(
    (expenditureId) => {
      confirmRepository.cancel(expenditureId);

      if (confirmRepository.confirmableItems.length !== 0) {
        setConfirmRepository(new ConfirmRepository<IExpenditure>(confirmRepository.confirmableItems));
      } else {
        onCancel();
      }
    },
    [confirmRepository, onCancel]
  );

  const handleOnSubmit = useCallback(() => {
    onSubmit(confirmRepository.confirmableItems.map((item) => item.id));
  }, [onSubmit, confirmRepository]);

  useEffect(() => {
    setConfirmRepository(new ConfirmRepository<IExpenditure>(expenditures));
  }, [expenditures]);

  return (
    <Modal title={`Подтвердить назначение: ${confirmRepository.toConfirmCount > 0 ? confirmRepository.toConfirmCount : ""}`} onClose={onCancel} closeOnClickOutside={false} isOpen>
      <div data-testid="confirmDeliveryToProduction">
        <div className={styles.expenditures}>
          {confirmRepository.confirmableItems.map((expenditure) => (
            <ConfirmWriteOffExpenditure
              expenditure={expenditure}
              onConfirm={onConfirmExpenditure}
              onCancel={onCancelExpenditure}
              onChangeWriteOffTarget={onChangeProductWriteOffTarget}
              key={expenditure.id}
            />
          ))}
        </div>
        <footer className={styles.footer}>
          <ButtonBase data-testid="cancelDeliveryButton" onClick={onCancel} secondary>
            Отменить
          </ButtonBase>
          <ButtonBase
            data-testid="submitDeliveryButton"
            disabled={!confirmRepository.checkAreAllConfirm()}
            onClick={handleOnSubmit}
            primary
          >
            <Check />
            Подтвердить
          </ButtonBase>
        </footer>
      </div>
    </Modal>
  );
};

export default React.memo(ConfirmDeliveryToProduction);
