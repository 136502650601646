const moduleName = 'breadcrumbs';
const ADD_ITEM = `${moduleName}/ADD_ITEM`;
const CLEAR_CRUMBS = `${moduleName}/CLEAR_CRUMBS`;

const initialState = {
  crumbs: [],
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ADD_ITEM:
      const newCrumbs = payload.level === 1 ? [] : [...state.crumbs]
        .filter(crumb => (crumb.level && payload.level) ? crumb.level <= payload.level : true);

      const crumbId = newCrumbs.indexOf(
        newCrumbs.filter(
          crumb =>
            (crumb.text === payload.text) ||
            (crumb.type === payload.type) ||
            (payload.level !== false && (
              crumb.level === payload.level
            ))
        )[0]
      );

      if (crumbId !== -1) {
        newCrumbs.splice(crumbId, newCrumbs.length - crumbId);
      }

      newCrumbs.push(payload);

      return {
        ...state,
        crumbs: newCrumbs,
      };

    case CLEAR_CRUMBS:
      return {
        ...state,
        crumbs: [],
      };

    default:
      return state;
  }
};

export const addCrumb = crumb => ({
  type: ADD_ITEM,
  payload: crumb,
});

export const clearCrumbs = () => ({
  type: CLEAR_CRUMBS,
});
