import React from 'react';
import { RUBLE } from 'utils/constant';

export const splitThousands = (num, ruble = true, span = true, fixedLength = 2, isFloatingDecimals) => {
  const value = parseFloat(num || 0)
  let decimalLength = fixedLength

  if (isFloatingDecimals) {
    if ((value % 1).toFixed(2) > 0) {
      decimalLength = 2
    } else {
      decimalLength = 0
    }
  }

  return (
    span ?
      <span
        style={{
          whiteSpace: 'nowrap',
        }}
      >
        {value
          .toFixed(decimalLength)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        {ruble && ` ${RUBLE}`}
      </span>
      :
      value
        .toFixed(decimalLength)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ` ${ruble ? RUBLE : ""}`
  );
};
