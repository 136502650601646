import axios from "axios"
import React from "react"
import { useParams } from "react-router-dom"
import { IRouterParamsWithObjectId } from "types/routerTypes"
import { errorCatcher } from "utils/errorCatcher"

interface IProps {
    intervalId: number;
    onIntervalReload: (id: number) => void;
    objectId: number | string
}

export const useShareCompletedWork = ({ intervalId, onIntervalReload, objectId }: IProps) => {
    const [comment, setComment] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSended, setIsSended] = React.useState(false)

    const commentHanlder = React.useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value)
    }, [])

    const submitHandler = React.useCallback(() => {
        const data = {
            reason_msg: comment
        }
        setIsLoading(true)
        setIsSended(true)
        axios
            .post(`/building/${objectId}/fact-intervals/${intervalId}/share/`, data)
            .then(() => onIntervalReload(intervalId))
            .catch((err) => {
                errorCatcher(err)
                setIsSended(false)
            })
            .finally(() => setIsLoading(false))
    }, [comment, intervalId, objectId])

    return {
        comment,
        commentHanlder,
        isLoading,
        submitHandler,
        isSended
    }
}