import {combineReducers} from 'redux'

import finance from './finance'
import audit from './audit'
import summary from './summary'
import planFact from './planFact'

export const moduleName = 'finance'

export default combineReducers({
  index: finance,
  audit,
  summary,
  planFact
})
