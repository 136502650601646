import { compose } from "redux";
import axios from "axios";

import { errorCatcher } from "../../../../../../../utils/errorCatcher";

import { setMoreKsesAction } from "../actions";
import { ksesOffsetSelector } from "../reducer";

export const getMoreKses = (filters) => (dispatch, getState) =>
  axios
    .get("/building/work/approve_dates/", { params: { offset: ksesOffsetSelector(getState()), ...filters } })
    .then((response) => compose(dispatch, setMoreKsesAction)(response.data))
    .catch(errorCatcher);
