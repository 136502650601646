import React, { useCallback } from "react";
import cn from "classnames";
import { compose } from "redux";

import { transformDigitToFinancial } from "../../../../utils/transformDigitToFinancial";
import { beautifyNumberValue } from "../../../../utils/beautifyNumberValue";

import styles from "./index.module.scss";

export const VALUE_TYPES = { PRICE: "price", NUMBER: "number", TEXT: "text" };
const NUMBERS_FLOAT_CONSTRAINT = 4;

const transformPriceValue = (value) => {
  if (!value) return value;
  return transformDigitToFinancial(beautifyNumberValue(value), { withFloat: false });
};

const InputBase = (props) => {
  const {
    input, // for react-final-form Field
    variant = "primary",
    className,
    classNameInput,
    label,
    meta: { error, touched } = {},
    value,
    valueType = VALUE_TYPES.TEXT,
    onChange,
    ...anotherProps
  } = props;

  const transformEventTargetValue = useCallback((e) => {
    if (!e.target.value) return e;

    switch (valueType) {
      case VALUE_TYPES.NUMBER:
        return { ...e, target: { ...e.target, value: beautifyNumberValue(e.target.value, NUMBERS_FLOAT_CONSTRAINT) } };
      case VALUE_TYPES.PRICE:
        return { ...e, target: { ...e.target, value: beautifyNumberValue(e.target.value) } };
      default:
        return e;
    }
  }, [valueType]);

  const onBlur = useCallback((e) => {
    const changedValue = e.target.value.replace(/[.,]$/g, "");
    if (changedValue === e.target.value) return;

    if (valueType === VALUE_TYPES.PRICE || valueType === VALUE_TYPES.NUMBER) onChange({
      ...e,
      target: { ...e.target, value: beautifyNumberValue(changedValue) }
    });
  }, [valueType, onChange]);

  const callOnChangeWithNotEqualValues = useCallback((e) => {
    if (e.target.value === value) return;
    onChange && onChange(e);
  }, [value, onChange]);

  return (
    <div className={cn(styles.container, className)}>
      {label && (
        <label>{label}</label>
      )}
      <input
        {...input}
        {...anotherProps}
        className={cn(
          styles.input,
          styles[variant],
          classNameInput,
          { [styles.inputError]: touched && error, [styles.price]: valueType === VALUE_TYPES.PRICE }
        )}
        value={valueType === VALUE_TYPES.PRICE ? transformPriceValue(value) : value}
        onChange={compose(callOnChangeWithNotEqualValues, transformEventTargetValue)}
        onBlur={onBlur}
      />
      {touched && error && (
        <div className={cn(styles.errorMessage)}>{error}</div>
      )}
    </div>
  );
};

export default React.memo(InputBase);