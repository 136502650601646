import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import { tasksRouteParamsType } from "redux/modules/purchaser/Tasks/types";

import ButtonBase from "components/UI/atoms/ButtonBase";
import EmptyPlaceholder from "components/UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import { Spinner } from "components/UI/Spinner/Spinner";
import TemplateBase from "components/UI/templates/TemplateBase";
import ControlsBar from "./components/ControlsBar/ControlsBar";
import CreateTasksList from "./components/CreateTasksList/CreateTasksList";
import TasksModal from "./components/TasksModal/TasksModal";
import TasksIndicators from "./components/TasksIndicators/TasksIndicators";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import TasksListHeader from "./components/TasksListHeader/TasksListHeader";

import { useTasksLists } from "./hooks/useTasksListsData";
import { useTasksFilter } from "./hooks/useTasksFilter";
import { TASK_LISTS_TYPES } from "./constants";

import taskIcon from "images/icons/navigation/tasksIcon.svg";


function TasksLists() {
  const { objectId } = useParams<tasksRouteParamsType>()
  const { filters, filtersHandler, dateRangeHandler } = useTasksFilter()
  const { tasksLists, isTasksLoading, loadMoreTasksListsHandler } = useTasksLists(objectId!)

  const [isCreatingNewList, setIsCreatingNewList] = useState(false);
  const [isIndicatorsOpen, setIsIndicatorsOpen] = useState(true);

  const creatingNewListSwitcher = useCallback(() => {
    setIsCreatingNewList(prev => !prev)
  }, [])

  return (
    <TemplateBase>
      <TasksIndicators isOpen={isIndicatorsOpen} setIsOpen={setIsIndicatorsOpen} type={TASK_LISTS_TYPES.LISTS} />
      <ControlsBar
        onDateChange={dateRangeHandler}
        filters={filters}
        onChangeFilters={filtersHandler}
        variant="lists"
        onAddNew={creatingNewListSwitcher}
      />
      {isCreatingNewList && <CreateTasksList onClose={creatingNewListSwitcher} />}
      {isTasksLoading && <Spinner />}
      {!isTasksLoading && !tasksLists.results.length && (
        <EmptyPlaceholder text="Нет данных в разделе списков задач." img={taskIcon}>
          <ButtonBase onClick={creatingNewListSwitcher} primary>
            Новый список
          </ButtonBase>
        </EmptyPlaceholder>
      )}
      {!isTasksLoading &&
        !!tasksLists.results.length &&
        tasksLists.results.map((el) => (
          <TasksListHeader 
            key={el.id} 
            name={el.list_name} 
            id={el.id} 
            user={el.user} 
            count={el.tasks_count} 
            deadline_end={filters.deadline_end}
            deadline_start={filters.deadline_start}
            building_id={objectId} 
          />
        ))}
      <ShowMoreButton
        handleAdd={loadMoreTasksListsHandler}
        showedCount={tasksLists.results.length}
        allCount={tasksLists.count}
        isExists={!isTasksLoading}
      />
      <TasksModal presetBuildingId={objectId ? +objectId : 0}/>
    </TemplateBase>
  );
}

export default TasksLists;
