import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_LIMIT_EXPORT,
  deleteExport,
  exportsIsLoadingSelector,
  exportsSelector,
  loadExports,
  loadMoreExport,
} from "redux/modules/common/building/documents/exports";
import { exportSvg } from "../MakeExport/exportSvg";
import EmptyPlaceholder from "../../../UI/atoms/EmptyPlaceholder/EmptyPlaceholder";
import ButtonBase from "../../../UI/atoms/ButtonBase";
import { objectsBreadcrumbsSelector } from "redux/modules/common/building/objects";
import ConfirmationModal from "../../../UI/molecules/ConfirmationModal/index";
import ShowMoreButton from "components/UI/atoms/ShowMoreButton";
import { IIdAndName } from "types/idAndName";
import { Spinner } from "components/UI/Spinner/Spinner";
import { IExport } from "types/interfaces/Export";
import ExportRow from "./ExportRow/ExportRow";
import ExportTableHead from "./ExportTableHead/ExportTableHead";

interface Iprops {
  onClickExportBtn: () => void;
  canDeleteExport: boolean;
}

const Export: React.FC<Iprops> = ({ onClickExportBtn, canDeleteExport }) => {
  const dispatch = useDispatch();
  const exports = useSelector(exportsSelector);
  const isLoading = useSelector(exportsIsLoadingSelector);

  const [filters, setFilters] = useState({ limit: DEFAULT_LIMIT_EXPORT, offset: 0 });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [exportsFileId, setExportsFileId] = useState<null | number>(null);

  const breadcrumbsProjects = useSelector(objectsBreadcrumbsSelector);

  const formattedObjects = React.useMemo(() => {
    return breadcrumbsProjects.results.map((el: IIdAndName) => ({ id: el.id, name: el.id, label: el.name }));
  }, [breadcrumbsProjects]);

  const changeFilters = (name: string, value: string) => {
    setFilters((prevState) => ({ ...prevState, [name]: value }));
  };

  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };

  const deleteHandler = (id: number) => {
    dispatch(deleteExport(id));
    closeModal();
  };
  const openDeleteModalHandler = (id: number) => {
    setExportsFileId(id);
    setIsDeleteModalOpen(true);
  };
  const hasNextPage = useMemo(() => exports?.count > exports?.results?.length, [exports]);
  const loadMore = () => {
    dispatch(
      loadMoreExport({
        ...filters,
        offset: exports?.results?.length,
      })
    );
  };

  useEffect(() => {
    dispatch(loadExports(false, filters));
  }, [filters]);

  return (
    <>
      <ConfirmationModal
        variant="secondary"
        action={() => deleteHandler(exportsFileId as number)}
        isOpen={isDeleteModalOpen}
        onClose={closeModal}
        title="Подтвердите действие"
        acceptButtonText="Удалить"
      >
        Вы действительно хотите удалить выгрузку?
      </ConfirmationModal>
      <ExportTableHead
        onChangeFilter={changeFilters}
        formattedObjects={formattedObjects}
        onReload={() => dispatch(loadExports(false, filters))}
      />
      {exports?.results.map((el: IExport, i: number) => (
        <ExportRow
          key={el.id}
          exportDoc={el}
          idx={i}
          canDeleteExport={canDeleteExport}
          onDelete={openDeleteModalHandler}
        />
      ))}
      {!isLoading && !exports?.results.length && (
        <EmptyPlaceholder text="Нет выгрузок" svgNative={exportSvg}>
          <ButtonBase primary onClick={onClickExportBtn}>
            Создать выгрузку
          </ButtonBase>
        </EmptyPlaceholder>
      )}
      {isLoading && <Spinner />}
      <ShowMoreButton
        isExists={hasNextPage}
        handleAdd={loadMore}
        showedCount={exports?.results?.length}
        allCount={exports?.count}
      />
    </>
  );
};

export default React.memo(Export);
