import { dropNonSignificantZeros } from "./dropNonSignificantZeros";

const DEFAULT_OPTIONS = { withFloat: true };
const FINANCIAL_FRACTION_DIGITS = 2;

export type Options = {
  withFloat: boolean;
  withRuble?: boolean;
  withSign?: boolean;
  withPercent?: boolean;
  dropZeros?: boolean;
};

const leadToNumber = (value: string) => {
  const numberValue = Number(value);
  if (isNaN(numberValue)) throw new Error();
  return numberValue;
};

const localeString = (string: string, options: Options) => {
  let localedString = leadToNumber(string)
    .toLocaleString(
      "en",
      options.withFloat
        ? {
            minimumFractionDigits: FINANCIAL_FRACTION_DIGITS,
            maximumFractionDigits: FINANCIAL_FRACTION_DIGITS,
          }
        : {}
    )
    .replace(/,/g, " ");

  if (/\.0$/.test(string)) localedString += ".0";
  if (/\.$/.test(string)) localedString += ".";

  return localedString;
};

export const transformDigitToFinancial = (initialDigit: string | number, options: Options = DEFAULT_OPTIONS) => {
  try {
    let transformedDigit = localeString(String(initialDigit), { withFloat: options.withFloat });

    if (options.dropZeros) transformedDigit = (dropNonSignificantZeros(transformedDigit) as number).toString();
    if (options.withSign && +initialDigit > 0) transformedDigit = `+${transformedDigit}`;
    if (options.withSign && +initialDigit < 0) transformedDigit = `-${transformedDigit}`;
    if (options.withPercent) transformedDigit += "%";
    if (options.withRuble) transformedDigit += " ₽";

    return transformedDigit;
  } catch (e) {
    return initialDigit;
  }
};
