import React from "react";
import cn from 'classnames'

import { IRequest } from "../../OrdersComparison";

import orderComparisonStyles from "../../OrderComparison.module.scss";
import orderRequestCardStyles from "../OrderRequestCard/OrderRequestCard.module.scss";

import {transformPrice} from '../../utils'

export interface IExpenditureInfoProps {
  request: IRequest;
  hasBlueBg?: boolean
}

const ExpenditureInfo: React.FC<IExpenditureInfoProps> = ({ request, hasBlueBg }) => {
  const {name, estimate_price, estimate_count, estimate_measure, estimate_amount} = request.product_building?.estimate_expenditure || {}
  return (
    <div
      className={cn(orderComparisonStyles.expenditureName, orderRequestCardStyles.card, {
        [orderComparisonStyles.blueBg]: hasBlueBg,
      })}
    >
      <span className={orderRequestCardStyles.bold}>
        {name}
      </span>
      <div className={orderRequestCardStyles.block}>
        <div className={orderRequestCardStyles.blockName}>По проекту:</div>
      </div>
      <div className={orderRequestCardStyles.block}>
        <div className={orderRequestCardStyles.blockName}>Цена за ед.:</div>
        <div className={orderRequestCardStyles.bold}>
          {transformPrice(estimate_price)}
        </div>
      </div>
      <div className={orderRequestCardStyles.block}>
        <div className={orderRequestCardStyles.blockName}>Количество:</div>
        <div
          className={orderRequestCardStyles.bold}
        >{`${estimate_count} ${estimate_measure}`}</div>
      </div>
      <div className={orderRequestCardStyles.block}>
        <div className={orderRequestCardStyles.blockName}>Бюджет:</div>
        <div className={orderRequestCardStyles.bold}>
          {transformPrice(estimate_amount)}
        </div>
      </div>
    </div>
  );
};

export default ExpenditureInfo;
