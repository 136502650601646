import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import Icon from "components/UI/Icon";

import arrowSmallBack from "images/icons/arrowSmallBack.svg";

import styles from "./index.module.scss";


type propsType = {
  className?: string;
  link?: string | false;
  onClick?: (e?: React.MouseEvent) => void;
};

const ButtonBack = ({ className, link, onClick }: propsType) => {
  const history = useHistory();

  const clickHandler = useCallback(() => {
    if (onClick) {
      onClick();
      return;
    }
    if (link === false) return;
    if (link) {
      history.push(link);
      return;
    }
    history.goBack();
  }, [link, history, onClick]);

  return (
    <div className={cn(styles.button, className)} onClick={clickHandler}>
      <Icon icon={arrowSmallBack} className={styles.arrowLeft} />
      Назад
    </div>
  );
};

export default React.memo(ButtonBack);
