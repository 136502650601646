import TableReusableRow, { TableReusableCell } from 'components/UI/atoms/TableReusable/TableReusableRow'
import CrossRoundedIcon from 'images/icons/CrossRoundedIcon'
import SaveImportList from 'images/icons/SaveImportList'
import moment from 'moment'
import React from 'react'
import { IExport } from 'types/interfaces/Export'
import getShortFullName from 'utils/getShortFullName'
import { exportDocTypes } from '../constants'
import styles from "./ExportRow.module.scss"

interface Iprops {
    exportDoc: IExport,
    idx: number;
    canDeleteExport?: boolean;
    onDelete: (id: number) => void;
}

const ExportRow: React.FC<Iprops> = ({ exportDoc, idx, canDeleteExport, onDelete }: Iprops) => {
    return (
        <TableReusableRow className={styles.row}>
            <TableReusableCell>
                {idx + 1}
            </TableReusableCell>
            <TableReusableCell>
                {exportDocTypes[exportDoc.doc_type]}
            </TableReusableCell>
            <TableReusableCell isNoBreak>
                {exportDoc.building?.name || "Все объекты"}
            </TableReusableCell>
            <TableReusableCell isCentered>
                {`${moment(exportDoc.start_at).format("DD.MM.YY")} - ${moment(exportDoc.end_at).format("DD.MM.YY")}`}
            </TableReusableCell>
            <TableReusableCell isNoBreak>
                {getShortFullName(exportDoc.creator)}
            </TableReusableCell>
            <TableReusableCell isCentered>
                {moment(exportDoc.created_at).format("DD.MM.YYYY")}
            </TableReusableCell>
            <TableReusableCell>
                {exportDoc.status_name}
            </TableReusableCell>
            <TableReusableCell>
                <div className={styles.actions}>
                    <div className={styles.action}>
                        {exportDoc.status === "complete" && (
                            <a href={exportDoc.file} download>
                                <SaveImportList />
                            </a>
                        )}
                    </div>
                    {canDeleteExport && (
                        <div
                            className={styles.action}
                            onClick={() => {
                                onDelete(exportDoc.id)
                            }}
                        >
                            <CrossRoundedIcon />
                        </div>
                    )}
                </div>
            </TableReusableCell>
        </TableReusableRow>
    )
}

export default ExportRow