import React, { useMemo } from "react";

import { ItaskFile } from "../../../../../redux/modules/purchaser/Tasks/types";
import { stopEventPropagation } from "../../../../../utils/stopEventPropagation";

import InputFiles from "../../../../UI/atoms/InputFiles/InputFiles";
import downloadFileByUrl from "../../../../../utils/downloadFileByUrl";

import { TASK_MODAL_TYPES } from "../../constants";
import { useTaskFiles } from "../../hooks/useTaskFiles";

import styles from './TaskFiles.module.scss'

export interface ITaskFilesProps {
  files: ItaskFile[];
  taskId?: number;
  variant: TASK_MODAL_TYPES;
}

const TaskFiles: React.FC<ITaskFilesProps> = ({ files, taskId, variant }) => {
  const {
    files: filesAddBuffer,
    handleSetFiles,
    uploadFilesHandler,
    deleteFileHandler,
  } = useTaskFiles({
    taskId,
    variant,
  });

  const preparedFiles = useMemo(() => files && files.map((x) => ({ ...x, name: x.file_name })), [files]);

  const filesToShow = variant === TASK_MODAL_TYPES.ADD ? filesAddBuffer : preparedFiles;

  if (!files) return null;

  const canExtractFiles = variant !== TASK_MODAL_TYPES.ADD;
  const canUploadFiles = variant !== TASK_MODAL_TYPES.VIEW;
  const canDeleteFiles = variant !== TASK_MODAL_TYPES.VIEW;

  const getFilesHandler = (e: React.MouseEvent) => {
    stopEventPropagation(e);
    preparedFiles?.forEach((el) => {
      downloadFileByUrl(el.file, el.name || el.file_name);
    });
  };

  return (
    <div className={styles.taskFiles}>
      <div className={styles.filesHead}>
        <span>Список вложений: {files?.length}</span>
        {canExtractFiles && !!files.length && <div onClick={getFilesHandler}>Выгрузить</div>}
      </div>
      <InputFiles
        value={filesToShow}
        setValue={handleSetFiles}
        uploadFilesDirectly={(file: File) => uploadFilesHandler([file])}
        removeFileDirectly={deleteFileHandler}
        permissions={{
          addFiles: canUploadFiles,
          deleteFiles: canDeleteFiles,
        }}
        canExpand={undefined}
        disabled={undefined}
      />
    </div>
  );
};

export default TaskFiles;
