import React, { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { memoize } from "lodash";

import Expenditure from "../../../../UI/molecules/Expenditure/Expenditure";
import EstimateSection, {
  ESTIMATE_SECTION_VARIANTS,
} from "../../../../UI/molecules/EstimateSection/EstimateSection";
import { AMOUNTS_VARIANTS } from "../../../../UI/molecules/EstimateSection/components/Amounts/Amounts";
import SectionPlanModal from "../SectionPlanModal/SectionPlanModal";

import { TabsEnum } from "../../enums";

import { stopEventPropagation } from "../../../../../utils/stopEventPropagation";
import { stringifyArgs } from "../../../../../utils/stringifyArgs";

import styles from "./SubSection.module.scss";

const EMPTY_ARRAY = [];

const SubSection = ({
  objectId,
  section,
  expenditures,
  measure,
  activeTab,
  loadProduct,
  isShared,
  isIndicatorsOpen,
  canSharing,
  permissions,
  onCheck,
  checkedExpenditures,
  checkedSections,
  expendituresSubMaterials,
  intervals,
  expendituresOverview,
  sectionPlanData,
  onReloadSubSections,
}) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => setIsActive(!isActive);

  const subSectionStyle = useMemo(() => {
    let topOffset = 15.6;
    if (!canSharing) topOffset = topOffset - 3;
    if (!isIndicatorsOpen) topOffset = topOffset - 6.3;
    return { top: `${topOffset}rem` };
  }, [canSharing, isIndicatorsOpen]);

  const productHeaderStyle = useMemo(() => {
    let topOffset = 18.8;
    if (!canSharing) topOffset = topOffset - 3;
    if (!isIndicatorsOpen) topOffset = topOffset - 6.3;
    return { top: `${topOffset}rem` };
  }, [canSharing, isIndicatorsOpen]);

  const onCheckExpenditure = useMemo(
    () =>
      memoize(
        (expenditureId) => (isChecked) => onCheck(isChecked, expenditureId),
        stringifyArgs
      ),
    [onCheck]
  );

  const onCheckSubSection = useCallback(
    (isChecked) => onCheck(isChecked, section.id),
    [onCheck, section.id]
  );

  const amounts = useMemo(
    () => ({
      all: section.indicators ? section.indicators.estimate_amount : 0,
      completed: section.indicators ? section.indicators.work_completed : 0,
      accepted: section.indicators ? section.indicators.amount_accepted : 0,
      invested: section.indicators ? section.indicators.invested : 0,
      variant: AMOUNTS_VARIANTS.INLINE,
      measure,
    }),
    [section.indicators, measure]
  );

  const [isSectionPlanModalOpen, setIsSectionPlanModalOpen] =
    React.useState(false);

  const sectionPlanModalHandler = React.useCallback(() => {
    setIsSectionPlanModalOpen((prev) => !prev);
  }, []);

  const sectionModalCloseHandler = React.useCallback(() => {
    onReloadSubSections?.(section.parent_id);
    setIsSectionPlanModalOpen(false);
  }, [onReloadSubSections, section.parent_id]);

  return (
    <div
      className={cn(styles.container, { [styles.active]: isActive })}
      data-testid="subSection"
    >
      <div
        className={cn(styles.titleContainer, { [styles.active]: isActive })}
        style={subSectionStyle}
        onClick={handleClick}
        data-testid="sectionActiveTrigger"
      >
        <EstimateSection
          section={section}
          isChecked={checkedSections.includes(section.id)}
          onCheck={onCheckSubSection}
          amounts={amounts}
          variant={
            isActive
              ? ESTIMATE_SECTION_VARIANTS.secondary
              : ESTIMATE_SECTION_VARIANTS.primary
          }
          canCheck={!isShared && !isActive && canSharing}
          isDisplayAddButton={activeTab === "plan"}
          onAddButtonClick={sectionPlanModalHandler}
          isDisplayEstimateStateMark
          isDisplayAmounts
          sectionPlanData={sectionPlanData}
        />
      </div>
      {isActive && (
        <>
          <div className={cn(styles.header)} style={productHeaderStyle}>
            <div className={styles.numberCol}>№</div>
            <div className={styles.nameCol}>Наименование</div>
            <div className={styles.countCol}>Количество</div>
            <div className={styles.addIntervalCol} />
            <div
              className={styles.intervalCol}
              data-testid="sectionFirstIntervalCol"
            >
              {activeTab === TabsEnum.progress ? "В производстве" : "План"}
            </div>
            <div
              className={styles.intervalCol}
              data-testid="sectionSecondIntervalCol"
            >
              {activeTab === TabsEnum.progress ? "Выполнено" : "Согласовано"}
            </div>
            <div
              className={styles.intervalCol}
              data-testid="sectionThirdIntervalCol"
            >
              {activeTab === TabsEnum.progress ? "Принято" : "Факт"}
            </div>
          </div>
          {expenditures.map((expenditure) => (
            <Expenditure
              buildingId={objectId}
              subMaterials={
                expendituresSubMaterials[expenditure.id] || EMPTY_ARRAY
              }
              expenditure={
                expendituresOverview[objectId]?.find(
                  (el) => el.id === expenditure.id
                ) || expenditure
              }
              loadProduct={loadProduct}
              variant={activeTab}
              isShared={isShared}
              check={onCheckExpenditure(expenditure.id)}
              isChecked={checkedExpenditures.indexOf(expenditure.id) !== -1}
              canCheck={!isShared}
              sectionName={section.name}
              intervals={intervals[expenditure.id]}
              actions={EMPTY_ARRAY}
              permissions={permissions}
              key={expenditure.id}
            />
          ))}
        </>
      )}
      {activeTab === "plan" && (
        <div onClick={stopEventPropagation}>
          <SectionPlanModal
            isOpen={isSectionPlanModalOpen}
            onClose={sectionModalCloseHandler}
            sectionName={section.name}
            sectionId={section.id}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(SubSection);
