import store from '../redux/store';
import {addNotification} from "../redux/modules/common/notifications";

let socket = null;
let toBeKilled = false;

const events = {
  notification: [data => store.dispatch(addNotification(data))],
};

export const socketAddEvent = (name, event) => {
  if (events[name]) {
    if (!events[name].find(item => item === event)) {
      events[name].push(event)
    }
  } else {
    events[name] = [event];
  }
};

export const socketRemoveEvent = (name, event) => {
  events[name] = events[name].filter( item => item !== event  );
};

export const socketSend = data => {
  try {
    socket.send(JSON.stringify(data));
  } catch (err) {
    console.error(err);
  }
};

export const socketConnect = (token) => {
  try {
    if (socket) {
      socketKill();
    }
    socket = new WebSocket(`${window.externalConfig._websocketURL}/?auth_token=${token}`);

    socket.onopen = () => {
      console.log('WebSockets connection created.');
    };

    socket.onmessage = event => {
      const data = JSON.parse(event.data);
      events[data.ws_type]?.forEach(event => event?.(data));
    };

    socket.onclose = () => {
      if (!toBeKilled) {
        socket = null;
        socketConnect(token);
      }
    };
  } catch (error) {
    console.log(error);
  }
};

export const socketKill = () => {
  toBeKilled = true;
  socket && socket.close();
  socket = null;
  toBeKilled = false;
  console.log('WebSockets connection killed.');
};
