import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { useHistory, useParams } from "react-router-dom";
import { isEmpty, isEqual, memoize, xorWith } from "lodash";
import { message } from "antd";
import moment from "moment";

import {
  acceptPackingList as acceptPackingListRequest,
  addFileInPackingList,
  changePackingListDate,
  deleteFileFromPackingList,
  loadPackingListV2,
} from "redux/modules/common/documents/documents";
import {
  packingItemsFilesLoadingSelector,
  packingItemsFilesSelector,
  packingListErrorSelector,
  packingListLoadingSelector,
  packingListSelector,
} from "redux/modules/common/documents/reducer";
import { getPackingItemsFiles } from "redux/modules/common/documents/thunks/getPackingItemsFiles";
import { addPackingItemFile } from "redux/modules/common/documents/thunks/addPackingItemFile";
import { deletePackingItemFile } from "redux/modules/common/documents/thunks/deletePackingItemFile";
import { setPackingListNumber } from "redux/modules/common/documents/thunks/setPackingListNumber";
import { commentsLoadingSelector, commentsSelector } from "redux/modules/common/comments/reducer";
import { createPackingItemComment } from "redux/modules/common/comments/thunks/createPackingItemComment";
import { getPackingItemComments } from "redux/modules/common/comments/thunks/getPackingItemComments";

import { stringifyArgs } from "utils/stringifyArgs";
import { serializeFiles } from "utils/serializeFiles";

import { Spinner } from "../../UI/Spinner/Spinner";
import BackNavigationBar from "../../UI/atoms/BackNavigationBar/BackNavigationBar";
import Paper from "../../UI/templates/Paper/Paper";

import { getSerializedProductsComments } from "../Requisition/utils/getSerializedProductsComments";

import Header from "./components/Header";
import Products from "./components/Products";
import SplitModal from "./components/SplitModal";
import Footer from "./components/Footer";

import PackingListValidator from "./domain/PackingListValidator";
import { PACKING_LIST_STATUSES } from "./constants";
import { calculateAmount } from "./helpers/calculateAmount";
import styles from "./index.module.scss";

const PackingList = ({ propsPackingListId = null, permissions, isSimplified }) => {
  const paramsPackingListId = useParams().packingListId;
  const packingListId = paramsPackingListId || propsPackingListId;
  const history = useHistory();
  const from = new URLSearchParams(history.location.search).get("from");

  const dispatch = useDispatch();
  const packingList = useSelector(packingListSelector);
  const packingListIsLoading = useSelector(packingListLoadingSelector);
  const packingListIsError = useSelector(packingListErrorSelector);
  const isPurchaser = useSelector((state) => state.auth.entity.type === "pcr");

  const productsFiles = useSelector(packingItemsFilesSelector);
  const productsFilesAreLoading = useSelector(packingItemsFilesLoadingSelector);

  const productsComments = useSelector(commentsSelector);
  const productsCommentsAreLoading = useSelector(commentsLoadingSelector);

  const [comment, setComment] = useState("");
  const [products, setProducts] = useState(null);
  const [splitModalIsOpen, setSplitModalIsOpen] = useState(false);

  const productsHaveChanges = React.useMemo(() => {
    return !isEmpty(xorWith(products, packingList?.items, isEqual));
  }, [products, packingList?.items, packingListIsLoading]);

  const onChangePackingListNumber = useCallback(
    (number) => compose(dispatch, setPackingListNumber)(packingListId, number),
    [packingListId]
  );

  const commentChangeHandler = useCallback((e) => setComment(e.target.value), []);

  const onChangeProductItem = useCallback(
    (changedProductId, changedItemName) => (e) => {
      const changedItemValue = e.target.value;

      setProducts((prevState) =>
        prevState?.map((product) =>
          product.id === changedProductId
            ? {
                ...product,
                [changedItemName]: changedItemValue,
              }
            : product
        )
      );
    },
    []
  );

  const onDeleteProduct = useCallback(
    (deletedProductId) => () => {
      setProducts((prevState) => {
        if (prevState.length <= 1) {
          message.error("В УПД должен быть как минимум 1 товар");
          return prevState;
        }

        return prevState?.map((product) =>
          product.id === deletedProductId ? { ...product, is_removed: true } : product
        );
      });
    },
    []
  );

  const productsWithoutRemoved = useMemo(() => products?.filter((product) => !product.is_removed) || [], [products]);

  const memoizedOnDeleteProduct = useMemo(() => memoize(onDeleteProduct, stringifyArgs), [onDeleteProduct]);

  const memoizedOnChangeProductItem = useMemo(() => memoize(onChangeProductItem, stringifyArgs), [onChangeProductItem]);

  const handleSplitModalOpen = useCallback(
    (status) =>
      setSplitModalIsOpen((prevState) => {
        if (status !== undefined) {
          return status;
        } else {
          return !prevState;
        }
      }),
    []
  );

  const acceptPackingList = useCallback(
    (isSplit = false) => {
      if (!packingList || !products) return;

      compose(dispatch, acceptPackingListRequest)(packingList.id, {
        comment,
        items: products.map((product) => ({
          id: product.id,
          count_get: product.count_get,
          amount: product.amount || calculateAmount(product.count_get, product.price),
          is_removed: product.is_removed,
        })),
        refuse_product: !isSplit,
      });
      handleSplitModalOpen(false);
    },
    [products, packingList, comment]
  );

  const acceptPackingListWithSplitting = useCallback(() => {
    acceptPackingList(true);
  }, [acceptPackingList]);

  const onValidatorError = (errors) => errors?.forEach((error) => message.error(error));

  const onAccept = useCallback(() => {
    const packingListValidator = new PackingListValidator(onValidatorError);

    if (!packingListValidator.validate(packingList.number, products)) return;

    if (productsHaveChanges) {
      handleSplitModalOpen();
      return;
    }
    acceptPackingList();
  }, [packingList?.number, productsHaveChanges, acceptPackingList, products, productsWithoutRemoved]);

  const handleAddFiles = useCallback(
    (files) => files.forEach((file) => compose(dispatch, addFileInPackingList)(packingListId, file)),
    [packingListId]
  );

  const handleDeleteFile = useCallback(
    (removedFileId) => compose(dispatch, deleteFileFromPackingList)(packingListId, removedFileId),
    [packingListId]
  );

  const changeExecutionDate = useCallback(
    (changedDate) => compose(dispatch, changePackingListDate)(packingListId, moment(changedDate).format("YYYY-MM-DD")),
    [packingListId]
  );

  const addProductFiles = useCallback(
    (productId, addedFiles) =>
      addedFiles.forEach((file) => compose(dispatch, addPackingItemFile)(productId, file.file)),
    []
  );

  const deleteProductFile = useCallback(
    (productId, file) => compose(dispatch, deletePackingItemFile)(productId, file.id),
    []
  );

  const serializedProductsFiles = useMemo(() => {
    const result = {};
    Object.entries(productsFiles).forEach(([productId, files]) => (result[productId] = serializeFiles(files)));
    return result;
  }, [productsFiles]);

  const handleCreateProductComment = useCallback(
    (productId, comment) => compose(dispatch, createPackingItemComment)(productId, comment),
    []
  );

  const handleGetProductComments = useCallback((productId) => compose(dispatch, getPackingItemComments)(productId), []);

  const serializedProductsComments = useMemo(() => {
    const result = {};
    Object.entries(productsComments).forEach(
      ([productId, comments]) => (result[productId] = getSerializedProductsComments(comments))
    );
    return result;
  }, [productsComments]);

  useEffect(() => {
    if (!packingListId) return;
    compose(dispatch, loadPackingListV2)(packingListId);
  }, [packingListId]);

  useEffect(() => {
    if (!packingList) return;

    setProducts(packingList.items || []);
    setComment(packingList.comment);

    if (packingList.items) compose(dispatch, getPackingItemsFiles)(packingList.items.map((product) => product.id));
  }, [packingList?.items, packingList?.comment]);

  if (packingListIsLoading || productsFilesAreLoading)
    return <Spinner />

  if (packingListIsError) return null;

  const canEditPackingList =
    packingList.status === PACKING_LIST_STATUSES.waitingShipment && permissions.viewPackingListAccept;

  return (
    <>
      <div className={styles.packingListContainer}>
        {!isSimplified && (
          <BackNavigationBar title="Документы" backLink={from} rightSideText={packingList?.building?.name} />
        )}
        <Paper>
          <div className={styles.packingList}>
            <Header
              packingList={packingList}
              onChangeNumber={onChangePackingListNumber}
              addFiles={handleAddFiles}
              deleteFile={handleDeleteFile}
              canEdit={canEditPackingList}
              changeExecutionDate={changeExecutionDate}
              subPackingList={packingList.subpackinglist}
              filesPermissions={permissions}
              isSimplified={isSimplified}
            />
            {productsWithoutRemoved.length !== 0 && (
              <Products
                products={productsWithoutRemoved}
                productsComments={serializedProductsComments}
                productsCommentsAreLoading={productsCommentsAreLoading}
                createProductComment={handleCreateProductComment}
                getProductComments={handleGetProductComments}
                files={serializedProductsFiles}
                addFiles={addProductFiles}
                deleteFile={deleteProductFile}
                onChangeProductItem={memoizedOnChangeProductItem}
                canEdit={canEditPackingList}
                onDeleteProduct={memoizedOnDeleteProduct}
                isPurchaser={isPurchaser}
                permissions={permissions}
              />
            )}
            <Footer
              canEditPackingList={canEditPackingList}
              productsHaveChanges={productsHaveChanges}
              onAccept={onAccept}
              packingList={packingList}
              commentChangeHandler={commentChangeHandler}
              comment={comment}
            />
          </div>
        </Paper>
      </div>
      <SplitModal
        isOpen={splitModalIsOpen}
        onClose={handleSplitModalOpen}
        acceptPackingList={acceptPackingList}
        acceptPackingListWithSplitting={acceptPackingListWithSplitting}
      />
    </>
  );
};

export default React.memo(PackingList);
