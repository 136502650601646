import React from "react";

import { splitThousands } from "../../../../../../utils/splitThousands";

export const priceColumnFormatter = (cellData, row) => {
  const showStatus = () => {
    if (row.price_changed === "up") return <span className="change-icon change-icon_up" />;
    if (row.price_changed === "dw") return <span className="change-icon change-icon_down" />;
  };

  return (
    <div>
      <div style={{ width: "100%", textAlign: "right" }}>{splitThousands(row.price, false, false)}</div>
      <div title={`Стартовая цена: ${row.start_price}`}>{showStatus()}</div>
    </div>
  );
};
