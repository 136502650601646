import { MatchingStatusesEnum } from "components/UI/atoms/MatchingIcon/MatchingIcon";

export const getProductMatchingStatus = (product: {
  product_building: { type: string; estimate_expanditure: string; created_from_estimate: boolean };
}) => {
  if (product.product_building.type === "out_of_estimate" && !product.product_building.estimate_expanditure) {
    return MatchingStatusesEnum.outOfEstimate;
  }
  if (product.product_building.created_from_estimate) return MatchingStatusesEnum.auto;
  if (product.product_building.estimate_expanditure) return MatchingStatusesEnum.manual;
};
