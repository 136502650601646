import React, { useCallback, useMemo } from "react";
import cn from "classnames";

import Checkbox, { ALIGNS } from "../../../../UI/Checkbox";

import { ESTIMATE_ITEMS_TYPES } from "../../constants";
import { buildExpendituresDictionaryByIds } from "../../utils/buildExpendituresDictionaryByIds";

import styles from "./AllChecker.module.scss";

const AllChecker = ({
                      className,
                      estimateItemsType,
                      checkOnceEstimateItem,
                      sections,
                      expenditures,
                      checkerItems,
                      subsectionId
                    }) => {
  const sectionsLengthWithoutDuplicates = useMemo(() => {
    if (!sections) return 0;
    const sectionsIds = {};
    sections.forEach((section) => sectionsIds[section.id] = true);
    return Object.keys(sectionsIds).length;
  }, [sections]);

  const checkedDisplayedSectionsCount = useMemo(() => {
    if (!sections) return 0;
    const displayedSectionsIds = {};
    sections.forEach((section) => displayedSectionsIds[section.id] = true);

    return Object.entries(checkerItems).reduce((acc, [sectionId, isChecked]) => {
      if (displayedSectionsIds[sectionId] && isChecked) return acc + 1;
      return acc;
    }, 0);
  }, [checkerItems, sections]);

  const checkAllSections = useCallback((isChecked) => {
    if (!sections) return;
    sections.forEach((section) => checkOnceEstimateItem(section.id, isChecked));
  }, [sections, checkOnceEstimateItem]);

  const checkAllExpenditures = useCallback((isChecked) => {
    if (!expenditures) return;
    expenditures.results.forEach((expenditure) => checkOnceEstimateItem(expenditure.id, isChecked));
  }, [expenditures?.results, checkOnceEstimateItem]);

  const handleCheckAll = useCallback((e) => {
    if (estimateItemsType === ESTIMATE_ITEMS_TYPES.SECTION) checkAllSections(e.target.checked);
    if (estimateItemsType === ESTIMATE_ITEMS_TYPES.EXPENDITURE) checkAllExpenditures(e.target.checked);
  }, [estimateItemsType, checkAllSections, checkAllExpenditures]);

  const checkedDisplayedExpendituresCount = useMemo(() => {
    if (!expenditures) return 0;
    if (checkerItems[+subsectionId]) return expenditures.results.length;
    const displayedExpendituresIds = buildExpendituresDictionaryByIds(expenditures.results);
    return Object.entries(checkerItems).reduce((acc, [estimateItemId, isChecked]) => {
      if (displayedExpendituresIds[estimateItemId] && isChecked) return acc + 1;
      return acc;
    }, 0);
  }, [checkerItems, expenditures?.results, subsectionId]);

  const allExpendituresAreChecked = useMemo(() => {
    if (!expenditures) return false;
    return checkedDisplayedExpendituresCount === expenditures.results.length;
  }, [checkedDisplayedExpendituresCount, expenditures?.results.length]);

  const allCheckedCount = useMemo(() => {
    if (estimateItemsType === ESTIMATE_ITEMS_TYPES.SECTION) return checkedDisplayedSectionsCount;
    if (estimateItemsType === ESTIMATE_ITEMS_TYPES.EXPENDITURE) return checkedDisplayedExpendituresCount;
  }, [estimateItemsType, checkedDisplayedSectionsCount, checkedDisplayedExpendituresCount]);

  const allAreChecked = useMemo(() => {
    if (allCheckedCount === 0) return false;
    if (estimateItemsType === ESTIMATE_ITEMS_TYPES.SECTION) return checkedDisplayedSectionsCount === sectionsLengthWithoutDuplicates;
    if (estimateItemsType === ESTIMATE_ITEMS_TYPES.EXPENDITURE) return allExpendituresAreChecked;
  }, [sectionsLengthWithoutDuplicates, allCheckedCount, estimateItemsType, allExpendituresAreChecked, checkedDisplayedSectionsCount]);

  return (
    <div className={cn(styles.checker, className)}>
      <Checkbox
        className={styles.checkbox}
        align={ALIGNS.LEFT}
        checked={allAreChecked}
        onChange={handleCheckAll}
      >
        Выбрать все
      </Checkbox>
      <span>Выбрано: {allCheckedCount}</span>
    </div>
  );
};

export default React.memo(AllChecker);
