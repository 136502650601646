import React, { useRef, useState } from "react";
import { useDispatch } from 'react-redux'
import styles from './index.module.scss';
import cn from 'classnames';
import Conture from '../../../../images/icons/Conture';
import ButtonBase from '../ButtonBase';
import CalendarRange from '../../molecules/CalendarRange';
import moment from 'moment';
import useEscapeHandler from '../../../../utils/hooks/useEscapeHandler'
import { loadPaymentV2 } from '../../../../redux/modules/purchaser/payments'
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

const SearchRangeDateFilter = ({ title, setFilterStart, setFilterEnd, last = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEscapeHandler(() => setIsOpen(false));

  const dispatch = useDispatch()

  const [mark, setMark] = useState(false);
  const [dateStart, setDateStart] = useState(moment());
  const [dateEnd, setDateEnd] = useState(moment());


  const ref = useRef();

  const handleSaveValue = () => {
    setFilterStart(moment(dateStart).format("YYYY-MM-DD"));
    setFilterEnd(moment(dateEnd).format("YYYY-MM-DD"));
    setMark(true);
    setIsOpen(false);
  };

  const handleRemoveValue = () => {
    setFilterStart(null);
    setFilterEnd(null);
    setDateStart(moment());
    setDateEnd(moment());
    setIsOpen(false);
    setMark(false)
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className={styles.container} ref={ref}>
      <div className={cn(styles.button, {
        [styles.active]: isOpen,
      })} onClick={() => setIsOpen(!isOpen)}>
        <Conture />
        {mark && <div className={styles.redMark}></div>}
      </div>
      {isOpen &&
        <div className={cn(styles.modal, { [styles.modalLast]: last })}>
          <div className={styles.title}>{title}</div>
          <CalendarRange
            defaultDateStart={dateStart}
            setDefaultDateStart={setDateStart}
            defaultDateEnd={dateEnd}
            setDefaultDateEnd={setDateEnd}
          />
          <div className={styles.actionBlock}>
            <ButtonBase secondary small
              onClick={handleRemoveValue}>Очистить</ButtonBase>
            &nbsp;&nbsp;&nbsp;
            <ButtonBase primary small onClick={handleSaveValue}>Применить</ButtonBase>
          </div>
        </div>
      }
    </div>
  );
};

export default SearchRangeDateFilter;