import { IMaterialInPlanInterval, ISerializedMaterialInPlan } from "types/interfaces/Materials"
import { formatMaterialCount } from "utils/formatMaterialCount"

export const serializePlanMaterialsInEditPlan = (materials: IMaterialInPlanInterval[]): ISerializedMaterialInPlan[] => {
    return materials.map((el) => {
        return {
            count: formatMaterialCount(el.material.estimate_expanditure.count, el.material.estimate_expanditure.measure),
            name: el.material.name,
            measure: el.material.estimate_expanditure.measure,
            local_count: el.count,
            id: el.material.id
        }
    })
}

export const filterRestPlannedMaterials = (materials: ISerializedMaterialInPlan[], addedIds: number[]) => {
    return materials.filter(el => {
        return !addedIds.includes(el.id)
    })
}

export const mapEditingPlanMaterials = (materials: ISerializedMaterialInPlan[], prevMaterials: IMaterialInPlanInterval[]) => {
    const savingMaterialsIds = materials.map(el => el.id)
    const deletingMaterials = prevMaterials.filter(el => !savingMaterialsIds.includes(el.material.id)).map(el => ({ material_id: el.material.id, is_deleted: true, count: el.count }))
    const savingMaterials =  materials.map(el => ({ material_id: el.id, count: el.local_count || el.count }))

    return [...savingMaterials, ...deletingMaterials]
}