import React, { MouseEventHandler, useCallback } from "react";
import cn from "classnames";

import { MATERIALS_TAB_ID, WORKS_TAB_ID, MT_REM } from "../../../../constants";
import { ISpittingTreeElement, ManufacturingTabsType } from "../../../../types";

import RublePopover from "../../../Popovers/RublePopover/RublePopover";
import InfoPopover from "../../../Popovers/InfoPopover/InfoPopover";

import styles from "./ProjectsBranch.module.scss";
import { useDispatch } from "react-redux";
import {setActiveBranch} from '../../../../../../../redux/modules/common/building/manufacturing/thunks'

export interface IProjectsBranchProps {
  branch: ISpittingTreeElement;
  type: ManufacturingTabsType;
  checkIsExpandedBranchId: (id: number) => boolean;
  toggleBranch: (branch: ISpittingTreeElement) => void;
  index: number;
  checkIsCheckboxVisible: (branch: ISpittingTreeElement) => boolean;
  checkIsCheckboxTrue: (branch: ISpittingTreeElement) => boolean;
  onHover: MouseEventHandler;
  onLeave: MouseEventHandler;
}

const ProjectsBranch: React.FC<IProjectsBranchProps> = ({
  branch,
  toggleBranch,
  index,
  type,
  checkIsCheckboxTrue,
  checkIsCheckboxVisible,
  onHover,
  onLeave,
}) => {
  const hide = checkIsCheckboxTrue(branch);

  const infoIndicators = {
    ...branch.data?.indicators,
    count: branch.data?.count,
    measure: branch.data?.measure,
  };
  const rubleIndicators = branch.data?.indicators;

  const dispatch = useDispatch();

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onHover?.(e);
      dispatch(setActiveBranch({ index: index, eventFrom: "tree" }));
    },
    [onHover, index]
  );

  return (
    <div
      className={cn(styles.branch, {
        [styles.branchChild]: branch.lvl === 2,
        [styles.branchChildActive]: branch.lvl === 2 && branch.childCount > 0,
        [styles.branchExpenditure]: branch.lvl === 3,
        [styles.branchExpenditureChild]: branch.lvl === 4,
      })}
      style={{ top: `${index * 3 + MT_REM}rem` }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onLeave}
    >
      <div className={styles.branchNameWrapper}>
        {checkIsCheckboxVisible(branch) && (
          <div
            className={cn(
              styles.branchCheckbox,
              {
                [styles.branchCheckboxTrue]: hide,
              },
              styles.branchCheckboxFalse
            )}
            onClick={() => toggleBranch(branch)}
          />
        )}
        <div className={styles.branchName}>{branch.name}</div>
      </div>
      <div className={styles.branchBubbles}>
        {branch.data?.indicators && !branch.childCount && (
          <>
            <div className={styles.bubbleRuble}>
              <RublePopover indicators={rubleIndicators} type={type} />
            </div>
            <div className={styles.bubbleInfo}>
              <InfoPopover indicators={infoIndicators} type={type} />
            </div>
          </>
        )}
        {branch.count_work !== undefined && branch.count_work > 0 && type === WORKS_TAB_ID && (
          <div className={styles.bubbleCount}>
            <div className={styles.count}>{branch.count_work}</div>
          </div>
        )}
        {branch.count_material !== undefined && branch.count_material > 0 && type === MATERIALS_TAB_ID && (
          <div className={styles.bubbleCount}>
            <div className={styles.count}>{branch.count_material}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsBranch;
