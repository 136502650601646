import React from 'react';

import CircleComponents from 'components/UI/molecules/CircleComponents';
import IndicatorsWrapper from 'components/UI/molecules/Indicators/IndicatorsWrapper/IndicatorsWrapper';

import { TASK_LISTS_TYPES } from '../../constants';
import { useTaskAggregations } from '../../hooks/useTaskAggregations';
import { isNanChecker } from 'utils/isNanChecker';

import styles from "./TasksIndicators.module.scss";


interface Iprops {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  type: TASK_LISTS_TYPES,
  objectId?: string
}

function TasksIndicators({ isOpen, setIsOpen, objectId, type }: Iprops) {
  const indicators = useTaskAggregations(type, Number(objectId));
  const myTasksCount = indicators.executor_count || 0;
  const referenceCount = type === TASK_LISTS_TYPES.MY ? myTasksCount : indicators.all_count;

  return (
    <IndicatorsWrapper
      containerClassName={styles.container}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      contentClassName={styles.content}
    >
      <div className={styles.all}>
        <span className={styles.allCount}>{indicators.all_count} задач</span>
        Всего
      </div>
      {(type === TASK_LISTS_TYPES.ALL && indicators.executor_count !== undefined) && (
        <CircleComponents
          color='#5EC5EB'
          displayingValue={`${indicators.executor_count} из ${indicators.all_count}`}
          progress={isNanChecker(Math.round((myTasksCount) / (indicators.all_count / 100)))}
          description="Мои задачи"
          isProgressStringHidden
          titleClassName={styles.circleTitle}
          descriptionClassName={styles.circleDescription}
        />
      )}
      <CircleComponents
        color='#D05EEB'
        displayingValue={indicators.in_work_count}
        progress={isNanChecker(Math.round((indicators.in_work_count || 0) / (referenceCount / 100)))}
        description="В работе"
        isProgressStringHidden
        titleClassName={styles.circleTitle}
        descriptionClassName={styles.circleDescription}
      />
      <CircleComponents
        color='#FF0558'
        displayingValue={indicators.expired_count}
        progress={isNanChecker(Math.round((indicators.expired_count || 0) / (referenceCount / 100)))}
        description="Просрочено"
        isProgressStringHidden
        titleClassName={styles.circleTitle}
        descriptionClassName={styles.circleDescription}
      />
      <CircleComponents
        color='#FF8900'
        displayingValue={indicators.expires_count}
        progress={isNanChecker(Math.round((indicators.expires_count || 0) / (referenceCount / 100)))}
        description="Срок истекает"
        isProgressStringHidden
        titleClassName={styles.circleTitle}
        descriptionClassName={styles.circleDescription}
      />
      <CircleComponents
        color='#FF0558'
        displayingValue={indicators.completed_count}
        progress={isNanChecker(Math.round((indicators.completed_count || 0) / (referenceCount / 100)))}
        description="Завершённые"
        isProgressStringHidden
        titleClassName={styles.circleTitle}
        descriptionClassName={styles.circleDescription}
      />
    </IndicatorsWrapper>
  )
}

export default React.memo(TasksIndicators);