import React, { useEffect, useRef } from "react";
import { Field, Form } from "react-final-form";

import { composeFieldValidators, maxLength, minLength, required } from "../../../../../../../../../utils/validations";

import InputBase from "../../../../../../../../UI/atoms/InputBase";
import ButtonBase from "../../../../../../../../UI/atoms/ButtonBase";

import styles from "../../ProviderContacts.module.scss";

const FIELDS = [
  { name: "bic", label: "БИК", length: 9 },
  { name: "bank_name", label: "Наименование банка" },
  { name: "itn", label: "ИНН", minLength: 10, maxLength: 12 },
  { name: "iec", label: "КПП", length: 9 },
  { name: "checking_account", label: "Расчетный счет", length: 20 },
  { name: "corresponding_account", label: "Корреспондентский счет", length: 20 },
  { name: "address", label: "Юридический адрес" }
];

const MAX_LENGTH_MESSAGE = "Кол-во символов больше максимального";
const MIN_LENGTH_MESSAGE = "Кол-во символов меньше минимального";

const getFieldValidators = (field) => {
  const validators = [required()];
  if (field.length) validators.push(
    maxLength(field.length, MAX_LENGTH_MESSAGE),
    minLength(field.length, MIN_LENGTH_MESSAGE)
  );
  return composeFieldValidators(...validators);
};

const ProviderRequisitesForm = ({ requisites, onSubmit }) => {
  const formRef = useRef();

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.form.submit();
  }, []);

  return (
    <Form
      ref={formRef}
      onSubmit={onSubmit}
      initialValues={requisites}
      validateOnBlur={true}
      render={({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          {FIELDS.map((field) => (
            <div className={styles.field} key={field.name}>
              <Field component={InputBase} validate={getFieldValidators(field)}{...field} />
            </div>
          ))}
          {dirty && (
            <footer className={styles.footer}>
              <ButtonBase type="submit" primary>Сохранить</ButtonBase>
            </footer>
          )}
        </form>
      )}
    />
  );
};

export default React.memo(ProviderRequisitesForm);
