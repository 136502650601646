import { createSelector } from "reselect";

import {
  MODULE_NAME,
  SET_ALL_PERMISSIONS_DATA,
  SET_ALL_PERMISSIONS_LOADING,
  SET_CURRENT_USER_PERMISSIONS_DATA,
  SET_CURRENT_USER_PERMISSIONS_LOADING,
  SET_USER_PERMISSIONS_DATA,
  SET_USER_PERMISSIONS_LOADING, UPDATE_USER_PERMISSIONS_DATA
} from "./constants";

export const INITIAL_STATE = {
  allPermissions: { data: null, isLoading: true },
  currentUserPermissions: { data: null, isLoading: true },
  userPermissions: { data: null, isLoading: true },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALL_PERMISSIONS_DATA:
      return { ...state, allPermissions: { ...state.allPermissions, data: action.payload } };
    case SET_ALL_PERMISSIONS_LOADING:
      return { ...state, allPermissions: { ...state.allPermissions, isLoading: action.payload } };
    case SET_CURRENT_USER_PERMISSIONS_DATA:
      return { ...state, currentUserPermissions: { ...state.currentUserPermissions, data: action.payload } };
    case SET_CURRENT_USER_PERMISSIONS_LOADING:
      return { ...state, currentUserPermissions: { ...state.currentUserPermissions, isLoading: action.payload } };
    case SET_USER_PERMISSIONS_DATA:
      return { ...state, userPermissions: { ...state.userPermissions, data: action.payload } };
    case SET_USER_PERMISSIONS_LOADING:
      return { ...state, userPermissions: { ...state.userPermissions, isLoading: action.payload } };
    case UPDATE_USER_PERMISSIONS_DATA:
      return { ...state, userPermissions: { ...state.userPermissions, data: [...state.userPermissions.data, ...action.payload] } };
    default:
      return state;
  }
};

const stateSelector = (state) => state[MODULE_NAME];

export const allPermissionsDataSelector = createSelector(stateSelector, (state) => state.allPermissions.data);
export const allPermissionsLoadingSelector = createSelector(stateSelector, (state) => state.allPermissions.isLoading);

export const currentUserPermissionsDataSelector = createSelector(
  stateSelector,
  (state) => state.currentUserPermissions.data
);
export const currentUserPermissionsLoadingSelector = createSelector(
  stateSelector,
  (state) => state.currentUserPermissions.isLoading
);

export const userPermissionsDataSelector = createSelector(stateSelector, (state) => state.userPermissions.data);
export const userPermissionsLoadingSelector = createSelector(stateSelector, (state) => state.userPermissions.isLoading);

export default reducer;
