import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import { useHistory, useParams } from "react-router-dom";
import { memoize } from "lodash";
import { message } from "antd";
import update from "immutability-helper";

import { filesSelector } from "redux/modules/common/orderCard/files/selectors";
import { getFiles } from "redux/modules/common/orderCard/files/thunks/getFiles";
import { getEmployees } from "redux/modules/common/orderCard/approval/thunks/getEmployees";
import { addEmployees } from "redux/modules/common/orderCard/approval/thunks/addEmployees";
import { deleteEmployee } from "redux/modules/common/orderCard/approval/thunks/deleteEmployee";
import { userSelector } from "redux/modules/common/auth";
import { resetToInitialAction as resetFilesToInitialAction } from "redux/modules/common/orderCard/files/actions";
import { resetToInitialAction as resetApprovalToInitialAction } from "redux/modules/common/orderCard/approval/actions";

import ManageOrder from "components/UI/organism/ManageOrder";
import { optionsIds } from "components/UI/organism/ManageOrder/options";
import useManageOrderOptions from "components/UI/organism/ManageOrder/hooks/useManageOrderOptions";
import { TYPES } from "components/UI/organism/Approvers";

import {
  COMPLETED,
  NEW,
  PAYMENT_DECISION,
  PCR_NEED_APPROVE,
  PCR_REJECTED,
  PVR_NEED_APPROVE,
  PVR_REJECTED,
} from "utils/constant";

import { stringifyArgs } from "utils/stringifyArgs";

import {
  cancelOrderV2,
  currentOrderSelector,
  getOrder,
  getProviderRequisites,
  providerRequisitesSelector,
  resetProviderRequisitesToInitialAction,
  saveOrder,
  sendToPayment as sendToPaymentDecisionAction,
  setCurrentOrderData,
  setProviderRequisitesModalOpenAction,
} from "../../../redux/modules/common/orderCard/orders";

import {
  orderApprovalSelector,
  paymentApprovalSelector,
} from "../../../redux/modules/common/orderCard/approval/selectors";

import { setOrderPayment } from "../../../redux/modules/common/building/requisition/reducer";
import { APPROVAL_TYPES } from "../../../redux/modules/common/orderCard/approval/constants";

import useApprovalAndView from "../../../hooks/useApprovalAndView";
import ApprovalAndView from "../../../domain/ApprovalAndView";
import { getUserType } from "../../../utils/getUserType";

import OrderRequests from "../../UI/OrderRequests";
import { Spinner } from "../../UI/Spinner/Spinner";
import Checkbox from "../../UI/Checkbox";
import BackNavigationBar from "../../UI/atoms/BackNavigationBar/BackNavigationBar";
import Paper from "../../UI/templates/Paper/Paper";

import { checkOrderStatusForApprove } from "../Requisition/utils/checkOrderStatusForApprove";

import OrderHeader from "./components/Header/Header";
import OrderFooter from "./components/Footer/Footer";
import ApproversAndViewers from "./components/ApproversAndViewers";

import usePreFillingOrderBody from "./hooks/usePreFillingOrderBody";
import OrderValidator from "./domain/OrderValidator";

import { DEFAULT_ENTITY_TYPE } from "./constants";
import styles from "./index.module.scss";

const checkProviderRequisitesValid = (providerRequisites) =>
  Object.values(providerRequisites).every((requisite) => !!requisite);

const Order = ({ permissions, isSimplified }) => {
  const { orderId } = useParams();
  const history = useHistory();
  const from = new URLSearchParams(history.location.search).get("from");

  const dispatch = useDispatch();
  const { data: order, isLoading: orderIsLoading, isError: orderIsError } = useSelector(currentOrderSelector);

  const user = useSelector(userSelector);
  const authEntityType = useSelector((state) => state.auth.entity.type) || DEFAULT_ENTITY_TYPE;

  const { data: providerRequisites, isLoading: providerRequisitesAreLoading } = useSelector(providerRequisitesSelector);

  const orderApproval = useSelector(orderApprovalSelector);
  const paymentApproval = useSelector(paymentApprovalSelector);

  const { approvalAndView: orderApprovalAndView, isLoading: orderApprovalAndViewIsLoading } = useApprovalAndView(
    orderApproval.list
  );
  const { approvalAndView: paymentApprovalAndView, isLoading: paymentApprovalAndViewIsLoading } = useApprovalAndView(
    paymentApproval.list
  );

  const onOrderValidatorError = (errors) => {
    errors.forEach((error) => message.error(error));
  };

  const checkOrderValid = (order, isThrowError = true) => {
    const orderValidator = new OrderValidator(isThrowError ? onOrderValidatorError : () => {});

    return orderValidator.validate(order);
  };

  const canAddOrderApprovers = useMemo(
    () => orderApprovalAndView.checkCanAddApprovers() && checkOrderStatusForApprove(order.status, APPROVAL_TYPES.order),
    [orderApprovalAndView, order.status]
  );

  const canAddPaymentApprovers = useMemo(
    () =>
      paymentApprovalAndView.checkCanAddApprovers() && checkOrderStatusForApprove(order.status, APPROVAL_TYPES.payment),
    [paymentApprovalAndView, order.status]
  );

  const areShownPaymentApproversAndViewers = useMemo(() => {
    return !canAddOrderApprovers && order.status !== PCR_NEED_APPROVE;
  }, [canAddOrderApprovers, order.status]);

  const checkManageOrderOptionsIds = useCallback(
    () => ({
      [optionsIds.approveOrder]:
        order.was_saved &&
        order.status !== PAYMENT_DECISION &&
        orderApprovalAndView.checkCanUserApprove(orderApprovalAndView.approvers, user.id),
      [optionsIds.unApproveOrder]:
        order.status === PCR_NEED_APPROVE &&
        orderApprovalAndView.checkCanUserUnApprove(orderApprovalAndView.approvers, user.id),
      [optionsIds.approvePayment]:
        areShownPaymentApproversAndViewers &&
        paymentApprovalAndView.checkCanUserApprove(paymentApprovalAndView.approvers, user.id),
      [optionsIds.unApprovePayment]: paymentApprovalAndView.checkCanUserUnApprove(
        paymentApprovalAndView.approvers,
        user.id
      ),
    }),
    [
      paymentApprovalAndView,
      orderApprovalAndView,
      user.id,
      order.status,
      order.auto_transition_to_payment,
      order.was_saved,
      areShownPaymentApproversAndViewers,
    ]
  );

  const [manageOrderOptionsIds, setManageOrderOptionsIds] = compose(useState, checkManageOrderOptionsIds)();
  const manageOrderActiveOptions = useManageOrderOptions(manageOrderOptionsIds, dispatch, orderId);

  const [changedOrder, setChangedOrder] = useState();
  const [orderRequests, setOrderRequests] = useState();
  const [orderKits, setOrderKits] = useState();

  const files = useSelector(filesSelector);

  const handleAddEmployees = useCallback(
    (type) => (addedEmployees) => {
      if (!order) return;
      const employeesToRequest = ApprovalAndView.getApprovalElementsFromEmployees(addedEmployees);

      compose(dispatch, addEmployees)(employeesToRequest, {
        orderId: order.id,
        approvalType: APPROVAL_TYPES[type],
        userType: getUserType(authEntityType),
      });
    },
    [order.id, authEntityType]
  );

  const handleAddViewers = useCallback(
    (type) => (addedEmployees) => handleAddEmployees(type)(ApprovalAndView.makeEmployeesViewers(addedEmployees)),
    [handleAddEmployees]
  );

  const handleAddApprovers = useCallback(
    (type) => (addedEmployees) => handleAddEmployees(type)(ApprovalAndView.makeEmployeesApprovers(addedEmployees)),
    [handleAddEmployees]
  );

  const handleDeleteEmployee = useCallback(
    (type) => (deletedEmployee) => () =>
      compose(dispatch, deleteEmployee)(deletedEmployee, {
        orderId: order.id,
        approvalType: APPROVAL_TYPES[type],
        userType: getUserType(authEntityType),
      }),
    [order.id, authEntityType]
  );

  const memoizedHandleDeleteEmployee = useMemo(
    () => memoize(handleDeleteEmployee, stringifyArgs),
    [handleDeleteEmployee]
  );

  const setOfferSupplyOrComponentCountRequestMeasureByCount = (supplyOrComponent) => ({
    ...supplyOrComponent,
    count_request_measure: supplyOrComponent.count_request_measure || supplyOrComponent.count,
  });

  const prepareOfferToRequest = (offer) => {
    const preparedOffer = {
      ...offer,
      supplies: offer.supplies.map(setOfferSupplyOrComponentCountRequestMeasureByCount),
    };

    if (preparedOffer.components) {
      preparedOffer.components = offer.components.map(setOfferSupplyOrComponentCountRequestMeasureByCount);
    }

    return preparedOffer;
  };

  const prepareOrderToRequest = (order) => ({
    ...order,
    body: {
      ...order.body,
      requests: order.body.requests.map((request) => ({
        ...request,
        offers: request.offers.map(prepareOfferToRequest),
      })),
      kits: order.body.kits.map((kit) => ({ ...kit, offers: kit.offers.map(prepareOfferToRequest) })),
    },
  });

  const handleSaveOrder = useCallback(() => {
    const orderWithUpdatedBody = {
      ...changedOrder,
      body: update(changedOrder.body, { requests: { $set: orderRequests }, kits: { $set: orderKits } }),
    };

    if (!checkOrderValid(orderWithUpdatedBody)) return;
    if (!checkProviderRequisitesValid(providerRequisites)) {
      message.error("Заполните реквизиты поставщика!");
      compose(dispatch, setProviderRequisitesModalOpenAction)(true);
      return;
    }

    compose(dispatch, saveOrder)(getUserType(authEntityType), orderId, prepareOrderToRequest(orderWithUpdatedBody));
  }, [changedOrder, orderId, authEntityType, orderKits, orderRequests, providerRequisites]);

  const cancelOrder = () => compose(dispatch, cancelOrderV2)(orderId);

  const handleAutoTransitionToPayment = useCallback(
    (e) => setChangedOrder((prevState) => ({ ...prevState, auto_transition_to_payment: e.target.checked })),
    []
  );

  const canSendToPayment = useMemo(() => {
    if (!order.status || !order.building) return false;

    const orderCanSendToPayment =
      paymentApprovalAndView.checkAllApproversApprove() && order.status === PAYMENT_DECISION;
    const userCanSendToPayment =
      !order.building.payment_decision_employee || user.id === order.building.payment_decision_employee.id;
    return orderCanSendToPayment && userCanSendToPayment;
  }, [paymentApprovalAndView, order.status, order.building?.payment_decision_employee?.id]);

  const sendToPayment = useCallback(
    (message) => {
      compose(dispatch, setOrderPayment)(orderId, { comment: message }, setCurrentOrderData, true);
    },
    [orderId]
  );

  const canSendToPaymentDecision = useMemo(() => {
    if (!order.status) return false;

    return (
      orderApprovalAndView.approvers.length !== 0 &&
      !order.auto_transition_to_payment &&
      order.status === PCR_NEED_APPROVE &&
      orderApprovalAndView.checkAllApproversApprove()
    );
  }, [order.status, order.auto_transition_to_payment, orderApprovalAndView]);

  const sendToPaymentDecision = useCallback(() => {
    compose(dispatch, sendToPaymentDecisionAction)(orderId);
  }, [orderId]);

  const canEditOrder = useMemo(() => {
    if (!order) return true;
    if (order.status === COMPLETED || order.status === PCR_REJECTED || order.status === PVR_REJECTED) return false;
    if (orderApprovalAndView.getApprovedApprovers().length !== 0) return false;
    if (order.is_internal) return order.status === PCR_NEED_APPROVE;

    return order.status !== NEW || order.status !== PVR_NEED_APPROVE;
  }, [order.is_internal, order.status, orderApprovalAndView]);

  const updateOrder = useCallback(() => compose(dispatch, setCurrentOrderData)({}), []);

  const orderRequestsPermissions = useMemo(
    () => ({
      viewInvoiceDifference: permissions.viewOrderInvoiceDifference,
      editRequest: canEditOrder,
      deleteRequest: order?.body?.requests.length > 1,
      addFiles: permissions.viewAddOrderRequestsFiles,
      deleteFiles: permissions.viewDeleteOrderRequestsFiles,
    }),
    [permissions.viewOrderInvoiceDifference, canEditOrder, order?.body?.requests.length]
  );

  useEffect(() => {
    compose(setManageOrderOptionsIds, checkManageOrderOptionsIds)();
  }, [checkManageOrderOptionsIds]);

  useEffect(() => {
    if (orderIsLoading || !order.id) return;
    setChangedOrder({ ...order, auto_transition_to_payment: permissions.autoTransitionToPayment || false });
  }, [order, orderIsLoading]);

  useEffect(() => {
    if (!orderId || !authEntityType) return;
    compose(dispatch, getOrder)(orderId, { userType: getUserType(authEntityType), withLoading: true });
    compose(dispatch, getFiles)(orderId, getUserType(authEntityType));
    compose(dispatch, getProviderRequisites)(orderId);
  }, [orderId, authEntityType]);

  useEffect(() => {
    if (
      order.status !== PCR_NEED_APPROVE ||
      canAddOrderApprovers ||
      !authEntityType ||
      !order.auto_transition_to_payment
    )
      return;

    compose(dispatch, getOrder)(orderId, { userType: getUserType(authEntityType) });
  }, [canAddOrderApprovers, orderId, order.status, authEntityType, order.auto_transition_to_payment]);

  useEffect(() => {
    if (!orderId || !order.was_saved || !authEntityType) return;

    compose(dispatch, getEmployees)(orderId, {
      userType: getUserType(authEntityType),
      approvalType: APPROVAL_TYPES.order,
    });

    compose(dispatch, getEmployees)(orderId, {
      userType: getUserType(authEntityType),
      approvalType: APPROVAL_TYPES.payment,
    });
  }, [orderId, authEntityType, order.was_saved]);

  usePreFillingOrderBody(order.was_saved, orderRequests, setOrderRequests);

  useEffect(() => {
    if (!order || !order.body) return;
    setOrderRequests(order.body.requests);
  }, [order.body?.requests]);

  useEffect(() => {
    if (!order || !order.body) return;
    setOrderKits(order.body.kits);
  }, [order.body?.kits]);

  useEffect(() => {
    return () => {
      updateOrder();
      compose(dispatch, resetFilesToInitialAction)();
      compose(dispatch, resetApprovalToInitialAction)({ approvalType: APPROVAL_TYPES.order });
      compose(dispatch, resetApprovalToInitialAction)({ approvalType: APPROVAL_TYPES.payment });
      compose(dispatch, resetProviderRequisitesToInitialAction)();
    };
  }, [updateOrder]);

  if (orderIsError) return null;

  if (files.isLoading || orderIsLoading || providerRequisitesAreLoading || !order || !changedOrder)
    return <Spinner isFixed />;

  return (
    <div className={styles.container}>
      {!isSimplified && <BackNavigationBar title="Заказы" backLink={from} rightSideText={order?.building?.name} />}
      <Paper>
        <div className={styles.content}>
          <OrderHeader order={changedOrder} permissions={permissions} isSimplified={isSimplified} />
          <OrderRequests
            className={styles.requests}
            requests={orderRequests}
            kits={orderKits}
            buildingId={order.building?.id}
            updateOrder={updateOrder}
            permissions={orderRequestsPermissions}
            setRequests={setOrderRequests}
            setKits={setOrderKits}
          />
          <OrderFooter canEdit={canEditOrder} order={changedOrder} changeOrder={setChangedOrder} />
          {order.was_saved && (
            <ApproversAndViewers
              buildingId={order.building.id}
              orderApprovers={orderApprovalAndView.approvers}
              paymentApprovers={paymentApprovalAndView.approvers}
              handleDeleteOrderApprover={memoizedHandleDeleteEmployee(TYPES.order)}
              handleAddOrderApprovers={handleAddApprovers(TYPES.order)}
              canAddOrderApprovers={canAddOrderApprovers}
              canAddPaymentApprovers={canAddPaymentApprovers}
              orderViewers={orderApprovalAndView.viewers}
              handleDeleteOrderViewer={memoizedHandleDeleteEmployee(TYPES.order)}
              handleAddOrderViewers={handleAddViewers(TYPES.order)}
              areShownPaymentApproversAndViewers={areShownPaymentApproversAndViewers}
              paymentViewers={paymentApprovalAndView.viewers}
              handleDeletePaymentApprover={memoizedHandleDeleteEmployee(TYPES.payment)}
              handleAddPaymentApprovers={handleAddApprovers(TYPES.payment)}
              handleDeletePaymentViewer={memoizedHandleDeleteEmployee(TYPES.payment)}
              handleAddPaymentViewers={handleAddViewers(TYPES.payment)}
              approversAreLoading={paymentApprovalAndViewIsLoading || orderApprovalAndViewIsLoading}
            />
          )}
          <footer className={styles.footer}>
            <div className={styles.checkboxContainer}>
              <Checkbox
                disabled={!canEditOrder || permissions.autoTransitionToPayment}
                checked={changedOrder.auto_transition_to_payment}
                onChange={handleAutoTransitionToPayment}
              >
                Автоматический переход по согласованию
              </Checkbox>
            </div>
            {checkOrderStatusForApprove(order.status) && (
              <ManageOrder
                options={manageOrderActiveOptions}
                canSendToPayment={canSendToPayment}
                sendToPayment={sendToPayment}
                orderPaymentTerms={changedOrder.payment_terms}
                canCancelOrder={order.status === PCR_NEED_APPROVE}
                cancelOrder={cancelOrder}
                canSaveOrder={canEditOrder}
                saveOrder={handleSaveOrder}
                canSendToPaymentDecision={canSendToPaymentDecision}
                sendToPaymentDecision={sendToPaymentDecision}
              />
            )}
          </footer>
        </div>
      </Paper>
    </div>
  );
};

export default React.memo(Order);
