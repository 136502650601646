import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { compose } from "redux";

import { getSections, getSectionsExpenditure } from "../../../../redux/modules/common/building/sections/sectionsApi";
import {
  expendituresBySectionsSelector,
  getExpendituresBySection,
} from "../../../../redux/modules/common/building/sections/sections";
import { errorCatcher } from "../../../../utils/errorCatcher";

import { ESTIMATE_STATES_IDS } from "../../../pages/Handler/constants";

import SectionsWithCheckedExpenditures from "../../molecules/SectionsWithCheckedExpenditures/SectionsWithCheckedExpenditures";
import SliderModal from "../../atoms/SliderModal";
import ButtonBase from "../../atoms/ButtonBase";
import { InputSearchRound } from "../../InputSearchRound";
import { Spinner } from "../../Spinner/Spinner";

import styles from "./index.module.scss";

const AddRelationToProduct = ({ idAddRelation, updateProducts, handleClose, objectId, type, onSubmit }) => {
  const [initialSections, setInitialSections] = useState();
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const expendituresBySections = useSelector(expendituresBySectionsSelector);

  const [selectedEstimateTarget, setSelectedEstimateTarget] = useState(null);

  const checkedExpenditures = useMemo(
    () => (selectedEstimateTarget ? { [selectedEstimateTarget.expenditureId]: true } : {}),
    [selectedEstimateTarget]
  );

  const editable = idAddRelation?.editable;

  const handleCloseModal = () => {
    handleClose();
  };

  const getEstimateTargetPath = useCallback(() => {
    const selectedSection = sections.find((section) => section.id === selectedEstimateTarget.sectionId);
    const selectedSubsection = selectedSection.subsections.find(
      (subsection) => subsection.id === selectedEstimateTarget.subsectionId
    );
    const selectedExpenditure = expendituresBySections[selectedEstimateTarget.subsectionId].results.find(
      (expenditure) => expenditure.id === selectedEstimateTarget.expenditureId
    );

    return { section: selectedSection, subsection: selectedSubsection, expenditure: selectedExpenditure };
  }, [expendituresBySections, sections, selectedEstimateTarget]);

  const handleSubmit = useCallback(() => {
    if (!selectedEstimateTarget) return;

    if (!onSubmit) {
      axios
        .patch(`/building/${objectId}/materials/${type}/${idAddRelation.id}/`, {
          estimate_expenditure_id: selectedEstimateTarget.expenditureId,
        })
        .then((response) => {
          message.success("Продукт сопоставлен");
          if (updateProducts) updateProducts(response.data);
        })
        .catch(errorCatcher);
    } else {
      onSubmit(selectedEstimateTarget.expenditureId, getEstimateTargetPath());
    }

    handleClose();
  }, [
    getEstimateTargetPath,
    handleClose,
    idAddRelation.id,
    objectId,
    onSubmit,
    selectedEstimateTarget,
    type,
    updateProducts,
  ]);

  const onSelectExpenditure = useCallback((estimateTarget, isSelect) => {
    if (isSelect) setSelectedEstimateTarget(estimateTarget);
  }, []);

  const searchedSections = useMemo(() => {
    if (!sections) return [];
    return sections.map((sectionItem) => ({
      ...sectionItem,
      subsections: sectionItem?.subsections?.filter(
        (subsectionItem) => subsectionItem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ),
    }));
  }, [sections, search]);

  const onChangeSearchInput = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  const debouncedOnChangeSearchInput = useMemo(() => debounce(onChangeSearchInput, 300), [onChangeSearchInput]);

  const serializedExpendituresBySections = useMemo(() => {
    const expenditures = {};
    Object.entries(expendituresBySections).forEach(([sectionId, { results }]) => (expenditures[sectionId] = results));
    return expenditures;
  }, [expendituresBySections]);

  const onOpenHiddenSubsection = useCallback(
    (subsectionId) => {
      if (expendituresBySections[subsectionId]) return;

      compose(
        dispatch,
        getExpendituresBySection
      )({
        building: objectId,
        section: subsectionId,
        estimateState: ESTIMATE_STATES_IDS.PRODUCTION,
      });
    },
    [expendituresBySections, objectId]
  );

  useEffect(() => {
    getSections(objectId).then(setInitialSections);
  }, [objectId]);

  useEffect(() => {
    if (!initialSections) return;

    const sectionsPromises = initialSections.map(({ id }) => {
      return getSectionsExpenditure(objectId, id).then((sectionsWithSubsections) => {
        if (!sectionsWithSubsections) return;
        setSections((prevState) => [...prevState, sectionsWithSubsections]);
      });
    });
    Promise.all(sectionsPromises).then(() => setIsLoading(false));
  }, [initialSections, objectId]);

  return (
    <SliderModal isOpen={idAddRelation?.id} closeHandler={handleCloseModal} className={styles.sliderClassName}>
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>Связь со сметой</div>
          </div>
          <div className={styles.searchBlock}>
            <InputSearchRound onChange={debouncedOnChangeSearchInput} className={styles.inputClassName} />
            <ButtonBase className={styles.btn} primary onClick={handleSubmit} disabled={!selectedEstimateTarget}>
              Связать
            </ButtonBase>
          </div>
          <div className={styles.content}>
            {!isLoading ? (
              <SectionsWithCheckedExpenditures
                sections={searchedSections}
                expendituresBySections={serializedExpendituresBySections}
                checkedExpenditures={checkedExpenditures}
                onCheckExpenditure={onSelectExpenditure}
                onOpenHiddenSubsection={onOpenHiddenSubsection}
              />
            ) : (
              <Spinner isStatic />
            )}
          </div>
        </div>
        <div className={styles.bottomBorder} />
      </div>
    </SliderModal>
  );
};

export default React.memo(AddRelationToProduct);
