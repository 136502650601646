import folderSvg from "./assets/folder.svg";
import wordSvg from "./assets/word.svg";
import excelSvg from "./assets/excel.svg";
import pdfSvg from "./assets/pdf.svg";
import djvuSvg from "./assets/djvu.svg";

import {
  FilesExtensionsEnum,
  IMG_EXTENSIONS_ARR,
  TABLE_EXTENSIONS_ARR,
  TEXT_EXTENSIONS_ARR,
} from "constants/files";

import { parseFileExtension } from "utils/parseFileFormat";
import { sliceTextByConstraint } from "utils/sliceTextByConstraint";

import styles from "./FileItem.module.scss";

export const switchFileIcons = (
  fileName: string,
  isFolder?: boolean,
  fileSrc?: string
) => {
  if (isFolder) return <img src={folderSvg} />;
  const extension = parseFileExtension(fileName);
  if (
    fileSrc &&
    IMG_EXTENSIONS_ARR.includes(extension as FilesExtensionsEnum)
  ) {
    return <img src={fileSrc} className={styles.photo} />;
  }
  if (TEXT_EXTENSIONS_ARR.includes(extension as FilesExtensionsEnum)) {
    return <img src={wordSvg} />;
  }
  if (TABLE_EXTENSIONS_ARR.includes(extension as FilesExtensionsEnum)) {
    return <img src={excelSvg} />;
  }
  if (extension === FilesExtensionsEnum.pdf) {
    return <img src={pdfSvg} />;
  }
  if (extension === FilesExtensionsEnum.djvu) {
    return <img src={djvuSvg} />;
  }
  return (
    <div className={styles.customExtension}>
      {sliceTextByConstraint(extension.toUpperCase(), 3, "")}
    </div>
  );
};
