import React from "react";

import TemplateSimple from "../../../../UI/templates/TemplateSimple";

import Bill from "./Bill";

const TemplatedBill = (props) => {
  return (
    <TemplateSimple>
      <Bill {...props} />
    </TemplateSimple>
  );
};

export default React.memo(TemplatedBill);
