import { isEqual } from "lodash";
import moment from "moment";

import { IBuilding } from "types/interfaces/Building";
import { Iemployee } from "types/personsTypes";
import { IBuildingApprovalsSets } from "../types";


interface IBaseProps {
  localForm: Record<string, string>;
  dirty: boolean;
}

interface IExtendedProps extends IBaseProps {
  object: IBuilding;
  employees: Iemployee[];
  responsible_employee: Iemployee,
  pto_employee: Iemployee,
  payment_decision_employee: Iemployee,
  timesheetResponsibleEmployee: Iemployee,
  stockResponsibleEmployee: Iemployee,
  estimateResponsible: Iemployee;
  stockData: { responsible: Iemployee },
  approvals: IBuildingApprovalsSets,
  dateStart: string,
  dateEnd: string
}

export const dirtySubmitBtnSwitcher = ({
  localForm,
  dirty,
  object,
  employees,
  responsible_employee,
  pto_employee,
  payment_decision_employee,
  timesheetResponsibleEmployee,
  stockResponsibleEmployee,
  stockData,
  approvals,
  dateEnd,
  dateStart,
  estimateResponsible
}: IExtendedProps): boolean => {
  if (dirty) return true;
  if (+localForm.entity !== +object?.entity?.id || localForm.name !== object?.name) return true;
  if (moment(dateStart).format() !== moment(object?.construction_date_start).format()) return true;
  if (moment(dateEnd).format() !== moment(object?.construction_date_end).format()) return true;

  if (responsible_employee?.id !== object?.responsible_employee?.id) return true;
  if (pto_employee?.id !== object?.pto_employee?.id) return true;
  if (payment_decision_employee?.id !== object?.payment_decision_employee?.id) return true;
  if (timesheetResponsibleEmployee?.id !== object?.timesheet_responsible?.id) return true;
  if (stockResponsibleEmployee?.id !== stockData?.responsible?.id) return true;
  if (estimateResponsible?.id !== object?.responsible_estimate?.id) return true;

  const localEmployeesIds = employees?.map(el => el.id).sort();
  const objectEmployees = object?.employees?.map(el => el.id).sort();
  if (!isEqual(localEmployeesIds, objectEmployees)) return true;

  const localApprovals = {
    orderApprovalEmployees: approvals?.orderApprovalEmployees?.map(el => ({ id: el.id, position: el.position })),
    paymentApprovalEmployees: approvals?.paymentApprovalEmployees?.map(el => ({ id: el.id, position: el.position })),
    requisitionApprovalEmployees: approvals?.requisitionApprovalEmployees?.map(el => ({ id: el.id, position: el.position })),
    timesheetApprovalEmployees: approvals?.timesheetApprovalEmployees?.map(el => ({ id: el.id, position: el.position }))
  };
  const objectApprovals = {
    orderApprovalEmployees: object?.orderchainapprovalemployees_set?.map(el => ({ id: el.user, position: el.position })),
    paymentApprovalEmployees: object?.paymentchainapprovalemployees_set?.map(el => ({ id: el.user, position: el.position })),
    requisitionApprovalEmployees: object?.requisitionchainapprovalemployees_set?.map(el => ({ id: el.user, position: el.position })),
    timesheetApprovalEmployees: object?.timesheetchainapprovalemployees_set?.map(el => ({ id: el.user, position: el.position }))
  };
  if (!isEqual(localApprovals, objectApprovals)) return true;

  return false;
};

export const dirtySubmitBtnSwitcherForNewProject = ({ localForm, dirty }: IBaseProps): boolean => {
  if (dirty) return true;

  if (localForm.name !== "") return true;
  if (+localForm.entity) return true;


  return false;
};