import axios from "axios";
import { createSelector } from "reselect";
import { errorCatcher } from "../../../../../utils/errorCatcher";

const moduleName = "employees";
const SET_PAGINATION = `${moduleName}/SET_PAGINATION`;
const SET_USERS_LIST = `${moduleName}/SET_USERS_LIST`;
const LOAD_USERS_LIST = `${moduleName}/LOAD_USERS_LIST`;
const ADD_LOAD_USERS = `${moduleName}/ADD_LOAD_USERS`;
const LOCK_USER = `${moduleName}/LOCK_USER`;
const PUSH_NEW_USER = `${moduleName}/PUSH_NEW_USER`;

const initialState = {
  tableData: {
    count: 1,
    list: [],
    results: [],
  },
  pagination: {
    params: { limit: 10, offset: 0, ordering: "last_name", is_blocked: false },
    page: 1,
  },
  sorting: null,
  loading: false,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...payload,
        },
      };
    }

    case LOAD_USERS_LIST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SET_USERS_LIST: {
      return {
        ...state,
        tableData: payload,
        loading: false,
      };
    }
    case ADD_LOAD_USERS:
      return {
        ...state,
        tableData: {
          count: payload.count,
          results: [...state.tableData.results, ...payload.results],
        },
      };
    case LOCK_USER:
      if (!state.tableData.results) return state;
      const updatedUsersList = state.tableData.results.map((user) => (user.id === payload.id ? payload : user));
      return {
        ...state,
        tableData: {
          ...state.tableData,
          results: !state.pagination.params.is_blocked
            ? updatedUsersList.filter((x) => !x.is_blocked)
            : updatedUsersList,
        },
      };

    case PUSH_NEW_USER:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          results: [payload, ...state.tableData.results.filter((x) => x.id !== payload.id)],
        },
      };

    default: {
      return state;
    }
  }
};

export const stateSelector = (state) => state.employees;
export const loadingStateSelector = createSelector(stateSelector, (state) => state.loading);
export const paginationStateSelector = createSelector(stateSelector, (state) => state.pagination);
export const dataStateSelector = createSelector(stateSelector, (state) => state.tableData);

export const setPaginationState = (params, page) => {
  return {
    type: SET_PAGINATION,
    payload: { params, page },
  };
};

export const loadUsersState = () => {
  return {
    type: LOAD_USERS_LIST,
  };
};

export const setUsersState = (value) => {
  return {
    type: SET_USERS_LIST,
    payload: value,
  };
};

const MAX_LIMIT = 1000;

export const getUsersList = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;
  return (entityId, paginationParams, filterParams = null) => {
    if (gu_cancel) gu_cancel();

    const config = {
      params: { ...paginationParams, ...filterParams },
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      }),
    };

    if (!config.params.limit) config.params.limit = MAX_LIMIT;

    return (dispatch) => {
      dispatch(loadUsersState());

      axios
        .get(`/users/`, config)
        .then((response) => dispatch(setUsersState(response.data)))
        .catch(errorCatcher);
    };
  };
})();

export const loadUsers = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;
  return (entityId, paginationParams, filterParams = null) => {
    if (gu_cancel) gu_cancel();
    const config = {
      params: {
        ...paginationParams,
        ...filterParams,
      },
      cancelToken: new CancelToken((c) => {
        gu_cancel = c;
      }),
    };

    return (dispatch) => {
      axios.get(`/users/`, config).then(
        (res) => {
          dispatch({
            type: ADD_LOAD_USERS,
            payload: res.data,
          });
        },
        (err) => {
          console.error(err);
        }
      );
    };
  };
})();

export const deleteUser = (() => {
  const CancelToken = axios.CancelToken;
  let gu_cancel;
  return (id, callback = null) => {
    if (gu_cancel) gu_cancel();
    const params = { is_active: false };
    const config = { cancelToken: new CancelToken((c) => (gu_cancel = c)) };

    return () =>
      axios
        .put(`/users/${id}/`, params, config)
        .then(() => {
          if (callback) callback();
        })
        .catch(errorCatcher);
  };
})();

export const lockUser = (id, status) => (dispatch) => {
  axios
    .patch(`/users/${id}/`, { is_blocked: status })
    .then((response) => dispatch({ type: LOCK_USER, payload: response.data }))
    .catch(errorCatcher);
};

export const pushNewUser = (userData) => {
  return {
    type: PUSH_NEW_USER,
    payload: userData,
  };
};
