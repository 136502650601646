import React, { useCallback } from "react";

import { Spinner } from "../../../../Spinner/Spinner";
import HideBlock from "../../../../atoms/HideBlock";

import CheckedExpenditure from "./components/CheckedExpenditure/CheckedExpenditure";
import styles from "./HiddenSubsection.module.scss";

const HiddenSubsection = ({
  subsection,
  expenditures,
  expendituresAreLoading,
  onOpen,
  checkedExpenditures,
  onCheckExpenditure,
}) => {
  const handleOnCheckExpenditure = useCallback(
    (expenditureId, isChecked) => onCheckExpenditure({ subsectionId: subsection.id, expenditureId }, isChecked),
    [onCheckExpenditure, subsection.id]
  );

  const handleOnOpen = useCallback(() => onOpen(subsection.id), [onOpen, subsection.id]);

  return (
    <HideBlock titleClassName={styles.subSectionTitleClassName} title={subsection.name} onOpenAction={handleOnOpen}>
      {!expendituresAreLoading ? (
        expenditures.map((expenditure) => (
          <CheckedExpenditure
            expenditure={expenditure}
            isChecked={checkedExpenditures[expenditure.id]}
            onCheck={handleOnCheckExpenditure}
            key={expenditure.id}
          />
        ))
      ) : (
        <Spinner isSmall isStatic />
      )}
    </HideBlock>
  );
};

export default React.memo(HiddenSubsection);
