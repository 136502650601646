import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { manufacturingTabSelector } from "redux/modules/common/building/manufacturing/selectors";
import { setManufacturingTab } from "redux/modules/common/building/manufacturing/thunks";

import { MODULES_ENUM } from "types/enums/ModulesEnum";
import { MATERIALS_TAB_ID, WORKS_TAB_ID } from "../constants";

import { useUrlModule } from "utils/hooks/useUrlModule";

export interface IUseTabsWithPermissionsProps {
  haveViewWorksTabPermission: boolean;
  haveViewMaterialsTabPermission: boolean;
}

export const useTabsWithPermissions = ({
  haveViewWorksTabPermission,
  haveViewMaterialsTabPermission,
}: IUseTabsWithPermissionsProps) => {
  const dispatch = useDispatch();
  const tab = useSelector(manufacturingTabSelector);
  const module = useUrlModule();

  const setTab = useCallback((newTab: string) => {
    dispatch(setManufacturingTab(newTab));
  }, []);

  const isShowMaterials = useMemo(
    () => module === MODULES_ENUM.OBJECTS || module === MODULES_ENUM.PURCHASES,
    [module]
  );
  const isShowWorks = useMemo(
    () =>
      module === MODULES_ENUM.OBJECTS || module === MODULES_ENUM.CONSTRUCTING,
    [module]
  );

  const worksMaterialsTabs = useMemo(() => {
    const res = [];
    if (haveViewWorksTabPermission && isShowWorks)
      res.push({ id: WORKS_TAB_ID, text: "Работы" });
    if (haveViewMaterialsTabPermission && isShowMaterials)
      res.push({ id: MATERIALS_TAB_ID, text: "Материалы" });
    return res;
  }, [
    haveViewWorksTabPermission,
    isShowWorks,
    haveViewMaterialsTabPermission,
    isShowMaterials,
  ]);

  useEffect(() => {
    if (!isShowMaterials) setTab(WORKS_TAB_ID);

    if (
      (!haveViewWorksTabPermission && haveViewMaterialsTabPermission) ||
      !isShowWorks
    ) {
      setTab(MATERIALS_TAB_ID);
    }
  }, [
    haveViewWorksTabPermission,
    haveViewMaterialsTabPermission,
    isShowMaterials,
    setTab,
    isShowWorks,
  ]);

  return {
    worksMaterialsTabs,
    tab,
    setTab,
  };
};
